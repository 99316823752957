import { ParseKeys } from 'i18next';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import registry from '../../registry';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import useFetcher from '../../util/swr/use-fetcher';
import Translate from '../../util/translate/translate';
import PERSON_CHILD_ROUTES from './person-child-routes';
import { fetchPerson } from './person-queries';

const PersonEditor = React.lazy(() => import('./person-editor/person-editor'));
const PersonPage = React.lazy(() => import('./person-page/person-page'));
const PersonViewer = React.lazy(() => import('./person-viewer/person-viewer'));
const PersonHistory = React.lazy(() => import('./person-history/person-history'));
const PersonLister = React.lazy(() => import('./person-lister/person-lister'));
const PersonMerger = React.lazy(() => import('./person-merger/person-merger-page'));
const PersonDuplicatesLister = React.lazy(() => import('./person-lister/duplicates-lister/person-duplicates-lister'));
const PersonNonDuplicatesLister = React.lazy(
  () => import('./person-lister/duplicates-lister/person-non-duplicates-lister'),
);
const PersonToDeleteLister = React.lazy(() => import('./person-to-delete-lister/person-to-delete-lister'));
const PersonCommentViewer = React.lazy(() => import('./person-comment/person-comment-viewer'));
const PersonRelationViewer = React.lazy(() => import('./person-relation/person-relation-viewer'));
const PersonRelationEditor = React.lazy(() => import('./person-relation/person-relation-editor'));
const PersonDuplicatePage = React.lazy(() => import('./person-duplicate-page/person-duplicate-page'));
const AnonymisationPage = React.lazy(() => import('./anonymisation/anonymisation-page'));

const personRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'persons',
    handle: {
      breadcrumb: <Translate ns="person" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        element: <PersonLister />,
        handle: {
          helmet: <Translate ns="person">{(t) => <Helmet title={t('lister.title')} />}</Translate>,
        },
      },
      {
        path: 'new',
        handle: {
          breadcrumb: <Translate ns="person" i18nKey="action.new" />,
          helmet: <Translate ns="person">{(t) => <Helmet title={t('editor.helmetNew')} />}</Translate>,
        },
        element: <PersonEditor />,
      },
      {
        path: 'merge',
        element: <PersonMerger />,
        handle: {
          helmet: <Translate ns="person">{(t) => <Helmet title={t('merger.helmet')} />}</Translate>,
        },
      },
      {
        path: 'duplicates',
        element: <PersonDuplicatesLister />,
        handle: {
          breadcrumb: <Translate ns="person" i18nKey="duplicates_lister.title" />,
          helmet: <Translate ns="person">{(t) => <Helmet title={t('duplicates_lister.helmet')} />}</Translate>,
        },
      },
      {
        path: 'non-duplicates',
        element: <PersonNonDuplicatesLister />,
        handle: {
          breadcrumb: <Translate ns="person" i18nKey="duplicates_lister.non_duplicate" />,
          helmet: (
            <Translate ns="person">{(t) => <Helmet title={t('duplicates_lister.nonDuplicateHelmet')} />}</Translate>
          ),
        },
      },
      {
        path: ':personId',
        handle: {
          breadcrumb: <PersonBreadcrumb />,
        },
        children: [
          {
            path: '',
            element: <PersonPage />,
            children: [
              {
                path: '',
                element: <PersonViewer />,
                handle: {
                  helmet: <PersonHelmet i18nKey="viewer.helmet" />,
                },
              },
              {
                path: 'relations',
                element: <PersonRelationViewer />,
                handle: {
                  helmet: <PersonHelmet i18nKey="relations.helmet" />,
                },
              },
              {
                path: 'relations/edit',
                element: <PersonRelationEditor />,
                handle: {
                  helmet: <PersonHelmet i18nKey="relations.helmetEdit" />,
                },
              },
              {
                path: 'comments',
                element: <PersonCommentViewer />,
                handle: {
                  helmet: <PersonHelmet i18nKey="comments.helmet" />,
                },
              },
              {
                path: 'history',
                element: <PersonHistory />,
                handle: {
                  helmet: <PersonHelmet i18nKey="history.helmet" />,
                },
              },
            ],
          },
          {
            path: 'edit',
            element: <PersonEditor />,
            handle: {
              breadcrumb: <Translate ns="person" i18nKey="action.edit" />,
              helmet: <PersonHelmet i18nKey="editor.helmetEdit" />,
            },
          },
          {
            path: 'duplicate',
            element: <PersonDuplicatePage />,
            handle: {
              breadcrumb: <Translate ns="person" i18nKey="action.duplicate" />,
              helmet: <PersonHelmet i18nKey="duplicates.helmet" />,
            },
          },
          ...registry.getAll(PERSON_CHILD_ROUTES).flat(),
        ],
      },
    ],
  },
  {
    path: 'persons-to-delete',
    handle: {
      breadcrumb: <Translate ns="person" i18nKey="to-delete-lister.label" />,
    },
    children: [
      {
        path: '',
        element: <PersonToDeleteLister />,
        handle: {
          helmet: <Translate ns="person">{(t) => <Helmet title={t('to-delete-lister.label')} />}</Translate>,
        },
      },
      {},
    ],
  },
  {
    path: 'anonymisation',
    handle: {
      breadcrumb: <Translate ns="person" i18nKey="anonymisation.menu_item" />,
      helmet: <Translate ns="person">{(t) => <Helmet title={t('anonymisation.helmet')} />}</Translate>,
    },
    element: <AnonymisationPage />,
  },
];

interface PersonHelmetProps {
  i18nKey: ParseKeys<'person'>;
}

export function PersonHelmet({ i18nKey }: PersonHelmetProps) {
  const { t } = useTranslation('person');
  const params = useParams<{ personId: string }>();
  invariant(params.personId, 'Empty personId');
  const person = useFetcher(fetchPerson, { id: params.personId });

  return <Helmet title={t(i18nKey, { firstName: person.firstName, surname: person.surname })} />;
}

function PersonBreadcrumb() {
  const { personId } = useParams<{ personId: string }>();
  invariant(personId, 'Empty personId');
  const person = useFetcher(fetchPerson, { id: personId });

  return <>{`${person.firstName} ${person.surname}`}</>;
}

export default personRoutes;
