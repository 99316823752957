/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const StaffSourceDto = {
    AD_SYNCHRONIZED: 'AD_SYNCHRONIZED',
    MANUAL: 'MANUAL'
} as const;
export type StaffSourceDto = typeof StaffSourceDto[keyof typeof StaffSourceDto];


export function StaffSourceDtoFromJSON(json: any): StaffSourceDto {
    return StaffSourceDtoFromJSONTyped(json, false);
}

export function StaffSourceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffSourceDto {
    return json as StaffSourceDto;
}

export function StaffSourceDtoToJSON(value?: StaffSourceDto | null): any {
    return value as any;
}

