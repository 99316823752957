import { Stack } from '@chakra-ui/react';
import React from 'react';
import { LayoutType } from '../../common/LayoutType';
import FieldsOfInterestControl from './fields-of-interest-control';
import OccupationControl from './occupation/occupation-control';

export interface ActivitiesControlProps {
  layout: LayoutType;
}

export default function ActivitiesControl({ layout }: ActivitiesControlProps) {
  return (
    <Stack spacing={4}>
      <OccupationControl layout={layout} />
      <FieldsOfInterestControl />
    </Stack>
  );
}
