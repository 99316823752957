import { Stack, StackItem } from '@chakra-ui/react';
import React from 'react';
import AppNavigationSubMenuItem from './app-navigation-sub-menu-item';
import SubMenuItem from './sub-menu-item';

interface AppNavigationSubMenuProps {
  items: SubMenuItem[];
  isCollapsed?: boolean;
}

export default function AppNavigationSubMenu({ items, isCollapsed }: AppNavigationSubMenuProps) {
  return (
    <Stack spacing={0}>
      {items.map((item, index) => (
        <StackItem key={index}>
          <AppNavigationSubMenuItem item={item} isCollapsed={isCollapsed} />
        </StackItem>
      ))}
    </Stack>
  );
}
