import { HStack, Link, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { placementIcon } from '../../../../ui/icons/business-objects';

export default function PlacementReference({ placementLink }: { placementLink: string }) {
  const { t } = useTranslation('placement');

  return (
    <Link as={RouterLink} to={placementLink} fontWeight="medium" display="inline-flex" alignItems="baseline" ml={1}>
      <HStack alignItems="baseline">
        <FontAwesomeIcon icon={placementIcon} />
        <Text ml={2}>{t('placement')}</Text>
      </HStack>
    </Link>
  );
}
