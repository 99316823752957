import { Alert, AlertIcon, AlertTitle, chakra, HStack, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import React from 'react';
import invariant from 'tiny-invariant';
import { MergePersonParticipationConflictDto } from '../../../api';
import ParticipationReference from '../particiaption-reference/participation-reference';

export function getMergeConflictMessages(
  conflicts: MergePersonParticipationConflictDto[],
  t: any,
  index: number,
): React.ReactNode {
  const participationConflicts = conflicts.filter((conflict) => conflict.conflictType === 'PARTICIPATION');
  if (participationConflicts.length > 0) {
    return (
      <Alert key={index} status="warning" aria-labelledby="merger-conflict-warning-title">
        <AlertIcon />
        <chakra.div>
          <AlertTitle id="merger-guestlist-conflict-warning-title" mr={2}>
            {t('person:merger.conflicts.title')}
          </AlertTitle>
          <Text mt={1} mb={4}>
            {t('person:merger.conflicts.participation.intro')}
          </Text>
          <UnorderedList mt={1} listStyleType="'—'" marginInlineStart="0.8em" spacing={1}>
            {participationConflicts.map((conflict) => {
              invariant(conflict.participations);

              return conflict.participations.map((participation) => (
                <ListItem pl={2} key={participation.id}>
                  <HStack>
                    <ParticipationReference participationReference={participation} />
                  </HStack>
                </ListItem>
              ));
            })}
          </UnorderedList>
          <Text mt={4}>{t('person:merger.conflicts.participation.body')}</Text>
        </chakra.div>
      </Alert>
    );
  }
  return undefined;
}
