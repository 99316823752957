import { FindSettingsRequest, LiveTicketingSettingsDto, ResponseError } from '../../api';
import * as runtime from '../../api/runtime';
import { CustomRequestInit } from '../../data-access/error-middleware';
import liveTicketingApi from '../../data-access/live-ticketing-api';
import liveTicketingPublicationApi from '../../data-access/live-ticketing-publication-api';
import publicLiveTicketingApi from '../../data-access/public-live-ticketing-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';

// live ticketing settings
export const liveTicketing = createFetcherScope(liveTicketingApi);
export const fetchLiveTicketingSettings = liveTicketing.createFetcher(findLiveTicketingSettings);

// attendee list
export const liveTicketingAttendees = createFetcherScope(liveTicketingApi);
export const fetchAttendees = liveTicketingAttendees.createFetcher(liveTicketingApi.getAttendees);

// config part of publications
export const liveTicketingPublication = createFetcherScope(liveTicketingPublicationApi);
export const fetchLiveTicketingPublications = liveTicketingPublication.createFetcher(
  liveTicketingPublicationApi.getLiveTicketingPublications,
);

// public part of publication
export const liveTicketingPublicPublication = createFetcherScope(publicLiveTicketingApi);
export const fetchPublicEventData = liveTicketingPublicPublication.createFetcher(
  publicLiveTicketingApi.fetchPublicEventData,
);
export const fetchPublicLiveTicketingAttendeeList = liveTicketingPublicPublication.createFetcher(
  publicLiveTicketingApi.fetchLiveTicketingAttendeeList,
);

async function findLiveTicketingSettings(
  requestParameters: FindSettingsRequest,
  initOverrides?: RequestInit | runtime.InitOverrideFunction,
): Promise<LiveTicketingSettingsDto | null> {
  return liveTicketingApi
    .findSettings(requestParameters, {
      ...initOverrides,
      allowedErrorCodes: [412],
    } as CustomRequestInit)
    .catch((error) => {
      if (error instanceof ResponseError) {
        return null;
      } else {
        throw error;
      }
    });
}
