import { HStack, Link } from '@chakra-ui/react';
import React from 'react';
import { EmailAddressDto } from '../../../api';
import BooleanViewer from '../../../ui/boolean-viewer/boolean-viewer';
import { DataTableColumn } from '../../../ui/data-table';
import HelperPopover from '../../../ui/helper-buttons/helper-popover';
import Translate from '../../../util/translate/translate';

/**
 * Default columns of person's email data tables.
 */
const emailDataTableColumns: DataTableColumn<EmailAddressDto>[] = [
  {
    key: 'eMailAddress',
    name: <Translate ns="person" i18nKey="contacts.email.email_address" />,
    renderCell: (data) => <Link href={'mailto:' + data.email}>{data.email}</Link>,
    cellProps: {
      wordBreak: 'break-all',
      flexGrow: 2,
    },
  },
  {
    key: 'label',
    name: <Translate ns="person" i18nKey="contacts.email.label" />,
    renderCell: (data) => <Translate ns="person">{(t) => t(`contacts.email.labelOptions.${data.label}`)}</Translate>,
  },
  {
    key: 'publish',
    name: (
      <HStack>
        <Translate ns="person" i18nKey="contacts.email.publish" />
        <HelperPopover children={<Translate ns="person" i18nKey="contacts.email.publish_popover" />} />
      </HStack>
    ),
    renderCell: (data) => <BooleanViewer value={data.publish} />,
  },
  {
    key: 'confidential',
    name: <Translate ns="person" i18nKey="contacts.email.confidential" />,
    renderCell: (data) => <BooleanViewer value={data.confidential} />,
  },
];

export default emailDataTableColumns;
