/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Status of the code.
 * @export
 */
export const CodeStatusDto = {
    VALID: 'VALID',
    INVALID: 'INVALID',
    EXPIRED: 'EXPIRED',
    REDEEMED: 'REDEEMED'
} as const;
export type CodeStatusDto = typeof CodeStatusDto[keyof typeof CodeStatusDto];


export function CodeStatusDtoFromJSON(json: any): CodeStatusDto {
    return CodeStatusDtoFromJSONTyped(json, false);
}

export function CodeStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodeStatusDto {
    return json as CodeStatusDto;
}

export function CodeStatusDtoToJSON(value?: CodeStatusDto | null): any {
    return value as any;
}

