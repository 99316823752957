import React from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchOrder } from '../order-queries';

export default function OrderBreadcrumb() {
  const { orderId } = useParams<{ orderId: string }>();
  invariant(orderId, 'Empty orderId');
  const order = useFetcher(fetchOrder, { id: orderId });

  return <>{order.orderNumber}</>;
}
