/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The type of guest added to the guest list.
 * @export
 */
export const GuestTypeDto = {
    PERSON: 'PERSON',
    DUMMY: 'DUMMY',
    BODYGUARD: 'BODYGUARD'
} as const;
export type GuestTypeDto = typeof GuestTypeDto[keyof typeof GuestTypeDto];


export function GuestTypeDtoFromJSON(json: any): GuestTypeDto {
    return GuestTypeDtoFromJSONTyped(json, false);
}

export function GuestTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuestTypeDto {
    return json as GuestTypeDto;
}

export function GuestTypeDtoToJSON(value?: GuestTypeDto | null): any {
    return value as any;
}

