import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import useFetcher from '../../util/swr/use-fetcher';
import PersonPage from '../person/person-page/person-page';
import { fetchPerson } from '../person/person-queries';
import { PersonOrderPageContent } from './order-lister/person-order-page-content';

const personOrderRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: '',
    element: <PersonPage />,
    children: [
      {
        path: 'orders',
        element: <PersonOrderPageContent />,
        handle: {
          helmet: <PersonOrdersHelmet />,
        },
      },
    ],
  },
];

export default personOrderRoutes;

function PersonOrdersHelmet() {
  const { t } = useTranslation('order');
  const params = useParams<{ personId: string }>();
  invariant(params.personId, 'Empty personId');
  const person = useFetcher(fetchPerson, { id: params.personId });

  return <Helmet title={t('connections.helmet', { firstName: person.firstName, surname: person.surname })} />;
}
