import React from 'react';
import { PersonDto } from '../../../api';
import { AccordionFormItem } from '../../../ui/form/use-accordion-form/use-accordion-form';
import Translate from '../../../util/translate/translate';
import { LayoutType } from '../../common/LayoutType';
import ActivitiesControl from './activities-control';
import AdditionalInformationControl from './additional-information-control';
import AdditionalPersonalDataControl from './additional-personal-data-control';
import AlternativeNameControl from './alternative-name-control';
import ContactControl from './contact-control';
import MarkersGroupControl from './markers-control';
import RelationControl from './person-relation-control';
import SalutationControl from './salutation-control';

export interface PersonFormAccordionItemsProps {
  layout: LayoutType;
  showInitialComment: boolean;
  showPersonRelations?: boolean;
  disableStatus: boolean;
}

export default function usePersonFormAccordionItems({
  layout,
  showInitialComment,
  showPersonRelations = true,
  disableStatus,
}: PersonFormAccordionItemsProps) {
  const accordionItems: AccordionFormItem<PersonDto>[] = [
    {
      name: 'salutation',
      button: <Translate ns="person">{(t) => t('salutationGroupLabel')}</Translate>,
      panel: <SalutationControl layout={layout} />,
      controls: ['salutationIndividual', 'salutation', 'title', 'postNominal'],
    },
    {
      name: 'additional_personal_data',
      button: <Translate ns="person">{(t) => t('additional_personal_data')}</Translate>,
      panel: <AdditionalPersonalDataControl layout={layout} />,
      controls: [
        'genderIdentityText',
        'genderIdentity',
        'officiallyRegisteredGender',
        'placeOfBirth',
        'dateOfBirth',
        'nationalities',
      ],
    },
    {
      name: 'alternative_name',
      button: <Translate ns="person">{(t) => t('alternativeNameGroupLabel')}</Translate>,
      panel: <AlternativeNameControl layout={layout} />,
      controls: ['stageName', 'publishStageName', 'alternativeNames'],
    },
    {
      name: 'contact',
      button: <Translate ns="person">{(t) => t('contacts.title')}</Translate>,
      panel: <ContactControl layout={layout} />,
      controls: ['contactLanguage', 'emailAddresses', 'phoneNumbers', 'postalAddresses'],
    },
    {
      name: 'activities',
      button: <Translate ns="person">{(t) => t('activitiesGroupLabel')}</Translate>,
      panel: <ActivitiesControl layout={layout} />,
      controls: ['occupations', 'fieldsOfInterest'],
    },
    {
      name: 'markers',
      button: <Translate ns="person">{(t) => t('markersGroupLabel')}</Translate>,
      panel: <MarkersGroupControl layout={layout} />,
      controls: ['markers', 'confidentiality', 'historicMarkers'],
    },
    {
      name: 'additional_information',
      button: <Translate ns="person">{(t) => t('additional_information')}</Translate>,
      panel: (
        <AdditionalInformationControl
          layout={layout}
          showInitialComment={showInitialComment}
          disableStatus={disableStatus}
        />
      ),
      controls: ['photoMetadata', 'status'],
    },
  ];

  if (showPersonRelations) {
    accordionItems.push({
      name: 'connections',
      button: <Translate ns="person">{(t) => t('connections.label')}</Translate>,
      panel: <RelationControl layout={layout} />,
    });
  }

  return accordionItems;
}
