import React from 'react';
import registry from '../../registry';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import StandBreadcrumb from './common/stand-breadcrumb';
import STAND_CHILD_ROUTES from './stand-child-routes';

const standRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'stands',
    handle: {
      breadcrumb: <Translate ns="stand" i18nKey="stand" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./stand-lister/stand-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./stand-editor/new-stand-route'),
      },
      {
        path: ':standId',
        handle: {
          breadcrumb: <StandBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./stand-page/stand-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./stand-viewer/stand-viewer-route'),
              },
              {
                path: 'persons',
                lazy: () => import('./stand-persons/stand-persons-route'),
              },
              {
                path: 'companies',
                lazy: () => import('./stand-companies/stand-companies-route'),
              },
              {
                path: 'history',
                lazy: () => import('./stand-history/stand-history-route'),
              },
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./stand-editor/edit-stand-route'),
          },
          ...registry.getAll(STAND_CHILD_ROUTES).flat(),
        ],
      },
    ],
  },
];

export default standRoutes;
