import { forwardRef, useMergeRefs, Link, LinkProps } from '@chakra-ui/react';
import React from 'react';
import useDefinedContext from '../../util/context/use-defined-context/use-defined-context';

export const DataTableClickRefContext = React.createContext<React.RefObject<any> | undefined>(undefined);

interface DataTableLinkProps extends LinkProps {}

const DataTableLink = forwardRef<DataTableLinkProps, 'a'>((props, ref) => {
  const dataTableClickRef = useDefinedContext(DataTableClickRefContext);
  const mergedRef = useMergeRefs(ref, dataTableClickRef);

  return <Link {...props} ref={mergedRef} />;
});

export default DataTableLink;
