/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClosedDateRangeDto
 */
export interface ClosedDateRangeDto {
    /**
     * 
     * @type {Date}
     * @memberof ClosedDateRangeDto
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClosedDateRangeDto
     */
    end: Date;
}

/**
 * Check if a given object implements the ClosedDateRangeDto interface.
 */
export function instanceOfClosedDateRangeDto(value: object): boolean {
    if (!('start' in value)) return false;
    if (!('end' in value)) return false;
    return true;
}

export function ClosedDateRangeDtoFromJSON(json: any): ClosedDateRangeDto {
    return ClosedDateRangeDtoFromJSONTyped(json, false);
}

export function ClosedDateRangeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClosedDateRangeDto {
    if (json == null) {
        return json;
    }
    return {
        
        'start': (new Date(json['start'])),
        'end': (new Date(json['end'])),
    };
}

export function ClosedDateRangeDtoToJSON(value?: ClosedDateRangeDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'start': ((value['start']).toISOString().substring(0,10)),
        'end': ((value['end']).toISOString().substring(0,10)),
    };
}

