import formatApi from '../../data-access/format-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';

export const formatFetcher = createFetcherScope(formatApi);
export const fetchFormat = formatFetcher.createFetcher(formatApi.fetchFormat);
export const fetchFormats = formatFetcher.createFetcher(formatApi.searchFormats);
export const fetchFormatHistory = formatFetcher.createFetcher(formatApi.fetchFormatHistory);
export const fetchFormatDuplicates = formatFetcher.createFetcher(formatApi.getPossibleFormatDuplicates);
export const fetchExistingFormatDuplicates = formatFetcher.createFetcher(formatApi.getExistingPossibleFormatDuplicates);
export const fetchAllFormatDuplicates = formatFetcher.createFetcher(formatApi.getAllPossibleFormatDuplicates);
