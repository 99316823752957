/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderCancellationFeeDto } from './OrderCancellationFeeDto';
import {
    OrderCancellationFeeDtoFromJSON,
    OrderCancellationFeeDtoFromJSONTyped,
    OrderCancellationFeeDtoToJSON,
} from './OrderCancellationFeeDto';
import type { OrderProductBundleViewerItemDto } from './OrderProductBundleViewerItemDto';
import {
    OrderProductBundleViewerItemDtoFromJSON,
    OrderProductBundleViewerItemDtoFromJSONTyped,
    OrderProductBundleViewerItemDtoToJSON,
} from './OrderProductBundleViewerItemDto';

/**
 * 
 * @export
 * @interface OrderProductBundleDto
 */
export interface OrderProductBundleDto {
    /**
     * 
     * @type {OrderProductBundleViewerItemDto}
     * @memberof OrderProductBundleDto
     */
    productBundle: OrderProductBundleViewerItemDto;
    /**
     * 
     * @type {boolean}
     * @memberof OrderProductBundleDto
     */
    cancelled?: boolean;
    /**
     * 
     * @type {OrderCancellationFeeDto}
     * @memberof OrderProductBundleDto
     */
    cancellationFee?: OrderCancellationFeeDto;
    /**
     * 
     * @type {string}
     * @memberof OrderProductBundleDto
     */
    reason?: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderProductBundleDto
     */
    timestamp?: Date;
}

/**
 * Check if a given object implements the OrderProductBundleDto interface.
 */
export function instanceOfOrderProductBundleDto(value: object): boolean {
    if (!('productBundle' in value)) return false;
    return true;
}

export function OrderProductBundleDtoFromJSON(json: any): OrderProductBundleDto {
    return OrderProductBundleDtoFromJSONTyped(json, false);
}

export function OrderProductBundleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderProductBundleDto {
    if (json == null) {
        return json;
    }
    return {
        
        'productBundle': OrderProductBundleViewerItemDtoFromJSON(json['productBundle']),
        'cancelled': json['cancelled'] == null ? undefined : json['cancelled'],
        'cancellationFee': json['cancellationFee'] == null ? undefined : OrderCancellationFeeDtoFromJSON(json['cancellationFee']),
        'reason': json['reason'] == null ? undefined : json['reason'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
    };
}

export function OrderProductBundleDtoToJSON(value?: OrderProductBundleDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'productBundle': OrderProductBundleViewerItemDtoToJSON(value['productBundle']),
        'cancelled': value['cancelled'],
        'cancellationFee': OrderCancellationFeeDtoToJSON(value['cancellationFee']),
        'reason': value['reason'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
    };
}

