/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccountDto } from './AccountDto';
import {
    AccountDtoFromJSON,
    AccountDtoFromJSONTyped,
    AccountDtoToJSON,
} from './AccountDto';

/**
 * 
 * @export
 * @interface AccountSearchResultDto
 */
export interface AccountSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof AccountSearchResultDto
     */
    resultType: AccountSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AccountSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {AccountDto}
     * @memberof AccountSearchResultDto
     */
    result: AccountDto;
}


/**
 * @export
 */
export const AccountSearchResultDtoResultTypeEnum = {
    ACCOUNT: 'ACCOUNT'
} as const;
export type AccountSearchResultDtoResultTypeEnum = typeof AccountSearchResultDtoResultTypeEnum[keyof typeof AccountSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the AccountSearchResultDto interface.
 */
export function instanceOfAccountSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function AccountSearchResultDtoFromJSON(json: any): AccountSearchResultDto {
    return AccountSearchResultDtoFromJSONTyped(json, false);
}

export function AccountSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': AccountDtoFromJSON(json['result']),
    };
}

export function AccountSearchResultDtoToJSON(value?: AccountSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': AccountDtoToJSON(value['result']),
    };
}

