import { CompanyReferenceDto } from '../../../api';
import { companyIcon } from '../../../ui/icons/business-objects';
import Optional from '../../../ui/optional/optional';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';

export interface CompanyReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  companyReference: CompanyReferenceDto | undefined;
}

export default function CompanyReference({ companyReference, ...props }: CompanyReferenceProps) {
  return (
    <Optional>
      {companyReference?.name && (
        <Reference
          icon={companyIcon}
          to={'/companies/' + companyReference.id}
          necessaryPermission="COMPANY:CAN_SEE_DETAILS"
          {...props}
        >
          {companyReference.name}
        </Reference>
      )}
    </Optional>
  );
}
