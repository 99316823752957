import React from 'react';
import { personIcon } from '../../../ui/icons/business-objects';
import { PluginRegistry } from '../../../util/plugin/use-plugins';
import Translate from '../../../util/translate/translate';
import FORMAT_CHILD_ROUTES from '../../format/format-child-routes';
import { FORMAT_TAB_ITEM, FormatTabItem } from '../../format/format-page/format-page-route';
import { FORMAT_HISTORY_EXTENSION } from '../../format/format-relation/format-history-extension';
import { formatOccupationHistoryExtension } from './format-occupation-history/format-occupation-history';
import formatOccupationRoutes from './format-to-occupation-viewer/format-occupation-routes';

export const formatToOccupationTabItem: FormatTabItem = {
  order: 2,
  label: <Translate ns="person" i18nKey="lister.title" />,
  icon: personIcon,
  to: './persons',
};

export default function FormatOccupationPluginProvider(registry: PluginRegistry) {
  registry.register(FORMAT_TAB_ITEM, formatToOccupationTabItem);
  registry.register(FORMAT_CHILD_ROUTES, formatOccupationRoutes);
  registry.register(FORMAT_HISTORY_EXTENSION, formatOccupationHistoryExtension);
}
