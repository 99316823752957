import { Stack } from '@chakra-ui/react';
import { isEmpty, keyBy } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Empty from '../../ui/no-data/empty';
import Pagination from '../../ui/pagination/pagination';
import usePaginationState from '../../ui/pagination/use-pagination-state';
import usePlugins from '../../util/plugin/use-plugins';
import useFetcher from '../../util/swr/use-fetcher';
import FallbackSearchResult from './fallback-search-result';
import { SEARCH_RESULT_CONTENT } from './search-plugin';
import { search } from './search-queries';
import { useGlobalSearchContext } from './use-global-search-state';

export default function SearchResults() {
  const { phrase, filter, removeState } = useGlobalSearchContext();
  const [state, setState] = usePaginationState({ parameterName: { page: 'searchPage', size: 'searchSize' } });
  const page = useFetcher(
    search,
    { phrase: phrase ?? '', filter: filter ?? undefined, pageable: { page: state.page, size: state.size } },
    { active: phrase !== '' },
  );
  const resultComponents = usePlugins(SEARCH_RESULT_CONTENT);
  const componentsByType = React.useMemo(() => keyBy(resultComponents, ({ type }) => type), [resultComponents]);
  const { t } = useTranslation('app');

  if (page == null) {
    return <Empty text={t('search.noPhrase')} />;
  }

  if (isEmpty(page.content)) {
    return <Empty text={t('search.noResults')} />;
  }

  return (
    <Stack>
      <Stack spacing={1} role="listbox">
        {page.content.map((result, idx) => {
          const DomainSpecificSearchResult = componentsByType[result.resultType]?.Component;

          if (!DomainSpecificSearchResult) {
            console.error('No SearchResultPlugin for type ' + result.resultType);
            return <FallbackSearchResult key={idx} result={result} onClick={removeState} />;
          }

          return <DomainSpecificSearchResult result={result} key={idx} onClick={removeState} />;
        })}
      </Stack>
      {page.totalPages > 1 && (
        <Pagination
          page={page}
          onPrevPage={() => {
            setState({ page: (state.page ?? 0) - 1 });
          }}
          onNextPage={() => {
            setState({ page: (state.page ?? 0) + 1 });
          }}
        />
      )}
    </Stack>
  );
}
