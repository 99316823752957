import { Button, Flex, Grid, GridItem, Heading, HStack } from '@chakra-ui/react';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useId } from 'react';
import { useTranslation } from 'react-i18next';
import { To } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

/**
 * Properties for description group.
 */
export interface DescriptionsProps {
  children?: React.ReactNode;
  columnCount?: number;
  label?: string;
  linkTo?: To | false;
  helperPopover?: React.ReactElement;
}

/**
 * Groups description items with additional caption with caption in viewers and editors.
 */
export default function DescriptionGroup({
  children,
  columnCount = 2,
  label,
  linkTo,
  helperPopover,
}: DescriptionsProps) {
  const { t } = useTranslation('common');
  const id = useId();

  return (
    <Grid
      templateColumns={`repeat(${columnCount}, 1fr 1fr)`}
      sx={{
        '--gap': '1px',
        gap: 'var(--gap)',
        bgColor: 'layer.01',
        border: '1px solid',
        borderColor: 'border.01',
        borderRadius: 'base',
        overflow: 'hidden',
      }}
      aria-labelledby={id}
    >
      {label != null && (
        <GridItem
          as={Flex}
          sx={{
            px: 4,
            py: linkTo ? 1 : 2,
            pr: linkTo ? 1 : 4,
            backgroundColor: 'layer.02',
            alignItems: 'center',
            height: 10,
            justifyContent: 'space-between',
          }}
          colSpan={columnCount * 2}
        >
          <HStack spacing={0} alignItems="center">
            <Heading as="h3" size="sm" sx={{ mr: 0, fontWeight: 'semibold' }} id={id}>
              {label}
            </Heading>
            {helperPopover}
          </HStack>
          {linkTo && (
            <Button
              leftIcon={<FontAwesomeIcon icon={faPencil} />}
              colorScheme="teal"
              variant="ghost"
              as={RouterLink}
              to={linkTo}
              size="sm"
            >
              {t('action.edit')}
            </Button>
          )}
        </GridItem>
      )}
      {children}
    </Grid>
  );
}
