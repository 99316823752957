/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FileMetadataDto } from './FileMetadataDto';
import {
    FileMetadataDtoFromJSON,
    FileMetadataDtoFromJSONTyped,
    FileMetadataDtoToJSON,
} from './FileMetadataDto';

/**
 * 
 * @export
 * @interface MailingPictureModuleDataDto
 */
export interface MailingPictureModuleDataDto {
    /**
     * 
     * @type {FileMetadataDto}
     * @memberof MailingPictureModuleDataDto
     */
    image: FileMetadataDto;
    /**
     * 
     * @type {string}
     * @memberof MailingPictureModuleDataDto
     */
    caption?: string;
    /**
     * A URL restricted through uri format of open api
     * @type {string}
     * @memberof MailingPictureModuleDataDto
     */
    linkTarget?: string;
}

/**
 * Check if a given object implements the MailingPictureModuleDataDto interface.
 */
export function instanceOfMailingPictureModuleDataDto(value: object): boolean {
    if (!('image' in value)) return false;
    return true;
}

export function MailingPictureModuleDataDtoFromJSON(json: any): MailingPictureModuleDataDto {
    return MailingPictureModuleDataDtoFromJSONTyped(json, false);
}

export function MailingPictureModuleDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingPictureModuleDataDto {
    if (json == null) {
        return json;
    }
    return {
        
        'image': FileMetadataDtoFromJSON(json['image']),
        'caption': json['caption'] == null ? undefined : json['caption'],
        'linkTarget': json['linkTarget'] == null ? undefined : json['linkTarget'],
    };
}

export function MailingPictureModuleDataDtoToJSON(value?: MailingPictureModuleDataDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'image': FileMetadataDtoToJSON(value['image']),
        'caption': value['caption'],
        'linkTarget': value['linkTarget'],
    };
}

