/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * Comment of a person
 * @export
 * @interface PersonCommentDto
 */
export interface PersonCommentDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof PersonCommentDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof PersonCommentDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof PersonCommentDto
     */
    text: string;
    /**
     * The purpose of the comment
     * @type {string}
     * @memberof PersonCommentDto
     */
    purpose: PersonCommentDtoPurposeEnum;
}


/**
 * @export
 */
export const PersonCommentDtoPurposeEnum = {
    GLOBAL: 'GLOBAL',
    GUEST_MANAGEMENT: 'GUEST_MANAGEMENT',
    EVENT_PROTOCOL: 'EVENT_PROTOCOL',
    INTENDANZ: 'INTENDANZ'
} as const;
export type PersonCommentDtoPurposeEnum = typeof PersonCommentDtoPurposeEnum[keyof typeof PersonCommentDtoPurposeEnum];


/**
 * Check if a given object implements the PersonCommentDto interface.
 */
export function instanceOfPersonCommentDto(value: object): boolean {
    if (!('text' in value)) return false;
    if (!('purpose' in value)) return false;
    return true;
}

export function PersonCommentDtoFromJSON(json: any): PersonCommentDto {
    return PersonCommentDtoFromJSONTyped(json, false);
}

export function PersonCommentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonCommentDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'text': json['text'],
        'purpose': json['purpose'],
    };
}

export function PersonCommentDtoToJSON(value?: PersonCommentDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'text': value['text'],
        'purpose': value['purpose'],
    };
}

