/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyPublicationNameDto
 */
export interface CompanyPublicationNameDto {
    /**
     * The publication name
     * @type {string}
     * @memberof CompanyPublicationNameDto
     */
    name: string;
    /**
     * The label publication name
     * @type {string}
     * @memberof CompanyPublicationNameDto
     */
    label: string;
    /**
     * 
     * @type {Date}
     * @memberof CompanyPublicationNameDto
     */
    timestamp: Date;
}

/**
 * Check if a given object implements the CompanyPublicationNameDto interface.
 */
export function instanceOfCompanyPublicationNameDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('label' in value)) return false;
    if (!('timestamp' in value)) return false;
    return true;
}

export function CompanyPublicationNameDtoFromJSON(json: any): CompanyPublicationNameDto {
    return CompanyPublicationNameDtoFromJSONTyped(json, false);
}

export function CompanyPublicationNameDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyPublicationNameDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'label': json['label'],
        'timestamp': (new Date(json['timestamp'])),
    };
}

export function CompanyPublicationNameDtoToJSON(value?: CompanyPublicationNameDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'label': value['label'],
        'timestamp': ((value['timestamp']).toISOString()),
    };
}

