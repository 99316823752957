import { Button } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { SectionStaffListItemDto } from '../../../api';
import { DataTableState } from '../../../ui/data-table';
import { UnassignStaffsToRoleButton } from './unassign-staffs-role-button';

interface AssignStaffsRoleSelectionButtonProps {
  staffs: SectionStaffListItemDto[];
  patchState: (patchedState: Partial<DataTableState>) => void;
}

export default function AssignStaffsRoleSelectionButton({ staffs, patchState }: AssignStaffsRoleSelectionButtonProps) {
  const { t } = useTranslation(['permission_role']);

  return (
    <UnassignStaffsToRoleButton
      as={Button}
      variant="outline"
      size="sm"
      disableReason={t('permission_role:assignStaffRole.unassignDisabledReason', { count: staffs.length })}
      isDisabled={isEmpty(staffs) || staffs.some((staff) => staff.role == null || staff.role.isAdminRole)}
      staffs={staffs.map((staff) => staff.id)}
      onSuccess={() => {
        patchState({ selection: [] });
      }}
      aria-label={t('assignStaffRole.unassignRoles')}
    >
      {t('permission_role:assignStaffRole.unassignRoles')}
    </UnassignStaffsToRoleButton>
  );
}
