import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyReferenceDto, FormatCompanyRelationDto, FormatCompanyStatusDto, FormatDto } from '../../../../api';
import formatApi from '../../../../data-access/format-api';
import formatCompanyApi from '../../../../data-access/format-company-api';
import ValueAsyncSelectFormControl from '../../../../ui/form/select-control/value-async-select-form-control';
import ValueSelectFormControl from '../../../../ui/form/select-control/value-select-form-control';
import { companyIcon, formatIcon } from '../../../../ui/icons/business-objects';
import ConnectionLine from '../../common/connection-line';
import { FormatCompanyValidityControl } from '../format-company-editor/connection-control';
import { CONNECTION_TYPE_OPTIONS, STATUS_OPTIONS } from '../format-company-enum-constants';

interface ConnectionControlProps {
  companyReference: CompanyReferenceDto;
  isEdit?: boolean;
  initialFocusRef?: RefObject<HTMLInputElement>;
  relationId?: string;
  currentRelations?: FormatCompanyRelationDto[];
}

export default function ConnectionControl({
  companyReference,
  isEdit = false,
  initialFocusRef,
  relationId,
  currentRelations = [],
}: ConnectionControlProps) {
  const { t } = useTranslation(['format', 'company', 'common']);

  const validateNoLocalDuplicate = (format: FormatDto) => {
    return (
      !currentRelations.some((relation) => relation.id != relationId && relation.format.id == format.id) ||
      t('format:companyConnections.notUnique')
    );
  };

  const validateNoRemoteDuplicate = async (format: FormatDto) => {
    if (format != null) {
      const isUnique = await formatCompanyApi.isFormatCompanyUnique({
        formatId: format.id,
        companyId: companyReference.id,
        relationId,
      });
      if (!isUnique.value) {
        return t('format:companyConnections.notUnique');
      }
    }
    return true;
  };

  return (
    <>
      <Stack spacing={2} backgroundColor="background.highlight" pr={2} py={2} pl={3} borderRadius="base">
        <Flex position="relative" align="center">
          <Box as={FontAwesomeIcon} icon={companyIcon} fixedWidth flexShrink={0} />
          <Box ml={3} aria-label={t('company:formatConnections.fromCompany')}>
            <Text as="span" fontWeight="medium">
              {companyReference.name}
            </Text>
          </Box>
        </Flex>
        <Flex align="center" position="relative">
          <ConnectionLine position="top" showArrow spacing={2} />
          <Box as={FontAwesomeIcon} icon={formatIcon} fixedWidth flexShrink={0} />
          <Box width="full" ml={3}>
            <ValueAsyncSelectFormControl
              isRequired
              name="format"
              ref={initialFocusRef}
              label={t('format:format')}
              loadOptions={async (q) => {
                const formats = await formatApi.searchFormats({
                  filter: ['status,eq,ACTIVE', `name,contain,${q}`],
                  pageable: { sort: ['name,ASC'] },
                });
                return formats.content;
              }}
              renderLabel={(format) => format.name}
              aria-label={t('company:formatConnections.toFormat')}
              optionIdentifier={(option) => option.id!}
              rules={{
                validate: { validateNoLocalDuplicate, validateNoRemoteDuplicate },
              }}
            />
          </Box>
        </Flex>
      </Stack>

      <Stack
        spacing={4}
        sx={{
          marginTop: 4,
          borderWidth: 1,
          borderRadius: 4,
          padding: 4,
          borderColor: 'border.01',
        }}
      >
        <FormatCompanyValidityControl />
        <ValueSelectFormControl
          name="connectionType"
          label={t('format:formatCompany.connectionType')}
          isRequired
          options={CONNECTION_TYPE_OPTIONS}
          renderLabel={(value) => t(`format:formatCompany.connectionTypeOptions.${value}`)}
        />
        <ValueSelectFormControl
          name="status"
          label={t('format:formatCompany.status')}
          isRequired
          options={STATUS_OPTIONS}
          renderLabel={(value) => t(`format:formatCompany.statusOptions.${value}`)}
          defaultValue={FormatCompanyStatusDto.ACTIVE}
          isDisabled={!isEdit}
        />
      </Stack>
    </>
  );
}
