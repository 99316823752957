import { IconButton, Tooltip } from '@chakra-ui/react';
import { faArrowToBottom } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OccupationDto } from '../../../../api';
import ElementContext from '../../../../ui/form/element-control/element-context';
import useDefinedContext from '../../../../util/context/use-defined-context/use-defined-context';

interface RemoveMainOccupationButtonProps {
  removeMainActivity: (occupation: OccupationDto) => void;
}

export default function RemoveMainOccupationButton({ removeMainActivity }: RemoveMainOccupationButtonProps) {
  const { t } = useTranslation('person');
  const { element: occupation } = useDefinedContext(ElementContext);

  if (!occupation.mainActivity) {
    return undefined;
  }

  return (
    <Tooltip label={t('occupations.mainActivity.remove')}>
      <IconButton
        onClick={() => removeMainActivity(occupation)}
        size="sm"
        variant="ghost"
        icon={<FontAwesomeIcon icon={faArrowToBottom} />}
        aria-label={t('occupations.mainActivity.remove')}
      />
    </Tooltip>
  );
}
