/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateRangeDto } from './ClosedDateRangeDto';
import {
    ClosedDateRangeDtoFromJSON,
    ClosedDateRangeDtoFromJSONTyped,
    ClosedDateRangeDtoToJSON,
} from './ClosedDateRangeDto';

/**
 * 
 * @export
 * @interface CancellationDateRangeDto
 */
export interface CancellationDateRangeDto {
    /**
     * 
     * @type {ClosedDateRangeDto}
     * @memberof CancellationDateRangeDto
     */
    dateRange: ClosedDateRangeDto;
    /**
     * 
     * @type {number}
     * @memberof CancellationDateRangeDto
     */
    cancellationRate: number;
    /**
     * 
     * @type {Date}
     * @memberof CancellationDateRangeDto
     */
    timestamp: Date;
}

/**
 * Check if a given object implements the CancellationDateRangeDto interface.
 */
export function instanceOfCancellationDateRangeDto(value: object): boolean {
    if (!('dateRange' in value)) return false;
    if (!('cancellationRate' in value)) return false;
    if (!('timestamp' in value)) return false;
    return true;
}

export function CancellationDateRangeDtoFromJSON(json: any): CancellationDateRangeDto {
    return CancellationDateRangeDtoFromJSONTyped(json, false);
}

export function CancellationDateRangeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancellationDateRangeDto {
    if (json == null) {
        return json;
    }
    return {
        
        'dateRange': ClosedDateRangeDtoFromJSON(json['dateRange']),
        'cancellationRate': json['cancellationRate'],
        'timestamp': (new Date(json['timestamp'])),
    };
}

export function CancellationDateRangeDtoToJSON(value?: CancellationDateRangeDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dateRange': ClosedDateRangeDtoToJSON(value['dateRange']),
        'cancellationRate': value['cancellationRate'],
        'timestamp': ((value['timestamp']).toISOString()),
    };
}

