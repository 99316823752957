import React from 'react';
import { StandReferenceDto } from '../../../api';
import { standIcon } from '../../../ui/icons/business-objects';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';

interface StandReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  stand: Omit<StandReferenceDto, 'standId'>;
}

export default function StandReference({ stand: { id, name }, ...props }: StandReferenceProps) {
  return (
    <Reference icon={standIcon} to={`/stands/${id}`} {...props} necessaryPermission="STAND:CAN_SEE_DETAILS">
      {name}
    </Reference>
  );
}
