import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { GenderIdentityDto } from '../../api';
import { companyIcon, locationIcon, personIcon } from '../../ui/icons/business-objects';
import renderPhoneNumber from '../../ui/phone-number/render-phone-number';
import CompanyReference from '../company/company-reference/company-reference';
import LocationReference from '../location/location-reference/location-reference';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';
import SearchResultConnection from '../search/search-result-connection';
import PersonReference from './person-reference/person-reference';

export function PersonSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'PERSON', `${result.resultType} is no instance of PersonDto`);
  const person = result.result;
  const { t } = useTranslation(['person', 'common']);

  const getGenderIdentity = () => {
    switch (person.genderIdentity) {
      case GenderIdentityDto.NOT_SPECIFIED:
        return null;
      case GenderIdentityDto.FREE_HAND_TEXT:
        return person.genderIdentityText;
      default:
        return t(`person:genderIdentityLabels.${person.genderIdentity}`);
    }
  };

  return (
    <SearchResult
      icon={personIcon}
      title={t('person:personFormat', { firstName: person.firstName, surname: person.surname })}
      link={`/persons/${person.id}`}
      score={result.score}
      necessaryPermission="PERSON:CAN_SEE_DETAILS"
      onClick={onClick}
    >
      <SearchResultAttributes
        attributes={[
          { label: t('person:personKey'), element: person.personKey },
          { label: t('person:salutation_individual'), element: person.salutationIndividual },
          {
            label: t('person:title'),
            element: person.title ? t(`person:titleLabels.${person.title}`) : null,
          },
          {
            label: t('person:postNominal'),
            element: person.postNominal ? t(`person:postNominalLabels.${person.postNominal}`) : null,
          },
          {
            label: t('person:genderIdentity'),
            element: getGenderIdentity(),
          },
          {
            label: t('person:officiallyRegisteredGender'),
            element: person.officiallyRegisteredGender
              ? t(`person:officiallyRegisteredGenderLabels.${person.officiallyRegisteredGender}`)
              : null,
          },
          {
            label: t('person:occupations.title'),
            element: person.occupations
              ?.filter((o) => o.connectedCompany == null)
              .map((o) =>
                o.mainActivity ? o.jobTitle + ' (' + t('person:occupations.mainActivity.tooltip') + ')' : o.jobTitle,
              )
              .join(', '),
          },
          {
            label: t('person:date_of_birth'),
            element: person.dateOfBirth ? t('common:format.date', { date: person.dateOfBirth }) : null,
          },
          {
            label: t('person:place_of_birth'),
            element: person.placeOfBirth,
          },
          {
            label: t('person:nationalities'),
            element: (person.nationalities ?? [])
              .map((country) => country.name!)
              .sort(localeCompare)
              .join(', '),
          },
          {
            label: t('person:stage_name'),
            element: person.stageName,
          },
          {
            label: t('person:alternative_names'),
            element: (person.alternativeNames?.sort(localeCompare) ?? []).join(', '),
          },
          {
            label: t('person:contacts.email.header'),
            element: (person.emailAddresses?.map((e) => e.email) ?? []).join(', '),
          },
          {
            label: t('person:status'),
            element: t(`person:statusLabel.${person.status}`),
          },
          {
            label: t('person:contacts.phone_number.header'),
            element: (person.phoneNumbers?.map(renderPhoneNumber) ?? []).join(', '),
          },
        ]}
      />
      {person.personLocationRelations?.map((rel) => (
        <SearchResultConnection
          onTargetClick={onClick}
          key={rel.timestamp?.toISOString()}
          connection={
            <SearchResultAttributes
              attributes={[
                { element: t(`person:relations.relationTypeLabels.${rel.relationType}`) },
                {
                  label: t('person:relations.comment'),
                  element: rel.comment,
                },
              ]}
            />
          }
          targetIcon={locationIcon}
        >
          <LocationReference size="sm" locationReference={rel.connectedLocation} hideIcon />
        </SearchResultConnection>
      ))}
      {person.personPersonConnections?.map((rel) => (
        <SearchResultConnection
          key={rel.timestamp?.toISOString()}
          onTargetClick={onClick}
          connection={t(`person:connections.connectionTypeLabels.${rel.connectionType}`)}
          targetIcon={personIcon}
        >
          <PersonReference size="sm" personReference={rel.connectedPerson} hideIcon />
        </SearchResultConnection>
      ))}
      {person.occupations
        ?.filter((o) => o.connectedCompany != null)
        .map((rel) => (
          <SearchResultConnection
            key={rel.id}
            onTargetClick={onClick}
            connection={
              <SearchResultAttributes
                attributes={[
                  {
                    element: rel.mainActivity
                      ? rel.jobTitle + ' (' + t('person:occupations.mainActivity.tooltip') + ')'
                      : rel.jobTitle,
                  },
                ]}
              />
            }
            targetIcon={companyIcon}
          >
            <CompanyReference size="sm" companyReference={rel.connectedCompany} hideIcon />
          </SearchResultConnection>
        ))}
    </SearchResult>
  );
}

const localeCompare = (a: string, b: string) => a.localeCompare(b);
