import { Button, chakra, forwardRef, HTMLChakraProps } from '@chakra-ui/react';
import React, { ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useDialog from '../../util/use-dialog/use-dialog';
import Dialog from '../dialog/dialog';

export interface DisableableButtonProps extends HTMLChakraProps<'button'> {
  disableReason: ReactNode;
  isDisabled?: boolean;
}

export const DisableableButton = forwardRef<DisableableButtonProps, 'button'>(
  ({ disableReason, isDisabled, children, onClick, disabled, ...props }, ref) => {
    const { t } = useTranslation('common');
    const cancelRef = useRef<HTMLButtonElement>(null);
    const [isDialogOpen, onDialogClose, openDialog] = useDialog<void>();
    isDisabled = isDisabled ?? disabled;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (isDisabled) {
        event.preventDefault();

        openDialog();
      } else {
        onClick?.(event);
      }
    };

    return (
      <>
        <chakra.button
          onClick={handleClick}
          ref={ref}
          data-disabled={isDisabled ? true : undefined}
          aria-disabled={isDisabled}
          {...props}
        >
          {children}
        </chakra.button>
        <Dialog
          leastDestructiveRef={cancelRef}
          title={t('action.not_possible')}
          status="error"
          isOpen={isDialogOpen}
          onClose={onDialogClose}
          footer={
            <Button variant="primary" ref={cancelRef} onClick={() => onDialogClose()}>
              {t('action.abort')}
            </Button>
          }
        >
          {disableReason}
        </Dialog>
      </>
    );
  },
);
