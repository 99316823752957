import { EditionDto } from '../../api';
import useEdition from '../../feature/edition/use-edition/use-edition';
import useStateFromSearchParams from '../use-state-from-search-params/use-state-from-search-params';

export default function useEditionFromSearchParams(defaultEdition: EditionDto) {
  const [editionId, setEditionId] = useStateFromSearchParams<string | null>({
    name: 'edition',
    defaultValue: null,
  });

  const edition = useEdition(editionId ?? undefined, { suspense: false }) ?? defaultEdition;
  const setEdition = ({ id }: EditionDto) => setEditionId(id);

  return { edition, setEdition };
}
