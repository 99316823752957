/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocationLinkDto
 */
export interface LocationLinkDto {
    /**
     * 
     * @type {string}
     * @memberof LocationLinkDto
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof LocationLinkDto
     */
    type: LocationLinkDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LocationLinkDto
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof LocationLinkDto
     */
    timestamp?: Date;
}


/**
 * @export
 */
export const LocationLinkDtoTypeEnum = {
    ROOM_RESERVATION: 'ROOM_RESERVATION',
    WEBSITE: 'WEBSITE',
    WIKI: 'WIKI',
    OTHER: 'OTHER'
} as const;
export type LocationLinkDtoTypeEnum = typeof LocationLinkDtoTypeEnum[keyof typeof LocationLinkDtoTypeEnum];


/**
 * Check if a given object implements the LocationLinkDto interface.
 */
export function instanceOfLocationLinkDto(value: object): boolean {
    if (!('url' in value)) return false;
    if (!('type' in value)) return false;
    return true;
}

export function LocationLinkDtoFromJSON(json: any): LocationLinkDto {
    return LocationLinkDtoFromJSONTyped(json, false);
}

export function LocationLinkDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationLinkDto {
    if (json == null) {
        return json;
    }
    return {
        
        'url': json['url'],
        'type': json['type'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
    };
}

export function LocationLinkDtoToJSON(value?: LocationLinkDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'url': value['url'],
        'type': value['type'],
        'comment': value['comment'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
    };
}

