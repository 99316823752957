/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AccessGroupStatusDto = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
} as const;
export type AccessGroupStatusDto = typeof AccessGroupStatusDto[keyof typeof AccessGroupStatusDto];


export function AccessGroupStatusDtoFromJSON(json: any): AccessGroupStatusDto {
    return AccessGroupStatusDtoFromJSONTyped(json, false);
}

export function AccessGroupStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessGroupStatusDto {
    return json as AccessGroupStatusDto;
}

export function AccessGroupStatusDtoToJSON(value?: AccessGroupStatusDto | null): any {
    return value as any;
}

