/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SectionPermissionDto
 */
export interface SectionPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof SectionPermissionDto
     */
    name: SectionPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof SectionPermissionDto
     */
    action: SectionPermissionDtoActionEnum;
}


/**
 * @export
 */
export const SectionPermissionDtoNameEnum = {
    SECTION: 'SECTION'
} as const;
export type SectionPermissionDtoNameEnum = typeof SectionPermissionDtoNameEnum[keyof typeof SectionPermissionDtoNameEnum];

/**
 * @export
 */
export const SectionPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    SEE_DETAILS_OWN: 'CAN_SEE_DETAILS_OWN',
    EDIT: 'CAN_EDIT',
    EDIT_OWN: 'CAN_EDIT_OWN',
    CREATE: 'CAN_CREATE',
    DEACTIVATE: 'CAN_DEACTIVATE',
    REACTIVATE: 'CAN_REACTIVATE',
    EDIT_BREVO_API_KEY: 'CAN_EDIT_BREVO_API_KEY'
} as const;
export type SectionPermissionDtoActionEnum = typeof SectionPermissionDtoActionEnum[keyof typeof SectionPermissionDtoActionEnum];


/**
 * Check if a given object implements the SectionPermissionDto interface.
 */
export function instanceOfSectionPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function SectionPermissionDtoFromJSON(json: any): SectionPermissionDto {
    return SectionPermissionDtoFromJSONTyped(json, false);
}

export function SectionPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function SectionPermissionDtoToJSON(value?: SectionPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

