import React from 'react';
import { rundownIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import EVENT_CHILD_ROUTES from '../event/event-child-routes';
import EVENT_CHILD_ROUTES_MOBILE from '../event/event-child-routes-mobile';
import { EVENT_MENU_ITEM, EVENT_MOBILE_MENU_ITEM, EventMenuItem } from '../event/event-page/event-page-menu';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import RundownLaneElementSearchResult from './rundown-lane-element-search-result';
import RundownLaneSearchResult from './rundown-lane-search-result';
import rundownRoutes, { rundownRoutesMobile } from './rundown-routes';

function createRundownEventMenuItem(isMobile = false): EventMenuItem {
  return {
    order: 5,
    label: <Translate ns="rundown" i18nKey="rundown" />,
    icon: rundownIcon,
    path: (eventId) => `${isMobile ? '/mobile' : ''}/events/${eventId}/rundown`,
  };
}

export default function RundownProvider(registry: PluginRegistry) {
  registry.register(EVENT_MENU_ITEM, createRundownEventMenuItem());
  registry.register(EVENT_MOBILE_MENU_ITEM, createRundownEventMenuItem(true));
  registry.register(EVENT_CHILD_ROUTES, rundownRoutes);
  registry.register(EVENT_CHILD_ROUTES_MOBILE, rundownRoutesMobile);
  registry.register(SEARCH_RESULT_CONTENT, { type: 'RUNDOWN_LANE_ELEMENT', Component: RundownLaneElementSearchResult });
  registry.register(SEARCH_RESULT_CONTENT, { type: 'RUNDOWN_LANE', Component: RundownLaneSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'RUNDOWN_LANE_ELEMENT',
    icon: rundownIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.RUNDOWN_LANE_ELEMENT" />,
  });
  registry.register(SEARCH_FILTER, {
    key: 'RUNDOWN_LANE',
    icon: rundownIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.RUNDOWN_LANE" />,
  });
}
