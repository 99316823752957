/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  HistoryPageDto,
  ListDto,
  ListPageDto,
  ListReferenceDto,
  PageableDto,
  QueryResultPageDto,
  TemplateListRequestDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    ListDtoFromJSON,
    ListDtoToJSON,
    ListPageDtoFromJSON,
    ListPageDtoToJSON,
    ListReferenceDtoFromJSON,
    ListReferenceDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    QueryResultPageDtoFromJSON,
    QueryResultPageDtoToJSON,
    TemplateListRequestDtoFromJSON,
    TemplateListRequestDtoToJSON,
} from '../models/index';

export interface CreateListRequest {
    listDto: ListDto;
}

export interface ExistsListNameRequest {
    name: string;
    edition: string;
    id: string;
}

export interface FetchListRequest {
    id: string;
}

export interface FetchListEntriesHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchListHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface QueryListEntriesRequest {
    id: string;
    pageable?: PageableDto;
}

export interface RefreshListRequest {
    id: string;
}

export interface SearchListListItemsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface TemplateListRequest {
    templateListRequestDto: TemplateListRequestDto;
}

export interface UpdateListRequest {
    id: string;
    listDto: ListDto;
}

/**
 * 
 */
export class ListApi extends runtime.BaseAPI {

    /**
     */
    async createListRaw(requestParameters: CreateListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['listDto'] == null) {
            throw new runtime.RequiredError(
                'listDto',
                'Required parameter "listDto" was null or undefined when calling createList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lists`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListDtoToJSON(requestParameters['listDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createList(requestParameters: CreateListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createListRaw(requestParameters, initOverrides);
    }

    /**
     */
    async existsListNameRaw(requestParameters: ExistsListNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListReferenceDto>> {
        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling existsListName().'
            );
        }

        if (requestParameters['edition'] == null) {
            throw new runtime.RequiredError(
                'edition',
                'Required parameter "edition" was null or undefined when calling existsListName().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling existsListName().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['edition'] != null) {
            queryParameters['edition'] = requestParameters['edition'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lists/exists-name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListReferenceDtoFromJSON(jsonValue));
    }

    /**
     */
    async existsListName(requestParameters: ExistsListNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListReferenceDto | null | undefined > {
        const response = await this.existsListNameRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     */
    async fetchListRaw(requestParameters: FetchListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lists/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchList(requestParameters: FetchListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListDto> {
        const response = await this.fetchListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fetchListEntriesHistoryRaw(requestParameters: FetchListEntriesHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchListEntriesHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lists/{id}/entries-versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchListEntriesHistory(requestParameters: FetchListEntriesHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchListEntriesHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fetchListHistoryRaw(requestParameters: FetchListHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchListHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lists/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchListHistory(requestParameters: FetchListHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchListHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async queryListEntriesRaw(requestParameters: QueryListEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QueryResultPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling queryListEntries().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lists/{id}/query-list-entries`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QueryResultPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async queryListEntries(requestParameters: QueryListEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QueryResultPageDto> {
        const response = await this.queryListEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async refreshListRaw(requestParameters: RefreshListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling refreshList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lists/refresh-list/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async refreshList(requestParameters: RefreshListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.refreshListRaw(requestParameters, initOverrides);
    }

    /**
     */
    async searchListListItemsRaw(requestParameters: SearchListListItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async searchListListItems(requestParameters: SearchListListItemsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListPageDto> {
        const response = await this.searchListListItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async templateListRaw(requestParameters: TemplateListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListDto>> {
        if (requestParameters['templateListRequestDto'] == null) {
            throw new runtime.RequiredError(
                'templateListRequestDto',
                'Required parameter "templateListRequestDto" was null or undefined when calling templateList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lists/template`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TemplateListRequestDtoToJSON(requestParameters['templateListRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListDtoFromJSON(jsonValue));
    }

    /**
     */
    async templateList(requestParameters: TemplateListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListDto> {
        const response = await this.templateListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateListRaw(requestParameters: UpdateListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateList().'
            );
        }

        if (requestParameters['listDto'] == null) {
            throw new runtime.RequiredError(
                'listDto',
                'Required parameter "listDto" was null or undefined when calling updateList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lists/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ListDtoToJSON(requestParameters['listDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateList(requestParameters: UpdateListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateListRaw(requestParameters, initOverrides);
    }

}
