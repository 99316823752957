import { upperFirst } from 'lodash-es';
import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import useDialog from '../../../util/use-dialog/use-dialog';
import FinalDeleteConfirmDialog, { createDefaultDeleteChecklistItem } from '../../dialog/final-delete-confirm-dialog';
import { DisableableButton, DisableableButtonProps } from '../../disableable-button/disableable-button';
import useToast from '../../use-toast/use-toast';

export interface DeleteButtonProps<BO> extends Partial<DisableableButtonProps> {
  businessObject: BO;
  objectName: string;
  objectTypeToDelete: string;
  objectTypeWithArticleNominative: string;
  objectTypeWithArticleGenitive: string;
  deleteObject: (object: BO) => Promise<void>;
  afterDelete?(): void;
}

function DeleteButton<BO>(
  {
    businessObject,
    objectName,
    deleteObject,
    objectTypeToDelete,
    objectTypeWithArticleNominative,
    objectTypeWithArticleGenitive,
    onClick,
    afterDelete,
    children,
    ...props
  }: DeleteButtonProps<BO>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const [isOpen, onClose, openDeleteDialog] = useDialog<false>();
  const { t } = useTranslation(['common']);
  const toast = useToast();
  const checkList = [
    createDefaultDeleteChecklistItem({
      objectTypeWithArticleGenitive,
      objectToDelete: objectName,
    }),
  ];

  const handleDelete: MouseEventHandler<HTMLButtonElement> = async (event) => {
    onClick?.(event);

    if (event.defaultPrevented) {
      return;
    }

    if (await openDeleteDialog()) {
      await deleteObject(businessObject);
      toast({
        status: 'success',
        title: t('action.deleteBusinessObject.defaultSuccessTitle', { objectType: objectTypeToDelete }),
        description: t('action.deleteBusinessObject.defaultSuccessDescription', {
          objectType: upperFirst(objectTypeWithArticleNominative),
          name: objectName,
        }),
      });

      afterDelete?.();
    }
  };

  return (
    <>
      <DisableableButton disableReason={t('common:action.not_possible')} {...props} ref={ref} onClick={handleDelete}>
        {children}
      </DisableableButton>

      <FinalDeleteConfirmDialog
        {...props}
        isOpen={isOpen}
        objectTypeToDelete={objectTypeToDelete}
        confirmationCheckListItems={checkList}
        onClose={onClose}
      />
    </>
  );
}

export default React.forwardRef(DeleteButton);
