import { Box, chakra, Container, Flex, Heading, HStack, Spacer, Stack } from '@chakra-ui/react';
import React from 'react';
import Breadcrumbs from '../breadcrumbs/breadcrumbs';

interface PageHeaderProps {
  title: React.ReactNode;
  actions?: React.ReactNode;
  actionHint?: React.ReactNode;
  picture?: React.ReactNode;
  tabs?: React.ReactNode;
  menu?: React.ReactNode;
  titleAddon?: React.ReactNode;
}

export default function PageHeader({ title, actions, actionHint, tabs, menu, picture, titleAddon }: PageHeaderProps) {
  return (
    <chakra.header
      sx={{
        width: '100%',
        bg: 'layer.02',
        borderBottom: '1px solid',
        borderColor: 'border.01',
        position: 'sticky',
        top: 0,
        zIndex: 'docked',
      }}
    >
      <Container pl={8} pr={8} pb={tabs != null ? 0 : undefined} maxW="full">
        <Flex alignItems="center">
          {picture != null && (
            <Box mr={4} flexShrink={0}>
              {picture}
            </Box>
          )}
          <Stack spacing={1} alignSelf="center">
            <Breadcrumbs />
            <HStack>
              <Heading as="h2" size="lg" noOfLines={3} overflowWrap="anywhere">
                {title}
              </Heading>
              {titleAddon}
            </HStack>
          </Stack>
          <Spacer />
          <Stack spacing={1} alignSelf="end">
            {actions != null && (
              <Box alignSelf="end" flexShrink={0}>
                {actions}
              </Box>
            )}
            {actionHint != null && <Box flexShrink={0}>{actionHint}</Box>}
          </Stack>
        </Flex>
        {(tabs != null || menu != null) && (
          <HStack mt={4} spacing={4} alignItems="end">
            {menu != null && <Box>{menu}</Box>}
            {tabs != null && (
              <HStack spacing={0} alignItems="stretch">
                {tabs}
              </HStack>
            )}
          </HStack>
        )}
      </Container>
    </chakra.header>
  );
}
