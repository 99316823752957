import invariant from 'tiny-invariant';

export interface BuilderItem {
  key: string;
}

export default interface Builder<T extends BuilderItem> {
  readonly items: T[];
  has(key: string): boolean;
  get(key: string): T;
  push(item: T): Builder<T>;
  unshift(item: T): Builder<T>;
  remove(key: string): Builder<T>;
  update(item: T): Builder<T>;
  merge(items: T[], pushUnknown?: boolean): Builder<T>;
  after(key: string, item: T): Builder<T>;
  before(key: string, item: T): Builder<T>;
}

export function createBuilder<T extends BuilderItem>(items: T[] = []): Builder<T> {
  const keys = new Set(items.map((item) => item.key));
  invariant(keys.size === items.length, 'Keys must be unique');

  return {
    items,
    has: (key: string) => keys.has(key),
    get: (key: string) => {
      const item = items.find((item) => item.key === key);
      invariant(item != null, `Unknown key ${key}`);

      return item;
    },
    push: (item: T) => {
      invariant(!keys.has(item.key), `Key ${item.key} already exists`);

      return createBuilder([...items, item]);
    },
    unshift: (item: T) => {
      invariant(!keys.has(item.key), `Key ${item.key} already exists`);

      return createBuilder([item, ...items]);
    },
    remove: (key: string) => {
      return createBuilder(items.filter((item) => item.key !== key));
    },
    update: (nextItem: T) => {
      invariant(keys.has(nextItem.key), `Unknown key ${nextItem.key}`);

      return createBuilder(items.map((item) => (item.key === nextItem.key ? nextItem : item)));
    },
    merge: (nextItems: T[], pushUnknown = true) => {
      return nextItems.reduce(
        (builder, nextItem) =>
          pushUnknown && !keys.has(nextItem.key) ? builder.push(nextItem) : builder.update(nextItem),
        createBuilder(items),
      );
    },
    after: (key: string, ...nextItem: T[]) => {
      nextItem.forEach((item) => {
        invariant(!keys.has(item.key), `Key ${item.key} already exists`);
      });

      const index = items.findIndex((item) => item.key === key);
      invariant(index !== -1, `Unknown key ${key}`);

      return createBuilder([...items.slice(0, index + 1), ...nextItem, ...items.slice(index + 1)]);
    },
    before: (key: string, ...nextItem: T[]) => {
      nextItem.forEach((item) => {
        invariant(!keys.has(item.key), `Key ${item.key} already exists`);
      });

      const index = items.findIndex((item) => item.key === key);
      invariant(index !== -1, `Unknown key ${key}`);

      return createBuilder([...items.slice(0, index), ...nextItem, ...items.slice(index)]);
    },
  } as const;
}
