import useSWR from 'swr';
import invariant from 'tiny-invariant';
import { KeyPlayerPageDto, KeyPlayersListSettingsDto } from '../../../api';
import keyPlayersListApi from '../../../data-access/key-players-list-api';
import publicKeyPlayerApi from '../../../data-access/public-key-player-api';
import { DataTableState } from '../../../ui/data-table';
import useRequestParams from '../../../ui/data-table/use-request-params';
import fallbackMiddleware from '../../../util/swr/fallback-middleware';
import useMatchMutate from '../../../util/use-match-mutate/use-match-mutate';

export function usePublicKeyPlayers(state: DataTableState, publicationId: string): KeyPlayerPageDto {
  const requestParams = useRequestParams(state, [
    { property: 'section', direction: 'ASC' },
    { property: 'company', direction: 'ASC' },
  ]);

  const { data } = useSWR(
    ['keyPlayersPublic', requestParams],
    () => {
      return publicKeyPlayerApi.fetchPublishedKeyPlayerList({ ...requestParams, publicationId });
    },
    {
      suspense: true,
      use: [fallbackMiddleware],
    },
  );
  invariant(data != null, 'Missing key players page');

  return data;
}

export function useKeyPlayerListMutate() {
  return useMatchMutate(/^@"keyPlayerWithEventIds"/);
}

export function useKeyPlayersWithEventIds(state: DataTableState, eventIds: string[], page?: number): KeyPlayerPageDto {
  if (page !== undefined) {
    state = { ...state, page: page };
  }

  const requestParams = useRequestParams(state, [
    { property: 'section', direction: 'ASC' },
    { property: 'company', direction: 'ASC' },
  ]);

  const { data } = useSWR(
    ['keyPlayerWithEventIds', requestParams, eventIds],
    () => {
      return keyPlayersListApi.fetchKeyPlayersList({
        ...requestParams,
        eventIds: eventIds,
      });
    },
    {
      suspense: true,
      use: [fallbackMiddleware],
    },
  );
  invariant(data != null, 'Missing key players page');

  return data;
}

export function useKeyPlayerListSettings(eventId: string): KeyPlayersListSettingsDto {
  const { data } = useSWR(
    ['keyPlayerListSettings', eventId],
    () => {
      return keyPlayersListApi.fetchKeyPlayersListSettings({ eventId });
    },
    {
      suspense: true,
      use: [fallbackMiddleware],
    },
  );
  invariant(data != null, 'Missing key players list settings');

  return data;
}
