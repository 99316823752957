/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';

/**
 * 
 * @export
 * @interface ReachDto
 */
export interface ReachDto {
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof ReachDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {number}
     * @memberof ReachDto
     */
    amount: number;
    /**
     * 
     * @type {Date}
     * @memberof ReachDto
     */
    timestamp?: Date;
}

/**
 * Check if a given object implements the ReachDto interface.
 */
export function instanceOfReachDto(value: object): boolean {
    if (!('edition' in value)) return false;
    if (!('amount' in value)) return false;
    return true;
}

export function ReachDtoFromJSON(json: any): ReachDto {
    return ReachDtoFromJSONTyped(json, false);
}

export function ReachDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReachDto {
    if (json == null) {
        return json;
    }
    return {
        
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'amount': json['amount'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
    };
}

export function ReachDtoToJSON(value?: ReachDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'amount': value['amount'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
    };
}

