import React from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';

const mailingBlocklistRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'mailing-blocklist',
    handle: {
      breadcrumb: <Translate ns="mailing_blocklist" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./mailing-blocklist-page/mailing-blocklist-page-route'),
        children: [
          {
            path: '',
            lazy: () => import('././mailing-blocklist-viewer/mailing-blocklist-viewer-route'),
          },
          {
            path: 'history',
            lazy: () => import('./mailing-blocklist-history/mailing-blocklist-history-route'),
          },
        ],
      },
    ],
  },
];
export default mailingBlocklistRoutes;
