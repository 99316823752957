const FOCUSABLE_SELECTOR = [
  'input:not([type=hidden]):not([disabled])',
  'button:not([disabled])',
  'select:not([disabled])',
  'textarea:not([disabled])',
  '[contenteditable=""]',
  '[contenteditable="true"]',
  'a[href]',
  '[tabindex]:not([disabled]):not([tabindex="-1"])',
  '.chakra-checkbox', // focusable part of a Chakra Checkbox is rendered as span, with an input as sibling
].join(', ');

export default function isFocusable(element: Element) {
  return window.getComputedStyle(element).display !== 'none' && element.closest(FOCUSABLE_SELECTOR) != null;
}
