/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GroupTemplatePermissionDto
 */
export interface GroupTemplatePermissionDto {
    /**
     * 
     * @type {string}
     * @memberof GroupTemplatePermissionDto
     */
    name: GroupTemplatePermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof GroupTemplatePermissionDto
     */
    action: GroupTemplatePermissionDtoActionEnum;
}


/**
 * @export
 */
export const GroupTemplatePermissionDtoNameEnum = {
    GROUP_TEMPLATE: 'GROUP_TEMPLATE'
} as const;
export type GroupTemplatePermissionDtoNameEnum = typeof GroupTemplatePermissionDtoNameEnum[keyof typeof GroupTemplatePermissionDtoNameEnum];

/**
 * @export
 */
export const GroupTemplatePermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    EDIT: 'CAN_EDIT',
    CREATE: 'CAN_CREATE',
    DELETE: 'CAN_DELETE'
} as const;
export type GroupTemplatePermissionDtoActionEnum = typeof GroupTemplatePermissionDtoActionEnum[keyof typeof GroupTemplatePermissionDtoActionEnum];


/**
 * Check if a given object implements the GroupTemplatePermissionDto interface.
 */
export function instanceOfGroupTemplatePermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function GroupTemplatePermissionDtoFromJSON(json: any): GroupTemplatePermissionDto {
    return GroupTemplatePermissionDtoFromJSONTyped(json, false);
}

export function GroupTemplatePermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupTemplatePermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function GroupTemplatePermissionDtoToJSON(value?: GroupTemplatePermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

