/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GroupDto } from './GroupDto';
import {
    GroupDtoFromJSON,
    GroupDtoFromJSONTyped,
    GroupDtoToJSON,
} from './GroupDto';

/**
 * 
 * @export
 * @interface GroupPageDto
 */
export interface GroupPageDto {
    /**
     * The number of the page.
     * @type {number}
     * @memberof GroupPageDto
     */
    page: number;
    /**
     * The size of the page.
     * @type {number}
     * @memberof GroupPageDto
     */
    size: number;
    /**
     * The total number of data returned by the search.
     * @type {number}
     * @memberof GroupPageDto
     */
    totalElements: number;
    /**
     * The total number of pages of current size to fit all data.
     * @type {number}
     * @memberof GroupPageDto
     */
    totalPages: number;
    /**
     * 
     * @type {Array<GroupDto>}
     * @memberof GroupPageDto
     */
    content: Array<GroupDto>;
}

/**
 * Check if a given object implements the GroupPageDto interface.
 */
export function instanceOfGroupPageDto(value: object): boolean {
    if (!('page' in value)) return false;
    if (!('size' in value)) return false;
    if (!('totalElements' in value)) return false;
    if (!('totalPages' in value)) return false;
    if (!('content' in value)) return false;
    return true;
}

export function GroupPageDtoFromJSON(json: any): GroupPageDto {
    return GroupPageDtoFromJSONTyped(json, false);
}

export function GroupPageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupPageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'page': json['page'],
        'size': json['size'],
        'totalElements': json['totalElements'],
        'totalPages': json['totalPages'],
        'content': ((json['content'] as Array<any>).map(GroupDtoFromJSON)),
    };
}

export function GroupPageDtoToJSON(value?: GroupPageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'page': value['page'],
        'size': value['size'],
        'totalElements': value['totalElements'],
        'totalPages': value['totalPages'],
        'content': ((value['content'] as Array<any>).map(GroupDtoToJSON)),
    };
}

