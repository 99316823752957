import { Grid, GridItem } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { StandPersonListItemDto } from '../../../api';
import standApi from '../../../data-access/stand-api';
import DataTable, { DataTableColumn, DataTableState, useDataTableState } from '../../../ui/data-table';
import { DataTableFilter, DataTableSort } from '../../../ui/data-table/data-table-state';
import EnumFilter from '../../../ui/data-table/filter/enum-filter';
import useRequestParams from '../../../ui/data-table/use-request-params';
import fallbackMiddleware from '../../../util/swr/fallback-middleware';
import useFetcher from '../../../util/swr/use-fetcher';
import usePermission from '../../permission/use-permission';
import DeleteRelationsButton from '../../relations/common/delete-relations-button';
import { standPersonRelationTypeOptions } from '../stand-enum-constants';
import { searchStandPersonRelationListItems, standFetcher } from '../stand-queries';

function useStandPersons(
  state: DataTableState,
  fallbackSort: DataTableSort[] | undefined,
  forcedFilter: DataTableFilter[],
) {
  const requestParams = useRequestParams(state, fallbackSort, forcedFilter);

  return useFetcher(searchStandPersonRelationListItems, requestParams, { use: [fallbackMiddleware] });
}

interface StandPersonDataTableProps {
  defaultState?: Partial<DataTableState>;
  primaryColumn: DataTableColumn<StandPersonListItemDto>;
  fallbackSort?: DataTableSort[];
  forcedFilter: DataTableFilter[];
  isDeletionAllowed?: boolean;
}

export default function StandPersonDataTable({
  defaultState,
  primaryColumn,
  fallbackSort,
  forcedFilter,
  isDeletionAllowed = false,
}: StandPersonDataTableProps) {
  const { t } = useTranslation(['common', 'stand']);
  const [state, setState] = useDataTableState(defaultState);
  const { hasPermission } = usePermission();
  const page = useStandPersons(state, fallbackSort, forcedFilter);

  const columns: DataTableColumn<StandPersonListItemDto>[] = React.useMemo(() => {
    const columns: DataTableColumn<StandPersonListItemDto>[] = [
      primaryColumn,
      {
        key: 'relationType',
        name: t('stand:personRelation.relationType'),
        isSortable: true,
        renderCell: (standPerson) => t(`stand:personRelation.relationTypeOptions.${standPerson.relationType}`),
        filter: (
          <EnumFilter
            label={t('stand:personRelation.relationType')}
            options={standPersonRelationTypeOptions}
            renderOptionLabel={(key) => t(`stand:personRelation.relationTypeOptions.${key}`)}
          />
        ),
      },
    ];

    return columns;
  }, [primaryColumn, t]);

  const rowKey = React.useCallback((standPerson: StandPersonListItemDto) => {
    invariant(standPerson.id != null, 'Missing stand id');

    return standPerson.id;
  }, []);

  return (
    <Grid gridTemplateRows="1fr auto" gridRowGap={4} minH={0}>
      <DataTable
        page={page == null ? { content: [] } : page}
        state={state}
        columns={columns}
        rowKey={rowKey}
        onStateChange={setState}
        isPageable={!!isDeletionAllowed}
        selection={isDeletionAllowed && hasPermission('PERSON-STAND:CAN_UNLINK') ? { keySelector: rowKey } : undefined}
      />
      {isDeletionAllowed && hasPermission('PERSON-STAND:CAN_UNLINK') && (
        <GridItem display="flex" flexDir="row" justifyContent="end">
          <DeleteRelationsButton
            selection={state.selection}
            isDisabled={isEmpty(state.selection)}
            deleteRelations={(ids) => standApi.deleteStandPersonRelations({ ids: ids })}
            mutate={() => standFetcher.mutate()}
            resetSelection={() => {
              setState({ ...state, selection: [] });
            }}
          />
        </GridItem>
      )}
    </Grid>
  );
}
