import { forwardRef, HTMLChakraProps, MenuItem, useDisclosure } from '@chakra-ui/react';
import { faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import AssignStaffsToStaffRoleDialog from './assign-staffs-role-dialog';
import { useParams } from 'react-router-dom';
import getSectionId from '../../section/common/get-section-id';
import usePermission from '../../permission/use-permission';

export function useHasPermissionsForAssignButton() {
  const sectionId = getSectionId(useParams());
  const { hasPermission, hasPermissionFromSection } = usePermission();

  return (
    hasPermission('STAFF_ROLE:CAN_ASSIGN') ||
    hasPermissionFromSection(sectionId, 'STAFF_ROLE:CAN_ASSIGN_MEMBERS_OWN_SECTION')
  );
}

export default function AssignStaffsToStaffRoleMenuItem({ staffId }: { staffId: string }) {
  const { t } = useTranslation('permission_role');
  const hasPermission = useHasPermissionsForAssignButton();

  return (
    hasPermission && (
      <MenuItem
        as={AssignStaffsToRoleButton}
        staffs={[staffId]}
        isDisabled={false}
        disableReason=""
        icon={<FontAwesomeIcon icon={faUserPlus} />}
        aria-label={t('assignStaffRole.assignRole')}
      >
        {t('assignStaffRole.assignRole')}
      </MenuItem>
    )
  );
}

interface AssignStaffsToStaffRoleButtonProps extends HTMLChakraProps<'button'> {
  staffs: string[];
  disableReason: string;
  onSuccess?: () => void;
}

function AssignStaffsToStaffRoleButtonWithRef(
  { children, staffs, disableReason, onClick, onSuccess, ...props }: AssignStaffsToStaffRoleButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const mergedOnClick = (x: MouseEvent<HTMLButtonElement>) => {
    onOpen();
    onClick?.(x);
  };

  return (
    <>
      <DisableableButton ref={ref} {...props} onClick={mergedOnClick} disableReason={disableReason}>
        {children}
      </DisableableButton>
      <AssignStaffsToStaffRoleDialog
        staffs={staffs}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          onSuccess?.();
        }}
      />
    </>
  );
}

export const AssignStaffsToRoleButton = forwardRef<AssignStaffsToStaffRoleButtonProps, 'button'>(
  AssignStaffsToStaffRoleButtonWithRef,
);
