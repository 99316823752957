import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DraggableItem from './draggable-item';

interface SortableItemProps {
  id: UniqueIdentifier;
  children?: React.ReactNode;
  index: number;
}

export default function SortableItem({ id, children, index }: SortableItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0 : 1,
  };

  return (
    <DraggableItem ref={setNodeRef} style={style} attributes={attributes} listeners={listeners} index={index}>
      {children}
    </DraggableItem>
  );
}
