/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StaffPermissionDto
 */
export interface StaffPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof StaffPermissionDto
     */
    name: StaffPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof StaffPermissionDto
     */
    action: StaffPermissionDtoActionEnum;
}


/**
 * @export
 */
export const StaffPermissionDtoNameEnum = {
    STAFF: 'STAFF'
} as const;
export type StaffPermissionDtoNameEnum = typeof StaffPermissionDtoNameEnum[keyof typeof StaffPermissionDtoNameEnum];

/**
 * @export
 */
export const StaffPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS_OWN: 'CAN_SEE_DETAILS_OWN',
    EDIT_OWN: 'CAN_EDIT_OWN',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    EDIT: 'CAN_EDIT',
    CREATE: 'CAN_CREATE',
    DEACTIVATE: 'CAN_DEACTIVATE',
    REACTIVATE: 'CAN_REACTIVATE'
} as const;
export type StaffPermissionDtoActionEnum = typeof StaffPermissionDtoActionEnum[keyof typeof StaffPermissionDtoActionEnum];


/**
 * Check if a given object implements the StaffPermissionDto interface.
 */
export function instanceOfStaffPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function StaffPermissionDtoFromJSON(json: any): StaffPermissionDto {
    return StaffPermissionDtoFromJSONTyped(json, false);
}

export function StaffPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function StaffPermissionDtoToJSON(value?: StaffPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

