/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PersonNameDto
 */
export interface PersonNameDto {
    /**
     * The first name of the person in latin letters
     * @type {string}
     * @memberof PersonNameDto
     */
    firstName: string;
    /**
     * The surname of the person in latin letters
     * @type {string}
     * @memberof PersonNameDto
     */
    surname: string;
}

/**
 * Check if a given object implements the PersonNameDto interface.
 */
export function instanceOfPersonNameDto(value: object): boolean {
    if (!('firstName' in value)) return false;
    if (!('surname' in value)) return false;
    return true;
}

export function PersonNameDtoFromJSON(json: any): PersonNameDto {
    return PersonNameDtoFromJSONTyped(json, false);
}

export function PersonNameDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonNameDto {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'surname': json['surname'],
    };
}

export function PersonNameDtoToJSON(value?: PersonNameDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'firstName': value['firstName'],
        'surname': value['surname'],
    };
}

