import {
  Button,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useOutsideClick,
} from '@chakra-ui/react';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, getYear, isSameDay, isSameMinute, isSameSecond, isValid, startOfDay, subMinutes } from 'date-fns';
import { de } from 'date-fns/locale/de';
import { flatten, isEqual, sortBy } from 'lodash-es';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import parseMultiple from '../../util/date/parse-multiple';
import useMemoDate from '../../util/use-memo-date/use-memo-date';
import './react-date-picker.scss';

registerLocale('de', de);

export const DATE_FORMAT_STRING = 'dd.MM.yyyy';
const PARSE_DATE_FORMAT_STRINGS = [
  'd',
  'dd',
  'd.M',
  'd.M.',
  'dd.M',
  'dd.M.',
  'd.MM',
  'd.MM.',
  'dd.MM',
  'dd.MM.',
  'd.M.yy',
  'dd.M.yy',
  'd.MM.yy',
  'dd.MM.yy',
  'd.M.yyyy',
  'dd.M.yyyy',
  'd.MM.yyyy',
  'dd.MM.yyyy',
];

const DATE_TIME_FORMAT_STRING = 'dd.MM.yyyy, HH:mm';
const DATE_TIME_SECONDS_FORMAT_STRING = 'dd.MM.yyyy, HH:mm:ss';
const PARSE_TIME_FORMAT_STRINGS = ['H', 'HH', 'H:m', 'HH:m', 'H:mm', 'HH:mm', 'HH:mm:ss'];

const REGEX_DATE = /^[.0-9]*$/;
const REGEX_DATE_TIME = /^[.0-9,\s:]*$/;

// lh: Merge time and date format string for parsing both
const PARSE_DATE_TIME_FORMAT_STRINGS = sortBy(
  [
    ...PARSE_DATE_FORMAT_STRINGS,
    ...flatten(
      PARSE_DATE_FORMAT_STRINGS.map((parseDateFormatString) =>
        flatten(
          PARSE_TIME_FORMAT_STRINGS.map((parseTimeFormatString) => [
            `${parseDateFormatString}, ${parseTimeFormatString}`,
            `${parseDateFormatString},${parseTimeFormatString}`,
          ]),
        ),
      ),
    ),
  ],
  // lh: Sort by format string length which might improve performance (assuming shorter parsing
  // strings are faster than longer once). This also matches more closely how users type in a date
  // time string.
  (formatString) => formatString.length,
);

export interface DateInputProps extends Omit<InputProps, 'value' | 'onChange' | 'type'> {
  value?: Date | null;
  onChange?(value: Date | null): void;
  referenceDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  autocompletePastOnly?: boolean;
  showYearDropdown?: boolean;
  showTimeSelect?: boolean;
  showSeconds?: boolean;
  minDatePicker?: Date;
  maxDatePicker?: Date;
  onPickerToggle?(isOpen: boolean): void;
}

const DateInput = React.forwardRef(
  (
    {
      value: date,
      onChange: onDateChange,
      referenceDate = new Date(),
      minDate,
      maxDate,
      showYearDropdown,
      showTimeSelect,
      showSeconds,
      minDatePicker,
      maxDatePicker,
      onPickerToggle,
      ...props
    }: DateInputProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const { t } = useTranslation('common');
    const popoverRef = React.useRef<HTMLDivElement>(null);
    const [isPickerOpen, setIsPickerOpen] = React.useState(false);

    const handlePickerToggle = (isOpen: boolean) => {
      setIsPickerOpen(isOpen);
      onPickerToggle?.(isOpen);
    };

    date = useMemoDate(date ?? undefined);
    referenceDate = useMemoDate(referenceDate) as Date;

    const validDate = isValid(date) ? date : undefined;

    useOutsideClick({
      ref: popoverRef,
      handler() {
        handlePickerToggle(false);
      },
    });

    const handleChange = (nextDate: Date | null) => {
      // lh: This one is tricky. Our API client on it's way to the server transforms a date (without
      // time) into an ISO date by simply cutting off the part for the time.
      // Now, if someone in Germany (one to two hours time offset) selects a date in the date picker
      // or date input, we get the respective date at zero o'clock. If we convert this to an ISO
      // date, a conversion to UTC is done and the date is at 23 or 22 o'clock the day before. Since
      // the API client simply truncates this date, the previous day at zero o'clock is actually
      // stored in the database and shown again later. Ugh …
      // We solve this (for now) as follows: if the time zone offset is negative (such as in
      // Germany), we must subtract this from the date entered so that no day is lost when
      // converting to ISO date (UTC).
      // This should not be a problem in countries with positive time offset.
      if (nextDate != null && !showTimeSelect && !showSeconds) {
        const timezoneOffset = nextDate.getTimezoneOffset();

        nextDate = startOfDay(nextDate);

        if (timezoneOffset < 0) {
          nextDate = subMinutes(nextDate, timezoneOffset);
        }
      }

      onDateChange?.(nextDate);
    };

    const handleDatePickerChange = (
      nextDate: Date | null,
      event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    ) => {
      handleChange(nextDate);

      // lh: When showing a time select, we want to close the picker only after a time was selected.
      // react-datepicker has no event to distinguish between a selection of a date and
      // a selection of time. So what to do here?
      // For some strange reason react-datepicker is propagating no pointer event for time
      // selections. Let's use that for now …
      if (!showTimeSelect || event == null) {
        handlePickerToggle(false);
      }
    };

    return (
      // lh: If this input is part of stack, margin is applied to both the time input and the
      // popover. Margin at the popover element will throw a warning within popper (used to position
      // the popover).
      <div>
        <Popover autoFocus={false} isOpen={isPickerOpen} isLazy>
          <BaseDateInput
            {...props}
            parseTime={showTimeSelect}
            parseTimeWithSeconds={showSeconds}
            value={date}
            onChange={handleChange}
            referenceDate={referenceDate}
            ref={ref}
            onDatePickerToggle={() => handlePickerToggle(true)}
          />
          <PopoverContent w="auto" ref={popoverRef}>
            <PopoverArrow bgColor="layer.02" />
            <PopoverBody p={0}>
              <DatePicker
                selected={validDate}
                openToDate={validDate ?? referenceDate}
                onChange={handleDatePickerChange}
                minDate={minDatePicker ?? minDate}
                maxDate={maxDatePicker ?? maxDate}
                showYearDropdown={showYearDropdown}
                showTimeSelect={showTimeSelect}
                yearDropdownItemNumber={150}
                scrollableYearDropdown
                inline
                locale="de"
                timeCaption={t('date_input.time')}
              />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </div>
    );
  },
);

export default React.memo(DateInput, isEqual);

export interface BaseDateInputProps extends Omit<InputProps, 'value' | 'onChange' | 'type'> {
  value?: Date | null;
  onChange?(value: Date | null): void;
  referenceDate?: Date;
  onDatePickerToggle(): void;
  autocompletePastOnly?: boolean;
  parseTime?: boolean;
  parseTimeWithSeconds?: boolean;
}

const BaseDateInput = React.forwardRef<HTMLInputElement, BaseDateInputProps>(
  (
    {
      value: date,
      onChange: onDateChange,
      onFocus,
      onBlur,
      placeholder,
      autoComplete = 'off',
      referenceDate = startOfDay(new Date()),
      onDatePickerToggle,
      autocompletePastOnly,
      parseTime,
      parseTimeWithSeconds,
      isDisabled,
      size,
      ...props
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = React.useState('');
    const { t } = useTranslation('common');

    const parseFormatStrings =
      parseTime || parseTimeWithSeconds ? PARSE_DATE_TIME_FORMAT_STRINGS : PARSE_DATE_FORMAT_STRINGS;
    const formatString = parseTimeWithSeconds
      ? DATE_TIME_SECONDS_FORMAT_STRING
      : parseTime
        ? DATE_TIME_FORMAT_STRING
        : DATE_FORMAT_STRING;

    React.useEffect(() => {
      setInputValue((inputValue) => {
        if (date == null) {
          return '';
        }

        const nextDate = parseInputValue(inputValue, parseFormatStrings, referenceDate, autocompletePastOnly);

        // lh: Only overwrite input value if the component was given a new date (and time).
        if (
          isValid(date) &&
          (nextDate == null ||
            (parseTimeWithSeconds
              ? !isSameSecond(date, nextDate)
              : parseTime
                ? !isSameMinute(date, nextDate)
                : !isSameDay(date, nextDate)))
        ) {
          return format(date as Date, formatString);
        }

        return inputValue;
      });
    }, [parseTime, parseTimeWithSeconds, autocompletePastOnly, date, formatString, parseFormatStrings, referenceDate]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;
      const regex = parseTime ? REGEX_DATE_TIME : REGEX_DATE;
      const maxLength = parseTimeWithSeconds
        ? DATE_TIME_SECONDS_FORMAT_STRING.length
        : parseTime
          ? DATE_TIME_FORMAT_STRING.length
          : DATE_FORMAT_STRING.length;

      if (!regex.test(event.target.value) || event.target.value.length > maxLength) {
        return;
      }

      const nextDate = parseInputValue(inputValue, parseFormatStrings, referenceDate, autocompletePastOnly);

      if (nextDate?.valueOf() !== date?.valueOf()) {
        onDateChange?.(nextDate);
      }

      setInputValue(inputValue);
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      onFocus?.(event);

      if (event.defaultPrevented) {
        return;
      }

      event.target.select();
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      onBlur?.(event);

      if (event.defaultPrevented) {
        return;
      }

      const nextDate = parseInputValue(inputValue, parseFormatStrings, referenceDate, autocompletePastOnly);

      if (isValid(nextDate)) {
        setInputValue(format(nextDate as Date, formatString));
      }

      if (nextDate?.valueOf() !== date?.valueOf()) {
        onDateChange?.(nextDate);
      }
    };

    return (
      <InputGroup size={size}>
        <PopoverAnchor>
          <Input
            {...props}
            type="text"
            value={inputValue}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={
              placeholder ??
              (parseTimeWithSeconds ? 'TT.MM.JJJJ, HH:MM:SS' : parseTime ? 'TT.MM.JJJJ, HH:MM' : 'TT.MM.JJJJ')
            }
            autoComplete={autoComplete}
            ref={ref}
            size={size}
            isDisabled={isDisabled}
          />
        </PopoverAnchor>
        <PopoverTrigger>
          <InputRightElement
            as={Button}
            isDisabled={isDisabled}
            aria-label={t('date_input.pick_date')}
            variant="unstyled"
            children={<FontAwesomeIcon icon={faCalendar} />}
            cursor="pointer"
            onClick={onDatePickerToggle}
            size={size}
          />
        </PopoverTrigger>
      </InputGroup>
    );
  },
);

function parseInputValue(
  inputValue: string,
  formatStrings: string[],
  referenceDate: Date,
  autocompleteToPastDates?: boolean,
) {
  if (inputValue === '') {
    return null;
  }

  const date = parseMultiple(inputValue, formatStrings, referenceDate, { autocompleteToPastDates });

  // lh: Fixes a bug in the parse function parsing 3-digit years although using "yyyy" in the format string.
  if (isValid(date) && getYear(date) < 1000) {
    return new Date(NaN);
  }

  return date;
}
