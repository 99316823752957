import React from 'react';
import invariant from 'tiny-invariant';
import {
  MailingTemplateDataDto,
  MailingModuleConstraintDto,
  MailingModuleSettingsDto,
  ModuleTypeDto,
} from '../../../api';
import usePlugins from '../../../util/plugin/use-plugins';
import { MAILING_MODULE_TYPE_EXTENSION } from '../mailing-module-type-extension';

export default function useModuleTypeExtension(namePrefix: string) {
  const extensions = usePlugins(MAILING_MODULE_TYPE_EXTENSION);

  return React.useCallback(
    ({
      template,
      module,
      index,
      constraints,
      connectionId,
    }: {
      template: MailingTemplateDataDto;
      module: MailingModuleSettingsDto;
      index: number;
      constraints: Partial<{ [key in ModuleTypeDto]: MailingModuleConstraintDto }>;
      connectionId?: string;
    }) => {
      const extension = extensions.find((extension) => extension.type === module.moduleType);
      invariant(extension != null, `Unknown extension type ${module.moduleType}`);

      return extension.moduleControl({ template, module, name: `${namePrefix}.${index}`, constraints, connectionId });
    },
    [extensions, namePrefix],
  );
}
