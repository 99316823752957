import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const sectionMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.section" />,
    path: '/sections',
    neccessaryPermission: 'SECTION:CAN_SEE_LIST_VIEW',
  },
];

export default sectionMenuItems;
