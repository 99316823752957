/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ParticipationReferenceDto } from './ParticipationReferenceDto';
import {
    ParticipationReferenceDtoFromJSON,
    ParticipationReferenceDtoFromJSONTyped,
    ParticipationReferenceDtoToJSON,
} from './ParticipationReferenceDto';

/**
 * A person merge conflict with its cause in participation
 * @export
 * @interface MergePersonParticipationConflictDto
 */
export interface MergePersonParticipationConflictDto {
    /**
     * 
     * @type {string}
     * @memberof MergePersonParticipationConflictDto
     */
    conflictType: MergePersonParticipationConflictDtoConflictTypeEnum;
    /**
     * 
     * @type {Array<ParticipationReferenceDto>}
     * @memberof MergePersonParticipationConflictDto
     */
    participations?: Array<ParticipationReferenceDto>;
}


/**
 * @export
 */
export const MergePersonParticipationConflictDtoConflictTypeEnum = {
    GUEST_LIST: 'GUEST_LIST',
    PARTICIPATION: 'PARTICIPATION',
    GROUP_PERSON: 'GROUP_PERSON'
} as const;
export type MergePersonParticipationConflictDtoConflictTypeEnum = typeof MergePersonParticipationConflictDtoConflictTypeEnum[keyof typeof MergePersonParticipationConflictDtoConflictTypeEnum];


/**
 * Check if a given object implements the MergePersonParticipationConflictDto interface.
 */
export function instanceOfMergePersonParticipationConflictDto(value: object): boolean {
    if (!('conflictType' in value)) return false;
    return true;
}

export function MergePersonParticipationConflictDtoFromJSON(json: any): MergePersonParticipationConflictDto {
    return MergePersonParticipationConflictDtoFromJSONTyped(json, false);
}

export function MergePersonParticipationConflictDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MergePersonParticipationConflictDto {
    if (json == null) {
        return json;
    }
    return {
        
        'conflictType': json['conflictType'],
        'participations': json['participations'] == null ? undefined : ((json['participations'] as Array<any>).map(ParticipationReferenceDtoFromJSON)),
    };
}

export function MergePersonParticipationConflictDtoToJSON(value?: MergePersonParticipationConflictDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'conflictType': value['conflictType'],
        'participations': value['participations'] == null ? undefined : ((value['participations'] as Array<any>).map(ParticipationReferenceDtoToJSON)),
    };
}

