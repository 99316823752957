import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingPageRsvpModuleSettingsDto } from '../../../api';
import { PluginRegistry } from '../../../util/plugin/use-plugins';
import LandingPageModuleConfig, {
  LANDING_PAGE_MODULE_HOOK,
} from '../../landing-page-template/landing-page-module-config';
import { LANDING_PAGE_TYPE_HOOK } from '../../landing-page-template/landing-page-type-config';
import { LANDING_PAGE_EXTENSION } from '../../landing-page/use-landing-page-extension';
import { guestListLandingPagePlugin } from './guest-list-landing-page-plugin';
import RsvpModuleContentControl from './rsvp-module-content-control';
import RsvpModuleSettingsControl from './rsvp-module-settings-control';
import RsvpModuleViewer from './rsvp-module-viewer';

export default function GuestListLandingPageProvider(registry: PluginRegistry) {
  registry.register(LANDING_PAGE_TYPE_HOOK, useLandingPageGuestListType);
  registry.register(LANDING_PAGE_MODULE_HOOK, useLandingPageRsvpModule);
  registry.register(LANDING_PAGE_EXTENSION, guestListLandingPagePlugin);
}

function useLandingPageGuestListType() {
  const { t } = useTranslation('guest_list');

  return {
    type: 'guestList',
    label: t('guestList'),
  };
}

function useLandingPageRsvpModule(): LandingPageModuleConfig<LandingPageRsvpModuleSettingsDto> {
  const { t } = useTranslation(['guest_list', 'common']);

  return {
    type: 'rsvp',
    label: t('guest_list:landingPage.rsvpModule.label'),
    renderSettingsControl: ({ name }) => <RsvpModuleSettingsControl name={name} />,
    renderContentControl: ({ name }) => <RsvpModuleContentControl name={name} />,
    renderViewer: ({ module }) => (
      <RsvpModuleViewer commentsAllowed={module.commentsAllowed} commentText={module.helperText} />
    ),
    historyDisplaySettings: {
      attributeLabels: {
        commentsAllowed: t('guest_list:landingPage.rsvpModule.commentsAllowed'),
        helperText: t('guest_list:landingPage.rsvpModule.helperTextLabel'),
      },
      valueFormatter: {
        commentsAllowed: (value) => (value != null ? t(`common:boolean_viewer.${value}`) : value),
      },
      diffEntireWord: {
        commentsAllowed: true,
      },
    },
  };
}
