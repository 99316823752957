/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvoicePermissionDto
 */
export interface InvoicePermissionDto {
    /**
     * 
     * @type {string}
     * @memberof InvoicePermissionDto
     */
    name: InvoicePermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof InvoicePermissionDto
     */
    action: InvoicePermissionDtoActionEnum;
}


/**
 * @export
 */
export const InvoicePermissionDtoNameEnum = {
    INVOICE: 'INVOICE'
} as const;
export type InvoicePermissionDtoNameEnum = typeof InvoicePermissionDtoNameEnum[keyof typeof InvoicePermissionDtoNameEnum];

/**
 * @export
 */
export const InvoicePermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    EDIT: 'CAN_EDIT',
    CREATE: 'CAN_CREATE',
    SEND: 'CAN_SEND',
    DOWNLOAD: 'CAN_DOWNLOAD',
    CANCEL: 'CAN_CANCEL',
    REFUND_TAX: 'CAN_REFUND_TAX',
    SEE_RECIPIENT_UPDATE: 'CAN_SEE_RECIPIENT_UPDATE',
    VALIDATE_RECIPIENT_UPDATE: 'CAN_VALIDATE_RECIPIENT_UPDATE'
} as const;
export type InvoicePermissionDtoActionEnum = typeof InvoicePermissionDtoActionEnum[keyof typeof InvoicePermissionDtoActionEnum];


/**
 * Check if a given object implements the InvoicePermissionDto interface.
 */
export function instanceOfInvoicePermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function InvoicePermissionDtoFromJSON(json: any): InvoicePermissionDto {
    return InvoicePermissionDtoFromJSONTyped(json, false);
}

export function InvoicePermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoicePermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function InvoicePermissionDtoToJSON(value?: InvoicePermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

