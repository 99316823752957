/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccessGroupLocationsInnerDto } from './AccessGroupLocationsInnerDto';
import {
    AccessGroupLocationsInnerDtoFromJSON,
    AccessGroupLocationsInnerDtoFromJSONTyped,
    AccessGroupLocationsInnerDtoToJSON,
} from './AccessGroupLocationsInnerDto';
import type { AccessGroupStatusDto } from './AccessGroupStatusDto';
import {
    AccessGroupStatusDtoFromJSON,
    AccessGroupStatusDtoFromJSONTyped,
    AccessGroupStatusDtoToJSON,
} from './AccessGroupStatusDto';
import type { LiveTicketingPriorityDto } from './LiveTicketingPriorityDto';
import {
    LiveTicketingPriorityDtoFromJSON,
    LiveTicketingPriorityDtoFromJSONTyped,
    LiveTicketingPriorityDtoToJSON,
} from './LiveTicketingPriorityDto';
import type { ProfessionDto } from './ProfessionDto';
import {
    ProfessionDtoFromJSON,
    ProfessionDtoFromJSONTyped,
    ProfessionDtoToJSON,
} from './ProfessionDto';
import type { TicketingPriorityDto } from './TicketingPriorityDto';
import {
    TicketingPriorityDtoFromJSON,
    TicketingPriorityDtoFromJSONTyped,
    TicketingPriorityDtoToJSON,
} from './TicketingPriorityDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * An access group.
 * @export
 * @interface AccessGroupDto
 */
export interface AccessGroupDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof AccessGroupDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof AccessGroupDto
     */
    version?: VersionDto;
    /**
     * The name of the access group.
     * @type {string}
     * @memberof AccessGroupDto
     */
    title: string;
    /**
     * The english name of the access group.
     * @type {string}
     * @memberof AccessGroupDto
     */
    englishTitle?: string;
    /**
     * 
     * @type {LiveTicketingPriorityDto}
     * @memberof AccessGroupDto
     */
    liveTicketingPriority: LiveTicketingPriorityDto;
    /**
     * 
     * @type {TicketingPriorityDto}
     * @memberof AccessGroupDto
     */
    ticketingPriority?: TicketingPriorityDto;
    /**
     * 
     * @type {Array<ProfessionDto>}
     * @memberof AccessGroupDto
     */
    professions?: Array<ProfessionDto>;
    /**
     * 
     * @type {Array<AccessGroupLocationsInnerDto>}
     * @memberof AccessGroupDto
     */
    locations?: Array<AccessGroupLocationsInnerDto>;
    /**
     * 
     * @type {AccessGroupStatusDto}
     * @memberof AccessGroupDto
     */
    status: AccessGroupStatusDto;
}

/**
 * Check if a given object implements the AccessGroupDto interface.
 */
export function instanceOfAccessGroupDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('title' in value)) return false;
    if (!('liveTicketingPriority' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function AccessGroupDtoFromJSON(json: any): AccessGroupDto {
    return AccessGroupDtoFromJSONTyped(json, false);
}

export function AccessGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessGroupDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'title': json['title'],
        'englishTitle': json['englishTitle'] == null ? undefined : json['englishTitle'],
        'liveTicketingPriority': LiveTicketingPriorityDtoFromJSON(json['liveTicketingPriority']),
        'ticketingPriority': json['ticketingPriority'] == null ? undefined : TicketingPriorityDtoFromJSON(json['ticketingPriority']),
        'professions': json['professions'] == null ? undefined : ((json['professions'] as Array<any>).map(ProfessionDtoFromJSON)),
        'locations': json['locations'] == null ? undefined : ((json['locations'] as Array<any>).map(AccessGroupLocationsInnerDtoFromJSON)),
        'status': AccessGroupStatusDtoFromJSON(json['status']),
    };
}

export function AccessGroupDtoToJSON(value?: AccessGroupDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'title': value['title'],
        'englishTitle': value['englishTitle'],
        'liveTicketingPriority': LiveTicketingPriorityDtoToJSON(value['liveTicketingPriority']),
        'ticketingPriority': TicketingPriorityDtoToJSON(value['ticketingPriority']),
        'professions': value['professions'] == null ? undefined : ((value['professions'] as Array<any>).map(ProfessionDtoToJSON)),
        'locations': value['locations'] == null ? undefined : ((value['locations'] as Array<any>).map(AccessGroupLocationsInnerDtoToJSON)),
        'status': AccessGroupStatusDtoToJSON(value['status']),
    };
}

