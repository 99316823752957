import { PersonMergeConflictDto } from '../../../api';
import { PluginToken } from '../../../util/plugin/use-plugins';

export type getMergeConflictMessages = (
  mergeConflicts: PersonMergeConflictDto[],
  translation: any,
  index: number,
) => React.ReactNode;

export const MERGE_CONFLICT_PROVIDER_EXTENSION = new PluginToken<getMergeConflictMessages>(
  'MergeConflictProviderExtension',
);
