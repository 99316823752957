import { isArray } from 'lodash-es';
import React from 'react';

export function sortAttributesByHighlightQuery(
  attributes: { label?: string | undefined; element: React.ReactNode }[],
  highlightQuery: string | string[],
) {
  if (isArray(highlightQuery)) {
    return attributes.sort((a, b) => {
      const firstElement = a.element!.toString().toLowerCase();
      const secondElement = b.element!.toString().toLowerCase();

      const indexA = highlightQuery.findIndex((query) => firstElement.includes(query.toLowerCase()));
      const indexB = highlightQuery.findIndex((query) => secondElement.includes(query.toLowerCase()));

      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }

      if (indexA !== -1) {
        return -1;
      }

      if (indexB !== -1) {
        return 1;
      }

      return firstElement.localeCompare(secondElement);
    });
  }

  return attributes.sort((a, b) => {
    const firstElement = a.element!.toString().toLowerCase();
    const secondElement = b.element!.toString().toLowerCase();

    const firstContains = firstElement.includes(highlightQuery.toLowerCase());

    return firstContains === secondElement.includes(highlightQuery.toLowerCase())
      ? firstElement.localeCompare(secondElement)
      : firstContains
        ? -1
        : 1;
  });
}
