import { EditionImplicationDto } from '../../../api';

enum EditionImplicationType {
  DATE_RANGE = 'DATE_RANGE',
  NAME = 'NAME',
}

export function getImplicationType(implications: EditionImplicationDto[]) {
  const hasDateRangeImplications = implications.some((implication) => implication.dateRange != null);
  const hasNameImplications = implications.some((implication) => implication.name != null);

  if (hasDateRangeImplications && !hasNameImplications) {
    return EditionImplicationType.DATE_RANGE;
  } else if (hasNameImplications && !hasDateRangeImplications) {
    return EditionImplicationType.NAME;
  } else {
    return null;
  }
}

export default EditionImplicationType;
