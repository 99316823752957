import { isEqual, omit } from 'lodash-es';
import { Path } from 'react-hook-form';
import { SubElementHistoryDisplaySettings } from './history-display-settings';
import { SubElements } from './sub-elements';

export interface ChangeDto<T> {
  attribute: Path<T>;
  before: string | SubElements[];
  after: string | SubElements[];
  subElementSettings?: SubElementHistoryDisplaySettings<any>;
}

export function cleanArrays(change: ChangeDto<any>): void {
  if (Array.isArray(change.before) && Array.isArray(change.after)) {
    const minLength = Math.min(change.before.length, change.after.length);
    const indexesToRemove = [];
    for (let i = 0; i < minLength; i++) {
      const beforeElements = change.before[i];
      const afterElements = change.after[i];
      let remove = true;
      const longestArray = Math.max(beforeElements.elements.length, afterElements.elements.length);
      for (let j = 0; j < longestArray; j++) {
        const beforeElement = beforeElements.elements[j];
        const afterElement = afterElements.elements[j];
        if (!isEqual(omit(beforeElement, ['type']), omit(afterElement, ['type']))) {
          remove = false;
        }
      }
      if (remove) {
        indexesToRemove.push(i);
      }
    }

    for (const val of indexesToRemove.reverse()) {
      change.before.splice(val, 1);
      change.after.splice(val, 1);
    }
  }
}
