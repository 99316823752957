import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import useFetcher from '../../util/swr/use-fetcher';
import PersonPage from '../person/person-page/person-page';
import { fetchPerson } from '../person/person-queries';

const EventOfPersonLister = React.lazy(() => import('./events-of-person-lister/events-of-person-lister'));

const eventsOfPersonRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: '',
    element: <PersonPage />,
    children: [
      {
        path: 'events-of-person',
        element: <EventOfPersonLister />,
        handle: {
          helmet: <EventOfPersonHelmet />,
        },
      },
    ],
  },
];

export default eventsOfPersonRoutes;

function EventOfPersonHelmet() {
  const { t } = useTranslation('events_of_person');
  const params = useParams<{ personId: string }>();
  invariant(params.personId, 'Empty personId');
  const person = useFetcher(fetchPerson, { id: params.personId });

  return <Helmet title={t('lister.helmet', { firstName: person.firstName, surname: person.surname })} />;
}
