import { useCallbackRef } from '@chakra-ui/react';
import { useSWRConfig } from 'swr';

export default function useMatchMutate(matcher: RegExp) {
  const { cache, mutate } = useSWRConfig();

  return useCallbackRef(() => {
    const keys = [];

    for (const key of cache.keys()) {
      if (matcher.test(key)) {
        keys.push(key);
      }
    }

    const mutations = keys.map((key) => mutate(key));
    return Promise.all(mutations);
  });
}
