import { createContext, ReactNode, useMemo } from 'react';
import { PermissionDto } from '../../api';
import useDefinedContext from '../../util/context/use-defined-context/use-defined-context';
import useFetcher from '../../util/swr/use-fetcher';
import { fetchPermissions, fetchPermissionsBySection } from './permission-queries';

export type PermissionName = PermissionDto['name'];
export type PermissionByName<TName extends PermissionName> = PermissionDto & { name: TName };
export type PermissionString<TName extends PermissionName> = TName extends PermissionName
  ? `${TName}:${PermissionByName<TName>['action']}`
  : never;
export type Permission = PermissionString<PermissionName>;

export interface UsePermissionReturn {
  hasPermission: (permission: Permission) => boolean;
  hasPermissionFromSection: (sectionId: string, permission: Permission) => boolean;
}

export default function usePermission(): UsePermissionReturn {
  return useDefinedContext(PermissionContext);
}

export const PermissionContext = createContext<UsePermissionReturn | null>(null);

export function PermissionContextProvider({ children }: { children: ReactNode }) {
  const permissions = useFetcher(fetchPermissions, {}, { immutable: true });
  const permissionsBySection = useFetcher(fetchPermissionsBySection, {}, { immutable: true });

  const context = useMemo(
    () => ({
      hasPermission: (permission: Permission) => permissions.includes(permission),
      hasPermissionFromSection: (sectionId: string, permission: Permission) =>
        permissionsBySection[sectionId]?.includes(permission),
    }),
    [permissions, permissionsBySection],
  );

  return <PermissionContext.Provider value={context}>{children}</PermissionContext.Provider>;
}
