import React from 'react';
import invariant from 'tiny-invariant';
import { SearchResultDto } from '../../../api';
import { locationIcon, placementIcon } from '../../../ui/icons/business-objects';
import LocationReference from '../../location/location-reference/location-reference';
import { SearchResult } from '../../search/search-result';
import SearchResultConnection from '../../search/search-result-connection';

export default function PlacementTemplateSearchResult({
  result,
  onClick,
}: {
  result: SearchResultDto;
  onClick: () => void;
}) {
  invariant(
    result.resultType === 'PLACEMENT_TEMPLATE',
    `${result.resultType} is no instance of PlacementTemplateSearchResult`,
  );

  const placementTemplate = result.placementTemplate;
  const location = result.location;

  return (
    <SearchResult
      icon={placementIcon}
      title={placementTemplate.name}
      link={`/locations/${location.id}/placement-templates/${placementTemplate.id}`}
      score={result.score}
      onClick={onClick}
      necessaryPermission="LOCATION:CAN_SEE_PLACEMENT_TEMPLATE"
    >
      <SearchResultConnection targetIcon={locationIcon} onTargetClick={onClick}>
        <LocationReference locationReference={location} hideIcon />
      </SearchResultConnection>
    </SearchResult>
  );
}
