/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ParticipationPrivilegesDto = {
    AIRPORT_PICKUP: 'AIRPORT_PICKUP',
    RED_CARPET_ARRIVAL: 'RED_CARPET_ARRIVAL',
    VIP_TICKETING: 'VIP_TICKETING',
    DINING_LUNCH_CLUB: 'DINING_LUNCH_CLUB'
} as const;
export type ParticipationPrivilegesDto = typeof ParticipationPrivilegesDto[keyof typeof ParticipationPrivilegesDto];


export function ParticipationPrivilegesDtoFromJSON(json: any): ParticipationPrivilegesDto {
    return ParticipationPrivilegesDtoFromJSONTyped(json, false);
}

export function ParticipationPrivilegesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipationPrivilegesDto {
    return json as ParticipationPrivilegesDto;
}

export function ParticipationPrivilegesDtoToJSON(value?: ParticipationPrivilegesDto | null): any {
    return value as any;
}

