import { chakra, Flex, FormControl, FormLabel } from '@chakra-ui/react';
import { isValid } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DateInput from '../../date-input/date-input';
import { useDataTableFilter } from '../data-table-context';

interface DateFilterProps {
  showTimeSelect?: boolean;
  twoLine?: boolean;
}

/**
 * Date range filter, may be used for all date and date/time columns in data tables.
 * If showTimeSelect is set, a date/time control will be displayed instead of a simple date control.
 */
export default function DateFilter({ showTimeSelect = false, twoLine = false }: DateFilterProps) {
  const { t } = useTranslation('common');
  const { property, getFilters, setFilterReplaceIfOperatorMatches, removeFilter, initialFocusRef, setIsCloseable } =
    useDataTableFilter();

  const dateAfterInUrl = getFilters(property)?.find((filter) => filter.operator === 'gte');
  const dateBeforeInUrl = getFilters(property)?.find((filter) => filter.operator === 'lte');

  const [afterDate, setAfterDate] = useState<Date | null | undefined>(fromISODate(dateAfterInUrl?.value));
  const [beforeDate, setBeforeDate] = useState<Date | null | undefined>(fromISODate(dateBeforeInUrl?.value));

  return (
    <chakra.form>
      <Flex gap={3} flexDirection={twoLine ? 'column' : 'row'}>
        <FormControl>
          <FormLabel>{t('data_table.dateFilter.after')}</FormLabel>
          <DateInput
            size="sm"
            showTimeSelect={showTimeSelect}
            value={afterDate}
            ref={initialFocusRef}
            onPickerToggle={(isOpen) => setIsCloseable(!isOpen)}
            onChange={(newDate) => {
              setAfterDate(newDate);
              if (newDate == null) {
                removeFilter(property, 'gte');
                return;
              }
              if (!isValid(newDate)) {
                return;
              }

              setFilterReplaceIfOperatorMatches({
                operator: 'gte',
                value: toISODate(newDate, showTimeSelect),
                property,
              });
            }}
          />
          {afterDate != null && !isValid(afterDate) && (
            <chakra.div color="text.error" fontSize="sm" lineHeight="normal">
              {showTimeSelect ? t('validation_error.invalid_date_time') : t('validation_error.invalid_date')}
            </chakra.div>
          )}
        </FormControl>

        <FormControl>
          <FormLabel>{t('data_table.dateFilter.before')}</FormLabel>
          <DateInput
            size="sm"
            showTimeSelect={showTimeSelect}
            value={beforeDate}
            ref={initialFocusRef}
            onPickerToggle={(isOpen) => setIsCloseable(!isOpen)}
            onChange={(newDate) => {
              setBeforeDate(newDate);
              if (newDate == null) {
                removeFilter(property, 'lte');
                return;
              }
              if (!isValid(newDate)) {
                return;
              }

              setFilterReplaceIfOperatorMatches({
                operator: 'lte',
                value: toISODate(newDate, showTimeSelect),
                property,
              });
            }}
          />
          {beforeDate != null && !isValid(beforeDate) && (
            <chakra.div color="text.error" fontSize="sm" lineHeight="normal">
              {showTimeSelect ? t('validation_error.invalid_date_time') : t('validation_error.invalid_date')}
            </chakra.div>
          )}
        </FormControl>
      </Flex>
    </chakra.form>
  );
}

function toISODate(date: Date, withTime: boolean): string {
  return withTime ? date.toISOString() : date.toISOString().split('T')[0];
}

function fromISODate(isoDate: string | undefined): Date | undefined {
  if (isoDate == null) {
    return undefined;
  }

  return new Date(isoDate);
}
