/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Reference of the editor of the current version. If not set, the system did the the last modification (like an import).
 * @export
 * @interface StaffAccountReferenceDto
 */
export interface StaffAccountReferenceDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof StaffAccountReferenceDto
     */
    id: string;
    /**
     * An email address
     * @type {string}
     * @memberof StaffAccountReferenceDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof StaffAccountReferenceDto
     */
    displayName?: string;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof StaffAccountReferenceDto
     */
    staffId?: string;
}

/**
 * Check if a given object implements the StaffAccountReferenceDto interface.
 */
export function instanceOfStaffAccountReferenceDto(value: object): boolean {
    if (!('id' in value)) return false;
    return true;
}

export function StaffAccountReferenceDtoFromJSON(json: any): StaffAccountReferenceDto {
    return StaffAccountReferenceDtoFromJSONTyped(json, false);
}

export function StaffAccountReferenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffAccountReferenceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'] == null ? undefined : json['email'],
        'displayName': json['displayName'] == null ? undefined : json['displayName'],
        'staffId': json['staffId'] == null ? undefined : json['staffId'],
    };
}

export function StaffAccountReferenceDtoToJSON(value?: StaffAccountReferenceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'email': value['email'],
        'displayName': value['displayName'],
        'staffId': value['staffId'],
    };
}

