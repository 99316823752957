import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import AppNavigationButton from './app-navigation-button';
import isActiveSubMenu from './is-active-sub-menu';
import SubMenuItem from './sub-menu-item';

interface AppNavigationSubMenuItemProps {
  item: SubMenuItem;
  isCollapsed?: boolean;
}

export default function AppNavigationSubMenuItem({ item, isCollapsed }: AppNavigationSubMenuItemProps) {
  const { pathname } = useLocation();
  const isActive = isActiveSubMenu(item, pathname);

  return (
    <AppNavigationButton
      as={Link}
      to={item.path}
      icon={item.icon}
      isCollapsed={isCollapsed}
      className={isActive ? 'active' : undefined}
    >
      {item.name}
    </AppNavigationButton>
  );
}
