import React from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchTag } from '../tag-queries';

export default function TagBreadcrumb() {
  const { tagId } = useParams<{ tagId: string }>();
  invariant(tagId, 'Empty tagId');
  const tag = useFetcher(fetchTag, { id: tagId });

  return <>{tag.name}</>;
}
