/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TagTypeDto = {
    INTERNAL: 'INTERNAL',
    EXTERNAL: 'EXTERNAL'
} as const;
export type TagTypeDto = typeof TagTypeDto[keyof typeof TagTypeDto];


export function TagTypeDtoFromJSON(json: any): TagTypeDto {
    return TagTypeDtoFromJSONTyped(json, false);
}

export function TagTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagTypeDto {
    return json as TagTypeDto;
}

export function TagTypeDtoToJSON(value?: TagTypeDto | null): any {
    return value as any;
}

