import React from 'react';
import invariant from 'tiny-invariant';
import { GroupStaffRelationDto } from '../../../../../api';
import HistoryPage from '../../../../../ui/history/history-page';
import { PageState } from '../../../../../ui/pagination/use-pagination-state';
import useFetcher from '../../../../../util/swr/use-fetcher';
import { GroupRelationHistoryExtension } from '../../../../group/group-relation/group-relation-history-extension';
import { fetchGroupStaffHistory } from '../group-staff-queries';
import useGroupStaffHistorySettings from './use-group-staff-relation-history-settings';

export const groupStaffHistoryExtension: GroupRelationHistoryExtension = {
  content: (groupId, state, onStateChanged) => (
    <GroupStaffHistory groupId={groupId} state={state} onStateChanged={onStateChanged} />
  ),
};

interface GroupStaffHistoryProps {
  groupId: string;
  state: PageState;
  onStateChanged: (state: PageState) => void;
}

function GroupStaffHistory({ groupId, state, onStateChanged }: GroupStaffHistoryProps) {
  const groupStaffHistorySettings = useGroupStaffHistorySettings();

  const groupStaffHistoryPage = useFetcher(fetchGroupStaffHistory, { id: groupId, pageable: state });
  invariant(groupStaffHistoryPage != null, 'Missing group staff history page');

  return (
    <HistoryPage<GroupStaffRelationDto>
      page={groupStaffHistoryPage}
      state={state}
      onStateChange={onStateChanged}
      historyDisplaySettings={groupStaffHistorySettings}
      showLine={false}
    />
  );
}
