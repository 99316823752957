/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccessToBuildingDto
 */
export interface AccessToBuildingDto {
    /**
     * 
     * @type {string}
     * @memberof AccessToBuildingDto
     */
    type: AccessToBuildingDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AccessToBuildingDto
     */
    text?: string;
    /**
     * 
     * @type {Date}
     * @memberof AccessToBuildingDto
     */
    timestamp?: Date;
}


/**
 * @export
 */
export const AccessToBuildingDtoTypeEnum = {
    PUBLIC: 'PUBLIC',
    RESTRICTED: 'RESTRICTED'
} as const;
export type AccessToBuildingDtoTypeEnum = typeof AccessToBuildingDtoTypeEnum[keyof typeof AccessToBuildingDtoTypeEnum];


/**
 * Check if a given object implements the AccessToBuildingDto interface.
 */
export function instanceOfAccessToBuildingDto(value: object): boolean {
    if (!('type' in value)) return false;
    return true;
}

export function AccessToBuildingDtoFromJSON(json: any): AccessToBuildingDto {
    return AccessToBuildingDtoFromJSONTyped(json, false);
}

export function AccessToBuildingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessToBuildingDto {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'text': json['text'] == null ? undefined : json['text'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
    };
}

export function AccessToBuildingDtoToJSON(value?: AccessToBuildingDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'text': value['text'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
    };
}

