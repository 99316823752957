import useSWR from 'swr';
import invariant from 'tiny-invariant';
import personApi from '../../../data-access/person-api';

export function getPersonCardCacheKey(personId: string) {
  return ['personCard', personId];
}

export default function usePersonCard(personId: string) {
  const { data } = useSWR(getPersonCardCacheKey(personId), () => personApi.fetchPersonCard({ personId }), {
    suspense: true,
  });
  invariant(data != null);
  return data;
}
