import { DefaultPhoneNumberDto } from '../../api';

export default function linkPhoneNumber(phoneNumber: DefaultPhoneNumberDto) {
  return linkPhoneNumberString(
    '+' +
      phoneNumber.countryCode.countryCode +
      (phoneNumber.dialingCode == null ? '' : phoneNumber.dialingCode) +
      phoneNumber.number +
      (phoneNumber.extension == null ? '' : phoneNumber.extension),
  );
}

export function linkPhoneNumberString(phoneNumber: string) {
  return 'tel:' + phoneNumber;
}
