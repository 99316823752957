import personApi from '../../data-access/person-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';

export const personFetcher = createFetcherScope(personApi);
export const fetchPerson = personFetcher.createFetcher(personApi.fetchPerson);
export const fetchPersonPage = personFetcher.createFetcher(personApi.searchPersons);
export const fetchPersonListItemPage = personFetcher.createFetcher(personApi.searchPersonListItems);
export const fetchDeletedPersonPage = personFetcher.createFetcher(personApi.searchDeletedPersons);
export const fetchAnonymisedPersonPage = personFetcher.createFetcher(personApi.searchAnonymizedPersons);
export const fetchPersonComments = personFetcher.createFetcher(personApi.fetchPersonComments);
export const fetchPersonHistory = personFetcher.createFetcher(personApi.fetchPersonHistory);
export const fetchOccupationHistory = personFetcher.createFetcher(personApi.fetchOccupationHistory);
export const fetchPersonDuplicatesPage = personFetcher.createFetcher(personApi.getPossibleDuplicatesPage);
export const fetchPersonAllDuplicates = personFetcher.createFetcher(personApi.getAllPossibleDuplicates);
export const searchPersonsByCompany = personFetcher.createFetcher(personApi.searchPersonsByCompany);
