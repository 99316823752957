import { ReactNode } from 'react';
import { RouteObject } from 'react-router-dom';
import { ConnectedEntityReferenceDto, LandingPageReferenceDto, MailingTypeDto } from '../../api';
import Builder from '../../util/builder/builder';
import { PluginRegistry, PluginToken } from '../../util/plugin/use-plugins';

export interface MailingTab {
  key: string;
  element: ReactNode;
}

export default interface MailingTypeExtension {
  type: MailingTypeDto;
  connectionSelect?: ReactNode;
  pageRoutes?: RouteObject[];
  routes?: RouteObject[];
  reference?(connectedEntity: ConnectedEntityReferenceDto): ReactNode;
  bannerAddon?(connectionId: string): ReactNode;
  infoAddon?(connectionId: string): ReactNode;
  recipientEditorAddon?(props: { connectionId?: string; mailingId: string }): ReactNode;
  recipientListerAddon?(props: { connectionId?: string; mailingId: string }): ReactNode;
  tabsAddon?(builder: Builder<MailingTab>, props: { connectionId?: string; mailingId: string }): Builder<MailingTab>;
  landingPageConfig?: {
    fetchAvailable({
      connectionId,
      q,
      size,
    }: {
      connectionId?: string;
      q?: string;
      size?: number;
    }): Promise<LandingPageReferenceDto[]>;
    helperText?: ReactNode;
  };
}

export const MAILING_TYPE_EXTENSION = new PluginToken<MailingTypeExtension>('MailingTypeExtension');

export function getMailingPageRoutes(registry: PluginRegistry) {
  return registry.getAll(MAILING_TYPE_EXTENSION).flatMap((extension) => extension.pageRoutes ?? []);
}

export function getMailingRoutes(registry: PluginRegistry) {
  return registry.getAll(MAILING_TYPE_EXTENSION).flatMap((extension) => extension.routes ?? []);
}
