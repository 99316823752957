/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StaffReferenceDto
 */
export interface StaffReferenceDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof StaffReferenceDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StaffReferenceDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof StaffReferenceDto
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof StaffReferenceDto
     */
    emailAddress?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StaffReferenceDto
     */
    belongsToSections: Array<string>;
}

/**
 * Check if a given object implements the StaffReferenceDto interface.
 */
export function instanceOfStaffReferenceDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('firstName' in value)) return false;
    if (!('surname' in value)) return false;
    if (!('belongsToSections' in value)) return false;
    return true;
}

export function StaffReferenceDtoFromJSON(json: any): StaffReferenceDto {
    return StaffReferenceDtoFromJSONTyped(json, false);
}

export function StaffReferenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffReferenceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'surname': json['surname'],
        'emailAddress': json['emailAddress'] == null ? undefined : json['emailAddress'],
        'belongsToSections': json['belongsToSections'],
    };
}

export function StaffReferenceDtoToJSON(value?: StaffReferenceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'firstName': value['firstName'],
        'surname': value['surname'],
        'emailAddress': value['emailAddress'],
        'belongsToSections': value['belongsToSections'],
    };
}

