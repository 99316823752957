import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditionReferenceDto } from '../../../api';
import { eventIcon } from '../../../ui/icons/business-objects';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchEventReference } from '../event-queries';

interface EventReferenceData {
  id?: string;
  title?: string;
  englishTitle?: string;
  edition?: EditionReferenceDto;
  sectionIdsRequiredToSeeEvent?: string[];
}

export interface EventReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  event: EventReferenceData;
  includeEdition?: boolean;
  mobile?: boolean;
  useEnglishTitle?: boolean;
  isInline?: boolean;
  fullWidth?: boolean;
  readOnly?: boolean;
}

export default function EventReference({
  event,
  includeEdition = false,
  useEnglishTitle = false,
  mobile = false,
  isInline,
  fullWidth = false,
  readOnly = false,
  ...props
}: EventReferenceProps) {
  const { t } = useTranslation('event', { useSuspense: false });
  let sectionIds = event.sectionIdsRequiredToSeeEvent;

  const searchQuery = event.title ?? event.englishTitle ?? '';
  const relatedEventReference = useFetcher(
    fetchEventReference,
    { searchQuery: `${searchQuery}`, filter: [`id,eq,${event.id!}`] },
    { active: sectionIds == null },
  )?.content;

  if (sectionIds == null) {
    sectionIds = relatedEventReference ? relatedEventReference[0].sectionIdsRequiredToSeeEvent! : [];
  }

  return event.title != null && event.id != null && (!useEnglishTitle || event.englishTitle) ? (
    <Reference
      icon={eventIcon}
      to={readOnly ? undefined : `${mobile ? '/mobile' : ''}/events/${event.id}`}
      ml={isInline ? 1 : 0}
      width={fullWidth ? 'full' : undefined}
      necessaryPermission={({ hasPermission, hasPermissionFromSection }) => {
        return (
          hasPermission('EVENT:CAN_SEE_DETAILS') ||
          sectionIds.some((sectionId) => hasPermissionFromSection(sectionId, 'EVENT:CAN_SEE_DETAILS_OWN'))
        );
      }}
      {...props}
    >
      {useEnglishTitle ? event.englishTitle : event.title}
      {includeEdition && event.edition != null ? ` (${event.edition.name})` : ''}
    </Reference>
  ) : (
    <Reference color="heading" display="flex" alignItems="center" icon={eventIcon} {...props}>
      {t('forbiddenEventTitle')}
    </Reference>
  );
}
