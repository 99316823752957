/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LinkUrlDto } from './LinkUrlDto';
import {
    LinkUrlDtoFromJSON,
    LinkUrlDtoFromJSONTyped,
    LinkUrlDtoToJSON,
} from './LinkUrlDto';

/**
 * 
 * @export
 * @interface BookingDto
 */
export interface BookingDto {
    /**
     * 
     * @type {boolean}
     * @memberof BookingDto
     */
    isBookable?: boolean;
    /**
     * 
     * @type {LinkUrlDto}
     * @memberof BookingDto
     */
    link?: LinkUrlDto;
    /**
     * 
     * @type {string}
     * @memberof BookingDto
     */
    comment?: string;
}

/**
 * Check if a given object implements the BookingDto interface.
 */
export function instanceOfBookingDto(value: object): boolean {
    return true;
}

export function BookingDtoFromJSON(json: any): BookingDto {
    return BookingDtoFromJSONTyped(json, false);
}

export function BookingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingDto {
    if (json == null) {
        return json;
    }
    return {
        
        'isBookable': json['isBookable'] == null ? undefined : json['isBookable'],
        'link': json['link'] == null ? undefined : LinkUrlDtoFromJSON(json['link']),
        'comment': json['comment'] == null ? undefined : json['comment'],
    };
}

export function BookingDtoToJSON(value?: BookingDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isBookable': value['isBookable'],
        'link': LinkUrlDtoToJSON(value['link']),
        'comment': value['comment'],
    };
}

