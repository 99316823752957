import React from 'react';
import invariant from 'tiny-invariant';
import { TeamStaffRelationDto } from '../../../../api';
import HistoryPage from '../../../../ui/history/history-page';
import { PageState } from '../../../../ui/pagination/use-pagination-state';
import useFetcher from '../../../../util/swr/use-fetcher';
import { TeamRelationHistoryExtension } from '../../../team/team-relation/team-relation-history-extension';
import { fetchTeamStaffHistory } from '../team-staff-queries';
import useTeamStaffHistorySettings from './use-team-staff-relation-history-settings';

export const teamStaffHistoryExtension: TeamRelationHistoryExtension = {
  content: (teamId, state, onStateChanged) => (
    <TeamStaffHistory teamId={teamId} state={state} onStateChanged={onStateChanged} />
  ),
};

interface TeamStaffHistoryProps {
  teamId: string;
  state: PageState;
  onStateChanged: (state: PageState) => void;
}

function TeamStaffHistory({ teamId, state, onStateChanged }: TeamStaffHistoryProps) {
  const teamStaffHistorySettings = useTeamStaffHistorySettings();

  const teamStaffHistoryPage = useFetcher(fetchTeamStaffHistory, { id: teamId, pageable: state });
  invariant(teamStaffHistoryPage != null, 'Missing team staff history page');

  return (
    <HistoryPage<TeamStaffRelationDto>
      page={teamStaffHistoryPage}
      state={state}
      onStateChange={onStateChanged}
      historyDisplaySettings={teamStaffHistorySettings}
      showLine={false}
    />
  );
}
