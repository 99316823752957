/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LocationReferenceDto } from './LocationReferenceDto';
import {
    LocationReferenceDtoFromJSON,
    LocationReferenceDtoFromJSONTyped,
    LocationReferenceDtoToJSON,
} from './LocationReferenceDto';

/**
 * 
 * @export
 * @interface EventVenueDto
 */
export interface EventVenueDto {
    /**
     * 
     * @type {string}
     * @memberof EventVenueDto
     */
    realisation: EventVenueDtoRealisationEnum;
    /**
     * 
     * @type {LocationReferenceDto}
     * @memberof EventVenueDto
     */
    location?: LocationReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof EventVenueDto
     */
    digitalVenue?: string;
    /**
     * 
     * @type {string}
     * @memberof EventVenueDto
     */
    comment?: string;
}


/**
 * @export
 */
export const EventVenueDtoRealisationEnum = {
    IN_PERSON: 'IN_PERSON',
    ONLINE: 'ONLINE',
    HYBRID: 'HYBRID'
} as const;
export type EventVenueDtoRealisationEnum = typeof EventVenueDtoRealisationEnum[keyof typeof EventVenueDtoRealisationEnum];


/**
 * Check if a given object implements the EventVenueDto interface.
 */
export function instanceOfEventVenueDto(value: object): boolean {
    if (!('realisation' in value)) return false;
    return true;
}

export function EventVenueDtoFromJSON(json: any): EventVenueDto {
    return EventVenueDtoFromJSONTyped(json, false);
}

export function EventVenueDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventVenueDto {
    if (json == null) {
        return json;
    }
    return {
        
        'realisation': json['realisation'],
        'location': json['location'] == null ? undefined : LocationReferenceDtoFromJSON(json['location']),
        'digitalVenue': json['digitalVenue'] == null ? undefined : json['digitalVenue'],
        'comment': json['comment'] == null ? undefined : json['comment'],
    };
}

export function EventVenueDtoToJSON(value?: EventVenueDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'realisation': value['realisation'],
        'location': LocationReferenceDtoToJSON(value['location']),
        'digitalVenue': value['digitalVenue'],
        'comment': value['comment'],
    };
}

