import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { HelmetRouteObject } from '../../../ui/helmet/helmet-outlet';
import PublicRundownViewer from './public-rundown-viewer';
import { usePublishedRundownSettings } from './use-public-rundown';

const publicRundownRoutes: HelmetRouteObject[] = [
  {
    path: 'rundown',
    children: [
      {
        path: ':publicationId',
        element: <PublicRundownViewer />,
        handle: {
          helmet: <PublicRundownViewerHelmet />,
        },
      },
    ],
  },
];

export default publicRundownRoutes;

function PublicRundownViewerHelmet() {
  const { t } = useTranslation('rundown');
  const { publicationId } = useParams<{ publicationId: string }>();
  invariant(publicationId != null, 'Missing publicationId');

  const rundown = usePublishedRundownSettings(publicationId);

  return <Helmet title={t('viewer.helmet', { title: rundown.eventSettings.eventReference.title })} />;
}
