/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProfessionDto = {
    ARCHIVE_PRODUCER_RESEARCHER: 'ARCHIVE_PRODUCER_RESEARCHER',
    AUDIENCE_DESIGN: 'AUDIENCE_DESIGN',
    CASTING_DIRECTOR: 'CASTING_DIRECTOR',
    SCREENWRITER: 'SCREENWRITER',
    ACQUISITION_BUYER: 'ACQUISITION_BUYER',
    DEVELOPMENT: 'DEVELOPMENT',
    SCORE: 'SCORE',
    COMMISSIONING_EDITOR: 'COMMISSIONING_EDITOR',
    CINEMATOGRAPHY: 'CINEMATOGRAPHY',
    COSTUME_MAKEUP: 'COSTUME_MAKEUP',
    ARTIST: 'ARTIST',
    CURATOR_FILM_PROGRAMMER: 'CURATOR_FILM_PROGRAMMER',
    MANAGEMENT: 'MANAGEMENT',
    MARKETING_COMMUNICATION_PROMOTION: 'MARKETING_COMMUNICATION_PROMOTION',
    POLITICIAN: 'POLITICIAN',
    PRESS_PHOTOGRAPHER: 'PRESS_PHOTOGRAPHER',
    PRESS_JOURNALIST: 'PRESS_JOURNALIST',
    PRESS_PR_AGENT: 'PRESS_PR_AGENT',
    PRESS_TV_TECHNICIAN: 'PRESS_TV_TECHNICIAN',
    PRODUCTION_SET_DESIGNER_ART_DIRECTOR: 'PRODUCTION_SET_DESIGNER_ART_DIRECTOR',
    PRODUCTION_TEAM: 'PRODUCTION_TEAM',
    PRODUCER: 'PRODUCER',
    LEGAL_FINANCE: 'LEGAL_FINANCE',
    ASSISTANT_DIRECTOR: 'ASSISTANT_DIRECTOR',
    DIRECTOR: 'DIRECTOR',
    ACTOR_ACTRESS: 'ACTOR_ACTRESS',
    EDITING: 'EDITING',
    STUDENT: 'STUDENT',
    SOUND: 'SOUND',
    SALES: 'SALES',
    ACADEMIC_TEACHING_STAFF: 'ACADEMIC_TEACHING_STAFF',
    OTHER: 'OTHER'
} as const;
export type ProfessionDto = typeof ProfessionDto[keyof typeof ProfessionDto];


export function ProfessionDtoFromJSON(json: any): ProfessionDto {
    return ProfessionDtoFromJSONTyped(json, false);
}

export function ProfessionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfessionDto {
    return json as ProfessionDto;
}

export function ProfessionDtoToJSON(value?: ProfessionDto | null): any {
    return value as any;
}

