import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { editionIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function EditionSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'EDITION', `${result.resultType} is no instance of EditionSearchResult`);
  const edition = result.result;
  const { t } = useTranslation(['edition', 'common']);

  return (
    <SearchResult
      icon={editionIcon}
      title={edition.name}
      link={`/editions/${edition.id}`}
      score={result.score}
      onClick={onClick}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('edition:edition_type'),
            element: t(`edition:editionTypeOptions.${edition.editionType}`),
          },
          {
            label: t('edition:date_range'),
            element: t('common:format.date_range', { dateRange: edition.dateRange }),
          },
          {
            label: t('edition:festival_range'),
            element: t('common:format.date_range', { dateRange: edition.festivalDateRange }),
          },
        ]}
      />
    </SearchResult>
  );
}
