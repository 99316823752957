import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useEventPermissionClassGroupExtension(): PermissionClassGroupExtension<'EVENT'> {
  const { t } = useTranslation(['event']);

  return {
    name: 'EVENT',
    label: t('event:event'),
    getActionLabel: (action) => t(`event:permission.${action}`),
    getAdditionalRequirement: (action) => t(`event:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`event:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
