import useCallbackRef from '../use-callback-ref/use-callback-ref';

export interface Dispatch<TAction> {
  (action: TAction): void;
}

export default function useControlledReducer<TState, TAction>(
  reducer: (state: TState, action: TAction) => TState,
  state: TState,
  callback: (state: TState) => void,
): Dispatch<TAction> {
  return useCallbackRef((action: TAction) => {
    callback(reducer(state, action));
  });
}
