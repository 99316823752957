/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PublicEventReferenceDto } from './PublicEventReferenceDto';
import {
    PublicEventReferenceDtoFromJSON,
    PublicEventReferenceDtoFromJSONTyped,
    PublicEventReferenceDtoToJSON,
} from './PublicEventReferenceDto';
import type { PublicLocationReferenceDto } from './PublicLocationReferenceDto';
import {
    PublicLocationReferenceDtoFromJSON,
    PublicLocationReferenceDtoFromJSONTyped,
    PublicLocationReferenceDtoToJSON,
} from './PublicLocationReferenceDto';

/**
 * Settings for public live ticketing
 * @export
 * @interface PublishedEventSettingsDto
 */
export interface PublishedEventSettingsDto {
    /**
     * 
     * @type {PublicEventReferenceDto}
     * @memberof PublishedEventSettingsDto
     */
    eventReference: PublicEventReferenceDto;
    /**
     * 
     * @type {PublicLocationReferenceDto}
     * @memberof PublishedEventSettingsDto
     */
    locationReference: PublicLocationReferenceDto;
}

/**
 * Check if a given object implements the PublishedEventSettingsDto interface.
 */
export function instanceOfPublishedEventSettingsDto(value: object): boolean {
    if (!('eventReference' in value)) return false;
    if (!('locationReference' in value)) return false;
    return true;
}

export function PublishedEventSettingsDtoFromJSON(json: any): PublishedEventSettingsDto {
    return PublishedEventSettingsDtoFromJSONTyped(json, false);
}

export function PublishedEventSettingsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublishedEventSettingsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'eventReference': PublicEventReferenceDtoFromJSON(json['eventReference']),
        'locationReference': PublicLocationReferenceDtoFromJSON(json['locationReference']),
    };
}

export function PublishedEventSettingsDtoToJSON(value?: PublishedEventSettingsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'eventReference': PublicEventReferenceDtoToJSON(value['eventReference']),
        'locationReference': PublicLocationReferenceDtoToJSON(value['locationReference']),
    };
}

