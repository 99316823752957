/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const StandCategoryDto = {
    SUITE: 'SUITE',
    OFFICE: 'OFFICE',
    POP_UP_OFFICE: 'POP_UP_OFFICE',
    STAND: 'STAND',
    TABLE: 'TABLE',
    MEETING_SPACE: 'MEETING_SPACE'
} as const;
export type StandCategoryDto = typeof StandCategoryDto[keyof typeof StandCategoryDto];


export function StandCategoryDtoFromJSON(json: any): StandCategoryDto {
    return StandCategoryDtoFromJSONTyped(json, false);
}

export function StandCategoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandCategoryDto {
    return json as StandCategoryDto;
}

export function StandCategoryDtoToJSON(value?: StandCategoryDto | null): any {
    return value as any;
}

