import { OccupationFormatConfirmationStatusDto } from '../../../api';
import { createStatusDisplayOptions } from '../../../ui/status-display-options/status-display-options';

export const CONFIRMATION_STATUS_OPTIONS = [
  OccupationFormatConfirmationStatusDto.CONFIRMED,
  OccupationFormatConfirmationStatusDto.UNCONFIRMED,
];

export const STATUS_DISPLAY_OPTIONS = createStatusDisplayOptions<OccupationFormatConfirmationStatusDto>(
  OccupationFormatConfirmationStatusDto.CONFIRMED,
  OccupationFormatConfirmationStatusDto.UNCONFIRMED,
);
