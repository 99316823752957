import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useMailingTemplatePermissionClassGroupExtension(): PermissionClassGroupExtension<'MAILING_TEMPLATE'> {
  const { t } = useTranslation(['mailing_template']);

  return {
    name: 'MAILING_TEMPLATE',
    label: t('mailing_template:mailingTemplate'),
    getActionLabel: (action) => t(`mailing_template:permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`mailing_template:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`mailing_template:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
