import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { RefObject } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AwardDto, AwardWinnerRelationDto, EditionDto, PersonReferenceDto } from '../../../../api';
import awardWinnerApi from '../../../../data-access/award-winner-api';
import FormControl from '../../../../ui/form/form-control';
import { awardIcon, personIcon } from '../../../../ui/icons/business-objects';
import PersonSelectControl from '../../../common/form/person-select-control/person-select-control';
import ConnectionLine from '../../common/connection-line';

export default function AwardWinnerEditorForm({
  award,
  edition,
  initialFocusRef,
}: {
  award: AwardDto;
  edition: EditionDto;
  initialFocusRef?: RefObject<HTMLInputElement>;
}) {
  const { t } = useTranslation('award');
  const { register } = useFormContext<AwardWinnerRelationDto>();
  register('id');
  register('edition');

  const validateNoDuplicate = React.useCallback(
    async (winner: PersonReferenceDto) => {
      if (winner == null) {
        return true;
      }
      const page = await awardWinnerApi.searchAwardWinnerRelations({
        filter: [`award.id,eq,${award.id}`, `winner.id,eq,${winner.id}`, `edition.id,eq,${edition.id}`],
      });

      return page.totalElements === 0 || t('connections.duplicateWinnerMessage');
    },
    [award.id, edition.id, t],
  );

  return (
    <Stack spacing={2} backgroundColor="background.highlight" pr={2} py={2} pl={3} borderRadius="base">
      <Flex position="relative" align="center">
        <Box as={FontAwesomeIcon} icon={awardIcon} fixedWidth flexShrink={0} />
        <Box ml={3} aria-label={t('connections.fromAward')}>
          <Text as="span" fontWeight="medium">
            {award.title}
          </Text>
        </Box>
      </Flex>
      <Flex align="center" position="relative">
        <ConnectionLine position="top" showArrow spacing={2} />
        <Box as={FontAwesomeIcon} icon={personIcon} fixedWidth flexShrink={0} />
        <Box width="full" ml={3}>
          <FormControl name="winner">
            <PersonSelectControl
              size="sm"
              name="winner"
              ref={initialFocusRef}
              aria-label={t('connections.toWinner')}
              label={t('connections.winner')}
              rules={{ validate: validateNoDuplicate }}
            />
          </FormControl>
        </Box>
      </Flex>
    </Stack>
  );
}
