/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Field of interest of the company, at the moment same as of person but may differ in the future
 * @export
 */
export const FieldOfInterestDto = {
    ACTION: 'ACTION',
    ADULT: 'ADULT',
    ADVANCED_TRAINING: 'ADVANCED_TRAINING',
    ADVENTURE: 'ADVENTURE',
    ANIMATION: 'ANIMATION',
    ARAB_CINEMA: 'ARAB_CINEMA',
    ARTHOUSE: 'ARTHOUSE',
    BOLLYWOOD: 'BOLLYWOOD',
    CHILDRENS_FILM: 'CHILDRENS_FILM',
    COMEDY: 'COMEDY',
    CRIME: 'CRIME',
    DATA_ANALYST: 'DATA_ANALYST',
    DOCUMENTARY_FORM: 'DOCUMENTARY_FORM',
    DRAMA: 'DRAMA',
    EUROPEAN_CINEMA: 'EUROPEAN_CINEMA',
    EXPERIMENTAL: 'EXPERIMENTAL',
    FANTASY: 'FANTASY',
    FICTION: 'FICTION',
    FINANCING: 'FINANCING',
    FUNDING: 'FUNDING',
    GREEN_PRODUCTION: 'GREEN_PRODUCTION',
    HISTORICAL: 'HISTORICAL',
    HORROR: 'HORROR',
    INDIGENOUS: 'INDIGENOUS',
    INTERNATIONAL_CO_PRODUCTION: 'INTERNATIONAL_CO_PRODUCTION',
    MUSIC_VIDEO: 'MUSIC_VIDEO',
    MUSICAL_AND_DANCE: 'MUSICAL_AND_DANCE',
    NEW_TECH: 'NEW_TECH',
    QUEER: 'QUEER',
    ROMANCE: 'ROMANCE',
    SATIRE: 'SATIRE',
    SCIENCE_FICTION: 'SCIENCE_FICTION',
    SERIES: 'SERIES',
    SHORT_FORM: 'SHORT_FORM',
    SHOWRUNNER: 'SHOWRUNNER',
    THRILLER: 'THRILLER',
    US_INDIES: 'US_INDIES',
    WESTERN: 'WESTERN',
    WRITERS_ROOM: 'WRITERS_ROOM'
} as const;
export type FieldOfInterestDto = typeof FieldOfInterestDto[keyof typeof FieldOfInterestDto];


export function FieldOfInterestDtoFromJSON(json: any): FieldOfInterestDto {
    return FieldOfInterestDtoFromJSONTyped(json, false);
}

export function FieldOfInterestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldOfInterestDto {
    return json as FieldOfInterestDto;
}

export function FieldOfInterestDtoToJSON(value?: FieldOfInterestDto | null): any {
    return value as any;
}

