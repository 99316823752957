/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GroupStatusDto = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
} as const;
export type GroupStatusDto = typeof GroupStatusDto[keyof typeof GroupStatusDto];


export function GroupStatusDtoFromJSON(json: any): GroupStatusDto {
    return GroupStatusDtoFromJSONTyped(json, false);
}

export function GroupStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupStatusDto {
    return json as GroupStatusDto;
}

export function GroupStatusDtoToJSON(value?: GroupStatusDto | null): any {
    return value as any;
}

