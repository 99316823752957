/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  CreatePlacementCommandDto,
  FailureDto,
  HistoryPageDto,
  PageableDto,
  PlacementDto,
  PrintSeatDetailsDto,
  SeatAssignmentDto,
  UpdateSeatAssignmentsCommandDto,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    CreatePlacementCommandDtoFromJSON,
    CreatePlacementCommandDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    PlacementDtoFromJSON,
    PlacementDtoToJSON,
    PrintSeatDetailsDtoFromJSON,
    PrintSeatDetailsDtoToJSON,
    SeatAssignmentDtoFromJSON,
    SeatAssignmentDtoToJSON,
    UpdateSeatAssignmentsCommandDtoFromJSON,
    UpdateSeatAssignmentsCommandDtoToJSON,
} from '../models/index';

export interface CreatePlacementRequest {
    parentId: string;
    createPlacementCommandDto: CreatePlacementCommandDto;
}

export interface CreateSeatAssignmentRequest {
    parentId: string;
    seatId: string;
    seatAssignmentDto: SeatAssignmentDto;
}

export interface DeletePlacementRequest {
    parentId: string;
}

export interface DeleteSeatAssignmentRequest {
    parentId: string;
    seatId: string;
}

export interface ExportPrintDetailsRequest {
    parentId: string;
    requestBody: Array<string>;
}

export interface FetchPlacementRequest {
    parentId: string;
}

export interface FetchPlacementHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface HasSeatAssignmentRequest {
    allocationId: string;
}

export interface UpdateSeatAssignmentsRequest {
    parentId: string;
    updateSeatAssignmentsCommandDto: UpdateSeatAssignmentsCommandDto;
}

/**
 * 
 */
export class PlacementApi extends runtime.BaseAPI {

    /**
     * Create a new placement.
     */
    async createPlacementRaw(requestParameters: CreatePlacementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlacementDto>> {
        if (requestParameters['parentId'] == null) {
            throw new runtime.RequiredError(
                'parentId',
                'Required parameter "parentId" was null or undefined when calling createPlacement().'
            );
        }

        if (requestParameters['createPlacementCommandDto'] == null) {
            throw new runtime.RequiredError(
                'createPlacementCommandDto',
                'Required parameter "createPlacementCommandDto" was null or undefined when calling createPlacement().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{parentId}/placement`.replace(`{${"parentId"}}`, encodeURIComponent(String(requestParameters['parentId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePlacementCommandDtoToJSON(requestParameters['createPlacementCommandDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlacementDtoFromJSON(jsonValue));
    }

    /**
     * Create a new placement.
     */
    async createPlacement(requestParameters: CreatePlacementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlacementDto> {
        const response = await this.createPlacementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new seat assignment.
     */
    async createSeatAssignmentRaw(requestParameters: CreateSeatAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['parentId'] == null) {
            throw new runtime.RequiredError(
                'parentId',
                'Required parameter "parentId" was null or undefined when calling createSeatAssignment().'
            );
        }

        if (requestParameters['seatId'] == null) {
            throw new runtime.RequiredError(
                'seatId',
                'Required parameter "seatId" was null or undefined when calling createSeatAssignment().'
            );
        }

        if (requestParameters['seatAssignmentDto'] == null) {
            throw new runtime.RequiredError(
                'seatAssignmentDto',
                'Required parameter "seatAssignmentDto" was null or undefined when calling createSeatAssignment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{parentId}/placement/seat-assignment/{seatId}`.replace(`{${"parentId"}}`, encodeURIComponent(String(requestParameters['parentId']))).replace(`{${"seatId"}}`, encodeURIComponent(String(requestParameters['seatId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SeatAssignmentDtoToJSON(requestParameters['seatAssignmentDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new seat assignment.
     */
    async createSeatAssignment(requestParameters: CreateSeatAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createSeatAssignmentRaw(requestParameters, initOverrides);
    }

    /**
     * Delete the placement
     */
    async deletePlacementRaw(requestParameters: DeletePlacementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['parentId'] == null) {
            throw new runtime.RequiredError(
                'parentId',
                'Required parameter "parentId" was null or undefined when calling deletePlacement().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{parentId}/placement`.replace(`{${"parentId"}}`, encodeURIComponent(String(requestParameters['parentId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the placement
     */
    async deletePlacement(requestParameters: DeletePlacementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePlacementRaw(requestParameters, initOverrides);
    }

    /**
     * Delete the seat assignment
     */
    async deleteSeatAssignmentRaw(requestParameters: DeleteSeatAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['parentId'] == null) {
            throw new runtime.RequiredError(
                'parentId',
                'Required parameter "parentId" was null or undefined when calling deleteSeatAssignment().'
            );
        }

        if (requestParameters['seatId'] == null) {
            throw new runtime.RequiredError(
                'seatId',
                'Required parameter "seatId" was null or undefined when calling deleteSeatAssignment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{parentId}/placement/seat-assignment/{seatId}`.replace(`{${"parentId"}}`, encodeURIComponent(String(requestParameters['parentId']))).replace(`{${"seatId"}}`, encodeURIComponent(String(requestParameters['seatId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the seat assignment
     */
    async deleteSeatAssignment(requestParameters: DeleteSeatAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSeatAssignmentRaw(requestParameters, initOverrides);
    }

    /**
     * Export the seats to print
     */
    async exportPrintDetailsRaw(requestParameters: ExportPrintDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PrintSeatDetailsDto>>> {
        if (requestParameters['parentId'] == null) {
            throw new runtime.RequiredError(
                'parentId',
                'Required parameter "parentId" was null or undefined when calling exportPrintDetails().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling exportPrintDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{parentId}/placement/print`.replace(`{${"parentId"}}`, encodeURIComponent(String(requestParameters['parentId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PrintSeatDetailsDtoFromJSON));
    }

    /**
     * Export the seats to print
     */
    async exportPrintDetails(requestParameters: ExportPrintDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PrintSeatDetailsDto>> {
        const response = await this.exportPrintDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the placement.
     */
    async fetchPlacementRaw(requestParameters: FetchPlacementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlacementDto>> {
        if (requestParameters['parentId'] == null) {
            throw new runtime.RequiredError(
                'parentId',
                'Required parameter "parentId" was null or undefined when calling fetchPlacement().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{parentId}/placement`.replace(`{${"parentId"}}`, encodeURIComponent(String(requestParameters['parentId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlacementDtoFromJSON(jsonValue));
    }

    /**
     * Get the placement.
     */
    async fetchPlacement(requestParameters: FetchPlacementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlacementDto | null | undefined > {
        const response = await this.fetchPlacementRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Get the history of the placement.
     */
    async fetchPlacementHistoryRaw(requestParameters: FetchPlacementHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchPlacementHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/placement/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the placement.
     */
    async fetchPlacementHistory(requestParameters: FetchPlacementHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchPlacementHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get whether the specified allocation is assigned to a seat.
     */
    async hasSeatAssignmentRaw(requestParameters: HasSeatAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        if (requestParameters['allocationId'] == null) {
            throw new runtime.RequiredError(
                'allocationId',
                'Required parameter "allocationId" was null or undefined when calling hasSeatAssignment().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['allocationId'] != null) {
            queryParameters['allocationId'] = requestParameters['allocationId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/placement/has-seat-assignment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     * Get whether the specified allocation is assigned to a seat.
     */
    async hasSeatAssignment(requestParameters: HasSeatAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.hasSeatAssignmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the seat assignments.
     */
    async updateSeatAssignmentsRaw(requestParameters: UpdateSeatAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['parentId'] == null) {
            throw new runtime.RequiredError(
                'parentId',
                'Required parameter "parentId" was null or undefined when calling updateSeatAssignments().'
            );
        }

        if (requestParameters['updateSeatAssignmentsCommandDto'] == null) {
            throw new runtime.RequiredError(
                'updateSeatAssignmentsCommandDto',
                'Required parameter "updateSeatAssignmentsCommandDto" was null or undefined when calling updateSeatAssignments().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{parentId}/placement/seat-assignment`.replace(`{${"parentId"}}`, encodeURIComponent(String(requestParameters['parentId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSeatAssignmentsCommandDtoToJSON(requestParameters['updateSeatAssignmentsCommandDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the seat assignments.
     */
    async updateSeatAssignments(requestParameters: UpdateSeatAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateSeatAssignmentsRaw(requestParameters, initOverrides);
    }

}
