/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * An email address of a person
 * @export
 * @interface DefaultEmailAddressDto
 */
export interface DefaultEmailAddressDto {
    /**
     * An email address
     * @type {string}
     * @memberof DefaultEmailAddressDto
     */
    email: string;
    /**
     * Can the information be published?
     * @type {boolean}
     * @memberof DefaultEmailAddressDto
     */
    publish?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof DefaultEmailAddressDto
     */
    timestamp?: Date;
}

/**
 * Check if a given object implements the DefaultEmailAddressDto interface.
 */
export function instanceOfDefaultEmailAddressDto(value: object): boolean {
    if (!('email' in value)) return false;
    return true;
}

export function DefaultEmailAddressDtoFromJSON(json: any): DefaultEmailAddressDto {
    return DefaultEmailAddressDtoFromJSONTyped(json, false);
}

export function DefaultEmailAddressDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DefaultEmailAddressDto {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'publish': json['publish'] == null ? undefined : json['publish'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
    };
}

export function DefaultEmailAddressDtoToJSON(value?: DefaultEmailAddressDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'publish': value['publish'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
    };
}

