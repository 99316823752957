import { Flex, ListItem, Stack, Text, UnorderedList } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { CompanyReferenceDto, EventDto, EventPartnerDtoPartnerTypeEnum } from '../../../api';
import companyApi from '../../../data-access/company-api';
import FormControl from '../../../ui/form/form-control';
import MultiValueAsyncSelectFormControl from '../../../ui/form/select-control/multi-value-async-select-form-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import useWatchChange from '../../../ui/form/use-watch-change/use-watch-change';
import HelperPopover from '../../../ui/helper-buttons/helper-popover';

export default function EventPartnerControl() {
  const { t } = useTranslation('event');
  const partnerType = useWatch<EventDto, 'partners.partnerType'>({ name: 'partners.partnerType' });
  const { setValue, trigger, resetField } = useFormContext();
  const [type, setType] = React.useState(partnerType);

  React.useEffect(() => {
    const trig = async () => {
      await trigger('partners.companies');
    };

    if (partnerType == null) {
      //vb: validation of companies must be triggered manually, otherwise field stays invalid
      trig().catch(console.error);
      resetField('partners.partnerType', { defaultValue: null });
    }
  }, [partnerType, resetField, trigger]);

  useWatchChange<EventDto>(['partners.partnerType'], async (data: EventDto) => {
    setType(data.partners?.partnerType);
    if (data.partners?.partnerType == null) {
      setValue('partners.companies', [], { shouldValidate: true, shouldDirty: true });
    }
  });

  return (
    <Stack width="100%">
      <Flex>
        <Text fontWeight="medium">{t('partner.title')}</Text>
        <HelperPopover>
          <Trans t={t} i18nKey="partner.helper_text" components={{ ul: <UnorderedList />, li: <ListItem /> }} />
        </HelperPopover>
      </Flex>
      <Stack borderWidth="1px" borderRadius="base" p={4} pt={2} spacing={4} width="100%" borderColor="border.01">
        <FormControl label={t('partner.type')} name="partners.partnerType" size="sm">
          <ValueSelectControl
            options={[EventPartnerDtoPartnerTypeEnum.PARTNER_EVENT, EventPartnerDtoPartnerTypeEnum.THIRD_PARTY_EVENT]}
            renderLabel={(option) => t(`partner.labels.${option}`)}
            name="partners.partnerType"
          />
        </FormControl>

        <MultiValueAsyncSelectFormControl<CompanyReferenceDto>
          name="partners.companies"
          label={t('partner.company')}
          isRequired={type != null}
          size="sm"
          loadOptions={async (value: string, size: number) => {
            const { content } = await companyApi.searchActiveCompanyReferences({
              pageable: { size, sort: ['name,ASC'] },
              q: value,
            });

            return content.map(({ name, id }) => {
              invariant(id != null);
              return {
                id,
                name,
              };
            });
          }}
          defaultOptions={false}
          renderLabel={(company) => company.name}
          optionIdentifier={(company) => company.id}
          isDisabled={partnerType == null}
          rules={{
            validate: (values) => {
              if (type != null) {
                return (values != null && !isEmpty(values)) || t('partner.validation');
              } else {
                return values == null || isEmpty(values) || t('partner.validation');
              }
            },
          }}
        />
      </Stack>
    </Stack>
  );
}
