import React from 'react';
import { useParams } from 'react-router-dom';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchList } from '../list-queries';
import { getListId } from './get-list-id';

export default function ListBreadcrumb() {
  const list = useFetcher(fetchList, { id: getListId(useParams()) });

  return <>{list.name}</>;
}
