import React from 'react';
import { FormatReferenceDto } from '../../../api';
import { formatIcon } from '../../../ui/icons/business-objects';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';

interface FormatReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  format: FormatReferenceDto;
}

export default function FormatReference({ format, ...props }: FormatReferenceProps) {
  return (
    <Reference icon={formatIcon} to={'/formats/' + format.id} necessaryPermission="FORMAT:CAN_SEE_DETAILS" {...props}>
      {format.name}
    </Reference>
  );
}
