/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EventReferenceDto } from './EventReferenceDto';
import {
    EventReferenceDtoFromJSON,
    EventReferenceDtoFromJSONTyped,
    EventReferenceDtoToJSON,
} from './EventReferenceDto';

/**
 * Connection to another Event
 * @export
 * @interface EventEventConnectionDto
 */
export interface EventEventConnectionDto {
    /**
     * 
     * @type {EventReferenceDto}
     * @memberof EventEventConnectionDto
     */
    connectedEvent: EventReferenceDto;
}

/**
 * Check if a given object implements the EventEventConnectionDto interface.
 */
export function instanceOfEventEventConnectionDto(value: object): boolean {
    if (!('connectedEvent' in value)) return false;
    return true;
}

export function EventEventConnectionDtoFromJSON(json: any): EventEventConnectionDto {
    return EventEventConnectionDtoFromJSONTyped(json, false);
}

export function EventEventConnectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventEventConnectionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'connectedEvent': EventReferenceDtoFromJSON(json['connectedEvent']),
    };
}

export function EventEventConnectionDtoToJSON(value?: EventEventConnectionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'connectedEvent': EventReferenceDtoToJSON(value['connectedEvent']),
    };
}

