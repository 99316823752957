/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TagStatusDto } from './TagStatusDto';
import {
    TagStatusDtoFromJSON,
    TagStatusDtoFromJSONTyped,
    TagStatusDtoToJSON,
} from './TagStatusDto';
import type { TagTypeDto } from './TagTypeDto';
import {
    TagTypeDtoFromJSON,
    TagTypeDtoFromJSONTyped,
    TagTypeDtoToJSON,
} from './TagTypeDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * Organisational representation of grouped interests and persons
 * @export
 * @interface TagDto
 */
export interface TagDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof TagDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof TagDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof TagDto
     */
    name: string;
    /**
     * 
     * @type {TagTypeDto}
     * @memberof TagDto
     */
    type: TagTypeDto;
    /**
     * 
     * @type {TagStatusDto}
     * @memberof TagDto
     */
    status: TagStatusDto;
}

/**
 * Check if a given object implements the TagDto interface.
 */
export function instanceOfTagDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('type' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function TagDtoFromJSON(json: any): TagDto {
    return TagDtoFromJSONTyped(json, false);
}

export function TagDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'name': json['name'],
        'type': TagTypeDtoFromJSON(json['type']),
        'status': TagStatusDtoFromJSON(json['status']),
    };
}

export function TagDtoToJSON(value?: TagDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'name': value['name'],
        'type': TagTypeDtoToJSON(value['type']),
        'status': TagStatusDtoToJSON(value['status']),
    };
}

