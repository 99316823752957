import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
} from '@chakra-ui/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { GroupStaffRelationDto } from '../../../../../api';

interface GroupStaffAdminToggleDialogProps extends Pick<AlertDialogProps, 'isOpen' | 'onClose'> {
  groupStaffRelation: GroupStaffRelationDto;
  isAdmin: boolean;

  onToggle(): void;
}

export default function GroupStaffAdminToggleDialog({
  groupStaffRelation,
  isAdmin,
  onToggle,
  onClose,
  ...props
}: GroupStaffAdminToggleDialogProps) {
  const closeButtonRef = React.useRef<HTMLButtonElement>(null);
  const { t } = useTranslation(['group', 'common']);

  return (
    <AlertDialog {...props} onClose={onClose} leastDestructiveRef={closeButtonRef}>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>
          {isAdmin ? t('group:internalGroupAdmins.actions.remove') : t('group:internalGroupAdmins.actions.add')}
        </AlertDialogHeader>
        <AlertDialogBody>
          <Trans
            t={t}
            i18nKey={
              isAdmin ? 'group:internalGroupAdmins.dialog.removeMessage' : 'group:internalGroupAdmins.dialog.addMessage'
            }
            values={{
              name: `${groupStaffRelation.staff.firstName} ${groupStaffRelation.staff.surname}`,
            }}
          />
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button onClick={onClose} mr={3} ref={closeButtonRef}>
            {t('common:action.abort')}
          </Button>
          <Button onClick={onToggle} variant={isAdmin ? 'solid' : 'primary'} colorScheme={isAdmin ? 'red' : 'teal'}>
            {isAdmin ? t('group:internalGroupAdmins.actions.remove') : t('group:internalGroupAdmins.actions.add')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
