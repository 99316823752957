/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormatPermissionDto
 */
export interface FormatPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof FormatPermissionDto
     */
    name: FormatPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof FormatPermissionDto
     */
    action: FormatPermissionDtoActionEnum;
}


/**
 * @export
 */
export const FormatPermissionDtoNameEnum = {
    FORMAT: 'FORMAT'
} as const;
export type FormatPermissionDtoNameEnum = typeof FormatPermissionDtoNameEnum[keyof typeof FormatPermissionDtoNameEnum];

/**
 * @export
 */
export const FormatPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    EDIT: 'CAN_EDIT',
    CREATE: 'CAN_CREATE',
    DELETE: 'CAN_DELETE',
    MERGE: 'CAN_MERGE',
    LINK_FORMAT_WITH_PERSON: 'CAN_LINK_FORMAT_WITH_PERSON'
} as const;
export type FormatPermissionDtoActionEnum = typeof FormatPermissionDtoActionEnum[keyof typeof FormatPermissionDtoActionEnum];


/**
 * Check if a given object implements the FormatPermissionDto interface.
 */
export function instanceOfFormatPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function FormatPermissionDtoFromJSON(json: any): FormatPermissionDto {
    return FormatPermissionDtoFromJSONTyped(json, false);
}

export function FormatPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormatPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function FormatPermissionDtoToJSON(value?: FormatPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

