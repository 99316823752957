import React from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import AccountBreadcrumb from './common/account-breadcrumb';

const accountRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'accounts',
    handle: {
      breadcrumb: <Translate ns="account" i18nKey="accounts" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./account-lister/account-lister-route'),
      },
      {
        path: 'new/:personId',
        lazy: () => import('./account-editor/new-account-route'),
      },
      {
        path: ':accountId',
        handle: {
          breadcrumb: <AccountBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./account-page/account-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./account-viewer/account-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./account-history/account-history-route'),
              },
            ],
          },
        ],
      },
    ],
  },
];

export default accountRoutes;
