/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateTimeRangeDto } from './ClosedDateTimeRangeDto';
import {
    ClosedDateTimeRangeDtoFromJSON,
    ClosedDateTimeRangeDtoFromJSONTyped,
    ClosedDateTimeRangeDtoToJSON,
} from './ClosedDateTimeRangeDto';

/**
 * 
 * @export
 * @interface PriceDto
 */
export interface PriceDto {
    /**
     * The price of the product in euro cents excluding taxes.
     * @type {number}
     * @memberof PriceDto
     */
    amount: number;
    /**
     * 
     * @type {ClosedDateTimeRangeDto}
     * @memberof PriceDto
     */
    availability: ClosedDateTimeRangeDto;
    /**
     * 
     * @type {number}
     * @memberof PriceDto
     */
    taxRate: number;
}

/**
 * Check if a given object implements the PriceDto interface.
 */
export function instanceOfPriceDto(value: object): boolean {
    if (!('amount' in value)) return false;
    if (!('availability' in value)) return false;
    if (!('taxRate' in value)) return false;
    return true;
}

export function PriceDtoFromJSON(json: any): PriceDto {
    return PriceDtoFromJSONTyped(json, false);
}

export function PriceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'availability': ClosedDateTimeRangeDtoFromJSON(json['availability']),
        'taxRate': json['taxRate'],
    };
}

export function PriceDtoToJSON(value?: PriceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'amount': value['amount'],
        'availability': ClosedDateTimeRangeDtoToJSON(value['availability']),
        'taxRate': value['taxRate'],
    };
}

