/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface SeatTemplateDto
 */
export interface SeatTemplateDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof SeatTemplateDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof SeatTemplateDto
     */
    version?: VersionDto;
    /**
     * The area this seat belongs to.
     * @type {string}
     * @memberof SeatTemplateDto
     */
    area: string;
    /**
     * The row this seat belongs to.
     * @type {string}
     * @memberof SeatTemplateDto
     */
    row: string;
    /**
     * The seat number.
     * @type {string}
     * @memberof SeatTemplateDto
     */
    seat: string;
    /**
     * True if the seat is locked for placement
     * @type {boolean}
     * @memberof SeatTemplateDto
     */
    locked: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SeatTemplateDto
     */
    specialMarkers?: Array<SeatTemplateDtoSpecialMarkersEnum>;
    /**
     * Defines the position of an element in the format [x,y].
     * @type {Array<number>}
     * @memberof SeatTemplateDto
     */
    position: Array<number>;
}


/**
 * @export
 */
export const SeatTemplateDtoSpecialMarkersEnum = {
    EXTRA_LEGROOM: 'EXTRA_LEGROOM',
    TWIN_SEAT: 'TWIN_SEAT',
    FILM_TEAM: 'FILM_TEAM',
    JURY: 'JURY',
    HOST: 'HOST',
    AWARD_RECIPIENT: 'AWARD_RECIPIENT',
    WHEELCHAIR_SPACE: 'WHEELCHAIR_SPACE',
    RESTRICTED_VIEW: 'RESTRICTED_VIEW',
    DOCTOR: 'DOCTOR'
} as const;
export type SeatTemplateDtoSpecialMarkersEnum = typeof SeatTemplateDtoSpecialMarkersEnum[keyof typeof SeatTemplateDtoSpecialMarkersEnum];


/**
 * Check if a given object implements the SeatTemplateDto interface.
 */
export function instanceOfSeatTemplateDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('area' in value)) return false;
    if (!('row' in value)) return false;
    if (!('seat' in value)) return false;
    if (!('locked' in value)) return false;
    if (!('position' in value)) return false;
    return true;
}

export function SeatTemplateDtoFromJSON(json: any): SeatTemplateDto {
    return SeatTemplateDtoFromJSONTyped(json, false);
}

export function SeatTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeatTemplateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'area': json['area'],
        'row': json['row'],
        'seat': json['seat'],
        'locked': json['locked'],
        'specialMarkers': json['specialMarkers'] == null ? undefined : json['specialMarkers'],
        'position': json['position'],
    };
}

export function SeatTemplateDtoToJSON(value?: SeatTemplateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'area': value['area'],
        'row': value['row'],
        'seat': value['seat'],
        'locked': value['locked'],
        'specialMarkers': value['specialMarkers'],
        'position': value['position'],
    };
}

