import { Button, Stack } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FunctionDto,
  GroupDto,
  StaffDto,
  StaffListItemDto,
  StaffReferenceDto,
  StaffToAddDto,
} from '../../../../../api';
import groupStaffApi from '../../../../../data-access/group-staff-api';
import staffApi from '../../../../../data-access/staff-api';
import FormControl from '../../../../../ui/form/form-control';
import ValueAsyncSelectControl from '../../../../../ui/form/select-control/value-async-select-control';
import ValueSelectControl from '../../../../../ui/form/select-control/value-select-control';
import useWatchChange from '../../../../../ui/form/use-watch-change/use-watch-change';

export interface StaffForGroupSelectionControlProps {
  group: GroupDto;
  staffAlreadyInGroup: StaffToAddDto[];
  onAdd: (entry: StaffToAddDto) => void;
  initialFocusRef?: React.RefObject<any>;
}

export default function StaffForGroupSelectionControl({
  group,
  staffAlreadyInGroup,
  onAdd,
  initialFocusRef,
}: StaffForGroupSelectionControlProps) {
  const form = useForm<StaffToAddDto>({ mode: 'onChange' });
  const { t } = useTranslation(['staff', 'group', 'common']);

  const handleAdd = (entry: StaffToAddDto) => {
    if (validateNoLocalDuplicate(entry.staff.id!)) {
      form.resetField('staff', { defaultValue: null });
      form.resetField('staffFunction', { defaultValue: null });
      onAdd(entry);
    }
  };

  const selectedSection = form.watch('section');

  useWatchChange<StaffToAddDto>(
    ['section'],
    (data) => {
      if (data.section) {
        form.resetField('staff', { defaultValue: null });
        form.resetField('staffFunction', { defaultValue: null });
      }
    },
    form,
  );

  const validateNoLocalDuplicate = (staff: string) => {
    const staffId = (staff as unknown as StaffReferenceDto).id;
    return staffAlreadyInGroup.find((e) => e.staff.id === staffId) == null || t('group:connections.duplicate_staff');
  };

  const validateNoRemoteDuplicate = async (staff: StaffDto) => {
    const { value: canAddStaff } = await groupStaffApi.canAddStaffToGroup({
      staffId: staff.id!,
      groupId: group.id,
    });
    return canAddStaff || t('group:connections.duplicate_staff');
  };

  const loadOptions = async (query: string, pageSizeLimit: number) => {
    const page = await staffApi.searchStaff({
      pageable: { size: pageSizeLimit, sort: ['firstName,DESC', 'surname,ASC'] },
      filter: [`belongsToSections[*].id,eq,${selectedSection.id}`],
      q: query,
    });

    return page.content;
  };

  return (
    <FormProvider {...form}>
      <Stack spacing={4} paddingX={4} paddingBottom={4}>
        <FormControl
          name="section"
          isRequired
          variant="transparent"
          label={t('staff:belongs_to_section.section')}
          helperText={t('group:connections.sectionHelperText')}
        >
          <ValueSelectControl
            isRequired
            label={t('staff:belongs_to_section.section')}
            options={group.owners.map((o) => o.section).sort((a, b) => a.name.localeCompare(b.name))}
            renderLabel={(option) => option.name}
            name="section"
            ref={initialFocusRef}
          />
        </FormControl>

        {selectedSection && (
          <Stack>
            <FormControl
              name="staff"
              variant="transparent"
              isRequired
              label={t('group:connections.staffSingle')}
              helperText={t('group:connections.staffHelperText')}
            >
              <ValueAsyncSelectControl<StaffListItemDto>
                aria-label={t('group:connections.staffSingle')}
                label={t('group:connections.staffSingle')}
                loadOptions={loadOptions}
                renderLabel={(staff) => `${staff.firstName} ${staff.surname}`}
                name="staff"
                getOptionValue={(value) => value.value.id}
                isRequired
                optionIdentifier={(staff) => staff.id!}
                rules={{ validate: { validateNoLocalDuplicate, validateNoRemoteDuplicate } }}
              />
            </FormControl>

            <FormControl name="staffFunction" label={t('group:connections.function')} isRequired variant="transparent">
              <ValueSelectControl<FunctionDto>
                name="staffFunction"
                label={t('group:connections.function')}
                options={[
                  FunctionDto.SENIOR_ASSISTANCE,
                  FunctionDto.JUNIOR_ASSISTANCE,
                  FunctionDto.PRESS_COORDINATION,
                  FunctionDto.PROTOCOL_OFFICER,
                  FunctionDto.BACKUP,
                  FunctionDto.OTHERS,
                ].sort((a, b) =>
                  t(`group:connections.functions.${a}`).localeCompare(t(`group:connections.functions.${b}`)),
                )}
                renderLabel={(type) => t(`group:connections.functions.${type}`)}
                isRequired
              />
            </FormControl>

            <Button
              onClick={form.handleSubmit(handleAdd)}
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
              size="sm"
              variant="transparent"
              isLoading={form.formState.isSubmitting}
              isDisabled={!form.formState.isValid}
            >
              {t('common:action.add')}
            </Button>
          </Stack>
        )}
      </Stack>
    </FormProvider>
  );
}
