/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StandPersonRelationTypeDto } from './StandPersonRelationTypeDto';
import {
    StandPersonRelationTypeDtoFromJSON,
    StandPersonRelationTypeDtoFromJSONTyped,
    StandPersonRelationTypeDtoToJSON,
} from './StandPersonRelationTypeDto';
import type { StandReferenceDto } from './StandReferenceDto';
import {
    StandReferenceDtoFromJSON,
    StandReferenceDtoFromJSONTyped,
    StandReferenceDtoToJSON,
} from './StandReferenceDto';

/**
 * 
 * @export
 * @interface AutomaticStandConnectionDto
 */
export interface AutomaticStandConnectionDto {
    /**
     * 
     * @type {StandReferenceDto}
     * @memberof AutomaticStandConnectionDto
     */
    standConnection: StandReferenceDto;
    /**
     * 
     * @type {StandPersonRelationTypeDto}
     * @memberof AutomaticStandConnectionDto
     */
    type: StandPersonRelationTypeDto;
}

/**
 * Check if a given object implements the AutomaticStandConnectionDto interface.
 */
export function instanceOfAutomaticStandConnectionDto(value: object): boolean {
    if (!('standConnection' in value)) return false;
    if (!('type' in value)) return false;
    return true;
}

export function AutomaticStandConnectionDtoFromJSON(json: any): AutomaticStandConnectionDto {
    return AutomaticStandConnectionDtoFromJSONTyped(json, false);
}

export function AutomaticStandConnectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutomaticStandConnectionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'standConnection': StandReferenceDtoFromJSON(json['standConnection']),
        'type': StandPersonRelationTypeDtoFromJSON(json['type']),
    };
}

export function AutomaticStandConnectionDtoToJSON(value?: AutomaticStandConnectionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'standConnection': StandReferenceDtoToJSON(value['standConnection']),
        'type': StandPersonRelationTypeDtoToJSON(value['type']),
    };
}

