/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddPeopleToGroupDto,
  BooleanDto,
  FailureDto,
  GroupPersonPageDto,
  GroupPersonRelationDto,
  HistoryPageDto,
  PageableDto,
} from '../models/index';
import {
    AddPeopleToGroupDtoFromJSON,
    AddPeopleToGroupDtoToJSON,
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    GroupPersonPageDtoFromJSON,
    GroupPersonPageDtoToJSON,
    GroupPersonRelationDtoFromJSON,
    GroupPersonRelationDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface AddPeopleToGroupRequest {
    addPeopleToGroupDto: AddPeopleToGroupDto;
}

export interface CanAddPersonToGroupRequest {
    groupId: string;
    personId: string;
}

export interface DeleteRelationsByIdsRequest {
    ids: Array<string>;
}

export interface FetchGroupPersonHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchGroupPersonRelationRequest {
    id: string;
}

export interface SearchGroupPersonRelationsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface UpdateGroupPersonRelationRequest {
    id: string;
    groupPersonRelationDto: GroupPersonRelationDto;
}

/**
 * 
 */
export class GroupPersonApi extends runtime.BaseAPI {

    /**
     * Add people to group.
     */
    async addPeopleToGroupRaw(requestParameters: AddPeopleToGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['addPeopleToGroupDto'] == null) {
            throw new runtime.RequiredError(
                'addPeopleToGroupDto',
                'Required parameter "addPeopleToGroupDto" was null or undefined when calling addPeopleToGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-person-relations/add-people`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddPeopleToGroupDtoToJSON(requestParameters['addPeopleToGroupDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add people to group.
     */
    async addPeopleToGroup(requestParameters: AddPeopleToGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addPeopleToGroupRaw(requestParameters, initOverrides);
    }

    /**
     * Get whether the specified person can be added to the group.
     */
    async canAddPersonToGroupRaw(requestParameters: CanAddPersonToGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        if (requestParameters['groupId'] == null) {
            throw new runtime.RequiredError(
                'groupId',
                'Required parameter "groupId" was null or undefined when calling canAddPersonToGroup().'
            );
        }

        if (requestParameters['personId'] == null) {
            throw new runtime.RequiredError(
                'personId',
                'Required parameter "personId" was null or undefined when calling canAddPersonToGroup().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['personId'] != null) {
            queryParameters['personId'] = requestParameters['personId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-person-relations/{groupId}/can-add-person`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters['groupId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     * Get whether the specified person can be added to the group.
     */
    async canAddPersonToGroup(requestParameters: CanAddPersonToGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.canAddPersonToGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteRelationsByIdsRaw(requestParameters: DeleteRelationsByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['ids'] == null) {
            throw new runtime.RequiredError(
                'ids',
                'Required parameter "ids" was null or undefined when calling deleteRelationsByIds().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ids'] != null) {
            queryParameters['ids'] = requestParameters['ids']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-person-relations/delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteRelationsByIds(requestParameters: DeleteRelationsByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteRelationsByIdsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async fetchGroupPersonHistoryRaw(requestParameters: FetchGroupPersonHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchGroupPersonHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-person-relations/versions-by-group/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchGroupPersonHistory(requestParameters: FetchGroupPersonHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchGroupPersonHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the group-person relation
     */
    async fetchGroupPersonRelationRaw(requestParameters: FetchGroupPersonRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupPersonRelationDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchGroupPersonRelation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-person-relations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupPersonRelationDtoFromJSON(jsonValue));
    }

    /**
     * Get the group-person relation
     */
    async fetchGroupPersonRelation(requestParameters: FetchGroupPersonRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupPersonRelationDto> {
        const response = await this.fetchGroupPersonRelationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for group person relations.
     */
    async searchGroupPersonRelationsRaw(requestParameters: SearchGroupPersonRelationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupPersonPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-person-relations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupPersonPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for group person relations.
     */
    async searchGroupPersonRelations(requestParameters: SearchGroupPersonRelationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupPersonPageDto> {
        const response = await this.searchGroupPersonRelationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the existing group-person relation.
     */
    async updateGroupPersonRelationRaw(requestParameters: UpdateGroupPersonRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupPersonRelationDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateGroupPersonRelation().'
            );
        }

        if (requestParameters['groupPersonRelationDto'] == null) {
            throw new runtime.RequiredError(
                'groupPersonRelationDto',
                'Required parameter "groupPersonRelationDto" was null or undefined when calling updateGroupPersonRelation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-person-relations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GroupPersonRelationDtoToJSON(requestParameters['groupPersonRelationDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupPersonRelationDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing group-person relation.
     */
    async updateGroupPersonRelation(requestParameters: UpdateGroupPersonRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupPersonRelationDto> {
        const response = await this.updateGroupPersonRelationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
