import React from 'react';

export default function usePromise<TData>(callback: (signal: AbortSignal) => Promise<TData> | undefined, deps: any[]) {
  const [data, setData] = React.useState<TData>();

  React.useEffect(
    () => {
      const abortController = new AbortController();

      setData(undefined);

      callback(abortController.signal)
        ?.then((data) => {
          setData(data);
        })
        .catch((error) => {
          if (error instanceof DOMException && error.name === 'AbortError') {
            return;
          }

          throw error;
        });

      return () => {
        abortController.abort();
      };
    },
    // lh: We do not use callback as dependency here, as the hook already has a way to explicitly
    // define dependencies.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );

  return data;
}
