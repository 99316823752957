/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AllocationReferenceDto } from './AllocationReferenceDto';
import {
    AllocationReferenceDtoFromJSON,
    AllocationReferenceDtoFromJSONTyped,
    AllocationReferenceDtoToJSON,
} from './AllocationReferenceDto';
import type { CategoryOfParticipationDto } from './CategoryOfParticipationDto';
import {
    CategoryOfParticipationDtoFromJSON,
    CategoryOfParticipationDtoFromJSONTyped,
    CategoryOfParticipationDtoToJSON,
} from './CategoryOfParticipationDto';
import type { GuestTypeDto } from './GuestTypeDto';
import {
    GuestTypeDtoFromJSON,
    GuestTypeDtoFromJSONTyped,
    GuestTypeDtoToJSON,
} from './GuestTypeDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { StatusForParticipationDto } from './StatusForParticipationDto';
import {
    StatusForParticipationDtoFromJSON,
    StatusForParticipationDtoFromJSONTyped,
    StatusForParticipationDtoToJSON,
} from './StatusForParticipationDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * Dto to edit person on the guest list.
 * @export
 * @interface EditPersonOnGuestListDto
 */
export interface EditPersonOnGuestListDto {
    /**
     * 
     * @type {VersionDto}
     * @memberof EditPersonOnGuestListDto
     */
    version: VersionDto;
    /**
     * 
     * @type {GuestTypeDto}
     * @memberof EditPersonOnGuestListDto
     */
    guestType: GuestTypeDto;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof EditPersonOnGuestListDto
     */
    person?: PersonReferenceDto;
    /**
     * 
     * @type {CategoryOfParticipationDto}
     * @memberof EditPersonOnGuestListDto
     */
    category: CategoryOfParticipationDto;
    /**
     * 
     * @type {StatusForParticipationDto}
     * @memberof EditPersonOnGuestListDto
     */
    status: StatusForParticipationDto;
    /**
     * 
     * @type {AllocationReferenceDto}
     * @memberof EditPersonOnGuestListDto
     */
    allocation: AllocationReferenceDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditPersonOnGuestListDto
     */
    labels?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EditPersonOnGuestListDto
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonOnGuestListDto
     */
    number?: string;
    /**
     * The id of the coupling group if person is coupled
     * @type {string}
     * @memberof EditPersonOnGuestListDto
     */
    couplingGroupId?: string;
}

/**
 * Check if a given object implements the EditPersonOnGuestListDto interface.
 */
export function instanceOfEditPersonOnGuestListDto(value: object): boolean {
    if (!('version' in value)) return false;
    if (!('guestType' in value)) return false;
    if (!('category' in value)) return false;
    if (!('status' in value)) return false;
    if (!('allocation' in value)) return false;
    return true;
}

export function EditPersonOnGuestListDtoFromJSON(json: any): EditPersonOnGuestListDto {
    return EditPersonOnGuestListDtoFromJSONTyped(json, false);
}

export function EditPersonOnGuestListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditPersonOnGuestListDto {
    if (json == null) {
        return json;
    }
    return {
        
        'version': VersionDtoFromJSON(json['version']),
        'guestType': GuestTypeDtoFromJSON(json['guestType']),
        'person': json['person'] == null ? undefined : PersonReferenceDtoFromJSON(json['person']),
        'category': CategoryOfParticipationDtoFromJSON(json['category']),
        'status': StatusForParticipationDtoFromJSON(json['status']),
        'allocation': AllocationReferenceDtoFromJSON(json['allocation']),
        'labels': json['labels'] == null ? undefined : json['labels'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'number': json['number'] == null ? undefined : json['number'],
        'couplingGroupId': json['couplingGroupId'] == null ? undefined : json['couplingGroupId'],
    };
}

export function EditPersonOnGuestListDtoToJSON(value?: EditPersonOnGuestListDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'version': VersionDtoToJSON(value['version']),
        'guestType': GuestTypeDtoToJSON(value['guestType']),
        'person': PersonReferenceDtoToJSON(value['person']),
        'category': CategoryOfParticipationDtoToJSON(value['category']),
        'status': StatusForParticipationDtoToJSON(value['status']),
        'allocation': AllocationReferenceDtoToJSON(value['allocation']),
        'labels': value['labels'],
        'comment': value['comment'],
        'number': value['number'],
        'couplingGroupId': value['couplingGroupId'],
    };
}

