import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { BreadcrumbRouteObject } from '../../../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../../../ui/helmet/helmet-outlet';
import useFetcher from '../../../../util/swr/use-fetcher';
import { AccessGroupPage } from '../../../access-group/access-group-page/access-group-page-route';
import { fetchAccessGroup } from '../../../access-group/access-group-queries';
import AccessGroupPersonPageContent from './access-group-person-page-content';

const accessGroupPersonRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: '',
    element: <AccessGroupPage />,
    children: [
      {
        path: 'persons',
        element: <AccessGroupPersonPageContent />,
        handle: {
          helmet: <AccessGroupPersonsHelmet />,
        },
      },
    ],
  },
];

export default accessGroupPersonRoutes;

function AccessGroupPersonsHelmet() {
  const { t } = useTranslation('relations');
  const params = useParams<{ accessGroupId: string }>();
  invariant(params.accessGroupId, 'Empty accessGroupId');
  const accessGroup = useFetcher(fetchAccessGroup, { id: params.accessGroupId });

  return <Helmet title={t('accessGroupToPersonHelmet', { title: accessGroup.title })} />;
}
