import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { HelmetRouteObject } from '../../../ui/helmet/helmet-outlet';
import PublicKeyPlayerListViewer from './public-key-player-list-viewer';
import { usePublicKeyPlayerListSettings } from './use-public-key-players';

const publicKeyPlayersListRoutes: HelmetRouteObject[] = [
  {
    path: 'key-player-list',
    children: [
      {
        path: ':publicationId',
        element: <PublicKeyPlayerListViewer />,
        handle: {
          helmet: <PublicKeyPlayerListHelmet />,
        },
      },
    ],
  },
];

export default publicKeyPlayersListRoutes;

function PublicKeyPlayerListHelmet() {
  const { t } = useTranslation('key_player_list');
  const { publicationId } = useParams<{ publicationId: string }>();
  invariant(publicationId != null, 'Missing publicationId');

  const settings = usePublicKeyPlayerListSettings(publicationId);

  return <Helmet title={t('viewer.helmet', { title: settings.eventSettings.eventReference.title })} />;
}
