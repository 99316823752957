import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormatCompanyRelationDto } from '../../../../api';
import { CompanyReferenceDtoStatusEnum } from '../../../../api/models/CompanyReferenceDto';
import HistoryDisplaySettings from '../../../../ui/history/history-display-settings';

export default function useFormatCompanyHistorySettings(): HistoryDisplaySettings<FormatCompanyRelationDto> {
  const { t } = useTranslation(['format', 'company', 'common']);

  return useMemo<HistoryDisplaySettings<FormatCompanyRelationDto>>(() => {
    return {
      attributeLabels: {
        company: t('company:company'),
        connectionType: t('format:formatCompany.connectionType'),
        validityPeriod: t('format:formatCompany.validityPeriod'),
        status: t('format:formatCompany.status'),
      },
      valueFormatter: {
        company: (value) =>
          value == null
            ? undefined
            : value.status === CompanyReferenceDtoStatusEnum.ARCHIVED
              ? `${value.name} ${t('common:history.business_object_deleted')}`
              : value.name,
        connectionType: (value) => (value == null ? undefined : t(`formatCompany.connectionTypeOptions.${value}`)),
        validityPeriod: (value) => (value == null ? undefined : t('common:format.date_range', { dateRange: value })),
        status: (value) => (value == null ? undefined : t(`formatCompany.statusOptions.${value}`)),
      },
      diffEntireWord: {
        company: true,
        connectionType: true,
        status: true,
      },
      name: () => t('format:connections.companies'),
      showEditDescription: false,
    };
  }, [t]);
}
