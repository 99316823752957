/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PermissionClassGroupPermissionDto
 */
export interface PermissionClassGroupPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof PermissionClassGroupPermissionDto
     */
    name: PermissionClassGroupPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof PermissionClassGroupPermissionDto
     */
    action: PermissionClassGroupPermissionDtoActionEnum;
}


/**
 * @export
 */
export const PermissionClassGroupPermissionDtoNameEnum = {
    PERMISSION_CLASS_GROUP: 'PERMISSION_CLASS_GROUP'
} as const;
export type PermissionClassGroupPermissionDtoNameEnum = typeof PermissionClassGroupPermissionDtoNameEnum[keyof typeof PermissionClassGroupPermissionDtoNameEnum];

/**
 * @export
 */
export const PermissionClassGroupPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    EDIT: 'CAN_EDIT'
} as const;
export type PermissionClassGroupPermissionDtoActionEnum = typeof PermissionClassGroupPermissionDtoActionEnum[keyof typeof PermissionClassGroupPermissionDtoActionEnum];


/**
 * Check if a given object implements the PermissionClassGroupPermissionDto interface.
 */
export function instanceOfPermissionClassGroupPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function PermissionClassGroupPermissionDtoFromJSON(json: any): PermissionClassGroupPermissionDto {
    return PermissionClassGroupPermissionDtoFromJSONTyped(json, false);
}

export function PermissionClassGroupPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionClassGroupPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function PermissionClassGroupPermissionDtoToJSON(value?: PermissionClassGroupPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

