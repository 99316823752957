import { drawerAnatomy } from '@chakra-ui/anatomy';
import { MultiStyleConfig } from '@chakra-ui/theme-tools';

const Drawer: MultiStyleConfig<typeof drawerAnatomy> = {
  parts: drawerAnatomy.keys,
  baseStyle: {
    overlay: { _dark: { bg: 'blackAlpha.700' } },
    header: {
      color: 'header',
      borderBottom: '1px solid',
      borderColor: 'border.01',
      flexBasis: 16,
      bgColor: 'layer.01',
    },
    body: {
      py: 4,
      bgColor: 'layer.01',
    },
    footer: {
      borderTop: '1px solid',
      borderColor: 'border.01',
      bgColor: 'layer.01',
    },
    closeButton: {
      top: 4,
      color: 'text.interactive',
      borderRadius: 'base',
      _hover: {
        color: 'text.interactive.hover',
      },
    },
  },
  sizes: {
    'full-with-overlay': {
      dialog: {
        // Always show an 8-space-unit-margin on the left to be able to click the overlay and close
        // the drawer.
        w: 'min(var(--chakra-sizes-xs), calc(100vw - var(--chakra-space-8))) !important',
      },
    },
  },
};

export default Drawer;
