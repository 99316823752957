import { Box, HStack } from '@chakra-ui/react';
import { get, keys } from 'lodash-es';
import React from 'react';
import { RenderElementProps, RenderLeafProps, useSlate } from 'slate-react';
import invariant from 'tiny-invariant';
import { MarkFormatOption } from './format-types';
import { getMarkRenderers, getRenderer } from './render';

export function Toolbar() {
  const editor = useSlate();

  return editor.toolbarButtons.length === 0 ? null : (
    <Box borderX="1px" borderTop="1px" borderTopRadius="base" borderColor="border.01" p={2} bgColor="layer.01">
      <HStack spacing="5px" wrap="wrap">
        {editor.toolbarButtons.map((button) => button(editor))}
      </HStack>
    </Box>
  );
}

export const Element = (props: RenderElementProps) => {
  const editor = useSlate();
  const render = getRenderer<'block' | 'inline'>(props.element.type, editor);

  invariant(render != null, 'action must be defined for element type, missing action is: ' + props.element.type);

  return render(props);
};

export function Leaf(props: RenderLeafProps) {
  const editor = useSlate();
  const leafActions = keys(props.leaf).filter((key) => get(props.leaf, key) === true) as MarkFormatOption[];
  const renderers = getMarkRenderers(leafActions, editor);
  let children = props.children;

  renderers.forEach((render) => {
    children = render(children);
  });

  return (
    <span
      style={{
        fontSize: 'inherit',
        lineHeight: 'inherit',
        fontFamily: 'inherit',
        color: 'inherit',
      }}
      {...props.attributes}
    >
      {children}
    </span>
  );
}
