import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const eventMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.event" />,
    path: '/events',
    neccessaryPermission: 'EVENT:CAN_SEE_LIST_VIEW',
  },
];

/**
 * Event menu item.
 */
export default eventMenuItems;
