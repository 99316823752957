import React from 'react';
import invariant from 'tiny-invariant';
import { GuestListListItemDto, RecipientLinkDto } from '../../api';
import { DescriptionGroup, DescriptionItem } from '../../ui/description';
import { guestlistIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import EVENT_CHILD_ROUTES from '../event/event-child-routes';
import { EVENT_MENU_ITEM, EVENT_MOBILE_MENU_ITEM, EventMenuItem } from '../event/event-page/event-page-menu';
import { MAILING_MODULE_TYPE_EXTENSION } from '../mailing/mailing-module-type-extension';
import { MAILING_RECIPIENT_EXTENSION } from '../mailing/mailing-recipient-extension';
import { MAILING_TYPE_EXTENSION } from '../mailing/mailing-type-extension';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { MERGE_CONFLICT_PROVIDER_EXTENSION } from '../person/person-merger/merge-conflict-provider-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { useGuestListPermissionClassGroupExtension } from './guest-list-permissions';
import guestListRoutes from './guest-list-routes';
import GuestListSearchResult from './guest-list-search-result';
import { GuestNameControl } from './guest-list-viewer/guest-name-control';
import {
  invitationGuestListEmergencyExtension,
  invitationGuestListPersonalizedExtension,
  invitationGuestListUnpersonalizedExtension,
  ticketGuestListCancelExtension,
  ticketGuestListInitialExtension,
  ticketGuestListInitialWithoutPlacementExtension,
  ticketGuestListReplaceExtension,
} from './mailing/guest-list-mailing-extensions';
import { getMergeConflictMessages } from './person-extensions/guest-list-merge-conflict-provider';

function createGuestListEventMenuItem(isMobile = false): EventMenuItem {
  return {
    order: 2,
    label: isMobile ? (
      <Translate ns="admission" i18nKey="menu_guest_list" />
    ) : (
      <Translate ns="guest_list" i18nKey="guestList" />
    ),
    icon: guestlistIcon,
    path: (eventId) => `/events/${eventId}/guest-list`,
    isVisible: (ownerSectionIds, permissions) =>
      permissions.hasPermission('GUEST_LIST:CAN_SEE_DETAILS') ||
      ownerSectionIds.some((sectionId) =>
        permissions.hasPermissionFromSection(sectionId, 'GUEST_LIST:CAN_SEE_DETAILS_OWN'),
      ),
  };
}

export default function GuestListProvider(registry: PluginRegistry) {
  registry.register(EVENT_MENU_ITEM, createGuestListEventMenuItem());
  registry.register(EVENT_MOBILE_MENU_ITEM, createGuestListEventMenuItem(true));
  registry.register(EVENT_CHILD_ROUTES, guestListRoutes);
  registry.register(MERGE_CONFLICT_PROVIDER_EXTENSION, getMergeConflictMessages);
  registry.register(SEARCH_RESULT_CONTENT, { type: 'GUESTLIST', Component: GuestListSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'GUESTLIST',
    icon: guestlistIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.GUESTLIST" />,
    necessaryPermission: 'GUEST_LIST:CAN_SEE_DETAILS_OWN',
  });
  registry.register(MAILING_RECIPIENT_EXTENSION, {
    type: 'PERSON_ON_GUEST_LIST',
    viewer(link, isInline) {
      invariant(isPersonOnGuestListRecipientLink(link), `Unexpected link type ${link.type}`);
      return <GuestNameControl personOnList={link.linkedRecipient} isInline={isInline} />;
    },
    asString(link) {
      invariant(isPersonOnGuestListRecipientLink(link), `Unexpected link type ${link.type}`);
      return `${link.linkedRecipient.person!.surname}, ${link.linkedRecipient.person!.firstName}`;
    },
  });
  registry.register(MAILING_MODULE_TYPE_EXTENSION, {
    type: 'TICKET',
    moduleControl() {
      return (
        <DescriptionGroup>
          <DescriptionItem span={2} label={<Translate i18nKey="ticket_module.label" ns="mailing" />}>
            <Translate i18nKey="ticket_module.active" ns="mailing" />
          </DescriptionItem>
        </DescriptionGroup>
      );
    },
  });
  registry.register(MAILING_MODULE_TYPE_EXTENSION, {
    type: 'TICKET_WITHOUT_PLACEMENT',
    moduleControl() {
      return (
        <DescriptionGroup>
          <DescriptionItem span={2} label={<Translate i18nKey="ticket_without_placement_module.label" ns="mailing" />}>
            <Translate i18nKey="ticket_without_placement_module.active" ns="mailing" />
          </DescriptionItem>
        </DescriptionGroup>
      );
    },
  });
  registry.register(MAILING_TYPE_EXTENSION, invitationGuestListPersonalizedExtension);
  registry.register(MAILING_TYPE_EXTENSION, invitationGuestListUnpersonalizedExtension);
  registry.register(MAILING_TYPE_EXTENSION, invitationGuestListEmergencyExtension);
  registry.register(MAILING_TYPE_EXTENSION, ticketGuestListInitialExtension);
  registry.register(MAILING_TYPE_EXTENSION, ticketGuestListReplaceExtension);
  registry.register(MAILING_TYPE_EXTENSION, ticketGuestListCancelExtension);
  registry.register(MAILING_TYPE_EXTENSION, ticketGuestListInitialWithoutPlacementExtension);
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useGuestListPermissionClassGroupExtension);
}

export type PersonOnGuestListRecipientLink = Omit<RecipientLinkDto, 'linkedRecipient'> & {
  linkedRecipient: GuestListListItemDto;
};

export function isPersonOnGuestListRecipientLink(link: RecipientLinkDto): link is PersonOnGuestListRecipientLink {
  return link.type === 'PERSON_ON_GUEST_LIST';
}
