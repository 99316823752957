import React from 'react';

// lh: Memorize date by timestamp to be able to shallow compare them.
export default function useMemoDate(date: Date | undefined) {
  const dateRef = React.useRef<Date>();

  if (dateRef.current?.valueOf() !== date?.valueOf()) {
    dateRef.current = date;
  }

  return dateRef.current;
}
