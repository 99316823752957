/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AddPeoplePeopleInnerDto } from './AddPeoplePeopleInnerDto';
import {
    AddPeoplePeopleInnerDtoFromJSON,
    AddPeoplePeopleInnerDtoFromJSONTyped,
    AddPeoplePeopleInnerDtoToJSON,
} from './AddPeoplePeopleInnerDto';
import type { AllocationReferenceDto } from './AllocationReferenceDto';
import {
    AllocationReferenceDtoFromJSON,
    AllocationReferenceDtoFromJSONTyped,
    AllocationReferenceDtoToJSON,
} from './AllocationReferenceDto';
import type { StatusForParticipationDto } from './StatusForParticipationDto';
import {
    StatusForParticipationDtoFromJSON,
    StatusForParticipationDtoFromJSONTyped,
    StatusForParticipationDtoToJSON,
} from './StatusForParticipationDto';

/**
 * Dto to add people to the guest list. Persons can be bodyguards (category: BODYGUARD, person: null), dummies (category: !BODYGUARD, person: null), or real persons (category: !BODYGUARD, person: !null)
 * @export
 * @interface AddPeopleDto
 */
export interface AddPeopleDto {
    /**
     * 
     * @type {Array<AddPeoplePeopleInnerDto>}
     * @memberof AddPeopleDto
     */
    people: Array<AddPeoplePeopleInnerDto>;
    /**
     * 
     * @type {StatusForParticipationDto}
     * @memberof AddPeopleDto
     */
    status: StatusForParticipationDto;
    /**
     * 
     * @type {AllocationReferenceDto}
     * @memberof AddPeopleDto
     */
    allocation: AllocationReferenceDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddPeopleDto
     */
    labels?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AddPeopleDto
     */
    comment?: string;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof AddPeopleDto
     */
    createCouplingGroupWithMainPerson?: string;
}

/**
 * Check if a given object implements the AddPeopleDto interface.
 */
export function instanceOfAddPeopleDto(value: object): boolean {
    if (!('people' in value)) return false;
    if (!('status' in value)) return false;
    if (!('allocation' in value)) return false;
    return true;
}

export function AddPeopleDtoFromJSON(json: any): AddPeopleDto {
    return AddPeopleDtoFromJSONTyped(json, false);
}

export function AddPeopleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddPeopleDto {
    if (json == null) {
        return json;
    }
    return {
        
        'people': ((json['people'] as Array<any>).map(AddPeoplePeopleInnerDtoFromJSON)),
        'status': StatusForParticipationDtoFromJSON(json['status']),
        'allocation': AllocationReferenceDtoFromJSON(json['allocation']),
        'labels': json['labels'] == null ? undefined : json['labels'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'createCouplingGroupWithMainPerson': json['createCouplingGroupWithMainPerson'] == null ? undefined : json['createCouplingGroupWithMainPerson'],
    };
}

export function AddPeopleDtoToJSON(value?: AddPeopleDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'people': ((value['people'] as Array<any>).map(AddPeoplePeopleInnerDtoToJSON)),
        'status': StatusForParticipationDtoToJSON(value['status']),
        'allocation': AllocationReferenceDtoToJSON(value['allocation']),
        'labels': value['labels'],
        'comment': value['comment'],
        'createCouplingGroupWithMainPerson': value['createCouplingGroupWithMainPerson'],
    };
}

