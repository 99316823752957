import { TokenResponseDto } from '../api';

export const AUTH_TOKEN_STORE_KEY = 'auth-token-response';

export interface AuthToken extends TokenResponseDto {
  expiresAt?: number;
}

export function writeAuthToken(authToken: AuthToken): void {
  localStorage.setItem(AUTH_TOKEN_STORE_KEY, JSON.stringify(authToken));
}

export function readAuthToken(): AuthToken | null {
  const stringifiedTokenResponse = localStorage.getItem(AUTH_TOKEN_STORE_KEY);

  if (stringifiedTokenResponse == null) {
    return null;
  }

  return JSON.parse(stringifiedTokenResponse);
}

export function removeAuthToken(): void {
  localStorage.removeItem(AUTH_TOKEN_STORE_KEY);
}
