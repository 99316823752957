import { Stack, Wrap, WrapItem } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CodeEditorContentDto, MailingCodeConfigModuleContentDto } from '../../../api';
import BooleanViewer from '../../../ui/boolean-viewer/boolean-viewer';
import { DescriptionGroup, DescriptionItem } from '../../../ui/description';
import Optional from '../../../ui/optional/optional';
import CodeConnectionViewer from '../../code/code-viewer/code-connection-viewer';
import CodeDiscountViewer from '../../code/code-viewer/code-discount-viewer';
import ProductReference from '../../product/product-reference/product-reference';

interface CodeConfigModuleContentViewerProps {
  module: MailingCodeConfigModuleContentDto;
}

export default function CodeConfigModuleContentViewer({ module }: CodeConfigModuleContentViewerProps) {
  const code = module.code;
  return <CodeConfigModuleContent code={code} />;
}

interface CodeConfigModuleContentProps {
  code?: CodeEditorContentDto;
}

export function CodeConfigModuleContent({ code }: CodeConfigModuleContentProps) {
  const { t } = useTranslation(['common', 'code', 'stand']);

  return code === undefined ? (
    <Optional />
  ) : (
    <Stack spacing={4}>
      <DescriptionGroup columnCount={2}>
        <DescriptionItem label={t('code:start')}>
          <Optional>
            {code.validityPeriod != null && t('common:format.date_time', { dateTime: code.validityPeriod.start })}
          </Optional>
        </DescriptionItem>
        <DescriptionItem label={t('code:end')}>
          <Optional>
            {code.validityPeriod != null && t('common:format.date_time', { dateTime: code.validityPeriod.end })}
          </Optional>
        </DescriptionItem>
        <DescriptionItem label={t('code:products')} span={2}>
          <Wrap spacingX={4} spacingY={2}>
            {code.products?.map((product) => (
              <WrapItem key={product.id}>
                <ProductReference productReference={product} size="sm" />
              </WrapItem>
            ))}
          </Wrap>
        </DescriptionItem>
        <DescriptionItem label={t('code:paymentModifier')}>
          <Optional>
            {code.paymentModifier != null && t(`code:paymentModifierOptions.${code.paymentModifier}`)}
          </Optional>
        </DescriptionItem>
        <DescriptionItem label={t('code:discountForWholeOrder')}>
          <BooleanViewer value={code.discountForWholeOrder} />
        </DescriptionItem>
      </DescriptionGroup>

      {code.discount && <CodeDiscountViewer discount={code.discount} />}
      {code.automaticConnections && <CodeConnectionViewer automaticConnections={code.automaticConnections} />}
    </Stack>
  );
}
