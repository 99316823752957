/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Technical disruptions
 * @export
 */
export const EventEvaluationTechnicalDisruptionsDto = {
    PICTURE: 'PICTURE',
    SOUND: 'SOUND'
} as const;
export type EventEvaluationTechnicalDisruptionsDto = typeof EventEvaluationTechnicalDisruptionsDto[keyof typeof EventEvaluationTechnicalDisruptionsDto];


export function EventEvaluationTechnicalDisruptionsDtoFromJSON(json: any): EventEvaluationTechnicalDisruptionsDto {
    return EventEvaluationTechnicalDisruptionsDtoFromJSONTyped(json, false);
}

export function EventEvaluationTechnicalDisruptionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventEvaluationTechnicalDisruptionsDto {
    return json as EventEvaluationTechnicalDisruptionsDto;
}

export function EventEvaluationTechnicalDisruptionsDtoToJSON(value?: EventEvaluationTechnicalDisruptionsDto | null): any {
    return value as any;
}

