/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';
import type { StaffStatusDto } from './StaffStatusDto';
import {
    StaffStatusDtoFromJSON,
    StaffStatusDtoFromJSONTyped,
    StaffStatusDtoToJSON,
} from './StaffStatusDto';
import type { TeamReferenceDto } from './TeamReferenceDto';
import {
    TeamReferenceDtoFromJSON,
    TeamReferenceDtoFromJSONTyped,
    TeamReferenceDtoToJSON,
} from './TeamReferenceDto';

/**
 * 
 * @export
 * @interface StaffForRoleListItemDto
 */
export interface StaffForRoleListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof StaffForRoleListItemDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StaffForRoleListItemDto
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof StaffForRoleListItemDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof StaffForRoleListItemDto
     */
    jobTitle?: string;
    /**
     * 
     * @type {Array<SectionReferenceDto>}
     * @memberof StaffForRoleListItemDto
     */
    belongsToSections: Array<SectionReferenceDto>;
    /**
     * 
     * @type {string}
     * @memberof StaffForRoleListItemDto
     */
    site?: string;
    /**
     * 
     * @type {string}
     * @memberof StaffForRoleListItemDto
     */
    room?: string;
    /**
     * 
     * @type {StaffStatusDto}
     * @memberof StaffForRoleListItemDto
     */
    status: StaffStatusDto;
    /**
     * 
     * @type {Array<TeamReferenceDto>}
     * @memberof StaffForRoleListItemDto
     */
    teams: Array<TeamReferenceDto>;
}

/**
 * Check if a given object implements the StaffForRoleListItemDto interface.
 */
export function instanceOfStaffForRoleListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('surname' in value)) return false;
    if (!('firstName' in value)) return false;
    if (!('belongsToSections' in value)) return false;
    if (!('status' in value)) return false;
    if (!('teams' in value)) return false;
    return true;
}

export function StaffForRoleListItemDtoFromJSON(json: any): StaffForRoleListItemDto {
    return StaffForRoleListItemDtoFromJSONTyped(json, false);
}

export function StaffForRoleListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffForRoleListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'surname': json['surname'],
        'firstName': json['firstName'],
        'jobTitle': json['jobTitle'] == null ? undefined : json['jobTitle'],
        'belongsToSections': ((json['belongsToSections'] as Array<any>).map(SectionReferenceDtoFromJSON)),
        'site': json['site'] == null ? undefined : json['site'],
        'room': json['room'] == null ? undefined : json['room'],
        'status': StaffStatusDtoFromJSON(json['status']),
        'teams': ((json['teams'] as Array<any>).map(TeamReferenceDtoFromJSON)),
    };
}

export function StaffForRoleListItemDtoToJSON(value?: StaffForRoleListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'surname': value['surname'],
        'firstName': value['firstName'],
        'jobTitle': value['jobTitle'],
        'belongsToSections': ((value['belongsToSections'] as Array<any>).map(SectionReferenceDtoToJSON)),
        'site': value['site'],
        'room': value['room'],
        'status': StaffStatusDtoToJSON(value['status']),
        'teams': ((value['teams'] as Array<any>).map(TeamReferenceDtoToJSON)),
    };
}

