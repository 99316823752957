import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useLocationPermissionClassGroupExtension(): PermissionClassGroupExtension<'LOCATION'> {
  const { t } = useTranslation(['location']);

  return {
    name: 'LOCATION',
    label: t('location:location'),
    getActionLabel: (action) => t(`location:permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`location:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`location:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
