/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MailingTemplatePermissionDto
 */
export interface MailingTemplatePermissionDto {
    /**
     * 
     * @type {string}
     * @memberof MailingTemplatePermissionDto
     */
    name: MailingTemplatePermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof MailingTemplatePermissionDto
     */
    action: MailingTemplatePermissionDtoActionEnum;
}


/**
 * @export
 */
export const MailingTemplatePermissionDtoNameEnum = {
    MAILING_TEMPLATE: 'MAILING_TEMPLATE'
} as const;
export type MailingTemplatePermissionDtoNameEnum = typeof MailingTemplatePermissionDtoNameEnum[keyof typeof MailingTemplatePermissionDtoNameEnum];

/**
 * @export
 */
export const MailingTemplatePermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    EDIT: 'CAN_EDIT',
    CREATE: 'CAN_CREATE',
    COPY: 'CAN_COPY',
    DELETE: 'CAN_DELETE'
} as const;
export type MailingTemplatePermissionDtoActionEnum = typeof MailingTemplatePermissionDtoActionEnum[keyof typeof MailingTemplatePermissionDtoActionEnum];


/**
 * Check if a given object implements the MailingTemplatePermissionDto interface.
 */
export function instanceOfMailingTemplatePermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function MailingTemplatePermissionDtoFromJSON(json: any): MailingTemplatePermissionDto {
    return MailingTemplatePermissionDtoFromJSONTyped(json, false);
}

export function MailingTemplatePermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingTemplatePermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function MailingTemplatePermissionDtoToJSON(value?: MailingTemplatePermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

