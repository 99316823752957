import { BlockerFunction } from '@remix-run/router';
import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import useCallbackRef from '../use-callback-ref/use-callback-ref';

export default function usePrompt({
  when,
  message,
  exemption,
}: {
  when: boolean;
  message: string;
  exemption?: RegExp;
}) {
  const blocker = useBlocker(
    useCallbackRef<BlockerFunction>(({ currentLocation, nextLocation }) => {
      // Only block if we're navigating to a different path (ignoring search and hash)
      if (currentLocation.pathname === nextLocation.pathname) {
        return false;
      }

      // If we have an exemption, we need to check if the next location matches
      if (exemption != null && exemption.test(nextLocation.pathname)) {
        return false;
      }

      return when;
    }),
  );

  useEffect(() => {
    if (blocker.state === 'blocked' && !when) {
      blocker.reset();
    }
  }, [blocker, when]);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      const proceed = window.confirm(message);

      if (proceed) {
        setTimeout(blocker.proceed, 0);
      } else {
        blocker.reset();
      }
    }
  }, [blocker, message]);
}
