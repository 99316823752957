import { Badge, Box, Button, ButtonGroup, HStack, Spinner, Tag } from '@chakra-ui/react';
import { faHistory, faHomeAlt } from '@fortawesome/pro-regular-svg-icons';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, Outlet, useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { CompanyDtoStatusEnum } from '../../../api';
import { PageHeader, PageTab } from '../../../ui/page';
import Page from '../../../ui/page/page';
import { createBuilder } from '../../../util/builder/builder';
import { useBuilderPlugins } from '../../../util/plugin/use-plugins';
import useFetcher from '../../../util/swr/use-fetcher';
import usePermission from '../../permission/use-permission';
import CompanyMenu from '../company-menu/company-menu';
import { fetchCompany, fetchCompanyComments } from '../company-queries';
import CompanyPageTab, { COMPANY_PAGE_TAB } from './company-page-tab';

export default function CompanyPage() {
  const { t } = useTranslation('company');
  const { companyId } = useParams<{ companyId: string }>();
  const { hasPermission } = usePermission();
  const navigate = useNavigate();

  invariant(companyId, 'Empty companyId');
  const company = useFetcher(fetchCompany, { id: companyId }, { suspense: true });

  return (
    <Page>
      <PageHeader
        title={
          <>
            {company.name}
            {company.status === CompanyDtoStatusEnum.HISTORIC && <Tag m={2}>{t('statusLabel.HISTORIC')}</Tag>}
          </>
        }
        actions={
          company.status !== CompanyDtoStatusEnum.ARCHIVED && (
            <HStack>
              <ButtonGroup>
                <Box>
                  <CompanyMenu company={company} onDelete={() => navigate(`/companies`)} />
                </Box>
              </ButtonGroup>
              {hasPermission('COMPANY:CAN_EDIT') && (
                <Button as={RouterLink} to="edit" variant="primary">
                  {t('action.edit')}
                </Button>
              )}
            </HStack>
          )
        }
        tabs={<CompanyPageTabs />}
      />
      <Outlet />
    </Page>
  );
}

function CompanyPageTabs() {
  const { companyId } = useParams<{ companyId: string }>();
  invariant(companyId, 'Empty companyId');
  const { t } = useTranslation(['common', 'format', 'company']);
  const comments = useFetcher(
    fetchCompanyComments,
    {
      id: companyId,
      pageable: { size: 1 },
    },
    { suspense: false },
  );
  const tabBuilder = useMemo(
    () =>
      createBuilder<CompanyPageTab>([
        { key: 'home', element: <PageTab to="." icon={faHomeAlt} /> },
        {
          key: 'comments',
          element: (
            <PageTab to="./comments">
              {t('common:comments.label')}
              <Badge colorScheme="teal" variant="solid" ml={2} display="inline-flex">
                {comments?.totalElements ?? <Spinner color="white" size="xs" my={1} />}
              </Badge>
            </PageTab>
          ),
        },
        {
          key: 'formats',
          element: <PageTab to="./formats">{t('format:companyConnections.formats')}</PageTab>,
        },
        {
          key: 'persons',
          element: <PageTab to="./persons">{t('company:personRelation.persons')}</PageTab>,
        },
        {
          key: 'companies',
          element: <PageTab to="./companies">{t('company:relation.company.tabLabel')}</PageTab>,
        },
        {
          key: 'films',
          element: <PageTab to="./films">{t('company:relation.film.tabLabel')}</PageTab>,
        },
        {
          key: 'history',
          element: (
            <PageTab to="./history" icon={faHistory}>
              {t('common:history.label')}
            </PageTab>
          ),
        },
      ]),
    [comments?.totalElements, t],
  );
  const tabs = useBuilderPlugins(COMPANY_PAGE_TAB, tabBuilder);
  return (
    <>
      {tabs.map(({ key, element }) => (
        <Fragment key={key}>{element}</Fragment>
      ))}
    </>
  );
}
