import React from 'react';
import { createSearchParams, URLSearchParamsInit, useSearchParams } from 'react-router-dom';

export function useMergedSearchParams(keysToUpdate: string[], defaultInit?: URLSearchParamsInit) {
  const [searchParams, setSearchParams] = useSearchParams(defaultInit);

  const setMergedSearchParams = React.useCallback(
    (
      nextInit: URLSearchParamsInit,
      navigateOptions?:
        | {
            replace?: boolean | undefined;
            state?: any;
          }
        | undefined,
    ) => {
      const nextSearchParams = mergeSearchParams(keysToUpdate, searchParams, nextInit);
      setSearchParams(nextSearchParams, navigateOptions);
    },
    [keysToUpdate, searchParams, setSearchParams],
  );

  return [searchParams, setMergedSearchParams] as const;
}

export function mergeSearchParams(
  keysToUpdate: string[],
  searchParams: URLSearchParams,
  nextInit: URLSearchParamsInit,
) {
  const nextSearchParams = createSearchParams(nextInit);

  searchParams.forEach((value, key) => {
    if (!keysToUpdate.includes(key)) {
      nextSearchParams.append(key, value);
    }
  });

  return nextSearchParams;
}
