/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccountingCreationTypeDto } from './AccountingCreationTypeDto';
import {
    AccountingCreationTypeDtoFromJSON,
    AccountingCreationTypeDtoFromJSONTyped,
    AccountingCreationTypeDtoToJSON,
} from './AccountingCreationTypeDto';
import type { AccountingIssuerDto } from './AccountingIssuerDto';
import {
    AccountingIssuerDtoFromJSON,
    AccountingIssuerDtoFromJSONTyped,
    AccountingIssuerDtoToJSON,
} from './AccountingIssuerDto';
import type { AccountingItemsViewerDto } from './AccountingItemsViewerDto';
import {
    AccountingItemsViewerDtoFromJSON,
    AccountingItemsViewerDtoFromJSONTyped,
    AccountingItemsViewerDtoToJSON,
} from './AccountingItemsViewerDto';
import type { AccountingRecipientDto } from './AccountingRecipientDto';
import {
    AccountingRecipientDtoFromJSON,
    AccountingRecipientDtoFromJSONTyped,
    AccountingRecipientDtoToJSON,
} from './AccountingRecipientDto';
import type { FileMetadataDto } from './FileMetadataDto';
import {
    FileMetadataDtoFromJSON,
    FileMetadataDtoFromJSONTyped,
    FileMetadataDtoToJSON,
} from './FileMetadataDto';
import type { OrderConfirmationPaymentResultDto } from './OrderConfirmationPaymentResultDto';
import {
    OrderConfirmationPaymentResultDtoFromJSON,
    OrderConfirmationPaymentResultDtoFromJSONTyped,
    OrderConfirmationPaymentResultDtoToJSON,
} from './OrderConfirmationPaymentResultDto';
import type { OrderConfirmationStatusDto } from './OrderConfirmationStatusDto';
import {
    OrderConfirmationStatusDtoFromJSON,
    OrderConfirmationStatusDtoFromJSONTyped,
    OrderConfirmationStatusDtoToJSON,
} from './OrderConfirmationStatusDto';
import type { OrderReferenceDto } from './OrderReferenceDto';
import {
    OrderReferenceDtoFromJSON,
    OrderReferenceDtoFromJSONTyped,
    OrderReferenceDtoToJSON,
} from './OrderReferenceDto';
import type { PaymentTargetDto } from './PaymentTargetDto';
import {
    PaymentTargetDtoFromJSON,
    PaymentTargetDtoFromJSONTyped,
    PaymentTargetDtoToJSON,
} from './PaymentTargetDto';
import type { SumTotalDto } from './SumTotalDto';
import {
    SumTotalDtoFromJSON,
    SumTotalDtoFromJSONTyped,
    SumTotalDtoToJSON,
} from './SumTotalDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface OrderConfirmationViewerDto
 */
export interface OrderConfirmationViewerDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof OrderConfirmationViewerDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof OrderConfirmationViewerDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof OrderConfirmationViewerDto
     */
    orderConfirmationNumber?: string;
    /**
     * 
     * @type {AccountingRecipientDto}
     * @memberof OrderConfirmationViewerDto
     */
    recipient: AccountingRecipientDto;
    /**
     * 
     * @type {AccountingIssuerDto}
     * @memberof OrderConfirmationViewerDto
     */
    issuer: AccountingIssuerDto;
    /**
     * 
     * @type {AccountingCreationTypeDto}
     * @memberof OrderConfirmationViewerDto
     */
    creationType: AccountingCreationTypeDto;
    /**
     * 
     * @type {FileMetadataDto}
     * @memberof OrderConfirmationViewerDto
     */
    document?: FileMetadataDto;
    /**
     * 
     * @type {Date}
     * @memberof OrderConfirmationViewerDto
     */
    sentAt?: Date;
    /**
     * 
     * @type {Array<AccountingItemsViewerDto>}
     * @memberof OrderConfirmationViewerDto
     */
    items: Array<AccountingItemsViewerDto>;
    /**
     * 
     * @type {number}
     * @memberof OrderConfirmationViewerDto
     */
    internalPaymentTerm: number;
    /**
     * 
     * @type {OrderReferenceDto}
     * @memberof OrderConfirmationViewerDto
     */
    order?: OrderReferenceDto;
    /**
     * 
     * @type {OrderConfirmationStatusDto}
     * @memberof OrderConfirmationViewerDto
     */
    status: OrderConfirmationStatusDto;
    /**
     * 
     * @type {SumTotalDto}
     * @memberof OrderConfirmationViewerDto
     */
    sumTotal: SumTotalDto;
    /**
     * 
     * @type {string}
     * @memberof OrderConfirmationViewerDto
     */
    paymentLink?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderConfirmationViewerDto
     */
    orderConfirmationDate: string;
    /**
     * 
     * @type {Array<PaymentTargetDto>}
     * @memberof OrderConfirmationViewerDto
     */
    paymentPlan: Array<PaymentTargetDto>;
    /**
     * 
     * @type {OrderConfirmationPaymentResultDto}
     * @memberof OrderConfirmationViewerDto
     */
    paymentResult?: OrderConfirmationPaymentResultDto;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof OrderConfirmationViewerDto
     */
    extensions: { [key: string]: any; };
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderConfirmationViewerDto
     */
    sentToEmails: Array<string>;
}

/**
 * Check if a given object implements the OrderConfirmationViewerDto interface.
 */
export function instanceOfOrderConfirmationViewerDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('recipient' in value)) return false;
    if (!('issuer' in value)) return false;
    if (!('creationType' in value)) return false;
    if (!('items' in value)) return false;
    if (!('internalPaymentTerm' in value)) return false;
    if (!('status' in value)) return false;
    if (!('sumTotal' in value)) return false;
    if (!('orderConfirmationDate' in value)) return false;
    if (!('paymentPlan' in value)) return false;
    if (!('extensions' in value)) return false;
    if (!('sentToEmails' in value)) return false;
    return true;
}

export function OrderConfirmationViewerDtoFromJSON(json: any): OrderConfirmationViewerDto {
    return OrderConfirmationViewerDtoFromJSONTyped(json, false);
}

export function OrderConfirmationViewerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderConfirmationViewerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'orderConfirmationNumber': json['orderConfirmationNumber'] == null ? undefined : json['orderConfirmationNumber'],
        'recipient': AccountingRecipientDtoFromJSON(json['recipient']),
        'issuer': AccountingIssuerDtoFromJSON(json['issuer']),
        'creationType': AccountingCreationTypeDtoFromJSON(json['creationType']),
        'document': json['document'] == null ? undefined : FileMetadataDtoFromJSON(json['document']),
        'sentAt': json['sentAt'] == null ? undefined : (new Date(json['sentAt'])),
        'items': ((json['items'] as Array<any>).map(AccountingItemsViewerDtoFromJSON)),
        'internalPaymentTerm': json['internalPaymentTerm'],
        'order': json['order'] == null ? undefined : OrderReferenceDtoFromJSON(json['order']),
        'status': OrderConfirmationStatusDtoFromJSON(json['status']),
        'sumTotal': SumTotalDtoFromJSON(json['sumTotal']),
        'paymentLink': json['paymentLink'] == null ? undefined : json['paymentLink'],
        'orderConfirmationDate': json['orderConfirmationDate'],
        'paymentPlan': ((json['paymentPlan'] as Array<any>).map(PaymentTargetDtoFromJSON)),
        'paymentResult': json['paymentResult'] == null ? undefined : OrderConfirmationPaymentResultDtoFromJSON(json['paymentResult']),
        'extensions': json['extensions'],
        'sentToEmails': json['sentToEmails'],
    };
}

export function OrderConfirmationViewerDtoToJSON(value?: OrderConfirmationViewerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'orderConfirmationNumber': value['orderConfirmationNumber'],
        'recipient': AccountingRecipientDtoToJSON(value['recipient']),
        'issuer': AccountingIssuerDtoToJSON(value['issuer']),
        'creationType': AccountingCreationTypeDtoToJSON(value['creationType']),
        'document': FileMetadataDtoToJSON(value['document']),
        'sentAt': value['sentAt'] == null ? undefined : ((value['sentAt']).toISOString()),
        'items': ((value['items'] as Array<any>).map(AccountingItemsViewerDtoToJSON)),
        'internalPaymentTerm': value['internalPaymentTerm'],
        'order': OrderReferenceDtoToJSON(value['order']),
        'status': OrderConfirmationStatusDtoToJSON(value['status']),
        'sumTotal': SumTotalDtoToJSON(value['sumTotal']),
        'paymentLink': value['paymentLink'],
        'orderConfirmationDate': value['orderConfirmationDate'],
        'paymentPlan': ((value['paymentPlan'] as Array<any>).map(PaymentTargetDtoToJSON)),
        'paymentResult': OrderConfirmationPaymentResultDtoToJSON(value['paymentResult']),
        'extensions': value['extensions'],
        'sentToEmails': value['sentToEmails'],
    };
}

