/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MyAccountPermissionDto
 */
export interface MyAccountPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof MyAccountPermissionDto
     */
    name: MyAccountPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof MyAccountPermissionDto
     */
    action: MyAccountPermissionDtoActionEnum;
}


/**
 * @export
 */
export const MyAccountPermissionDtoNameEnum = {
    MY_ACCOUNT: 'MY_ACCOUNT'
} as const;
export type MyAccountPermissionDtoNameEnum = typeof MyAccountPermissionDtoNameEnum[keyof typeof MyAccountPermissionDtoNameEnum];

/**
 * @export
 */
export const MyAccountPermissionDtoActionEnum = {
    CAN_EDIT: 'CAN_EDIT'
} as const;
export type MyAccountPermissionDtoActionEnum = typeof MyAccountPermissionDtoActionEnum[keyof typeof MyAccountPermissionDtoActionEnum];


/**
 * Check if a given object implements the MyAccountPermissionDto interface.
 */
export function instanceOfMyAccountPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function MyAccountPermissionDtoFromJSON(json: any): MyAccountPermissionDto {
    return MyAccountPermissionDtoFromJSONTyped(json, false);
}

export function MyAccountPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MyAccountPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function MyAccountPermissionDtoToJSON(value?: MyAccountPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

