import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { accountIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function AccountSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'ACCOUNT', `${result.resultType} is no instance of AccountSearchResult`);

  const account = result.result;
  const { t } = useTranslation(['account', 'person']);

  return (
    <SearchResult
      icon={accountIcon}
      title={t('person:personFormat', {
        firstName: account.firstName,
        surname: account.surname,
      })}
      link={`/accounts/${account.id}`}
      score={result.score}
      onClick={onClick}
      necessaryPermission="ACCOUNT:CAN_SEE_DETAILS"
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('account:email'),
            element: account.email,
          },
          {
            label: t('account:type'),
            element: t(`account:typeOptions.${account.type}`),
          },
          {
            label: t('account:status'),
            element: t(`account:statusOptions.${account.status}`),
          },
        ]}
      />
    </SearchResult>
  );
}
