import React from 'react';
import registry from '../../registry';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import FormatBreadcrumb from './common/format-breadcrumb';
import FORMAT_CHILD_ROUTES from './format-child-routes';

const formatRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'formats',
    handle: {
      breadcrumb: <Translate ns="format" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./format-lister/format-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./format-editor/new-format-route'),
      },
      {
        path: 'merge',
        lazy: () => import('./format-merger/format-merger-route'),
      },
      {
        path: 'duplicates',
        lazy: () => import('./format-duplicate-lister/format-duplicate-lister-route'),
      },
      {
        path: 'non-duplicates',
        lazy: () => import('./format-duplicate-lister/format-non-duplicate-lister-route'),
      },
      {
        path: ':formatId',
        handle: {
          breadcrumb: <FormatBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./format-page/format-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./format-viewer/format-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./format-history/format-history-route'),
              },
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./format-editor/edit-format-route'),
          },
          ...registry.getAll(FORMAT_CHILD_ROUTES).flat(),
        ],
      },
    ],
  },
];

export default formatRoutes;
