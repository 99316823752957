import useSWR from 'swr';
import invariant from 'tiny-invariant';
import { KeyPlayerDto } from '../../../api';
import keyPlayersListApi from '../../../data-access/key-players-list-api';
import fallbackMiddleware from '../../../util/swr/fallback-middleware';

export function useNotCopyableKeyPlayers(eventId: string): KeyPlayerDto[] {
  const { data } = useSWR(
    ['notCopyableKeyPlayers', eventId],
    () => {
      return keyPlayersListApi.getNotCopyableKeyPlayers({ eventId: eventId });
    },
    {
      suspense: true,
      use: [fallbackMiddleware],
    },
  );
  invariant(data != null, 'Missing key players list');

  return data;
}
