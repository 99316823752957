/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PersonDto } from './PersonDto';
import {
    PersonDtoFromJSON,
    PersonDtoFromJSONTyped,
    PersonDtoToJSON,
} from './PersonDto';

/**
 * Person that will be created after merge of two other persons.
 * @export
 * @interface MergedPersonDto
 */
export interface MergedPersonDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof MergedPersonDto
     */
    personA: string;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof MergedPersonDto
     */
    personB: string;
    /**
     * 
     * @type {PersonDto}
     * @memberof MergedPersonDto
     */
    newPerson: PersonDto;
}

/**
 * Check if a given object implements the MergedPersonDto interface.
 */
export function instanceOfMergedPersonDto(value: object): boolean {
    if (!('personA' in value)) return false;
    if (!('personB' in value)) return false;
    if (!('newPerson' in value)) return false;
    return true;
}

export function MergedPersonDtoFromJSON(json: any): MergedPersonDto {
    return MergedPersonDtoFromJSONTyped(json, false);
}

export function MergedPersonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MergedPersonDto {
    if (json == null) {
        return json;
    }
    return {
        
        'personA': json['personA'],
        'personB': json['personB'],
        'newPerson': PersonDtoFromJSON(json['newPerson']),
    };
}

export function MergedPersonDtoToJSON(value?: MergedPersonDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'personA': value['personA'],
        'personB': value['personB'],
        'newPerson': PersonDtoToJSON(value['newPerson']),
    };
}

