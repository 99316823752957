import { produce } from 'immer';

interface AddElementAction<TElement> {
  type: 'ADD_ELEMENT';
  payload: TElement;
}

interface EditElementAction<TElement> {
  type: 'EDIT_ELEMENT';
  payload: {
    index: number;
    element: TElement;
  };
}

interface DeleteElementAction {
  type: 'DELETE_ELEMENT';
  payload: number;
}

export type ElementTableControlAction<TElement> =
  | AddElementAction<TElement>
  | EditElementAction<TElement>
  | DeleteElementAction;

export default function elementControlReducer<TElement>(
  elements: TElement[],
  action: ElementTableControlAction<TElement>,
) {
  return produce(elements, (draft: TElement[]) => {
    switch (action.type) {
      case 'ADD_ELEMENT':
        draft.push(action.payload);
        break;
      case 'EDIT_ELEMENT':
        draft.splice(action.payload.index, 1, action.payload.element);
        break;
      case 'DELETE_ELEMENT':
        draft.splice(action.payload, 1);
        break;
    }
  });
}
