import React from 'react';

interface ElementContextProps<TData> {
  element: TData;
  index: number;
}

const ElementContext = React.createContext<ElementContextProps<any> | null>(null);

export default ElementContext;
