/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductBundlePriceDto } from './ProductBundlePriceDto';
import {
    ProductBundlePriceDtoFromJSON,
    ProductBundlePriceDtoFromJSONTyped,
    ProductBundlePriceDtoToJSON,
} from './ProductBundlePriceDto';

/**
 * 
 * @export
 * @interface ProductBundleReferenceDto
 */
export interface ProductBundleReferenceDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof ProductBundleReferenceDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleReferenceDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleReferenceDto
     */
    titleEnglish: string;
    /**
     * The unique key for a product bundle starting with "AB".
     * @type {string}
     * @memberof ProductBundleReferenceDto
     */
    articleNumber: string;
    /**
     * 
     * @type {ProductBundlePriceDto}
     * @memberof ProductBundleReferenceDto
     */
    price: ProductBundlePriceDto;
}

/**
 * Check if a given object implements the ProductBundleReferenceDto interface.
 */
export function instanceOfProductBundleReferenceDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('title' in value)) return false;
    if (!('titleEnglish' in value)) return false;
    if (!('articleNumber' in value)) return false;
    if (!('price' in value)) return false;
    return true;
}

export function ProductBundleReferenceDtoFromJSON(json: any): ProductBundleReferenceDto {
    return ProductBundleReferenceDtoFromJSONTyped(json, false);
}

export function ProductBundleReferenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductBundleReferenceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'titleEnglish': json['titleEnglish'],
        'articleNumber': json['articleNumber'],
        'price': ProductBundlePriceDtoFromJSON(json['price']),
    };
}

export function ProductBundleReferenceDtoToJSON(value?: ProductBundleReferenceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
        'titleEnglish': value['titleEnglish'],
        'articleNumber': value['articleNumber'],
        'price': ProductBundlePriceDtoToJSON(value['price']),
    };
}

