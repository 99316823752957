/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  HistoryPageDto,
  MailingModuleConstraintDto,
  MailingTemplateDto,
  MailingTemplatePageDto,
  PageableDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    MailingModuleConstraintDtoFromJSON,
    MailingModuleConstraintDtoToJSON,
    MailingTemplateDtoFromJSON,
    MailingTemplateDtoToJSON,
    MailingTemplatePageDtoFromJSON,
    MailingTemplatePageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface CreateMailingTemplateRequest {
    mailingTemplateDto: MailingTemplateDto;
}

export interface DeleteMailingTemplateRequest {
    id: string;
}

export interface FetchMailingTemplateRequest {
    id: string;
}

export interface FetchMailingTemplateHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchModuleConstraintsRequest {
    mailingType: string;
}

export interface SearchMailingTemplatesRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface TemplateMailingTemplateRequest {
    mailingType: string;
}

export interface UpdateMailingTemplateRequest {
    id: string;
    mailingTemplateDto: MailingTemplateDto;
}

/**
 * 
 */
export class MailingTemplateApi extends runtime.BaseAPI {

    /**
     * Create a new mailing template.
     */
    async createMailingTemplateRaw(requestParameters: CreateMailingTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingTemplateDto>> {
        if (requestParameters['mailingTemplateDto'] == null) {
            throw new runtime.RequiredError(
                'mailingTemplateDto',
                'Required parameter "mailingTemplateDto" was null or undefined when calling createMailingTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MailingTemplateDtoToJSON(requestParameters['mailingTemplateDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingTemplateDtoFromJSON(jsonValue));
    }

    /**
     * Create a new mailing template.
     */
    async createMailingTemplate(requestParameters: CreateMailingTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingTemplateDto> {
        const response = await this.createMailingTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the mailing template.
     */
    async deleteMailingTemplateRaw(requestParameters: DeleteMailingTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteMailingTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the mailing template.
     */
    async deleteMailingTemplate(requestParameters: DeleteMailingTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteMailingTemplateRaw(requestParameters, initOverrides);
    }

    /**
     * Get the mailing template business object.
     */
    async fetchMailingTemplateRaw(requestParameters: FetchMailingTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingTemplateDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchMailingTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingTemplateDtoFromJSON(jsonValue));
    }

    /**
     * Get the mailing template business object.
     */
    async fetchMailingTemplate(requestParameters: FetchMailingTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingTemplateDto> {
        const response = await this.fetchMailingTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the mailing template.
     */
    async fetchMailingTemplateHistoryRaw(requestParameters: FetchMailingTemplateHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchMailingTemplateHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-templates/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the mailing template.
     */
    async fetchMailingTemplateHistory(requestParameters: FetchMailingTemplateHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchMailingTemplateHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the constraints for the mailing type.
     */
    async fetchModuleConstraintsRaw(requestParameters: FetchModuleConstraintsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: MailingModuleConstraintDto; }>> {
        if (requestParameters['mailingType'] == null) {
            throw new runtime.RequiredError(
                'mailingType',
                'Required parameter "mailingType" was null or undefined when calling fetchModuleConstraints().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-templates/module-constraints/{mailingType}`.replace(`{${"mailingType"}}`, encodeURIComponent(String(requestParameters['mailingType']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, MailingModuleConstraintDtoFromJSON));
    }

    /**
     * Get the constraints for the mailing type.
     */
    async fetchModuleConstraints(requestParameters: FetchModuleConstraintsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: MailingModuleConstraintDto; }> {
        const response = await this.fetchModuleConstraintsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for mailing templates.
     */
    async searchMailingTemplatesRaw(requestParameters: SearchMailingTemplatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingTemplatePageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingTemplatePageDtoFromJSON(jsonValue));
    }

    /**
     * Search for mailing templates.
     */
    async searchMailingTemplates(requestParameters: SearchMailingTemplatesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingTemplatePageDto> {
        const response = await this.searchMailingTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new mailing template.
     */
    async templateMailingTemplateRaw(requestParameters: TemplateMailingTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingTemplateDto>> {
        if (requestParameters['mailingType'] == null) {
            throw new runtime.RequiredError(
                'mailingType',
                'Required parameter "mailingType" was null or undefined when calling templateMailingTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-templates/template/{mailingType}`.replace(`{${"mailingType"}}`, encodeURIComponent(String(requestParameters['mailingType']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingTemplateDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new mailing template.
     */
    async templateMailingTemplate(requestParameters: TemplateMailingTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingTemplateDto> {
        const response = await this.templateMailingTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the existing mailing template.
     */
    async updateMailingTemplateRaw(requestParameters: UpdateMailingTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingTemplateDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateMailingTemplate().'
            );
        }

        if (requestParameters['mailingTemplateDto'] == null) {
            throw new runtime.RequiredError(
                'mailingTemplateDto',
                'Required parameter "mailingTemplateDto" was null or undefined when calling updateMailingTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MailingTemplateDtoToJSON(requestParameters['mailingTemplateDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingTemplateDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing mailing template.
     */
    async updateMailingTemplate(requestParameters: UpdateMailingTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingTemplateDto> {
        const response = await this.updateMailingTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
