/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ColumnDto
 */
export interface ColumnDto {
    /**
     * 
     * @type {string}
     * @memberof ColumnDto
     */
    type: ColumnDtoTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ColumnDto
     */
    editable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ColumnDto
     */
    active: boolean;
}


/**
 * @export
 */
export const ColumnDtoTypeEnum = {
    PERSON: 'PERSON',
    ROLE: 'ROLE',
    FOR: 'FOR',
    PRESENCE: 'PRESENCE',
    PRESENCE_TIME: 'PRESENCE_TIME',
    COMMENT: 'COMMENT',
    PHONE_NUMBER: 'PHONE_NUMBER',
    EMAIL: 'EMAIL',
    STAGE_NAME: 'STAGE_NAME',
    CONTACT_LANGUAGE: 'CONTACT_LANGUAGE',
    MARKER: 'MARKER'
} as const;
export type ColumnDtoTypeEnum = typeof ColumnDtoTypeEnum[keyof typeof ColumnDtoTypeEnum];


/**
 * Check if a given object implements the ColumnDto interface.
 */
export function instanceOfColumnDto(value: object): boolean {
    if (!('type' in value)) return false;
    if (!('editable' in value)) return false;
    if (!('active' in value)) return false;
    return true;
}

export function ColumnDtoFromJSON(json: any): ColumnDto {
    return ColumnDtoFromJSONTyped(json, false);
}

export function ColumnDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColumnDto {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'editable': json['editable'],
        'active': json['active'],
    };
}

export function ColumnDtoToJSON(value?: ColumnDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'editable': value['editable'],
        'active': value['active'],
    };
}

