import useSWR from 'swr';
import invariant from 'tiny-invariant';
import placementTemplateApi from '../../../../data-access/placement-template-api';

export default function usePlacementTemplates(parentId: string) {
  const { data, mutate } = useSWR(
    [parentId, 'placement_templates'],
    () => placementTemplateApi.placementTemplates({ parentId }),
    {
      suspense: true,
    },
  );
  invariant(data != null);

  return { templates: data, reloadTemplates: mutate };
}
