import { Tr, useBoolean } from '@chakra-ui/react';
import { SystemStyleObject } from '@chakra-ui/styled-system';
import React from 'react';
import invariant from 'tiny-invariant';
import useDefinedContext from '../../util/context/use-defined-context/use-defined-context';
import isDisabled from '../../util/dom/is-disabled';
import isFocusable from '../../util/dom/is-focusable';
import Optional from '../optional/optional';
import DataTableColumn, { isChildDataTableColumn, isParentDataTableColumn } from './data-table-column';
import { DataTableStylesContext } from './data-table-context';
import { DataTableDataCell } from './data-table-data-cell';
import { DataTableClickRefContext } from './data-table-link';
import { DataTableCheckboxRefContext } from './use-selection-column';

interface DataTableBodyRowProps<TData, TChildData = void> {
  data: TData;
  index: number;
  lastRow: boolean;
  columns: DataTableColumn<TData, TChildData>[];
  rowKey(data: TData | TChildData, index: number): React.Key;
  childRows?(data: TData): TChildData[] | undefined;
  rowStyle?(data: TData | TChildData): SystemStyleObject;
}

export default function DataTableBodyRow<TData, TChildData = void>({
  data,
  index,
  lastRow,
  columns,
  rowKey,
  childRows,
  rowStyle,
}: DataTableBodyRowProps<TData, TChildData>) {
  const [hovering, setHovering] = useBoolean(false);
  const [clickable, setClickable] = React.useState(false);
  const dataTableClickRef = React.useRef<any>(null);
  const dataTableCheckboxRef = React.useRef<any>(null);
  const styles = useDefinedContext(DataTableStylesContext);

  const getClickElement = (): null | HTMLElement => {
    const element = dataTableClickRef.current ?? dataTableCheckboxRef.current;
    invariant(element == null || element instanceof HTMLElement);
    return element;
  };

  const toggleHovering = (event: React.MouseEvent) => {
    // Only show hovering styling if the table is using a data table link.
    const element = getClickElement();

    if (element == null || isDisabled(element)) {
      return;
    }

    // Only toggle hovering styling when table row, data table link or non-focusable elements
    // were hovered.
    if (element.contains(event.target as HTMLElement) || !isFocusable(event.target as HTMLElement)) {
      setHovering.toggle();
    }
  };

  const handleClick = (event: React.MouseEvent) => {
    const element = getClickElement();

    // Only propagate click event to data table link if data table link is present.
    if (element == null) {
      return;
    }

    // Only propagate click event if data table link or non-focusable element were clicked.
    if (element.contains(event.target as HTMLElement) || isFocusable(event.target as HTMLElement)) {
      return;
    }

    event.preventDefault();
    element?.click();
  };

  React.useEffect(() => {
    const element = getClickElement();
    setClickable(element != null && !isDisabled(element));
  }, [columns, data]);

  const childData = childRows?.(data);

  const rowProps = {
    sx: { ...styles.tr, ...rowStyle?.(data) },
    onMouseOver: toggleHovering,
    onMouseOut: toggleHovering,
    onClick: handleClick,
    'data-clickable': clickable,
    'data-hovering': hovering,
  };

  if (childData == null) {
    return (
      <DataTableClickRefContext.Provider value={dataTableClickRef}>
        <DataTableCheckboxRefContext.Provider value={dataTableCheckboxRef}>
          <Tr {...rowProps}>
            {columns.map(
              (column, columnIndex) =>
                column && (
                  <DataTableDataCell key={column.key} column={column} firstColumn={columnIndex === 0} lastRow={lastRow}>
                    {isParentDataTableColumn(column) ? column.renderCell(data, index) : <Optional />}
                  </DataTableDataCell>
                ),
            )}
          </Tr>
        </DataTableCheckboxRefContext.Provider>
      </DataTableClickRefContext.Provider>
    );
  }

  const [firstChildData, ...childDataRest] = childData;

  return (
    <DataTableClickRefContext.Provider value={dataTableClickRef}>
      <Tr {...rowProps}>
        {columns.map((column, columnIndex) => {
          if (!column) {
            return null;
          }

          const dataCellProps = {
            column: column,
            firstColumn: columnIndex === 0,
          };

          return isParentDataTableColumn(column) ? (
            <DataTableDataCell
              key={column.key}
              {...dataCellProps}
              rowSpan={childData.length > 0 ? childData.length : undefined}
              lastRow={lastRow}
            >
              {column.renderCell(data, index)}
            </DataTableDataCell>
          ) : (
            <DataTableDataCell key={column.key} {...dataCellProps} lastRow={lastRow && childData.length === 1}>
              {column.renderChildCell(firstChildData, index)}
            </DataTableDataCell>
          );
        })}
      </Tr>
      {childDataRest.map((child, childIndex) => {
        // The rest of child data is missing the first item due to slicing.
        // So lets "fix" the index accordingly.
        childIndex += 1;

        return (
          <Tr key={rowKey(child, childIndex)} {...rowProps}>
            {columns.map(
              (column, columnIndex) =>
                isChildDataTableColumn(column) && (
                  <DataTableDataCell
                    key={column.key}
                    column={column}
                    firstColumn={columnIndex === 0}
                    lastRow={lastRow && childData.length - 1 === childIndex}
                  >
                    {column.renderChildCell(child, childIndex)}
                  </DataTableDataCell>
                ),
            )}
          </Tr>
        );
      })}
    </DataTableClickRefContext.Provider>
  );
}
