import { isEmpty } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { eventIcon, locationIcon, rundownIcon } from '../../ui/icons/business-objects';
import EventReference from '../event/event-reference/event-reference';
import LocationReference from '../location/location-reference/location-reference';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';
import SearchResultConnection from '../search/search-result-connection';

export default function RundownLaneElementSearchResult({ result, onClick }: SearchResultProps) {
  invariant(
    result.resultType === 'RUNDOWN_LANE_ELEMENT',
    `${result.resultType} is no instance of RundownLaneElementSearchResult`,
  );

  const laneElement = result.laneElement;
  const event = result.event;
  const { t } = useTranslation(['rundown', 'common']);

  return (
    <SearchResult
      icon={rundownIcon}
      title={laneElement.title}
      link={`/events/${event.id}/rundown?as=table`}
      score={result.score}
      onClick={onClick}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('rundown:element.date_time_range'),
            element: t('common:format.datetime_range', { dateTimeRange: laneElement.dateTimeRange }),
          },
          {
            label: t('rundown:element.description'),
            element: laneElement.description,
          },
          {
            label: t('rundown:lane.label'),
            element: laneElement.lane.title + ' (' + laneElement.lane.englishTitle + ')',
          },
          {
            label: t('rundown:lane.type.label'),
            element: t(`rundown:lane.typeOptions.${laneElement.lane.laneType}`),
          },
        ]}
      />
      <SearchResultConnection targetIcon={eventIcon} onTargetClick={onClick} connection={t('rundown:calendar.event')}>
        <EventReference event={event} hideIcon />
      </SearchResultConnection>
      {!isEmpty(laneElement.locations) &&
        laneElement.locations.map((location) => (
          <SearchResultConnection
            targetIcon={locationIcon}
            onTargetClick={onClick}
            connection={
              <SearchResultAttributes
                attributes={[
                  {
                    label: t('rundown:element.location_zone'),
                    element: location.zone,
                  },
                  {
                    label: t('rundown:element.location_sub_zone'),
                    element: location.subZone,
                  },
                ]}
              />
            }
          >
            <LocationReference locationReference={location.location} hideIcon />
          </SearchResultConnection>
        ))}
    </SearchResult>
  );
}
