import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OccupationDto, OccupationDtoConnectionTypeEnum, OccupationDtoStatusEnum, ProfessionDto } from '../../../api';
import HistoryDisplaySettings from '../../../ui/history/history-display-settings';

export default function useOccupationHistorySettings(): HistoryDisplaySettings<OccupationDto> {
  const { t } = useTranslation('person');
  const { t: tCommon } = useTranslation('common');

  return useMemo<HistoryDisplaySettings<OccupationDto>>(() => {
    return {
      attributeLabels: {
        connectionType: t('occupations.connectionType'),
        jobTitle: t('occupations.jobTitle'),
        profession: t('occupations.profession'),
        workingSectors: t('occupations.workingSectors'),
        mainActivity: t('occupations.mainActivity.tooltip'),
        publishJob: t('occupations.publishJob'),
        dateRange: t('occupations.dateRange'),
        status: t('occupations.status'),
        connectedCompany: t('occupations.connected_company'),
        formats: t('occupations.connectedFormats'),
        companyAdmin: t('occupations.companyAdmin'),
      },
      valueFormatter: {
        connectionType: (value) =>
          value == null
            ? undefined
            : t(`occupations.connectionTypeOptions.${value as OccupationDtoConnectionTypeEnum}`),
        profession: (value) =>
          value == null ? undefined : t(`occupations.professionOptions.${value as ProfessionDto}`),
        workingSectors: (value) =>
          value == null || value.length === 0
            ? undefined
            : value.map((v) => t(`occupations.workingSectorOptions.${v}`)).join(', '),
        mainActivity: (value) => (value == null ? undefined : tCommon(`boolean_viewer.${value as boolean}`)),
        publishJob: (value) => (value == null ? undefined : tCommon(`boolean_viewer.${value as boolean}`)),
        dateRange: (value) => tCommon('format.date_range', { dateRange: value }),
        status: (value) =>
          value == null ? undefined : t(`occupations.statusOptions.${value as OccupationDtoStatusEnum}`),
        connectedCompany: (value) => (value?.name == null ? undefined : value.name),
        companyAdmin: (value) => (value == null ? undefined : tCommon(`boolean_viewer.${value as boolean}`)),
        formats: (value) =>
          value == null || value.length === 0 ? undefined : value.map((format) => format.format.name).join(', '),
      },
      diffEntireWord: {
        connectionType: true,
      },
      name: (entry, _) => {
        return entry.displayName;
      },
      keyAttribute: 'id',
      showEditDescription: true,
    };
  }, [t, tCommon]);
}
