import permissionClassGroupApi from '../../data-access/permission-class-group-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';

export const permissionClassGroupFetcher = createFetcherScope(permissionClassGroupApi);
export const fetchPermissionClassGroup = permissionClassGroupFetcher.createFetcher(
  permissionClassGroupApi.fetchPermissionClassGroup,
);
export const searchPermissionClassGroupItems = permissionClassGroupFetcher.createFetcher(
  permissionClassGroupApi.searchPermissionClassGroupItems,
);
export const fetchPermissionClassGroupHistory = permissionClassGroupFetcher.createFetcher(
  permissionClassGroupApi.fetchPermissionClassGroupHistory,
);
