/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParticipationPassportDataDto
 */
export interface ParticipationPassportDataDto {
    /**
     * 
     * @type {string}
     * @memberof ParticipationPassportDataDto
     */
    passportNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof ParticipationPassportDataDto
     */
    dateOfIssue?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ParticipationPassportDataDto
     */
    dateOfExpiry?: Date;
    /**
     * 
     * @type {string}
     * @memberof ParticipationPassportDataDto
     */
    issuingAuthority?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipationPassportDataDto
     */
    firstNamePassport?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipationPassportDataDto
     */
    surnamePassport?: string;
    /**
     * 
     * @type {Date}
     * @memberof ParticipationPassportDataDto
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the ParticipationPassportDataDto interface.
 */
export function instanceOfParticipationPassportDataDto(value: object): boolean {
    if (!('createdAt' in value)) return false;
    return true;
}

export function ParticipationPassportDataDtoFromJSON(json: any): ParticipationPassportDataDto {
    return ParticipationPassportDataDtoFromJSONTyped(json, false);
}

export function ParticipationPassportDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipationPassportDataDto {
    if (json == null) {
        return json;
    }
    return {
        
        'passportNumber': json['passportNumber'] == null ? undefined : json['passportNumber'],
        'dateOfIssue': json['dateOfIssue'] == null ? undefined : (new Date(json['dateOfIssue'])),
        'dateOfExpiry': json['dateOfExpiry'] == null ? undefined : (new Date(json['dateOfExpiry'])),
        'issuingAuthority': json['issuingAuthority'] == null ? undefined : json['issuingAuthority'],
        'firstNamePassport': json['firstNamePassport'] == null ? undefined : json['firstNamePassport'],
        'surnamePassport': json['surnamePassport'] == null ? undefined : json['surnamePassport'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function ParticipationPassportDataDtoToJSON(value?: ParticipationPassportDataDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'passportNumber': value['passportNumber'],
        'dateOfIssue': value['dateOfIssue'] == null ? undefined : ((value['dateOfIssue']).toISOString().substring(0,10)),
        'dateOfExpiry': value['dateOfExpiry'] == null ? undefined : ((value['dateOfExpiry']).toISOString().substring(0,10)),
        'issuingAuthority': value['issuingAuthority'],
        'firstNamePassport': value['firstNamePassport'],
        'surnamePassport': value['surnamePassport'],
        'createdAt': ((value['createdAt']).toISOString()),
    };
}

