import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useId,
} from '@chakra-ui/react';
import React from 'react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { PlacementTemplateDto } from '../../../../api';
import InputFormControl from '../../../../ui/form/input-form-control';
import usePlacementTemplates from '../placement-template-lister/use-placement-templates';

interface PlacementEditNameFormModalProps {
  isOpen: boolean;
  onClose(name: false | string): void;
  templateId?: string;
  templateName?: string;
}

export default function PlacementTemplateNameFormModal({
  isOpen,
  onClose,
  templateId,
  templateName,
}: PlacementEditNameFormModalProps) {
  const { t } = useTranslation(['common', 'placement_template']);
  const [dirty, setDirty] = React.useState(false);
  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const formRef = React.useRef<UseFormReturn>();
  const formId = useId(undefined, 'placement-edit-name-form-modal');

  return (
    <Modal isOpen={isOpen} onClose={() => onClose(false)} initialFocusRef={initialFocusRef}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {templateName != null ? t('placement_template:action.edit_name') : t('placement_template:action.new')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <PlacementTemplateNameForm
            formId={formId}
            onDirty={setDirty}
            initialFocusRef={initialFocusRef}
            templateId={templateId}
            templateName={templateName}
            onValid={onClose}
            formRef={formRef}
          />
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button
              onFocus={() => {
                formRef.current?.clearErrors();
              }}
              onClick={() => onClose(false)}
            >
              {t('common:action.abort')}
            </Button>
            <Button type="submit" form={formId} variant="primary" isDisabled={!dirty}>
              {templateName != null ? t('common:action.save') : t('common:action.add')}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

interface PlacementTemplateNameFormProps {
  templateId?: string;
  templateName?: string;
  initialFocusRef: React.RefObject<any>;
  onValid(text: string): void;
  onDirty?(dirty: boolean): void;
  formId: string;
  formRef: React.MutableRefObject<UseFormReturn<any> | undefined>;
}

function PlacementTemplateNameForm({
  templateId,
  templateName,
  initialFocusRef,
  onValid,
  onDirty,
  formId,
  formRef,
}: PlacementTemplateNameFormProps) {
  const { t } = useTranslation('placement_template');
  const form = useForm<PlacementTemplateDto>({
    mode: 'all',
    defaultValues: {
      name: templateName,
    },
  });

  const { locationId } = useParams<{ locationId: string }>();
  invariant(locationId, 'Empty locationId');
  const { templates } = usePlacementTemplates(locationId);
  const placementTemplates = templates.content;

  React.useEffect(() => {
    onDirty?.(form.formState.isDirty);
  }, [form.formState, onDirty]);

  React.useImperativeHandle(formRef, () => form, [form]);

  const handleValid = (placement: PlacementTemplateDto) => {
    onValid(placement.name);
  };

  const hasUniqueName = (name: string) => {
    return (
      !placementTemplates.some((pt) => pt.id !== templateId && pt.name.toLowerCase() === name.toLowerCase()) ||
      t('validation.duplicate_name')
    );
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleValid)} noValidate id={formId}>
        <InputFormControl<PlacementTemplateDto>
          name="name"
          label={t('attribute.name')}
          isRequired
          maxLength={50}
          validate={hasUniqueName}
          ref={initialFocusRef}
        />
      </form>
    </FormProvider>
  );
}
