import { Checkbox, Tooltip } from '@chakra-ui/react';
import { difference, intersection, isEmpty } from 'lodash-es';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useDefinedContext from '../../util/context/use-defined-context/use-defined-context';
import DataTableColumn from './data-table-column';
import { DataTableStateContext } from './data-table-context';
import DataTablePage from './data-table-page';
import DataTableSelection from './data-table-selection';

function SelectAllCheckbox({ keysOnPage }: { keysOnPage: string[] }) {
  const { t } = useTranslation(['common']);
  const { state, dispatch } = useDefinedContext(DataTableStateContext);
  const selectedKeys = state.selection || [];

  const allChecked = difference(keysOnPage, selectedKeys).length === 0;
  const anyChecked = intersection(keysOnPage, selectedKeys).length > 0;
  const label = allChecked ? t('common:deselectAll') : t('common:selectAll');
  const isDisabled = isEmpty(keysOnPage);
  return (
    <Tooltip label={label} shouldWrapChildren>
      <Checkbox
        isChecked={!isDisabled && allChecked}
        isIndeterminate={anyChecked && !allChecked}
        isDisabled={isDisabled}
        onChange={(event) =>
          dispatch({
            type: event.target.checked ? 'ADD_SELECTION' : 'REMOVE_SELECTION',
            payload: keysOnPage,
          })
        }
        aria-label={label}
      />
    </Tooltip>
  );
}

export default function useSelectionColumn({
  selection,
  page,
}: {
  selection?: DataTableSelection<any>;
  page: DataTablePage<any>;
}): DataTableColumn<any, any> {
  return useMemo(() => {
    if (selection == null) return null;
    return {
      key: 'selected',
      sticky: true,
      renderCell: (data) => <SelectionCheckbox selection={selection} data={data} />,
      isSortable: false,
      name: !selection.single && (
        <SelectAllCheckbox
          keysOnPage={page.content
            .filter((content) => !(selection.isDisabled?.(content) ?? false))
            .map(selection.keySelector)}
        />
      ),
    };
  }, [page, selection]);
}

export interface SelectionCheckboxProps {
  data: any;
  selection: DataTableSelection<any>;
}

function SelectionCheckbox({ data, selection }: SelectionCheckboxProps) {
  const { state, dispatch } = useDefinedContext(DataTableStateContext);
  const selected = state.selection ?? [];
  const selectionKey = selection.keySelector(data);
  const isSelected = selected.includes(selectionKey);
  const isDisabled = selection.isDisabled?.(data) ?? false;
  const dataTableCheckboxRef = useDefinedContext(DataTableCheckboxRefContext);

  const dispatchAndOnSelectionChange = (checked: boolean) => {
    selection.onChange?.(checked, selection.keySelector(data));

    if (selection.single) {
      if (checked) {
        dispatch({ type: 'SET_SELECTION', payload: [selectionKey] });
      } else {
        dispatch({ type: 'SET_SELECTION', payload: [] });
      }
    } else {
      if (checked) {
        dispatch({ type: 'ADD_SELECTION', payload: [selectionKey] });
      } else {
        dispatch({ type: 'REMOVE_SELECTION', payload: [selectionKey] });
      }
    }
  };

  return (
    <Checkbox
      isChecked={isSelected}
      isDisabled={isDisabled}
      onChange={(event) => dispatchAndOnSelectionChange(event.target.checked)}
      ref={dataTableCheckboxRef}
    />
  );
}

export function adjustColumnIfSticky(column: DataTableColumn<unknown, unknown>) {
  if (!column || !column.sticky) {
    return column;
  }
  return {
    ...column,
    cellProps: { left: '52px' /* width of the checkbox col */, ...column.cellProps },
  };
}

export const DataTableCheckboxRefContext = React.createContext<React.RefObject<any> | undefined>(undefined);
