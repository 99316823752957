/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VisaPermissionDto
 */
export interface VisaPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof VisaPermissionDto
     */
    name: VisaPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof VisaPermissionDto
     */
    action: VisaPermissionDtoActionEnum;
}


/**
 * @export
 */
export const VisaPermissionDtoNameEnum = {
    VISA: 'VISA'
} as const;
export type VisaPermissionDtoNameEnum = typeof VisaPermissionDtoNameEnum[keyof typeof VisaPermissionDtoNameEnum];

/**
 * @export
 */
export const VisaPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    CREATE_SINGLE: 'CAN_CREATE_SINGLE',
    CREATE_ALL: 'CAN_CREATE_ALL',
    DELETE: 'CAN_DELETE'
} as const;
export type VisaPermissionDtoActionEnum = typeof VisaPermissionDtoActionEnum[keyof typeof VisaPermissionDtoActionEnum];


/**
 * Check if a given object implements the VisaPermissionDto interface.
 */
export function instanceOfVisaPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function VisaPermissionDtoFromJSON(json: any): VisaPermissionDto {
    return VisaPermissionDtoFromJSONTyped(json, false);
}

export function VisaPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisaPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function VisaPermissionDtoToJSON(value?: VisaPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

