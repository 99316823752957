/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PersonStatusDto = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    HISTORIC: 'HISTORIC',
    ARCHIVED: 'ARCHIVED',
    ANONYMISED: 'ANONYMISED',
    DELETED: 'DELETED'
} as const;
export type PersonStatusDto = typeof PersonStatusDto[keyof typeof PersonStatusDto];


export function PersonStatusDtoFromJSON(json: any): PersonStatusDto {
    return PersonStatusDtoFromJSONTyped(json, false);
}

export function PersonStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonStatusDto {
    return json as PersonStatusDto;
}

export function PersonStatusDtoToJSON(value?: PersonStatusDto | null): any {
    return value as any;
}

