/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CodeDto } from './CodeDto';
import {
    CodeDtoFromJSON,
    CodeDtoFromJSONTyped,
    CodeDtoToJSON,
} from './CodeDto';

/**
 * 
 * @export
 * @interface CodeSearchResultDto
 */
export interface CodeSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof CodeSearchResultDto
     */
    resultType: CodeSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CodeSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {CodeDto}
     * @memberof CodeSearchResultDto
     */
    result: CodeDto;
}


/**
 * @export
 */
export const CodeSearchResultDtoResultTypeEnum = {
    CODE: 'CODE'
} as const;
export type CodeSearchResultDtoResultTypeEnum = typeof CodeSearchResultDtoResultTypeEnum[keyof typeof CodeSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the CodeSearchResultDto interface.
 */
export function instanceOfCodeSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function CodeSearchResultDtoFromJSON(json: any): CodeSearchResultDto {
    return CodeSearchResultDtoFromJSONTyped(json, false);
}

export function CodeSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodeSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': CodeDtoFromJSON(json['result']),
    };
}

export function CodeSearchResultDtoToJSON(value?: CodeSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': CodeDtoToJSON(value['result']),
    };
}

