import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditionImplicationDto } from '../../../api';
import Checklist from '../../../ui/checklist/checklist';
import ConfirmDialog, { ConfirmDialogProps } from '../../../ui/dialog/confirm-dialog';
import EditionImplicationAgreeChange from '../edition-implication/edition-implication-agree-change';
import { getImplicationType } from '../edition-implication/edition-implication-type';
import EditionImplicationIntro from './edition-implication-intro';

interface EditionImplicationFormDialogProps extends Omit<ConfirmDialogProps, 'confirmActionLabel'> {
  implications: EditionImplicationDto[];
}

export default function EditionImplicationFormDialog({ implications, ...props }: EditionImplicationFormDialogProps) {
  const { t } = useTranslation('common');
  const { t: tEdition } = useTranslation('edition');
  const [allImplicationChecked, setAllImplicationChecked] = React.useState<boolean>(false);
  const initFocusRef = React.useRef<HTMLInputElement>(null);

  return (
    <ConfirmDialog
      {...props}
      initialFocusRef={initFocusRef}
      confirmActionLabel={t('action.save', { object: tEdition('edition') })}
      isConfirmActionDisabled={!allImplicationChecked}
      submitButtonVariant="primary"
    >
      <p>
        <EditionImplicationIntro
          affectedEditions={implications.map((implication) => implication.affected)}
          type={getImplicationType(implications)}
        />
      </p>
      <Checklist
        mt={2}
        initialFocusRef={initFocusRef}
        tasks={implications.map((implication) => (
          <EditionImplicationAgreeChange implication={implication} />
        ))}
        onAllChecked={setAllImplicationChecked}
      />
    </ConfirmDialog>
  );
}
