/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PermissionsDto } from './PermissionsDto';
import {
    PermissionsDtoFromJSON,
    PermissionsDtoFromJSONTyped,
    PermissionsDtoToJSON,
} from './PermissionsDto';
import type { StaffListItemViewerDto } from './StaffListItemViewerDto';
import {
    StaffListItemViewerDtoFromJSON,
    StaffListItemViewerDtoFromJSONTyped,
    StaffListItemViewerDtoToJSON,
} from './StaffListItemViewerDto';
import type { TeamDto } from './TeamDto';
import {
    TeamDtoFromJSON,
    TeamDtoFromJSONTyped,
    TeamDtoToJSON,
} from './TeamDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * The connection between a team and a staff
 * @export
 * @interface TeamStaffRelationDto
 */
export interface TeamStaffRelationDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof TeamStaffRelationDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof TeamStaffRelationDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {PermissionsDto}
     * @memberof TeamStaffRelationDto
     */
    permissions?: PermissionsDto;
    /**
     * 
     * @type {TeamDto}
     * @memberof TeamStaffRelationDto
     */
    team: TeamDto;
    /**
     * 
     * @type {StaffListItemViewerDto}
     * @memberof TeamStaffRelationDto
     */
    staff: StaffListItemViewerDto;
}

/**
 * Check if a given object implements the TeamStaffRelationDto interface.
 */
export function instanceOfTeamStaffRelationDto(value: object): boolean {
    if (!('team' in value)) return false;
    if (!('staff' in value)) return false;
    return true;
}

export function TeamStaffRelationDtoFromJSON(json: any): TeamStaffRelationDto {
    return TeamStaffRelationDtoFromJSONTyped(json, false);
}

export function TeamStaffRelationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamStaffRelationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'permissions': json['permissions'] == null ? undefined : PermissionsDtoFromJSON(json['permissions']),
        'team': TeamDtoFromJSON(json['team']),
        'staff': StaffListItemViewerDtoFromJSON(json['staff']),
    };
}

export function TeamStaffRelationDtoToJSON(value?: TeamStaffRelationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'permissions': PermissionsDtoToJSON(value['permissions']),
        'team': TeamDtoToJSON(value['team']),
        'staff': StaffListItemViewerDtoToJSON(value['staff']),
    };
}

