import { popoverAnatomy } from '@chakra-ui/anatomy';
import { cssVar } from '@chakra-ui/system';
import { MultiStyleConfig } from '@chakra-ui/theme-tools';

const Popover: MultiStyleConfig<typeof popoverAnatomy> = {
  parts: popoverAnatomy.keys,
  baseStyle: {
    popper: { zIndex: 'popover' },
    content: {
      borderColor: 'border.01',
      borderRadius: 'base',
      bg: 'layer.01',
      [cssVar('popper-bg').variable]: 'colors.layer.01',
      color: 'inherit',
      boxShadow: 'md',
      _dark: {
        [cssVar('popper-bg').variable]: 'colors.layer.01',
      },
    },
  },
  variants: {
    helper() {
      const $popperBg = cssVar('popper-bg');

      const $arrowBg = cssVar('popper-arrow-bg');
      const $arrowShadowColor = cssVar('popper-arrow-shadow-color');

      // helper popover colors are the same for light and dark mode
      const shadowColor = 'whiteAlpha.300';
      const bg = 'gray.700';
      const fg = 'whiteAlpha.900';
      const headerFg = 'teal.200';

      return {
        popper: { bg: bg },
        header: {
          fontWeight: 'semibold',
          color: headerFg,
        },
        content: {
          [$popperBg.variable]: `colors.${bg}`,
          bg: $popperBg.reference,
          [$arrowBg.variable]: $popperBg.reference,
          [$arrowShadowColor.variable]: `colors.${shadowColor}`,
          fontWeight: 'medium',
          fontSize: 'sm',
          borderRadius: 'sm',
          borderColor: bg,
          width: 'unset',
          maxW: '320px',
          shadowColor: shadowColor,
          color: fg,
          _focus: {
            outline: 0,
            boxShadow: 'outline',
          },
        },
        closeButton: {
          my: '1',
          color: headerFg,
        },
      };
    },
  },
};

export default Popover;
