/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OperationDto } from './OperationDto';
import {
    OperationDtoFromJSON,
    OperationDtoFromJSONTyped,
    OperationDtoToJSON,
} from './OperationDto';
import type { OperationTypeDto } from './OperationTypeDto';
import {
    OperationTypeDtoFromJSON,
    OperationTypeDtoFromJSONTyped,
    OperationTypeDtoToJSON,
} from './OperationTypeDto';

/**
 * 
 * @export
 * @interface SortOperationDto
 */
export interface SortOperationDto {
    /**
     * 
     * @type {OperationTypeDto}
     * @memberof SortOperationDto
     */
    operationType: OperationTypeDto;
    /**
     * 
     * @type {Array<OperationDto>}
     * @memberof SortOperationDto
     */
    subOperations: Array<OperationDto>;
}

/**
 * Check if a given object implements the SortOperationDto interface.
 */
export function instanceOfSortOperationDto(value: object): boolean {
    if (!('operationType' in value)) return false;
    if (!('subOperations' in value)) return false;
    return true;
}

export function SortOperationDtoFromJSON(json: any): SortOperationDto {
    return SortOperationDtoFromJSONTyped(json, false);
}

export function SortOperationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortOperationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'operationType': OperationTypeDtoFromJSON(json['operationType']),
        'subOperations': ((json['subOperations'] as Array<any>).map(OperationDtoFromJSON)),
    };
}

export function SortOperationDtoToJSON(value?: SortOperationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'operationType': OperationTypeDtoToJSON(value['operationType']),
        'subOperations': ((value['subOperations'] as Array<any>).map(OperationDtoToJSON)),
    };
}

