/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderConfirmationPaymentResultDto
 */
export interface OrderConfirmationPaymentResultDto {
    /**
     * 
     * @type {Date}
     * @memberof OrderConfirmationPaymentResultDto
     */
    receiptOfPayment?: Date;
    /**
     * 
     * @type {string}
     * @memberof OrderConfirmationPaymentResultDto
     */
    method: string;
    /**
     * 
     * @type {string}
     * @memberof OrderConfirmationPaymentResultDto
     */
    reason?: string;
}

/**
 * Check if a given object implements the OrderConfirmationPaymentResultDto interface.
 */
export function instanceOfOrderConfirmationPaymentResultDto(value: object): boolean {
    if (!('method' in value)) return false;
    return true;
}

export function OrderConfirmationPaymentResultDtoFromJSON(json: any): OrderConfirmationPaymentResultDto {
    return OrderConfirmationPaymentResultDtoFromJSONTyped(json, false);
}

export function OrderConfirmationPaymentResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderConfirmationPaymentResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'receiptOfPayment': json['receiptOfPayment'] == null ? undefined : (new Date(json['receiptOfPayment'])),
        'method': json['method'],
        'reason': json['reason'] == null ? undefined : json['reason'],
    };
}

export function OrderConfirmationPaymentResultDtoToJSON(value?: OrderConfirmationPaymentResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'receiptOfPayment': value['receiptOfPayment'] == null ? undefined : ((value['receiptOfPayment']).toISOString().substring(0,10)),
        'method': value['method'],
        'reason': value['reason'],
    };
}

