/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EventReferenceDto } from './EventReferenceDto';
import {
    EventReferenceDtoFromJSON,
    EventReferenceDtoFromJSONTyped,
    EventReferenceDtoToJSON,
} from './EventReferenceDto';
import type { LaneDto } from './LaneDto';
import {
    LaneDtoFromJSON,
    LaneDtoFromJSONTyped,
    LaneDtoToJSON,
} from './LaneDto';

/**
 * 
 * @export
 * @interface LaneSearchResultDto
 */
export interface LaneSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof LaneSearchResultDto
     */
    resultType: LaneSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof LaneSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {LaneDto}
     * @memberof LaneSearchResultDto
     */
    lane: LaneDto;
    /**
     * 
     * @type {EventReferenceDto}
     * @memberof LaneSearchResultDto
     */
    event: EventReferenceDto;
}


/**
 * @export
 */
export const LaneSearchResultDtoResultTypeEnum = {
    RUNDOWN_LANE: 'RUNDOWN_LANE'
} as const;
export type LaneSearchResultDtoResultTypeEnum = typeof LaneSearchResultDtoResultTypeEnum[keyof typeof LaneSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the LaneSearchResultDto interface.
 */
export function instanceOfLaneSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('lane' in value)) return false;
    if (!('event' in value)) return false;
    return true;
}

export function LaneSearchResultDtoFromJSON(json: any): LaneSearchResultDto {
    return LaneSearchResultDtoFromJSONTyped(json, false);
}

export function LaneSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaneSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'lane': LaneDtoFromJSON(json['lane']),
        'event': EventReferenceDtoFromJSON(json['event']),
    };
}

export function LaneSearchResultDtoToJSON(value?: LaneSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'lane': LaneDtoToJSON(value['lane']),
        'event': EventReferenceDtoToJSON(value['event']),
    };
}

