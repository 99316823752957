/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EventPermissionDto
 */
export interface EventPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof EventPermissionDto
     */
    name: EventPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof EventPermissionDto
     */
    action: EventPermissionDtoActionEnum;
}


/**
 * @export
 */
export const EventPermissionDtoNameEnum = {
    EVENT: 'EVENT'
} as const;
export type EventPermissionDtoNameEnum = typeof EventPermissionDtoNameEnum[keyof typeof EventPermissionDtoNameEnum];

/**
 * @export
 */
export const EventPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    SEE_DETAILS_OWN: 'CAN_SEE_DETAILS_OWN',
    CREATE: 'CAN_CREATE',
    COPY: 'CAN_COPY',
    COPY_OWN: 'CAN_COPY_OWN',
    EDIT: 'CAN_EDIT',
    EDIT_OWN: 'CAN_EDIT_OWN',
    ACCESS_ATTACHMENTS: 'CAN_ACCESS_ATTACHMENTS',
    ACCESS_ATTACHMENTS_OWN: 'CAN_ACCESS_ATTACHMENTS_OWN',
    DELETE: 'CAN_DELETE',
    DELETE_OWN: 'CAN_DELETE_OWN',
    SEE_COMMENT: 'CAN_SEE_COMMENT',
    SEE_COMMENT_OWN: 'CAN_SEE_COMMENT_OWN',
    ADD_COMMENT: 'CAN_ADD_COMMENT',
    ADD_COMMENT_OWN: 'CAN_ADD_COMMENT_OWN',
    EDIT_COMMENT: 'CAN_EDIT_COMMENT',
    EDIT_COMMENT_OWN: 'CAN_EDIT_COMMENT_OWN',
    DELETE_COMMENT: 'CAN_DELETE_COMMENT',
    DELETE_COMMENT_OWN: 'CAN_DELETE_COMMENT_OWN'
} as const;
export type EventPermissionDtoActionEnum = typeof EventPermissionDtoActionEnum[keyof typeof EventPermissionDtoActionEnum];


/**
 * Check if a given object implements the EventPermissionDto interface.
 */
export function instanceOfEventPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function EventPermissionDtoFromJSON(json: any): EventPermissionDto {
    return EventPermissionDtoFromJSONTyped(json, false);
}

export function EventPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function EventPermissionDtoToJSON(value?: EventPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

