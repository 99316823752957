import React from 'react';
import { Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import Checklist from '../../../ui/checklist/checklist';
import ConfirmDialog from '../../../ui/dialog/confirm-dialog';

export interface AccountConvertDialogProps {
  displayName: string;
  isOpen: boolean;

  onClose(confirm: boolean): void;
}

export default function AccountConvertDialog({ onClose, isOpen, displayName, ...props }: AccountConvertDialogProps) {
  const { t } = useTranslation(['account', 'common']);
  const submitButtonText = t('account:downgrade');
  const initFocusRef = React.useRef<HTMLInputElement>(null);

  const [isChecked, setChecked] = React.useState<boolean>(false);
  const onCloseWithReset = React.useCallback(
    (confirm: boolean) => {
      setChecked(false);
      onClose(confirm);
    },
    [onClose],
  );

  const checkListItems = [
    <Text>
      <Trans
        ns="account"
        i18nKey="acceptDowngrade"
        values={{
          object: displayName,
        }}
      />
    </Text>,
  ];

  return (
    <ConfirmDialog
      {...props}
      isOpen={isOpen}
      initialFocusRef={initFocusRef}
      confirmActionLabel={submitButtonText}
      isConfirmActionDisabled={!isChecked}
      onClose={onCloseWithReset}
      submitButtonColorScheme="red"
    >
      <Checklist initialFocusRef={initFocusRef} tasks={checkListItems} onAllChecked={setChecked} />
    </ConfirmDialog>
  );
}
