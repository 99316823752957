import { Link } from '@chakra-ui/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

interface TranslationWithLinkProps {
  entity: { id?: string; [others: string]: any };
  route: string;
  i18nKey: any;
  openInNewTab?: boolean;
}

export default function TranslationWithLink({ entity, route, i18nKey, openInNewTab }: TranslationWithLinkProps) {
  const { t, ready } = useTranslation(['location', 'event'], { useSuspense: false });

  if (!ready) {
    return null;
  }

  return (
    <span>
      <Trans
        t={t}
        i18nKey={i18nKey}
        values={entity}
        components={{
          entityLink: (
            <Link as={RouterLink} target={openInNewTab ? '_blank' : undefined} to={`/${route}/${entity.id}/edit`} />
          ),
        }}
      />
    </span>
  );
}
