import React from 'react';
import { LocationReferenceDto, LocationStatusDto } from '../../../api';
import locationApi from '../../../data-access/location-api';
import ValueAsyncSelectControl, {
  ValueAsyncSelectControlProps,
} from '../../../ui/form/select-control/value-async-select-control';

export interface LocationSelectControlProps
  extends Omit<
    ValueAsyncSelectControlProps<LocationReferenceDto>,
    'loadOptions' | 'renderLabel' | 'optionIdentifier' | 'defaultOptions'
  > {
  filter?: string[];
}

function LocationSelectControl(
  { filter = [`status,eq,${LocationStatusDto.ACTIVE}`, `visible,eq,true`], ...props }: LocationSelectControlProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  return (
    <ValueAsyncSelectControl<LocationReferenceDto>
      {...props}
      ref={ref}
      loadOptions={async (q: string, size: number) => {
        const page = await locationApi.searchLocations({
          pageable: { size },
          filter,
          q,
        });
        return page.content
          .filter((l) => l.visible)
          .map((location) => ({
            id: location.id!,
            name: location.name,
            englishName: location.englishName,
            addresses: [],
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
      }}
      defaultOptions={false}
      renderLabel={(location) => location.name}
      optionIdentifier={(location) => location.id}
    />
  );
}

export default React.forwardRef(LocationSelectControl);
