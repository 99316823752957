import React from 'react';
import invariant from 'tiny-invariant';
import { FormatOccupationRelationDto } from '../../../../api';
import HistoryPage from '../../../../ui/history/history-page';
import { PageState } from '../../../../ui/pagination/use-pagination-state';
import useFetcher from '../../../../util/swr/use-fetcher';
import { FormatHistoryExtension } from '../../../format/format-relation/format-history-extension';
import { fetchFormatOccupationHistory } from '../format-occupation-queries';
import useFormatOccupationHistorySettings from './use-format-occupation-history-settings';

export const formatOccupationHistoryExtension: FormatHistoryExtension = {
  content: (formatId, state, onStateChanged) => (
    <FormatOccupationHistory formatId={formatId} state={state} onStateChanged={onStateChanged} />
  ),
};

interface PersonAccessGroupHistoryProps {
  formatId: string;
  state: PageState;
  onStateChanged: (state: PageState) => void;
}

function FormatOccupationHistory({ formatId, state, onStateChanged }: PersonAccessGroupHistoryProps) {
  const formatOccupationHistorySettings = useFormatOccupationHistorySettings();

  const formatOccupationHistoryPage = useFetcher(fetchFormatOccupationHistory, {
    id: formatId,
    pageable: state,
  });
  invariant(formatOccupationHistoryPage != null, 'Missing format occupation history page');

  return (
    <HistoryPage<FormatOccupationRelationDto>
      page={formatOccupationHistoryPage}
      state={state}
      onStateChange={onStateChanged}
      historyDisplaySettings={formatOccupationHistorySettings}
      showLine={false}
    />
  );
}
