/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TeamReferenceDto
 */
export interface TeamReferenceDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof TeamReferenceDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TeamReferenceDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TeamReferenceDto
     */
    englishName: string;
}

/**
 * Check if a given object implements the TeamReferenceDto interface.
 */
export function instanceOfTeamReferenceDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('englishName' in value)) return false;
    return true;
}

export function TeamReferenceDtoFromJSON(json: any): TeamReferenceDto {
    return TeamReferenceDtoFromJSONTyped(json, false);
}

export function TeamReferenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamReferenceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'englishName': json['englishName'],
    };
}

export function TeamReferenceDtoToJSON(value?: TeamReferenceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'englishName': value['englishName'],
    };
}

