/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ModuleTypeDto } from './ModuleTypeDto';
import {
    ModuleTypeDtoFromJSON,
    ModuleTypeDtoFromJSONTyped,
    ModuleTypeDtoToJSON,
} from './ModuleTypeDto';
import type { RichTextOptionsDto } from './RichTextOptionsDto';
import {
    RichTextOptionsDtoFromJSON,
    RichTextOptionsDtoFromJSONTyped,
    RichTextOptionsDtoToJSON,
} from './RichTextOptionsDto';

/**
 * 
 * @export
 * @interface MailingTextModuleSettingsDto
 */
export interface MailingTextModuleSettingsDto {
    /**
     * 
     * @type {ModuleTypeDto}
     * @memberof MailingTextModuleSettingsDto
     */
    moduleType: ModuleTypeDto;
    /**
     * 
     * @type {Date}
     * @memberof MailingTextModuleSettingsDto
     */
    timestamp?: Date;
    /**
     * 
     * @type {Array<RichTextOptionsDto>}
     * @memberof MailingTextModuleSettingsDto
     */
    richTextOptions: Array<RichTextOptionsDto>;
}

/**
 * Check if a given object implements the MailingTextModuleSettingsDto interface.
 */
export function instanceOfMailingTextModuleSettingsDto(value: object): boolean {
    if (!('moduleType' in value)) return false;
    if (!('richTextOptions' in value)) return false;
    return true;
}

export function MailingTextModuleSettingsDtoFromJSON(json: any): MailingTextModuleSettingsDto {
    return MailingTextModuleSettingsDtoFromJSONTyped(json, false);
}

export function MailingTextModuleSettingsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingTextModuleSettingsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'moduleType': ModuleTypeDtoFromJSON(json['moduleType']),
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
        'richTextOptions': ((json['richTextOptions'] as Array<any>).map(RichTextOptionsDtoFromJSON)),
    };
}

export function MailingTextModuleSettingsDtoToJSON(value?: MailingTextModuleSettingsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'moduleType': ModuleTypeDtoToJSON(value['moduleType']),
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
        'richTextOptions': ((value['richTextOptions'] as Array<any>).map(RichTextOptionsDtoToJSON)),
    };
}

