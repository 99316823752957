import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { CompanyDto, StandCompanyRelationDto } from '../../../api';
import standApi from '../../../data-access/stand-api';
import Form from '../../../ui/form/form';
import SubmitButton from '../../../ui/form/submit-button';
import useToast from '../../../ui/use-toast/use-toast';
import { standFetcher } from '../stand-queries';
import CompanyStandRelationEditor from './company-stand-relation-editor';

interface CompanyStandRelationEditorDialogProps {
  isOpen: boolean;
  onClose: () => void;
  company: CompanyDto;
}

export default function CompanyStandRelationEditorDialog({
  isOpen,
  onClose,
  company,
}: CompanyStandRelationEditorDialogProps) {
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick={false} initialFocusRef={initialFocusRef}>
      <ModalOverlay />
      <ModalContent>
        <React.Suspense>
          <CompanyStandRelationModal initialFocusRef={initialFocusRef} onClose={onClose} company={company} />
        </React.Suspense>
      </ModalContent>
    </Modal>
  );
}

interface CompanyStandRelationModalProps {
  initialFocusRef?: React.RefObject<any>;
  onClose: () => void;
  company: CompanyDto;
}

function CompanyStandRelationModal({ initialFocusRef, onClose, company }: CompanyStandRelationModalProps) {
  const { t } = useTranslation(['common', 'stand']);

  const form = useForm<StandCompanyRelationDto>({
    mode: 'all',
    defaultValues: {
      id: uuid(),
      company: { id: company.id, name: company.name },
    },
  });

  const showSuccessToast = useToast({
    id: 'add-stand-to-company-success-toast',
    status: 'success',
  });

  const handleValid = async (standCompanyRelation: StandCompanyRelationDto) => {
    await standApi.createStandCompanyRelation({
      standCompanyRelationDto: standCompanyRelation,
    });

    showSuccessToast({
      title: t('stand:companyRelation.toast.success.titleStand'),
      description: t('stand:companyRelation.toast.success.description'),
    });

    onClose();

    await standFetcher.mutate();
  };

  const formIsDirty = Object.keys(form.formState.dirtyFields).length > 0;
  const { isValid } = form.formState;

  return (
    <FormProvider {...form}>
      <Form<StandCompanyRelationDto> onValid={handleValid} initialFocusRef={initialFocusRef}>
        <ModalHeader>{t('stand:companyRelation.actions.addStand')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CompanyStandRelationEditor fromName={company.name} initialFocusRef={initialFocusRef} />
        </ModalBody>
        <ModalFooter>
          <ButtonGroup spacing={4}>
            <Button onClick={onClose}>{t('common:action.abort')}</Button>
            <SubmitButton variant="primary" isDisabled={!formIsDirty || !isValid}>
              {t('common:action.add')}
            </SubmitButton>
          </ButtonGroup>
        </ModalFooter>
      </Form>
    </FormProvider>
  );
}
