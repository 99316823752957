import React from 'react';
import { useTranslation } from 'react-i18next';
import { VersionDto } from '../../api';
import { DescriptionItem } from '../description';
import useLastModified from './use-last-modified';

export interface LastModifiedDescriptionItemProps {
  version?: VersionDto;
  label?: string;
}

export default function LastModifiedDescriptionItem({ version, label }: LastModifiedDescriptionItemProps) {
  const { t } = useTranslation('common');
  const lastModifiedString = useLastModified(version);

  return (
    <DescriptionItem label={label ?? t('viewer.last_modification')} span={2}>
      {lastModifiedString}
    </DescriptionItem>
  );
}
