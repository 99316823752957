import {
  IconButton,
  IconButtonProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface HelperPopoverProps {
  header?: React.ReactNode;
  children: React.ReactNode;
  ariaLabel?: string;
  variant?: string;
  size?: IconButtonProps['size'];
}

/**
 * Question mark icon with popover containing configurable helper text.
 */
export default function HelperPopover({
  header,
  children,
  ariaLabel,
  variant = 'ghost',
  size = 'sm',
}: HelperPopoverProps) {
  const { t } = useTranslation('common');

  return (
    <Popover variant="helper">
      <PopoverTrigger>
        <IconButton
          variant={variant}
          size={size}
          icon={<FontAwesomeIcon icon={faQuestionCircle} />}
          aria-label={ariaLabel ?? t('show_help')}
          height="6"
          minWidth="6"
          onClick={(event) => event.stopPropagation()}
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent rootProps={{ zIndex: 'modal' }}>
          <PopoverArrow />
          {header && <PopoverCloseButton />}
          {header && <PopoverHeader>{header}</PopoverHeader>}
          <PopoverBody fontWeight="medium">{children}</PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
