/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TicketingPriorityDto = {
    ONE: 'ONE',
    TWO: 'TWO',
    THREE: 'THREE'
} as const;
export type TicketingPriorityDto = typeof TicketingPriorityDto[keyof typeof TicketingPriorityDto];


export function TicketingPriorityDtoFromJSON(json: any): TicketingPriorityDto {
    return TicketingPriorityDtoFromJSONTyped(json, false);
}

export function TicketingPriorityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TicketingPriorityDto {
    return json as TicketingPriorityDto;
}

export function TicketingPriorityDtoToJSON(value?: TicketingPriorityDto | null): any {
    return value as any;
}

