import { invoiceIcon } from '../../../ui/icons/business-objects';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';

interface InvoiceReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  invoice: { id: string; invoiceNumber: string };
}

export default function InvoiceReference({ invoice, ...props }: InvoiceReferenceProps) {
  return (
    <Reference
      icon={invoiceIcon}
      to={'/invoices/' + invoice.id}
      necessaryPermission="INVOICE:CAN_SEE_DETAILS"
      {...props}
    >
      {invoice.invoiceNumber}
    </Reference>
  );
}
