import landingPageDesignApi from '../../data-access/landing-page-design-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';

export const landingPageDesignFetcher = createFetcherScope(landingPageDesignApi);
export const fetchLandingPageDesign = landingPageDesignFetcher.createFetcher(
  landingPageDesignApi.fetchLandingPageDesign,
);
export const fetchLandingPageDesigns = landingPageDesignFetcher.createFetcher(
  landingPageDesignApi.searchLandingPageDesigns,
);
export const fetchLandingPageDesignHistory = landingPageDesignFetcher.createFetcher(
  landingPageDesignApi.fetchLandingPageDesignHistory,
);

export const getLandingPageDesignStatusBlockers = landingPageDesignFetcher.createFetcher(
  landingPageDesignApi.getLandingPageDesignStatusBlockers,
);
