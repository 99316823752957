/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Staff that will be added to a group.
 * @export
 * @interface GroupStaffAdminPermissionDto
 */
export interface GroupStaffAdminPermissionDto {
    /**
     * 
     * @type {boolean}
     * @memberof GroupStaffAdminPermissionDto
     */
    maxAdminCountReached: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GroupStaffAdminPermissionDto
     */
    lastAdminWithStaff: boolean;
}

/**
 * Check if a given object implements the GroupStaffAdminPermissionDto interface.
 */
export function instanceOfGroupStaffAdminPermissionDto(value: object): boolean {
    if (!('maxAdminCountReached' in value)) return false;
    if (!('lastAdminWithStaff' in value)) return false;
    return true;
}

export function GroupStaffAdminPermissionDtoFromJSON(json: any): GroupStaffAdminPermissionDto {
    return GroupStaffAdminPermissionDtoFromJSONTyped(json, false);
}

export function GroupStaffAdminPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupStaffAdminPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'maxAdminCountReached': json['maxAdminCountReached'],
        'lastAdminWithStaff': json['lastAdminWithStaff'],
    };
}

export function GroupStaffAdminPermissionDtoToJSON(value?: GroupStaffAdminPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'maxAdminCountReached': value['maxAdminCountReached'],
        'lastAdminWithStaff': value['lastAdminWithStaff'],
    };
}

