import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { TagRelationTypeDto } from '../../../../api';
import productApi from '../../../../data-access/product-api';
import { BreadcrumbRouteObject } from '../../../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../../../ui/helmet/helmet-outlet';
import { productIcon } from '../../../../ui/icons/business-objects';
import useFetcher from '../../../../util/swr/use-fetcher';
import usePromise from '../../../../util/use-promise/use-promise';
import ProductPageRoute from '../../../product/product-page/product-page-route';
import { fetchProduct } from '../../../product/product-queries';
import usePermission from '../../../permission/use-permission';

const ProductTagRelationContent = React.lazy(() => import('../tag-relation-page-content'));

const productTagRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: '',
    element: <ProductPageRoute />,
    children: [
      {
        path: 'tags',
        element: <ProductTagContent />,
        handle: {
          helmet: <ProductTagsHelmet />,
        },
      },
    ],
  },
];

export default productTagRoutes;

function ProductTagContent() {
  const relationType = TagRelationTypeDto.PRODUCT;

  const params = useParams<{ productId: string }>();
  const productId = params.productId;
  invariant(productId, 'Empty productId');

  const product = usePromise((signal) => productApi.fetchProduct({ id: productId }, { signal }), [productId]);
  const productRef = {
    id: productId,
    title: product?.title,
    titleEnglish: product?.titleEnglish,
    articleNumber: product?.articleNumber,
    availability: product?.price?.availability,
  };

  const fromAnnotation = productRef.title!;

  const { hasPermission } = usePermission();

  return (
    <ProductTagRelationContent
      relationRef={productRef}
      relationType={relationType}
      fromIcon={productIcon}
      fromAnnotation={fromAnnotation}
      canLink={hasPermission('PRODUCT-TAG:CAN_LINK')}
      canUnlink={hasPermission('PRODUCT-TAG:CAN_UNLINK')}
    />
  );
}

function ProductTagsHelmet() {
  const { t } = useTranslation('tag');
  const params = useParams<{ productId: string }>();
  invariant(params.productId, 'Empty productId');
  const product = useFetcher(fetchProduct, { id: params.productId });

  return (
    <Helmet
      title={t('relations.product.helmet', {
        name: product.title,
      })}
    />
  );
}
