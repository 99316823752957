import { ParseKeys } from 'i18next';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import registry from '../../registry';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import useFetcher from '../../util/swr/use-fetcher';
import Translate from '../../util/translate/translate';
import getSectionId from './common/get-section-id';
import { SECTION_PAGE_ROUTES_CALLBACK } from './section-page/section-page-route';
import { fetchSection } from './section-queries';
import SectionRelationViewer from './section-relation/section-relation-viewer';
import { SECTION_ROUTES_CALLBACK } from './section-route';

const SectionPage = React.lazy(() => import('./section-page/section-page'));
const SectionLister = React.lazy(() => import('./section-lister/section-lister'));
const SectionEditor = React.lazy(() => import('./section-editor/section-editor'));
const SectionViewer = React.lazy(() => import('./section-viewer/section-viewer'));
const SectionHistory = React.lazy(() => import('./section-history/section-history'));

const sectionRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'sections',
    handle: {
      breadcrumb: <Translate ns="section" i18nKey="sections" />,
    },
    children: [
      {
        path: '',
        element: <SectionLister />,
        handle: {
          helmet: <Translate ns="section">{(t) => <Helmet title={t('lister.helmet')} />}</Translate>,
        },
      },
      {
        path: 'new',
        handle: {
          breadcrumb: <Translate ns="section" i18nKey="action.new" />,
          helmet: <Translate ns="section">{(t) => <Helmet title={t('editor.helmetNew')} />}</Translate>,
        },
        element: <SectionEditor />,
      },
      {
        path: ':sectionId',
        handle: {
          breadcrumb: <SectionBreadcrumb />,
        },
        children: [
          {
            path: '',
            element: <SectionPage />,
            children: [
              {
                path: '',
                handle: {
                  helmet: <SectionHelmet i18nKey="viewer.helmet" />,
                },
                element: <SectionViewer />,
              },
              {
                path: 'relations',
                handle: {
                  helmet: <SectionHelmet i18nKey="relations.helmet" />,
                },
                element: <SectionRelationViewer />,
              },
              {
                path: 'history',
                handle: {
                  helmet: <SectionHelmet i18nKey="history.helmet" />,
                },
                element: <SectionHistory />,
              },
              ...registry.callAll(SECTION_PAGE_ROUTES_CALLBACK).flat(),
            ],
          },
          {
            path: 'edit',
            handle: {
              breadcrumb: <Translate ns="section" i18nKey="action.edit" />,
              helmet: <SectionHelmet i18nKey="editor.helmetEdit" />,
            },
            element: <SectionEditor />,
          },
          ...registry.callAll(SECTION_ROUTES_CALLBACK).flat(),
        ],
      },
    ],
  },
];

interface SectionHelmetProps {
  i18nKey: ParseKeys<'section'>;
}

function SectionHelmet({ i18nKey }: SectionHelmetProps) {
  const { t } = useTranslation('section');
  const { name } = useFetcher(fetchSection, { id: getSectionId(useParams()) });

  return <Helmet title={t(i18nKey, { section: name })} />;
}

function SectionBreadcrumb() {
  const params = useParams<{ sectionId: string }>();
  invariant(params.sectionId, 'Empty sectionId');
  const { name } = useFetcher(fetchSection, { id: params.sectionId });

  return <>{`${name}`}</>;
}

export default sectionRoutes;
