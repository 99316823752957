import { Button, Stack } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { AddPeoplePeopleInnerDto, CategoryOfParticipationDto, GuestTypeDto, PersonReferenceDto } from '../../../api';
import guestListApi from '../../../data-access/guest-list-api';
import FormControl from '../../../ui/form/form-control';
import ValueSelectFormControl from '../../../ui/form/select-control/value-select-form-control';
import PersonSelectControl from '../../common/form/person-select-control/person-select-control';

interface PersonSelectionControlProps {
  categories: Array<CategoryOfParticipationDto>;
  guestListId: string;
  peopleAlreadyOnList: AddPeoplePeopleInnerDto[];
  onAdd: (entry: AddPeoplePeopleInnerDto) => void;
}

interface PersonEntry {
  person?: PersonReferenceDto;
  category: CategoryOfParticipationDto;
}

export default function PersonSelectionControl({
  categories,
  guestListId,
  peopleAlreadyOnList,
  onAdd,
}: PersonSelectionControlProps) {
  const form = useForm<PersonEntry>({ mode: 'onChange' });
  const { t: tCommon } = useTranslation('common');
  const { t } = useTranslation('guest_list');

  const handleAdd = (entry: Omit<AddPeoplePeopleInnerDto, 'id'>) => {
    // reset before add to clear person field before validation is triggered
    if (validateNoLocalDuplicate(entry.person!)) {
      form.reset({ category: entry.category });
      onAdd({ guestType: GuestTypeDto.PERSON, person: entry.person, category: entry.category, id: uuid() });
    }
  };

  const personIsDirty = form.getFieldState('person', form.formState).isDirty;

  // triggers validation when removing person in parent component
  useEffect(() => {
    if (personIsDirty) {
      form.trigger('person');
    }
  }, [form, peopleAlreadyOnList, personIsDirty]);

  const validateNoLocalDuplicate = (person: PersonReferenceDto) => {
    return (
      peopleAlreadyOnList.find((e) => e.person?.id === person.id) == null ||
      t('guest_list_people_connection.validation.duplicate_person')
    );
  };

  const validateNoRemoteDuplicate = async (person: PersonReferenceDto) => {
    const { value: canAddPerson } = await guestListApi.canAddPerson({
      personId: person.id,
      guestListId: guestListId,
    });
    return canAddPerson || t('guest_list_people_connection.validation.duplicate_person');
  };

  return (
    <FormProvider {...form}>
      <Stack spacing={4} paddingX={4} paddingBottom={4} paddingTop={3}>
        <FormControl name="person" label={t('person')} size="sm" isRequired>
          <PersonSelectControl
            size="sm"
            name="person"
            isRequired
            enableQuickPerson
            label={t('person')}
            flipName
            rules={{
              validate: {
                validateNoLocalDuplicate,
                validateNoRemoteDuplicate,
              },
            }}
          />
        </FormControl>
        <ValueSelectFormControl<CategoryOfParticipationDto>
          name="category"
          label={t('categoryOfParticipation')}
          options={categories}
          renderLabel={(category) => t(`categoryOfParticipationOptions.${category}`)}
          isRequired
          size="sm"
        />

        <Button
          onClick={form.handleSubmit(handleAdd)}
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
          size="sm"
          variant="outline"
          isLoading={form.formState.isSubmitting}
        >
          {tCommon('action.add')}
        </Button>
      </Stack>
    </FormProvider>
  );
}
