/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MergePersonIdsDto
 */
export interface MergePersonIdsDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof MergePersonIdsDto
     */
    personAId: string;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof MergePersonIdsDto
     */
    personBId: string;
}

/**
 * Check if a given object implements the MergePersonIdsDto interface.
 */
export function instanceOfMergePersonIdsDto(value: object): boolean {
    if (!('personAId' in value)) return false;
    if (!('personBId' in value)) return false;
    return true;
}

export function MergePersonIdsDtoFromJSON(json: any): MergePersonIdsDto {
    return MergePersonIdsDtoFromJSONTyped(json, false);
}

export function MergePersonIdsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MergePersonIdsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'personAId': json['personAId'],
        'personBId': json['personBId'],
    };
}

export function MergePersonIdsDtoToJSON(value?: MergePersonIdsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'personAId': value['personAId'],
        'personBId': value['personBId'],
    };
}

