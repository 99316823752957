export default function dateTimeFormatter(
  value: Date | number | string,
  lng?: string,
  options?: Intl.DateTimeFormatOptions,
) {
  if (!(value instanceof Date)) {
    value = new Date(value);
  }

  return new Intl.DateTimeFormat(lng, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    ...options,
  }).format(value);
}
