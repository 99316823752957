import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { EventEvaluationDto, EventEvaluationStatusDto } from '../../../api';
import eventEvaluationApi from '../../../data-access/event-evaluation-api';
import { PageContent, PageHeader, PageSpinner } from '../../../ui/page';
import Page from '../../../ui/page/page';
import useToast from '../../../ui/use-toast/use-toast';
import useHistoryBack from '../../../util/history/use-history-back';
import usePromise from '../../../util/use-promise/use-promise';
import useStaffAccount from '../../staff-account/use-account/use-staff-account';
import useEvent from '../../event/use-event/use-event';
import EventEvaluationForm from '../event-evaluation-form/event-evaluation-form';
import { canBeEdited } from '../event-evaluation-status';

/**
 * Event evaluation editor actions.
 */
export enum EventEvaluationEditorAction {
  NEW = 'new',
  EDIT = 'edit',
  REVIEW = 'review',
}

/**
 * Properties for event evaluation editor.
 */
export interface EventEvaluationEditorProps {
  editorAction: EventEvaluationEditorAction;
}

/**
 * Event evaluation editor.
 */
export default function EventEvaluationEditor({ editorAction }: EventEvaluationEditorProps) {
  const { t } = useTranslation('event_evaluation');
  const { eventId } = useParams<{ eventId: string }>();

  const navigate = useNavigate();
  const back = useHistoryBack();

  const showSuccessToast = useToast({
    id: 'event-evaluation-editor-success-toast',
    status: 'success',
  });
  invariant(eventId != null, 'Missing eventId');

  const eventEvaluation = useEditorEventEvaluation(eventId);
  const eventDto = useEvent(eventId);

  const fetchEventEvaluation = async (id: string): Promise<EventEvaluationDto> => {
    return await eventEvaluationApi.fetchEvaluation({ id });
  };

  const handleSave = async (
    eventEvaluationDto: EventEvaluationDto,
    alreadySavedEvaluation: EventEvaluationDto | undefined,
    editorAction: EventEvaluationEditorAction,
  ) => {
    const id = eventEvaluationDto.id!;

    showSuccessToast.closeAll();

    if (editorAction === EventEvaluationEditorAction.REVIEW) {
      await eventEvaluationApi.finishReview({ id, eventEvaluationDto });
    } else {
      let dto = eventEvaluationDto;
      if (alreadySavedEvaluation != null) {
        dto = { ...eventEvaluationDto, version: alreadySavedEvaluation.version };
      }
      await eventEvaluationApi.updateEvaluation({ id, eventEvaluationDto: dto });
    }

    showSuccessToast({
      title: t('toast.success.title'),
      description: t(`toast.success.description.${editorAction}`),
    });

    navigate(back('..'));
  };

  const handleEditAbort = async (eventEvaluationDto: EventEvaluationDto, editorAction: EventEvaluationEditorAction) => {
    if (
      editorAction === EventEvaluationEditorAction.REVIEW &&
      eventEvaluation?.status === EventEvaluationStatusDto.IN_REVIEW
    ) {
      await eventEvaluationApi.stopReview({ id: eventEvaluation.id! });
    }
    navigate(back('..'));
  };

  const currentUser = useStaffAccount();

  return (
    <Page>
      <PageHeader title={t(`action.${editorAction}`)} />
      {eventEvaluation != null ? (
        <PageContent>
          {canBeEdited(eventEvaluation, currentUser) ? (
            <EventEvaluationForm
              eventEvaluation={eventEvaluation}
              editorAction={editorAction}
              eventDateTimeRange={eventDto.dateTimeRange}
              onSave={handleSave}
              onEditAbort={handleEditAbort}
              fetchEventEvaluation={fetchEventEvaluation}
            />
          ) : (
            <Alert mb="8" status="error" aria-live="polite">
              <AlertIcon />
              <AlertDescription>
                <Trans t={t} i18nKey="error_when_not_editable" />
              </AlertDescription>
            </Alert>
          )}
        </PageContent>
      ) : (
        <PageSpinner />
      )}
    </Page>
  );
}

/**
 * The object used in the editor, template in status pending if new or else existing object.
 */
function useEditorEventEvaluation(eventId: string) {
  return usePromise(
    (signal) => {
      return eventEvaluationApi.fetchEvaluationByEventId({ eventId }, { signal });
    },
    [eventId],
  );
}
