/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';

/**
 * Describes the reason why a person can not be anonymised.
 * @export
 * @interface CommonPersonAnonymisationBlockerDto
 */
export interface CommonPersonAnonymisationBlockerDto {
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof CommonPersonAnonymisationBlockerDto
     */
    person: PersonReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof CommonPersonAnonymisationBlockerDto
     */
    reason: string;
}

/**
 * Check if a given object implements the CommonPersonAnonymisationBlockerDto interface.
 */
export function instanceOfCommonPersonAnonymisationBlockerDto(value: object): boolean {
    if (!('person' in value)) return false;
    if (!('reason' in value)) return false;
    return true;
}

export function CommonPersonAnonymisationBlockerDtoFromJSON(json: any): CommonPersonAnonymisationBlockerDto {
    return CommonPersonAnonymisationBlockerDtoFromJSONTyped(json, false);
}

export function CommonPersonAnonymisationBlockerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommonPersonAnonymisationBlockerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'person': PersonReferenceDtoFromJSON(json['person']),
        'reason': json['reason'],
    };
}

export function CommonPersonAnonymisationBlockerDtoToJSON(value?: CommonPersonAnonymisationBlockerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'person': PersonReferenceDtoToJSON(value['person']),
        'reason': value['reason'],
    };
}

