import { WarningIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Center,
  chakra,
  HStack,
  Stack,
  Tag,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import {
  EventDto,
  EventEvaluationAudienceStatusDto,
  EventEvaluationDto,
  EventEvaluationDtoRealisationEnum,
  EventEvaluationStatusDto,
  EventStatusDto,
} from '../../../api';
import { DescriptionGroup, DescriptionItem } from '../../../ui/description';
import Optional from '../../../ui/optional/optional';
import LastModifiedDescriptionItem from '../../../ui/version/last-modified-description-item';
import useEvent from '../../event/use-event/use-event';
import useStaffAccount from '../../staff-account/use-account/use-staff-account';
import usePermission from '../../staff-account/use-permission/use-permission';
import { canBeEdited } from '../event-evaluation-status';
import useEventEvaluation from '../use-event-evaluation/use-event-evaluation';

/**
 * Event evaluation viewer.
 */
export default function EventEvaluationViewer() {
  const { eventId } = useParams<{ eventId: string }>();
  invariant(eventId != null, 'Missing eventId');
  const event = useEvent(eventId);
  const eventEvaluation = useEventEvaluation(eventId);

  if (
    event.status === EventStatusDto.CANCELLED ||
    eventEvaluation == null ||
    eventEvaluation.status === EventEvaluationStatusDto.PENDING
  ) {
    return <NoEventEvaluationPresent event={event} />;
  }

  return <SubmittedEventEvaluationViewer eventEvaluation={eventEvaluation} event={event} />;
}

/**
 * Viewer displayed if event evaluation is already existing.
 */
function SubmittedEventEvaluationViewer({
  eventEvaluation,
  event,
}: {
  eventEvaluation: EventEvaluationDto;
  event: EventDto;
}) {
  const { t } = useTranslation('event_evaluation');
  const { t: tCommon } = useTranslation('common');
  const shouldShowStatusInfo =
    eventEvaluation.status === EventEvaluationStatusDto.REVIEWED ||
    eventEvaluation.status === EventEvaluationStatusDto.IN_REVIEW ||
    eventEvaluation.status === EventEvaluationStatusDto.SUBMITTED;
  const currentUser = useStaffAccount();
  const editable = canBeEdited(eventEvaluation, currentUser);

  return (
    <Stack>
      {shouldShowStatusInfo && (
        <Alert mb="8" status="info" aria-live="polite">
          <AlertIcon />
          <AlertDescription>{t(`info_when.${eventEvaluation.status}`)}</AlertDescription>
        </Alert>
      )}
      <DescriptionGroup>
        <DescriptionItem label={t('event.title')} span={2}>
          {event.title} ({event.englishTitle})
        </DescriptionItem>
        <DescriptionItem label={t('event.date')}>
          {tCommon('format.datetime_range', { dateTimeRange: event.dateTimeRange })}
        </DescriptionItem>
        <DescriptionItem label={t('event.location')}>{event.venue?.location?.name}</DescriptionItem>
      </DescriptionGroup>
      <EventEvaluationBaseViewer eventEvaluation={eventEvaluation} editable={editable} showLastModified={true} />
    </Stack>
  );
}

interface EventEvaluationBaseViewerProps {
  eventEvaluation: EventEvaluationDto;
  editable: boolean;
  showLastModified: boolean;
}

export function EventEvaluationBaseViewer({
  eventEvaluation,
  editable,
  showLastModified,
}: EventEvaluationBaseViewerProps) {
  const { t } = useTranslation('event_evaluation');
  const { t: tCommon } = useTranslation('common');

  return (
    <>
      <DescriptionGroup>
        <CriticalDescriptionItem
          label={t('realisation.label')}
          text={
            eventEvaluation.realisation !== undefined
              ? t(`realisationLabels.${eventEvaluation.realisation}`)
              : undefined
          }
          span={2}
          isCritical={eventEvaluation.realisation === EventEvaluationDtoRealisationEnum.DELAYED}
        />
        <DescriptionItem label={t('realisation.delayed_start')}>
          {eventEvaluation.delayedDateTimeRange &&
            tCommon('format.date_time', { dateTime: eventEvaluation.delayedDateTimeRange?.start })}
        </DescriptionItem>
        <DescriptionItem label={t('realisation.delayed_end')}>
          {eventEvaluation.delayedDateTimeRange &&
            tCommon('format.date_time', { dateTime: eventEvaluation.delayedDateTimeRange?.end })}
        </DescriptionItem>
      </DescriptionGroup>

      <DescriptionGroup
        label={t('access_and_occupancy')}
        linkTo={editable ? { pathname: 'edit', hash: 'accessAndOccupancy' } : undefined}
      >
        <DescriptionItem label={t('tickets_issued.label')} span={2}>
          {eventEvaluation.ticketsIssued}
        </DescriptionItem>
        <DescriptionItem label={t('access.with_ticket')}>{eventEvaluation.accessWithTicket}</DescriptionItem>
        <DescriptionItem label={t('access.without_ticket')}>{eventEvaluation.accessWithoutTicket}</DescriptionItem>
        <DescriptionItem label={t('occupancy.label')} span={2}>
          {eventEvaluation.occupancy}
        </DescriptionItem>
      </DescriptionGroup>

      <DescriptionGroup label={t('problems')} linkTo={editable ? { pathname: 'edit', hash: 'problems' } : undefined}>
        <DescriptionItem
          label={t('technical_disruption.label')}
          backgroundColor={bgColorForCriticalItem(!isEmpty(eventEvaluation.technicalDisruption))}
          span={2}
        >
          <Optional isEmpty={isEmpty(eventEvaluation.technicalDisruption)}>
            <Wrap>
              {eventEvaluation.technicalDisruption?.map((disruptionItem) => (
                <WrapItem key={disruptionItem}>
                  <Tag colorScheme="red">{t(`technicalDisruptionOptions.${disruptionItem}`)}</Tag>
                </WrapItem>
              ))}
            </Wrap>
          </Optional>
        </DescriptionItem>
        <CriticalDescriptionItem
          label={t('audience.entry.label')}
          text={
            eventEvaluation.audienceEntry !== undefined
              ? t(`audienceEntryOptions.${eventEvaluation.audienceEntry}`)
              : undefined
          }
          isCritical={eventEvaluation.audienceEntry === EventEvaluationAudienceStatusDto.PROBLEMS}
        />
        <CriticalDescriptionItem
          label={t('audience.exit.label')}
          text={
            eventEvaluation.audienceExit !== undefined
              ? t(`audienceEntryOptions.${eventEvaluation.audienceExit}`)
              : undefined
          }
          isCritical={eventEvaluation.audienceExit === EventEvaluationAudienceStatusDto.PROBLEMS}
        />
      </DescriptionGroup>

      <DescriptionGroup>
        <DescriptionItem label={t('film_team.label')} span={2}>
          <Optional>{eventEvaluation.filmTeam && t(`filmTeamOptions.${eventEvaluation.filmTeam}`)}</Optional>
        </DescriptionItem>
        <DescriptionItem label={t('further_information.label')} span={2}>
          {eventEvaluation.furtherInformation}
        </DescriptionItem>
        <DescriptionItem label={t('status.label')} span={2}>
          {t(`statusOptions.${eventEvaluation.status}`)}
        </DescriptionItem>
        {showLastModified && <LastModifiedDescriptionItem version={eventEvaluation.version} />}
      </DescriptionGroup>
    </>
  );
}

const bgColorForCriticalItem = (isCritical: boolean) => {
  return isCritical ? 'background.error' : undefined;
};

const fgColorForCriticalItem = (isCritical: boolean) => {
  return isCritical ? 'text.error' : undefined;
};

const fontWeightForCriticalItem = (isCritical: boolean) => {
  return isCritical ? 'medium' : undefined;
};

/**
 * Properties for critical description item marking.
 */
interface CriticalDescriptionItemProps {
  label: string;
  text: string | undefined;
  span?: number;
  isCritical: boolean;
}

/**
 * Critical description item marking.
 */
function CriticalDescriptionItem({ label, text, span, isCritical }: CriticalDescriptionItemProps) {
  return (
    <DescriptionItem label={label} span={span} backgroundColor={bgColorForCriticalItem(isCritical)}>
      <Optional>
        {text && (
          <HStack>
            {isCritical && <WarningIcon color="text.error" />}
            <chakra.div color={fgColorForCriticalItem(isCritical)} fontWeight={fontWeightForCriticalItem(isCritical)}>
              {text}
            </chakra.div>
          </HStack>
        )}
      </Optional>
    </DescriptionItem>
  );
}

/**
 * Viewer displayed if event evaluation is not existing or event is cancelled.
 */
function NoEventEvaluationPresent({ event }: { event: EventDto }) {
  const { t } = useTranslation('event_evaluation');
  const isCanceled = event.status === EventStatusDto.CANCELLED;
  const canCreate = usePermission('EVENT_EVALUATION:STANDARD-CREATE');
  const canEdit = usePermission('EVENT_EVALUATION:STANDARD-EDIT');

  return (
    <Center borderWidth="1px" backgroundColor="layer.01" minHeight={256} borderRadius="base" borderColor="border.01">
      <Stack spacing={8} align="center">
        <Text>{t('info_when.PENDING')}</Text>
        {!isCanceled && (canCreate || canEdit) && (
          <Button as={RouterLink} to="new">
            {t('action.new')}
          </Button>
        )}
      </Stack>
    </Center>
  );
}
