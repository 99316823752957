import orderApi from '../../data-access/order-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';

export const orderFetcher = createFetcherScope(orderApi);
export const fetchOrders = orderFetcher.createFetcher(orderApi.searchOrders);
export const fetchPersonOrders = orderFetcher.createFetcher(orderApi.searchOrdersByPerson);
export const fetchEditorOrderItems = orderFetcher.createFetcher(orderApi.searchEditorViewItems);
export const fetchOrder = orderFetcher.createFetcher(orderApi.fetchOrder);

export const fetchBuyerFormatOccupation = orderFetcher.createFetcher(orderApi.fetchBuyerFormatOccupation);

export const fetchOrderHistory = orderFetcher.createFetcher(orderApi.fetchOrderHistory);
