/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Status of the landing page.
 * @export
 */
export const LandingPageDesignStatusDto = {
    ACTIVE: 'ACTIVE',
    ARCHIVED: 'ARCHIVED'
} as const;
export type LandingPageDesignStatusDto = typeof LandingPageDesignStatusDto[keyof typeof LandingPageDesignStatusDto];


export function LandingPageDesignStatusDtoFromJSON(json: any): LandingPageDesignStatusDto {
    return LandingPageDesignStatusDtoFromJSONTyped(json, false);
}

export function LandingPageDesignStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageDesignStatusDto {
    return json as LandingPageDesignStatusDto;
}

export function LandingPageDesignStatusDtoToJSON(value?: LandingPageDesignStatusDto | null): any {
    return value as any;
}

