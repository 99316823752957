import { ReactNode, useCallback } from 'react';
import { SectionStaffListItemDto } from '../../../../api';
import usePlugins, { PluginToken } from '../../../../util/plugin/use-plugins';
import { Flex, Menu, MenuList, Portal } from '@chakra-ui/react';
import { BurgerMenuButton } from '../../../../ui/burger-menu-button/burger-menu-button';

export type SectionStaffListerActionExtension = {
  action: (staffDto: SectionStaffListItemDto) => ReactNode;
  actionAllowed: (staffDto: SectionStaffListItemDto) => boolean;
};

export const SECTION_STAFF_LISTER_ACTION_EXTENSION = new PluginToken<SectionStaffListerActionExtension>(
  'SectionStaffListerActionExtension',
);

export default function useSectionStaffTableActions() {
  const additionalActions = usePlugins(SECTION_STAFF_LISTER_ACTION_EXTENSION);

  return useCallback(
    (staff: SectionStaffListItemDto) => {
      const mappedActions = additionalActions
        .filter((extension) => extension.actionAllowed(staff))
        .map((extension) => extension.action(staff))
        .filter(canRender);

      if (mappedActions.length === 0) {
        return null;
      }

      return (
        <Flex>
          <Menu>
            <BurgerMenuButton size="sm" />
            <Portal>
              <MenuList>{mappedActions}</MenuList>
            </Portal>
          </Menu>
        </Flex>
      );
    },
    [additionalActions],
  );
}

const canRender = (element: ReactNode) => {
  return element !== null && element !== undefined && element !== false;
};
