/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SectionDto } from './SectionDto';
import {
    SectionDtoFromJSON,
    SectionDtoFromJSONTyped,
    SectionDtoToJSON,
} from './SectionDto';

/**
 * 
 * @export
 * @interface SectionSearchResultDto
 */
export interface SectionSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof SectionSearchResultDto
     */
    resultType: SectionSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SectionSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {SectionDto}
     * @memberof SectionSearchResultDto
     */
    result: SectionDto;
}


/**
 * @export
 */
export const SectionSearchResultDtoResultTypeEnum = {
    SECTION: 'SECTION'
} as const;
export type SectionSearchResultDtoResultTypeEnum = typeof SectionSearchResultDtoResultTypeEnum[keyof typeof SectionSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the SectionSearchResultDto interface.
 */
export function instanceOfSectionSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function SectionSearchResultDtoFromJSON(json: any): SectionSearchResultDto {
    return SectionSearchResultDtoFromJSONTyped(json, false);
}

export function SectionSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': SectionDtoFromJSON(json['result']),
    };
}

export function SectionSearchResultDtoToJSON(value?: SectionSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': SectionDtoToJSON(value['result']),
    };
}

