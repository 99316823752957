/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CountryPhonePrefixPageDto,
  FailureDto,
  PageableDto,
} from '../models/index';
import {
    CountryPhonePrefixPageDtoFromJSON,
    CountryPhonePrefixPageDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface SearchPhonePrefixesRequest {
    q?: string;
    pageable?: PageableDto;
}

/**
 * 
 */
export class PhonePrefixApi extends runtime.BaseAPI {

    /**
     * Search for country phone prefixes.
     */
    async searchPhonePrefixesRaw(requestParameters: SearchPhonePrefixesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CountryPhonePrefixPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/countries/phone-prefixes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountryPhonePrefixPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for country phone prefixes.
     */
    async searchPhonePrefixes(requestParameters: SearchPhonePrefixesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CountryPhonePrefixPageDto> {
        const response = await this.searchPhonePrefixesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
