import { chakra, Flex, HStack, Stack, Text, useId } from '@chakra-ui/react';
import { identity } from 'lodash-es';
import React from 'react';
import { FieldPath, FieldValues, RegisterOptions, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LayoutType } from '../../../feature/common/LayoutType';
import useControlledReducer from '../../../util/use-controlled-reducer/use-controlled-reducer';
import Label from '../label';
import AddElementButton from './add-element-button';
import DeleteElementButton from './delete-element-button';
import EditElementButton from './edit-element-button';
import ElementContext from './element-context';
import elementControlReducer, { ElementTableControlAction } from './element-control-reducer';
import { ElementTableDispatchContext } from './element-table-control';

/**
 * Properties for element list control.
 */
interface ElementListControlProps<TElement, TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> {
  name: TName;
  label: React.ReactNode;
  addLabel: React.ReactNode;
  editLabel?: string;
  deleteLabel: string;
  formModal: React.ReactElement;
  helperText?: React.ReactNode;
  helperPopover?: React.ReactElement;
  addButtonDisabled?: boolean;
  isEditable?: boolean;
  isRequired?: boolean;
  validate?: RegisterOptions<TFieldValues, TName>['validate'];
  renderElement?(element: TElement): React.ReactNode;
  renderDeleteMessage(element: TElement): React.ReactNode;
  renderDeleteLabel?(element: TElement): string;
  deleteDialogSize?: 'sm' | 'md' | 'lg' | 'xl';
  layout: LayoutType;
}

/**
 * Default control to add, edit, delete and display elements in a list.
 */
export default function ElementListControl<
  TElement,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  label,
  addLabel,
  editLabel,
  deleteLabel,
  formModal,
  helperText: helperTextProp,
  helperPopover,
  renderElement = identity,
  renderDeleteMessage,
  addButtonDisabled,
  isEditable = true,
  isRequired,
  validate,
  renderDeleteLabel,
  deleteDialogSize,
  layout,
}: ElementListControlProps<TElement, TFieldValues, TName>) {
  const { t } = useTranslation('common');
  const { field, fieldState } = useController({
    name,
    rules: {
      required: isRequired ? t('validation_error.required', { field: label }) : undefined,
      validate: validate,
    },
  });
  const elements = React.useMemo(() => (field.value as TElement[]) ?? [], [field.value]);
  const id = useId(undefined, 'element-table-control');
  const dispatch = useControlledReducer<TElement[], ElementTableControlAction<TElement>>(
    elementControlReducer,
    elements,
    field.onChange,
  );
  const helperText = React.useMemo(() => {
    return helperTextProp ? helperTextProp + ' ' : '';
  }, [helperTextProp]);

  return (
    <Stack w={layout === LayoutType.SMALL ? '100%' : undefined}>
      <Stack spacing={0.5}>
        <HStack spacing={0} alignItems="flex-start">
          <Label id={id}>
            {label} {isRequired ? <chakra.span color="text.error">*</chakra.span> : undefined}
          </Label>
          {helperPopover}
        </HStack>
        {helperText != null && <Text sx={{ fontSize: 'sm', color: 'text.muted' }}>{helperText}</Text>}
      </Stack>
      <ElementTableDispatchContext.Provider value={dispatch}>
        <ElementList aria-labelledby={id} role="list">
          {elements.map((element, index) => (
            <ElementListItem role="listitem" key={index} w={layout === LayoutType.SMALL ? '100%' : undefined}>
              <chakra.span overflowWrap="anywhere" w={layout === LayoutType.SMALL ? '100%' : undefined}>
                {renderElement!(element)}
              </chakra.span>
              <chakra.div ml={3} flexShrink={0}>
                <ElementContext.Provider value={{ element, index }}>
                  {isEditable && editLabel && <EditElementButton label={editLabel} formModal={formModal} />}
                  <DeleteElementButton
                    label={deleteLabel}
                    renderDeleteLabel={renderDeleteLabel}
                    renderDeleteMessage={renderDeleteMessage}
                    dialogSize={deleteDialogSize}
                  />
                </ElementContext.Provider>
              </chakra.div>
            </ElementListItem>
          ))}
          <AddElementButton
            h={10}
            label={addLabel}
            w={layout === LayoutType.SMALL ? '100%' : undefined}
            formModal={formModal}
            isDisabled={addButtonDisabled}
            hasValidationError={fieldState.error != null}
            ref={field.ref}
          />
        </ElementList>
      </ElementTableDispatchContext.Provider>
      {fieldState.error != null && (
        <Text fontSize="sm" color="text.error">
          {fieldState.error.message}
        </Text>
      )}
    </Stack>
  );
}

const ElementList = chakra(Flex, {
  baseStyle: {
    flexWrap: 'wrap',
    w: 'full',
    gap: 2,
  },
});

const ElementListItem = chakra(Flex, {
  baseStyle: {
    minH: 10,
    pl: 4,
    pr: 1,
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'border.01',
    borderRadius: 'base',
    fontSize: 'sm',
    maxW: 'full',
    bg: 'layer.01',
  },
});
