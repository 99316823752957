/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateTimeRangeDto } from './ClosedDateTimeRangeDto';
import {
    ClosedDateTimeRangeDtoFromJSON,
    ClosedDateTimeRangeDtoFromJSONTyped,
    ClosedDateTimeRangeDtoToJSON,
} from './ClosedDateTimeRangeDto';
import type { LocationZoneReferenceDto } from './LocationZoneReferenceDto';
import {
    LocationZoneReferenceDtoFromJSON,
    LocationZoneReferenceDtoFromJSONTyped,
    LocationZoneReferenceDtoToJSON,
} from './LocationZoneReferenceDto';
import type { PublishedLaneElementLaneDto } from './PublishedLaneElementLaneDto';
import {
    PublishedLaneElementLaneDtoFromJSON,
    PublishedLaneElementLaneDtoFromJSONTyped,
    PublishedLaneElementLaneDtoToJSON,
} from './PublishedLaneElementLaneDto';
import type { PublishedLaneElementPersonsInnerDto } from './PublishedLaneElementPersonsInnerDto';
import {
    PublishedLaneElementPersonsInnerDtoFromJSON,
    PublishedLaneElementPersonsInnerDtoFromJSONTyped,
    PublishedLaneElementPersonsInnerDtoToJSON,
} from './PublishedLaneElementPersonsInnerDto';

/**
 * Lane element in a public rundown.
 * @export
 * @interface PublishedLaneElementDto
 */
export interface PublishedLaneElementDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof PublishedLaneElementDto
     */
    id: string;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof PublishedLaneElementDto
     */
    eventId: string;
    /**
     * 
     * @type {PublishedLaneElementLaneDto}
     * @memberof PublishedLaneElementDto
     */
    lane: PublishedLaneElementLaneDto;
    /**
     * 
     * @type {ClosedDateTimeRangeDto}
     * @memberof PublishedLaneElementDto
     */
    dateTimeRange: ClosedDateTimeRangeDto;
    /**
     * 
     * @type {string}
     * @memberof PublishedLaneElementDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PublishedLaneElementDto
     */
    description?: string;
    /**
     * 
     * @type {Array<PublishedLaneElementPersonsInnerDto>}
     * @memberof PublishedLaneElementDto
     */
    persons: Array<PublishedLaneElementPersonsInnerDto>;
    /**
     * 
     * @type {Array<LocationZoneReferenceDto>}
     * @memberof PublishedLaneElementDto
     */
    locations: Array<LocationZoneReferenceDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublishedLaneElementDto
     */
    attachments: Array<string>;
}

/**
 * Check if a given object implements the PublishedLaneElementDto interface.
 */
export function instanceOfPublishedLaneElementDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('eventId' in value)) return false;
    if (!('lane' in value)) return false;
    if (!('dateTimeRange' in value)) return false;
    if (!('title' in value)) return false;
    if (!('persons' in value)) return false;
    if (!('locations' in value)) return false;
    if (!('attachments' in value)) return false;
    return true;
}

export function PublishedLaneElementDtoFromJSON(json: any): PublishedLaneElementDto {
    return PublishedLaneElementDtoFromJSONTyped(json, false);
}

export function PublishedLaneElementDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublishedLaneElementDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'eventId': json['eventId'],
        'lane': PublishedLaneElementLaneDtoFromJSON(json['lane']),
        'dateTimeRange': ClosedDateTimeRangeDtoFromJSON(json['dateTimeRange']),
        'title': json['title'],
        'description': json['description'] == null ? undefined : json['description'],
        'persons': ((json['persons'] as Array<any>).map(PublishedLaneElementPersonsInnerDtoFromJSON)),
        'locations': ((json['locations'] as Array<any>).map(LocationZoneReferenceDtoFromJSON)),
        'attachments': json['attachments'],
    };
}

export function PublishedLaneElementDtoToJSON(value?: PublishedLaneElementDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'eventId': value['eventId'],
        'lane': PublishedLaneElementLaneDtoToJSON(value['lane']),
        'dateTimeRange': ClosedDateTimeRangeDtoToJSON(value['dateTimeRange']),
        'title': value['title'],
        'description': value['description'],
        'persons': ((value['persons'] as Array<any>).map(PublishedLaneElementPersonsInnerDtoToJSON)),
        'locations': ((value['locations'] as Array<any>).map(LocationZoneReferenceDtoToJSON)),
        'attachments': value['attachments'],
    };
}

