import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const countryMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.country" />,
    path: '/countries',
    hasAccess: (permissions) => permissions.includes('COUNTRY:STANDARD-READ'),
  },
];

export default countryMenuItems;
