import React from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchTeam } from '../team-queries';

export default function TeamBreadcrumb() {
  const { teamId } = useParams<{ teamId: string }>();
  invariant(teamId, 'Empty teamId');
  const team = useFetcher(fetchTeam, { id: teamId });

  return <>{team.name}</>;
}
