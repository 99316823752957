import { useCallbackRef } from '@chakra-ui/react';
import { useEffect } from 'react';

export default function useOnline(callback: (isOnline: boolean) => void) {
  const callbackRef = useCallbackRef(callback);

  useEffect(() => {
    const handleOnline = () => {
      callbackRef(true);
    };
    const handleOffline = () => {
      callbackRef(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [callbackRef]);
}
