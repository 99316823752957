import React from 'react';
import { useTranslation } from 'react-i18next';
import { MailingDtoStatusEnum, RecipientLinkDto } from '../../../api';
import { DataTableColumn } from '../../../ui/data-table';
import StringFilter from '../../../ui/data-table/filter/string-filter';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchMailing } from '../mailing-queries';
import { isEmailRecipientLink } from '../mailing-recipient-util/email-recipient-link';
import DeleteRecipientButton from './delete-recipient-button';

export default function useEmailRecipientColumns(mailingId: string) {
  const { t } = useTranslation('mailing');
  const mailing = useFetcher(fetchMailing, { id: mailingId });

  const columns: DataTableColumn<RecipientLinkDto>[] = React.useMemo(
    () => [
      {
        key: 'linkedRecipient.email',
        name: t('recipients.emails'),
        isSortable: true,
        renderCell: (recipientLink) => isEmailRecipientLink(recipientLink) && recipientLink.linkedRecipient.email,
        filter: (
          <StringFilter
            label={t('recipients.emails')}
            availableOperators={['CONTAIN', 'NOT_CONTAIN', 'START_WITH', 'NOT_START_WITH', 'END_WITH', 'NOT_END_WITH']}
          />
        ),
      },
      {
        key: 'action',
        sticky: true,
        renderCell: (recipientLink) =>
          isEmailRecipientLink(recipientLink) && (
            <DeleteRecipientButton
              mailing={mailing}
              recipientId={recipientLink.id}
              recipientType="EMAIL"
              isDisabled={mailing.status !== MailingDtoStatusEnum.DRAFT}
              displayName={recipientLink.linkedRecipient.email}
            />
          ),
      },
    ],
    [mailing, t],
  );
  return columns;
}
