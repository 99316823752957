import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ClosedDateTimeRangeDto, EventEvaluationDto, EventEvaluationDtoRealisationEnum } from '../../../api';
import DateInputControl from '../../../ui/form/date-input-control/date-input-control';
import ErrorMessage from '../../../ui/form/error-message';
import validDate from '../../../util/date/valid-date';

export default function EvaluationDelayDataRangeControl({
  eventDateTimeRange,
}: {
  eventDateTimeRange: ClosedDateTimeRangeDto;
}) {
  const { t } = useTranslation('event_evaluation');
  const {
    trigger,
    formState: { errors },
    resetField,
  } = useFormContext<EventEvaluationDto>();

  const [realisation] = useWatch<EventEvaluationDto, ['realisation']>({
    name: ['realisation'],
  });
  const watchStart = useWatch({ name: 'delayedDateTimeRange.start' });
  const watchEnd = useWatch({ name: 'delayedDateTimeRange.end' });

  const start = validDate(watchStart as Date);
  const end = validDate(watchEnd as Date);

  React.useEffect(() => {
    if (realisation === EventEvaluationDtoRealisationEnum.ON_SCHEDULE) {
      resetField('delayedDateTimeRange.start', { defaultValue: null });
      resetField('delayedDateTimeRange.end', { defaultValue: null });
    }

    if (realisation === EventEvaluationDtoRealisationEnum.DELAYED) {
      trigger('delayedDateTimeRange.start'); // triggers validation on field
      trigger('delayedDateTimeRange.end'); // triggers validation on field
    }
  }, [realisation, resetField, trigger]);

  async function onStartBlured() {
    await trigger('delayedDateTimeRange.end');
  }

  async function onEndBlured() {
    await trigger('delayedDateTimeRange.start');
  }

  return (
    <>
      <FormControl
        isInvalid={errors.delayedDateTimeRange?.start != null}
        onBlur={onStartBlured}
        isRequired={realisation === EventEvaluationDtoRealisationEnum.DELAYED}
      >
        <FormLabel>{t('realisation.delayed_start')}</FormLabel>
        <DateInputControl<EventEvaluationDto>
          showTimeSelect
          name="delayedDateTimeRange.start"
          isDisabled={realisation !== EventEvaluationDtoRealisationEnum.DELAYED}
          required={
            (realisation === EventEvaluationDtoRealisationEnum.DELAYED || end) &&
            t('realisation.validation_error.delayed_start.required')
          }
          max={{ value: end, message: t('realisation.validation_error.delayed_start.max') }}
          referenceDate={eventDateTimeRange.start}
        />
        <ErrorMessage name="delayedDateTimeRange.start" as={FormErrorMessage} />
      </FormControl>
      <FormControl
        isInvalid={errors.delayedDateTimeRange?.end != null}
        onBlur={onEndBlured}
        isRequired={realisation === EventEvaluationDtoRealisationEnum.DELAYED}
      >
        <FormLabel>{t('realisation.delayed_end')}</FormLabel>
        <DateInputControl<EventEvaluationDto>
          showTimeSelect
          name="delayedDateTimeRange.end"
          isDisabled={realisation !== EventEvaluationDtoRealisationEnum.DELAYED}
          required={
            (realisation === EventEvaluationDtoRealisationEnum.DELAYED || start) &&
            t('realisation.validation_error.delayed_end.required')
          }
          min={{ value: start, message: t('realisation.validation_error.delayed_end.min') }}
          referenceDate={eventDateTimeRange.end}
        />
        <ErrorMessage name="delayedDateTimeRange.end" as={FormErrorMessage} />
      </FormControl>
    </>
  );
}
