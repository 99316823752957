/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StandDto } from './StandDto';
import {
    StandDtoFromJSON,
    StandDtoFromJSONTyped,
    StandDtoToJSON,
} from './StandDto';

/**
 * 
 * @export
 * @interface StandSearchResultDto
 */
export interface StandSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof StandSearchResultDto
     */
    resultType: StandSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof StandSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {StandDto}
     * @memberof StandSearchResultDto
     */
    result: StandDto;
}


/**
 * @export
 */
export const StandSearchResultDtoResultTypeEnum = {
    STAND: 'STAND'
} as const;
export type StandSearchResultDtoResultTypeEnum = typeof StandSearchResultDtoResultTypeEnum[keyof typeof StandSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the StandSearchResultDto interface.
 */
export function instanceOfStandSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function StandSearchResultDtoFromJSON(json: any): StandSearchResultDto {
    return StandSearchResultDtoFromJSONTyped(json, false);
}

export function StandSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': StandDtoFromJSON(json['result']),
    };
}

export function StandSearchResultDtoToJSON(value?: StandSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': StandDtoToJSON(value['result']),
    };
}

