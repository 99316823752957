/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OccupationViewDto } from './OccupationViewDto';
import {
    OccupationViewDtoFromJSON,
    OccupationViewDtoFromJSONTyped,
    OccupationViewDtoToJSON,
} from './OccupationViewDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface CompanyPersonListItemDto
 */
export interface CompanyPersonListItemDto {
    /**
     * 
     * @type {OccupationViewDto}
     * @memberof CompanyPersonListItemDto
     */
    occupation: OccupationViewDto;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof CompanyPersonListItemDto
     */
    person: PersonReferenceDto;
    /**
     * 
     * @type {VersionDto}
     * @memberof CompanyPersonListItemDto
     */
    version?: VersionDto;
}

/**
 * Check if a given object implements the CompanyPersonListItemDto interface.
 */
export function instanceOfCompanyPersonListItemDto(value: object): boolean {
    if (!('occupation' in value)) return false;
    if (!('person' in value)) return false;
    return true;
}

export function CompanyPersonListItemDtoFromJSON(json: any): CompanyPersonListItemDto {
    return CompanyPersonListItemDtoFromJSONTyped(json, false);
}

export function CompanyPersonListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyPersonListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'occupation': OccupationViewDtoFromJSON(json['occupation']),
        'person': PersonReferenceDtoFromJSON(json['person']),
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
    };
}

export function CompanyPersonListItemDtoToJSON(value?: CompanyPersonListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'occupation': OccupationViewDtoToJSON(value['occupation']),
        'person': PersonReferenceDtoToJSON(value['person']),
        'version': VersionDtoToJSON(value['version']),
    };
}

