import { HStack, Link } from '@chakra-ui/react';
import React from 'react';
import { PhoneNumberDto } from '../../../api';
import BooleanViewer from '../../../ui/boolean-viewer/boolean-viewer';
import { DataTableColumn } from '../../../ui/data-table';
import HelperPopover from '../../../ui/helper-buttons/helper-popover';
import Optional from '../../../ui/optional/optional';
import linkPhoneNumber from '../../../ui/phone-number/link-phone-number';
import renderPhoneNumber from '../../../ui/phone-number/render-phone-number';
import Translate from '../../../util/translate/translate';

/**
 * Default columns of person's phone number data tables.
 */
const phoneNumberDataTableColumns: DataTableColumn<PhoneNumberDto>[] = [
  {
    key: 'phoneNumber',
    name: <Translate ns="person" i18nKey="contacts.phone_number.table.number" />,
    renderCell: (data) => (
      <Link href={linkPhoneNumber(data)} isExternal>
        {renderPhoneNumber(data)}
      </Link>
    ),
    cellProps: {
      flexGrow: 2,
    },
  },
  {
    key: 'label',
    name: <Translate ns="person" i18nKey="contacts.phone_number.label" />,
    renderCell: (data) => (
      <Translate ns="person">{(t) => t(`contacts.phone_number.labelOptions.${data.label}`)}</Translate>
    ),
  },
  {
    key: 'publish',
    name: (
      <HStack>
        <Translate ns="person" i18nKey="contacts.phone_number.publish" />
        <HelperPopover children={<Translate ns="person" i18nKey="contacts.phone_number.publish_popover" />} />
      </HStack>
    ),
    renderCell: (data) => <BooleanViewer value={data.publish} />,
  },
  {
    key: 'confidential',
    name: <Translate ns="person" i18nKey="contacts.phone_number.confidential" />,
    renderCell: (data) => <BooleanViewer value={data.confidential} />,
  },
  {
    key: 'note',
    name: <Translate ns="person" i18nKey="contacts.phone_number.note" />,
    renderCell: (data) => <Optional>{data.note}</Optional>,
    cellProps: { flexGrow: 2 },
  },
];

export default phoneNumberDataTableColumns;
