/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ModuleTypeDto } from './ModuleTypeDto';
import {
    ModuleTypeDtoFromJSON,
    ModuleTypeDtoFromJSONTyped,
    ModuleTypeDtoToJSON,
} from './ModuleTypeDto';

/**
 * 
 * @export
 * @interface MailingSalutationModuleContentDto
 */
export interface MailingSalutationModuleContentDto {
    /**
     * 
     * @type {ModuleTypeDto}
     * @memberof MailingSalutationModuleContentDto
     */
    moduleType: ModuleTypeDto;
    /**
     * 
     * @type {Date}
     * @memberof MailingSalutationModuleContentDto
     */
    timestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof MailingSalutationModuleContentDto
     */
    customSalutation?: string;
    /**
     * 
     * @type {string}
     * @memberof MailingSalutationModuleContentDto
     */
    customSalutationEnglish?: string;
    /**
     * 
     * @type {string}
     * @memberof MailingSalutationModuleContentDto
     */
    salutationOption?: MailingSalutationModuleContentDtoSalutationOptionEnum;
}


/**
 * @export
 */
export const MailingSalutationModuleContentDtoSalutationOptionEnum = {
    FORMAL: 'FORMAL',
    CASUAL: 'CASUAL'
} as const;
export type MailingSalutationModuleContentDtoSalutationOptionEnum = typeof MailingSalutationModuleContentDtoSalutationOptionEnum[keyof typeof MailingSalutationModuleContentDtoSalutationOptionEnum];


/**
 * Check if a given object implements the MailingSalutationModuleContentDto interface.
 */
export function instanceOfMailingSalutationModuleContentDto(value: object): boolean {
    if (!('moduleType' in value)) return false;
    return true;
}

export function MailingSalutationModuleContentDtoFromJSON(json: any): MailingSalutationModuleContentDto {
    return MailingSalutationModuleContentDtoFromJSONTyped(json, false);
}

export function MailingSalutationModuleContentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingSalutationModuleContentDto {
    if (json == null) {
        return json;
    }
    return {
        
        'moduleType': ModuleTypeDtoFromJSON(json['moduleType']),
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
        'customSalutation': json['customSalutation'] == null ? undefined : json['customSalutation'],
        'customSalutationEnglish': json['customSalutationEnglish'] == null ? undefined : json['customSalutationEnglish'],
        'salutationOption': json['salutationOption'] == null ? undefined : json['salutationOption'],
    };
}

export function MailingSalutationModuleContentDtoToJSON(value?: MailingSalutationModuleContentDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'moduleType': ModuleTypeDtoToJSON(value['moduleType']),
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
        'customSalutation': value['customSalutation'],
        'customSalutationEnglish': value['customSalutationEnglish'],
        'salutationOption': value['salutationOption'],
    };
}

