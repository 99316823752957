/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BerlinaleLinkDto } from './BerlinaleLinkDto';
import {
    BerlinaleLinkDtoFromJSON,
    BerlinaleLinkDtoFromJSONTyped,
    BerlinaleLinkDtoToJSON,
} from './BerlinaleLinkDto';
import type { SocialMediaLinksDto } from './SocialMediaLinksDto';
import {
    SocialMediaLinksDtoFromJSON,
    SocialMediaLinksDtoFromJSONTyped,
    SocialMediaLinksDtoToJSON,
} from './SocialMediaLinksDto';

/**
 * 
 * @export
 * @interface LandingPageDesignFooterDto
 */
export interface LandingPageDesignFooterDto {
    /**
     * A hex color code
     * @type {string}
     * @memberof LandingPageDesignFooterDto
     */
    backgroundColor: string;
    /**
     * A hex color code
     * @type {string}
     * @memberof LandingPageDesignFooterDto
     */
    textSymbolColor: string;
    /**
     * 
     * @type {SocialMediaLinksDto}
     * @memberof LandingPageDesignFooterDto
     */
    socialMediaLinks: SocialMediaLinksDto;
    /**
     * 
     * @type {Array<BerlinaleLinkDto>}
     * @memberof LandingPageDesignFooterDto
     */
    berlinaleLinks: Array<BerlinaleLinkDto>;
}

/**
 * Check if a given object implements the LandingPageDesignFooterDto interface.
 */
export function instanceOfLandingPageDesignFooterDto(value: object): boolean {
    if (!('backgroundColor' in value)) return false;
    if (!('textSymbolColor' in value)) return false;
    if (!('socialMediaLinks' in value)) return false;
    if (!('berlinaleLinks' in value)) return false;
    return true;
}

export function LandingPageDesignFooterDtoFromJSON(json: any): LandingPageDesignFooterDto {
    return LandingPageDesignFooterDtoFromJSONTyped(json, false);
}

export function LandingPageDesignFooterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageDesignFooterDto {
    if (json == null) {
        return json;
    }
    return {
        
        'backgroundColor': json['backgroundColor'],
        'textSymbolColor': json['textSymbolColor'],
        'socialMediaLinks': SocialMediaLinksDtoFromJSON(json['socialMediaLinks']),
        'berlinaleLinks': ((json['berlinaleLinks'] as Array<any>).map(BerlinaleLinkDtoFromJSON)),
    };
}

export function LandingPageDesignFooterDtoToJSON(value?: LandingPageDesignFooterDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'backgroundColor': value['backgroundColor'],
        'textSymbolColor': value['textSymbolColor'],
        'socialMediaLinks': SocialMediaLinksDtoToJSON(value['socialMediaLinks']),
        'berlinaleLinks': ((value['berlinaleLinks'] as Array<any>).map(BerlinaleLinkDtoToJSON)),
    };
}

