import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import React from 'react';
import { CompanyDto } from '../../../api';
import CompanyAdminEditorDialogContent from './company-admin-editor-dialog-content';

interface CompanyAdminEditorDialogProps {
  isOpen: boolean;
  onClose: () => void;
  company: CompanyDto;
}

export default function CompanyAdminEditorDialog({ isOpen, onClose, company }: CompanyAdminEditorDialogProps) {
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} initialFocusRef={initialFocusRef} size="xl">
      <ModalOverlay />
      <ModalContent>
        <CompanyAdminEditorDialogContent onClose={onClose} company={company} initialFocusRef={initialFocusRef} />
      </ModalContent>
    </Modal>
  );
}
