import { ReactNode } from 'react';
import { AwardDto } from '../../../api';
import { PluginToken } from '../../../util/plugin/use-plugins';

export interface AwardWinnerRelationExtension {
  pageContent: (award: AwardDto) => ReactNode;
}

export const AWARD_WINNER_RELATION_EXTENSION = new PluginToken<AwardWinnerRelationExtension>(
  'AwardWinnerRelationExtension',
);
