/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FormatCompanyListItemDto } from './FormatCompanyListItemDto';
import {
    FormatCompanyListItemDtoFromJSON,
    FormatCompanyListItemDtoFromJSONTyped,
    FormatCompanyListItemDtoToJSON,
} from './FormatCompanyListItemDto';

/**
 * 
 * @export
 * @interface FormatCompanyPageDto
 */
export interface FormatCompanyPageDto {
    /**
     * The number of the page.
     * @type {number}
     * @memberof FormatCompanyPageDto
     */
    page: number;
    /**
     * The size of the page.
     * @type {number}
     * @memberof FormatCompanyPageDto
     */
    size: number;
    /**
     * The total number of data returned by the search.
     * @type {number}
     * @memberof FormatCompanyPageDto
     */
    totalElements: number;
    /**
     * The total number of pages of current size to fit all data.
     * @type {number}
     * @memberof FormatCompanyPageDto
     */
    totalPages: number;
    /**
     * 
     * @type {Array<FormatCompanyListItemDto>}
     * @memberof FormatCompanyPageDto
     */
    content: Array<FormatCompanyListItemDto>;
}

/**
 * Check if a given object implements the FormatCompanyPageDto interface.
 */
export function instanceOfFormatCompanyPageDto(value: object): boolean {
    if (!('page' in value)) return false;
    if (!('size' in value)) return false;
    if (!('totalElements' in value)) return false;
    if (!('totalPages' in value)) return false;
    if (!('content' in value)) return false;
    return true;
}

export function FormatCompanyPageDtoFromJSON(json: any): FormatCompanyPageDto {
    return FormatCompanyPageDtoFromJSONTyped(json, false);
}

export function FormatCompanyPageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormatCompanyPageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'page': json['page'],
        'size': json['size'],
        'totalElements': json['totalElements'],
        'totalPages': json['totalPages'],
        'content': ((json['content'] as Array<any>).map(FormatCompanyListItemDtoFromJSON)),
    };
}

export function FormatCompanyPageDtoToJSON(value?: FormatCompanyPageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'page': value['page'],
        'size': value['size'],
        'totalElements': value['totalElements'],
        'totalPages': value['totalPages'],
        'content': ((value['content'] as Array<any>).map(FormatCompanyListItemDtoToJSON)),
    };
}

