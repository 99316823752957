import { HStack } from '@chakra-ui/react';
import React from 'react';
import { FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CodeDto } from '../../../api';
import DateInputFormControl from '../../../ui/form/date-input-control/date-input-form-control';
import dateMax from '../../../util/date/date-max';
import validDate from '../../../util/date/valid-date';
import now from '../../../util/now';

interface ValidityPeriodControlProps<T extends FieldValues> {
  initialFocusRef?: React.RefObject<HTMLInputElement>;
  path?: FieldPath<T>;
}

export default function ValidityPeriodControl<T extends FieldValues>({
  initialFocusRef,
  path,
}: ValidityPeriodControlProps<T>) {
  const prefixWithPath = <TPath extends string>(name: TPath) => (path != null ? (`${path}.${name}` as TPath) : name);
  const { t } = useTranslation('code');
  const { watch } = useFormContext<CodeDto>();
  const start = validDate(watch(prefixWithPath('validityPeriod.start')));
  const end = validDate(watch(prefixWithPath('validityPeriod.end')));
  const minStart = new Date(now());
  const [defaultStart] = React.useState(start);
  const [defaultEnd] = React.useState(end);
  const defaultStartInThePast = defaultStart != null && defaultStart.valueOf() < now();
  const defaultEndInThePast = defaultEnd != null && defaultEnd.valueOf() < now();

  return (
    <HStack alignItems="start">
      <DateInputFormControl<CodeDto>
        isDisabled={defaultStartInThePast}
        deps={[prefixWithPath('validityPeriod.end')]}
        name={prefixWithPath('validityPeriod.start')}
        isRequired
        label={t('start')}
        referenceDate={minStart}
        showTimeSelect
        min={
          !defaultStartInThePast
            ? {
                value: minStart,
                message: t('editor.validation_error.start.min'),
              }
            : undefined
        }
        max={
          !defaultStartInThePast
            ? {
                value: end,
                message: t('editor.validation_error.start.max'),
              }
            : undefined
        }
        ref={initialFocusRef}
      />

      <DateInputFormControl<CodeDto>
        isDisabled={defaultEnd != null && defaultEnd.valueOf() < now()}
        deps={[prefixWithPath('validityPeriod.start')]}
        name={prefixWithPath('validityPeriod.end')}
        referenceDate={dateMax(start, minStart)}
        showTimeSelect
        isRequired
        min={
          !defaultEndInThePast
            ? {
                value: dateMax(start, minStart),
                message: t('editor.validation_error.end.min'),
              }
            : undefined
        }
        label={t('end')}
      />
    </HStack>
  );
}
