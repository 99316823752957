import { ReactNode } from 'react';
import { PageState } from '../../../ui/pagination/use-pagination-state';
import { PluginToken } from '../../../util/plugin/use-plugins';

export interface AccessGroupHistoryExtension {
  content: (accessGroupId: string, state: PageState, onStageChanged: (state: PageState) => void) => ReactNode;
}

export const ACCESS_GROUP_HISTORY_EXTENSION = new PluginToken<AccessGroupHistoryExtension>(
  'AccessGroupHistoryExtension',
);
