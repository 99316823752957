/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OperationDto } from './OperationDto';
import {
    OperationDtoFromJSON,
    OperationDtoFromJSONTyped,
    OperationDtoToJSON,
} from './OperationDto';
import type { OperationTypeDto } from './OperationTypeDto';
import {
    OperationTypeDtoFromJSON,
    OperationTypeDtoFromJSONTyped,
    OperationTypeDtoToJSON,
} from './OperationTypeDto';

/**
 * 
 * @export
 * @interface ArrayFunctionOperationDto
 */
export interface ArrayFunctionOperationDto {
    /**
     * 
     * @type {OperationTypeDto}
     * @memberof ArrayFunctionOperationDto
     */
    operationType: OperationTypeDto;
    /**
     * 
     * @type {Array<OperationDto>}
     * @memberof ArrayFunctionOperationDto
     */
    subOperations: Array<OperationDto>;
    /**
     * 
     * @type {string}
     * @memberof ArrayFunctionOperationDto
     */
    _function?: ArrayFunctionOperationDtoFunctionEnum;
}


/**
 * @export
 */
export const ArrayFunctionOperationDtoFunctionEnum = {
    FIRST: 'FIRST'
} as const;
export type ArrayFunctionOperationDtoFunctionEnum = typeof ArrayFunctionOperationDtoFunctionEnum[keyof typeof ArrayFunctionOperationDtoFunctionEnum];


/**
 * Check if a given object implements the ArrayFunctionOperationDto interface.
 */
export function instanceOfArrayFunctionOperationDto(value: object): boolean {
    if (!('operationType' in value)) return false;
    if (!('subOperations' in value)) return false;
    return true;
}

export function ArrayFunctionOperationDtoFromJSON(json: any): ArrayFunctionOperationDto {
    return ArrayFunctionOperationDtoFromJSONTyped(json, false);
}

export function ArrayFunctionOperationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArrayFunctionOperationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'operationType': OperationTypeDtoFromJSON(json['operationType']),
        'subOperations': ((json['subOperations'] as Array<any>).map(OperationDtoFromJSON)),
        '_function': json['function'] == null ? undefined : json['function'],
    };
}

export function ArrayFunctionOperationDtoToJSON(value?: ArrayFunctionOperationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'operationType': OperationTypeDtoToJSON(value['operationType']),
        'subOperations': ((value['subOperations'] as Array<any>).map(OperationDtoToJSON)),
        'function': value['_function'],
    };
}

