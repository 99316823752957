/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PersonDto } from './PersonDto';
import {
    PersonDtoFromJSON,
    PersonDtoFromJSONTyped,
    PersonDtoToJSON,
} from './PersonDto';

/**
 * 
 * @export
 * @interface PersonSearchResultDto
 */
export interface PersonSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof PersonSearchResultDto
     */
    resultType: PersonSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PersonSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {PersonDto}
     * @memberof PersonSearchResultDto
     */
    result: PersonDto;
}


/**
 * @export
 */
export const PersonSearchResultDtoResultTypeEnum = {
    PERSON: 'PERSON'
} as const;
export type PersonSearchResultDtoResultTypeEnum = typeof PersonSearchResultDtoResultTypeEnum[keyof typeof PersonSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the PersonSearchResultDto interface.
 */
export function instanceOfPersonSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function PersonSearchResultDtoFromJSON(json: any): PersonSearchResultDto {
    return PersonSearchResultDtoFromJSONTyped(json, false);
}

export function PersonSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': PersonDtoFromJSON(json['result']),
    };
}

export function PersonSearchResultDtoToJSON(value?: PersonSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': PersonDtoToJSON(value['result']),
    };
}

