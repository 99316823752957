/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GroupPersonPermissionDto
 */
export interface GroupPersonPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof GroupPersonPermissionDto
     */
    name: GroupPersonPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof GroupPersonPermissionDto
     */
    action: GroupPersonPermissionDtoActionEnum;
}


/**
 * @export
 */
export const GroupPersonPermissionDtoNameEnum = {
    GROUP_PERSON: 'GROUP-PERSON'
} as const;
export type GroupPersonPermissionDtoNameEnum = typeof GroupPersonPermissionDtoNameEnum[keyof typeof GroupPersonPermissionDtoNameEnum];

/**
 * @export
 */
export const GroupPersonPermissionDtoActionEnum = {
    CAN_LINK: 'CAN_LINK'
} as const;
export type GroupPersonPermissionDtoActionEnum = typeof GroupPersonPermissionDtoActionEnum[keyof typeof GroupPersonPermissionDtoActionEnum];


/**
 * Check if a given object implements the GroupPersonPermissionDto interface.
 */
export function instanceOfGroupPersonPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function GroupPersonPermissionDtoFromJSON(json: any): GroupPersonPermissionDto {
    return GroupPersonPermissionDtoFromJSONTyped(json, false);
}

export function GroupPersonPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupPersonPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function GroupPersonPermissionDtoToJSON(value?: GroupPersonPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

