import { FormControl as BaseFormControl, FormHelperText, HStack, Stack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AccessGroupReferenceDto, EventDto, EventTypeDto } from '../../../api';
import accessGroupApi from '../../../data-access/access-group-api';
import FormControl from '../../../ui/form/form-control';
import MultiValueAsyncSelectControl from '../../../ui/form/select-control/multi-value-async-select-control';
import MultiValueSelectControl from '../../../ui/form/select-control/multi-value-select-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import { useWatchChange2 } from '../../../ui/form/use-watch-change/use-watch-change';
import ValueSelect from '../../../ui/select/value-select';
import { accessControlOptions } from '../event-enum-constants';
import { actualEventTypeOptions, EventSupertype, eventSupertypeOfEventType, eventTypeOptions } from './event-supertype';

/**
 * Represents a form control for classification and access control inside {@link EventForm}
 */
export default function ClassificationAndAccessControl() {
  const { t } = useTranslation('event');
  const { setValue } = useFormContext<Omit<EventDto, 'type'> & { type: EventTypeDto | null }>();
  const type = useWatch<EventDto>({ name: 'type' }) as EventTypeDto;
  const [supertype, setSuperType] = useState<EventSupertype>(eventSupertypeOfEventType[type] ?? EventSupertype.MOVIE);

  useWatchChange2<EventDto>(['type'], (event) => {
    if (event.type !== null) {
      setSuperType(event?.type ? eventSupertypeOfEventType[event?.type] : EventSupertype.MOVIE);
    }
  });

  return (
    <Stack spacing={4}>
      <BaseFormControl isRequired>
        <HStack spacing={6} alignItems="flex-start">
          {/* lh, va: workaround, because property "type" from dto has two UI components*/}
          <FormControl label={t('type.label')} name="supertype" isRequired>
            <ValueSelect<EventSupertype>
              options={eventTypeOptions}
              renderLabel={(option) => t(`type.type.${option}`)}
              value={supertype}
              onChange={(value) => {
                setSuperType(value!);
                setValue('type', null, { shouldDirty: true, shouldValidate: true });
              }}
            />
          </FormControl>

          <FormControl<EventDto> label={t('type.subTypeLabel')} name="type" isRequired>
            <ValueSelectControl
              isRequired
              label={t('type.subTypeLabel')}
              options={actualEventTypeOptions(supertype)}
              renderLabel={(option) => t(`type.subtype.${option}`)}
              name="type"
            />
          </FormControl>
        </HStack>
        <FormHelperText>{t('type.helper_text')}</FormHelperText>
      </BaseFormControl>

      <FormControl<EventDto>
        label={t('access_control.label')}
        name="accessControls"
        helperText={t('access_control.helper_text')}
        isRequired
      >
        <MultiValueSelectControl
          isRequired
          label={t('access_control.label')}
          options={accessControlOptions}
          renderLabel={(option) => t(`accessControlOptions.${option}`)}
          name="accessControls"
        />
      </FormControl>

      <FormControl<EventDto> label={t('access_group.label')} name="accessGroups">
        <MultiValueAsyncSelectControl
          label={t('access_group.label')}
          loadOptions={async (value, size) => {
            const page = await accessGroupApi.searchAccessGroupListItems({
              pageable: { size, sort: ['title'] },
              filter: [],
            });

            return page.content.map(
              (accessGroup): AccessGroupReferenceDto => ({
                id: accessGroup.id,
                title: accessGroup.title,
                englishTitle: accessGroup.englishTitle,
              }),
            );
          }}
          optionIdentifier={(accessGroup) => accessGroup.id}
          renderLabel={(accessGroup) => accessGroup.title}
          name="accessGroups"
        />
      </FormControl>
    </Stack>
  );
}
