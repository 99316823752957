import { chakra } from '@chakra-ui/react';
import React from 'react';
import { EditorProps, InlineFormatOption, LinkButtonElement } from '../format-types';
import { normalizeChildLength } from '../inline';
import { Render } from '../render';
import { WithFunction } from './create-editor';
import { CreateLink } from './with-link';

const format: InlineFormatOption = 'linkButton';

const withLinkButton: () => WithFunction = () => (editor) => {
  const renderLinkButton: Render<'block'> = {
    type: format,
    render: ({ children, attributes, element }) => {
      element = element as LinkButtonElement;
      return (
        <chakra.a
          href={element.url}
          sx={{
            border: '1px solid #D62E4A',
            padding: '10px 25px',
            borderRadius: '40px',
            fontSize: '16px',
            lineHeight: '55px',
            color: '#D62E4A',
          }}
          {...attributes}
        >
          {children}
        </chakra.a>
      );
    },
  };
  editor.renderers = [...editor.renderers, renderLinkButton];

  const linkButtonToolbarButton = (editor: EditorProps) => <CreateLink key={format} editor={editor} format={format} />;
  editor.toolbarButtons = [...editor.toolbarButtons, linkButtonToolbarButton];

  const { isInline } = editor;
  editor.isInline = (element) => format === element.type || isInline(element);

  normalizeChildLength(editor, 26, format);

  return editor;
};

export default withLinkButton;
