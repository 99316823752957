import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { TagRelationTypeDto } from '../../../../api';
import { BreadcrumbRouteObject } from '../../../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../../../ui/helmet/helmet-outlet';
import { standIcon } from '../../../../ui/icons/business-objects';
import useFetcher from '../../../../util/swr/use-fetcher';
import StandPageRoute from '../../../stand/stand-page/stand-page-route';
import { fetchStand } from '../../../stand/stand-queries';
import usePermission from '../../../permission/use-permission';

const StandTagRelationContent = React.lazy(() => import('../tag-relation-page-content'));

const standTagRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: '',
    element: <StandPageRoute />,
    children: [
      {
        path: 'tags',
        element: <StandTagContent />,
        handle: {
          helmet: <StandTagsHelmet />,
        },
      },
    ],
  },
];

export default standTagRoutes;

function StandTagContent() {
  const relationType = TagRelationTypeDto.STAND;

  const params = useParams<{ standId: string }>();
  const standId = params.standId;
  invariant(standId, 'Empty standId');

  const stand = useFetcher(fetchStand, { id: params.standId });
  const standRef = {
    id: standId,
    name: stand?.name,
  };

  const fromAnnotation = standRef.name!;

  const { hasPermission } = usePermission();

  return (
    <StandTagRelationContent
      relationRef={standRef}
      relationType={relationType}
      fromIcon={standIcon}
      fromAnnotation={fromAnnotation}
      canLink={hasPermission('STAND-TAG:CAN_LINK')}
      canUnlink={hasPermission('STAND-TAG:CAN_UNLINK')}
    />
  );
}

function StandTagsHelmet() {
  const { t } = useTranslation('tag');
  const params = useParams<{ standId: string }>();
  invariant(params.standId, 'Empty standId');
  const stand = useFetcher(fetchStand, { id: params.standId });

  return (
    <Helmet
      title={t('relations.stand.helmet', {
        name: stand.name,
      })}
    />
  );
}
