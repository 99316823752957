import { Stack } from '@chakra-ui/react';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import PageForm from '../../../../ui/page/page-form';
import PersonMailingRecipientControl, {
  AddPersonRecipient,
} from '../../../mailing/mailing-recipient-editor/recipient-controls/person-mailing-recipient-control';
import useMailingRecipientEditor from '../../../mailing/mailing-recipient-editor/use-mailing-recipient-editor';
import PersonOnGuestListMailingRecipientControl, {
  AddPersonOnGuestListRecipient,
} from '../person-on-guest-list-recipient-control';

interface EmergencyGuestListMailingRecipientEditorProps {
  eventId: string;
  mailingId: string;
}

type FormType = AddPersonRecipient & AddPersonOnGuestListRecipient;

export default function EmergencyGuestListMailingRecipientEditor({
  eventId,
  mailingId,
}: EmergencyGuestListMailingRecipientEditorProps) {
  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const { form, onValid } = useMailingRecipientEditor<FormType>(mailingId, {
    recipients: [],
    personRecipients: [],
  });

  const handleValid = ({ recipients, personRecipients }: FormType) => {
    onValid({ recipients: [...recipients, ...personRecipients] });
  };

  return (
    <FormProvider {...form}>
      <PageForm onValid={handleValid} height="full" initialFocusRef={initialFocusRef}>
        <Stack spacing={4}>
          <PersonMailingRecipientControl mailingId={mailingId} />
          <PersonOnGuestListMailingRecipientControl eventId={eventId} mailingId={mailingId} />
        </Stack>
      </PageForm>
    </FormProvider>
  );
}
