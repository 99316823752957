import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import TeamBreadcrumb from './common/team-breadcrumb';

const teamRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'teams',
    handle: {
      breadcrumb: <Translate ns="team" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./team-lister/team-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./team-editor/new-team-route'),
      },
      {
        path: ':teamId',
        handle: {
          breadcrumb: <TeamBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./team-page/team-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./team-viewer/team-viewer-route'),
              },
              {
                path: 'relations',
                lazy: () => import('./team-relation/team-relation-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./team-history/team-history-route'),
              },
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./team-editor/edit-team-route'),
          },
        ],
      },
    ],
  },
];

export default teamRoutes;
