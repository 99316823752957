/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateTimeRangeDto } from './ClosedDateTimeRangeDto';
import {
    ClosedDateTimeRangeDtoFromJSON,
    ClosedDateTimeRangeDtoFromJSONTyped,
    ClosedDateTimeRangeDtoToJSON,
} from './ClosedDateTimeRangeDto';

/**
 * 
 * @export
 * @interface AvailabilityDto
 */
export interface AvailabilityDto {
    /**
     * 
     * @type {ClosedDateTimeRangeDto}
     * @memberof AvailabilityDto
     */
    availability?: ClosedDateTimeRangeDto;
}

/**
 * Check if a given object implements the AvailabilityDto interface.
 */
export function instanceOfAvailabilityDto(value: object): boolean {
    return true;
}

export function AvailabilityDtoFromJSON(json: any): AvailabilityDto {
    return AvailabilityDtoFromJSONTyped(json, false);
}

export function AvailabilityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailabilityDto {
    if (json == null) {
        return json;
    }
    return {
        
        'availability': json['availability'] == null ? undefined : ClosedDateTimeRangeDtoFromJSON(json['availability']),
    };
}

export function AvailabilityDtoToJSON(value?: AvailabilityDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'availability': ClosedDateTimeRangeDtoToJSON(value['availability']),
    };
}

