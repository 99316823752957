/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyReferenceDto } from './CompanyReferenceDto';
import {
    CompanyReferenceDtoFromJSON,
    CompanyReferenceDtoFromJSONTyped,
    CompanyReferenceDtoToJSON,
} from './CompanyReferenceDto';

/**
 * Represents a duplicate relation between two companies.
 * @export
 * @interface CompanyDuplicateDto
 */
export interface CompanyDuplicateDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyDuplicateDto
     */
    key: string;
    /**
     * 
     * @type {CompanyReferenceDto}
     * @memberof CompanyDuplicateDto
     */
    companyA: CompanyReferenceDto;
    /**
     * 
     * @type {CompanyReferenceDto}
     * @memberof CompanyDuplicateDto
     */
    companyB: CompanyReferenceDto;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyDuplicateDto
     */
    notDuplicate: boolean;
}

/**
 * Check if a given object implements the CompanyDuplicateDto interface.
 */
export function instanceOfCompanyDuplicateDto(value: object): boolean {
    if (!('key' in value)) return false;
    if (!('companyA' in value)) return false;
    if (!('companyB' in value)) return false;
    if (!('notDuplicate' in value)) return false;
    return true;
}

export function CompanyDuplicateDtoFromJSON(json: any): CompanyDuplicateDto {
    return CompanyDuplicateDtoFromJSONTyped(json, false);
}

export function CompanyDuplicateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyDuplicateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'companyA': CompanyReferenceDtoFromJSON(json['companyA']),
        'companyB': CompanyReferenceDtoFromJSON(json['companyB']),
        'notDuplicate': json['notDuplicate'],
    };
}

export function CompanyDuplicateDtoToJSON(value?: CompanyDuplicateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'key': value['key'],
        'companyA': CompanyReferenceDtoToJSON(value['companyA']),
        'companyB': CompanyReferenceDtoToJSON(value['companyB']),
        'notDuplicate': value['notDuplicate'],
    };
}

