/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The access control of an event
 * @export
 */
export const EventAccessControlDto = {
    ACCREDITED: 'ACCREDITED',
    INVITED: 'INVITED',
    JURY: 'JURY',
    PRESS_ALL: 'PRESS_ALL',
    PRESS_DAILY: 'PRESS_DAILY',
    PUBLIC_AUDIENCE: 'PUBLIC_AUDIENCE',
    REGISTERED: 'REGISTERED'
} as const;
export type EventAccessControlDto = typeof EventAccessControlDto[keyof typeof EventAccessControlDto];


export function EventAccessControlDtoFromJSON(json: any): EventAccessControlDto {
    return EventAccessControlDtoFromJSONTyped(json, false);
}

export function EventAccessControlDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventAccessControlDto {
    return json as EventAccessControlDto;
}

export function EventAccessControlDtoToJSON(value?: EventAccessControlDto | null): any {
    return value as any;
}

