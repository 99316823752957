import React from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchFormat } from '../format-queries';

export default function FormatBreadcrumb() {
  const { formatId } = useParams<{ formatId: string }>();
  invariant(formatId, 'Empty formatId');
  const format = useFetcher(fetchFormat, { id: formatId });

  return <>{format.name}</>;
}
