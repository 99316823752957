/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PersonDto } from './PersonDto';
import {
    PersonDtoFromJSON,
    PersonDtoFromJSONTyped,
    PersonDtoToJSON,
} from './PersonDto';

/**
 * 
 * @export
 * @interface PersonPageDto
 */
export interface PersonPageDto {
    /**
     * The number of the page.
     * @type {number}
     * @memberof PersonPageDto
     */
    page: number;
    /**
     * The size of the page.
     * @type {number}
     * @memberof PersonPageDto
     */
    size: number;
    /**
     * The total number of data returned by the search.
     * @type {number}
     * @memberof PersonPageDto
     */
    totalElements: number;
    /**
     * The total number of pages of current size to fit all data.
     * @type {number}
     * @memberof PersonPageDto
     */
    totalPages: number;
    /**
     * 
     * @type {Array<PersonDto>}
     * @memberof PersonPageDto
     */
    content: Array<PersonDto>;
}

/**
 * Check if a given object implements the PersonPageDto interface.
 */
export function instanceOfPersonPageDto(value: object): boolean {
    if (!('page' in value)) return false;
    if (!('size' in value)) return false;
    if (!('totalElements' in value)) return false;
    if (!('totalPages' in value)) return false;
    if (!('content' in value)) return false;
    return true;
}

export function PersonPageDtoFromJSON(json: any): PersonPageDto {
    return PersonPageDtoFromJSONTyped(json, false);
}

export function PersonPageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonPageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'page': json['page'],
        'size': json['size'],
        'totalElements': json['totalElements'],
        'totalPages': json['totalPages'],
        'content': ((json['content'] as Array<any>).map(PersonDtoFromJSON)),
    };
}

export function PersonPageDtoToJSON(value?: PersonPageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'page': value['page'],
        'size': value['size'],
        'totalElements': value['totalElements'],
        'totalPages': value['totalPages'],
        'content': ((value['content'] as Array<any>).map(PersonDtoToJSON)),
    };
}

