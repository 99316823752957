export const MAX_INT_32: number = 0x7fffffff;
export const DEBOUNCE_TIME = 300;
//Only strings including at least one latin character and optional punctuation marks
export const PERSON_NAME = /^(?=.*\p{Script=Latin})[\p{Script=Latin}\p{P} ]*$/u;
export const LATIN_ALPHABET_AND_NUMBERS_AND_PUNCATIONS = /^[\p{Script=Latin}\p{P}\d ]*$/u;
export const LATIN_ALPHABET_AND_NUMBERS = /^[\p{Script=Latin}\d]*$/u;
export const EMAIL_PATTERN = /^(?=.{1,64}@)[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
export const LETTERS_NUMBERS_AND_SPACE = /^[\p{Script=Latin}\d ]*$/u;

export const CURRENCY_AMOUNT = /^\d+(?:[.,]\d{0,2})?$/;
export const PERCENTAGE_AMOUNT = /^\d+(?:[.,]\d{0,2})?$/;
export const CONTAINS_LETTER_OR_DIGIT = /[\p{L}\p{Nd}]/u;
