import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EditionDto } from '../../../api';
import DateInputControl from '../../../ui/form/date-input-control/date-input-control';
import ErrorMessage from '../../../ui/form/error-message';
import validDate from '../../../util/date/valid-date';

export default function EditionFestivalDateRangeControl() {
  const { t } = useTranslation('edition');
  const {
    watch,
    formState: { errors },
  } = useFormContext<EditionDto>();

  const start = validDate(watch('festivalDateRange.start'));
  const end = validDate(watch('festivalDateRange.end'));

  return (
    <>
      <FormControl isInvalid={errors.festivalDateRange?.start != null}>
        <FormLabel>{t('festival_start')}</FormLabel>
        <DateInputControl<EditionDto>
          deps={['festivalDateRange.end']}
          name="festivalDateRange.start"
          required={end && t('editor.validation_error.festival_start.required')}
          max={{ value: end, message: t('editor.validation_error.festival_start.max') }}
        />
        <ErrorMessage name="festivalDateRange.start" as={FormErrorMessage} />
      </FormControl>
      <FormControl isInvalid={errors.festivalDateRange?.end != null}>
        <FormLabel>{t('festival_end')}</FormLabel>
        <DateInputControl<EditionDto>
          deps={['festivalDateRange.start']}
          name="festivalDateRange.end"
          required={start && t('editor.validation_error.festival_end.required')}
          min={{ value: start, message: t('editor.validation_error.festival_end.min') }}
        />
        <ErrorMessage name="festivalDateRange.end" as={FormErrorMessage} />
      </FormControl>
    </>
  );
}
