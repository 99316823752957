/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * the occupations to delete or confirm, can be null if no occupations exist
 * @export
 * @interface ConfirmationBodyOccupationsDto
 */
export interface ConfirmationBodyOccupationsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ConfirmationBodyOccupationsDto
     */
    occupationsToConfirm: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConfirmationBodyOccupationsDto
     */
    occupationsToDelete: Array<string>;
}

/**
 * Check if a given object implements the ConfirmationBodyOccupationsDto interface.
 */
export function instanceOfConfirmationBodyOccupationsDto(value: object): boolean {
    if (!('occupationsToConfirm' in value)) return false;
    if (!('occupationsToDelete' in value)) return false;
    return true;
}

export function ConfirmationBodyOccupationsDtoFromJSON(json: any): ConfirmationBodyOccupationsDto {
    return ConfirmationBodyOccupationsDtoFromJSONTyped(json, false);
}

export function ConfirmationBodyOccupationsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfirmationBodyOccupationsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'occupationsToConfirm': json['occupationsToConfirm'],
        'occupationsToDelete': json['occupationsToDelete'],
    };
}

export function ConfirmationBodyOccupationsDtoToJSON(value?: ConfirmationBodyOccupationsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'occupationsToConfirm': value['occupationsToConfirm'],
        'occupationsToDelete': value['occupationsToDelete'],
    };
}

