import { RecipientPageDto, RecipientTypeDto } from '../../../api';
import { useDataTableState } from '../../../ui/data-table';
import useRequestParams from '../../../ui/data-table/use-request-params';
import fallbackMiddleware from '../../../util/swr/fallback-middleware';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchPossibleRecipients } from '../mailing-queries';

export default function usePossibleRecipients(
  mailingId: string,
  { onSuccess, recipientType }: { onSuccess?: (data: RecipientPageDto) => void; recipientType?: RecipientTypeDto },
) {
  const [state, setState] = useDataTableState();
  const recipientPage = useFetcher(
    fetchPossibleRecipients,
    { id: mailingId, recipientType, ...useRequestParams(state) },
    { use: [fallbackMiddleware], onSuccess },
  );

  return { state, setState, recipientPage };
}
