/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyTagPermissionDto
 */
export interface CompanyTagPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyTagPermissionDto
     */
    name: CompanyTagPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanyTagPermissionDto
     */
    action: CompanyTagPermissionDtoActionEnum;
}


/**
 * @export
 */
export const CompanyTagPermissionDtoNameEnum = {
    COMPANY_TAG: 'COMPANY-TAG'
} as const;
export type CompanyTagPermissionDtoNameEnum = typeof CompanyTagPermissionDtoNameEnum[keyof typeof CompanyTagPermissionDtoNameEnum];

/**
 * @export
 */
export const CompanyTagPermissionDtoActionEnum = {
    LINK: 'CAN_LINK',
    UNLINK: 'CAN_UNLINK'
} as const;
export type CompanyTagPermissionDtoActionEnum = typeof CompanyTagPermissionDtoActionEnum[keyof typeof CompanyTagPermissionDtoActionEnum];


/**
 * Check if a given object implements the CompanyTagPermissionDto interface.
 */
export function instanceOfCompanyTagPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function CompanyTagPermissionDtoFromJSON(json: any): CompanyTagPermissionDto {
    return CompanyTagPermissionDtoFromJSONTyped(json, false);
}

export function CompanyTagPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyTagPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function CompanyTagPermissionDtoToJSON(value?: CompanyTagPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

