import { Configuration } from '../api';
import AuthMiddleware from './auth-middleware';
import basePath from './base-path';
import ErrorMiddleware from './error-middleware';
import stringifyQueryParams from './stringify-query-params';
import VersionMismatchMiddleware from './version-mismatch-middleware';

const configuration = new Configuration({
  basePath,
  queryParamsStringify: stringifyQueryParams,
  middleware: [VersionMismatchMiddleware, AuthMiddleware, ErrorMiddleware],
});

export default configuration;
