/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FileMetadataDto } from './FileMetadataDto';
import {
    FileMetadataDtoFromJSON,
    FileMetadataDtoFromJSONTyped,
    FileMetadataDtoToJSON,
} from './FileMetadataDto';

/**
 * 
 * @export
 * @interface ParticipationDocumentsInnerDto
 */
export interface ParticipationDocumentsInnerDto {
    /**
     * 
     * @type {FileMetadataDto}
     * @memberof ParticipationDocumentsInnerDto
     */
    file: FileMetadataDto;
    /**
     * 
     * @type {string}
     * @memberof ParticipationDocumentsInnerDto
     */
    label?: ParticipationDocumentsInnerDtoLabelEnum;
}


/**
 * @export
 */
export const ParticipationDocumentsInnerDtoLabelEnum = {
    REQUEST: 'REQUEST',
    APPLICATION: 'APPLICATION',
    PRESS_DOCUMENT: 'PRESS_DOCUMENT',
    LEGITIMATION: 'LEGITIMATION',
    OTHER: 'OTHER',
    VISA_GENERATED: 'VISA_GENERATED',
    VISA_UPLOADED: 'VISA_UPLOADED'
} as const;
export type ParticipationDocumentsInnerDtoLabelEnum = typeof ParticipationDocumentsInnerDtoLabelEnum[keyof typeof ParticipationDocumentsInnerDtoLabelEnum];


/**
 * Check if a given object implements the ParticipationDocumentsInnerDto interface.
 */
export function instanceOfParticipationDocumentsInnerDto(value: object): boolean {
    if (!('file' in value)) return false;
    return true;
}

export function ParticipationDocumentsInnerDtoFromJSON(json: any): ParticipationDocumentsInnerDto {
    return ParticipationDocumentsInnerDtoFromJSONTyped(json, false);
}

export function ParticipationDocumentsInnerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipationDocumentsInnerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'file': FileMetadataDtoFromJSON(json['file']),
        'label': json['label'] == null ? undefined : json['label'],
    };
}

export function ParticipationDocumentsInnerDtoToJSON(value?: ParticipationDocumentsInnerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'file': FileMetadataDtoToJSON(value['file']),
        'label': value['label'],
    };
}

