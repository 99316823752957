/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BlockElementDto } from './BlockElementDto';
import {
    BlockElementDtoFromJSON,
    BlockElementDtoFromJSONTyped,
    BlockElementDtoToJSON,
} from './BlockElementDto';
import type { MailingDesignFooterTextBlock6Dto } from './MailingDesignFooterTextBlock6Dto';
import {
    MailingDesignFooterTextBlock6DtoFromJSON,
    MailingDesignFooterTextBlock6DtoFromJSONTyped,
    MailingDesignFooterTextBlock6DtoToJSON,
} from './MailingDesignFooterTextBlock6Dto';
import type { SocialMediaLinksDto } from './SocialMediaLinksDto';
import {
    SocialMediaLinksDtoFromJSON,
    SocialMediaLinksDtoFromJSONTyped,
    SocialMediaLinksDtoToJSON,
} from './SocialMediaLinksDto';

/**
 * A footer for the mailing design
 * @export
 * @interface MailingDesignFooterDto
 */
export interface MailingDesignFooterDto {
    /**
     * A hex color code
     * @type {string}
     * @memberof MailingDesignFooterDto
     */
    backgroundColor: string;
    /**
     * A hex color code
     * @type {string}
     * @memberof MailingDesignFooterDto
     */
    textColor: string;
    /**
     * 
     * @type {Array<BlockElementDto>}
     * @memberof MailingDesignFooterDto
     */
    textBlock1: Array<BlockElementDto>;
    /**
     * 
     * @type {SocialMediaLinksDto}
     * @memberof MailingDesignFooterDto
     */
    textBlock2: SocialMediaLinksDto;
    /**
     * 
     * @type {Array<BlockElementDto>}
     * @memberof MailingDesignFooterDto
     */
    textBlock3: Array<BlockElementDto>;
    /**
     * 
     * @type {Array<BlockElementDto>}
     * @memberof MailingDesignFooterDto
     */
    textBlock4: Array<BlockElementDto>;
    /**
     * 
     * @type {Array<BlockElementDto>}
     * @memberof MailingDesignFooterDto
     */
    textBlock5: Array<BlockElementDto>;
    /**
     * 
     * @type {MailingDesignFooterTextBlock6Dto}
     * @memberof MailingDesignFooterDto
     */
    textBlock6: MailingDesignFooterTextBlock6Dto;
}

/**
 * Check if a given object implements the MailingDesignFooterDto interface.
 */
export function instanceOfMailingDesignFooterDto(value: object): boolean {
    if (!('backgroundColor' in value)) return false;
    if (!('textColor' in value)) return false;
    if (!('textBlock1' in value)) return false;
    if (!('textBlock2' in value)) return false;
    if (!('textBlock3' in value)) return false;
    if (!('textBlock4' in value)) return false;
    if (!('textBlock5' in value)) return false;
    if (!('textBlock6' in value)) return false;
    return true;
}

export function MailingDesignFooterDtoFromJSON(json: any): MailingDesignFooterDto {
    return MailingDesignFooterDtoFromJSONTyped(json, false);
}

export function MailingDesignFooterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingDesignFooterDto {
    if (json == null) {
        return json;
    }
    return {
        
        'backgroundColor': json['backgroundColor'],
        'textColor': json['textColor'],
        'textBlock1': ((json['textBlock1'] as Array<any>).map(BlockElementDtoFromJSON)),
        'textBlock2': SocialMediaLinksDtoFromJSON(json['textBlock2']),
        'textBlock3': ((json['textBlock3'] as Array<any>).map(BlockElementDtoFromJSON)),
        'textBlock4': ((json['textBlock4'] as Array<any>).map(BlockElementDtoFromJSON)),
        'textBlock5': ((json['textBlock5'] as Array<any>).map(BlockElementDtoFromJSON)),
        'textBlock6': MailingDesignFooterTextBlock6DtoFromJSON(json['textBlock6']),
    };
}

export function MailingDesignFooterDtoToJSON(value?: MailingDesignFooterDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'backgroundColor': value['backgroundColor'],
        'textColor': value['textColor'],
        'textBlock1': ((value['textBlock1'] as Array<any>).map(BlockElementDtoToJSON)),
        'textBlock2': SocialMediaLinksDtoToJSON(value['textBlock2']),
        'textBlock3': ((value['textBlock3'] as Array<any>).map(BlockElementDtoToJSON)),
        'textBlock4': ((value['textBlock4'] as Array<any>).map(BlockElementDtoToJSON)),
        'textBlock5': ((value['textBlock5'] as Array<any>).map(BlockElementDtoToJSON)),
        'textBlock6': MailingDesignFooterTextBlock6DtoToJSON(value['textBlock6']),
    };
}

