/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EventCopySettingsDto } from './EventCopySettingsDto';
import {
    EventCopySettingsDtoFromJSON,
    EventCopySettingsDtoFromJSONTyped,
    EventCopySettingsDtoToJSON,
} from './EventCopySettingsDto';
import type { EventDto } from './EventDto';
import {
    EventDtoFromJSON,
    EventDtoFromJSONTyped,
    EventDtoToJSON,
} from './EventDto';

/**
 * Wrapper object of event containing optional copy settings.
 * @export
 * @interface EventWrapperDto
 */
export interface EventWrapperDto {
    /**
     * 
     * @type {EventDto}
     * @memberof EventWrapperDto
     */
    event: EventDto;
    /**
     * 
     * @type {EventCopySettingsDto}
     * @memberof EventWrapperDto
     */
    copySettings?: EventCopySettingsDto;
}

/**
 * Check if a given object implements the EventWrapperDto interface.
 */
export function instanceOfEventWrapperDto(value: object): boolean {
    if (!('event' in value)) return false;
    return true;
}

export function EventWrapperDtoFromJSON(json: any): EventWrapperDto {
    return EventWrapperDtoFromJSONTyped(json, false);
}

export function EventWrapperDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventWrapperDto {
    if (json == null) {
        return json;
    }
    return {
        
        'event': EventDtoFromJSON(json['event']),
        'copySettings': json['copySettings'] == null ? undefined : EventCopySettingsDtoFromJSON(json['copySettings']),
    };
}

export function EventWrapperDtoToJSON(value?: EventWrapperDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'event': EventDtoToJSON(value['event']),
        'copySettings': EventCopySettingsDtoToJSON(value['copySettings']),
    };
}

