/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OperationDto } from './OperationDto';
import {
    OperationDtoFromJSON,
    OperationDtoFromJSONTyped,
    OperationDtoToJSON,
} from './OperationDto';
import type { OperationTypeDto } from './OperationTypeDto';
import {
    OperationTypeDtoFromJSON,
    OperationTypeDtoFromJSONTyped,
    OperationTypeDtoToJSON,
} from './OperationTypeDto';

/**
 * 
 * @export
 * @interface ForEdgeOperationDto
 */
export interface ForEdgeOperationDto {
    /**
     * 
     * @type {OperationTypeDto}
     * @memberof ForEdgeOperationDto
     */
    operationType: OperationTypeDto;
    /**
     * 
     * @type {Array<OperationDto>}
     * @memberof ForEdgeOperationDto
     */
    subOperations: Array<OperationDto>;
    /**
     * 
     * @type {string}
     * @memberof ForEdgeOperationDto
     */
    collection?: string;
    /**
     * 
     * @type {string}
     * @memberof ForEdgeOperationDto
     */
    joinAlias?: string;
    /**
     * 
     * @type {string}
     * @memberof ForEdgeOperationDto
     */
    joinCollection?: string;
    /**
     * 
     * @type {string}
     * @memberof ForEdgeOperationDto
     */
    targetAlias?: string;
    /**
     * 
     * @type {string}
     * @memberof ForEdgeOperationDto
     */
    targetCollection?: string;
    /**
     * 
     * @type {string}
     * @memberof ForEdgeOperationDto
     */
    edgeAlias?: string;
}

/**
 * Check if a given object implements the ForEdgeOperationDto interface.
 */
export function instanceOfForEdgeOperationDto(value: object): boolean {
    if (!('operationType' in value)) return false;
    if (!('subOperations' in value)) return false;
    return true;
}

export function ForEdgeOperationDtoFromJSON(json: any): ForEdgeOperationDto {
    return ForEdgeOperationDtoFromJSONTyped(json, false);
}

export function ForEdgeOperationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForEdgeOperationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'operationType': OperationTypeDtoFromJSON(json['operationType']),
        'subOperations': ((json['subOperations'] as Array<any>).map(OperationDtoFromJSON)),
        'collection': json['collection'] == null ? undefined : json['collection'],
        'joinAlias': json['joinAlias'] == null ? undefined : json['joinAlias'],
        'joinCollection': json['joinCollection'] == null ? undefined : json['joinCollection'],
        'targetAlias': json['targetAlias'] == null ? undefined : json['targetAlias'],
        'targetCollection': json['targetCollection'] == null ? undefined : json['targetCollection'],
        'edgeAlias': json['edgeAlias'] == null ? undefined : json['edgeAlias'],
    };
}

export function ForEdgeOperationDtoToJSON(value?: ForEdgeOperationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'operationType': OperationTypeDtoToJSON(value['operationType']),
        'subOperations': ((value['subOperations'] as Array<any>).map(OperationDtoToJSON)),
        'collection': value['collection'],
        'joinAlias': value['joinAlias'],
        'joinCollection': value['joinCollection'],
        'targetAlias': value['targetAlias'],
        'targetCollection': value['targetCollection'],
        'edgeAlias': value['edgeAlias'],
    };
}

