/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BerlinaleLinkDto
 */
export interface BerlinaleLinkDto {
    /**
     * 
     * @type {string}
     * @memberof BerlinaleLinkDto
     */
    textGerman: string;
    /**
     * 
     * @type {string}
     * @memberof BerlinaleLinkDto
     */
    textEnglish: string;
    /**
     * A URL restricted through uri format of open api
     * @type {string}
     * @memberof BerlinaleLinkDto
     */
    linkGerman: string;
    /**
     * A URL restricted through uri format of open api
     * @type {string}
     * @memberof BerlinaleLinkDto
     */
    linkEnglish: string;
    /**
     * 
     * @type {Date}
     * @memberof BerlinaleLinkDto
     */
    timestamp: Date;
}

/**
 * Check if a given object implements the BerlinaleLinkDto interface.
 */
export function instanceOfBerlinaleLinkDto(value: object): boolean {
    if (!('textGerman' in value)) return false;
    if (!('textEnglish' in value)) return false;
    if (!('linkGerman' in value)) return false;
    if (!('linkEnglish' in value)) return false;
    if (!('timestamp' in value)) return false;
    return true;
}

export function BerlinaleLinkDtoFromJSON(json: any): BerlinaleLinkDto {
    return BerlinaleLinkDtoFromJSONTyped(json, false);
}

export function BerlinaleLinkDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BerlinaleLinkDto {
    if (json == null) {
        return json;
    }
    return {
        
        'textGerman': json['textGerman'],
        'textEnglish': json['textEnglish'],
        'linkGerman': json['linkGerman'],
        'linkEnglish': json['linkEnglish'],
        'timestamp': (new Date(json['timestamp'])),
    };
}

export function BerlinaleLinkDtoToJSON(value?: BerlinaleLinkDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'textGerman': value['textGerman'],
        'textEnglish': value['textEnglish'],
        'linkGerman': value['linkGerman'],
        'linkEnglish': value['linkEnglish'],
        'timestamp': ((value['timestamp']).toISOString()),
    };
}

