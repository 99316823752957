import React from 'react';
import { ValidateResult } from 'react-hook-form';
import groupPersonApi from '../../../../../data-access/group-person-api';
import Translate from '../../../../../util/translate/translate';

export async function validateGroupCapacity(capacity: number, groupId: string) {
  const existingRelations = await groupPersonApi.searchGroupPersonRelations({ filter: [`group.id,eq,${groupId}`] });

  return (
    capacity >= existingRelations.content.length ||
    ((<Translate i18nKey="capacity_validation" ns="group" />) as unknown as ValidateResult)
  );
}
