/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PermissionDto } from './PermissionDto';
import {
    PermissionDtoFromJSON,
    PermissionDtoFromJSONTyped,
    PermissionDtoToJSON,
} from './PermissionDto';

/**
 * 
 * @export
 * @interface PermissionsPerLevelDto
 */
export interface PermissionsPerLevelDto {
    /**
     * 
     * @type {Array<PermissionDto>}
     * @memberof PermissionsPerLevelDto
     */
    minimal: Array<PermissionDto>;
    /**
     * 
     * @type {Array<PermissionDto>}
     * @memberof PermissionsPerLevelDto
     */
    basic: Array<PermissionDto>;
    /**
     * 
     * @type {Array<PermissionDto>}
     * @memberof PermissionsPerLevelDto
     */
    medium: Array<PermissionDto>;
    /**
     * 
     * @type {Array<PermissionDto>}
     * @memberof PermissionsPerLevelDto
     */
    extended: Array<PermissionDto>;
    /**
     * 
     * @type {Array<PermissionDto>}
     * @memberof PermissionsPerLevelDto
     */
    admin: Array<PermissionDto>;
}

/**
 * Check if a given object implements the PermissionsPerLevelDto interface.
 */
export function instanceOfPermissionsPerLevelDto(value: object): boolean {
    if (!('minimal' in value)) return false;
    if (!('basic' in value)) return false;
    if (!('medium' in value)) return false;
    if (!('extended' in value)) return false;
    if (!('admin' in value)) return false;
    return true;
}

export function PermissionsPerLevelDtoFromJSON(json: any): PermissionsPerLevelDto {
    return PermissionsPerLevelDtoFromJSONTyped(json, false);
}

export function PermissionsPerLevelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionsPerLevelDto {
    if (json == null) {
        return json;
    }
    return {
        
        'minimal': ((json['minimal'] as Array<any>).map(PermissionDtoFromJSON)),
        'basic': ((json['basic'] as Array<any>).map(PermissionDtoFromJSON)),
        'medium': ((json['medium'] as Array<any>).map(PermissionDtoFromJSON)),
        'extended': ((json['extended'] as Array<any>).map(PermissionDtoFromJSON)),
        'admin': ((json['admin'] as Array<any>).map(PermissionDtoFromJSON)),
    };
}

export function PermissionsPerLevelDtoToJSON(value?: PermissionsPerLevelDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'minimal': ((value['minimal'] as Array<any>).map(PermissionDtoToJSON)),
        'basic': ((value['basic'] as Array<any>).map(PermissionDtoToJSON)),
        'medium': ((value['medium'] as Array<any>).map(PermissionDtoToJSON)),
        'extended': ((value['extended'] as Array<any>).map(PermissionDtoToJSON)),
        'admin': ((value['admin'] as Array<any>).map(PermissionDtoToJSON)),
    };
}

