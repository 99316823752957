/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Travel data to be shown within a visa
 * @export
 */
export const TravelDataToShowDto = {
    PARTICIPATION_PERIOD: 'PARTICIPATION_PERIOD',
    IFB_TRAVEL_HEALTH_INSURANCE: 'IFB_TRAVEL_HEALTH_INSURANCE',
    IFB_ASSUMPTION_OF_TRAVEL_COSTS: 'IFB_ASSUMPTION_OF_TRAVEL_COSTS',
    DATE_OF_ARRIVAL: 'DATE_OF_ARRIVAL',
    DATE_OF_DEPARTURE: 'DATE_OF_DEPARTURE'
} as const;
export type TravelDataToShowDto = typeof TravelDataToShowDto[keyof typeof TravelDataToShowDto];


export function TravelDataToShowDtoFromJSON(json: any): TravelDataToShowDto {
    return TravelDataToShowDtoFromJSONTyped(json, false);
}

export function TravelDataToShowDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TravelDataToShowDto {
    return json as TravelDataToShowDto;
}

export function TravelDataToShowDtoToJSON(value?: TravelDataToShowDto | null): any {
    return value as any;
}

