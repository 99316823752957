import { Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StaffAccountReferenceDto } from '../../../api';
import { staffIcon } from '../../../ui/icons/business-objects';
import Reference from '../../../ui/reference/reference';

export interface StaffAccountReferenceProps {
  staffAccountReference: StaffAccountReferenceDto;
  hideIcon?: boolean;
  color?: string;
}

export default function StaffAccountReference({
  staffAccountReference,
  color,
  hideIcon = false,
  ...props
}: StaffAccountReferenceProps) {
  const { t } = useTranslation('common');

  // staff id is only null for technical accounts like active directory sync etc.
  if (staffAccountReference.staffId == null) {
    return (
      <Text as="span" fontWeight="medium">
        {t('viewer.system')}
      </Text>
    );
  }

  return (
    <Reference
      icon={staffIcon}
      hideIcon={hideIcon}
      color={color}
      to={'/staff/' + staffAccountReference.staffId}
      necessaryPermission="STAFF:CAN_SEE_DETAILS"
      {...props}
    >
      {staffAccountReference.displayName}
    </Reference>
  );
}
