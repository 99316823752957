/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OwnerTypeDto } from './OwnerTypeDto';
import {
    OwnerTypeDtoFromJSON,
    OwnerTypeDtoFromJSONTyped,
    OwnerTypeDtoToJSON,
} from './OwnerTypeDto';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';

/**
 * 
 * @export
 * @interface OwnerDto
 */
export interface OwnerDto {
    /**
     * 
     * @type {SectionReferenceDto}
     * @memberof OwnerDto
     */
    section: SectionReferenceDto;
    /**
     * 
     * @type {OwnerTypeDto}
     * @memberof OwnerDto
     */
    type: OwnerTypeDto;
    /**
     * 
     * @type {Date}
     * @memberof OwnerDto
     */
    timestamp?: Date;
}

/**
 * Check if a given object implements the OwnerDto interface.
 */
export function instanceOfOwnerDto(value: object): boolean {
    if (!('section' in value)) return false;
    if (!('type' in value)) return false;
    return true;
}

export function OwnerDtoFromJSON(json: any): OwnerDto {
    return OwnerDtoFromJSONTyped(json, false);
}

export function OwnerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'section': SectionReferenceDtoFromJSON(json['section']),
        'type': OwnerTypeDtoFromJSON(json['type']),
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
    };
}

export function OwnerDtoToJSON(value?: OwnerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'section': SectionReferenceDtoToJSON(value['section']),
        'type': OwnerTypeDtoToJSON(value['type']),
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
    };
}

