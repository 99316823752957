import React from 'react';
import invariant from 'tiny-invariant';
import { PersonOrderListItemDto } from '../../../api';
import DataTable, { DataTableState, useDataTableState } from '../../../ui/data-table';
import useRequestParams from '../../../ui/data-table/use-request-params';
import fallbackMiddleware from '../../../util/swr/fallback-middleware';
import useFetcher from '../../../util/swr/use-fetcher';
import { statusDisplay } from '../order-enum-constants';
import { fetchPersonOrders } from '../order-queries';
import usePersonOrderTableColumns from './use-person-order-table-columns';

function usePersonOrderListItems(state: DataTableState, personId: string) {
  const requestParams = useRequestParams(state, [{ property: 'createdAt', direction: 'DESC' }]);
  return useFetcher(
    fetchPersonOrders,
    {
      ...requestParams,
      id: personId,
    },
    { use: [fallbackMiddleware] },
  );
}

export default function PersonOrderDataTable({ personId }: { personId: string }) {
  const [state, setState] = useDataTableState();
  const page = usePersonOrderListItems(state, personId);

  const columns = usePersonOrderTableColumns();

  const rowKey = React.useCallback((order: PersonOrderListItemDto) => {
    invariant(order.id != null, 'Missing order id');

    return order.id;
  }, []);

  return (
    <DataTable
      page={page == null ? { content: [] } : page}
      state={state}
      columns={columns}
      rowKey={rowKey}
      onStateChange={setState}
      isPageable
      rowStyle={(data) => ({
        bg: statusDisplay[data.status]?.bgColor,
      })}
    />
  );
}
