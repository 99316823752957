import { ButtonProps, IconButton, MenuButton } from '@chakra-ui/react';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

export interface BurgerMenuButtonProps extends Omit<ButtonProps, 'icon | variant'> {
  ariaLabel?: string;
}

export function BurgerMenuButton({ ariaLabel, ...props }: BurgerMenuButtonProps) {
  const { t } = useTranslation('common');

  return (
    <MenuButton
      as={IconButton}
      icon={<FontAwesomeIcon icon={faEllipsisV} />}
      variant="ghost"
      aria-label={ariaLabel || t('action.actions')}
      {...props}
    />
  );
}
