/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StaffAccountDto } from './StaffAccountDto';
import {
    StaffAccountDtoFromJSON,
    StaffAccountDtoFromJSONTyped,
    StaffAccountDtoToJSON,
} from './StaffAccountDto';

/**
 * 
 * @export
 * @interface StaffAccountPageDto
 */
export interface StaffAccountPageDto {
    /**
     * The number of the page.
     * @type {number}
     * @memberof StaffAccountPageDto
     */
    page: number;
    /**
     * The size of the page.
     * @type {number}
     * @memberof StaffAccountPageDto
     */
    size: number;
    /**
     * The total number of data returned by the search.
     * @type {number}
     * @memberof StaffAccountPageDto
     */
    totalElements: number;
    /**
     * The total number of pages of current size to fit all data.
     * @type {number}
     * @memberof StaffAccountPageDto
     */
    totalPages: number;
    /**
     * 
     * @type {Array<StaffAccountDto>}
     * @memberof StaffAccountPageDto
     */
    content: Array<StaffAccountDto>;
}

/**
 * Check if a given object implements the StaffAccountPageDto interface.
 */
export function instanceOfStaffAccountPageDto(value: object): boolean {
    if (!('page' in value)) return false;
    if (!('size' in value)) return false;
    if (!('totalElements' in value)) return false;
    if (!('totalPages' in value)) return false;
    if (!('content' in value)) return false;
    return true;
}

export function StaffAccountPageDtoFromJSON(json: any): StaffAccountPageDto {
    return StaffAccountPageDtoFromJSONTyped(json, false);
}

export function StaffAccountPageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffAccountPageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'page': json['page'],
        'size': json['size'],
        'totalElements': json['totalElements'],
        'totalPages': json['totalPages'],
        'content': ((json['content'] as Array<any>).map(StaffAccountDtoFromJSON)),
    };
}

export function StaffAccountPageDtoToJSON(value?: StaffAccountPageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'page': value['page'],
        'size': value['size'],
        'totalElements': value['totalElements'],
        'totalPages': value['totalPages'],
        'content': ((value['content'] as Array<any>).map(StaffAccountDtoToJSON)),
    };
}

