/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  EditionDto,
  EditionImplicationDto,
  EditionPageDto,
  FailureDto,
  HistoryPageDto,
  ImplicationVersionDto,
  PageableDto,
  UpsertEditionDto,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    EditionDtoFromJSON,
    EditionDtoToJSON,
    EditionImplicationDtoFromJSON,
    EditionImplicationDtoToJSON,
    EditionPageDtoFromJSON,
    EditionPageDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    ImplicationVersionDtoFromJSON,
    ImplicationVersionDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    UpsertEditionDtoFromJSON,
    UpsertEditionDtoToJSON,
} from '../models/index';

export interface CheckEditionImplicationsRequest {
    editionDto: EditionDto;
}

export interface CheckEditionImplicationsForDeletionRequest {
    id: string;
}

export interface CreateEditionRequest {
    upsertEditionDto: UpsertEditionDto;
}

export interface DeletableRequest {
    editionId: string;
}

export interface DeleteEditionRequest {
    id: string;
    implicationVersionDto: Array<ImplicationVersionDto>;
}

export interface FetchEditionRequest {
    id: string;
}

export interface FetchEditionHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface SearchEditionsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface TemplateEditionRequest {
    startDate?: Date;
}

export interface UpdateEditionRequest {
    id: string;
    upsertEditionDto: UpsertEditionDto;
}

/**
 * 
 */
export class EditionApi extends runtime.BaseAPI {

    /**
     * Get the active edition.
     */
    async activeEditionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/editions/active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EditionDtoFromJSON(jsonValue));
    }

    /**
     * Get the active edition.
     */
    async activeEdition(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditionDto> {
        const response = await this.activeEditionRaw(initOverrides);
        return await response.value();
    }

    /**
     * Check implications for either a new or an existing edition.
     */
    async checkEditionImplicationsRaw(requestParameters: CheckEditionImplicationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EditionImplicationDto>>> {
        if (requestParameters['editionDto'] == null) {
            throw new runtime.RequiredError(
                'editionDto',
                'Required parameter "editionDto" was null or undefined when calling checkEditionImplications().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/editions/implications`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditionDtoToJSON(requestParameters['editionDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EditionImplicationDtoFromJSON));
    }

    /**
     * Check implications for either a new or an existing edition.
     */
    async checkEditionImplications(requestParameters: CheckEditionImplicationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EditionImplicationDto>> {
        const response = await this.checkEditionImplicationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check implications for deleting an existing edition.
     */
    async checkEditionImplicationsForDeletionRaw(requestParameters: CheckEditionImplicationsForDeletionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EditionImplicationDto>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling checkEditionImplicationsForDeletion().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/editions/delete-implications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EditionImplicationDtoFromJSON));
    }

    /**
     * Check implications for deleting an existing edition.
     */
    async checkEditionImplicationsForDeletion(requestParameters: CheckEditionImplicationsForDeletionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EditionImplicationDto>> {
        const response = await this.checkEditionImplicationsForDeletionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new edition. The new edition will be inactive.
     */
    async createEditionRaw(requestParameters: CreateEditionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditionDto>> {
        if (requestParameters['upsertEditionDto'] == null) {
            throw new runtime.RequiredError(
                'upsertEditionDto',
                'Required parameter "upsertEditionDto" was null or undefined when calling createEdition().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/editions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpsertEditionDtoToJSON(requestParameters['upsertEditionDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EditionDtoFromJSON(jsonValue));
    }

    /**
     * Create a new edition. The new edition will be inactive.
     */
    async createEdition(requestParameters: CreateEditionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditionDto> {
        const response = await this.createEditionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the current deletability of the edition.
     */
    async deletableRaw(requestParameters: DeletableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        if (requestParameters['editionId'] == null) {
            throw new runtime.RequiredError(
                'editionId',
                'Required parameter "editionId" was null or undefined when calling deletable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/editions/{editionId}/deletable`.replace(`{${"editionId"}}`, encodeURIComponent(String(requestParameters['editionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     * Get the current deletability of the edition.
     */
    async deletable(requestParameters: DeletableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.deletableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the edition.
     */
    async deleteEditionRaw(requestParameters: DeleteEditionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteEdition().'
            );
        }

        if (requestParameters['implicationVersionDto'] == null) {
            throw new runtime.RequiredError(
                'implicationVersionDto',
                'Required parameter "implicationVersionDto" was null or undefined when calling deleteEdition().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/editions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['implicationVersionDto']!.map(ImplicationVersionDtoToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the edition.
     */
    async deleteEdition(requestParameters: DeleteEditionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteEditionRaw(requestParameters, initOverrides);
    }

    /**
     * Get the edition business object.
     */
    async fetchEditionRaw(requestParameters: FetchEditionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditionDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchEdition().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/editions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EditionDtoFromJSON(jsonValue));
    }

    /**
     * Get the edition business object.
     */
    async fetchEdition(requestParameters: FetchEditionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditionDto> {
        const response = await this.fetchEditionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the edition.
     */
    async fetchEditionHistoryRaw(requestParameters: FetchEditionHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchEditionHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/editions/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the edition.
     */
    async fetchEditionHistory(requestParameters: FetchEditionHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchEditionHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the next active edition.
     */
    async nextActiveEditionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/editions/next-active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EditionDtoFromJSON(jsonValue));
    }

    /**
     * Get the next active edition.
     */
    async nextActiveEdition(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditionDto> {
        const response = await this.nextActiveEditionRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get the previous active edition.
     */
    async previousActiveEditionIdRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/editions/previous-active-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get the previous active edition.
     */
    async previousActiveEditionId(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.previousActiveEditionIdRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Search for editions.
     */
    async searchEditionsRaw(requestParameters: SearchEditionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditionPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/editions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EditionPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for editions.
     */
    async searchEditions(requestParameters: SearchEditionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditionPageDto> {
        const response = await this.searchEditionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new edition.
     */
    async templateEditionRaw(requestParameters: TemplateEditionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditionDto>> {
        const queryParameters: any = {};

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = (requestParameters['startDate'] as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/editions/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EditionDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new edition.
     */
    async templateEdition(requestParameters: TemplateEditionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditionDto> {
        const response = await this.templateEditionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the existing edition.
     */
    async updateEditionRaw(requestParameters: UpdateEditionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditionDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateEdition().'
            );
        }

        if (requestParameters['upsertEditionDto'] == null) {
            throw new runtime.RequiredError(
                'upsertEditionDto',
                'Required parameter "upsertEditionDto" was null or undefined when calling updateEdition().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/editions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpsertEditionDtoToJSON(requestParameters['upsertEditionDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EditionDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing edition.
     */
    async updateEdition(requestParameters: UpdateEditionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditionDto> {
        const response = await this.updateEditionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
