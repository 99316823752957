import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormatDto, FormatListItemDto } from '../../../api';
import FinalDeleteConfirmDialog, {
  createDefaultDeleteChecklistItem,
} from '../../../ui/dialog/final-delete-confirm-dialog';

export interface FormatDeleteDialogProps {
  format: FormatListItemDto | FormatDto;
  isOpen: boolean;
  onClose(confirm: boolean): void;
}

export default function FormatDeleteDialog({ format, onClose, ...props }: FormatDeleteDialogProps) {
  const { t } = useTranslation('format');

  const confirmationMessageChecklistItems = [
    createDefaultDeleteChecklistItem({
      objectTypeWithArticleGenitive: t('format_with_article_genetive'),
      objectToDelete: format.name,
    }),
  ];

  return (
    <FinalDeleteConfirmDialog
      {...props}
      objectTypeToDelete={t('format')}
      confirmationCheckListItems={confirmationMessageChecklistItems}
      onClose={onClose}
    />
  );
}
