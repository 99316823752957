/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PersonPermissionDto
 */
export interface PersonPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof PersonPermissionDto
     */
    name: PersonPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonPermissionDto
     */
    action: PersonPermissionDtoActionEnum;
}


/**
 * @export
 */
export const PersonPermissionDtoNameEnum = {
    PERSON: 'PERSON'
} as const;
export type PersonPermissionDtoNameEnum = typeof PersonPermissionDtoNameEnum[keyof typeof PersonPermissionDtoNameEnum];

/**
 * @export
 */
export const PersonPermissionDtoActionEnum = {
    ADD_COMMENT: 'CAN_ADD_COMMENT',
    ANONYMISE: 'CAN_ANONYMISE',
    CREATE: 'CAN_CREATE',
    DELETE: 'CAN_DELETE',
    DELETE_ALL_COMMENTS: 'CAN_DELETE_ALL_COMMENTS',
    DELETE_OWN_COMMENTS: 'CAN_DELETE_OWN_COMMENTS',
    EDIT: 'CAN_EDIT',
    EDIT_ALL_COMMENTS: 'CAN_EDIT_ALL_COMMENTS',
    EDIT_OWN_COMMENTS: 'CAN_EDIT_OWN_COMMENTS',
    IMPORT: 'CAN_IMPORT',
    MERGE: 'CAN_MERGE',
    SEE_BADGE: 'CAN_SEE_BADGE',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_PERSON_CARD: 'CAN_SEE_PERSON_CARD',
    SET_DELETE_STATUS: 'CAN_SET_DELETE_STATUS'
} as const;
export type PersonPermissionDtoActionEnum = typeof PersonPermissionDtoActionEnum[keyof typeof PersonPermissionDtoActionEnum];


/**
 * Check if a given object implements the PersonPermissionDto interface.
 */
export function instanceOfPersonPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function PersonPermissionDtoFromJSON(json: any): PersonPermissionDto {
    return PersonPermissionDtoFromJSONTyped(json, false);
}

export function PersonPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function PersonPermissionDtoToJSON(value?: PersonPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

