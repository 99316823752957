import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TeamStaffRelationDto } from '../../../../api';
import HistoryDisplaySettings from '../../../../ui/history/history-display-settings';

export default function useTeamStaffHistorySettings(): HistoryDisplaySettings<TeamStaffRelationDto> {
  const { t } = useTranslation('relations');

  return useMemo<HistoryDisplaySettings<TeamStaffRelationDto>>(() => {
    return {
      attributeLabels: {
        staff: t('history.staff'),
      },
      valueFormatter: {
        staff: (value) => (value == null ? undefined : value.firstName + ' ' + value.surname),
      },
      diffEntireWord: {
        staff: true,
      },
      name: () => t('history.staff'),
    };
  }, [t]);
}
