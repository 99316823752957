/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The title of the person.
 * @export
 */
export const TitleDto = {
    AMBASSADOR: 'AMBASSADOR',
    CHANCELLOR: 'CHANCELLOR',
    FEDERAL_MINISTER: 'FEDERAL_MINISTER',
    FEDERAL_PRESIDENT: 'FEDERAL_PRESIDENT',
    PRESIDENT_OF_THE_BUNDESTAG: 'PRESIDENT_OF_THE_BUNDESTAG',
    MAYOR_MAYORESS: 'MAYOR_MAYORESS',
    ENVOY: 'ENVOY',
    MINISTER: 'MINISTER',
    MINISTERIAL_DIRECTOR: 'MINISTERIAL_DIRECTOR',
    MINISTERIAL_DIRECTOR_RETIRED: 'MINISTERIAL_DIRECTOR_RETIRED',
    SENIOR_MINISTERIAL_COUNSELLOR: 'SENIOR_MINISTERIAL_COUNSELLOR',
    MINISTER_PRESIDENT: 'MINISTER_PRESIDENT',
    GOVERNING_MAYOR: 'GOVERNING_MAYOR',
    GOVERNING_MAYOR_OF_BERLIN: 'GOVERNING_MAYOR_OF_BERLIN',
    SENATOR: 'SENATOR',
    SENATOR_RETIRED: 'SENATOR_RETIRED',
    MINISTER_OF_STATE: 'MINISTER_OF_STATE',
    MINISTER_OF_STATE_RETIRED: 'MINISTER_OF_STATE_RETIRED',
    STATE_SECRETARY: 'STATE_SECRETARY',
    DEPUTY_MINISTER: 'DEPUTY_MINISTER',
    SENATE_DIRECTOR: 'SENATE_DIRECTOR',
    GOVERNING_MAYOR_RETIRED: 'GOVERNING_MAYOR_RETIRED',
    DIPL_ING: 'DIPL_ING',
    DR: 'DR',
    DR_H_C: 'DR_H_C',
    DR_MED: 'DR_MED',
    PROF: 'PROF',
    PROF_DR: 'PROF_DR',
    PROF_DR_DR_H_C_MULT: 'PROF_DR_DR_H_C_MULT',
    PROF_DR_MED: 'PROF_DR_MED',
    A_D_PROF_DR_H_C: 'A_D_PROF_DR_H_C'
} as const;
export type TitleDto = typeof TitleDto[keyof typeof TitleDto];


export function TitleDtoFromJSON(json: any): TitleDto {
    return TitleDtoFromJSONTyped(json, false);
}

export function TitleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TitleDto {
    return json as TitleDto;
}

export function TitleDtoToJSON(value?: TitleDto | null): any {
    return value as any;
}

