/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormatCompanyPermissionDto
 */
export interface FormatCompanyPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof FormatCompanyPermissionDto
     */
    name: FormatCompanyPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof FormatCompanyPermissionDto
     */
    action: FormatCompanyPermissionDtoActionEnum;
}


/**
 * @export
 */
export const FormatCompanyPermissionDtoNameEnum = {
    COMPANY_FORMAT: 'COMPANY-FORMAT'
} as const;
export type FormatCompanyPermissionDtoNameEnum = typeof FormatCompanyPermissionDtoNameEnum[keyof typeof FormatCompanyPermissionDtoNameEnum];

/**
 * @export
 */
export const FormatCompanyPermissionDtoActionEnum = {
    CAN_LINK: 'CAN_LINK'
} as const;
export type FormatCompanyPermissionDtoActionEnum = typeof FormatCompanyPermissionDtoActionEnum[keyof typeof FormatCompanyPermissionDtoActionEnum];


/**
 * Check if a given object implements the FormatCompanyPermissionDto interface.
 */
export function instanceOfFormatCompanyPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function FormatCompanyPermissionDtoFromJSON(json: any): FormatCompanyPermissionDto {
    return FormatCompanyPermissionDtoFromJSONTyped(json, false);
}

export function FormatCompanyPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormatCompanyPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function FormatCompanyPermissionDtoToJSON(value?: FormatCompanyPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

