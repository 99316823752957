/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AllocationDto } from './AllocationDto';
import {
    AllocationDtoFromJSON,
    AllocationDtoFromJSONTyped,
    AllocationDtoToJSON,
} from './AllocationDto';
import type { CategoryOfParticipationDto } from './CategoryOfParticipationDto';
import {
    CategoryOfParticipationDtoFromJSON,
    CategoryOfParticipationDtoFromJSONTyped,
    CategoryOfParticipationDtoToJSON,
} from './CategoryOfParticipationDto';
import type { StatusForParticipationDto } from './StatusForParticipationDto';
import {
    StatusForParticipationDtoFromJSON,
    StatusForParticipationDtoFromJSONTyped,
    StatusForParticipationDtoToJSON,
} from './StatusForParticipationDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * A guest list pertaining to an event
 * @export
 * @interface GuestListSettingsDto
 */
export interface GuestListSettingsDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof GuestListSettingsDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof GuestListSettingsDto
     */
    version?: VersionDto;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof GuestListSettingsDto
     */
    eventId: string;
    /**
     * The type of the guest list, either invitation or registration
     * @type {string}
     * @memberof GuestListSettingsDto
     */
    guestListType: GuestListSettingsDtoGuestListTypeEnum;
    /**
     * Whether the guest list allows placement of dummies
     * @type {boolean}
     * @memberof GuestListSettingsDto
     */
    allowDummies: boolean;
    /**
     * 
     * @type {Array<CategoryOfParticipationDto>}
     * @memberof GuestListSettingsDto
     */
    categoryOfParticipation: Array<CategoryOfParticipationDto>;
    /**
     * 
     * @type {Array<StatusForParticipationDto>}
     * @memberof GuestListSettingsDto
     */
    statusForParticipation: Array<StatusForParticipationDto>;
    /**
     * Whether the guest list allows for an RSVP of guests
     * @type {boolean}
     * @memberof GuestListSettingsDto
     */
    rsvpFunction: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GuestListSettingsDto
     */
    defaultRsvpDeadline?: Date;
    /**
     * Labels for the guest list
     * @type {Array<string>}
     * @memberof GuestListSettingsDto
     */
    labels: Array<string>;
    /**
     * The allocation of persons per section
     * @type {Array<AllocationDto>}
     * @memberof GuestListSettingsDto
     */
    allocations: Array<AllocationDto>;
    /**
     * The status of the guest list, whether it is active or inactive
     * @type {boolean}
     * @memberof GuestListSettingsDto
     */
    active: boolean;
    /**
     * If the admission is active for this guest list
     * @type {boolean}
     * @memberof GuestListSettingsDto
     */
    admissionActive?: boolean;
}


/**
 * @export
 */
export const GuestListSettingsDtoGuestListTypeEnum = {
    INVITATION: 'INVITATION',
    REGISTRATION: 'REGISTRATION'
} as const;
export type GuestListSettingsDtoGuestListTypeEnum = typeof GuestListSettingsDtoGuestListTypeEnum[keyof typeof GuestListSettingsDtoGuestListTypeEnum];


/**
 * Check if a given object implements the GuestListSettingsDto interface.
 */
export function instanceOfGuestListSettingsDto(value: object): boolean {
    if (!('eventId' in value)) return false;
    if (!('guestListType' in value)) return false;
    if (!('allowDummies' in value)) return false;
    if (!('categoryOfParticipation' in value)) return false;
    if (!('statusForParticipation' in value)) return false;
    if (!('rsvpFunction' in value)) return false;
    if (!('labels' in value)) return false;
    if (!('allocations' in value)) return false;
    if (!('active' in value)) return false;
    return true;
}

export function GuestListSettingsDtoFromJSON(json: any): GuestListSettingsDto {
    return GuestListSettingsDtoFromJSONTyped(json, false);
}

export function GuestListSettingsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuestListSettingsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'eventId': json['eventId'],
        'guestListType': json['guestListType'],
        'allowDummies': json['allowDummies'],
        'categoryOfParticipation': ((json['categoryOfParticipation'] as Array<any>).map(CategoryOfParticipationDtoFromJSON)),
        'statusForParticipation': ((json['statusForParticipation'] as Array<any>).map(StatusForParticipationDtoFromJSON)),
        'rsvpFunction': json['rsvpFunction'],
        'defaultRsvpDeadline': json['defaultRsvpDeadline'] == null ? undefined : (new Date(json['defaultRsvpDeadline'])),
        'labels': json['labels'],
        'allocations': ((json['allocations'] as Array<any>).map(AllocationDtoFromJSON)),
        'active': json['active'],
        'admissionActive': json['admissionActive'] == null ? undefined : json['admissionActive'],
    };
}

export function GuestListSettingsDtoToJSON(value?: GuestListSettingsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'eventId': value['eventId'],
        'guestListType': value['guestListType'],
        'allowDummies': value['allowDummies'],
        'categoryOfParticipation': ((value['categoryOfParticipation'] as Array<any>).map(CategoryOfParticipationDtoToJSON)),
        'statusForParticipation': ((value['statusForParticipation'] as Array<any>).map(StatusForParticipationDtoToJSON)),
        'rsvpFunction': value['rsvpFunction'],
        'defaultRsvpDeadline': value['defaultRsvpDeadline'] == null ? undefined : ((value['defaultRsvpDeadline']).toISOString()),
        'labels': value['labels'],
        'allocations': ((value['allocations'] as Array<any>).map(AllocationDtoToJSON)),
        'active': value['active'],
        'admissionActive': value['admissionActive'],
    };
}

