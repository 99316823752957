import { ReactNode } from 'react';
import { BreadcrumbRouteObject } from '../../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../../ui/helmet/helmet-outlet';
import { PluginToken } from '../../../util/plugin/use-plugins';

interface FilmViewerTab {
  route: BreadcrumbRouteObject & HelmetRouteObject;
  tab: ReactNode;
}

export const FILM_VIEWER_TAB = new PluginToken<FilmViewerTab>('FilmViewerTab');
