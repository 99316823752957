import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import ExportLister from './export-lister/export-lister';

const exportRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'exports',
    handle: {
      breadcrumb: <Translate ns="export" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        element: <ExportLister />,
        handle: {
          helmet: <Translate ns="export">{(t) => <Helmet title={t('lister.title')} />}</Translate>,
        },
      },
    ],
  },
];

export default exportRoutes;
