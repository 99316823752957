/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The type of the lane
 * @export
 */
export const LaneTypeDto = {
    OFFICIAL: 'OFFICIAL',
    GUEST_SERVICES: 'GUEST_SERVICES',
    PROTOCOL: 'PROTOCOL',
    RED_CARPET: 'RED_CARPET',
    SECURITY: 'SECURITY',
    TECHNICIANS: 'TECHNICIANS',
    CUSTOM: 'CUSTOM'
} as const;
export type LaneTypeDto = typeof LaneTypeDto[keyof typeof LaneTypeDto];


export function LaneTypeDtoFromJSON(json: any): LaneTypeDto {
    return LaneTypeDtoFromJSONTyped(json, false);
}

export function LaneTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaneTypeDto {
    return json as LaneTypeDto;
}

export function LaneTypeDtoToJSON(value?: LaneTypeDto | null): any {
    return value as any;
}

