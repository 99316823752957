/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CompanyMarkerDto = {
    BUYER: 'BUYER',
    EXHIBITOR: 'EXHIBITOR',
    CO_PRO: 'CO_PRO',
    BERLINALE_SERIES_MARKET: 'BERLINALE_SERIES_MARKET'
} as const;
export type CompanyMarkerDto = typeof CompanyMarkerDto[keyof typeof CompanyMarkerDto];


export function CompanyMarkerDtoFromJSON(json: any): CompanyMarkerDto {
    return CompanyMarkerDtoFromJSONTyped(json, false);
}

export function CompanyMarkerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyMarkerDto {
    return json as CompanyMarkerDto;
}

export function CompanyMarkerDtoToJSON(value?: CompanyMarkerDto | null): any {
    return value as any;
}

