import { ParseKeys } from 'i18next';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import useFetcher from '../../util/swr/use-fetcher';
import useParam from '../../util/use-params/use-params';
import { fetchFilm } from './film-queries';
import { mostRelevantTitle } from './film-utils';

interface FilmHelmetProps {
  i18nKey: ParseKeys<'film'>;
  prefix?: string;
}

export function FilmHelmet({ i18nKey, prefix }: FilmHelmetProps) {
  const { t } = useTranslation('film');
  const film = useFetcher(fetchFilm, { id: useParam('filmId') }, { suspense: true });

  let helmet = t(i18nKey, { film: mostRelevantTitle(film) });
  if (prefix) {
    helmet = `${prefix} / ${helmet}`;
  }
  return <Helmet title={helmet} />;
}
