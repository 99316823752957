import { chakra, Grid, GridItem, HTMLChakraProps, useToken } from '@chakra-ui/react';
import { Fragment, ReactNode } from 'react';

interface ConnectionProps extends HTMLChakraProps<'div'> {
  size?: 'md' | 'sm';
  start?: { icon: ReactNode; element: ReactNode };
  connection?: { icon?: ReactNode; element: ReactNode };
  end: { icon: ReactNode; element: ReactNode };
}

export default function Connection({ size = 'md', start, connection, end, sx, ...props }: ConnectionProps) {
  const extraSpacingBetweenColumns = useToken('space', size === 'md' ? 3 : 2);
  const connectionIconSpacing = useToken('space', 0.5);
  const startEndIconSpacing = useToken('space', 1);
  const sections = Object.entries({ start, connection, end }).filter(
    (entry): entry is [string, { icon?: ReactNode; element: ReactNode }] => entry[1] != null,
  );
  const areas = sections.map(([key]) => `"${key}-icon . ${key}" `.repeat(2)).join(' ". . ." ');

  return (
    <Grid
      sx={{
        gridTemplateColumns: `auto ${extraSpacingBetweenColumns} 1fr`,
        gridTemplateAreas: areas,
        fontSize: size,
        ...sx,
      }}
      {...props}
    >
      {sections.map(([key, section], index) => (
        <Fragment key={index}>
          {index > 0 && (
            <GridItem
              className="connection-row-spacer"
              gridRow={index * 3}
              gridColumnStart={1}
              gridColumnEnd={4}
              h={2}
            />
          )}
          <GridItem gridArea={`${key}-icon`} className="connection-row-icon" alignSelf="center">
            {section.icon}
          </GridItem>
          <GridItem gridArea={key} className="connection-row-element">
            {section.element}
          </GridItem>
        </Fragment>
      ))}
      {start != null && connection != null && (
        <>
          <GridItem gridRowStart={2} gridRowEnd={5} gridColumn={1} justifySelf="center" w={3} position="relative">
            <ConnectionLine
              size={size}
              position="absolute"
              top={`calc(1em / 2 + ${startEndIconSpacing})`}
              bottom={`calc(1em / 2 + ${connectionIconSpacing})`}
            />
          </GridItem>
          <GridItem gridRowStart={5} gridRowEnd={8} gridColumn={1} justifySelf="center" w={3} position="relative">
            <ConnectionLine
              size={size}
              position="absolute"
              top={`calc(1em / 2 + ${connectionIconSpacing})`}
              bottom={`calc(1em / 2 + ${startEndIconSpacing})`}
              arrow
            />
          </GridItem>
        </>
      )}
      {start == null && connection != null && (
        <>
          <GridItem gridArea="connection-icon" justifySelf="center" w={3} position="relative">
            <ConnectionLine
              size={size}
              position="absolute"
              top={connectionIconSpacing}
              bottom={`calc(50% + 1em / 2 + ${connectionIconSpacing})`}
            />
          </GridItem>
          <GridItem gridRowStart={2} gridRowEnd={5} gridColumn={1} justifySelf="center" w={3} position="relative">
            <ConnectionLine
              size={size}
              position="absolute"
              top={`calc(1em / 2 + ${connectionIconSpacing})`}
              bottom={`calc(1em / 2 + ${startEndIconSpacing})`}
              arrow
            />
          </GridItem>
        </>
      )}
      {start != null && connection == null && (
        <GridItem gridRowStart={2} gridRowEnd={5} gridColumn={1} justifySelf="center" w={3} position="relative">
          <ConnectionLine
            position="absolute"
            top={`calc(1em / 2 + ${startEndIconSpacing})`}
            bottom={`calc(1em / 2 + ${startEndIconSpacing})`}
            arrow
          />
        </GridItem>
      )}
    </Grid>
  );
}

interface ConnectionLineProps extends HTMLChakraProps<'div'> {
  arrow?: boolean;
  size?: 'md' | 'sm';
}

function ConnectionLine({ arrow = false, size = 'md', ...props }: ConnectionLineProps) {
  return (
    <chakra.div __css={{ overflow: 'hidden', w: 3, position: 'relative' }} {...props}>
      <chakra.svg
        width={3}
        height="400px"
        position="absolute"
        bottom={arrow ? 0 : undefined}
        top={arrow ? undefined : 0}
        opacity={0.5}
        transform={size === 'sm' ? 'scale(calc(2 / 3))' : undefined}
        transformOrigin={arrow ? 'bottom' : 'top'}
      >
        <chakra.path
          fill="currentColor"
          d="M5.47 399.53c.3.3.77.3 1.06 0l4.77-4.77a.75.75 0 1 0-1.06-1.06L6 397.94l-4.24-4.24a.75.75 0 0 0-1.06 1.06l4.77 4.77ZM5.25 0v399h1.5V0h-1.5Z"
        />
      </chakra.svg>
    </chakra.div>
  );
}
