import { chakra, forwardRef, HTMLChakraProps, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { AccessGroupDto, AccessGroupStatusDto } from '../../../api';
import accessGroupApi from '../../../data-access/access-group-api';
import useToast from '../../../ui/use-toast/use-toast';
import { accessGroupFetcher } from '../access-group-queries';
import AccessGroupStatusToggleDialog from './access-group-status-toggle-dialog';

interface AccessGroupStatusToggleButtonProps extends HTMLChakraProps<'button'> {
  accessGroup: AccessGroupDto;
}

function AccessGroupStatusToggleButton(
  { children, accessGroup, ...props }: AccessGroupStatusToggleButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation('access_group');

  const shouldActivate = accessGroup.status === AccessGroupStatusDto.INACTIVE;

  const showSuccessToast = useToast({
    id: 'access-group-status-toggle-success-toast',
    status: 'success',
  });

  const handleToggle = async () => {
    invariant(accessGroup.id != null, 'Missing access group id');

    if (shouldActivate) {
      await accessGroupApi.activateAccessGroup({ id: accessGroup.id });
    } else {
      await accessGroupApi.deactivateAccessGroup({ id: accessGroup.id });
    }

    showSuccessToast({
      title: shouldActivate ? t('activation.toast.activate') : t('activation.toast.deactivate'),
      description: shouldActivate
        ? t('activation.toast.activate_message', { name: accessGroup.title })
        : t('activation.toast.deactivate_message', { name: accessGroup.title }),
    });

    accessGroupFetcher.mutate();

    onClose();
  };

  return (
    <>
      <chakra.button
        ref={ref}
        {...props}
        aria-label={shouldActivate ? t('action.activate') : t('action.deactivate')}
        onClick={onOpen}
      >
        {children}
      </chakra.button>
      <AccessGroupStatusToggleDialog
        accessGroup={accessGroup}
        shouldActivate={shouldActivate}
        isOpen={isOpen}
        onClose={onClose}
        onToggle={handleToggle}
      />
    </>
  );
}

export default forwardRef<AccessGroupStatusToggleButtonProps, 'button'>(AccessGroupStatusToggleButton);
