import { isObject } from 'lodash-es';
import React from 'react';
import { FailureDto, Middleware, RequestContext, ResponseContext } from '../api';
import { toast } from '../util/standalone-toast';
import Translate from '../util/translate/translate';

const version = removeSnapshotVersion(import.meta.env.VITE_APP_VERSION) ?? '0.0.0';

const VERSION_MISMATCH_TOAST_ID = 'version-mismatch-toast';

const VersionMismatchMiddleware: Middleware = {
  pre({ url, init }: RequestContext) {
    const headers = new Headers(init.headers);
    headers.set('X-VERSION', version);

    init = { ...init, headers };

    return Promise.resolve({ url, init });
  },

  async post({ response }: ResponseContext): Promise<Response | void> {
    if (response.status === 412) {
      const error = await asFailureDto(response);
      if (error != null && error.code === 'VersionMismatch') {
        if (!toast.isActive(VERSION_MISMATCH_TOAST_ID)) {
          toast({
            id: VERSION_MISMATCH_TOAST_ID,
            status: 'info',
            title: <Translate ns="common" i18nKey="toast.versionMismatch.title" suspense={false} />,
            description: <Translate ns="common" i18nKey="toast.versionMismatch.description" suspense={false} />,
          });
        }
        throw new Error('version mismatch, no other middleware should be run');
      }
    }

    return response;
  },
};

async function asFailureDto(response: Response) {
  const contentType = response.headers.get('content-type');
  if (response.body == null || !(contentType && contentType.includes('application/json'))) {
    return undefined;
  }
  const responseJson = await response.clone().json();
  if (isObject(responseJson)) {
    if ('code' in responseJson && 'message' in responseJson) {
      return responseJson as FailureDto;
    }
    return undefined;
  }
}

function removeSnapshotVersion(version: string) {
  if (version.includes('SNAPSHOT')) {
    // 1.2.3-SNAPSHOT.0 --> 1.2.3-SNAPSHOT
    return version.substring(0, version.indexOf('SNAPSHOT') + 8);
  }
  return version;
}

export default VersionMismatchMiddleware;
