import React from 'react';
import { SectionDto } from '../../../api';
import { PageContent } from '../../../ui/page';
import { SectionRelationExtension } from '../../section/section-relation/section-relation-extension';
import SectionStaffRelationDataTable from './section-staff-lister/section-staff-relation-data-table';

export const sectionStaffPageContentExtension: SectionRelationExtension = {
  pageContent: (section) => <SectionStaffPageContent section={section} />,
};

interface SectionStaffPageContentProps {
  section: SectionDto;
}

function SectionStaffPageContent({ section }: SectionStaffPageContentProps) {
  return (
    <PageContent useFullWidth fixedHeight>
      <SectionStaffRelationDataTable section={section} />
    </PageContent>
  );
}
