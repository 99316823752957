import React from 'react';
import { PersonReferenceDto } from '../../../api';
import personApi from '../../../data-access/person-api';
import { PendingProvider } from '../../../util/use-pending/use-pending';
import usePromise from '../../../util/use-promise/use-promise';
import PersonDrawerForm from './person-drawer-form';

interface PersonDrawerProps {
  isOpen: boolean;
  onClose(): void;
  onSave?(person: PersonReferenceDto): void;
}

export default function PersonDrawer({ isOpen, onClose, onSave }: PersonDrawerProps) {
  const templatePerson = useTemplatePerson();

  return (
    <>
      {templatePerson != null && (
        <PendingProvider>
          <PersonDrawerForm templatePerson={templatePerson} isOpen={isOpen} onClose={onClose} onSave={onSave} />
        </PendingProvider>
      )}
    </>
  );
}

function useTemplatePerson() {
  return usePromise((signal) => personApi.templatePerson({ signal }), []);
}
