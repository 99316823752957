import { Stack } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { FormProvider } from 'react-hook-form';
import PageForm from '../../../../ui/page/page-form';
import useMailingRecipientEditor from '../../../mailing/mailing-recipient-editor/use-mailing-recipient-editor';
import PersonOnGuestListMailingRecipientControl, {
  AddPersonOnGuestListRecipient,
} from '../person-on-guest-list-recipient-control';

interface TicketGuestListMailingRecipientEditorProps {
  eventId: string;
  mailingId: string;
  helperText: ReactNode;
}

export default function TicketGuestListMailingRecipientEditor({
  eventId,
  mailingId,
  helperText,
}: TicketGuestListMailingRecipientEditorProps) {
  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const { form, onValid } = useMailingRecipientEditor<AddPersonOnGuestListRecipient>(mailingId, {
    recipients: [],
  });

  return (
    <FormProvider {...form}>
      <PageForm onValid={onValid} height="full" initialFocusRef={initialFocusRef}>
        <Stack spacing={4}>
          <PersonOnGuestListMailingRecipientControl eventId={eventId} mailingId={mailingId} helperText={helperText} />
        </Stack>
      </PageForm>
    </FormProvider>
  );
}
