/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ForRefOperationAllOfEdgeModelDto
 */
export interface ForRefOperationAllOfEdgeModelDto {
    /**
     * 
     * @type {string}
     * @memberof ForRefOperationAllOfEdgeModelDto
     */
    fromCollection?: string;
    /**
     * 
     * @type {string}
     * @memberof ForRefOperationAllOfEdgeModelDto
     */
    toCollection?: string;
    /**
     * 
     * @type {string}
     * @memberof ForRefOperationAllOfEdgeModelDto
     */
    joinField?: string;
}

/**
 * Check if a given object implements the ForRefOperationAllOfEdgeModelDto interface.
 */
export function instanceOfForRefOperationAllOfEdgeModelDto(value: object): boolean {
    return true;
}

export function ForRefOperationAllOfEdgeModelDtoFromJSON(json: any): ForRefOperationAllOfEdgeModelDto {
    return ForRefOperationAllOfEdgeModelDtoFromJSONTyped(json, false);
}

export function ForRefOperationAllOfEdgeModelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForRefOperationAllOfEdgeModelDto {
    if (json == null) {
        return json;
    }
    return {
        
        'fromCollection': json['fromCollection'] == null ? undefined : json['fromCollection'],
        'toCollection': json['toCollection'] == null ? undefined : json['toCollection'],
        'joinField': json['joinField'] == null ? undefined : json['joinField'],
    };
}

export function ForRefOperationAllOfEdgeModelDtoToJSON(value?: ForRefOperationAllOfEdgeModelDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'fromCollection': value['fromCollection'],
        'toCollection': value['toCollection'],
        'joinField': value['joinField'],
    };
}

