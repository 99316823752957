/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EventReferenceDto } from './EventReferenceDto';
import {
    EventReferenceDtoFromJSON,
    EventReferenceDtoFromJSONTyped,
    EventReferenceDtoToJSON,
} from './EventReferenceDto';
import type { TagReferenceDto } from './TagReferenceDto';
import {
    TagReferenceDtoFromJSON,
    TagReferenceDtoFromJSONTyped,
    TagReferenceDtoToJSON,
} from './TagReferenceDto';
import type { TagRelationTypeDto } from './TagRelationTypeDto';
import {
    TagRelationTypeDtoFromJSON,
    TagRelationTypeDtoFromJSONTyped,
    TagRelationTypeDtoToJSON,
} from './TagRelationTypeDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * A relation between a tag and a event
 * @export
 * @interface TagEventRelationDto
 */
export interface TagEventRelationDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof TagEventRelationDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof TagEventRelationDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {TagReferenceDto}
     * @memberof TagEventRelationDto
     */
    tag: TagReferenceDto;
    /**
     * 
     * @type {TagRelationTypeDto}
     * @memberof TagEventRelationDto
     */
    relationType: TagRelationTypeDto;
    /**
     * 
     * @type {EventReferenceDto}
     * @memberof TagEventRelationDto
     */
    relation: EventReferenceDto;
}

/**
 * Check if a given object implements the TagEventRelationDto interface.
 */
export function instanceOfTagEventRelationDto(value: object): boolean {
    if (!('tag' in value)) return false;
    if (!('relationType' in value)) return false;
    if (!('relation' in value)) return false;
    return true;
}

export function TagEventRelationDtoFromJSON(json: any): TagEventRelationDto {
    return TagEventRelationDtoFromJSONTyped(json, false);
}

export function TagEventRelationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagEventRelationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'tag': TagReferenceDtoFromJSON(json['tag']),
        'relationType': TagRelationTypeDtoFromJSON(json['relationType']),
        'relation': EventReferenceDtoFromJSON(json['relation']),
    };
}

export function TagEventRelationDtoToJSON(value?: TagEventRelationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'tag': TagReferenceDtoToJSON(value['tag']),
        'relationType': TagRelationTypeDtoToJSON(value['relationType']),
        'relation': EventReferenceDtoToJSON(value['relation']),
    };
}

