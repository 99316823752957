export default function dateMax(date: Date, ...moreDates: (Date | undefined)[]): Date;
export default function dateMax(...dates: (Date | undefined)[]): Date | undefined;
export default function dateMax(...dates: (Date | undefined)[]): Date | undefined {
  let maxDate: Date | undefined;

  dates.forEach((date) => {
    maxDate = maxDate == null || (date != null && maxDate < date) ? date : maxDate;
  });

  return maxDate;
}
