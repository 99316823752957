import { Stack } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LandingPageDto } from '../../../api';
import CheckboxControl from '../../../ui/form/checkbox-control';
import InputFormControl from '../../../ui/form/input-form-control';

interface RsvpModuleSettingsControlProps {
  name: `modules.${number}`;
}

export default function RsvpModuleSettingsControl({ name }: RsvpModuleSettingsControlProps) {
  const { setValue, control } = useFormContext<LandingPageDto>();
  const commentsAllowed = useWatch({
    name: `${name}.commentsAllowed`,
    control,
  });
  const { t } = useTranslation('guest_list');

  return (
    <Stack spacing={4}>
      <CheckboxControl<LandingPageDto>
        name={`${name}.commentsAllowed`}
        label={t('landingPage.rsvpModule.commentsAllowed')}
        onChange={() => setValue(`${name}.helperText`, '')}
      />
      {commentsAllowed && (
        <InputFormControl<LandingPageDto>
          name={`${name}.helperText`}
          label={t('landingPage.rsvpModule.helperTextLabel')}
          maxLength={150}
        />
      )}
    </Stack>
  );
}
