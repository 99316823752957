/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CapacityDto
 */
export interface CapacityDto {
    /**
     * 
     * @type {number}
     * @memberof CapacityDto
     */
    maximalOccupancy?: number;
    /**
     * 
     * @type {number}
     * @memberof CapacityDto
     */
    seats?: number;
    /**
     * 
     * @type {number}
     * @memberof CapacityDto
     */
    standingRoom?: number;
    /**
     * 
     * @type {number}
     * @memberof CapacityDto
     */
    workStations?: number;
}

/**
 * Check if a given object implements the CapacityDto interface.
 */
export function instanceOfCapacityDto(value: object): boolean {
    return true;
}

export function CapacityDtoFromJSON(json: any): CapacityDto {
    return CapacityDtoFromJSONTyped(json, false);
}

export function CapacityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CapacityDto {
    if (json == null) {
        return json;
    }
    return {
        
        'maximalOccupancy': json['maximalOccupancy'] == null ? undefined : json['maximalOccupancy'],
        'seats': json['seats'] == null ? undefined : json['seats'],
        'standingRoom': json['standingRoom'] == null ? undefined : json['standingRoom'],
        'workStations': json['workStations'] == null ? undefined : json['workStations'],
    };
}

export function CapacityDtoToJSON(value?: CapacityDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'maximalOccupancy': value['maximalOccupancy'],
        'seats': value['seats'],
        'standingRoom': value['standingRoom'],
        'workStations': value['workStations'],
    };
}

