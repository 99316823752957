import formatOccupationApi from '../../../data-access/format-occupation-api';
import { createFetcherScope } from '../../../util/swr/fetcher-scope';

export const formatOccupationFetcher = createFetcherScope(formatOccupationApi);

export const fetchOccupationsByFormat = formatOccupationFetcher.createFetcher(formatOccupationApi.searchByFormat);

export const fetchFormatOccupationHistory = formatOccupationFetcher.createFetcher(
  formatOccupationApi.fetchHistoryByFormat,
);
