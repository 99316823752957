import React from 'react';
import { Outlet } from 'react-router-dom';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import PersonPage from '../person/person-page/person-page';
import ParticipationHelmet from './common/participation-helmet';

const personParticipationRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: '',
    element: <PersonPage />,
    children: [
      {
        path: 'participations',
        handle: {
          helmet: <ParticipationHelmet i18nKey="person.helmet" />,
        },
        element: <Outlet />,
        children: [
          {
            path: '',
            lazy: () => import('./participation-lister/person-participation-lister-route'),
          },
        ],
      },
    ],
  },
  {
    path: 'participations/:participationId',
    lazy: () => import('./participation-page/participation-page-route'),
    children: [
      {
        path: '',
        lazy: () => import('./participation-viewer/participation-viewer-route'),
      },
      {
        path: 'history',
        lazy: () => import('./participation-history/participation-history-route'),
      },
    ],
  },
  {
    path: 'participations/:participationId/edit',
    lazy: () => import('./participation-editor/edit-participation-route'),
  },
  {
    path: 'participations/new',
    lazy: () => import('./participation-editor/new-participation-route'),
  },
];

export default personParticipationRoutes;
