import React from 'react';
import { useParams } from 'react-router-dom';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchMailingTemplate } from '../mailing-template-queries';
import { getMailingTemplateId } from './get-mailing-template-id';

export default function MailingTemplateBreadcrumb() {
  const params = useParams<{ mailingTemplateId: string }>();
  const mailingTemplate = useFetcher(fetchMailingTemplate, { id: getMailingTemplateId(params) });

  return <>{mailingTemplate.name}</>;
}
