import { Td } from '@chakra-ui/react';
import React from 'react';
import useDefinedContext from '../../util/context/use-defined-context/use-defined-context';
import DataTableColumn from './data-table-column';
import { DataTableStylesContext } from './data-table-context';

interface DataTableDataCellProps<TData, TChildData = void> {
  column: DataTableColumn<TData, TChildData>;
  firstColumn: boolean;
  lastRow: boolean;
  children: React.ReactNode;
  rowSpan?: number;
}

export function DataTableDataCell<TData, TChildData = void>({
  column,
  firstColumn,
  lastRow,
  children,
  rowSpan,
}: DataTableDataCellProps<TData, TChildData>) {
  const styles = useDefinedContext(DataTableStylesContext);

  if (!column) {
    return null;
  }

  return (
    <Td
      {...{ isNumeric: column.isNumeric, ...column.cellProps, ...column.bodyCellProps }}
      data-first-column={firstColumn}
      data-sticky={column.sticky}
      key={column.key}
      sx={{ ...styles.td, ...column.style }}
      data-last-row={lastRow}
      rowSpan={rowSpan}
    >
      {children}
    </Td>
  );
}
