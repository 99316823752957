/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AutomaticConnectionsDto } from './AutomaticConnectionsDto';
import {
    AutomaticConnectionsDtoFromJSON,
    AutomaticConnectionsDtoFromJSONTyped,
    AutomaticConnectionsDtoToJSON,
} from './AutomaticConnectionsDto';
import type { ClosedDateTimeRangeDto } from './ClosedDateTimeRangeDto';
import {
    ClosedDateTimeRangeDtoFromJSON,
    ClosedDateTimeRangeDtoFromJSONTyped,
    ClosedDateTimeRangeDtoToJSON,
} from './ClosedDateTimeRangeDto';
import type { DiscountDto } from './DiscountDto';
import {
    DiscountDtoFromJSON,
    DiscountDtoFromJSONTyped,
    DiscountDtoToJSON,
} from './DiscountDto';
import type { ProductReferenceDto } from './ProductReferenceDto';
import {
    ProductReferenceDtoFromJSON,
    ProductReferenceDtoFromJSONTyped,
    ProductReferenceDtoToJSON,
} from './ProductReferenceDto';

/**
 * 
 * @export
 * @interface CodeEditorContentDto
 */
export interface CodeEditorContentDto {
    /**
     * 
     * @type {ClosedDateTimeRangeDto}
     * @memberof CodeEditorContentDto
     */
    validityPeriod?: ClosedDateTimeRangeDto;
    /**
     * 
     * @type {boolean}
     * @memberof CodeEditorContentDto
     */
    discountForWholeOrder?: boolean;
    /**
     * 
     * @type {Array<ProductReferenceDto>}
     * @memberof CodeEditorContentDto
     */
    products?: Array<ProductReferenceDto>;
    /**
     * 
     * @type {string}
     * @memberof CodeEditorContentDto
     */
    paymentModifier?: CodeEditorContentDtoPaymentModifierEnum;
    /**
     * 
     * @type {DiscountDto}
     * @memberof CodeEditorContentDto
     */
    discount?: DiscountDto;
    /**
     * 
     * @type {AutomaticConnectionsDto}
     * @memberof CodeEditorContentDto
     */
    automaticConnections?: AutomaticConnectionsDto;
}


/**
 * @export
 */
export const CodeEditorContentDtoPaymentModifierEnum = {
    NONE: 'NONE',
    DISCOUNT: 'DISCOUNT',
    PAYMENT_SUBSTITUTE: 'PAYMENT_SUBSTITUTE'
} as const;
export type CodeEditorContentDtoPaymentModifierEnum = typeof CodeEditorContentDtoPaymentModifierEnum[keyof typeof CodeEditorContentDtoPaymentModifierEnum];


/**
 * Check if a given object implements the CodeEditorContentDto interface.
 */
export function instanceOfCodeEditorContentDto(value: object): boolean {
    return true;
}

export function CodeEditorContentDtoFromJSON(json: any): CodeEditorContentDto {
    return CodeEditorContentDtoFromJSONTyped(json, false);
}

export function CodeEditorContentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodeEditorContentDto {
    if (json == null) {
        return json;
    }
    return {
        
        'validityPeriod': json['validityPeriod'] == null ? undefined : ClosedDateTimeRangeDtoFromJSON(json['validityPeriod']),
        'discountForWholeOrder': json['discountForWholeOrder'] == null ? undefined : json['discountForWholeOrder'],
        'products': json['products'] == null ? undefined : ((json['products'] as Array<any>).map(ProductReferenceDtoFromJSON)),
        'paymentModifier': json['paymentModifier'] == null ? undefined : json['paymentModifier'],
        'discount': json['discount'] == null ? undefined : DiscountDtoFromJSON(json['discount']),
        'automaticConnections': json['automaticConnections'] == null ? undefined : AutomaticConnectionsDtoFromJSON(json['automaticConnections']),
    };
}

export function CodeEditorContentDtoToJSON(value?: CodeEditorContentDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'validityPeriod': ClosedDateTimeRangeDtoToJSON(value['validityPeriod']),
        'discountForWholeOrder': value['discountForWholeOrder'],
        'products': value['products'] == null ? undefined : ((value['products'] as Array<any>).map(ProductReferenceDtoToJSON)),
        'paymentModifier': value['paymentModifier'],
        'discount': DiscountDtoToJSON(value['discount']),
        'automaticConnections': AutomaticConnectionsDtoToJSON(value['automaticConnections']),
    };
}

