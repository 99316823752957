import React from 'react';
import { useTranslation } from 'react-i18next';
import FinalDeleteConfirmDialog, {
  createDefaultDeleteChecklistItem,
} from '../../../ui/dialog/final-delete-confirm-dialog';

export interface CompanyAdminDeleteDialogProps {
  name: string;
  isLastAdmin: boolean;
  isOpen: boolean;

  onClose(confirm: boolean): void;
}

export default function CompanyAdminDeleteDialog({
  onClose,
  name,
  isLastAdmin,
  ...props
}: CompanyAdminDeleteDialogProps) {
  const { t } = useTranslation('company');

  const confirmationMessageChecklistItems = [
    createDefaultDeleteChecklistItem({
      objectTypeWithArticleGenitive: t('externalAdmin.companyAdminWithArticleGenetive', {
        name: name,
      }),
    }),
  ];

  const infoText = isLastAdmin ? t('externalAdmin.deleteLastAdmin') : undefined;

  return (
    <FinalDeleteConfirmDialog
      {...props}
      infoText={infoText}
      objectTypeToDelete={t('externalAdmin.label')}
      confirmationCheckListItems={confirmationMessageChecklistItems}
      onClose={onClose}
    />
  );
}
