/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StatusStatisticDto } from './StatusStatisticDto';
import {
    StatusStatisticDtoFromJSON,
    StatusStatisticDtoFromJSONTyped,
    StatusStatisticDtoToJSON,
} from './StatusStatisticDto';

/**
 * 
 * @export
 * @interface StatusStatisticsDto
 */
export interface StatusStatisticsDto {
    /**
     * 
     * @type {Array<StatusStatisticDto>}
     * @memberof StatusStatisticsDto
     */
    statistics: Array<StatusStatisticDto>;
    /**
     * 
     * @type {number}
     * @memberof StatusStatisticsDto
     */
    remainingPercentage: number;
    /**
     * 
     * @type {number}
     * @memberof StatusStatisticsDto
     */
    totalCount: number;
}

/**
 * Check if a given object implements the StatusStatisticsDto interface.
 */
export function instanceOfStatusStatisticsDto(value: object): boolean {
    if (!('statistics' in value)) return false;
    if (!('remainingPercentage' in value)) return false;
    if (!('totalCount' in value)) return false;
    return true;
}

export function StatusStatisticsDtoFromJSON(json: any): StatusStatisticsDto {
    return StatusStatisticsDtoFromJSONTyped(json, false);
}

export function StatusStatisticsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusStatisticsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'statistics': ((json['statistics'] as Array<any>).map(StatusStatisticDtoFromJSON)),
        'remainingPercentage': json['remainingPercentage'],
        'totalCount': json['totalCount'],
    };
}

export function StatusStatisticsDtoToJSON(value?: StatusStatisticsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'statistics': ((value['statistics'] as Array<any>).map(StatusStatisticDtoToJSON)),
        'remainingPercentage': value['remainingPercentage'],
        'totalCount': value['totalCount'],
    };
}

