import { chakra } from '@chakra-ui/react';
import { faQuoteLeft } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { Descendant, Element as SlateElement, Node, Path, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import invariant from 'tiny-invariant';
import { isBlockActive } from '../block';
import {
  BlockFormatOption,
  CitationElement,
  CitationTextElement,
  EditorProps,
  ElementFormatOption,
  FormatOption,
  MarkElement,
} from '../format-types';
import { Render } from '../render';
import RichTextButton from '../rich-text-button';
import { WithFunction } from './create-editor';

const format: ElementFormatOption = 'citation';
const openQuoteMark = '„';
const closeQuoteMark = '“';

const withCitation: () => WithFunction =
  () =>
  (editor, { onlyOneBlockOption }) => {
    const renderCitation: Render<'block'> = {
      type: 'citation',
      render: ({ children, attributes }) => (
        <chakra.div mx={4} my={6} {...attributes}>
          {children}
        </chakra.div>
      ),
    };
    const renderCitationText: Render<'block'> = {
      type: 'citation_text',
      render: ({ children, attributes }) => <strong {...attributes}>{children}</strong>,
    };
    const renderCitationAuthor: Render<'block'> = {
      type: 'citation_author',
      render: ({ children, attributes }) => <p {...attributes}>{children}</p>,
    };

    editor.renderers = [...editor.renderers, renderCitation, renderCitationAuthor, renderCitationText];

    if (!onlyOneBlockOption) {
      const citationToolbarButton = (editor: EditorProps) => (
        <RichTextButton
          key={format}
          isActive={isBlockActive(editor, format, 'citation_text', 'citation_author')}
          isDisabled={
            !isBlockActive(editor, 'paragraph', format, 'citation_text', 'citation_author') && editor.selection !== null
          }
          onClick={(event) => {
            const isCitationActive = isBlockActive(editor, format, 'citation_text', 'citation_author');
            const isSubCitationActive = isBlockActive(editor, 'citation_text', 'citation_author');
            const isActive = isCitationActive || isSubCitationActive;

            if (isSubCitationActive) {
              Transforms.unwrapNodes(editor, {
                at: pathToParentOfType(editor.children, editor.selection!.anchor.path, 'citation'),
              });
            } else if (!isActive) {
              Transforms.wrapNodes(editor, { type: 'citation', children: [] });
            }
            ReactEditor.focus(editor);
            event.preventDefault();
          }}
          format={format}
          icon={faQuoteLeft}
        />
      );
      editor.toolbarButtons = [...editor.toolbarButtons, citationToolbarButton];
    }

    const { normalizeNode } = editor;
    editor.normalizeNode = ([node, path]) => {
      if (path.length > 0) {
        if (isCitationElement(node)) {
          let citationNode = node;
          // remove citation if no children present
          if (citationNode.children.length === 0) {
            Transforms.removeNodes(editor, { at: path });
          }

          // change sub nodes to citation text and author
          citationNode.children.forEach((child, index) => {
            if (child.type === 'text') {
              Transforms.wrapNodes(
                editor,
                {
                  type: 'citation_text',
                  children: [],
                },
                { at: [...path, index] },
              );
              citationNode = getUpdatedCitationNode(editor, path);
            } else if (!(child.type === 'citation_text' || child.type === 'citation_author')) {
              Transforms.setNodes(
                editor,
                {
                  type: 'citation_text',
                  children: [{ text: getTextForNode(child), type: 'text' }],
                },
                { at: [...path, index] },
              );
              citationNode = getUpdatedCitationNode(editor, path);
            }
          });

          // add text if missing
          if (citationNode.children.findIndex((child) => child.type === 'citation_text') === -1) {
            Transforms.insertNodes(
              editor,
              {
                type: 'citation_text',
                children: [{ text: 'zitat', type: 'text' }],
              },
              { at: [...path, 0] },
            );
            return normalizeNode([citationNode, path]);
          }

          // add author if missing
          if (citationNode.children.findIndex((child) => child.type === 'citation_author') === -1) {
            Transforms.insertNodes(
              editor,
              {
                type: 'citation_author',
                children: [{ text: 'autor', type: 'text' }],
              },
              { at: [...path, citationNode.children.length] },
            );
            return normalizeNode([citationNode, path]);
          }

          // exit citation block when enter is pressed after the author
          const lastChild = citationNode.children[citationNode.children.length - 1];
          if (lastChild.type === 'citation_author' && hasEmptyTextChild(lastChild)) {
            Transforms.removeNodes(editor, { at: [...path, citationNode.children.length - 1] });
            Transforms.insertNodes(
              editor,
              {
                type: 'paragraph',
                children: [{ type: 'text', text: '' }],
              },
              { at: Path.next(path), select: true },
            );
            return normalizeNode([citationNode, path]);
          }

          // exit citation block when enter is pressed at first point in citation
          const firstChild = citationNode.children[0];
          if (
            firstChild.type === 'citation_text' &&
            hasEmptyTextChild(firstChild) &&
            citationNode.children[1].type === 'citation_text'
          ) {
            Transforms.removeNodes(editor, { at: [...path, 0] });
            Transforms.insertNodes(
              editor,
              {
                type: 'paragraph',
                children: [{ type: 'text', text: '' }],
              },
              { at: path },
            );
            return normalizeNode([citationNode, path]);
          }

          // check all elements have correct type
          let didStuff = false;
          didStuff = false;
          citationNode.children.forEach((child, index) => {
            // last element must be 'author', all others must be 'text'
            if (citationNode.children.length - 1 === index) {
              const wasValid = enforceType('citation_author', child, [...path, index], editor);
              if (!wasValid) {
                didStuff = true;
              }
            } else {
              const wasValid = enforceType('citation_text', child, [...path, index], editor);
              if (!wasValid) {
                didStuff = true;
              }
            }
          });
          if (didStuff) {
            return normalizeNode([citationNode, path]);
          }

          // check quote marks are present
          const firstText = ((citationNode.children[0] as CitationTextElement).children[0] as MarkElement).text;
          if (!firstText.startsWith(openQuoteMark)) {
            Transforms.insertText(editor, openQuoteMark + firstText, { at: [...path, 0, 0] });
            return normalizeNode([citationNode, path]);
          }
          const lastTextLocation = citationNode.children.length - 2;
          const lastText = ((citationNode.children[lastTextLocation] as CitationTextElement).children[0] as MarkElement)
            .text;
          if (!lastText.endsWith(closeQuoteMark)) {
            Transforms.insertText(editor, lastText + closeQuoteMark, { at: [...path, lastTextLocation, 0] });
            Transforms.select(editor, {
              path: [...path, lastTextLocation, 0],
              offset: lastText.length,
            });
            return normalizeNode([citationNode, path]);
          }

          const lastIndex = citationNode.children.length - 2;
          const openOrClosingQuoteMarkRegex = /[„“]/gi;
          citationNode.children.forEach((child, index) => {
            invariant(child.type === 'citation_author' || child.type === 'citation_text');
            const childText = (child.children[0] as MarkElement).text;
            if (index === 0 && lastIndex === 0) {
              if (childText.slice(1).includes(openQuoteMark) || childText.slice(0, -1).includes(closeQuoteMark)) {
                const text =
                  openQuoteMark + childText.slice(1, -1).replaceAll(openOrClosingQuoteMarkRegex, '') + closeQuoteMark;
                Transforms.insertText(editor, text, { at: [...path, index, 0] });
              }
              return;
            }

            if (index === 0) {
              if (childText.slice(1).includes(openQuoteMark) || childText.includes(closeQuoteMark)) {
                const text = openQuoteMark + childText.replaceAll(openOrClosingQuoteMarkRegex, '');
                Transforms.insertText(editor, text, { at: [...path, index, 0] });
              }
              return;
            }
            if (index === lastIndex) {
              if (childText.slice(0, -1).includes(closeQuoteMark) || childText.includes(openQuoteMark)) {
                const text = childText.replaceAll(openOrClosingQuoteMarkRegex, '') + closeQuoteMark;
                Transforms.insertText(editor, text, { at: [...path, index, 0] });
              }
              return;
            }
            if (childText.includes(closeQuoteMark) || childText.includes(openQuoteMark)) {
              const text = childText.replaceAll(openOrClosingQuoteMarkRegex, '');
              Transforms.insertText(editor, text, { at: [...path, index, 0] });
            }
          });
        }
      }

      // when removing the citation we need to make sure all sub nodes are converted to paragraphs
      if (path.length === 0) {
        // this is always the case for path length 0
        if (node.type !== 'text') {
          node.children.forEach((rootChild, index) => {
            if (rootChild.type === 'citation_text' || rootChild.type === 'citation_author') {
              Transforms.setNodes(editor, { type: 'paragraph' }, { at: [index] });
            }
          });
        }
      }

      return normalizeNode([node, path]);
    };

    return editor;
  };

export default withCitation;

function pathToParentOfType(nodes: Descendant[], pathToChild: Path, type: FormatOption): Path {
  const nextNode = nodes[pathToChild[0]];
  // if next node in path has correct type
  if (nextNode.type === type) {
    return [pathToChild[0]];
  }

  invariant(nextNode.type !== 'text', 'type not found in path');

  return pathToParentOfType(nextNode.children, pathToChild.splice(1), type);
}

function enforceType(type: BlockFormatOption, node: Descendant, path: Path, editor: EditorProps): boolean {
  if (SlateElement.isElement(node) && node.type !== type) {
    const newProperties: Partial<SlateElement> = { type };
    Transforms.setNodes<SlateElement>(editor, newProperties, {
      at: path,
    });
    return false;
  }
  return true;
}

function hasEmptyTextChild(node: Descendant) {
  return (
    node.type !== 'text' &&
    node.children.length === 1 &&
    node.children[0].type === 'text' &&
    node.children[0].text === ''
  );
}

function isCitationElement(element: Node): element is CitationElement {
  return element.type === 'citation';
}

function getTextForNode(node: Node): string {
  if (node.type === 'text') {
    return node.text;
  }

  return node.children.map((child) => getTextForNode(child)).join();
}

function getUpdatedCitationNode(editor: EditorProps, path: Path) {
  const [node] = editor.node(path);
  invariant(isCitationElement(node));
  return node;
}
