/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PhoneNumberListItemDto
 */
export interface PhoneNumberListItemDto {
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberListItemDto
     */
    pretty: string;
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberListItemDto
     */
    raw: string;
}

/**
 * Check if a given object implements the PhoneNumberListItemDto interface.
 */
export function instanceOfPhoneNumberListItemDto(value: object): boolean {
    if (!('pretty' in value)) return false;
    if (!('raw' in value)) return false;
    return true;
}

export function PhoneNumberListItemDtoFromJSON(json: any): PhoneNumberListItemDto {
    return PhoneNumberListItemDtoFromJSONTyped(json, false);
}

export function PhoneNumberListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhoneNumberListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'pretty': json['pretty'],
        'raw': json['raw'],
    };
}

export function PhoneNumberListItemDtoToJSON(value?: PhoneNumberListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pretty': value['pretty'],
        'raw': value['raw'],
    };
}

