import { concat } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { KeyPlayerDto, PublishedKeyPlayersListSettingsDto } from '../../../api';
import publicKeyPlayerApi from '../../../data-access/public-key-player-api';
import { DataTableColumn } from '../../../ui/data-table';
import StringFilter from '../../../ui/data-table/filter/string-filter';
import Optional from '../../../ui/optional/optional';
import Translate from '../../../util/translate/translate';
import useDynamicColumns from '../../key-players/key-players-list-viewer/dynamic-key-players-columns';
import useKeyPlayerColumns from '../../key-players/key-players-list-viewer/use-key-players-columns';
import { addColumns } from '../../key-players/key-players-list-viewer/use-key-players-list-table-columns';

export default function useKeyPlayerListPublicTableColumns(
  keyPlayersListSettings: PublishedKeyPlayersListSettingsDto,
  publicationId: string,
  readOnly: boolean,
) {
  const { t } = useTranslation(['common', 'key_player_list', 'person']);
  const dynamicColumns = useDynamicColumns();
  const commonColumns = useKeyPlayerColumns(readOnly);

  const sectionColumnWithPublicFilter: DataTableColumn<KeyPlayerDto> = React.useMemo(() => {
    return {
      key: 'section',
      name: <Translate ns="key_player_list" i18nKey="lister.section" />,
      renderCell: (keyPlayerDto: KeyPlayerDto) => (
        <Optional isEmpty={keyPlayerDto.section?.section == null}>
          {keyPlayerDto.section?.section?.name + ' (' + keyPlayerDto.section?.jobTitle + ')'}
        </Optional>
      ),
      cellProps: {
        textAlign: 'left',
      },
      isSortable: true,
      filter: (
        <StringFilter
          label={t('key_player_list:lister.section')}
          loadOptions={async (value: string) => {
            const page = await publicKeyPlayerApi.searchSectionsInList({
              publicationId,
              pageable: { size: 10 },
              filter: [`name,contain,${value}`],
            });
            return page.content;
          }}
        />
      ),
    };
  }, [publicationId, t]);

  const staticColumns: DataTableColumn<KeyPlayerDto>[] = React.useMemo(() => {
    return [
      commonColumns.personColumn,
      commonColumns.typesColumn,
      commonColumns.rolesColumn,
      commonColumns.companyColumn,
      sectionColumnWithPublicFilter,
      commonColumns.presenceColumn,
      commonColumns.dateTimeRangePresenceColumn,
      commonColumns.commentColumn,
    ];
  }, [
    commonColumns.commentColumn,
    commonColumns.companyColumn,
    commonColumns.dateTimeRangePresenceColumn,
    commonColumns.personColumn,
    commonColumns.presenceColumn,
    commonColumns.rolesColumn,
    commonColumns.typesColumn,
    sectionColumnWithPublicFilter,
  ]);

  const configuredColumns = addColumns(keyPlayersListSettings, dynamicColumns);

  return configuredColumns.length > 0 ? concat(staticColumns, configuredColumns) : staticColumns;
}
