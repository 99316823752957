/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SenderTypeDto } from './SenderTypeDto';
import {
    SenderTypeDtoFromJSON,
    SenderTypeDtoFromJSONTyped,
    SenderTypeDtoToJSON,
} from './SenderTypeDto';

/**
 * 
 * @export
 * @interface MailingSenderDto
 */
export interface MailingSenderDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof MailingSenderDto
     */
    senderId: string;
    /**
     * 
     * @type {string}
     * @memberof MailingSenderDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof MailingSenderDto
     */
    email: string;
    /**
     * 
     * @type {SenderTypeDto}
     * @memberof MailingSenderDto
     */
    senderType: SenderTypeDto;
}

/**
 * Check if a given object implements the MailingSenderDto interface.
 */
export function instanceOfMailingSenderDto(value: object): boolean {
    if (!('senderId' in value)) return false;
    if (!('title' in value)) return false;
    if (!('email' in value)) return false;
    if (!('senderType' in value)) return false;
    return true;
}

export function MailingSenderDtoFromJSON(json: any): MailingSenderDto {
    return MailingSenderDtoFromJSONTyped(json, false);
}

export function MailingSenderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingSenderDto {
    if (json == null) {
        return json;
    }
    return {
        
        'senderId': json['senderId'],
        'title': json['title'],
        'email': json['email'],
        'senderType': SenderTypeDtoFromJSON(json['senderType']),
    };
}

export function MailingSenderDtoToJSON(value?: MailingSenderDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'senderId': value['senderId'],
        'title': value['title'],
        'email': value['email'],
        'senderType': SenderTypeDtoToJSON(value['senderType']),
    };
}

