/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderRejectionDto
 */
export interface OrderRejectionDto {
    /**
     * 
     * @type {string}
     * @memberof OrderRejectionDto
     */
    customMessage?: string;
}

/**
 * Check if a given object implements the OrderRejectionDto interface.
 */
export function instanceOfOrderRejectionDto(value: object): boolean {
    return true;
}

export function OrderRejectionDtoFromJSON(json: any): OrderRejectionDto {
    return OrderRejectionDtoFromJSONTyped(json, false);
}

export function OrderRejectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderRejectionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'customMessage': json['customMessage'] == null ? undefined : json['customMessage'],
    };
}

export function OrderRejectionDtoToJSON(value?: OrderRejectionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'customMessage': value['customMessage'],
    };
}

