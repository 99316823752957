/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VisaListItemDto } from './VisaListItemDto';
import {
    VisaListItemDtoFromJSON,
    VisaListItemDtoFromJSONTyped,
    VisaListItemDtoToJSON,
} from './VisaListItemDto';

/**
 * 
 * @export
 * @interface VisaPageDto
 */
export interface VisaPageDto {
    /**
     * The number of the page.
     * @type {number}
     * @memberof VisaPageDto
     */
    page: number;
    /**
     * The size of the page.
     * @type {number}
     * @memberof VisaPageDto
     */
    size: number;
    /**
     * The total number of data returned by the search.
     * @type {number}
     * @memberof VisaPageDto
     */
    totalElements: number;
    /**
     * The total number of pages of current size to fit all data.
     * @type {number}
     * @memberof VisaPageDto
     */
    totalPages: number;
    /**
     * 
     * @type {Array<VisaListItemDto>}
     * @memberof VisaPageDto
     */
    content: Array<VisaListItemDto>;
}

/**
 * Check if a given object implements the VisaPageDto interface.
 */
export function instanceOfVisaPageDto(value: object): boolean {
    if (!('page' in value)) return false;
    if (!('size' in value)) return false;
    if (!('totalElements' in value)) return false;
    if (!('totalPages' in value)) return false;
    if (!('content' in value)) return false;
    return true;
}

export function VisaPageDtoFromJSON(json: any): VisaPageDto {
    return VisaPageDtoFromJSONTyped(json, false);
}

export function VisaPageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisaPageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'page': json['page'],
        'size': json['size'],
        'totalElements': json['totalElements'],
        'totalPages': json['totalPages'],
        'content': ((json['content'] as Array<any>).map(VisaListItemDtoFromJSON)),
    };
}

export function VisaPageDtoToJSON(value?: VisaPageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'page': value['page'],
        'size': value['size'],
        'totalElements': value['totalElements'],
        'totalPages': value['totalPages'],
        'content': ((value['content'] as Array<any>).map(VisaListItemDtoToJSON)),
    };
}

