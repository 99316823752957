import { Alert, AlertIcon, AlertTitle, chakra, HStack, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import React from 'react';
import invariant from 'tiny-invariant';
import { MergePersonGuestListConflictDto } from '../../../api';
import EventReference from '../../event/event-reference/event-reference';

export function getMergeConflictMessages(
  conflicts: MergePersonGuestListConflictDto[],
  t: any,
  index: number,
): React.ReactNode {
  const guestListConflicts = conflicts.filter((conflict) => conflict.conflictType === 'GUEST_LIST');
  if (guestListConflicts.length > 0) {
    return (
      <Alert key={index} status="warning" aria-labelledby="merger-conflict-warning-title">
        <AlertIcon />
        <chakra.div>
          <AlertTitle id="merger-guestlist-conflict-warning-title" mr={2}>
            {t('person:merger.conflicts.title')}
          </AlertTitle>
          <Text mt={1} mb={4}>
            {t('person:merger.conflicts.guestList.guestListIntro')}
          </Text>
          <UnorderedList mt={1} listStyleType="'—'" marginInlineStart="0.8em" spacing={1}>
            {guestListConflicts.map((conflict) => {
              invariant(conflict.event);
              invariant(conflict.owners);

              const owners = conflict.owners.map((owner) => owner.abbreviation).join(', ');

              return (
                <ListItem pl={2} key={conflict.event.id}>
                  <HStack>
                    <EventReference size="sm" event={conflict.event} includeEdition />
                    <chakra.span>{' (' + owners + ')'}</chakra.span>
                  </HStack>
                </ListItem>
              );
            })}
          </UnorderedList>
          <Text mt={4}>
            {t('person:merger.conflicts.guestList.guestListBody') +
              ' ' +
              t('person:merger.conflicts.guestList.guestListTip')}
          </Text>
        </chakra.div>
      </Alert>
    );
  }
  return undefined;
}
