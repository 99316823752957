/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddPersonsDto
 */
export interface AddPersonsDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof AddPersonsDto
     */
    accessGroupId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddPersonsDto
     */
    personIds: Array<string>;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof AddPersonsDto
     */
    editionId: string;
}

/**
 * Check if a given object implements the AddPersonsDto interface.
 */
export function instanceOfAddPersonsDto(value: object): boolean {
    if (!('accessGroupId' in value)) return false;
    if (!('personIds' in value)) return false;
    if (!('editionId' in value)) return false;
    return true;
}

export function AddPersonsDtoFromJSON(json: any): AddPersonsDto {
    return AddPersonsDtoFromJSONTyped(json, false);
}

export function AddPersonsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddPersonsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'accessGroupId': json['accessGroupId'],
        'personIds': json['personIds'],
        'editionId': json['editionId'],
    };
}

export function AddPersonsDtoToJSON(value?: AddPersonsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accessGroupId': value['accessGroupId'],
        'personIds': value['personIds'],
        'editionId': value['editionId'],
    };
}

