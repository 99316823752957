import { Box, HStack } from '@chakra-ui/react';
import { faUserRobot } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HistoryEntryDto } from '../../api';
import { personIcon } from '../icons/business-objects';
import DateRelative from './date-relative';

interface HistoryEntryAuthorProps {
  entry: HistoryEntryDto;
}

export default function HistoryEntryAuthor({ entry }: HistoryEntryAuthorProps) {
  const { t } = useTranslation('common');

  return (
    <HStack spacing={1} fontSize="sm">
      <HStack fontWeight="medium" spacing={1}>
        <FontAwesomeIcon icon={entry.version.modifiedBy?.email != null ? personIcon : faUserRobot} />
        <Box>{entry.version.modifiedBy?.displayName ?? entry.version.modifiedBy?.email ?? t('viewer.system')}</Box>
      </HStack>
      <DateRelative
        date={entry.version.modifiedAt!}
        sx={{
          color: 'text.muted',
        }}
      />
    </HStack>
  );
}
