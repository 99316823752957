import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { type FormatOccupationListItemDto, FormatOccupationRelationDto, FormatReferenceDto } from '../../../../api';
import formatOccupationApi from '../../../../data-access/format-occupation-api';
import Connection from '../../../../ui/connection/connection';
import Form from '../../../../ui/form/form';
import { formatIcon, personIcon } from '../../../../ui/icons/business-objects';
import useToast from '../../../../ui/use-toast/use-toast';
import usePermission from '../../../permission/use-permission';

export interface FormatOccupationTerminateButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  onSuccess?(): void;

  format: FormatReferenceDto;
  relation: FormatOccupationListItemDto;
}

const FormatOccupationTerminateButton = React.forwardRef<HTMLButtonElement, FormatOccupationTerminateButtonProps>(
  ({ children, format, relation, onSuccess, ...props }, ref) => {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { hasPermission } = usePermission();

    return (
      <>
        {hasPermission('FORMAT:CAN_LINK_FORMAT_WITH_PERSON') && (
          <button ref={ref} {...props} onClick={onOpen}>
            {children}
          </button>
        )}
        <Modal isOpen={isOpen} onClose={onClose} size="md" closeOnOverlayClick={false}>
          <ModalOverlay />
          <FormatOccupationTerminateDialog
            onClose={onClose}
            onSuccess={onSuccess}
            format={format}
            relation={relation}
          />
        </Modal>
      </>
    );
  },
);

export default FormatOccupationTerminateButton;

interface FormatOccupationTerminateDialogProps {
  format: FormatReferenceDto;
  relation: FormatOccupationListItemDto;
  onClose: () => void;

  onSuccess?(): void;
}

export function FormatOccupationTerminateDialog({
  format,
  relation,
  onClose,
  onSuccess,
}: FormatOccupationTerminateDialogProps) {
  const { t } = useTranslation(['common', 'format']);
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  const showSuccessToast = useToast({
    id: 'format-company-terminate-success-toast',
    status: 'success',
  });

  const form = useForm<FormatOccupationRelationDto>({
    mode: 'all',
    defaultValues: {
      format,
      ...relation,
    },
  });

  const onCloseWithReset = () => {
    onClose();
  };

  const onTerminate = async (relation: FormatOccupationRelationDto) => {
    await formatOccupationApi.unlinkRelation({
      id: relation.id!,
    });

    showSuccessToast({
      title: t('format:connections.disconnectToast'),
    });

    onClose();
    onSuccess?.();
  };

  return (
    <ModalContent>
      <FormProvider<FormatOccupationRelationDto> {...form}>
        <Form<FormatOccupationRelationDto> onValid={onTerminate} initialFocusRef={initialFocusRef}>
          <ModalHeader>{t('format:connections.terminate')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Stack spacing={2} backgroundColor="background.highlight" pr={2} py={2} pl={3} borderRadius="base">
                <Connection
                  start={{
                    icon: <Box as={FontAwesomeIcon} icon={formatIcon} fixedWidth />,
                    element: (
                      <Text as="span" fontWeight="medium">
                        {format.name}
                      </Text>
                    ),
                  }}
                  end={{
                    icon: <Box as={FontAwesomeIcon} icon={personIcon} fixedWidth />,
                    element: (
                      <Text as="span" fontWeight="medium">
                        {`${relation.person.surname}, ${relation.person.firstName}`}
                      </Text>
                    ),
                  }}
                  size="md"
                />
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onCloseWithReset}>
              {t('common:action.abort')}
            </Button>
            <Button variant="primary" type="submit">
              {t('format:connections.disconnect')}
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </ModalContent>
  );
}
