import { DefaultPhoneNumberDto } from '../../api';

export default function renderPhoneNumber(value: DefaultPhoneNumberDto): string;
export default function renderPhoneNumber(value: DefaultPhoneNumberDto | undefined): string | undefined;
export default function renderPhoneNumber(value: DefaultPhoneNumberDto | undefined) {
  if (value == null) return undefined;

  const {
    countryCode: { countryCode },
    dialingCode,
    extension,
    number,
  } = value;

  let phoneNumber = `+${countryCode} `;
  if (dialingCode != null) phoneNumber += `${dialingCode} `;
  phoneNumber += number;
  if (extension != null) phoneNumber += `-${extension}`;

  return phoneNumber;
}
