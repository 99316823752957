import React from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchCode } from '../code-queries';

export default function CodeBreadcrumb() {
  const { codeId } = useParams<{ codeId: string }>();
  invariant(codeId, 'Empty codeId');
  const code = useFetcher(fetchCode, { id: codeId });

  return <>{code.code}</>;
}
