import React from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchVisa } from '../visa-queries';

export default function VisaBreadcrumb() {
  const { visaId } = useParams<{ visaId: string }>();
  invariant(visaId, 'Empty visaId');
  const visa = useFetcher(fetchVisa, { id: visaId });

  return <>{`${visa.applicant.firstName} ${visa.applicant.surname}`}</>;
}
