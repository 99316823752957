/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AllocationColorDto } from './AllocationColorDto';
import {
    AllocationColorDtoFromJSON,
    AllocationColorDtoFromJSONTyped,
    AllocationColorDtoToJSON,
} from './AllocationColorDto';
import type { AllocationStatusDto } from './AllocationStatusDto';
import {
    AllocationStatusDtoFromJSON,
    AllocationStatusDtoFromJSONTyped,
    AllocationStatusDtoToJSON,
} from './AllocationStatusDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';

/**
 * The allocation of persons per section
 * @export
 * @interface AllocationDto
 */
export interface AllocationDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof AllocationDto
     */
    id: string;
    /**
     * The name of the allocation
     * @type {string}
     * @memberof AllocationDto
     */
    name: string;
    /**
     * 
     * @type {SectionReferenceDto}
     * @memberof AllocationDto
     */
    owner: SectionReferenceDto;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof AllocationDto
     */
    personInCharge?: PersonReferenceDto;
    /**
     * 
     * @type {AllocationColorDto}
     * @memberof AllocationDto
     */
    color?: AllocationColorDto;
    /**
     * The area of the allocation
     * @type {string}
     * @memberof AllocationDto
     */
    area?: string;
    /**
     * The quantity of the allocation
     * @type {number}
     * @memberof AllocationDto
     */
    quantity?: number;
    /**
     * Whether the allocation overwrites the RSVP of the guest list
     * @type {boolean}
     * @memberof AllocationDto
     */
    overwriteRsvpDeadline: boolean;
    /**
     * 
     * @type {Date}
     * @memberof AllocationDto
     */
    rsvpDeadline?: Date;
    /**
     * 
     * @type {AllocationStatusDto}
     * @memberof AllocationDto
     */
    status: AllocationStatusDto;
}

/**
 * Check if a given object implements the AllocationDto interface.
 */
export function instanceOfAllocationDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('owner' in value)) return false;
    if (!('overwriteRsvpDeadline' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function AllocationDtoFromJSON(json: any): AllocationDto {
    return AllocationDtoFromJSONTyped(json, false);
}

export function AllocationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllocationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'owner': SectionReferenceDtoFromJSON(json['owner']),
        'personInCharge': json['personInCharge'] == null ? undefined : PersonReferenceDtoFromJSON(json['personInCharge']),
        'color': json['color'] == null ? undefined : AllocationColorDtoFromJSON(json['color']),
        'area': json['area'] == null ? undefined : json['area'],
        'quantity': json['quantity'] == null ? undefined : json['quantity'],
        'overwriteRsvpDeadline': json['overwriteRsvpDeadline'],
        'rsvpDeadline': json['rsvpDeadline'] == null ? undefined : (new Date(json['rsvpDeadline'])),
        'status': AllocationStatusDtoFromJSON(json['status']),
    };
}

export function AllocationDtoToJSON(value?: AllocationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'owner': SectionReferenceDtoToJSON(value['owner']),
        'personInCharge': PersonReferenceDtoToJSON(value['personInCharge']),
        'color': AllocationColorDtoToJSON(value['color']),
        'area': value['area'],
        'quantity': value['quantity'],
        'overwriteRsvpDeadline': value['overwriteRsvpDeadline'],
        'rsvpDeadline': value['rsvpDeadline'] == null ? undefined : ((value['rsvpDeadline']).toISOString()),
        'status': AllocationStatusDtoToJSON(value['status']),
    };
}

