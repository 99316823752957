/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const StaffStatusDto = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
} as const;
export type StaffStatusDto = typeof StaffStatusDto[keyof typeof StaffStatusDto];


export function StaffStatusDtoFromJSON(json: any): StaffStatusDto {
    return StaffStatusDtoFromJSONTyped(json, false);
}

export function StaffStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffStatusDto {
    return json as StaffStatusDto;
}

export function StaffStatusDtoToJSON(value?: StaffStatusDto | null): any {
    return value as any;
}

