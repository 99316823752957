import React from 'react';
import invariant from 'tiny-invariant';
import { AwardWinnerRelationDto } from '../../../../api';
import HistoryPage from '../../../../ui/history/history-page';
import { PageState } from '../../../../ui/pagination/use-pagination-state';
import useFetcher from '../../../../util/swr/use-fetcher';
import { AwardWinnerHistoryExtension } from '../../../award/award-relation/award-winner-history-extension';
import { fetchAwardWinnerHistory } from '../award-winner-queries';
import useAwardWinnerHistorySettings from './use-award-winner-history-settings';

export const awardWinnerHistoryExtension: AwardWinnerHistoryExtension = {
  content: (awardId, state, onStateChanged) => (
    <AwardWinnerHistory awardId={awardId} state={state} onStateChanged={onStateChanged} />
  ),
};

interface AwardWinnerHistoryProps {
  awardId: string;
  state: PageState;
  onStateChanged: (state: PageState) => void;
}

function AwardWinnerHistory({ awardId, state, onStateChanged }: AwardWinnerHistoryProps) {
  const awardWinnerHistorySettings = useAwardWinnerHistorySettings();

  const awardWinnerHistoryPage = useFetcher(fetchAwardWinnerHistory, { id: awardId, pageable: state });
  invariant(awardWinnerHistoryPage != null, 'Missing award winner history page');

  return (
    <HistoryPage<AwardWinnerRelationDto>
      page={awardWinnerHistoryPage}
      state={state}
      onStateChange={onStateChanged}
      historyDisplaySettings={awardWinnerHistorySettings}
      showLine={false}
    />
  );
}
