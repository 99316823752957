import { Popover as BasePopover, PopoverProps as BasePopoverProps, usePopoverContext } from '@chakra-ui/react';
import React from 'react';

interface PopoverProps extends Omit<BasePopoverProps, 'children'> {
  closeOnHorizontalScroll?: boolean;
  children?: React.ReactNode;
}

export default function Popover({ children, closeOnHorizontalScroll, ...props }: PopoverProps) {
  return (
    <BasePopover {...props}>
      <PopoverCloseOnScroll closeOnHorizontalScroll={closeOnHorizontalScroll}>{children}</PopoverCloseOnScroll>
    </BasePopover>
  );
}

interface PopoverCloseOnScrollProps {
  children: PopoverProps['children'];
  closeOnHorizontalScroll?: boolean;
}

function PopoverCloseOnScroll({ children, closeOnHorizontalScroll }: PopoverCloseOnScrollProps) {
  const { onClose, isOpen } = usePopoverContext();

  React.useEffect(() => {
    if (!isOpen || !closeOnHorizontalScroll) {
      return;
    }

    // Keep track of the previous scroll position to detect horizontal scrolling.
    const prevScrollXMap = new Map<Element, number>();

    const handleScroll = (event: Event) => {
      const target = event.target as Element;
      const prevScrollX = prevScrollXMap.get(target);

      if (prevScrollX != null && prevScrollX !== target.scrollLeft) {
        onClose();
      }

      prevScrollXMap.set(target, target.scrollLeft);
    };

    document.addEventListener('scroll', handleScroll, true);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [isOpen, closeOnHorizontalScroll, onClose]);

  return <>{children}</>;
}
