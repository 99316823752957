import React, { useMemo } from 'react';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';

export interface PageState {
  page?: number;
  size?: number;
  parameterName?: { page: string; size: string };
}

export default function usePaginationState(defaultState?: PageState) {
  const { page: pageParameterName, size: sizeParameterName } = defaultState?.parameterName ?? {
    page: 'page',
    size: 'size',
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultStateRef = React.useRef<PageState>({ page: 0, size: 20, ...defaultState });
  const stateOverwrittenRef = React.useRef(false);

  const state: PageState = React.useMemo(() => {
    const defaultState = defaultStateRef.current;

    return {
      page: Number(searchParams.get(pageParameterName) ?? defaultState.page),
      size: Number(searchParams.get(sizeParameterName) ?? defaultState.size),
    };
  }, [pageParameterName, searchParams, sizeParameterName]);

  const otherSearchParameters = useMemo(
    () =>
      [...searchParams.entries()].filter((param) => param[0] !== pageParameterName && param[0] !== sizeParameterName),
    [pageParameterName, searchParams, sizeParameterName],
  );

  const setState = React.useCallback(
    (state: PageState) => {
      const newSearchParams: URLSearchParamsInit = {};

      if (state.page != null) {
        newSearchParams[pageParameterName] = String(state.page);
      }

      if (state.size != null) {
        newSearchParams[sizeParameterName] = String(state.size);
      }
      otherSearchParameters.forEach((param) => {
        newSearchParams[param[0]] = param[1];
      });

      setSearchParams(newSearchParams, { replace: true });
      stateOverwrittenRef.current = true;
    },
    [otherSearchParameters, pageParameterName, setSearchParams, sizeParameterName],
  );

  return [state, setState] as const;
}
