import { useParams } from 'react-router-dom';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchLandingPageTemplate } from '../landing-page-template-queries';
import getLandingPageTemplateId from './get-landing-page-template-id';

export default function LandingPageTemplateBreadcrumb() {
  const landingPageTemplate = useFetcher(fetchLandingPageTemplate, { id: getLandingPageTemplateId(useParams()) });

  return <>{landingPageTemplate.name}</>;
}
