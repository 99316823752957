import productApi from '../../data-access/product-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';

export const productFetcher = createFetcherScope(productApi);
export const fetchProduct = productFetcher.createFetcher(productApi.fetchProduct);
export const fetchProductPage = productFetcher.createFetcher(productApi.searchProductListItems);
export const fetchProductHistory = productFetcher.createFetcher(productApi.fetchProductHistory);
export const fetchProductBundleAvailability = productFetcher.createFetcher(
  productApi.getRelatedProductBundleAvailability,
);
