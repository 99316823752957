import React from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';

const systemMailingRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'system-mailings',
    handle: {
      breadcrumb: <Translate ns="system_mailing" i18nKey="lister.title" />,
    },
    children: [
      {
        index: true,
        lazy: () => import('./system-mailing-lister/system-mailing-lister-route'),
      },
      {
        path: ':systemMailingId',
        lazy: () => import('./system-mailing-page/system-mailing-route'),
        children: [
          {
            lazy: () => import('./system-mailing-page/system-mailing-page-route'),
            children: [
              {
                index: true,
                lazy: () => import('./system-mailing-viewer/system-mailing-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./system-mailing-history/system-mailing-history-route'),
              },
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./system-mailing-editor/edit-system-mailing-route'),
          },
        ],
      },
    ],
  },
];

export default systemMailingRoutes;
