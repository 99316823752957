import { Flex, Grid, GridItem, Stack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { AttendeeDto } from '../../../api';
import DataTable, { useDataTableState } from '../../../ui/data-table';
import useRequestParams from '../../../ui/data-table/use-request-params';
import Page from '../../../ui/page/page';
import PageContent from '../../../ui/page/page-content';
import PagePublicHeader from '../../../ui/page/page-public-header';
import { LightModeBox } from '../../../util/color-mode/color-mode-box';
import useFetcher from '../../../util/swr/use-fetcher';
import { PublicEventReferenceHeader } from '../../event/event-reference/event-reference-header';
import {
  fetchPublicEventData,
  fetchPublicLiveTicketingAttendeeList,
} from '../../live-ticketing/live-ticketing-queries';
import useAttendeeColumns from '../../live-ticketing/live-ticketing-viewer/use-attendee-columns';

export default function PublicLiveTicketingViewer() {
  const { t } = useTranslation(['live_ticketing', 'common', 'app']);
  const { publicationId } = useParams<{ publicationId: string }>();
  invariant(publicationId != null, 'Missing publicationId');

  const eventSettings = useFetcher(fetchPublicEventData, { publicationId });

  return (
    <LightModeBox>
      <Page height="full">
        <GridItem>
          <PagePublicHeader />
        </GridItem>
        <PageContent useFullWidth fixedHeight>
          <Stack spacing={6}>
            <Flex as="header">
              <PublicEventReferenceHeader
                eventSettings={eventSettings}
                title={t('live_ticketing:lister.title', { event: eventSettings.eventReference.title })}
              />
            </Flex>
            <PublicLiveTicketingTable publicationId={publicationId} />
          </Stack>
        </PageContent>
      </Page>
    </LightModeBox>
  );
}

interface PublicLiveTicketingTableProps {
  publicationId: string;
}

function PublicLiveTicketingTable({ publicationId }: PublicLiveTicketingTableProps) {
  const [state, setState] = useDataTableState({ size: 20 });
  const rowKey = React.useCallback((attendee: AttendeeDto) => {
    invariant(attendee.timeOfAdmission != null, 'Missing attendee time of admission');

    return attendee.timeOfAdmission.toISOString();
  }, []);

  const requestParams = useRequestParams(state);
  const page = useFetcher(fetchPublicLiveTicketingAttendeeList, { publicationId, ...requestParams });
  const columns = useAttendeeColumns({ isReadOnly: true });

  return (
    <Grid
      height="inherit"
      gridTemplateAreas={`"alert"
                          "dataTable"`}
      gridTemplateRows="auto 1fr"
    >
      <GridItem area="dataTable" minHeight={0}>
        <DataTable
          page={page}
          state={state}
          columns={columns}
          rowKey={rowKey}
          onStateChange={setState}
          isPageable={true}
        />
      </GridItem>
    </Grid>
  );
}
