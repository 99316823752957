import { concat } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ColumnDtoTypeEnum,
  KeyPlayerDto,
  KeyPlayersListSettingsDto,
  PublishedKeyPlayersListSettingsDto,
} from '../../../api';
import keyPlayersListApi from '../../../data-access/key-players-list-api';
import { DataTableColumn } from '../../../ui/data-table';
import StringFilter from '../../../ui/data-table/filter/string-filter';
import Optional from '../../../ui/optional/optional';
import Translate from '../../../util/translate/translate';
import useDynamicColumns from './dynamic-key-players-columns';
import useKeyPlayerColumns from './use-key-players-columns';

export default function useKeyPlayerListTableColumns(
  listSettings: KeyPlayersListSettingsDto,
  readOnly: boolean,
  showEvent = false,
  getEventTitle?: (keyPlayer: KeyPlayerDto) => string | undefined,
) {
  const { t } = useTranslation('key_player_list');
  const dynamicColumns = useDynamicColumns();
  const commonColumns = useKeyPlayerColumns(readOnly, getEventTitle);

  const sectionColumn: DataTableColumn<KeyPlayerDto> = React.useMemo(() => {
    return {
      key: 'section',
      name: <Translate ns="key_player_list" i18nKey="lister.section" />,
      renderCell: (keyPlayerDto: KeyPlayerDto) => (
        <Optional isEmpty={keyPlayerDto.section?.section == null}>
          {keyPlayerDto.section?.section?.name + ' (' + keyPlayerDto.section?.jobTitle + ')'}
        </Optional>
      ),
      cellProps: {
        textAlign: 'left',
      },
      isSortable: true,
      filter: (
        <StringFilter
          label={t('lister.section')}
          loadOptions={async (value: string) => {
            const page = await keyPlayersListApi.searchSectionsWithinList({
              eventId: listSettings.eventId,
              pageable: { size: 10 },
              filter: [`name,contain,${value}`],
            });
            return page.content;
          }}
        />
      ),
    };
  }, [listSettings.eventId, t]);

  const staticColumns: DataTableColumn<KeyPlayerDto>[] = React.useMemo(() => {
    return [
      commonColumns.personColumn,
      commonColumns.typesColumn,
      commonColumns.rolesColumn,
      commonColumns.companyColumn,
      sectionColumn,
      commonColumns.presenceColumn,
      commonColumns.dateTimeRangePresenceColumn,
      commonColumns.commentColumn,
    ];
  }, [
    commonColumns.commentColumn,
    commonColumns.companyColumn,
    commonColumns.dateTimeRangePresenceColumn,
    commonColumns.personColumn,
    commonColumns.presenceColumn,
    commonColumns.rolesColumn,
    commonColumns.typesColumn,
    sectionColumn,
  ]);

  const configuredColumns = addColumns(listSettings, dynamicColumns);
  const columns = showEvent ? [commonColumns.eventColumn, ...staticColumns] : staticColumns;

  return configuredColumns.length > 0 ? concat(columns, configuredColumns) : columns;
}

export const addColumns = (
  keyPlayersListSettings: KeyPlayersListSettingsDto | PublishedKeyPlayersListSettingsDto,
  dynamicColumns: any,
) => {
  const columnsToAdd: DataTableColumn<KeyPlayerDto>[] = [];

  keyPlayersListSettings.columns.forEach((c) => {
    if (c.type === ColumnDtoTypeEnum.PHONE_NUMBER && c.active) {
      columnsToAdd.push({ ...dynamicColumns.phoneColumn });
    }
    if (c.type === ColumnDtoTypeEnum.EMAIL && c.active) {
      columnsToAdd.push({ ...dynamicColumns.emailColumn });
    }
    if (c.type === ColumnDtoTypeEnum.STAGE_NAME && c.active) {
      columnsToAdd.push({ ...dynamicColumns.stageNameColumn });
    }
    if (c.type === ColumnDtoTypeEnum.CONTACT_LANGUAGE && c.active) {
      columnsToAdd.push({ ...dynamicColumns.contactLanguageColumn });
    }
    if (c.type === ColumnDtoTypeEnum.MARKER && c.active) {
      columnsToAdd.push({ ...dynamicColumns.markerColumn });
    }
  });

  return columnsToAdd;
};
