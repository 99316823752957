import { Button, Flex, Grid, Heading, HStack, Spacer, useDisclosure } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { AwardDto, AwardStatusDto } from '../../../api';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import PageContent from '../../../ui/page/page-content';
import useEditionFromSearchParams from '../../../util/use-edition-from-search-param/use-edition-from-search-param';
import EditionSelectButton from '../../edition/edition-select-button';
import useActiveEdition from '../../edition/use-active-edition/use-active-edition';
import AwardWinnerEditorDialog from './award-winner-editor/award-winner-editor-dialog';
import AwardWinnerRelationDataTable from './award-winner-viewer/award-winner-relation-data-table';
import usePermission from '../../permission/use-permission';

interface AwardWinnerPageContentProps {
  award: AwardDto;
}

export default function AwardWinnerPageContent({ award }: AwardWinnerPageContentProps) {
  const { t } = useTranslation(['common', 'award']);
  const { isOpen: editDialogIsOpen, onOpen: onEditDialogOpen, onClose: onEditDialogClose } = useDisclosure();
  const activeEdition = useActiveEdition();
  const { hasPermission } = usePermission();

  const { edition, setEdition } = useEditionFromSearchParams(activeEdition);

  return (
    <PageContent useFullWidth>
      <Grid gridRowGap={6} maxH="full" gridTemplateRows="auto 1fr">
        <Flex as="header" alignItems="center">
          <HStack spacing={4}>
            <Heading size="md" as="h3" display="flex" alignItems="center">
              {t('award:connections.winners')}
            </Heading>
            <EditionSelectButton edition={edition} onChange={setEdition} buttonLabel={edition.name} />
          </HStack>

          <Spacer />
          {hasPermission('AWARD-PERSON:CAN_LINK_PERSON_WITH_AWARD') && (
            <Button
              as={DisableableButton}
              variant="primary"
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
              onClick={onEditDialogOpen}
              aria-label={t('award:connections.add')}
              isDisabled={award.status !== AwardStatusDto.ACTIVE}
              disableReason={t('award:connections.toast.reject.award')}
            >
              {t('award:connections.add')}
            </Button>
          )}
        </Flex>
        <AwardWinnerRelationDataTable award={award} edition={edition} />
        <AwardWinnerEditorDialog
          isOpen={editDialogIsOpen}
          onClose={onEditDialogClose}
          award={award}
          edition={edition}
        />
      </Grid>
    </PageContent>
  );
}
