/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PersonWithOccupationDto } from './PersonWithOccupationDto';
import {
    PersonWithOccupationDtoFromJSON,
    PersonWithOccupationDtoFromJSONTyped,
    PersonWithOccupationDtoToJSON,
} from './PersonWithOccupationDto';

/**
 * 
 * @export
 * @interface AddExternalCompanyAdminsDto
 */
export interface AddExternalCompanyAdminsDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof AddExternalCompanyAdminsDto
     */
    companyId: string;
    /**
     * 
     * @type {Array<PersonWithOccupationDto>}
     * @memberof AddExternalCompanyAdminsDto
     */
    relationIds: Array<PersonWithOccupationDto>;
}

/**
 * Check if a given object implements the AddExternalCompanyAdminsDto interface.
 */
export function instanceOfAddExternalCompanyAdminsDto(value: object): boolean {
    if (!('companyId' in value)) return false;
    if (!('relationIds' in value)) return false;
    return true;
}

export function AddExternalCompanyAdminsDtoFromJSON(json: any): AddExternalCompanyAdminsDto {
    return AddExternalCompanyAdminsDtoFromJSONTyped(json, false);
}

export function AddExternalCompanyAdminsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddExternalCompanyAdminsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'companyId': json['companyId'],
        'relationIds': ((json['relationIds'] as Array<any>).map(PersonWithOccupationDtoFromJSON)),
    };
}

export function AddExternalCompanyAdminsDtoToJSON(value?: AddExternalCompanyAdminsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'companyId': value['companyId'],
        'relationIds': ((value['relationIds'] as Array<any>).map(PersonWithOccupationDtoToJSON)),
    };
}

