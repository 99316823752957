/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FormatDto } from './FormatDto';
import {
    FormatDtoFromJSON,
    FormatDtoFromJSONTyped,
    FormatDtoToJSON,
} from './FormatDto';

/**
 * Format that will be created after merge of two other formats.
 * @export
 * @interface MergedFormatDto
 */
export interface MergedFormatDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof MergedFormatDto
     */
    formatA: string;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof MergedFormatDto
     */
    formatB: string;
    /**
     * 
     * @type {FormatDto}
     * @memberof MergedFormatDto
     */
    newFormat: FormatDto;
}

/**
 * Check if a given object implements the MergedFormatDto interface.
 */
export function instanceOfMergedFormatDto(value: object): boolean {
    if (!('formatA' in value)) return false;
    if (!('formatB' in value)) return false;
    if (!('newFormat' in value)) return false;
    return true;
}

export function MergedFormatDtoFromJSON(json: any): MergedFormatDto {
    return MergedFormatDtoFromJSONTyped(json, false);
}

export function MergedFormatDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MergedFormatDto {
    if (json == null) {
        return json;
    }
    return {
        
        'formatA': json['formatA'],
        'formatB': json['formatB'],
        'newFormat': FormatDtoFromJSON(json['newFormat']),
    };
}

export function MergedFormatDtoToJSON(value?: MergedFormatDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'formatA': value['formatA'],
        'formatB': value['formatB'],
        'newFormat': FormatDtoToJSON(value['newFormat']),
    };
}

