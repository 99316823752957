/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Reference to an edition with the last checked version
 * @export
 * @interface ImplicationVersionDto
 */
export interface ImplicationVersionDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof ImplicationVersionDto
     */
    affected?: string;
    /**
     * The internal revision identifier of the affected edition.
     * @type {string}
     * @memberof ImplicationVersionDto
     */
    revision?: string;
}

/**
 * Check if a given object implements the ImplicationVersionDto interface.
 */
export function instanceOfImplicationVersionDto(value: object): boolean {
    return true;
}

export function ImplicationVersionDtoFromJSON(json: any): ImplicationVersionDto {
    return ImplicationVersionDtoFromJSONTyped(json, false);
}

export function ImplicationVersionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImplicationVersionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'affected': json['affected'] == null ? undefined : json['affected'],
        'revision': json['revision'] == null ? undefined : json['revision'],
    };
}

export function ImplicationVersionDtoToJSON(value?: ImplicationVersionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'affected': value['affected'],
        'revision': value['revision'],
    };
}

