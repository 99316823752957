/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InvoiceTypeDto = {
    INVOICE: 'INVOICE',
    CANCELLATION_INVOICE: 'CANCELLATION_INVOICE'
} as const;
export type InvoiceTypeDto = typeof InvoiceTypeDto[keyof typeof InvoiceTypeDto];


export function InvoiceTypeDtoFromJSON(json: any): InvoiceTypeDto {
    return InvoiceTypeDtoFromJSONTyped(json, false);
}

export function InvoiceTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceTypeDto {
    return json as InvoiceTypeDto;
}

export function InvoiceTypeDtoToJSON(value?: InvoiceTypeDto | null): any {
    return value as any;
}

