/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A seat assigned to either a person-on-guest-list or an allocation.
 * @export
 * @interface SeatAssignmentDto
 */
export interface SeatAssignmentDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof SeatAssignmentDto
     */
    seatId: string;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof SeatAssignmentDto
     */
    allocationId?: string;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof SeatAssignmentDto
     */
    personOnGuestListId?: string;
}

/**
 * Check if a given object implements the SeatAssignmentDto interface.
 */
export function instanceOfSeatAssignmentDto(value: object): boolean {
    if (!('seatId' in value)) return false;
    return true;
}

export function SeatAssignmentDtoFromJSON(json: any): SeatAssignmentDto {
    return SeatAssignmentDtoFromJSONTyped(json, false);
}

export function SeatAssignmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeatAssignmentDto {
    if (json == null) {
        return json;
    }
    return {
        
        'seatId': json['seatId'],
        'allocationId': json['allocationId'] == null ? undefined : json['allocationId'],
        'personOnGuestListId': json['personOnGuestListId'] == null ? undefined : json['personOnGuestListId'],
    };
}

export function SeatAssignmentDtoToJSON(value?: SeatAssignmentDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'seatId': value['seatId'],
        'allocationId': value['allocationId'],
        'personOnGuestListId': value['personOnGuestListId'],
    };
}

