/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';

/**
 * Request visa creation
 * @export
 * @interface VisaCreateDto
 */
export interface VisaCreateDto {
    /**
     * 
     * @type {Date}
     * @memberof VisaCreateDto
     */
    date?: Date;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof VisaCreateDto
     */
    applicant: PersonReferenceDto;
}

/**
 * Check if a given object implements the VisaCreateDto interface.
 */
export function instanceOfVisaCreateDto(value: object): boolean {
    if (!('applicant' in value)) return false;
    return true;
}

export function VisaCreateDtoFromJSON(json: any): VisaCreateDto {
    return VisaCreateDtoFromJSONTyped(json, false);
}

export function VisaCreateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisaCreateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'date': json['date'] == null ? undefined : (new Date(json['date'])),
        'applicant': PersonReferenceDtoFromJSON(json['applicant']),
    };
}

export function VisaCreateDtoToJSON(value?: VisaCreateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'date': value['date'] == null ? undefined : ((value['date']).toISOString().substring(0,10)),
        'applicant': PersonReferenceDtoToJSON(value['applicant']),
    };
}

