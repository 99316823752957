/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TagDto } from './TagDto';
import {
    TagDtoFromJSON,
    TagDtoFromJSONTyped,
    TagDtoToJSON,
} from './TagDto';
import type { TagRelationReferenceDto } from './TagRelationReferenceDto';
import {
    TagRelationReferenceDtoFromJSON,
    TagRelationReferenceDtoFromJSONTyped,
    TagRelationReferenceDtoToJSON,
} from './TagRelationReferenceDto';

/**
 * Business Object associated with a tag to be shown in a list
 * @export
 * @interface TagRelationListItemDto
 */
export interface TagRelationListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof TagRelationListItemDto
     */
    id: string;
    /**
     * 
     * @type {TagDto}
     * @memberof TagRelationListItemDto
     */
    tag: TagDto;
    /**
     * 
     * @type {TagRelationReferenceDto}
     * @memberof TagRelationListItemDto
     */
    relation: TagRelationReferenceDto;
}

/**
 * Check if a given object implements the TagRelationListItemDto interface.
 */
export function instanceOfTagRelationListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('tag' in value)) return false;
    if (!('relation' in value)) return false;
    return true;
}

export function TagRelationListItemDtoFromJSON(json: any): TagRelationListItemDto {
    return TagRelationListItemDtoFromJSONTyped(json, false);
}

export function TagRelationListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagRelationListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tag': TagDtoFromJSON(json['tag']),
        'relation': TagRelationReferenceDtoFromJSON(json['relation']),
    };
}

export function TagRelationListItemDtoToJSON(value?: TagRelationListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'tag': TagDtoToJSON(value['tag']),
        'relation': TagRelationReferenceDtoToJSON(value['relation']),
    };
}

