/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The result of a status check.
 * @export
 * @interface PersonOnGuestListStatusCheckDto
 */
export interface PersonOnGuestListStatusCheckDto {
    /**
     * 
     * @type {boolean}
     * @memberof PersonOnGuestListStatusCheckDto
     */
    readonly isDeletable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PersonOnGuestListStatusCheckDto
     */
    readonly isRecipientInMailing: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PersonOnGuestListStatusCheckDto
     */
    readonly hasCorrectStatus: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PersonOnGuestListStatusCheckDto
     */
    readonly isCoupled: boolean;
}

/**
 * Check if a given object implements the PersonOnGuestListStatusCheckDto interface.
 */
export function instanceOfPersonOnGuestListStatusCheckDto(value: object): boolean {
    if (!('isDeletable' in value)) return false;
    if (!('isRecipientInMailing' in value)) return false;
    if (!('hasCorrectStatus' in value)) return false;
    if (!('isCoupled' in value)) return false;
    return true;
}

export function PersonOnGuestListStatusCheckDtoFromJSON(json: any): PersonOnGuestListStatusCheckDto {
    return PersonOnGuestListStatusCheckDtoFromJSONTyped(json, false);
}

export function PersonOnGuestListStatusCheckDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonOnGuestListStatusCheckDto {
    if (json == null) {
        return json;
    }
    return {
        
        'isDeletable': json['isDeletable'],
        'isRecipientInMailing': json['isRecipientInMailing'],
        'hasCorrectStatus': json['hasCorrectStatus'],
        'isCoupled': json['isCoupled'],
    };
}

export function PersonOnGuestListStatusCheckDtoToJSON(value?: PersonOnGuestListStatusCheckDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
    };
}

