/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  HistoryPageDto,
  PageableDto,
  PermissionClassGroupDto,
  PermissionClassGroupPageDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    PermissionClassGroupDtoFromJSON,
    PermissionClassGroupDtoToJSON,
    PermissionClassGroupPageDtoFromJSON,
    PermissionClassGroupPageDtoToJSON,
} from '../models/index';

export interface FetchPermissionClassGroupRequest {
    id: string;
}

export interface FetchPermissionClassGroupHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface SearchPermissionClassGroupItemsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface UpdatePermissionClassGroupRequest {
    id: string;
    permissionClassGroupDto: PermissionClassGroupDto;
}

/**
 * 
 */
export class PermissionClassGroupApi extends runtime.BaseAPI {

    /**
     * Get permission class group by id
     */
    async fetchPermissionClassGroupRaw(requestParameters: FetchPermissionClassGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PermissionClassGroupDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchPermissionClassGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/permission-class-groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionClassGroupDtoFromJSON(jsonValue));
    }

    /**
     * Get permission class group by id
     */
    async fetchPermissionClassGroup(requestParameters: FetchPermissionClassGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PermissionClassGroupDto> {
        const response = await this.fetchPermissionClassGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the permission class group
     */
    async fetchPermissionClassGroupHistoryRaw(requestParameters: FetchPermissionClassGroupHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchPermissionClassGroupHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/permission-class-groups/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the permission class group
     */
    async fetchPermissionClassGroupHistory(requestParameters: FetchPermissionClassGroupHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchPermissionClassGroupHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search permission class groups.
     */
    async searchPermissionClassGroupItemsRaw(requestParameters: SearchPermissionClassGroupItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PermissionClassGroupPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/permission-class-groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionClassGroupPageDtoFromJSON(jsonValue));
    }

    /**
     * Search permission class groups.
     */
    async searchPermissionClassGroupItems(requestParameters: SearchPermissionClassGroupItemsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PermissionClassGroupPageDto> {
        const response = await this.searchPermissionClassGroupItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the existing permission class group.
     */
    async updatePermissionClassGroupRaw(requestParameters: UpdatePermissionClassGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updatePermissionClassGroup().'
            );
        }

        if (requestParameters['permissionClassGroupDto'] == null) {
            throw new runtime.RequiredError(
                'permissionClassGroupDto',
                'Required parameter "permissionClassGroupDto" was null or undefined when calling updatePermissionClassGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/permission-class-groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PermissionClassGroupDtoToJSON(requestParameters['permissionClassGroupDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the existing permission class group.
     */
    async updatePermissionClassGroup(requestParameters: UpdatePermissionClassGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePermissionClassGroupRaw(requestParameters, initOverrides);
    }

}
