import { modalAnatomy } from '@chakra-ui/anatomy';
import { MultiStyleConfig } from '@chakra-ui/theme-tools';

const Modal: MultiStyleConfig<typeof modalAnatomy> = {
  parts: modalAnatomy.keys,
  baseStyle: {
    header: { textColor: 'heading' },
    dialog: { bg: 'layer.01' },
    overlay: { _dark: { bg: 'blackAlpha.700' } },
  },
};

export default Modal;
