import { menuAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: PartsStyleObject<typeof parts> = {
  list: {
    shadow: 'base',
    zIndex: 'dropdown',
    bgColor: 'layer.01',
    borderColor: 'border.01',
  },
  item: {
    bg: 'layer.01',
    _hover: {
      bg: 'layer.01.hover',
    },
    _focus: {
      bg: 'layer.01.hover',
    },
    '.chakra-menu__icon-wrapper': {
      fontSize: 'md',
    },
  },
  divider: {
    borderColor: 'border.01',
  },
};

const Menu = {
  parts: parts.keys,
  baseStyle,
};

export default Menu;
