import {
  Button,
  ButtonGroup,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PersonDto, VisaCreateDto } from '../../../api';
import DateInputFormControl from '../../../ui/form/date-input-control/date-input-form-control';
import Form from '../../../ui/form/form';
import SubmitButton from '../../../ui/form/submit-button';
import HelperPopover from '../../../ui/helper-buttons/helper-popover';

interface VisaEditDateDialogProps {
  person: PersonDto;

  handleValid(visa: VisaCreateDto): Promise<void>;

  onClose: () => void;
}

export default function VisaEditDateDialog({ person, handleValid, onClose }: VisaEditDateDialogProps) {
  const { t } = useTranslation(['common', 'visa']);
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  const form = useForm<VisaCreateDto>({
    mode: 'all',
    defaultValues: {
      applicant: { id: person.id! },
    },
  });

  return (
    <ModalContent>
      <FormProvider<VisaCreateDto> {...form}>
        <Form<VisaCreateDto> onValid={handleValid} initialFocusRef={initialFocusRef}>
          <ModalHeader>{t('visa:action.new_one')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <DateInputFormControl<VisaCreateDto>
              label={t('visa:date')}
              name="date"
              ref={initialFocusRef}
              helperPopover={<HelperPopover children={t('visa:helperText.date')} />}
            />
          </ModalBody>
          <ModalFooter>
            <ButtonGroup spacing={4}>
              <Button onClick={onClose}>{t('common:action.abort')}</Button>
              <SubmitButton variant="primary">{t('common:action.save')}</SubmitButton>
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </FormProvider>
    </ModalContent>
  );
}
