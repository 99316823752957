import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../../permission-class-group/permission-class-group-extension';

export function useAwardPersonPermissionClassGroupExtension(): PermissionClassGroupExtension<'AWARD-PERSON'> {
  const { t } = useTranslation('award');

  return {
    name: 'AWARD-PERSON',
    label: t('award'),
    getActionLabel: (action) => t(`awardWinnerPermission.permissions.${action}`),
    getAdditionalRequirement: (action) =>
      t(`awardWinnerPermission.permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`awardWinnerPermission.permissionInfo.${action}`, { defaultValue: '' }),
  };
}
