/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ModuleTypeDto } from './ModuleTypeDto';
import {
    ModuleTypeDtoFromJSON,
    ModuleTypeDtoFromJSONTyped,
    ModuleTypeDtoToJSON,
} from './ModuleTypeDto';

/**
 * 
 * @export
 * @interface MailingCodeModuleSettingsDto
 */
export interface MailingCodeModuleSettingsDto {
    /**
     * 
     * @type {ModuleTypeDto}
     * @memberof MailingCodeModuleSettingsDto
     */
    moduleType: ModuleTypeDto;
    /**
     * 
     * @type {Date}
     * @memberof MailingCodeModuleSettingsDto
     */
    timestamp?: Date;
}

/**
 * Check if a given object implements the MailingCodeModuleSettingsDto interface.
 */
export function instanceOfMailingCodeModuleSettingsDto(value: object): boolean {
    if (!('moduleType' in value)) return false;
    return true;
}

export function MailingCodeModuleSettingsDtoFromJSON(json: any): MailingCodeModuleSettingsDto {
    return MailingCodeModuleSettingsDtoFromJSONTyped(json, false);
}

export function MailingCodeModuleSettingsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingCodeModuleSettingsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'moduleType': ModuleTypeDtoFromJSON(json['moduleType']),
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
    };
}

export function MailingCodeModuleSettingsDtoToJSON(value?: MailingCodeModuleSettingsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'moduleType': ModuleTypeDtoToJSON(value['moduleType']),
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
    };
}

