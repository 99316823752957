import React, { Dispatch, SetStateAction } from 'react';
import createDefinedContext from '../context/create-defined-context/create-defined-context';

const [PendingContextProvider, usePendingContext] =
  createDefinedContext<[Promise<any>[], Dispatch<SetStateAction<Promise<any>[]>>]>('PendingContext');

/**
 * Provides Promises for asynchronous actions, e.g. uploading a file in a form context.
 */
export default function usePending() {
  const [pendingActions, setPendingActions] = usePendingContext();

  function awaitPending<T>(promise: Promise<T>): Promise<T> {
    setPendingActions([...pendingActions, promise]);
    return promise;
  }

  const allPending = () => Promise.all(pendingActions);

  return { awaitPending, allPending };
}

export interface PendingProviderProps {
  children: React.ReactNode;
}

/**
 * PendingProvider is needed if usePending is used.
 */
export function PendingProvider({ children }: PendingProviderProps) {
  const state = React.useState<Promise<any>[]>([]);

  return <PendingContextProvider value={state}>{children}</PendingContextProvider>;
}
