import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import UnconfirmedCompanyBreadcrumb from './common/unconfirmed-company-breadcrumb';

const unconfirmedCompaniesRoute: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'unconfirmed',
    handle: {
      breadcrumb: <Translate ns="unconfirmed_company" i18nKey="unconfirmedCompanies" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./unconfirmed-company-lister/unconfirmed-company-lister-route'),
      },
      {
        path: ':unconfirmedCompanyId',
        handle: {
          breadcrumb: <UnconfirmedCompanyBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./verify-company/verify-company-route'),
          },
        ],
      },
    ],
  },
];

export default unconfirmedCompaniesRoute;
