import { Stack } from '@chakra-ui/react';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { AddRecipientsDto } from '../../../../api';
import PageForm from '../../../../ui/page/page-form';
import useMailingRecipientEditor from '../../../mailing/mailing-recipient-editor/use-mailing-recipient-editor';
import PersonOnGuestListMailingRecipientControl from '../person-on-guest-list-recipient-control';

interface EventMailingRecipientEditorProps {
  eventId: string;
  mailingId: string;
}

export default function PersonalizedGuestListInvitationMailingRecipientEditor({
  eventId,
  mailingId,
}: EventMailingRecipientEditorProps) {
  const { t } = useTranslation(['event', 'guest_list']);
  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const { form, onValid } = useMailingRecipientEditor<AddRecipientsDto>(mailingId, { recipients: [] });

  return (
    <FormProvider {...form}>
      <PageForm onValid={onValid} height="full" initialFocusRef={initialFocusRef}>
        <Stack spacing={4}>
          <PersonOnGuestListMailingRecipientControl
            eventId={eventId}
            mailingId={mailingId}
            selectAllRef={initialFocusRef}
            helperText={<Trans t={t} i18nKey="mailingExtension.recipients.helperText" />}
          />
        </Stack>
      </PageForm>
    </FormProvider>
  );
}
