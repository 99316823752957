import { uniqueId } from 'lodash-es';
import { BareFetcher, Key, mutate, SWRConfiguration } from 'swr';
import Fetcher, { createFetcher } from './fetcher';

export default interface FetcherScope {
  key: string;
  createFetcher<TArgs extends Key = Key, TData = unknown, TError = unknown>(
    fetcher: (args: TArgs) => Promise<TData>,
    config?: Omit<SWRConfiguration<TData, TError, BareFetcher<TData>>, 'suspense'>,
  ): Fetcher<TArgs, TData, TError>;
  mutate(): Promise<unknown>;
}

export function createFetcherScope(thisArg?: any): FetcherScope {
  const scopeName = (thisArg != null && 'constructor' in thisArg && thisArg.constructor.name) || 'scope';

  return {
    key: uniqueId(`${scopeName}-`),

    createFetcher<TArgs extends Key = Key, TData = unknown, TError = unknown>(
      fetcherFn: (args: TArgs) => Promise<TData>,
      config: Omit<SWRConfiguration<TData, TError, BareFetcher<TData>>, 'suspense'> = {},
    ): Fetcher<TArgs, TData, TError> {
      const fetcher = createFetcher<TArgs, TData, TError>(
        thisArg != null ? fetcherFn.bind(thisArg) : fetcherFn,
        config,
      );

      return {
        ...fetcher,
        key: (args) => {
          return [this.key, ...fetcher.key(args)];
        },
      };
    },

    mutate() {
      return mutate((key) => Array.isArray(key) && key[0] === this.key) as Promise<unknown>;
    },
  };
}
