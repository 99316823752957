import { Button, Center, Spinner } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PersonBadgeDto } from '../../api';
import { usePublicKey } from '../../util/validate-signature/validate-signature';
import usePersonScanner from '../admission/use-person-scanner';

interface ScannerProps {
  closeScanner: () => void;
  onScan: () => void;
}

export default function SearchScanner({ closeScanner, onScan }: ScannerProps) {
  const { t } = useTranslation('app');
  const { data: publicKey } = usePublicKey();
  const navigate = useNavigate();
  const onSuccessfulScan = (personBadge: PersonBadgeDto) => {
    resetLastScanResult();
    navigate(`/persons/${personBadge.person.id}`);
    onScan();
  };
  const { resetLastScanResult, scanner } = usePersonScanner(onSuccessfulScan, undefined, publicKey);

  return (
    <>
      {publicKey == null ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        scanner
      )}
      <Button marginTop={2} onClick={closeScanner}>
        {t('search.qrSearch.back')}
      </Button>
    </>
  );
}
