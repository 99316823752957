/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SimplePersonDto } from './SimplePersonDto';
import {
    SimplePersonDtoFromJSON,
    SimplePersonDtoFromJSONTyped,
    SimplePersonDtoToJSON,
} from './SimplePersonDto';

/**
 * The list of IDs of possible duplicates.
 * @export
 * @interface PersonDuplicatesDto
 */
export interface PersonDuplicatesDto {
    /**
     * 
     * @type {Array<SimplePersonDto>}
     * @memberof PersonDuplicatesDto
     */
    duplicates?: Array<SimplePersonDto>;
}

/**
 * Check if a given object implements the PersonDuplicatesDto interface.
 */
export function instanceOfPersonDuplicatesDto(value: object): boolean {
    return true;
}

export function PersonDuplicatesDtoFromJSON(json: any): PersonDuplicatesDto {
    return PersonDuplicatesDtoFromJSONTyped(json, false);
}

export function PersonDuplicatesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonDuplicatesDto {
    if (json == null) {
        return json;
    }
    return {
        
        'duplicates': json['duplicates'] == null ? undefined : ((json['duplicates'] as Array<any>).map(SimplePersonDtoFromJSON)),
    };
}

export function PersonDuplicatesDtoToJSON(value?: PersonDuplicatesDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'duplicates': value['duplicates'] == null ? undefined : ((value['duplicates'] as Array<any>).map(SimplePersonDtoToJSON)),
    };
}

