import { Configuration, OAuth2Api } from '../api';
import basePath from './base-path';
import ErrorMiddleware from './error-middleware';
import stringifyQueryParams from './stringify-query-params';

// mj, lh: Separate configuration for OAuth api to omit AuthMiddleware (causing endless request due
// to refresh token calls)
const configuration = new Configuration({
  basePath,
  queryParamsStringify: stringifyQueryParams,
  middleware: [ErrorMiddleware],
});

const oAuth2Api = new OAuth2Api(configuration);

export default oAuth2Api;
