/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DateRangeDto } from './DateRangeDto';
import {
    DateRangeDtoFromJSON,
    DateRangeDtoFromJSONTyped,
    DateRangeDtoToJSON,
} from './DateRangeDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';

/**
 * A comment
 * @export
 * @interface PersonPersonConnectionDto
 */
export interface PersonPersonConnectionDto {
    /**
     * 
     * @type {string}
     * @memberof PersonPersonConnectionDto
     */
    connectionType: PersonPersonConnectionDtoConnectionTypeEnum;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof PersonPersonConnectionDto
     */
    connectedPerson: PersonReferenceDto;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof PersonPersonConnectionDto
     */
    dateRange: DateRangeDto;
    /**
     * 
     * @type {Date}
     * @memberof PersonPersonConnectionDto
     */
    timestamp?: Date;
}


/**
 * @export
 */
export const PersonPersonConnectionDtoConnectionTypeEnum = {
    AGENT_FOR: 'AGENT_FOR',
    HAS_AGENT: 'HAS_AGENT',
    ASSISTANT_TO: 'ASSISTANT_TO',
    HAS_ASSISTANT: 'HAS_ASSISTANT',
    GUEST_MANAGER_FOR: 'GUEST_MANAGER_FOR',
    HAS_GUEST_MANAGER: 'HAS_GUEST_MANAGER',
    GUEST_LIAISON_FOR: 'GUEST_LIAISON_FOR',
    HAS_GUEST_LIAISON: 'HAS_GUEST_LIAISON',
    HAS_PERSONAL_RELATIONSHIP: 'HAS_PERSONAL_RELATIONSHIP',
    AIDE_TO: 'AIDE_TO',
    HAS_AIDE: 'HAS_AIDE',
    PUBLICIST_FOR: 'PUBLICIST_FOR',
    HAS_PUBLICIST: 'HAS_PUBLICIST'
} as const;
export type PersonPersonConnectionDtoConnectionTypeEnum = typeof PersonPersonConnectionDtoConnectionTypeEnum[keyof typeof PersonPersonConnectionDtoConnectionTypeEnum];


/**
 * Check if a given object implements the PersonPersonConnectionDto interface.
 */
export function instanceOfPersonPersonConnectionDto(value: object): boolean {
    if (!('connectionType' in value)) return false;
    if (!('connectedPerson' in value)) return false;
    if (!('dateRange' in value)) return false;
    return true;
}

export function PersonPersonConnectionDtoFromJSON(json: any): PersonPersonConnectionDto {
    return PersonPersonConnectionDtoFromJSONTyped(json, false);
}

export function PersonPersonConnectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonPersonConnectionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'connectionType': json['connectionType'],
        'connectedPerson': PersonReferenceDtoFromJSON(json['connectedPerson']),
        'dateRange': DateRangeDtoFromJSON(json['dateRange']),
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
    };
}

export function PersonPersonConnectionDtoToJSON(value?: PersonPersonConnectionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'connectionType': value['connectionType'],
        'connectedPerson': PersonReferenceDtoToJSON(value['connectedPerson']),
        'dateRange': DateRangeDtoToJSON(value['dateRange']),
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
    };
}

