import { ReactNode, useEffect, useMemo, useRef } from 'react';
import { ValidateResult as ReactHookFormValidationResult } from 'react-hook-form';
import { DEBOUNCE_TIME } from '../constants';
import useCallbackRef from '../use-callback-ref/use-callback-ref';

type ValidateResult = ReactHookFormValidationResult | ReactNode;

const NO_VALUE = Symbol('NO_VALUE');

interface AsyncValidation<T> {
  /**
   * Validation function signature
   */
  (data: T): Promise<ReactHookFormValidationResult>;

  /**
   * Method to reset the validation result and allow re-performing
   */
  reset(): void;
}

export interface AsyncValidationCallback<T> {
  // Callback function signature
  (data: T, ctx: { signal: AbortSignal }): ValidateResult | Promise<ValidateResult>;
}

export default function useAsyncValidation<T>(
  callback: AsyncValidationCallback<T>,
  { debounce = DEBOUNCE_TIME }: { debounce?: number } = {},
): AsyncValidation<T> {
  const abortControllerRef = useRef<AbortController | null>(null);
  const deferredRef = useRef<Deferred<ValidateResult> | null>(null);
  const resultRef = useRef<ValidateResult | typeof NO_VALUE>(NO_VALUE);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const isValidatingRef = useRef(false);

  // Wrap the callback in a ref to maintain a stable reference
  callback = useCallbackRef(callback);

  const reset = useCallbackRef(() => {
    // Clear the timeout
    if (timeoutRef.current != null) {
      clearTimeout(timeoutRef.current);
    }

    // Abort any ongoing requests
    abortControllerRef.current?.abort();

    // Reset timeout ref
    timeoutRef.current = null;
    // Reset the AbortController
    abortControllerRef.current = null;
    // Reset validating flag
    isValidatingRef.current = false;
    // Reset the result
    resultRef.current = NO_VALUE;
  });

  // Cleanup effect to abort ongoing validation when the component unmounts
  useEffect(
    () => () => {
      reset();
    },
    [reset],
  );

  return useMemo(() => {
    const innerValidate = async (data: T) => {
      if (isValidatingRef.current) {
        return;
      }

      isValidatingRef.current = true;

      await new Promise((resolve) => {
        // Wait for the debounce duration
        timeoutRef.current = setTimeout(resolve, debounce);
      });

      // Create a new AbortController
      const { signal } = (abortControllerRef.current = new AbortController());

      const result = await callback(data, {
        // Pass the AbortController's signal to the callback
        signal,
      });

      // Make sure the current promise is never resolved if signal was aborted.
      signal.throwIfAborted();

      deferredRef.current?.resolve?.(result);

      // Store the result
      resultRef.current = result;
      // Reset the AbortController
      abortControllerRef.current = null;
      // Reset cached promise
      deferredRef.current = null;
      // Reset timeout ref
      timeoutRef.current = null;

      isValidatingRef.current = false;
    };

    const validate = (data: T) => {
      if (resultRef.current !== NO_VALUE) {
        // Return the previous result if available
        return Promise.resolve(resultRef.current);
      }

      if (deferredRef.current == null) {
        deferredRef.current = defer<ValidateResult>();
      }

      // Imagine this:
      // * We have a custom form control component
      // * Inside this form control is some kind of form component (e.g. Input, Textbox, etc.)
      // * Via an onChange event handler we track changes to this form component
      // * Meaning: inside this onChange handler we call two handlers
      //   * `field.onChange` from `useController` (react-hook-form)
      //   * `props.onChange` used e.g. to call `validation.reset` from `useAsyncValidation`
      //
      // To make sure `useAsyncValidation` always works correctly we would need some way to enforce
      // that `props.onChange` is always called before `field.onChange`. But this is not always
      // possible due to other restrictions.
      //
      // So the solution here is to delay the validation to the next tick to first run synchronous
      // code (invalidation of the cache, aborting and clearing timeouts), before running the
      // actual validation.
      const waitForPossibleAbort = Promise.resolve();

      waitForPossibleAbort.then(() => {
        innerValidate(data).catch(deferredRef.current?.reject);
      });

      return deferredRef.current.promise;
    };

    validate.reset = reset;

    // Return the validation function with the invalidate method
    return validate as AsyncValidation<T>;
  }, [callback, debounce, reset]);
}

interface Deferred<T> {
  promise: Promise<T>;
  resolve?: (value: T) => void;
  reject?: (reason?: unknown) => void;
}

export function defer<T>() {
  const deferred = {} as Deferred<T>;

  deferred.promise = new Promise<T>((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });

  return deferred;
}
