/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Event evaluation status
 * @export
 */
export const EventEvaluationStatusDto = {
    PENDING: 'PENDING',
    SUBMITTED: 'SUBMITTED',
    IN_REVIEW: 'IN_REVIEW',
    REVIEWED: 'REVIEWED'
} as const;
export type EventEvaluationStatusDto = typeof EventEvaluationStatusDto[keyof typeof EventEvaluationStatusDto];


export function EventEvaluationStatusDtoFromJSON(json: any): EventEvaluationStatusDto {
    return EventEvaluationStatusDtoFromJSONTyped(json, false);
}

export function EventEvaluationStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventEvaluationStatusDto {
    return json as EventEvaluationStatusDto;
}

export function EventEvaluationStatusDtoToJSON(value?: EventEvaluationStatusDto | null): any {
    return value as any;
}

