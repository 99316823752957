/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GuestListPermissionDto
 */
export interface GuestListPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof GuestListPermissionDto
     */
    name: GuestListPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof GuestListPermissionDto
     */
    action: GuestListPermissionDtoActionEnum;
}


/**
 * @export
 */
export const GuestListPermissionDtoNameEnum = {
    GUEST_LIST: 'GUEST_LIST'
} as const;
export type GuestListPermissionDtoNameEnum = typeof GuestListPermissionDtoNameEnum[keyof typeof GuestListPermissionDtoNameEnum];

/**
 * @export
 */
export const GuestListPermissionDtoActionEnum = {
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    SEE_DETAILS_OWN: 'CAN_SEE_DETAILS_OWN',
    CREATE: 'CAN_CREATE',
    CREATE_OWN: 'CAN_CREATE_OWN',
    SEE_SETTINGS: 'CAN_SEE_SETTINGS',
    SEE_SETTINGS_OWN: 'CAN_SEE_SETTINGS_OWN',
    EDIT_SETTINGS: 'CAN_EDIT_SETTINGS',
    EDIT_SETTINGS_OWN: 'CAN_EDIT_SETTINGS_OWN',
    ADD_PERSON_ALLOCATION_CLOSED: 'CAN_ADD_PERSON_ALLOCATION_CLOSED',
    ADD_PERSON_ALLOCATION_CLOSED_OWN: 'CAN_ADD_PERSON_ALLOCATION_CLOSED_OWN',
    ADD_PERSON_ALLOCATION_SHARED: 'CAN_ADD_PERSON_ALLOCATION_SHARED',
    ADD_PERSON_ALLOCATION_SHARED_OWN: 'CAN_ADD_PERSON_ALLOCATION_SHARED_OWN',
    SHARE_ALLOCATION: 'CAN_SHARE_ALLOCATION',
    SHARE_ALLOCATION_OWN: 'CAN_SHARE_ALLOCATION_OWN',
    START_ADMISSION: 'CAN_START_ADMISSION',
    START_ADMISSION_OWN: 'CAN_START_ADMISSION_OWN',
    ACTIVATE: 'CAN_ACTIVATE',
    ACTIVATE_OWN: 'CAN_ACTIVATE_OWN',
    EXPORT: 'CAN_EXPORT',
    EXPORT_OWN: 'CAN_EXPORT_OWN'
} as const;
export type GuestListPermissionDtoActionEnum = typeof GuestListPermissionDtoActionEnum[keyof typeof GuestListPermissionDtoActionEnum];


/**
 * Check if a given object implements the GuestListPermissionDto interface.
 */
export function instanceOfGuestListPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function GuestListPermissionDtoFromJSON(json: any): GuestListPermissionDto {
    return GuestListPermissionDtoFromJSONTyped(json, false);
}

export function GuestListPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuestListPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function GuestListPermissionDtoToJSON(value?: GuestListPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

