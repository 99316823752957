import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useMyAccountPermissionClassGroupExtension(): PermissionClassGroupExtension<'MY_ACCOUNT'> {
  const { t } = useTranslation(['my_account']);

  return {
    name: 'MY_ACCOUNT',
    label: t('my_account:my-account'),
    getActionLabel: (action) => t(`my_account:permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`my_account:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`my_account:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
