import { GroupBase } from 'chakra-react-select';
import React from 'react';
import { DEBOUNCE_TIME } from '../../util/constants';
import AsyncSelect, { AsyncSelectProps } from './async-select';
import useAsyncNoOptionsMessage from './use-async-no-options-message';
import useDebouncedLoadOptions from './use-debounced-load-options';
import ValueSelectOption from './value-select-option';

export interface ValueAsyncSelectProps<
  TOption,
  TGroup extends GroupBase<ValueSelectOption<TOption>> = GroupBase<ValueSelectOption<TOption>>,
> extends Omit<
    AsyncSelectProps<ValueSelectOption<TOption>, false, TGroup>,
    'onChange' | 'value' | 'isMulti' | 'loadOptions'
  > {
  renderLabel: (value: TOption) => React.ReactNode;
  optionIdentifier: (value: TOption) => string;
  onChange: (value: TOption | null) => void;
  value: TOption | null;
  pageSizeLimit?: number;
  debounceTime?: number;
  loadOptions: (searchQuery: string, pageSizeLimit: number) => Promise<TOption[]>;
}

function ValueAsyncSelect<T>(
  {
    renderLabel,
    value,
    onChange,
    loadOptions,
    defaultOptions,
    pageSizeLimit = 50,
    debounceTime = DEBOUNCE_TIME,
    optionIdentifier,
    ...props
  }: ValueAsyncSelectProps<T>,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const selectedOption = value != null ? { value: value, label: renderLabel(value) } : null;
  const debouncedLoadOptions = useDebouncedLoadOptions(loadOptions, pageSizeLimit, debounceTime);
  const noOptionsMessage = useAsyncNoOptionsMessage(defaultOptions !== false);

  const mappedLoadOptions = (value: string) =>
    debouncedLoadOptions(value).then((options) =>
      options.map((option) => ({
        value: option,
        label: renderLabel(option),
      })),
    );

  return (
    <AsyncSelect<ValueSelectOption<T>, false>
      {...props}
      isMulti={false}
      value={selectedOption}
      loadOptions={mappedLoadOptions}
      getOptionValue={({ value }) => optionIdentifier(value)}
      defaultOptions={defaultOptions}
      onChange={(selectedOption) => {
        onChange(selectedOption?.value ?? null);
      }}
      noOptionsMessage={noOptionsMessage}
      ref={ref}
    />
  );
}

export default React.forwardRef(ValueAsyncSelect);
