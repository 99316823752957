import React from 'react';
import { DefaultValues, FieldValues, FormProvider, useForm } from 'react-hook-form';
import useDefinedContext from '../../../util/context/use-defined-context/use-defined-context';

interface ElementFormProps<TElement> {
  element?: TElement;
  onSubmit(element: TElement): void;
}

interface ElementFormModalProps {
  label: React.ReactNode;
  isOpen: boolean;
  onClose(): void;
}

const ElementFormContext = React.createContext<ElementFormProps<any> | undefined>(undefined);

const ElementFormModalContext = React.createContext<ElementFormModalProps | undefined>(undefined);

interface ElementFormProviderProps<TElement extends FieldValues> extends ElementFormProps<TElement> {
  children?: React.ReactNode;
  defaultElement?: DefaultValues<TElement>;
}

export function ElementFormProvider<TElement extends FieldValues>({
  children,
  defaultElement,
  ...props
}: ElementFormProviderProps<TElement>) {
  const form = useForm<TElement>({
    mode: 'all',
    shouldUnregister: true,
    defaultValues: defaultElement,
  });

  return (
    <FormProvider {...form}>
      <ElementFormContext.Provider value={props}>{children}</ElementFormContext.Provider>
    </FormProvider>
  );
}

interface ElementFormModalProviderProps extends ElementFormModalProps {
  children?: React.ReactNode;
}

export function ElementFormModalProvider({ children, ...props }: ElementFormModalProviderProps) {
  return <ElementFormModalContext.Provider value={props}>{children}</ElementFormModalContext.Provider>;
}

export function useElementForm<TElement>() {
  return useDefinedContext(ElementFormContext) as ElementFormProps<TElement>;
}

export function useElementFormModal() {
  return useDefinedContext(ElementFormModalContext);
}
