import { HStack } from '@chakra-ui/react';
import React from 'react';
import { FieldPath, FieldValues, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  AutomaticCompanyConnectionDtoTypeEnum,
  AutomaticConnectionsDtoConnectionTypeEnum,
  CodeDto,
  CompanyReferenceDto,
  StandPersonRelationTypeDto,
  StandReferenceDto,
} from '../../../api';
import companyApi from '../../../data-access/company-api';
import standApi from '../../../data-access/stand-api';
import ValueAsyncSelectFormControl from '../../../ui/form/select-control/value-async-select-form-control';
import ValueSelectFormControl from '../../../ui/form/select-control/value-select-form-control';

const connectionTypeOptions: AutomaticConnectionsDtoConnectionTypeEnum[] = ['COMPANY', 'STAND', 'NONE'];
const typeOptions: AutomaticCompanyConnectionDtoTypeEnum[] = ['BOOTH_STAFF', 'PRESS', 'STUDENT'];
const standTypeOptions: StandPersonRelationTypeDto[] = [
  'OTHER',
  'STAND_COORDINATOR',
  'STAND_PERSONNEL',
  'EXTERNAL_CONTACT',
];

const companyLoadOptions = async (value: string, pageSizeLimit: number) => {
  const page = await companyApi.searchActiveCompanyReferences({
    pageable: { size: pageSizeLimit, sort: ['title,ASC'] },
    q: value,
  });
  return page.content.map((company) => ({
    id: company.id!,
    name: company.name,
  }));
};

const standLoadOptions = async (value: string, pageSizeLimit: number) => {
  const page = await standApi.searchStandReferences({
    pageable: { size: pageSizeLimit, sort: ['name,ASC'] },
    searchQuery: value,
  });
  return page.content;
};

interface AutomaticConnectionsDtoConnectionProps<T extends FieldValues> {
  path?: FieldPath<T>;
}
export default function AutomaticConnectionControl<T extends FieldValues>({
  path,
}: AutomaticConnectionsDtoConnectionProps<T>) {
  const prefixWithPath = <TPath extends string>(name: TPath) => (path != null ? (`${path}.${name}` as TPath) : name);
  const { t } = useTranslation(['code', 'common', 'stand']);
  const connectionType = useWatch({ name: prefixWithPath('automaticConnections.connectionType') }) as
    | AutomaticConnectionsDtoConnectionTypeEnum
    | undefined;
  const { setValue } = useFormContext();

  return (
    <>
      <HStack alignItems="start">
        <ValueSelectFormControl<AutomaticConnectionsDtoConnectionTypeEnum, CodeDto>
          name={prefixWithPath('automaticConnections.connectionType')}
          label={t('code:automaticConnections.connectionType')}
          isRequired
          options={connectionTypeOptions}
          renderLabel={(option) => t(`code:automaticConnections.connectionTypeOptions.${option}`)}
          onChange={(newValue) => {
            if (newValue === connectionType) {
              return;
            }
            if (newValue !== 'COMPANY') {
              setValue(prefixWithPath('automaticConnections.companyConnection'), undefined);
            }
            if (newValue !== 'STAND') {
              setValue(prefixWithPath('automaticConnections.standConnection'), undefined);
            }
          }}
        />
      </HStack>
      {connectionType === 'COMPANY' && (
        <HStack alignItems="start">
          <ValueAsyncSelectFormControl<CompanyReferenceDto, CodeDto>
            name={prefixWithPath('automaticConnections.companyConnection.companyConnection')}
            label={t('code:automaticConnections.companyConnection')}
            isRequired
            optionIdentifier={(company) => company.id}
            loadOptions={companyLoadOptions}
            renderLabel={(option) => option.name}
          />

          <ValueSelectFormControl<AutomaticCompanyConnectionDtoTypeEnum, CodeDto>
            name={prefixWithPath('automaticConnections.companyConnection.type')}
            label={t('code:automaticConnections.type')}
            isRequired
            options={typeOptions}
            renderLabel={(option) => t(`code:automaticConnections.typeOptions.${option}`)}
          />
        </HStack>
      )}
      {connectionType === 'STAND' && (
        <HStack alignItems="start">
          <ValueAsyncSelectFormControl<StandReferenceDto>
            name={prefixWithPath('automaticConnections.standConnection.standConnection')}
            label={t('code:automaticConnections.standConnection')}
            isRequired
            optionIdentifier={(stand) => stand.id}
            loadOptions={standLoadOptions}
            renderLabel={(option) => `${option.name} ${option.standId}`}
          />

          <ValueSelectFormControl<StandPersonRelationTypeDto, CodeDto>
            name={prefixWithPath('automaticConnections.standConnection.type')}
            label={t('code:automaticConnections.type')}
            isRequired
            options={standTypeOptions}
            renderLabel={(option) => t(`stand:personRelation.relationTypeOptions.${option}`)}
          />
        </HStack>
      )}
    </>
  );
}
