import { Stack } from '@chakra-ui/react';
import React from 'react';
import { AwardDto, PersonReferenceDtoFromJSON } from '../../../api';
import Optional from '../../../ui/optional/optional';
import PersonReference from '../person-reference/person-reference';

interface PersonAwardReferenceProps {
  award: AwardDto;
}

export default function PersonAwardReference({ award }: PersonAwardReferenceProps) {
  const winners = award.extensions.winners;
  return (
    <Optional>
      {winners != null && winners.length > 0 && (
        <Stack spacing={0}>
          {winners.map((winner: any, index: number) => (
            <PersonReference
              key={index}
              personReference={PersonReferenceDtoFromJSON(winner)}
              hidePersonKey
              usePortalForCard
            />
          ))}
        </Stack>
      )}
    </Optional>
  );
}
