import { mutate } from 'swr';
import { RequestContext, ResponseContext } from '../api';
import { createRedirectToParam } from '../util/use-redirect-to/use-redirect-to';
import { logout, refreshToken } from './auth';

const AuthMiddleware = {
  async pre({ url, init }: RequestContext) {
    const authToken = await refreshToken();

    if (authToken !== false) {
      const headers = new Headers(init.headers);
      headers.set('Authorization', `Bearer ${authToken.accessToken}`);

      init = { ...init, headers };
    }

    return { url, init };
  },

  async post({ response }: ResponseContext) {
    if (response.status === 401) {
      logout();

      // Clear all cached data from SWR
      // noinspection ES6MissingAwait
      mutate(() => true, undefined, { revalidate: false });

      const redirect = [window.location.pathname, window.location.search, window.location.hash].join('');
      if (!redirect.startsWith('/login')) {
        window.location.href = `/login?${createRedirectToParam(redirect)}`;
      }
    }

    return response;
  },
};

export default AuthMiddleware;
