import { ListItem, Stack, Text, UnorderedList } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { keyPlayerListIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import EVENT_CHILD_ROUTES from '../event/event-child-routes';
import EVENT_CHILD_ROUTES_MOBILE from '../event/event-child-routes-mobile';
import { EVENT_COPY_INFO } from '../event/event-form/event-form';
import { EVENT_MENU_ITEM, EVENT_MOBILE_MENU_ITEM, EventMenuItem } from '../event/event-page/event-page-menu';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import KeyPlayerSearchResult from './key-player-search-result';
import keyPlayersListRoutes, { keyPlayersListRoutesMobile } from './key-players-list-routes';
import { useNotCopyableKeyPlayers } from './use-keyplayerlist/use-not-copyable-key-players';

function createKeyPlayerListEventMenuItem(isMobile = false): EventMenuItem {
  return {
    order: 4,
    label: <Translate ns="key_player_list" i18nKey="key_player_list" />,
    icon: keyPlayerListIcon,
    path: (eventId) => `${isMobile ? '/mobile' : ''}/events/${eventId}/key-player-list`,
  };
}

function KeyPlayerEventCopyInfo({ eventId }: { eventId: string }): JSX.Element | null {
  const notCopyableKeyPlayers = useNotCopyableKeyPlayers(eventId);
  const { t } = useTranslation(['key_player_list', 'person']);

  if (isEmpty(notCopyableKeyPlayers)) {
    return null;
  }

  return (
    <Stack>
      <Text>{t('key_player_list:no_copy_for', { count: notCopyableKeyPlayers.length })}</Text>
      <UnorderedList style={{ marginLeft: '16px' }}>
        {notCopyableKeyPlayers.map((kp) => (
          <ListItem>
            {kp.person.firstName + ' ' + kp.person.surname + ' (' + t(`person:statusLabel.${kp.person.status!}`) + ')'}
          </ListItem>
        ))}
      </UnorderedList>
    </Stack>
  );
}

export default function KeyPlayerPluginProvider(registry: PluginRegistry) {
  registry.register(EVENT_MENU_ITEM, createKeyPlayerListEventMenuItem());
  registry.register(EVENT_MOBILE_MENU_ITEM, createKeyPlayerListEventMenuItem(true));
  registry.register(EVENT_CHILD_ROUTES, keyPlayersListRoutes);
  registry.register(EVENT_CHILD_ROUTES_MOBILE, keyPlayersListRoutesMobile);
  registry.register(EVENT_COPY_INFO, KeyPlayerEventCopyInfo);
  registry.register(SEARCH_RESULT_CONTENT, { type: 'KEY_PLAYER', Component: KeyPlayerSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'KEY_PLAYER',
    icon: keyPlayerListIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.KEY_PLAYER" />,
  });
}
