import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import accountApi from '../../../data-access/account-api';
import useAsyncValidation from '../../../util/use-async-validation/use-async-validation';

export function useAccountEmailValidation(originalEmail?: string, canConvertBasic?: boolean) {
  const { t } = useTranslation(['common', 'account']);
  const navigate = useNavigate();
  const navigateToViewer = (entityId: string) => {
    navigate(`/accounts/${entityId}`);
  };

  const handleLinkClicked = (entityId: string) => {
    const message = t('account:editor.emailValidationConfirmation');
    if (window.confirm(message)) {
      navigateToViewer(entityId);
    }
  };

  return useAsyncValidation(async (fieldValue: string | null | undefined, { signal }) => {
    if (fieldValue == null) {
      return true;
    }
    fieldValue = fieldValue.trim();
    if (fieldValue.length === 0) {
      return true;
    }

    if (originalEmail != null && fieldValue === originalEmail) {
      return true;
    }

    const syncStatus = await accountApi.getSyncStatus({ emails: [fieldValue] }, { signal });

    if (syncStatus.length === 0) {
      return true;
    }

    if (syncStatus[0]?.status === 'MAILING_BLOCKLIST') {
      return t('account:editor.emailValidationBlocklist');
    }

    return (
      syncStatus[0]?.status === 'NO_ACCOUNT' ||
      (!!canConvertBasic && syncStatus[0]?.status === 'BASIC') ||
      ((
        <span>
          <Trans
            t={t}
            i18nKey="account:editor.emailValidation"
            values={fieldValue}
            components={{
              entityLink: (
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => handleLinkClicked(syncStatus[0]!.accountId!)}
                />
              ),
            }}
          />
        </span>
      ) as any)
    );
  });
}
