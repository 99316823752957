/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StandReferenceDto } from './StandReferenceDto';
import {
    StandReferenceDtoFromJSON,
    StandReferenceDtoFromJSONTyped,
    StandReferenceDtoToJSON,
} from './StandReferenceDto';

/**
 * 
 * @export
 * @interface StandReferencePageDto
 */
export interface StandReferencePageDto {
    /**
     * The number of the page.
     * @type {number}
     * @memberof StandReferencePageDto
     */
    page: number;
    /**
     * The size of the page.
     * @type {number}
     * @memberof StandReferencePageDto
     */
    size: number;
    /**
     * The total number of data returned by the search.
     * @type {number}
     * @memberof StandReferencePageDto
     */
    totalElements: number;
    /**
     * The total number of pages of current size to fit all data.
     * @type {number}
     * @memberof StandReferencePageDto
     */
    totalPages: number;
    /**
     * 
     * @type {Array<StandReferenceDto>}
     * @memberof StandReferencePageDto
     */
    content: Array<StandReferenceDto>;
}

/**
 * Check if a given object implements the StandReferencePageDto interface.
 */
export function instanceOfStandReferencePageDto(value: object): boolean {
    if (!('page' in value)) return false;
    if (!('size' in value)) return false;
    if (!('totalElements' in value)) return false;
    if (!('totalPages' in value)) return false;
    if (!('content' in value)) return false;
    return true;
}

export function StandReferencePageDtoFromJSON(json: any): StandReferencePageDto {
    return StandReferencePageDtoFromJSONTyped(json, false);
}

export function StandReferencePageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandReferencePageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'page': json['page'],
        'size': json['size'],
        'totalElements': json['totalElements'],
        'totalPages': json['totalPages'],
        'content': ((json['content'] as Array<any>).map(StandReferenceDtoFromJSON)),
    };
}

export function StandReferencePageDtoToJSON(value?: StandReferencePageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'page': value['page'],
        'size': value['size'],
        'totalElements': value['totalElements'],
        'totalPages': value['totalPages'],
        'content': ((value['content'] as Array<any>).map(StandReferenceDtoToJSON)),
    };
}

