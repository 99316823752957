/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LanguagesDto = {
    GERMAN: 'GERMAN',
    ENGLISH: 'ENGLISH'
} as const;
export type LanguagesDto = typeof LanguagesDto[keyof typeof LanguagesDto];


export function LanguagesDtoFromJSON(json: any): LanguagesDto {
    return LanguagesDtoFromJSONTyped(json, false);
}

export function LanguagesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LanguagesDto {
    return json as LanguagesDto;
}

export function LanguagesDtoToJSON(value?: LanguagesDto | null): any {
    return value as any;
}

