/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The result of a status check.
 * @export
 * @interface KeyPlayerStatusCheckDto
 */
export interface KeyPlayerStatusCheckDto {
    /**
     * 
     * @type {boolean}
     * @memberof KeyPlayerStatusCheckDto
     */
    readonly isPersonInRundown: boolean;
}

/**
 * Check if a given object implements the KeyPlayerStatusCheckDto interface.
 */
export function instanceOfKeyPlayerStatusCheckDto(value: object): boolean {
    if (!('isPersonInRundown' in value)) return false;
    return true;
}

export function KeyPlayerStatusCheckDtoFromJSON(json: any): KeyPlayerStatusCheckDto {
    return KeyPlayerStatusCheckDtoFromJSONTyped(json, false);
}

export function KeyPlayerStatusCheckDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KeyPlayerStatusCheckDto {
    if (json == null) {
        return json;
    }
    return {
        
        'isPersonInRundown': json['isPersonInRundown'],
    };
}

export function KeyPlayerStatusCheckDtoToJSON(value?: KeyPlayerStatusCheckDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
    };
}

