const styles = {
  global: {
    html: {
      height: '100%',
    },
    body: {
      color: 'text',
      bgColor: 'background',
    },
    'body, #root, [data-cy-root]': {
      height: '100%',
    },
    strong: {
      fontWeight: 600,
    },
    '*, *::before, &::after': {
      borderColor: 'border.01',
    },
    '.chakra-collapse': {
      overflow: 'initial !important',
    },
  },
};

export default styles;
