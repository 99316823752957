/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GroupDto } from './GroupDto';
import {
    GroupDtoFromJSON,
    GroupDtoFromJSONTyped,
    GroupDtoToJSON,
} from './GroupDto';
import type { StaffToAddDto } from './StaffToAddDto';
import {
    StaffToAddDtoFromJSON,
    StaffToAddDtoFromJSONTyped,
    StaffToAddDtoToJSON,
} from './StaffToAddDto';

/**
 * Dto to add multiple staff to a group.
 * @export
 * @interface AddStaffToGroupDto
 */
export interface AddStaffToGroupDto {
    /**
     * 
     * @type {Array<StaffToAddDto>}
     * @memberof AddStaffToGroupDto
     */
    staff: Array<StaffToAddDto>;
    /**
     * 
     * @type {GroupDto}
     * @memberof AddStaffToGroupDto
     */
    group: GroupDto;
}

/**
 * Check if a given object implements the AddStaffToGroupDto interface.
 */
export function instanceOfAddStaffToGroupDto(value: object): boolean {
    if (!('staff' in value)) return false;
    if (!('group' in value)) return false;
    return true;
}

export function AddStaffToGroupDtoFromJSON(json: any): AddStaffToGroupDto {
    return AddStaffToGroupDtoFromJSONTyped(json, false);
}

export function AddStaffToGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddStaffToGroupDto {
    if (json == null) {
        return json;
    }
    return {
        
        'staff': ((json['staff'] as Array<any>).map(StaffToAddDtoFromJSON)),
        'group': GroupDtoFromJSON(json['group']),
    };
}

export function AddStaffToGroupDtoToJSON(value?: AddStaffToGroupDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'staff': ((value['staff'] as Array<any>).map(StaffToAddDtoToJSON)),
        'group': GroupDtoToJSON(value['group']),
    };
}

