import { ValidateResult } from 'react-hook-form';
import { PluginToken } from '../../../util/plugin/use-plugins';

export interface GroupCapacityValidationExtension {
  (capacity: number, groupId: string): Promise<ValidateResult>;
}

export const GROUP_CAPACITY_VALIDATION_EXTENSION = new PluginToken<GroupCapacityValidationExtension>(
  'GroupCapacityValidationExtension',
);
