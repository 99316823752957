import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyFilmRelationListItemDto } from '../../../../api';
import HistoryDisplaySettings from '../../../../ui/history/history-display-settings';

export default function useCompanyFilmHistorySettings(): HistoryDisplaySettings<CompanyFilmRelationListItemDto> {
  const { t } = useTranslation(['company', 'film', 'common']);

  return useMemo<HistoryDisplaySettings<CompanyFilmRelationListItemDto>>(() => {
    return {
      attributeLabels: {
        'film.title': t('film:title'),
        role: t('company:relation.film.role'),
      },
      valueFormatter: {
        role: (role) => role && t(`company:relation.film.roleOptions.${role}`),
      },
      name: () => t('company:relation.film.label'),
      showEditDescription: false,
    };
  }, [t]);
}
