/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * Comment of a company
 * @export
 * @interface CompanyCommentDto
 */
export interface CompanyCommentDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof CompanyCommentDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof CompanyCommentDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof CompanyCommentDto
     */
    text: string;
}

/**
 * Check if a given object implements the CompanyCommentDto interface.
 */
export function instanceOfCompanyCommentDto(value: object): boolean {
    if (!('text' in value)) return false;
    return true;
}

export function CompanyCommentDtoFromJSON(json: any): CompanyCommentDto {
    return CompanyCommentDtoFromJSONTyped(json, false);
}

export function CompanyCommentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyCommentDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'text': json['text'],
    };
}

export function CompanyCommentDtoToJSON(value?: CompanyCommentDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'text': value['text'],
    };
}

