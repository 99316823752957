/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClassLevelDto } from './ClassLevelDto';
import {
    ClassLevelDtoFromJSON,
    ClassLevelDtoFromJSONTyped,
    ClassLevelDtoToJSON,
} from './ClassLevelDto';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';

/**
 * 
 * @export
 * @interface AdminRoleForClassGroupListItemDto
 */
export interface AdminRoleForClassGroupListItemDto {
    /**
     * 
     * @type {SectionReferenceDto}
     * @memberof AdminRoleForClassGroupListItemDto
     */
    section: SectionReferenceDto;
    /**
     * 
     * @type {ClassLevelDto}
     * @memberof AdminRoleForClassGroupListItemDto
     */
    level: ClassLevelDto;
}

/**
 * Check if a given object implements the AdminRoleForClassGroupListItemDto interface.
 */
export function instanceOfAdminRoleForClassGroupListItemDto(value: object): boolean {
    if (!('section' in value)) return false;
    if (!('level' in value)) return false;
    return true;
}

export function AdminRoleForClassGroupListItemDtoFromJSON(json: any): AdminRoleForClassGroupListItemDto {
    return AdminRoleForClassGroupListItemDtoFromJSONTyped(json, false);
}

export function AdminRoleForClassGroupListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminRoleForClassGroupListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'section': SectionReferenceDtoFromJSON(json['section']),
        'level': ClassLevelDtoFromJSON(json['level']),
    };
}

export function AdminRoleForClassGroupListItemDtoToJSON(value?: AdminRoleForClassGroupListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'section': SectionReferenceDtoToJSON(value['section']),
        'level': ClassLevelDtoToJSON(value['level']),
    };
}

