import { Grid, GridItem, Spacer, Stack, Text, useBreakpoint, useTheme } from '@chakra-ui/react';
import {
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faCircleQuestion,
  faUserCrown,
} from '@fortawesome/pro-regular-svg-icons';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '../../../ui/page/logo';
import { GlobalSearch } from '../../search/global-search';
import useStaffAccount from '../../staff-account/use-account/use-staff-account';
import usePermission from '../../staff-account/use-permission/use-permission';
import ToggleColorModeButton from '../toggle-color-mode-button';
import AppNavigationButton from './app-navigation-button';
import AppNavigationMenu from './app-navigation-menu';
import AppNavigationSubMenu from './app-navigation-sub-menu';
import AppNavigationSubMenuItem from './app-navigation-sub-menu-item';
import InfoMenuItem from './info-menu-item';
import logoBerlinale from './logo_berlinale.svg';
import MenuItem from './menu-item';
import SubMenuItem from './sub-menu-item';

interface AppNavigationProps {
  mainMenuItems: MenuItem[];
  accountMenuItems: SubMenuItem[];
}

const AppNavigation = memo(function AppNavigation({ mainMenuItems, accountMenuItems }: AppNavigationProps) {
  const { t } = useTranslation('app');
  const [isCollapsed, toggleIsCollapsed] = useNavigationCollapsed();

  const { email, displayName } = useStaffAccount();
  const accountInfoItem: InfoMenuItem = {
    name: <Text textAlign="left">{t('account_navigation.logged_in_user', { user: displayName ?? email })}</Text>,
    icon: faUserCrown,
  };

  const helperSystemItem: SubMenuItem = {
    name: <Text as="span">{t('help.label')}</Text>,
    path: 'help',
    icon: faCircleQuestion,
  };

  const canUseSearch = usePermission('SEARCH:EXTENDED-READ');

  return (
    <Grid
      gridTemplateRows="auto 1fr auto"
      w={isCollapsed ? 'auto' : 'xs'}
      maxWidth="xs"
      h="100vh"
      top={0}
      color="gray.50"
      bgImage={logoBerlinale}
      bgSize="auto 110%"
      bgPosition={isCollapsed ? '59% 70%' : '88% 70%'}
      borderColor="whiteAlpha.200"
      borderRightWidth="1px"
      sx={{ bgColor: 'gray.900', _dark: { bgColor: 'gray.800' } }}
      style={{ colorScheme: 'dark' }}
    >
      <GridItem pt={6} pb={2} mx={isCollapsed ? 0 : 6} borderBottom="1px solid" borderColor="inherit" minH={18}>
        <Stack direction={isCollapsed ? 'column' : 'row'} alignItems="center" spacing={0} mr={isCollapsed ? 0 : -3}>
          <Logo isCollapsed={isCollapsed} />
          <Spacer />
          <ToggleColorModeButton mt={isCollapsed ? 3 : 0} />
          {canUseSearch && <GlobalSearch />}
        </Stack>
      </GridItem>
      <GridItem py={6} flexGrow={1} overflowY="auto" minH={0}>
        <AppNavigationMenu items={mainMenuItems} isCollapsed={isCollapsed} />
      </GridItem>
      <GridItem pt={3} pb={6} borderTop="1px solid" borderColor="inherit">
        <AppNavigationToggleButton onToggle={toggleIsCollapsed} isCollapsed={isCollapsed} />
        <AppNavigationSubMenuItem item={helperSystemItem} isCollapsed={isCollapsed} />
        <AppNavigationInfoButton item={accountInfoItem} isCollapsed={isCollapsed} />
        <AppNavigationSubMenu items={accountMenuItems} isCollapsed={isCollapsed} />
      </GridItem>
    </Grid>
  );
});

export default AppNavigation;

interface AppNavigationToggleButtonProps {
  onToggle(): void;

  isCollapsed: boolean;
}

function AppNavigationToggleButton({ onToggle, isCollapsed }: AppNavigationToggleButtonProps) {
  const { t } = useTranslation('app');

  return (
    <AppNavigationButton
      onClick={onToggle}
      isCollapsed={isCollapsed}
      icon={isCollapsed ? faChevronDoubleRight : faChevronDoubleLeft}
    >
      {isCollapsed ? t('navigation.expand') : t('navigation.collapse')}
    </AppNavigationButton>
  );
}

function useNavigationCollapsed() {
  const theme = useTheme();
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  // lh: Once the navigation was manually collapsed, we do not want toggle the collapsed state via
  // breakpoints anymore.
  const [manuallyCollapsed, setManuallyCollapsed] = React.useState(false);
  const breakpoint = Mockables.useBreakpoint();
  const shouldCollapse = React.useMemo(() => {
    const collapseBreakpoint = 'md';
    const breakpointKeys = Array.from(theme.__breakpoints?.keys ?? []);

    return breakpointKeys.indexOf(breakpoint ?? 'base') <= breakpointKeys.indexOf(collapseBreakpoint);
  }, [theme, breakpoint]);

  React.useLayoutEffect(() => {
    if (!manuallyCollapsed) {
      setIsCollapsed(shouldCollapse);
    }
  }, [shouldCollapse, manuallyCollapsed]);

  const toggle = React.useCallback(() => {
    const nextIsCollapsed = !isCollapsed;

    setIsCollapsed(nextIsCollapsed);
    setManuallyCollapsed(nextIsCollapsed);
  }, [isCollapsed]);

  return [isCollapsed, toggle] as const;
}

interface AppNavigationInfoButtonProps {
  item: InfoMenuItem;
  isCollapsed: boolean;
}

function AppNavigationInfoButton({ item, isCollapsed }: AppNavigationInfoButtonProps) {
  return (
    <AppNavigationButton isCollapsed={isCollapsed} icon={item.icon}>
      {item.name}
    </AppNavigationButton>
  );
}

export const Mockables = {
  useBreakpoint,
};
