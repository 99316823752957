import React from 'react';
import MailingTypeExtension from '../mailing-type-extension';
import StandardInfoMailingRecipientEditor from './standard-info-mailing-recipient-editor';
import StandardInfoMailingRecipientLister from './standard-info-mailing-recipient-lister';

export function createStandardInfoMailing(): MailingTypeExtension {
  return {
    type: 'STANDARD_INFO',
    recipientEditorAddon(props: { connectionId?: string; mailingId: string }): React.ReactNode {
      return <StandardInfoMailingRecipientEditor {...props} />;
    },
    recipientListerAddon(props: { connectionId?: string; mailingId: string }): React.ReactNode {
      return <StandardInfoMailingRecipientLister {...props} />;
    },
  };
}
