import { Button, HStack, Spacer, Stack, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { PersonDto, PersonPersonConnectionDto, PersonReferenceDto, PersonStatusDto } from '../../../../api';
import DateInputFormControl from '../../../../ui/form/date-input-control/date-input-form-control';
import { ElementFormModal, ElementTableControl, useElementForm } from '../../../../ui/form/element-control';
import AddElementButton from '../../../../ui/form/element-control/add-element-button';
import DeleteElementButton from '../../../../ui/form/element-control/delete-element-button';
import EditElementButton from '../../../../ui/form/element-control/edit-element-button';
import ElementContext from '../../../../ui/form/element-control/element-context';
import useDefinedContext from '../../../../util/context/use-defined-context/use-defined-context';
import now from '../../../../util/now';
import { LayoutType } from '../../../common/LayoutType';
import useActiveEdition from '../../../edition/use-active-edition/use-active-edition';
import personPersonConnectionColumns from '../../person-table-columns/person-person-connection-columns';
import CancelConnectionButton from './cancel-connection-button';
import ConnectionControl from './connection-control';

interface NewPersonPersonConnection extends PersonPersonConnectionDto {
  isNew?: boolean;
}

export interface PersonPersonConnectionControlProps {
  layout: LayoutType;
  showAddButton?: boolean;
}

export default function PersonPersonConnectionControl({
  layout,
  showAddButton = true,
}: PersonPersonConnectionControlProps) {
  const { t } = useTranslation('person');
  const [currentPersonId, surname, firstName, personKey, status] = useWatch<PersonDto>({
    name: ['id', 'surname', 'firstName', 'personKey', 'status'],
  });
  const fromPersonReference: PersonReferenceDto = {
    id: String(currentPersonId),
    personKey: String(personKey),
    firstName: String(firstName),
    surname: String(surname),
  };

  return (
    <ElementTableControl<PersonDto, PersonPersonConnectionDto>
      label={t('connections.persons')}
      name="personPersonConnections"
      columns={personPersonConnectionColumns}
      addButton={
        status !== PersonStatusDto.ACTIVE || !showAddButton ? undefined : (
          <AddElementButton
            label={t('connections.add')}
            formModal={<PersonPersonConnectionFormModal fromPersonReference={fromPersonReference} />}
          />
        )
      }
      editButton={
        <EditElementButton
          label={t('connections.edit')}
          formModal={<PersonPersonConnectionFormModal fromPersonReference={fromPersonReference} isEdit />}
        />
      }
      deleteButton={<EndOrDeleteConnectionButton fromPersonReference={fromPersonReference} />}
      layout={layout}
    />
  );
}

interface DeleteConnectionButtonProps {
  fromPersonReference: PersonReferenceDto;
}

function EndOrDeleteConnectionButton({ fromPersonReference }: DeleteConnectionButtonProps) {
  const { t } = useTranslation('person');
  const { element } = useDefinedContext(ElementContext);

  // jaj, lh: Show delete button for unsaved connections and the cancel dialog for saved ones.
  return (element as NewPersonPersonConnection).isNew ? (
    <DeleteElementButton<PersonPersonConnectionDto>
      label={t('connections.delete_connection')}
      renderDeleteMessage={(element) => (
        <Trans
          t={t}
          i18nKey="connections.delete_message"
          values={{ connectedName: element.connectedPerson.firstName + ' ' + element.connectedPerson.surname }}
        />
      )}
    />
  ) : (
    <CancelConnectionButton label={t('connections.end_connection')} fromPersonReference={fromPersonReference} />
  );
}

interface PersonPersonConnectionFormModalProps {
  fromPersonReference: PersonReferenceDto;
  isEdit?: boolean;
}

function PersonPersonConnectionFormModal({ fromPersonReference, isEdit }: PersonPersonConnectionFormModalProps) {
  const { t } = useTranslation('person');
  const { t: tCommon } = useTranslation('common');
  const { element: personPersonConnection, onSubmit } = useElementForm<PersonPersonConnectionDto>();
  const initialFocusRef = React.useRef<HTMLSelectElement>(null);
  const { setValue, register } = useFormContext<NewPersonPersonConnection>();
  register('timestamp');
  const activeEdition = useActiveEdition();

  const prefillToday = () =>
    setValue(
      'dateRange',
      { start: new Date(now()), end: undefined },
      {
        shouldValidate: true,
        shouldDirty: true,
      },
    );
  const prefillActiveEdition = () =>
    setValue('dateRange', activeEdition.dateRange, { shouldValidate: true, shouldDirty: true });
  const start = useWatch<PersonPersonConnectionDto>({ name: 'dateRange.start' }) as Date;
  const isNew = personPersonConnection == null || (personPersonConnection as NewPersonPersonConnection).isNew;

  const handleSubmit = (personPersonConnection: PersonPersonConnectionDto) => {
    // jaj, lh: Add flag to be able to show different delete dialogs for unsaved connections.
    onSubmit({ ...personPersonConnection, isNew } as NewPersonPersonConnection);
  };

  return (
    <ElementFormModal<PersonPersonConnectionDto>
      onSubmit={handleSubmit}
      element={personPersonConnection}
      initialFocusRef={initialFocusRef}
      defaultElement={{ dateRange: { start: new Date(now()) }, timestamp: new Date(now()) }}
    >
      <ConnectionControl fromReference={fromPersonReference} ref={initialFocusRef} toReferenceEditable={!isEdit} />
      <Stack
        spacing={4}
        sx={{
          marginTop: 4,
          borderWidth: 1,
          borderRadius: 4,
          padding: 4,
          borderColor: 'border.01',
        }}
      >
        <HStack spacing={2}>
          <span>{tCommon('prefill')}: </span> <Spacer />
          <Tooltip label={t('connections.as_of_today_tooltip')}>
            <Button size="sm" onClick={prefillToday}>
              {t('connections.as_of_today')}
            </Button>
          </Tooltip>
          <Tooltip label={t('connections.active_edition_tooltip')}>
            <Button size="sm" onClick={prefillActiveEdition}>
              {t('connections.active_edition')}
            </Button>
          </Tooltip>
        </HStack>
        <HStack alignItems="start">
          <DateInputFormControl<PersonPersonConnectionDto>
            name="dateRange.start"
            showYearDropdown
            isRequired
            deps={['dateRange.end']}
            label={t('connections.date_range_start')}
          />
          <DateInputFormControl<PersonPersonConnectionDto>
            name="dateRange.end"
            showYearDropdown
            deps={['dateRange.start']}
            label={t('connections.date_range_end')}
            referenceDate={start}
            min={{
              value: start,
              message: tCommon('validation_error.date_end_before_date_start'),
            }}
          />
        </HStack>
      </Stack>
    </ElementFormModal>
  );
}
