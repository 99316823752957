import React from 'react';
import MailingTypeExtension from '../../mailing/mailing-type-extension';
import CodeMailingRecipientEditor from './code-mailing-recipient-editor';
import CodeMailingRecipientLister from './code-mailing-recipient-lister';

export function createCodeMailing(): MailingTypeExtension {
  return {
    type: 'CODE',
    recipientEditorAddon(props: { connectionId?: string; mailingId: string }): React.ReactNode {
      return <CodeMailingRecipientEditor {...props} />;
    },
    recipientListerAddon(props: { connectionId?: string; mailingId: string }): React.ReactNode {
      return <CodeMailingRecipientLister {...props} />;
    },
  };
}
