import { forwardRef, HTMLChakraProps, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { GroupStaffRelationDto } from '../../../../../api';
import groupStaffApi from '../../../../../data-access/group-staff-api';
import { DisableableButton } from '../../../../../ui/disableable-button/disableable-button';
import useToast from '../../../../../ui/use-toast/use-toast';
import GroupStaffAdminToggleDialog from './group-staff-admin-toggle-dialog';

interface GroupStaffAdminToggleButtonProps extends HTMLChakraProps<'button'> {
  groupStaffRelation: GroupStaffRelationDto;
  lastAdminWithStaff: boolean;
  maxAdminCountReached: boolean;

  onSuccess?(): void;
}

function GroupStaffAdminToggleButton(
  {
    groupStaffRelation,
    lastAdminWithStaff,
    maxAdminCountReached,
    onSuccess,
    children,
    ...props
  }: GroupStaffAdminToggleButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation('group');

  const isAdmin = groupStaffRelation.groupAdmin;

  const showSuccessToast = useToast({
    id: 'group-staff-admin-toggle-success-toast',
    status: 'success',
  });

  const handleToggle = async () => {
    invariant(groupStaffRelation.id != null, 'Missing group staff id');

    let groupStaffRelationToUpdate = { ...groupStaffRelation, groupAdmin: true };
    if (isAdmin) {
      groupStaffRelationToUpdate = { ...groupStaffRelation, groupAdmin: false };
    }

    await groupStaffApi.updateGroupStaffRelation({
      id: groupStaffRelation.id,
      groupStaffRelationDto: groupStaffRelationToUpdate,
    });

    showSuccessToast({
      title: isAdmin ? t('internalGroupAdmins.toast.remove.header') : t('internalGroupAdmins.toast.add.header'),
      description: isAdmin
        ? t('internalGroupAdmins.toast.remove.message', {
            name: `${groupStaffRelation.staff.firstName} ${groupStaffRelation.staff.surname}`,
          })
        : t('internalGroupAdmins.toast.add.message', {
            name: `${groupStaffRelation.staff.firstName} ${groupStaffRelation.staff.surname}`,
          }),
    });

    onSuccess?.();
    onClose();
  };
  return (
    <>
      <DisableableButton
        ref={ref}
        {...props}
        aria-label={isAdmin ? t('internalGroupAdmins.actions.remove') : t('internalGroupAdmins.actions.add')}
        onClick={onOpen}
        isDisabled={(isAdmin && lastAdminWithStaff) || (!isAdmin && maxAdminCountReached)}
        disableReason={
          maxAdminCountReached
            ? t('internalGroupAdmins.disableReasons.maxAdminCount')
            : t('internalGroupAdmins.disableReasons.remove')
        }
      >
        {children}
      </DisableableButton>
      <GroupStaffAdminToggleDialog
        groupStaffRelation={groupStaffRelation}
        isAdmin={isAdmin}
        isOpen={isOpen}
        onClose={onClose}
        onToggle={handleToggle}
      />
    </>
  );
}

export default forwardRef<GroupStaffAdminToggleButtonProps, 'button'>(GroupStaffAdminToggleButton);
