import { Box, Stack, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import StringDiff from '../diff/string-diff';
import Optional from '../optional/optional';
import Pre from '../pre/pre';
import { ElementType } from './element-types';
import { SubElement } from './sub-element';
import { SubElements } from './sub-elements';
import { getEqualizeItemProps } from './use-size-equalizer';
import { SubElementHistoryDisplaySettings } from './history-display-settings';

export interface SubElementEntryProps {
  subElementsArray: SubElements[];
  subElementsArrayRef: SubElements[];
  type: ElementType;
  settings?: SubElementHistoryDisplaySettings<any>;
}

// lh: This component is in dire need of refactoring. Best practice here probably: add tests with
// each bug and with sufficient test coverage, clean up this component and its logic.
export default function SubElementEntry({
  subElementsArray,
  subElementsArrayRef,
  type,
  settings,
}: SubElementEntryProps) {
  const { t } = useTranslation('common');

  return (
    <>
      {isEmpty(subElementsArray) ? (
        <Box h="100%" alignItems="center" display="flex">
          <Text color="text.muted">{type === ElementType.BEFORE ? t('history.added') : t('history.removed')}</Text>
        </Box>
      ) : (
        <Stack spacing={2} h="full">
          {subElementsArray.map((subElements, mainIndex) => {
            const diff =
              subElementsArrayRef[mainIndex]?.elements.every((e) => e.value === '') ?? true
                ? type === ElementType.BEFORE
                  ? 'delete'
                  : 'insert'
                : null;

            return subElements.elements.every((e) => e.value === '') ? (
              <Box display="flex" alignItems="center" key={mainIndex} {...getEqualizeItemProps(mainIndex)}>
                <Text color="text.muted">
                  {type === ElementType.BEFORE ? t('history.added') : t('history.removed')}
                </Text>
              </Box>
            ) : (
              <Box key={mainIndex} data-testid={diff ?? undefined} {...getEqualizeItemProps(mainIndex)}>
                {isEmpty(subElements.elements) && <Optional isEmpty={true} />}
                {!isEmpty(subElements.elements) && (
                  <Table
                    variant="unstyled"
                    sx={{
                      px: 1,
                      borderRadius: 'sm',
                      borderWidth: diff == null ? 1 : undefined,
                      td: {
                        paddingY: 1,
                        paddingInline: 1,
                      },
                      color: diff === 'insert' ? 'text.insert' : diff === 'delete' ? 'text.delete' : undefined,
                      bg:
                        diff === 'insert' ? 'background.insert' : diff === 'delete' ? 'background.delete' : 'layer.01',
                    }}
                  >
                    <Tbody>
                      {subElements.elements.map((subElement, index) => {
                        let element =
                          diff != null ? (
                            subElement.value
                          ) : type === ElementType.BEFORE ? (
                            <StringDiff
                              to={getElementString(subElementsArrayRef, subElement, mainIndex)}
                              diffEntireWord={settings?.diffEntireWord?.[subElement.attribute]}
                            >
                              {subElement.value}
                            </StringDiff>
                          ) : (
                            <StringDiff
                              from={getElementString(subElementsArrayRef, subElement, mainIndex)}
                              diffEntireWord={settings?.diffEntireWord?.[subElement.attribute]}
                            >
                              {subElement.value}
                            </StringDiff>
                          );

                        if (settings?.preformatted?.[subElement.attribute]) {
                          element = (
                            <Pre fontSize="xs" width="full">
                              {element}
                            </Pre>
                          );
                        }

                        return (
                          <Tr wordBreak="normal" overflowWrap="break-word" key={index}>
                            <Td>
                              <Text as="span" padding={0} fontWeight="medium">
                                {subElement.key}:
                              </Text>
                            </Td>
                            <Td alignItems="center" display="flex">
                              {element}
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                )}
              </Box>
            );
          })}
        </Stack>
      )}
    </>
  );
}

function getElementString(subElementsArrayRef: SubElements[], subElement: SubElement<any>, mainIndex: number) {
  if (subElementsArrayRef.length <= mainIndex) {
    return '';
  }
  const element = subElementsArrayRef[mainIndex].elements.findLast((element) => element.key === subElement.key);
  return element?.value ?? '';
}
