/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EventSecurityStaffDto
 */
export interface EventSecurityStaffDto {
    /**
     * 
     * @type {string}
     * @memberof EventSecurityStaffDto
     */
    type: EventSecurityStaffDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof EventSecurityStaffDto
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof EventSecurityStaffDto
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof EventSecurityStaffDto
     */
    timestamp?: Date;
}


/**
 * @export
 */
export const EventSecurityStaffDtoTypeEnum = {
    NATIONAL_AUTHORITY: 'NATIONAL_AUTHORITY',
    FOREIGN_AUTHORITY: 'FOREIGN_AUTHORITY',
    IFB: 'IFB',
    PRIVATE: 'PRIVATE'
} as const;
export type EventSecurityStaffDtoTypeEnum = typeof EventSecurityStaffDtoTypeEnum[keyof typeof EventSecurityStaffDtoTypeEnum];


/**
 * Check if a given object implements the EventSecurityStaffDto interface.
 */
export function instanceOfEventSecurityStaffDto(value: object): boolean {
    if (!('type' in value)) return false;
    if (!('count' in value)) return false;
    return true;
}

export function EventSecurityStaffDtoFromJSON(json: any): EventSecurityStaffDto {
    return EventSecurityStaffDtoFromJSONTyped(json, false);
}

export function EventSecurityStaffDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventSecurityStaffDto {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'count': json['count'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
    };
}

export function EventSecurityStaffDtoToJSON(value?: EventSecurityStaffDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'count': value['count'],
        'comment': value['comment'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
    };
}

