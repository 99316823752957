/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EventTagPermissionDto
 */
export interface EventTagPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof EventTagPermissionDto
     */
    name: EventTagPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof EventTagPermissionDto
     */
    action: EventTagPermissionDtoActionEnum;
}


/**
 * @export
 */
export const EventTagPermissionDtoNameEnum = {
    EVENT_TAG: 'EVENT-TAG'
} as const;
export type EventTagPermissionDtoNameEnum = typeof EventTagPermissionDtoNameEnum[keyof typeof EventTagPermissionDtoNameEnum];

/**
 * @export
 */
export const EventTagPermissionDtoActionEnum = {
    LINK: 'CAN_LINK',
    UNLINK: 'CAN_UNLINK'
} as const;
export type EventTagPermissionDtoActionEnum = typeof EventTagPermissionDtoActionEnum[keyof typeof EventTagPermissionDtoActionEnum];


/**
 * Check if a given object implements the EventTagPermissionDto interface.
 */
export function instanceOfEventTagPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function EventTagPermissionDtoFromJSON(json: any): EventTagPermissionDto {
    return EventTagPermissionDtoFromJSONTyped(json, false);
}

export function EventTagPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventTagPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function EventTagPermissionDtoToJSON(value?: EventTagPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

