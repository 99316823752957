import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { BreadcrumbRouteObject } from '../../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../../ui/helmet/helmet-outlet';
import LocationPage from '../../location/location-page/location-page';
import useLocation from '../../location/use-location/use-location';

const AppointmentViewer = React.lazy(() => import('./location-appointment-viewer'));

const locationAppointmentRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: '',
    element: <LocationPage />,
    children: [
      {
        path: 'appointments',
        element: <AppointmentViewer />,
        handle: {
          helmet: <LocationAppointmentHelmet />,
        },
      },
    ],
  },
];

export default locationAppointmentRoutes;

function LocationAppointmentHelmet() {
  const { t } = useTranslation('location');
  const { locationId } = useParams<{ locationId: string }>();
  invariant(locationId != null, 'Missing location id');
  const { name, abbreviation } = useLocation(locationId);

  return <Helmet title={t('appointments.helmet', { location: name, abbreviation })} />;
}
