import { Box, IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import { faArrowToTop, faCrown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CategoryOfParticipationDto, GuestTypeDto } from '../../../api';
import ConfirmDialog from '../../../ui/dialog/confirm-dialog';
import Translate from '../../../util/translate/translate';
import { SelectedGuestViewModel } from '../person-on-guest-list-selection-viewer/person-on-guest-list-selection-viewer';

interface MainPersonInCouplingControlProps {
  guest: SelectedGuestViewModel;
  mainPersonId: string | undefined;
  onChangeMainPerson: (mainPersonId: string | undefined) => void;
}

export default function MainPersonInCouplingControl({
  guest,
  mainPersonId,
  onChangeMainPerson,
}: MainPersonInCouplingControlProps) {
  if (guest.guestType !== GuestTypeDto.PERSON || guest.category === CategoryOfParticipationDto.BODYGUARD) {
    return <></>;
  }

  if (guest.id === mainPersonId) {
    return (
      <Tooltip label={<Translate ns="guest_list" i18nKey="coupling.main_person.tooltip" />}>
        <Box as={FontAwesomeIcon} fontSize="sm" minWidth={8} icon={faCrown} color="text.interactive" />
      </Tooltip>
    );
  }

  return <MakeMainPersonInCouplingButton guest={guest} onChangeMainPerson={onChangeMainPerson} />;
}

interface MakeMainPersonInCouplingButtonProps {
  guest: SelectedGuestViewModel;
  onChangeMainPerson: (mainPersonId: string | undefined) => void;
}

function MakeMainPersonInCouplingButton({ guest, onChangeMainPerson }: MakeMainPersonInCouplingButtonProps) {
  const { t } = useTranslation('guest_list');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onConfirm = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        onChangeMainPerson(guest.id);
      }
      onClose();
    },
    [guest, onChangeMainPerson, onClose],
  );

  return (
    <>
      <IconButton
        size="sm"
        variant="ghost"
        aria-label={t('coupling.main_person.action.title')}
        onClick={onOpen}
        icon={<FontAwesomeIcon icon={faArrowToTop} />}
      />
      <MakeMainPersonInCouplingDialog guest={guest} isOpen={isOpen} onClose={onConfirm} />
    </>
  );
}

interface MakeMainPersonInCouplingDialogProps {
  guest: SelectedGuestViewModel;
  isOpen: boolean;
  onClose: (confirm: boolean) => void;
}

function MakeMainPersonInCouplingDialog({ guest, isOpen, onClose }: MakeMainPersonInCouplingDialogProps) {
  const { t } = useTranslation('guest_list');

  return (
    <ConfirmDialog
      title={t('coupling.main_person.action.title')}
      isOpen={isOpen}
      onClose={onClose}
      confirmActionLabel={<Trans t={t} i18nKey="coupling.main_person.action.confirm" />}
    >
      <p>
        <Trans
          t={t}
          i18nKey="coupling.main_person.action.description"
          values={{
            person: guest.person?.firstName + ' ' + guest.person?.surname,
          }}
        />
      </p>
    </ConfirmDialog>
  );
}
