import useSWR from 'swr';
import guestListApi from '../../../data-access/guest-list-api';

export function useGuestListSettingsByEventId(eventId: string) {
  const { data } = useSWR(['guest-list-settings-by-event-id', eventId], () => fetchGuestList(eventId), {
    suspense: true,
  });

  return data;
}

export async function fetchGuestList(eventId: string) {
  const response = await guestListApi.fetchGuestListSettingsForEventRaw({ eventId });

  // vb: 204 = no content = no guest list.
  if (response.raw.status === 204) {
    return null;
  }

  return response.value();
}
