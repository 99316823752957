/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StaffEmailAddressDto
 */
export interface StaffEmailAddressDto {
    /**
     * An email address
     * @type {string}
     * @memberof StaffEmailAddressDto
     */
    email: string;
    /**
     * Can the information be published?
     * @type {boolean}
     * @memberof StaffEmailAddressDto
     */
    publish?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof StaffEmailAddressDto
     */
    timestamp?: Date;
    /**
     * The label for the phone number
     * @type {string}
     * @memberof StaffEmailAddressDto
     */
    label: StaffEmailAddressDtoLabelEnum;
    /**
     * Are the information confidential?
     * @type {boolean}
     * @memberof StaffEmailAddressDto
     */
    confidential?: boolean;
}


/**
 * @export
 */
export const StaffEmailAddressDtoLabelEnum = {
    EMPLOYEE: 'EMPLOYEE'
} as const;
export type StaffEmailAddressDtoLabelEnum = typeof StaffEmailAddressDtoLabelEnum[keyof typeof StaffEmailAddressDtoLabelEnum];


/**
 * Check if a given object implements the StaffEmailAddressDto interface.
 */
export function instanceOfStaffEmailAddressDto(value: object): boolean {
    if (!('email' in value)) return false;
    if (!('label' in value)) return false;
    return true;
}

export function StaffEmailAddressDtoFromJSON(json: any): StaffEmailAddressDto {
    return StaffEmailAddressDtoFromJSONTyped(json, false);
}

export function StaffEmailAddressDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffEmailAddressDto {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'publish': json['publish'] == null ? undefined : json['publish'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
        'label': json['label'],
        'confidential': json['confidential'] == null ? undefined : json['confidential'],
    };
}

export function StaffEmailAddressDtoToJSON(value?: StaffEmailAddressDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'publish': value['publish'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
        'label': value['label'],
        'confidential': value['confidential'],
    };
}

