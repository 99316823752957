import { TFunction } from 'i18next';
import { FileMetadataDto } from '../../../api';
import { FileSuffixes, FileType } from './file-type';

export interface PhotoDimension {
  width: number;
  height: number;
}

export function validatePhotoDimension(
  file: FileMetadataDto,
  t: TFunction<'attachment'>,
  maxFileSizeInMB: number,
  acceptFileTypes: FileType[],
  minDimension?: PhotoDimension,
  maxDimension?: PhotoDimension,
): string | undefined {
  if (file.mimeType.startsWith('image')) {
    if (!(file.height || file.width)) {
      return t('validation_error.photo_no_dimensions', {
        size_mb: maxFileSizeInMB,
        acceptedFileTypes: acceptFileTypes.flatMap((type) => FileSuffixes[type]),
      });
    }
    if (minDimension != null && ((file.height ?? 0) < minDimension.height || (file.width ?? 0) < minDimension.width)) {
      return t('validation_error.photo_dimensions_small', {
        minWidth: minDimension.width,
        minHeight: minDimension.height,
      });
    }
    if (maxDimension != null && ((file.height ?? 0) > maxDimension.height || (file.width ?? 0) > maxDimension.width)) {
      return t('validation_error.photo_dimensions_large', {
        maxWidth: maxDimension.width,
        maxHeight: maxDimension.height,
      });
    }
  }
  return undefined;
}
