/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LandingPageModuleSettingsDto } from './LandingPageModuleSettingsDto';
import {
    LandingPageModuleSettingsDtoFromJSON,
    LandingPageModuleSettingsDtoFromJSONTyped,
    LandingPageModuleSettingsDtoToJSON,
} from './LandingPageModuleSettingsDto';

/**
 * 
 * @export
 * @interface LandingPageModuleConstraintDto
 */
export interface LandingPageModuleConstraintDto {
    /**
     * 
     * @type {number}
     * @memberof LandingPageModuleConstraintDto
     */
    minAmount: number;
    /**
     * 
     * @type {number}
     * @memberof LandingPageModuleConstraintDto
     */
    maxAmount?: number;
    /**
     * 
     * @type {LandingPageModuleSettingsDto}
     * @memberof LandingPageModuleConstraintDto
     */
    defaultSettings: LandingPageModuleSettingsDto;
}

/**
 * Check if a given object implements the LandingPageModuleConstraintDto interface.
 */
export function instanceOfLandingPageModuleConstraintDto(value: object): boolean {
    if (!('minAmount' in value)) return false;
    if (!('defaultSettings' in value)) return false;
    return true;
}

export function LandingPageModuleConstraintDtoFromJSON(json: any): LandingPageModuleConstraintDto {
    return LandingPageModuleConstraintDtoFromJSONTyped(json, false);
}

export function LandingPageModuleConstraintDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageModuleConstraintDto {
    if (json == null) {
        return json;
    }
    return {
        
        'minAmount': json['minAmount'],
        'maxAmount': json['maxAmount'] == null ? undefined : json['maxAmount'],
        'defaultSettings': LandingPageModuleSettingsDtoFromJSON(json['defaultSettings']),
    };
}

export function LandingPageModuleConstraintDtoToJSON(value?: LandingPageModuleConstraintDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'minAmount': value['minAmount'],
        'maxAmount': value['maxAmount'],
        'defaultSettings': LandingPageModuleSettingsDtoToJSON(value['defaultSettings']),
    };
}

