/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PersonBadgePersonDto } from './PersonBadgePersonDto';
import {
    PersonBadgePersonDtoFromJSON,
    PersonBadgePersonDtoFromJSONTyped,
    PersonBadgePersonDtoToJSON,
} from './PersonBadgePersonDto';

/**
 * The information that are contained in the qr code of the badge
 * @export
 * @interface PersonBadgeDto
 */
export interface PersonBadgeDto {
    /**
     * 
     * @type {PersonBadgePersonDto}
     * @memberof PersonBadgeDto
     */
    person: PersonBadgePersonDto;
    /**
     * download url of the file
     * @type {string}
     * @memberof PersonBadgeDto
     */
    personPhotoUrl?: string;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof PersonBadgeDto
     */
    edition: string;
}

/**
 * Check if a given object implements the PersonBadgeDto interface.
 */
export function instanceOfPersonBadgeDto(value: object): boolean {
    if (!('person' in value)) return false;
    if (!('edition' in value)) return false;
    return true;
}

export function PersonBadgeDtoFromJSON(json: any): PersonBadgeDto {
    return PersonBadgeDtoFromJSONTyped(json, false);
}

export function PersonBadgeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonBadgeDto {
    if (json == null) {
        return json;
    }
    return {
        
        'person': PersonBadgePersonDtoFromJSON(json['person']),
        'personPhotoUrl': json['personPhotoUrl'] == null ? undefined : json['personPhotoUrl'],
        'edition': json['edition'],
    };
}

export function PersonBadgeDtoToJSON(value?: PersonBadgeDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'person': PersonBadgePersonDtoToJSON(value['person']),
        'personPhotoUrl': value['personPhotoUrl'],
        'edition': value['edition'],
    };
}

