/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BlocklistEntryDto,
  BlocklistEntryPageDto,
  FailureDto,
  HistoryPageDto,
  PageableDto,
} from '../models/index';
import {
    BlocklistEntryDtoFromJSON,
    BlocklistEntryDtoToJSON,
    BlocklistEntryPageDtoFromJSON,
    BlocklistEntryPageDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface CreateMailingBlocklistEntryRequest {
    blocklistEntryDto: BlocklistEntryDto;
}

export interface DeleteMailingBlocklistEntryRequest {
    id: string;
}

export interface ExistsOnBlocklistRequest {
    email: Array<string>;
}

export interface FetchMailingBlockListRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface FetchMailingBlocklistHistoryRequest {
    pageable?: PageableDto;
}

/**
 * 
 */
export class BlocklistApi extends runtime.BaseAPI {

    /**
     * Create a new blocklist entry.
     */
    async createMailingBlocklistEntryRaw(requestParameters: CreateMailingBlocklistEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BlocklistEntryDto>> {
        if (requestParameters['blocklistEntryDto'] == null) {
            throw new runtime.RequiredError(
                'blocklistEntryDto',
                'Required parameter "blocklistEntryDto" was null or undefined when calling createMailingBlocklistEntry().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-blocklist`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BlocklistEntryDtoToJSON(requestParameters['blocklistEntryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlocklistEntryDtoFromJSON(jsonValue));
    }

    /**
     * Create a new blocklist entry.
     */
    async createMailingBlocklistEntry(requestParameters: CreateMailingBlocklistEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BlocklistEntryDto> {
        const response = await this.createMailingBlocklistEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the blocklist entry.
     */
    async deleteMailingBlocklistEntryRaw(requestParameters: DeleteMailingBlocklistEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteMailingBlocklistEntry().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-blocklist/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the blocklist entry.
     */
    async deleteMailingBlocklistEntry(requestParameters: DeleteMailingBlocklistEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteMailingBlocklistEntryRaw(requestParameters, initOverrides);
    }

    /**
     * Get whether any email in the list is on the blocklist
     */
    async existsOnBlocklistRaw(requestParameters: ExistsOnBlocklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling existsOnBlocklist().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-blocklist/exists-on-blocklist`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get whether any email in the list is on the blocklist
     */
    async existsOnBlocklist(requestParameters: ExistsOnBlocklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.existsOnBlocklistRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the block list for mailings.
     */
    async fetchMailingBlockListRaw(requestParameters: FetchMailingBlockListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BlocklistEntryPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-blocklist`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlocklistEntryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the block list for mailings.
     */
    async fetchMailingBlockList(requestParameters: FetchMailingBlockListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BlocklistEntryPageDto> {
        const response = await this.fetchMailingBlockListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the mailing blocklist.
     */
    async fetchMailingBlocklistHistoryRaw(requestParameters: FetchMailingBlocklistHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-blocklist/versions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the mailing blocklist.
     */
    async fetchMailingBlocklistHistory(requestParameters: FetchMailingBlocklistHistoryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchMailingBlocklistHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
