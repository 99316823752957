import { isEqual } from 'lodash-es';
import React from 'react';
import { DefaultValues, FieldNamesMarkedBoolean, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  EmailAddressDto,
  EmailAddressDtoLabelEnum,
  OccupationDto,
  OccupationDtoConnectionTypeEnum,
  OccupationDtoStatusEnum,
  PersonDto,
} from '../../../api';
import ConfirmDialog from '../../../ui/dialog/confirm-dialog';
import now from '../../../util/now';
import useDialog from '../../../util/use-dialog/use-dialog';
import usePending from '../../../util/use-pending/use-pending';
import PersonSimpleFormModel from './person-simple-form-model';

export default function useSimpleHandleValid({
  form,
  templatePerson,
  onPersonSave,
}: {
  form: UseFormReturn<PersonSimpleFormModel>;
  templatePerson: DefaultValues<PersonDto>;
  onPersonSave(person: PersonDto): Promise<void>;
}) {
  const [isConfirmationDialogOpen, onConfirmationDialogClose, openConfirmationDialog] = useDialog<boolean>();
  const { allPending } = usePending();
  const { t } = useTranslation('person');

  const handleValid = React.useCallback(
    async (simplePersonFormModel: PersonSimpleFormModel) => {
      if (
        !hasOnlyRequiredFields(simplePersonFormModel, form.formState.dirtyFields) ||
        (await openConfirmationDialog())
      ) {
        await allPending();
        const person = transferDataToPersonDto(templatePerson, simplePersonFormModel);

        await onPersonSave(person);
      }
    },
    [allPending, form.formState.dirtyFields, onPersonSave, openConfirmationDialog, templatePerson],
  );

  const confirmDialog = (
    <ConfirmDialog
      isOpen={isConfirmationDialogOpen}
      onClose={onConfirmationDialogClose}
      cancelActionLabel={t('editor.only_required_fields_warning_cancel')}
      confirmActionLabel={t('editor.only_required_fields_warning_submit')}
    >
      {t('editor.only_required_fields_warning_text')}
    </ConfirmDialog>
  );

  return { handleValid, confirmDialog };
}

function hasOnlyRequiredFields(
  personToSave: PersonSimpleFormModel,
  dirtyFields: FieldNamesMarkedBoolean<PersonSimpleFormModel>,
) {
  return personToSave.id == null && isEqual(dirtyFields, { firstName: true, surname: true });
}

function transferDataToPersonDto(
  templatePerson: DefaultValues<PersonDto>,
  simplePersonFormModel: PersonSimpleFormModel,
): PersonDto {
  const firstName = simplePersonFormModel.firstName;
  const surname = simplePersonFormModel.surname;
  const dateOfBirth = simplePersonFormModel.dateOfBirth;
  const contactLanguage = simplePersonFormModel.language;

  let emailAddresses: EmailAddressDto[] = [];
  if (simplePersonFormModel.email != null) {
    emailAddresses = [
      {
        email: simplePersonFormModel.email,
        label: EmailAddressDtoLabelEnum.CONTACT,
      } as EmailAddressDto,
    ];
  }

  let occupations: OccupationDto[] = [];
  if (simplePersonFormModel.occupation != null && simplePersonFormModel.occupation.length === 1) {
    occupations = [
      {
        id: simplePersonFormModel.occupation[0].id,
        jobTitle: simplePersonFormModel.occupation[0].jobTitle,
        profession: simplePersonFormModel.occupation[0].profession,
        workingSectors: simplePersonFormModel.occupation[0].workingSectors,
        dateRange: { start: new Date(now()), end: undefined },
        mainActivity: true,
        status: OccupationDtoStatusEnum.CONFIRMED,
        connectedCompany: simplePersonFormModel.occupation[0].company,
        connectionType:
          simplePersonFormModel.occupation[0].company != null
            ? OccupationDtoConnectionTypeEnum.EMPLOYEE
            : OccupationDtoConnectionTypeEnum.FREELANCER,
      } as OccupationDto,
    ];
  }

  const initialComment = simplePersonFormModel.initialComment;

  return {
    ...templatePerson,
    firstName,
    surname,
    dateOfBirth,
    contactLanguage,
    emailAddresses,
    occupations,
    initialComment,
  } as PersonDto;
}
