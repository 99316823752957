/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StatusForParticipationDto } from './StatusForParticipationDto';
import {
    StatusForParticipationDtoFromJSON,
    StatusForParticipationDtoFromJSONTyped,
    StatusForParticipationDtoToJSON,
} from './StatusForParticipationDto';

/**
 * 
 * @export
 * @interface StatusStatisticDto
 */
export interface StatusStatisticDto {
    /**
     * 
     * @type {StatusForParticipationDto}
     * @memberof StatusStatisticDto
     */
    status: StatusForParticipationDto;
    /**
     * 
     * @type {number}
     * @memberof StatusStatisticDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof StatusStatisticDto
     */
    percentage: number;
}

/**
 * Check if a given object implements the StatusStatisticDto interface.
 */
export function instanceOfStatusStatisticDto(value: object): boolean {
    if (!('status' in value)) return false;
    if (!('count' in value)) return false;
    if (!('percentage' in value)) return false;
    return true;
}

export function StatusStatisticDtoFromJSON(json: any): StatusStatisticDto {
    return StatusStatisticDtoFromJSONTyped(json, false);
}

export function StatusStatisticDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusStatisticDto {
    if (json == null) {
        return json;
    }
    return {
        
        'status': StatusForParticipationDtoFromJSON(json['status']),
        'count': json['count'],
        'percentage': json['percentage'],
    };
}

export function StatusStatisticDtoToJSON(value?: StatusStatisticDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': StatusForParticipationDtoToJSON(value['status']),
        'count': value['count'],
        'percentage': value['percentage'],
    };
}

