import React from 'react';
import { companyIcon } from '../../../ui/icons/business-objects';
import { PluginRegistry } from '../../../util/plugin/use-plugins';
import Translate from '../../../util/translate/translate';
import FORMAT_CHILD_ROUTES from '../../format/format-child-routes';
import { FORMAT_TAB_ITEM, FormatTabItem } from '../../format/format-page/format-page-route';
import { FORMAT_HISTORY_EXTENSION } from '../../format/format-relation/format-history-extension';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../../permission-class-group/permission-class-group-extension';
import { formatCompanyHistoryExtension } from './format-company-history/format-company-history';
import { useFormatCompanyPermissionClassGroupExtension } from './format-company-permissions';
import formatCompanyRoutes from './format-to-company-viewer/format-company-routes';

export const formatToCompanyTabItem: FormatTabItem = {
  order: 2,
  label: <Translate ns="company" i18nKey="lister.title" />,
  icon: companyIcon,
  to: './companies',
};

export default function FormatCompanyPluginProvider(registry: PluginRegistry) {
  registry.register(FORMAT_TAB_ITEM, formatToCompanyTabItem);
  registry.register(FORMAT_CHILD_ROUTES, formatCompanyRoutes);
  registry.register(FORMAT_HISTORY_EXTENSION, formatCompanyHistoryExtension);
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useFormatCompanyPermissionClassGroupExtension);
}
