import { differenceInDays, endOfDay, isAfter, isBefore, startOfDay } from 'date-fns';
import { first } from 'lodash-es';
import { OccupationDto } from '../../../../api';

export function calculateNextMainOccupation(occupations: OccupationDto[], now: Date): OccupationDto {
  const allActiveInfiniteOccupations = occupations.filter(
    (o) => startOfDay(o.dateRange.start) < now && o.dateRange.end == null,
  );
  if (allActiveInfiniteOccupations.length > 0) {
    return first(allActiveInfiniteOccupations) as OccupationDto;
  }

  const allActiveOccupationsWithEndDate = occupations.filter(
    (o) => startOfDay(o.dateRange.start) < now && o.dateRange.end != null && now < endOfDay(o.dateRange.end),
  );
  if (allActiveOccupationsWithEndDate.length > 0) {
    let latestEndIndex = 0;
    allActiveOccupationsWithEndDate.forEach((o, index) => {
      if (isAfter(o.dateRange.end!, allActiveOccupationsWithEndDate[latestEndIndex].dateRange.end!)) {
        latestEndIndex = index;
      }
    });

    return allActiveOccupationsWithEndDate[latestEndIndex];
  }

  const nearestPastOccupation = getNearestPastOccupation(occupations, now);
  const nearestFutureOccupation = getNearestFutureOccupation(occupations, now);

  if (nearestPastOccupation != null && nearestFutureOccupation != null) {
    return differenceInDays(now, nearestPastOccupation.dateRange.end!) <
      differenceInDays(nearestFutureOccupation.dateRange.start, now)
      ? nearestPastOccupation
      : nearestFutureOccupation;
  }

  return nearestFutureOccupation ?? (nearestPastOccupation as OccupationDto);
}

function getNearestPastOccupation(occupations: OccupationDto[], rightNow: Date): OccupationDto | null {
  const allPastOccupations = occupations.filter(
    (o) => startOfDay(o.dateRange.start) < rightNow && o.dateRange.end != null && rightNow > endOfDay(o.dateRange.end),
  );

  if (allPastOccupations.length > 0) {
    let nearestEndIndex = 0;
    allPastOccupations.forEach((o, index) => {
      if (isAfter(o.dateRange.end!, allPastOccupations[nearestEndIndex].dateRange.end!)) {
        nearestEndIndex = index;
      }
    });

    return allPastOccupations[nearestEndIndex];
  }

  return null;
}

function getNearestFutureOccupation(occupations: OccupationDto[], rightNow: Date): OccupationDto | null {
  const allFutureOccupations = occupations.filter(
    (o) =>
      startOfDay(o.dateRange.start) > rightNow && (o.dateRange.end == null || rightNow < endOfDay(o.dateRange.end)),
  );

  if (allFutureOccupations.length > 0) {
    let nearestStartIndex = 0;
    allFutureOccupations.forEach((o, index) => {
      if (isBefore(o.dateRange.start!, allFutureOccupations[nearestStartIndex].dateRange.start!)) {
        nearestStartIndex = index;
      }
    });

    return allFutureOccupations[nearestStartIndex];
  }

  return null;
}
