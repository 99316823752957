import { addSeconds, isFuture } from 'date-fns';
import { CreateTokenGrantTypeEnum, CreateTokenRequest, TokenResponseDto } from '../api';
import { AuthToken, readAuthToken, removeAuthToken, writeAuthToken } from './auth-token-storage';
import { CustomRequestInit } from './error-middleware';
import oAuth2Api from './o-auth-2-api';

export interface LoginDto {
  username: string;
  password: string;
}

export async function login(data: LoginDto): Promise<AuthToken | false> {
  return createToken({ grantType: CreateTokenGrantTypeEnum.PASSWORD, ...data });
}

export async function refreshToken(): Promise<AuthToken | false> {
  const authToken = readAuthToken();

  if (authToken == null || authToken.expiresAt == null) {
    return false;
  }

  if (isFuture(authToken.expiresAt)) {
    return authToken;
  }

  if (authToken.refreshToken == null) {
    return false;
  }

  return createToken({ grantType: CreateTokenGrantTypeEnum.REFRESH_TOKEN, refreshToken: authToken.refreshToken });
}

export function logout() {
  removeAuthToken();

  return true;
}

async function createToken(request: CreateTokenRequest): Promise<AuthToken | false> {
  let tokenResponse: TokenResponseDto;

  try {
    tokenResponse = await oAuth2Api.createToken(request, {
      headers: getAuthorizationHeaders(),
      allowedErrorCodes: [400],
    } as CustomRequestInit);
  } catch (_) {
    return false;
  }

  const authToken = {
    ...tokenResponse,
    expiresAt: tokenResponse.expiresIn != null ? addSeconds(new Date(), tokenResponse.expiresIn).valueOf() : undefined,
  } as AuthToken;

  writeAuthToken(authToken);

  return authToken;
}

export const CLIENT_ID = 'berlinalx';
export const CLIENT_SECRET = 'wps';

function getAuthorizationHeaders() {
  return new Headers({ Authorization: `Basic ${btoa(`${CLIENT_ID}:${CLIENT_SECRET}`)}` });
}
