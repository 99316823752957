import { SystemStyleObject } from '@chakra-ui/react';
import { noop } from 'lodash-es';
import React from 'react';
import { DataTableAnatomy } from '../../theme/component/data-table';
import useDefinedContext from '../../util/context/use-defined-context/use-defined-context';
import useControlledReducer from '../../util/use-controlled-reducer/use-controlled-reducer';
import { ParentDataTableColumn } from './data-table-column';
import DataTableState, { DataTableAction, DataTableFilter, dataTableStateReducer } from './data-table-state';

export const DataTableStateContext = React.createContext<
  { state: Partial<DataTableState>; dispatch: (action: DataTableAction) => void } | undefined
>(undefined);

interface DataTableStateProviderProps {
  children: React.ReactNode;
  state: Partial<DataTableState> | undefined;
  onStateChange(state: Partial<DataTableState>): void;
}

export function DataTableStateProvider({ state = {}, onStateChange, children }: DataTableStateProviderProps) {
  const dispatch = useControlledReducer(dataTableStateReducer, state, onStateChange);
  const value = React.useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return <DataTableStateContext.Provider value={value}>{children}</DataTableStateContext.Provider>;
}

export const DataTableColumnContext = React.createContext<
  | {
      column: ParentDataTableColumn<any>;
      initialFilterFocusRef: React.RefObject<any>;
      setIsCloseable(isCloseable: boolean): void;
    }
  | undefined
>(undefined);

interface DataTableColumnProviderProps {
  children: React.ReactNode;
  column: ParentDataTableColumn<any>;
  initialFilterFocusRef: React.RefObject<any>;
  onCloseableChange?(isCloseable: boolean): void;
}

export function DataTableColumnProvider({
  column,
  initialFilterFocusRef,
  onCloseableChange = noop,
  children,
}: DataTableColumnProviderProps) {
  const value = React.useMemo(
    () => ({
      column,
      initialFilterFocusRef,
      setIsCloseable: onCloseableChange,
    }),
    [column, initialFilterFocusRef, onCloseableChange],
  );

  return <DataTableColumnContext.Provider value={value}>{children}</DataTableColumnContext.Provider>;
}

export function useDataTableFilter(): {
  property: string;
  getFilter(property: string): DataTableFilter | undefined;
  getFilters(property: string): DataTableFilter[] | undefined;
  setFilter(filter: DataTableFilter): void;
  setFilterReplaceIfOperatorMatches(filter: DataTableFilter): void;
  replaceFilter(oldFilterProperty: string, newFilter: DataTableFilter): void;
  removeFilters(...properties: string[]): void;
  removeFilter(property: string, operation: string): void;
  initialFocusRef: React.RefObject<any>;
  setIsCloseable(isCloseable: boolean): void;
} {
  const { state, dispatch } = useDefinedContext(DataTableStateContext);
  const { column, initialFilterFocusRef, setIsCloseable } = useDefinedContext(DataTableColumnContext);
  const property = column.filterProperty ?? column.key;

  const setFilter = React.useCallback(
    (filter: DataTableFilter) => {
      dispatch({ type: 'SET_FILTER', payload: { filter, options: {} } });
    },
    [dispatch],
  );

  const setFilterReplaceIfOperatorMatches = React.useCallback(
    (filter: DataTableFilter) => {
      dispatch({ type: 'SET_FILTER', payload: { filter, options: { replaceOnlyIfOperatorMatches: true } } });
    },
    [dispatch],
  );

  const replaceFilter = React.useCallback(
    (oldFilterProperty: string, newFilter: DataTableFilter) => {
      dispatch({ type: 'REPLACE_FILTER', payload: { oldFilterProperty, newFilter } });
    },
    [dispatch],
  );

  const removeFilters = React.useCallback(
    (...properties: string[]) => {
      dispatch({ type: 'REMOVE_FILTERS', payload: properties });
    },
    [dispatch],
  );

  const removeFilter = React.useCallback(
    (property: string, operation: string) => {
      dispatch({ type: 'REMOVE_FILTER', payload: { property, operator: operation } });
    },
    [dispatch],
  );

  const getFilter = React.useCallback(
    (property: string) => {
      return state.filter?.find((filter) => filter.property === property || filter.property.startsWith(`${property}.`));
    },
    [state.filter],
  );

  const getFilters = React.useCallback(
    (property: string) => {
      return state.filter?.filter(
        (filter) => filter.property === property || filter.property.startsWith(`${property}.`),
      );
    },
    [state.filter],
  );

  return {
    property,
    getFilter,
    getFilters,
    replaceFilter,
    setFilter,
    setFilterReplaceIfOperatorMatches,
    removeFilters,
    removeFilter,
    initialFocusRef: initialFilterFocusRef,
    setIsCloseable,
  };
}

export const DataTableStylesContext = React.createContext<
  Record<DataTableAnatomy['__type'], SystemStyleObject> | undefined
>(undefined);
