import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { BreadcrumbRouteObject } from '../../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../../ui/helmet/helmet-outlet';
import useFetcher from '../../../util/swr/use-fetcher';
import PersonPage from '../../person/person-page/person-page';
import { fetchPerson } from '../../person/person-queries';

const AppointmentViewer = React.lazy(() => import('./person-appointment-viewer'));

const personAppointmentRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: '',
    element: <PersonPage />,
    children: [
      {
        path: 'appointments',
        element: <AppointmentViewer />,
        handle: {
          helmet: <PersonAppointmentViewerHelmet />,
        },
      },
    ],
  },
];

export default personAppointmentRoutes;

function PersonAppointmentViewerHelmet() {
  const { t } = useTranslation('person');
  const params = useParams<{ personId: string }>();
  invariant(params.personId, 'Empty personId');
  const person = useFetcher(fetchPerson, { id: params.personId });

  return (
    <Helmet
      title={t('appointmentViewer.helmet', {
        firstName: person.firstName,
        surname: person.surname,
      })}
    />
  );
}
