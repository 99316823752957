import { Button, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findLastIndex, sortBy } from 'lodash-es';
import React from 'react';
import { Link as RouterLink, matchPath, useLocation, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { EventDto } from '../../../api';
import { BurgerMenuButton } from '../../../ui/burger-menu-button/burger-menu-button';
import usePlugins, { PluginToken } from '../../../util/plugin/use-plugins';
import { useGuestListSettingsByEventId } from '../../guest-list/use-guest-list-settings/use-guest-list-settings';
import usePermission, { UsePermissionReturn } from '../../permission/use-permission';
import useEvent from '../use-event/use-event';

export interface EventMenuItem {
  order: number;
  label: React.ReactNode;
  icon: IconProp;
  path: (eventId: string) => string;
  isVisible?: (ownerSectionIds: string[], permissions: UsePermissionReturn) => boolean;
  isDisabled?: (event: EventDto) => boolean;
}

export const EVENT_MENU_ITEM = new PluginToken<EventMenuItem>('EventMenuItem');
export const EVENT_MOBILE_MENU_ITEM = new PluginToken<EventMenuItem>('EventMobileMenuItem');

interface EventPageMenuProps {
  isMobile?: boolean;
  headerMenuItem?: React.ReactNode;
}

export default function EventPageMenu({ isMobile, headerMenuItem }: EventPageMenuProps) {
  const { eventId } = useParams<{ eventId: string }>();
  invariant(eventId != null, 'Missing event id');

  const event = useEvent(eventId);
  const guestList = useGuestListSettingsByEventId(eventId);

  const location = useLocation();
  const ownerSectionIds = event.owners
    .map((owner) => owner.section.id)
    .concat(guestList?.allocations.map((allocation) => allocation.owner.id) || []);

  const permissions = usePermission();
  const menuItems = usePlugins(isMobile ? EVENT_MOBILE_MENU_ITEM : EVENT_MENU_ITEM);

  const sortedMenuItems = React.useMemo(
    () =>
      sortBy(
        menuItems.filter((menuItem) => menuItem.isVisible == null || menuItem.isVisible(ownerSectionIds, permissions)),
        (menuItem) => menuItem.order,
      ),
    [menuItems, ownerSectionIds, permissions],
  );

  const activeMenuItemIndex = React.useMemo(() => {
    let activeMenuItemIndex = findLastIndex(sortedMenuItems, (menuItem) => {
      return (
        matchPath(
          {
            path: menuItem.path(eventId),
            end: false,
          },
          location.pathname,
        ) != null
      );
    });

    if (activeMenuItemIndex === -1 && sortedMenuItems.length > 0) {
      activeMenuItemIndex = 0;
    }

    return activeMenuItemIndex;
  }, [sortedMenuItems, eventId, location.pathname]);

  if (activeMenuItemIndex === -1) {
    return null;
  }

  const activeMenuItem = sortedMenuItems[activeMenuItemIndex];

  return (
    <Menu>
      {isMobile ? (
        <BurgerMenuButton size="sm" />
      ) : (
        <MenuButton
          key={activeMenuItemIndex}
          as={Button}
          leftIcon={<FontAwesomeIcon icon={activeMenuItem.icon} />}
          rightIcon={<FontAwesomeIcon icon={faChevronDown} />}
          bgColor="background.highlight"
          borderBottomRadius="none"
          _dark={{ bgColor: 'background.hero' }}
        >
          {activeMenuItem.label}
        </MenuButton>
      )}
      <Portal>
        {/* add portal to ensure menulist is properly closed by routing on page tabs or linked events */}
        <MenuList>
          {headerMenuItem && (
            <>
              {headerMenuItem}
              <MenuDivider />
            </>
          )}
          {sortedMenuItems.map((menuItem, index) => (
            <MenuItem
              key={index}
              icon={<FontAwesomeIcon icon={menuItem.icon} fixedWidth />}
              as={RouterLink}
              isDisabled={menuItem.isDisabled?.(event)}
              color={menuItem === activeMenuItem ? 'text.interactive' : undefined}
              fontWeight={menuItem === activeMenuItem ? 'medium' : undefined}
              to={menuItem.path(eventId)}
            >
              {menuItem.label}
            </MenuItem>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  );
}
