/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddAccessGroupsDto
 */
export interface AddAccessGroupsDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof AddAccessGroupsDto
     */
    personId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddAccessGroupsDto
     */
    accessGroupIds: Array<string>;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof AddAccessGroupsDto
     */
    editionId: string;
}

/**
 * Check if a given object implements the AddAccessGroupsDto interface.
 */
export function instanceOfAddAccessGroupsDto(value: object): boolean {
    if (!('personId' in value)) return false;
    if (!('accessGroupIds' in value)) return false;
    if (!('editionId' in value)) return false;
    return true;
}

export function AddAccessGroupsDtoFromJSON(json: any): AddAccessGroupsDto {
    return AddAccessGroupsDtoFromJSONTyped(json, false);
}

export function AddAccessGroupsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddAccessGroupsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'personId': json['personId'],
        'accessGroupIds': json['accessGroupIds'],
        'editionId': json['editionId'],
    };
}

export function AddAccessGroupsDtoToJSON(value?: AddAccessGroupsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'personId': value['personId'],
        'accessGroupIds': value['accessGroupIds'],
        'editionId': value['editionId'],
    };
}

