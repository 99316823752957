import { Tag, Tooltip, Wrap, WrapItem } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { KeyPlayerDto, KeyPlayerDtoPresenceEnum, KeyPlayerRoleDto, PersonTypeDto } from '../../../api';
import { DataTableColumn } from '../../../ui/data-table';
import DateRangeFilter from '../../../ui/data-table/filter/date-range-filter';
import EnumFilter from '../../../ui/data-table/filter/enum-filter';
import StringFilter from '../../../ui/data-table/filter/string-filter';
import HelperPopover from '../../../ui/helper-buttons/helper-popover';
import Optional from '../../../ui/optional/optional';
import { types } from '../../person/person-enum-constants';
import PersonReference from '../../person/person-reference/person-reference';
import PersonReferenceViewer from '../../person/person-reference/person-reference-viewer';

export default function useKeyPlayerColumns(
  readOnly: boolean,
  getEventTitle?: (keyPlayer: KeyPlayerDto) => string | undefined,
) {
  const { t } = useTranslation(['common', 'key_player_list', 'person']);

  const eventColumn: DataTableColumn<KeyPlayerDto> = React.useMemo(() => {
    return {
      key: 'eventId',
      name: t('key_player_list:lister.event'),
      renderCell: (keyPlayerDto: KeyPlayerDto) => <Optional>{getEventTitle && getEventTitle(keyPlayerDto)}</Optional>,
      cellProps: {
        textAlign: 'left',
      },
      isSortable: true,
    };
  }, [getEventTitle, t]);

  const personColumn: DataTableColumn<KeyPlayerDto> = React.useMemo(() => {
    return {
      key: 'person',
      sticky: true,
      name: t('key_player_list:lister.person'),
      renderCell: (keyPlayerDto: KeyPlayerDto) =>
        readOnly ? (
          <PersonReferenceViewer personReference={keyPlayerDto.person} hidePersonKey readOnly />
        ) : (
          <PersonReference personReference={keyPlayerDto.person} hidePersonKey usePortalForCard />
        ),
      cellProps: {
        textAlign: 'left',
        minWidth: '250px',
      },
      isSortable: true,
      filter: <StringFilter label={t('key_player_list:lister.person')} />,
    };
  }, [readOnly, t]);

  const typesColumn: DataTableColumn<KeyPlayerDto> = React.useMemo(() => {
    return {
      key: 'types',
      name: t('person:type'),
      cellProps: {
        whiteSpace: 'nowrap',
        width: '12%',
      },
      isSortable: false,
      renderCell: (keyPlayerDto: KeyPlayerDto) => (
        <Wrap>
          {keyPlayerDto.person.types?.map((type) => (
            <WrapItem key={type}>
              <Tag colorScheme="gray">{t(`person:typeLabel.${type}`)}</Tag>
            </WrapItem>
          ))}
        </Wrap>
      ),
      filter: (
        <EnumFilter<PersonTypeDto>
          label={t('person:type')}
          options={types}
          renderOptionLabel={(key) => t(`person:typeLabel.${key}`)}
        />
      ),
    };
  }, [t]);

  const rolesColumn: DataTableColumn<KeyPlayerDto> = React.useMemo(() => {
    return {
      key: 'roles',
      name: t('key_player_list:lister.role'),
      renderCell: (keyPlayerDto: KeyPlayerDto) => (
        <Wrap>
          {keyPlayerDto.roles.map((role, index) => (
            <WrapItem key={index}>
              <Tag colorScheme="gray">{t(`key_player_list:roleLabel.${role}`)}</Tag>
            </WrapItem>
          ))}
        </Wrap>
      ),
      cellProps: {
        textAlign: 'left',
      },
      isSortable: false,
      filter: (
        <EnumFilter
          label={t('key_player_list:role')}
          options={roleEnum}
          renderOptionLabel={(key) => t(`key_player_list:roleLabel.${key}`)}
        />
      ),
    };
  }, [t]);

  const companyColumn: DataTableColumn<KeyPlayerDto> = React.useMemo(() => {
    return {
      key: 'company',
      name: t('key_player_list:lister.company'),
      renderCell: (keyPlayerDto: KeyPlayerDto) => <Optional>{keyPlayerDto.company?.name}</Optional>,
      cellProps: {
        textAlign: 'left',
      },
      isSortable: true,
      filter: <StringFilter label={t('key_player_list:lister.company')} />,
    };
  }, [t]);

  const presenceColumn: DataTableColumn<KeyPlayerDto> = React.useMemo(() => {
    return {
      key: 'presence',
      name: (
        <>
          {t('key_player_list:lister.presence')}
          <HelperPopover
            children={t(`key_player_list:lister.presence_time_helper`)}
            ariaLabel={t('key_player_list:lister.presence')}
          />
        </>
      ),
      renderCell: (keyPlayerDto: KeyPlayerDto) => (
        <Tag colorScheme="gray">{t(`key_player_list:presenceLabel.${keyPlayerDto.presence}`)}</Tag>
      ),
      isSortable: true,
      filter: (
        <EnumFilter
          label={t('key_player_list:presence')}
          options={presenceEnum}
          renderOptionLabel={(key) => t(`key_player_list:presenceLabel.${key}`)}
        />
      ),
    };
  }, [t]);

  const dateTimeRangePresenceColumn: DataTableColumn<KeyPlayerDto> = React.useMemo(() => {
    return {
      key: 'dateTimeRangePresence',
      name: t('key_player_list:lister.presence_time'),
      cellProps: {
        whiteSpace: 'nowrap',
        width: '20%',
      },
      renderCell: (keyPlayerDto: KeyPlayerDto) => (
        <Optional isEmpty={isEmpty(keyPlayerDto.dateTimeRangePresence)}>
          {t('common:format.datetime_range', { dateTimeRange: keyPlayerDto.dateTimeRangePresence })}
        </Optional>
      ),
      isSortable: true,
      sortProperty: 'dateTimeRangePresence.start',
      filter: <DateRangeFilter showTimeSelect />,
    };
  }, [t]);

  const commentColumn: DataTableColumn<KeyPlayerDto> = React.useMemo(() => {
    return {
      key: 'comment',
      name: t('key_player_list:lister.comment'),
      cellProps: {
        whiteSpace: 'break-spaces',
        width: '20%',
      },
      renderCell: (keyPlayer) => (
        <Optional isEmpty={keyPlayer.comment == null}>
          <Tooltip label={keyPlayer.comment}>
            <Wrap>
              <WrapItem noOfLines={2}>{keyPlayer.comment}</WrapItem>
            </Wrap>
          </Tooltip>
        </Optional>
      ),
      isSortable: true,
      filter: <StringFilter label={t('key_player_list:lister.comment')} />,
    };
  }, [t]);

  return {
    eventColumn,
    personColumn,
    typesColumn,
    rolesColumn,
    companyColumn,
    presenceColumn,
    dateTimeRangePresenceColumn,
    commentColumn,
  };
}

export const presenceEnum = [
  KeyPlayerDtoPresenceEnum.PRESENT_ON_DUTY,
  KeyPlayerDtoPresenceEnum.PRESENT_OFF_DUTY,
  KeyPlayerDtoPresenceEnum.ABSENT,
];

export const roleEnum = [
  KeyPlayerRoleDto.WELCOME_RED_CARPET,
  KeyPlayerRoleDto.WELCOME_VIP_SPACE_PRESS_CONFERENCE,
  KeyPlayerRoleDto.VISION_MIXER,
  KeyPlayerRoleDto.INTERPRETER,
  KeyPlayerRoleDto.FILM_INTRODUCTION,
  KeyPlayerRoleDto.GUEST_LIAISON_OFFICER,
  KeyPlayerRoleDto.LOCATION_MANAGEMENT,
  KeyPlayerRoleDto.MEDIA_COORDINATOR,
  KeyPlayerRoleDto.HOST,
  KeyPlayerRoleDto.HOST_QA_ONLY,
  KeyPlayerRoleDto.PANELIST,
  KeyPlayerRoleDto.DIRECTOR,
  KeyPlayerRoleDto.RED_CARPET_PHOTOGRAPHER,
  KeyPlayerRoleDto.RED_CARPET_CAMERA,
  KeyPlayerRoleDto.RED_CARPET_MANAGEMENT,
  KeyPlayerRoleDto.RED_CARPET_PUBLICIST,
  KeyPlayerRoleDto.BERLINALE_SECURITY,
  KeyPlayerRoleDto.SPEAKER,
  KeyPlayerRoleDto.TECHNICIAN,
  KeyPlayerRoleDto.ZDF_PARTNER,
  KeyPlayerRoleDto.OTHERS,
];
