/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MailingTemplateDto } from './MailingTemplateDto';
import {
    MailingTemplateDtoFromJSON,
    MailingTemplateDtoFromJSONTyped,
    MailingTemplateDtoToJSON,
} from './MailingTemplateDto';

/**
 * 
 * @export
 * @interface MailingTemplateSearchResultDto
 */
export interface MailingTemplateSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof MailingTemplateSearchResultDto
     */
    resultType: MailingTemplateSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof MailingTemplateSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {MailingTemplateDto}
     * @memberof MailingTemplateSearchResultDto
     */
    result: MailingTemplateDto;
}


/**
 * @export
 */
export const MailingTemplateSearchResultDtoResultTypeEnum = {
    MAILING_TEMPLATE: 'MAILING_TEMPLATE'
} as const;
export type MailingTemplateSearchResultDtoResultTypeEnum = typeof MailingTemplateSearchResultDtoResultTypeEnum[keyof typeof MailingTemplateSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the MailingTemplateSearchResultDto interface.
 */
export function instanceOfMailingTemplateSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function MailingTemplateSearchResultDtoFromJSON(json: any): MailingTemplateSearchResultDto {
    return MailingTemplateSearchResultDtoFromJSONTyped(json, false);
}

export function MailingTemplateSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingTemplateSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': MailingTemplateDtoFromJSON(json['result']),
    };
}

export function MailingTemplateSearchResultDtoToJSON(value?: MailingTemplateSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': MailingTemplateDtoToJSON(value['result']),
    };
}

