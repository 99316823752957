import { Stack, Text, useId } from '@chakra-ui/react';
import { values } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MultiValueSelect, { MultiValueSelectProps } from '../../select/multi-value-select';
import { useDataTableFilter } from '../data-table-context';
import RecordSelect from './record-select';

/**
 * Enum filter operations.
 */
const EnumOperatorOptions = {
  OR: 'anyin',
  AND: 'allin',
} as const;

const EmptyEnumOperatorOptions = {
  _EMPTY_: '!exists',
  _SOMETHING_: 'exists',
} as const;

type EmptyEnumOperatorOptions = '_EMPTY_' | '_SOMETHING_';

export interface EnumFilterProps<T extends string> {
  label: React.ReactNode;
  options: T[];
  renderOptionLabel: (value: T) => string;
  getOptionStringValue?: MultiValueSelectProps<T>['getStringValue'];
  showOperatorOptions?: boolean;
  showLabel?: boolean;
  multiValue?: boolean;
  includeEmptyFilter?: boolean;
}

/**
 * String filter, may be used for all columns with enums in data tables. Note that enum values to
 * filter has to be passed as options.
 */
export default function EnumFilter<T extends string>({
  options,
  renderOptionLabel,
  getOptionStringValue,
  label,
  showOperatorOptions = false,
  showLabel = true,
  multiValue = showOperatorOptions,
  includeEmptyFilter = false,
}: EnumFilterProps<T>) {
  const { t } = useTranslation('common');
  const { property, getFilter, setFilter, removeFilters, initialFocusRef } = useDataTableFilter();
  const id = useId(undefined, 'enum-filter');

  const { operator, value: filterValue } = getFilter(property) ?? {};

  const [operatorOption, setOperatorOption] = React.useState(
    filterEmptyOperators(operator) ?? multiValue ? 'anyin' : 'in',
  );

  React.useEffect(() => {
    setOperatorOption((operatorOption) => filterEmptyOperators(operator) ?? operatorOption);
  }, [operator]);

  const filterValues: (T | EmptyEnumOperatorOptions)[] = isEmptyOperator(operator)
    ? [mapEmptyOperator(operator)]
    : filterValue?.split(',').map((value) => value as T) ?? [];

  const updateFilter = (
    selectedValues: (T | EmptyEnumOperatorOptions)[] | null,
    selectedValue: T | EmptyEnumOperatorOptions | undefined,
  ) => {
    if (selectedValues != null && selectedValues.length > 0) {
      if (isEmptyFilter(selectedValue)) {
        setFilter({
          operator: EmptyEnumOperatorOptions[selectedValue],
          value: '',
          property,
        });
      } else {
        setFilter({
          operator: operatorOption,
          value: selectedValues.filter((x) => !isEmptyFilter(x)).join(','),
          property,
        });
      }
    } else {
      removeFilters(property);
    }
  };

  const handleOperatorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const operatorOption = event.target.value;

    setOperatorOption(operatorOption);
    if (filterValue) {
      setFilter({ operator: operatorOption, value: filterValue, property });
    }
  };

  const optionsWithEmpty: (T | EmptyEnumOperatorOptions)[] = includeEmptyFilter
    ? [...options, '_EMPTY_', '_SOMETHING_']
    : options;

  return (
    <Stack as="fieldset" spacing={2} aria-labelledby={`${id}-label`}>
      {showLabel && (
        <Text as="div" fontSize="sm" fontWeight="medium" id={`${id}-label`}>
          {label}
        </Text>
      )}
      {showOperatorOptions && (
        <RecordSelect<typeof EnumOperatorOptions>
          size="sm"
          value={operatorOption}
          onChange={handleOperatorChange}
          ref={initialFocusRef}
          options={EnumOperatorOptions}
          aria-label={t(`data_table.string_filter.operator_label`)}
        >
          {(key) => t(`data_table.enum_filter.${key}`)}
        </RecordSelect>
      )}
      <MultiValueSelect<T | EmptyEnumOperatorOptions>
        aria-label={t('select.placeholder')}
        options={optionsWithEmpty}
        onChange={updateFilter}
        values={filterValues}
        renderLabel={(val) => (isEmptyFilter(val) ? t(`data_table.enum_filter.${val}`) : renderOptionLabel(val))}
        getStringValue={(val) =>
          isEmptyFilter(val)
            ? t(`data_table.enum_filter.${val}`)
            : getOptionStringValue?.(val) ?? renderOptionLabel(val)
        }
        size="sm"
        ref={initialFocusRef}
      />
    </Stack>
  );
}

function isEmptyFilter(value: string | undefined): value is EmptyEnumOperatorOptions {
  return value === '_EMPTY_' || value === '_SOMETHING_';
}

function isEmptyOperator(value: string | undefined): value is 'exists' | '!exists' {
  return value === 'exists' || value === '!exists';
}

function mapEmptyOperator(value: 'exists' | '!exists'): EmptyEnumOperatorOptions {
  return value === '!exists' ? '_EMPTY_' : '_SOMETHING_';
}

function filterEmptyOperators(value: string | undefined) {
  // @ts-expect-error is correct
  if (values(EmptyEnumOperatorOptions).includes(value)) {
    return undefined;
  }
  return value;
}
