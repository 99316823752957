import { chakra, Divider, HStack, IconButton, Tooltip } from '@chakra-ui/react';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface PaginationProps {
  page: PaginationPage;
  onPrevPage(): void;
  onNextPage(): void;
  showDivider?: boolean;
}

interface PaginationPage {
  size: number;
  page: number;
  totalElements: number;
  totalPages: number;
}

export default function Pagination({ page, onPrevPage, onNextPage, showDivider }: PaginationProps) {
  const { t } = useTranslation('common');
  const { size: pageSize, page: currentPage, totalElements, totalPages } = page;

  const offset = pageSize * currentPage;
  const hasPrev = currentPage > 0;
  const hasNext = currentPage < totalPages - 1;

  return (
    <>
      {showDivider && <Divider />}
      <HStack justifyContent="end" data-testid="pagination">
        <chakra.div role="group" marginRight={3}>
          <Trans
            t={t}
            i18nKey="data_table.slice_of"
            components={{ muted: <chakra.span color="text.muted" /> }}
            values={{
              start: totalElements > 0 ? offset + 1 : 0,
              end: offset + Math.min(totalElements - offset, pageSize),
              total: totalElements,
            }}
          />
        </chakra.div>
        <Tooltip label={t('pagination.back')}>
          <IconButton
            isDisabled={!hasPrev}
            variant="ghost"
            icon={<FontAwesomeIcon icon={faArrowLeft} />}
            size="sm"
            aria-label={t('data_table.previous_page')}
            onClick={onPrevPage}
          />
        </Tooltip>
        <Tooltip label={t('pagination.forward')}>
          <IconButton
            isDisabled={!hasNext}
            variant="ghost"
            icon={<FontAwesomeIcon icon={faArrowRight} />}
            size="sm"
            aria-label={t('data_table.next_page')}
            onClick={onNextPage}
          />
        </Tooltip>
      </HStack>
    </>
  );
}
