/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';

/**
 * 
 * @export
 * @interface ParticipationReferenceDto
 */
export interface ParticipationReferenceDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof ParticipationReferenceDto
     */
    id: string;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof ParticipationReferenceDto
     */
    person: PersonReferenceDto;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof ParticipationReferenceDto
     */
    edition: EditionReferenceDto;
}

/**
 * Check if a given object implements the ParticipationReferenceDto interface.
 */
export function instanceOfParticipationReferenceDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('person' in value)) return false;
    if (!('edition' in value)) return false;
    return true;
}

export function ParticipationReferenceDtoFromJSON(json: any): ParticipationReferenceDto {
    return ParticipationReferenceDtoFromJSONTyped(json, false);
}

export function ParticipationReferenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipationReferenceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'person': PersonReferenceDtoFromJSON(json['person']),
        'edition': EditionReferenceDtoFromJSON(json['edition']),
    };
}

export function ParticipationReferenceDtoToJSON(value?: ParticipationReferenceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'person': PersonReferenceDtoToJSON(value['person']),
        'edition': EditionReferenceDtoToJSON(value['edition']),
    };
}

