import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { LocationStatusDto, TagRelationTypeDto } from '../../../../api';
import locationApi from '../../../../data-access/location-api';
import { BreadcrumbRouteObject } from '../../../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../../../ui/helmet/helmet-outlet';
import { locationIcon } from '../../../../ui/icons/business-objects';
import usePromise from '../../../../util/use-promise/use-promise';
import LocationPage from '../../../location/location-page/location-page';
import useLocation from '../../../location/use-location/use-location';
import usePermission from '../../../permission/use-permission';

const LocationTagRelationContent = React.lazy(() => import('../tag-relation-page-content'));

const locationTagRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: '',
    element: <LocationPage />,
    children: [
      {
        path: 'tags',
        element: <LocationTagContent />,
        handle: {
          helmet: <LocationTagsHelmet />,
        },
      },
    ],
  },
];

export default locationTagRoutes;

function LocationTagContent() {
  const relationType = TagRelationTypeDto.LOCATION;

  const params = useParams<{ locationId: string }>();
  const locationId = params.locationId;
  invariant(locationId, 'Empty locationId');

  const location = usePromise((signal) => locationApi.fetchLocation({ id: locationId }, { signal }), [locationId]);
  const locationRef = {
    id: locationId,
    name: location?.name,
    englishName: location?.englishName,
    addresses: location?.postalAddresses,
  };

  const fromAnnotation = locationRef.name!;

  const { hasPermission } = usePermission();

  return (
    <LocationTagRelationContent
      isAddingTagPossible={location?.status == LocationStatusDto.ACTIVE}
      relationRef={locationRef}
      relationType={relationType}
      fromIcon={locationIcon}
      fromAnnotation={fromAnnotation}
      canLink={hasPermission('LOCATION-TAG:CAN_LINK')}
      canUnlink={hasPermission('LOCATION-TAG:CAN_UNLINK')}
    />
  );
}

function LocationTagsHelmet() {
  const { t } = useTranslation('tag');
  const params = useParams<{ locationId: string }>();
  invariant(params.locationId, 'Empty locationId');
  const location = useLocation(params.locationId);

  return (
    <Helmet
      title={t('relations.location.helmet', {
        name: location.name,
        abbreviation: location.abbreviation,
      })}
    />
  );
}
