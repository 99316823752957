import { WarningIcon } from '@chakra-ui/icons';
import { Heading, HStack, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClosedDateTimeRangeDto, EventDto, PublishedEventSettingsDto } from '../../../api';
import LocationReference, { LocationReferenceViewer } from '../../location/location-reference/location-reference';

export interface EventReferenceHeaderProps {
  title?: string;
  event: EventDto;
  isConfidential?: boolean;
  isMobile?: boolean;
}

export default function EventReferenceHeader({
  title,
  event,
  isConfidential = false,
  isMobile = false,
}: EventReferenceHeaderProps) {
  const eventTitle = event.title;
  const eventDateTimeRange = event.dateTimeRange;
  const eventLocation = event.venue?.location;

  return (
    <EventReferenceHeaderContent
      title={title}
      eventTitle={eventTitle}
      eventDateTimeRange={eventDateTimeRange}
      isConfidential={isConfidential}
      isMobile={isMobile}
      locationReferenceComponent={eventLocation && <LocationReference locationReference={eventLocation} size="sm" />}
    />
  );
}

export interface PublicEventReferenceHeaderProps {
  title?: string;
  eventSettings: PublishedEventSettingsDto;
  isConfidential?: boolean;
  isMobile?: boolean;
}

export function PublicEventReferenceHeader({
  title,
  eventSettings,
  isConfidential = true,
  isMobile = false,
}: PublicEventReferenceHeaderProps) {
  const eventTitle = eventSettings.eventReference.title;
  const eventDateTimeRange = eventSettings.eventReference.dateTimeRange;
  const eventLocation = eventSettings.locationReference;

  return (
    <EventReferenceHeaderContent
      title={title}
      eventTitle={eventTitle}
      eventDateTimeRange={eventDateTimeRange}
      isConfidential={isConfidential}
      isMobile={isMobile}
      locationReferenceComponent={
        eventLocation && <LocationReferenceViewer locationReference={eventLocation} size="sm" />
      }
    />
  );
}

interface EventReferenceHeaderContentProps {
  title?: string;
  eventTitle: string;
  eventDateTimeRange: ClosedDateTimeRangeDto;
  isConfidential: boolean;
  isMobile: boolean;
  locationReferenceComponent?: React.ReactNode;
}

function EventReferenceHeaderContent({
  title,
  eventTitle,
  eventDateTimeRange,
  isConfidential,
  isMobile,
  locationReferenceComponent,
}: EventReferenceHeaderContentProps) {
  const { t } = useTranslation(['common', 'app']);

  return (
    <Stack spacing={isMobile ? 1 : 2}>
      <Heading size={isMobile ? 'sm' : 'md'} as="h3">
        {title ?? eventTitle}
      </Heading>
      <HStack>
        <HStack spacing={0}>
          <Text as="div" fontSize="sm" data-testid="event-time">
            {t('common:format.datetime_range', { dateTimeRange: eventDateTimeRange })}
          </Text>
          {locationReferenceComponent != null && (
            <Text as="div" fontSize="sm">
              ,
            </Text>
          )}
        </HStack>
        {locationReferenceComponent}
      </HStack>
      {isConfidential && (
        <HStack>
          <WarningIcon color="text.warning" />
          <Text>{t('app:confidential')}</Text>
        </HStack>
      )}
    </Stack>
  );
}
