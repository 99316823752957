/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';

/**
 * 
 * @export
 * @interface CopyProductRequestDto
 */
export interface CopyProductRequestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof CopyProductRequestDto
     */
    productIds: Array<string>;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof CopyProductRequestDto
     */
    edition: EditionReferenceDto;
}

/**
 * Check if a given object implements the CopyProductRequestDto interface.
 */
export function instanceOfCopyProductRequestDto(value: object): boolean {
    if (!('productIds' in value)) return false;
    if (!('edition' in value)) return false;
    return true;
}

export function CopyProductRequestDtoFromJSON(json: any): CopyProductRequestDto {
    return CopyProductRequestDtoFromJSONTyped(json, false);
}

export function CopyProductRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopyProductRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'productIds': json['productIds'],
        'edition': EditionReferenceDtoFromJSON(json['edition']),
    };
}

export function CopyProductRequestDtoToJSON(value?: CopyProductRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'productIds': value['productIds'],
        'edition': EditionReferenceDtoToJSON(value['edition']),
    };
}

