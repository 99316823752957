/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CancelInfoInnerDto
 */
export interface CancelInfoInnerDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof CancelInfoInnerDto
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof CancelInfoInnerDto
     */
    canCancel: boolean;
}

/**
 * Check if a given object implements the CancelInfoInnerDto interface.
 */
export function instanceOfCancelInfoInnerDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('canCancel' in value)) return false;
    return true;
}

export function CancelInfoInnerDtoFromJSON(json: any): CancelInfoInnerDto {
    return CancelInfoInnerDtoFromJSONTyped(json, false);
}

export function CancelInfoInnerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancelInfoInnerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'canCancel': json['canCancel'],
    };
}

export function CancelInfoInnerDtoToJSON(value?: CancelInfoInnerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'canCancel': value['canCancel'],
    };
}

