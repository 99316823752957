/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RessortDto = {
    OTHER: 'OTHER',
    ENTERTAINMENT_PEOPLE: 'ENTERTAINMENT_PEOPLE',
    FAMILY_CHILD_YOUTH: 'FAMILY_CHILD_YOUTH',
    FILM_FESTIVAL: 'FILM_FESTIVAL',
    SOCIETY_SOCIAL: 'SOCIETY_SOCIAL',
    FILM: 'FILM',
    CULTURE: 'CULTURE',
    LGBTQ_PLUS: 'LGBTQ_PLUS',
    LIFESTYLE: 'LIFESTYLE',
    MEDIA: 'MEDIA',
    SERIES: 'SERIES',
    FILM_BUSINESS: 'FILM_BUSINESS'
} as const;
export type RessortDto = typeof RessortDto[keyof typeof RessortDto];


export function RessortDtoFromJSON(json: any): RessortDto {
    return RessortDtoFromJSONTyped(json, false);
}

export function RessortDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RessortDto {
    return json as RessortDto;
}

export function RessortDtoToJSON(value?: RessortDto | null): any {
    return value as any;
}

