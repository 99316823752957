import { chakra, Stack } from '@chakra-ui/react';
import React from 'react';
import { EventVenueDto } from '../../../api';
import LocationReference from '../../location/location-reference/location-reference';

export interface VenueViewerProps {
  venue: EventVenueDto;
  size?: 'sm' | 'md';
}

export default function VenueViewer({ venue, size = 'md' }: VenueViewerProps) {
  return (
    <Stack spacing={0}>
      {venue.location && <LocationReference locationReference={venue.location} size={size} />}
      {venue.digitalVenue && (
        <chakra.div fontSize={size} noOfLines={1}>
          {venue.digitalVenue}
        </chakra.div>
      )}
    </Stack>
  );
}
