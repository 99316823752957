/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FormatCompanyConnectionTypeDto = {
    IN_HOUSE_PRODUCTION: 'IN_HOUSE_PRODUCTION',
    CONTRACT_PRODUCTION: 'CONTRACT_PRODUCTION'
} as const;
export type FormatCompanyConnectionTypeDto = typeof FormatCompanyConnectionTypeDto[keyof typeof FormatCompanyConnectionTypeDto];


export function FormatCompanyConnectionTypeDtoFromJSON(json: any): FormatCompanyConnectionTypeDto {
    return FormatCompanyConnectionTypeDtoFromJSONTyped(json, false);
}

export function FormatCompanyConnectionTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormatCompanyConnectionTypeDto {
    return json as FormatCompanyConnectionTypeDto;
}

export function FormatCompanyConnectionTypeDtoToJSON(value?: FormatCompanyConnectionTypeDto | null): any {
    return value as any;
}

