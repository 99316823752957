/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EventDto } from './EventDto';
import {
    EventDtoFromJSON,
    EventDtoFromJSONTyped,
    EventDtoToJSON,
} from './EventDto';

/**
 * 
 * @export
 * @interface EventSearchResultDto
 */
export interface EventSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof EventSearchResultDto
     */
    resultType: EventSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof EventSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {EventDto}
     * @memberof EventSearchResultDto
     */
    result: EventDto;
}


/**
 * @export
 */
export const EventSearchResultDtoResultTypeEnum = {
    EVENT: 'EVENT'
} as const;
export type EventSearchResultDtoResultTypeEnum = typeof EventSearchResultDtoResultTypeEnum[keyof typeof EventSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the EventSearchResultDto interface.
 */
export function instanceOfEventSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function EventSearchResultDtoFromJSON(json: any): EventSearchResultDto {
    return EventSearchResultDtoFromJSONTyped(json, false);
}

export function EventSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': EventDtoFromJSON(json['result']),
    };
}

export function EventSearchResultDtoToJSON(value?: EventSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': EventDtoToJSON(value['result']),
    };
}

