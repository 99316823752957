/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccessGroupReferenceDto } from './AccessGroupReferenceDto';
import {
    AccessGroupReferenceDtoFromJSON,
    AccessGroupReferenceDtoFromJSONTyped,
    AccessGroupReferenceDtoToJSON,
} from './AccessGroupReferenceDto';
import type { ClosedDateTimeRangeDto } from './ClosedDateTimeRangeDto';
import {
    ClosedDateTimeRangeDtoFromJSON,
    ClosedDateTimeRangeDtoFromJSONTyped,
    ClosedDateTimeRangeDtoToJSON,
} from './ClosedDateTimeRangeDto';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { EventAccessControlDto } from './EventAccessControlDto';
import {
    EventAccessControlDtoFromJSON,
    EventAccessControlDtoFromJSONTyped,
    EventAccessControlDtoToJSON,
} from './EventAccessControlDto';
import type { EventArchivedLinkDto } from './EventArchivedLinkDto';
import {
    EventArchivedLinkDtoFromJSON,
    EventArchivedLinkDtoFromJSONTyped,
    EventArchivedLinkDtoToJSON,
} from './EventArchivedLinkDto';
import type { EventEventConnectionDto } from './EventEventConnectionDto';
import {
    EventEventConnectionDtoFromJSON,
    EventEventConnectionDtoFromJSONTyped,
    EventEventConnectionDtoToJSON,
} from './EventEventConnectionDto';
import type { EventLinkDto } from './EventLinkDto';
import {
    EventLinkDtoFromJSON,
    EventLinkDtoFromJSONTyped,
    EventLinkDtoToJSON,
} from './EventLinkDto';
import type { EventPartnerDto } from './EventPartnerDto';
import {
    EventPartnerDtoFromJSON,
    EventPartnerDtoFromJSONTyped,
    EventPartnerDtoToJSON,
} from './EventPartnerDto';
import type { EventSecurityStaffDto } from './EventSecurityStaffDto';
import {
    EventSecurityStaffDtoFromJSON,
    EventSecurityStaffDtoFromJSONTyped,
    EventSecurityStaffDtoToJSON,
} from './EventSecurityStaffDto';
import type { EventStatusDto } from './EventStatusDto';
import {
    EventStatusDtoFromJSON,
    EventStatusDtoFromJSONTyped,
    EventStatusDtoToJSON,
} from './EventStatusDto';
import type { EventTypeDto } from './EventTypeDto';
import {
    EventTypeDtoFromJSON,
    EventTypeDtoFromJSONTyped,
    EventTypeDtoToJSON,
} from './EventTypeDto';
import type { EventVenueDto } from './EventVenueDto';
import {
    EventVenueDtoFromJSON,
    EventVenueDtoFromJSONTyped,
    EventVenueDtoToJSON,
} from './EventVenueDto';
import type { OwnerDto } from './OwnerDto';
import {
    OwnerDtoFromJSON,
    OwnerDtoFromJSONTyped,
    OwnerDtoToJSON,
} from './OwnerDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * An event of the Berlinale festival.
 * @export
 * @interface EventDto
 */
export interface EventDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof EventDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof EventDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof EventDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {EventVenueDto}
     * @memberof EventDto
     */
    venue: EventVenueDto;
    /**
     * 
     * @type {Array<OwnerDto>}
     * @memberof EventDto
     */
    owners: Array<OwnerDto>;
    /**
     * 
     * @type {EventPartnerDto}
     * @memberof EventDto
     */
    partners?: EventPartnerDto;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    englishTitle: string;
    /**
     * 
     * @type {ClosedDateTimeRangeDto}
     * @memberof EventDto
     */
    dateTimeRange: ClosedDateTimeRangeDto;
    /**
     * 
     * @type {EventStatusDto}
     * @memberof EventDto
     */
    status: EventStatusDto;
    /**
     * 
     * @type {EventTypeDto}
     * @memberof EventDto
     */
    type: EventTypeDto;
    /**
     * 
     * @type {Array<EventAccessControlDto>}
     * @memberof EventDto
     */
    accessControls: Array<EventAccessControlDto>;
    /**
     * 
     * @type {Array<AccessGroupReferenceDto>}
     * @memberof EventDto
     */
    accessGroups?: Array<AccessGroupReferenceDto>;
    /**
     * 
     * @type {boolean}
     * @memberof EventDto
     */
    visible: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    securityLevel: EventDtoSecurityLevelEnum;
    /**
     * 
     * @type {Array<EventSecurityStaffDto>}
     * @memberof EventDto
     */
    eventSecurityStaff?: Array<EventSecurityStaffDto>;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    catering?: EventDtoCateringEnum;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    dressCode?: EventDtoDressCodeEnum;
    /**
     * 
     * @type {Array<EventLinkDto>}
     * @memberof EventDto
     */
    links?: Array<EventLinkDto>;
    /**
     * 
     * @type {Array<EventArchivedLinkDto>}
     * @memberof EventDto
     */
    archivedLinks?: Array<EventArchivedLinkDto>;
    /**
     * List of event event connections
     * @type {Array<EventEventConnectionDto>}
     * @memberof EventDto
     */
    eventEventConnections?: Array<EventEventConnectionDto>;
}


/**
 * @export
 */
export const EventDtoSecurityLevelEnum = {
    REGULAR: 'REGULAR',
    ELEVATED: 'ELEVATED'
} as const;
export type EventDtoSecurityLevelEnum = typeof EventDtoSecurityLevelEnum[keyof typeof EventDtoSecurityLevelEnum];

/**
 * @export
 */
export const EventDtoCateringEnum = {
    NO_CATERING: 'NO_CATERING',
    COCKTAIL: 'COCKTAIL',
    FULL_CATERING: 'FULL_CATERING'
} as const;
export type EventDtoCateringEnum = typeof EventDtoCateringEnum[keyof typeof EventDtoCateringEnum];

/**
 * @export
 */
export const EventDtoDressCodeEnum = {
    NOT_SPECIFIED: 'NOT_SPECIFIED',
    BLACK_TIE: 'BLACK_TIE',
    BLACK_TIE_OPTIONAL: 'BLACK_TIE_OPTIONAL',
    COCKTAIL: 'COCKTAIL'
} as const;
export type EventDtoDressCodeEnum = typeof EventDtoDressCodeEnum[keyof typeof EventDtoDressCodeEnum];


/**
 * Check if a given object implements the EventDto interface.
 */
export function instanceOfEventDto(value: object): boolean {
    if (!('edition' in value)) return false;
    if (!('venue' in value)) return false;
    if (!('owners' in value)) return false;
    if (!('title' in value)) return false;
    if (!('englishTitle' in value)) return false;
    if (!('dateTimeRange' in value)) return false;
    if (!('status' in value)) return false;
    if (!('type' in value)) return false;
    if (!('accessControls' in value)) return false;
    if (!('visible' in value)) return false;
    if (!('securityLevel' in value)) return false;
    return true;
}

export function EventDtoFromJSON(json: any): EventDto {
    return EventDtoFromJSONTyped(json, false);
}

export function EventDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'venue': EventVenueDtoFromJSON(json['venue']),
        'owners': ((json['owners'] as Array<any>).map(OwnerDtoFromJSON)),
        'partners': json['partners'] == null ? undefined : EventPartnerDtoFromJSON(json['partners']),
        'title': json['title'],
        'englishTitle': json['englishTitle'],
        'dateTimeRange': ClosedDateTimeRangeDtoFromJSON(json['dateTimeRange']),
        'status': EventStatusDtoFromJSON(json['status']),
        'type': EventTypeDtoFromJSON(json['type']),
        'accessControls': ((json['accessControls'] as Array<any>).map(EventAccessControlDtoFromJSON)),
        'accessGroups': json['accessGroups'] == null ? undefined : ((json['accessGroups'] as Array<any>).map(AccessGroupReferenceDtoFromJSON)),
        'visible': json['visible'],
        'securityLevel': json['securityLevel'],
        'eventSecurityStaff': json['eventSecurityStaff'] == null ? undefined : ((json['eventSecurityStaff'] as Array<any>).map(EventSecurityStaffDtoFromJSON)),
        'catering': json['catering'] == null ? undefined : json['catering'],
        'dressCode': json['dressCode'] == null ? undefined : json['dressCode'],
        'links': json['links'] == null ? undefined : ((json['links'] as Array<any>).map(EventLinkDtoFromJSON)),
        'archivedLinks': json['archivedLinks'] == null ? undefined : ((json['archivedLinks'] as Array<any>).map(EventArchivedLinkDtoFromJSON)),
        'eventEventConnections': json['eventEventConnections'] == null ? undefined : ((json['eventEventConnections'] as Array<any>).map(EventEventConnectionDtoFromJSON)),
    };
}

export function EventDtoToJSON(value?: EventDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'venue': EventVenueDtoToJSON(value['venue']),
        'owners': ((value['owners'] as Array<any>).map(OwnerDtoToJSON)),
        'partners': EventPartnerDtoToJSON(value['partners']),
        'title': value['title'],
        'englishTitle': value['englishTitle'],
        'dateTimeRange': ClosedDateTimeRangeDtoToJSON(value['dateTimeRange']),
        'status': EventStatusDtoToJSON(value['status']),
        'type': EventTypeDtoToJSON(value['type']),
        'accessControls': ((value['accessControls'] as Array<any>).map(EventAccessControlDtoToJSON)),
        'accessGroups': value['accessGroups'] == null ? undefined : ((value['accessGroups'] as Array<any>).map(AccessGroupReferenceDtoToJSON)),
        'visible': value['visible'],
        'securityLevel': value['securityLevel'],
        'eventSecurityStaff': value['eventSecurityStaff'] == null ? undefined : ((value['eventSecurityStaff'] as Array<any>).map(EventSecurityStaffDtoToJSON)),
        'catering': value['catering'],
        'dressCode': value['dressCode'],
        'links': value['links'] == null ? undefined : ((value['links'] as Array<any>).map(EventLinkDtoToJSON)),
        'archivedLinks': value['archivedLinks'] == null ? undefined : ((value['archivedLinks'] as Array<any>).map(EventArchivedLinkDtoToJSON)),
        'eventEventConnections': value['eventEventConnections'] == null ? undefined : ((value['eventEventConnections'] as Array<any>).map(EventEventConnectionDtoToJSON)),
    };
}

