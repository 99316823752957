import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { landingPageDesignIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function TeamSearchResult({ result, onClick }: SearchResultProps) {
  invariant(
    result.resultType === 'LANDING_PAGE_DESIGN',
    `${result.resultType} is no instance of LandingPageDesignSearchResult`,
  );

  const landingPageDesign = result.result;
  const { t } = useTranslation(['landing_page_design', 'common']);

  return (
    <SearchResult
      icon={landingPageDesignIcon}
      title={landingPageDesign.brand}
      link={`/landing-page-designs/${landingPageDesign.id}`}
      score={result.score}
      onClick={onClick}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('landing_page_design:status'),
            element: t(`statusOptions.${landingPageDesign.status}`),
          },
          {
            label: t('edition'),
            element: landingPageDesign.edition?.name,
          },
        ]}
      />
    </SearchResult>
  );
}
