/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GroupPersonRelationDto } from './GroupPersonRelationDto';
import {
    GroupPersonRelationDtoFromJSON,
    GroupPersonRelationDtoFromJSONTyped,
    GroupPersonRelationDtoToJSON,
} from './GroupPersonRelationDto';

/**
 * 
 * @export
 * @interface GroupPersonPageDto
 */
export interface GroupPersonPageDto {
    /**
     * The number of the page.
     * @type {number}
     * @memberof GroupPersonPageDto
     */
    page: number;
    /**
     * The size of the page.
     * @type {number}
     * @memberof GroupPersonPageDto
     */
    size: number;
    /**
     * The total number of data returned by the search.
     * @type {number}
     * @memberof GroupPersonPageDto
     */
    totalElements: number;
    /**
     * The total number of pages of current size to fit all data.
     * @type {number}
     * @memberof GroupPersonPageDto
     */
    totalPages: number;
    /**
     * 
     * @type {Array<GroupPersonRelationDto>}
     * @memberof GroupPersonPageDto
     */
    content: Array<GroupPersonRelationDto>;
}

/**
 * Check if a given object implements the GroupPersonPageDto interface.
 */
export function instanceOfGroupPersonPageDto(value: object): boolean {
    if (!('page' in value)) return false;
    if (!('size' in value)) return false;
    if (!('totalElements' in value)) return false;
    if (!('totalPages' in value)) return false;
    if (!('content' in value)) return false;
    return true;
}

export function GroupPersonPageDtoFromJSON(json: any): GroupPersonPageDto {
    return GroupPersonPageDtoFromJSONTyped(json, false);
}

export function GroupPersonPageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupPersonPageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'page': json['page'],
        'size': json['size'],
        'totalElements': json['totalElements'],
        'totalPages': json['totalPages'],
        'content': ((json['content'] as Array<any>).map(GroupPersonRelationDtoFromJSON)),
    };
}

export function GroupPersonPageDtoToJSON(value?: GroupPersonPageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'page': value['page'],
        'size': value['size'],
        'totalElements': value['totalElements'],
        'totalPages': value['totalPages'],
        'content': ((value['content'] as Array<any>).map(GroupPersonRelationDtoToJSON)),
    };
}

