import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchLandingPageDesign } from '../landing-page-design-queries';

export default function LandingPageDesignBreadcrumb() {
  const { designId } = useParams<{ designId: string }>();
  invariant(designId, 'Empty designId');
  const landingPageDesign = useFetcher(fetchLandingPageDesign, { id: designId });
  const breadcrumb =
    landingPageDesign.edition != null
      ? landingPageDesign.brand + ' (' + landingPageDesign.edition.name + ')'
      : landingPageDesign.brand;

  return <>{breadcrumb}</>;
}
