import React from 'react';
import invariant from 'tiny-invariant';
import { ConnectedEntityReferenceDto, LandingPageReferenceDto } from '../../../api';
import guestListApi from '../../../data-access/guest-list-api';
import { PageTab } from '../../../ui/page';
import Translate from '../../../util/translate/translate';
import EventReference from '../../event/event-reference/event-reference';
import { EventMailingConnectionSelect } from '../../event/mailing/event-mailing-connection-select';
import { EventMailingInfoAddon } from '../../event/mailing/event-mailing-info-addon';
import MailingTypeExtension from '../../mailing/mailing-type-extension';
import EmergencyGuestListMailingRecipientEditor from './emergency-guest-list/emergency-guest-list-mailing-recipient-editor';
import EmergencyGuestListMailingRecipientLister from './emergency-guest-list/emergency-guest-list-mailing-recipient-lister';
import { GuestListMailingBannerAddon } from './guest-list-mailing-banner-addon';
import PersonalizedGuestListInvitationMailingRecipientEditor from './personalized-guest-list-invitation/personalized-guest-list-invitation-mailing-recipient-editor';
import PersonalizedGuestListInvitationMailingRecipientLister from './personalized-guest-list-invitation/personalized-guest-list-invitation-mailing-recipient-lister';
import TicketGuestListMailingRecipientEditor from './ticket-guest-list/ticket-guest-list-mailing-recipient-editor';
import TicketGuestListMailingRecipientLister from './ticket-guest-list/ticket-guest-list-mailing-recipient-lister';
import UnpersonalizedGuestListInvitationMailingRecipientEditor from './unpersonalized-guest-list-invitation/unpersonalized-guest-list-invitation-mailing-recipient-editor';
import UnpersonalizedGuestListInvitationMailingRecipientLister from './unpersonalized-guest-list-invitation/unpersonalized-guest-list-invitation-mailing-recipient-lister';

function createInvitationExtension(extension: Omit<MailingTypeExtension, 'connectionSelect' | 'reference'>) {
  return {
    connectionSelect: <EventMailingConnectionSelect />,
    reference: (event: ConnectedEntityReferenceDto) => <EventReference event={event} />,
    bannerAddon: (eventId: string) => <GuestListMailingBannerAddon eventId={eventId} />,
    infoAddon: (eventId: string) => <EventMailingInfoAddon eventId={eventId} />,
    ...extension,
  } satisfies MailingTypeExtension;
}

export const invitationGuestListPersonalizedExtension = createInvitationExtension({
  type: 'INVITATION_GUEST_LIST_PERSONALIZED',
  recipientEditorAddon: ({ connectionId: eventId, ...props }) => {
    invariant(eventId != null, 'Event ID must be set');

    return <PersonalizedGuestListInvitationMailingRecipientEditor {...props} eventId={eventId} />;
  },
  recipientListerAddon: ({ connectionId: eventId, ...props }) => {
    invariant(eventId != null, 'Event ID must be set');

    return <PersonalizedGuestListInvitationMailingRecipientLister {...props} eventId={eventId} />;
  },
  landingPageConfig: {
    helperText: <Translate ns="guest_list" i18nKey="landingPage.availableHelperText" />,
    async fetchAvailable({ connectionId: eventId, q, size }): Promise<LandingPageReferenceDto[]> {
      invariant(eventId != null, 'missing eventId');
      const { content } = await guestListApi.findLinkableLandingPagesForGuestList({
        eventId,
        pageable: { size, sort: ['name,ASC'] },
        q,
      });
      return content;
    },
  },
});

export const invitationGuestListUnpersonalizedExtension = createInvitationExtension({
  type: 'INVITATION_GUEST_LIST_UNPERSONALIZED',
  recipientEditorAddon: ({ ...props }) => <UnpersonalizedGuestListInvitationMailingRecipientEditor {...props} />,
  recipientListerAddon: ({ ...props }) => <UnpersonalizedGuestListInvitationMailingRecipientLister {...props} />,
  landingPageConfig: {
    helperText: <Translate ns="guest_list" i18nKey="landingPage.availableHelperText" />,
    async fetchAvailable({ connectionId: eventId, q, size }): Promise<LandingPageReferenceDto[]> {
      invariant(eventId != null, 'missing eventId');
      const { content } = await guestListApi.findLinkableLandingPagesForGuestList({
        eventId,
        pageable: { size, sort: ['name,ASC'] },
        q,
      });
      return content;
    },
  },
  tabsAddon: (builder) =>
    builder.after('recipients', {
      key: 'dummies',
      element: (
        <PageTab to="dummies">
          <Translate ns="event" i18nKey="mailingExtension.dummy.label" />
        </PageTab>
      ),
    }),
  pageRoutes: [
    {
      path: 'dummies',
      lazy: () =>
        import('./unpersonalized-guest-list-invitation/unpersonalized-guest-list-invitation-dummy-lister-route'),
    },
  ],
  routes: [
    {
      path: 'dummies/edit',
      lazy: () =>
        import('./unpersonalized-guest-list-invitation/unpersonalized-guest-list-invitation-dummy-editor-route'),
    },
  ],
});

export const invitationGuestListEmergencyExtension = createInvitationExtension({
  type: 'INVITATION_GUEST_LIST_EMERGENCY',
  recipientEditorAddon: ({ connectionId: eventId, ...props }) => {
    invariant(eventId != null, 'Event ID must be set');
    return <EmergencyGuestListMailingRecipientEditor {...props} eventId={eventId} />;
  },
  recipientListerAddon: ({ connectionId: eventId, ...props }) => {
    invariant(eventId != null, 'Event ID must be set');

    return <EmergencyGuestListMailingRecipientLister {...props} eventId={eventId} />;
  },
});

export const ticketGuestListInitialExtension = createInvitationExtension({
  type: 'TICKET_GUEST_LIST_INITIAL',
  recipientEditorAddon: ({ connectionId: eventId, ...props }) => {
    invariant(eventId != null, 'Event ID must be set');

    return (
      <TicketGuestListMailingRecipientEditor
        {...props}
        eventId={eventId}
        helperText={<Translate ns="event" i18nKey="mailingExtension.recipients.initial_ticket_helperText" />}
      />
    );
  },
  recipientListerAddon: ({ connectionId: eventId, ...props }) => {
    invariant(eventId != null, 'Event ID must be set');

    return <TicketGuestListMailingRecipientLister {...props} eventId={eventId} />;
  },
});

export const ticketGuestListInitialWithoutPlacementExtension = createInvitationExtension({
  type: 'TICKET_GUEST_LIST_INITIAL_WITHOUT_PLACEMENT',
  recipientEditorAddon: ({ connectionId: eventId, ...props }) => {
    invariant(eventId != null, 'Event ID must be set');

    return (
      <TicketGuestListMailingRecipientEditor
        {...props}
        eventId={eventId}
        helperText={
          <Translate ns="event" i18nKey="mailingExtension.recipients.initial_ticket_without_placement_helperText" />
        }
      />
    );
  },
  recipientListerAddon: ({ connectionId: eventId, ...props }) => {
    invariant(eventId != null, 'Event ID must be set');

    return <TicketGuestListMailingRecipientLister {...props} eventId={eventId} />;
  },
});

export const ticketGuestListReplaceExtension = createInvitationExtension({
  type: 'TICKET_GUEST_LIST_REPLACE',
  recipientEditorAddon: ({ connectionId: eventId, ...props }) => {
    invariant(eventId != null, 'Event ID must be set');
    return (
      <TicketGuestListMailingRecipientEditor
        {...props}
        eventId={eventId}
        helperText={<Translate ns="event" i18nKey="mailingExtension.recipients.ticket_changed_helperText" />}
      />
    );
  },
  recipientListerAddon: ({ connectionId: eventId, ...props }) => {
    invariant(eventId != null, 'Event ID must be set');

    return <TicketGuestListMailingRecipientLister {...props} eventId={eventId} />;
  },
});

export const ticketGuestListCancelExtension = createInvitationExtension({
  type: 'TICKET_GUEST_LIST_CANCEL',
  recipientEditorAddon: ({ connectionId: eventId, ...props }) => {
    invariant(eventId != null, 'Event ID must be set');
    return (
      <TicketGuestListMailingRecipientEditor
        {...props}
        eventId={eventId}
        helperText={<Translate ns="event" i18nKey="mailingExtension.recipients.ticket_cancelled_helperText" />}
      />
    );
  },
  recipientListerAddon: ({ connectionId: eventId, ...props }) => {
    invariant(eventId != null, 'Event ID must be set');

    return <TicketGuestListMailingRecipientLister {...props} eventId={eventId} />;
  },
});
