/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StandTagPermissionDto
 */
export interface StandTagPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof StandTagPermissionDto
     */
    name: StandTagPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof StandTagPermissionDto
     */
    action: StandTagPermissionDtoActionEnum;
}


/**
 * @export
 */
export const StandTagPermissionDtoNameEnum = {
    STAND_TAG: 'STAND-TAG'
} as const;
export type StandTagPermissionDtoNameEnum = typeof StandTagPermissionDtoNameEnum[keyof typeof StandTagPermissionDtoNameEnum];

/**
 * @export
 */
export const StandTagPermissionDtoActionEnum = {
    LINK: 'CAN_LINK',
    UNLINK: 'CAN_UNLINK'
} as const;
export type StandTagPermissionDtoActionEnum = typeof StandTagPermissionDtoActionEnum[keyof typeof StandTagPermissionDtoActionEnum];


/**
 * Check if a given object implements the StandTagPermissionDto interface.
 */
export function instanceOfStandTagPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function StandTagPermissionDtoFromJSON(json: any): StandTagPermissionDto {
    return StandTagPermissionDtoFromJSONTyped(json, false);
}

export function StandTagPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandTagPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function StandTagPermissionDtoToJSON(value?: StandTagPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

