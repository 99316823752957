import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Card, Center, Icon, Link, Stack, Text } from '@chakra-ui/react';
import { faConstruction } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export function NotYetImplemented({ ticketId }: { ticketId?: string }) {
  return (
    <Card size="xl" p={8}>
      <Stack>
        <Center>
          <Icon as={FontAwesomeIcon} icon={faConstruction} size="10x" color="text.warning" />
          <Text fontSize="2xl">
            Noch nicht umgesetzt.{' '}
            {ticketId && (
              <>
                Komm wieder, sobald Ticket{' '}
                <Link as={RouterLink} to={`https://jira.kbb.eu/browse/${ticketId}`}>
                  {ticketId} <ExternalLinkIcon mx="2px" />
                </Link>{' '}
                fertig ist!
              </>
            )}
          </Text>
        </Center>
      </Stack>
    </Card>
  );
}
