import { Input, InputGroup, InputRightAddon } from '@chakra-ui/react';
import { ReactNode, useMemo } from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CURRENCY_AMOUNT } from '../../../util/constants';
import FormControl from '../form-control';
import useFormatterNumberInput from '../use-formatted-number-input';

interface CurrencyFormControlProps<TFieldValues extends FieldValues> {
  name: FieldPath<TFieldValues>;
  label: ReactNode;
  isRequired?: boolean;
  min?: number;
  max?: number;
}

export default function CurrencyFormControl<TFieldValues extends FieldValues>({
  name,
  label,
  isRequired,
  min,
  max,
}: CurrencyFormControlProps<TFieldValues>) {
  const { t } = useTranslation('common');
  const formatter = useMemo(() => {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency: 'EUR',
      useGrouping: true,
    });
  }, []);
  const { field } = useController({
    name,
    rules: {
      required: isRequired ? t('validation_error.required', { field: label }) : undefined,
      pattern: {
        value: CURRENCY_AMOUNT,
        message: t('validation_error.invalidCurrencyAmount'),
      },
      validate: {
        min: (value: string | number) => {
          if (min != null && typeof value === 'number' && value < min) {
            return t('validation_error.min_number', { field: label, min: formatter.format(min) });
          }

          return true;
        },
        max: (value: string | number) => {
          if (max != null && typeof value === 'number' && value > max) {
            return t('validation_error.max_number', { field: label, max: formatter.format(max) });
          }

          return true;
        },
      },
    },
  });

  const { getInputProps } = useFormatterNumberInput({
    value: field.value,
    onChange: field.onChange,
    minFractionDigits: 2,
    maxFractionDigits: 2,
  });

  return (
    <FormControl name={name} label={label} isRequired={isRequired}>
      <InputGroup>
        <Input ref={field.ref} {...getInputProps({ onBlur: field.onBlur })} />
        <InputRightAddon>€</InputRightAddon>
      </InputGroup>
    </FormControl>
  );
}
