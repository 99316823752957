import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import TagBreadcrumb from './common/tag-breadcrumb';

const tagRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'tags',
    handle: {
      breadcrumb: <Translate ns="tag" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./tag-lister/tag-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./tag-editor/new-tag-route'),
      },
      {
        path: ':tagId',
        handle: {
          breadcrumb: <TagBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./tag-page/tag-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./tag-viewer/tag-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./tag-history/tag-history-route'),
              },
              {
                path: 'relations',
                lazy: () => import('./tag-relations/tag-relations-route'),
              },
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./tag-editor/edit-tag-route'),
          },
        ],
      },
    ],
  },
];

export default tagRoutes;
