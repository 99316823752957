/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InvoiceDto } from './InvoiceDto';
import {
    InvoiceDtoFromJSON,
    InvoiceDtoFromJSONTyped,
    InvoiceDtoToJSON,
} from './InvoiceDto';

/**
 * 
 * @export
 * @interface InvoiceSearchResultDto
 */
export interface InvoiceSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof InvoiceSearchResultDto
     */
    resultType: InvoiceSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof InvoiceSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {InvoiceDto}
     * @memberof InvoiceSearchResultDto
     */
    result: InvoiceDto;
}


/**
 * @export
 */
export const InvoiceSearchResultDtoResultTypeEnum = {
    INVOICE: 'INVOICE'
} as const;
export type InvoiceSearchResultDtoResultTypeEnum = typeof InvoiceSearchResultDtoResultTypeEnum[keyof typeof InvoiceSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the InvoiceSearchResultDto interface.
 */
export function instanceOfInvoiceSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function InvoiceSearchResultDtoFromJSON(json: any): InvoiceSearchResultDto {
    return InvoiceSearchResultDtoFromJSONTyped(json, false);
}

export function InvoiceSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': InvoiceDtoFromJSON(json['result']),
    };
}

export function InvoiceSearchResultDtoToJSON(value?: InvoiceSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': InvoiceDtoToJSON(value['result']),
    };
}

