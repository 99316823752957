import { BlockFormatOption } from '../format-types';
import { Render } from '../render';
import { WithFunction } from './create-editor';

const format: BlockFormatOption = 'paragraph';

const withParagraph: (options: { render: Render<'block'>['render'] }) => WithFunction =
  ({ render }) =>
  (editor) => {
    const paragraphStyle: Render<'block'> = {
      type: format,
      render,
    };
    editor.renderers = [...editor.renderers, paragraphStyle];

    return editor;
  };

export default withParagraph;
