/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { FilmStatusDto } from './FilmStatusDto';
import {
    FilmStatusDtoFromJSON,
    FilmStatusDtoFromJSONTyped,
    FilmStatusDtoToJSON,
} from './FilmStatusDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface FilmListItemDto
 */
export interface FilmListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof FilmListItemDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof FilmListItemDto
     */
    ffId: number;
    /**
     * A URL restricted through uri format of open api
     * @type {string}
     * @memberof FilmListItemDto
     */
    ffLink?: string;
    /**
     * 
     * @type {string}
     * @memberof FilmListItemDto
     */
    originalTitle: string;
    /**
     * 
     * @type {string}
     * @memberof FilmListItemDto
     */
    englishTitle: string;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof FilmListItemDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {FilmStatusDto}
     * @memberof FilmListItemDto
     */
    status: FilmStatusDto;
    /**
     * 
     * @type {VersionDto}
     * @memberof FilmListItemDto
     */
    version: VersionDto;
}

/**
 * Check if a given object implements the FilmListItemDto interface.
 */
export function instanceOfFilmListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('ffId' in value)) return false;
    if (!('originalTitle' in value)) return false;
    if (!('englishTitle' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('status' in value)) return false;
    if (!('version' in value)) return false;
    return true;
}

export function FilmListItemDtoFromJSON(json: any): FilmListItemDto {
    return FilmListItemDtoFromJSONTyped(json, false);
}

export function FilmListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilmListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'ffId': json['ffId'],
        'ffLink': json['ffLink'] == null ? undefined : json['ffLink'],
        'originalTitle': json['originalTitle'],
        'englishTitle': json['englishTitle'],
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'status': FilmStatusDtoFromJSON(json['status']),
        'version': VersionDtoFromJSON(json['version']),
    };
}

export function FilmListItemDtoToJSON(value?: FilmListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'ffId': value['ffId'],
        'ffLink': value['ffLink'],
        'originalTitle': value['originalTitle'],
        'englishTitle': value['englishTitle'],
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'status': FilmStatusDtoToJSON(value['status']),
        'version': VersionDtoToJSON(value['version']),
    };
}

