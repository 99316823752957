import { Text } from '@chakra-ui/react';
import { isArray, isEmpty as lodashIsEmpty } from 'lodash-es';

import React from 'react';

interface OptionalProps {
  children?: React.ReactNode;
  /**
   * @deprecated
   */
  isEmpty?: boolean;
}

export default function Optional({ children, isEmpty }: OptionalProps) {
  // lh, vb: Be as explicit as possible with falsy values (e.g. „0“ is a set value)
  if (
    children === false ||
    children === '' ||
    children == null ||
    (isArray(children) && lodashIsEmpty(children)) ||
    isEmpty
  ) {
    return (
      <Text sx={{ color: 'text.muted' }} aria-hidden="true">
        —
      </Text>
    );
  }

  return <>{children}</>;
}
