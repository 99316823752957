/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GroupTemplateListItemDto } from './GroupTemplateListItemDto';
import {
    GroupTemplateListItemDtoFromJSON,
    GroupTemplateListItemDtoFromJSONTyped,
    GroupTemplateListItemDtoToJSON,
} from './GroupTemplateListItemDto';

/**
 * 
 * @export
 * @interface GroupTemplatePageDto
 */
export interface GroupTemplatePageDto {
    /**
     * The number of the page.
     * @type {number}
     * @memberof GroupTemplatePageDto
     */
    page: number;
    /**
     * The size of the page.
     * @type {number}
     * @memberof GroupTemplatePageDto
     */
    size: number;
    /**
     * The total number of data returned by the search.
     * @type {number}
     * @memberof GroupTemplatePageDto
     */
    totalElements: number;
    /**
     * The total number of pages of current size to fit all data.
     * @type {number}
     * @memberof GroupTemplatePageDto
     */
    totalPages: number;
    /**
     * 
     * @type {Array<GroupTemplateListItemDto>}
     * @memberof GroupTemplatePageDto
     */
    content: Array<GroupTemplateListItemDto>;
}

/**
 * Check if a given object implements the GroupTemplatePageDto interface.
 */
export function instanceOfGroupTemplatePageDto(value: object): boolean {
    if (!('page' in value)) return false;
    if (!('size' in value)) return false;
    if (!('totalElements' in value)) return false;
    if (!('totalPages' in value)) return false;
    if (!('content' in value)) return false;
    return true;
}

export function GroupTemplatePageDtoFromJSON(json: any): GroupTemplatePageDto {
    return GroupTemplatePageDtoFromJSONTyped(json, false);
}

export function GroupTemplatePageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupTemplatePageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'page': json['page'],
        'size': json['size'],
        'totalElements': json['totalElements'],
        'totalPages': json['totalPages'],
        'content': ((json['content'] as Array<any>).map(GroupTemplateListItemDtoFromJSON)),
    };
}

export function GroupTemplatePageDtoToJSON(value?: GroupTemplatePageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'page': value['page'],
        'size': value['size'],
        'totalElements': value['totalElements'],
        'totalPages': value['totalPages'],
        'content': ((value['content'] as Array<any>).map(GroupTemplateListItemDtoToJSON)),
    };
}

