/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MailingTemplateStatusDto = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
} as const;
export type MailingTemplateStatusDto = typeof MailingTemplateStatusDto[keyof typeof MailingTemplateStatusDto];


export function MailingTemplateStatusDtoFromJSON(json: any): MailingTemplateStatusDto {
    return MailingTemplateStatusDtoFromJSONTyped(json, false);
}

export function MailingTemplateStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingTemplateStatusDto {
    return json as MailingTemplateStatusDto;
}

export function MailingTemplateStatusDtoToJSON(value?: MailingTemplateStatusDto | null): any {
    return value as any;
}

