/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FileMetadataDto } from './FileMetadataDto';
import {
    FileMetadataDtoFromJSON,
    FileMetadataDtoFromJSONTyped,
    FileMetadataDtoToJSON,
} from './FileMetadataDto';
import type { GuestTypeDto } from './GuestTypeDto';
import {
    GuestTypeDtoFromJSON,
    GuestTypeDtoFromJSONTyped,
    GuestTypeDtoToJSON,
} from './GuestTypeDto';

/**
 * 
 * @export
 * @interface PrintSeatDetailsDto
 */
export interface PrintSeatDetailsDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof PrintSeatDetailsDto
     */
    seatId: string;
    /**
     * 
     * @type {string}
     * @memberof PrintSeatDetailsDto
     */
    area: string;
    /**
     * 
     * @type {string}
     * @memberof PrintSeatDetailsDto
     */
    row: string;
    /**
     * 
     * @type {string}
     * @memberof PrintSeatDetailsDto
     */
    seat: string;
    /**
     * 
     * @type {GuestTypeDto}
     * @memberof PrintSeatDetailsDto
     */
    guestType: GuestTypeDto;
    /**
     * 
     * @type {string}
     * @memberof PrintSeatDetailsDto
     */
    name?: string;
    /**
     * 
     * @type {FileMetadataDto}
     * @memberof PrintSeatDetailsDto
     */
    photoMetadata?: FileMetadataDto;
    /**
     * 
     * @type {string}
     * @memberof PrintSeatDetailsDto
     */
    jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof PrintSeatDetailsDto
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof PrintSeatDetailsDto
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof PrintSeatDetailsDto
     */
    mainCoupledPerson?: string;
}

/**
 * Check if a given object implements the PrintSeatDetailsDto interface.
 */
export function instanceOfPrintSeatDetailsDto(value: object): boolean {
    if (!('seatId' in value)) return false;
    if (!('area' in value)) return false;
    if (!('row' in value)) return false;
    if (!('seat' in value)) return false;
    if (!('guestType' in value)) return false;
    return true;
}

export function PrintSeatDetailsDtoFromJSON(json: any): PrintSeatDetailsDto {
    return PrintSeatDetailsDtoFromJSONTyped(json, false);
}

export function PrintSeatDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrintSeatDetailsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'seatId': json['seatId'],
        'area': json['area'],
        'row': json['row'],
        'seat': json['seat'],
        'guestType': GuestTypeDtoFromJSON(json['guestType']),
        'name': json['name'] == null ? undefined : json['name'],
        'photoMetadata': json['photoMetadata'] == null ? undefined : FileMetadataDtoFromJSON(json['photoMetadata']),
        'jobTitle': json['jobTitle'] == null ? undefined : json['jobTitle'],
        'company': json['company'] == null ? undefined : json['company'],
        'number': json['number'] == null ? undefined : json['number'],
        'mainCoupledPerson': json['mainCoupledPerson'] == null ? undefined : json['mainCoupledPerson'],
    };
}

export function PrintSeatDetailsDtoToJSON(value?: PrintSeatDetailsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'seatId': value['seatId'],
        'area': value['area'],
        'row': value['row'],
        'seat': value['seat'],
        'guestType': GuestTypeDtoToJSON(value['guestType']),
        'name': value['name'],
        'photoMetadata': FileMetadataDtoToJSON(value['photoMetadata']),
        'jobTitle': value['jobTitle'],
        'company': value['company'],
        'number': value['number'],
        'mainCoupledPerson': value['mainCoupledPerson'],
    };
}

