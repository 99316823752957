const Spinner = {
  defaultProps: {
    size: 'xl',
    speed: '1s',
    emptyColor: 'border.01',
    thickness: '2px',
    margin: 'auto',
  },
  baseStyle: {
    color: 'text.interactive',
  },
};

export default Spinner;
