import { SectionStaffListItemDto } from '../../../../api';
import { DataTableColumn } from '../../../../ui/data-table';
import { PluginToken } from '../../../../util/plugin/use-plugins';

export interface SectionStaffListerColumnExtension {
  columnToAdd: DataTableColumn<SectionStaffListItemDto>;
}

export const SECTION_STAFF_LISTER_COLUMN_EXTENSION = new PluginToken<SectionStaffListerColumnExtension>(
  'SectionStaffListerColumnExtension',
);
