import React, { ReactNode } from 'react';
import invariant from 'tiny-invariant';
import { LandingPageDto } from '../../api';
import HistoryDisplaySettings from '../../ui/history/history-display-settings';
import usePlugins, { PluginToken } from '../../util/plugin/use-plugins';

export const LANDING_PAGE_EXTENSION = new PluginToken<LandingPageExtension>('LandingPageExtension');

export interface LandingPageExtension {
  type: string;
  createFormExtension: ReactNode;
  omitEdition?: boolean;
  searchResultConnection?: (props: { onClick: () => void; landingPage: LandingPageDto }) => React.ReactNode;
  historyExtension?: Pick<HistoryDisplaySettings<LandingPageDto>, 'valueFormatter' | 'attributeLabels'>;

  addSearchParams?(searchParams: URLSearchParams, extensionData: unknown): void;
  parseSearchParams?(searchParams: URLSearchParams, landingPage: LandingPageDto): void;
}

export function useLandingPageExtensions(): LandingPageExtension[] {
  return usePlugins(LANDING_PAGE_EXTENSION);
}

export default function useLandingPageExtension(): (type: string) => LandingPageExtension {
  const extensions = useLandingPageExtensions();

  return React.useCallback(
    (type) => {
      const extension = extensions.find((extension) => extension.type === type);
      invariant(extension != null, `Unknown extension type ${type}`);

      return extension;
    },
    [extensions],
  );
}
