/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MergeFormatIdsDto
 */
export interface MergeFormatIdsDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof MergeFormatIdsDto
     */
    formatAId: string;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof MergeFormatIdsDto
     */
    formatBId: string;
}

/**
 * Check if a given object implements the MergeFormatIdsDto interface.
 */
export function instanceOfMergeFormatIdsDto(value: object): boolean {
    if (!('formatAId' in value)) return false;
    if (!('formatBId' in value)) return false;
    return true;
}

export function MergeFormatIdsDtoFromJSON(json: any): MergeFormatIdsDto {
    return MergeFormatIdsDtoFromJSONTyped(json, false);
}

export function MergeFormatIdsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MergeFormatIdsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'formatAId': json['formatAId'],
        'formatBId': json['formatBId'],
    };
}

export function MergeFormatIdsDtoToJSON(value?: MergeFormatIdsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'formatAId': value['formatAId'],
        'formatBId': value['formatBId'],
    };
}

