/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The type and subtype of a mailing template
 * @export
 */
export const MailingTypeDto = {
    INVITATION_GUEST_LIST_PERSONALIZED: 'INVITATION_GUEST_LIST_PERSONALIZED',
    INVITATION_GUEST_LIST_UNPERSONALIZED: 'INVITATION_GUEST_LIST_UNPERSONALIZED',
    INVITATION_GUEST_LIST_EMERGENCY: 'INVITATION_GUEST_LIST_EMERGENCY',
    TICKET_GUEST_LIST_INITIAL: 'TICKET_GUEST_LIST_INITIAL',
    TICKET_GUEST_LIST_REPLACE: 'TICKET_GUEST_LIST_REPLACE',
    TICKET_GUEST_LIST_CANCEL: 'TICKET_GUEST_LIST_CANCEL',
    TICKET_GUEST_LIST_INITIAL_WITHOUT_PLACEMENT: 'TICKET_GUEST_LIST_INITIAL_WITHOUT_PLACEMENT',
    SYSTEM_MAILING: 'SYSTEM_MAILING',
    STANDARD_INFO: 'STANDARD_INFO',
    CODE: 'CODE'
} as const;
export type MailingTypeDto = typeof MailingTypeDto[keyof typeof MailingTypeDto];


export function MailingTypeDtoFromJSON(json: any): MailingTypeDto {
    return MailingTypeDtoFromJSONTyped(json, false);
}

export function MailingTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingTypeDto {
    return json as MailingTypeDto;
}

export function MailingTypeDtoToJSON(value?: MailingTypeDto | null): any {
    return value as any;
}

