import React from 'react';
import { countryIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import CountrySearchResult from './country-search-result';

export default function CountryPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, { type: 'COUNTRY', Component: CountrySearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'COUNTRY',
    icon: countryIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.COUNTRY" />,
  });
}
