import React from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import useFetcher from '../../util/swr/use-fetcher';
import Translate from '../../util/translate/translate';
import { fetchStaff } from './staff-queries';
import registry from '../../registry';
import { STAFF_PAGE_ROUTES_CALLBACK } from './staff-page/staff-page-tab-extension';

const staffRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'staff',
    handle: {
      breadcrumb: <Translate ns="staff" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./staff-lister/staff-lister-route'),
      },
      {
        path: 'new/:personId',
        lazy: () => import('./staff-editor/new-staff-route'),
      },
      {
        path: ':staffId',
        handle: {
          breadcrumb: <StaffBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./staff-page/staff-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./staff-viewer/staff-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./staff-history/staff-history-route'),
              },
              ...registry.callAll(STAFF_PAGE_ROUTES_CALLBACK).flat(),
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./staff-editor/edit-staff-route'),
          },
        ],
      },
    ],
  },
];

export default staffRoutes;

function StaffBreadcrumb() {
  const { staffId } = useParams<{ staffId: string }>();
  invariant(staffId, 'Empty staffId');
  const staff = useFetcher(fetchStaff, { id: staffId });

  return <>{`${staff.firstName} ${staff.surname}`}</>;
}
