/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GroupDto } from './GroupDto';
import {
    GroupDtoFromJSON,
    GroupDtoFromJSONTyped,
    GroupDtoToJSON,
} from './GroupDto';
import type { PersonToAddDto } from './PersonToAddDto';
import {
    PersonToAddDtoFromJSON,
    PersonToAddDtoFromJSONTyped,
    PersonToAddDtoToJSON,
} from './PersonToAddDto';

/**
 * Dto to add multiple person to a group.
 * @export
 * @interface AddPeopleToGroupDto
 */
export interface AddPeopleToGroupDto {
    /**
     * 
     * @type {Array<PersonToAddDto>}
     * @memberof AddPeopleToGroupDto
     */
    people: Array<PersonToAddDto>;
    /**
     * 
     * @type {GroupDto}
     * @memberof AddPeopleToGroupDto
     */
    group: GroupDto;
}

/**
 * Check if a given object implements the AddPeopleToGroupDto interface.
 */
export function instanceOfAddPeopleToGroupDto(value: object): boolean {
    if (!('people' in value)) return false;
    if (!('group' in value)) return false;
    return true;
}

export function AddPeopleToGroupDtoFromJSON(json: any): AddPeopleToGroupDto {
    return AddPeopleToGroupDtoFromJSONTyped(json, false);
}

export function AddPeopleToGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddPeopleToGroupDto {
    if (json == null) {
        return json;
    }
    return {
        
        'people': ((json['people'] as Array<any>).map(PersonToAddDtoFromJSON)),
        'group': GroupDtoFromJSON(json['group']),
    };
}

export function AddPeopleToGroupDtoToJSON(value?: AddPeopleToGroupDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'people': ((value['people'] as Array<any>).map(PersonToAddDtoToJSON)),
        'group': GroupDtoToJSON(value['group']),
    };
}

