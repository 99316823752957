/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FileMetadataDto } from './FileMetadataDto';
import {
    FileMetadataDtoFromJSON,
    FileMetadataDtoFromJSONTyped,
    FileMetadataDtoToJSON,
} from './FileMetadataDto';
import type { OccupationDto } from './OccupationDto';
import {
    OccupationDtoFromJSON,
    OccupationDtoFromJSONTyped,
    OccupationDtoToJSON,
} from './OccupationDto';
import type { PersonStaffDto } from './PersonStaffDto';
import {
    PersonStaffDtoFromJSON,
    PersonStaffDtoFromJSONTyped,
    PersonStaffDtoToJSON,
} from './PersonStaffDto';
import type { PersonStatusDto } from './PersonStatusDto';
import {
    PersonStatusDtoFromJSON,
    PersonStatusDtoFromJSONTyped,
    PersonStatusDtoToJSON,
} from './PersonStatusDto';
import type { PhoneNumberDto } from './PhoneNumberDto';
import {
    PhoneNumberDtoFromJSON,
    PhoneNumberDtoFromJSONTyped,
    PhoneNumberDtoToJSON,
} from './PhoneNumberDto';

/**
 * Business card of a Person
 * @export
 * @interface PersonCardDto
 */
export interface PersonCardDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof PersonCardDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PersonCardDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonCardDto
     */
    surname?: string;
    /**
     * The unique key for a person starting with "P".
     * @type {string}
     * @memberof PersonCardDto
     */
    personKey?: string;
    /**
     * List of contact email addresses (confidential and non-confidential)
     * @type {Array<string>}
     * @memberof PersonCardDto
     */
    emailAddresses?: Array<string>;
    /**
     * 
     * @type {FileMetadataDto}
     * @memberof PersonCardDto
     */
    photoMetadata?: FileMetadataDto;
    /**
     * 
     * @type {OccupationDto}
     * @memberof PersonCardDto
     */
    occupation?: OccupationDto;
    /**
     * List of phone numbers
     * @type {Array<PhoneNumberDto>}
     * @memberof PersonCardDto
     */
    phoneNumbers?: Array<PhoneNumberDto>;
    /**
     * 
     * @type {PersonStatusDto}
     * @memberof PersonCardDto
     */
    status?: PersonStatusDto;
    /**
     * 
     * @type {PersonStaffDto}
     * @memberof PersonCardDto
     */
    staffInformation?: PersonStaffDto;
}

/**
 * Check if a given object implements the PersonCardDto interface.
 */
export function instanceOfPersonCardDto(value: object): boolean {
    if (!('id' in value)) return false;
    return true;
}

export function PersonCardDtoFromJSON(json: any): PersonCardDto {
    return PersonCardDtoFromJSONTyped(json, false);
}

export function PersonCardDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonCardDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'surname': json['surname'] == null ? undefined : json['surname'],
        'personKey': json['personKey'] == null ? undefined : json['personKey'],
        'emailAddresses': json['emailAddresses'] == null ? undefined : json['emailAddresses'],
        'photoMetadata': json['photoMetadata'] == null ? undefined : FileMetadataDtoFromJSON(json['photoMetadata']),
        'occupation': json['occupation'] == null ? undefined : OccupationDtoFromJSON(json['occupation']),
        'phoneNumbers': json['phoneNumbers'] == null ? undefined : ((json['phoneNumbers'] as Array<any>).map(PhoneNumberDtoFromJSON)),
        'status': json['status'] == null ? undefined : PersonStatusDtoFromJSON(json['status']),
        'staffInformation': json['staffInformation'] == null ? undefined : PersonStaffDtoFromJSON(json['staffInformation']),
    };
}

export function PersonCardDtoToJSON(value?: PersonCardDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'firstName': value['firstName'],
        'surname': value['surname'],
        'personKey': value['personKey'],
        'emailAddresses': value['emailAddresses'],
        'photoMetadata': FileMetadataDtoToJSON(value['photoMetadata']),
        'occupation': OccupationDtoToJSON(value['occupation']),
        'phoneNumbers': value['phoneNumbers'] == null ? undefined : ((value['phoneNumbers'] as Array<any>).map(PhoneNumberDtoToJSON)),
        'status': PersonStatusDtoToJSON(value['status']),
        'staffInformation': PersonStaffDtoToJSON(value['staffInformation']),
    };
}

