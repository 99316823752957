import { chakra } from '@chakra-ui/react';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export interface CheckmarkBooleanViewerProps<T> {
  renderLabel: (value: T) => React.ReactNode;
  truthiness: (value: T) => boolean;
  value: T | undefined;
}

const StyledFontAwesomeIcon = chakra(FontAwesomeIcon);

export default function CheckmarkBooleanViewer<T>({ renderLabel, value, truthiness }: CheckmarkBooleanViewerProps<T>) {
  if (value == null) {
    return null;
  }

  return (
    <chakra.span display="flex" alignItems="center">
      <StyledFontAwesomeIcon
        color={truthiness(value) ? 'text.success' : 'text.error'}
        icon={truthiness(value) ? faCheck : faTimes}
        mr={2}
      />
      {renderLabel(value)}
    </chakra.span>
  );
}
