import { TFunction } from 'i18next';
import { isEmpty } from 'lodash-es';
import { Message } from 'react-hook-form/dist/types/errors';
import { FileMetadataDto } from '../../../api';
import { FileSuffixes, FileType, MEGABYTES } from './file-type';

export function singleFileUploadValidator(
  file: FileMetadataDto | undefined,
  maxFileSize: number,
  acceptedFileTypes: FileType[],
  t: TFunction<'attachment'>,
  additionalValidators?: ((file: FileMetadataDto) => Message | undefined)[],
) {
  if (file == null) {
    return true;
  }
  const fileSizeError = validateFileSize(file.size, maxFileSize, t);

  if (fileSizeError) {
    return fileSizeError;
  }

  if (!isValidFileType(file.mimeType, acceptedFileTypes)) {
    return t('validation_error.mime_type', { acceptedFileTypes });
  }

  const additionalValidationErrors = additionalValidators
    ?.map((validate) => validate(file))
    .filter((result) => result != null)
    .join('. ');

  return isEmpty(additionalValidationErrors) ? undefined : additionalValidationErrors;
}

export function validateSelectedFileForUpload(
  file: File,
  maxFileSizeInMB: number,
  acceptFileTypes: FileType[],
  t: TFunction<'attachment'>,
) {
  const validationErrors = [];

  if (!isValidFileType(file.type, acceptFileTypes)) {
    validationErrors.push(
      t('validation_error.mime_type', {
        acceptedFileTypes: acceptFileTypes.flatMap((type) => FileSuffixes[type]),
      }),
    );
  }
  const fileSizeError = validateFileSize(file.size, maxFileSizeInMB, t);
  if (fileSizeError != null) {
    validationErrors.push(fileSizeError);
  }
  return validationErrors;
}

function isValidFileType(fileMimeType: string, acceptedFileTypes: FileType[]) {
  return acceptedFileTypes.includes(fileMimeType as FileType);
}

function validateFileSize(bytes: number, maxFileSizeInMB: number, t: TFunction<'attachment'>) {
  if (!bytes) {
    return t('validation_error.no_size');
  } else {
    return bytes > maxFileSizeInMB * MEGABYTES ? t('validation_error.size', { size_mb: maxFileSizeInMB }) : null;
  }
}
