import { Grid, GridItem } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { KeyPlayerDto, PublishedKeyPlayersListSettingsDto } from '../../../api';
import DataTable, { useDataTableState } from '../../../ui/data-table';
import Page from '../../../ui/page/page';
import PageContent from '../../../ui/page/page-content';
import PagePublicHeader from '../../../ui/page/page-public-header';
import { LightModeBox } from '../../../util/color-mode/color-mode-box';
import { PublicKeyPlayerEventHeader } from '../../key-players/key-player-event-header/key-player-event-header';
import { usePublicKeyPlayers } from '../../key-players/use-keyplayerlist/use-key-players';
import useKeyPlayerListPublicTableColumns from './use-key-players-list-public-table-columns';
import { usePublicKeyPlayerListSettings } from './use-public-key-players';

export default function PublicKeyPlayerListViewer() {
  const { publicationId } = useParams<{ publicationId: string }>();
  invariant(publicationId != null, 'Missing publicationId');

  const publicSettings = usePublicKeyPlayerListSettings(publicationId);

  return (
    <LightModeBox>
      <Page height="full">
        <GridItem>
          <PagePublicHeader />
        </GridItem>
        <PageContent useFullWidth fixedHeight>
          <Grid gridRowGap={6} maxH="full" gridTemplateRows="auto 1fr">
            <PublicKeyPlayerEventHeader eventSettings={publicSettings.eventSettings} />
            <PublicKeyPlayersDataTable settings={publicSettings} publicationId={publicationId} />
          </Grid>
        </PageContent>
      </Page>
    </LightModeBox>
  );
}

interface KeyPlayersDataTableProps {
  publicationId: string;
  settings: PublishedKeyPlayersListSettingsDto;
}

function PublicKeyPlayersDataTable({ publicationId, settings }: KeyPlayersDataTableProps) {
  const [state, setState] = useDataTableState({ size: 20 });
  const page = usePublicKeyPlayers(state, publicationId);
  const rowKey = React.useCallback((keyPlayerDto: KeyPlayerDto) => {
    invariant(keyPlayerDto.id != null, 'Missing keyPlayerDto id');

    return keyPlayerDto.id;
  }, []);

  const keyPlayerListTableColumns = useKeyPlayerListPublicTableColumns(settings, publicationId, true);

  return (
    <Grid maxHeight="full" minH={0} gridTemplateRows="auto 1fr">
      <GridItem minHeight={0}>
        <DataTable
          page={page}
          state={state}
          columns={keyPlayerListTableColumns}
          rowKey={rowKey}
          onStateChange={setState}
          isPageable={true}
        />
      </GridItem>
    </Grid>
  );
}
