import { Box, chakra, ChakraProps } from '@chakra-ui/react';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash-es';
import React, { ForwardedRef, forwardRef } from 'react';
import { LinkProps } from 'react-router-dom';
import { LocationReferenceDto, PublicLocationReferenceDto } from '../../../api';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';
import { VisitingCardPopover } from '../../../ui/visiting-card/visiting-card';
import LocationCard from './location-card';

export interface LocationReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  locationReference: LocationReferenceDto | null | undefined;
  size?: LocationReferenceViewerProps['size'];
  useEnglishName?: boolean;
  targetBlank?: boolean;
  zone?: string;
  subZone?: string;
  // In some viewers the infos needs to be in a portal because of the zIndex or hidden overflow
  usePortalForCard?: boolean;
  display?: ChakraProps['display'];
}

export default function LocationReference({
  locationReference,
  size = 'md',
  useEnglishName = false,
  zone,
  subZone,
  usePortalForCard,
  display,
  ...props
}: LocationReferenceProps) {
  if (locationReference == null) {
    return (
      <chakra.div display="inline-flex" alignItems="center">
        <>
          <LocationReferenceViewer useEnglishName={useEnglishName} {...props} />
        </>
      </chakra.div>
    );
  }

  return (
    <chakra.div display={display} fontSize={size}>
      <VisitingCardPopover
        trigger={
          <LocationReferenceViewer
            locationReference={locationReference}
            useEnglishName={useEnglishName}
            to={'/locations/' + locationReference.id}
            {...props}
          />
        }
        usePortal={usePortalForCard}
        necessaryPermission="LOCATION:CAN_SEE_LOCATION_CARD"
      >
        <LocationCard locationId={locationReference.id} zone={zone} subZone={subZone} />
      </VisitingCardPopover>{' '}
      <LocationZoneViewer zone={zone} subZone={subZone} />
    </chakra.div>
  );
}

export interface LocationReferenceViewerProps extends Omit<ReferenceProps, 'icon' | 'children'> {
  locationReference?: LocationReferenceDto | PublicLocationReferenceDto | null | undefined;
  to?: LinkProps['to'];
  useEnglishName?: boolean;
  hideIcon?: boolean;
}

export const LocationReferenceViewer = forwardRef(
  (
    {
      locationReference,
      to,
      useEnglishName = false,
      size = 'md',
      hideIcon = false,
      ...props
    }: LocationReferenceViewerProps,
    ref: ForwardedRef<HTMLAnchorElement | HTMLDivElement>,
  ) => {
    const name =
      locationReference != null
        ? useEnglishName && !isEmpty(locationReference.englishName)
          ? locationReference.englishName!
          : locationReference.name
        : 'BerlinalX';

    return (
      <Reference
        icon={faMapMarkerAlt}
        size={size}
        to={to}
        hideIcon={hideIcon}
        ref={ref}
        {...props}
        necessaryPermission="LOCATION:CAN_SEE_DETAILS"
      >
        {name}
      </Reference>
    );
  },
);

interface LocationZoneViewerProps {
  zone: string | undefined;
  subZone: string | undefined;
}

export function LocationZoneViewer({ zone, subZone }: LocationZoneViewerProps) {
  if (zone == null) {
    return null;
  }

  return (
    <chakra.span wordBreak="break-word">
      ({zone}
      {subZone != null && (
        <>
          {' '}
          <Box as={FontAwesomeIcon} icon={faLongArrowRight} /> {subZone}
        </>
      )}
      )
    </chakra.span>
  );
}
