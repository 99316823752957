import { mode, SystemStyleFunction } from '@chakra-ui/theme-tools';

const variantSubtle: SystemStyleFunction = (props) => {
  return {
    container: {
      bg: mode(`${props.colorScheme}.100`, `${props.colorScheme}.700`)(props),
    },
  };
};

const Tag = {
  variants: {
    subtle: variantSubtle,
  },
  sizes: {
    sm: {
      container: {
        borderRadius: 'base',
      },
    },
    md: {
      container: {
        borderRadius: 'base',
      },
    },
    lg: {
      container: {
        borderRadius: 'base',
      },
    },
  },
  baseStyle: {
    container: {
      hyphens: 'auto',
    },
    label: {
      hyphens: 'auto',
    },
  },
};

export default Tag;
