/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AreaDto
 */
export interface AreaDto {
    /**
     * 
     * @type {string}
     * @memberof AreaDto
     */
    text: string;
    /**
     * Defines a bounding box via two corner positions [[x1,y1],[x2,y2]].
     * @type {Array<Array<number>>}
     * @memberof AreaDto
     */
    bounds: Array<Array<number>>;
}

/**
 * Check if a given object implements the AreaDto interface.
 */
export function instanceOfAreaDto(value: object): boolean {
    if (!('text' in value)) return false;
    if (!('bounds' in value)) return false;
    return true;
}

export function AreaDtoFromJSON(json: any): AreaDto {
    return AreaDtoFromJSONTyped(json, false);
}

export function AreaDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AreaDto {
    if (json == null) {
        return json;
    }
    return {
        
        'text': json['text'],
        'bounds': json['bounds'],
    };
}

export function AreaDtoToJSON(value?: AreaDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'text': value['text'],
        'bounds': value['bounds'],
    };
}

