import { Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { ExportDto, ExportDtoTypeEnum } from '../../../api';
import exportApi from '../../../data-access/export-api';
import DataTable, { DataTableColumn, DataTableTruncatedText, useDataTableState } from '../../../ui/data-table';
import DateRangeFilter from '../../../ui/data-table/filter/date-range-filter';
import EnumFilter from '../../../ui/data-table/filter/enum-filter';
import StringFilter from '../../../ui/data-table/filter/string-filter';
import useExports from './use-exports';

export default function ExportDataTable() {
  const [state, setState] = useDataTableState();
  const { t } = useTranslation(['common', 'export']);
  const { page } = useExports(state);

  const columns: DataTableColumn<ExportDto>[] = React.useMemo(() => {
    const columns: DataTableColumn<ExportDto>[] = [
      {
        key: 'fileName',
        sticky: true,
        name: t('export:fileName'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        renderCell: (exported) => exported.fileName,
        isSortable: true,
        filter: (
          <StringFilter
            label={t('export:fileName')}
            loadOptions={async (value: string) => {
              const page = await exportApi.searchExports({
                pageable: { size: 10 },
                filter: [`fileName,contain,${value}`],
              });
              return page.content.map((exported) => exported.fileName);
            }}
          />
        ),
      },
      {
        key: 'type',
        name: t('export:type'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        renderCell: (exported) => t(`export:typeOptions.${exported.type}`),
        isSortable: true,
        filter: (
          <EnumFilter
            label={t('export:type')}
            options={[ExportDtoTypeEnum.GUESTLIST]}
            renderOptionLabel={(key) => t(`export:typeOptions.${key}`)}
          />
        ),
      },
      {
        key: 'version.modifiedAt',
        name: t('export:createdAt'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        renderCell: (exported) => t('common:format.date_time', { dateTime: exported.version.modifiedAt }),
        isSortable: true,
        filter: <DateRangeFilter showTimeSelect isTimestamp={true} />,
        filterWidth: 'md',
      },
      {
        key: 'version.modifiedBy',
        name: t('export:createdBy'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        renderCell: (exported) =>
          exported.version.modifiedBy?.displayName ?? exported.version.modifiedBy?.email ?? t('common:viewer.system'),
      },
      {
        key: 'reason',
        name: t('export:reason'),
        cellProps: {
          whiteSpace: 'nowrap',
          width: '30%',
        },
        renderCell: (exported) => (
          <DataTableTruncatedText>
            <Tooltip label={exported.reason} placement="left">
              {exported.reason}
            </Tooltip>
          </DataTableTruncatedText>
        ),
        filter: (
          <StringFilter
            label={t('export:reason')}
            loadOptions={async (value: string) => {
              const page = await exportApi.searchExports({
                pageable: { size: 10 },
                filter: [`reason,contain,${value}`],
              });
              return page.content.map((exported) => exported.reason);
            }}
          />
        ),
      },
    ];
    return columns;
  }, [t]);

  const rowKey = React.useCallback((exported: ExportDto) => {
    invariant(exported.id != null, 'Missing export id');

    return exported.id;
  }, []);

  return (
    <DataTable page={page} state={state} columns={columns} rowKey={rowKey} onStateChange={setState} isPageable={true} />
  );
}
