import { Box, HStack, IconButton, Tag, Text, Tooltip, Wrap, WrapItem } from '@chakra-ui/react';
import { faPlug } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { identity } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { GuestListListItemDto, GuestListSettingsDto, GuestTypeDto } from '../../../api';
import guestListApi from '../../../data-access/guest-list-api';
import { DataTableColumn } from '../../../ui/data-table';
import DateFilter from '../../../ui/data-table/filter/date-filter';
import EnumFilter from '../../../ui/data-table/filter/enum-filter';
import ExistsFilter from '../../../ui/data-table/filter/exists-filter';
import InFilter from '../../../ui/data-table/filter/in-filter';
import StringFilter from '../../../ui/data-table/filter/string-filter';
import HelperPopover from '../../../ui/helper-buttons/helper-popover';
import { mailingIcon } from '../../../ui/icons/business-objects';
import Optional from '../../../ui/optional/optional';
import LastModifiedLabel from '../../../ui/version/last-modified-label';
import Translate from '../../../util/translate/translate';
import CompanyReference from '../../company/company-reference/company-reference';
import useEvent from '../../event/use-event/use-event';
import { markers } from '../../person/person-enum-constants';
import AllocationColor from '../allocation-color/allocation-color';
import { guestTypeOptions } from '../guest-list-enum-constants';
import GuestIconControl from '../guest-list-viewer/guest-icon-control';
import { GuestNameControl } from '../guest-list-viewer/guest-name-control';
import StatusForParticipationControl from '../guest-list-viewer/status-for-participation-control';

interface GuestTableColumnsProps<T> {
  guestList: GuestListSettingsDto;
  showCoupledColumn?: boolean;
  keyPrefix?: string;

  accessor?(data: T): GuestListListItemDto | undefined;
}

export default function useGuestTableColumns<T = GuestListListItemDto>({
  guestList,
  showCoupledColumn = true,
  keyPrefix = '',
  accessor = identity,
}: GuestTableColumnsProps<T>) {
  const { t } = useTranslation(['common', 'guest_list', 'person', 'placement']);
  const event = useEvent(guestList.eventId);
  const withOptional = React.useCallback(
    (render: (personOnGuestList: GuestListListItemDto) => React.ReactNode) => (data: T) => {
      const personOnGuestList = accessor(data);

      return <Optional>{personOnGuestList != null && render(personOnGuestList)}</Optional>;
    },
    [accessor],
  );

  const columns: DataTableColumn<T>[] = React.useMemo(() => {
    return [
      {
        key: `${keyPrefix}guest`,
        sticky: true,
        name: (
          <HStack>
            <Translate ns="guest_list">{(t) => t('lister.guest')}</Translate>
            <HelperPopover children={t(`guest_list:lister.guest_tooltip`)} ariaLabel={t('guest_list:lister.guest')} />
          </HStack>
        ),
        renderCell: withOptional((personOnGuestList) => <GuestNameControl personOnList={personOnGuestList} />),
        isSortable: true,
        filter: (
          <StringFilter
            label={t('guest_list:lister.guest')}
            availableOperators={['CONTAIN', 'NOT_CONTAIN', 'START_WITH', 'NOT_START_WITH', 'END_WITH', 'NOT_END_WITH']}
            loadOptions={async (value: string) => {
              if (!value) {
                return [];
              }

              const page = await guestListApi.searchPersonOnGuestList({
                guestListId: guestList.id!,
                pageable: { size: 10 },
              });

              return page.content.map((guestListListItem) =>
                guestListListItem.guestType === GuestTypeDto.PERSON
                  ? guestListListItem.person?.firstName + ' ' + guestListListItem.person?.surname
                  : guestListListItem.guestType === GuestTypeDto.BODYGUARD
                    ? t('guest_list:bodyguardGuest') + ' ' + guestListListItem.number
                    : t('guest_list:guestTypeOptions.DUMMY') + ' ' + guestListListItem.number,
              );
            }}
          />
        ),
      },
      showCoupledColumn && {
        key: `${keyPrefix}coupled`,
        renderCell: withOptional(
          (personOnGuestList) =>
            personOnGuestList.couplingGroupId != null && (
              <Tooltip label={t('guest_list:lister.coupled')}>
                <Box color="text.muted">
                  <FontAwesomeIcon icon={faPlug} />
                </Box>
              </Tooltip>
            ),
        ),
        cellProps: {
          textAlign: 'center',
        },
        filterProperty: `${keyPrefix}couplingGroupId`,
        filter: <ExistsFilter label={t('guest_list:coupling.filter_title')} />,
      },
      {
        key: `${keyPrefix}guestType`,
        renderCell: withOptional((personOnGuestList) => (
          <GuestIconControl guestType={personOnGuestList.guestType} isDuplicate={personOnGuestList.duplicate} />
        )),
        cellProps: {
          textAlign: 'center',
        },
        isSortable: true,
        filter: (
          <EnumFilter
            label={t('guest_list:guestType')}
            options={guestTypeOptions}
            renderOptionLabel={(key) => t(`guest_list:guestTypeOptions.${key}`)}
          />
        ),
      },
      {
        key: `${keyPrefix}mailingLink`,
        renderCell: withOptional(
          (personOnGuestList) =>
            personOnGuestList.guestType === 'PERSON' && (
              <Tooltip label={t('guest_list:lister.mailingLink')}>
                <IconButton
                  as={RouterLink}
                  variant="ghost"
                  size="sm"
                  to={`/mailings?filter=connectedEntity.id%2Cin%2C${guestList.eventId}&filterLabel=connectedEntity.id%2C${event.title}&filter=recipientLinks.linkedRecipient.id%2Ceq%2C${personOnGuestList.id}&filterRecipient=${personOnGuestList.person?.firstName}+${personOnGuestList.person?.surname}`}
                  aria-label={t('guest_list:lister.mailingLink')}
                >
                  <FontAwesomeIcon icon={mailingIcon} />
                </IconButton>
              </Tooltip>
            ),
        ),
        cellProps: {
          textAlign: 'center',
        },
      },
      {
        key: `${keyPrefix}category`,
        name: t('guest_list:categoryOfParticipation'),
        renderCell: withOptional((personOnGuestList) => (
          <Wrap>
            <WrapItem key={personOnGuestList.category}>
              <Tag colorScheme="gray">
                {t(`guest_list:categoryOfParticipationOptions.${personOnGuestList.category}`)}
              </Tag>
            </WrapItem>
          </Wrap>
        )),
        isSortable: true,
        filter: (
          <EnumFilter
            label={t('guest_list:categoryOfParticipation')}
            options={guestList.categoryOfParticipation}
            renderOptionLabel={(key) => t(`guest_list:categoryOfParticipationOptions.${key}`)}
          />
        ),
      },
      {
        key: `${keyPrefix}allocation`,
        name: t('guest_list:allocation.label'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        renderCell: withOptional((personOnGuestList) => (
          <HStack>
            <AllocationColor color={personOnGuestList.allocation.color} mr={2} />
            <Text>{personOnGuestList.allocation.name}</Text>
          </HStack>
        )),
        isSortable: true,
        sortProperty: `${keyPrefix}allocation.name`,
        filterProperty: `${keyPrefix}allocation.name`,
        filter: (
          <InFilter
            label={t('guest_list:allocation.label')}
            loadOptions={async (value: string) => {
              const page = await guestListApi.searchAllocations({
                guestListId: guestList.id!,
                pageable: { size: 10, sort: ['name,ASC'] },
                filter: [`name,contain,${value}`],
              });

              return page.content.map((allocation) => ({ value: allocation, label: allocation }));
            }}
          />
        ),
      },
      {
        key: `${keyPrefix}labels`,
        name: t('guest_list:label.label'),
        cellProps: {
          whiteSpace: 'nowrap',
          width: '20%',
        },
        renderCell: withOptional((personOnGuestList) => (
          <Optional>
            {personOnGuestList.labels != null && personOnGuestList.labels.length > 0 && (
              <Wrap>
                {personOnGuestList.labels.map((label) => (
                  <WrapItem key={label}>
                    <Tag colorScheme="gray">{label}</Tag>{' '}
                  </WrapItem>
                ))}
              </Wrap>
            )}
          </Optional>
        )),
        isSortable: true,
        filter: <EnumFilter label="" options={guestList.labels} renderOptionLabel={(key) => key} />,
      },
      {
        key: `${keyPrefix}statusForParticipation`,
        name: t('guest_list:status'),
        filterProperty: 'status',
        renderCell: withOptional((personOnGuestList) => (
          <StatusForParticipationControl status={personOnGuestList.status} />
        )),
        isSortable: true,
        filter: (
          <EnumFilter
            label={t('guest_list:status')}
            options={guestList.statusForParticipation}
            renderOptionLabel={(key) => t(`guest_list:statusForParticipationOptions.${key}`)}
          />
        ),
      },
      {
        key: `${keyPrefix}comment`,
        name: t('guest_list:lister.comment'),
        renderCell: withOptional(
          (personOnGuestList) =>
            personOnGuestList.comment != null && (
              <Tooltip label={personOnGuestList.comment}>
                <Wrap>
                  <WrapItem noOfLines={2}>{personOnGuestList.comment}</WrapItem>
                </Wrap>
              </Tooltip>
            ),
        ),
        isSortable: true,
        filter: <StringFilter label={t('guest_list:lister.comment')} />,
      },
      {
        key: `${keyPrefix}markers`,
        name: (
          <HStack>
            <Translate ns="person">{(t) => t('markers')}</Translate>
            <HelperPopover children={t(`person:markersTooltip`)} ariaLabel={t('person:markers')} />
          </HStack>
        ),
        cellProps: {
          whiteSpace: 'nowrap',
          width: '20%',
        },
        renderCell: withOptional(
          (personOnGuestList) =>
            personOnGuestList.markers != null &&
            personOnGuestList.markers?.length > 0 && (
              <Wrap>
                {personOnGuestList.markers?.map((marker) => (
                  <WrapItem key={marker}>
                    <Tag colorScheme="gray">{t(`person:markersLabel.${marker}`)}</Tag>{' '}
                  </WrapItem>
                ))}
              </Wrap>
            ),
        ),
        filter: (
          <EnumFilter
            label={<Translate ns="person" i18nKey="type" />}
            options={markers}
            renderOptionLabel={(value) => t(`person:markersLabel.${value}`)}
          />
        ),
      },
      {
        key: `${keyPrefix}company`,
        name: <Translate ns="person">{(t) => t('occupations.company')}</Translate>,
        cellProps: {
          whiteSpace: 'nowrap',
          width: '20%',
        },
        renderCell: withOptional((personOnGuestList) => (
          <CompanyReference companyReference={personOnGuestList.company} />
        )),
        isSortable: true,
        filter: <StringFilter label={t('person:occupations.company')} />,
      },
      {
        key: `${keyPrefix}jobTitle`,
        name: <Translate ns="person">{(t) => t('occupations.jobTitle')}</Translate>,
        cellProps: {
          whiteSpace: 'nowrap',
          width: '20%',
        },
        renderCell: withOptional((personOnGuestList) => (
          <Optional isEmpty={personOnGuestList.jobTitle == null}>{personOnGuestList.jobTitle}</Optional>
        )),
        isSortable: true,
        filter: <StringFilter label={t('person:occupations.jobTitle')} />,
      },
      {
        key: 'placementDisplayName',
        name: (
          <HStack>
            <Text>{t('placement:guest_list.column_name')}</Text>
            <HelperPopover
              children={t('placement:guest_list.column_tooltip')}
              aria-label={t('placement:guest_list.column_name')}
            />
          </HStack>
        ),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        renderCell: withOptional((personOnGuestList) => personOnGuestList.placementDisplayName),
        filter: (
          <InFilter
            label={t('placement:guest_list.column_name')}
            loadOptions={async (value: string) => {
              const page = await guestListApi.searchGuestList({
                pageable: { size: 10, sort: ['placementDisplayName,ASC'] },
                guestListId: guestList.id!,
                filter: [`placementDisplayName,contain,${value}`],
              });

              return page.content.map((guestListListItem) => ({
                value: guestListListItem.placementDisplayName,
                label: guestListListItem.placementDisplayName,
              }));
            }}
          />
        ),
        isSortable: true,
      },
      {
        key: `${keyPrefix}version.modifiedAt`,
        name: t('common:viewer.last_modification'),
        renderCell: withOptional((personOnGuestList) => (
          <LastModifiedLabel version={personOnGuestList.version}></LastModifiedLabel>
        )),
        isSortable: true,
        sortProperty: 'modifiedAt',
        filter: <DateFilter showTimeSelect />,
        filterWidth: 'md',
      },
    ];
  }, [
    keyPrefix,
    t,
    withOptional,
    showCoupledColumn,
    guestList.categoryOfParticipation,
    guestList.labels,
    guestList.statusForParticipation,
    guestList.id,
    guestList.eventId,
    event.title,
  ]);

  return columns;
}
