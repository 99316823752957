import React from 'react';
import { PersonDto } from '../../../api';
import usePlugins, { PluginToken } from '../../../util/plugin/use-plugins';

export interface PersonRelationExtension {
  label: React.ReactNode;
  element: (person: PersonDto) => React.ReactNode;
}

export const PERSON_RELATION_EXTENSION = new PluginToken<PersonRelationExtension>('PersonRelationExtension');

export function usePersonRelationExtensions() {
  return usePlugins(PERSON_RELATION_EXTENSION);
}
