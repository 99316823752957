import { HStack, Link, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link as RouterLink } from 'react-router-dom';
import { ProductBundleReferenceDto } from '../../api';
import { productBundleIcon } from '../../ui/icons/business-objects';

interface ProductBundleReferenceProps {
  productBundle: ProductBundleReferenceDto;
  size?: 'sm' | 'md';
  color?: string;
}

export default function ProductBundleReference({ productBundle, color, size = 'md' }: ProductBundleReferenceProps) {
  return (
    <Link display="inline-block" as={RouterLink} to={'/product-bundles/' + productBundle.id} size={size} color={color}>
      <HStack spacing={1} alignItems="center" fontSize={size}>
        <FontAwesomeIcon icon={productBundleIcon} />
        <Text as="span" fontWeight="medium">
          {productBundle.title}
        </Text>
      </HStack>
    </Link>
  );
}
