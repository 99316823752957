/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SimplePersonDto } from './SimplePersonDto';
import {
    SimplePersonDtoFromJSON,
    SimplePersonDtoFromJSONTyped,
    SimplePersonDtoToJSON,
} from './SimplePersonDto';

/**
 * Represents a duplicate relation between two persons.
 * @export
 * @interface DuplicateDto
 */
export interface DuplicateDto {
    /**
     * 
     * @type {string}
     * @memberof DuplicateDto
     */
    key: string;
    /**
     * 
     * @type {SimplePersonDto}
     * @memberof DuplicateDto
     */
    personA: SimplePersonDto;
    /**
     * 
     * @type {SimplePersonDto}
     * @memberof DuplicateDto
     */
    personB: SimplePersonDto;
    /**
     * 
     * @type {boolean}
     * @memberof DuplicateDto
     */
    notDuplicate: boolean;
}

/**
 * Check if a given object implements the DuplicateDto interface.
 */
export function instanceOfDuplicateDto(value: object): boolean {
    if (!('key' in value)) return false;
    if (!('personA' in value)) return false;
    if (!('personB' in value)) return false;
    if (!('notDuplicate' in value)) return false;
    return true;
}

export function DuplicateDtoFromJSON(json: any): DuplicateDto {
    return DuplicateDtoFromJSONTyped(json, false);
}

export function DuplicateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DuplicateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'personA': SimplePersonDtoFromJSON(json['personA']),
        'personB': SimplePersonDtoFromJSON(json['personB']),
        'notDuplicate': json['notDuplicate'],
    };
}

export function DuplicateDtoToJSON(value?: DuplicateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'key': value['key'],
        'personA': SimplePersonDtoToJSON(value['personA']),
        'personB': SimplePersonDtoToJSON(value['personB']),
        'notDuplicate': value['notDuplicate'],
    };
}

