import { ReactNode } from 'react';
import { CompanyDto } from '../../api/models/CompanyDto';
import { PluginToken } from '../../util/plugin/use-plugins';
import { Permission } from '../permission/use-permission';

export interface CompanyActionExtension {
  item: (company: CompanyDto, hasPermission: (necessaryPermission: Permission) => boolean) => ReactNode;
}

export const COMPANY_ACTION_EXTENSION = new PluginToken<CompanyActionExtension>('CompanyActionExtension');
