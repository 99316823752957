import { SearchResultDto } from '../../api';
import { PluginToken } from '../../util/plugin/use-plugins';
import { SearchResultProps } from './search-result';

export interface SearchResultContent {
  type: SearchResultDto['resultType'];
  Component: (props: SearchResultProps) => JSX.Element;
}

export const SEARCH_RESULT_CONTENT = new PluginToken<SearchResultContent>('SearchResultContent');
