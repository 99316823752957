import { Flex, Grid, GridItem, IconButton, Tooltip } from '@chakra-ui/react';
import { faUnlink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { FormatCompanyListItemDto, FormatDto } from '../../../../api';
import DataTable, { DataTableColumn, DataTableState, useDataTableState } from '../../../../ui/data-table';
import DateRangeFilter from '../../../../ui/data-table/filter/date-range-filter';
import EnumFilter from '../../../../ui/data-table/filter/enum-filter';
import StringFilter from '../../../../ui/data-table/filter/string-filter';
import useRequestParams from '../../../../ui/data-table/use-request-params';
import fallbackMiddleware from '../../../../util/swr/fallback-middleware';
import useFetcher from '../../../../util/swr/use-fetcher';
import Translate from '../../../../util/translate/translate';
import CompanyReference from '../../../company/company-reference/company-reference';
import usePermission from '../../../permission/use-permission';
import { CONNECTION_TYPE_OPTIONS, STATUS_OPTIONS } from '../format-company-enum-constants';
import { fetchCompaniesByFormat, formatCompanyFetcher } from '../format-company-queries';
import FormatCompanyTerminateButton from './format-company-terminate-button';

function useFormatCompanyListItems(state: DataTableState, formatId: string) {
  const requestParams = useRequestParams(state, [
    {
      property: 'company.name',
      direction: 'ASC',
    },
  ]);
  return useFetcher(
    fetchCompaniesByFormat,
    {
      ...requestParams,
      id: formatId,
      filter: [...requestParams.filter],
    },
    { use: [fallbackMiddleware] },
  );
}

export default function FormatCompanyViewer({ format }: { format: FormatDto }) {
  const { t } = useTranslation(['common', 'company', 'format']);
  const [state, setState] = useDataTableState();
  const page = useFormatCompanyListItems(state, format.id);
  const { hasPermission } = usePermission();

  const columns: DataTableColumn<FormatCompanyListItemDto>[] = React.useMemo(() => {
    const columns: DataTableColumn<FormatCompanyListItemDto>[] = [
      {
        key: 'company',
        name: <Translate ns="format" i18nKey="connections.companies" />,
        cellProps: {
          whiteSpace: 'nowrap',
          textAlign: 'left',
        },
        renderCell: (data: FormatCompanyListItemDto) => <CompanyReference companyReference={data.company} />,
        filter: (
          <StringFilter
            label={<Translate ns="format" i18nKey="connections.companies" />}
            availableOperators={['CONTAIN', 'NOT_CONTAIN', 'START_WITH', 'NOT_START_WITH', 'END_WITH', 'NOT_END_WITH']}
          />
        ),
        isSortable: true,
        sortProperty: 'company',
      },
      {
        key: 'connectionType',
        name: <Translate ns="format" i18nKey="formatCompany.connectionType" />,
        renderCell: (data) => (
          <Translate ns="format">{(t) => t(`formatCompany.connectionTypeOptions.${data.connectionType}`)}</Translate>
        ),
        isSortable: true,
        filter: (
          <EnumFilter
            label={t('format:formatCompany.connectionType')}
            options={CONNECTION_TYPE_OPTIONS}
            renderOptionLabel={(key) => t(`format:formatCompany.connectionTypeOptions.${key}`)}
          />
        ),
      },
      {
        key: 'validityPeriod',
        name: t('format:formatCompany.validityPeriod'),
        cellProps: {
          whiteSpace: 'nowrap',
          width: '20%',
        },
        renderCell: (format) => t('common:format.date_range', { dateRange: format.validityPeriod }),
        isSortable: true,
        sortProperty: 'validityPeriod.start',
        filter: <DateRangeFilter />,
      },
      {
        key: 'status',
        name: <Translate ns="format" i18nKey="formatCompany.status" />,
        renderCell: (data) => (
          <Translate ns="format">{(t) => t(`formatCompany.statusOptions.${data.status}`)}</Translate>
        ),
        isSortable: true,
        filter: (
          <EnumFilter
            label={t('format:formatCompany.status')}
            options={STATUS_OPTIONS}
            renderOptionLabel={(key) => t(`format:formatCompany.statusOptions.${key}`)}
          />
        ),
      },
    ];

    return columns;
  }, [t]);

  const actions = useCallback(
    (relation: FormatCompanyListItemDto) =>
      hasPermission('COMPANY-FORMAT:CAN_LINK') && (
        <Flex>
          <Tooltip label={t('format:connections.terminate')} placement="right-end">
            <IconButton
              as={FormatCompanyTerminateButton}
              connectedEntity={{ type: 'FORMAT', format: { id: format.id, name: format.name } }}
              relation={relation}
              onSuccess={() => formatCompanyFetcher.mutate()}
              size="sm"
              variant="ghost"
              icon={<FontAwesomeIcon icon={faUnlink} />}
              aria-label={t('format:connections.terminate')}
            />
          </Tooltip>
        </Flex>
      ),
    [hasPermission, t, format.id, format.name],
  );

  const rowKey = React.useCallback((relation: FormatCompanyListItemDto) => {
    invariant(relation.id != null, 'Missing relation id');

    return relation.id;
  }, []);

  return (
    <Grid gridTemplateRows="1fr auto" height="full" gridRowGap={4}>
      <GridItem minHeight={0}>
        <DataTable
          page={page == null ? { content: [] } : page}
          state={state}
          columns={columns}
          rowKey={rowKey}
          onStateChange={setState}
          isPageable={true}
          actions={actions}
        />
      </GridItem>
    </Grid>
  );
}
