export enum RichTextOptions {
  HEADLINE = 'HEADLINE',
  SUB_HEADLINE = 'SUB_HEADLINE',
  PARAGRAPH = 'PARAGRAPH',
  BOLD = 'BOLD',
  ITALIC = 'ITALIC',
  HYPERLINK = 'HYPERLINK',
  HYPERLINK_BUTTON = 'HYPERLINK_BUTTON',
  CITATION = 'CITATION',
}
