import { Button, Stack, useDisclosure } from '@chakra-ui/react';
import { faPencil, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CodeConfigModuleContent } from '../../mailing/mailing-viewer/code-config-module-content';
import CodeConfigMailingModuleControlDialog from './code-config-mailing-module-control-dialog';

interface CodeConfigMailingModuleControlProps {
  name: string;
}

export default function CodeConfigMailingModuleControl({ name }: CodeConfigMailingModuleControlProps) {
  const { t } = useTranslation('code');
  const path = `${name}.code`;
  const { watch, control } = useFormContext();
  const code = watch(path);
  const { field } = useController({ name: path, control });

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Stack spacing={4}>
      {code != null && <CodeConfigModuleContent code={code} />}
      <CodeConfigMailingModuleControlDialog
        isOpen={isOpen}
        onClose={onClose}
        onChange={(dto) => field.onChange(dto)}
        code={code}
      />
      <Button onClick={() => onOpen()} leftIcon={<FontAwesomeIcon icon={code == null ? faPlus : faPencil} />}>
        {code == null ? t('action.new') : t('action.edit')}
      </Button>
    </Stack>
  );
}
