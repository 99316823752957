import { Button, Stack, Text } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fill } from 'lodash-es';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { AddPeoplePeopleInnerDto, CategoryOfParticipationDto, GuestTypeDto } from '../../../api';
import NumberInputControl from '../../../ui/form/number-input-control';
import ValueSelectFormControl from '../../../ui/form/select-control/value-select-form-control';
import { MAX_PERSONS } from './constants';

interface BodyguardSelectionControlProps {
  onAdd: (entries: AddPeoplePeopleInnerDto[]) => void;
}

interface BodyguardEntry {
  count: number;
  category: CategoryOfParticipationDto;
}

export default function BodyguardSelectionControl({ onAdd }: BodyguardSelectionControlProps) {
  const form = useForm<BodyguardEntry>({
    mode: 'onChange',
    defaultValues: { category: CategoryOfParticipationDto.BODYGUARD },
  });
  const { t } = useTranslation(['common', 'guest_list']);

  const handleAdd = ({ count, category }: BodyguardEntry) => {
    form.reset();
    const bodyguards: AddPeoplePeopleInnerDto[] = fill(Array(count), {
      guestType: GuestTypeDto.BODYGUARD,
      category: category,
    }).map((bodyguard) => ({ ...bodyguard, id: uuid() }));
    onAdd(bodyguards);
  };

  return (
    <FormProvider {...form}>
      <Stack spacing={4} paddingX={4} paddingBottom={4} paddingTop={3}>
        <NumberInputControl
          name="count"
          label={t('guest_list:guest_list_people_connection.count')}
          size="sm"
          isRequired
          showStepper
          min={1}
          max={MAX_PERSONS}
        />

        <ValueSelectFormControl<CategoryOfParticipationDto>
          name="category"
          label={t('guest_list:categoryOfParticipation')}
          size="sm"
          options={[CategoryOfParticipationDto.BODYGUARD]}
          renderLabel={(category) => t(`guest_list:categoryOfParticipationOptions.${category}`)}
          isDisabled
        />

        <Button
          onClick={form.handleSubmit(handleAdd)}
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
          variant="outline"
          size="sm"
        >
          {t('common:action.add')}
        </Button>
        <Text fontSize="sm" color="text.muted">
          {t('guest_list:guest_list_people_connection.bodyguard_helper_text')}
        </Text>
      </Stack>
    </FormProvider>
  );
}
