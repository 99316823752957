/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RecipientUpdateApplicationStatusDto = {
    IN_PROGRESS: 'IN_PROGRESS',
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED'
} as const;
export type RecipientUpdateApplicationStatusDto = typeof RecipientUpdateApplicationStatusDto[keyof typeof RecipientUpdateApplicationStatusDto];


export function RecipientUpdateApplicationStatusDtoFromJSON(json: any): RecipientUpdateApplicationStatusDto {
    return RecipientUpdateApplicationStatusDtoFromJSONTyped(json, false);
}

export function RecipientUpdateApplicationStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecipientUpdateApplicationStatusDto {
    return json as RecipientUpdateApplicationStatusDto;
}

export function RecipientUpdateApplicationStatusDtoToJSON(value?: RecipientUpdateApplicationStatusDto | null): any {
    return value as any;
}

