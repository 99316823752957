import { DarkMode, IconButton, IconButtonProps, Tooltip, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { faSpaceStationMoon, faStarfighter } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

interface ToggleColorModeButtonProps extends Omit<IconButtonProps, 'onClick' | 'icon' | 'aria-label'> {}

export default function ToggleColorModeButton({ ...props }: ToggleColorModeButtonProps) {
  const { t } = useTranslation('app');
  const { toggleColorMode } = useColorMode();
  const colorModeSwitchLabel = useColorModeValue('navigation.switchToDarkMode', 'navigation.switchToLightMode');
  const colorModeSwitchIcon = useColorModeValue(faSpaceStationMoon, faStarfighter);

  return (
    <DarkMode>
      <Tooltip label={t(colorModeSwitchLabel)}>
        <IconButton
          {...props}
          onClick={toggleColorMode}
          variant="ghost"
          icon={<FontAwesomeIcon icon={colorModeSwitchIcon} />}
          aria-label={colorModeSwitchLabel}
        />
      </Tooltip>
    </DarkMode>
  );
}
