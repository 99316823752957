import React from 'react';
import { FieldValues } from 'react-hook-form';
import { RichTextOptions } from '../../rich-text/rich-text-options';
import FormControl, { FormControlProps } from '../form-control';
import FormControlContextConsumer from '../form-control-context-consumer';
import RichTextControl from './rich-text-control';

interface RichTextFormControlProps<TFieldValues extends FieldValues>
  extends Omit<FormControlProps<TFieldValues>, 'children'> {
  maxLength?: number;
  isRequired?: boolean;
  options: RichTextOptions[];
}

export default function RichTextFormControl<TFieldValues extends FieldValues>({
  name,
  maxLength,
  options,
  isRequired = false,
  ...props
}: RichTextFormControlProps<TFieldValues>) {
  return (
    <FormControl name={name} {...props} isRequired={isRequired}>
      <FormControlContextConsumer>
        {({ labelId, feedbackId }) => (
          <RichTextControl
            {...props}
            isRequired={isRequired}
            maxLength={maxLength}
            name={name}
            labelId={labelId}
            feedbackId={feedbackId}
            options={options}
          />
        )}
      </FormControlContextConsumer>
    </FormControl>
  );
}
