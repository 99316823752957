/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateTimeRangeDto } from './ClosedDateTimeRangeDto';
import {
    ClosedDateTimeRangeDtoFromJSON,
    ClosedDateTimeRangeDtoFromJSONTyped,
    ClosedDateTimeRangeDtoToJSON,
} from './ClosedDateTimeRangeDto';

/**
 * 
 * @export
 * @interface PresenceDto
 */
export interface PresenceDto {
    /**
     * 
     * @type {ClosedDateTimeRangeDto}
     * @memberof PresenceDto
     */
    dateRange: ClosedDateTimeRangeDto;
    /**
     * 
     * @type {Date}
     * @memberof PresenceDto
     */
    timestamp?: Date;
}

/**
 * Check if a given object implements the PresenceDto interface.
 */
export function instanceOfPresenceDto(value: object): boolean {
    if (!('dateRange' in value)) return false;
    return true;
}

export function PresenceDtoFromJSON(json: any): PresenceDto {
    return PresenceDtoFromJSONTyped(json, false);
}

export function PresenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PresenceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'dateRange': ClosedDateTimeRangeDtoFromJSON(json['dateRange']),
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
    };
}

export function PresenceDtoToJSON(value?: PresenceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dateRange': ClosedDateTimeRangeDtoToJSON(value['dateRange']),
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
    };
}

