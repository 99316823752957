import React from 'react';
import invariant from 'tiny-invariant';
import { FormatCompanyRelationDto } from '../../../../api';
import HistoryPage from '../../../../ui/history/history-page';
import { PageState } from '../../../../ui/pagination/use-pagination-state';
import useFetcher from '../../../../util/swr/use-fetcher';
import { FormatHistoryExtension } from '../../../format/format-relation/format-history-extension';
import { fetchFormatCompanyHistoryByFormat } from '../format-company-queries';
import useFormatCompanyHistorySettings from './use-format-company-history-settings';

export const formatCompanyHistoryExtension: FormatHistoryExtension = {
  content: (formatId, state, onStateChanged) => (
    <FormatCompanyHistory formatId={formatId} state={state} onStateChanged={onStateChanged} />
  ),
};

interface PersonAccessGroupHistoryProps {
  formatId: string;
  state: PageState;
  onStateChanged: (state: PageState) => void;
}

function FormatCompanyHistory({ formatId, state, onStateChanged }: PersonAccessGroupHistoryProps) {
  const formatCompanyHistorySettings = useFormatCompanyHistorySettings();

  const formatCompanyHistoryPage = useFetcher(fetchFormatCompanyHistoryByFormat, {
    id: formatId,
    pageable: state,
  });

  invariant(formatCompanyHistoryPage != null, 'Missing format company history page');

  return (
    <HistoryPage<FormatCompanyRelationDto>
      page={formatCompanyHistoryPage}
      state={state}
      onStateChange={onStateChanged}
      historyDisplaySettings={formatCompanyHistorySettings}
      showLine={false}
    />
  );
}
