import React from 'react';
import { useTranslation } from 'react-i18next';
import { DescriptionGroup, DescriptionItem } from '../../../ui/description';
import Optional from '../../../ui/optional/optional';
import CompanyReference from '../../company/company-reference/company-reference';
import StandReference from '../../stand/stand-reference/stand-reference';
import { AutomaticConnectionsDto } from '../../../api';

interface CodeConnectionViewerProps {
  automaticConnections: AutomaticConnectionsDto;
}

export default function CodeConnectionViewer({ automaticConnections }: CodeConnectionViewerProps) {
  const { t } = useTranslation(['code', 'stand']);

  function getConnection(): JSX.Element {
    switch (automaticConnections.connectionType) {
      case 'COMPANY':
        return (
          <>
            <DescriptionItem label={t('code:automaticConnections.connectionType')}>
              <CompanyReference companyReference={automaticConnections.companyConnection?.companyConnection} />
            </DescriptionItem>
            <DescriptionItem label={t('code:automaticConnections.type')}>
              <Optional>
                {automaticConnections.companyConnection?.type &&
                  t(`code:automaticConnections.typeOptions.${automaticConnections.companyConnection?.type}`)}
              </Optional>
            </DescriptionItem>
          </>
        );
      case 'STAND':
        return (
          <>
            <DescriptionItem label={t('code:automaticConnections.connectionType')}>
              <StandReference stand={automaticConnections.standConnection!.standConnection} />
            </DescriptionItem>
            <DescriptionItem label={t('code:automaticConnections.type')}>
              <Optional>
                {automaticConnections.standConnection?.type &&
                  t(`stand:personRelation.relationTypeOptions.${automaticConnections.standConnection?.type}`)}
              </Optional>
            </DescriptionItem>
          </>
        );
      case 'NONE':
        return (
          <DescriptionItem label={t('code:automaticConnections.connectionType')}>
            {t(`code:automaticConnections.connectionTypeOptions.${automaticConnections.connectionType}`)}
          </DescriptionItem>
        );
    }
  }

  return <DescriptionGroup label={t('code:automaticConnection')}>{getConnection()}</DescriptionGroup>;
}
