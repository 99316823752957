/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * JWK public key.
 * @export
 * @interface JwkDto
 */
export interface JwkDto {
    [key: string]: any | any;
    /**
     * 
     * @type {string}
     * @memberof JwkDto
     */
    kty: string;
    /**
     * 
     * @type {string}
     * @memberof JwkDto
     */
    alg?: string;
    /**
     * 
     * @type {string}
     * @memberof JwkDto
     */
    use?: JwkDtoUseEnum;
}


/**
 * @export
 */
export const JwkDtoUseEnum = {
    SIG: 'sig',
    ENC: 'enc'
} as const;
export type JwkDtoUseEnum = typeof JwkDtoUseEnum[keyof typeof JwkDtoUseEnum];


/**
 * Check if a given object implements the JwkDto interface.
 */
export function instanceOfJwkDto(value: object): boolean {
    if (!('kty' in value)) return false;
    return true;
}

export function JwkDtoFromJSON(json: any): JwkDto {
    return JwkDtoFromJSONTyped(json, false);
}

export function JwkDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JwkDto {
    if (json == null) {
        return json;
    }
    return {
        
            ...json,
        'kty': json['kty'],
        'alg': json['alg'] == null ? undefined : json['alg'],
        'use': json['use'] == null ? undefined : json['use'],
    };
}

export function JwkDtoToJSON(value?: JwkDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
            ...value,
        'kty': value['kty'],
        'alg': value['alg'],
        'use': value['use'],
    };
}

