import React, { createContext, useContext } from 'react';

const StickyOffsetContext = createContext<number>(0);

export function StickyOffsetContextProvider({ children, value }: { children: React.ReactNode; value: number }) {
  return <StickyOffsetContext.Provider value={value}>{children}</StickyOffsetContext.Provider>;
}

export function useStickyOffset() {
  return useContext(StickyOffsetContext);
}
