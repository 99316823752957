import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useStaffPermissionClassGroupExtension(): PermissionClassGroupExtension<'STAFF'> {
  const { t } = useTranslation(['staff']);

  return {
    name: 'STAFF',
    label: t('staff:staff'),
    getActionLabel: (action) => t(`staff:permission.${action}`),
    getAdditionalRequirement: (action) => t(`staff:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`staff:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
