import useSWR from 'swr';
import { EditionDto } from '../../../api';
import editionApi from '../../../data-access/edition-api';

export default function useEdition(editionId: string, options?: { suspense?: true }): EditionDto;
export default function useEdition(
  editionId: string | undefined,
  options?: { suspense?: true },
): EditionDto | undefined;
export default function useEdition(editionId: string, options: { suspense: false }): EditionDto | undefined;
export default function useEdition(editionId: string | undefined, options: { suspense: false }): EditionDto | undefined;
export default function useEdition(editionId: string | undefined, { suspense = true }: { suspense?: boolean } = {}) {
  const { data } = useSWR(
    editionId != null ? ['edition', editionId] : null,
    () => editionApi.fetchEdition({ id: editionId! }),
    {
      suspense,
    },
  );

  return data;
}
