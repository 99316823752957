/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClosedDateTimeRangeDto
 */
export interface ClosedDateTimeRangeDto {
    /**
     * 
     * @type {Date}
     * @memberof ClosedDateTimeRangeDto
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClosedDateTimeRangeDto
     */
    end: Date;
}

/**
 * Check if a given object implements the ClosedDateTimeRangeDto interface.
 */
export function instanceOfClosedDateTimeRangeDto(value: object): boolean {
    if (!('start' in value)) return false;
    if (!('end' in value)) return false;
    return true;
}

export function ClosedDateTimeRangeDtoFromJSON(json: any): ClosedDateTimeRangeDto {
    return ClosedDateTimeRangeDtoFromJSONTyped(json, false);
}

export function ClosedDateTimeRangeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClosedDateTimeRangeDto {
    if (json == null) {
        return json;
    }
    return {
        
        'start': (new Date(json['start'])),
        'end': (new Date(json['end'])),
    };
}

export function ClosedDateTimeRangeDtoToJSON(value?: ClosedDateTimeRangeDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'start': ((value['start']).toISOString()),
        'end': ((value['end']).toISOString()),
    };
}

