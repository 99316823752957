import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const productMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.product" />,
    path: '/products',
    neccessaryPermission: 'PRODUCT:CAN_SEE_LIST_VIEW',
  },
];

export default productMenuItems;
