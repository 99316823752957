import { FormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import usePrompt from '../../util/use-prompt/use-prompt';

export default function useUnsavedChangesPrompt({
  formState: { dirtyFields, isSubmitted, isSubmitting },
  promptExemptions,
  when = false,
}: {
  formState: FormState<any>;
  promptExemptions?: RegExp;
  when?: boolean;
}) {
  const { t } = useTranslation('common');

  usePrompt({
    message: t('misc.unsaved_changes'),
    when: (when || Object.keys(dirtyFields).length > 0) && !isSubmitting && !isSubmitted,
    exemption: promptExemptions,
  });
}
