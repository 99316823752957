import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocationDto } from '../../../api';
import FinalDeleteConfirmDialog, {
  createDefaultDeleteChecklistItem,
} from '../../../ui/dialog/final-delete-confirm-dialog';

export interface LocationDeleteDialogProps {
  location: LocationDto;
  isOpen: boolean;
  onClose(confirm: boolean): void;
}

export default function LocationDeleteDialog({ location, onClose, ...props }: LocationDeleteDialogProps) {
  const { t } = useTranslation('location');

  const confirmationMessageChecklistItems = [
    createDefaultDeleteChecklistItem({
      objectTypeWithArticleGenitive: t('location_with_article_genitive'),
      objectToDelete: t('location_object_formatted', {
        name: location.name,
        abbreviation: location.abbreviation,
      }),
    }),
  ];

  return (
    <FinalDeleteConfirmDialog
      {...props}
      objectTypeToDelete={t('location')}
      confirmationCheckListItems={confirmationMessageChecklistItems}
      onClose={onClose}
    />
  );
}
