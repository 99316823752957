/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The sex of the person in official document.
 * @export
 */
export const OfficiallyRegisteredGenderDto = {
    FEMALE: 'FEMALE',
    MALE: 'MALE',
    NON_BINARY: 'NON_BINARY',
    X: 'X'
} as const;
export type OfficiallyRegisteredGenderDto = typeof OfficiallyRegisteredGenderDto[keyof typeof OfficiallyRegisteredGenderDto];


export function OfficiallyRegisteredGenderDtoFromJSON(json: any): OfficiallyRegisteredGenderDto {
    return OfficiallyRegisteredGenderDtoFromJSONTyped(json, false);
}

export function OfficiallyRegisteredGenderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfficiallyRegisteredGenderDto {
    return json as OfficiallyRegisteredGenderDto;
}

export function OfficiallyRegisteredGenderDtoToJSON(value?: OfficiallyRegisteredGenderDto | null): any {
    return value as any;
}

