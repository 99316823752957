import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useCompanyPermissionClassGroupExtension(): PermissionClassGroupExtension<'COMPANY'> {
  const { t } = useTranslation(['company']);

  return {
    name: 'COMPANY',
    label: t('company:company'),
    getActionLabel: (action) => t(`company:permission.${action}`),
    getAdditionalRequirement: (action) => t(`company:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`company:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
