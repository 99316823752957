import { faCalendarCheck } from '@fortawesome/pro-regular-svg-icons';
import { PluginRegistry } from '../../../util/plugin/use-plugins';
import Translate from '../../../util/translate/translate';
import PERSON_CHILD_ROUTES from '../../person/person-child-routes';
import { PERSON_TAB_ITEM, PersonTabItem } from '../../person/person-page/person-page';
import personAppointmentRoutes from './person-appointment-routes';

export const appointmentPersonTabItem: PersonTabItem = {
  order: 2,
  label: <Translate ns="appointment" i18nKey="lister.title" />,
  icon: faCalendarCheck,
  to: './appointments',
};

export default function PersonAppointmentProvider(registry: PluginRegistry) {
  registry.register(PERSON_TAB_ITEM, appointmentPersonTabItem);
  registry.register(PERSON_CHILD_ROUTES, personAppointmentRoutes);
}
