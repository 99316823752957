import { Stack } from '@chakra-ui/react';
import React, { useId } from 'react';
import { useTranslation } from 'react-i18next';
import CheckmarkBooleanViewer from '../../../ui/boolean-viewer/checkmark-boolean-viewer';
import Label from '../../../ui/form/label';
import Optional from '../../../ui/optional/optional';

interface RsvpModuleViewerProps {
  commentsAllowed: boolean;
  commentText?: string;
}

export default function RsvpModuleViewer({ commentsAllowed, commentText }: RsvpModuleViewerProps) {
  const { t } = useTranslation('guest_list');
  const id = useId();
  return (
    <Stack spacing={4}>
      <CheckmarkBooleanViewer<boolean>
        renderLabel={(value) =>
          value ? t('landingPage.rsvpModule.commentsAllowed') : t('landingPage.rsvpModule.commentsNotAllowed')
        }
        truthiness={(value) => value}
        value={commentsAllowed}
      />
      {commentsAllowed && (
        <Optional>
          {commentText !== '' && (
            <Stack spacing={1}>
              <Label id={id}>{t('landingPage.rsvpModule.helperTextLabel')}</Label>{' '}
              <em aria-labelledby={id}>{commentText}</em>
            </Stack>
          )}
        </Optional>
      )}
    </Stack>
  );
}
