/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlacementPermissionDto
 */
export interface PlacementPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof PlacementPermissionDto
     */
    name: PlacementPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof PlacementPermissionDto
     */
    action: PlacementPermissionDtoActionEnum;
}


/**
 * @export
 */
export const PlacementPermissionDtoNameEnum = {
    PLACEMENT: 'PLACEMENT'
} as const;
export type PlacementPermissionDtoNameEnum = typeof PlacementPermissionDtoNameEnum[keyof typeof PlacementPermissionDtoNameEnum];

/**
 * @export
 */
export const PlacementPermissionDtoActionEnum = {
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    SEE_DETAILS_OWN: 'CAN_SEE_DETAILS_OWN',
    EDIT: 'CAN_EDIT',
    EDIT_OWN: 'CAN_EDIT_OWN',
    CREATE: 'CAN_CREATE',
    CREATE_OWN: 'CAN_CREATE_OWN',
    DELETE: 'CAN_DELETE',
    DELETE_OWN: 'CAN_DELETE_OWN',
    COMPARE_OWN: 'CAN_COMPARE_OWN',
    EXPORT: 'CAN_EXPORT',
    EXPORT_OWN: 'CAN_EXPORT_OWN'
} as const;
export type PlacementPermissionDtoActionEnum = typeof PlacementPermissionDtoActionEnum[keyof typeof PlacementPermissionDtoActionEnum];


/**
 * Check if a given object implements the PlacementPermissionDto interface.
 */
export function instanceOfPlacementPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function PlacementPermissionDtoFromJSON(json: any): PlacementPermissionDto {
    return PlacementPermissionDtoFromJSONTyped(json, false);
}

export function PlacementPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlacementPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function PlacementPermissionDtoToJSON(value?: PlacementPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

