import { HStack } from '@chakra-ui/react';
import React from 'react';
import { AccountDto, PersonReferenceDto, PersonReferenceDtoFromJSON } from '../../../api';
import { DescriptionItem } from '../../../ui/description';
import Translate from '../../../util/translate/translate';
import PersonReference from '../person-reference/person-reference';

interface PersonAccountDescriptionItemProps {
  account: AccountDto;
}

export default function PersonAccountDescriptionItem({ account }: PersonAccountDescriptionItemProps) {
  const person = usePerson(account);

  return person != null ? (
    <DescriptionItem label={<Translate ns="person" i18nKey="person" />} span={2}>
      <HStack>
        <PersonReference personReference={person} hidePersonKey usePortalForCard />
      </HStack>
    </DescriptionItem>
  ) : undefined;
}

function usePerson(account: AccountDto): PersonReferenceDto {
  return PersonReferenceDtoFromJSON(account.extensions.person);
}
