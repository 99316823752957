/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CountryPhonePrefixDto
 */
export interface CountryPhonePrefixDto {
    /**
     * The country code of the phone number
     * @type {string}
     * @memberof CountryPhonePrefixDto
     */
    countryCode: string;
    /**
     * 
     * @type {string}
     * @memberof CountryPhonePrefixDto
     */
    countryName: string;
    /**
     * 
     * @type {string}
     * @memberof CountryPhonePrefixDto
     */
    englishCountryName: string;
}

/**
 * Check if a given object implements the CountryPhonePrefixDto interface.
 */
export function instanceOfCountryPhonePrefixDto(value: object): boolean {
    if (!('countryCode' in value)) return false;
    if (!('countryName' in value)) return false;
    if (!('englishCountryName' in value)) return false;
    return true;
}

export function CountryPhonePrefixDtoFromJSON(json: any): CountryPhonePrefixDto {
    return CountryPhonePrefixDtoFromJSONTyped(json, false);
}

export function CountryPhonePrefixDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountryPhonePrefixDto {
    if (json == null) {
        return json;
    }
    return {
        
        'countryCode': json['countryCode'],
        'countryName': json['countryName'],
        'englishCountryName': json['englishCountryName'],
    };
}

export function CountryPhonePrefixDtoToJSON(value?: CountryPhonePrefixDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'countryCode': value['countryCode'],
        'countryName': value['countryName'],
        'englishCountryName': value['englishCountryName'],
    };
}

