/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LocationPostalAddressDto } from './LocationPostalAddressDto';
import {
    LocationPostalAddressDtoFromJSON,
    LocationPostalAddressDtoFromJSONTyped,
    LocationPostalAddressDtoToJSON,
} from './LocationPostalAddressDto';
import type { LocationStatusDto } from './LocationStatusDto';
import {
    LocationStatusDtoFromJSON,
    LocationStatusDtoFromJSONTyped,
    LocationStatusDtoToJSON,
} from './LocationStatusDto';

/**
 * Short infos of a location
 * @export
 * @interface LocationCardDto
 */
export interface LocationCardDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof LocationCardDto
     */
    id: string;
    /**
     * the german name of the location
     * @type {string}
     * @memberof LocationCardDto
     */
    name: string;
    /**
     * the english name of the location
     * @type {string}
     * @memberof LocationCardDto
     */
    englishName?: string;
    /**
     * 
     * @type {Array<LocationPostalAddressDto>}
     * @memberof LocationCardDto
     */
    addresses?: Array<LocationPostalAddressDto>;
    /**
     * 
     * @type {LocationStatusDto}
     * @memberof LocationCardDto
     */
    status: LocationStatusDto;
}

/**
 * Check if a given object implements the LocationCardDto interface.
 */
export function instanceOfLocationCardDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function LocationCardDtoFromJSON(json: any): LocationCardDto {
    return LocationCardDtoFromJSONTyped(json, false);
}

export function LocationCardDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationCardDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'englishName': json['englishName'] == null ? undefined : json['englishName'],
        'addresses': json['addresses'] == null ? undefined : ((json['addresses'] as Array<any>).map(LocationPostalAddressDtoFromJSON)),
        'status': LocationStatusDtoFromJSON(json['status']),
    };
}

export function LocationCardDtoToJSON(value?: LocationCardDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'englishName': value['englishName'],
        'addresses': value['addresses'] == null ? undefined : ((value['addresses'] as Array<any>).map(LocationPostalAddressDtoToJSON)),
        'status': LocationStatusDtoToJSON(value['status']),
    };
}

