/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryReferenceDto } from './CountryReferenceDto';
import {
    CountryReferenceDtoFromJSON,
    CountryReferenceDtoFromJSONTyped,
    CountryReferenceDtoToJSON,
} from './CountryReferenceDto';

/**
 * 
 * @export
 * @interface RecipientPersonPostalAddressDto
 */
export interface RecipientPersonPostalAddressDto {
    /**
     * The address
     * @type {string}
     * @memberof RecipientPersonPostalAddressDto
     */
    address?: string;
    /**
     * The address supplement
     * @type {string}
     * @memberof RecipientPersonPostalAddressDto
     */
    addressSupplement?: string;
    /**
     * The post code of the address
     * @type {string}
     * @memberof RecipientPersonPostalAddressDto
     */
    postCode?: string;
    /**
     * The city of the address
     * @type {string}
     * @memberof RecipientPersonPostalAddressDto
     */
    city: string;
    /**
     * The state of the address
     * @type {string}
     * @memberof RecipientPersonPostalAddressDto
     */
    state?: string;
    /**
     * 
     * @type {CountryReferenceDto}
     * @memberof RecipientPersonPostalAddressDto
     */
    country: CountryReferenceDto;
    /**
     * 
     * @type {Date}
     * @memberof RecipientPersonPostalAddressDto
     */
    timestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof RecipientPersonPostalAddressDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientPersonPostalAddressDto
     */
    surname: string;
}

/**
 * Check if a given object implements the RecipientPersonPostalAddressDto interface.
 */
export function instanceOfRecipientPersonPostalAddressDto(value: object): boolean {
    if (!('city' in value)) return false;
    if (!('country' in value)) return false;
    if (!('firstName' in value)) return false;
    if (!('surname' in value)) return false;
    return true;
}

export function RecipientPersonPostalAddressDtoFromJSON(json: any): RecipientPersonPostalAddressDto {
    return RecipientPersonPostalAddressDtoFromJSONTyped(json, false);
}

export function RecipientPersonPostalAddressDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecipientPersonPostalAddressDto {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'] == null ? undefined : json['address'],
        'addressSupplement': json['addressSupplement'] == null ? undefined : json['addressSupplement'],
        'postCode': json['postCode'] == null ? undefined : json['postCode'],
        'city': json['city'],
        'state': json['state'] == null ? undefined : json['state'],
        'country': CountryReferenceDtoFromJSON(json['country']),
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
        'firstName': json['firstName'],
        'surname': json['surname'],
    };
}

export function RecipientPersonPostalAddressDtoToJSON(value?: RecipientPersonPostalAddressDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': value['address'],
        'addressSupplement': value['addressSupplement'],
        'postCode': value['postCode'],
        'city': value['city'],
        'state': value['state'],
        'country': CountryReferenceDtoToJSON(value['country']),
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
        'firstName': value['firstName'],
        'surname': value['surname'],
    };
}

