/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const StandPersonRelationTypeDto = {
    OTHER: 'OTHER',
    STAND_COORDINATOR: 'STAND_COORDINATOR',
    STAND_PERSONNEL: 'STAND_PERSONNEL',
    EXTERNAL_CONTACT: 'EXTERNAL_CONTACT'
} as const;
export type StandPersonRelationTypeDto = typeof StandPersonRelationTypeDto[keyof typeof StandPersonRelationTypeDto];


export function StandPersonRelationTypeDtoFromJSON(json: any): StandPersonRelationTypeDto {
    return StandPersonRelationTypeDtoFromJSONTyped(json, false);
}

export function StandPersonRelationTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandPersonRelationTypeDto {
    return json as StandPersonRelationTypeDto;
}

export function StandPersonRelationTypeDtoToJSON(value?: StandPersonRelationTypeDto | null): any {
    return value as any;
}

