/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DateTimeRangeDto } from './DateTimeRangeDto';
import {
    DateTimeRangeDtoFromJSON,
    DateTimeRangeDtoFromJSONTyped,
    DateTimeRangeDtoToJSON,
} from './DateTimeRangeDto';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { LandingPageModuleContentDto } from './LandingPageModuleContentDto';
import {
    LandingPageModuleContentDtoFromJSON,
    LandingPageModuleContentDtoFromJSONTyped,
    LandingPageModuleContentDtoToJSON,
} from './LandingPageModuleContentDto';
import type { LandingPageTemplateReferenceDto } from './LandingPageTemplateReferenceDto';
import {
    LandingPageTemplateReferenceDtoFromJSON,
    LandingPageTemplateReferenceDtoFromJSONTyped,
    LandingPageTemplateReferenceDtoToJSON,
} from './LandingPageTemplateReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface LandingPageDto
 */
export interface LandingPageDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof LandingPageDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof LandingPageDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {LandingPageTemplateReferenceDto}
     * @memberof LandingPageDto
     */
    template: LandingPageTemplateReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof LandingPageDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPageDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPageDto
     */
    brand: string;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof LandingPageDto
     */
    edition?: EditionReferenceDto;
    /**
     * 
     * @type {DateTimeRangeDto}
     * @memberof LandingPageDto
     */
    active: DateTimeRangeDto;
    /**
     * 
     * @type {Array<LandingPageModuleContentDto>}
     * @memberof LandingPageDto
     */
    modules: Array<LandingPageModuleContentDto>;
    /**
     * Like Map but serialize from/to JsonNodes in the Backend
     * @type {{ [key: string]: any; }}
     * @memberof LandingPageDto
     */
    extensionData?: { [key: string]: any; };
}

/**
 * Check if a given object implements the LandingPageDto interface.
 */
export function instanceOfLandingPageDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('template' in value)) return false;
    if (!('type' in value)) return false;
    if (!('name' in value)) return false;
    if (!('brand' in value)) return false;
    if (!('active' in value)) return false;
    if (!('modules' in value)) return false;
    return true;
}

export function LandingPageDtoFromJSON(json: any): LandingPageDto {
    return LandingPageDtoFromJSONTyped(json, false);
}

export function LandingPageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'template': LandingPageTemplateReferenceDtoFromJSON(json['template']),
        'type': json['type'],
        'name': json['name'],
        'brand': json['brand'],
        'edition': json['edition'] == null ? undefined : EditionReferenceDtoFromJSON(json['edition']),
        'active': DateTimeRangeDtoFromJSON(json['active']),
        'modules': ((json['modules'] as Array<any>).map(LandingPageModuleContentDtoFromJSON)),
        'extensionData': json['extensionData'] == null ? undefined : json['extensionData'],
    };
}

export function LandingPageDtoToJSON(value?: LandingPageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'template': LandingPageTemplateReferenceDtoToJSON(value['template']),
        'type': value['type'],
        'name': value['name'],
        'brand': value['brand'],
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'active': DateTimeRangeDtoToJSON(value['active']),
        'modules': ((value['modules'] as Array<any>).map(LandingPageModuleContentDtoToJSON)),
        'extensionData': value['extensionData'],
    };
}

