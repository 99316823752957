/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MailingTypeDto } from './MailingTypeDto';
import {
    MailingTypeDtoFromJSON,
    MailingTypeDtoFromJSONTyped,
    MailingTypeDtoToJSON,
} from './MailingTypeDto';

/**
 * 
 * @export
 * @interface MailingTemplateReferenceDto
 */
export interface MailingTemplateReferenceDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof MailingTemplateReferenceDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MailingTemplateReferenceDto
     */
    name: string;
    /**
     * 
     * @type {MailingTypeDto}
     * @memberof MailingTemplateReferenceDto
     */
    type: MailingTypeDto;
}

/**
 * Check if a given object implements the MailingTemplateReferenceDto interface.
 */
export function instanceOfMailingTemplateReferenceDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('type' in value)) return false;
    return true;
}

export function MailingTemplateReferenceDtoFromJSON(json: any): MailingTemplateReferenceDto {
    return MailingTemplateReferenceDtoFromJSONTyped(json, false);
}

export function MailingTemplateReferenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingTemplateReferenceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'type': MailingTypeDtoFromJSON(json['type']),
    };
}

export function MailingTemplateReferenceDtoToJSON(value?: MailingTemplateReferenceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'type': MailingTypeDtoToJSON(value['type']),
    };
}

