import { Alert, AlertIcon, AlertTitle, chakra, HStack, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import React from 'react';
import invariant from 'tiny-invariant';
import { MergePersonGroupPersonConflictDto } from '../../../../../api';
import GroupReference from '../../../../group/group-reference/group-reference';

export function getMergeConflictMessages(
  conflicts: MergePersonGroupPersonConflictDto[],
  t: any,
  index: number,
): React.ReactNode {
  const groupPersonConflicts = conflicts.filter((conflict) => conflict.conflictType === 'GROUP_PERSON');
  if (groupPersonConflicts.length > 0) {
    return (
      <Alert key={index} status="warning" aria-labelledby="merger-conflict-warning-title">
        <AlertIcon />
        <chakra.div>
          <AlertTitle id="merger-guestlist-conflict-warning-title" mr={2}>
            {t('person:merger.conflicts.title')}
          </AlertTitle>
          <Text mt={1} mb={4}>
            {t('person:merger.conflicts.groupPerson.intro')}
          </Text>
          <UnorderedList mt={1} listStyleType="'—'" marginInlineStart="0.8em" spacing={1}>
            {groupPersonConflicts.map((conflict) => {
              invariant(conflict.group);

              return (
                <ListItem pl={2} key={conflict.group?.id}>
                  <HStack>
                    <GroupReference groupReference={conflict.group} maxWidth="450" />
                  </HStack>
                </ListItem>
              );
            })}
          </UnorderedList>
          <Text mt={4}>{t('person:merger.conflicts.groupPerson.body')}</Text>
        </chakra.div>
      </Alert>
    );
  }
  return undefined;
}
