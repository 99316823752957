/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DateRangeDto } from './DateRangeDto';
import {
    DateRangeDtoFromJSON,
    DateRangeDtoFromJSONTyped,
    DateRangeDtoToJSON,
} from './DateRangeDto';

/**
 * 
 * @export
 * @interface TerminateCompanyPersonRelationRequestDto
 */
export interface TerminateCompanyPersonRelationRequestDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof TerminateCompanyPersonRelationRequestDto
     */
    occupationId: string;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof TerminateCompanyPersonRelationRequestDto
     */
    validityPeriod: DateRangeDto;
}

/**
 * Check if a given object implements the TerminateCompanyPersonRelationRequestDto interface.
 */
export function instanceOfTerminateCompanyPersonRelationRequestDto(value: object): boolean {
    if (!('occupationId' in value)) return false;
    if (!('validityPeriod' in value)) return false;
    return true;
}

export function TerminateCompanyPersonRelationRequestDtoFromJSON(json: any): TerminateCompanyPersonRelationRequestDto {
    return TerminateCompanyPersonRelationRequestDtoFromJSONTyped(json, false);
}

export function TerminateCompanyPersonRelationRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TerminateCompanyPersonRelationRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'occupationId': json['occupationId'],
        'validityPeriod': DateRangeDtoFromJSON(json['validityPeriod']),
    };
}

export function TerminateCompanyPersonRelationRequestDtoToJSON(value?: TerminateCompanyPersonRelationRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'occupationId': value['occupationId'],
        'validityPeriod': DateRangeDtoToJSON(value['validityPeriod']),
    };
}

