import { Checkbox, List, ListItem, ListProps } from '@chakra-ui/react';
import React from 'react';

export interface ChecklistProps extends ListProps {
  tasks: React.ReactNode[];
  onAllChecked?(allChecked: boolean): void;
  initialFocusRef?: React.RefObject<any>;
}

export default function Checklist({ tasks, onAllChecked, spacing = 2, initialFocusRef, ...props }: ChecklistProps) {
  const [checkedTasks, setCheckedTasks] = React.useState<number[]>([]);
  const allImplicationCheckedRef = React.useRef<boolean>();

  // It's valid to pass a boolean, null or undefined as a task (to have skipped tasks because some condition is not met)
  // but we don't them to take part in all checked calculation.
  tasks = tasks.filter((task) => task != null && task !== false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const nextCheckedTasks = [...checkedTasks, index].filter(
      (checkedTasks) => checkedTasks !== index || event.target.checked,
    );

    setCheckedTasks(nextCheckedTasks);

    const allImplicationChecked = tasks.every((task, index) => nextCheckedTasks.includes(index));

    if (allImplicationChecked !== allImplicationCheckedRef.current) {
      onAllChecked?.(allImplicationChecked);
      allImplicationCheckedRef.current = allImplicationChecked;
    }
  };

  return (
    <List spacing={spacing} {...props}>
      {tasks.map((task, index) => (
        <ListItem key={index}>
          <Checkbox
            ref={index === 0 ? initialFocusRef : undefined}
            spacing={3}
            isChecked={checkedTasks.includes(index)}
            onChange={(event) => handleCheckboxChange(event, index)}
          >
            {task}
          </Checkbox>
        </ListItem>
      ))}
    </List>
  );
}
