/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AreaDto } from './AreaDto';
import {
    AreaDtoFromJSON,
    AreaDtoFromJSONTyped,
    AreaDtoToJSON,
} from './AreaDto';
import type { MarkerDto } from './MarkerDto';
import {
    MarkerDtoFromJSON,
    MarkerDtoFromJSONTyped,
    MarkerDtoToJSON,
} from './MarkerDto';
import type { SeatTemplateDto } from './SeatTemplateDto';
import {
    SeatTemplateDtoFromJSON,
    SeatTemplateDtoFromJSONTyped,
    SeatTemplateDtoToJSON,
} from './SeatTemplateDto';
import type { TextDto } from './TextDto';
import {
    TextDtoFromJSON,
    TextDtoFromJSONTyped,
    TextDtoToJSON,
} from './TextDto';

/**
 * 
 * @export
 * @interface PlanDto
 */
export interface PlanDto {
    /**
     * 
     * @type {Array<AreaDto>}
     * @memberof PlanDto
     */
    areas: Array<AreaDto>;
    /**
     * 
     * @type {Array<TextDto>}
     * @memberof PlanDto
     */
    texts: Array<TextDto>;
    /**
     * 
     * @type {Array<MarkerDto>}
     * @memberof PlanDto
     */
    markers: Array<MarkerDto>;
    /**
     * 
     * @type {Array<SeatTemplateDto>}
     * @memberof PlanDto
     */
    seats: Array<SeatTemplateDto>;
}

/**
 * Check if a given object implements the PlanDto interface.
 */
export function instanceOfPlanDto(value: object): boolean {
    if (!('areas' in value)) return false;
    if (!('texts' in value)) return false;
    if (!('markers' in value)) return false;
    if (!('seats' in value)) return false;
    return true;
}

export function PlanDtoFromJSON(json: any): PlanDto {
    return PlanDtoFromJSONTyped(json, false);
}

export function PlanDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanDto {
    if (json == null) {
        return json;
    }
    return {
        
        'areas': ((json['areas'] as Array<any>).map(AreaDtoFromJSON)),
        'texts': ((json['texts'] as Array<any>).map(TextDtoFromJSON)),
        'markers': ((json['markers'] as Array<any>).map(MarkerDtoFromJSON)),
        'seats': ((json['seats'] as Array<any>).map(SeatTemplateDtoFromJSON)),
    };
}

export function PlanDtoToJSON(value?: PlanDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'areas': ((value['areas'] as Array<any>).map(AreaDtoToJSON)),
        'texts': ((value['texts'] as Array<any>).map(TextDtoToJSON)),
        'markers': ((value['markers'] as Array<any>).map(MarkerDtoToJSON)),
        'seats': ((value['seats'] as Array<any>).map(SeatTemplateDtoToJSON)),
    };
}

