/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { StaffAccountReferenceDto } from './StaffAccountReferenceDto';
import {
    StaffAccountReferenceDtoFromJSON,
    StaffAccountReferenceDtoFromJSONTyped,
    StaffAccountReferenceDtoToJSON,
} from './StaffAccountReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface LiveTicketingPublicationDto
 */
export interface LiveTicketingPublicationDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof LiveTicketingPublicationDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof LiveTicketingPublicationDto
     */
    version?: VersionDto;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof LiveTicketingPublicationDto
     */
    eventId?: string;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof LiveTicketingPublicationDto
     */
    recipient: PersonReferenceDto;
    /**
     * 
     * @type {Date}
     * @memberof LiveTicketingPublicationDto
     */
    dateOfPublication?: Date;
    /**
     * 
     * @type {StaffAccountReferenceDto}
     * @memberof LiveTicketingPublicationDto
     */
    publishedBy?: StaffAccountReferenceDto;
    /**
     * 
     * @type {Date}
     * @memberof LiveTicketingPublicationDto
     */
    expiryDate: Date;
    /**
     * 
     * @type {string}
     * @memberof LiveTicketingPublicationDto
     */
    cause: string;
    /**
     * 
     * @type {Date}
     * @memberof LiveTicketingPublicationDto
     */
    suspended?: Date;
    /**
     * 
     * @type {StaffAccountReferenceDto}
     * @memberof LiveTicketingPublicationDto
     */
    suspendedBy?: StaffAccountReferenceDto;
    /**
     * 
     * @type {boolean}
     * @memberof LiveTicketingPublicationDto
     */
    anonymizePersonalData: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LiveTicketingPublicationDto
     */
    isLiveTicketingPublication?: boolean;
}

/**
 * Check if a given object implements the LiveTicketingPublicationDto interface.
 */
export function instanceOfLiveTicketingPublicationDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('recipient' in value)) return false;
    if (!('expiryDate' in value)) return false;
    if (!('cause' in value)) return false;
    if (!('anonymizePersonalData' in value)) return false;
    return true;
}

export function LiveTicketingPublicationDtoFromJSON(json: any): LiveTicketingPublicationDto {
    return LiveTicketingPublicationDtoFromJSONTyped(json, false);
}

export function LiveTicketingPublicationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LiveTicketingPublicationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'eventId': json['eventId'] == null ? undefined : json['eventId'],
        'recipient': PersonReferenceDtoFromJSON(json['recipient']),
        'dateOfPublication': json['dateOfPublication'] == null ? undefined : (new Date(json['dateOfPublication'])),
        'publishedBy': json['publishedBy'] == null ? undefined : StaffAccountReferenceDtoFromJSON(json['publishedBy']),
        'expiryDate': (new Date(json['expiryDate'])),
        'cause': json['cause'],
        'suspended': json['suspended'] == null ? undefined : (new Date(json['suspended'])),
        'suspendedBy': json['suspendedBy'] == null ? undefined : StaffAccountReferenceDtoFromJSON(json['suspendedBy']),
        'anonymizePersonalData': json['anonymizePersonalData'],
        'isLiveTicketingPublication': json['isLiveTicketingPublication'] == null ? undefined : json['isLiveTicketingPublication'],
    };
}

export function LiveTicketingPublicationDtoToJSON(value?: LiveTicketingPublicationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'eventId': value['eventId'],
        'recipient': PersonReferenceDtoToJSON(value['recipient']),
        'dateOfPublication': value['dateOfPublication'] == null ? undefined : ((value['dateOfPublication']).toISOString()),
        'publishedBy': StaffAccountReferenceDtoToJSON(value['publishedBy']),
        'expiryDate': ((value['expiryDate']).toISOString()),
        'cause': value['cause'],
        'suspended': value['suspended'] == null ? undefined : ((value['suspended']).toISOString()),
        'suspendedBy': StaffAccountReferenceDtoToJSON(value['suspendedBy']),
        'anonymizePersonalData': value['anonymizePersonalData'],
        'isLiveTicketingPublication': value['isLiveTicketingPublication'],
    };
}

