import { chakra, Grid, GridItem } from '@chakra-ui/react';
import React from 'react';
import { AttendeeDto } from '../../../api';
import DataTable, { useDataTableState } from '../../../ui/data-table';
import useRequestParams from '../../../ui/data-table/use-request-params';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchAttendees } from '../live-ticketing-queries';
import useAttendeeColumns from './use-attendee-columns';

interface AttendeesListProps {
  eventId: string;
}

export default function AttendeesList({ eventId }: AttendeesListProps) {
  const [state, setState] = useDataTableState({ size: 20 });
  const requestParams = useRequestParams(state);
  const page = useFetcher(fetchAttendees, { eventId, ...requestParams });
  const columns = useAttendeeColumns({ isReadOnly: false });

  return (
    <chakra.div m={4}>
      <Grid gridTemplateRows="auto 1fr" height="full">
        <GridItem minHeight={0}>
          <DataTable
            page={page}
            state={state}
            columns={columns}
            rowKey={(attendee: AttendeeDto) => attendee.timeOfAdmission.toISOString()}
            onStateChange={setState}
            isPageable={true}
          />
        </GridItem>
      </Grid>
    </chakra.div>
  );
}
