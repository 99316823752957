/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LiveTicketingPriorityDto = {
    ONE: 'ONE',
    TWO: 'TWO',
    THREE: 'THREE'
} as const;
export type LiveTicketingPriorityDto = typeof LiveTicketingPriorityDto[keyof typeof LiveTicketingPriorityDto];


export function LiveTicketingPriorityDtoFromJSON(json: any): LiveTicketingPriorityDto {
    return LiveTicketingPriorityDtoFromJSONTyped(json, false);
}

export function LiveTicketingPriorityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LiveTicketingPriorityDto {
    return json as LiveTicketingPriorityDto;
}

export function LiveTicketingPriorityDtoToJSON(value?: LiveTicketingPriorityDto | null): any {
    return value as any;
}

