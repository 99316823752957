import { isFunction } from 'lodash-es';
import { ReactNode } from 'react';
import usePermission, { Permission, UsePermissionReturn } from './use-permission';

interface HasPermissionProps {
  necessaryPermission: Permission | ((hasPermission: UsePermissionReturn) => boolean);
  children: ReactNode;
  fallback: ReactNode;
}

export default function HasPermission({ necessaryPermission, children, fallback }: HasPermissionProps) {
  const permissions = usePermission();
  const hasPermission = isFunction(necessaryPermission)
    ? necessaryPermission(permissions)
    : permissions.hasPermission(necessaryPermission);

  return hasPermission ? children : fallback;
}
