/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MailingDesignDto } from './MailingDesignDto';
import {
    MailingDesignDtoFromJSON,
    MailingDesignDtoFromJSONTyped,
    MailingDesignDtoToJSON,
} from './MailingDesignDto';

/**
 * 
 * @export
 * @interface MailingDesignSearchResultDto
 */
export interface MailingDesignSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof MailingDesignSearchResultDto
     */
    resultType: MailingDesignSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof MailingDesignSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {MailingDesignDto}
     * @memberof MailingDesignSearchResultDto
     */
    result: MailingDesignDto;
}


/**
 * @export
 */
export const MailingDesignSearchResultDtoResultTypeEnum = {
    MAILING_DESIGN: 'MAILING_DESIGN'
} as const;
export type MailingDesignSearchResultDtoResultTypeEnum = typeof MailingDesignSearchResultDtoResultTypeEnum[keyof typeof MailingDesignSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the MailingDesignSearchResultDto interface.
 */
export function instanceOfMailingDesignSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function MailingDesignSearchResultDtoFromJSON(json: any): MailingDesignSearchResultDto {
    return MailingDesignSearchResultDtoFromJSONTyped(json, false);
}

export function MailingDesignSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingDesignSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': MailingDesignDtoFromJSON(json['result']),
    };
}

export function MailingDesignSearchResultDtoToJSON(value?: MailingDesignSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': MailingDesignDtoToJSON(value['result']),
    };
}

