import { Button, Stack } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PersonReferenceDto, PersonToAddDto, RelationTypeDto } from '../../../../../api';
import groupPersonApi from '../../../../../data-access/group-person-api';
import FormControl from '../../../../../ui/form/form-control';
import ValueSelectFormControl from '../../../../../ui/form/select-control/value-select-form-control';
import PersonSelectControl from '../../../../common/form/person-select-control/person-select-control';

export interface PersonForGroupSelectionControlProps {
  groupId: string;
  peopleAlreadyInGroup: PersonToAddDto[];
  onAdd: (entry: PersonToAddDto) => void;
  initialFocusRef?: React.RefObject<any>;
}

export default function PersonForGroupSelectionControl({
  groupId,
  peopleAlreadyInGroup,
  onAdd,
  initialFocusRef,
}: PersonForGroupSelectionControlProps) {
  const form = useForm<PersonToAddDto>({ mode: 'onChange' });
  const { t } = useTranslation(['group', 'common']);

  const handleAdd = (entry: PersonToAddDto) => {
    // reset before add to clear person field before validation is triggered
    if (validateNoLocalDuplicate(entry.person!)) {
      form.reset({ relationType: entry.relationType });
      onAdd(entry);
    }
  };

  const validateNoLocalDuplicate = (person: PersonReferenceDto) => {
    return (
      peopleAlreadyInGroup.find((e) => e.person?.id === person.id) == null || t('group:connections.duplicate_person')
    );
  };

  const validateNoRemoteDuplicate = async (person: PersonReferenceDto) => {
    const { value: canAddPerson } = await groupPersonApi.canAddPersonToGroup({
      personId: person.id,
      groupId: groupId,
    });
    return canAddPerson || t('group:connections.duplicate_person');
  };

  return (
    <FormProvider {...form}>
      <Stack spacing={4} paddingX={4} paddingBottom={4} paddingTop={3}>
        <FormControl name="person" label={t('group:connections.person')} size="sm" isRequired>
          <PersonSelectControl
            size="sm"
            name="person"
            isRequired
            enableQuickPerson
            ref={initialFocusRef}
            label={t('group:connections.person')}
            rules={{
              validate: {
                validateNoLocalDuplicate,
                validateNoRemoteDuplicate,
              },
            }}
          />
        </FormControl>
        <ValueSelectFormControl<RelationTypeDto>
          name="relationType"
          size="sm"
          label={t('group:connections.relationType')}
          options={[RelationTypeDto.MAIN_GROUP, RelationTypeDto.SECONDARY_GROUP]}
          renderLabel={(type) => t(`group:connections.relationTypeOptions.${type}`)}
          isRequired
        />

        <Button
          onClick={form.handleSubmit(handleAdd)}
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
          size="sm"
          variant="outline"
          isLoading={form.formState.isSubmitting}
          isDisabled={!form.formState.isValid}
        >
          {t('common:action.add')}
        </Button>
      </Stack>
    </FormProvider>
  );
}
