/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Deactivate or reactivate guest list
 * @export
 * @interface GuestListSettingsStatusChangeDto
 */
export interface GuestListSettingsStatusChangeDto {
    /**
     * The new status of the guest list
     * @type {boolean}
     * @memberof GuestListSettingsStatusChangeDto
     */
    active: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GuestListSettingsStatusChangeDto
     */
    defaultRsvpDeadline?: Date;
}

/**
 * Check if a given object implements the GuestListSettingsStatusChangeDto interface.
 */
export function instanceOfGuestListSettingsStatusChangeDto(value: object): boolean {
    if (!('active' in value)) return false;
    return true;
}

export function GuestListSettingsStatusChangeDtoFromJSON(json: any): GuestListSettingsStatusChangeDto {
    return GuestListSettingsStatusChangeDtoFromJSONTyped(json, false);
}

export function GuestListSettingsStatusChangeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuestListSettingsStatusChangeDto {
    if (json == null) {
        return json;
    }
    return {
        
        'active': json['active'],
        'defaultRsvpDeadline': json['defaultRsvpDeadline'] == null ? undefined : (new Date(json['defaultRsvpDeadline'])),
    };
}

export function GuestListSettingsStatusChangeDtoToJSON(value?: GuestListSettingsStatusChangeDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'active': value['active'],
        'defaultRsvpDeadline': value['defaultRsvpDeadline'] == null ? undefined : ((value['defaultRsvpDeadline']).toISOString()),
    };
}

