import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useOrderConfirmationPermissionClassGroupExtension(): PermissionClassGroupExtension<'ORDER_CONFIRMATION'> {
  const { t } = useTranslation(['order_confirmation']);

  return {
    name: 'ORDER_CONFIRMATION',
    label: t('order_confirmation:orderConfirmation'),
    getActionLabel: (action) => t(`order_confirmation:permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`order_confirmation:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`order_confirmation:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
