import { flatMap, sumBy } from 'lodash-es';
import React from 'react';
import { useController, useFieldArray, useFormContext } from 'react-hook-form';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';
import { Message } from 'react-hook-form/dist/types/errors';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { FileMetadataDto, instanceOfFileMetadataDto } from '../../../api';
import storageApi from '../../../data-access/storage-api';
import usePending from '../../../util/use-pending/use-pending';
import { FileType, MEGABYTES } from './file-type';
import { singleFileUploadValidator, validateSelectedFileForUpload } from './single-file-upload-validator';

export interface UseFileUploadProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  maxFileSizeInMB: number;
  acceptFileTypes: FileType[];
  name: TName;
  namespace: string;
  required?: boolean;
  validators?: ((file: FileMetadataDto) => Message | undefined)[];
}

export interface UseMultipleFileUploadProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseFileUploadProps<TFieldValues, TName> {
  totalMaxFileSizeInMB: number;
  maxFileAmount?: number;
}

export function useFileUpload<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  maxFileSizeInMB,
  acceptFileTypes,
  name,
  namespace,
  validators,
  required,
}: UseFileUploadProps<TFieldValues, TName>) {
  const { t: tAttachment } = useTranslation('attachment');
  const { field: fileMetadataField } = useController<TFieldValues, TName>({
    rules: {
      required,
      validate: (value: FileMetadataDto) =>
        singleFileUploadValidator(value, maxFileSizeInMB, acceptFileTypes, tAttachment, validators),
    },
    name: name,
  });

  const { awaitPending } = usePending();

  const [validationErrors, setValidationErrors] = React.useState<string[]>([]);
  const [isUploading, setIsUploading] = React.useState(false);

  const uploadFile = (fileList: FileList | null) => {
    if (fileList === null) {
      return;
    }
    const file = fileList[0];
    const fileErrors = validateSelectedFileForUpload(file, maxFileSizeInMB, acceptFileTypes, tAttachment);
    setValidationErrors(fileErrors);
    if (fileErrors.length === 0) {
      setIsUploading(true);
      awaitPending(storageApi.upload({ namespace, file }))
        .then((metadata) => {
          setIsUploading(false);
          fileMetadataField.onChange(metadata);
        })
        .catch(() => setIsUploading(false));
    }
  };

  return {
    uploadFile,
    isUploading,
    fileMetadata: fileMetadataField.value as FileMetadataDto,
    validationErrors,
    deleteFile: () => fileMetadataField.onChange(null),
  };
}

function isFileMetadataDto(value: unknown): value is FileMetadataDto {
  return value != null && typeof value === 'object' && instanceOfFileMetadataDto(value);
}

function allFiles(fileList: FileList) {
  const files: File[] = [];
  for (let i = 0; i < fileList.length; i++) {
    files.push(fileList[i]);
  }
  return files;
}

export function useMultipleFilesUpload<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  totalMaxFileSizeInMB,
  maxFileAmount,
  maxFileSizeInMB,
  acceptFileTypes,
  name,
  namespace,
  validators,
}: UseMultipleFileUploadProps<TFieldValues, TName>) {
  const { t } = useTranslation('attachment');
  const { setError, clearErrors } = useFormContext();

  const validate = React.useCallback(
    (values: unknown[]) => {
      if (values == null) {
        return true;
      }

      const files = values.map((v) => {
        invariant(isFileMetadataDto(v));
        return v;
      });

      if (maxFileAmount != null && files.length > maxFileAmount) {
        return t('validation_error.total_amount', { amount: maxFileAmount });
      }

      files.forEach((file, index) => {
        const error = singleFileUploadValidator(file, maxFileSizeInMB, acceptFileTypes, t, validators);
        if (typeof error === 'string') {
          setError(`${name}.${index}`, { message: error, type: 'validate' });
        }
      });

      if (sumBy(files, 'size') > totalMaxFileSizeInMB * MEGABYTES) {
        return t('validation_error.total_size', { size_mb: totalMaxFileSizeInMB });
      }

      return true;
    },
    [acceptFileTypes, maxFileAmount, maxFileSizeInMB, name, setError, t, totalMaxFileSizeInMB, validators],
  );
  const { append } = useFieldArray({ name, rules: { validate } });

  const { awaitPending } = usePending();

  const [isUploading, setIsUploading] = React.useState(false);

  const uploadFiles = React.useCallback(
    async (fileList: FileList | null) => {
      if (fileList === null) {
        return;
      }
      const fileErrors = flatMap(fileList, (file) =>
        validateSelectedFileForUpload(file, maxFileSizeInMB, acceptFileTypes, t),
      );

      if (fileErrors.length !== 0) {
        setError(name, { message: fileErrors.join(', ') });
        return;
      }
      try {
        clearErrors(name);
        setIsUploading(true);
        const uploads = allFiles(fileList).map((file) => storageApi.upload({ namespace, file }));
        const metadata = await awaitPending(Promise.all(uploads));

        // @ts-expect-error todo
        append(metadata);
      } finally {
        setIsUploading(false);
      }
    },
    [acceptFileTypes, append, awaitPending, clearErrors, maxFileSizeInMB, name, namespace, setError, t],
  );

  return { uploadFiles, isUploading };
}
