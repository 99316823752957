import React from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import AwardBreadcrumb from './award-breadcrumb';

const awardRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'awards',
    handle: {
      breadcrumb: <Translate ns="award" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./award-lister/award-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./award-editor/new-award-route'),
      },
      {
        path: ':awardId',
        handle: {
          breadcrumb: <AwardBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./award-page/award-page'),
            children: [
              {
                path: '',
                lazy: () => import('./award-viewer/award-viewer'),
              },
              {
                path: 'winnerRelations',
                lazy: () => import('./award-relation/award-winner-relation-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./award-history/award-history-route'),
              },
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./award-editor/edit-award-route'),
          },
        ],
      },
    ],
  },
];

export default awardRoutes;
