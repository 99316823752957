import { Input, InputProps } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { DEBOUNCE_TIME } from '../constants';
import useCallbackRef from '../use-callback-ref/use-callback-ref';
import useDebounce from './use-debounce';

interface DebouncedInputProps extends Omit<InputProps, 'value' | 'defaultValue' | 'onChange'> {
  defaultValue?: string;
  onDebouncedChange?(value: string): void;
}

export default function DebouncedInput({ defaultValue, onDebouncedChange, ...props }: DebouncedInputProps) {
  const [value, setValue] = useState(defaultValue ?? '');
  const debouncedValue = useDebounce(value, DEBOUNCE_TIME);

  onDebouncedChange = useCallbackRef(onDebouncedChange);

  useEffect(() => {
    onDebouncedChange?.(debouncedValue);
  }, [debouncedValue, onDebouncedChange]);

  return <Input {...props} value={value} onChange={(event) => setValue(event.target.value)} />;
}
