import { BoxProps } from '@chakra-ui/layout/dist/box';
import { Box, LightMode } from '@chakra-ui/react';
import React from 'react';

/**
 * Light and Dark mode wrappers do not affect text and background color. This serves as a workaround.
 * See:
 * https://github.com/chakra-ui/chakra-ui/issues/6916
 * The issue also suggests another fix (applying theme styling to text and background),
 * but that did not seem to fix the issue.
 */
export const LightModeBox = (props: BoxProps) => (
  <LightMode>
    <Box data-theme="light" bg="chakra-body-bg" color="chakra-body-text" {...props} />
  </LightMode>
);
