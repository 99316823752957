import { FormErrorMessage, Text } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { FieldPath, FieldValues, useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FileMetadataDto, PersonDto } from '../../../../api';
import { FileSuffixes, FileType } from '../../../../ui/attachments/upload/file-type';
import photoDataTableColumns from '../../../../ui/attachments/upload/photo-data-table-columns';
import { validatePhotoDimension } from '../../../../ui/attachments/upload/photo-validator';
import UploadFileButton from '../../../../ui/attachments/upload/upload-file-button';
import UploadTable from '../../../../ui/attachments/upload/upload-table';
import { useFileUpload } from '../../../../ui/attachments/upload/use-file-upload';
import ErrorMessage from '../../../../ui/form/error-message';
import FormControl from '../../../../ui/form/form-control';
import HelperPopover from '../../../../ui/helper-buttons/helper-popover';
import { LayoutType } from '../../../common/LayoutType';

/**
 * Properties for photo control.
 */
export interface PhotoControlProps<T extends FieldValues> {
  layout: LayoutType;
  showUploadButton?: boolean;
  path?: FieldPath<T>;
}

export default function PhotoControl<T extends FieldValues>({
  layout,
  showUploadButton = true,
  path,
}: PhotoControlProps<T>) {
  const prefixWithPath = <TPath extends string>(name: TPath) => (path != null ? (`${path}.${name}` as TPath) : name);
  const { t } = useTranslation('person');
  const { t: tAttachment } = useTranslation('attachment');
  const { control } = useFormContext<PersonDto>();
  const { field, fieldState } = useController({ control, name: prefixWithPath('photoMetadata') });

  const photoMetadata: FileMetadataDto | undefined = (field.value as FileMetadataDto) ?? undefined;

  return showUploadButton ? (
    <PhotoControlWithUpload layout={layout} />
  ) : (
    <FormControl
      isInvalid={fieldState.error != null}
      name={prefixWithPath('photo')}
      label={tAttachment('photo')}
      helperPopover={<HelperPopover children={t('photo.label_popover')} />}
    >
      <UploadTable
        layout={layout}
        fileData={photoMetadata ? [photoMetadata] : undefined}
        onDelete={() => field.onChange(null)}
        columns={photoDataTableColumns}
        maxElements={1}
      />
    </FormControl>
  );
}

/**
 * Control to upload person's photo.
 */
function PhotoControlWithUpload<T extends FieldValues>({ layout, path }: PhotoControlProps<T>) {
  const prefixWithPath = <TPath extends string>(name: TPath) => (path != null ? (`${path}.${name}` as TPath) : name);
  const { t } = useTranslation('person');
  const { t: tAttachment } = useTranslation('attachment');

  const { control } = useFormContext<PersonDto>();
  const { field, fieldState } = useController({ control, name: prefixWithPath('photoMetadata') });

  // Mail from Manja on the 23th of December 2022 around 3pm. Wish for webp to be available whereever jpeg is available.
  const acceptFileTypes = [FileType.JPEG, FileType.PNG, FileType.WEBP];
  const minWidth = 400;
  const maxWidth = 4000;
  const minHeight = 350;
  const maxHeight = 3500;
  const maxFileSizeInMB = 5;
  const photoMetadata: FileMetadataDto | undefined = (field.value as FileMetadataDto) ?? undefined;

  const { validationErrors, isUploading, uploadFile } = useFileUpload({
    maxFileSizeInMB,
    acceptFileTypes,
    name: prefixWithPath('photoMetadata'),
    namespace: 'person_photo',
    validators: [
      (file) =>
        validatePhotoDimension(
          file!,
          tAttachment,
          maxFileSizeInMB,
          acceptFileTypes,
          { width: minWidth, height: minHeight },
          { width: maxWidth, height: maxHeight },
        ),
    ],
  });
  const hasValidationErrors = fieldState.error != null || !isEmpty(validationErrors);
  return (
    <FormControl
      isInvalid={hasValidationErrors}
      name="photo"
      label={tAttachment('photo')}
      helperPopover={<HelperPopover children={t('photo.label_popover')} />}
    >
      <UploadTable
        layout={layout}
        fileData={photoMetadata ? [photoMetadata] : undefined}
        onDelete={() => field.onChange(null)}
        columns={photoDataTableColumns}
        helperText={
          hasValidationErrors
            ? undefined
            : tAttachment('helper_text.size_and_accepted_types', {
                size_mb: maxFileSizeInMB,
                acceptedFileTypes: acceptFileTypes.flatMap((type) => FileSuffixes[type]),
              }) +
              ' ' +
              tAttachment('helper_text.photo_dimension', {
                minWidth,
                minHeight,
                maxWidth,
                maxHeight,
              })
        }
        uploadButton={
          <UploadFileButton
            acceptFileTypes={acceptFileTypes}
            isUploading={isUploading}
            uploadFile={uploadFile}
            buttonText={tAttachment('upload', { fileType: tAttachment('photo') })}
            variant="ghost"
            w="100%"
          />
        }
        maxElements={1}
      />
      <ErrorMessage name="photoMetadata" as={FormErrorMessage} />
      {validationErrors.map((error, index) => (
        <Text key={index} color="text.error" fontSize="sm" mt={2}>
          {error}
        </Text>
      ))}
    </FormControl>
  );
}
