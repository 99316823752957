/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FieldModelDto } from './FieldModelDto';
import {
    FieldModelDtoFromJSON,
    FieldModelDtoFromJSONTyped,
    FieldModelDtoToJSON,
} from './FieldModelDto';

/**
 * 
 * @export
 * @interface CollectionModelDto
 */
export interface CollectionModelDto {
    /**
     * 
     * @type {string}
     * @memberof CollectionModelDto
     */
    collection: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionModelDto
     */
    translation: string;
    /**
     * 
     * @type {Array<FieldModelDto>}
     * @memberof CollectionModelDto
     */
    fields: Array<FieldModelDto>;
}

/**
 * Check if a given object implements the CollectionModelDto interface.
 */
export function instanceOfCollectionModelDto(value: object): boolean {
    if (!('collection' in value)) return false;
    if (!('translation' in value)) return false;
    if (!('fields' in value)) return false;
    return true;
}

export function CollectionModelDtoFromJSON(json: any): CollectionModelDto {
    return CollectionModelDtoFromJSONTyped(json, false);
}

export function CollectionModelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectionModelDto {
    if (json == null) {
        return json;
    }
    return {
        
        'collection': json['collection'],
        'translation': json['translation'],
        'fields': ((json['fields'] as Array<any>).map(FieldModelDtoFromJSON)),
    };
}

export function CollectionModelDtoToJSON(value?: CollectionModelDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'collection': value['collection'],
        'translation': value['translation'],
        'fields': ((value['fields'] as Array<any>).map(FieldModelDtoToJSON)),
    };
}

