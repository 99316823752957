/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LocationReferenceDto } from './LocationReferenceDto';
import {
    LocationReferenceDtoFromJSON,
    LocationReferenceDtoFromJSONTyped,
    LocationReferenceDtoToJSON,
} from './LocationReferenceDto';

/**
 * A reference of a location with zone and subzone
 * @export
 * @interface LocationZoneReferenceDto
 */
export interface LocationZoneReferenceDto {
    /**
     * 
     * @type {LocationReferenceDto}
     * @memberof LocationZoneReferenceDto
     */
    location: LocationReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof LocationZoneReferenceDto
     */
    zone?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationZoneReferenceDto
     */
    subZone?: string;
}

/**
 * Check if a given object implements the LocationZoneReferenceDto interface.
 */
export function instanceOfLocationZoneReferenceDto(value: object): boolean {
    if (!('location' in value)) return false;
    return true;
}

export function LocationZoneReferenceDtoFromJSON(json: any): LocationZoneReferenceDto {
    return LocationZoneReferenceDtoFromJSONTyped(json, false);
}

export function LocationZoneReferenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationZoneReferenceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'location': LocationReferenceDtoFromJSON(json['location']),
        'zone': json['zone'] == null ? undefined : json['zone'],
        'subZone': json['subZone'] == null ? undefined : json['subZone'],
    };
}

export function LocationZoneReferenceDtoToJSON(value?: LocationZoneReferenceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'location': LocationReferenceDtoToJSON(value['location']),
        'zone': value['zone'],
        'subZone': value['subZone'],
    };
}

