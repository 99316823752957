/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OwnerTypeDto = {
    OWNER: 'OWNER',
    CO_OWNER: 'CO_OWNER'
} as const;
export type OwnerTypeDto = typeof OwnerTypeDto[keyof typeof OwnerTypeDto];


export function OwnerTypeDtoFromJSON(json: any): OwnerTypeDto {
    return OwnerTypeDtoFromJSONTyped(json, false);
}

export function OwnerTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnerTypeDto {
    return json as OwnerTypeDto;
}

export function OwnerTypeDtoToJSON(value?: OwnerTypeDto | null): any {
    return value as any;
}

