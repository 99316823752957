/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ListOperatorTypeDto = {
    AT_LEAST_X_VALUES: 'HAS_AT_LEAST_X_VALUES',
    AT_LEAST_X_VALUES_THAT_MATCH: 'HAS_AT_LEAST_X_VALUES_THAT_MATCH',
    EXACTLY_X_VALUES: 'HAS_EXACTLY_X_VALUES',
    EXACTLY_X_VALUES_THAT_MATCH: 'HAS_EXACTLY_X_VALUES_THAT_MATCH',
    AT_MOST_X_VALUES: 'HAS_AT_MOST_X_VALUES',
    AT_MOST_X_VALUES_THAT_MATCH: 'HAS_AT_MOST_X_VALUES_THAT_MATCH'
} as const;
export type ListOperatorTypeDto = typeof ListOperatorTypeDto[keyof typeof ListOperatorTypeDto];


export function ListOperatorTypeDtoFromJSON(json: any): ListOperatorTypeDto {
    return ListOperatorTypeDtoFromJSONTyped(json, false);
}

export function ListOperatorTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListOperatorTypeDto {
    return json as ListOperatorTypeDto;
}

export function ListOperatorTypeDtoToJSON(value?: ListOperatorTypeDto | null): any {
    return value as any;
}

