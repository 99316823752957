import { useMemo } from 'react';
import invariant from 'tiny-invariant';
import usePlugins, { PluginToken } from '../../util/plugin/use-plugins';

export interface LandingPageTypeHook {
  (): LandingPageTypeConfig;
}

export default interface LandingPageTypeConfig {
  type: string;
  label: string;
}

export const LANDING_PAGE_TYPE_HOOK = new PluginToken<LandingPageTypeHook>('LandingPageTypeExtension');

export function useLandingPageTypes() {
  const typeHooks = usePlugins(LANDING_PAGE_TYPE_HOOK);
  // Hooks should never be called conditionally. So this is actually a no-go. But: the list of
  // plugins returned by usePlugins is static. Therefor the order in which the hooks are called is
  // always the same. Just not wrap it in useMemo and everything should work out fine.
  const typeExtensions = typeHooks.map((hook) => hook());

  return useMemo(
    () =>
      ({
        landingPageTypes: typeExtensions.map((extension) => extension.type),
        getLandingPageTypeConfig: (type: string) => {
          const extension = typeExtensions.find((extension) => type === extension.type);
          invariant(extension != null, `no type extension found for landing page type ${type}`);
          return extension;
        },
      }) as const,
    [typeExtensions],
  );
}
