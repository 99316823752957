import React from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchInvoice } from '../invoice-queries';

export default function InvoiceBreadcrumb() {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  invariant(invoiceId, 'Empty invoiceId');
  const invoice = useFetcher(fetchInvoice, { id: invoiceId });

  return <>{invoice.invoiceNumber}</>;
}
