import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { HelmetRouteObject } from '../../../ui/helmet/helmet-outlet';
import PublicEventEvaluationPage from './public-event-evaluation-page';
import usePublicEventEvaluation from './use-public-event-evaluation';

const publicEventEvaluationRoutes: HelmetRouteObject[] = [
  {
    path: 'evaluation',
    children: [
      {
        path: ':eventEvaluationId',
        element: <PublicEventEvaluationPage />,
        handle: {
          helmet: <PublicEventEvaluationHelmet />,
        },
      },
    ],
  },
];

export default publicEventEvaluationRoutes;

function PublicEventEvaluationHelmet() {
  const { t } = useTranslation('event_evaluation');
  const { eventEvaluationId } = useParams<{ eventEvaluationId: string }>();

  invariant(eventEvaluationId != null, 'Missing eventEvaluationId');
  const publicEventEvaluation = usePublicEventEvaluation(eventEvaluationId);

  return <Helmet title={t('viewer.helmet', { title: publicEventEvaluation.eventSettings.eventReference.title })} />;
}
