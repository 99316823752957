/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ClassLevelDto = {
    MINIMAL: 'MINIMAL',
    BASIC: 'BASIC',
    MEDIUM: 'MEDIUM',
    EXTENDED: 'EXTENDED',
    ADMIN: 'ADMIN'
} as const;
export type ClassLevelDto = typeof ClassLevelDto[keyof typeof ClassLevelDto];


export function ClassLevelDtoFromJSON(json: any): ClassLevelDto {
    return ClassLevelDtoFromJSONTyped(json, false);
}

export function ClassLevelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassLevelDto {
    return json as ClassLevelDto;
}

export function ClassLevelDtoToJSON(value?: ClassLevelDto | null): any {
    return value as any;
}

