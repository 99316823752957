/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyReferenceDto } from './CompanyReferenceDto';
import {
    CompanyReferenceDtoFromJSON,
    CompanyReferenceDtoFromJSONTyped,
    CompanyReferenceDtoToJSON,
} from './CompanyReferenceDto';
import type { CompanyRoleDto } from './CompanyRoleDto';
import {
    CompanyRoleDtoFromJSON,
    CompanyRoleDtoFromJSONTyped,
    CompanyRoleDtoToJSON,
} from './CompanyRoleDto';
import type { FilmReferenceDto } from './FilmReferenceDto';
import {
    FilmReferenceDtoFromJSON,
    FilmReferenceDtoFromJSONTyped,
    FilmReferenceDtoToJSON,
} from './FilmReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface CompanyFilmRelationListItemDto
 */
export interface CompanyFilmRelationListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof CompanyFilmRelationListItemDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof CompanyFilmRelationListItemDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {CompanyReferenceDto}
     * @memberof CompanyFilmRelationListItemDto
     */
    company: CompanyReferenceDto;
    /**
     * 
     * @type {FilmReferenceDto}
     * @memberof CompanyFilmRelationListItemDto
     */
    film: FilmReferenceDto;
    /**
     * 
     * @type {CompanyRoleDto}
     * @memberof CompanyFilmRelationListItemDto
     */
    role: CompanyRoleDto;
}

/**
 * Check if a given object implements the CompanyFilmRelationListItemDto interface.
 */
export function instanceOfCompanyFilmRelationListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('company' in value)) return false;
    if (!('film' in value)) return false;
    if (!('role' in value)) return false;
    return true;
}

export function CompanyFilmRelationListItemDtoFromJSON(json: any): CompanyFilmRelationListItemDto {
    return CompanyFilmRelationListItemDtoFromJSONTyped(json, false);
}

export function CompanyFilmRelationListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyFilmRelationListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'company': CompanyReferenceDtoFromJSON(json['company']),
        'film': FilmReferenceDtoFromJSON(json['film']),
        'role': CompanyRoleDtoFromJSON(json['role']),
    };
}

export function CompanyFilmRelationListItemDtoToJSON(value?: CompanyFilmRelationListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'company': CompanyReferenceDtoToJSON(value['company']),
        'film': FilmReferenceDtoToJSON(value['film']),
        'role': CompanyRoleDtoToJSON(value['role']),
    };
}

