/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MailingPermissionDto
 */
export interface MailingPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof MailingPermissionDto
     */
    name: MailingPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof MailingPermissionDto
     */
    action: MailingPermissionDtoActionEnum;
}


/**
 * @export
 */
export const MailingPermissionDtoNameEnum = {
    MAILING: 'MAILING'
} as const;
export type MailingPermissionDtoNameEnum = typeof MailingPermissionDtoNameEnum[keyof typeof MailingPermissionDtoNameEnum];

/**
 * @export
 */
export const MailingPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS_OWN: 'CAN_SEE_DETAILS_OWN',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    EDIT_OWN: 'CAN_EDIT_OWN',
    EDIT: 'CAN_EDIT',
    CREATE: 'CAN_CREATE',
    COPY_OWN: 'CAN_COPY_OWN',
    COPY: 'CAN_COPY',
    DELETE_OWN: 'CAN_DELETE_OWN',
    DELETE: 'CAN_DELETE',
    MANAGE_RECIPIENTS_OWN: 'CAN_MANAGE_RECIPIENTS_OWN',
    MANAGE_RECIPIENTS: 'CAN_MANAGE_RECIPIENTS',
    TEST_SEND_OWN: 'CAN_TEST_SEND_OWN',
    TEST_SEND: 'CAN_TEST_SEND',
    SEND_OWN: 'CAN_SEND_OWN',
    SEND: 'CAN_SEND'
} as const;
export type MailingPermissionDtoActionEnum = typeof MailingPermissionDtoActionEnum[keyof typeof MailingPermissionDtoActionEnum];


/**
 * Check if a given object implements the MailingPermissionDto interface.
 */
export function instanceOfMailingPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function MailingPermissionDtoFromJSON(json: any): MailingPermissionDto {
    return MailingPermissionDtoFromJSONTyped(json, false);
}

export function MailingPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function MailingPermissionDtoToJSON(value?: MailingPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

