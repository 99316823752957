import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Link } from '@chakra-ui/react';
import React from 'react';
import { EventLinkDto } from '../../../api';
import { DataTableColumn } from '../../../ui/data-table';
import Optional from '../../../ui/optional/optional';
import Translate from '../../../util/translate/translate';

const linkColumns: DataTableColumn<EventLinkDto>[] = [
  {
    key: 'link',
    name: <Translate ns="event" i18nKey="links.link" />,
    renderCell: (data) => (
      <Link href={data.url} isExternal>
        {data.url} <ExternalLinkIcon mx="2px" />
      </Link>
    ),
    cellProps: {
      wordBreak: 'break-all',
    },
  },
  {
    key: 'internalSoftware',
    name: <Translate ns="event" i18nKey="links.internal_software.label" />,
    renderCell: (data) => (
      <Optional>
        <Translate ns="event">{(t) => t(`links.internalSoftwareOptions.${data.internalSoftware!}`)}</Translate>
      </Optional>
    ),
  },
];
/**
 * Exports columns for the link table inside {@link EventForm}
 */
export default linkColumns;
