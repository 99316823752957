/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddExternalCompanyAdminsDto,
  BooleanDto,
  CompanyAdminPageDto,
  ExternalCompanyAdminCreationCheckDto,
  FailureDto,
  PageableDto,
} from '../models/index';
import {
    AddExternalCompanyAdminsDtoFromJSON,
    AddExternalCompanyAdminsDtoToJSON,
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    CompanyAdminPageDtoFromJSON,
    CompanyAdminPageDtoToJSON,
    ExternalCompanyAdminCreationCheckDtoFromJSON,
    ExternalCompanyAdminCreationCheckDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface CanCreateExternalCompanyAdminRelationRequest {
    companyId?: string;
    personId?: string;
}

export interface CreateExternalCompanyAdminRelationsRequest {
    addExternalCompanyAdminsDto: AddExternalCompanyAdminsDto;
}

export interface DeleteCompanyAdminRelationRequest {
    companyId?: string;
    personId?: string;
    occupationId?: string;
}

export interface IsCompanyAdminUniqueRequest {
    companyId?: string;
    personId?: string;
    occupationId?: string;
}

export interface SearchByCompanyRequest {
    id: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

/**
 * 
 */
export class CompanyAdminApi extends runtime.BaseAPI {

    /**
     */
    async canCreateExternalCompanyAdminRelationRaw(requestParameters: CanCreateExternalCompanyAdminRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExternalCompanyAdminCreationCheckDto>> {
        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['personId'] != null) {
            queryParameters['personId'] = requestParameters['personId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company-admins/status-check`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalCompanyAdminCreationCheckDtoFromJSON(jsonValue));
    }

    /**
     */
    async canCreateExternalCompanyAdminRelation(requestParameters: CanCreateExternalCompanyAdminRelationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExternalCompanyAdminCreationCheckDto> {
        const response = await this.canCreateExternalCompanyAdminRelationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create company admin relations.
     */
    async createExternalCompanyAdminRelationsRaw(requestParameters: CreateExternalCompanyAdminRelationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['addExternalCompanyAdminsDto'] == null) {
            throw new runtime.RequiredError(
                'addExternalCompanyAdminsDto',
                'Required parameter "addExternalCompanyAdminsDto" was null or undefined when calling createExternalCompanyAdminRelations().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company-admins/new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddExternalCompanyAdminsDtoToJSON(requestParameters['addExternalCompanyAdminsDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create company admin relations.
     */
    async createExternalCompanyAdminRelations(requestParameters: CreateExternalCompanyAdminRelationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createExternalCompanyAdminRelationsRaw(requestParameters, initOverrides);
    }

    /**
     * Delete relation by personId, occupationId and companyId
     */
    async deleteCompanyAdminRelationRaw(requestParameters: DeleteCompanyAdminRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['personId'] != null) {
            queryParameters['personId'] = requestParameters['personId'];
        }

        if (requestParameters['occupationId'] != null) {
            queryParameters['occupationId'] = requestParameters['occupationId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company-admins/delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete relation by personId, occupationId and companyId
     */
    async deleteCompanyAdminRelation(requestParameters: DeleteCompanyAdminRelationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCompanyAdminRelationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async isCompanyAdminUniqueRaw(requestParameters: IsCompanyAdminUniqueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['personId'] != null) {
            queryParameters['personId'] = requestParameters['personId'];
        }

        if (requestParameters['occupationId'] != null) {
            queryParameters['occupationId'] = requestParameters['occupationId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company-admins/is-unique`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async isCompanyAdminUnique(requestParameters: IsCompanyAdminUniqueRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.isCompanyAdminUniqueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for admins by persons.
     */
    async searchByCompanyRaw(requestParameters: SearchByCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyAdminPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling searchByCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company-admins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyAdminPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for admins by persons.
     */
    async searchByCompany(requestParameters: SearchByCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyAdminPageDto> {
        const response = await this.searchByCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
