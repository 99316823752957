/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RelationTypeDto = {
    MAIN_GROUP: 'MAIN_GROUP',
    SECONDARY_GROUP: 'SECONDARY_GROUP'
} as const;
export type RelationTypeDto = typeof RelationTypeDto[keyof typeof RelationTypeDto];


export function RelationTypeDtoFromJSON(json: any): RelationTypeDto {
    return RelationTypeDtoFromJSONTyped(json, false);
}

export function RelationTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelationTypeDto {
    return json as RelationTypeDto;
}

export function RelationTypeDtoToJSON(value?: RelationTypeDto | null): any {
    return value as any;
}

