import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EventDto, EventEvaluationDto } from '../../../api';

import HistoryDisplaySettings from '../../../ui/history/history-display-settings';

export default function useEventEvaluationHistorySettings(event: EventDto): HistoryDisplaySettings<EventEvaluationDto> {
  const { t } = useTranslation('event_evaluation');
  const { t: tCommon } = useTranslation('common');

  return useMemo<HistoryDisplaySettings<EventEvaluationDto>>(() => {
    return {
      attributeLabels: {
        realisation: t('realisation.label'),
        delayedDateTimeRange: t('realisation.delayed_date_time_range'),
        ticketsIssued: t('tickets_issued.label'),
        accessWithTicket: t('access.with_ticket'),
        accessWithoutTicket: t('access.without_ticket'),
        occupancy: t('occupancy.label'),
        technicalDisruption: t('technical_disruption.label'),
        audienceEntry: t('audience.entry.label'),
        audienceExit: t('audience.exit.label'),
        filmTeam: t('film_team.label'),
        furtherInformation: t('further_information.label'),
        status: t('status.label'),
      },
      valueFormatter: {
        realisation: (value) => (value == null ? undefined : t(`realisationLabels.${value}`)),
        delayedDateTimeRange: (value) =>
          value == null ? undefined : tCommon('format.datetime_range', { dateTimeRange: value }),
        ticketsIssued: (value) => (value == null ? undefined : value.toString()),
        accessWithTicket: (value) => (value == null ? undefined : value.toString()),
        accessWithoutTicket: (value) => (value == null ? undefined : value.toString()),
        occupancy: (value) => (value == null ? undefined : value.toString()),
        technicalDisruption: (value) =>
          value == null || value.length === 0
            ? undefined
            : value.map((v) => t(`technicalDisruptionOptions.${v}`)).join(', '),
        audienceEntry: (value) => (value == null ? undefined : t(`audienceEntryOptions.${value}`)),
        audienceExit: (value) => (value == null ? undefined : t(`audienceEntryOptions.${value}`)),
        filmTeam: (value) => (value == null ? undefined : t(`filmTeamOptions.${value}`)),
        status: (value) => (value == null ? undefined : t(`statusOptions.${value}`)),
      },
      diffEntireWord: {
        realisation: true,
        ticketsIssued: true,
        accessWithTicket: true,
        accessWithoutTicket: true,
        occupancy: true,
        audienceEntry: true,
        audienceExit: true,
        filmTeam: true,
        status: true,
      },
      name: () => t('history.title', { eventTitle: event.title }),
    };
  }, [t, tCommon, event.title]);
}
