import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { participationIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function ParticipationSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'PARTICIPATION', `${result.resultType} is no instance of ParticipationSearchResult`);

  const participation = result.result;
  const { t } = useTranslation(['participation', 'common']);

  return (
    <SearchResult
      icon={participationIcon}
      title={t('participation:participationFormat', {
        firstName: participation.person.firstName,
        surname: participation.person.surname,
        edition: participation.edition.name,
      })}
      link={`/persons/${participation.person.id}/participations/${participation.id}`}
      score={result.score}
      onClick={onClick}
      necessaryPermission="PARTICIPATION:CAN_SEE_DETAILS"
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('participation:edition'),
            element: participation.edition.name,
          },
          {
            label: t('participation:duration.label'),
            element: t('common:format.date_range', {
              dateRange: participation.duration,
            }),
          },
          {
            label: t('participation:securityLevel'),
            element: t(`participation:securityLevelLabel.${participation.securityLevel}`),
          },
          {
            label: t('participation:confirmationStatus'),
            element: t(`participation:confirmationStatusOptions.${participation.confirmationStatus}`),
          },
        ]}
      />
    </SearchResult>
  );
}
