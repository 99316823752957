/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PersonSectionDto } from './PersonSectionDto';
import {
    PersonSectionDtoFromJSON,
    PersonSectionDtoFromJSONTyped,
    PersonSectionDtoToJSON,
} from './PersonSectionDto';

/**
 * Information about a person who is a staff
 * @export
 * @interface PersonStaffDto
 */
export interface PersonStaffDto {
    /**
     * 
     * @type {Array<PersonSectionDto>}
     * @memberof PersonStaffDto
     */
    sections?: Array<PersonSectionDto>;
    /**
     * 
     * @type {string}
     * @memberof PersonStaffDto
     */
    room?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonStaffDto
     */
    email?: string;
    /**
     * List of phone numbers
     * @type {Array<string>}
     * @memberof PersonStaffDto
     */
    phoneNumbers?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PersonStaffDto
     */
    status: PersonStaffDtoStatusEnum;
}


/**
 * @export
 */
export const PersonStaffDtoStatusEnum = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
} as const;
export type PersonStaffDtoStatusEnum = typeof PersonStaffDtoStatusEnum[keyof typeof PersonStaffDtoStatusEnum];


/**
 * Check if a given object implements the PersonStaffDto interface.
 */
export function instanceOfPersonStaffDto(value: object): boolean {
    if (!('status' in value)) return false;
    return true;
}

export function PersonStaffDtoFromJSON(json: any): PersonStaffDto {
    return PersonStaffDtoFromJSONTyped(json, false);
}

export function PersonStaffDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonStaffDto {
    if (json == null) {
        return json;
    }
    return {
        
        'sections': json['sections'] == null ? undefined : ((json['sections'] as Array<any>).map(PersonSectionDtoFromJSON)),
        'room': json['room'] == null ? undefined : json['room'],
        'email': json['email'] == null ? undefined : json['email'],
        'phoneNumbers': json['phoneNumbers'] == null ? undefined : json['phoneNumbers'],
        'status': json['status'],
    };
}

export function PersonStaffDtoToJSON(value?: PersonStaffDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sections': value['sections'] == null ? undefined : ((value['sections'] as Array<any>).map(PersonSectionDtoToJSON)),
        'room': value['room'],
        'email': value['email'],
        'phoneNumbers': value['phoneNumbers'],
        'status': value['status'],
    };
}

