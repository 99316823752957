/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { FormatReferenceDto } from './FormatReferenceDto';
import {
    FormatReferenceDtoFromJSON,
    FormatReferenceDtoFromJSONTyped,
    FormatReferenceDtoToJSON,
} from './FormatReferenceDto';
import type { OccupationDto } from './OccupationDto';
import {
    OccupationDtoFromJSON,
    OccupationDtoFromJSONTyped,
    OccupationDtoToJSON,
} from './OccupationDto';
import type { OccupationFormatConfirmationStatusDto } from './OccupationFormatConfirmationStatusDto';
import {
    OccupationFormatConfirmationStatusDtoFromJSON,
    OccupationFormatConfirmationStatusDtoFromJSONTyped,
    OccupationFormatConfirmationStatusDtoToJSON,
} from './OccupationFormatConfirmationStatusDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * The connection between format and occupation
 * @export
 * @interface FormatOccupationRelationDto
 */
export interface FormatOccupationRelationDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof FormatOccupationRelationDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof FormatOccupationRelationDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {FormatReferenceDto}
     * @memberof FormatOccupationRelationDto
     */
    format: FormatReferenceDto;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof FormatOccupationRelationDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {OccupationDto}
     * @memberof FormatOccupationRelationDto
     */
    occupation: OccupationDto;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof FormatOccupationRelationDto
     */
    person?: PersonReferenceDto;
    /**
     * 
     * @type {OccupationFormatConfirmationStatusDto}
     * @memberof FormatOccupationRelationDto
     */
    confirmationStatus: OccupationFormatConfirmationStatusDto;
}

/**
 * Check if a given object implements the FormatOccupationRelationDto interface.
 */
export function instanceOfFormatOccupationRelationDto(value: object): boolean {
    if (!('format' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('occupation' in value)) return false;
    if (!('confirmationStatus' in value)) return false;
    return true;
}

export function FormatOccupationRelationDtoFromJSON(json: any): FormatOccupationRelationDto {
    return FormatOccupationRelationDtoFromJSONTyped(json, false);
}

export function FormatOccupationRelationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormatOccupationRelationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'format': FormatReferenceDtoFromJSON(json['format']),
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'occupation': OccupationDtoFromJSON(json['occupation']),
        'person': json['person'] == null ? undefined : PersonReferenceDtoFromJSON(json['person']),
        'confirmationStatus': OccupationFormatConfirmationStatusDtoFromJSON(json['confirmationStatus']),
    };
}

export function FormatOccupationRelationDtoToJSON(value?: FormatOccupationRelationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'format': FormatReferenceDtoToJSON(value['format']),
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'occupation': OccupationDtoToJSON(value['occupation']),
        'person': PersonReferenceDtoToJSON(value['person']),
        'confirmationStatus': OccupationFormatConfirmationStatusDtoToJSON(value['confirmationStatus']),
    };
}

