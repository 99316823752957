import registry from '../../registry';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import LandingPageBreadcrumb from './landing-page-breadcrumb';
import { LANDING_PAGE_VIEWER_TAB } from './landing-page-viewer-tab';

const landingPageRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'landing-pages',
    handle: {
      breadcrumb: <Translate ns="landing_page" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./landing-page-lister/landing-page-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./landing-page-editor/new-landing-page-route'),
      },
      {
        path: ':landingPageId',
        handle: {
          breadcrumb: <LandingPageBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./landing-page-page/landing-page-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./landing-page-viewer/landing-page-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./landing-page-history/landing-page-history-route'),
              },
              ...registry.getAll(LANDING_PAGE_VIEWER_TAB).map((value) => value.route),
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./landing-page-editor/edit-landing-page-route'),
          },
          {
            path: 'copy',
            lazy: () => import('./landing-page-editor/copy-landing-page-route'),
          },
        ],
      },
    ],
  },
];

export default landingPageRoutes;
