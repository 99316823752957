import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useParticipationPermissionClassGroupExtension(): PermissionClassGroupExtension<'PARTICIPATION'> {
  const { t } = useTranslation(['participation']);

  return {
    name: 'PARTICIPATION',
    label: t('participation:participation'),
    getActionLabel: (action) => t(`participation:permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`participation:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`participation:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
