/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListPermissionDto
 */
export interface ListPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof ListPermissionDto
     */
    name: ListPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof ListPermissionDto
     */
    action: ListPermissionDtoActionEnum;
}


/**
 * @export
 */
export const ListPermissionDtoNameEnum = {
    LIST: 'LIST'
} as const;
export type ListPermissionDtoNameEnum = typeof ListPermissionDtoNameEnum[keyof typeof ListPermissionDtoNameEnum];

/**
 * @export
 */
export const ListPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    SEE_DETAILS_OWN: 'CAN_SEE_DETAILS_OWN',
    REFRESH: 'CAN_REFRESH',
    REFRESH_OWN: 'CAN_REFRESH_OWN',
    EDIT: 'CAN_EDIT',
    EDIT_OWN: 'CAN_EDIT_OWN',
    CREATE_FROM_REPORTING: 'CAN_CREATE_FROM_REPORTING'
} as const;
export type ListPermissionDtoActionEnum = typeof ListPermissionDtoActionEnum[keyof typeof ListPermissionDtoActionEnum];


/**
 * Check if a given object implements the ListPermissionDto interface.
 */
export function instanceOfListPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function ListPermissionDtoFromJSON(json: any): ListPermissionDto {
    return ListPermissionDtoFromJSONTyped(json, false);
}

export function ListPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function ListPermissionDtoToJSON(value?: ListPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

