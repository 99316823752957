import { Highlight } from '@chakra-ui/react';
import React from 'react';

export default function ResultHighlight({ query, children }: { query: string | string[]; children: any }) {
  return (
    <Highlight query={query} styles={{ bg: 'background.hero', color: 'inherit' }}>
      {children}
    </Highlight>
  );
}
