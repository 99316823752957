import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import VisaTemplateBreadcrumb from './common/visa-template-breadcrumb';

const visaTemplateRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'visa-templates',
    handle: {
      breadcrumb: <Translate ns="visa_template" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./visa-template-lister/visa-template-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./visa-template-editor/new-visa-template-route'),
      },
      {
        path: ':visaTemplateId',
        handle: {
          breadcrumb: <VisaTemplateBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./visa-template-page/visa-template-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./visa-template-viewer/visa-template-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./visa-template-history/visa-template-history-route'),
              },
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./visa-template-editor/edit-visa-template-route'),
          },
        ],
      },
    ],
  },
];

export default visaTemplateRoutes;
