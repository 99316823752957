/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ConfirmationStatusDto } from './ConfirmationStatusDto';
import {
    ConfirmationStatusDtoFromJSON,
    ConfirmationStatusDtoFromJSONTyped,
    ConfirmationStatusDtoToJSON,
} from './ConfirmationStatusDto';
import type { CountryReferenceDto } from './CountryReferenceDto';
import {
    CountryReferenceDtoFromJSON,
    CountryReferenceDtoFromJSONTyped,
    CountryReferenceDtoToJSON,
} from './CountryReferenceDto';
import type { FormatStatusDto } from './FormatStatusDto';
import {
    FormatStatusDtoFromJSON,
    FormatStatusDtoFromJSONTyped,
    FormatStatusDtoToJSON,
} from './FormatStatusDto';
import type { MediaTypeDto } from './MediaTypeDto';
import {
    MediaTypeDtoFromJSON,
    MediaTypeDtoFromJSONTyped,
    MediaTypeDtoToJSON,
} from './MediaTypeDto';
import type { PeriodicityTypeDto } from './PeriodicityTypeDto';
import {
    PeriodicityTypeDtoFromJSON,
    PeriodicityTypeDtoFromJSONTyped,
    PeriodicityTypeDtoToJSON,
} from './PeriodicityTypeDto';

/**
 * 
 * @export
 * @interface FormatListItemDto
 */
export interface FormatListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof FormatListItemDto
     */
    id: string;
    /**
     * 
     * @type {MediaTypeDto}
     * @memberof FormatListItemDto
     */
    mediaType: MediaTypeDto;
    /**
     * 
     * @type {string}
     * @memberof FormatListItemDto
     */
    name: string;
    /**
     * 
     * @type {PeriodicityTypeDto}
     * @memberof FormatListItemDto
     */
    periodicityType?: PeriodicityTypeDto;
    /**
     * 
     * @type {number}
     * @memberof FormatListItemDto
     */
    reachAmount?: number;
    /**
     * 
     * @type {Array<CountryReferenceDto>}
     * @memberof FormatListItemDto
     */
    publicationCountries: Array<CountryReferenceDto>;
    /**
     * 
     * @type {FormatStatusDto}
     * @memberof FormatListItemDto
     */
    status: FormatStatusDto;
    /**
     * 
     * @type {ConfirmationStatusDto}
     * @memberof FormatListItemDto
     */
    confirmationStatus: ConfirmationStatusDto;
}

/**
 * Check if a given object implements the FormatListItemDto interface.
 */
export function instanceOfFormatListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('mediaType' in value)) return false;
    if (!('name' in value)) return false;
    if (!('publicationCountries' in value)) return false;
    if (!('status' in value)) return false;
    if (!('confirmationStatus' in value)) return false;
    return true;
}

export function FormatListItemDtoFromJSON(json: any): FormatListItemDto {
    return FormatListItemDtoFromJSONTyped(json, false);
}

export function FormatListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormatListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'mediaType': MediaTypeDtoFromJSON(json['mediaType']),
        'name': json['name'],
        'periodicityType': json['periodicityType'] == null ? undefined : PeriodicityTypeDtoFromJSON(json['periodicityType']),
        'reachAmount': json['reachAmount'] == null ? undefined : json['reachAmount'],
        'publicationCountries': ((json['publicationCountries'] as Array<any>).map(CountryReferenceDtoFromJSON)),
        'status': FormatStatusDtoFromJSON(json['status']),
        'confirmationStatus': ConfirmationStatusDtoFromJSON(json['confirmationStatus']),
    };
}

export function FormatListItemDtoToJSON(value?: FormatListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'mediaType': MediaTypeDtoToJSON(value['mediaType']),
        'name': value['name'],
        'periodicityType': PeriodicityTypeDtoToJSON(value['periodicityType']),
        'reachAmount': value['reachAmount'],
        'publicationCountries': ((value['publicationCountries'] as Array<any>).map(CountryReferenceDtoToJSON)),
        'status': FormatStatusDtoToJSON(value['status']),
        'confirmationStatus': ConfirmationStatusDtoToJSON(value['confirmationStatus']),
    };
}

