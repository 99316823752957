/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductBundleDto } from './ProductBundleDto';
import {
    ProductBundleDtoFromJSON,
    ProductBundleDtoFromJSONTyped,
    ProductBundleDtoToJSON,
} from './ProductBundleDto';

/**
 * 
 * @export
 * @interface ProductBundleSearchResultDto
 */
export interface ProductBundleSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof ProductBundleSearchResultDto
     */
    resultType: ProductBundleSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {ProductBundleDto}
     * @memberof ProductBundleSearchResultDto
     */
    result: ProductBundleDto;
}


/**
 * @export
 */
export const ProductBundleSearchResultDtoResultTypeEnum = {
    PRODUCT_BUNDLE: 'PRODUCT_BUNDLE'
} as const;
export type ProductBundleSearchResultDtoResultTypeEnum = typeof ProductBundleSearchResultDtoResultTypeEnum[keyof typeof ProductBundleSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the ProductBundleSearchResultDto interface.
 */
export function instanceOfProductBundleSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function ProductBundleSearchResultDtoFromJSON(json: any): ProductBundleSearchResultDto {
    return ProductBundleSearchResultDtoFromJSONTyped(json, false);
}

export function ProductBundleSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductBundleSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': ProductBundleDtoFromJSON(json['result']),
    };
}

export function ProductBundleSearchResultDtoToJSON(value?: ProductBundleSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': ProductBundleDtoToJSON(value['result']),
    };
}

