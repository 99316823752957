/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyReferenceDto } from './CompanyReferenceDto';
import {
    CompanyReferenceDtoFromJSON,
    CompanyReferenceDtoFromJSONTyped,
    CompanyReferenceDtoToJSON,
} from './CompanyReferenceDto';

/**
 * 
 * @export
 * @interface EventPartnerDto
 */
export interface EventPartnerDto {
    /**
     * 
     * @type {string}
     * @memberof EventPartnerDto
     */
    partnerType?: EventPartnerDtoPartnerTypeEnum;
    /**
     * 
     * @type {Array<CompanyReferenceDto>}
     * @memberof EventPartnerDto
     */
    companies?: Array<CompanyReferenceDto>;
}


/**
 * @export
 */
export const EventPartnerDtoPartnerTypeEnum = {
    PARTNER_EVENT: 'PARTNER_EVENT',
    THIRD_PARTY_EVENT: 'THIRD_PARTY_EVENT'
} as const;
export type EventPartnerDtoPartnerTypeEnum = typeof EventPartnerDtoPartnerTypeEnum[keyof typeof EventPartnerDtoPartnerTypeEnum];


/**
 * Check if a given object implements the EventPartnerDto interface.
 */
export function instanceOfEventPartnerDto(value: object): boolean {
    return true;
}

export function EventPartnerDtoFromJSON(json: any): EventPartnerDto {
    return EventPartnerDtoFromJSONTyped(json, false);
}

export function EventPartnerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventPartnerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'partnerType': json['partnerType'] == null ? undefined : json['partnerType'],
        'companies': json['companies'] == null ? undefined : ((json['companies'] as Array<any>).map(CompanyReferenceDtoFromJSON)),
    };
}

export function EventPartnerDtoToJSON(value?: EventPartnerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'partnerType': value['partnerType'],
        'companies': value['companies'] == null ? undefined : ((value['companies'] as Array<any>).map(CompanyReferenceDtoToJSON)),
    };
}

