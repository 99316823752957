/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AllFormatDuplicatesPageDto,
  FailureDto,
  FormatDto,
  FormatPageDto,
  FormatReferenceDto,
  FormatStatusCheckDto,
  HistoryPageDto,
  MediaTypeDto,
  MergeFormatIdsDto,
  MergedFormatDto,
  MergedHistoryObjectDto,
  PageableDto,
} from '../models/index';
import {
    AllFormatDuplicatesPageDtoFromJSON,
    AllFormatDuplicatesPageDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    FormatDtoFromJSON,
    FormatDtoToJSON,
    FormatPageDtoFromJSON,
    FormatPageDtoToJSON,
    FormatReferenceDtoFromJSON,
    FormatReferenceDtoToJSON,
    FormatStatusCheckDtoFromJSON,
    FormatStatusCheckDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    MediaTypeDtoFromJSON,
    MediaTypeDtoToJSON,
    MergeFormatIdsDtoFromJSON,
    MergeFormatIdsDtoToJSON,
    MergedFormatDtoFromJSON,
    MergedFormatDtoToJSON,
    MergedHistoryObjectDtoFromJSON,
    MergedHistoryObjectDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface CheckFormatStatusRequest {
    formatId: string;
}

export interface CreateFormatRequest {
    formatDto: FormatDto;
}

export interface DeleteFormatRequest {
    id: string;
}

export interface FetchFormatRequest {
    id: string;
}

export interface FetchFormatHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchMergedHistoryFormatRequest {
    id: string;
}

export interface GetAllPossibleFormatDuplicatesRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface GetExistingPossibleFormatDuplicatesRequest {
    id: string;
}

export interface GetPossibleFormatDuplicatesRequest {
    name: string;
    mediaType: MediaTypeDto;
    synonyms?: Array<string>;
}

export interface MergeFormatRequest {
    mergedFormatDto: MergedFormatDto;
}

export interface NoFormatDuplicateRequest {
    mergeFormatIdsDto: MergeFormatIdsDto;
}

export interface SearchFormatsRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface UpdateFormatRequest {
    id: string;
    formatDto: FormatDto;
}

/**
 * 
 */
export class FormatApi extends runtime.BaseAPI {

    /**
     * Get the current status of the format.
     */
    async checkFormatStatusRaw(requestParameters: CheckFormatStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormatStatusCheckDto>> {
        if (requestParameters['formatId'] == null) {
            throw new runtime.RequiredError(
                'formatId',
                'Required parameter "formatId" was null or undefined when calling checkFormatStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/formats/{formatId}/check-status`.replace(`{${"formatId"}}`, encodeURIComponent(String(requestParameters['formatId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormatStatusCheckDtoFromJSON(jsonValue));
    }

    /**
     * Get the current status of the format.
     */
    async checkFormatStatus(requestParameters: CheckFormatStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormatStatusCheckDto> {
        const response = await this.checkFormatStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new format.
     */
    async createFormatRaw(requestParameters: CreateFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['formatDto'] == null) {
            throw new runtime.RequiredError(
                'formatDto',
                'Required parameter "formatDto" was null or undefined when calling createFormat().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/formats`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormatDtoToJSON(requestParameters['formatDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new format.
     */
    async createFormat(requestParameters: CreateFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createFormatRaw(requestParameters, initOverrides);
    }

    /**
     * Delete the format.
     */
    async deleteFormatRaw(requestParameters: DeleteFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteFormat().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/formats/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the format.
     */
    async deleteFormat(requestParameters: DeleteFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteFormatRaw(requestParameters, initOverrides);
    }

    /**
     * Get the format business object.
     */
    async fetchFormatRaw(requestParameters: FetchFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormatDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchFormat().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/formats/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormatDtoFromJSON(jsonValue));
    }

    /**
     * Get the format business object.
     */
    async fetchFormat(requestParameters: FetchFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormatDto> {
        const response = await this.fetchFormatRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of a format.
     */
    async fetchFormatHistoryRaw(requestParameters: FetchFormatHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchFormatHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/formats/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of a format.
     */
    async fetchFormatHistory(requestParameters: FetchFormatHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchFormatHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a format reference
     */
    async fetchMergedHistoryFormatRaw(requestParameters: FetchMergedHistoryFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MergedHistoryObjectDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchMergedHistoryFormat().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/formats/{id}/merge-history-format`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MergedHistoryObjectDtoFromJSON(jsonValue));
    }

    /**
     * Get a format reference
     */
    async fetchMergedHistoryFormat(requestParameters: FetchMergedHistoryFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MergedHistoryObjectDto> {
        const response = await this.fetchMergedHistoryFormatRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all possible format duplicates sorted and paginated.
     */
    async getAllPossibleFormatDuplicatesRaw(requestParameters: GetAllPossibleFormatDuplicatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AllFormatDuplicatesPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/formats/all-duplicates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AllFormatDuplicatesPageDtoFromJSON(jsonValue));
    }

    /**
     * Get all possible format duplicates sorted and paginated.
     */
    async getAllPossibleFormatDuplicates(requestParameters: GetAllPossibleFormatDuplicatesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AllFormatDuplicatesPageDto> {
        const response = await this.getAllPossibleFormatDuplicatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all possible duplicates of the format
     */
    async getExistingPossibleFormatDuplicatesRaw(requestParameters: GetExistingPossibleFormatDuplicatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FormatReferenceDto>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getExistingPossibleFormatDuplicates().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/formats/{id}/existing-possible-duplicates`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FormatReferenceDtoFromJSON));
    }

    /**
     * Get all possible duplicates of the format
     */
    async getExistingPossibleFormatDuplicates(requestParameters: GetExistingPossibleFormatDuplicatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FormatReferenceDto>> {
        const response = await this.getExistingPossibleFormatDuplicatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all possible duplicates of the format using name and media type
     */
    async getPossibleFormatDuplicatesRaw(requestParameters: GetPossibleFormatDuplicatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FormatReferenceDto>>> {
        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling getPossibleFormatDuplicates().'
            );
        }

        if (requestParameters['mediaType'] == null) {
            throw new runtime.RequiredError(
                'mediaType',
                'Required parameter "mediaType" was null or undefined when calling getPossibleFormatDuplicates().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['synonyms'] != null) {
            queryParameters['synonyms'] = requestParameters['synonyms'];
        }

        if (requestParameters['mediaType'] != null) {
            queryParameters['mediaType'] = requestParameters['mediaType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/formats/possible-duplicates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FormatReferenceDtoFromJSON));
    }

    /**
     * Get all possible duplicates of the format using name and media type
     */
    async getPossibleFormatDuplicates(requestParameters: GetPossibleFormatDuplicatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FormatReferenceDto>> {
        const response = await this.getPossibleFormatDuplicatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Merge two formats and create a new one.
     */
    async mergeFormatRaw(requestParameters: MergeFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormatDto>> {
        if (requestParameters['mergedFormatDto'] == null) {
            throw new runtime.RequiredError(
                'mergedFormatDto',
                'Required parameter "mergedFormatDto" was null or undefined when calling mergeFormat().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/formats/merge`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MergedFormatDtoToJSON(requestParameters['mergedFormatDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormatDtoFromJSON(jsonValue));
    }

    /**
     * Merge two formats and create a new one.
     */
    async mergeFormat(requestParameters: MergeFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormatDto> {
        const response = await this.mergeFormatRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mark two formats as no duplicates
     */
    async noFormatDuplicateRaw(requestParameters: NoFormatDuplicateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['mergeFormatIdsDto'] == null) {
            throw new runtime.RequiredError(
                'mergeFormatIdsDto',
                'Required parameter "mergeFormatIdsDto" was null or undefined when calling noFormatDuplicate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/formats/no-duplicate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MergeFormatIdsDtoToJSON(requestParameters['mergeFormatIdsDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark two formats as no duplicates
     */
    async noFormatDuplicate(requestParameters: NoFormatDuplicateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.noFormatDuplicateRaw(requestParameters, initOverrides);
    }

    /**
     * Search for formats.
     */
    async searchFormatsRaw(requestParameters: SearchFormatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormatPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/formats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormatPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for formats.
     */
    async searchFormats(requestParameters: SearchFormatsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormatPageDto> {
        const response = await this.searchFormatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new format.
     */
    async templateFormatRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormatDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/formats/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormatDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new format.
     */
    async templateFormat(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormatDto> {
        const response = await this.templateFormatRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the existing format.
     */
    async updateFormatRaw(requestParameters: UpdateFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateFormat().'
            );
        }

        if (requestParameters['formatDto'] == null) {
            throw new runtime.RequiredError(
                'formatDto',
                'Required parameter "formatDto" was null or undefined when calling updateFormat().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/formats/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FormatDtoToJSON(requestParameters['formatDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the existing format.
     */
    async updateFormat(requestParameters: UpdateFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateFormatRaw(requestParameters, initOverrides);
    }

}
