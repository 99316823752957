import { InitOverrideFunction } from '../../api';
import permissionApi from '../../data-access/permission-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';

export const permissionFetcher = createFetcherScope(permissionApi);

export const fetchPermissions = permissionFetcher.createFetcher(
  (initOverrides?: RequestInit | InitOverrideFunction | undefined) =>
    permissionApi
      .fetchSelf(initOverrides)
      .then((x) => x.map((permission) => `${permission.name}:${permission.action}`)),
);

export const fetchPermissionsBySection = permissionFetcher.createFetcher(
  (initOverrides?: RequestInit | InitOverrideFunction) =>
    permissionApi
      .fetchSelfBySection(initOverrides)
      .then((bySection) =>
        Object.fromEntries(
          bySection.map((bySection) => [
            bySection.sectionId,
            bySection.permission?.map((permission) => `${permission.name}:${permission.action}`),
          ]),
        ),
      ),
);
