import React, { memo, Suspense } from 'react';
import { LoaderFunction, RouteObject, useLocation, useMatches } from 'react-router-dom';

export type HelmetHandle<TLoaderFn extends LoaderFunction = any> = {
  helmet?: React.ReactElement | ((data: Awaited<ReturnType<TLoaderFn>>) => React.ReactElement);
};

export type HelmetRouteObject = RouteObject & {
  handle?: HelmetHandle;
  children?: HelmetRouteObject[];
};

function isHelmetHandle(handle: unknown): handle is HelmetHandle {
  return typeof handle === 'object' && handle != null && 'helmet' in handle;
}

const HelmetOutlet = memo(() => {
  const matches = useMatches();
  const location = useLocation();

  return (
    <Suspense
      // Rerender whenever router location was changed to fix issues with Helmet not updating
      key={location.key}
    >
      {matches
        .filter(
          (match): match is typeof match & { handle: HelmetHandle } =>
            isHelmetHandle(match.handle) && match.handle.helmet != null,
        )
        .map((match) => {
          const { handle, data, id } = match;

          return (
            <React.Fragment key={id}>
              {typeof handle.helmet === 'function' ? handle.helmet(data) : handle.helmet}
            </React.Fragment>
          );
        })}
    </Suspense>
  );
});

export default HelmetOutlet;
