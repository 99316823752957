/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AccountingCreationTypeDto = {
    MANUAL: 'MANUAL',
    SYSTEM: 'SYSTEM'
} as const;
export type AccountingCreationTypeDto = typeof AccountingCreationTypeDto[keyof typeof AccountingCreationTypeDto];


export function AccountingCreationTypeDtoFromJSON(json: any): AccountingCreationTypeDto {
    return AccountingCreationTypeDtoFromJSONTyped(json, false);
}

export function AccountingCreationTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountingCreationTypeDto {
    return json as AccountingCreationTypeDto;
}

export function AccountingCreationTypeDtoToJSON(value?: AccountingCreationTypeDto | null): any {
    return value as any;
}

