/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditionDto } from './EditionDto';
import {
    EditionDtoFromJSON,
    EditionDtoFromJSONTyped,
    EditionDtoToJSON,
} from './EditionDto';

/**
 * 
 * @export
 * @interface EditionSearchResultDto
 */
export interface EditionSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof EditionSearchResultDto
     */
    resultType: EditionSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof EditionSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {EditionDto}
     * @memberof EditionSearchResultDto
     */
    result: EditionDto;
}


/**
 * @export
 */
export const EditionSearchResultDtoResultTypeEnum = {
    EDITION: 'EDITION'
} as const;
export type EditionSearchResultDtoResultTypeEnum = typeof EditionSearchResultDtoResultTypeEnum[keyof typeof EditionSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the EditionSearchResultDto interface.
 */
export function instanceOfEditionSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function EditionSearchResultDtoFromJSON(json: any): EditionSearchResultDto {
    return EditionSearchResultDtoFromJSONTyped(json, false);
}

export function EditionSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditionSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': EditionDtoFromJSON(json['result']),
    };
}

export function EditionSearchResultDtoToJSON(value?: EditionSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': EditionDtoToJSON(value['result']),
    };
}

