import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { MailingTemplateDtoFromJSON } from '../../api';
import { mailingTemplateIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function MailingTemplateSearchResult({ result, onClick }: SearchResultProps) {
  invariant(
    result.resultType === 'MAILING_TEMPLATE',
    `${result.resultType} is no instance of MailingTemplateSearchResult`,
  );

  const mailingTemplate = MailingTemplateDtoFromJSON(result.result);
  const { t } = useTranslation('mailing_template');

  return (
    <SearchResult
      icon={mailingTemplateIcon}
      title={mailingTemplate.name}
      link={`/mailing-templates/${mailingTemplate.id}`}
      score={result.score}
      onClick={onClick}
      necessaryPermission="MAILING_TEMPLATE:CAN_SEE_DETAILS"
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('status.label'),
            element: t(`statusOptions.${mailingTemplate.status!}`),
          },
          {
            label: t('type.label'),
            element: t(`mailingTypeOptions.${mailingTemplate.type}`),
          },
          {
            label: t('language_version.label'),
            element: t(`languageVersionOptions.${mailingTemplate.languageVersion}`),
          },
          {
            label: t('sender_type.label'),
            element: mailingTemplate.senderTypes.map((st) => t(`senderTypeOptions.${st}`)).join(', '),
          },
          {
            label: t('header_editing.header'),
            element: mailingTemplate.title,
          },
          {
            label: t('header_editing.header_english'),
            element: mailingTemplate.titleEnglish,
          },
          {
            label: t('subject_editing.subject'),
            element: mailingTemplate.subject,
          },
          {
            label: t('subject_editing.subject_english'),
            element: mailingTemplate.subjectEnglish,
          },
          {
            label: t('subject_editing.prefix'),
            element: mailingTemplate.subjectPrefix,
          },
          {
            label: t('subject_editing.prefix_english'),
            element: mailingTemplate.subjectPrefixEnglish,
          },
          {
            label: t('mailing_design'),
            element: mailingTemplate.mailingDesignBrand,
          },
        ]}
      />
    </SearchResult>
  );
}
