/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrderStatusDto = {
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    FORWARDED: 'FORWARDED',
    BLOCKED: 'BLOCKED',
    REJECTED: 'REJECTED',
    WITHDRAWN: 'WITHDRAWN',
    ACCEPTED: 'ACCEPTED'
} as const;
export type OrderStatusDto = typeof OrderStatusDto[keyof typeof OrderStatusDto];


export function OrderStatusDtoFromJSON(json: any): OrderStatusDto {
    return OrderStatusDtoFromJSONTyped(json, false);
}

export function OrderStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderStatusDto {
    return json as OrderStatusDto;
}

export function OrderStatusDtoToJSON(value?: OrderStatusDto | null): any {
    return value as any;
}

