import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import useFetcher from '../../util/swr/use-fetcher';
import CompanyPage from '../company/company-page/company-page';
import { fetchCompany } from '../company/company-queries';
import { CompanyAdminPageContent } from './company-admin-page-content';

const companyAdminRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: '',
    element: <CompanyPage />,
    children: [
      {
        path: 'admins',
        element: <CompanyAdminPageContent />,
        handle: {
          helmet: <CompanyAdminHelmet />,
        },
      },
    ],
  },
];

export default companyAdminRoutes;

function CompanyAdminHelmet() {
  const { t } = useTranslation('company');
  const params = useParams<{ companyId: string }>();
  invariant(params.companyId, 'Empty companyId');
  const company = useFetcher(fetchCompany, { id: params.companyId });

  return <Helmet title={t('externalAdmin.helmet', { name: company.name })} />;
}
