/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The status of the allocation
 * @export
 */
export const AllocationStatusDto = {
    SHARED: 'SHARED',
    CLOSED: 'CLOSED'
} as const;
export type AllocationStatusDto = typeof AllocationStatusDto[keyof typeof AllocationStatusDto];


export function AllocationStatusDtoFromJSON(json: any): AllocationStatusDto {
    return AllocationStatusDtoFromJSONTyped(json, false);
}

export function AllocationStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllocationStatusDto {
    return json as AllocationStatusDto;
}

export function AllocationStatusDtoToJSON(value?: AllocationStatusDto | null): any {
    return value as any;
}

