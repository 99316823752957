/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PersonMarkerDto } from './PersonMarkerDto';
import {
    PersonMarkerDtoFromJSON,
    PersonMarkerDtoFromJSONTyped,
    PersonMarkerDtoToJSON,
} from './PersonMarkerDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';

/**
 * The Person has one or more markers which prevent it from being anonymised.
 * @export
 * @interface PersonHasMarkerBlockerDto
 */
export interface PersonHasMarkerBlockerDto {
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof PersonHasMarkerBlockerDto
     */
    person: PersonReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof PersonHasMarkerBlockerDto
     */
    reason: PersonHasMarkerBlockerDtoReasonEnum;
    /**
     * 
     * @type {Array<PersonMarkerDto>}
     * @memberof PersonHasMarkerBlockerDto
     */
    readonly markers: Array<PersonMarkerDto>;
}


/**
 * @export
 */
export const PersonHasMarkerBlockerDtoReasonEnum = {
    PERSON_HAS_MARKER: 'PERSON_HAS_MARKER'
} as const;
export type PersonHasMarkerBlockerDtoReasonEnum = typeof PersonHasMarkerBlockerDtoReasonEnum[keyof typeof PersonHasMarkerBlockerDtoReasonEnum];


/**
 * Check if a given object implements the PersonHasMarkerBlockerDto interface.
 */
export function instanceOfPersonHasMarkerBlockerDto(value: object): boolean {
    if (!('person' in value)) return false;
    if (!('reason' in value)) return false;
    if (!('markers' in value)) return false;
    return true;
}

export function PersonHasMarkerBlockerDtoFromJSON(json: any): PersonHasMarkerBlockerDto {
    return PersonHasMarkerBlockerDtoFromJSONTyped(json, false);
}

export function PersonHasMarkerBlockerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonHasMarkerBlockerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'person': PersonReferenceDtoFromJSON(json['person']),
        'reason': json['reason'],
        'markers': ((json['markers'] as Array<any>).map(PersonMarkerDtoFromJSON)),
    };
}

export function PersonHasMarkerBlockerDtoToJSON(value?: PersonHasMarkerBlockerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'person': PersonReferenceDtoToJSON(value['person']),
        'reason': value['reason'],
    };
}

