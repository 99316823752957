import React from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';

const awardRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'countries',
    handle: {
      breadcrumb: <Translate ns="country" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./country-lister/country-lister-route'),
      },
    ],
  },
];

export default awardRoutes;
