import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { BreadcrumbRouteObject } from '../../../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../../../ui/helmet/helmet-outlet';
import useFetcher from '../../../../util/swr/use-fetcher';
import { FormatPageRoute } from '../../../format/format-page/format-page-route';
import { fetchFormat } from '../../../format/format-queries';
import FormatOccupationPageContent from './format-occupation-page-content';

const formatOccupationRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: '',
    element: <FormatPageRoute />,
    children: [
      {
        path: 'persons',
        element: <FormatOccupationPageContent />,
        handle: {
          helmet: <FormatOccupationsHelmet />,
        },
      },
    ],
  },
];

export default formatOccupationRoutes;

function FormatOccupationsHelmet() {
  const { t } = useTranslation('relations');
  const params = useParams<{ formatId: string }>();
  invariant(params.formatId, 'Empty formatId');
  const format = useFetcher(fetchFormat, { id: params.formatId });

  return <Helmet title={t('formatToOccupationHelmet', { title: format.name })} />;
}
