import { TFunction } from 'i18next';

export default function getFormattedPersonValueForHistory(
  tCommon: TFunction<'common'>,
  person?: { firstName?: string; surname?: string; personKey?: string },
): string | undefined {
  if (person == null) {
    return undefined;
  }
  if (person.firstName == null) {
    return tCommon('history.deleted_person');
  }
  return person.firstName + ' ' + person.surname + ' (' + person.personKey + ')';
}
