/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  FailureDto,
  GroupDto,
  GroupPageDto,
  HistoryPageDto,
  PageableDto,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    GroupDtoFromJSON,
    GroupDtoToJSON,
    GroupPageDtoFromJSON,
    GroupPageDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface CreateGroupRequest {
    groupDto: GroupDto;
}

export interface DeleteGroupByIdRequest {
    id: string;
}

export interface FetchGroupRequest {
    id: string;
}

export interface FetchGroupHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface GroupDeletableRequest {
    id: string;
}

export interface SearchGroupsRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
    focusedEditionId?: string;
}

export interface TemplateGroupRequest {
    groupTemplateId: string;
}

export interface UpdateGroupRequest {
    id: string;
    groupDto: GroupDto;
}

/**
 * 
 */
export class GroupApi extends runtime.BaseAPI {

    /**
     * Create a new group.
     */
    async createGroupRaw(requestParameters: CreateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupDto>> {
        if (requestParameters['groupDto'] == null) {
            throw new runtime.RequiredError(
                'groupDto',
                'Required parameter "groupDto" was null or undefined when calling createGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GroupDtoToJSON(requestParameters['groupDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupDtoFromJSON(jsonValue));
    }

    /**
     * Create a new group.
     */
    async createGroup(requestParameters: CreateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupDto> {
        const response = await this.createGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete group by id
     */
    async deleteGroupByIdRaw(requestParameters: DeleteGroupByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteGroupById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete group by id
     */
    async deleteGroupById(requestParameters: DeleteGroupByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteGroupByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Get the group template business object.
     */
    async fetchGroupRaw(requestParameters: FetchGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupDtoFromJSON(jsonValue));
    }

    /**
     * Get the group template business object.
     */
    async fetchGroup(requestParameters: FetchGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupDto> {
        const response = await this.fetchGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the group.
     */
    async fetchGroupHistoryRaw(requestParameters: FetchGroupHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchGroupHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the group.
     */
    async fetchGroupHistory(requestParameters: FetchGroupHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchGroupHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the current deletability of the group.
     */
    async groupDeletableRaw(requestParameters: GroupDeletableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling groupDeletable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/{id}/can-delete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     * Get the current deletability of the group.
     */
    async groupDeletable(requestParameters: GroupDeletableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.groupDeletableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for groups.
     */
    async searchGroupsRaw(requestParameters: SearchGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        if (requestParameters['focusedEditionId'] != null) {
            queryParameters['focusedEditionId'] = requestParameters['focusedEditionId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for groups.
     */
    async searchGroups(requestParameters: SearchGroupsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupPageDto> {
        const response = await this.searchGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new group.
     */
    async templateGroupRaw(requestParameters: TemplateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupDto>> {
        if (requestParameters['groupTemplateId'] == null) {
            throw new runtime.RequiredError(
                'groupTemplateId',
                'Required parameter "groupTemplateId" was null or undefined when calling templateGroup().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['groupTemplateId'] != null) {
            queryParameters['groupTemplateId'] = requestParameters['groupTemplateId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new group.
     */
    async templateGroup(requestParameters: TemplateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupDto> {
        const response = await this.templateGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the existing group.
     */
    async updateGroupRaw(requestParameters: UpdateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateGroup().'
            );
        }

        if (requestParameters['groupDto'] == null) {
            throw new runtime.RequiredError(
                'groupDto',
                'Required parameter "groupDto" was null or undefined when calling updateGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GroupDtoToJSON(requestParameters['groupDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing group.
     */
    async updateGroup(requestParameters: UpdateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupDto> {
        const response = await this.updateGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
