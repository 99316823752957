import { Accordion } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '../../ui/dialog/dialog';
import { HelperItem } from '../person/person-merger/helper-item';

export default function SearchHelperDialog({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: (confirm: boolean) => void;
}) {
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('app');

  return (
    <Dialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={() => onClose(false)}
      size="lg"
      title={t('search.helper.title')}
    >
      <Accordion variant="simple" allowMultiple mb={6}>
        <HelperItem namespace="app" title="search.helper.question-1" text={['search.helper.answer-1']} />
        <HelperItem namespace="app" title="search.helper.question-2" text={['search.helper.answer-2']} />
        <HelperItem namespace="app" title="search.helper.question-3" text={['search.helper.answer-3']} />
        <HelperItem namespace="app" title="search.helper.question-4" text={['search.helper.answer-4']} />
        <HelperItem namespace="app" title="search.helper.question-5" text={['search.helper.answer-5']} />
      </Accordion>
    </Dialog>
  );
}
