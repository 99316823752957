import { Box, Center, ChakraProps, HStack, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { StatusForParticipationDto } from '../../../api';
import Translate from '../../../util/translate/translate';
import { statusForParticipationDisplay } from '../guest-list-enum-constants';

interface StatusForParticipationControlProps {
  status: StatusForParticipationDto;
  fontSize?: ChakraProps['fontSize'];
  fontWeight?: ChakraProps['fontWeight'];
  fixedWidthIcon?: boolean;
}

export default function StatusForParticipationControl({
  status,
  fontSize = 'sm',
  fontWeight,
  fixedWidthIcon,
}: StatusForParticipationControlProps) {
  const statusValue = statusForParticipationDisplay[status];

  return (
    <HStack>
      {statusValue != null && statusValue.icon && (
        <Center width={fixedWidthIcon ? 4 : undefined}>
          <Box as={FontAwesomeIcon} icon={statusValue.icon} color={statusValue.iconColor} fontSize={fontSize} />
        </Center>
      )}
      <Translate ns="guest_list">
        {(t) => (
          <Text fontWeight={fontWeight} whiteSpace="nowrap">
            {t(`statusForParticipationOptions.${status}`)}
          </Text>
        )}
      </Translate>
    </HStack>
  );
}
