import { mode, StyleConfig } from '@chakra-ui/theme-tools';

const Link: StyleConfig = {
  baseStyle: (props) => ({
    textDecoration: 'underline',
    textDecorationColor: mode('blackAlpha.300', 'whiteAlpha.500')(props),
    color: 'text.interactive',
  }),
};

export default Link;
