import { useCallback, useRef, useState } from 'react';
import invariant from 'tiny-invariant';

export function useDialogWithInput<TResult = boolean, TInput = any>() {
  const [dialogIsOpen, internalOnDialogClose, internalOpenDialog] = useDialog<TResult>();
  const [dialogInput, setDialogInput] = useState<TInput>();

  const onDialogClose = useCallback(
    (value: TResult) => {
      setDialogInput(undefined);
      internalOnDialogClose(value);
    },
    [internalOnDialogClose],
  );

  const openDialog = useCallback(
    (input: TInput) => {
      setDialogInput(input);
      return internalOpenDialog();
    },
    [internalOpenDialog],
  );

  return [dialogIsOpen, onDialogClose, openDialog, dialogInput] as const;
}

export default function useDialog<T = boolean>() {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const resolveRef = useRef<(value: T) => void>();

  const onDialogClose = useCallback((value: T) => {
    invariant(resolveRef.current != null, 'Dialog has not been opened yet.');
    resolveRef.current(value);
    setDialogIsOpen(false);
  }, []);

  const openDialog = useCallback(
    () =>
      new Promise<T>((resolve) => {
        resolveRef.current = resolve;
        setDialogIsOpen(true);
      }),
    [],
  );

  return [dialogIsOpen, onDialogClose, openDialog] as const;
}
