/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EventReferenceDto } from './EventReferenceDto';
import {
    EventReferenceDtoFromJSON,
    EventReferenceDtoFromJSONTyped,
    EventReferenceDtoToJSON,
} from './EventReferenceDto';
import type { GuestListSettingsDto } from './GuestListSettingsDto';
import {
    GuestListSettingsDtoFromJSON,
    GuestListSettingsDtoFromJSONTyped,
    GuestListSettingsDtoToJSON,
} from './GuestListSettingsDto';

/**
 * 
 * @export
 * @interface GuestlistSearchResultDto
 */
export interface GuestlistSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof GuestlistSearchResultDto
     */
    resultType: GuestlistSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof GuestlistSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {GuestListSettingsDto}
     * @memberof GuestlistSearchResultDto
     */
    guestlist: GuestListSettingsDto;
    /**
     * 
     * @type {EventReferenceDto}
     * @memberof GuestlistSearchResultDto
     */
    event: EventReferenceDto;
}


/**
 * @export
 */
export const GuestlistSearchResultDtoResultTypeEnum = {
    GUESTLIST: 'GUESTLIST'
} as const;
export type GuestlistSearchResultDtoResultTypeEnum = typeof GuestlistSearchResultDtoResultTypeEnum[keyof typeof GuestlistSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the GuestlistSearchResultDto interface.
 */
export function instanceOfGuestlistSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('guestlist' in value)) return false;
    if (!('event' in value)) return false;
    return true;
}

export function GuestlistSearchResultDtoFromJSON(json: any): GuestlistSearchResultDto {
    return GuestlistSearchResultDtoFromJSONTyped(json, false);
}

export function GuestlistSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuestlistSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'guestlist': GuestListSettingsDtoFromJSON(json['guestlist']),
        'event': EventReferenceDtoFromJSON(json['event']),
    };
}

export function GuestlistSearchResultDtoToJSON(value?: GuestlistSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'guestlist': GuestListSettingsDtoToJSON(value['guestlist']),
        'event': EventReferenceDtoToJSON(value['event']),
    };
}

