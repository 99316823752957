/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  FailureDto,
  FormatOccupationPageDto,
  FormatOccupationRelationDto,
  HistoryPageDto,
  PageableDto,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    FormatOccupationPageDtoFromJSON,
    FormatOccupationPageDtoToJSON,
    FormatOccupationRelationDtoFromJSON,
    FormatOccupationRelationDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface CreateRelationsByFormatRequest {
    formatOccupationRelationDto: FormatOccupationRelationDto;
}

export interface FetchHistoryByFormatRequest {
    id: string;
    pageable?: PageableDto;
}

export interface IsFormatOccupationUniqueRequest {
    formatId?: string;
    occupationId?: string;
    editionId?: string;
}

export interface SearchByFormatRequest {
    id: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface UnlinkRelationRequest {
    id: string;
}

/**
 * 
 */
export class FormatOccupationApi extends runtime.BaseAPI {

    /**
     * Create a format occupation relation by format.
     */
    async createRelationsByFormatRaw(requestParameters: CreateRelationsByFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['formatOccupationRelationDto'] == null) {
            throw new runtime.RequiredError(
                'formatOccupationRelationDto',
                'Required parameter "formatOccupationRelationDto" was null or undefined when calling createRelationsByFormat().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/format-occupation-relations/format`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormatOccupationRelationDtoToJSON(requestParameters['formatOccupationRelationDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a format occupation relation by format.
     */
    async createRelationsByFormat(requestParameters: CreateRelationsByFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createRelationsByFormatRaw(requestParameters, initOverrides);
    }

    /**
     */
    async fetchHistoryByFormatRaw(requestParameters: FetchHistoryByFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchHistoryByFormat().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/format-occupation-relations/versions-by-format/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchHistoryByFormat(requestParameters: FetchHistoryByFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchHistoryByFormatRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get wheather the combination of format, occupation and edition is unique.
     */
    async isFormatOccupationUniqueRaw(requestParameters: IsFormatOccupationUniqueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters['formatId'] != null) {
            queryParameters['formatId'] = requestParameters['formatId'];
        }

        if (requestParameters['occupationId'] != null) {
            queryParameters['occupationId'] = requestParameters['occupationId'];
        }

        if (requestParameters['editionId'] != null) {
            queryParameters['editionId'] = requestParameters['editionId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/format-occupation-relations/is-unique`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     * Get wheather the combination of format, occupation and edition is unique.
     */
    async isFormatOccupationUnique(requestParameters: IsFormatOccupationUniqueRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.isFormatOccupationUniqueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for format occupation relations by format.
     */
    async searchByFormatRaw(requestParameters: SearchByFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormatOccupationPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling searchByFormat().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/format-occupation-relations/format/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormatOccupationPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for format occupation relations by format.
     */
    async searchByFormat(requestParameters: SearchByFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormatOccupationPageDto> {
        const response = await this.searchByFormatRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete relation by id
     */
    async unlinkRelationRaw(requestParameters: UnlinkRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling unlinkRelation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/format-occupation-relations/{id}/unlinkRelation`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete relation by id
     */
    async unlinkRelation(requestParameters: UnlinkRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unlinkRelationRaw(requestParameters, initOverrides);
    }

}
