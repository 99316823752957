import { Link, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { LocationDto, LocationStatusCheckDto } from '../../../api';
import locationApi from '../../../data-access/location-api';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import useToast from '../../../ui/use-toast/use-toast';
import useDialog from '../../../util/use-dialog/use-dialog';
import LocationDeleteDialog from './location-delete-dialog';

export interface LocationDeleteButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  location: LocationDto;
  isDeletable: boolean;
  locationStatusCheck: LocationStatusCheckDto;

  onSuccess?(): void;
}

const LocationDeleteButton = React.forwardRef<HTMLButtonElement, LocationDeleteButtonProps>(
  ({ children, location, isDeletable, locationStatusCheck, onSuccess, onClick, disabled, ...props }, ref) => {
    const { t } = useTranslation('location');
    const [deleteDialogIsOpen, onDeleteDialogClose, openDeleteDialog] = useDialog<false>();

    const showDeleteSuccessToast = useToast({
      id: 'location-editor-success-toast',
      status: 'success',
    });

    const showDeleteFailedToast = useToast({
      id: 'location-editor-failed-toast',
      status: 'error',
    });

    const deleteLocation = async (event: React.MouseEvent<HTMLButtonElement>) => {
      onClick?.(event);

      if (event.defaultPrevented) {
        return;
      }

      invariant(location.id != null, 'Missing location id');

      const checked = await openDeleteDialog();
      if (checked) {
        try {
          await locationApi.deleteLocation(
            {
              id: location.id,
            },
            { allowedErrorCodes: [409] } as RequestInit,
          );

          showDeleteSuccessToast({
            title: t('lister.toast.delete_success.title'),
            description: t('lister.toast.delete_success.description', {
              name: location.name,
              abbreviation: location.abbreviation,
            }),
          });
          onSuccess?.();
        } catch (_) {
          showDeleteFailedToast({
            title: t('lister.toast.delete_failed.title'),
            description: t('lister.toast.delete_failed.description', {
              name: location.name,
              abbreviation: location.abbreviation,
            }),
          });
        }
      }
    };

    return (
      <>
        <DisableableButton
          {...props}
          ref={ref}
          onClick={deleteLocation}
          disableReason={<LocationNotDeletableReason location={location} locationStatusCheck={locationStatusCheck} />}
          isDisabled={!isDeletable || disabled}
        >
          {children}
        </DisableableButton>
        <LocationDeleteDialog location={location} isOpen={deleteDialogIsOpen} onClose={onDeleteDialogClose} />
      </>
    );
  },
);

export default LocationDeleteButton;

interface LocationNotDeletableReasonProps {
  location: LocationDto;
  locationStatusCheck: LocationStatusCheckDto;
}

function LocationNotDeletableReason({ location, locationStatusCheck }: LocationNotDeletableReasonProps) {
  const { t } = useTranslation('location');

  return (
    <Stack>
      <Text>
        {t('lister.toast.delete_failed.description', {
          name: location.name,
          abbreviation: location.abbreviation,
        })}
      </Text>
      {locationStatusCheck.eventReferences.length > 0 && (
        <Text>{t('lister.toast.delete_failed.description_event')}</Text>
      )}
      {locationStatusCheck.eventReferences.map((value) => (
        <Link key={value.id} variant="link" as={RouterLink} to={`/events/${value.id}`}>
          <Text isTruncated fontWeight="medium" max-width="fit-content">
            {value.title}
          </Text>
        </Link>
      ))}
      {locationStatusCheck.rundownReferences.length > 0 && (
        <Text>{t('lister.toast.delete_failed.description_rundown')}</Text>
      )}
      {locationStatusCheck.rundownReferences.map((value) => (
        <Link key={value.id} variant="link" as={RouterLink} to={`/events/${value.id}/rundown`}>
          <Text noOfLines={1} fontWeight="medium" max-width="fit-content">
            {value.title}
          </Text>
        </Link>
      ))}
    </Stack>
  );
}
