import useSWR from 'swr';
import invariant from 'tiny-invariant';
import editionApi from '../../../data-access/edition-api';
import { DataTableState } from '../../../ui/data-table';
import useRequestParams from '../../../ui/data-table/use-request-params';
import fallbackMiddleware from '../../../util/swr/fallback-middleware';

export default function useEditions(state?: DataTableState) {
  const requestParams = useRequestParams(state, [
    {
      property: 'dateRange.start',
      direction: 'DESC',
    },
  ]);
  const { data: page, mutate } = useSWR(['editions', requestParams], () => editionApi.searchEditions(requestParams), {
    use: [fallbackMiddleware],
    suspense: true,
  });
  invariant(page != null, 'Missing edition page');

  return { page, mutate };
}
