const backgroundColorTokens = {
  background: {
    default: 'gray.50',
    _dark: 'gray.950',
  },
  'background.hover': {
    default: 'gray.100',
    _dark: 'gray.900',
  },
  'background.muted': {
    default: 'gray.50',
    _dark: 'gray.950',
  },
  'background.interactive': {
    default: 'teal.500',
    _dark: 'teal.600',
  },
  'background.interactive.hover': {
    default: 'teal.600',
    _dark: 'teal.500',
  },
  'background.interactive.active': {
    default: 'teal.700',
    _dark: 'teal.400',
  },
  'background.highlight': {
    default: 'teal.50',
    _dark: 'teal.950',
  },
  'background.hero': {
    default: 'teal.100',
    _dark: 'teal.900',
  },
  'background.error': {
    default: 'red.50',
    _dark: 'red.950',
  },
  'background.error.hover': {
    default: 'red.100',
    _dark: 'red.900',
  },
  'background.success': {
    default: 'green.50',
    _dark: 'green.950',
  },
  'background.success.hover': {
    default: 'green.100',
    _dark: 'green.900',
  },
  'background.warning': {
    default: 'yellow.50',
    _dark: 'yellow.950',
  },
  'background.warning.hover': {
    default: 'yellow.100',
    _dark: 'yellow.900',
  },
  'background.info': {
    default: 'blue.50',
    _dark: 'blue.950',
  },
  'background.info.hover': {
    default: 'blue.100',
    _dark: 'blue.900',
  },
  'background.insert': {
    default: 'green.100',
    _dark: 'green.900',
  },
  'background.delete': {
    default: 'red.100',
    _dark: 'red.900',
  },
} as const;

const layerColorTokens = {
  'layer.01': {
    default: 'white',
    _dark: 'gray.900',
  },
  'layer.01.hover': {
    default: 'gray.100',
    _dark: 'gray.800',
  },
  'layer.02': {
    default: 'gray.100',
    _dark: 'gray.800',
  },
  'layer.02.disabled': {
    default: 'gray.100',
    _dark: 'gray.800',
  },
  'layer.02.hover': {
    default: 'gray.200',
    _dark: 'gray.700',
  },
  'layer.02.active': {
    default: 'gray.300',
    _dark: 'gray.600',
  },
  'layer.03': {
    default: 'gray.200',
    _dark: 'gray.700',
  },
} as const;

const borderColorTokens = {
  'border.interactive': {
    default: 'teal.500',
    _dark: 'teal.400',
  },
  'border.interactive.hover': {
    default: 'teal.600',
    _dark: 'teal.300',
  },
  'border.error': {
    default: 'red.500',
    _dark: 'red.300',
  },
  'border.01': {
    default: 'blackAlpha.300',
    _dark: 'whiteAlpha.300',
  },
  'border.01.muted': {
    default: 'gray.100',
    _dark: 'whiteAlpha.200',
  },
  'border.01.hover': {
    default: 'gray.300',
    _dark: 'gray.600',
  },
  'border.02': {
    default: 'gray.300',
    _dark: 'gray.600',
  },
  'border.02.hover': {
    default: 'gray.400',
    _dark: 'gray.500',
  },
} as const;

const textColorTokens = {
  heading: {
    default: 'teal.700',
    _dark: 'teal.400',
  },
  text: {
    default: 'gray.700',
    _dark: 'gray.200',
  },
  'text.interactive': {
    default: 'teal.600',
    _dark: 'teal.400',
  },
  'text.interactive.hover': {
    default: 'teal.600',
    _dark: 'teal.300',
  },
  'text.interactive.active': {
    default: 'teal.700',
    _dark: 'teal.200',
  },
  'text.muted': {
    default: 'gray.500',
    _dark: 'gray.400',
  },
  'text.disabled': {
    default: 'gray.400',
    _dark: 'gray.600',
  },
  'text.error': {
    default: 'red.500',
    _dark: 'red.300',
  },
  'text.success': {
    default: 'green.500',
    _dark: 'green.300',
  },
  'text.warning': {
    default: 'orange.500',
    _dark: 'orange.300',
  },
  'text.info': {
    default: 'blue.500',
    _dark: 'blue.300',
  },
  'text.insert': {
    default: 'green.800',
    _dark: 'green.100',
  },
  'text.delete': {
    default: 'red.800',
    _dark: 'red.100',
  },
} as const;

export type SemanticToken = keyof (typeof semanticTokens)['colors'];

const semanticTokens = {
  colors: {
    ...backgroundColorTokens,
    ...layerColorTokens,
    ...borderColorTokens,
    ...textColorTokens,
  },
} as const;

export default semanticTokens;
