/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RecipientLinkDto } from './RecipientLinkDto';
import {
    RecipientLinkDtoFromJSON,
    RecipientLinkDtoFromJSONTyped,
    RecipientLinkDtoToJSON,
} from './RecipientLinkDto';

/**
 * 
 * @export
 * @interface RecipientPageDto
 */
export interface RecipientPageDto {
    /**
     * The number of the page.
     * @type {number}
     * @memberof RecipientPageDto
     */
    page: number;
    /**
     * The size of the page.
     * @type {number}
     * @memberof RecipientPageDto
     */
    size: number;
    /**
     * The total number of data returned by the search.
     * @type {number}
     * @memberof RecipientPageDto
     */
    totalElements: number;
    /**
     * The total number of pages of current size to fit all data.
     * @type {number}
     * @memberof RecipientPageDto
     */
    totalPages: number;
    /**
     * 
     * @type {Array<RecipientLinkDto>}
     * @memberof RecipientPageDto
     */
    content: Array<RecipientLinkDto>;
}

/**
 * Check if a given object implements the RecipientPageDto interface.
 */
export function instanceOfRecipientPageDto(value: object): boolean {
    if (!('page' in value)) return false;
    if (!('size' in value)) return false;
    if (!('totalElements' in value)) return false;
    if (!('totalPages' in value)) return false;
    if (!('content' in value)) return false;
    return true;
}

export function RecipientPageDtoFromJSON(json: any): RecipientPageDto {
    return RecipientPageDtoFromJSONTyped(json, false);
}

export function RecipientPageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecipientPageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'page': json['page'],
        'size': json['size'],
        'totalElements': json['totalElements'],
        'totalPages': json['totalPages'],
        'content': ((json['content'] as Array<any>).map(RecipientLinkDtoFromJSON)),
    };
}

export function RecipientPageDtoToJSON(value?: RecipientPageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'page': value['page'],
        'size': value['size'],
        'totalElements': value['totalElements'],
        'totalPages': value['totalPages'],
        'content': ((value['content'] as Array<any>).map(RecipientLinkDtoToJSON)),
    };
}

