/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductDto } from './ProductDto';
import {
    ProductDtoFromJSON,
    ProductDtoFromJSONTyped,
    ProductDtoToJSON,
} from './ProductDto';

/**
 * 
 * @export
 * @interface ProductSearchResultDto
 */
export interface ProductSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof ProductSearchResultDto
     */
    resultType: ProductSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {ProductDto}
     * @memberof ProductSearchResultDto
     */
    result: ProductDto;
}


/**
 * @export
 */
export const ProductSearchResultDtoResultTypeEnum = {
    PRODUCT: 'PRODUCT'
} as const;
export type ProductSearchResultDtoResultTypeEnum = typeof ProductSearchResultDtoResultTypeEnum[keyof typeof ProductSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the ProductSearchResultDto interface.
 */
export function instanceOfProductSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function ProductSearchResultDtoFromJSON(json: any): ProductSearchResultDto {
    return ProductSearchResultDtoFromJSONTyped(json, false);
}

export function ProductSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': ProductDtoFromJSON(json['result']),
    };
}

export function ProductSearchResultDtoToJSON(value?: ProductSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': ProductDtoToJSON(value['result']),
    };
}

