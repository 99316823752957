/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MailingModuleSettingsDto } from './MailingModuleSettingsDto';
import {
    MailingModuleSettingsDtoFromJSON,
    MailingModuleSettingsDtoFromJSONTyped,
    MailingModuleSettingsDtoToJSON,
} from './MailingModuleSettingsDto';
import type { MailingTypeDto } from './MailingTypeDto';
import {
    MailingTypeDtoFromJSON,
    MailingTypeDtoFromJSONTyped,
    MailingTypeDtoToJSON,
} from './MailingTypeDto';
import type { RecipientTypeDto } from './RecipientTypeDto';
import {
    RecipientTypeDtoFromJSON,
    RecipientTypeDtoFromJSONTyped,
    RecipientTypeDtoToJSON,
} from './RecipientTypeDto';
import type { SenderTypeDto } from './SenderTypeDto';
import {
    SenderTypeDtoFromJSON,
    SenderTypeDtoFromJSONTyped,
    SenderTypeDtoToJSON,
} from './SenderTypeDto';

/**
 * Settings and structure of the template
 * @export
 * @interface MailingTemplateDataDto
 */
export interface MailingTemplateDataDto {
    /**
     * 
     * @type {string}
     * @memberof MailingTemplateDataDto
     */
    name: string;
    /**
     * 
     * @type {MailingTypeDto}
     * @memberof MailingTemplateDataDto
     */
    type: MailingTypeDto;
    /**
     * 
     * @type {string}
     * @memberof MailingTemplateDataDto
     */
    languageVersion: MailingTemplateDataDtoLanguageVersionEnum;
    /**
     * 
     * @type {Array<SenderTypeDto>}
     * @memberof MailingTemplateDataDto
     */
    senderTypes: Array<SenderTypeDto>;
    /**
     * 
     * @type {Array<RecipientTypeDto>}
     * @memberof MailingTemplateDataDto
     */
    recipientTypes: Array<RecipientTypeDto>;
    /**
     * 
     * @type {boolean}
     * @memberof MailingTemplateDataDto
     */
    titleIsEditable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MailingTemplateDataDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof MailingTemplateDataDto
     */
    titleEnglish?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MailingTemplateDataDto
     */
    subjectIsEditable?: boolean;
    /**
     * edit mode for user is on, this is the prefix, can also be empty
     * @type {string}
     * @memberof MailingTemplateDataDto
     */
    subjectPrefix?: string;
    /**
     * edit mode for user is on, this is the prefix, can also be empty
     * @type {string}
     * @memberof MailingTemplateDataDto
     */
    subjectPrefixEnglish?: string;
    /**
     * edit mode for user is off, this is the given subject
     * @type {string}
     * @memberof MailingTemplateDataDto
     */
    subject?: string;
    /**
     * edit mode for user is off, this is the given subject
     * @type {string}
     * @memberof MailingTemplateDataDto
     */
    subjectEnglish?: string;
    /**
     * 
     * @type {Array<MailingModuleSettingsDto>}
     * @memberof MailingTemplateDataDto
     */
    moduleSettings: Array<MailingModuleSettingsDto>;
    /**
     * 
     * @type {boolean}
     * @memberof MailingTemplateDataDto
     */
    attachmentsAllowed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MailingTemplateDataDto
     */
    maximumAttachmentSize?: number;
    /**
     * 
     * @type {number}
     * @memberof MailingTemplateDataDto
     */
    maximumAttachmentAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof MailingTemplateDataDto
     */
    mailingDesignBrand: string;
    /**
     * 
     * @type {boolean}
     * @memberof MailingTemplateDataDto
     */
    hasSignature?: boolean;
}


/**
 * @export
 */
export const MailingTemplateDataDtoLanguageVersionEnum = {
    MONOLINGUAL: 'MONOLINGUAL',
    BILINGUAL: 'BILINGUAL'
} as const;
export type MailingTemplateDataDtoLanguageVersionEnum = typeof MailingTemplateDataDtoLanguageVersionEnum[keyof typeof MailingTemplateDataDtoLanguageVersionEnum];


/**
 * Check if a given object implements the MailingTemplateDataDto interface.
 */
export function instanceOfMailingTemplateDataDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('type' in value)) return false;
    if (!('languageVersion' in value)) return false;
    if (!('senderTypes' in value)) return false;
    if (!('recipientTypes' in value)) return false;
    if (!('moduleSettings' in value)) return false;
    if (!('mailingDesignBrand' in value)) return false;
    return true;
}

export function MailingTemplateDataDtoFromJSON(json: any): MailingTemplateDataDto {
    return MailingTemplateDataDtoFromJSONTyped(json, false);
}

export function MailingTemplateDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingTemplateDataDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'type': MailingTypeDtoFromJSON(json['type']),
        'languageVersion': json['languageVersion'],
        'senderTypes': ((json['senderTypes'] as Array<any>).map(SenderTypeDtoFromJSON)),
        'recipientTypes': ((json['recipientTypes'] as Array<any>).map(RecipientTypeDtoFromJSON)),
        'titleIsEditable': json['titleIsEditable'] == null ? undefined : json['titleIsEditable'],
        'title': json['title'] == null ? undefined : json['title'],
        'titleEnglish': json['titleEnglish'] == null ? undefined : json['titleEnglish'],
        'subjectIsEditable': json['subjectIsEditable'] == null ? undefined : json['subjectIsEditable'],
        'subjectPrefix': json['subjectPrefix'] == null ? undefined : json['subjectPrefix'],
        'subjectPrefixEnglish': json['subjectPrefixEnglish'] == null ? undefined : json['subjectPrefixEnglish'],
        'subject': json['subject'] == null ? undefined : json['subject'],
        'subjectEnglish': json['subjectEnglish'] == null ? undefined : json['subjectEnglish'],
        'moduleSettings': ((json['moduleSettings'] as Array<any>).map(MailingModuleSettingsDtoFromJSON)),
        'attachmentsAllowed': json['attachmentsAllowed'] == null ? undefined : json['attachmentsAllowed'],
        'maximumAttachmentSize': json['maximumAttachmentSize'] == null ? undefined : json['maximumAttachmentSize'],
        'maximumAttachmentAmount': json['maximumAttachmentAmount'] == null ? undefined : json['maximumAttachmentAmount'],
        'mailingDesignBrand': json['mailingDesignBrand'],
        'hasSignature': json['hasSignature'] == null ? undefined : json['hasSignature'],
    };
}

export function MailingTemplateDataDtoToJSON(value?: MailingTemplateDataDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'type': MailingTypeDtoToJSON(value['type']),
        'languageVersion': value['languageVersion'],
        'senderTypes': ((value['senderTypes'] as Array<any>).map(SenderTypeDtoToJSON)),
        'recipientTypes': ((value['recipientTypes'] as Array<any>).map(RecipientTypeDtoToJSON)),
        'titleIsEditable': value['titleIsEditable'],
        'title': value['title'],
        'titleEnglish': value['titleEnglish'],
        'subjectIsEditable': value['subjectIsEditable'],
        'subjectPrefix': value['subjectPrefix'],
        'subjectPrefixEnglish': value['subjectPrefixEnglish'],
        'subject': value['subject'],
        'subjectEnglish': value['subjectEnglish'],
        'moduleSettings': ((value['moduleSettings'] as Array<any>).map(MailingModuleSettingsDtoToJSON)),
        'attachmentsAllowed': value['attachmentsAllowed'],
        'maximumAttachmentSize': value['maximumAttachmentSize'],
        'maximumAttachmentAmount': value['maximumAttachmentAmount'],
        'mailingDesignBrand': value['mailingDesignBrand'],
        'hasSignature': value['hasSignature'],
    };
}

