import useSWRImmutable from 'swr/immutable';
import invariant from 'tiny-invariant';
import editionApi from '../../../data-access/edition-api';

export default function useNextActiveEdition() {
  const { data: edition } = useSWRImmutable('nextActiveEdition', () => editionApi.nextActiveEdition(), {
    suspense: true,
  });
  invariant(edition != null);

  return edition;
}
