import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';

const myAccountRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'my-account',
    lazy: () => import('./my-account-page'),
    children: [
      { path: '', lazy: () => import('./editor/my-account-page-editor-route') },
      { path: 'history', lazy: () => import('./history/my-account-page-history-route') },
    ],
  },
];

export default myAccountRoutes;
