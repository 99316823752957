import { Grid, GridItem, HStack, Spacer } from '@chakra-ui/react';
import React from 'react';
import { SectionDto, SectionStaffListItemDto, StaffStatusDto } from '../../../../api';
import DataTable, { DataTableState, useDataTableState } from '../../../../ui/data-table';
import useRequestParams from '../../../../ui/data-table/use-request-params';
import fallbackMiddleware from '../../../../util/swr/fallback-middleware';
import useFetcher from '../../../../util/swr/use-fetcher';
import { fetchSectionStaffRelations } from '../section-staff-queries';
import useSectionStaffTableActions from './section-staff-lister-action-extension';
import useSectionStaffSelectionActions from './section-staff-selection-action-extension';
import useSectionStaffTableColumns from './section-staff-table-columns';

function useSectionStaffRelations(state: DataTableState, sectionId: string) {
  const requestParams = useRequestParams(state, [
    { property: 'surname', direction: 'ASC' },
    { property: 'firstName', direction: 'ASC' },
  ]);
  return useFetcher(fetchSectionStaffRelations, { ...requestParams, id: sectionId }, { use: [fallbackMiddleware] });
}

export default function SectionStaffRelationDataTable({ section }: { section: SectionDto }) {
  const [state, patchState] = useDataTableState({
    filter: [
      {
        property: 'status',
        operator: 'in',
        value: 'ACTIVE',
      },
    ],
  });
  const page = useSectionStaffRelations(state, section.id!);

  const columns = useSectionStaffTableColumns(section.id!);
  const actions = useSectionStaffTableActions();
  const selectionActions = useSectionStaffSelectionActions();

  const keySelector = React.useCallback((sectionStaff: SectionStaffListItemDto) => {
    return sectionStaff.id!;
  }, []);

  return (
    <Grid gridTemplateRows="1fr auto" maxHeight="full" gridRowGap={2}>
      <GridItem>
        <HStack justifyContent="space-between" alignItems="end">
          <Spacer />
          <HStack>
            {selectionActions(
              page.content.filter((item) => state.selection.includes(keySelector(item))),
              patchState,
            )}
          </HStack>
        </HStack>
      </GridItem>
      <GridItem minHeight={0}>
        <DataTable
          page={page == null ? { content: [] } : page}
          state={state}
          columns={columns}
          actions={actions}
          rowKey={keySelector}
          onStateChange={patchState}
          isPageable
          selection={{ keySelector }}
          rowStyle={(staff) =>
            staff.status === StaffStatusDto.INACTIVE
              ? {
                  transition: 'opacity var(--chakra-transition-duration-normal)',
                  opacity: 0.7,
                  _hover: {
                    opacity: 1,
                  },
                }
              : {}
          }
        />
      </GridItem>
    </Grid>
  );
}
