import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { invoiceIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function InvoiceSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'INVOICE', `${result.resultType} is no instance of InvoiceSearchResult`);

  const invoice = result.result;
  const { t } = useTranslation(['invoice']);

  return (
    <SearchResult
      icon={invoiceIcon}
      title={invoice.invoiceNumber!}
      link={`/invoices/${invoice.id}`}
      score={result.score}
      onClick={onClick}
      necessaryPermission="INVOICE:CAN_SEE_DETAILS"
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('invoice:issuer'),
            element: t(`invoice:issuerOptions.${invoice.issuer}`),
          },
        ]}
      />
    </SearchResult>
  );
}
