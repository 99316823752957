/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OccupationFormatConfirmationStatusDto = {
    CONFIRMED: 'CONFIRMED',
    UNCONFIRMED: 'UNCONFIRMED'
} as const;
export type OccupationFormatConfirmationStatusDto = typeof OccupationFormatConfirmationStatusDto[keyof typeof OccupationFormatConfirmationStatusDto];


export function OccupationFormatConfirmationStatusDtoFromJSON(json: any): OccupationFormatConfirmationStatusDto {
    return OccupationFormatConfirmationStatusDtoFromJSONTyped(json, false);
}

export function OccupationFormatConfirmationStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OccupationFormatConfirmationStatusDto {
    return json as OccupationFormatConfirmationStatusDto;
}

export function OccupationFormatConfirmationStatusDtoToJSON(value?: OccupationFormatConfirmationStatusDto | null): any {
    return value as any;
}

