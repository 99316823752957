import { Button, Flex, Grid, Heading, Spacer, useDisclosure } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GroupDto, GroupStatusDto } from '../../../../api';
import PageContent from '../../../../ui/page/page-content';
import { GroupRelationExtension } from '../../../group/group-relation/group-relation-extension';
import usePermission from '../../../permission/use-permission';

import GroupPersonEditorDialog from './group-person-editor-dialog/group-person-editor-dialog';
import GroupPersonRelationDataTable from './group-person-viewer/group-person-relation-data-table';

export const groupPersonPageContentExtension: GroupRelationExtension = {
  pageContent: (group) => <GroupPersonPageContent group={group} />,
};

interface GroupPersonPageContentProps {
  group: GroupDto;
}

function GroupPersonPageContent({ group }: GroupPersonPageContentProps) {
  const { t } = useTranslation('group');
  const { hasPermission } = usePermission();
  const { isOpen: editDialogIsOpen, onOpen: onEditDialogOpen, onClose: onEditDialogClose } = useDisclosure();

  return (
    <PageContent useFullWidth>
      <Grid gridRowGap={6} maxH="full" gridTemplateRows="auto 1fr">
        <Flex as="header">
          <Heading size="md" as="h3">
            {t('connections.persons')}
          </Heading>
          <Spacer />
          {hasPermission('GROUP-PERSON:CAN_LINK') && (
            <Button
              variant="primary"
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
              onClick={onEditDialogOpen}
              aria-label={t('connections.add_person')}
              isDisabled={group.status !== GroupStatusDto.ACTIVE}
            >
              {t('connections.add_person')}
            </Button>
          )}
        </Flex>
        <GroupPersonRelationDataTable group={group} />
        <GroupPersonEditorDialog isOpen={editDialogIsOpen} onClose={onEditDialogClose} group={group} />
      </Grid>
    </PageContent>
  );
}
