import { ParseKeys } from 'i18next/typescript/t';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import useEvent from '../event/use-event/use-event';

const KeyPlayerHistory = React.lazy(() => import('./key-player-history/key-player-history'));
const KeyPlayerListPublicationLister = React.lazy(
  () => import('./key-player-publication-lister/key-player-list-publication-lister'),
);
const KeyPlayersListPage = React.lazy(() => import('./key-players-list-page/key-players-list-page'));
const KeyPlayersListSettingsEditor = React.lazy(
  () => import('./key-players-list-settings-editor/key-players-list-settings-editor'),
);
const KeyPlayersListSettingsViewer = React.lazy(
  () => import('./key-players-list-settings-viewer/key-players-list-settings-viewer'),
);
const KeyPlayersListViewer = React.lazy(() => import('./key-players-list-viewer/key-players-list-viewer'));

const KeyPlayerListViewerMobile = React.lazy(
  () => import('./key-players-list-viewer-mobile/key-player-list-viewer-mobile'),
);

const keyPlayersListRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'key-player-list',
    handle: {
      breadcrumb: <Translate ns="key_player_list" i18nKey="key_player_list" />,
    },
    children: [
      {
        path: '',
        element: <KeyPlayersListPage />,
        children: [
          {
            path: '',
            element: <KeyPlayersListViewer />,
            handle: {
              helmet: <KeyPlayerListHelmet i18nKey="viewer.helmet" />,
            },
          },
          {
            path: 'publication',
            element: <KeyPlayerListPublicationLister />,
            handle: {
              helmet: <KeyPlayerListHelmet i18nKey="publication.helmet" />,
            },
          },
        ],
      },
      {
        path: '',
        element: <KeyPlayersListPage />,
        children: [
          {
            path: 'settings',
            element: <KeyPlayersListSettingsViewer />,
            handle: {
              helmet: <KeyPlayerListHelmet i18nKey="settings.helmet" />,
            },
          },
          {
            path: 'history',
            element: <KeyPlayerHistory />,
            handle: {
              helmet: <KeyPlayerListHelmet i18nKey="history.helmet" />,
            },
          },
        ],
      },
      {
        path: 'settings/edit',
        element: <KeyPlayersListSettingsEditor />,
        handle: {
          breadcrumb: <Translate ns="key_player_list" i18nKey="action.edit" />,
          helmet: <KeyPlayerListHelmet i18nKey="settings.helmetEdit" />,
        },
      },
    ],
  },
];

export default keyPlayersListRoutes;

export const keyPlayersListRoutesMobile: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'key-player-list',
    handle: {
      breadcrumb: <Translate ns="key_player_list" i18nKey="key_player_list" />,
      helmet: <KeyPlayerListHelmet i18nKey="viewer.helmet" />,
    },
    element: <KeyPlayerListViewerMobile />,
  },
];

interface KeyPlayerListHelmetProps {
  i18nKey: ParseKeys<'key_player_list'>;
}

function KeyPlayerListHelmet({ i18nKey }: KeyPlayerListHelmetProps) {
  const { t } = useTranslation('key_player_list');
  const params = useParams<{ eventId: string }>();
  invariant(params.eventId, 'Empty eventId');
  const { title } = useEvent(params.eventId);

  return <Helmet title={t(i18nKey, { title })} />;
}
