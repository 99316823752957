import React from 'react';
import { useParams } from 'react-router-dom';
import useFetcher from '../../../util/swr/use-fetcher';
import { usePermissionClassGroupExtensions } from '../permission-class-group-extension';
import { fetchPermissionClassGroup } from '../permission-class-group-queries';
import { getPermissionClassGroupId } from './get-permission-class-group-id';

export default function PermissionClassGroupBreadcrumb() {
  const permissionClassGroup = useFetcher(fetchPermissionClassGroup, { id: getPermissionClassGroupId(useParams()) });
  const { getExtension } = usePermissionClassGroupExtensions();

  return <>{getExtension(permissionClassGroup.name).label}</>;
}
