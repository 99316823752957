import { formAnatomy } from '@chakra-ui/anatomy';
import { MultiStyleConfig } from '@chakra-ui/theme-tools';

const Form: MultiStyleConfig<typeof formAnatomy> = {
  parts: formAnatomy.keys,
  baseStyle: {
    helperText: {
      color: 'text.muted',
    },
  },
};

export default Form;
