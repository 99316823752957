/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { TaxExemptionAreaDto } from './TaxExemptionAreaDto';
import {
    TaxExemptionAreaDtoFromJSON,
    TaxExemptionAreaDtoFromJSONTyped,
    TaxExemptionAreaDtoToJSON,
} from './TaxExemptionAreaDto';

/**
 * 
 * @export
 * @interface TaxExemptionDto
 */
export interface TaxExemptionDto {
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof TaxExemptionDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof TaxExemptionDto
     */
    taxId: string;
    /**
     * 
     * @type {TaxExemptionAreaDto}
     * @memberof TaxExemptionDto
     */
    area: TaxExemptionAreaDto;
    /**
     * 
     * @type {string}
     * @memberof TaxExemptionDto
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof TaxExemptionDto
     */
    timestamp?: Date;
}

/**
 * Check if a given object implements the TaxExemptionDto interface.
 */
export function instanceOfTaxExemptionDto(value: object): boolean {
    if (!('edition' in value)) return false;
    if (!('taxId' in value)) return false;
    if (!('area' in value)) return false;
    return true;
}

export function TaxExemptionDtoFromJSON(json: any): TaxExemptionDto {
    return TaxExemptionDtoFromJSONTyped(json, false);
}

export function TaxExemptionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxExemptionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'taxId': json['taxId'],
        'area': TaxExemptionAreaDtoFromJSON(json['area']),
        'comment': json['comment'] == null ? undefined : json['comment'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
    };
}

export function TaxExemptionDtoToJSON(value?: TaxExemptionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'taxId': value['taxId'],
        'area': TaxExemptionAreaDtoToJSON(value['area']),
        'comment': value['comment'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
    };
}

