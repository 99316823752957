import React from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import invariant from 'tiny-invariant';
import { EventEvaluationDto } from '../../../api';
import eventEvaluationApi from '../../../data-access/event-evaluation-api';
import HistoryPage from '../../../ui/history/history-page';
import usePaginationState from '../../../ui/pagination/use-pagination-state';
import useEvent from '../../event/use-event/use-event';
import useEventEvaluationHistorySettings from './use-event-evaluation-history-settings';

export default function EventEvaluationHistory() {
  const [state, setState] = usePaginationState();
  const { eventId } = useParams<{ eventId: string }>();
  invariant(eventId != null, 'Missing eventId');
  const event = useEvent(eventId);
  const eventEvaluationHistorySettings = useEventEvaluationHistorySettings(event);

  const { data: page } = useSWR(
    ['eventEvaluationHistory', eventId, 'state', state],
    () =>
      eventEvaluationApi.fetchEventEvaluationHistory({
        eventId: eventId,
        pageable: state,
      }),
    {
      suspense: true,
    },
  );

  invariant(page != null, 'Missing page');
  return (
    <HistoryPage<EventEvaluationDto>
      page={page}
      state={state}
      onStateChange={setState}
      historyDisplaySettings={eventEvaluationHistorySettings}
    />
  );
}
