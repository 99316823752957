/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MediaTypeDto = {
    PRINT: 'PRINT',
    ONLINE: 'ONLINE',
    TV_VIDEO: 'TV_VIDEO',
    RADIO_AUDIO: 'RADIO_AUDIO',
    WEB_TV: 'WEB_TV',
    PODCAST: 'PODCAST',
    SOCIAL_MEDIA: 'SOCIAL_MEDIA',
    SOCIAL_MEDIA_VIDEO: 'SOCIAL_MEDIA_VIDEO',
    PR: 'PR',
    NEWS_AGENCY_PHOTO: 'NEWS_AGENCY_PHOTO',
    NEWS_AGENCY_PRINT: 'NEWS_AGENCY_PRINT',
    NEWS_AGENCY_AUDIO: 'NEWS_AGENCY_AUDIO',
    NEWS_AGENCY_TV: 'NEWS_AGENCY_TV'
} as const;
export type MediaTypeDto = typeof MediaTypeDto[keyof typeof MediaTypeDto];


export function MediaTypeDtoFromJSON(json: any): MediaTypeDto {
    return MediaTypeDtoFromJSONTyped(json, false);
}

export function MediaTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MediaTypeDto {
    return json as MediaTypeDto;
}

export function MediaTypeDtoToJSON(value?: MediaTypeDto | null): any {
    return value as any;
}

