import React from 'react';
import { FileMetadataDto } from '../../../api';
import formatFileSize from '../../../util/file-size/file-size';
import Translate from '../../../util/translate/translate';
import { DataTableColumn, DataTableTruncatedText } from '../../data-table';
import { AttachmentImage } from '../attachment-image';

const photoDataTableColumns: DataTableColumn<FileMetadataDto>[] = [
  {
    key: 'photo',
    name: '',
    renderCell: (data) => {
      return <AttachmentImage metadata={data} />;
    },
  },
  {
    key: 'label',
    name: <Translate ns="attachment" i18nKey="file_name" />,
    renderCell: (data) => <DataTableTruncatedText> {data.originalFileName}</DataTableTruncatedText>,
  },
  {
    key: 'publish',
    name: <Translate ns="attachment" i18nKey="file_size" />,
    renderCell: (data) => <Translate ns="common">{(t) => t('format.file_size', formatFileSize(data.size))}</Translate>,
  },
  {
    key: 'confidential',
    name: <Translate ns="attachment" i18nKey="resolution" />,
    renderCell: (data) => (
      <Translate ns="attachment">
        {(t) =>
          t('resolution_dimensions', {
            width: data.width,
            height: data.height,
          })
        }
      </Translate>
    ),
  },
];

export default photoDataTableColumns;
