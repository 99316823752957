import { useMemo } from 'react';
import asyncDebounce from '../../util/async-debounce/async-debounce';

export default function useDebouncedLoadOptions<TOption>(
  loadOptions: (searchQuery: string, pageSizeLimit: number) => Promise<TOption[]>,
  pageSizeLimit: number,
  debounceTime: number,
) {
  const debouncedLoadOptions = useMemo(
    () => (debounceTime !== 0 ? asyncDebounce(loadOptions, debounceTime) : loadOptions),
    [debounceTime, loadOptions],
  );

  return (value: string) => {
    return debouncedLoadOptions(value, pageSizeLimit);
  };
}
