/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ParticipationBookingClassDto = {
    BUSINESS: 'BUSINESS',
    ECONOMY_PLUS: 'ECONOMY_PLUS',
    ECONOMY: 'ECONOMY',
    FIRST_CLASS: 'FIRST_CLASS',
    SECOND_CLASS: 'SECOND_CLASS'
} as const;
export type ParticipationBookingClassDto = typeof ParticipationBookingClassDto[keyof typeof ParticipationBookingClassDto];


export function ParticipationBookingClassDtoFromJSON(json: any): ParticipationBookingClassDto {
    return ParticipationBookingClassDtoFromJSONTyped(json, false);
}

export function ParticipationBookingClassDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipationBookingClassDto {
    return json as ParticipationBookingClassDto;
}

export function ParticipationBookingClassDtoToJSON(value?: ParticipationBookingClassDto | null): any {
    return value as any;
}

