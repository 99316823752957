import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
} from '@chakra-ui/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ProductDto, ProductListItemDto } from '../../../api';

interface ProductStatusToggleDialogProps extends Pick<AlertDialogProps, 'isOpen' | 'onClose'> {
  product: ProductDto | ProductListItemDto;
  shouldActivate: boolean;

  onToggle(): void;
}

export default function ProductStatusToggleDialog({
  product,
  shouldActivate,
  onToggle,
  onClose,
  ...props
}: ProductStatusToggleDialogProps) {
  const closeButtonRef = React.useRef<HTMLButtonElement>(null);
  const { t } = useTranslation(['product', 'common']);

  return (
    <AlertDialog {...props} onClose={onClose} leastDestructiveRef={closeButtonRef}>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>
          {shouldActivate ? t('product:action.reactivate') : t('product:action.archive')}
        </AlertDialogHeader>
        <AlertDialogBody>
          <Trans
            t={t}
            i18nKey={shouldActivate ? 'product:reactivateMessage' : 'product:archiveMessage'}
            values={{
              name: product.title,
            }}
          />
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button onClick={onClose} mr={3} ref={closeButtonRef}>
            {t('common:action.abort')}
          </Button>
          <Button
            onClick={onToggle}
            variant={shouldActivate ? 'primary' : 'solid'}
            colorScheme={shouldActivate ? 'teal' : 'red'}
          >
            {shouldActivate ? t('product:action.reactivate') : t('product:action.archive')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
