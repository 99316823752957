/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccessGroupPermissionDto
 */
export interface AccessGroupPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof AccessGroupPermissionDto
     */
    name: AccessGroupPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof AccessGroupPermissionDto
     */
    action: AccessGroupPermissionDtoActionEnum;
}


/**
 * @export
 */
export const AccessGroupPermissionDtoNameEnum = {
    ACCESS_GROUP: 'ACCESS_GROUP'
} as const;
export type AccessGroupPermissionDtoNameEnum = typeof AccessGroupPermissionDtoNameEnum[keyof typeof AccessGroupPermissionDtoNameEnum];

/**
 * @export
 */
export const AccessGroupPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    EDIT: 'CAN_EDIT',
    CREATE: 'CAN_CREATE',
    ACTIVATE: 'CAN_ACTIVATE',
    DEACTIVATE: 'CAN_DEACTIVATE'
} as const;
export type AccessGroupPermissionDtoActionEnum = typeof AccessGroupPermissionDtoActionEnum[keyof typeof AccessGroupPermissionDtoActionEnum];


/**
 * Check if a given object implements the AccessGroupPermissionDto interface.
 */
export function instanceOfAccessGroupPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function AccessGroupPermissionDtoFromJSON(json: any): AccessGroupPermissionDto {
    return AccessGroupPermissionDtoFromJSONTyped(json, false);
}

export function AccessGroupPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessGroupPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function AccessGroupPermissionDtoToJSON(value?: AccessGroupPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

