/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CompanyRoleDto = {
    AGENCY: 'AGENCY',
    COMMISSIONING_EDITOR: 'COMMISSIONING_EDITOR',
    CO_PRODUCTION: 'CO_PRODUCTION',
    GERMAN_DISTRIBUTOR: 'GERMAN_DISTRIBUTOR',
    EFM_CO_PRODUCTION: 'EFM_CO_PRODUCTION',
    EFM_PRODUCTION: 'EFM_PRODUCTION',
    EFM_WORLD_SALES: 'EFM_WORLD_SALES',
    FILM_FUND: 'FILM_FUND',
    FILM_SCHOOL: 'FILM_SCHOOL',
    INSTITUTE: 'INSTITUTE',
    PRODUCTION: 'PRODUCTION',
    DISTRIBUTOR: 'DISTRIBUTOR',
    WORLD_SALES: 'WORLD_SALES'
} as const;
export type CompanyRoleDto = typeof CompanyRoleDto[keyof typeof CompanyRoleDto];


export function CompanyRoleDtoFromJSON(json: any): CompanyRoleDto {
    return CompanyRoleDtoFromJSONTyped(json, false);
}

export function CompanyRoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyRoleDto {
    return json as CompanyRoleDto;
}

export function CompanyRoleDtoToJSON(value?: CompanyRoleDto | null): any {
    return value as any;
}

