import useSWR from 'swr';
import invariant from 'tiny-invariant';
import placementTemplateApi from '../../../../data-access/placement-template-api';

export default function usePlacementTemplate(parentId: string, id: string) {
  const { data, mutate } = useSWR(
    [parentId, 'placement_templates', id],
    () => placementTemplateApi.fetchPlacementTemplate({ parentId, id }),
    {
      suspense: true,
    },
  );
  invariant(data != null);

  return { placementTemplate: data, mutate };
}
