import React from 'react';
import { PostalAddressDto } from '../../../api';
import { DataTableColumn } from '../../../ui/data-table';
import Optional from '../../../ui/optional/optional';
import Translate from '../../../util/translate/translate';
import { renderPostalAddress } from '../../../ui/postal-address/render-postal-address';

const addressDataTableColumns: DataTableColumn<PostalAddressDto>[] = [
  {
    key: 'postalAddress',
    name: <Translate ns="person" i18nKey="contacts.postal_address.address" />,
    renderCell: (data) => renderPostalAddress(data),
  },
  {
    key: 'addressSupplement',
    name: <Translate ns="person" i18nKey="contacts.postal_address.address_supplement" />,
    renderCell: (data) => <Optional>{data.addressSupplement}</Optional>,
  },
  {
    key: 'label',
    name: <Translate ns="person" i18nKey="contacts.postal_address.label" />,
    renderCell: (data) => (
      <Translate ns="person">{(t) => t(`contacts.postal_address.labelOptions.${data.label}`)}</Translate>
    ),
  },
];

export default addressDataTableColumns;
