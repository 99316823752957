/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AwardDto } from './AwardDto';
import {
    AwardDtoFromJSON,
    AwardDtoFromJSONTyped,
    AwardDtoToJSON,
} from './AwardDto';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * The connection between an award and a winner
 * @export
 * @interface AwardWinnerRelationDto
 */
export interface AwardWinnerRelationDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof AwardWinnerRelationDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof AwardWinnerRelationDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {AwardDto}
     * @memberof AwardWinnerRelationDto
     */
    award: AwardDto;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof AwardWinnerRelationDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof AwardWinnerRelationDto
     */
    winner: PersonReferenceDto;
}

/**
 * Check if a given object implements the AwardWinnerRelationDto interface.
 */
export function instanceOfAwardWinnerRelationDto(value: object): boolean {
    if (!('award' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('winner' in value)) return false;
    return true;
}

export function AwardWinnerRelationDtoFromJSON(json: any): AwardWinnerRelationDto {
    return AwardWinnerRelationDtoFromJSONTyped(json, false);
}

export function AwardWinnerRelationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AwardWinnerRelationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'award': AwardDtoFromJSON(json['award']),
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'winner': PersonReferenceDtoFromJSON(json['winner']),
    };
}

export function AwardWinnerRelationDtoToJSON(value?: AwardWinnerRelationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'award': AwardDtoToJSON(value['award']),
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'winner': PersonReferenceDtoToJSON(value['winner']),
    };
}

