/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ParticipationSecurityLevelDto = {
    NORMAL: 'NORMAL',
    HIGH: 'HIGH',
    HIGHEST: 'HIGHEST'
} as const;
export type ParticipationSecurityLevelDto = typeof ParticipationSecurityLevelDto[keyof typeof ParticipationSecurityLevelDto];


export function ParticipationSecurityLevelDtoFromJSON(json: any): ParticipationSecurityLevelDto {
    return ParticipationSecurityLevelDtoFromJSONTyped(json, false);
}

export function ParticipationSecurityLevelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipationSecurityLevelDto {
    return json as ParticipationSecurityLevelDto;
}

export function ParticipationSecurityLevelDtoToJSON(value?: ParticipationSecurityLevelDto | null): any {
    return value as any;
}

