import { WarningIcon } from '@chakra-ui/icons';
import { Box, HStack, Tooltip } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faLoveseat as faLoveseatLight,
  faUserAlt as faUserAltLight,
  faUserSecret as faUserSecretLight,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GuestTypeDto } from '../../../api';
import { bodyguardIcon, personIcon, placeholderIcon } from '../../../ui/icons/business-objects';

interface GuestIconControlProps {
  guestType: GuestTypeDto;
  isDuplicate?: boolean;
}

export const guestTypeMap: { [key in GuestTypeDto]: IconProp } = {
  [GuestTypeDto.PERSON]: personIcon,
  [GuestTypeDto.DUMMY]: placeholderIcon,
  [GuestTypeDto.BODYGUARD]: bodyguardIcon,
};

export const guestTypeMapLight: { [key in GuestTypeDto]: IconProp } = {
  [GuestTypeDto.PERSON]: faUserAltLight,
  [GuestTypeDto.DUMMY]: faLoveseatLight,
  [GuestTypeDto.BODYGUARD]: faUserSecretLight,
};

export default function GuestIconControl({ guestType, isDuplicate }: GuestIconControlProps) {
  const { t } = useTranslation('guest_list');
  return (
    <HStack>
      <Tooltip label={t(`guestTypeOptions.${guestType}`)}>
        <Box color="text.muted">
          <FontAwesomeIcon icon={guestTypeMap[guestType]} />
        </Box>
      </Tooltip>
      {isDuplicate && (
        <Tooltip label={t(`duplicate`)}>
          <Box color="text.warning">
            <WarningIcon aria-label={t(`duplicate`)} mb="2px" />
          </Box>
        </Tooltip>
      )}
    </HStack>
  );
}
