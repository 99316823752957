/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GroupDto } from './GroupDto';
import {
    GroupDtoFromJSON,
    GroupDtoFromJSONTyped,
    GroupDtoToJSON,
} from './GroupDto';

/**
 * 
 * @export
 * @interface GroupSearchResultDto
 */
export interface GroupSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof GroupSearchResultDto
     */
    resultType: GroupSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof GroupSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {GroupDto}
     * @memberof GroupSearchResultDto
     */
    result: GroupDto;
}


/**
 * @export
 */
export const GroupSearchResultDtoResultTypeEnum = {
    GROUP: 'GROUP'
} as const;
export type GroupSearchResultDtoResultTypeEnum = typeof GroupSearchResultDtoResultTypeEnum[keyof typeof GroupSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the GroupSearchResultDto interface.
 */
export function instanceOfGroupSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function GroupSearchResultDtoFromJSON(json: any): GroupSearchResultDto {
    return GroupSearchResultDtoFromJSONTyped(json, false);
}

export function GroupSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': GroupDtoFromJSON(json['result']),
    };
}

export function GroupSearchResultDtoToJSON(value?: GroupSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': GroupDtoToJSON(value['result']),
    };
}

