import { chakra, forwardRef, HTMLChakraProps, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { GroupDto, GroupPersonRelationDto } from '../../../../../api';
import PersonInGroupEditorDialog from './person-in-group-editor-dialog';

interface PersonInGroupEditorButtonProps extends HTMLChakraProps<'button'> {
  group: GroupDto;
  groupPersonRelation: GroupPersonRelationDto;
  dialogIsOpen?: boolean;
  onDialogOpen?(): void;
  onDialogClose?(): void;
}

function PersonInGroupEditorButton(
  {
    onClick,
    group,
    groupPersonRelation,
    dialogIsOpen,
    onDialogOpen,
    onDialogClose,
    ...props
  }: PersonInGroupEditorButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const { isOpen, onOpen, onClose } = useDisclosure({
    isOpen: dialogIsOpen,
    onOpen: onDialogOpen,
    onClose: onDialogClose,
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);

    if (!event.defaultPrevented) {
      onOpen();
    }
  };

  return (
    <>
      <chakra.button {...props} ref={ref} onClick={handleClick} />
      <PersonInGroupEditorDialog
        group={group}
        groupPersonRelation={groupPersonRelation}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
}

export default forwardRef<PersonInGroupEditorButtonProps, 'button'>(PersonInGroupEditorButton);
