import { has } from 'lodash-es';
import { PersonReferenceDto, PersonReferenceDtoFromJSON, StaffDto, StaffListItemDto } from '../../../api';
import PersonReference from '../person-reference/person-reference';

interface PersonStaffReferenceProps {
  staff: StaffDto | StaffListItemDto;
  flipName?: boolean;
}

export default function PersonStaffReference({ staff, flipName = false }: PersonStaffReferenceProps) {
  const person = usePerson(staff);
  return <PersonReference personReference={person} hidePersonKey usePortalForCard flipName={flipName} />;
}

function usePerson(staff: StaffDto | StaffListItemDto): PersonReferenceDto {
  if (isStaffDto(staff)) {
    return PersonReferenceDtoFromJSON(staff.extensions.person);
  } else {
    return staff.person;
  }
}

function isStaffDto(staff: StaffDto | StaffListItemDto): staff is StaffDto {
  return has(staff, 'extensions');
}
