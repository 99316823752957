import { isEqual } from 'lodash-es';
import React from 'react';
import { FieldNamesMarkedBoolean, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PersonDto } from '../../../api';
import ConfirmDialog from '../../../ui/dialog/confirm-dialog';
import useDialog from '../../../util/use-dialog/use-dialog';
import usePending from '../../../util/use-pending/use-pending';
import { PersonFormModel } from './person-form';

export default function useHandleValid({
  form,
  catchOptimisticLockingFailure,
  onPersonSave,
}: {
  form: UseFormReturn<PersonFormModel>;
  catchOptimisticLockingFailure?: (functionPossiblyThrowingOptimisticLock: () => Promise<any>) => Promise<boolean>;
  onPersonSave(person: PersonDto): Promise<void>;
}) {
  const [isConfirmationDialogOpen, onConfirmationDialogClose, openConfirmationDialog] = useDialog<boolean>();
  const { allPending } = usePending();
  const { t } = useTranslation('person');

  const handleValid = React.useCallback(
    async (person: PersonDto) => {
      if (!hasOnlyRequiredFields(person, form.formState.dirtyFields) || (await openConfirmationDialog())) {
        await allPending();
        if (catchOptimisticLockingFailure == null) {
          await onPersonSave(person);
        } else {
          await catchOptimisticLockingFailure(() => onPersonSave(person));
        }
      }
    },
    [allPending, catchOptimisticLockingFailure, form.formState.dirtyFields, onPersonSave, openConfirmationDialog],
  );

  const confirmDialog = (
    <ConfirmDialog
      isOpen={isConfirmationDialogOpen}
      onClose={onConfirmationDialogClose}
      cancelActionLabel={t('editor.only_required_fields_warning_cancel')}
      confirmActionLabel={t('editor.only_required_fields_warning_submit')}
    >
      {t('editor.only_required_fields_warning_text')}
    </ConfirmDialog>
  );

  return { handleValid, confirmDialog };
}

function hasOnlyRequiredFields(personToSave: PersonDto, dirtyFields: FieldNamesMarkedBoolean<PersonDto>) {
  return personToSave.id == null && isEqual(dirtyFields, { firstName: true, surname: true });
}
