/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AllocationCapacityDto } from './AllocationCapacityDto';
import {
    AllocationCapacityDtoFromJSON,
    AllocationCapacityDtoFromJSONTyped,
    AllocationCapacityDtoToJSON,
} from './AllocationCapacityDto';

/**
 * Dto to show capacity of the allocations in the guest list.
 * @export
 * @interface GuestListCapacityDto
 */
export interface GuestListCapacityDto {
    /**
     * 
     * @type {Array<AllocationCapacityDto>}
     * @memberof GuestListCapacityDto
     */
    allocationCapacities: Array<AllocationCapacityDto>;
}

/**
 * Check if a given object implements the GuestListCapacityDto interface.
 */
export function instanceOfGuestListCapacityDto(value: object): boolean {
    if (!('allocationCapacities' in value)) return false;
    return true;
}

export function GuestListCapacityDtoFromJSON(json: any): GuestListCapacityDto {
    return GuestListCapacityDtoFromJSONTyped(json, false);
}

export function GuestListCapacityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuestListCapacityDto {
    if (json == null) {
        return json;
    }
    return {
        
        'allocationCapacities': ((json['allocationCapacities'] as Array<any>).map(AllocationCapacityDtoFromJSON)),
    };
}

export function GuestListCapacityDtoToJSON(value?: GuestListCapacityDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'allocationCapacities': ((value['allocationCapacities'] as Array<any>).map(AllocationCapacityDtoToJSON)),
    };
}

