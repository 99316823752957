import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { PersonBadgeDto, PersonBadgeDtoFromJSON } from '../../api';
import useToast from '../../ui/use-toast/use-toast';
import { useQrScanner } from '../../util/qr-scanner/qr-scanner';
import isSignatureValid, { parseAsSignedObject } from '../../util/validate-signature/validate-signature';

export default function usePersonScanner(
  onScanPersonBadge: (personBadge: PersonBadgeDto) => void,
  editionId?: string,
  publicKey?: string,
) {
  const { t } = useTranslation('admission');

  const showInvalidQrCodeToast = useToast({
    status: 'error',
  });

  const onDecode = (data: string) => {
    if (!isEmpty(data)) {
      try {
        invariant(publicKey != null);
        const signedObject = parseAsSignedObject(decodeURIComponent(data));
        if (!isSignatureValid(signedObject, publicKey)) {
          showInvalidQrCodeToast({ title: t('scanner.toast.failure_invalid_qr.title') });
          return;
        }
        const personBadge = PersonBadgeDtoFromJSON(JSON.parse(signedObject.payload));
        invariant(personBadge.person != null && personBadge.edition != null);
        if (editionId !== undefined && personBadge.edition !== editionId) {
          showInvalidQrCodeToast({ title: t('scanner.toast.failure_invalid_qr.title') });
          return;
        }
        onScanPersonBadge(personBadge);
      } catch (_) {
        showInvalidQrCodeToast({ title: t('scanner.toast.failure_invalid_qr.title') });
      }
    }
  };

  return useQrScanner({
    width: '100%',
    height: '100%',
    flex: '1',
    onDecode,
  });
}
