/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SumTotalDto
 */
export interface SumTotalDto {
    /**
     * 
     * @type {number}
     * @memberof SumTotalDto
     */
    netTotal: number;
    /**
     * 
     * @type {number}
     * @memberof SumTotalDto
     */
    grossTotal: number;
    /**
     * 
     * @type {number}
     * @memberof SumTotalDto
     */
    taxTotal: number;
}

/**
 * Check if a given object implements the SumTotalDto interface.
 */
export function instanceOfSumTotalDto(value: object): boolean {
    if (!('netTotal' in value)) return false;
    if (!('grossTotal' in value)) return false;
    if (!('taxTotal' in value)) return false;
    return true;
}

export function SumTotalDtoFromJSON(json: any): SumTotalDto {
    return SumTotalDtoFromJSONTyped(json, false);
}

export function SumTotalDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SumTotalDto {
    if (json == null) {
        return json;
    }
    return {
        
        'netTotal': json['netTotal'],
        'grossTotal': json['grossTotal'],
        'taxTotal': json['taxTotal'],
    };
}

export function SumTotalDtoToJSON(value?: SumTotalDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'netTotal': value['netTotal'],
        'grossTotal': value['grossTotal'],
        'taxTotal': value['taxTotal'],
    };
}

