import { useTranslation } from 'react-i18next';

export default function useAsyncNoOptionsMessage(loadOptionsOnEmptyInput: boolean) {
  const { t } = useTranslation('common');

  return (input: { inputValue: string }) => {
    if (!loadOptionsOnEmptyInput && input.inputValue === '') {
      return t('select.type_to_search');
    }
    return t('select.no_options', {
      context: input.inputValue !== '' ? 'with' : undefined,
      inputValue: input.inputValue,
    });
  };
}
