import { Button, Checkbox, HStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '../../../ui/dialog/dialog';

/**
 * Action chosen in event evaluation finish review confirmation dialog.
 */
export enum ConfirmationResult {
  FINISH_REVIEW = 'finish_review',
  ONLY_SUBMIT = 'update',
  ABORT = 'abort',
}

/**
 * Finish review confirmation dialog props.
 */
export interface FinishReviewConfirmDialogProps {
  isOpen: boolean;
  onClose: (confirm: ConfirmationResult) => void;
}

/**
 * Finish review confirmation dialog.
 */
export default function FinishReviewConfirmDialog({ isOpen, onClose }: FinishReviewConfirmDialogProps) {
  const { t } = useTranslation('event_evaluation');
  const { t: tCommon } = useTranslation('common');
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [isChecked, setChecked] = React.useState<boolean>(false);

  const onCloseWithReset = React.useCallback(
    (result: ConfirmationResult) => {
      setChecked(false);
      onClose(result);
    },
    [onClose],
  );

  return (
    <Dialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={() => onCloseWithReset(ConfirmationResult.ABORT)}
      size="2xl"
      status="warning"
      title={tCommon('misc.warning')}
      closeOnOverlayClick={false}
      footer={
        <HStack justifyContent="space-between">
          <Button ref={cancelRef} onClick={() => onCloseWithReset(ConfirmationResult.ABORT)}>
            {tCommon('action.abort')}
          </Button>
          <HStack>
            <Button onClick={() => onCloseWithReset(ConfirmationResult.ONLY_SUBMIT)} ml={3}>
              {t('confirm_dialog.review_submit_only_button')}
            </Button>
            <Button
              onClick={() => onCloseWithReset(ConfirmationResult.FINISH_REVIEW)}
              ml={3}
              variant="primary"
              isDisabled={!isChecked}
            >
              {t('confirm_dialog.review_finish_button')}
            </Button>
          </HStack>
        </HStack>
      }
    >
      <Checkbox spacing={3} onChange={(event) => setChecked(event.target.checked)}>
        {t('confirm_dialog.review_confirm_text')}
      </Checkbox>
    </Dialog>
  );
}
