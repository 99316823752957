import React from 'react';
import { GroupReferenceDto } from '../../../api';
import { groupIcon } from '../../../ui/icons/business-objects';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';

export interface GroupReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  groupReference: GroupReferenceDto;
}

export default function GroupReference({ groupReference, ...props }: GroupReferenceProps) {
  return (
    <Reference
      icon={groupIcon}
      to={`/groups/${groupReference.id}/personRelations`}
      necessaryPermission="GROUP:CAN_SEE_DETAILS"
      {...props}
    >
      {groupReference.name}
    </Reference>
  );
}
