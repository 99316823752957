/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TeamDto } from './TeamDto';
import {
    TeamDtoFromJSON,
    TeamDtoFromJSONTyped,
    TeamDtoToJSON,
} from './TeamDto';

/**
 * 
 * @export
 * @interface TeamSearchResultDto
 */
export interface TeamSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof TeamSearchResultDto
     */
    resultType: TeamSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof TeamSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {TeamDto}
     * @memberof TeamSearchResultDto
     */
    result: TeamDto;
}


/**
 * @export
 */
export const TeamSearchResultDtoResultTypeEnum = {
    TEAM: 'TEAM'
} as const;
export type TeamSearchResultDtoResultTypeEnum = typeof TeamSearchResultDtoResultTypeEnum[keyof typeof TeamSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the TeamSearchResultDto interface.
 */
export function instanceOfTeamSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function TeamSearchResultDtoFromJSON(json: any): TeamSearchResultDto {
    return TeamSearchResultDtoFromJSONTyped(json, false);
}

export function TeamSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': TeamDtoFromJSON(json['result']),
    };
}

export function TeamSearchResultDtoToJSON(value?: TeamSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': TeamDtoToJSON(value['result']),
    };
}

