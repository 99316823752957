import { EventTypeDto } from '../../../api';

export const enum EventSupertype {
  MOVIE = 'MOVIE',
  MEETING = 'MEETING',
  NETWORKING = 'NETWORKING',
  AWARD_CEREMONY = 'AWARD_CEREMONY',
  PRESS_LAUNCH = 'PRESS_LAUNCH',
  PODIUM_DISCUSSION = 'PODIUM_DISCUSSION',
}

export const eventTypeOptions = [
  EventSupertype.MOVIE,
  EventSupertype.MEETING,
  EventSupertype.NETWORKING,
  EventSupertype.AWARD_CEREMONY,
  EventSupertype.PRESS_LAUNCH,
  EventSupertype.PODIUM_DISCUSSION,
];

export function actualEventTypeOptions(forSupertype: EventSupertype): EventTypeDto[] {
  return Object.entries(eventSupertypeOfEventType)
    .filter(([, supertype]) => supertype === forSupertype)
    .map(([type]) => type as EventTypeDto);
}

export const eventSupertypeOfEventType: Record<EventTypeDto, EventSupertype> = {
  AWARD_CEREMONY_BERLINALE: EventSupertype.AWARD_CEREMONY,
  AWARD_CEREMONY_MISC: EventSupertype.AWARD_CEREMONY,
  MEETING_GROUP_MEETING: EventSupertype.MEETING,
  MEETING_MEET_UP: EventSupertype.MEETING,
  MEETING_ONE_ON_ONE: EventSupertype.MEETING,
  MEETING_ROUND_TABLE: EventSupertype.MEETING,
  MEETING_THINK_TANK: EventSupertype.MEETING,
  MEETING_WORKSHOP: EventSupertype.MEETING,
  MOVIE_SCREENING_GALA_PREMIERE: EventSupertype.MOVIE,
  MOVIE_SCREENING_PREMIERE: EventSupertype.MOVIE,
  MOVIE_SCREENING_REPETITION: EventSupertype.MOVIE,
  NETWORKING_DINNER: EventSupertype.NETWORKING,
  NETWORKING_RECEPTION: EventSupertype.NETWORKING,
  NETWORKING_HAPPY_HOUR: EventSupertype.NETWORKING,
  NETWORKING_LOUNGE: EventSupertype.NETWORKING,
  NETWORKING_LUNCH: EventSupertype.NETWORKING,
  NETWORKING_PARTY: EventSupertype.NETWORKING,
  NETWORKING_WARM_UP: EventSupertype.NETWORKING,
  PODIUM_DISCUSSION_LIVE_WORKSHOP: EventSupertype.PODIUM_DISCUSSION,
  PODIUM_DISCUSSION_PITCH: EventSupertype.PODIUM_DISCUSSION,
  PODIUM_DISCUSSION_PRESENTATION: EventSupertype.PODIUM_DISCUSSION,
  PODIUM_DISCUSSION_SHOW_CASE: EventSupertype.PODIUM_DISCUSSION,
  PODIUM_DISCUSSION_TALK: EventSupertype.PODIUM_DISCUSSION,
  PRESS_LAUNCH_DIRECTORS_TALK: EventSupertype.PRESS_LAUNCH,
  PRESS_LAUNCH_INTERVIEW: EventSupertype.PRESS_LAUNCH,
  PRESS_LAUNCH_PRESS_CONFERENCE: EventSupertype.PRESS_LAUNCH,
};
