/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccessGroupReferenceDto } from './AccessGroupReferenceDto';
import {
    AccessGroupReferenceDtoFromJSON,
    AccessGroupReferenceDtoFromJSONTyped,
    AccessGroupReferenceDtoToJSON,
} from './AccessGroupReferenceDto';
import type { SectionEmailAddressDto } from './SectionEmailAddressDto';
import {
    SectionEmailAddressDtoFromJSON,
    SectionEmailAddressDtoFromJSONTyped,
    SectionEmailAddressDtoToJSON,
} from './SectionEmailAddressDto';
import type { SectionPhoneNumberDto } from './SectionPhoneNumberDto';
import {
    SectionPhoneNumberDtoFromJSON,
    SectionPhoneNumberDtoFromJSONTyped,
    SectionPhoneNumberDtoToJSON,
} from './SectionPhoneNumberDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * A section used for the Berlinale festival.
 * @export
 * @interface SectionDto
 */
export interface SectionDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof SectionDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof SectionDto
     */
    version?: VersionDto;
    /**
     * The name of the section.
     * @type {string}
     * @memberof SectionDto
     */
    name: string;
    /**
     * The english name of the section.
     * @type {string}
     * @memberof SectionDto
     */
    englishName: string;
    /**
     * 
     * @type {string}
     * @memberof SectionDto
     */
    abbreviation: string;
    /**
     * List of email addresses
     * @type {Array<SectionEmailAddressDto>}
     * @memberof SectionDto
     */
    emailAddresses: Array<SectionEmailAddressDto>;
    /**
     * List of phone numbers
     * @type {Array<SectionPhoneNumberDto>}
     * @memberof SectionDto
     */
    phoneNumbers: Array<SectionPhoneNumberDto>;
    /**
     * Status of the section.
     * @type {string}
     * @memberof SectionDto
     */
    status: SectionDtoStatusEnum;
    /**
     * List of access groups
     * @type {Array<AccessGroupReferenceDto>}
     * @memberof SectionDto
     */
    standardAccessGroups: Array<AccessGroupReferenceDto>;
    /**
     * Program-IDs in FF which are mapped to this section when importing Films from FF
     * @type {Array<number>}
     * @memberof SectionDto
     */
    ffProgramIds: Array<number>;
}


/**
 * @export
 */
export const SectionDtoStatusEnum = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
} as const;
export type SectionDtoStatusEnum = typeof SectionDtoStatusEnum[keyof typeof SectionDtoStatusEnum];


/**
 * Check if a given object implements the SectionDto interface.
 */
export function instanceOfSectionDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('englishName' in value)) return false;
    if (!('abbreviation' in value)) return false;
    if (!('emailAddresses' in value)) return false;
    if (!('phoneNumbers' in value)) return false;
    if (!('status' in value)) return false;
    if (!('standardAccessGroups' in value)) return false;
    if (!('ffProgramIds' in value)) return false;
    return true;
}

export function SectionDtoFromJSON(json: any): SectionDto {
    return SectionDtoFromJSONTyped(json, false);
}

export function SectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'name': json['name'],
        'englishName': json['englishName'],
        'abbreviation': json['abbreviation'],
        'emailAddresses': ((json['emailAddresses'] as Array<any>).map(SectionEmailAddressDtoFromJSON)),
        'phoneNumbers': ((json['phoneNumbers'] as Array<any>).map(SectionPhoneNumberDtoFromJSON)),
        'status': json['status'],
        'standardAccessGroups': ((json['standardAccessGroups'] as Array<any>).map(AccessGroupReferenceDtoFromJSON)),
        'ffProgramIds': json['ffProgramIds'],
    };
}

export function SectionDtoToJSON(value?: SectionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'name': value['name'],
        'englishName': value['englishName'],
        'abbreviation': value['abbreviation'],
        'emailAddresses': ((value['emailAddresses'] as Array<any>).map(SectionEmailAddressDtoToJSON)),
        'phoneNumbers': ((value['phoneNumbers'] as Array<any>).map(SectionPhoneNumberDtoToJSON)),
        'status': value['status'],
        'standardAccessGroups': ((value['standardAccessGroups'] as Array<any>).map(AccessGroupReferenceDtoToJSON)),
        'ffProgramIds': value['ffProgramIds'],
    };
}

