/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Reference of a merged object
 * @export
 * @interface MergedHistoryObjectDto
 */
export interface MergedHistoryObjectDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof MergedHistoryObjectDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MergedHistoryObjectDto
     */
    displayName: string;
}

/**
 * Check if a given object implements the MergedHistoryObjectDto interface.
 */
export function instanceOfMergedHistoryObjectDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('displayName' in value)) return false;
    return true;
}

export function MergedHistoryObjectDtoFromJSON(json: any): MergedHistoryObjectDto {
    return MergedHistoryObjectDtoFromJSONTyped(json, false);
}

export function MergedHistoryObjectDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MergedHistoryObjectDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'displayName': json['displayName'],
    };
}

export function MergedHistoryObjectDtoToJSON(value?: MergedHistoryObjectDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'displayName': value['displayName'],
    };
}

