import { MenuItem } from '@chakra-ui/react';
import { faCashRegister } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { CompanyDto } from '../../../api';
import { CompanyActionExtension } from '../../company/company-action-extension';
import { Permission } from '../../permission/use-permission';
import { InvoiceRefundTaxButton } from './invoice-refund-tax-button';

export const invoiceCompanyActionItem: CompanyActionExtension = {
  item: (company: CompanyDto, hasPermission: (necessaryPermission: Permission) => boolean) =>
    hasPermission('INVOICE:CAN_REFUND_TAX') && (
      <MenuItem
        as={InvoiceRefundTaxButton}
        icon={<FontAwesomeIcon icon={faCashRegister} fixedWidth />}
        company={company}
        key="InvoiceRefundTaxButton"
      />
    ),
};
