/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CodeDto,
  CodePageDto,
  CreateCodeDto,
  FailureDto,
  HistoryPageDto,
  InvalidateCodesRequestDto,
  PageableDto,
  SendCodesRequestDto,
} from '../models/index';
import {
    CodeDtoFromJSON,
    CodeDtoToJSON,
    CodePageDtoFromJSON,
    CodePageDtoToJSON,
    CreateCodeDtoFromJSON,
    CreateCodeDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    InvalidateCodesRequestDtoFromJSON,
    InvalidateCodesRequestDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    SendCodesRequestDtoFromJSON,
    SendCodesRequestDtoToJSON,
} from '../models/index';

export interface CreateCodesRequest {
    createCodeDto: CreateCodeDto;
}

export interface FetchCodeRequest {
    id: string;
}

export interface FetchCodeByOrderRequest {
    id: string;
}

export interface FetchCodeHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface InvalidateCodesRequest {
    invalidateCodesRequestDto: InvalidateCodesRequestDto;
}

export interface SearchCodesRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SendCodesRequest {
    sendCodesRequestDto: SendCodesRequestDto;
}

export interface UpdateCodeRequest {
    id: string;
    codeDto: CodeDto;
}

/**
 * 
 */
export class CodeApi extends runtime.BaseAPI {

    /**
     * Create a new code.
     */
    async createCodesRaw(requestParameters: CreateCodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['createCodeDto'] == null) {
            throw new runtime.RequiredError(
                'createCodeDto',
                'Required parameter "createCodeDto" was null or undefined when calling createCodes().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/codes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCodeDtoToJSON(requestParameters['createCodeDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new code.
     */
    async createCodes(requestParameters: CreateCodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createCodesRaw(requestParameters, initOverrides);
    }

    /**
     * Get the code business object.
     */
    async fetchCodeRaw(requestParameters: FetchCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CodeDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchCode().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/codes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CodeDtoFromJSON(jsonValue));
    }

    /**
     * Get the code business object.
     */
    async fetchCode(requestParameters: FetchCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CodeDto> {
        const response = await this.fetchCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the code business object.
     */
    async fetchCodeByOrderRaw(requestParameters: FetchCodeByOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CodeDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchCodeByOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/codes/code-by-order/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CodeDtoFromJSON(jsonValue));
    }

    /**
     * Get the code business object.
     */
    async fetchCodeByOrder(requestParameters: FetchCodeByOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CodeDto | null | undefined > {
        const response = await this.fetchCodeByOrderRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Get the history of the code.
     */
    async fetchCodeHistoryRaw(requestParameters: FetchCodeHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchCodeHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/codes/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the code.
     */
    async fetchCodeHistory(requestParameters: FetchCodeHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchCodeHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Make the codes invalid.
     */
    async invalidateCodesRaw(requestParameters: InvalidateCodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['invalidateCodesRequestDto'] == null) {
            throw new runtime.RequiredError(
                'invalidateCodesRequestDto',
                'Required parameter "invalidateCodesRequestDto" was null or undefined when calling invalidateCodes().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/codes/invalidate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvalidateCodesRequestDtoToJSON(requestParameters['invalidateCodesRequestDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Make the codes invalid.
     */
    async invalidateCodes(requestParameters: InvalidateCodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.invalidateCodesRaw(requestParameters, initOverrides);
    }

    /**
     * Search for codes.
     */
    async searchCodesRaw(requestParameters: SearchCodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CodePageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/codes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CodePageDtoFromJSON(jsonValue));
    }

    /**
     * Search for codes.
     */
    async searchCodes(requestParameters: SearchCodesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CodePageDto> {
        const response = await this.searchCodesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send codes.
     */
    async sendCodesRaw(requestParameters: SendCodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['sendCodesRequestDto'] == null) {
            throw new runtime.RequiredError(
                'sendCodesRequestDto',
                'Required parameter "sendCodesRequestDto" was null or undefined when calling sendCodes().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/codes/send`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendCodesRequestDtoToJSON(requestParameters['sendCodesRequestDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send codes.
     */
    async sendCodes(requestParameters: SendCodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendCodesRaw(requestParameters, initOverrides);
    }

    /**
     * Fetch the template for creating a new code.
     */
    async templateCodeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CodeDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/codes/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CodeDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new code.
     */
    async templateCode(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CodeDto> {
        const response = await this.templateCodeRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the existing code.
     */
    async updateCodeRaw(requestParameters: UpdateCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CodeDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateCode().'
            );
        }

        if (requestParameters['codeDto'] == null) {
            throw new runtime.RequiredError(
                'codeDto',
                'Required parameter "codeDto" was null or undefined when calling updateCode().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/codes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CodeDtoToJSON(requestParameters['codeDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CodeDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing code.
     */
    async updateCode(requestParameters: UpdateCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CodeDto> {
        const response = await this.updateCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
