import { Grid, GridProps } from '@chakra-ui/react';

interface GridPageProps extends GridProps {}

/**
 * Page Layout based on a CSS Grid. Defaults to two rows spanning the entire height.
 * (Header and scrollable Content)
 */
export default function Page({ children, gridTemplateRows = 'auto 1fr', height = '100vh', ...props }: GridPageProps) {
  return (
    <Grid gridTemplateRows={gridTemplateRows} height={height} {...props}>
      {children}
    </Grid>
  );
}
