import useSWRImmutable from 'swr/immutable';
import invariant from 'tiny-invariant';
import editionApi from '../../../data-access/edition-api';

export default function useActiveEdition() {
  const { data: edition } = useSWRImmutable('activeEdition', () => editionApi.activeEdition(), {
    suspense: true,
  });
  invariant(edition != null);

  return edition;
}
