/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PlacementTemplateDto } from './PlacementTemplateDto';
import {
    PlacementTemplateDtoFromJSON,
    PlacementTemplateDtoFromJSONTyped,
    PlacementTemplateDtoToJSON,
} from './PlacementTemplateDto';
import type { SeatAssignmentDto } from './SeatAssignmentDto';
import {
    SeatAssignmentDtoFromJSON,
    SeatAssignmentDtoFromJSONTyped,
    SeatAssignmentDtoToJSON,
} from './SeatAssignmentDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface PlacementDto
 */
export interface PlacementDto {
    /**
     * 
     * @type {PlacementTemplateDto}
     * @memberof PlacementDto
     */
    template?: PlacementTemplateDto;
    /**
     * 
     * @type {Array<SeatAssignmentDto>}
     * @memberof PlacementDto
     */
    seatAssignments?: Array<SeatAssignmentDto>;
    /**
     * 
     * @type {VersionDto}
     * @memberof PlacementDto
     */
    version?: VersionDto;
}

/**
 * Check if a given object implements the PlacementDto interface.
 */
export function instanceOfPlacementDto(value: object): boolean {
    return true;
}

export function PlacementDtoFromJSON(json: any): PlacementDto {
    return PlacementDtoFromJSONTyped(json, false);
}

export function PlacementDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlacementDto {
    if (json == null) {
        return json;
    }
    return {
        
        'template': json['template'] == null ? undefined : PlacementTemplateDtoFromJSON(json['template']),
        'seatAssignments': json['seatAssignments'] == null ? undefined : ((json['seatAssignments'] as Array<any>).map(SeatAssignmentDtoFromJSON)),
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
    };
}

export function PlacementDtoToJSON(value?: PlacementDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'template': PlacementTemplateDtoToJSON(value['template']),
        'seatAssignments': value['seatAssignments'] == null ? undefined : ((value['seatAssignments'] as Array<any>).map(SeatAssignmentDtoToJSON)),
        'version': VersionDtoToJSON(value['version']),
    };
}

