import { Button, Flex, Grid, Heading, HStack, Spacer, useDisclosure } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { FormatStatusDto } from '../../../../api';
import { DisableableButton } from '../../../../ui/disableable-button/disableable-button';
import PageContent from '../../../../ui/page/page-content';
import useFetcher from '../../../../util/swr/use-fetcher';
import { fetchFormat } from '../../../format/format-queries';
import usePermission from '../../../permission/use-permission';
import FormatCompanyEditorDialog from '../format-company-editor/format-company-editor-dialog';
import FormatCompanyViewer from './format-company-viewer';

export default function FormatCompanyPageContent() {
  const { t } = useTranslation('company');
  const { formatId } = useParams<{ formatId: string }>();
  invariant(formatId, 'Missing formatId');
  const format = useFetcher(fetchFormat, { id: formatId });
  const formatReference = { id: format.id, name: format.name };
  const { isOpen: editDialogIsOpen, onOpen: onEditDialogOpen, onClose: onEditDialogClose } = useDisclosure();
  const { hasPermission } = usePermission();

  return (
    <PageContent useFullWidth>
      <Grid gridRowGap={6} maxH="full" gridTemplateRows="auto 1fr">
        <Flex as="header">
          <HStack spacing={4}>
            <Heading size="md" as="h3">
              {t('lister.title')}
            </Heading>
          </HStack>
          <Spacer />
          {hasPermission('COMPANY-FORMAT:CAN_LINK') && (
            <Button
              as={DisableableButton}
              variant="primary"
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
              onClick={onEditDialogOpen}
              aria-label={t('formatConnections.add')}
              isDisabled={format.status !== FormatStatusDto.ACTIVE}
              disableReason={t('formatConnections.disabled')}
            >
              {t('formatConnections.add')}
            </Button>
          )}
        </Flex>
        <FormatCompanyViewer format={format} />
        <FormatCompanyEditorDialog
          formatReference={formatReference}
          isOpen={editDialogIsOpen}
          onClose={onEditDialogClose}
        />
      </Grid>
    </PageContent>
  );
}
