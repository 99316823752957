/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LandingPageDesignDto } from './LandingPageDesignDto';
import {
    LandingPageDesignDtoFromJSON,
    LandingPageDesignDtoFromJSONTyped,
    LandingPageDesignDtoToJSON,
} from './LandingPageDesignDto';

/**
 * 
 * @export
 * @interface LandingPageDesignSearchResultDto
 */
export interface LandingPageDesignSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof LandingPageDesignSearchResultDto
     */
    resultType: LandingPageDesignSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof LandingPageDesignSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {LandingPageDesignDto}
     * @memberof LandingPageDesignSearchResultDto
     */
    result: LandingPageDesignDto;
}


/**
 * @export
 */
export const LandingPageDesignSearchResultDtoResultTypeEnum = {
    LANDING_PAGE_DESIGN: 'LANDING_PAGE_DESIGN'
} as const;
export type LandingPageDesignSearchResultDtoResultTypeEnum = typeof LandingPageDesignSearchResultDtoResultTypeEnum[keyof typeof LandingPageDesignSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the LandingPageDesignSearchResultDto interface.
 */
export function instanceOfLandingPageDesignSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function LandingPageDesignSearchResultDtoFromJSON(json: any): LandingPageDesignSearchResultDto {
    return LandingPageDesignSearchResultDtoFromJSONTyped(json, false);
}

export function LandingPageDesignSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageDesignSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': LandingPageDesignDtoFromJSON(json['result']),
    };
}

export function LandingPageDesignSearchResultDtoToJSON(value?: LandingPageDesignSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': LandingPageDesignDtoToJSON(value['result']),
    };
}

