import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PersonAccessGroupRelationDto } from '../../../../api';
import HistoryDisplaySettings from '../../../../ui/history/history-display-settings';

export default function usePersonAccessGroupHistorySettings(): HistoryDisplaySettings<PersonAccessGroupRelationDto> {
  const { t } = useTranslation(['access_group', 'edition', 'person']);

  return useMemo<HistoryDisplaySettings<PersonAccessGroupRelationDto>>(() => {
    return {
      attributeLabels: {
        person: t('person:person'),
        edition: t('edition:edition'),
      },
      valueFormatter: {
        person: (value) => (value == null ? undefined : `${value.firstName} ${value.surname} (${value.personKey})`),
        edition: (value) => (value == null ? undefined : value.name),
      },
      diffEntireWord: {
        person: true,
        edition: true,
      },
      name: () => t('access_group:connections.person'),
      showEditDescription: false,
    };
  }, [t]);
}
