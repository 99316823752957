import { chakra, HStack, Text } from '@chakra-ui/react';
import React from 'react';

interface LabelProps {
  id?: string;
  isRequired?: boolean;
  children?: React.ReactNode;
}

export default function Label({ id, children, isRequired = false }: LabelProps) {
  return (
    <HStack id={id} alignItems="flex-start" spacing={1}>
      <Text as="div" display="block" textAlign="start" fontSize="md" fontWeight="medium">
        {children}
      </Text>
      {isRequired && (
        <chakra.span role="presentation" aria-hidden="true" color="text.error" fontWeight={500}>
          *
        </chakra.span>
      )}
    </HStack>
  );
}
