import React from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchAccessGroup } from '../access-group-queries';

export default function AccessGroupBreadcrumb() {
  const { accessGroupId } = useParams<{ accessGroupId: string }>();
  invariant(accessGroupId, 'Empty accessGroupId');
  const accessGroup = useFetcher(fetchAccessGroup, { id: accessGroupId });

  return <>{`${accessGroup.title}`}</>;
}
