import { produce } from 'immer';
import React from 'react';
import { Location, NavigationType, useLocation, useNavigationType } from 'react-router-dom';
import useDefinedContext from '../context/use-defined-context/use-defined-context';

export default function useHistoryBack() {
  const locations = useDefinedContext(HistoryBackContext);
  const lastLocation = locations[locations.length - 2];
  return React.useCallback((fallbackPath: string) => lastLocation ?? fallbackPath, [lastLocation]);
}

export const HistoryBackContext = React.createContext<Location[] | undefined>(undefined);

interface HistoryBackProviderProps {
  children: React.ReactNode;
}

const reducer = (
  state: Location[],
  { navigationType, location }: { navigationType: NavigationType; location: Location },
) => {
  switch (navigationType) {
    case NavigationType.Push:
      return produce(state, (draftState) => {
        draftState.push(location);
      });
    case NavigationType.Pop:
      return produce(state, (draftState) => {
        draftState.pop();
      });
    case NavigationType.Replace:
      return produce(state, (draftState) => {
        draftState.pop();
        draftState.push(location);
      });
  }
};

export const HISTORY_ABORT = 'abort';

export function HistoryBackProvider({ children }: HistoryBackProviderProps) {
  const location = useLocation();
  const navigationType = useNavigationType();
  const [locations, dispatch] = React.useReducer(reducer, [location]);

  React.useEffect(() => {
    // There is a strange behavior in react-router-dom where the initial location gets popped
    // from the history stack. This leads to the initial location being removed from the
    // history stack. We don't want that, so let's ignore it.
    if (navigationType === NavigationType.Pop && location.key === 'default') {
      return;
    }

    dispatch({
      // Use navigation type Pop when the user navigates back via an abort button.
      navigationType: location.state === HISTORY_ABORT ? NavigationType.Pop : navigationType,
      location,
    });
  }, [navigationType, location]);

  return <HistoryBackContext.Provider value={locations}>{children}</HistoryBackContext.Provider>;
}
