/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GroupTemplateOwnerDto } from './GroupTemplateOwnerDto';
import {
    GroupTemplateOwnerDtoFromJSON,
    GroupTemplateOwnerDtoFromJSONTyped,
    GroupTemplateOwnerDtoToJSON,
} from './GroupTemplateOwnerDto';
import type { GroupTemplateStatusDto } from './GroupTemplateStatusDto';
import {
    GroupTemplateStatusDtoFromJSON,
    GroupTemplateStatusDtoFromJSONTyped,
    GroupTemplateStatusDtoToJSON,
} from './GroupTemplateStatusDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * A template for a group.
 * @export
 * @interface GroupTemplateDto
 */
export interface GroupTemplateDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof GroupTemplateDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof GroupTemplateDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof GroupTemplateDto
     */
    name: string;
    /**
     * 
     * @type {Array<GroupTemplateOwnerDto>}
     * @memberof GroupTemplateDto
     */
    owners: Array<GroupTemplateOwnerDto>;
    /**
     * 
     * @type {number}
     * @memberof GroupTemplateDto
     */
    capacity?: number;
    /**
     * 
     * @type {GroupTemplateStatusDto}
     * @memberof GroupTemplateDto
     */
    status: GroupTemplateStatusDto;
}

/**
 * Check if a given object implements the GroupTemplateDto interface.
 */
export function instanceOfGroupTemplateDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('owners' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function GroupTemplateDtoFromJSON(json: any): GroupTemplateDto {
    return GroupTemplateDtoFromJSONTyped(json, false);
}

export function GroupTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupTemplateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'name': json['name'],
        'owners': ((json['owners'] as Array<any>).map(GroupTemplateOwnerDtoFromJSON)),
        'capacity': json['capacity'] == null ? undefined : json['capacity'],
        'status': GroupTemplateStatusDtoFromJSON(json['status']),
    };
}

export function GroupTemplateDtoToJSON(value?: GroupTemplateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'name': value['name'],
        'owners': ((value['owners'] as Array<any>).map(GroupTemplateOwnerDtoToJSON)),
        'capacity': value['capacity'],
        'status': GroupTemplateStatusDtoToJSON(value['status']),
    };
}

