/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  FilmDto,
  FilmPageDto,
  FilmReferencePageDto,
  PageableDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    FilmDtoFromJSON,
    FilmDtoToJSON,
    FilmPageDtoFromJSON,
    FilmPageDtoToJSON,
    FilmReferencePageDtoFromJSON,
    FilmReferencePageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface FetchFilmRequest {
    id: string;
}

export interface SearchFilmListItemsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchFilmReferencesRequest {
    q?: string;
    country?: string;
    pageable?: PageableDto;
}

/**
 * 
 */
export class FilmApi extends runtime.BaseAPI {

    /**
     * Fetch a single Film by id
     */
    async fetchFilmRaw(requestParameters: FetchFilmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilmDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchFilm().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/films/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilmDtoFromJSON(jsonValue));
    }

    /**
     * Fetch a single Film by id
     */
    async fetchFilm(requestParameters: FetchFilmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilmDto> {
        const response = await this.fetchFilmRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for film list items.
     */
    async searchFilmListItemsRaw(requestParameters: SearchFilmListItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilmPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/films`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilmPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for film list items.
     */
    async searchFilmListItems(requestParameters: SearchFilmListItemsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilmPageDto> {
        const response = await this.searchFilmListItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for films.
     */
    async searchFilmReferencesRaw(requestParameters: SearchFilmReferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilmReferencePageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['country'] != null) {
            queryParameters['country'] = requestParameters['country'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/films/references`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilmReferencePageDtoFromJSON(jsonValue));
    }

    /**
     * Search for films.
     */
    async searchFilmReferences(requestParameters: SearchFilmReferencesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilmReferencePageDto> {
        const response = await this.searchFilmReferencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
