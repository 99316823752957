import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import Translate from '../../util/translate/translate';
import { EventBreadcrumb, eventRoutesWithPrefix } from '../event/event-routes';
import useEvent from '../event/use-event/use-event';

const AdmissionGuestlistPage = React.lazy(
  () => import('./admission-guestlist/admission-guestlist-page/admission-guestlist-page'),
);
const AdmissionLiveTicketingPage = React.lazy(
  () => import('./admission-live-ticketing/admission-live-ticketing-page/admission-live-ticketing-page'),
);

const admissionRoutesMobile = eventRoutesWithPrefix([
  {
    path: ':eventId',
    handle: {
      breadcrumb: <EventBreadcrumb />,
    },
    children: [
      {
        path: 'guest-list',
        handle: {
          breadcrumb: <Translate ns="guest_list" i18nKey="admission" />,
          helmet: <AdmissionHelmet />,
        },
        children: [
          {
            path: '',
            element: <AdmissionGuestlistPage />,
          },
        ],
      },
      {
        path: 'live-ticketing',
        handle: {
          breadcrumb: <Translate ns="live_ticketing" i18nKey="live_ticketing_admission" />,
          helmet: <LiveTicketingHelmet />,
        },
        children: [
          {
            path: '',
            element: <AdmissionLiveTicketingPage />,
          },
        ],
      },
    ],
  },
]);

export default admissionRoutesMobile;

function AdmissionHelmet() {
  const { t } = useTranslation('admission');
  const params = useParams<{ eventId: string }>();
  invariant(params.eventId, 'Empty eventId');
  const event = useEvent(params.eventId);

  return <Helmet title={t('page.helmet', { event: event.title })} />;
}

function LiveTicketingHelmet() {
  const { t } = useTranslation('live_ticketing');
  const params = useParams<{ eventId: string }>();
  invariant(params.eventId, 'Empty eventId');
  const event = useEvent(params.eventId);

  return <Helmet title={t('liveTicketingAdmissionHelmet', { event: event.title })} />;
}
