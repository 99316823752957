import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import useFetcher from '../../util/swr/use-fetcher';
import { getLandingPage } from './landing-page-queries';

export default function LandingPageBreadcrumb() {
  const { landingPageId: id } = useParams<{ landingPageId: string }>();
  invariant(id);
  return useFetcher(getLandingPage, { id }).landingPage.name;
}
