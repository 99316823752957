/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InvoiceStatusDto = {
    PAID: 'PAID',
    PAID_AND_SEND: 'PAID_AND_SEND',
    CANCELLED: 'CANCELLED',
    CREATED: 'CREATED',
    DOCUMENT_GENERATED: 'DOCUMENT_GENERATED'
} as const;
export type InvoiceStatusDto = typeof InvoiceStatusDto[keyof typeof InvoiceStatusDto];


export function InvoiceStatusDtoFromJSON(json: any): InvoiceStatusDto {
    return InvoiceStatusDtoFromJSONTyped(json, false);
}

export function InvoiceStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceStatusDto {
    return json as InvoiceStatusDto;
}

export function InvoiceStatusDtoToJSON(value?: InvoiceStatusDto | null): any {
    return value as any;
}

