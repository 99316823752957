import { Center, HStack, Link, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link as RouterLink } from 'react-router-dom';
import { EditionReferenceDto } from '../../api';
import { editionIcon } from '../../ui/icons/business-objects';

interface EditionReferenceProps {
  edition: EditionReferenceDto;
}

export default function EditionReference({ edition }: EditionReferenceProps) {
  return (
    <Link as={RouterLink} display="inline-block" to={'/editions/' + edition.id}>
      <HStack>
        <Center>
          <FontAwesomeIcon icon={editionIcon} />
        </Center>
        <Text as="span" fontWeight="medium" noOfLines={1} maxWidth="240">
          {edition.name}
        </Text>
      </HStack>
    </Link>
  );
}
