import React from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RecipientLinkDto } from '../../../../api';
import recipientApi from '../../../../data-access/recipient-api';
import FormControl from '../../../../ui/form/form-control';
import MultiValueAsyncSelect from '../../../../ui/select/multi-value-async-select';
import { isPersonRecipientLink } from '../../mailing-recipient-util/person-recipient-link';

export interface AddPersonRecipient {
  personRecipients: Array<RecipientLinkDto>;
}

export default function PersonMailingRecipientControl({ mailingId }: { mailingId: string }) {
  const { field } = useController<AddPersonRecipient, 'personRecipients'>({
    name: 'personRecipients',
    defaultValue: [],
    rules: {
      validate(links) {
        if (links.length > 10) {
          return t('recipients.max_person_recipients_error', { count: 10 });
        }
      },
    },
  });
  const { t } = useTranslation('mailing');

  return (
    <FormControl<AddPersonRecipient>
      label={t('recipients.person')}
      helperText={t('recipients.max_person_recipients_helper', { count: 10 })}
      name="personRecipients"
    >
      <MultiValueAsyncSelect<RecipientLinkDto>
        {...field}
        values={field.value}
        loadOptions={async (value: string, size: number) => {
          const { content } = await recipientApi.fetchPossibleRecipients({
            id: mailingId,
            pageable: { size },
            q: value,
            recipientType: 'PERSON',
          });
          return content;
        }}
        defaultOptions={false}
        renderLabel={(person) =>
          isPersonRecipientLink(person) && `${person.linkedRecipient.firstName} ${person.linkedRecipient.surname}`
        }
        optionIdentifier={(person) => person.id!}
      />
    </FormControl>
  );
}
