import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { evaluationIcon, eventIcon } from '../../ui/icons/business-objects';
import EventReference from '../event/event-reference/event-reference';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';
import SearchResultConnection from '../search/search-result-connection';

export default function EventEvaluationSearchResult({ result, onClick }: SearchResultProps) {
  invariant(
    result.resultType === 'EVENT_EVALUATION',
    `${result.resultType} is no instance of EventEvaluationSearchResult`,
  );

  const evaluation = result.evaluation;
  const { t } = useTranslation(['event_evaluation', 'common']);

  return (
    <SearchResult
      icon={evaluationIcon}
      title={t('event_evaluation:title_view', { event: result.event.title })}
      link={`/events/${result.event.id}/evaluation`}
      score={result.score}
      onClick={onClick}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('event_evaluation:status.label'),
            element: t(`event_evaluation:statusOptions.${evaluation.status}`),
          },
          {
            label: t('event_evaluation:realisation.label'),
            element: t(`event_evaluation:realisationLabels.${evaluation.realisation}`),
          },
          {
            label: t('event_evaluation:realisation.delayed_start'),
            element: t('common:format.date_time', { dateTime: evaluation.delayedDateTimeRange?.start }),
          },
          {
            label: t('event_evaluation:realisation.delayed_end'),
            element: t('common:format.date_time', { dateTime: evaluation.delayedDateTimeRange?.end }),
          },
          {
            label: t('event_evaluation:tickets_issued.label'),
            element: evaluation.ticketsIssued?.toString(),
          },
          {
            label: t('event_evaluation:access.with_ticket'),
            element: evaluation.accessWithTicket?.toString(),
          },
          {
            label: t('event_evaluation:access.without_ticket'),
            element: evaluation.accessWithoutTicket?.toString(),
          },
          {
            label: t('event_evaluation:occupancy.label'),
            element: evaluation.occupancy?.toString(),
          },
          {
            label: t('event_evaluation:technical_disruption.label'),
            element: evaluation.technicalDisruption
              ?.map((td) => t(`event_evaluation:technicalDisruptionOptions.${td}`))
              .join(', '),
          },
          {
            label: t('event_evaluation:audience.entry.label'),
            element: t(`event_evaluation:audienceEntryOptions.${evaluation.audienceEntry}`),
          },
          {
            label: t('event_evaluation:audience.exit.label'),
            element: t(`event_evaluation:audienceEntryOptions.${evaluation.audienceExit}`),
          },
          {
            label: t('event_evaluation:film_team.label'),
            element: t(`event_evaluation:filmTeamOptions.${evaluation.filmTeam}`),
          },
          {
            label: t('event_evaluation:further_information.label'),
            element: evaluation.furtherInformation,
          },
        ]}
      />
      <SearchResultConnection targetIcon={eventIcon} onTargetClick={onClick}>
        <EventReference event={result.event} hideIcon />
      </SearchResultConnection>
    </SearchResult>
  );
}
