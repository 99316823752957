import { Trans, useTranslation } from 'react-i18next';
import { EditionImplicationDto } from '../../../api';
import StringDiff from '../../../ui/diff/string-diff';
import EditionImplicationType from '../edition-implication/edition-implication-type';

interface EditionWillChangeProps {
  implication: EditionImplicationDto;
  type: EditionImplicationType;
}

export default function EditionImplicationWillChange({ implication, type }: EditionWillChangeProps) {
  const { t } = useTranslation(['common', 'edition']);

  if (type === EditionImplicationType.DATE_RANGE) {
    return (
      <Trans
        t={t}
        i18nKey="edition:editor.implication.will_change_date_range"
        values={{ from: implication.affected.dateRange, to: implication.dateRange }}
        components={{
          from: (
            <StringDiff
              to={t('common:format.date_range', {
                dateRange: implication.dateRange,
              })}
            />
          ),
          to: (
            <StringDiff
              from={t('common:format.date_range', {
                dateRange: implication.affected.dateRange,
              })}
            />
          ),
        }}
      />
    );
  }

  return (
    <Trans
      t={t}
      i18nKey="edition:editor.implication.will_change_name"
      values={{ from: implication.affected.name, to: implication.name }}
      components={{
        from: <StringDiff to={implication.name} />,
        to: <StringDiff from={implication.affected.name} />,
      }}
    />
  );
}
