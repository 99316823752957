/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateRangeDto } from './ClosedDateRangeDto';
import {
    ClosedDateRangeDtoFromJSON,
    ClosedDateRangeDtoFromJSONTyped,
    ClosedDateRangeDtoToJSON,
} from './ClosedDateRangeDto';
import type { EditionDto } from './EditionDto';
import {
    EditionDtoFromJSON,
    EditionDtoFromJSONTyped,
    EditionDtoToJSON,
} from './EditionDto';

/**
 * Describes affected edition and what kind of implication will happen, either date range or name or both.
 * @export
 * @interface EditionImplicationDto
 */
export interface EditionImplicationDto {
    /**
     * 
     * @type {EditionDto}
     * @memberof EditionImplicationDto
     */
    affected: EditionDto;
    /**
     * 
     * @type {ClosedDateRangeDto}
     * @memberof EditionImplicationDto
     */
    dateRange?: ClosedDateRangeDto;
    /**
     * 
     * @type {string}
     * @memberof EditionImplicationDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EditionImplicationDto
     */
    editionOrder: EditionImplicationDtoEditionOrderEnum;
}


/**
 * @export
 */
export const EditionImplicationDtoEditionOrderEnum = {
    PREVIOUS: 'PREVIOUS',
    FOLLOWING: 'FOLLOWING'
} as const;
export type EditionImplicationDtoEditionOrderEnum = typeof EditionImplicationDtoEditionOrderEnum[keyof typeof EditionImplicationDtoEditionOrderEnum];


/**
 * Check if a given object implements the EditionImplicationDto interface.
 */
export function instanceOfEditionImplicationDto(value: object): boolean {
    if (!('affected' in value)) return false;
    if (!('editionOrder' in value)) return false;
    return true;
}

export function EditionImplicationDtoFromJSON(json: any): EditionImplicationDto {
    return EditionImplicationDtoFromJSONTyped(json, false);
}

export function EditionImplicationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditionImplicationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'affected': EditionDtoFromJSON(json['affected']),
        'dateRange': json['dateRange'] == null ? undefined : ClosedDateRangeDtoFromJSON(json['dateRange']),
        'name': json['name'] == null ? undefined : json['name'],
        'editionOrder': json['editionOrder'],
    };
}

export function EditionImplicationDtoToJSON(value?: EditionImplicationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'affected': EditionDtoToJSON(value['affected']),
        'dateRange': ClosedDateRangeDtoToJSON(value['dateRange']),
        'name': value['name'],
        'editionOrder': value['editionOrder'],
    };
}

