import { Box, ChakraProps, useAccordionItemState } from '@chakra-ui/react';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface AccordionIconProps extends ChakraProps {}

export default function AccordionIcon(props: AccordionIconProps) {
  const { isOpen } = useAccordionItemState();

  return (
    <Box
      as={FontAwesomeIcon}
      icon={faChevronDown}
      rotation={isOpen ? 180 : undefined}
      transition="transform 300ms"
      {...props}
    />
  );
}
