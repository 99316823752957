/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailAddressDto
 */
export interface EmailAddressDto {
    /**
     * An email address
     * @type {string}
     * @memberof EmailAddressDto
     */
    email: string;
    /**
     * Can the information be published?
     * @type {boolean}
     * @memberof EmailAddressDto
     */
    publish?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof EmailAddressDto
     */
    timestamp?: Date;
    /**
     * The label for the email address
     * @type {string}
     * @memberof EmailAddressDto
     */
    label: EmailAddressDtoLabelEnum;
    /**
     * Are the information confidential?
     * @type {boolean}
     * @memberof EmailAddressDto
     */
    confidential?: boolean;
}


/**
 * @export
 */
export const EmailAddressDtoLabelEnum = {
    CONTACT: 'CONTACT',
    PRIVATE: 'PRIVATE',
    OTHER: 'OTHER'
} as const;
export type EmailAddressDtoLabelEnum = typeof EmailAddressDtoLabelEnum[keyof typeof EmailAddressDtoLabelEnum];


/**
 * Check if a given object implements the EmailAddressDto interface.
 */
export function instanceOfEmailAddressDto(value: object): boolean {
    if (!('email' in value)) return false;
    if (!('label' in value)) return false;
    return true;
}

export function EmailAddressDtoFromJSON(json: any): EmailAddressDto {
    return EmailAddressDtoFromJSONTyped(json, false);
}

export function EmailAddressDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailAddressDto {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'publish': json['publish'] == null ? undefined : json['publish'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
        'label': json['label'],
        'confidential': json['confidential'] == null ? undefined : json['confidential'],
    };
}

export function EmailAddressDtoToJSON(value?: EmailAddressDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'publish': value['publish'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
        'label': value['label'],
        'confidential': value['confidential'],
    };
}

