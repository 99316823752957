/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyRoleDto } from './CompanyRoleDto';
import {
    CompanyRoleDtoFromJSON,
    CompanyRoleDtoFromJSONTyped,
    CompanyRoleDtoToJSON,
} from './CompanyRoleDto';
import type { FilmReferenceDto } from './FilmReferenceDto';
import {
    FilmReferenceDtoFromJSON,
    FilmReferenceDtoFromJSONTyped,
    FilmReferenceDtoToJSON,
} from './FilmReferenceDto';

/**
 * The relation between a company and a film.
 * @export
 * @interface CreateCompanyFilmRelationsDto
 */
export interface CreateCompanyFilmRelationsDto {
    /**
     * 
     * @type {CompanyRoleDto}
     * @memberof CreateCompanyFilmRelationsDto
     */
    role: CompanyRoleDto;
    /**
     * 
     * @type {Array<FilmReferenceDto>}
     * @memberof CreateCompanyFilmRelationsDto
     */
    films: Array<FilmReferenceDto>;
}

/**
 * Check if a given object implements the CreateCompanyFilmRelationsDto interface.
 */
export function instanceOfCreateCompanyFilmRelationsDto(value: object): boolean {
    if (!('role' in value)) return false;
    if (!('films' in value)) return false;
    return true;
}

export function CreateCompanyFilmRelationsDtoFromJSON(json: any): CreateCompanyFilmRelationsDto {
    return CreateCompanyFilmRelationsDtoFromJSONTyped(json, false);
}

export function CreateCompanyFilmRelationsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCompanyFilmRelationsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'role': CompanyRoleDtoFromJSON(json['role']),
        'films': ((json['films'] as Array<any>).map(FilmReferenceDtoFromJSON)),
    };
}

export function CreateCompanyFilmRelationsDtoToJSON(value?: CreateCompanyFilmRelationsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'role': CompanyRoleDtoToJSON(value['role']),
        'films': ((value['films'] as Array<any>).map(FilmReferenceDtoToJSON)),
    };
}

