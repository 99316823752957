/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VisaCreationCheckDto
 */
export interface VisaCreationCheckDto {
    /**
     * 
     * @type {boolean}
     * @memberof VisaCreationCheckDto
     */
    participationDataAvailableWithinActiveEdition: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VisaCreationCheckDto
     */
    validVisaAlreadyAvailable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VisaCreationCheckDto
     */
    nationalityAvailable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VisaCreationCheckDto
     */
    visaRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VisaCreationCheckDto
     */
    defaultTemplateExists?: boolean;
}

/**
 * Check if a given object implements the VisaCreationCheckDto interface.
 */
export function instanceOfVisaCreationCheckDto(value: object): boolean {
    if (!('participationDataAvailableWithinActiveEdition' in value)) return false;
    if (!('validVisaAlreadyAvailable' in value)) return false;
    if (!('nationalityAvailable' in value)) return false;
    if (!('visaRequired' in value)) return false;
    return true;
}

export function VisaCreationCheckDtoFromJSON(json: any): VisaCreationCheckDto {
    return VisaCreationCheckDtoFromJSONTyped(json, false);
}

export function VisaCreationCheckDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisaCreationCheckDto {
    if (json == null) {
        return json;
    }
    return {
        
        'participationDataAvailableWithinActiveEdition': json['participationDataAvailableWithinActiveEdition'],
        'validVisaAlreadyAvailable': json['validVisaAlreadyAvailable'],
        'nationalityAvailable': json['nationalityAvailable'],
        'visaRequired': json['visaRequired'],
        'defaultTemplateExists': json['defaultTemplateExists'] == null ? undefined : json['defaultTemplateExists'],
    };
}

export function VisaCreationCheckDtoToJSON(value?: VisaCreationCheckDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'participationDataAvailableWithinActiveEdition': value['participationDataAvailableWithinActiveEdition'],
        'validVisaAlreadyAvailable': value['validVisaAlreadyAvailable'],
        'nationalityAvailable': value['nationalityAvailable'],
        'visaRequired': value['visaRequired'],
        'defaultTemplateExists': value['defaultTemplateExists'],
    };
}

