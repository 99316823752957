import { HStack, Link, Tag, Wrap, WrapItem } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { KeyPlayerDto, PhoneNumberDtoLabelEnum } from '../../../api';
import { DataTableColumn } from '../../../ui/data-table';
import EnumFilter from '../../../ui/data-table/filter/enum-filter';
import HelperPopover from '../../../ui/helper-buttons/helper-popover';
import Optional from '../../../ui/optional/optional';
import linkPhoneNumber from '../../../ui/phone-number/link-phone-number';
import renderPhoneNumber from '../../../ui/phone-number/render-phone-number';
import Translate from '../../../util/translate/translate';
import { markers } from '../../person/person-enum-constants';

export default function useDynamicColumns() {
  const { t } = useTranslation(['person', 'key_player_list']);

  return React.useMemo(() => {
    const phoneColumn: DataTableColumn<KeyPlayerDto> = {
      key: 'phoneNumber',
      name: (
        <HStack>
          <Translate ns="key_player_list">{(t) => t('lister.phone_number')}</Translate>
          <HelperPopover
            children={t(`key_player_list:lister.phone_number_helper`)}
            ariaLabel={t('key_player_list:lister.phone_number')}
          />
        </HStack>
      ),
      renderCell: (keyPlayer) => (
        <Optional isEmpty={isEmpty(keyPlayer.person.phoneNumbers)}>
          <Wrap>
            {keyPlayer.person.phoneNumbers
              ?.filter(
                (n) =>
                  !n.confidential &&
                  (n.label === PhoneNumberDtoLabelEnum.MOBILE || n.label === PhoneNumberDtoLabelEnum.BUSINESS),
              )
              .map((number, index) => (
                <WrapItem key={index}>
                  <Link href={linkPhoneNumber(number)} isExternal>
                    {renderPhoneNumber(number)}
                  </Link>
                </WrapItem>
              ))}
          </Wrap>
        </Optional>
      ),
    };

    const emailColumn: DataTableColumn<KeyPlayerDto> = {
      key: 'emailAddresses',
      name: t('person:contacts.email.email_address'),
      renderCell: (keyPlayer) => (
        <Optional isEmpty={isEmpty(keyPlayer.person.emailAddresses)}>
          <Wrap>
            {keyPlayer.person.emailAddresses?.map((email, index) => (
              <WrapItem key={index}>
                <Link href={'mailto:' + email}>{email}</Link>
              </WrapItem>
            ))}
          </Wrap>
        </Optional>
      ),
    };

    const stageNameColumn: DataTableColumn<KeyPlayerDto> = {
      key: 'stageName',
      name: t('person:stage_name'),
      renderCell: (keyPlayer) => <Optional>{keyPlayer.person.stageName}</Optional>,
    };

    const contactLanguageColumn: DataTableColumn<KeyPlayerDto> = {
      key: 'contactLanguage',
      name: t('person:contacts.contactLanguage'),
      renderCell: (keyPlayer) => (
        <Optional isEmpty={keyPlayer.person.contactLanguage == null}>
          {t(`person:contacts.contactLanguageLabels.${keyPlayer.person.contactLanguage!}`)}
        </Optional>
      ),
    };

    const markerColumn: DataTableColumn<KeyPlayerDto> = {
      key: 'markers',
      name: (
        <HStack>
          <Translate ns="key_player_list">{(t) => t('lister.marker')}</Translate>
          <HelperPopover
            children={t(`key_player_list:lister.marker_helper`)}
            ariaLabel={t('key_player_list:lister.marker')}
          />
        </HStack>
      ),
      renderCell: (keyPlayer) => (
        <Optional isEmpty={isEmpty(keyPlayer.person.markers)}>
          <Wrap>
            {keyPlayer.person.markers?.map((marker, index) => (
              <WrapItem key={index}>
                <Tag colorScheme="gray">{t(`person:markersLabel.${marker}`)}</Tag>{' '}
              </WrapItem>
            ))}
          </Wrap>
        </Optional>
      ),
      filter: (
        <EnumFilter
          label={<Translate ns="person" i18nKey="type" />}
          options={markers}
          renderOptionLabel={(value) => t(`person:markersLabel.${value}`)}
        />
      ),
    };

    return { phoneColumn, emailColumn, stageNameColumn, contactLanguageColumn, markerColumn };
  }, [t]);
}
