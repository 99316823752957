import { Key, RefObject, useEffect } from 'react';
import invariant from 'tiny-invariant';

const EQUALIZE_ITEM_DATA_PROP = 'equalize-item';
const KEY_DATA_PROP = 'key';

export function getEqualizeItemProps(key: Key) {
  return {
    [`data-${EQUALIZE_ITEM_DATA_PROP}`]: true,
    [`data-${KEY_DATA_PROP}`]: key,
  };
}

// Hacky solution for equalizing the height of the history sub element entries.
// Collects all elements with a certain data attribute. Groups these elements by key and closest
// parent and then equalize the height of all items in a group.
// Do not use elswhere!
export default function useSizeEqualizer(containerRef: RefObject<HTMLElement>) {
  useEffect(() => {
    const container = containerRef.current;

    // No container, no equalizing
    if (container == null) {
      return;
    }

    let items: HTMLElement[] = [];

    const equalizeItems = () => {
      // Reset the height of all items to be able to measure them again
      items.forEach((item) => {
        item.style.height = '';
      });

      // For each group, set the height of all items to the maximum height of the group
      groupItemsByKeyAndClosestParent(items).forEach((group) => {
        const maxHeight = Math.max(...group.map((item) => item.getBoundingClientRect().height));

        group.forEach((item) => {
          item.style.height = `${maxHeight}px`;
        });
      });
    };

    // Observe resize of items
    const resizeObserver = new ResizeObserver(equalizeItems);

    const collectItems = () => {
      // Stop observing size of old items
      items.forEach((item) => {
        resizeObserver.unobserve(item);
      });

      // Collect new items
      items = Array.from(container.querySelectorAll(`[data-${EQUALIZE_ITEM_DATA_PROP}]`));

      // Start observing size of new items
      items.forEach((item) => {
        resizeObserver.observe(item);
      });
    };

    // Observe changes to the container and its children
    const mutationObserver = new MutationObserver(() => {
      collectItems();
      equalizeItems();
    });

    mutationObserver.observe(container, { subtree: true, childList: true });

    collectItems();
    equalizeItems();

    return () => {
      mutationObserver.disconnect();
      resizeObserver.disconnect();
    };
  }, [containerRef]);
}

function groupItemsByKeyAndClosestParent(items: HTMLElement[]) {
  // Create lookup for all parents of all items
  const parentsByItem = new Map<HTMLElement, HTMLElement[]>(items.map((item) => [item, getParentElements(item)]));
  const itemsByKey = new Map<string, HTMLElement[]>();

  // Group items by key
  items.forEach((item) => {
    const key = item.dataset[KEY_DATA_PROP];
    invariant(key != null, 'Key is missing on equalize item');

    const itemsForKey = itemsByKey.get(key) ?? [];
    itemsByKey.set(key, [...itemsForKey, item]);
  });

  const itemsByKeyByParent = new Map<string, Map<HTMLElement, HTMLElement[]>>();

  // Group items by key and closest parent
  itemsByKey.forEach((itemsForKey, key) => {
    itemsForKey.forEach((item) => {
      // Get all parents of the current item
      const parentsOfItem = parentsByItem.get(item)!;

      // Get all parents of all items except the current one
      const parentsOfAllOtherItems = new Set(
        itemsForKey.flatMap((otherItem) => (otherItem !== item ? parentsByItem.get(otherItem) ?? [] : [])),
      );

      // Find the closest parent of the current item that is also a parent of one of the other items
      const closestParent = parentsOfItem.find((parent) => parentsOfAllOtherItems.has(parent));
      invariant(closestParent != null, 'No closest parent found');

      // Copy group of items by key
      const itemsByParent = itemsByKeyByParent.get(key) ?? new Map();
      itemsByKeyByParent.set(key, itemsByParent);

      // Add the current item to the group of items by key and closest parent
      const itemsForParent = itemsByParent.get(closestParent) ?? [];
      itemsByParent.set(closestParent, [...itemsForParent, item]);
    });
  });

  // Flatten the map of items by key and closest parent to a two-dimensional array of items
  return Array.from(itemsByKeyByParent.values())
    .map((itemsByParent) => Array.from(itemsByParent.values()))
    .flat();
}

function getParentElements(element: HTMLElement) {
  const parents: HTMLElement[] = [];
  let currentElement: HTMLElement | null = element.parentElement;

  while (currentElement != null) {
    parents.push(currentElement);
    currentElement = currentElement.parentElement;
  }

  return parents;
}
