import { Button, chakra, HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { MailingTypeDto } from '../../../../api';
import DateInputFormControl from '../../../../ui/form/date-input-control/date-input-form-control';
import HelperPopover from '../../../../ui/helper-buttons/helper-popover';
import useEvent from '../../../event/use-event/use-event';
import { useGuestListSettingsByEventId } from '../../../guest-list/use-guest-list-settings/use-guest-list-settings';

export interface RsvpMailingModuleControlProps {
  eventId: string;
  name: string;
  mailingType: MailingTypeDto;
}

export default function RsvpMailingModuleControl({ eventId, name, mailingType }: RsvpMailingModuleControlProps) {
  const { t } = useTranslation(['mailing', 'common', 'guest_list']);
  const { field } = useController({ name: `${name}.rsvpDeadline` });

  const guestList = useGuestListSettingsByEventId(eventId);
  invariant(guestList != null, 'guestList must be present');
  const isRsvpActive = guestList.rsvpFunction;
  const defaultRsvp = guestList.defaultRsvpDeadline;
  const event = useEvent(eventId);

  const getHelperText = () => {
    if (mailingType === 'INVITATION_GUEST_LIST_UNPERSONALIZED') {
      if (isRsvpActive) {
        return t('mailing:rsvp_module.helper_text.placeholder_active');
      }
      return t('mailing:rsvp_module.helper_text.placeholder_inactive');
    }
    // !shouldContainPlaceholderHelperText
    if (isRsvpActive) {
      return t('mailing:rsvp_module.helper_text.active');
    }
    return t('mailing:rsvp_module.helper_text.inactive');
  };

  return (
    <Stack spacing={0}>
      <HStack>
        <chakra.span color={isRsvpActive ? 'inherit' : 'text.disabled'}>{t('common:prefill')}:</chakra.span>
        <Spacer />
        <Button size="sm" isDisabled={!isRsvpActive} onClick={() => field.onChange(defaultRsvp)}>
          {t('mailing:rsvp_module.default_rsvp')}
        </Button>
      </HStack>
      <DateInputFormControl
        label={t('mailing:rsvp_module.label')}
        isRequired={isRsvpActive}
        helperPopover={
          <HelperPopover>
            <Stack>
              <Text>{t('mailing:rsvp_module.helper_popover_1')}</Text>
              <Text>{t('mailing:rsvp_module.helper_popover_2')}</Text>
            </Stack>
          </HelperPopover>
        }
        helperText={getHelperText()}
        name={`${name}.rsvpDeadline`}
        showTimeSelect
        isDisabled={!isRsvpActive}
        max={{
          value: event.dateTimeRange.start,
          message: t('guest_list:validation_error.rsvpAfterEventStart'),
        }}
      />
    </Stack>
  );
}
