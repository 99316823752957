/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The result of a status check.
 * @export
 * @interface EventStatusCheckDto
 */
export interface EventStatusCheckDto {
    /**
     * 
     * @type {boolean}
     * @memberof EventStatusCheckDto
     */
    readonly isDeletable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventStatusCheckDto
     */
    readonly hasGuestList: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof EventStatusCheckDto
     */
    readonly connectedEvents: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof EventStatusCheckDto
     */
    readonly hasPersonInRundown: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventStatusCheckDto
     */
    readonly hasKeyPlayer: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventStatusCheckDto
     */
    readonly hasLiveTicketingAttendee: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventStatusCheckDto
     */
    readonly hasMailing: boolean;
}

/**
 * Check if a given object implements the EventStatusCheckDto interface.
 */
export function instanceOfEventStatusCheckDto(value: object): boolean {
    if (!('isDeletable' in value)) return false;
    if (!('hasGuestList' in value)) return false;
    if (!('connectedEvents' in value)) return false;
    if (!('hasPersonInRundown' in value)) return false;
    if (!('hasKeyPlayer' in value)) return false;
    if (!('hasLiveTicketingAttendee' in value)) return false;
    if (!('hasMailing' in value)) return false;
    return true;
}

export function EventStatusCheckDtoFromJSON(json: any): EventStatusCheckDto {
    return EventStatusCheckDtoFromJSONTyped(json, false);
}

export function EventStatusCheckDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventStatusCheckDto {
    if (json == null) {
        return json;
    }
    return {
        
        'isDeletable': json['isDeletable'],
        'hasGuestList': json['hasGuestList'],
        'connectedEvents': json['connectedEvents'],
        'hasPersonInRundown': json['hasPersonInRundown'],
        'hasKeyPlayer': json['hasKeyPlayer'],
        'hasLiveTicketingAttendee': json['hasLiveTicketingAttendee'],
        'hasMailing': json['hasMailing'],
    };
}

export function EventStatusCheckDtoToJSON(value?: EventStatusCheckDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
    };
}

