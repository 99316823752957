import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormatOccupationRelationDto } from '../../../../api';
import HistoryDisplaySettings from '../../../../ui/history/history-display-settings';

export default function useFormatOccupationHistorySettings(): HistoryDisplaySettings<FormatOccupationRelationDto> {
  const { t } = useTranslation(['format', 'edition', 'person', 'common']);

  return useMemo<HistoryDisplaySettings<FormatOccupationRelationDto>>(() => {
    return {
      attributeLabels: {
        person: t('person:person'),
        occupation: t('person:occupations.label'),
        edition: t('edition:edition'),
      },
      valueFormatter: {
        person: (value) => (value == null ? undefined : `${value.firstName} ${value.surname} (${value.personKey})`),
        occupation: (value) =>
          value == null ? undefined : value.jobTitle == null ? t('common:history.removed') : `${value.jobTitle}`,
        edition: (value) => (value == null ? undefined : value.name),
      },
      diffEntireWord: {
        person: true,
        edition: true,
      },
      name: () => t('format:connections.person'),
      showEditDescription: false,
    };
  }, [t]);
}
