import dateTimeFormatter from './date-time-formatter';
import timeFormatter from './time-formatter';

interface DateTimeRange {
  start: string;
  end: string;
}

const prefixMap = new Map<string, string>([
  ['de', 'Seit'],
  ['en', 'Since'],
]);

const getDate = function (value: Date | number | string) {
  if (!(value instanceof Date)) {
    value = new Date(value);
  }
  return value.toDateString();
};

const isSameDay = function (range: DateTimeRange) {
  return getDate(range.start) === getDate(range.end);
};

export default function dateTimeRangeFormatter(
  value: DateTimeRange | undefined,
  lng?: string,
  options?: Intl.DateTimeFormatOptions,
): string {
  if (value === undefined) {
    return '';
  }

  if (value.end == null) {
    return `${prefixMap.get(lng ?? 'de')} ${dateTimeFormatter(value.start, lng, options)}`;
  }

  let startOptions = options;
  let endOptions = options;
  if (new Date(value.start).getSeconds() === 0 && new Date(value.end).getSeconds() === 0) {
    startOptions = { ...options, second: undefined };
    endOptions = { ...options, second: undefined };
  }

  // if starting and ending on same day, display only time but not date of end
  const sameDay = isSameDay(value);
  const start = dateTimeFormatter(value.start, lng, startOptions);
  const end = sameDay ? timeFormatter(value.end, lng, endOptions) : dateTimeFormatter(value.end, lng, endOptions);

  return sameDay
    ? `${start}\u200A\u200A–\u200A\u200A${end}\u200A\u200AUhr`
    : `${start}\u200A\u200AUhr\u200A\u200A–\u200A\u200A${end}\u200A\u200AUhr`;
}
