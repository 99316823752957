import { ParseKeys } from 'i18next/typescript/t';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import registry from '../../registry';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import { LayoutType } from '../common/LayoutType';
import { createFocusedEditionSearchParam } from '../edition/use-focused-or-active-edition/use-focused-or-active-edition-id';
import EVENT_CHILD_ROUTES from './event-child-routes';
import EVENT_CHILD_ROUTES_MOBILE from './event-child-routes-mobile';
import { EventEditorAction } from './event-editor/event-editor';
import EventPage from './event-page/event-page';
import useEvent from './use-event/use-event';

const EventLister = React.lazy(() => import('./event-lister/event-lister'));
const EventListerMobile = React.lazy(() => import('./event-lister-mobile/event-lister-mobile'));
const EventEditor = React.lazy(() => import('./event-editor/event-editor'));
const EventViewer = React.lazy(() => import('./event-viewer/event-viewer'));
const EventHistory = React.lazy(() => import('./event-history/event-history'));
const EventCommentViewer = React.lazy(() => import('./event-comment/event-comment-viewer'));
const EventAttachmentPageContent = React.lazy(() => import('./event-attachments/event-attachments-page-content'));

const EventViewerMobile = React.lazy(() => import('./event-viewer-mobile/event-viewer-mobile'));

type RouteObject = BreadcrumbRouteObject & HelmetRouteObject;

export const eventFocusedEditionRedirectRoutes = [
  {
    path: 'focused-edition',
    children: [
      {
        path: ':focusedEditionId',
        children: [
          {
            path: '*',
            element: <RedirectWithQueryParam />,
          },
        ],
      },
    ],
  },
  {
    path: 'focusedEdition',
    children: [
      {
        path: ':focusedEditionId',
        children: [
          {
            path: '*',
            element: <RedirectWithQueryParam />,
          },
        ],
      },
    ],
  },
];

export function eventRoutesWithPrefix(children: RouteObject[]): RouteObject[] {
  return [
    {
      path: 'events',
      handle: {
        breadcrumb: <Translate ns="event" i18nKey="lister.title" />,
      },
      children,
    },
    ...eventFocusedEditionRedirectRoutes,
  ];
}

const eventRoutes = eventRoutesWithPrefix([
  {
    path: '',
    element: <EventLister />,
    handle: {
      helmet: <Translate ns="event">{(t) => <Helmet title={t('lister.helmet')} />}</Translate>,
    },
  },
  {
    path: 'new',
    handle: {
      breadcrumb: <Translate ns="event" i18nKey="action.new" />,
      helmet: <Translate ns="event">{(t) => <Helmet title={t('action.new')} />}</Translate>,
    },
    element: <EventEditor layout={LayoutType.NORMAL} editorAction={EventEditorAction.NEW} />,
  },
  {
    path: ':eventId',
    handle: {
      breadcrumb: <EventBreadcrumb />,
      helmet: <EventHelmet i18nKey="viewer.helmet" />,
    },
    children: [
      {
        path: '',
        element: <EventPage />,
        children: [
          {
            path: '',
            element: <EventViewer />,
          },
          {
            path: 'comments',
            element: <EventCommentViewer />,
            handle: {
              helmet: <EventHelmet i18nKey="comments.helmet" />,
            },
          },
          {
            path: 'history',
            element: <EventHistory />,
            handle: {
              helmet: <EventHelmet i18nKey="history.helmet" />,
            },
          },
          {
            path: 'attachments',
            element: <EventAttachmentPageContent />,
            handle: {
              helmet: <EventHelmet i18nKey="attachments.helmet" />,
            },
          },
        ],
      },
      {
        path: 'edit',
        handle: {
          breadcrumb: <Translate ns="event" i18nKey="action.edit" />,
          helmet: <EventHelmet i18nKey="editor.helmet" />,
        },
        element: <EventEditor layout={LayoutType.NORMAL} editorAction={EventEditorAction.EDIT} />,
      },
      {
        path: 'copy',
        handle: {
          breadcrumb: <Translate ns="event" i18nKey="action.copy" />,
          helmet: <EventHelmet i18nKey="editor.helmetCopy" />,
        },
        element: <EventEditor layout={LayoutType.NORMAL} editorAction={EventEditorAction.COPY} />,
      },
      ...registry.getAll(EVENT_CHILD_ROUTES).flat(),
    ],
  },
]);

export const eventRoutesMobile = eventRoutesWithPrefix([
  {
    path: '',
    element: <EventListerMobile />,
    handle: {
      helmet: <Translate ns="event">{(t) => <Helmet title={t('lister.helmet')} />}</Translate>,
    },
  },
  {
    path: ':eventId',
    handle: {
      breadcrumb: <EventBreadcrumb />,
      helmet: <EventHelmet i18nKey="viewer.helmet" />,
    },
    children: [
      {
        path: '',
        element: <EventViewerMobile />,
      },
      ...registry.getAll(EVENT_CHILD_ROUTES_MOBILE).flat(),
    ],
  },
]);

/**
 * Represents event routes.
 */
export default eventRoutes;

interface EventHelmetProps {
  i18nKey: ParseKeys<'event'>;
}

function EventHelmet({ i18nKey }: EventHelmetProps) {
  const { t } = useTranslation('event');
  const params = useParams<{ eventId: string }>();
  invariant(params.eventId, 'Empty eventId');
  const { title } = useEvent(params.eventId);

  return <Helmet title={t(i18nKey, { title })} />;
}

export function EventBreadcrumb() {
  const { eventId } = useParams<{ eventId: string }>();
  invariant(eventId, 'Empty eventId');
  const event = useEvent(eventId);

  return <>{event.title}</>;
}

export function RedirectWithQueryParam() {
  const params = useParams();
  const focusedEdition = params['focusedEditionId'];
  const to = `/${params['*']}${focusedEdition ? `?${createFocusedEditionSearchParam(focusedEdition)}` : ''}`;

  return <Navigate to={to} replace />;
}
