/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AllocationReferenceDto } from './AllocationReferenceDto';
import {
    AllocationReferenceDtoFromJSON,
    AllocationReferenceDtoFromJSONTyped,
    AllocationReferenceDtoToJSON,
} from './AllocationReferenceDto';

/**
 * 
 * @export
 * @interface AllocationCapacityDto
 */
export interface AllocationCapacityDto {
    /**
     * 
     * @type {AllocationReferenceDto}
     * @memberof AllocationCapacityDto
     */
    allocation: AllocationReferenceDto;
    /**
     * 
     * @type {number}
     * @memberof AllocationCapacityDto
     */
    capacity: number;
    /**
     * 
     * @type {number}
     * @memberof AllocationCapacityDto
     */
    occupied: number;
    /**
     * 
     * @type {number}
     * @memberof AllocationCapacityDto
     */
    free: number;
}

/**
 * Check if a given object implements the AllocationCapacityDto interface.
 */
export function instanceOfAllocationCapacityDto(value: object): boolean {
    if (!('allocation' in value)) return false;
    if (!('capacity' in value)) return false;
    if (!('occupied' in value)) return false;
    if (!('free' in value)) return false;
    return true;
}

export function AllocationCapacityDtoFromJSON(json: any): AllocationCapacityDto {
    return AllocationCapacityDtoFromJSONTyped(json, false);
}

export function AllocationCapacityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllocationCapacityDto {
    if (json == null) {
        return json;
    }
    return {
        
        'allocation': AllocationReferenceDtoFromJSON(json['allocation']),
        'capacity': json['capacity'],
        'occupied': json['occupied'],
        'free': json['free'],
    };
}

export function AllocationCapacityDtoToJSON(value?: AllocationCapacityDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'allocation': AllocationReferenceDtoToJSON(value['allocation']),
        'capacity': value['capacity'],
        'occupied': value['occupied'],
        'free': value['free'],
    };
}

