import { chakra, Stack } from '@chakra-ui/react';
import React from 'react';
import { FieldValues } from 'react-hook-form';
import { HistoryPageDto } from '../../api';
import DataTableState from '../data-table/data-table-state';
import Empty from '../no-data/empty';
import { PageContent } from '../page';
import { PageContentProps } from '../page/page-content';
import Pagination from '../pagination/pagination';
import { PageState } from '../pagination/use-pagination-state';
import HistoryDisplaySettings from './history-display-settings';
import HistoryEntry from './history-entry';

interface HistoryPageProps<T extends FieldValues> extends PageContentProps {
  page: HistoryPageDto;

  onStateChange(state: Partial<DataTableState>): void;

  historyDisplaySettings: HistoryDisplaySettings<T>;
  state?: PageState;

  fetchMerged?(id: string, signal: AbortSignal): Promise<any>;

  showLine?: boolean;
}

export default function HistoryPage<T extends FieldValues>({
  page,
  state,
  onStateChange,
  historyDisplaySettings,
  fetchMerged,
  showLine = true,
  ...props
}: HistoryPageProps<T>) {
  return (
    <PageContent {...props}>
      <chakra.section>
        <Stack spacing={6}>
          {page.content.length > 0 ? (
            page.content.map((entry) => (
              <HistoryEntry<T>
                key={entry.version.revision}
                entry={entry}
                fetchMerged={fetchMerged}
                showLine={showLine}
                {...historyDisplaySettings}
              />
            ))
          ) : (
            <Empty />
          )}
          <Pagination
            page={page}
            onPrevPage={() => {
              onStateChange({ page: (state?.page ?? 0) - 1 });
            }}
            onNextPage={() => {
              onStateChange({ page: (state?.page ?? 0) + 1 });
            }}
          />
        </Stack>
      </chakra.section>
    </PageContent>
  );
}
