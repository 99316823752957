/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const StandCompanyRelationTypeDto = {
    MAIN_PARTNER: 'MAIN_PARTNER',
    SECONDARY_PARTNER: 'SECONDARY_PARTNER',
    EXCLUDED: 'EXCLUDED'
} as const;
export type StandCompanyRelationTypeDto = typeof StandCompanyRelationTypeDto[keyof typeof StandCompanyRelationTypeDto];


export function StandCompanyRelationTypeDtoFromJSON(json: any): StandCompanyRelationTypeDto {
    return StandCompanyRelationTypeDtoFromJSONTyped(json, false);
}

export function StandCompanyRelationTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandCompanyRelationTypeDto {
    return json as StandCompanyRelationTypeDto;
}

export function StandCompanyRelationTypeDtoToJSON(value?: StandCompanyRelationTypeDto | null): any {
    return value as any;
}

