import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Link } from '@chakra-ui/react';
import React from 'react';
import { EventArchivedLinkDto } from '../../../api';
import { DataTableColumn } from '../../../ui/data-table';
import Optional from '../../../ui/optional/optional';
import Translate from '../../../util/translate/translate';

const archivedLinkColumns: DataTableColumn<EventArchivedLinkDto>[] = [
  {
    key: 'archivedLink',
    name: <Translate ns="event" i18nKey="archived_links.label" />,
    renderCell: (data) => (
      <Link href={data.url} isExternal>
        {data.url} <ExternalLinkIcon mx="2px" />
      </Link>
    ),
    cellProps: {
      wordBreak: 'break-all',
    },
  },
  {
    key: 'comment',
    name: <Translate ns="event" i18nKey="comment" />,
    renderCell: (data) => <Optional>{data.comment}</Optional>,
  },
  {
    key: 'expirationDate',
    name: <Translate ns="event" i18nKey="archived_links.expiration_date" />,
    renderCell: (data) => (
      <Optional>
        {data.expirationDate && (
          <Translate ns="common">{(t) => t('format.date', { date: data.expirationDate })}</Translate>
        )}
      </Optional>
    ),
  },
];

/**
 * Exports columns for the archived link table inside {@link EventForm}
 */
export default archivedLinkColumns;
