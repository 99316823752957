import {
  AsyncCreatableProps,
  AsyncCreatableSelect,
  AsyncSelect as AsyncChakraReactSelect,
  GroupBase,
  SelectInstance,
} from 'chakra-react-select';
import React from 'react';
import invariant from 'tiny-invariant';
import useSelectProps from './use-select-props';

export interface AsyncSelectProps<TOption, TIsMulti extends boolean, TGroup extends GroupBase<TOption>>
  extends AsyncCreatableProps<TOption, TIsMulti, TGroup> {
  enableCreate?: boolean;
}

function AsyncSelect<TOption, TIsMulti extends boolean = false, TGroup extends GroupBase<TOption> = GroupBase<TOption>>(
  { enableCreate = false, ...props }: Omit<AsyncSelectProps<TOption, TIsMulti, TGroup>, 'variant'>,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const selectRef = React.useRef<SelectInstance<TOption, TIsMulti, TGroup>>(null);
  const selectProps = useSelectProps(props);

  React.useImperativeHandle(ref, () => {
    invariant(selectRef.current?.inputRef != null);

    return selectRef.current.inputRef;
  });

  if (enableCreate) {
    return <AsyncCreatableSelect ref={selectRef} {...selectProps} />;
  } else {
    return <AsyncChakraReactSelect ref={selectRef} {...selectProps} />;
  }
}

export default React.forwardRef(AsyncSelect);
