import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FieldsOfInterestDto } from '../../../api';
import FormControl from '../../../ui/form/form-control';
import MultiValueSelectControl from '../../../ui/form/select-control/multi-value-select-control';
import HelperPopover from '../../../ui/helper-buttons/helper-popover';

const fieldsOfInterest = [
  FieldsOfInterestDto.ADVENTURE,
  FieldsOfInterestDto.ACTION,
  FieldsOfInterestDto.US_INDIES,
  FieldsOfInterestDto.ANIMATION,
  FieldsOfInterestDto.ARAB_CINEMA,
  FieldsOfInterestDto.ARTHOUSE,
  FieldsOfInterestDto.BOLLYWOOD,
  FieldsOfInterestDto.DATA_ANALYST,
  FieldsOfInterestDto.DOCUMENTARY_FORM,
  FieldsOfInterestDto.DRAMA,
  FieldsOfInterestDto.ADULT,
  FieldsOfInterestDto.EUROPEAN_CINEMA,
  FieldsOfInterestDto.EXPERIMENTAL,
  FieldsOfInterestDto.FANTASY,
  FieldsOfInterestDto.FUNDING,
  FieldsOfInterestDto.FINANCING,
  FieldsOfInterestDto.ADVANCED_TRAINING,
  FieldsOfInterestDto.HISTORICAL,
  FieldsOfInterestDto.HORROR,
  FieldsOfInterestDto.INDIGENOUS,
  FieldsOfInterestDto.INTERNATIONAL_CO_PRODUCTION,
  FieldsOfInterestDto.CHILDRENS_FILM,
  FieldsOfInterestDto.COMEDY,
  FieldsOfInterestDto.CRIME,
  FieldsOfInterestDto.SHORT_FORM,
  FieldsOfInterestDto.ROMANCE,
  FieldsOfInterestDto.MUSICAL_AND_DANCE,
  FieldsOfInterestDto.MUSIC_VIDEO,
  FieldsOfInterestDto.NEW_TECH,
  FieldsOfInterestDto.QUEER,
  FieldsOfInterestDto.SATIRE,
  FieldsOfInterestDto.SCIENCE_FICTION,
  FieldsOfInterestDto.SERIES,
  FieldsOfInterestDto.SHOWRUNNER,
  FieldsOfInterestDto.FICTION,
  FieldsOfInterestDto.THRILLER,
  FieldsOfInterestDto.GREEN_PRODUCTION,
  FieldsOfInterestDto.WESTERN,
  FieldsOfInterestDto.WRITERS_ROOM,
];

/**
 * Control to edit person's fields of interest.
 */
export default function FieldsOfInterestControl<T extends FieldValues>({ path }: { path?: FieldPath<T> }) {
  const prefixWithPath = <TPath extends string>(name: TPath) => (path != null ? (`${path}.${name}` as TPath) : name);
  const { t } = useTranslation('person');

  return (
    <FormControl
      label={t('fieldsOfInterest')}
      name={prefixWithPath('fieldsOfInterest')}
      helperPopover={<HelperPopover children={t('fieldsOfInterestPopover')} />}
      helperText={t('fieldsOfInterestHint')}
    >
      <MultiValueSelectControl
        options={fieldsOfInterest}
        renderLabel={(option) => t(`fieldsOfInterestLabels.${option}`)}
        name={prefixWithPath('fieldsOfInterest')}
        isSearchable={true}
      />
    </FormControl>
  );
}
