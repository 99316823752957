/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ForRefOperationAllOfEdgeModelDto } from './ForRefOperationAllOfEdgeModelDto';
import {
    ForRefOperationAllOfEdgeModelDtoFromJSON,
    ForRefOperationAllOfEdgeModelDtoFromJSONTyped,
    ForRefOperationAllOfEdgeModelDtoToJSON,
} from './ForRefOperationAllOfEdgeModelDto';
import type { OperationDto } from './OperationDto';
import {
    OperationDtoFromJSON,
    OperationDtoFromJSONTyped,
    OperationDtoToJSON,
} from './OperationDto';
import type { OperationTypeDto } from './OperationTypeDto';
import {
    OperationTypeDtoFromJSON,
    OperationTypeDtoFromJSONTyped,
    OperationTypeDtoToJSON,
} from './OperationTypeDto';

/**
 * 
 * @export
 * @interface ForRefOperationDto
 */
export interface ForRefOperationDto {
    /**
     * 
     * @type {OperationTypeDto}
     * @memberof ForRefOperationDto
     */
    operationType: OperationTypeDto;
    /**
     * 
     * @type {Array<OperationDto>}
     * @memberof ForRefOperationDto
     */
    subOperations: Array<OperationDto>;
    /**
     * 
     * @type {ForRefOperationAllOfEdgeModelDto}
     * @memberof ForRefOperationDto
     */
    edgeModel?: ForRefOperationAllOfEdgeModelDto;
    /**
     * 
     * @type {string}
     * @memberof ForRefOperationDto
     */
    joinAlias?: string;
    /**
     * 
     * @type {string}
     * @memberof ForRefOperationDto
     */
    joinCollection?: string;
    /**
     * 
     * @type {string}
     * @memberof ForRefOperationDto
     */
    alias?: string;
}

/**
 * Check if a given object implements the ForRefOperationDto interface.
 */
export function instanceOfForRefOperationDto(value: object): boolean {
    if (!('operationType' in value)) return false;
    if (!('subOperations' in value)) return false;
    return true;
}

export function ForRefOperationDtoFromJSON(json: any): ForRefOperationDto {
    return ForRefOperationDtoFromJSONTyped(json, false);
}

export function ForRefOperationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForRefOperationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'operationType': OperationTypeDtoFromJSON(json['operationType']),
        'subOperations': ((json['subOperations'] as Array<any>).map(OperationDtoFromJSON)),
        'edgeModel': json['edgeModel'] == null ? undefined : ForRefOperationAllOfEdgeModelDtoFromJSON(json['edgeModel']),
        'joinAlias': json['joinAlias'] == null ? undefined : json['joinAlias'],
        'joinCollection': json['joinCollection'] == null ? undefined : json['joinCollection'],
        'alias': json['alias'] == null ? undefined : json['alias'],
    };
}

export function ForRefOperationDtoToJSON(value?: ForRefOperationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'operationType': OperationTypeDtoToJSON(value['operationType']),
        'subOperations': ((value['subOperations'] as Array<any>).map(OperationDtoToJSON)),
        'edgeModel': ForRefOperationAllOfEdgeModelDtoToJSON(value['edgeModel']),
        'joinAlias': value['joinAlias'],
        'joinCollection': value['joinCollection'],
        'alias': value['alias'],
    };
}

