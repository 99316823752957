/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PersonBadgePersonDto
 */
export interface PersonBadgePersonDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof PersonBadgePersonDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PersonBadgePersonDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PersonBadgePersonDto
     */
    surname: string;
    /**
     * The unique key for a person starting with "P".
     * @type {string}
     * @memberof PersonBadgePersonDto
     */
    personKey: string;
}

/**
 * Check if a given object implements the PersonBadgePersonDto interface.
 */
export function instanceOfPersonBadgePersonDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('firstName' in value)) return false;
    if (!('surname' in value)) return false;
    if (!('personKey' in value)) return false;
    return true;
}

export function PersonBadgePersonDtoFromJSON(json: any): PersonBadgePersonDto {
    return PersonBadgePersonDtoFromJSONTyped(json, false);
}

export function PersonBadgePersonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonBadgePersonDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'surname': json['surname'],
        'personKey': json['personKey'],
    };
}

export function PersonBadgePersonDtoToJSON(value?: PersonBadgePersonDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'firstName': value['firstName'],
        'surname': value['surname'],
        'personKey': value['personKey'],
    };
}

