import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const exportMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.export" />,
    path: '/exports',
    hasAccess: (permissions) => permissions.includes('EVENT:FULL-READ'),
  },
];

export default exportMenuItems;
