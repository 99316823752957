import useSWR from 'swr';
import invariant from 'tiny-invariant';
import eventEvaluationApi from '../../../data-access/event-evaluation-api';

export function getPublicEventEvaluationCacheKey(eventEvaluationId: string) {
  return ['publicEventEvaluation', eventEvaluationId];
}

export default function usePublicEventEvaluation(eventEvaluationId: string) {
  const { data } = useSWR(
    getPublicEventEvaluationCacheKey(eventEvaluationId),
    () => eventEvaluationApi.fetchPublicEvaluation({ id: eventEvaluationId }),
    {
      suspense: true,
    },
  );
  invariant(data != null);

  return data;
}
