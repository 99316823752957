/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrderConfirmationStatusDto = {
    NOT_SEND: 'NOT_SEND',
    SEND: 'SEND',
    PAID: 'PAID',
    CANCELLED: 'CANCELLED',
    DECLINED: 'DECLINED',
    EXPIRED: 'EXPIRED',
    INVOICE_GENERATED: 'INVOICE_GENERATED'
} as const;
export type OrderConfirmationStatusDto = typeof OrderConfirmationStatusDto[keyof typeof OrderConfirmationStatusDto];


export function OrderConfirmationStatusDtoFromJSON(json: any): OrderConfirmationStatusDto {
    return OrderConfirmationStatusDtoFromJSONTyped(json, false);
}

export function OrderConfirmationStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderConfirmationStatusDto {
    return json as OrderConfirmationStatusDto;
}

export function OrderConfirmationStatusDtoToJSON(value?: OrderConfirmationStatusDto | null): any {
    return value as any;
}

