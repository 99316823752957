import { mode, StyleConfig, SystemStyleFunction } from '@chakra-ui/theme-tools';

const solid: SystemStyleFunction = (props) => {
  const { colorScheme } = props;

  if (colorScheme === 'teal') {
    return {
      color: 'text.interactive',
      bg: 'layer.02',
      _hover: {
        color: 'text.interactive.hover',
        bg: 'layer.02.hover',
        _disabled: {
          bg: 'layer.02.disabled',
        },
      },
      _active: {
        color: 'text.interactive.active',
        bg: 'layer.02.active',
      },
    };
  }

  return {
    _dark: {
      color: `${colorScheme}.200`,
      bg: `${colorScheme}.900`,
      _hover: {
        color: `${colorScheme}.100`,
        bg: `${colorScheme}.800`,
        _disabled: {
          color: `${colorScheme}.200`,
          bg: `${colorScheme}.900`,
        },
      },
      _active: {
        color: `${colorScheme}.100`,
        bg: `${colorScheme}.700`,
      },
    },
  };
};

const ghost: SystemStyleFunction = (props) => {
  return props.colorScheme === 'teal'
    ? {
        color: 'text.interactive',
        _hover: {
          color: 'text.interactive.hover',
          bg: 'teal.100',
        },
        _active: {
          color: 'text.interactive.active',
          bg: 'teal.100',
        },
        _dark: {
          _hover: {
            bg: 'teal.900',
          },
          _active: {
            bg: 'teal.900',
          },
        },
      }
    : {};
};
const primary = {
  color: 'white',
  bg: 'background.interactive',
  _hover: {
    bg: 'background.interactive.hover',
    _disabled: {
      bg: 'background.interactive',
    },
  },
  _active: {
    bg: 'background.interactive.active',
  },
};

const link: SystemStyleFunction = (props) => {
  return {
    color: mode('teal.700', 'white')(props),
    borderRadius: 'none',
    _hover: {
      color: mode('teal.800', 'teal.400')(props),
      textDecoration: 'none',
    },
    _active: {
      color: mode('teal.900', 'teal.300')(props),
    },
  };
};

const outline: SystemStyleFunction = ({ colorScheme }) => {
  return {
    color: `${colorScheme}.700`,
    borderColor: 'border.01',
    _hover: {
      color: `${colorScheme}.700`,
      bgColor: `${colorScheme}.100`,
    },
    _active: {
      color: `${colorScheme}.700`,
      bgColor: `${colorScheme}.200`,
    },
    _dark: {
      color: `${colorScheme}.300`,
      _hover: {
        bgColor: `${colorScheme}.900`,
      },
      _active: {
        color: `${colorScheme}.200`,
        bgColor: `${colorScheme}.800`,
      },
    },
  };
};

const Button: StyleConfig = {
  baseStyle: {
    borderRadius: 'base',
    flexShrink: 0,
  },
  variants: {
    solid,
    ghost,
    link,
    outline,
    primary,
  },
};

export default Button;
