import { Box, chakra, HStack, Stack, useId } from '@chakra-ui/react';
import { FieldValues } from 'react-hook-form';
import { HistoryEntryDto } from '../../api';
import HistoryDisplaySettings from './history-display-settings';
import HistoryEntryAuthor from './history-entry-author';
import HistoryEntryDescription from './history-entry-description';
import HistoryEntryDiffDataTable from './history-entry-diff-data-table';
import HistoryEntryIcon from './history-entry-icon';
import { useChangedHistoryEntries } from './use-changed-history-entries';

export interface HistoryEntryProps<T extends FieldValues> extends HistoryDisplaySettings<T> {
  entry: HistoryEntryDto;
  showLine?: boolean;
  fetchMerged?(id: string, signal: AbortSignal): Promise<any>;
}

export default function HistoryEntry<T extends FieldValues>({
  entry,
  showLine = true,
  statusFormatter,
  fetchMerged,
  name,
  showEditDescription,
  valueFormatter,
  attributeLabels,
  subElementSettings,
  diffEntireWord,
  preformatted,
  highlightAttributes,
  showStringWithoutChange,
  hideTableForCreatedAndDeleted,
}: HistoryEntryProps<T>) {
  const id = useId(undefined, 'history-entry');

  const rows = useChangedHistoryEntries({
    attributeLabels,
    valueFormatter,
    subElementSettings,
    showStringWithoutChange,
    entry,
  });

  return (
    <Box position="relative">
      <Stack spacing={0.5}>
        <HStack spacing={4} id={`${id}-title`}>
          <HistoryEntryIcon entry={entry} statusFormatter={statusFormatter} />
          <HistoryEntryAuthor entry={entry} />
        </HStack>
        <Box pl={12}>
          <HistoryEntryDescription
            entry={entry}
            statusFormatter={statusFormatter}
            fetchMerged={fetchMerged}
            name={name}
            showEditDescription={showEditDescription}
            id={`${id}-description`}
          />
          <HistoryEntryDiffDataTable
            entry={entry}
            rows={rows}
            statusFormatter={statusFormatter}
            attributeLabels={attributeLabels}
            diffEntireWord={diffEntireWord}
            preformatted={preformatted}
            highlightAttributes={highlightAttributes}
            hideTableForCreatedAndDeleted={hideTableForCreatedAndDeleted}
            aria-labelledby={`${id}-title`}
            aria-describedby={`${id}-description`}
          />
        </Box>
      </Stack>
      {entry.before != null && showLine && <HistoryEntryLine aria-hidden="true" />}
    </Box>
  );
}

const HistoryEntryLine = chakra('div', {
  baseStyle: {
    position: 'absolute',
    borderLeft: '1px',
    borderColor: 'border.01',
    top: 8,
    bottom: -6,
    left: 4,
    transform: 'auto',
    translateX: '-50%',
  },
});
