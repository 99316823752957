/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LaneSectionsInnerDto } from './LaneSectionsInnerDto';
import {
    LaneSectionsInnerDtoFromJSON,
    LaneSectionsInnerDtoFromJSONTyped,
    LaneSectionsInnerDtoToJSON,
} from './LaneSectionsInnerDto';
import type { LaneTypeDto } from './LaneTypeDto';
import {
    LaneTypeDtoFromJSON,
    LaneTypeDtoFromJSONTyped,
    LaneTypeDtoToJSON,
} from './LaneTypeDto';
import type { PermissionsDto } from './PermissionsDto';
import {
    PermissionsDtoFromJSON,
    PermissionsDtoFromJSONTyped,
    PermissionsDtoToJSON,
} from './PermissionsDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * Custom, standard or official lane that are available on the rundown
 * @export
 * @interface LaneDto
 */
export interface LaneDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof LaneDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof LaneDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {LaneTypeDto}
     * @memberof LaneDto
     */
    laneType: LaneTypeDto;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof LaneDto
     */
    eventId: string;
    /**
     * 
     * @type {string}
     * @memberof LaneDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof LaneDto
     */
    englishTitle: string;
    /**
     * 
     * @type {Array<LaneSectionsInnerDto>}
     * @memberof LaneDto
     */
    sections: Array<LaneSectionsInnerDto>;
    /**
     * 
     * @type {boolean}
     * @memberof LaneDto
     */
    isActive: boolean;
    /**
     * 
     * @type {PermissionsDto}
     * @memberof LaneDto
     */
    permissions?: PermissionsDto;
    /**
     * Whether the lane can be viewed
     * @type {boolean}
     * @memberof LaneDto
     */
    canView: boolean;
    /**
     * Whether the lane can be published
     * @type {boolean}
     * @memberof LaneDto
     */
    canPublish: boolean;
    /**
     * Whether the publication can be suspended
     * @type {boolean}
     * @memberof LaneDto
     */
    canSuspendPublication: boolean;
    /**
     * Whether elements can be added to the lane
     * @type {boolean}
     * @memberof LaneDto
     */
    canAddLaneElements: boolean;
}

/**
 * Check if a given object implements the LaneDto interface.
 */
export function instanceOfLaneDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('laneType' in value)) return false;
    if (!('eventId' in value)) return false;
    if (!('title' in value)) return false;
    if (!('englishTitle' in value)) return false;
    if (!('sections' in value)) return false;
    if (!('isActive' in value)) return false;
    if (!('canView' in value)) return false;
    if (!('canPublish' in value)) return false;
    if (!('canSuspendPublication' in value)) return false;
    if (!('canAddLaneElements' in value)) return false;
    return true;
}

export function LaneDtoFromJSON(json: any): LaneDto {
    return LaneDtoFromJSONTyped(json, false);
}

export function LaneDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaneDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'laneType': LaneTypeDtoFromJSON(json['laneType']),
        'eventId': json['eventId'],
        'title': json['title'],
        'englishTitle': json['englishTitle'],
        'sections': ((json['sections'] as Array<any>).map(LaneSectionsInnerDtoFromJSON)),
        'isActive': json['isActive'],
        'permissions': json['permissions'] == null ? undefined : PermissionsDtoFromJSON(json['permissions']),
        'canView': json['canView'],
        'canPublish': json['canPublish'],
        'canSuspendPublication': json['canSuspendPublication'],
        'canAddLaneElements': json['canAddLaneElements'],
    };
}

export function LaneDtoToJSON(value?: LaneDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'laneType': LaneTypeDtoToJSON(value['laneType']),
        'eventId': value['eventId'],
        'title': value['title'],
        'englishTitle': value['englishTitle'],
        'sections': ((value['sections'] as Array<any>).map(LaneSectionsInnerDtoToJSON)),
        'isActive': value['isActive'],
        'permissions': PermissionsDtoToJSON(value['permissions']),
        'canView': value['canView'],
        'canPublish': value['canPublish'],
        'canSuspendPublication': value['canSuspendPublication'],
        'canAddLaneElements': value['canAddLaneElements'],
    };
}

