/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * signed json that can be verified by using the public key of the server
 * @export
 * @interface SignedObjectDto
 */
export interface SignedObjectDto {
    /**
     * signature of the payload
     * @type {string}
     * @memberof SignedObjectDto
     */
    signature: string;
    /**
     * json as string
     * @type {string}
     * @memberof SignedObjectDto
     */
    payload: string;
}

/**
 * Check if a given object implements the SignedObjectDto interface.
 */
export function instanceOfSignedObjectDto(value: object): boolean {
    if (!('signature' in value)) return false;
    if (!('payload' in value)) return false;
    return true;
}

export function SignedObjectDtoFromJSON(json: any): SignedObjectDto {
    return SignedObjectDtoFromJSONTyped(json, false);
}

export function SignedObjectDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignedObjectDto {
    if (json == null) {
        return json;
    }
    return {
        
        'signature': json['signature'],
        'payload': json['payload'],
    };
}

export function SignedObjectDtoToJSON(value?: SignedObjectDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'signature': value['signature'],
        'payload': value['payload'],
    };
}

