/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccessGroupStatusDto } from './AccessGroupStatusDto';
import {
    AccessGroupStatusDtoFromJSON,
    AccessGroupStatusDtoFromJSONTyped,
    AccessGroupStatusDtoToJSON,
} from './AccessGroupStatusDto';
import type { LiveTicketingPriorityDto } from './LiveTicketingPriorityDto';
import {
    LiveTicketingPriorityDtoFromJSON,
    LiveTicketingPriorityDtoFromJSONTyped,
    LiveTicketingPriorityDtoToJSON,
} from './LiveTicketingPriorityDto';

/**
 * 
 * @export
 * @interface AccessGroupListItemDto
 */
export interface AccessGroupListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof AccessGroupListItemDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AccessGroupListItemDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AccessGroupListItemDto
     */
    englishTitle: string;
    /**
     * 
     * @type {LiveTicketingPriorityDto}
     * @memberof AccessGroupListItemDto
     */
    liveTicketingPriority: LiveTicketingPriorityDto;
    /**
     * 
     * @type {AccessGroupStatusDto}
     * @memberof AccessGroupListItemDto
     */
    status: AccessGroupStatusDto;
}

/**
 * Check if a given object implements the AccessGroupListItemDto interface.
 */
export function instanceOfAccessGroupListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('title' in value)) return false;
    if (!('englishTitle' in value)) return false;
    if (!('liveTicketingPriority' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function AccessGroupListItemDtoFromJSON(json: any): AccessGroupListItemDto {
    return AccessGroupListItemDtoFromJSONTyped(json, false);
}

export function AccessGroupListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessGroupListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'englishTitle': json['englishTitle'],
        'liveTicketingPriority': LiveTicketingPriorityDtoFromJSON(json['liveTicketingPriority']),
        'status': AccessGroupStatusDtoFromJSON(json['status']),
    };
}

export function AccessGroupListItemDtoToJSON(value?: AccessGroupListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
        'englishTitle': value['englishTitle'],
        'liveTicketingPriority': LiveTicketingPriorityDtoToJSON(value['liveTicketingPriority']),
        'status': AccessGroupStatusDtoToJSON(value['status']),
    };
}

