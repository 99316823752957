import { ChakraProps, Link, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import logoBerlinalX from '../../feature/app/app-navigation/logo_berlinalx.svg';
import logoBerlinalXCollapsed from '../../feature/app/app-navigation/logo_berlinalx_collapsed.svg';

export interface LogoProps extends ChakraProps {
  isCollapsed?: boolean;
  logoutOnClick?: boolean;
}

export default function Logo({ isCollapsed = false, logoutOnClick = false, ...props }: LogoProps) {
  const { t } = useTranslation('app');
  const version = import.meta.env.VITE_APP_VERSION ?? '0.0.0';

  return (
    <Link {...props} as={RouterLink} to={logoutOnClick ? '/logout' : '/'}>
      <Tooltip label={t('project_version', { version: version })}>
        <img src={isCollapsed ? logoBerlinalXCollapsed : logoBerlinalX} alt={t('project_title')} />
      </Tooltip>
    </Link>
  );
}
