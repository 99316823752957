/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GroupCompanyDto } from './GroupCompanyDto';
import {
    GroupCompanyDtoFromJSON,
    GroupCompanyDtoFromJSONTyped,
    GroupCompanyDtoToJSON,
} from './GroupCompanyDto';
import type { GroupDto } from './GroupDto';
import {
    GroupDtoFromJSON,
    GroupDtoFromJSONTyped,
    GroupDtoToJSON,
} from './GroupDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * The connection between a group and a company
 * @export
 * @interface GroupCompanyRelationDto
 */
export interface GroupCompanyRelationDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof GroupCompanyRelationDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof GroupCompanyRelationDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {GroupDto}
     * @memberof GroupCompanyRelationDto
     */
    group: GroupDto;
    /**
     * 
     * @type {GroupCompanyDto}
     * @memberof GroupCompanyRelationDto
     */
    company: GroupCompanyDto;
}

/**
 * Check if a given object implements the GroupCompanyRelationDto interface.
 */
export function instanceOfGroupCompanyRelationDto(value: object): boolean {
    if (!('group' in value)) return false;
    if (!('company' in value)) return false;
    return true;
}

export function GroupCompanyRelationDtoFromJSON(json: any): GroupCompanyRelationDto {
    return GroupCompanyRelationDtoFromJSONTyped(json, false);
}

export function GroupCompanyRelationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupCompanyRelationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'group': GroupDtoFromJSON(json['group']),
        'company': GroupCompanyDtoFromJSON(json['company']),
    };
}

export function GroupCompanyRelationDtoToJSON(value?: GroupCompanyRelationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'group': GroupDtoToJSON(value['group']),
        'company': GroupCompanyDtoToJSON(value['company']),
    };
}

