/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StaffDto } from './StaffDto';
import {
    StaffDtoFromJSON,
    StaffDtoFromJSONTyped,
    StaffDtoToJSON,
} from './StaffDto';

/**
 * 
 * @export
 * @interface StaffSearchResultDto
 */
export interface StaffSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof StaffSearchResultDto
     */
    resultType: StaffSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof StaffSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {StaffDto}
     * @memberof StaffSearchResultDto
     */
    result: StaffDto;
}


/**
 * @export
 */
export const StaffSearchResultDtoResultTypeEnum = {
    STAFF: 'STAFF'
} as const;
export type StaffSearchResultDtoResultTypeEnum = typeof StaffSearchResultDtoResultTypeEnum[keyof typeof StaffSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the StaffSearchResultDto interface.
 */
export function instanceOfStaffSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function StaffSearchResultDtoFromJSON(json: any): StaffSearchResultDto {
    return StaffSearchResultDtoFromJSONTyped(json, false);
}

export function StaffSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': StaffDtoFromJSON(json['result']),
    };
}

export function StaffSearchResultDtoToJSON(value?: StaffSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': StaffDtoToJSON(value['result']),
    };
}

