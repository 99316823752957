/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParticipationFilmSubmissionsInnerDto
 */
export interface ParticipationFilmSubmissionsInnerDto {
    /**
     * 
     * @type {string}
     * @memberof ParticipationFilmSubmissionsInnerDto
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipationFilmSubmissionsInnerDto
     */
    label?: ParticipationFilmSubmissionsInnerDtoLabelEnum;
    /**
     * 
     * @type {Date}
     * @memberof ParticipationFilmSubmissionsInnerDto
     */
    timestamp?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ParticipationFilmSubmissionsInnerDto
     */
    createdAt: Date;
}


/**
 * @export
 */
export const ParticipationFilmSubmissionsInnerDtoLabelEnum = {
    FESTIVAL: 'FESTIVAL',
    EFM: 'EFM'
} as const;
export type ParticipationFilmSubmissionsInnerDtoLabelEnum = typeof ParticipationFilmSubmissionsInnerDtoLabelEnum[keyof typeof ParticipationFilmSubmissionsInnerDtoLabelEnum];


/**
 * Check if a given object implements the ParticipationFilmSubmissionsInnerDto interface.
 */
export function instanceOfParticipationFilmSubmissionsInnerDto(value: object): boolean {
    if (!('createdAt' in value)) return false;
    return true;
}

export function ParticipationFilmSubmissionsInnerDtoFromJSON(json: any): ParticipationFilmSubmissionsInnerDto {
    return ParticipationFilmSubmissionsInnerDtoFromJSONTyped(json, false);
}

export function ParticipationFilmSubmissionsInnerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipationFilmSubmissionsInnerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'text': json['text'] == null ? undefined : json['text'],
        'label': json['label'] == null ? undefined : json['label'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function ParticipationFilmSubmissionsInnerDtoToJSON(value?: ParticipationFilmSubmissionsInnerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'text': value['text'],
        'label': value['label'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
        'createdAt': ((value['createdAt']).toISOString()),
    };
}

