import { useCallbackRef } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { To, useNavigate } from 'react-router-dom';
import ConfirmDialog from '../../../ui/dialog/confirm-dialog';
import useDialog from '../../../util/use-dialog/use-dialog';
import useSessionStorage from '../../../util/use-session-storage/use-session-storage';
import isMobile from '../auth/login/is-mobile';

interface SwitchMobileProps {
  to: To;
}

export default function SwitchMobile({ to }: SwitchMobileProps) {
  const navigate = useNavigate();
  const { switchMobile, switchMobileDialog } = useSwitchMobile();

  const navigateToMobile = useCallbackRef(async () => {
    if (await switchMobile()) {
      navigate(to);
    }
  });

  React.useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    navigateToMobile();
  }, [navigateToMobile]);

  return switchMobileDialog;
}

export function useSwitchMobileStorage() {
  const [checked, setChecked] = useSessionStorage('switch-mobile-check', false);

  const reset = useCallbackRef(() => {
    setChecked(false);
  });

  return { checked, setChecked, reset };
}

export function useSwitchMobile() {
  const { t } = useTranslation('app');
  const { checked, setChecked } = useSwitchMobileStorage();
  const [isDialogOpen, onDialogClose, openDialog] = useDialog<boolean>();

  const switchMobile = useCallbackRef(async () => {
    if (checked || !Mockables.isMobile()) {
      return false;
    }

    setChecked(true);

    return openDialog();
  });

  const switchMobileDialog = (
    <ConfirmDialog
      isOpen={isDialogOpen}
      onClose={onDialogClose}
      submitButtonVariant="primary"
      cancelActionLabel={t('redirect_options.desktop')}
      confirmActionLabel={t('redirect_options.mobile')}
    >
      {t('redirect_to_mobile')}
    </ConfirmDialog>
  );

  return { switchMobile, switchMobileDialog, isSwitchMobileDialogOpen: isDialogOpen };
}

// This is object enables to mock isMobile in the corresponding Cypress test.
export const Mockables = {
  isMobile: isMobile,
};
