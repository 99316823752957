import { Box, Button, Flex, Stack, useId } from '@chakra-ui/react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Dialog from '../../../ui/dialog/dialog';
import CheckboxControl from '../../../ui/form/checkbox-control';
import Form from '../../../ui/form/form';

export interface EventCopySettings {
  sourceEventId: string;
  copyKeyPlayerList: boolean;
  copyRundown: boolean;
  copyGuestList: boolean;
}

interface CopySettingsDialogProps {
  leastDestructiveRef: React.RefObject<HTMLButtonElement>;
  isOpen: boolean;
  onClose: (value: boolean) => void;
  copySettingsRef: React.MutableRefObject<EventCopySettings>;
}

export default function CopySettingsDialog({
  leastDestructiveRef,
  isOpen,
  onClose,
  copySettingsRef,
}: CopySettingsDialogProps) {
  const { t } = useTranslation(['common', 'event']);
  const formId = useId();

  const form = useForm<EventCopySettings>({
    mode: 'all',
  });

  const handleValid = async (settings: EventCopySettings) => {
    copySettingsRef.current = {
      ...copySettingsRef.current,
      copyKeyPlayerList: settings.copyKeyPlayerList ?? false,
      copyRundown: settings.copyRundown ?? false,
      copyGuestList: settings.copyGuestList ?? false,
    };
    onClose(true);
  };
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  return (
    <Dialog
      leastDestructiveRef={leastDestructiveRef}
      isOpen={isOpen}
      size="lg"
      onClose={() => onClose(false)}
      closeOnOverlayClick={false}
      title={t('event:editor.copy_info.title')}
      footer={
        <Flex mt={4} width="100%" justifyContent="space-between">
          <Button onClick={() => onClose(false)}>{t('common:action.abort')}</Button>
          <Button type="submit" form={formId} variant="primary">
            {t('common:action.apply')}
          </Button>
        </Flex>
      }
    >
      <FormProvider {...form}>
        <Form<EventCopySettings> onValid={handleValid} id={formId} initialFocusRef={initialFocusRef}>
          <Stack spacing={4}>
            <Box>{t('event:editor.copy_info.extra')}</Box>
            <CheckboxControl<EventCopySettings>
              name="copyKeyPlayerList"
              label={t('event:editor.copy_with_keyplayers')}
            />
            <CheckboxControl<EventCopySettings> name="copyRundown" label={t('event:editor.copy_with_rundown')} />
            <CheckboxControl<EventCopySettings> name="copyGuestList" label={t('event:editor.copy_with_guestList')} />
          </Stack>
        </Form>
      </FormProvider>
    </Dialog>
  );
}
