/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ConfirmationStatusDto } from './ConfirmationStatusDto';
import {
    ConfirmationStatusDtoFromJSON,
    ConfirmationStatusDtoFromJSONTyped,
    ConfirmationStatusDtoToJSON,
} from './ConfirmationStatusDto';
import type { CountryReferenceDto } from './CountryReferenceDto';
import {
    CountryReferenceDtoFromJSON,
    CountryReferenceDtoFromJSONTyped,
    CountryReferenceDtoToJSON,
} from './CountryReferenceDto';
import type { FormatEmailAddressDto } from './FormatEmailAddressDto';
import {
    FormatEmailAddressDtoFromJSON,
    FormatEmailAddressDtoFromJSONTyped,
    FormatEmailAddressDtoToJSON,
} from './FormatEmailAddressDto';
import type { FormatReferenceDto } from './FormatReferenceDto';
import {
    FormatReferenceDtoFromJSON,
    FormatReferenceDtoFromJSONTyped,
    FormatReferenceDtoToJSON,
} from './FormatReferenceDto';
import type { FormatStatusDto } from './FormatStatusDto';
import {
    FormatStatusDtoFromJSON,
    FormatStatusDtoFromJSONTyped,
    FormatStatusDtoToJSON,
} from './FormatStatusDto';
import type { MediaTypeDto } from './MediaTypeDto';
import {
    MediaTypeDtoFromJSON,
    MediaTypeDtoFromJSONTyped,
    MediaTypeDtoToJSON,
} from './MediaTypeDto';
import type { PeriodicityDto } from './PeriodicityDto';
import {
    PeriodicityDtoFromJSON,
    PeriodicityDtoFromJSONTyped,
    PeriodicityDtoToJSON,
} from './PeriodicityDto';
import type { ReachDto } from './ReachDto';
import {
    ReachDtoFromJSON,
    ReachDtoFromJSONTyped,
    ReachDtoToJSON,
} from './ReachDto';
import type { RessortDto } from './RessortDto';
import {
    RessortDtoFromJSON,
    RessortDtoFromJSONTyped,
    RessortDtoToJSON,
} from './RessortDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface FormatDto
 */
export interface FormatDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof FormatDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof FormatDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof FormatDto
     */
    name: string;
    /**
     * List of synonyms of the company.
     * @type {Array<string>}
     * @memberof FormatDto
     */
    synonyms: Array<string>;
    /**
     * 
     * @type {Array<FormatEmailAddressDto>}
     * @memberof FormatDto
     */
    emailAddresses: Array<FormatEmailAddressDto>;
    /**
     * List of links
     * @type {Array<string>}
     * @memberof FormatDto
     */
    links?: Array<string>;
    /**
     * 
     * @type {MediaTypeDto}
     * @memberof FormatDto
     */
    mediaType: MediaTypeDto;
    /**
     * 
     * @type {Array<RessortDto>}
     * @memberof FormatDto
     */
    ressorts: Array<RessortDto>;
    /**
     * 
     * @type {Array<PeriodicityDto>}
     * @memberof FormatDto
     */
    periodicity: Array<PeriodicityDto>;
    /**
     * 
     * @type {Array<ReachDto>}
     * @memberof FormatDto
     */
    reach: Array<ReachDto>;
    /**
     * 
     * @type {Array<CountryReferenceDto>}
     * @memberof FormatDto
     */
    publicationCountries: Array<CountryReferenceDto>;
    /**
     * 
     * @type {ConfirmationStatusDto}
     * @memberof FormatDto
     */
    confirmationStatus: ConfirmationStatusDto;
    /**
     * 
     * @type {FormatStatusDto}
     * @memberof FormatDto
     */
    status: FormatStatusDto;
    /**
     * 
     * @type {FormatReferenceDto}
     * @memberof FormatDto
     */
    mergedInto?: FormatReferenceDto;
}

/**
 * Check if a given object implements the FormatDto interface.
 */
export function instanceOfFormatDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('synonyms' in value)) return false;
    if (!('emailAddresses' in value)) return false;
    if (!('mediaType' in value)) return false;
    if (!('ressorts' in value)) return false;
    if (!('periodicity' in value)) return false;
    if (!('reach' in value)) return false;
    if (!('publicationCountries' in value)) return false;
    if (!('confirmationStatus' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function FormatDtoFromJSON(json: any): FormatDto {
    return FormatDtoFromJSONTyped(json, false);
}

export function FormatDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormatDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'name': json['name'],
        'synonyms': json['synonyms'],
        'emailAddresses': ((json['emailAddresses'] as Array<any>).map(FormatEmailAddressDtoFromJSON)),
        'links': json['links'] == null ? undefined : json['links'],
        'mediaType': MediaTypeDtoFromJSON(json['mediaType']),
        'ressorts': ((json['ressorts'] as Array<any>).map(RessortDtoFromJSON)),
        'periodicity': ((json['periodicity'] as Array<any>).map(PeriodicityDtoFromJSON)),
        'reach': ((json['reach'] as Array<any>).map(ReachDtoFromJSON)),
        'publicationCountries': ((json['publicationCountries'] as Array<any>).map(CountryReferenceDtoFromJSON)),
        'confirmationStatus': ConfirmationStatusDtoFromJSON(json['confirmationStatus']),
        'status': FormatStatusDtoFromJSON(json['status']),
        'mergedInto': json['mergedInto'] == null ? undefined : FormatReferenceDtoFromJSON(json['mergedInto']),
    };
}

export function FormatDtoToJSON(value?: FormatDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'name': value['name'],
        'synonyms': value['synonyms'],
        'emailAddresses': ((value['emailAddresses'] as Array<any>).map(FormatEmailAddressDtoToJSON)),
        'links': value['links'],
        'mediaType': MediaTypeDtoToJSON(value['mediaType']),
        'ressorts': ((value['ressorts'] as Array<any>).map(RessortDtoToJSON)),
        'periodicity': ((value['periodicity'] as Array<any>).map(PeriodicityDtoToJSON)),
        'reach': ((value['reach'] as Array<any>).map(ReachDtoToJSON)),
        'publicationCountries': ((value['publicationCountries'] as Array<any>).map(CountryReferenceDtoToJSON)),
        'confirmationStatus': ConfirmationStatusDtoToJSON(value['confirmationStatus']),
        'status': FormatStatusDtoToJSON(value['status']),
        'mergedInto': FormatReferenceDtoToJSON(value['mergedInto']),
    };
}

