import React from 'react';
import { useTranslation } from 'react-i18next';
import { EventDto, EventListItemDto } from '../../../api';
import FinalDeleteConfirmDialog, {
  createDefaultDeleteChecklistItem,
} from '../../../ui/dialog/final-delete-confirm-dialog';

export interface EventDeleteDialogProps {
  event: EventListItemDto | EventDto;
  connectedEvents: Array<string>;
  isOpen: boolean;

  onClose(confirm: boolean): void;
}

export default function EventDeleteDialog({ event, connectedEvents, onClose, ...props }: EventDeleteDialogProps) {
  const { t } = useTranslation('event');

  const hasConnectedEvents = connectedEvents.length >= 1;
  const additionalInfoConnectedEventsOnCheckListTask = hasConnectedEvents
    ? t('delete_confirmation_with_events_additional_infos')
    : undefined;
  const additionalInfoConnectedEventsIntro = hasConnectedEvents
    ? t('delete_intro', { connectedEvents: connectedEvents, count: connectedEvents.length })
    : undefined;

  const confirmationMessageChecklistItems = [
    createDefaultDeleteChecklistItem({
      objectTypeWithArticleGenitive: t('eventWithArticle'),
      objectToDelete: event.title,
      additionalInfo: additionalInfoConnectedEventsOnCheckListTask,
    }),
  ];

  return (
    <FinalDeleteConfirmDialog
      {...props}
      objectTypeToDelete={t('event')}
      confirmationCheckListItems={confirmationMessageChecklistItems}
      infoText={additionalInfoConnectedEventsIntro}
      onClose={onClose}
    />
  );
}
