import { HStack, StackItem, useColorModeValue } from '@chakra-ui/react';
import { faCrown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { RefObject } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StandCompanyRelationDto, StandCompanyRelationTypeDto } from '../../../api';
import ValueSelectFormControl from '../../../ui/form/select-control/value-select-form-control';
import { standCompanyRelationTypeOptions } from '../stand-enum-constants';

export interface StandCompanyRelationTypeSelectControlProps {
  initialFocusRef?: RefObject<HTMLInputElement | HTMLElement>;
}

export function StandCompanyRelationTypeSelectControl({ initialFocusRef }: StandCompanyRelationTypeSelectControlProps) {
  const { t } = useTranslation('stand');
  const { trigger } = useFormContext<StandCompanyRelationDto>();

  return (
    <ValueSelectFormControl<StandCompanyRelationTypeDto, StandCompanyRelationDto>
      label={t('companyRelation.relationType')}
      options={standCompanyRelationTypeOptions}
      renderLabel={(value) => <StandCompanyRelationTypeRenderLabel relationType={value} />}
      getStringValue={(value) => t(`companyRelation.relationTypeOptions.${value}`)}
      name="relationType"
      ref={initialFocusRef}
      isRequired
      onChange={() => trigger('company')}
    />
  );
}

function StandCompanyRelationTypeRenderLabel({ relationType }: { relationType: StandCompanyRelationTypeDto }) {
  const { t } = useTranslation('stand');
  const iconColor = useColorModeValue(undefined, 'gold');

  return (
    <HStack spacing={2} width="full" overflow="hidden">
      {relationType == StandCompanyRelationTypeDto.MAIN_PARTNER && <FontAwesomeIcon color={iconColor} icon={faCrown} />}
      <StackItem noOfLines={1} flexShrink={1}>
        {t(`companyRelation.relationTypeOptions.${relationType}`)}
      </StackItem>
    </HStack>
  );
}
