import {
  EventEvaluationAudienceStatusDto,
  EventEvaluationDtoFilmTeamEnum,
  EventEvaluationDtoRealisationEnum,
  EventEvaluationTechnicalDisruptionsDto,
} from '../../api';

export const realisationOptions = [
  EventEvaluationDtoRealisationEnum.ON_SCHEDULE,
  EventEvaluationDtoRealisationEnum.DELAYED,
];

export const audienceStatusOptions = [
  EventEvaluationAudienceStatusDto.NO_PROBLEMS,
  EventEvaluationAudienceStatusDto.PROBLEMS,
];

export const filmTeamAttendanceOptions = [
  EventEvaluationDtoFilmTeamEnum.PRESENT,
  EventEvaluationDtoFilmTeamEnum.NOT_PRESENT,
];

export const technicalDisruptionOptions = [
  EventEvaluationTechnicalDisruptionsDto.PICTURE,
  EventEvaluationTechnicalDisruptionsDto.SOUND,
];
