/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  HistoryPageDto,
  LandingPageDesignDto,
  LandingPageDesignPageDto,
  LandingPageDesignStatusBlockerDto,
  LandingPageDesignStatusDto,
  PageableDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    LandingPageDesignDtoFromJSON,
    LandingPageDesignDtoToJSON,
    LandingPageDesignPageDtoFromJSON,
    LandingPageDesignPageDtoToJSON,
    LandingPageDesignStatusBlockerDtoFromJSON,
    LandingPageDesignStatusBlockerDtoToJSON,
    LandingPageDesignStatusDtoFromJSON,
    LandingPageDesignStatusDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface ActivateLandingPageDesignRequest {
    id: string;
}

export interface ArchiveLandingPageDesignRequest {
    id: string;
}

export interface CreateLandingPageDesignRequest {
    landingPageDesignDto: LandingPageDesignDto;
}

export interface DeleteLandingPageDesignRequest {
    id: string;
}

export interface FetchLandingPageDesignRequest {
    id: string;
}

export interface FetchLandingPageDesignHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FindDesignRequest {
    brand: string;
    editionId?: string;
}

export interface GetLandingPageDesignStatusBlockersRequest {
    id: string;
}

export interface SearchLandingPageDesignBrandsRequest {
    q?: string;
    brand?: string;
    editionId?: string;
    status?: Array<LandingPageDesignStatusDto>;
}

export interface SearchLandingPageDesignsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface TemplateLandingPageDesignRequest {
    brand: string;
}

export interface UpdateLandingPageDesignRequest {
    id: string;
    landingPageDesignDto: LandingPageDesignDto;
}

/**
 * 
 */
export class LandingPageDesignApi extends runtime.BaseAPI {

    /**
     * Activate landing page design
     */
    async activateLandingPageDesignRaw(requestParameters: ActivateLandingPageDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling activateLandingPageDesign().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-designs/{id}/activate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Activate landing page design
     */
    async activateLandingPageDesign(requestParameters: ActivateLandingPageDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.activateLandingPageDesignRaw(requestParameters, initOverrides);
    }

    /**
     * Archive landing page design
     */
    async archiveLandingPageDesignRaw(requestParameters: ArchiveLandingPageDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling archiveLandingPageDesign().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-designs/{id}/archive`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archive landing page design
     */
    async archiveLandingPageDesign(requestParameters: ArchiveLandingPageDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.archiveLandingPageDesignRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new landingPageDesign.
     */
    async createLandingPageDesignRaw(requestParameters: CreateLandingPageDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['landingPageDesignDto'] == null) {
            throw new runtime.RequiredError(
                'landingPageDesignDto',
                'Required parameter "landingPageDesignDto" was null or undefined when calling createLandingPageDesign().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-designs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LandingPageDesignDtoToJSON(requestParameters['landingPageDesignDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new landingPageDesign.
     */
    async createLandingPageDesign(requestParameters: CreateLandingPageDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createLandingPageDesignRaw(requestParameters, initOverrides);
    }

    /**
     * Delete landing page design
     */
    async deleteLandingPageDesignRaw(requestParameters: DeleteLandingPageDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteLandingPageDesign().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-designs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete landing page design
     */
    async deleteLandingPageDesign(requestParameters: DeleteLandingPageDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteLandingPageDesignRaw(requestParameters, initOverrides);
    }

    /**
     * Get the landingPageDesign business object.
     */
    async fetchLandingPageDesignRaw(requestParameters: FetchLandingPageDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LandingPageDesignDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchLandingPageDesign().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-designs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LandingPageDesignDtoFromJSON(jsonValue));
    }

    /**
     * Get the landingPageDesign business object.
     */
    async fetchLandingPageDesign(requestParameters: FetchLandingPageDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LandingPageDesignDto> {
        const response = await this.fetchLandingPageDesignRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the landingPageDesign.
     */
    async fetchLandingPageDesignHistoryRaw(requestParameters: FetchLandingPageDesignHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchLandingPageDesignHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-designs/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the landingPageDesign.
     */
    async fetchLandingPageDesignHistory(requestParameters: FetchLandingPageDesignHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchLandingPageDesignHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find the design for the specified brand and edition
     */
    async findDesignRaw(requestParameters: FindDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LandingPageDesignDto>> {
        if (requestParameters['brand'] == null) {
            throw new runtime.RequiredError(
                'brand',
                'Required parameter "brand" was null or undefined when calling findDesign().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['brand'] != null) {
            queryParameters['brand'] = requestParameters['brand'];
        }

        if (requestParameters['editionId'] != null) {
            queryParameters['editionId'] = requestParameters['editionId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-designs/find-design`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LandingPageDesignDtoFromJSON(jsonValue));
    }

    /**
     * Find the design for the specified brand and edition
     */
    async findDesign(requestParameters: FindDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LandingPageDesignDto | null | undefined > {
        const response = await this.findDesignRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Get the status blockers of a landing page design.
     */
    async getLandingPageDesignStatusBlockersRaw(requestParameters: GetLandingPageDesignStatusBlockersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LandingPageDesignStatusBlockerDto>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getLandingPageDesignStatusBlockers().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-designs/{id}/status-blockers`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LandingPageDesignStatusBlockerDtoFromJSON));
    }

    /**
     * Get the status blockers of a landing page design.
     */
    async getLandingPageDesignStatusBlockers(requestParameters: GetLandingPageDesignStatusBlockersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LandingPageDesignStatusBlockerDto>> {
        const response = await this.getLandingPageDesignStatusBlockersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for brands.
     */
    async searchLandingPageDesignBrandsRaw(requestParameters: SearchLandingPageDesignBrandsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['brand'] != null) {
            queryParameters['brand'] = requestParameters['brand'];
        }

        if (requestParameters['editionId'] != null) {
            queryParameters['editionId'] = requestParameters['editionId'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-designs/brands`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Search for brands.
     */
    async searchLandingPageDesignBrands(requestParameters: SearchLandingPageDesignBrandsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.searchLandingPageDesignBrandsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for landing page designs.
     */
    async searchLandingPageDesignsRaw(requestParameters: SearchLandingPageDesignsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LandingPageDesignPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-designs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LandingPageDesignPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for landing page designs.
     */
    async searchLandingPageDesigns(requestParameters: SearchLandingPageDesignsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LandingPageDesignPageDto> {
        const response = await this.searchLandingPageDesignsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new landingPageDesign.
     */
    async templateLandingPageDesignRaw(requestParameters: TemplateLandingPageDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LandingPageDesignDto>> {
        if (requestParameters['brand'] == null) {
            throw new runtime.RequiredError(
                'brand',
                'Required parameter "brand" was null or undefined when calling templateLandingPageDesign().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['brand'] != null) {
            queryParameters['brand'] = requestParameters['brand'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-designs/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LandingPageDesignDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new landingPageDesign.
     */
    async templateLandingPageDesign(requestParameters: TemplateLandingPageDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LandingPageDesignDto> {
        const response = await this.templateLandingPageDesignRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the existing landingPageDesign.
     */
    async updateLandingPageDesignRaw(requestParameters: UpdateLandingPageDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateLandingPageDesign().'
            );
        }

        if (requestParameters['landingPageDesignDto'] == null) {
            throw new runtime.RequiredError(
                'landingPageDesignDto',
                'Required parameter "landingPageDesignDto" was null or undefined when calling updateLandingPageDesign().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-designs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LandingPageDesignDtoToJSON(requestParameters['landingPageDesignDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the existing landingPageDesign.
     */
    async updateLandingPageDesign(requestParameters: UpdateLandingPageDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateLandingPageDesignRaw(requestParameters, initOverrides);
    }

}
