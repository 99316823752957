import { chakra, Grid, GridItem } from '@chakra-ui/react';
import React from 'react';
import PageSpinner from '../../../ui/page/page-spinner';
import AppNavigation from '../app-navigation/app-navigation';
import MenuItem from '../app-navigation/menu-item';
import SubMenuItem from '../app-navigation/sub-menu-item';
import EditionEndHint from '../edition-end-hint';

export interface AppLayoutProps {
  mainMenuItems: MenuItem[];
  accountMenuItems: SubMenuItem[];
  children?: React.ReactNode;
}

function AppLayout({ children, mainMenuItems, accountMenuItems }: AppLayoutProps) {
  return (
    <React.Suspense fallback={<PageSpinner />}>
      <Grid sx={{ gridTemplateColumns: 'auto 1fr', bg: 'background' }}>
        <GridItem position="sticky" top={0} zIndex="sticky" alignSelf="start" overflowY="auto">
          <AppNavigation mainMenuItems={mainMenuItems} accountMenuItems={accountMenuItems} />
        </GridItem>
        <GridItem minWidth={0}>
          <chakra.div
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <React.Suspense fallback={<PageSpinner />}>{children}</React.Suspense>
            {/* TODO cyclic dependency should use plugin registry instead */}
            <EditionEndHint />
          </chakra.div>
        </GridItem>
      </Grid>
    </React.Suspense>
  );
}

export default AppLayout;
