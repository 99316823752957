/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccessGroupReferenceDto } from './AccessGroupReferenceDto';
import {
    AccessGroupReferenceDtoFromJSON,
    AccessGroupReferenceDtoFromJSONTyped,
    AccessGroupReferenceDtoToJSON,
} from './AccessGroupReferenceDto';
import type { PriceDto } from './PriceDto';
import {
    PriceDtoFromJSON,
    PriceDtoFromJSONTyped,
    PriceDtoToJSON,
} from './PriceDto';
import type { ProductReferenceDto } from './ProductReferenceDto';
import {
    ProductReferenceDtoFromJSON,
    ProductReferenceDtoFromJSONTyped,
    ProductReferenceDtoToJSON,
} from './ProductReferenceDto';

/**
 * 
 * @export
 * @interface OrderProductViewerItemDto
 */
export interface OrderProductViewerItemDto {
    /**
     * 
     * @type {ProductReferenceDto}
     * @memberof OrderProductViewerItemDto
     */
    reference: ProductReferenceDto;
    /**
     * 
     * @type {Array<AccessGroupReferenceDto>}
     * @memberof OrderProductViewerItemDto
     */
    accessGroups: Array<AccessGroupReferenceDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderProductViewerItemDto
     */
    excludedCombinations: Array<string>;
    /**
     * 
     * @type {PriceDto}
     * @memberof OrderProductViewerItemDto
     */
    price: PriceDto;
}

/**
 * Check if a given object implements the OrderProductViewerItemDto interface.
 */
export function instanceOfOrderProductViewerItemDto(value: object): boolean {
    if (!('reference' in value)) return false;
    if (!('accessGroups' in value)) return false;
    if (!('excludedCombinations' in value)) return false;
    if (!('price' in value)) return false;
    return true;
}

export function OrderProductViewerItemDtoFromJSON(json: any): OrderProductViewerItemDto {
    return OrderProductViewerItemDtoFromJSONTyped(json, false);
}

export function OrderProductViewerItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderProductViewerItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'reference': ProductReferenceDtoFromJSON(json['reference']),
        'accessGroups': ((json['accessGroups'] as Array<any>).map(AccessGroupReferenceDtoFromJSON)),
        'excludedCombinations': json['excludedCombinations'],
        'price': PriceDtoFromJSON(json['price']),
    };
}

export function OrderProductViewerItemDtoToJSON(value?: OrderProductViewerItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'reference': ProductReferenceDtoToJSON(value['reference']),
        'accessGroups': ((value['accessGroups'] as Array<any>).map(AccessGroupReferenceDtoToJSON)),
        'excludedCombinations': value['excludedCombinations'],
        'price': PriceDtoToJSON(value['price']),
    };
}

