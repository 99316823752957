import React from 'react';
import { PluginToken } from '../../../util/plugin/use-plugins';

export interface GuestListDeleteAllocationExtension {
  shouldShowDialog: (id: string) => Promise<boolean>;
  body: (allocationName: string, placementLink: string) => React.ReactNode;
  onDeleteLabel: React.ReactNode;
}

export const GUEST_LIST_DELETE_ALLOCATION_EXTENSION = new PluginToken<GuestListDeleteAllocationExtension>(
  'GuestListDeleteAllocationExtension',
);
