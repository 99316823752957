/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RecipientLinkDto } from './RecipientLinkDto';
import {
    RecipientLinkDtoFromJSON,
    RecipientLinkDtoFromJSONTyped,
    RecipientLinkDtoToJSON,
} from './RecipientLinkDto';

/**
 * A list of ids and specified recipient type
 * @export
 * @interface AddRecipientsDto
 */
export interface AddRecipientsDto {
    /**
     * 
     * @type {Array<RecipientLinkDto>}
     * @memberof AddRecipientsDto
     */
    recipients: Array<RecipientLinkDto>;
}

/**
 * Check if a given object implements the AddRecipientsDto interface.
 */
export function instanceOfAddRecipientsDto(value: object): boolean {
    if (!('recipients' in value)) return false;
    return true;
}

export function AddRecipientsDtoFromJSON(json: any): AddRecipientsDto {
    return AddRecipientsDtoFromJSONTyped(json, false);
}

export function AddRecipientsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddRecipientsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'recipients': ((json['recipients'] as Array<any>).map(RecipientLinkDtoFromJSON)),
    };
}

export function AddRecipientsDtoToJSON(value?: AddRecipientsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'recipients': ((value['recipients'] as Array<any>).map(RecipientLinkDtoToJSON)),
    };
}

