import React from 'react';
import { DescriptionGroup, DescriptionItem } from '../../ui/description';
import { codeIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { MAILING_MODULE_TYPE_EXTENSION } from '../mailing/mailing-module-type-extension';
import { MAILING_TYPE_EXTENSION } from '../mailing/mailing-type-extension';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { useCodePermissionClassGroupExtension } from './code-permissions';
import CodeSearchResult from './code-search-result';
import CodeConfigMailingModuleControl from './mailing/code-config-mailing-module-control';
import { createCodeMailing } from './mailing/code-mailing-extension';

export default function CodePluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, { type: 'CODE', Component: CodeSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'CODE',
    icon: codeIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.CODE" />,
    necessaryPermission: 'CODE:CAN_SEE_DETAILS',
  });
  registry.register(MAILING_MODULE_TYPE_EXTENSION, {
    type: 'CODE',
    moduleControl() {
      return (
        <DescriptionGroup>
          <DescriptionItem span={2} label={<Translate i18nKey="code_module.label" ns="mailing" />}>
            <Translate i18nKey="code_module.active" ns="mailing" />
          </DescriptionItem>
        </DescriptionGroup>
      );
    },
  });

  registry.register(MAILING_MODULE_TYPE_EXTENSION, {
    type: 'CODE_CONFIG',
    moduleControl({ name }) {
      return <CodeConfigMailingModuleControl name={name} />;
    },
  });

  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useCodePermissionClassGroupExtension);
  registry.register(MAILING_TYPE_EXTENSION, createCodeMailing());
}
