import { Box, tokenToCSSVar, useTheme } from '@chakra-ui/react';
import React from 'react';
import connectionArrow from '../../common/connection-arrow-light.svg';

export interface ConnectionLineProps {
  position: 'bottom' | 'top';
  showArrow?: boolean;
  spacing?: number;
}

export default function ConnectionLine({ position, showArrow, spacing = 2 }: ConnectionLineProps) {
  const theme = useTheme();
  const spacingCssVar = tokenToCSSVar('space', 3)(theme);

  return (
    <Box
      w={5}
      position="absolute"
      left={0}
      bgImage={connectionArrow}
      bgRepeat="no-repeat"
      top={position === 'bottom' ? `calc(50% + ${spacingCssVar})` : spacing / -2}
      bottom={position === 'top' ? `calc(50% + ${spacingCssVar})` : spacing / -2}
      // Arrow is at the bottom of the background image …
      bgPosition={`center ${showArrow ? 'bottom' : 'top'}`}
    />
  );
}
