import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../../permission-class-group/permission-class-group-extension';

export function useAccessGroupPersonPermissionClassGroupExtension(): PermissionClassGroupExtension<'ACCESS_GROUP-PERSON'> {
  const { t } = useTranslation(['access_group']);

  return {
    name: 'ACCESS_GROUP-PERSON',
    label: t('access_group:accessGroup'),
    getActionLabel: (action) => t(`access_group:personAccessGroupPermission.permissions.${action}`),
    getAdditionalRequirement: (action) =>
      t(`access_group:personAccessGroupPermission.permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`access_group:personAccessGroupPermission.permissionInfo.${action}`, { defaultValue: '' }),
  };
}
