/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BoxOfficeDto
 */
export interface BoxOfficeDto {
    /**
     * 
     * @type {string}
     * @memberof BoxOfficeDto
     */
    type: BoxOfficeDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BoxOfficeDto
     */
    text?: string;
    /**
     * 
     * @type {Date}
     * @memberof BoxOfficeDto
     */
    timestamp?: Date;
}


/**
 * @export
 */
export const BoxOfficeDtoTypeEnum = {
    DAY_BOX_OFFICE: 'DAY_BOX_OFFICE',
    PRESALE: 'PRESALE'
} as const;
export type BoxOfficeDtoTypeEnum = typeof BoxOfficeDtoTypeEnum[keyof typeof BoxOfficeDtoTypeEnum];


/**
 * Check if a given object implements the BoxOfficeDto interface.
 */
export function instanceOfBoxOfficeDto(value: object): boolean {
    if (!('type' in value)) return false;
    return true;
}

export function BoxOfficeDtoFromJSON(json: any): BoxOfficeDto {
    return BoxOfficeDtoFromJSONTyped(json, false);
}

export function BoxOfficeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BoxOfficeDto {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'text': json['text'] == null ? undefined : json['text'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
    };
}

export function BoxOfficeDtoToJSON(value?: BoxOfficeDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'text': value['text'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
    };
}

