import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useAccountPermissionClassGroupExtension(): PermissionClassGroupExtension<'ACCOUNT'> {
  const { t } = useTranslation('account');

  return {
    name: 'ACCOUNT',
    label: t('account'),
    getActionLabel: (action) => t(`permissions.${action}`),
    getAdditionalRequirement: (action) => t(`permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`permissionInfo.${action}`, { defaultValue: '' }),
  };
}
