import { Box } from '@chakra-ui/react';
import { faLink, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Connection from '../../ui/connection/connection';

interface SearchResultConnectionProps {
  connection?: React.ReactNode;
  targetIcon: IconDefinition;
  children: React.ReactNode;
  onTargetClick: () => void;
}

export default function SearchResultConnection({ connection, targetIcon, children }: SearchResultConnectionProps) {
  return (
    <Connection
      connection={
        connection != null
          ? { icon: <Box as={FontAwesomeIcon} icon={faLink} fixedWidth color="text.muted" />, element: connection }
          : undefined
      }
      end={{
        icon: <Box as={FontAwesomeIcon} icon={targetIcon} fixedWidth color="text.interactive" />,
        element: children,
      }}
      size="sm"
      ml={-2}
    />
  );
}
