import { format, setYear } from 'date-fns';

export function getCentury(date: Date): number {
  return Number(format(date, 'yyyy').substring(0, 2));
}

export function setCentury(date: Date, century: number): Date {
  const yearWithoutCentury = format(date, 'yy');
  return setYear(date, Number(String(century) + yearWithoutCentury));
}
