import React from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import InvoiceBreadcrumb from './common/invoice-breadcrumb';

const invoiceRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'invoices',
    handle: {
      breadcrumb: <Translate ns="invoice" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./invoice-lister/invoice-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./invoice-editor/new-invoice-route'),
      },
      {
        path: ':invoiceId',
        handle: {
          breadcrumb: <InvoiceBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./invoice-page/invoice-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./invoice-viewer/invoice-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./invoice-history/invoice-history-route'),
              },
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./invoice-editor/edit-invoice-route'),
          },
        ],
      },
      {
        path: 'recipient-update-applications',
        handle: {
          breadcrumb: <Translate ns="invoice" i18nKey="lister.recipientUpdateApplication" />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./recipient-update-application-lister/recipient-update-application-lister-route'),
          },
          {
            path: ':invoiceId',
            children: [
              {
                path: 'verify',
                lazy: () =>
                  import('./verify-recipient-update-application-editor/verify-recipient-update-application-route'),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'accounting-records',
    handle: {
      breadcrumb: <Translate ns="accounting_record" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./accounting-record-lister/accounting-record-lister-route'),
      },
    ],
  },
];
export default invoiceRoutes;
