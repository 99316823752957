import { chakra } from '@chakra-ui/react';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageSpinner from '../../../ui/page/page-spinner';

export interface AppPublicLayoutProps {
  children?: React.ReactNode;
}

export function AppPublicLayout({ children }: AppPublicLayoutProps) {
  return (
    <React.Suspense fallback={<PageSpinner />}>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <chakra.div bgColor="gray.50" width="100vw" height="100vh">
        <React.Suspense fallback={<PageSpinner />}>{children}</React.Suspense>
      </chakra.div>
    </React.Suspense>
  );
}

export default AppPublicLayout;
