import { HStack, IconButton, Spacer, Stack, StackDivider, Tag } from '@chakra-ui/react';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PersonToAddDto, RelationTypeDto } from '../../../../../api';
import PersonReference from '../../../../person/person-reference/person-reference';

interface PersonInGroupSelectionViewerProps {
  people: PersonToAddDto[];
  onRemove: (person: PersonToAddDto) => void;
  compare: (a: PersonToAddDto, b: PersonToAddDto) => number;
}

export function PersonInGroupSelectionViewer({ people, onRemove, compare }: PersonInGroupSelectionViewerProps) {
  const { t } = useTranslation(['common', 'group']);

  people.sort(compare);

  return people.length === 0 ? null : (
    <Stack
      borderWidth="thin"
      borderColor="border.01"
      borderRadius="base"
      divider={<StackDivider borderColor="border.01" />}
      spacing={0}
      padding={0}
      align="stretch"
    >
      {people.map((person, index) => {
        return (
          <HStack key={index} p={1} pl={4} spacing={1}>
            <PersonReference personReference={person.person} />
            <Spacer />
            <Tag sx={{ hyphens: 'auto' }}>{t(`group:connections.relationTypeOptions.${person.relationType}`)}</Tag>
            <IconButton
              size="sm"
              variant="ghost"
              aria-label={t('common:action.delete')}
              onClick={() => onRemove(person)}
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
            />
          </HStack>
        );
      })}
    </Stack>
  );
}

export function comparePeople(a: PersonToAddDto, b: PersonToAddDto) {
  if (Object.keys(RelationTypeDto).indexOf(a.relationType) > Object.keys(RelationTypeDto).indexOf(b.relationType)) {
    return 1;
  }
  if (Object.keys(RelationTypeDto).indexOf(a.relationType) < Object.keys(RelationTypeDto).indexOf(b.relationType)) {
    return -1;
  }

  if (a.person?.firstName != null && b.person?.firstName != null) {
    return a.person.firstName.localeCompare(b.person.firstName);
  }

  return 0;
}
