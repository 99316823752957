/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { MailingDesignFooterDto } from './MailingDesignFooterDto';
import {
    MailingDesignFooterDtoFromJSON,
    MailingDesignFooterDtoFromJSONTyped,
    MailingDesignFooterDtoToJSON,
} from './MailingDesignFooterDto';
import type { MailingDesignHeaderDto } from './MailingDesignHeaderDto';
import {
    MailingDesignHeaderDtoFromJSON,
    MailingDesignHeaderDtoFromJSONTyped,
    MailingDesignHeaderDtoToJSON,
} from './MailingDesignHeaderDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * A design for a mailing with a specific brand, logo, etc.
 * @export
 * @interface MailingDesignDto
 */
export interface MailingDesignDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof MailingDesignDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof MailingDesignDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof MailingDesignDto
     */
    brand: string;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof MailingDesignDto
     */
    edition?: EditionReferenceDto;
    /**
     * 
     * @type {MailingDesignHeaderDto}
     * @memberof MailingDesignDto
     */
    header: MailingDesignHeaderDto;
    /**
     * 
     * @type {MailingDesignFooterDto}
     * @memberof MailingDesignDto
     */
    footer: MailingDesignFooterDto;
    /**
     * Status of the design.
     * @type {string}
     * @memberof MailingDesignDto
     */
    status: MailingDesignDtoStatusEnum;
    /**
     * Whether the design is created by the system and certain fields may not be edited (edition and status).
     * @type {boolean}
     * @memberof MailingDesignDto
     */
    system: boolean;
}


/**
 * @export
 */
export const MailingDesignDtoStatusEnum = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
} as const;
export type MailingDesignDtoStatusEnum = typeof MailingDesignDtoStatusEnum[keyof typeof MailingDesignDtoStatusEnum];


/**
 * Check if a given object implements the MailingDesignDto interface.
 */
export function instanceOfMailingDesignDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('brand' in value)) return false;
    if (!('header' in value)) return false;
    if (!('footer' in value)) return false;
    if (!('status' in value)) return false;
    if (!('system' in value)) return false;
    return true;
}

export function MailingDesignDtoFromJSON(json: any): MailingDesignDto {
    return MailingDesignDtoFromJSONTyped(json, false);
}

export function MailingDesignDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingDesignDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'brand': json['brand'],
        'edition': json['edition'] == null ? undefined : EditionReferenceDtoFromJSON(json['edition']),
        'header': MailingDesignHeaderDtoFromJSON(json['header']),
        'footer': MailingDesignFooterDtoFromJSON(json['footer']),
        'status': json['status'],
        'system': json['system'],
    };
}

export function MailingDesignDtoToJSON(value?: MailingDesignDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'brand': value['brand'],
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'header': MailingDesignHeaderDtoToJSON(value['header']),
        'footer': MailingDesignFooterDtoToJSON(value['footer']),
        'status': value['status'],
        'system': value['system'],
    };
}

