import { createEditor as createSlateEditor, Transforms } from 'slate';
import { EditorProps } from '../format-types';
import { normalizeInline } from '../inline';

export default function createEditor() {
  const editor: EditorProps = createSlateEditor();
  editor.renderers = [];
  editor.toolbarButtons = [];
  editor.hotkeys = [];

  // normalization
  normalizeInline(editor);

  const { normalizeNode } = editor;
  editor.normalizeNode = ([node, path]) => {
    if (path.length > 0) {
      if (node.type == null && (node as any).text != null) {
        Transforms.setNodes(editor, { type: 'text' }, { at: path });
      }
    }

    return normalizeNode([node, path]);
  };

  return editor;
}

export interface WithFunction<T extends EditorProps = EditorProps> {
  (editor: T, config: WithConfig): T & EditorProps;
}

export interface WithConfig {
  onlyOneBlockOption?: boolean;
  paragraphPresent?: boolean;
}
