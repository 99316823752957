import { Box, ChakraProps } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

interface PageTabProps extends ChakraProps, Pick<NavLinkProps, 'to' | 'end'> {
  icon?: IconProp;
  children?: React.ReactNode;
}

export default function PageTab({ children, icon, end = true, ...props }: PageTabProps) {
  return (
    <Box
      as={NavLink}
      __css={{
        outline: 0,
        display: 'flex',
        px: 4,
        py: 2,
        minH: 10,
        color: 'gray.600',
        alignItems: 'center',
        borderBottom: '2px',
        marginBottom: '-1px',
        borderTopRadius: 'base',
        borderColor: 'transparent',
        transitionProperty: 'common',
        transitionDuration: 'normal',
        // Added by react-routers NavLink component
        '&.active': {
          color: 'text.interactive',
          borderColor: 'currentColor',
        },
        _focus: {
          boxShadow: 'outline',
        },
        _dark: { color: 'gray.300' },
      }}
      end={end}
      {...props}
    >
      {icon != null && <Box as={FontAwesomeIcon} mr={children != null ? 2 : undefined} icon={icon} />}
      {children}
    </Box>
  );
}
