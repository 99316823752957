/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EventEvaluationDto,
  FailureDto,
  HistoryPageDto,
  PageableDto,
  PublicEventEvaluationDto,
} from '../models/index';
import {
    EventEvaluationDtoFromJSON,
    EventEvaluationDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    PublicEventEvaluationDtoFromJSON,
    PublicEventEvaluationDtoToJSON,
} from '../models/index';

export interface FetchEvaluationRequest {
    id: string;
}

export interface FetchEvaluationByEventIdRequest {
    eventId: string;
}

export interface FetchEventEvaluationHistoryRequest {
    eventId: string;
    pageable?: PageableDto;
}

export interface FetchPublicEvaluationRequest {
    id: string;
}

export interface FinishReviewRequest {
    id: string;
    eventEvaluationDto: EventEvaluationDto;
}

export interface StartReviewRequest {
    id: string;
}

export interface StopReviewRequest {
    id: string;
}

export interface UpdateEvaluationRequest {
    id: string;
    eventEvaluationDto: EventEvaluationDto;
}

/**
 * 
 */
export class EventEvaluationApi extends runtime.BaseAPI {

    /**
     * Get the event evaluation. If not already submitted, an empty evaluation with status PENDING is returned.
     */
    async fetchEvaluationRaw(requestParameters: FetchEvaluationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventEvaluationDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchEvaluation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events-evaluation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventEvaluationDtoFromJSON(jsonValue));
    }

    /**
     * Get the event evaluation. If not already submitted, an empty evaluation with status PENDING is returned.
     */
    async fetchEvaluation(requestParameters: FetchEvaluationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventEvaluationDto> {
        const response = await this.fetchEvaluationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the event evaluation by event Id. If not already submitted, an empty evaluation with status PENDING is returned.
     */
    async fetchEvaluationByEventIdRaw(requestParameters: FetchEvaluationByEventIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventEvaluationDto>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling fetchEvaluationByEventId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events/{eventId}/evaluation`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventEvaluationDtoFromJSON(jsonValue));
    }

    /**
     * Get the event evaluation by event Id. If not already submitted, an empty evaluation with status PENDING is returned.
     */
    async fetchEvaluationByEventId(requestParameters: FetchEvaluationByEventIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventEvaluationDto> {
        const response = await this.fetchEvaluationByEventIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the event evaluation.
     */
    async fetchEventEvaluationHistoryRaw(requestParameters: FetchEventEvaluationHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling fetchEventEvaluationHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events/{eventId}/evaluation/versions`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the event evaluation.
     */
    async fetchEventEvaluationHistory(requestParameters: FetchEventEvaluationHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchEventEvaluationHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the event evaluation for external users. If not already submitted, an empty evaluation with status PENDING is returned.
     */
    async fetchPublicEvaluationRaw(requestParameters: FetchPublicEvaluationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicEventEvaluationDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchPublicEvaluation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/public/events-evaluation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicEventEvaluationDtoFromJSON(jsonValue));
    }

    /**
     * Get the event evaluation for external users. If not already submitted, an empty evaluation with status PENDING is returned.
     */
    async fetchPublicEvaluation(requestParameters: FetchPublicEvaluationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicEventEvaluationDto> {
        const response = await this.fetchPublicEvaluationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Finishes the review of an evaluation. May also change values of the evaluation.
     */
    async finishReviewRaw(requestParameters: FinishReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventEvaluationDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling finishReview().'
            );
        }

        if (requestParameters['eventEvaluationDto'] == null) {
            throw new runtime.RequiredError(
                'eventEvaluationDto',
                'Required parameter "eventEvaluationDto" was null or undefined when calling finishReview().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events-evaluation/{id}/finish-review`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EventEvaluationDtoToJSON(requestParameters['eventEvaluationDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventEvaluationDtoFromJSON(jsonValue));
    }

    /**
     * Finishes the review of an evaluation. May also change values of the evaluation.
     */
    async finishReview(requestParameters: FinishReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventEvaluationDto> {
        const response = await this.finishReviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Starts the review of an evaluation. Status is changed to in review.
     */
    async startReviewRaw(requestParameters: StartReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventEvaluationDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling startReview().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events-evaluation/{id}/start-review`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventEvaluationDtoFromJSON(jsonValue));
    }

    /**
     * Starts the review of an evaluation. Status is changed to in review.
     */
    async startReview(requestParameters: StartReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventEvaluationDto> {
        const response = await this.startReviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Stops the review of an evaluation. Status is changed to submitted again.
     */
    async stopReviewRaw(requestParameters: StopReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventEvaluationDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling stopReview().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events-evaluation/{id}/stop-review`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventEvaluationDtoFromJSON(jsonValue));
    }

    /**
     * Stops the review of an evaluation. Status is changed to submitted again.
     */
    async stopReview(requestParameters: StopReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventEvaluationDto> {
        const response = await this.stopReviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the evaluation (we assume that always an evaluation exists for an event).
     */
    async updateEvaluationRaw(requestParameters: UpdateEvaluationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventEvaluationDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateEvaluation().'
            );
        }

        if (requestParameters['eventEvaluationDto'] == null) {
            throw new runtime.RequiredError(
                'eventEvaluationDto',
                'Required parameter "eventEvaluationDto" was null or undefined when calling updateEvaluation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events-evaluation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EventEvaluationDtoToJSON(requestParameters['eventEvaluationDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventEvaluationDtoFromJSON(jsonValue));
    }

    /**
     * Update the evaluation (we assume that always an evaluation exists for an event).
     */
    async updateEvaluation(requestParameters: UpdateEvaluationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventEvaluationDto> {
        const response = await this.updateEvaluationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
