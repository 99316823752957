import React from 'react';
import { useNavigate } from 'react-router-dom';
import useDefinedContext from '../context/use-defined-context/use-defined-context';
import useHistoryBack, { HISTORY_ABORT, HistoryBackContext } from './use-history-back';

interface HistoryBackButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  fallbackPath?: string;
}

const HistoryBackButton = React.forwardRef(
  ({ fallbackPath = '..', onClick, ...props }: HistoryBackButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const locations = useDefinedContext(HistoryBackContext);
    const back = useHistoryBack();
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onClick?.(event);

      if (event.defaultPrevented) {
        return;
      }

      const previousLocation = locations.at(-2);

      if (previousLocation == null) {
        navigate(back(fallbackPath), { relative: 'path' });
      } else {
        navigate(previousLocation, { state: HISTORY_ABORT });
      }
    };

    return <button {...props} onClick={handleClick} ref={ref} />;
  },
);

export default HistoryBackButton;
