import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
} from '@chakra-ui/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export interface DeleteRelationsDialogProps extends Pick<AlertDialogProps, 'isOpen' | 'onClose'> {
  onDelete(): void;
  selectionCount: number;
}

export function DeleteRelationsDialog({ onDelete, selectionCount, onClose, ...props }: DeleteRelationsDialogProps) {
  const closeButtonRef = React.useRef<HTMLButtonElement>(null);
  const { t } = useTranslation(['relations', 'common']);

  return (
    <AlertDialog {...props} onClose={onClose} leastDestructiveRef={closeButtonRef}>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>{t('relations:action.remove', { count: selectionCount })}</AlertDialogHeader>
        <AlertDialogBody>
          {selectionCount === 1 ? (
            <Trans t={t} i18nKey="relations:delete_single_message" />
          ) : (
            <Trans t={t} i18nKey="relations:delete_multi_message" />
          )}
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button onClick={onClose} mr={3} ref={closeButtonRef}>
            {t('common:action.abort')}
          </Button>
          <Button onClick={onDelete} colorScheme="red">
            {t('common:action.delete')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
