import React from 'react';
import { useParams } from 'react-router-dom';
import useFetcher from '../../util/swr/use-fetcher';
import { fetchAward } from './award-queries';
import { getAwardId } from './get-award-id';

export default function AwardBreadcrumb() {
  const params = useParams<{ awardId: string }>();
  const award = useFetcher(fetchAward, { id: getAwardId(params) });

  return <>{award.title}</>;
}
