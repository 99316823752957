/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AccountingIssuerDto = {
    EFM: 'EFM',
    IFB: 'IFB'
} as const;
export type AccountingIssuerDto = typeof AccountingIssuerDto[keyof typeof AccountingIssuerDto];


export function AccountingIssuerDtoFromJSON(json: any): AccountingIssuerDto {
    return AccountingIssuerDtoFromJSONTyped(json, false);
}

export function AccountingIssuerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountingIssuerDto {
    return json as AccountingIssuerDto;
}

export function AccountingIssuerDtoToJSON(value?: AccountingIssuerDto | null): any {
    return value as any;
}

