import React from 'react';
import { useParams } from 'react-router-dom';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchGroupTemplate } from '../group-template-queries';
import { getGroupTemplateId } from './get-group-template-id';

export default function GroupTemplateBreadcrumb() {
  const groupTemplate = useFetcher(fetchGroupTemplate, { id: getGroupTemplateId(useParams()) });

  return <>{groupTemplate.name}</>;
}
