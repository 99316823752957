/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ParticipationSecurityTypeDto } from './ParticipationSecurityTypeDto';
import {
    ParticipationSecurityTypeDtoFromJSON,
    ParticipationSecurityTypeDtoFromJSONTyped,
    ParticipationSecurityTypeDtoToJSON,
} from './ParticipationSecurityTypeDto';

/**
 * 
 * @export
 * @interface ParticipationSecurityInnerDto
 */
export interface ParticipationSecurityInnerDto {
    /**
     * 
     * @type {ParticipationSecurityTypeDto}
     * @memberof ParticipationSecurityInnerDto
     */
    type?: ParticipationSecurityTypeDto;
    /**
     * 
     * @type {number}
     * @memberof ParticipationSecurityInnerDto
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof ParticipationSecurityInnerDto
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof ParticipationSecurityInnerDto
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the ParticipationSecurityInnerDto interface.
 */
export function instanceOfParticipationSecurityInnerDto(value: object): boolean {
    if (!('createdAt' in value)) return false;
    return true;
}

export function ParticipationSecurityInnerDtoFromJSON(json: any): ParticipationSecurityInnerDto {
    return ParticipationSecurityInnerDtoFromJSONTyped(json, false);
}

export function ParticipationSecurityInnerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipationSecurityInnerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : ParticipationSecurityTypeDtoFromJSON(json['type']),
        'count': json['count'] == null ? undefined : json['count'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function ParticipationSecurityInnerDtoToJSON(value?: ParticipationSecurityInnerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': ParticipationSecurityTypeDtoToJSON(value['type']),
        'count': value['count'],
        'comment': value['comment'],
        'createdAt': ((value['createdAt']).toISOString()),
    };
}

