/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  HistoryPageDto,
  LandingPageModuleConstraintDto,
  LandingPageTemplateDto,
  LandingPageTemplatePageDto,
  LandingPageTemplateReferenceDto,
  PageableDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    LandingPageModuleConstraintDtoFromJSON,
    LandingPageModuleConstraintDtoToJSON,
    LandingPageTemplateDtoFromJSON,
    LandingPageTemplateDtoToJSON,
    LandingPageTemplatePageDtoFromJSON,
    LandingPageTemplatePageDtoToJSON,
    LandingPageTemplateReferenceDtoFromJSON,
    LandingPageTemplateReferenceDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface CopyTemplateLandingPageTemplateRequest {
    id: string;
}

export interface CreateLandingPageTemplateRequest {
    landingPageTemplateDto: LandingPageTemplateDto;
}

export interface DeleteLandingPageTemplateRequest {
    id: string;
}

export interface ExistsNameRequest {
    name: string;
    id: string;
}

export interface FetchLandingPageModuleConstraintsRequest {
    landingPageType: string;
}

export interface FetchLandingPageTemplateRequest {
    id: string;
}

export interface FetchLandingPageTemplateHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface IsTemplateInUseRequest {
    id: string;
}

export interface SearchLandingPageTemplatesRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface TemplateLandingPageTemplateRequest {
    landingPageType: string;
}

export interface UpdateLandingPageTemplateRequest {
    id: string;
    landingPageTemplateDto: LandingPageTemplateDto;
}

/**
 * 
 */
export class LandingPageTemplateApi extends runtime.BaseAPI {

    /**
     */
    async copyTemplateLandingPageTemplateRaw(requestParameters: CopyTemplateLandingPageTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LandingPageTemplateDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling copyTemplateLandingPageTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-templates/template/copy/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LandingPageTemplateDtoFromJSON(jsonValue));
    }

    /**
     */
    async copyTemplateLandingPageTemplate(requestParameters: CopyTemplateLandingPageTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LandingPageTemplateDto> {
        const response = await this.copyTemplateLandingPageTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createLandingPageTemplateRaw(requestParameters: CreateLandingPageTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['landingPageTemplateDto'] == null) {
            throw new runtime.RequiredError(
                'landingPageTemplateDto',
                'Required parameter "landingPageTemplateDto" was null or undefined when calling createLandingPageTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LandingPageTemplateDtoToJSON(requestParameters['landingPageTemplateDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createLandingPageTemplate(requestParameters: CreateLandingPageTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createLandingPageTemplateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteLandingPageTemplateRaw(requestParameters: DeleteLandingPageTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteLandingPageTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteLandingPageTemplate(requestParameters: DeleteLandingPageTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteLandingPageTemplateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async existsNameRaw(requestParameters: ExistsNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LandingPageTemplateReferenceDto>> {
        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling existsName().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling existsName().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-templates/exists-name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LandingPageTemplateReferenceDtoFromJSON(jsonValue));
    }

    /**
     */
    async existsName(requestParameters: ExistsNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LandingPageTemplateReferenceDto | null | undefined > {
        const response = await this.existsNameRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Get a mapping of landing page types onto the login option, which is a boolean
     */
    async fetchLandingPageLoginOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: any; }>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-templates/login-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get a mapping of landing page types onto the login option, which is a boolean
     */
    async fetchLandingPageLoginOptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: any; }> {
        const response = await this.fetchLandingPageLoginOptionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get the constraints for the landing page type.
     */
    async fetchLandingPageModuleConstraintsRaw(requestParameters: FetchLandingPageModuleConstraintsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: LandingPageModuleConstraintDto; }>> {
        if (requestParameters['landingPageType'] == null) {
            throw new runtime.RequiredError(
                'landingPageType',
                'Required parameter "landingPageType" was null or undefined when calling fetchLandingPageModuleConstraints().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-templates/module-constraints/{landingPageType}`.replace(`{${"landingPageType"}}`, encodeURIComponent(String(requestParameters['landingPageType']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, LandingPageModuleConstraintDtoFromJSON));
    }

    /**
     * Get the constraints for the landing page type.
     */
    async fetchLandingPageModuleConstraints(requestParameters: FetchLandingPageModuleConstraintsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: LandingPageModuleConstraintDto; }> {
        const response = await this.fetchLandingPageModuleConstraintsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the landing page template.
     */
    async fetchLandingPageTemplateRaw(requestParameters: FetchLandingPageTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LandingPageTemplateDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchLandingPageTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LandingPageTemplateDtoFromJSON(jsonValue));
    }

    /**
     * Get the landing page template.
     */
    async fetchLandingPageTemplate(requestParameters: FetchLandingPageTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LandingPageTemplateDto> {
        const response = await this.fetchLandingPageTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fetchLandingPageTemplateHistoryRaw(requestParameters: FetchLandingPageTemplateHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchLandingPageTemplateHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-templates/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchLandingPageTemplateHistory(requestParameters: FetchLandingPageTemplateHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchLandingPageTemplateHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Do Landing Pages exist using this template?
     */
    async isTemplateInUseRaw(requestParameters: IsTemplateInUseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling isTemplateInUse().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-templates/{id}/is-in-use`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Do Landing Pages exist using this template?
     */
    async isTemplateInUse(requestParameters: IsTemplateInUseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.isTemplateInUseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchLandingPageTemplatesRaw(requestParameters: SearchLandingPageTemplatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LandingPageTemplatePageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LandingPageTemplatePageDtoFromJSON(jsonValue));
    }

    /**
     */
    async searchLandingPageTemplates(requestParameters: SearchLandingPageTemplatesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LandingPageTemplatePageDto> {
        const response = await this.searchLandingPageTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async templateLandingPageTemplateRaw(requestParameters: TemplateLandingPageTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LandingPageTemplateDto>> {
        if (requestParameters['landingPageType'] == null) {
            throw new runtime.RequiredError(
                'landingPageType',
                'Required parameter "landingPageType" was null or undefined when calling templateLandingPageTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-templates/template/new/{landingPageType}`.replace(`{${"landingPageType"}}`, encodeURIComponent(String(requestParameters['landingPageType']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LandingPageTemplateDtoFromJSON(jsonValue));
    }

    /**
     */
    async templateLandingPageTemplate(requestParameters: TemplateLandingPageTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LandingPageTemplateDto> {
        const response = await this.templateLandingPageTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateLandingPageTemplateRaw(requestParameters: UpdateLandingPageTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateLandingPageTemplate().'
            );
        }

        if (requestParameters['landingPageTemplateDto'] == null) {
            throw new runtime.RequiredError(
                'landingPageTemplateDto',
                'Required parameter "landingPageTemplateDto" was null or undefined when calling updateLandingPageTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LandingPageTemplateDtoToJSON(requestParameters['landingPageTemplateDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateLandingPageTemplate(requestParameters: UpdateLandingPageTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateLandingPageTemplateRaw(requestParameters, initOverrides);
    }

}
