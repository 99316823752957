/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MainOccupationListItemDto } from './MainOccupationListItemDto';
import {
    MainOccupationListItemDtoFromJSON,
    MainOccupationListItemDtoFromJSONTyped,
    MainOccupationListItemDtoToJSON,
} from './MainOccupationListItemDto';
import type { PersonStatusDto } from './PersonStatusDto';
import {
    PersonStatusDtoFromJSON,
    PersonStatusDtoFromJSONTyped,
    PersonStatusDtoToJSON,
} from './PersonStatusDto';
import type { PersonTypeDto } from './PersonTypeDto';
import {
    PersonTypeDtoFromJSON,
    PersonTypeDtoFromJSONTyped,
    PersonTypeDtoToJSON,
} from './PersonTypeDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface PersonListItemDto
 */
export interface PersonListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof PersonListItemDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PersonListItemDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PersonListItemDto
     */
    surname: string;
    /**
     * The unique key for a person starting with "P".
     * @type {string}
     * @memberof PersonListItemDto
     */
    personKey: string;
    /**
     * 
     * @type {Array<PersonTypeDto>}
     * @memberof PersonListItemDto
     */
    types?: Array<PersonTypeDto>;
    /**
     * 
     * @type {PersonStatusDto}
     * @memberof PersonListItemDto
     */
    status: PersonStatusDto;
    /**
     * 
     * @type {MainOccupationListItemDto}
     * @memberof PersonListItemDto
     */
    mainOccupation?: MainOccupationListItemDto;
    /**
     * 
     * @type {VersionDto}
     * @memberof PersonListItemDto
     */
    version: VersionDto;
}

/**
 * Check if a given object implements the PersonListItemDto interface.
 */
export function instanceOfPersonListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('firstName' in value)) return false;
    if (!('surname' in value)) return false;
    if (!('personKey' in value)) return false;
    if (!('status' in value)) return false;
    if (!('version' in value)) return false;
    return true;
}

export function PersonListItemDtoFromJSON(json: any): PersonListItemDto {
    return PersonListItemDtoFromJSONTyped(json, false);
}

export function PersonListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'surname': json['surname'],
        'personKey': json['personKey'],
        'types': json['types'] == null ? undefined : ((json['types'] as Array<any>).map(PersonTypeDtoFromJSON)),
        'status': PersonStatusDtoFromJSON(json['status']),
        'mainOccupation': json['mainOccupation'] == null ? undefined : MainOccupationListItemDtoFromJSON(json['mainOccupation']),
        'version': VersionDtoFromJSON(json['version']),
    };
}

export function PersonListItemDtoToJSON(value?: PersonListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'firstName': value['firstName'],
        'surname': value['surname'],
        'personKey': value['personKey'],
        'types': value['types'] == null ? undefined : ((value['types'] as Array<any>).map(PersonTypeDtoToJSON)),
        'status': PersonStatusDtoToJSON(value['status']),
        'mainOccupation': MainOccupationListItemDtoToJSON(value['mainOccupation']),
        'version': VersionDtoToJSON(value['version']),
    };
}

