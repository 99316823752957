/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  PageableDto,
  ResultCountDto,
  SearchResultDto,
  SearchResultPageDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    ResultCountDtoFromJSON,
    ResultCountDtoToJSON,
    SearchResultDtoFromJSON,
    SearchResultDtoToJSON,
    SearchResultPageDtoFromJSON,
    SearchResultPageDtoToJSON,
} from '../models/index';

export interface ResultCountRequest {
    phrase: string;
}

export interface SearchRequest {
    phrase: string;
    filter?: string;
    pageable?: PageableDto;
}

/**
 * 
 */
export class SearchApi extends runtime.BaseAPI {

    /**
     * Get count of each domain object found for given search phrase.
     */
    async resultCountRaw(requestParameters: ResultCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ResultCountDto>>> {
        if (requestParameters['phrase'] == null) {
            throw new runtime.RequiredError(
                'phrase',
                'Required parameter "phrase" was null or undefined when calling resultCount().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['phrase'] != null) {
            queryParameters['phrase'] = requestParameters['phrase'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/search-result-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResultCountDtoFromJSON));
    }

    /**
     * Get count of each domain object found for given search phrase.
     */
    async resultCount(requestParameters: ResultCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ResultCountDto>> {
        const response = await this.resultCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Perform a global search.
     */
    async searchRaw(requestParameters: SearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchResultPageDto>> {
        if (requestParameters['phrase'] == null) {
            throw new runtime.RequiredError(
                'phrase',
                'Required parameter "phrase" was null or undefined when calling search().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['phrase'] != null) {
            queryParameters['phrase'] = requestParameters['phrase'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchResultPageDtoFromJSON(jsonValue));
    }

    /**
     * Perform a global search.
     */
    async search(requestParameters: SearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchResultPageDto> {
        const response = await this.searchRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
