import { useToast } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function useFileDrop(onDrop: (files: FileList) => void, allowMultiDrop: boolean) {
  const { t } = useTranslation('attachment');
  const [isDragging, setIsDragging] = React.useState<boolean>(false);
  const toast = useToast();

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    // Prevent drag leave when hovering over child elements.
    if (event.target !== event.currentTarget) {
      return;
    }

    event.preventDefault();

    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setIsDragging(false);

    if (!allowMultiDrop && event.dataTransfer.files.length > 1) {
      toast({
        title: t('errors.only_one_file.title'),
        description: t('errors.only_one_file.description'),
        status: 'error',
        position: 'top-right',
      });
      return;
    }

    onDrop(event.dataTransfer.files);
  };

  return {
    field: {
      onDragEnter: handleDragEnter,
      onDragLeave: handleDragLeave,
      onDragOver: (event: React.DragEvent<HTMLDivElement>) => event.preventDefault(),
      onDrop: handleDrop,
    },
    isDragging,
  };
}
