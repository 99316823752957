/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvalidateCodesRequestDto
 */
export interface InvalidateCodesRequestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof InvalidateCodesRequestDto
     */
    codes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InvalidateCodesRequestDto
     */
    comment?: string;
}

/**
 * Check if a given object implements the InvalidateCodesRequestDto interface.
 */
export function instanceOfInvalidateCodesRequestDto(value: object): boolean {
    return true;
}

export function InvalidateCodesRequestDtoFromJSON(json: any): InvalidateCodesRequestDto {
    return InvalidateCodesRequestDtoFromJSONTyped(json, false);
}

export function InvalidateCodesRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvalidateCodesRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'codes': json['codes'] == null ? undefined : json['codes'],
        'comment': json['comment'] == null ? undefined : json['comment'],
    };
}

export function InvalidateCodesRequestDtoToJSON(value?: InvalidateCodesRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'codes': value['codes'],
        'comment': value['comment'],
    };
}

