import { WarningIcon } from '@chakra-ui/icons';
import { Box, Heading, HStack, Stack, Tag, TagLabel, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClosedDateTimeRangeDto, PublicEventReferenceDto, PublicLocationReferenceDto } from '../../../api';
import { LocationReferenceViewer } from '../../location/location-reference/location-reference';

interface PublicRundownEventHeaderProps {
  title: string;
  dateTimeRange: ClosedDateTimeRangeDto;
  location?: PublicLocationReferenceDto;
  isSettingsHeader?: boolean;
  connectedEvents?: PublicEventReferenceDto[];
}

export default function PublicRundownEventHeader({
  title,
  dateTimeRange,
  location,
  connectedEvents,
}: PublicRundownEventHeaderProps) {
  const { t } = useTranslation(['common', 'rundown', 'app']);

  return (
    <Stack spacing={2}>
      <Heading size="md" as="h3">
        {t('rundown:viewer.title', { event: title })}
      </Heading>
      {connectedEvents != null && connectedEvents.length > 0 && (
        <HStack>
          <Text>{t('rundown:view_settings.connected_events')}</Text>
          {connectedEvents.map((event, idx) => (
            <Tag key={idx} colorScheme="gray">
              <TagLabel>{event.title}</TagLabel>
            </Tag>
          ))}
        </HStack>
      )}
      <Box fontSize="sm">
        {t('common:format.datetime_range', { dateTimeRange: dateTimeRange })}
        {location != null && ', '}
        {location != null && <LocationReferenceViewer locationReference={location} size="sm" />}
      </Box>
      <HStack>
        <WarningIcon color="text.warning" />
        <Text>{t('app:confidential')}</Text>
      </HStack>
    </Stack>
  );
}
