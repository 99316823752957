/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocationPermissionDto
 */
export interface LocationPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof LocationPermissionDto
     */
    name: LocationPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof LocationPermissionDto
     */
    action: LocationPermissionDtoActionEnum;
}


/**
 * @export
 */
export const LocationPermissionDtoNameEnum = {
    LOCATION: 'LOCATION'
} as const;
export type LocationPermissionDtoNameEnum = typeof LocationPermissionDtoNameEnum[keyof typeof LocationPermissionDtoNameEnum];

/**
 * @export
 */
export const LocationPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    EDIT: 'CAN_EDIT',
    CREATE: 'CAN_CREATE',
    DELETE: 'CAN_DELETE',
    SEE_ATTACHMENTS: 'CAN_SEE_ATTACHMENTS',
    MANAGE_ATTACHMENTS: 'CAN_MANAGE_ATTACHMENTS',
    SEE_LOCATION_CARD: 'CAN_SEE_LOCATION_CARD',
    SEE_PLACEMENT_TEMPLATE: 'CAN_SEE_PLACEMENT_TEMPLATE',
    CREATE_PLACEMENT_TEMPLATE: 'CAN_CREATE_PLACEMENT_TEMPLATE',
    EDIT_PLACEMENT_TEMPLATE: 'CAN_EDIT_PLACEMENT_TEMPLATE',
    COPY_PLACEMENT_TEMPLATE: 'CAN_COPY_PLACEMENT_TEMPLATE',
    DELETE_PLACEMENT_TEMPLATE: 'CAN_DELETE_PLACEMENT_TEMPLATE'
} as const;
export type LocationPermissionDtoActionEnum = typeof LocationPermissionDtoActionEnum[keyof typeof LocationPermissionDtoActionEnum];


/**
 * Check if a given object implements the LocationPermissionDto interface.
 */
export function instanceOfLocationPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function LocationPermissionDtoFromJSON(json: any): LocationPermissionDto {
    return LocationPermissionDtoFromJSONTyped(json, false);
}

export function LocationPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function LocationPermissionDtoToJSON(value?: LocationPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

