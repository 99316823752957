import { alertAnatomy } from '@chakra-ui/anatomy';
import { mode, MultiStyleConfig } from '@chakra-ui/theme-tools';

const Alert: MultiStyleConfig<typeof alertAnatomy> = {
  parts: alertAnatomy.keys,
  baseStyle: {
    container: {
      borderRadius: 'base',
    },
  },
  variants: {
    subtle(props) {
      return {
        container: {
          color: mode('inherit', `gray.100`)(props),
        },
      };
    },
    solid(props) {
      const { colorScheme: c } = props;

      return {
        container: {
          bg: mode(`${c}.500`, `${c}.900`)(props),
          color: mode('white', `${c}.200`)(props),
        },
        icon: {
          color: mode('white', `${c}.200`)(props),
        },
      };
    },
  },
};

export default Alert;
