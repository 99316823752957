/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MarkElementDto
 */
export interface MarkElementDto {
    /**
     * 
     * @type {string}
     * @memberof MarkElementDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof MarkElementDto
     */
    text?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MarkElementDto
     */
    italic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MarkElementDto
     */
    bold?: boolean;
}

/**
 * Check if a given object implements the MarkElementDto interface.
 */
export function instanceOfMarkElementDto(value: object): boolean {
    if (!('type' in value)) return false;
    return true;
}

export function MarkElementDtoFromJSON(json: any): MarkElementDto {
    return MarkElementDtoFromJSONTyped(json, false);
}

export function MarkElementDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarkElementDto {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'text': json['text'] == null ? undefined : json['text'],
        'italic': json['italic'] == null ? undefined : json['italic'],
        'bold': json['bold'] == null ? undefined : json['bold'],
    };
}

export function MarkElementDtoToJSON(value?: MarkElementDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'text': value['text'],
        'italic': value['italic'],
        'bold': value['bold'],
    };
}

