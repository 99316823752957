/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
} from '../models/index';

export interface ExemptCompanyFromValueAddedTaxRequest {
    companyId: string;
}

/**
 * 
 */
export class RefundApi extends runtime.BaseAPI {

    /**
     * Exempt company from value added tax.
     */
    async exemptCompanyFromValueAddedTaxRaw(requestParameters: ExemptCompanyFromValueAddedTaxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling exemptCompanyFromValueAddedTax().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoices/recipients/{company-id}/exemptFromValueAddedTax`.replace(`{${"company-id"}}`, encodeURIComponent(String(requestParameters['companyId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Exempt company from value added tax.
     */
    async exemptCompanyFromValueAddedTax(requestParameters: ExemptCompanyFromValueAddedTaxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.exemptCompanyFromValueAddedTaxRaw(requestParameters, initOverrides);
    }

}
