/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LocationPostalAddressDto } from './LocationPostalAddressDto';
import {
    LocationPostalAddressDtoFromJSON,
    LocationPostalAddressDtoFromJSONTyped,
    LocationPostalAddressDtoToJSON,
} from './LocationPostalAddressDto';

/**
 * A reference of a location
 * @export
 * @interface LocationReferenceDto
 */
export interface LocationReferenceDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof LocationReferenceDto
     */
    id: string;
    /**
     * the german name of the location
     * @type {string}
     * @memberof LocationReferenceDto
     */
    name: string;
    /**
     * the english name of the location
     * @type {string}
     * @memberof LocationReferenceDto
     */
    englishName?: string;
    /**
     * 
     * @type {Array<LocationPostalAddressDto>}
     * @memberof LocationReferenceDto
     */
    addresses?: Array<LocationPostalAddressDto>;
}

/**
 * Check if a given object implements the LocationReferenceDto interface.
 */
export function instanceOfLocationReferenceDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    return true;
}

export function LocationReferenceDtoFromJSON(json: any): LocationReferenceDto {
    return LocationReferenceDtoFromJSONTyped(json, false);
}

export function LocationReferenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationReferenceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'englishName': json['englishName'] == null ? undefined : json['englishName'],
        'addresses': json['addresses'] == null ? undefined : ((json['addresses'] as Array<any>).map(LocationPostalAddressDtoFromJSON)),
    };
}

export function LocationReferenceDtoToJSON(value?: LocationReferenceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'englishName': value['englishName'],
        'addresses': value['addresses'] == null ? undefined : ((value['addresses'] as Array<any>).map(LocationPostalAddressDtoToJSON)),
    };
}

