import personAccessGroupApi from '../../../data-access/person-access-group-api';
import { createFetcherScope } from '../../../util/swr/fetcher-scope';

export const personAccessGroupFetcher = createFetcherScope(personAccessGroupApi);
export const fetchPersonAccessGroupListItems = personAccessGroupFetcher.createFetcher(
  personAccessGroupApi.searchByPerson,
);

export const fetchPersonAccessGroupHistory = personAccessGroupFetcher.createFetcher(
  personAccessGroupApi.fetchHistoryByAccessGroup,
);

export const fetchPersonsByAccessGroup = personAccessGroupFetcher.createFetcher(
  personAccessGroupApi.searchByAccessGroup,
);
