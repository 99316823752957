/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LocationReferenceDto } from './LocationReferenceDto';
import {
    LocationReferenceDtoFromJSON,
    LocationReferenceDtoFromJSONTyped,
    LocationReferenceDtoToJSON,
} from './LocationReferenceDto';
import type { PlacementTemplateDto } from './PlacementTemplateDto';
import {
    PlacementTemplateDtoFromJSON,
    PlacementTemplateDtoFromJSONTyped,
    PlacementTemplateDtoToJSON,
} from './PlacementTemplateDto';

/**
 * 
 * @export
 * @interface PlacementTemplateSearchResultDto
 */
export interface PlacementTemplateSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof PlacementTemplateSearchResultDto
     */
    resultType: PlacementTemplateSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PlacementTemplateSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {PlacementTemplateDto}
     * @memberof PlacementTemplateSearchResultDto
     */
    placementTemplate: PlacementTemplateDto;
    /**
     * 
     * @type {LocationReferenceDto}
     * @memberof PlacementTemplateSearchResultDto
     */
    location: LocationReferenceDto;
}


/**
 * @export
 */
export const PlacementTemplateSearchResultDtoResultTypeEnum = {
    PLACEMENT_TEMPLATE: 'PLACEMENT_TEMPLATE'
} as const;
export type PlacementTemplateSearchResultDtoResultTypeEnum = typeof PlacementTemplateSearchResultDtoResultTypeEnum[keyof typeof PlacementTemplateSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the PlacementTemplateSearchResultDto interface.
 */
export function instanceOfPlacementTemplateSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('placementTemplate' in value)) return false;
    if (!('location' in value)) return false;
    return true;
}

export function PlacementTemplateSearchResultDtoFromJSON(json: any): PlacementTemplateSearchResultDto {
    return PlacementTemplateSearchResultDtoFromJSONTyped(json, false);
}

export function PlacementTemplateSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlacementTemplateSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'placementTemplate': PlacementTemplateDtoFromJSON(json['placementTemplate']),
        'location': LocationReferenceDtoFromJSON(json['location']),
    };
}

export function PlacementTemplateSearchResultDtoToJSON(value?: PlacementTemplateSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'placementTemplate': PlacementTemplateDtoToJSON(value['placementTemplate']),
        'location': LocationReferenceDtoToJSON(value['location']),
    };
}

