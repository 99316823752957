/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The status of an event
 * @export
 */
export const EventStatusDto = {
    DRAFT: 'DRAFT',
    SCHEDULED: 'SCHEDULED',
    CANCELLED: 'CANCELLED'
} as const;
export type EventStatusDto = typeof EventStatusDto[keyof typeof EventStatusDto];


export function EventStatusDtoFromJSON(json: any): EventStatusDto {
    return EventStatusDtoFromJSONTyped(json, false);
}

export function EventStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventStatusDto {
    return json as EventStatusDto;
}

export function EventStatusDtoToJSON(value?: EventStatusDto | null): any {
    return value as any;
}

