/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyReferenceDto } from './CompanyReferenceDto';
import {
    CompanyReferenceDtoFromJSON,
    CompanyReferenceDtoFromJSONTyped,
    CompanyReferenceDtoToJSON,
} from './CompanyReferenceDto';
import type { ProductReferenceDto } from './ProductReferenceDto';
import {
    ProductReferenceDtoFromJSON,
    ProductReferenceDtoFromJSONTyped,
    ProductReferenceDtoToJSON,
} from './ProductReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * Person relations by accessGroup
 * @export
 * @interface AccessGroupPersonListItemDto
 */
export interface AccessGroupPersonListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof AccessGroupPersonListItemDto
     */
    id: string;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof AccessGroupPersonListItemDto
     */
    personId: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof AccessGroupPersonListItemDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof AccessGroupPersonListItemDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AccessGroupPersonListItemDto
     */
    surname: string;
    /**
     * 
     * @type {CompanyReferenceDto}
     * @memberof AccessGroupPersonListItemDto
     */
    company?: CompanyReferenceDto;
    /**
     * 
     * @type {Array<ProductReferenceDto>}
     * @memberof AccessGroupPersonListItemDto
     */
    products: Array<ProductReferenceDto>;
}

/**
 * Check if a given object implements the AccessGroupPersonListItemDto interface.
 */
export function instanceOfAccessGroupPersonListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('personId' in value)) return false;
    if (!('firstName' in value)) return false;
    if (!('surname' in value)) return false;
    if (!('products' in value)) return false;
    return true;
}

export function AccessGroupPersonListItemDtoFromJSON(json: any): AccessGroupPersonListItemDto {
    return AccessGroupPersonListItemDtoFromJSONTyped(json, false);
}

export function AccessGroupPersonListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessGroupPersonListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'personId': json['personId'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'firstName': json['firstName'],
        'surname': json['surname'],
        'company': json['company'] == null ? undefined : CompanyReferenceDtoFromJSON(json['company']),
        'products': ((json['products'] as Array<any>).map(ProductReferenceDtoFromJSON)),
    };
}

export function AccessGroupPersonListItemDtoToJSON(value?: AccessGroupPersonListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'personId': value['personId'],
        'version': VersionDtoToJSON(value['version']),
        'firstName': value['firstName'],
        'surname': value['surname'],
        'company': CompanyReferenceDtoToJSON(value['company']),
        'products': ((value['products'] as Array<any>).map(ProductReferenceDtoToJSON)),
    };
}

