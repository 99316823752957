import { useMergeRefs } from '@chakra-ui/react';
import { endOfDay, isValid, startOfDay } from 'date-fns';
import { isEqual } from 'lodash-es';
import React from 'react';
import { useController, ValidateResult } from 'react-hook-form';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';
import { Message } from 'react-hook-form/dist/types/errors';
import { useTranslation } from 'react-i18next';
import DateInput, { DateInputProps } from '../../date-input/date-input';

export interface ValidationValueMessage<TValidationValue> {
  value?: TValidationValue;
  message: Message;
}

export interface DateInputControlProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  name: TName;
  referenceDate?: Date;
  required?: string;
  min?: ValidationValueMessage<Date>;
  max?: ValidationValueMessage<Date>;
  deps?: TName[];
  size?: DateInputProps['size'];
  showYearDropdown?: boolean;
  showTimeSelect?: boolean;
  showSeconds?: boolean;
  autocompletePastOnly?: boolean;
  isDisabled?: boolean;
  minDatePicker?: Date;
  maxDatePicker?: Date;

  validate?(date: Date): ValidateResult | Promise<ValidateResult>;

  onPickerToggle?(isOpen: boolean): void;

  onChange?(value: Date | null): void;
}

function DateInputControl<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  {
    name,
    min,
    max,
    referenceDate,
    required,
    deps,
    size,
    showYearDropdown,
    showTimeSelect,
    showSeconds,
    autocompletePastOnly,
    isDisabled,
    minDatePicker,
    maxDatePicker,
    validate,
    onPickerToggle,
    onChange,
  }: DateInputControlProps<TFieldValues, TName>,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const { t } = useTranslation('common');
  const { field } = useController<TFieldValues, TName>({
    name,
    rules: {
      deps,
      validate: (value) => {
        if (value == null) {
          return required ?? true;
        }

        if (!isValid(value)) {
          return showSeconds
            ? t('validation_error.invalid_date_time_seconds')
            : showTimeSelect
              ? t('validation_error.invalid_date_time')
              : t('validation_error.invalid_date');
        }

        const dateValue = value as Date;

        if (min?.value != null && dateValue < (showTimeSelect || showSeconds ? min.value : startOfDay(min.value))) {
          return min.message;
        }

        if (max?.value != null && dateValue > (showTimeSelect || showSeconds ? max.value : endOfDay(max.value))) {
          return max.message;
        }

        return validate?.(value) ?? true;
      },
    },
  });
  const mergedRef = useMergeRefs(field.ref, ref);

  return (
    <DateInput
      {...field}
      onChange={(value) => {
        onChange?.(value);
        field.onChange(value);
      }}
      ref={mergedRef}
      minDate={min?.value}
      maxDate={max?.value}
      referenceDate={referenceDate}
      size={size}
      showYearDropdown={showYearDropdown}
      showTimeSelect={showTimeSelect}
      showSeconds={showSeconds}
      autocompletePastOnly={autocompletePastOnly}
      isDisabled={isDisabled}
      minDatePicker={minDatePicker}
      maxDatePicker={maxDatePicker}
      onPickerToggle={onPickerToggle}
    />
  );
}

export default React.memo(React.forwardRef(DateInputControl), isEqual) as <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: DateInputControlProps<TFieldValues, TName> & {
    ref?: React.ForwardedRef<HTMLInputElement>;
  },
) => ReturnType<typeof DateInputControl>;
