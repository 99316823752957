import { Button, forwardRef } from '@chakra-ui/react';
import { HTMLChakraProps, ThemingProps } from '@chakra-ui/system';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import useFileDrop from './use-file-drop';

interface UploadFileButtonProps extends HTMLChakraProps<'button'>, ThemingProps<'Button'> {
  acceptFileTypes: string[];
  isUploading: boolean;
  uploadFile: (file: FileList | null) => void;
  buttonText?: string;
  multiple?: boolean;
  showIcon?: boolean;
}

export default forwardRef<UploadFileButtonProps, 'button'>(
  (
    {
      acceptFileTypes,
      isUploading,
      uploadFile,
      buttonText,
      sx,
      multiple = false,
      showIcon = true,
      ...props
    }: UploadFileButtonProps,
    ref,
  ) => {
    const { t } = useTranslation('attachment');
    const { field, isDragging } = useFileDrop(uploadFile, multiple);

    return (
      <Button
        ref={ref}
        colorScheme="teal"
        size="sm"
        leftIcon={showIcon ? <FontAwesomeIcon icon={faPlus} /> : undefined}
        isLoading={isUploading}
        loadingText={t('upload_in_progress')}
        className={clsx({ 'is-drag-over': isDragging })}
        sx={{ ...sx, '&.is-drag-over': dragStyle }}
        {...props}
        title=""
      >
        {isDragging
          ? t('drag_drop_description')
          : buttonText ??
            t('upload', {
              fileType: buttonText ?? t('file'),
            })}
        <input
          type="file"
          accept={acceptFileTypes.join(', ')}
          onChange={(e) => uploadFile(e.target.files)}
          multiple={multiple}
          style={{
            position: 'absolute',
            zIndex: 1,
            width: '100%',
            height: '100%',
            opacity: 0,
            cursor: 'pointer',
          }}
          {...field}
        />
      </Button>
    );
  },
);

const dragStyle: CSSProperties = {
  backgroundColor: '#eefbf9',
  border: '2px dotted #319795',
};
