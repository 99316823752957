/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DateRangeDto } from './DateRangeDto';
import {
    DateRangeDtoFromJSON,
    DateRangeDtoFromJSONTyped,
    DateRangeDtoToJSON,
} from './DateRangeDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { PersonStatusDto } from './PersonStatusDto';
import {
    PersonStatusDtoFromJSON,
    PersonStatusDtoFromJSONTyped,
    PersonStatusDtoToJSON,
} from './PersonStatusDto';

/**
 * The connection between a location and a person
 * @export
 * @interface LocationPersonRelationDto
 */
export interface LocationPersonRelationDto {
    /**
     * 
     * @type {string}
     * @memberof LocationPersonRelationDto
     */
    relationType: LocationPersonRelationDtoRelationTypeEnum;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof LocationPersonRelationDto
     */
    connectedPerson: PersonReferenceDto;
    /**
     * 
     * @type {PersonStatusDto}
     * @memberof LocationPersonRelationDto
     */
    personStatus?: PersonStatusDto;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof LocationPersonRelationDto
     */
    dateRange: DateRangeDto;
    /**
     * 
     * @type {string}
     * @memberof LocationPersonRelationDto
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof LocationPersonRelationDto
     */
    timestamp?: Date;
}


/**
 * @export
 */
export const LocationPersonRelationDtoRelationTypeEnum = {
    HOUSEKEEPING: 'HOUSEKEEPING',
    IFB_CINEMA_MANAGEMENT: 'IFB_CINEMA_MANAGEMENT',
    LOUNGE_COORDINATION: 'LOUNGE_COORDINATION',
    CINEMA_SUPPORT: 'CINEMA_SUPPORT',
    CLEANING_SERVICE: 'CLEANING_SERVICE',
    TECHNICAL_MANAGEMENT: 'TECHNICAL_MANAGEMENT',
    CINEMA_MANAGEMENT: 'CINEMA_MANAGEMENT'
} as const;
export type LocationPersonRelationDtoRelationTypeEnum = typeof LocationPersonRelationDtoRelationTypeEnum[keyof typeof LocationPersonRelationDtoRelationTypeEnum];


/**
 * Check if a given object implements the LocationPersonRelationDto interface.
 */
export function instanceOfLocationPersonRelationDto(value: object): boolean {
    if (!('relationType' in value)) return false;
    if (!('connectedPerson' in value)) return false;
    if (!('dateRange' in value)) return false;
    return true;
}

export function LocationPersonRelationDtoFromJSON(json: any): LocationPersonRelationDto {
    return LocationPersonRelationDtoFromJSONTyped(json, false);
}

export function LocationPersonRelationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationPersonRelationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'relationType': json['relationType'],
        'connectedPerson': PersonReferenceDtoFromJSON(json['connectedPerson']),
        'personStatus': json['personStatus'] == null ? undefined : PersonStatusDtoFromJSON(json['personStatus']),
        'dateRange': DateRangeDtoFromJSON(json['dateRange']),
        'comment': json['comment'] == null ? undefined : json['comment'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
    };
}

export function LocationPersonRelationDtoToJSON(value?: LocationPersonRelationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'relationType': value['relationType'],
        'connectedPerson': PersonReferenceDtoToJSON(value['connectedPerson']),
        'personStatus': PersonStatusDtoToJSON(value['personStatus']),
        'dateRange': DateRangeDtoToJSON(value['dateRange']),
        'comment': value['comment'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
    };
}

