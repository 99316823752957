import React from 'react';
import { useParams } from 'react-router-dom';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchStand } from '../stand-queries';
import { getStandId } from './get-stand-id';

export default function StandBreadcrumb() {
  const stand = useFetcher(fetchStand, { id: getStandId(useParams()) });

  return <>{stand.name}</>;
}
