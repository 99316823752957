/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ExportFormatDto } from './ExportFormatDto';
import {
    ExportFormatDtoFromJSON,
    ExportFormatDtoFromJSONTyped,
    ExportFormatDtoToJSON,
} from './ExportFormatDto';

/**
 * 
 * @export
 * @interface PersonOnGuestListExportDto
 */
export interface PersonOnGuestListExportDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof PersonOnGuestListExportDto
     */
    filters?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PersonOnGuestListExportDto
     */
    reason: string;
    /**
     * 
     * @type {ExportFormatDto}
     * @memberof PersonOnGuestListExportDto
     */
    format: ExportFormatDto;
}

/**
 * Check if a given object implements the PersonOnGuestListExportDto interface.
 */
export function instanceOfPersonOnGuestListExportDto(value: object): boolean {
    if (!('reason' in value)) return false;
    if (!('format' in value)) return false;
    return true;
}

export function PersonOnGuestListExportDtoFromJSON(json: any): PersonOnGuestListExportDto {
    return PersonOnGuestListExportDtoFromJSONTyped(json, false);
}

export function PersonOnGuestListExportDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonOnGuestListExportDto {
    if (json == null) {
        return json;
    }
    return {
        
        'filters': json['filters'] == null ? undefined : json['filters'],
        'reason': json['reason'],
        'format': ExportFormatDtoFromJSON(json['format']),
    };
}

export function PersonOnGuestListExportDtoToJSON(value?: PersonOnGuestListExportDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'filters': value['filters'],
        'reason': value['reason'],
        'format': ExportFormatDtoToJSON(value['format']),
    };
}

