import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useAccessGroupPermissionClassGroupExtension(): PermissionClassGroupExtension<'ACCESS_GROUP'> {
  const { t } = useTranslation(['access_group']);

  return {
    name: 'ACCESS_GROUP',
    label: t('access_group:accessGroup'),
    getActionLabel: (action) => t(`access_group:permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`access_group:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`access_group:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
