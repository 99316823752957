/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddStaffToGroupDto,
  BooleanDto,
  FailureDto,
  GroupStaffAdminPermissionDto,
  GroupStaffPageDto,
  GroupStaffRelationDto,
  HistoryPageDto,
  PageableDto,
} from '../models/index';
import {
    AddStaffToGroupDtoFromJSON,
    AddStaffToGroupDtoToJSON,
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    GroupStaffAdminPermissionDtoFromJSON,
    GroupStaffAdminPermissionDtoToJSON,
    GroupStaffPageDtoFromJSON,
    GroupStaffPageDtoToJSON,
    GroupStaffRelationDtoFromJSON,
    GroupStaffRelationDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface AddStaffToGroupRequest {
    addStaffToGroupDto: AddStaffToGroupDto;
}

export interface CanAddStaffToGroupRequest {
    groupId: string;
    staffId: string;
}

export interface CheckGroupStaffAdminAvailableRequest {
    id: string;
}

export interface DeleteGroupStaffByIdsRequest {
    ids: Array<string>;
}

export interface FetchGroupStaffHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchGroupStaffRelationRequest {
    id: string;
}

export interface SearchGroupStaffRelationsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface UpdateGroupStaffRelationRequest {
    id: string;
    groupStaffRelationDto: GroupStaffRelationDto;
}

/**
 * 
 */
export class GroupStaffApi extends runtime.BaseAPI {

    /**
     * Add staff to group.
     */
    async addStaffToGroupRaw(requestParameters: AddStaffToGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['addStaffToGroupDto'] == null) {
            throw new runtime.RequiredError(
                'addStaffToGroupDto',
                'Required parameter "addStaffToGroupDto" was null or undefined when calling addStaffToGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-staff-relations/add-staff`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddStaffToGroupDtoToJSON(requestParameters['addStaffToGroupDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add staff to group.
     */
    async addStaffToGroup(requestParameters: AddStaffToGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addStaffToGroupRaw(requestParameters, initOverrides);
    }

    /**
     * Get whether the specified staff can be added to the group.
     */
    async canAddStaffToGroupRaw(requestParameters: CanAddStaffToGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        if (requestParameters['groupId'] == null) {
            throw new runtime.RequiredError(
                'groupId',
                'Required parameter "groupId" was null or undefined when calling canAddStaffToGroup().'
            );
        }

        if (requestParameters['staffId'] == null) {
            throw new runtime.RequiredError(
                'staffId',
                'Required parameter "staffId" was null or undefined when calling canAddStaffToGroup().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['staffId'] != null) {
            queryParameters['staffId'] = requestParameters['staffId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-staff-relations/{groupId}/can-add-staff`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters['groupId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     * Get whether the specified staff can be added to the group.
     */
    async canAddStaffToGroup(requestParameters: CanAddStaffToGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.canAddStaffToGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the current permission to make an employee group admin.
     */
    async checkGroupStaffAdminAvailableRaw(requestParameters: CheckGroupStaffAdminAvailableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupStaffAdminPermissionDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling checkGroupStaffAdminAvailable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-staff-relations/{id}/admin-permission`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupStaffAdminPermissionDtoFromJSON(jsonValue));
    }

    /**
     * Get the current permission to make an employee group admin.
     */
    async checkGroupStaffAdminAvailable(requestParameters: CheckGroupStaffAdminAvailableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupStaffAdminPermissionDto> {
        const response = await this.checkGroupStaffAdminAvailableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteGroupStaffByIdsRaw(requestParameters: DeleteGroupStaffByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['ids'] == null) {
            throw new runtime.RequiredError(
                'ids',
                'Required parameter "ids" was null or undefined when calling deleteGroupStaffByIds().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ids'] != null) {
            queryParameters['ids'] = requestParameters['ids']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-staff-relations/delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteGroupStaffByIds(requestParameters: DeleteGroupStaffByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteGroupStaffByIdsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async fetchGroupStaffHistoryRaw(requestParameters: FetchGroupStaffHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchGroupStaffHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-staff-relations/versions-by-group/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchGroupStaffHistory(requestParameters: FetchGroupStaffHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchGroupStaffHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the group-staff relation
     */
    async fetchGroupStaffRelationRaw(requestParameters: FetchGroupStaffRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupStaffRelationDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchGroupStaffRelation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-staff-relations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupStaffRelationDtoFromJSON(jsonValue));
    }

    /**
     * Get the group-staff relation
     */
    async fetchGroupStaffRelation(requestParameters: FetchGroupStaffRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupStaffRelationDto> {
        const response = await this.fetchGroupStaffRelationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for group staff relations.
     */
    async searchGroupStaffRelationsRaw(requestParameters: SearchGroupStaffRelationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupStaffPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-staff-relations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupStaffPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for group staff relations.
     */
    async searchGroupStaffRelations(requestParameters: SearchGroupStaffRelationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupStaffPageDto> {
        const response = await this.searchGroupStaffRelationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the existing group-staff relation.
     */
    async updateGroupStaffRelationRaw(requestParameters: UpdateGroupStaffRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupStaffRelationDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateGroupStaffRelation().'
            );
        }

        if (requestParameters['groupStaffRelationDto'] == null) {
            throw new runtime.RequiredError(
                'groupStaffRelationDto',
                'Required parameter "groupStaffRelationDto" was null or undefined when calling updateGroupStaffRelation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-staff-relations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GroupStaffRelationDtoToJSON(requestParameters['groupStaffRelationDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupStaffRelationDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing group-staff relation.
     */
    async updateGroupStaffRelation(requestParameters: UpdateGroupStaffRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupStaffRelationDto> {
        const response = await this.updateGroupStaffRelationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
