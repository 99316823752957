/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AccessGroupReferenceDto,
  BuyerOccupationFormatDto,
  CancelInfoInnerDto,
  FailureDto,
  HistoryPageDto,
  OrderBlockedDto,
  OrderDto,
  OrderEditorViewItemPageDto,
  OrderPageDto,
  OrderProductBundleViewerItemPageDto,
  OrderProductViewerItemPageDto,
  OrderRejectionDto,
  OrderVerificationDto,
  PageableDto,
  PersonOrderPageDto,
  ProductReferenceDto,
} from '../models/index';
import {
    AccessGroupReferenceDtoFromJSON,
    AccessGroupReferenceDtoToJSON,
    BuyerOccupationFormatDtoFromJSON,
    BuyerOccupationFormatDtoToJSON,
    CancelInfoInnerDtoFromJSON,
    CancelInfoInnerDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    OrderBlockedDtoFromJSON,
    OrderBlockedDtoToJSON,
    OrderDtoFromJSON,
    OrderDtoToJSON,
    OrderEditorViewItemPageDtoFromJSON,
    OrderEditorViewItemPageDtoToJSON,
    OrderPageDtoFromJSON,
    OrderPageDtoToJSON,
    OrderProductBundleViewerItemPageDtoFromJSON,
    OrderProductBundleViewerItemPageDtoToJSON,
    OrderProductViewerItemPageDtoFromJSON,
    OrderProductViewerItemPageDtoToJSON,
    OrderRejectionDtoFromJSON,
    OrderRejectionDtoToJSON,
    OrderVerificationDtoFromJSON,
    OrderVerificationDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    PersonOrderPageDtoFromJSON,
    PersonOrderPageDtoToJSON,
    ProductReferenceDtoFromJSON,
    ProductReferenceDtoToJSON,
} from '../models/index';

export interface AcceptOrderRequest {
    id: string;
    orderVerificationDto: OrderVerificationDto;
}

export interface BlockOrderRequest {
    id: string;
    body: string;
}

export interface CancelOrderRequest {
    id: string;
    orderDto: OrderDto;
}

export interface CreateOrderRequest {
    orderDto: OrderDto;
}

export interface FetchBuyerFormatOccupationRequest {
    id: string;
}

export interface FetchOrderRequest {
    id: string;
}

export interface FetchOrderHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchProductsOfPersonRequest {
    id: string;
    editionIdentifier?: string;
}

export interface ForwardOrderRequest {
    id: string;
    body: string;
}

export interface GetCancelInfoRequest {
    id: string;
}

export interface GetOrderAccessGroupsRequest {
    id: string;
}

export interface GetSuggestedAccessGroupsRequest {
    id: string;
}

export interface RejectOrderRequest {
    id: string;
    orderRejectionDto: OrderRejectionDto;
}

export interface SearchEditorViewItemsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchOrdersRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
    focusedEditionId?: string;
}

export interface SearchOrdersByPersonRequest {
    id: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchProductBundleOrderViewerItemsRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
    buyerId?: string;
}

export interface SearchProductViewerItemsRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
    buyerId?: string;
}

export interface UnlockOrderRequest {
    id: string;
}

export interface UpdateOrderRequest {
    id: string;
    orderDto: OrderDto;
}

/**
 * 
 */
export class OrderApi extends runtime.BaseAPI {

    /**
     * Accept the existing order.
     */
    async acceptOrderRaw(requestParameters: AcceptOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling acceptOrder().'
            );
        }

        if (requestParameters['orderVerificationDto'] == null) {
            throw new runtime.RequiredError(
                'orderVerificationDto',
                'Required parameter "orderVerificationDto" was null or undefined when calling acceptOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/{id}/accept`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrderVerificationDtoToJSON(requestParameters['orderVerificationDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Accept the existing order.
     */
    async acceptOrder(requestParameters: AcceptOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acceptOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Block the order
     */
    async blockOrderRaw(requestParameters: BlockOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling blockOrder().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling blockOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/{id}/block`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Block the order
     */
    async blockOrder(requestParameters: BlockOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.blockOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Cancel the existing order.
     */
    async cancelOrderRaw(requestParameters: CancelOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling cancelOrder().'
            );
        }

        if (requestParameters['orderDto'] == null) {
            throw new runtime.RequiredError(
                'orderDto',
                'Required parameter "orderDto" was null or undefined when calling cancelOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/{id}/cancel`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrderDtoToJSON(requestParameters['orderDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancel the existing order.
     */
    async cancelOrder(requestParameters: CancelOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.cancelOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new order.
     */
    async createOrderRaw(requestParameters: CreateOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['orderDto'] == null) {
            throw new runtime.RequiredError(
                'orderDto',
                'Required parameter "orderDto" was null or undefined when calling createOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderDtoToJSON(requestParameters['orderDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new order.
     */
    async createOrder(requestParameters: CreateOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Get the order buyer occupations incl. formats.
     */
    async fetchBuyerFormatOccupationRaw(requestParameters: FetchBuyerFormatOccupationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BuyerOccupationFormatDto>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchBuyerFormatOccupation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/buyer-occupations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BuyerOccupationFormatDtoFromJSON));
    }

    /**
     * Get the order buyer occupations incl. formats.
     */
    async fetchBuyerFormatOccupation(requestParameters: FetchBuyerFormatOccupationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BuyerOccupationFormatDto>> {
        const response = await this.fetchBuyerFormatOccupationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the order business object.
     */
    async fetchOrderRaw(requestParameters: FetchOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDtoFromJSON(jsonValue));
    }

    /**
     * Get the order business object.
     */
    async fetchOrder(requestParameters: FetchOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderDto> {
        const response = await this.fetchOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the order.
     */
    async fetchOrderHistoryRaw(requestParameters: FetchOrderHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchOrderHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the order.
     */
    async fetchOrderHistory(requestParameters: FetchOrderHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchOrderHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the products of a person in the given edition.
     */
    async fetchProductsOfPersonRaw(requestParameters: FetchProductsOfPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductReferenceDto>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchProductsOfPerson().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['editionIdentifier'] != null) {
            queryParameters['editionIdentifier'] = requestParameters['editionIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/person-products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductReferenceDtoFromJSON));
    }

    /**
     * Get the products of a person in the given edition.
     */
    async fetchProductsOfPerson(requestParameters: FetchProductsOfPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductReferenceDto>> {
        const response = await this.fetchProductsOfPersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Forward the order
     */
    async forwardOrderRaw(requestParameters: ForwardOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling forwardOrder().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling forwardOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/{id}/forward`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Forward the order
     */
    async forwardOrder(requestParameters: ForwardOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.forwardOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Gets weather the products and product bundles can be cancelled
     */
    async getCancelInfoRaw(requestParameters: GetCancelInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CancelInfoInnerDto>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getCancelInfo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/{id}/cancelInfo`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CancelInfoInnerDtoFromJSON));
    }

    /**
     * Gets weather the products and product bundles can be cancelled
     */
    async getCancelInfo(requestParameters: GetCancelInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CancelInfoInnerDto>> {
        const response = await this.getCancelInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for the access groups from products and product bundles in order.
     */
    async getOrderAccessGroupsRaw(requestParameters: GetOrderAccessGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AccessGroupReferenceDto>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getOrderAccessGroups().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/order-access-groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AccessGroupReferenceDtoFromJSON));
    }

    /**
     * Search for the access groups from products and product bundles in order.
     */
    async getOrderAccessGroups(requestParameters: GetOrderAccessGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AccessGroupReferenceDto>> {
        const response = await this.getOrderAccessGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for the suggested access groups for a person.
     */
    async getSuggestedAccessGroupsRaw(requestParameters: GetSuggestedAccessGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AccessGroupReferenceDto>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getSuggestedAccessGroups().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suggestedAccessGroups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AccessGroupReferenceDtoFromJSON));
    }

    /**
     * Search for the suggested access groups for a person.
     */
    async getSuggestedAccessGroups(requestParameters: GetSuggestedAccessGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AccessGroupReferenceDto>> {
        const response = await this.getSuggestedAccessGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reject the order.
     */
    async rejectOrderRaw(requestParameters: RejectOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling rejectOrder().'
            );
        }

        if (requestParameters['orderRejectionDto'] == null) {
            throw new runtime.RequiredError(
                'orderRejectionDto',
                'Required parameter "orderRejectionDto" was null or undefined when calling rejectOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/{id}/reject`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderRejectionDtoToJSON(requestParameters['orderRejectionDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reject the order.
     */
    async rejectOrder(requestParameters: RejectOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.rejectOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Search for orders.
     */
    async searchEditorViewItemsRaw(requestParameters: SearchEditorViewItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderEditorViewItemPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/editor`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderEditorViewItemPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for orders.
     */
    async searchEditorViewItems(requestParameters: SearchEditorViewItemsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderEditorViewItemPageDto> {
        const response = await this.searchEditorViewItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for orders.
     */
    async searchOrdersRaw(requestParameters: SearchOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        if (requestParameters['focusedEditionId'] != null) {
            queryParameters['focusedEditionId'] = requestParameters['focusedEditionId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for orders.
     */
    async searchOrders(requestParameters: SearchOrdersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderPageDto> {
        const response = await this.searchOrdersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for orders by person.
     */
    async searchOrdersByPersonRaw(requestParameters: SearchOrdersByPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonOrderPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling searchOrdersByPerson().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/person-orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonOrderPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for orders by person.
     */
    async searchOrdersByPerson(requestParameters: SearchOrdersByPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonOrderPageDto> {
        const response = await this.searchOrdersByPersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for product bundles order viewer items according to access groups.
     */
    async searchProductBundleOrderViewerItemsRaw(requestParameters: SearchProductBundleOrderViewerItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderProductBundleViewerItemPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        if (requestParameters['buyerId'] != null) {
            queryParameters['buyerId'] = requestParameters['buyerId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/productBundleViewerItems`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderProductBundleViewerItemPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for product bundles order viewer items according to access groups.
     */
    async searchProductBundleOrderViewerItems(requestParameters: SearchProductBundleOrderViewerItemsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderProductBundleViewerItemPageDto> {
        const response = await this.searchProductBundleOrderViewerItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for product viewer items according to access groups.
     */
    async searchProductViewerItemsRaw(requestParameters: SearchProductViewerItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderProductViewerItemPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        if (requestParameters['buyerId'] != null) {
            queryParameters['buyerId'] = requestParameters['buyerId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/productViewerItems`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderProductViewerItemPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for product viewer items according to access groups.
     */
    async searchProductViewerItems(requestParameters: SearchProductViewerItemsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderProductViewerItemPageDto> {
        const response = await this.searchProductViewerItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new order.
     */
    async templateOrderRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new order.
     */
    async templateOrder(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderDto> {
        const response = await this.templateOrderRaw(initOverrides);
        return await response.value();
    }

    /**
     * unlock the order
     */
    async unlockOrderRaw(requestParameters: UnlockOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling unlockOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/{id}/unlock`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * unlock the order
     */
    async unlockOrder(requestParameters: UnlockOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unlockOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Update the existing order.
     */
    async updateOrderRaw(requestParameters: UpdateOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateOrder().'
            );
        }

        if (requestParameters['orderDto'] == null) {
            throw new runtime.RequiredError(
                'orderDto',
                'Required parameter "orderDto" was null or undefined when calling updateOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrderDtoToJSON(requestParameters['orderDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the existing order.
     */
    async updateOrder(requestParameters: UpdateOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateOrderRaw(requestParameters, initOverrides);
    }

}
