/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FilmStatusDto = {
    CONFIRMED: 'CONFIRMED',
    CANCELLED: 'CANCELLED',
    DELETED: 'DELETED'
} as const;
export type FilmStatusDto = typeof FilmStatusDto[keyof typeof FilmStatusDto];


export function FilmStatusDtoFromJSON(json: any): FilmStatusDto {
    return FilmStatusDtoFromJSONTyped(json, false);
}

export function FilmStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilmStatusDto {
    return json as FilmStatusDto;
}

export function FilmStatusDtoToJSON(value?: FilmStatusDto | null): any {
    return value as any;
}

