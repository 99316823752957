import registry from '../../registry';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import LandingPageTemplateBreadcrumb from './common/landing-page-template-breadcrumb';
import { LANDING_PAGE_TEMPLATE_VIEWER_TAB } from './landing-page-template-plugins';

const landingPageTemplateRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'landing-page-templates',
    handle: {
      breadcrumb: <Translate ns="landing_page_template" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./landing-page-template-lister/landing-page-template-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./landing-page-template-editor/new-landing-page-template-route'),
      },
      {
        path: ':landingPageTemplateId',
        handle: {
          breadcrumb: <LandingPageTemplateBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./landing-page-template-page/landing-page-template-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./landing-page-template-viewer/landing-page-template-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./landing-page-template-history/landing-page-template-history-route'),
              },
              ...registry.getAll(LANDING_PAGE_TEMPLATE_VIEWER_TAB).map((value) => value.route),
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./landing-page-template-editor/edit-landing-page-template-route'),
          },
          {
            path: 'copy',
            lazy: () => import('./landing-page-template-editor/copy-landing-page-template-route'),
          },
        ],
      },
    ],
  },
];

export default landingPageTemplateRoutes;
