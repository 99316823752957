import { useToast as useBaseToast, UseToastOptions } from '@chakra-ui/react';
import { isEqual } from 'lodash-es';
import React from 'react';

export const DEFAULT_TOAST_OPTIONS: UseToastOptions = {
  position: 'top',
  isClosable: true,
  duration: 5000,
  variant: 'solid',
};

export default function useToast(immutableOptions?: UseToastOptions) {
  const immutableOptionsRef = React.useRef<UseToastOptions>();

  if (import.meta.env.DEV) {
    if (immutableOptionsRef.current != null && !isEqual(immutableOptionsRef.current, immutableOptions)) {
      throw new Error(
        'Toast options passed to useToast must be immutable. Use the options in the returned callback instead.',
      );
    }

    immutableOptionsRef.current = immutableOptions;
  }

  return useBaseToast({
    ...DEFAULT_TOAST_OPTIONS,
    ...immutableOptions,
  });
}
