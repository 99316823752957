import React from 'react';

const Empty = React.lazy(() => import('./empty'));

// lh: The empty component uses a lot of FontAwesome icons. To reduce build size we use a lazy
// component and a direct suspense border without fallback.
export default function EmptyLazy({ text }: { text?: string }) {
  return (
    <React.Suspense fallback={<></>}>
      <Empty text={text} />
    </React.Suspense>
  );
}
