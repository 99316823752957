import orderConfirmationApi from '../../data-access/order-confirmation-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';

export const orderConfirmationFetcher = createFetcherScope(orderConfirmationApi);
export const fetchOrderConfirmations = orderConfirmationFetcher.createFetcher(
  orderConfirmationApi.searchOrderConfirmations,
);
export const fetchOrderConfirmation = orderConfirmationFetcher.createFetcher(
  orderConfirmationApi.fetchOrderConfirmation,
);
export const fetchOrderConfirmationHistory = orderConfirmationFetcher.createFetcher(
  orderConfirmationApi.fetchOrderConfirmmationHistory,
);
