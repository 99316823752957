import {
  FormControl as BaseFormControl,
  FormControlProps as BaseFormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
} from '@chakra-ui/react';
import React from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';
import ErrorMessage from './error-message';
import { getHelperTextSize } from './helper-text-size';

export interface FormControlProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<BaseFormControlProps, 'label'> {
  name: TName;
  label?: React.ReactNode;
  children: React.ReactNode;
  helperText?: React.ReactNode;
  helperPopover?: React.ReactElement;
}

export default function FormControl<TFieldValues extends FieldValues>({
  name,
  label,
  children,
  helperText,
  helperPopover,
  size,
  variant,
  ...props
}: FormControlProps<TFieldValues>) {
  const { fieldState } = useController({ name });
  const helperTextFontSize = getHelperTextSize(size);

  return (
    <BaseFormControl isInvalid={fieldState.error != null} {...props} variant={variant}>
      <HStack spacing={0} alignItems="flex-start">
        <FormLabel marginInlineEnd={0} fontSize={size} variant={variant}>
          {label}
        </FormLabel>
        {helperPopover}
      </HStack>
      {children}
      {helperText != null && fieldState.error == null && (
        <FormHelperText fontSize={helperTextFontSize}>{helperText}</FormHelperText>
      )}
      <ErrorMessage name={name} as={FormErrorMessage} fontSize={helperTextFontSize} variant={variant} />
    </BaseFormControl>
  );
}
