/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TaxExemptionAreaDto = {
    EU: 'EU',
    OTHER: 'OTHER'
} as const;
export type TaxExemptionAreaDto = typeof TaxExemptionAreaDto[keyof typeof TaxExemptionAreaDto];


export function TaxExemptionAreaDtoFromJSON(json: any): TaxExemptionAreaDto {
    return TaxExemptionAreaDtoFromJSONTyped(json, false);
}

export function TaxExemptionAreaDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxExemptionAreaDto {
    return json as TaxExemptionAreaDto;
}

export function TaxExemptionAreaDtoToJSON(value?: TaxExemptionAreaDto | null): any {
    return value as any;
}

