import { Button, FormLabel, HStack, Stack, Text } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import invariant from 'tiny-invariant';
import { v4 as uuid } from 'uuid';
import {
  AddPeoplePeopleInnerDto,
  CategoryOfParticipationDto,
  GroupDto,
  GroupPersonRelationDto,
  GuestTypeDto,
  RelationTypeDto,
} from '../../../api';
import groupApi from '../../../data-access/group-api';
import groupPersonApi from '../../../data-access/group-person-api';
import guestListApi from '../../../data-access/guest-list-api';
import { DataTableColumn, DataTableSelection } from '../../../ui/data-table';
import DataTable from '../../../ui/data-table/data-table';
import DataTableState from '../../../ui/data-table/data-table-state';
import { PageSpinner } from '../../../ui/page';
import ValueAsyncSelect from '../../../ui/select/value-async-select';
import ValueSelect from '../../../ui/select/value-select';
import PersonReference from '../../person/person-reference/person-reference';

enum GroupType {
  ALL = 'ALL',
  MAIN_GROUP = 'MAIN_GROUP',
  SECONDARY_GROUP = 'SECONDARY_GROUP',
}

interface GroupSelectionControlProps {
  onAdd: (entries: AddPeoplePeopleInnerDto[]) => void;
  peopleOnLocalList: AddPeoplePeopleInnerDto[];
  guestListId: string;
}

export default function GroupSelectionControl({ onAdd, peopleOnLocalList, guestListId }: GroupSelectionControlProps) {
  const { t } = useTranslation(['common', 'guest_list']);

  const [selectedGroup, setSelectedGroup] = React.useState<GroupDto | null>(null);
  const groupTypeOptions = [GroupType.ALL, GroupType.MAIN_GROUP, GroupType.SECONDARY_GROUP];
  const [groupType, setGroupType] = React.useState(GroupType.ALL);
  const [peopleOnRemoteList, setPeopleOnRemoteList] = React.useState<string[]>([]);

  const initialState = {
    page: 0,
    size: 20,
    filter: [],
    sort: [],
    selection: [],
  };
  const [dataTableState, setDataTableState] = React.useState<Partial<DataTableState>>(initialState);

  const { data: groupPersonPage, isLoading } = useSWR(
    ['groupPersonPage', groupType, selectedGroup, dataTableState.page],
    async () => {
      if (selectedGroup == null) {
        return null;
      }
      const typeFilter = groupType != GroupType.ALL ? [`relationType,eq,${groupType}`] : [];
      return await groupPersonApi.searchGroupPersonRelations({
        filter: [...typeFilter, `group.id,eq,${selectedGroup?.id}`],
        pageable: {
          page: dataTableState.page,
          size: 20,
          sort: ['person.surname,ASC', 'person.firstName,ASC'],
        },
      });
    },
  );

  const columns: DataTableColumn<GroupPersonRelationDto>[] = [
    {
      key: 'person.surname',
      name: t('guest_list:person'),
      cellProps: {
        whiteSpace: 'nowrap',
      },
      renderCell: (relation) => (
        <HStack>
          <PersonReference
            personReference={{
              id: relation.person.id!,
              firstName: relation.person.firstName,
              surname: relation.person.surname,
              personKey: relation.person.personKey,
            }}
            usePortalForCard
            flipName
            hideIcon
          />
          {isDuplicate(relation.person.id!) && <Text color="gray.500">{t('guest_list:alreadyOnGuestList')}</Text>}
        </HStack>
      ),
    },
  ];

  const rowKey = React.useCallback((groupPerson: GroupPersonRelationDto) => {
    invariant(groupPerson.id != null, 'Missing group person relation id');

    return groupPerson.id;
  }, []);

  const isDuplicate = React.useCallback(
    (personId: string) => {
      const onLocalList = peopleOnLocalList.some((e) => e.person?.id === personId);
      const onRemoteList = peopleOnRemoteList.includes(personId);
      return onLocalList || onRemoteList;
    },
    [peopleOnLocalList, peopleOnRemoteList],
  );

  const selection = React.useMemo<DataTableSelection<GroupPersonRelationDto>>(
    () => ({
      keySelector: rowKey,
      isDisabled: (relation) => isDuplicate(relation.person.id!),
    }),
    [isDuplicate, rowKey],
  );

  const validateNoRemoteDuplicate = async (selectedGroup: GroupDto | null) => {
    if (selectedGroup == null) {
      setPeopleOnRemoteList([]);
      return;
    }
    const peeps = await guestListApi.findPeopleFromGroupOnGuestList({
      groupId: selectedGroup?.id,
      guestListId: guestListId,
    });
    setPeopleOnRemoteList(peeps);
  };

  const getGroupPeopleByGroup = (selectedGroup: GroupDto | null) => {
    setSelectedGroup(selectedGroup);
    setDataTableState(initialState);
    validateNoRemoteDuplicate(selectedGroup);
  };

  const getGroupPeopleByType = (groupType: GroupType) => {
    setGroupType(groupType);
    if (selectedGroup == null) {
      return;
    }
    setDataTableState(initialState);
  };

  const handleAdd = () => {
    const peopleToAdd =
      groupPersonPage != null
        ? groupPersonPage?.content.filter((relation) => dataTableState.selection?.includes(relation.id!))
        : [];
    let groupPeople: AddPeoplePeopleInnerDto[] = [];
    if (groupPersonPage != null) {
      groupPeople = peopleToAdd.map((relation) => ({
        id: uuid(),
        guestType: GuestTypeDto.PERSON,
        category:
          relation.relationType == RelationTypeDto.MAIN_GROUP
            ? CategoryOfParticipationDto.GUEST
            : CategoryOfParticipationDto.PLUS_ONE,
        person: {
          id: relation.person.id!,
          firstName: relation.person.firstName,
          surname: relation.person.surname,
          personKey: relation.person.personKey,
        },
      }));
    }
    onAdd(groupPeople);
    setSelectedGroup(null);
    setGroupType(GroupType.ALL);
    setDataTableState(initialState);
  };

  return (
    <Stack spacing={4} paddingX={4} paddingBottom={4} paddingTop={3}>
      <Stack spacing={0}>
        <FormLabel fontSize="sm">{t('guest_list:group')}</FormLabel>
        <ValueAsyncSelect<GroupDto>
          aria-label={t('guest_list:group')}
          size="sm"
          placeholder={t('common:select.placeholder')}
          loadOptions={async (value) => {
            const groups = await groupApi.searchGroups({
              filter: ['status,eq,ACTIVE', `name,contain,${value}`],
            });
            return groups.content;
          }}
          onChange={(newValue) => getGroupPeopleByGroup(newValue)}
          renderLabel={(option) => option.name + ' (' + option.edition.name + ')'}
          optionIdentifier={(option) => option.id}
          value={selectedGroup}
        />
      </Stack>

      <Stack spacing={0}>
        <FormLabel fontSize="sm">{t('guest_list:groupPersonType')}</FormLabel>
        <ValueSelect<GroupType>
          size="sm"
          options={groupTypeOptions}
          renderLabel={(type) => t(`guest_list:groupTypeOptions.${type}`)}
          onChange={(groupType) => getGroupPeopleByType(groupType!)}
          value={groupType}
          defaultValue={GroupType.ALL}
        />
      </Stack>

      {isLoading ? (
        <PageSpinner />
      ) : (
        <DataTable
          size="sm"
          page={groupPersonPage == null ? { content: [] } : groupPersonPage}
          state={dataTableState}
          columns={columns}
          rowKey={rowKey}
          selection={selection}
          onStateChange={setDataTableState}
          isPageable
        />
      )}

      <Button onClick={() => handleAdd()} leftIcon={<FontAwesomeIcon icon={faPlus} />} variant="outline" size="sm">
        {t('common:action.add')}
      </Button>
    </Stack>
  );
}
