/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FormatReferenceDto } from './FormatReferenceDto';
import {
    FormatReferenceDtoFromJSON,
    FormatReferenceDtoFromJSONTyped,
    FormatReferenceDtoToJSON,
} from './FormatReferenceDto';
import type { TagReferenceDto } from './TagReferenceDto';
import {
    TagReferenceDtoFromJSON,
    TagReferenceDtoFromJSONTyped,
    TagReferenceDtoToJSON,
} from './TagReferenceDto';
import type { TagRelationTypeDto } from './TagRelationTypeDto';
import {
    TagRelationTypeDtoFromJSON,
    TagRelationTypeDtoFromJSONTyped,
    TagRelationTypeDtoToJSON,
} from './TagRelationTypeDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * A relation between a tag and a format
 * @export
 * @interface TagFormatRelationDto
 */
export interface TagFormatRelationDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof TagFormatRelationDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof TagFormatRelationDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {TagReferenceDto}
     * @memberof TagFormatRelationDto
     */
    tag: TagReferenceDto;
    /**
     * 
     * @type {TagRelationTypeDto}
     * @memberof TagFormatRelationDto
     */
    relationType: TagRelationTypeDto;
    /**
     * 
     * @type {FormatReferenceDto}
     * @memberof TagFormatRelationDto
     */
    relation: FormatReferenceDto;
}

/**
 * Check if a given object implements the TagFormatRelationDto interface.
 */
export function instanceOfTagFormatRelationDto(value: object): boolean {
    if (!('tag' in value)) return false;
    if (!('relationType' in value)) return false;
    if (!('relation' in value)) return false;
    return true;
}

export function TagFormatRelationDtoFromJSON(json: any): TagFormatRelationDto {
    return TagFormatRelationDtoFromJSONTyped(json, false);
}

export function TagFormatRelationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagFormatRelationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'tag': TagReferenceDtoFromJSON(json['tag']),
        'relationType': TagRelationTypeDtoFromJSON(json['relationType']),
        'relation': FormatReferenceDtoFromJSON(json['relation']),
    };
}

export function TagFormatRelationDtoToJSON(value?: TagFormatRelationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'tag': TagReferenceDtoToJSON(value['tag']),
        'relationType': TagRelationTypeDtoToJSON(value['relationType']),
        'relation': FormatReferenceDtoToJSON(value['relation']),
    };
}

