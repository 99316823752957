/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EventEvaluationDto } from './EventEvaluationDto';
import {
    EventEvaluationDtoFromJSON,
    EventEvaluationDtoFromJSONTyped,
    EventEvaluationDtoToJSON,
} from './EventEvaluationDto';
import type { PublishedEventSettingsDto } from './PublishedEventSettingsDto';
import {
    PublishedEventSettingsDtoFromJSON,
    PublishedEventSettingsDtoFromJSONTyped,
    PublishedEventSettingsDtoToJSON,
} from './PublishedEventSettingsDto';

/**
 * Event evaluation for external users
 * @export
 * @interface PublicEventEvaluationDto
 */
export interface PublicEventEvaluationDto {
    /**
     * 
     * @type {EventEvaluationDto}
     * @memberof PublicEventEvaluationDto
     */
    eventEvaluation: EventEvaluationDto;
    /**
     * 
     * @type {PublishedEventSettingsDto}
     * @memberof PublicEventEvaluationDto
     */
    eventSettings: PublishedEventSettingsDto;
}

/**
 * Check if a given object implements the PublicEventEvaluationDto interface.
 */
export function instanceOfPublicEventEvaluationDto(value: object): boolean {
    if (!('eventEvaluation' in value)) return false;
    if (!('eventSettings' in value)) return false;
    return true;
}

export function PublicEventEvaluationDtoFromJSON(json: any): PublicEventEvaluationDto {
    return PublicEventEvaluationDtoFromJSONTyped(json, false);
}

export function PublicEventEvaluationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicEventEvaluationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'eventEvaluation': EventEvaluationDtoFromJSON(json['eventEvaluation']),
        'eventSettings': PublishedEventSettingsDtoFromJSON(json['eventSettings']),
    };
}

export function PublicEventEvaluationDtoToJSON(value?: PublicEventEvaluationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'eventEvaluation': EventEvaluationDtoToJSON(value['eventEvaluation']),
        'eventSettings': PublishedEventSettingsDtoToJSON(value['eventSettings']),
    };
}

