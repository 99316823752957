import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AwardWinnerRelationDto } from '../../../../api';
import HistoryDisplaySettings from '../../../../ui/history/history-display-settings';

export default function useAwardWinnerHistorySettings(): HistoryDisplaySettings<AwardWinnerRelationDto> {
  const { t } = useTranslation(['relations', 'award']);

  return useMemo<HistoryDisplaySettings<AwardWinnerRelationDto>>(() => {
    return {
      attributeLabels: {
        winner: t('award:connections.winner'),
        edition: t('award:connections.edition'),
      },
      valueFormatter: {
        winner: (value) => (value == null ? undefined : `${value.firstName} ${value.surname} (${value.personKey})`),
        edition: (value) => (value == null ? undefined : value.name),
      },
      diffEntireWord: {
        winner: true,
        edition: true,
      },
      name: () => t('award:connections.winner'),
      showEditDescription: true,
    };
  }, [t]);
}
