/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccessGroupReferenceDto } from './AccessGroupReferenceDto';
import {
    AccessGroupReferenceDtoFromJSON,
    AccessGroupReferenceDtoFromJSONTyped,
    AccessGroupReferenceDtoToJSON,
} from './AccessGroupReferenceDto';
import type { ProductReferenceDto } from './ProductReferenceDto';
import {
    ProductReferenceDtoFromJSON,
    ProductReferenceDtoFromJSONTyped,
    ProductReferenceDtoToJSON,
} from './ProductReferenceDto';

/**
 * 
 * @export
 * @interface ProductExtendedReferenceDto
 */
export interface ProductExtendedReferenceDto {
    /**
     * 
     * @type {ProductReferenceDto}
     * @memberof ProductExtendedReferenceDto
     */
    reference: ProductReferenceDto;
    /**
     * 
     * @type {Array<AccessGroupReferenceDto>}
     * @memberof ProductExtendedReferenceDto
     */
    accessGroups?: Array<AccessGroupReferenceDto>;
    /**
     * 
     * @type {string}
     * @memberof ProductExtendedReferenceDto
     */
    status?: ProductExtendedReferenceDtoStatusEnum;
}


/**
 * @export
 */
export const ProductExtendedReferenceDtoStatusEnum = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    ARCHIVED: 'ARCHIVED'
} as const;
export type ProductExtendedReferenceDtoStatusEnum = typeof ProductExtendedReferenceDtoStatusEnum[keyof typeof ProductExtendedReferenceDtoStatusEnum];


/**
 * Check if a given object implements the ProductExtendedReferenceDto interface.
 */
export function instanceOfProductExtendedReferenceDto(value: object): boolean {
    if (!('reference' in value)) return false;
    return true;
}

export function ProductExtendedReferenceDtoFromJSON(json: any): ProductExtendedReferenceDto {
    return ProductExtendedReferenceDtoFromJSONTyped(json, false);
}

export function ProductExtendedReferenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductExtendedReferenceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'reference': ProductReferenceDtoFromJSON(json['reference']),
        'accessGroups': json['accessGroups'] == null ? undefined : ((json['accessGroups'] as Array<any>).map(AccessGroupReferenceDtoFromJSON)),
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function ProductExtendedReferenceDtoToJSON(value?: ProductExtendedReferenceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'reference': ProductReferenceDtoToJSON(value['reference']),
        'accessGroups': value['accessGroups'] == null ? undefined : ((value['accessGroups'] as Array<any>).map(AccessGroupReferenceDtoToJSON)),
        'status': value['status'],
    };
}

