import {
  Radio as ChakraRadio,
  RadioGroup,
  RadioGroupProps,
  RadioProps as ChakraRadioProps,
  Text,
  useId,
} from '@chakra-ui/react';
import React from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';

interface RadioGroupControlProps<T extends FieldValues> extends Omit<RadioGroupProps, 'onChange' | 'value'> {
  name: FieldPath<T>;
  children: React.ReactNode;
}

export default function RadioGroupControl<T extends FieldValues>({ name, children }: RadioGroupControlProps<T>) {
  const { field } = useController({
    name,
  });

  return <RadioGroup {...field}>{children}</RadioGroup>;
}

export interface RadioProps extends ChakraRadioProps {
  children: React.ReactNode;
  label?: string;
}

export function Radio({ value, children, label }: RadioProps) {
  const id = useId();
  return (
    <ChakraRadio value={value} spacing={3} aria-labelledby={id}>
      {label && (
        <Text id={id} fontWeight="medium">
          {label}
        </Text>
      )}
      <Text>{children}</Text>
    </ChakraRadio>
  );
}
