import { HStack, Stack, Text } from '@chakra-ui/react';
import { without } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import {
  MailingSalutationModuleContentDtoSalutationOptionEnum,
  MailingTemplateDataDto,
  RecipientTypeDto,
} from '../../../../api';
import InputFormControl from '../../../../ui/form/input-form-control';
import ValueSelectFormControl from '../../../../ui/form/select-control/value-select-form-control';
import HelperPopover from '../../../../ui/helper-buttons/helper-popover';

interface SalutationMailingModuleControlProps {
  name: string;
  template: MailingTemplateDataDto;
}

export default function SalutationMailingModuleControl({ name, template }: SalutationMailingModuleControlProps) {
  const { t } = useTranslation('mailing');
  const options = [
    MailingSalutationModuleContentDtoSalutationOptionEnum.CASUAL,
    MailingSalutationModuleContentDtoSalutationOptionEnum.FORMAL,
  ];

  const showCustomSalutation = template.recipientTypes.includes(RecipientTypeDto.EMAIL);
  const showSalutationOption = without(template.recipientTypes, RecipientTypeDto.EMAIL).length > 0;

  return (
    <Stack>
      {showCustomSalutation && (
        <HStack>
          <InputFormControl
            name={`${name}.customSalutation`}
            label={t('salutation_module.label')}
            helperPopover={
              <HelperPopover>
                {t('salutation_module.helper_popover_custom_salutation', { salutationExample: 'Sehr geehrte*r' })}
              </HelperPopover>
            }
            helperText={t('salutation_module.helper_text_custom_salutation', { salutationExample: 'Sehr geehrte*r' })}
          />
          <InputFormControl
            name={`${name}.customSalutationEnglish`}
            label={t('salutation_module.label_english')}
            helperPopover={
              <HelperPopover>
                {t('salutation_module.helper_popover_custom_salutation', { salutationExample: 'Dear' })}
              </HelperPopover>
            }
            helperText={t('salutation_module.helper_text_custom_salutation', { salutationExample: 'Dear' })}
          />
        </HStack>
      )}
      {showSalutationOption && (
        <ValueSelectFormControl<MailingSalutationModuleContentDtoSalutationOptionEnum>
          name={`${name}.salutationOption`}
          label={t('salutation_module.label')}
          helperPopover={
            <HelperPopover>
              <Stack>
                <Text>{t('salutation_module.helper_popover.formal')}</Text>
                <Text>{t('salutation_module.helper_popover.casual')}</Text>
                <Text>{t('salutation_module.helper_popover.person')}</Text>
                <Text>{t('salutation_module.helper_popover.individual')}</Text>
              </Stack>
            </HelperPopover>
          }
          renderLabel={(type) => t(`salutation_module.options.${type}`)}
          options={options}
        />
      )}
    </Stack>
  );
}
