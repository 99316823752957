import React from 'react';
import { staffIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { useStaffPermissionClassGroupExtension } from './staff-permissions';
import { StaffSearchResult } from './staff-search-result';

export default function StaffPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, { type: 'STAFF', Component: StaffSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'STAFF',
    icon: staffIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.STAFF" />,
    necessaryPermission: 'STAFF:CAN_SEE_DETAILS',
  });

  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useStaffPermissionClassGroupExtension);
}
