/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The status for participation
 * @export
 */
export const StatusForParticipationDto = {
    REQUEST: 'REQUEST',
    REVIEW: 'REVIEW',
    RECOMMENDED: 'RECOMMENDED',
    WAITING_LIST: 'WAITING_LIST',
    REJECTED: 'REJECTED',
    TO_BE_INVITED: 'TO_BE_INVITED',
    INVITED: 'INVITED',
    ATTENDING: 'ATTENDING',
    DECLINED: 'DECLINED',
    EXPIRED: 'EXPIRED',
    PLACED: 'PLACED',
    REPLACED: 'REPLACED',
    RECEIVED_TICKET: 'RECEIVED_TICKET',
    TICKET_CANCELLED: 'TICKET_CANCELLED',
    IN_ATTENDANCE: 'IN_ATTENDANCE',
    NO_SHOW: 'NO_SHOW',
    CANCELLED: 'CANCELLED'
} as const;
export type StatusForParticipationDto = typeof StatusForParticipationDto[keyof typeof StatusForParticipationDto];


export function StatusForParticipationDtoFromJSON(json: any): StatusForParticipationDto {
    return StatusForParticipationDtoFromJSONTyped(json, false);
}

export function StatusForParticipationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusForParticipationDto {
    return json as StatusForParticipationDto;
}

export function StatusForParticipationDtoToJSON(value?: StatusForParticipationDto | null): any {
    return value as any;
}

