import { useFormControlContext, useMultiStyleConfig } from '@chakra-ui/react';
import { SystemStyleObject } from '@chakra-ui/styled-system';
import { chakraComponents, ContainerProps, GroupBase, InputProps, OptionProps, Props } from 'chakra-react-select';
import { mapValues } from 'lodash-es';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSelectVariant } from '../../theme/component/react-select';

export interface ReactSelectProps {
  variant?: ReactSelectVariant;
}

export default function useSelectProps<
  TOption = unknown,
  TIsMulti extends boolean = false,
  TGroup extends GroupBase<TOption> = GroupBase<TOption>,
>({
  chakraStyles,
  components,
  ...props
}: Props<TOption, TIsMulti, TGroup> & ReactSelectProps): Props<TOption, TIsMulti, TGroup> {
  const { t } = useTranslation('common');
  const styles = useMultiStyleConfig('ReactSelect', props);

  return {
    tabSelectsValue: false,
    noOptionsMessage: useNoOptionsMessage(),
    loadingMessage: ({ inputValue }) => t('select.loading', { inputValue }),
    placeholder: t('select.placeholder'),
    ...props,
    chakraStyles: {
      ...chakraStyles,
      // vb, Maps style anatomy parts to chakra styles (see corresponding theme for react select)
      ...mapValues(styles, (part: SystemStyleObject, key) => (provided: SystemStyleObject, state: any) => {
        return {
          ...provided,
          ...part,
          ...chakraStyles?.[key as keyof typeof chakraStyles]?.(provided, state),
        };
      }),
    },
    components: {
      ...components,
      SelectContainer,
      Input,
      Option,
    },
  };
}

function useNoOptionsMessage() {
  const { t } = useTranslation('common');

  return ({ inputValue }: { inputValue: string }) =>
    t('select.no_options', { context: inputValue !== '' ? 'with' : undefined, inputValue });
}

function SelectContainer<Option, IsMulti extends boolean, Group extends GroupBase<Option>>({
  children,
  isMulti,
  hasValue,
  innerProps,
  ...props
}: ContainerProps<Option, IsMulti, Group>) {
  return (
    <chakraComponents.SelectContainer
      {...props}
      isMulti={isMulti}
      hasValue={hasValue}
      innerProps={
        {
          ...innerProps,
          // Used for applying different styles based on select state.
          'data-is-multi': String(isMulti),
          'data-has-value': String(hasValue),
          onKeyDown(event) {
            // This fixes a bug where react-select is stopping propagation of key down events
            // and therefore preventing outer dialogs, modals, popovers, etc. to close on escape
            // key. So lets skip calling the original key down handler if no menu is open or another
            // key was pressed.
            if (props.selectProps.menuIsOpen || event.key !== 'Escape') {
              innerProps.onKeyDown?.(event);
            }
          },
        } as JSX.IntrinsicElements['div']
      }
    >
      {children}
    </chakraComponents.SelectContainer>
  );
}

// Special input component improving the chakra select input accessibility by adding a ARIA link
// to helper and feedback text.
function Input<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({ ...props }: InputProps<Option, IsMulti, Group>) {
  const field = useFormControlContext();

  const labelIds: string[] = props['aria-describedby'] ? [props['aria-describedby']] : [];

  if (field?.hasFeedbackText && field?.isInvalid) {
    labelIds.push(field.feedbackId);
  }

  if (field?.hasHelpText) {
    labelIds.push(field.helpTextId);
  }

  return <chakraComponents.Input {...props} aria-describedby={labelIds.join(' ')} aria-required={field?.isRequired} />;
}

type HTMLDivElementChrome = HTMLDivElement & {
  scrollIntoViewIfNeeded?(centerIfNeeded: boolean): void;
};

function Option<
  TOption = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<TOption> = GroupBase<TOption>,
>(props: OptionProps<TOption, IsMulti, Group>) {
  const innerRef = useCallback(
    (element: HTMLDivElementChrome | null) => {
      if (props.isFocused) {
        element?.scrollIntoViewIfNeeded?.(false);
      }
    },
    [props.isFocused],
  );

  return <chakraComponents.Option {...props} innerRef={innerRef} />;
}
