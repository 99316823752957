/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderConfirmationSendRequestDto
 */
export interface OrderConfirmationSendRequestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderConfirmationSendRequestDto
     */
    personRecipientIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderConfirmationSendRequestDto
     */
    companyRecipientIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderConfirmationSendRequestDto
     */
    emailRecipients: Array<string>;
}

/**
 * Check if a given object implements the OrderConfirmationSendRequestDto interface.
 */
export function instanceOfOrderConfirmationSendRequestDto(value: object): boolean {
    if (!('personRecipientIds' in value)) return false;
    if (!('companyRecipientIds' in value)) return false;
    if (!('emailRecipients' in value)) return false;
    return true;
}

export function OrderConfirmationSendRequestDtoFromJSON(json: any): OrderConfirmationSendRequestDto {
    return OrderConfirmationSendRequestDtoFromJSONTyped(json, false);
}

export function OrderConfirmationSendRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderConfirmationSendRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'personRecipientIds': json['personRecipientIds'],
        'companyRecipientIds': json['companyRecipientIds'],
        'emailRecipients': json['emailRecipients'],
    };
}

export function OrderConfirmationSendRequestDtoToJSON(value?: OrderConfirmationSendRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'personRecipientIds': value['personRecipientIds'],
        'companyRecipientIds': value['companyRecipientIds'],
        'emailRecipients': value['emailRecipients'],
    };
}

