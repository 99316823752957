/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DateRangeDto } from './DateRangeDto';
import {
    DateRangeDtoFromJSON,
    DateRangeDtoFromJSONTyped,
    DateRangeDtoToJSON,
} from './DateRangeDto';
import type { LocationReferenceDto } from './LocationReferenceDto';
import {
    LocationReferenceDtoFromJSON,
    LocationReferenceDtoFromJSONTyped,
    LocationReferenceDtoToJSON,
} from './LocationReferenceDto';
import type { LocationStatusDto } from './LocationStatusDto';
import {
    LocationStatusDtoFromJSON,
    LocationStatusDtoFromJSONTyped,
    LocationStatusDtoToJSON,
} from './LocationStatusDto';

/**
 * The connection between a location and a person
 * @export
 * @interface PersonLocationRelationDto
 */
export interface PersonLocationRelationDto {
    /**
     * 
     * @type {string}
     * @memberof PersonLocationRelationDto
     */
    relationType: PersonLocationRelationDtoRelationTypeEnum;
    /**
     * 
     * @type {LocationReferenceDto}
     * @memberof PersonLocationRelationDto
     */
    connectedLocation: LocationReferenceDto;
    /**
     * 
     * @type {LocationStatusDto}
     * @memberof PersonLocationRelationDto
     */
    locationStatus?: LocationStatusDto;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof PersonLocationRelationDto
     */
    dateRange: DateRangeDto;
    /**
     * 
     * @type {string}
     * @memberof PersonLocationRelationDto
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof PersonLocationRelationDto
     */
    timestamp?: Date;
}


/**
 * @export
 */
export const PersonLocationRelationDtoRelationTypeEnum = {
    HOUSEKEEPING: 'HOUSEKEEPING',
    IFB_CINEMA_MANAGEMENT: 'IFB_CINEMA_MANAGEMENT',
    LOUNGE_COORDINATION: 'LOUNGE_COORDINATION',
    CINEMA_SUPPORT: 'CINEMA_SUPPORT',
    CLEANING_SERVICE: 'CLEANING_SERVICE',
    TECHNICAL_MANAGEMENT: 'TECHNICAL_MANAGEMENT',
    CINEMA_MANAGEMENT: 'CINEMA_MANAGEMENT'
} as const;
export type PersonLocationRelationDtoRelationTypeEnum = typeof PersonLocationRelationDtoRelationTypeEnum[keyof typeof PersonLocationRelationDtoRelationTypeEnum];


/**
 * Check if a given object implements the PersonLocationRelationDto interface.
 */
export function instanceOfPersonLocationRelationDto(value: object): boolean {
    if (!('relationType' in value)) return false;
    if (!('connectedLocation' in value)) return false;
    if (!('dateRange' in value)) return false;
    return true;
}

export function PersonLocationRelationDtoFromJSON(json: any): PersonLocationRelationDto {
    return PersonLocationRelationDtoFromJSONTyped(json, false);
}

export function PersonLocationRelationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonLocationRelationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'relationType': json['relationType'],
        'connectedLocation': LocationReferenceDtoFromJSON(json['connectedLocation']),
        'locationStatus': json['locationStatus'] == null ? undefined : LocationStatusDtoFromJSON(json['locationStatus']),
        'dateRange': DateRangeDtoFromJSON(json['dateRange']),
        'comment': json['comment'] == null ? undefined : json['comment'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
    };
}

export function PersonLocationRelationDtoToJSON(value?: PersonLocationRelationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'relationType': value['relationType'],
        'connectedLocation': LocationReferenceDtoToJSON(value['connectedLocation']),
        'locationStatus': LocationStatusDtoToJSON(value['locationStatus']),
        'dateRange': DateRangeDtoToJSON(value['dateRange']),
        'comment': value['comment'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
    };
}

