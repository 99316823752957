import { Button, chakra, LightMode, Spacer } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface AppPrintLayoutProps {
  children?: React.ReactNode;
}

export default function AppPrintLayout({ children }: AppPrintLayoutProps) {
  return (
    <LightMode>
      <chakra.div bgColor="background">
        <AppPrintLayoutHeader />
        {children}
      </chakra.div>
    </LightMode>
  );
}

function AppPrintLayoutHeader() {
  const { t } = useTranslation('common');

  const handleClick = () => {
    window.print();
  };

  return (
    <chakra.header
      sx={{
        px: 4,
        py: 4,
        display: 'flex',
        bg: 'gray.50',
        boxShadow: 'md',
        position: 'sticky',
        borderBottomLeftRadius: 'md',
        top: 0,
        width: 'full',
        zIndex: 'sticky',
        '@media print': {
          '&': {
            display: 'none',
          },
        },
      }}
    >
      <Spacer />
      <Button variant="primary" onClick={handleClick}>
        {t('action.printPage')}
      </Button>
    </chakra.header>
  );
}
