import rs from 'jsrsasign';
import { isEmpty } from 'lodash-es';
import useSWRImmutable from 'swr/immutable';
import invariant from 'tiny-invariant';
import { SignedObjectDto } from '../../api';
import signingApi from '../../data-access/signing-api';

export default function isSignatureValid(signedObject: SignedObjectDto, publicKey: string): boolean {
  const signatureAsHex = rs.b64tohex(signedObject.signature);
  const sig = new rs.KJUR.crypto.Signature({ alg: 'SHA256withRSA' });
  sig.init(publicKey);
  sig.updateString(signedObject.payload);
  return sig.verify(signatureAsHex);
}

export function parseAsSignedObject(objectAsString: string): SignedObjectDto {
  const parsed = JSON.parse(objectAsString);
  invariant(typeof parsed === 'object', 'expected JSON Object but got ' + typeof parsed);
  invariant(
    typeof parsed.signature === 'string' && !isEmpty(parsed.signature),
    "expected property 'signature' to be non-empty string",
  );
  invariant(
    typeof parsed.payload === 'string' && !isEmpty(parsed.payload),
    "expected property 'payload' to be non-empty string",
  );
  return parsed;
}

export function usePublicKey() {
  return useSWRImmutable('signingPublicKey', () => {
    return signingApi.publicKey().then((result) => result.text());
  });
}
