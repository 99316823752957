import React from 'react';
import { teamIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import TeamSearchResult from './team-search-result';

export default function TeamPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, { type: 'TEAM', Component: TeamSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'TEAM',
    icon: teamIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.TEAM" />,
  });
}
