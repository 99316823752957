import { HStack, Stack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EventDto, EventDtoCateringEnum, EventDtoDressCodeEnum } from '../../../api';
import FormControl from '../../../ui/form/form-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import { cateringOptions, dressCodeOptions } from '../event-enum-constants';
import EventPartnerControl from './event-partner-control';

/**
 * Represents a form control for extras inside {@link EventForm}
 */
export default function ExtrasControl() {
  const { t } = useTranslation('event');

  return (
    <Stack spacing={6} alignItems="flex-start">
      <HStack spacing={6} width="100%" alignItems="flex-start">
        <FormControl<EventDto> label={t('catering.label')} name="catering" isRequired>
          <ValueSelectControl
            options={cateringOptions}
            renderLabel={(option) => t(`cateringOptions.${option}`)}
            name="catering"
            defaultValue={EventDtoCateringEnum.NO_CATERING}
            isRequired
          />
        </FormControl>
        <FormControl<EventDto>
          label={t('dress_code.label')}
          name="dressCode"
          helperText={t('dress_code.helper_text')}
          isRequired
        >
          <ValueSelectControl
            options={dressCodeOptions}
            renderLabel={(option) => t(`dressCodeOptions.${option}`)}
            name="dressCode"
            defaultValue={EventDtoDressCodeEnum.NOT_SPECIFIED}
            isRequired
          />
        </FormControl>
      </HStack>
      <EventPartnerControl />
    </Stack>
  );
}
