import {
  Center,
  chakra,
  forwardRef,
  HTMLChakraProps,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';

interface AttachmentModalButtonProps extends HTMLChakraProps<'button'> {
  url: string;
  alt: string;
  fileName?: string;
  onOpen?(): void;
  onClose?(): void;
}

const AttachmentModalButton = forwardRef<AttachmentModalButtonProps, 'button'>(
  ({ fileName, url, alt, onOpen: onOpenProp, onClose: onCloseProp, ...props }, ref) => {
    const { getDisclosureProps, getButtonProps, isOpen, onClose } = useDisclosure({
      onOpen: onOpenProp,
      onClose: onCloseProp,
    });

    return (
      <>
        <chakra.button type="button" {...getButtonProps(props)} ref={ref} />
        <Modal size="6xl" {...getDisclosureProps()} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            {fileName != null && <ModalHeader>{fileName}</ModalHeader>}
            <ModalCloseButton />
            <ModalBody>
              <Center>
                <Image src={url} objectFit="contain" alt={alt} />
              </Center>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  },
);
export default AttachmentModalButton;
