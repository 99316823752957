import { ColorProps } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faMinusCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import {
  AllocationColorDtoColorNameEnum,
  AllocationStatusDto,
  CategoryOfParticipationDto,
  ExportFormatDto,
  GuestListSettingsDtoGuestListTypeEnum,
  GuestTypeDto,
  StatusForParticipationDto,
} from '../../api';
import { bodyguardIcon, personIcon, placeholderIcon } from '../../ui/icons/business-objects';
import Translate from '../../util/translate/translate';
import { GuestType } from './add-guests-to-guest-list-dialog/guest-selection-control';

export const exportFormatOptions = [ExportFormatDto.CSV, ExportFormatDto.PDF, ExportFormatDto.DOCX];

export const typeOptions = [
  GuestListSettingsDtoGuestListTypeEnum.REGISTRATION,
  GuestListSettingsDtoGuestListTypeEnum.INVITATION,
];
export const categoryOfParticipationOptions = [
  CategoryOfParticipationDto.GUEST,
  CategoryOfParticipationDto.BODYGUARD,
  CategoryOfParticipationDto.PLUS_ONE,
  CategoryOfParticipationDto.GUEST_LIAISON,
  CategoryOfParticipationDto.SEATFILLER,
];
export const statusForParticipationOptions = [
  StatusForParticipationDto.TO_BE_INVITED,
  StatusForParticipationDto.INVITED,
  StatusForParticipationDto.CANCELLED,
  StatusForParticipationDto.REQUEST,
  StatusForParticipationDto.REVIEW,
  StatusForParticipationDto.RECOMMENDED,
  StatusForParticipationDto.WAITING_LIST,
  StatusForParticipationDto.REJECTED,
  StatusForParticipationDto.ATTENDING,
  StatusForParticipationDto.DECLINED,
  StatusForParticipationDto.EXPIRED,
  StatusForParticipationDto.PLACED,
  StatusForParticipationDto.REPLACED,
  StatusForParticipationDto.RECEIVED_TICKET,
  StatusForParticipationDto.TICKET_CANCELLED,
  StatusForParticipationDto.IN_ATTENDANCE,
  StatusForParticipationDto.NO_SHOW,
];
export const allocationColors = [
  AllocationColorDtoColorNameEnum.GRAY,
  AllocationColorDtoColorNameEnum.RED,
  AllocationColorDtoColorNameEnum.ORANGE,
  AllocationColorDtoColorNameEnum.YELLOW,
  AllocationColorDtoColorNameEnum.GREEN,
  AllocationColorDtoColorNameEnum.BLUE,
  AllocationColorDtoColorNameEnum.CYAN,
  AllocationColorDtoColorNameEnum.PURPLE,
  AllocationColorDtoColorNameEnum.PINK,
];

export const statusForParticipationDisplay: {
  [key in StatusForParticipationDto]?: {
    icon?: IconProp;
    iconColor: ColorProps['color'];
    bgColor: ColorProps['color'];
    hoverColor: ColorProps['color'];
  };
} = {
  [StatusForParticipationDto.EXPIRED]: {
    icon: faTimesCircle,
    iconColor: 'text.error',
    bgColor: 'background.error',
    hoverColor: 'background.error.hover',
  },
  [StatusForParticipationDto.DECLINED]: {
    icon: faTimesCircle,
    iconColor: 'text.error',
    bgColor: 'background.error',
    hoverColor: 'background.error.hover',
  },
  [StatusForParticipationDto.TICKET_CANCELLED]: {
    icon: faTimesCircle,
    iconColor: 'text.error',
    bgColor: 'background.error',
    hoverColor: 'background.error.hover',
  },
  [StatusForParticipationDto.WAITING_LIST]: {
    icon: faMinusCircle,
    iconColor: 'text.warning',
    bgColor: 'background.warning',
    hoverColor: 'background.warning.hover',
  },
  [StatusForParticipationDto.INVITED]: {
    icon: faMinusCircle,
    iconColor: 'text.warning',
    bgColor: 'background.warning',
    hoverColor: 'background.warning.hover',
  },
  [StatusForParticipationDto.TO_BE_INVITED]: {
    icon: faMinusCircle,
    iconColor: 'text.warning',
    bgColor: 'background.warning',
    hoverColor: 'background.warning.hover',
  },
  [StatusForParticipationDto.ATTENDING]: {
    icon: faCheckCircle,
    iconColor: 'text.success',
    bgColor: 'background.success',
    hoverColor: 'background.success.hover',
  },
  [StatusForParticipationDto.RECEIVED_TICKET]: {
    icon: faCheckCircle,
    iconColor: 'text.success',
    bgColor: 'background.success',
    hoverColor: 'background.success.hover',
  },
  [StatusForParticipationDto.IN_ATTENDANCE]: {
    icon: faCheckCircle,
    iconColor: 'text.success',
    bgColor: 'background.success',
    hoverColor: 'background.success.hover',
  },
  [StatusForParticipationDto.PLACED]: {
    icon: faCheckCircle,
    iconColor: 'text.success',
    bgColor: 'background.success',
    hoverColor: 'background.success.hover',
  },
  [StatusForParticipationDto.REPLACED]: {
    icon: faCheckCircle,
    iconColor: 'text.success',
    bgColor: 'background.success',
    hoverColor: 'background.success.hover',
  },
  [StatusForParticipationDto.CANCELLED]: {
    iconColor: 'text.muted',
    bgColor: 'background',
    hoverColor: 'background.hover',
  },
  [StatusForParticipationDto.REQUEST]: {
    iconColor: 'text.muted',
    bgColor: 'layer.01',
    hoverColor: 'layer.01.hover',
  },
  [StatusForParticipationDto.REVIEW]: {
    iconColor: 'text.muted',
    bgColor: 'layer.01',
    hoverColor: 'layer.01.hover',
  },
  [StatusForParticipationDto.RECOMMENDED]: {
    iconColor: 'text.muted',
    bgColor: 'layer.01',
    hoverColor: 'layer.01.hover',
  },
  [StatusForParticipationDto.REJECTED]: {
    iconColor: 'text.muted',
    bgColor: 'layer.01',
    hoverColor: 'layer.01.hover',
  },
  [StatusForParticipationDto.NO_SHOW]: {
    iconColor: 'text.muted',
    bgColor: 'layer.01',
    hoverColor: 'layer.01.hover',
  },
};

export const allocationStatusOptions = [AllocationStatusDto.CLOSED, AllocationStatusDto.SHARED];

export const guestTypeOptions = [GuestTypeDto.PERSON, GuestTypeDto.DUMMY, GuestTypeDto.BODYGUARD];

export const capacityStatusForParticipation: StatusForParticipationDto[] = [
  StatusForParticipationDto.TO_BE_INVITED,
  StatusForParticipationDto.INVITED,
  StatusForParticipationDto.ATTENDING,
  StatusForParticipationDto.RECEIVED_TICKET,
  StatusForParticipationDto.IN_ATTENDANCE,
  StatusForParticipationDto.NO_SHOW,
  StatusForParticipationDto.PLACED,
  StatusForParticipationDto.REPLACED,
];

export const capacityStatusForBodyguards: StatusForParticipationDto[] = [
  StatusForParticipationDto.PLACED,
  StatusForParticipationDto.REPLACED,
];

export const guestTypeIconOptions: Record<
  GuestType,
  {
    label: React.ReactNode;
    icon: IconProp;
  }
> = {
  [GuestType.PERSON]: {
    icon: personIcon,
    label: <Translate ns="guest_list" i18nKey="person" />,
  },
  [GuestType.DUMMY]: {
    icon: placeholderIcon,
    label: <Translate ns="guest_list" i18nKey="placeholder" />,
  },
  [GuestType.BODYGUARD]: {
    icon: bodyguardIcon,
    label: <Translate ns="guest_list" i18nKey="bodyguard" />,
  },
};
