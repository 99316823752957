/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AttendeePageDto,
  FailureDto,
  PageableDto,
  PublishedEventSettingsDto,
} from '../models/index';
import {
    AttendeePageDtoFromJSON,
    AttendeePageDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    PublishedEventSettingsDtoFromJSON,
    PublishedEventSettingsDtoToJSON,
} from '../models/index';

export interface FetchLiveTicketingAttendeeListRequest {
    publicationId: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface FetchPublicEventDataRequest {
    publicationId: string;
}

/**
 * 
 */
export class PublicLiveTicketingApi extends runtime.BaseAPI {

    /**
     * Get published entities
     */
    async fetchLiveTicketingAttendeeListRaw(requestParameters: FetchLiveTicketingAttendeeListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AttendeePageDto>> {
        if (requestParameters['publicationId'] == null) {
            throw new runtime.RequiredError(
                'publicationId',
                'Required parameter "publicationId" was null or undefined when calling fetchLiveTicketingAttendeeList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/public/live-ticketing/{publicationId}`.replace(`{${"publicationId"}}`, encodeURIComponent(String(requestParameters['publicationId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttendeePageDtoFromJSON(jsonValue));
    }

    /**
     * Get published entities
     */
    async fetchLiveTicketingAttendeeList(requestParameters: FetchLiveTicketingAttendeeListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AttendeePageDto> {
        const response = await this.fetchLiveTicketingAttendeeListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get public event data
     */
    async fetchPublicEventDataRaw(requestParameters: FetchPublicEventDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublishedEventSettingsDto>> {
        if (requestParameters['publicationId'] == null) {
            throw new runtime.RequiredError(
                'publicationId',
                'Required parameter "publicationId" was null or undefined when calling fetchPublicEventData().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/public/live-ticketing/{publicationId}/public-event-data`.replace(`{${"publicationId"}}`, encodeURIComponent(String(requestParameters['publicationId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublishedEventSettingsDtoFromJSON(jsonValue));
    }

    /**
     * Get public event data
     */
    async fetchPublicEventData(requestParameters: FetchPublicEventDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublishedEventSettingsDto> {
        const response = await this.fetchPublicEventDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
