import {
  Button,
  Flex,
  HStack,
  IconButton,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Spacer,
  Spinner,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { remove } from 'lodash-es';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CompanyDto, OccupationDto, PersonReferenceDto } from '../../../api';
import companyAdminApi from '../../../data-access/company-admin-api';
import useToast from '../../../ui/use-toast/use-toast';
import PersonReference from '../../person/person-reference/person-reference';
import { companyAdminFetcher } from '../company-admin-queries';
import CompanyAdminEditorForm from './company-admin-editor-form';

export interface AddCompanyAdminDto {
  person: PersonReferenceDto;
  occupation: OccupationDto;
  accountEmailAddress?: string;
}

interface CompanyAdminEditorDialogContentProps {
  initialFocusRef: React.RefObject<HTMLInputElement>;
  onClose: () => void;
  company: CompanyDto;
}

export default function CompanyAdminEditorDialogContent({
  initialFocusRef,
  onClose,
  company,
}: CompanyAdminEditorDialogContentProps) {
  const { t } = useTranslation(['common', 'person', 'company', 'account']);
  const [persons, setPersons] = React.useState<AddCompanyAdminDto[]>([]);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const showSuccessToast = useToast({
    status: 'success',
  });

  const handleAdd = async () => {
    setIsSubmitting(true);
    await companyAdminApi
      .createExternalCompanyAdminRelations({
        addExternalCompanyAdminsDto: {
          relationIds: persons.map((value) => ({
            personId: value.person.id!,
            occupationId: value.occupation.id!,
            accountEmail: value.accountEmailAddress,
          })),
          companyId: company.id!,
        },
      })
      .finally(() => {
        setIsSubmitting(false);
      });

    companyAdminFetcher.mutate();

    showSuccessToast({
      title: <Trans t={t} i18nKey="company:externalAdmin.toast.successTitle" values={{ count: persons.length }} />,
      description: (
        <Trans t={t} i18nKey="company:externalAdmin.toast.successDescription" values={{ count: persons.length }} />
      ),
    });

    onClose();
  };

  return (
    <>
      <ModalHeader>{t('person:accessGroupConnections.add')}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack spacing={4}>
          <CompanyAdminEditorForm
            initialFocusRef={initialFocusRef}
            company={company}
            addedPersons={persons}
            handleCompanyAdminAdded={(dto) => {
              setPersons([...persons, dto]);
            }}
          />
          {persons.length > 0 && (
            <Stack
              borderWidth="thin"
              borderColor="border.01"
              borderRadius="base"
              divider={<StackDivider borderColor="border.01" />}
              spacing={0}
              padding={0}
            >
              {persons.map((value, idx) => {
                return (
                  <HStack key={idx} p={1} pl={4}>
                    <PersonReference
                      personReference={{
                        id: value.person.id!,
                        firstName: value.person.firstName,
                        surname: value.person.surname,
                      }}
                    />
                    <Text>{value.occupation.jobTitle}</Text>
                    {value.accountEmailAddress != null && (
                      <Text color="text.muted">{`(${value.accountEmailAddress})`}</Text>
                    )}
                    <Spacer />
                    <IconButton
                      size="sm"
                      variant="ghost"
                      colorScheme="red"
                      aria-label={t('common:action.delete')}
                      onClick={() => {
                        const newPersons = [...persons];
                        remove(newPersons, (v) => v.occupation.id === value.occupation.id);
                        setPersons([...newPersons]);
                      }}
                      icon={<FontAwesomeIcon icon={faTrashAlt} />}
                    />
                  </HStack>
                );
              })}
            </Stack>
          )}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Flex width="100%">
          <Spacer />
          <Button mr={3} onClick={onClose}>
            {t('common:action.abort')}
          </Button>
          <Button
            variant="primary"
            isDisabled={persons.length === 0}
            onClick={() => handleAdd()}
            isLoading={isSubmitting}
            spinner={<Spinner color="currentColor" width="1em" height="1em" label={t('misc.loading')} />}
          >
            {t('common:action.add')}
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
}
