/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Analytics info from Brevo for a mailing
 * @export
 * @interface MailingAnalyticsDto
 */
export interface MailingAnalyticsDto {
    /**
     * 
     * @type {number}
     * @memberof MailingAnalyticsDto
     */
    sent?: number;
    /**
     * 
     * @type {number}
     * @memberof MailingAnalyticsDto
     */
    delivered?: number;
    /**
     * 
     * @type {number}
     * @memberof MailingAnalyticsDto
     */
    hardBounces?: number;
    /**
     * 
     * @type {number}
     * @memberof MailingAnalyticsDto
     */
    softBounces?: number;
    /**
     * 
     * @type {number}
     * @memberof MailingAnalyticsDto
     */
    clicks?: number;
    /**
     * 
     * @type {number}
     * @memberof MailingAnalyticsDto
     */
    uniqueClicks?: number;
    /**
     * 
     * @type {number}
     * @memberof MailingAnalyticsDto
     */
    opens?: number;
    /**
     * 
     * @type {number}
     * @memberof MailingAnalyticsDto
     */
    uniqueOpens?: number;
    /**
     * 
     * @type {number}
     * @memberof MailingAnalyticsDto
     */
    unsubscribed?: number;
}

/**
 * Check if a given object implements the MailingAnalyticsDto interface.
 */
export function instanceOfMailingAnalyticsDto(value: object): boolean {
    return true;
}

export function MailingAnalyticsDtoFromJSON(json: any): MailingAnalyticsDto {
    return MailingAnalyticsDtoFromJSONTyped(json, false);
}

export function MailingAnalyticsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingAnalyticsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'sent': json['sent'] == null ? undefined : json['sent'],
        'delivered': json['delivered'] == null ? undefined : json['delivered'],
        'hardBounces': json['hardBounces'] == null ? undefined : json['hardBounces'],
        'softBounces': json['softBounces'] == null ? undefined : json['softBounces'],
        'clicks': json['clicks'] == null ? undefined : json['clicks'],
        'uniqueClicks': json['uniqueClicks'] == null ? undefined : json['uniqueClicks'],
        'opens': json['opens'] == null ? undefined : json['opens'],
        'uniqueOpens': json['uniqueOpens'] == null ? undefined : json['uniqueOpens'],
        'unsubscribed': json['unsubscribed'] == null ? undefined : json['unsubscribed'],
    };
}

export function MailingAnalyticsDtoToJSON(value?: MailingAnalyticsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sent': value['sent'],
        'delivered': value['delivered'],
        'hardBounces': value['hardBounces'],
        'softBounces': value['softBounces'],
        'clicks': value['clicks'],
        'uniqueClicks': value['uniqueClicks'],
        'opens': value['opens'],
        'uniqueOpens': value['uniqueOpens'],
        'unsubscribed': value['unsubscribed'],
    };
}

