import { GetOptionValue } from 'chakra-react-select';
import invariant from 'tiny-invariant';
import ValueSelectOption from './value-select-option';

interface OptionStringValueProps<TOption> {
  getOptionValue: GetOptionValue<ValueSelectOption<TOption>>;
  getOptionLabel: GetOptionValue<ValueSelectOption<TOption>>;
}

export default function useOptionStringValue<TOption, TProps>(
  props: TProps,
  getStringValue?: (value: TOption) => string,
): TProps & OptionStringValueProps<TOption> {
  const getOptionStringValue = (option: ValueSelectOption<TOption>) => {
    let stringValue = option.label;

    if (getStringValue != null) {
      stringValue = getStringValue(option.value);
    }

    invariant(typeof stringValue === 'string', 'Option value must be a string');

    // Remove soft hyphen to fix search
    return stringValue.replace('­', '');
  };

  return {
    ...props,
    getOptionValue: getOptionStringValue,
    getOptionLabel: getOptionStringValue,
  } as TProps & OptionStringValueProps<TOption>;
}
