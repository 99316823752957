import { CompanyRoleDto } from '../../../api';

export const companyFilmRoleOptions = [
  CompanyRoleDto.AGENCY,
  CompanyRoleDto.COMMISSIONING_EDITOR,
  CompanyRoleDto.CO_PRODUCTION,
  CompanyRoleDto.GERMAN_DISTRIBUTOR,
  CompanyRoleDto.EFM_CO_PRODUCTION,
  CompanyRoleDto.EFM_PRODUCTION,
  CompanyRoleDto.EFM_WORLD_SALES,
  CompanyRoleDto.FILM_FUND,
  CompanyRoleDto.FILM_SCHOOL,
  CompanyRoleDto.INSTITUTE,
  CompanyRoleDto.PRODUCTION,
  CompanyRoleDto.DISTRIBUTOR,
  CompanyRoleDto.WORLD_SALES,
];
