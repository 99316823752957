import {
  Alert,
  AlertDescription,
  Box,
  Button,
  chakra,
  DarkMode,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { faChevronDown, faWifiSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import useStaffAccount from '../../feature/staff-account/use-account/use-staff-account';
import useOnline from '../../util/use-online-offline/use-online';
import Logo from './logo';

export default function PageMobileHeader() {
  const { t } = useTranslation('app');
  const [isOffline, setOffline] = useState<boolean>(false);
  const { email, displayName } = useStaffAccount();

  useOnline((isOnline) => {
    if (isOnline) {
      setOffline(false);
    } else {
      setOffline(true);
    }
  });

  return (
    <chakra.div>
      {isOffline && (
        <DarkMode>
          <Alert
            status="error"
            bg="background.error"
            color="text.error"
            fontSize="sm"
            justifyContent="center"
            borderRadius="none"
          >
            <Box as={FontAwesomeIcon} icon={faWifiSlash} mr={2} />
            <AlertDescription>{t('offline')}</AlertDescription>
          </Alert>
        </DarkMode>
      )}
      <Flex bg="gray.800" gap={1} justifyContent="space-between" padding={2}>
        <Flex flexWrap="wrap" gap={3} alignItems="center">
          <Logo ml={6} />
        </Flex>
        <Box flexShrink={0}>
          <Menu>
            <DarkMode>
              <MenuButton
                as={Button}
                color="white"
                rightIcon={<FontAwesomeIcon icon={faChevronDown} />}
                variant="ghost"
              >
                {t('account_navigation.logged_in_user', { user: displayName ?? email })}
              </MenuButton>
            </DarkMode>
            <MenuList>
              <MenuItem as={RouterLink} to="/logout">
                {t('account_navigation.logout')}
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Flex>
    </chakra.div>
  );
}
