/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HistoryEntryDto } from './HistoryEntryDto';
import {
    HistoryEntryDtoFromJSON,
    HistoryEntryDtoFromJSONTyped,
    HistoryEntryDtoToJSON,
} from './HistoryEntryDto';

/**
 * only filled for optimistic lock issues, will contain every change from the edited revision
 * @export
 * @interface FailureChangesDto
 */
export interface FailureChangesDto {
    /**
     * 
     * @type {string}
     * @memberof FailureChangesDto
     */
    rev: string;
    /**
     * 
     * @type {Array<HistoryEntryDto>}
     * @memberof FailureChangesDto
     */
    historyEntries: Array<HistoryEntryDto>;
}

/**
 * Check if a given object implements the FailureChangesDto interface.
 */
export function instanceOfFailureChangesDto(value: object): boolean {
    if (!('rev' in value)) return false;
    if (!('historyEntries' in value)) return false;
    return true;
}

export function FailureChangesDtoFromJSON(json: any): FailureChangesDto {
    return FailureChangesDtoFromJSONTyped(json, false);
}

export function FailureChangesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FailureChangesDto {
    if (json == null) {
        return json;
    }
    return {
        
        'rev': json['rev'],
        'historyEntries': ((json['historyEntries'] as Array<any>).map(HistoryEntryDtoFromJSON)),
    };
}

export function FailureChangesDtoToJSON(value?: FailureChangesDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'rev': value['rev'],
        'historyEntries': ((value['historyEntries'] as Array<any>).map(HistoryEntryDtoToJSON)),
    };
}

