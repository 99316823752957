import { Box, ChakraProps, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface DateRelativeProps extends ChakraProps {
  date: Date;
}

export default function DateRelative({ date, ...props }: DateRelativeProps) {
  const { t } = useTranslation('common');
  const renderRelative = React.useCallback(() => t('format.date_relative', { date }), [t, date]);
  const [relativeDate, setRelativeDate] = React.useState<string>(renderRelative);

  React.useEffect(() => {
    const interval = setInterval(
      // lh: Running the interval quite frequently is not a big deal here. The component is only
      // rerendered if the formatted date string changes.
      () => setRelativeDate(renderRelative),
      // lh: Update every 30 seconds. Time until there is a first change in the relative date
      // formatter.
      30 * 1000,
    );

    return () => {
      clearInterval(interval);
    };
  }, [renderRelative]);

  return (
    <Tooltip label={t('format.date_time_seconds', { dateTime: date })}>
      <Box as="span" {...props}>
        {relativeDate}
      </Box>
    </Tooltip>
  );
}
