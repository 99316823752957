/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AwardStatusDto } from './AwardStatusDto';
import {
    AwardStatusDtoFromJSON,
    AwardStatusDtoFromJSONTyped,
    AwardStatusDtoToJSON,
} from './AwardStatusDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * Represents one award of the Berlinale festival.
 * @export
 * @interface AwardDto
 */
export interface AwardDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof AwardDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof AwardDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof AwardDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AwardDto
     */
    titleEnglish?: string;
    /**
     * 
     * @type {string}
     * @memberof AwardDto
     */
    comment?: string;
    /**
     * 
     * @type {AwardStatusDto}
     * @memberof AwardDto
     */
    status: AwardStatusDto;
    /**
     * 
     * @type {string}
     * @memberof AwardDto
     */
    juryType?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof AwardDto
     */
    extensions: { [key: string]: any; };
}

/**
 * Check if a given object implements the AwardDto interface.
 */
export function instanceOfAwardDto(value: object): boolean {
    if (!('title' in value)) return false;
    if (!('status' in value)) return false;
    if (!('extensions' in value)) return false;
    return true;
}

export function AwardDtoFromJSON(json: any): AwardDto {
    return AwardDtoFromJSONTyped(json, false);
}

export function AwardDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AwardDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'title': json['title'],
        'titleEnglish': json['titleEnglish'] == null ? undefined : json['titleEnglish'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'status': AwardStatusDtoFromJSON(json['status']),
        'juryType': json['juryType'] == null ? undefined : json['juryType'],
        'extensions': json['extensions'],
    };
}

export function AwardDtoToJSON(value?: AwardDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'title': value['title'],
        'titleEnglish': value['titleEnglish'],
        'comment': value['comment'],
        'status': AwardStatusDtoToJSON(value['status']),
        'juryType': value['juryType'],
        'extensions': value['extensions'],
    };
}

