/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AutomaticCompanyConnectionDto } from './AutomaticCompanyConnectionDto';
import {
    AutomaticCompanyConnectionDtoFromJSON,
    AutomaticCompanyConnectionDtoFromJSONTyped,
    AutomaticCompanyConnectionDtoToJSON,
} from './AutomaticCompanyConnectionDto';
import type { AutomaticStandConnectionDto } from './AutomaticStandConnectionDto';
import {
    AutomaticStandConnectionDtoFromJSON,
    AutomaticStandConnectionDtoFromJSONTyped,
    AutomaticStandConnectionDtoToJSON,
} from './AutomaticStandConnectionDto';

/**
 * 
 * @export
 * @interface AutomaticConnectionsDto
 */
export interface AutomaticConnectionsDto {
    /**
     * 
     * @type {string}
     * @memberof AutomaticConnectionsDto
     */
    connectionType: AutomaticConnectionsDtoConnectionTypeEnum;
    /**
     * 
     * @type {AutomaticCompanyConnectionDto}
     * @memberof AutomaticConnectionsDto
     */
    companyConnection?: AutomaticCompanyConnectionDto;
    /**
     * 
     * @type {AutomaticStandConnectionDto}
     * @memberof AutomaticConnectionsDto
     */
    standConnection?: AutomaticStandConnectionDto;
}


/**
 * @export
 */
export const AutomaticConnectionsDtoConnectionTypeEnum = {
    COMPANY: 'COMPANY',
    STAND: 'STAND',
    NONE: 'NONE'
} as const;
export type AutomaticConnectionsDtoConnectionTypeEnum = typeof AutomaticConnectionsDtoConnectionTypeEnum[keyof typeof AutomaticConnectionsDtoConnectionTypeEnum];


/**
 * Check if a given object implements the AutomaticConnectionsDto interface.
 */
export function instanceOfAutomaticConnectionsDto(value: object): boolean {
    if (!('connectionType' in value)) return false;
    return true;
}

export function AutomaticConnectionsDtoFromJSON(json: any): AutomaticConnectionsDto {
    return AutomaticConnectionsDtoFromJSONTyped(json, false);
}

export function AutomaticConnectionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutomaticConnectionsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'connectionType': json['connectionType'],
        'companyConnection': json['companyConnection'] == null ? undefined : AutomaticCompanyConnectionDtoFromJSON(json['companyConnection']),
        'standConnection': json['standConnection'] == null ? undefined : AutomaticStandConnectionDtoFromJSON(json['standConnection']),
    };
}

export function AutomaticConnectionsDtoToJSON(value?: AutomaticConnectionsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'connectionType': value['connectionType'],
        'companyConnection': AutomaticCompanyConnectionDtoToJSON(value['companyConnection']),
        'standConnection': AutomaticStandConnectionDtoToJSON(value['standConnection']),
    };
}

