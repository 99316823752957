import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const teamMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.team" />,
    path: '/teams',
    hasAccess: (permissions) => permissions.includes('TEAM:STANDARD-READ'),
  },
];

export default teamMenuItems;
