import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const editionMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.edition" />,
    path: '/editions',
    hasAccess: (permissions) => permissions.includes('EDITION:FULL-READ'),
  },
];

export default editionMenuItems;
