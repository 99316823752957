/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryReferenceDto } from './CountryReferenceDto';
import {
    CountryReferenceDtoFromJSON,
    CountryReferenceDtoFromJSONTyped,
    CountryReferenceDtoToJSON,
} from './CountryReferenceDto';

/**
 * 
 * @export
 * @interface LocationPostalAddressDto
 */
export interface LocationPostalAddressDto {
    /**
     * The address
     * @type {string}
     * @memberof LocationPostalAddressDto
     */
    address?: string;
    /**
     * The address supplement
     * @type {string}
     * @memberof LocationPostalAddressDto
     */
    addressSupplement?: string;
    /**
     * The post code of the address
     * @type {string}
     * @memberof LocationPostalAddressDto
     */
    postCode?: string;
    /**
     * The city of the address
     * @type {string}
     * @memberof LocationPostalAddressDto
     */
    city: string;
    /**
     * The state of the address
     * @type {string}
     * @memberof LocationPostalAddressDto
     */
    state?: string;
    /**
     * 
     * @type {CountryReferenceDto}
     * @memberof LocationPostalAddressDto
     */
    country: CountryReferenceDto;
    /**
     * 
     * @type {Date}
     * @memberof LocationPostalAddressDto
     */
    timestamp?: Date;
    /**
     * The label for the postal address
     * @type {string}
     * @memberof LocationPostalAddressDto
     */
    label: LocationPostalAddressDtoLabelEnum;
    /**
     * The coordinates of the location
     * @type {string}
     * @memberof LocationPostalAddressDto
     */
    coordinates?: string;
}


/**
 * @export
 */
export const LocationPostalAddressDtoLabelEnum = {
    OFFICIAL: 'OFFICIAL',
    STAGE_DOOR: 'STAGE_DOOR',
    OTHER: 'OTHER'
} as const;
export type LocationPostalAddressDtoLabelEnum = typeof LocationPostalAddressDtoLabelEnum[keyof typeof LocationPostalAddressDtoLabelEnum];


/**
 * Check if a given object implements the LocationPostalAddressDto interface.
 */
export function instanceOfLocationPostalAddressDto(value: object): boolean {
    if (!('city' in value)) return false;
    if (!('country' in value)) return false;
    if (!('label' in value)) return false;
    return true;
}

export function LocationPostalAddressDtoFromJSON(json: any): LocationPostalAddressDto {
    return LocationPostalAddressDtoFromJSONTyped(json, false);
}

export function LocationPostalAddressDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationPostalAddressDto {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'] == null ? undefined : json['address'],
        'addressSupplement': json['addressSupplement'] == null ? undefined : json['addressSupplement'],
        'postCode': json['postCode'] == null ? undefined : json['postCode'],
        'city': json['city'],
        'state': json['state'] == null ? undefined : json['state'],
        'country': CountryReferenceDtoFromJSON(json['country']),
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
        'label': json['label'],
        'coordinates': json['coordinates'] == null ? undefined : json['coordinates'],
    };
}

export function LocationPostalAddressDtoToJSON(value?: LocationPostalAddressDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': value['address'],
        'addressSupplement': value['addressSupplement'],
        'postCode': value['postCode'],
        'city': value['city'],
        'state': value['state'],
        'country': CountryReferenceDtoToJSON(value['country']),
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
        'label': value['label'],
        'coordinates': value['coordinates'],
    };
}

