import React from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchVisaTemplate } from '../visa-template-queries';

export default function VisaTemplateBreadcrumb() {
  const { visaTemplateId } = useParams<{ visaTemplateId: string }>();
  invariant(visaTemplateId, 'Empty visaTemplateId');
  const visaTemplate = useFetcher(fetchVisaTemplate, { id: visaTemplateId });

  return <>{visaTemplate.title}</>;
}
