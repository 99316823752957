/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FormatReferenceDto } from './FormatReferenceDto';
import {
    FormatReferenceDtoFromJSON,
    FormatReferenceDtoFromJSONTyped,
    FormatReferenceDtoToJSON,
} from './FormatReferenceDto';

/**
 * Represents a duplicate relation between two formats.
 * @export
 * @interface FormatDuplicateDto
 */
export interface FormatDuplicateDto {
    /**
     * 
     * @type {string}
     * @memberof FormatDuplicateDto
     */
    key: string;
    /**
     * 
     * @type {FormatReferenceDto}
     * @memberof FormatDuplicateDto
     */
    formatA: FormatReferenceDto;
    /**
     * 
     * @type {FormatReferenceDto}
     * @memberof FormatDuplicateDto
     */
    formatB: FormatReferenceDto;
    /**
     * 
     * @type {boolean}
     * @memberof FormatDuplicateDto
     */
    notDuplicate: boolean;
}

/**
 * Check if a given object implements the FormatDuplicateDto interface.
 */
export function instanceOfFormatDuplicateDto(value: object): boolean {
    if (!('key' in value)) return false;
    if (!('formatA' in value)) return false;
    if (!('formatB' in value)) return false;
    if (!('notDuplicate' in value)) return false;
    return true;
}

export function FormatDuplicateDtoFromJSON(json: any): FormatDuplicateDto {
    return FormatDuplicateDtoFromJSONTyped(json, false);
}

export function FormatDuplicateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormatDuplicateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'formatA': FormatReferenceDtoFromJSON(json['formatA']),
        'formatB': FormatReferenceDtoFromJSON(json['formatB']),
        'notDuplicate': json['notDuplicate'],
    };
}

export function FormatDuplicateDtoToJSON(value?: FormatDuplicateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'key': value['key'],
        'formatA': FormatReferenceDtoToJSON(value['formatA']),
        'formatB': FormatReferenceDtoToJSON(value['formatB']),
        'notDuplicate': value['notDuplicate'],
    };
}

