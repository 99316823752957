/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AllocationReferenceDto } from './AllocationReferenceDto';
import {
    AllocationReferenceDtoFromJSON,
    AllocationReferenceDtoFromJSONTyped,
    AllocationReferenceDtoToJSON,
} from './AllocationReferenceDto';
import type { CategoryOfParticipationDto } from './CategoryOfParticipationDto';
import {
    CategoryOfParticipationDtoFromJSON,
    CategoryOfParticipationDtoFromJSONTyped,
    CategoryOfParticipationDtoToJSON,
} from './CategoryOfParticipationDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { StatusForParticipationDto } from './StatusForParticipationDto';
import {
    StatusForParticipationDtoFromJSON,
    StatusForParticipationDtoFromJSONTyped,
    StatusForParticipationDtoToJSON,
} from './StatusForParticipationDto';

/**
 * Dto to replace merged duplicates on guest list with new person
 * @export
 * @interface ReplaceMergedDuplicatesOnGuestListWithNewPersonDto
 */
export interface ReplaceMergedDuplicatesOnGuestListWithNewPersonDto {
    /**
     * The id of the old person on guest list
     * @type {string}
     * @memberof ReplaceMergedDuplicatesOnGuestListWithNewPersonDto
     */
    oldPersonOnGuestListA?: string;
    /**
     * The id of the old person on guest list
     * @type {string}
     * @memberof ReplaceMergedDuplicatesOnGuestListWithNewPersonDto
     */
    oldPersonOnGuestListB?: string;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof ReplaceMergedDuplicatesOnGuestListWithNewPersonDto
     */
    newPerson?: PersonReferenceDto;
    /**
     * 
     * @type {CategoryOfParticipationDto}
     * @memberof ReplaceMergedDuplicatesOnGuestListWithNewPersonDto
     */
    category: CategoryOfParticipationDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReplaceMergedDuplicatesOnGuestListWithNewPersonDto
     */
    labels?: Array<string>;
    /**
     * 
     * @type {AllocationReferenceDto}
     * @memberof ReplaceMergedDuplicatesOnGuestListWithNewPersonDto
     */
    allocation: AllocationReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof ReplaceMergedDuplicatesOnGuestListWithNewPersonDto
     */
    comment?: string;
    /**
     * 
     * @type {StatusForParticipationDto}
     * @memberof ReplaceMergedDuplicatesOnGuestListWithNewPersonDto
     */
    status: StatusForParticipationDto;
    /**
     * The id of the coupling group if person is coupled
     * @type {string}
     * @memberof ReplaceMergedDuplicatesOnGuestListWithNewPersonDto
     */
    couplingGroupId?: string;
}

/**
 * Check if a given object implements the ReplaceMergedDuplicatesOnGuestListWithNewPersonDto interface.
 */
export function instanceOfReplaceMergedDuplicatesOnGuestListWithNewPersonDto(value: object): boolean {
    if (!('category' in value)) return false;
    if (!('allocation' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function ReplaceMergedDuplicatesOnGuestListWithNewPersonDtoFromJSON(json: any): ReplaceMergedDuplicatesOnGuestListWithNewPersonDto {
    return ReplaceMergedDuplicatesOnGuestListWithNewPersonDtoFromJSONTyped(json, false);
}

export function ReplaceMergedDuplicatesOnGuestListWithNewPersonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReplaceMergedDuplicatesOnGuestListWithNewPersonDto {
    if (json == null) {
        return json;
    }
    return {
        
        'oldPersonOnGuestListA': json['oldPersonOnGuestListA'] == null ? undefined : json['oldPersonOnGuestListA'],
        'oldPersonOnGuestListB': json['oldPersonOnGuestListB'] == null ? undefined : json['oldPersonOnGuestListB'],
        'newPerson': json['newPerson'] == null ? undefined : PersonReferenceDtoFromJSON(json['newPerson']),
        'category': CategoryOfParticipationDtoFromJSON(json['category']),
        'labels': json['labels'] == null ? undefined : json['labels'],
        'allocation': AllocationReferenceDtoFromJSON(json['allocation']),
        'comment': json['comment'] == null ? undefined : json['comment'],
        'status': StatusForParticipationDtoFromJSON(json['status']),
        'couplingGroupId': json['couplingGroupId'] == null ? undefined : json['couplingGroupId'],
    };
}

export function ReplaceMergedDuplicatesOnGuestListWithNewPersonDtoToJSON(value?: ReplaceMergedDuplicatesOnGuestListWithNewPersonDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'oldPersonOnGuestListA': value['oldPersonOnGuestListA'],
        'oldPersonOnGuestListB': value['oldPersonOnGuestListB'],
        'newPerson': PersonReferenceDtoToJSON(value['newPerson']),
        'category': CategoryOfParticipationDtoToJSON(value['category']),
        'labels': value['labels'],
        'allocation': AllocationReferenceDtoToJSON(value['allocation']),
        'comment': value['comment'],
        'status': StatusForParticipationDtoToJSON(value['status']),
        'couplingGroupId': value['couplingGroupId'],
    };
}

