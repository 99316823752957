/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MailingDesignFooterTextBlock6Dto
 */
export interface MailingDesignFooterTextBlock6Dto {
    /**
     * A URL restricted through uri format of open api
     * @type {string}
     * @memberof MailingDesignFooterTextBlock6Dto
     */
    privacyPolicyLink: string;
    /**
     * A URL restricted through uri format of open api
     * @type {string}
     * @memberof MailingDesignFooterTextBlock6Dto
     */
    privacyPolicyLinkEnglish: string;
    /**
     * A URL restricted through uri format of open api
     * @type {string}
     * @memberof MailingDesignFooterTextBlock6Dto
     */
    imprintLink: string;
    /**
     * A URL restricted through uri format of open api
     * @type {string}
     * @memberof MailingDesignFooterTextBlock6Dto
     */
    imprintLinkEnglish: string;
}

/**
 * Check if a given object implements the MailingDesignFooterTextBlock6Dto interface.
 */
export function instanceOfMailingDesignFooterTextBlock6Dto(value: object): boolean {
    if (!('privacyPolicyLink' in value)) return false;
    if (!('privacyPolicyLinkEnglish' in value)) return false;
    if (!('imprintLink' in value)) return false;
    if (!('imprintLinkEnglish' in value)) return false;
    return true;
}

export function MailingDesignFooterTextBlock6DtoFromJSON(json: any): MailingDesignFooterTextBlock6Dto {
    return MailingDesignFooterTextBlock6DtoFromJSONTyped(json, false);
}

export function MailingDesignFooterTextBlock6DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingDesignFooterTextBlock6Dto {
    if (json == null) {
        return json;
    }
    return {
        
        'privacyPolicyLink': json['privacyPolicyLink'],
        'privacyPolicyLinkEnglish': json['privacyPolicyLinkEnglish'],
        'imprintLink': json['imprintLink'],
        'imprintLinkEnglish': json['imprintLinkEnglish'],
    };
}

export function MailingDesignFooterTextBlock6DtoToJSON(value?: MailingDesignFooterTextBlock6Dto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'privacyPolicyLink': value['privacyPolicyLink'],
        'privacyPolicyLinkEnglish': value['privacyPolicyLinkEnglish'],
        'imprintLink': value['imprintLink'],
        'imprintLinkEnglish': value['imprintLinkEnglish'],
    };
}

