import { Box, Flex, Stack, Text, tokenToCSSVar, useTheme } from '@chakra-ui/react';
import { faLink, faUserAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import {
  PersonPersonConnectionDto,
  PersonPersonConnectionDtoConnectionTypeEnum,
  PersonReferenceDto,
} from '../../../../api';
import { DEFAULT_PERSON_KEY } from '../../../../data-access/person-api';
import { useElementForm } from '../../../../ui/form/element-control';
import ValueSelectControl from '../../../../ui/form/select-control/value-select-control';
import connectionArrow from '../../../common/connection-arrow-light.svg';
import PersonSelectControl from '../../../common/form/person-select-control/person-select-control';

const connectionTypeOptions = [
  PersonPersonConnectionDtoConnectionTypeEnum.AGENT_FOR,
  PersonPersonConnectionDtoConnectionTypeEnum.HAS_AGENT,
  PersonPersonConnectionDtoConnectionTypeEnum.ASSISTANT_TO,
  PersonPersonConnectionDtoConnectionTypeEnum.HAS_ASSISTANT,
  PersonPersonConnectionDtoConnectionTypeEnum.GUEST_MANAGER_FOR,
  PersonPersonConnectionDtoConnectionTypeEnum.HAS_GUEST_MANAGER,
  PersonPersonConnectionDtoConnectionTypeEnum.GUEST_LIAISON_FOR,
  PersonPersonConnectionDtoConnectionTypeEnum.HAS_GUEST_LIAISON,
  PersonPersonConnectionDtoConnectionTypeEnum.HAS_PERSONAL_RELATIONSHIP,
  PersonPersonConnectionDtoConnectionTypeEnum.AIDE_TO,
  PersonPersonConnectionDtoConnectionTypeEnum.HAS_AIDE,
  PersonPersonConnectionDtoConnectionTypeEnum.PUBLICIST_FOR,
  PersonPersonConnectionDtoConnectionTypeEnum.HAS_PUBLICIST,
];

export interface ConnectionControlProps {
  connectionEditable?: boolean;
  toReferenceEditable?: boolean;
  fromReference: PersonReferenceDto;
}

function ConnectionControl(
  { connectionEditable = true, toReferenceEditable = true, fromReference }: ConnectionControlProps,
  ref: React.ForwardedRef<HTMLSelectElement>,
) {
  const { t } = useTranslation('person');
  const { element } = useElementForm<PersonPersonConnectionDto>();
  const spacing = connectionEditable ? 2 : 3;
  const { register } = useFormContext<PersonPersonConnectionDto>();
  const connectedPerson = element?.connectedPerson;
  const connectionType = element?.connectionType;

  invariant(connectionEditable || connectionType != null, 'Missing connection type when readonly');

  // jaj, cv, lh: inside react-hook-form not registered fields are omitted from the submitted data
  register('connectedPerson');

  return (
    <Stack
      spacing={spacing}
      backgroundColor="background.highlight"
      pr={2}
      py={connectionEditable ? 2 : 3}
      pl={3}
      borderRadius="base"
    >
      <Flex position="relative" align="center">
        <ConnectionLine position="bottom" spacing={spacing} />
        <Box as={FontAwesomeIcon} icon={faUserAlt} fixedWidth flexShrink={0} />
        <Box ml={3} aria-label={t('connections.from_person')}>
          <Text as="span" fontWeight="medium" wordBreak="break-all">
            {fromReference.surname === '' ? 'Neue Person' : fromReference.firstName + ' ' + fromReference.surname}
          </Text>
          {fromReference.personKey !== DEFAULT_PERSON_KEY && <Text as="span">{` ${fromReference.personKey}`}</Text>}
        </Box>
      </Flex>
      <Flex align="center" position="relative">
        <ConnectionLine position="top" spacing={spacing} />
        <Box as={FontAwesomeIcon} icon={faLink} fixedWidth flexShrink={0} />
        <ConnectionLine position="bottom" spacing={spacing} />
        <Box width="full" ml={3}>
          {connectionEditable ? (
            <ValueSelectControl<PersonPersonConnectionDtoConnectionTypeEnum>
              aria-label={t('connections.connectionType')}
              options={connectionTypeOptions}
              renderLabel={(value) => t(`connections.connectionTypeLabels.${value}`)}
              name="connectionType"
              ref={ref}
              isRequired
            />
          ) : (
            <Box aria-label={t('connections.connectionType')}>
              <Text as="span">{t(`connections.connectionTypeLabels.${connectionType!}`)}</Text>
            </Box>
          )}
        </Box>
      </Flex>
      <Flex align="center" position="relative">
        <ConnectionLine position="top" showArrow spacing={spacing} />
        <Box as={FontAwesomeIcon} icon={faUserAlt} fixedWidth flexShrink={0} />
        <Box width="full" ml={3}>
          {toReferenceEditable ? (
            <PersonSelectControl
              name="connectedPerson"
              aria-label={t('connections.connected_person')}
              isRequired
              enableQuickPerson
              excludeFromResults={fromReference}
            />
          ) : (
            <Box aria-label={t('connections.connected_person')}>
              <Text as="span" fontWeight="medium">
                {connectedPerson?.firstName + ' ' + connectedPerson?.surname}
              </Text>{' '}
              <Text as="span">{connectedPerson?.personKey}</Text>
            </Box>
          )}
        </Box>
      </Flex>
    </Stack>
  );
}

export default React.forwardRef(ConnectionControl);

interface ConnectionLineProps {
  position: 'bottom' | 'top';
  showArrow?: boolean;
  spacing?: number;
}

function ConnectionLine({ position, showArrow, spacing = 2 }: ConnectionLineProps) {
  const theme = useTheme();
  const spacingCssVar = tokenToCSSVar('space', 3)(theme);

  return (
    <Box
      w={5}
      position="absolute"
      left={0}
      bgImage={connectionArrow}
      bgRepeat="no-repeat"
      top={position === 'bottom' ? `calc(50% + ${spacingCssVar})` : spacing / -2}
      bottom={position === 'top' ? `calc(50% + ${spacingCssVar})` : spacing / -2}
      // vb, lh: Arrow is at the bottom of the background image …
      bgPosition={`center ${showArrow ? 'bottom' : 'top'}`}
    />
  );
}
