import { FieldPath, FieldValues, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormControl from '../form-control';
import FormControlContextConsumer from '../form-control-context-consumer';
import RichTextOptionsViewer, { RichTextOption } from './rich-text-options-viewer';

interface RichTextOptionsControlProps<TFieldValues extends FieldValues> {
  name: FieldPath<TFieldValues>;
  options: RichTextOption[];
}

const BLOCK_OPTIONS: RichTextOption[] = [
  'HEADLINE',
  'SMALL',
  'SUB_HEADLINE',
  'PARAGRAPH',
  'UNORDERED_LIST',
  'ORDERED_LIST',
];

const BLOCK_ALLOWING_TEXT_MODIFIER_OPTIONS: RichTextOption[] = ['PARAGRAPH', 'SMALL', 'UNORDERED_LIST', 'ORDERED_LIST'];

const TEXT_MODIFIER_OPTIONS: RichTextOption[] = ['ITALIC', 'BOLD', 'HYPERLINK', 'HYPERLINK_BUTTON', 'CITATION'];

export default function RichTextOptionsControl<TFieldValues extends FieldValues>({
  name,
  options,
}: RichTextOptionsControlProps<TFieldValues>) {
  const { t } = useTranslation('common');
  const { field } = useController<TFieldValues>({
    name,
    rules: {
      validate(value: RichTextOption[]) {
        // Validate that at least one block option is chosen
        if (!value.some((option) => BLOCK_OPTIONS.includes(option))) {
          return t('richTextOptions.requirementBlockOption', {
            options: options
              .filter((option) => BLOCK_OPTIONS.includes(option))
              .map((option) => t(`richTextOptions.options.${option}`)),
          });
        }

        // Validate that text modifiers are not used without the paragraph option
        return (
          value.some((option) => BLOCK_ALLOWING_TEXT_MODIFIER_OPTIONS.includes(option)) ||
          !value.some((option) => TEXT_MODIFIER_OPTIONS.includes(option)) ||
          t('richTextOptions.requirementParagraph', {
            options: options
              .filter((option) => TEXT_MODIFIER_OPTIONS.includes(option))
              .map((option) => t(`richTextOptions.options.${option}`)),
          })
        );
      },
    },
  });
  const value = field.value ?? [];

  return (
    <FormControl name={name} label={t('richTextOptions.label')}>
      <FormControlContextConsumer>
        {({ labelId, feedbackId }) => (
          <RichTextOptionsViewer
            options={options}
            activeOptions={value}
            onToggleOption={(type, isActive) => {
              field.onChange(isActive ? [...value, type] : value.filter((option) => option !== type));
            }}
            onBlurOption={field.onBlur}
            role="group"
            aria-labelledby={labelId}
            aria-describedby={feedbackId}
          />
        )}
      </FormControlContextConsumer>
    </FormControl>
  );
}
