/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderConfirmationDto } from './OrderConfirmationDto';
import {
    OrderConfirmationDtoFromJSON,
    OrderConfirmationDtoFromJSONTyped,
    OrderConfirmationDtoToJSON,
} from './OrderConfirmationDto';

/**
 * 
 * @export
 * @interface OrderConfirmationSearchResultDto
 */
export interface OrderConfirmationSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof OrderConfirmationSearchResultDto
     */
    resultType: OrderConfirmationSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderConfirmationSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {OrderConfirmationDto}
     * @memberof OrderConfirmationSearchResultDto
     */
    result: OrderConfirmationDto;
}


/**
 * @export
 */
export const OrderConfirmationSearchResultDtoResultTypeEnum = {
    ORDERCONFIRMATION: 'ORDERCONFIRMATION'
} as const;
export type OrderConfirmationSearchResultDtoResultTypeEnum = typeof OrderConfirmationSearchResultDtoResultTypeEnum[keyof typeof OrderConfirmationSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the OrderConfirmationSearchResultDto interface.
 */
export function instanceOfOrderConfirmationSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function OrderConfirmationSearchResultDtoFromJSON(json: any): OrderConfirmationSearchResultDto {
    return OrderConfirmationSearchResultDtoFromJSONTyped(json, false);
}

export function OrderConfirmationSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderConfirmationSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': OrderConfirmationDtoFromJSON(json['result']),
    };
}

export function OrderConfirmationSearchResultDtoToJSON(value?: OrderConfirmationSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': OrderConfirmationDtoToJSON(value['result']),
    };
}

