/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AutomaticConnectionsDto } from './AutomaticConnectionsDto';
import {
    AutomaticConnectionsDtoFromJSON,
    AutomaticConnectionsDtoFromJSONTyped,
    AutomaticConnectionsDtoToJSON,
} from './AutomaticConnectionsDto';
import type { ClosedDateTimeRangeDto } from './ClosedDateTimeRangeDto';
import {
    ClosedDateTimeRangeDtoFromJSON,
    ClosedDateTimeRangeDtoFromJSONTyped,
    ClosedDateTimeRangeDtoToJSON,
} from './ClosedDateTimeRangeDto';
import type { CodeSentToPeopleInnerDto } from './CodeSentToPeopleInnerDto';
import {
    CodeSentToPeopleInnerDtoFromJSON,
    CodeSentToPeopleInnerDtoFromJSONTyped,
    CodeSentToPeopleInnerDtoToJSON,
} from './CodeSentToPeopleInnerDto';
import type { CodeStatusDto } from './CodeStatusDto';
import {
    CodeStatusDtoFromJSON,
    CodeStatusDtoFromJSONTyped,
    CodeStatusDtoToJSON,
} from './CodeStatusDto';
import type { DiscountDto } from './DiscountDto';
import {
    DiscountDtoFromJSON,
    DiscountDtoFromJSONTyped,
    DiscountDtoToJSON,
} from './DiscountDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { ProductReferenceDto } from './ProductReferenceDto';
import {
    ProductReferenceDtoFromJSON,
    ProductReferenceDtoFromJSONTyped,
    ProductReferenceDtoToJSON,
} from './ProductReferenceDto';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';
import type { StaffAccountReferenceDto } from './StaffAccountReferenceDto';
import {
    StaffAccountReferenceDtoFromJSON,
    StaffAccountReferenceDtoFromJSONTyped,
    StaffAccountReferenceDtoToJSON,
} from './StaffAccountReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * Allows discounting of products or entire orders.
 * @export
 * @interface CodeDto
 */
export interface CodeDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof CodeDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof CodeDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof CodeDto
     */
    code: string;
    /**
     * 
     * @type {ClosedDateTimeRangeDto}
     * @memberof CodeDto
     */
    validityPeriod: ClosedDateTimeRangeDto;
    /**
     * 
     * @type {boolean}
     * @memberof CodeDto
     */
    discountForWholeOrder?: boolean;
    /**
     * 
     * @type {Array<ProductReferenceDto>}
     * @memberof CodeDto
     */
    products?: Array<ProductReferenceDto>;
    /**
     * 
     * @type {string}
     * @memberof CodeDto
     */
    paymentModifier: CodeDtoPaymentModifierEnum;
    /**
     * 
     * @type {DiscountDto}
     * @memberof CodeDto
     */
    discount?: DiscountDto;
    /**
     * 
     * @type {AutomaticConnectionsDto}
     * @memberof CodeDto
     */
    automaticConnections: AutomaticConnectionsDto;
    /**
     * 
     * @type {StaffAccountReferenceDto}
     * @memberof CodeDto
     */
    creatorPerson?: StaffAccountReferenceDto;
    /**
     * 
     * @type {SectionReferenceDto}
     * @memberof CodeDto
     */
    creatorSection?: SectionReferenceDto;
    /**
     * 
     * @type {Date}
     * @memberof CodeDto
     */
    createdAt?: Date;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof CodeDto
     */
    usedBy?: PersonReferenceDto;
    /**
     * 
     * @type {Date}
     * @memberof CodeDto
     */
    usedAt?: Date;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof CodeDto
     */
    order?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeDto
     */
    statusComment?: string;
    /**
     * 
     * @type {CodeStatusDto}
     * @memberof CodeDto
     */
    status: CodeStatusDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof CodeDto
     */
    sentToEmails?: Array<string>;
    /**
     * 
     * @type {Array<CodeSentToPeopleInnerDto>}
     * @memberof CodeDto
     */
    sentToPeople?: Array<CodeSentToPeopleInnerDto>;
}


/**
 * @export
 */
export const CodeDtoPaymentModifierEnum = {
    NONE: 'NONE',
    DISCOUNT: 'DISCOUNT',
    PAYMENT_SUBSTITUTE: 'PAYMENT_SUBSTITUTE'
} as const;
export type CodeDtoPaymentModifierEnum = typeof CodeDtoPaymentModifierEnum[keyof typeof CodeDtoPaymentModifierEnum];


/**
 * Check if a given object implements the CodeDto interface.
 */
export function instanceOfCodeDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('code' in value)) return false;
    if (!('validityPeriod' in value)) return false;
    if (!('paymentModifier' in value)) return false;
    if (!('automaticConnections' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function CodeDtoFromJSON(json: any): CodeDto {
    return CodeDtoFromJSONTyped(json, false);
}

export function CodeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodeDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'code': json['code'],
        'validityPeriod': ClosedDateTimeRangeDtoFromJSON(json['validityPeriod']),
        'discountForWholeOrder': json['discountForWholeOrder'] == null ? undefined : json['discountForWholeOrder'],
        'products': json['products'] == null ? undefined : ((json['products'] as Array<any>).map(ProductReferenceDtoFromJSON)),
        'paymentModifier': json['paymentModifier'],
        'discount': json['discount'] == null ? undefined : DiscountDtoFromJSON(json['discount']),
        'automaticConnections': AutomaticConnectionsDtoFromJSON(json['automaticConnections']),
        'creatorPerson': json['creatorPerson'] == null ? undefined : StaffAccountReferenceDtoFromJSON(json['creatorPerson']),
        'creatorSection': json['creatorSection'] == null ? undefined : SectionReferenceDtoFromJSON(json['creatorSection']),
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
        'usedBy': json['usedBy'] == null ? undefined : PersonReferenceDtoFromJSON(json['usedBy']),
        'usedAt': json['usedAt'] == null ? undefined : (new Date(json['usedAt'])),
        'order': json['order'] == null ? undefined : json['order'],
        'statusComment': json['statusComment'] == null ? undefined : json['statusComment'],
        'status': CodeStatusDtoFromJSON(json['status']),
        'sentToEmails': json['sentToEmails'] == null ? undefined : json['sentToEmails'],
        'sentToPeople': json['sentToPeople'] == null ? undefined : ((json['sentToPeople'] as Array<any>).map(CodeSentToPeopleInnerDtoFromJSON)),
    };
}

export function CodeDtoToJSON(value?: CodeDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'code': value['code'],
        'validityPeriod': ClosedDateTimeRangeDtoToJSON(value['validityPeriod']),
        'discountForWholeOrder': value['discountForWholeOrder'],
        'products': value['products'] == null ? undefined : ((value['products'] as Array<any>).map(ProductReferenceDtoToJSON)),
        'paymentModifier': value['paymentModifier'],
        'discount': DiscountDtoToJSON(value['discount']),
        'automaticConnections': AutomaticConnectionsDtoToJSON(value['automaticConnections']),
        'creatorPerson': StaffAccountReferenceDtoToJSON(value['creatorPerson']),
        'creatorSection': SectionReferenceDtoToJSON(value['creatorSection']),
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
        'usedBy': PersonReferenceDtoToJSON(value['usedBy']),
        'usedAt': value['usedAt'] == null ? undefined : ((value['usedAt']).toISOString()),
        'order': value['order'],
        'statusComment': value['statusComment'],
        'status': CodeStatusDtoToJSON(value['status']),
        'sentToEmails': value['sentToEmails'],
        'sentToPeople': value['sentToPeople'] == null ? undefined : ((value['sentToPeople'] as Array<any>).map(CodeSentToPeopleInnerDtoToJSON)),
    };
}

