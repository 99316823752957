import React from 'react';
import invariant from 'tiny-invariant';
import { PersonAccessGroupRelationDto } from '../../../../api';
import HistoryPage from '../../../../ui/history/history-page';
import { PageState } from '../../../../ui/pagination/use-pagination-state';
import useFetcher from '../../../../util/swr/use-fetcher';
import { AccessGroupHistoryExtension } from '../../../access-group/access-group-relation/access-group-history-extension';
import { fetchPersonAccessGroupHistory } from '../person-access-group-queries';
import usePersonAccessGroupHistorySettings from './use-person-access-group-history-settings';

export const personAccessGroupHistoryExtension: AccessGroupHistoryExtension = {
  content: (accessGroupId, state, onStateChanged) => (
    <PersonAccessGroupHistory accessGroupId={accessGroupId} state={state} onStateChanged={onStateChanged} />
  ),
};

interface PersonAccessGroupHistoryProps {
  accessGroupId: string;
  state: PageState;
  onStateChanged: (state: PageState) => void;
}

function PersonAccessGroupHistory({ accessGroupId, state, onStateChanged }: PersonAccessGroupHistoryProps) {
  const personAccessGroupHistorySettings = usePersonAccessGroupHistorySettings();

  const personAccessGroupHistoryPage = useFetcher(fetchPersonAccessGroupHistory, {
    id: accessGroupId,
    pageable: state,
  });
  invariant(personAccessGroupHistoryPage != null, 'Missing person access group history page');

  return (
    <HistoryPage<PersonAccessGroupRelationDto>
      page={personAccessGroupHistoryPage}
      state={state}
      onStateChange={onStateChanged}
      historyDisplaySettings={personAccessGroupHistorySettings}
      showLine={false}
    />
  );
}
