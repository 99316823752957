/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryReferenceDto } from './CountryReferenceDto';
import {
    CountryReferenceDtoFromJSON,
    CountryReferenceDtoFromJSONTyped,
    CountryReferenceDtoToJSON,
} from './CountryReferenceDto';

/**
 * 
 * @export
 * @interface CompanyPostalAddressDto
 */
export interface CompanyPostalAddressDto {
    /**
     * The address
     * @type {string}
     * @memberof CompanyPostalAddressDto
     */
    address?: string;
    /**
     * The address supplement
     * @type {string}
     * @memberof CompanyPostalAddressDto
     */
    addressSupplement?: string;
    /**
     * The post code of the address
     * @type {string}
     * @memberof CompanyPostalAddressDto
     */
    postCode?: string;
    /**
     * The city of the address
     * @type {string}
     * @memberof CompanyPostalAddressDto
     */
    city: string;
    /**
     * The state of the address
     * @type {string}
     * @memberof CompanyPostalAddressDto
     */
    state?: string;
    /**
     * 
     * @type {CountryReferenceDto}
     * @memberof CompanyPostalAddressDto
     */
    country: CountryReferenceDto;
    /**
     * 
     * @type {Date}
     * @memberof CompanyPostalAddressDto
     */
    timestamp?: Date;
    /**
     * The label for the postal address
     * @type {string}
     * @memberof CompanyPostalAddressDto
     */
    label: CompanyPostalAddressDtoLabelEnum;
}


/**
 * @export
 */
export const CompanyPostalAddressDtoLabelEnum = {
    HEAD_OFFICE: 'HEAD_OFFICE',
    HOUSE_ADDRESS: 'HOUSE_ADDRESS',
    POSTAL_ADDRESS: 'POSTAL_ADDRESS',
    BILLING_ADDRESS: 'BILLING_ADDRESS',
    SUPPLIER_ENTRANCE: 'SUPPLIER_ENTRANCE',
    STORE: 'STORE',
    OTHER: 'OTHER'
} as const;
export type CompanyPostalAddressDtoLabelEnum = typeof CompanyPostalAddressDtoLabelEnum[keyof typeof CompanyPostalAddressDtoLabelEnum];


/**
 * Check if a given object implements the CompanyPostalAddressDto interface.
 */
export function instanceOfCompanyPostalAddressDto(value: object): boolean {
    if (!('city' in value)) return false;
    if (!('country' in value)) return false;
    if (!('label' in value)) return false;
    return true;
}

export function CompanyPostalAddressDtoFromJSON(json: any): CompanyPostalAddressDto {
    return CompanyPostalAddressDtoFromJSONTyped(json, false);
}

export function CompanyPostalAddressDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyPostalAddressDto {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'] == null ? undefined : json['address'],
        'addressSupplement': json['addressSupplement'] == null ? undefined : json['addressSupplement'],
        'postCode': json['postCode'] == null ? undefined : json['postCode'],
        'city': json['city'],
        'state': json['state'] == null ? undefined : json['state'],
        'country': CountryReferenceDtoFromJSON(json['country']),
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
        'label': json['label'],
    };
}

export function CompanyPostalAddressDtoToJSON(value?: CompanyPostalAddressDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': value['address'],
        'addressSupplement': value['addressSupplement'],
        'postCode': value['postCode'],
        'city': value['city'],
        'state': value['state'],
        'country': CountryReferenceDtoToJSON(value['country']),
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
        'label': value['label'],
    };
}

