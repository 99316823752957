import { Menu, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import { faToggleOff, faToggleOn } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AccessGroupDto, AccessGroupStatusDto } from '../../../api';
import { BurgerMenuButton, BurgerMenuButtonProps } from '../../../ui/burger-menu-button/burger-menu-button';
import usePermission from '../../permission/use-permission';
import AccessGroupStatusToggleButton from '../access-group-status-toggle/access-group-status-toggle-button';

interface AccessGroupMenuProps extends BurgerMenuButtonProps {
  accessGroup: AccessGroupDto;
}

export default function AccessGroupMenu({ accessGroup, ...props }: AccessGroupMenuProps) {
  const { t } = useTranslation(['access_group']);
  const isActive = accessGroup.status === AccessGroupStatusDto.ACTIVE;
  const { hasPermission } = usePermission();

  return (
    ((hasPermission('ACCESS_GROUP:CAN_ACTIVATE') && !isActive) ||
      (hasPermission('ACCESS_GROUP:CAN_DEACTIVATE') && isActive)) && (
      <Menu>
        <BurgerMenuButton {...props} />
        <Portal>
          <MenuList>
            <MenuItem
              as={AccessGroupStatusToggleButton}
              accessGroup={accessGroup}
              color={isActive ? 'text.error' : 'text'}
              icon={<FontAwesomeIcon icon={isActive ? faToggleOff : faToggleOn} fixedWidth />}
            >
              {isActive ? t('access_group:action.deactivate') : t('access_group:action.activate')}
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    )
  );
}
