import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { HelmetRouteObject } from '../../../ui/helmet/helmet-outlet';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchPublicEventData } from '../../live-ticketing/live-ticketing-queries';
import PublicLiveTicketingViewer from './public-live-ticketing-viewer';

const publicLiveTicketingRoutes: HelmetRouteObject[] = [
  {
    path: 'live-ticketing',
    children: [
      {
        path: ':publicationId',
        element: <PublicLiveTicketingViewer />,
        handle: {
          helmet: <PublicLiveTicketingViewerHelmet />,
        },
      },
    ],
  },
];

export default publicLiveTicketingRoutes;

function PublicLiveTicketingViewerHelmet() {
  const { t } = useTranslation('live_ticketing');
  const { publicationId } = useParams<{ publicationId: string }>();
  invariant(publicationId != null, 'Missing publicationId');

  const eventSettings = useFetcher(fetchPublicEventData, { publicationId });

  return <Helmet title={t('viewer.helmet', { title: eventSettings.eventReference.title })} />;
}
