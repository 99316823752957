/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LandingPageRsvpModuleSettingsDto
 */
export interface LandingPageRsvpModuleSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof LandingPageRsvpModuleSettingsDto
     */
    moduleType: string;
    /**
     * 
     * @type {Date}
     * @memberof LandingPageRsvpModuleSettingsDto
     */
    timestamp?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof LandingPageRsvpModuleSettingsDto
     */
    commentsAllowed: boolean;
    /**
     * 
     * @type {string}
     * @memberof LandingPageRsvpModuleSettingsDto
     */
    helperText: string;
}

/**
 * Check if a given object implements the LandingPageRsvpModuleSettingsDto interface.
 */
export function instanceOfLandingPageRsvpModuleSettingsDto(value: object): boolean {
    if (!('moduleType' in value)) return false;
    if (!('commentsAllowed' in value)) return false;
    if (!('helperText' in value)) return false;
    return true;
}

export function LandingPageRsvpModuleSettingsDtoFromJSON(json: any): LandingPageRsvpModuleSettingsDto {
    return LandingPageRsvpModuleSettingsDtoFromJSONTyped(json, false);
}

export function LandingPageRsvpModuleSettingsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageRsvpModuleSettingsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'moduleType': json['moduleType'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
        'commentsAllowed': json['commentsAllowed'],
        'helperText': json['helperText'],
    };
}

export function LandingPageRsvpModuleSettingsDtoToJSON(value?: LandingPageRsvpModuleSettingsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'moduleType': value['moduleType'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
        'commentsAllowed': value['commentsAllowed'],
        'helperText': value['helperText'],
    };
}

