import { chakra, HTMLChakraProps } from '@chakra-ui/react';
import React, { ForwardedRef, RefObject, useEffect } from 'react';
import { FieldValues, SubmitErrorHandler, SubmitHandler, useFormContext } from 'react-hook-form';

export interface FormProps<TFieldValues extends FieldValues> extends Omit<HTMLChakraProps<'form'>, 'onInvalid'> {
  onValid: SubmitHandler<TFieldValues>;
  onInvalid?: SubmitErrorHandler<TFieldValues>;
  submitOnEnterKey?: boolean;
  initialFocusRef: RefObject<HTMLElement> | undefined;
}

function Form<TFieldValues extends FieldValues>(
  {
    onValid,
    onInvalid,
    noValidate = true,
    submitOnEnterKey = false,
    children,
    onSubmit,
    initialFocusRef,
    ...props
  }: FormProps<TFieldValues>,
  ref: ForwardedRef<HTMLFormElement>,
) {
  const { handleSubmit, reset } = useFormContext<TFieldValues>();

  useEffect(() => {
    initialFocusRef?.current?.focus();
  }, [initialFocusRef]);

  return (
    <chakra.form
      ref={ref}
      onSubmit={(event) => {
        onSubmit?.(event);

        if (!event.defaultPrevented) {
          handleSubmit(onValid, onInvalid)(event).catch(() => reset(undefined, { keepValues: true, keepDirty: true }));
        }
      }}
      noValidate={noValidate}
      onKeyDown={(event) => {
        if (
          !submitOnEnterKey &&
          event.code === 'Enter' &&
          // lh: Prevent default behaviour if enter key was hit on a button (submit button or otherwise).
          (event.target as Element).closest('button') == null &&
          !(event.target as Element).classList.contains('chakra-textarea') &&
          !((event.target as Element).getAttribute('role') === 'textbox')
        ) {
          event.preventDefault();
        }
      }}
      {...props}
    >
      {children}
    </chakra.form>
  );
}

export default React.forwardRef(Form);
