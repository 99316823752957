import { ListItem, UnorderedList } from '@chakra-ui/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import HelperPopover from '../../../ui/helper-buttons/helper-popover';
import MailingConnectionSelect from '../../mailing/create-mailing-modal/mailing-connection-select';

export function EventMailingConnectionSelect() {
  const { t } = useTranslation('event');

  return (
    <MailingConnectionSelect
      label={t('event')}
      helperPopover={
        <HelperPopover header={t('event')}>
          <Trans t={t} i18nKey="mailingExtension.helperText" components={{ ul: <UnorderedList />, li: <ListItem /> }} />
        </HelperPopover>
      }
    />
  );
}
