/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ColumnDescriptionDto
 */
export interface ColumnDescriptionDto {
    /**
     * 
     * @type {string}
     * @memberof ColumnDescriptionDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ColumnDescriptionDto
     */
    type: ColumnDescriptionDtoTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ColumnDescriptionDto
     */
    isList: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ColumnDescriptionDto
     */
    enumTranslations: { [key: string]: string; };
}


/**
 * @export
 */
export const ColumnDescriptionDtoTypeEnum = {
    DATE: 'DATE',
    DATE_TIME: 'DATE_TIME',
    BOOLEAN: 'BOOLEAN',
    NUMBER: 'NUMBER',
    STRING: 'STRING',
    UUID: 'UUID',
    OBJECT: 'OBJECT',
    ENUM: 'ENUM'
} as const;
export type ColumnDescriptionDtoTypeEnum = typeof ColumnDescriptionDtoTypeEnum[keyof typeof ColumnDescriptionDtoTypeEnum];


/**
 * Check if a given object implements the ColumnDescriptionDto interface.
 */
export function instanceOfColumnDescriptionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('type' in value)) return false;
    if (!('isList' in value)) return false;
    if (!('enumTranslations' in value)) return false;
    return true;
}

export function ColumnDescriptionDtoFromJSON(json: any): ColumnDescriptionDto {
    return ColumnDescriptionDtoFromJSONTyped(json, false);
}

export function ColumnDescriptionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColumnDescriptionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'type': json['type'],
        'isList': json['isList'],
        'enumTranslations': json['enumTranslations'],
    };
}

export function ColumnDescriptionDtoToJSON(value?: ColumnDescriptionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'type': value['type'],
        'isList': value['isList'],
        'enumTranslations': value['enumTranslations'],
    };
}

