import { SectionReferenceDto } from '../../../api';
import { sectionIcon } from '../../../ui/icons/business-objects';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';

export interface SectionReferenceProps extends Omit<ReferenceProps, 'children' | 'icon' | 'to'> {
  sectionReference: SectionReferenceDto;
}

export default function SectionReference({ sectionReference, ...props }: SectionReferenceProps) {
  return (
    <Reference
      icon={sectionIcon}
      to={`/sections/${sectionReference.id}`}
      {...props}
      necessaryPermission={({ hasPermission, hasPermissionFromSection }) => {
        return (
          hasPermission('SECTION:CAN_SEE_DETAILS') ||
          hasPermissionFromSection(sectionReference.id, 'SECTION:CAN_SEE_DETAILS_OWN')
        );
      }}
    >
      {sectionReference.name}
    </Reference>
  );
}
