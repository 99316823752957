import React from 'react';
import useSWR from 'swr';
import invariant from 'tiny-invariant';
import editionApi from '../../../data-access/edition-api';
import { useMergedSearchParams } from '../../../util/use-merged-search-params/use-merged-search-params';
import useSessionStorage from '../../../util/use-session-storage/use-session-storage';

export function useFocusedOrActiveEditionId(setSearchParam: boolean = false, focusActiveEdition: boolean = false) {
  const [focusedEditionId, setFocusedEditionId] = useFocusedEditionId(setSearchParam);

  // don't load active edition if focusedEditionId is set
  const { data: activeEdition } = useSWR(
    focusedEditionId != null ? null : 'activeEdition',
    () => editionApi.activeEdition(),
    {
      suspense: true,
    },
  );

  React.useEffect(() => {
    if (focusedEditionId == null && focusActiveEdition) {
      invariant(activeEdition.id != null);
      setFocusedEditionId(activeEdition.id);
    }
  }, [activeEdition?.id, focusActiveEdition, focusedEditionId, setFocusedEditionId]);

  if (focusedEditionId != null) {
    return focusedEditionId;
  } else {
    invariant(activeEdition?.id != null);
    return activeEdition.id;
  }
}

const FOCUSED_EDITION_URL_PARAM = 'focusedEdition';

export function createFocusedEditionSearchParam(editionId: string) {
  return new URLSearchParams([[FOCUSED_EDITION_URL_PARAM, editionId]]);
}

export function useFocusedEditionId(setSearchParam: boolean = false) {
  const [searchParams, setSearchParams] = useMergedSearchParams([FOCUSED_EDITION_URL_PARAM]);
  const paramFocusedEditionId = searchParams.get(FOCUSED_EDITION_URL_PARAM);

  const [sessionFocusedEditionId, setSessionFocusedEditionId] = useSessionStorage<string | null>(
    'focused-edition',
    null,
  );

  React.useEffect(() => {
    if (paramFocusedEditionId != null) {
      setSessionFocusedEditionId(paramFocusedEditionId);
    } else if (sessionFocusedEditionId != null && setSearchParam) {
      setSearchParams({ [FOCUSED_EDITION_URL_PARAM]: sessionFocusedEditionId }, { replace: true });
    }
  }, [setSessionFocusedEditionId, paramFocusedEditionId, sessionFocusedEditionId, setSearchParam, setSearchParams]);

  return [sessionFocusedEditionId, setSessionFocusedEditionId] as const;
}

export function useSetFocusedEdition(editionId: string) {
  const [, setFocusedEditionId] = useFocusedEditionId();

  React.useEffect(() => {
    setFocusedEditionId(editionId);
  }, [editionId, setFocusedEditionId]);
}
