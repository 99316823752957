/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SyncStatusInnerDto
 */
export interface SyncStatusInnerDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof SyncStatusInnerDto
     */
    accountId?: string;
    /**
     * 
     * @type {string}
     * @memberof SyncStatusInnerDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SyncStatusInnerDto
     */
    status: SyncStatusInnerDtoStatusEnum;
}


/**
 * @export
 */
export const SyncStatusInnerDtoStatusEnum = {
    NO_ACCOUNT: 'NO_ACCOUNT',
    MAILING_BLOCKLIST: 'MAILING_BLOCKLIST',
    BASIC: 'BASIC',
    PROFESSIONAL: 'PROFESSIONAL'
} as const;
export type SyncStatusInnerDtoStatusEnum = typeof SyncStatusInnerDtoStatusEnum[keyof typeof SyncStatusInnerDtoStatusEnum];


/**
 * Check if a given object implements the SyncStatusInnerDto interface.
 */
export function instanceOfSyncStatusInnerDto(value: object): boolean {
    if (!('email' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function SyncStatusInnerDtoFromJSON(json: any): SyncStatusInnerDto {
    return SyncStatusInnerDtoFromJSONTyped(json, false);
}

export function SyncStatusInnerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyncStatusInnerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'accountId': json['accountId'] == null ? undefined : json['accountId'],
        'email': json['email'],
        'status': json['status'],
    };
}

export function SyncStatusInnerDtoToJSON(value?: SyncStatusInnerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accountId': value['accountId'],
        'email': value['email'],
        'status': value['status'],
    };
}

