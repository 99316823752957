/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The result of a status check.
 * @export
 * @interface SectionStatusCheckDto
 */
export interface SectionStatusCheckDto {
    /**
     * 
     * @type {boolean}
     * @memberof SectionStatusCheckDto
     */
    readonly canBeDeactivated: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SectionStatusCheckDto
     */
    readonly hasActiveStaff: boolean;
}

/**
 * Check if a given object implements the SectionStatusCheckDto interface.
 */
export function instanceOfSectionStatusCheckDto(value: object): boolean {
    if (!('canBeDeactivated' in value)) return false;
    if (!('hasActiveStaff' in value)) return false;
    return true;
}

export function SectionStatusCheckDtoFromJSON(json: any): SectionStatusCheckDto {
    return SectionStatusCheckDtoFromJSONTyped(json, false);
}

export function SectionStatusCheckDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionStatusCheckDto {
    if (json == null) {
        return json;
    }
    return {
        
        'canBeDeactivated': json['canBeDeactivated'],
        'hasActiveStaff': json['hasActiveStaff'],
    };
}

export function SectionStatusCheckDtoToJSON(value?: SectionStatusCheckDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
    };
}

