import { ReactNode } from 'react';
import { PageState } from '../../../ui/pagination/use-pagination-state';
import { PluginToken } from '../../../util/plugin/use-plugins';

export interface GroupRelationHistoryExtension {
  content: (groupId: string, state: PageState, onStageChanged: (state: PageState) => void) => ReactNode;
}

export const GROUP_PERSON_RELATION_HISTORY_EXTENSION = new PluginToken<GroupRelationHistoryExtension>(
  'GroupPersonRelationHistoryExtension',
);

export const GROUP_COMPANY_RELATION_HISTORY_EXTENSION = new PluginToken<GroupRelationHistoryExtension>(
  'GroupCompanyRelationHistoryExtension',
);

export const GROUP_STAFF_RELATION_HISTORY_EXTENSION = new PluginToken<GroupRelationHistoryExtension>(
  'GroupStaffRelationHistoryExtension',
);
