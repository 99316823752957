import { Box, chakra, Flex, Image, Link, List, ListItem, Spacer, Stack, Tag, Wrap, WrapItem } from '@chakra-ui/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PersonStatusDto, PhoneNumberDtoLabelEnum } from '../../../api';
import renderPhoneNumber from '../../../ui/phone-number/render-phone-number';
import VisitingCard, { VisitingCardItem } from '../../../ui/visiting-card/visiting-card';
import CompanyReference from '../../company/company-reference/company-reference';
import SectionReference from '../../section/section-reference/section-reference';
import usePersonCard from '../use-person-card/use-person-card';

interface PersonCardProps {
  personId: string;
  flipName?: boolean;
}

const PHONE_NUMBER_ORDER: PhoneNumberDtoLabelEnum[] = ['MOBILE', 'BUSINESS', 'PRIVATE', 'OTHER'];

export default function PersonCard({ personId, flipName }: PersonCardProps) {
  const { t } = useTranslation('person');
  const { t: tStaff } = useTranslation('staff');
  const personDetails = usePersonCard(personId);
  const displayName = flipName
    ? `${personDetails.surname}, ${personDetails.firstName}`
    : `${personDetails.firstName} ${personDetails.surname}`;

  const title =
    personDetails.staffInformation == null
      ? personDetails.status === PersonStatusDto.ANONYMISED
        ? t('label.anonymized')
        : t('label.default')
      : t('label.staff');

  const renderJobTitleAndWorkplace = (
    index: number,
    jobTitle: string | undefined,
    reference: React.ReactElement | false | undefined,
  ) => {
    if (jobTitle != null && reference) {
      return (
        <Wrap
          key={index}
          spacingX={1}
          // "0px" due to a bug in Chakra (simply passing 0 is disabling spacing altogether)
          spacing="0px"
        >
          <Trans
            t={t}
            values={{ jobTitle }}
            i18nKey="card.job_title_and_workspace"
            components={{
              wrapItem: <WrapItem />,
              strong: <chakra.strong color="text" fontWeight="medium" />,
              reference,
            }}
          />
        </Wrap>
      );
    }

    if (jobTitle != null) {
      return <chakra.strong fontWeight="medium">{jobTitle}</chakra.strong>;
    }

    return reference;
  };

  const personPhoneNumbers = personDetails.phoneNumbers;
  const staffPhoneNumbers = personDetails.staffInformation?.phoneNumbers;

  return (
    <VisitingCard label={displayName} title={title}>
      <Flex align="center">
        <VisitingCardItem label={displayName}>
          {personDetails.staffInformation?.status != null && (
            <Tag size="sm">{tStaff(`statusOptions.${personDetails.staffInformation.status}`)}</Tag>
          )}
          {personDetails.staffInformation?.status == null &&
            personDetails.status != null &&
            personDetails.status !== PersonStatusDto.ACTIVE && (
              <Tag size="sm">{t(`statusLabel.${personDetails.status}`)}</Tag>
            )}
        </VisitingCardItem>
        {personDetails.photoMetadata?.url != null && (
          <>
            <Spacer />
            <Image
              src={personDetails.photoMetadata.url}
              boxSize={12}
              objectFit="cover"
              borderRadius="sm"
              ml={4}
              mr={-2}
              my={-1}
              alt={t('photo.picture_alt_text')}
            />
          </>
        )}
      </Flex>
      {personDetails.staffInformation?.sections?.length ? (
        <VisitingCardItem>
          {personDetails.staffInformation.sections.map((section, index) =>
            renderJobTitleAndWorkplace(
              index,
              section.jobTitle,
              section.section != null && <SectionReference key={index} sectionReference={section.section} size="sm" />,
            ),
          )}
        </VisitingCardItem>
      ) : (
        personDetails.occupation?.jobTitle != null && (
          <VisitingCardItem>
            {renderJobTitleAndWorkplace(
              0,
              personDetails.occupation?.jobTitle,
              personDetails.occupation?.connectedCompany != null && (
                <CompanyReference companyReference={personDetails.occupation.connectedCompany} size="sm" />
              ),
            )}
          </VisitingCardItem>
        )
      )}
      {personDetails.staffInformation?.room && (
        <VisitingCardItem label={t('card.room')}>{personDetails.staffInformation.room}</VisitingCardItem>
      )}
      {(personDetails.staffInformation?.email != null || personDetails.emailAddresses?.length) && (
        <VisitingCardItem label={t('contacts.email.email_address')} length={personDetails.emailAddresses?.length}>
          <Stack spacing={0.5}>
            {personDetails.staffInformation?.email != null ? (
              <MailTo email={personDetails.staffInformation.email} />
            ) : (
              personDetails.emailAddresses?.map((email, index) => <MailTo key={index} email={email} />)
            )}
          </Stack>
        </VisitingCardItem>
      )}
      {(personPhoneNumbers?.length || staffPhoneNumbers?.length) && (
        <VisitingCardItem
          label={t('contacts.phone_number.singular')}
          length={personPhoneNumbers?.length || staffPhoneNumbers?.length}
        >
          <List spacing={0}>
            {staffPhoneNumbers?.length
              ? staffPhoneNumbers.map((phoneNumber, index) => <ListItem key={index}>{phoneNumber}</ListItem>)
              : [...personDetails.phoneNumbers!]
                  .sort((a, b) => PHONE_NUMBER_ORDER.indexOf(a.label) - PHONE_NUMBER_ORDER.indexOf(b.label))
                  .slice(0, 2) // only first two should be shown
                  .map((phoneNumber, index) => (
                    <ListItem key={index}>
                      {renderPhoneNumber(phoneNumber) +
                        ' (' +
                        t(`contacts.phone_number.labelOptions.${phoneNumber.label}`) +
                        ')'}
                    </ListItem>
                  ))}
          </List>
          {((personPhoneNumbers?.length != null && personPhoneNumbers.length > 2) ||
            (staffPhoneNumbers?.length != null && staffPhoneNumbers.length > 2)) && <Box>{t('card.more_numbers')}</Box>}
        </VisitingCardItem>
      )}
    </VisitingCard>
  );
}

function MailTo({ email }: { email: string | undefined }) {
  return email ? (
    <Link href={'mailto:' + email} onClick={(e) => e.stopPropagation()}>
      {email}
    </Link>
  ) : null;
}
