/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RoleReferencePermissionDto
 */
export interface RoleReferencePermissionDto {
    /**
     * 
     * @type {boolean}
     * @memberof RoleReferencePermissionDto
     */
    userHasRole?: boolean;
}

/**
 * Check if a given object implements the RoleReferencePermissionDto interface.
 */
export function instanceOfRoleReferencePermissionDto(value: object): boolean {
    return true;
}

export function RoleReferencePermissionDtoFromJSON(json: any): RoleReferencePermissionDto {
    return RoleReferencePermissionDtoFromJSONTyped(json, false);
}

export function RoleReferencePermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleReferencePermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'userHasRole': json['userHasRole'] == null ? undefined : json['userHasRole'],
    };
}

export function RoleReferencePermissionDtoToJSON(value?: RoleReferencePermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userHasRole': value['userHasRole'],
    };
}

