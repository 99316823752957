/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProductBundleStatusDto = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    ARCHIVED: 'ARCHIVED'
} as const;
export type ProductBundleStatusDto = typeof ProductBundleStatusDto[keyof typeof ProductBundleStatusDto];


export function ProductBundleStatusDtoFromJSON(json: any): ProductBundleStatusDto {
    return ProductBundleStatusDtoFromJSONTyped(json, false);
}

export function ProductBundleStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductBundleStatusDto {
    return json as ProductBundleStatusDto;
}

export function ProductBundleStatusDtoToJSON(value?: ProductBundleStatusDto | null): any {
    return value as any;
}

