import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EmailAddressDto,
  EmailAddressDtoLabelEnum,
  GenderIdentityDto,
  LanguagesDto,
  OfficiallyRegisteredGenderDto,
  PersonDto,
  PersonDtoPostNominalEnum,
  PersonDtoPublishStageNameEnum,
  PersonDtoSalutationEnum,
  PersonLocationRelationDto,
  PersonLocationRelationDtoRelationTypeEnum,
  PersonPersonConnectionDto,
  PersonPersonConnectionDtoConnectionTypeEnum,
  PersonStatusDto,
  PersonTypeDto,
  PhoneNumberDto,
  PhoneNumberDtoLabelEnum,
  PostalAddressDto,
  PostalAddressDtoLabelEnum,
  TitleDto,
} from '../../../api';
import HistoryDisplaySettings, { HistoryStatusEnum } from '../../../ui/history/history-display-settings';
import getFormattedPersonValueForHistory from './get-formatted-person-value-for-history';

export default function usePersonHistorySettings(): HistoryDisplaySettings<PersonDto> {
  const { t } = useTranslation('person');
  const { t: tCommon } = useTranslation('common');
  const { t: tAttachment } = useTranslation('attachment');

  return useMemo<HistoryDisplaySettings<PersonDto>>(() => {
    return {
      attributeLabels: {
        personKey: t('personKey'),
        firstName: t('firstName'),
        surname: t('surname'),
        salutation: t('salutation'),
        salutationIndividual: t('salutation_individual'),
        title: t('title'),
        postNominal: t('postNominal'),
        genderIdentity: t('genderIdentity'),
        genderIdentityText: t('genderIdentityLabels.FREE_HAND_TEXT'),
        officiallyRegisteredGender: t('officiallyRegisteredGender'),
        dateOfBirth: t('date_of_birth'),
        placeOfBirth: t('place_of_birth'),
        nationalities: t('nationalities'),
        stageName: t('stage_name'),
        publishStageName: t('publishStageName'),
        alternativeNames: t('alternative_names'),
        contactLanguage: t('contacts.contactLanguage'),
        emailAddresses: t('contacts.email.header'),
        phoneNumbers: t('contacts.phone_number.header'),
        postalAddresses: t('contacts.postal_address.header'),
        fieldsOfInterest: t('fieldsOfInterest'),
        confidentiality: t('confidentiality'),
        markers: t('markers'),
        historicMarkers: t('historic_markers'),
        photoMetadata: tAttachment('photo'),
        status: t('status'),
        personPersonConnections: t('connections.persons'),
        personLocationRelations: t('relations.locations'),
        types: t('type'),
        occupations: t('occupations.title'),
      },
      valueFormatter: {
        salutation: (value) => (value == null ? undefined : t(`salutationLabels.${value as PersonDtoSalutationEnum}`)),
        genderIdentity: (value) =>
          value == null ? undefined : t(`genderIdentityLabels.${value as GenderIdentityDto}`),
        officiallyRegisteredGender: (value) =>
          value == null ? undefined : t(`officiallyRegisteredGenderLabels.${value as OfficiallyRegisteredGenderDto}`),
        title: (value) => (value == null ? undefined : t(`titleLabels.${value as TitleDto}`)),
        postNominal: (value) =>
          value == null ? undefined : t(`postNominalLabels.${value as PersonDtoPostNominalEnum}`),
        alternativeNames: (value) => (value == null || value.length === 0 ? undefined : value?.join(', ')),
        publishStageName: (value) =>
          value == null ? undefined : t(`publishStageNameLabels.${value as PersonDtoPublishStageNameEnum}`),
        dateOfBirth: (value) => (value == null ? undefined : tCommon('format.date', { date: value })),
        nationalities: (value) =>
          value == null || value.length === 0 ? undefined : value.map((c) => c.name).join(', '),
        contactLanguage: (value) =>
          value == null ? undefined : t(`contacts.contactLanguageLabels.${value as LanguagesDto}`),
        confidentiality: (value) => (value == null ? undefined : t(`confidentialityLabel.${value as boolean}`)),
        markers: (value) =>
          value == null || value.length === 0 ? undefined : value.map((v) => t(`markersLabel.${v}`)).join(', '),
        historicMarkers: (value) =>
          value == null || value.length === 0 ? undefined : value.map((v) => t(`markersLabel.${v}`)).join(', '),
        fieldsOfInterest: (value) =>
          value == null || value.length === 0
            ? undefined
            : value.map((v) => t(`fieldsOfInterestLabels.${v}`)).join(', '),
        status: (value) => (value == null ? undefined : t(`statusLabel.${value as PersonStatusDto}`)),
        photoMetadata: (value) => value?.originalFileName,
        types: (values) =>
          values == null ? undefined : values.map((value) => t(`typeLabel.${value as PersonTypeDto}`)).join(', '),
        occupations: (values) =>
          values == null
            ? undefined
            : values
                .map((value) => {
                  if (value.jobTitle == null) {
                    return `(${t('occupations.deleted')})`;
                  }
                  return value.mainActivity
                    ? value.jobTitle + ' (' + t('occupations.mainActivity.tooltip') + ')'
                    : value.jobTitle;
                })
                .join(', '),
      },
      subElementSettings: {
        emailAddresses: getEmailAddressHistorySettings(t, tCommon),
        phoneNumbers: getPhoneNumberHistorySettings(t, tCommon),
        postalAddresses: getPostalAddressDtoHistorySettings(t),
        personLocationRelations: getPersonLocationRelationDtoHistorySettings(t, tCommon),
        personPersonConnections: getPersonPersonConnectionDtoHistorySettings(t, tCommon),
      },
      diffEntireWord: {
        personKey: true,
        salutation: true,
        genderIdentity: true,
        title: true,
        contactLanguage: true,
        status: true,
        confidentiality: true,
        stageName: true,
        publishStageName: true,
        occupations: true,
      },
      name: 'personKey',
      statusFormatter: (value) => {
        if (value.status === 'ANONYMISED') {
          return HistoryStatusEnum.ANONYMISED;
        }
        if (value.status === 'DELETED') {
          return HistoryStatusEnum.DELETED;
        }
        return undefined;
      },
    };
  }, [t, tCommon, tAttachment]);
}

function getEmailAddressHistorySettings(t: TFunction<'person'>, tCommon: TFunction<'common'>) {
  return (): HistoryDisplaySettings<EmailAddressDto> => ({
    attributeLabels: {
      email: t('contacts.email.email_address'),
      publish: t('contacts.email.publish'),
      label: t('contacts.email.label'),
      confidential: t('contacts.email.confidential'),
    },
    valueFormatter: {
      publish: (value) => (value == null ? undefined : tCommon(`boolean_viewer.${value as boolean}`)),
      label: (value) =>
        value == null ? undefined : t(`contacts.email.labelOptions.${value as EmailAddressDtoLabelEnum}`),
      confidential: (value) => (value == null ? undefined : tCommon(`boolean_viewer.${value as boolean}`)),
    },
    diffEntireWord: {
      publish: true,
      label: true,
      confidential: true,
    },
    name: 'email',
  });
}

function getPhoneNumberHistorySettings(t: TFunction<'person'>, tCommon: TFunction<'common'>) {
  return (): HistoryDisplaySettings<PhoneNumberDto> => ({
    attributeLabels: {
      countryCode: t('contacts.phone_number.dialog.country_code'),
      dialingCode: t('contacts.phone_number.dialog.dialing_code'),
      number: t('contacts.phone_number.dialog.number'),
      extension: t('contacts.phone_number.dialog.extension'),
      note: t('contacts.phone_number.note'),
      label: t('contacts.phone_number.label'),
      publish: t('contacts.phone_number.publish'),
      confidential: t('contacts.phone_number.confidential'),
    },
    valueFormatter: {
      countryCode: (value) => (value == null ? undefined : value.countryCode + ' ' + value.countryName),
      publish: (value) => (value == null ? undefined : tCommon(`boolean_viewer.${value as boolean}`)),
      label: (value) =>
        value == null ? undefined : t(`contacts.phone_number.labelOptions.${value as PhoneNumberDtoLabelEnum}`),
      confidential: (value) => (value == null ? undefined : tCommon(`boolean_viewer.${value as boolean}`)),
    },
    diffEntireWord: {
      publish: true,
      label: true,
      confidential: true,
    },
    name: 'countryCode',
  });
}

function getPostalAddressDtoHistorySettings(t: TFunction<'person'>) {
  return (): HistoryDisplaySettings<PostalAddressDto> => ({
    attributeLabels: {
      address: t('contacts.postal_address.address'),
      addressSupplement: t('contacts.postal_address.address_supplement'),
      postCode: t('contacts.postal_address.dialog.post_code'),
      city: t('contacts.postal_address.dialog.city'),
      state: t('contacts.postal_address.state'),
      country: t('contacts.postal_address.dialog.state'),
      label: t('contacts.postal_address.label'),
    },
    valueFormatter: {
      country: (value) => (value == null ? undefined : value.name),
      label: (value) =>
        value == null ? undefined : t(`contacts.postal_address.labelOptions.${value as PostalAddressDtoLabelEnum}`),
    },
    diffEntireWord: {
      label: true,
    },
    name: 'address',
  });
}

function getPersonLocationRelationDtoHistorySettings(t: TFunction<'person'>, tCommon: TFunction<'common'>) {
  return (): HistoryDisplaySettings<PersonLocationRelationDto> => ({
    attributeLabels: {
      relationType: t('relations.relationType'),
      connectedLocation: t('relations.already_connected_location'),
      dateRange: t('relations.date_range'),
      comment: t('relations.comment'),
    },
    valueFormatter: {
      dateRange: (value) => tCommon('format.date_range', { dateRange: value }),
      connectedLocation: (value) =>
        value == null
          ? t('relations.deleted_location_title')
          : value.name == null
            ? t('relations.forbidden_location_title')
            : value.name,
      relationType: (value) =>
        value == null
          ? undefined
          : t(`relations.relationTypeLabels.${value as PersonLocationRelationDtoRelationTypeEnum}`),
    },
    diffEntireWord: {
      relationType: true,
    },
    name: 'relationType',
  });
}

function getPersonPersonConnectionDtoHistorySettings(t: TFunction<'person'>, tCommon: TFunction<'common'>) {
  return (): HistoryDisplaySettings<PersonPersonConnectionDto> => ({
    attributeLabels: {
      connectedPerson: t('connections.person'),
      connectionType: t('connections.connectionType'),
      dateRange: t('connections.date_range'),
    },
    valueFormatter: {
      dateRange: (value) => tCommon('format.date_range', { dateRange: value }),
      connectedPerson: (value) => getFormattedPersonValueForHistory(tCommon, value),
      connectionType: (value) =>
        value == null
          ? undefined
          : t(`connections.connectionTypeLabels.${value as PersonPersonConnectionDtoConnectionTypeEnum}`),
    },
    diffEntireWord: {
      connectionType: true,
    },
    name: 'connectionType',
  });
}
