import { Box, chakra, Divider, HStack, Stack } from '@chakra-ui/react';
import React from 'react';
import Optional from '../../optional/optional';
import DataTableColumn, { isParentDataTableColumn } from '../data-table-column';
import DataTablePage from '../data-table-page';

export interface DataTableTilesProps<TData, TChildData = void> {
  page: DataTablePage<TData>;
  columns: DataTableColumn<TData, TChildData>[];
}

export default function DataTableTiles<TData, TChildData = void>({
  page,
  columns,
}: DataTableTilesProps<TData, TChildData>) {
  const lastColumn = columns[columns.length - 1];

  return (
    <Stack divider={<Divider />}>
      {page.content.map((data, index) => (
        <Box px={3} py={2} key={index}>
          <HStack>
            <Stack w="70%" spacing={2}>
              {columns.map(
                (column, columnIndex) =>
                  column !== lastColumn &&
                  column && (
                    <chakra.div key={columnIndex}>
                      <chakra.div fontWeight="medium">{column.name}</chakra.div>
                      {isParentDataTableColumn(column) ? column.renderCell(data, index) : <Optional />}
                    </chakra.div>
                  ),
              )}
            </Stack>
            <Box w="30%" display="flex" flexDirection="row" justifyContent="flex-end">
              {isParentDataTableColumn(lastColumn) ? lastColumn.renderCell(data, index) : <Optional />}
            </Box>
          </HStack>
        </Box>
      ))}
    </Stack>
  );
}
