/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MailingModuleSettingsDto } from './MailingModuleSettingsDto';
import {
    MailingModuleSettingsDtoFromJSON,
    MailingModuleSettingsDtoFromJSONTyped,
    MailingModuleSettingsDtoToJSON,
} from './MailingModuleSettingsDto';

/**
 * 
 * @export
 * @interface MailingModuleConstraintDto
 */
export interface MailingModuleConstraintDto {
    /**
     * 
     * @type {number}
     * @memberof MailingModuleConstraintDto
     */
    minAmount: number;
    /**
     * 
     * @type {number}
     * @memberof MailingModuleConstraintDto
     */
    maxAmount?: number;
    /**
     * 
     * @type {MailingModuleSettingsDto}
     * @memberof MailingModuleConstraintDto
     */
    defaultSettings: MailingModuleSettingsDto;
}

/**
 * Check if a given object implements the MailingModuleConstraintDto interface.
 */
export function instanceOfMailingModuleConstraintDto(value: object): boolean {
    if (!('minAmount' in value)) return false;
    if (!('defaultSettings' in value)) return false;
    return true;
}

export function MailingModuleConstraintDtoFromJSON(json: any): MailingModuleConstraintDto {
    return MailingModuleConstraintDtoFromJSONTyped(json, false);
}

export function MailingModuleConstraintDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingModuleConstraintDto {
    if (json == null) {
        return json;
    }
    return {
        
        'minAmount': json['minAmount'],
        'maxAmount': json['maxAmount'] == null ? undefined : json['maxAmount'],
        'defaultSettings': MailingModuleSettingsDtoFromJSON(json['defaultSettings']),
    };
}

export function MailingModuleConstraintDtoToJSON(value?: MailingModuleConstraintDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'minAmount': value['minAmount'],
        'maxAmount': value['maxAmount'],
        'defaultSettings': MailingModuleSettingsDtoToJSON(value['defaultSettings']),
    };
}

