/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateTimeRangeDto } from './ClosedDateTimeRangeDto';
import {
    ClosedDateTimeRangeDtoFromJSON,
    ClosedDateTimeRangeDtoFromJSONTyped,
    ClosedDateTimeRangeDtoToJSON,
} from './ClosedDateTimeRangeDto';
import type { EventReferenceDto } from './EventReferenceDto';
import {
    EventReferenceDtoFromJSON,
    EventReferenceDtoFromJSONTyped,
    EventReferenceDtoToJSON,
} from './EventReferenceDto';

/**
 * Element in a rundown calendar
 * @export
 * @interface RundownCalendarElementDto
 */
export interface RundownCalendarElementDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof RundownCalendarElementDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RundownCalendarElementDto
     */
    title: string;
    /**
     * 
     * @type {ClosedDateTimeRangeDto}
     * @memberof RundownCalendarElementDto
     */
    dateTimeRange: ClosedDateTimeRangeDto;
    /**
     * 
     * @type {EventReferenceDto}
     * @memberof RundownCalendarElementDto
     */
    event?: EventReferenceDto;
}

/**
 * Check if a given object implements the RundownCalendarElementDto interface.
 */
export function instanceOfRundownCalendarElementDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('title' in value)) return false;
    if (!('dateTimeRange' in value)) return false;
    return true;
}

export function RundownCalendarElementDtoFromJSON(json: any): RundownCalendarElementDto {
    return RundownCalendarElementDtoFromJSONTyped(json, false);
}

export function RundownCalendarElementDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RundownCalendarElementDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'dateTimeRange': ClosedDateTimeRangeDtoFromJSON(json['dateTimeRange']),
        'event': json['event'] == null ? undefined : EventReferenceDtoFromJSON(json['event']),
    };
}

export function RundownCalendarElementDtoToJSON(value?: RundownCalendarElementDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
        'dateTimeRange': ClosedDateTimeRangeDtoToJSON(value['dateTimeRange']),
        'event': EventReferenceDtoToJSON(value['event']),
    };
}

