/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccountingPostalAddressDto } from './AccountingPostalAddressDto';
import {
    AccountingPostalAddressDtoFromJSON,
    AccountingPostalAddressDtoFromJSONTyped,
    AccountingPostalAddressDtoToJSON,
} from './AccountingPostalAddressDto';
import type { CompanyReferenceDto } from './CompanyReferenceDto';
import {
    CompanyReferenceDtoFromJSON,
    CompanyReferenceDtoFromJSONTyped,
    CompanyReferenceDtoToJSON,
} from './CompanyReferenceDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';

/**
 * 
 * @export
 * @interface AccountingRecipientDto
 */
export interface AccountingRecipientDto {
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof AccountingRecipientDto
     */
    person?: PersonReferenceDto;
    /**
     * 
     * @type {CompanyReferenceDto}
     * @memberof AccountingRecipientDto
     */
    company?: CompanyReferenceDto;
    /**
     * 
     * @type {AccountingPostalAddressDto}
     * @memberof AccountingRecipientDto
     */
    postalAddress?: AccountingPostalAddressDto;
}

/**
 * Check if a given object implements the AccountingRecipientDto interface.
 */
export function instanceOfAccountingRecipientDto(value: object): boolean {
    return true;
}

export function AccountingRecipientDtoFromJSON(json: any): AccountingRecipientDto {
    return AccountingRecipientDtoFromJSONTyped(json, false);
}

export function AccountingRecipientDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountingRecipientDto {
    if (json == null) {
        return json;
    }
    return {
        
        'person': json['person'] == null ? undefined : PersonReferenceDtoFromJSON(json['person']),
        'company': json['company'] == null ? undefined : CompanyReferenceDtoFromJSON(json['company']),
        'postalAddress': json['postalAddress'] == null ? undefined : AccountingPostalAddressDtoFromJSON(json['postalAddress']),
    };
}

export function AccountingRecipientDtoToJSON(value?: AccountingRecipientDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'person': PersonReferenceDtoToJSON(value['person']),
        'company': CompanyReferenceDtoToJSON(value['company']),
        'postalAddress': AccountingPostalAddressDtoToJSON(value['postalAddress']),
    };
}

