/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The industry, within the company operates.
 * @export
 */
export const IndustryDto = {
    CASTING: 'CASTING',
    COACHING: 'COACHING',
    CONSULTING: 'CONSULTING',
    CULTURAL_INSTITUTION: 'CULTURAL_INSTITUTION',
    DISTRIBUTION: 'DISTRIBUTION',
    FILM_ARCHIVE: 'FILM_ARCHIVE',
    FILM_COMMISSION: 'FILM_COMMISSION',
    FILM_FESTIVAL: 'FILM_FESTIVAL',
    FILM_FUND: 'FILM_FUND',
    FILM_INSTITUTE: 'FILM_INSTITUTE',
    FILM_MARKET: 'FILM_MARKET',
    FILM_SCHOOL: 'FILM_SCHOOL',
    LAW_FIRM: 'LAW_FIRM',
    MARKETING_PR_AGENCY: 'MARKETING_PR_AGENCY',
    MEDIA: 'MEDIA',
    NGO: 'NGO',
    OTHER: 'OTHER',
    POST_PRODUCTION: 'POST_PRODUCTION',
    PRODUCTION: 'PRODUCTION',
    PRESS_COVERAGE: 'PRESS_COVERAGE',
    PRESS_COVERAGE_PRODUCTION_COMPANY: 'PRESS_COVERAGE_PRODUCTION_COMPANY',
    PRESS_COVERAGE_NEWS_AGENCY: 'PRESS_COVERAGE_NEWS_AGENCY',
    PROFESSIONAL_ASSOCIATION: 'PROFESSIONAL_ASSOCIATION',
    PUBLIC_INSTITUTION: 'PUBLIC_INSTITUTION',
    PUBLISHER: 'PUBLISHER',
    SALES: 'SALES',
    STARTUP: 'STARTUP',
    STUDIO: 'STUDIO',
    TALENT_AGENCY: 'TALENT_AGENCY',
    TECHNICAL_SERVICES: 'TECHNICAL_SERVICES',
    TELEVISION: 'TELEVISION',
    THEATRICAL_EXHIBITION: 'THEATRICAL_EXHIBITION',
    VOD_AGGREGATOR: 'VOD_AGGREGATOR',
    VOD_PLATFORM: 'VOD_PLATFORM'
} as const;
export type IndustryDto = typeof IndustryDto[keyof typeof IndustryDto];


export function IndustryDtoFromJSON(json: any): IndustryDto {
    return IndustryDtoFromJSONTyped(json, false);
}

export function IndustryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndustryDto {
    return json as IndustryDto;
}

export function IndustryDtoToJSON(value?: IndustryDto | null): any {
    return value as any;
}

