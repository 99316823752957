import useSWR from 'swr';
import invariant from 'tiny-invariant';
import locationApi from '../../../data-access/location-api';

export function getLocationCacheKey(locationId: string) {
  return ['location', locationId];
}

export default function useLocation(locationId: string) {
  const { data } = useSWR(getLocationCacheKey(locationId), () => locationApi.fetchLocation({ id: locationId }), {
    suspense: true,
  });
  invariant(data != null);

  return data;
}
