import React from 'react';
import { useTranslation } from 'react-i18next';
import { ParticipationReferenceDto } from '../../../api';
import { participationIcon } from '../../../ui/icons/business-objects';
import Optional from '../../../ui/optional/optional';
import Reference from '../../../ui/reference/reference';

export interface ParticipationReferenceProps {
  participationReference: ParticipationReferenceDto | undefined;
  size?: 'sm' | 'md';
}

export default function ParticipationReference({ participationReference, size = 'md' }: ParticipationReferenceProps) {
  const { t } = useTranslation('participation');
  const name = participationReference
    ? t('participationFormat', {
        firstName: participationReference.person.firstName,
        surname: participationReference.person.surname,
        edition: participationReference.edition.name,
      })
    : '';

  return (
    <Optional>
      {participationReference != null && (
        <Reference
          icon={participationIcon}
          size={size}
          to={`/persons/${participationReference.person.id}/participations/${participationReference.id}`}
          necessaryPermission="PARTICIPATION:CAN_SEE_DETAILS"
        >
          {name}
        </Reference>
      )}
    </Optional>
  );
}
