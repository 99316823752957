/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryReferenceDto } from './CountryReferenceDto';
import {
    CountryReferenceDtoFromJSON,
    CountryReferenceDtoFromJSONTyped,
    CountryReferenceDtoToJSON,
} from './CountryReferenceDto';

/**
 * 
 * @export
 * @interface PersonForDuplicateDto
 */
export interface PersonForDuplicateDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof PersonForDuplicateDto
     */
    id?: string;
    /**
     * The first name of the person in latin letters
     * @type {string}
     * @memberof PersonForDuplicateDto
     */
    firstName?: string;
    /**
     * The surname of the person in latin letters
     * @type {string}
     * @memberof PersonForDuplicateDto
     */
    surname?: string;
    /**
     * The unique key for a person starting with "P".
     * @type {string}
     * @memberof PersonForDuplicateDto
     */
    personKey?: string;
    /**
     * 
     * @type {Date}
     * @memberof PersonForDuplicateDto
     */
    dateOfBirth?: Date;
    /**
     * List of nationalities of the person
     * @type {Array<CountryReferenceDto>}
     * @memberof PersonForDuplicateDto
     */
    nationalities?: Array<CountryReferenceDto>;
}

/**
 * Check if a given object implements the PersonForDuplicateDto interface.
 */
export function instanceOfPersonForDuplicateDto(value: object): boolean {
    return true;
}

export function PersonForDuplicateDtoFromJSON(json: any): PersonForDuplicateDto {
    return PersonForDuplicateDtoFromJSONTyped(json, false);
}

export function PersonForDuplicateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonForDuplicateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'surname': json['surname'] == null ? undefined : json['surname'],
        'personKey': json['personKey'] == null ? undefined : json['personKey'],
        'dateOfBirth': json['dateOfBirth'] == null ? undefined : (new Date(json['dateOfBirth'])),
        'nationalities': json['nationalities'] == null ? undefined : ((json['nationalities'] as Array<any>).map(CountryReferenceDtoFromJSON)),
    };
}

export function PersonForDuplicateDtoToJSON(value?: PersonForDuplicateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'firstName': value['firstName'],
        'surname': value['surname'],
        'personKey': value['personKey'],
        'dateOfBirth': value['dateOfBirth'] == null ? undefined : ((value['dateOfBirth']).toISOString().substring(0,10)),
        'nationalities': value['nationalities'] == null ? undefined : ((value['nationalities'] as Array<any>).map(CountryReferenceDtoToJSON)),
    };
}

