/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccessGroupReferenceDto } from './AccessGroupReferenceDto';
import {
    AccessGroupReferenceDtoFromJSON,
    AccessGroupReferenceDtoFromJSONTyped,
    AccessGroupReferenceDtoToJSON,
} from './AccessGroupReferenceDto';
import type { ProductBundleReferenceDto } from './ProductBundleReferenceDto';
import {
    ProductBundleReferenceDtoFromJSON,
    ProductBundleReferenceDtoFromJSONTyped,
    ProductBundleReferenceDtoToJSON,
} from './ProductBundleReferenceDto';

/**
 * 
 * @export
 * @interface OrderProductBundleViewerItemDto
 */
export interface OrderProductBundleViewerItemDto {
    /**
     * 
     * @type {ProductBundleReferenceDto}
     * @memberof OrderProductBundleViewerItemDto
     */
    reference: ProductBundleReferenceDto;
    /**
     * 
     * @type {Array<AccessGroupReferenceDto>}
     * @memberof OrderProductBundleViewerItemDto
     */
    accessGroups: Array<AccessGroupReferenceDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderProductBundleViewerItemDto
     */
    products: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderProductBundleViewerItemDto
     */
    excludedCombinations: Array<string>;
}

/**
 * Check if a given object implements the OrderProductBundleViewerItemDto interface.
 */
export function instanceOfOrderProductBundleViewerItemDto(value: object): boolean {
    if (!('reference' in value)) return false;
    if (!('accessGroups' in value)) return false;
    if (!('products' in value)) return false;
    if (!('excludedCombinations' in value)) return false;
    return true;
}

export function OrderProductBundleViewerItemDtoFromJSON(json: any): OrderProductBundleViewerItemDto {
    return OrderProductBundleViewerItemDtoFromJSONTyped(json, false);
}

export function OrderProductBundleViewerItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderProductBundleViewerItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'reference': ProductBundleReferenceDtoFromJSON(json['reference']),
        'accessGroups': ((json['accessGroups'] as Array<any>).map(AccessGroupReferenceDtoFromJSON)),
        'products': json['products'],
        'excludedCombinations': json['excludedCombinations'],
    };
}

export function OrderProductBundleViewerItemDtoToJSON(value?: OrderProductBundleViewerItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'reference': ProductBundleReferenceDtoToJSON(value['reference']),
        'accessGroups': ((value['accessGroups'] as Array<any>).map(AccessGroupReferenceDtoToJSON)),
        'products': value['products'],
        'excludedCombinations': value['excludedCombinations'],
    };
}

