/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateRangeDto } from './ClosedDateRangeDto';
import {
    ClosedDateRangeDtoFromJSON,
    ClosedDateRangeDtoFromJSONTyped,
    ClosedDateRangeDtoToJSON,
} from './ClosedDateRangeDto';
import type { ModeOfTransportDto } from './ModeOfTransportDto';
import {
    ModeOfTransportDtoFromJSON,
    ModeOfTransportDtoFromJSONTyped,
    ModeOfTransportDtoToJSON,
} from './ModeOfTransportDto';

/**
 * 
 * @export
 * @interface ParticipationTripsInnerDto
 */
export interface ParticipationTripsInnerDto {
    /**
     * 
     * @type {ClosedDateRangeDto}
     * @memberof ParticipationTripsInnerDto
     */
    duration: ClosedDateRangeDto;
    /**
     * 
     * @type {ModeOfTransportDto}
     * @memberof ParticipationTripsInnerDto
     */
    arrivalModeOfTransport?: ModeOfTransportDto;
    /**
     * 
     * @type {ModeOfTransportDto}
     * @memberof ParticipationTripsInnerDto
     */
    departureModeOfTransport?: ModeOfTransportDto;
    /**
     * 
     * @type {Date}
     * @memberof ParticipationTripsInnerDto
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the ParticipationTripsInnerDto interface.
 */
export function instanceOfParticipationTripsInnerDto(value: object): boolean {
    if (!('duration' in value)) return false;
    if (!('createdAt' in value)) return false;
    return true;
}

export function ParticipationTripsInnerDtoFromJSON(json: any): ParticipationTripsInnerDto {
    return ParticipationTripsInnerDtoFromJSONTyped(json, false);
}

export function ParticipationTripsInnerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipationTripsInnerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'duration': ClosedDateRangeDtoFromJSON(json['duration']),
        'arrivalModeOfTransport': json['arrivalModeOfTransport'] == null ? undefined : ModeOfTransportDtoFromJSON(json['arrivalModeOfTransport']),
        'departureModeOfTransport': json['departureModeOfTransport'] == null ? undefined : ModeOfTransportDtoFromJSON(json['departureModeOfTransport']),
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function ParticipationTripsInnerDtoToJSON(value?: ParticipationTripsInnerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'duration': ClosedDateRangeDtoToJSON(value['duration']),
        'arrivalModeOfTransport': ModeOfTransportDtoToJSON(value['arrivalModeOfTransport']),
        'departureModeOfTransport': ModeOfTransportDtoToJSON(value['departureModeOfTransport']),
        'createdAt': ((value['createdAt']).toISOString()),
    };
}

