/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StaffAccountReferenceDto } from './StaffAccountReferenceDto';
import {
    StaffAccountReferenceDtoFromJSON,
    StaffAccountReferenceDtoFromJSONTyped,
    StaffAccountReferenceDtoToJSON,
} from './StaffAccountReferenceDto';

/**
 * A Lock on a business object prevents from unwanted editing of the object
 * @export
 * @interface LockDto
 */
export interface LockDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof LockDto
     */
    id: string;
    /**
     * 
     * @type {StaffAccountReferenceDto}
     * @memberof LockDto
     */
    userReference: StaffAccountReferenceDto;
    /**
     * 
     * @type {Date}
     * @memberof LockDto
     */
    expiryDate: Date;
}

/**
 * Check if a given object implements the LockDto interface.
 */
export function instanceOfLockDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('userReference' in value)) return false;
    if (!('expiryDate' in value)) return false;
    return true;
}

export function LockDtoFromJSON(json: any): LockDto {
    return LockDtoFromJSONTyped(json, false);
}

export function LockDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LockDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userReference': StaffAccountReferenceDtoFromJSON(json['userReference']),
        'expiryDate': (new Date(json['expiryDate'])),
    };
}

export function LockDtoToJSON(value?: LockDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'userReference': StaffAccountReferenceDtoToJSON(value['userReference']),
        'expiryDate': ((value['expiryDate']).toISOString()),
    };
}

