import React from 'react';
import { useTranslation } from 'react-i18next';
import { PersonDto, PersonListItemDto } from '../../../api';
import FinalDeleteConfirmDialog, {
  createDefaultDeleteChecklistItem,
} from '../../../ui/dialog/final-delete-confirm-dialog';

export interface PersonDeleteDialogProps {
  person: PersonDto | PersonListItemDto;
  isOpen: boolean;
  onClose(confirm: boolean): void;
}

export default function PersonDeleteDialog({ person, onClose, ...props }: PersonDeleteDialogProps) {
  const { t } = useTranslation('person');

  const confirmationMessageChecklistItems = [
    createDefaultDeleteChecklistItem({
      objectTypeWithArticleGenitive: t('personWithArticle'),
      objectToDelete: t('personFormatWithKey', {
        firstName: person.firstName,
        surname: person.surname,
        personKey: person.personKey,
      }),
    }),
  ];

  return (
    <FinalDeleteConfirmDialog
      {...props}
      objectTypeToDelete={t('person')}
      confirmationCheckListItems={confirmationMessageChecklistItems}
      onClose={onClose}
    />
  );
}
