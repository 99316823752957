import { add, differenceInMinutes, isAfter, isBefore } from 'date-fns';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EventDto } from '../../../api';
import DateInputFormControl from '../../../ui/form/date-input-control/date-input-form-control';

export interface EventDateTimeRangeControlProps {
  editionStart?: Date;
  editionEnd?: Date;
}

/**
 * Represents a form control for dateTimeRange inside [EventForm]{@link import('./event-form').default}
 */
export default function EventDateTimeRangeControl({ editionEnd, editionStart }: EventDateTimeRangeControlProps) {
  const { t } = useTranslation('event');
  const { setValue, trigger, getValues } = useFormContext<EventDto>();

  const [start, setStart] = useState<Date | null>(() => getValues('dateTimeRange.start'));
  const [end, setEnd] = useState<Date | null>(() => getValues('dateTimeRange.end'));
  const [previousStart, setPreviousStart] = useState<Date | null>(start);

  React.useEffect(() => {
    if (start && end) {
      trigger(['dateTimeRange.start', 'dateTimeRange.end']);
    } else if (start) {
      trigger('dateTimeRange.start');
    } else if (end) {
      trigger('dateTimeRange.end');
    }
    // should depend only on edition date range
    // eslint-disable-next-line
  }, [editionStart, editionEnd]);

  const startMinimum = {
    value: editionStart,
    message: t('eventStart.event_start_must_be_after_edition_start', { editionStart }),
  };
  let startMaximum;
  // if there is an end set, the start should be before the end
  // but if the end is after the edition end, the start should be before the edition end
  // that the start is after the edition start is assured by startMinimum
  if (end && (editionEnd ? isBefore(end, editionEnd) : true)) {
    startMaximum = { value: end, message: t('eventStart.max') };
  } else {
    startMaximum = {
      value: editionEnd,
      message: t('eventStart.event_start_must_be_before_edition_end', { editionEnd }),
    };
  }

  const endMaximum = {
    value: editionEnd,
    message: t('eventEnd.event_end_must_be_before_edition_end', { editionEnd }),
  };
  let endMinimum;
  // if there is a start set, the end should be after the start
  // but if the start is before the edition start, the end should be after the edition start
  // that the end is before the edition end is assured by endMaximum
  if (start && (editionStart ? isAfter(start, editionStart) : true)) {
    endMinimum = { value: start, message: t('eventEnd.min') };
  } else {
    endMinimum = {
      value: editionStart,
      message: t('eventEnd.event_end_must_be_after_edition_start', { editionStart }),
    };
  }

  const handleStartBlur = async () => {
    if (start != null) {
      const diffInMinutes = end != null && previousStart != null ? differenceInMinutes(end, previousStart) : 60;
      const nextEnd = add(start, { minutes: diffInMinutes });

      setEnd(nextEnd);
      setValue('dateTimeRange.end', nextEnd);

      await trigger('dateTimeRange.end');
      // We also need to trigger a second validation for the start date as the end date could have changed
      // the maximum value for the start date.
      // This might impact performance due to extra renders, but it's the only way to make sure the validation
      // is correct.
      await trigger('dateTimeRange.start');
    } else {
      await trigger('dateTimeRange.end');
    }

    setPreviousStart(start);
  };

  const handleEndBlur = () => {
    trigger('dateTimeRange.start');
  };

  return (
    <>
      <DateInputFormControl
        label={t('eventStart.label')}
        name="dateTimeRange.start"
        helperText={t('eventStart.helper_text', {
          dateRange: {
            start: editionStart,
            end: editionEnd,
          },
        })}
        isRequired
        requiredLabel={t('eventStart.required', {
          dateRange: {
            start: editionStart,
            end: editionEnd,
          },
        })}
        onChange={setStart}
        onBlur={handleStartBlur}
        min={startMinimum}
        max={startMaximum}
        showTimeSelect
        referenceDate={editionStart}
        minDatePicker={editionStart}
        maxDatePicker={editionEnd}
      />

      <DateInputFormControl
        label={t('eventEnd.label')}
        name="dateTimeRange.end"
        helperText={t('eventEnd.helper_text', {
          dateRange: {
            start: editionStart,
            end: editionEnd,
          },
        })}
        isRequired
        onChange={setEnd}
        onBlur={handleEndBlur}
        requiredLabel={t('eventEnd.required', {
          dateRange: {
            start: editionStart,
            end: editionEnd,
          },
        })}
        min={endMinimum}
        max={endMaximum}
        showTimeSelect
        referenceDate={editionEnd}
        minDatePicker={editionStart}
        maxDatePicker={editionEnd}
      />
    </>
  );
}
