import { FindLockRequest } from '../../api';
import lockApi from '../../data-access/lock-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';

export const lockFetcher = createFetcherScope(lockApi);
export const findLock = lockFetcher.createFetcher(async ({ id, ...request }: FindLockRequest | { id: undefined }) => {
  if (id == null) {
    return null;
  }

  try {
    return await lockApi.findLock({ id, ...request });
  } catch (_) {
    return null;
  }
});
