import { addDays, differenceInMinutes, endOfDay, format, startOfDay } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT_STRING } from '../../ui/date-input/date-input';
import useToast from '../../ui/use-toast/use-toast';
import now from '../../util/now';
import useActiveEdition from '../edition/use-active-edition/use-active-edition';
import useNextActiveEdition from '../edition/use-active-edition/use-next-active-edition';

export default function EditionEndHint() {
  const { t } = useTranslation('edition');
  const activeEdition = useActiveEdition();
  const nextActiveEdition = useNextActiveEdition();
  const tomorrow = useTomorrow();
  const toastId = 'edition-ends-soon-toast';

  const toast = useToast({
    id: toastId,
    status: 'info',
    duration: null,
  });

  React.useEffect(() => {
    const editionEndsSoon = Math.abs(differenceInMinutes(endOfDay(activeEdition.dateRange.end), now())) < 1440;

    if (editionEndsSoon && !toast.isActive(toastId)) {
      toast({
        title: t('ends_soon_hint_title'),
        description: t('ends_soon_hint_text', {
          startDate: format(tomorrow, DATE_FORMAT_STRING),
          edition: nextActiveEdition.name,
        }),
      });
    }
  }, [activeEdition, nextActiveEdition, t, toast, tomorrow]);

  //On component unmount
  React.useEffect(() => {
    return () => {
      toast.close(toastId);
    };
  }, [toast]);

  return null;
}

function useTomorrow() {
  const [tomorrow, setTomorrow] = React.useState(() => startOfDay(addDays(now(), 1)));

  React.useEffect(() => {
    const intervalRef = setInterval(() => {
      setTomorrow((prevTomorrow) => {
        const nextTomorrow = startOfDay(addDays(now(), 1));

        if (prevTomorrow.valueOf() === nextTomorrow.valueOf()) {
          return prevTomorrow;
        }

        return nextTomorrow;
      });
    }, 60000);

    return () => clearInterval(intervalRef);
  }, []);

  return tomorrow;
}
