import { GroupBase, Props } from 'chakra-react-select';
import { differenceWith, intersectionWith, isEqual } from 'lodash-es';
import React from 'react';
import Select from './select';
import useOptionStringValue from './use-option-string-value';
import useSortedOptions from './use-sorted-options';

export interface MultiValueSelectProps<
  TOption,
  TGroup extends GroupBase<{ value: TOption; label: React.ReactNode }> = GroupBase<{
    value: TOption;
    label: React.ReactNode;
  }>,
> extends Omit<
    Props<{ value: TOption; label: React.ReactNode }, true, TGroup>,
    'onChange' | 'value' | 'options' | 'isMulti' | 'getOptionValue' | 'getOptionLabel'
  > {
  options: TOption[];
  renderLabel: (value: TOption) => React.ReactNode;
  getStringValue?: (value: TOption) => string;
  // selected Option is undefined for clear action etc.
  onChange: (value: TOption[], selectedOption?: TOption) => void;
  values: TOption[];
}

function MultiValueSelect<T>(
  { options, renderLabel, getStringValue, values, onChange, ...props }: MultiValueSelectProps<T>,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const valueOptions = options.map((value) => ({ value, label: renderLabel(value) }));
  const selectedOption = valueOptions.filter((option) => differenceWith([option.value], values, isEqual).length === 0);

  props = useSortedOptions({
    ...useOptionStringValue(props, getStringValue),
    options: valueOptions,
  });

  return (
    <Select
      {...props}
      isMulti
      value={selectedOption}
      formatOptionLabel={(option) => option.label}
      onChange={(selectedOptions, action) => {
        onChange(
          //lh, vb: selected options should be added in the same order as they are presented in dropdown
          intersectionWith(
            options,
            selectedOptions.map((option) => option.value),
            isEqual,
          ),
          action.option?.value,
        );
      }}
      ref={ref}
    />
  );
}

export default React.forwardRef(MultiValueSelect);
