/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocationTagPermissionDto
 */
export interface LocationTagPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof LocationTagPermissionDto
     */
    name: LocationTagPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof LocationTagPermissionDto
     */
    action: LocationTagPermissionDtoActionEnum;
}


/**
 * @export
 */
export const LocationTagPermissionDtoNameEnum = {
    LOCATION_TAG: 'LOCATION-TAG'
} as const;
export type LocationTagPermissionDtoNameEnum = typeof LocationTagPermissionDtoNameEnum[keyof typeof LocationTagPermissionDtoNameEnum];

/**
 * @export
 */
export const LocationTagPermissionDtoActionEnum = {
    LINK: 'CAN_LINK',
    UNLINK: 'CAN_UNLINK'
} as const;
export type LocationTagPermissionDtoActionEnum = typeof LocationTagPermissionDtoActionEnum[keyof typeof LocationTagPermissionDtoActionEnum];


/**
 * Check if a given object implements the LocationTagPermissionDto interface.
 */
export function instanceOfLocationTagPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function LocationTagPermissionDtoFromJSON(json: any): LocationTagPermissionDto {
    return LocationTagPermissionDtoFromJSONTyped(json, false);
}

export function LocationTagPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationTagPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function LocationTagPermissionDtoToJSON(value?: LocationTagPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

