import { Box, Button, useColorModeValue } from '@chakra-ui/react';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditionDto } from '../../api';
import EditionSelect from './edition-select/edition-select';

interface EditionSelectButtonProps {
  edition: EditionDto;
  onChange(edition: EditionDto): void;
  buttonLabel: React.ReactNode;
}

export default function EditionSelectButton({ edition, buttonLabel, onChange }: EditionSelectButtonProps) {
  const borderColor = useColorModeValue('blackAlpha.200', 'whiteAlpha.300');
  const { t } = useTranslation('edition');
  const [changeEdition, setChangeEdition] = React.useState(false);
  const editionSelectRef = React.useRef<HTMLInputElement>(null);

  if (changeEdition) {
    return (
      <Box
        as={EditionSelect}
        aria-label={t('edition')}
        menuIsOpen
        value={edition}
        width="3xs"
        autoFocus
        onChange={(edition: any) => {
          setChangeEdition(false);
          onChange(edition);
        }}
        onBlur={() => {
          setChangeEdition(false);
        }}
        components={{
          // Remove the dropdown indicator as the dropdown is always open
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
        }}
      />
    );
  }

  return (
    <Button
      onClick={() => {
        setChangeEdition(true);
        editionSelectRef.current?.focus();
      }}
      rightIcon={<FontAwesomeIcon icon={faChevronDown} />}
      sx={{
        bgColor: 'transparent',
        border: '1px solid',
        borderColor: borderColor,
      }}
    >
      {buttonLabel}
    </Button>
  );
}
