import { faBold } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { ReactEditor } from 'slate-react';
import { isBlockActive } from '../block';
import { EditorProps, HotkeyAction, MarkFormatOption } from '../format-types';
import { selectionIsInline } from '../inline';
import { isMarkActive, toggleMark } from '../mark';
import { Render } from '../render';
import RichTextButton from '../rich-text-button';
import { WithFunction } from './create-editor';

const type: MarkFormatOption = 'bold';

const withBold: (options: { render: Render<'mark'>['render'] }) => WithFunction =
  ({ render }) =>
  (editor) => {
    const renderBold: Render<'mark'> = {
      type,
      render,
    };
    editor.renderers = [...editor.renderers, renderBold];

    const boldToolbarButton = (editor: EditorProps) => (
      <RichTextButton
        key="bold"
        isActive={isMarkActive(editor, type)}
        // only allow bold for paragraph
        isDisabled={(!isBlockActive(editor, 'paragraph') || selectionIsInline(editor)) && editor.selection !== null}
        onClick={(event) => {
          toggleMark(editor, type);
          ReactEditor.focus(editor);
          event.preventDefault();
        }}
        format={type}
        icon={faBold}
      />
    );
    editor.toolbarButtons = [...editor.toolbarButtons, boldToolbarButton];

    const hotkey: HotkeyAction = { hotkey: 'mod+b', action: () => toggleMark(editor, type) };
    editor.hotkeys = [...editor.hotkeys, hotkey];

    return editor;
  };

export default withBold;
