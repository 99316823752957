/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GroupTemplateDto } from './GroupTemplateDto';
import {
    GroupTemplateDtoFromJSON,
    GroupTemplateDtoFromJSONTyped,
    GroupTemplateDtoToJSON,
} from './GroupTemplateDto';

/**
 * 
 * @export
 * @interface GroupTemplateSearchResultDto
 */
export interface GroupTemplateSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof GroupTemplateSearchResultDto
     */
    resultType: GroupTemplateSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof GroupTemplateSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {GroupTemplateDto}
     * @memberof GroupTemplateSearchResultDto
     */
    result: GroupTemplateDto;
}


/**
 * @export
 */
export const GroupTemplateSearchResultDtoResultTypeEnum = {
    GROUP_TEMPLATE: 'GROUP_TEMPLATE'
} as const;
export type GroupTemplateSearchResultDtoResultTypeEnum = typeof GroupTemplateSearchResultDtoResultTypeEnum[keyof typeof GroupTemplateSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the GroupTemplateSearchResultDto interface.
 */
export function instanceOfGroupTemplateSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function GroupTemplateSearchResultDtoFromJSON(json: any): GroupTemplateSearchResultDto {
    return GroupTemplateSearchResultDtoFromJSONTyped(json, false);
}

export function GroupTemplateSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupTemplateSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': GroupTemplateDtoFromJSON(json['result']),
    };
}

export function GroupTemplateSearchResultDtoToJSON(value?: GroupTemplateSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': GroupTemplateDtoToJSON(value['result']),
    };
}

