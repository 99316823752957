import { Flex, IconButton } from '@chakra-ui/react';
import { DraggableAttributes, DraggableSyntheticListeners } from '@dnd-kit/core';
import { faGripDots } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';
import { ComponentPropsWithoutRef, ElementRef, ForwardedRef, forwardRef, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface DraggableItemProp extends ComponentPropsWithoutRef<'div'> {
  attributes?: DraggableAttributes;
  listeners?: DraggableSyntheticListeners;
  isDragging?: boolean;
  index?: number;
}

const DraggableItem = forwardRef(
  (
    { attributes, listeners, children, isDragging, ...props }: DraggableItemProp,
    ref: ForwardedRef<ElementRef<'div'>>,
  ) => {
    const { t } = useTranslation('common');

    return (
      <Flex
        ref={ref}
        {...props}
        alignItems="stretch"
        className={clsx({ 'is-dragging': isDragging })}
        sx={{
          bg: 'layer.01',
          mt: '-1px',
          borderBottom: '1px solid',
          borderTop: '1px solid',
          borderColor: 'border.01',
          '&.is-dragging': {
            boxShadow: 'md',
            borderRadius: 'base',
            border: '1px solid',
            borderColor: 'border.01',
          },
          '&:first-of-type': {
            borderTopLeftRadius: 'base',
            borderTopRightRadius: 'base',
          },
        }}
      >
        <IconButton
          icon={<FontAwesomeIcon icon={faGripDots} />}
          variant="solid"
          aria-label={t('sortable.moveElement')}
          sx={{ borderRadius: '0', h: 'auto', borderTopLeftRadius: 'inherit' }}
          data-active={isDragging}
          {...attributes}
          {...listeners}
        />
        {children as ReactNode}
      </Flex>
    );
  },
);

export default DraggableItem;
