/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { StandPersonRelationTypeDto } from './StandPersonRelationTypeDto';
import {
    StandPersonRelationTypeDtoFromJSON,
    StandPersonRelationTypeDtoFromJSONTyped,
    StandPersonRelationTypeDtoToJSON,
} from './StandPersonRelationTypeDto';
import type { StandReferenceDto } from './StandReferenceDto';
import {
    StandReferenceDtoFromJSON,
    StandReferenceDtoFromJSONTyped,
    StandReferenceDtoToJSON,
} from './StandReferenceDto';

/**
 * The list item for a relation between a stand and a person.
 * @export
 * @interface StandPersonListItemDto
 */
export interface StandPersonListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof StandPersonListItemDto
     */
    id: string;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof StandPersonListItemDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {StandReferenceDto}
     * @memberof StandPersonListItemDto
     */
    stand: StandReferenceDto;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof StandPersonListItemDto
     */
    person: PersonReferenceDto;
    /**
     * 
     * @type {StandPersonRelationTypeDto}
     * @memberof StandPersonListItemDto
     */
    relationType: StandPersonRelationTypeDto;
}

/**
 * Check if a given object implements the StandPersonListItemDto interface.
 */
export function instanceOfStandPersonListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('stand' in value)) return false;
    if (!('person' in value)) return false;
    if (!('relationType' in value)) return false;
    return true;
}

export function StandPersonListItemDtoFromJSON(json: any): StandPersonListItemDto {
    return StandPersonListItemDtoFromJSONTyped(json, false);
}

export function StandPersonListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandPersonListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'stand': StandReferenceDtoFromJSON(json['stand']),
        'person': PersonReferenceDtoFromJSON(json['person']),
        'relationType': StandPersonRelationTypeDtoFromJSON(json['relationType']),
    };
}

export function StandPersonListItemDtoToJSON(value?: StandPersonListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'stand': StandReferenceDtoToJSON(value['stand']),
        'person': PersonReferenceDtoToJSON(value['person']),
        'relationType': StandPersonRelationTypeDtoToJSON(value['relationType']),
    };
}

