/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { JwkDto } from './JwkDto';
import {
    JwkDtoFromJSON,
    JwkDtoFromJSONTyped,
    JwkDtoToJSON,
} from './JwkDto';

/**
 * JWK set with public key.
 * @export
 * @interface JwkSetDto
 */
export interface JwkSetDto {
    /**
     * 
     * @type {Array<JwkDto>}
     * @memberof JwkSetDto
     */
    keys: Array<JwkDto>;
}

/**
 * Check if a given object implements the JwkSetDto interface.
 */
export function instanceOfJwkSetDto(value: object): boolean {
    if (!('keys' in value)) return false;
    return true;
}

export function JwkSetDtoFromJSON(json: any): JwkSetDto {
    return JwkSetDtoFromJSONTyped(json, false);
}

export function JwkSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JwkSetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'keys': ((json['keys'] as Array<any>).map(JwkDtoFromJSON)),
    };
}

export function JwkSetDtoToJSON(value?: JwkSetDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'keys': ((value['keys'] as Array<any>).map(JwkDtoToJSON)),
    };
}

