import { useDataTableState } from '../../../ui/data-table';
import useRequestParams from '../../../ui/data-table/use-request-params';
import fallbackMiddleware from '../../../util/swr/fallback-middleware';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchRecipients } from '../mailing-queries';

export default function useRecipientLister(mailingId: string) {
  const [state, setState] = useDataTableState();
  const recipientPage = useFetcher(
    fetchRecipients,
    { id: mailingId, ...useRequestParams(state) },
    { use: [fallbackMiddleware] },
  );

  return { state, setState, recipientPage };
}
