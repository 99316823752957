import React from 'react';
import { useTranslation } from 'react-i18next';
import Result from '../result/result';

export default function BadRequest() {
  const { t } = useTranslation(['common'], { useSuspense: false });

  return (
    <Result
      status="error"
      title={t('common:result.bad_request.title')}
      description={t('result.bad_request.description')}
    />
  );
}
