import { faH1 } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { ReactEditor } from 'slate-react';
import { isBlockActive, toggleBlock } from '../block';
import { BlockFormatOption, EditorProps, Heading1Element } from '../format-types';
import { Render } from '../render';
import RichTextButton from '../rich-text-button';
import { WithFunction } from './create-editor';

const format: BlockFormatOption = 'heading1';

const withH1: (options: { render: Render<'block'>['render'] }) => WithFunction =
  ({ render }) =>
  (editor, { paragraphPresent, onlyOneBlockOption }) => {
    const h1Style: Render<'block'> = { type: format, render };
    editor.renderers = [...editor.renderers, h1Style];

    if (!onlyOneBlockOption) {
      const h1ToolbarButton = (editor: EditorProps) => (
        <RichTextButton
          key={format}
          isActive={isBlockActive(editor, format)}
          onClick={(event) => {
            toggleBlock<Heading1Element>(editor, format, paragraphPresent ? 'paragraph' : undefined);
            ReactEditor.focus(editor);
            event.preventDefault();
          }}
          format={format}
          icon={faH1}
        />
      );
      editor.toolbarButtons = [h1ToolbarButton, ...editor.toolbarButtons];
    }

    return editor;
  };

export default withH1;
