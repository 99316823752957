import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { TagRelationTypeDto } from '../../../../api';
import eventApi from '../../../../data-access/event-api';
import { BreadcrumbRouteObject } from '../../../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../../../ui/helmet/helmet-outlet';
import { eventIcon } from '../../../../ui/icons/business-objects';
import useFetcher from '../../../../util/swr/use-fetcher';
import usePromise from '../../../../util/use-promise/use-promise';
import EventPage from '../../../event/event-page/event-page';

import { fetchEvent } from '../../../event/event-queries';
import usePermission from '../../../permission/use-permission';

const EventTagRelationContent = React.lazy(() => import('../tag-relation-page-content'));

const eventTagRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: '',
    element: <EventPage />,
    children: [
      {
        path: 'tags',
        element: <EventTagContent />,
        handle: {
          helmet: <EventTagsHelmet />,
        },
      },
    ],
  },
];

export default eventTagRoutes;

function EventTagContent() {
  const relationType = TagRelationTypeDto.EVENT;
  const params = useParams<{ eventId: string }>();
  const eventId = params.eventId;
  invariant(eventId, 'Empty eventId');

  const event = usePromise((signal) => eventApi.fetchEvent({ id: eventId }, { signal }), [eventId]);
  const eventRef = {
    id: eventId,
    title: event?.title,
    englishTitle: event?.englishTitle,
    edition: event?.edition,
    dateTimeRange: event?.dateTimeRange,
    status: event?.status,
  };

  const fromAnnotation = eventRef.title!;

  const { hasPermission } = usePermission();

  return (
    <EventTagRelationContent
      relationRef={eventRef}
      relationType={relationType}
      fromIcon={eventIcon}
      fromAnnotation={fromAnnotation}
      canLink={hasPermission('EVENT-TAG:CAN_LINK')}
      canUnlink={hasPermission('EVENT-TAG:CAN_UNLINK')}
    />
  );
}

function EventTagsHelmet() {
  const { t } = useTranslation('tag');
  const params = useParams<{ eventId: string }>();
  invariant(params.eventId, 'Empty eventId');
  const event = useFetcher(fetchEvent, { id: params.eventId });

  return (
    <Helmet
      title={t('relations.event.helmet', {
        name: event.title,
      })}
    />
  );
}
