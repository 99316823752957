/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HistoryEntryCreatedFromMergeOfDto } from './HistoryEntryCreatedFromMergeOfDto';
import {
    HistoryEntryCreatedFromMergeOfDtoFromJSON,
    HistoryEntryCreatedFromMergeOfDtoFromJSONTyped,
    HistoryEntryCreatedFromMergeOfDtoToJSON,
} from './HistoryEntryCreatedFromMergeOfDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * The history entry for one version as returned for a history.
 * @export
 * @interface HistoryEntryDto
 */
export interface HistoryEntryDto {
    /**
     * 
     * @type {VersionDto}
     * @memberof HistoryEntryDto
     */
    version: VersionDto;
    /**
     * 
     * @type {any}
     * @memberof HistoryEntryDto
     */
    before?: any;
    /**
     * 
     * @type {any}
     * @memberof HistoryEntryDto
     */
    after?: any;
    /**
     * 
     * @type {HistoryEntryCreatedFromMergeOfDto}
     * @memberof HistoryEntryDto
     */
    createdFromMergeOf?: HistoryEntryCreatedFromMergeOfDto;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof HistoryEntryDto
     */
    mergedInto?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryEntryDto
     */
    displayName?: string;
}

/**
 * Check if a given object implements the HistoryEntryDto interface.
 */
export function instanceOfHistoryEntryDto(value: object): boolean {
    if (!('version' in value)) return false;
    return true;
}

export function HistoryEntryDtoFromJSON(json: any): HistoryEntryDto {
    return HistoryEntryDtoFromJSONTyped(json, false);
}

export function HistoryEntryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoryEntryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'version': VersionDtoFromJSON(json['version']),
        'before': json['before'] == null ? undefined : json['before'],
        'after': json['after'] == null ? undefined : json['after'],
        'createdFromMergeOf': json['createdFromMergeOf'] == null ? undefined : HistoryEntryCreatedFromMergeOfDtoFromJSON(json['createdFromMergeOf']),
        'mergedInto': json['mergedInto'] == null ? undefined : json['mergedInto'],
        'displayName': json['displayName'] == null ? undefined : json['displayName'],
    };
}

export function HistoryEntryDtoToJSON(value?: HistoryEntryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'version': VersionDtoToJSON(value['version']),
        'before': value['before'],
        'after': value['after'],
        'createdFromMergeOf': HistoryEntryCreatedFromMergeOfDtoToJSON(value['createdFromMergeOf']),
        'mergedInto': value['mergedInto'],
        'displayName': value['displayName'],
    };
}

