import { ParseKeys } from 'i18next/typescript/t';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import useEvent from '../event/use-event/use-event';
import LiveTicketingPublicationLister from './live-ticketing-publication-lister/live-ticketing-publication-lister';

const LiveTicketingPage = React.lazy(() => import('./live-ticketing-page/live-ticketing-page'));
const LiveTicketingViewer = React.lazy(() => import('./live-ticketing-viewer/live-ticketing-viewer'));
const LiveTicketingSettingsViewer = React.lazy(
  () => import('./live-ticketing-settings-viewer/live-ticketing-settings-viewer'),
);
const LiveTicketingSettingsEditor = React.lazy(
  () => import('./live-ticketing-settings-editor/live-ticketing-settings-editor'),
);

const liveTicketingRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'live-ticketing',
    handle: {
      breadcrumb: <Translate ns="live_ticketing" i18nKey="live_ticketing" />,
    },
    children: [
      {
        path: '',
        element: <LiveTicketingPage useFullWidth fixedHeight />,
        children: [
          {
            path: '',
            element: <LiveTicketingViewer />,
            handle: {
              helmet: <LiveTicketingHelmet i18nKey="viewer.helmet" />,
            },
          },
          {
            path: 'publication',
            element: <LiveTicketingPublicationLister />,
            handle: {
              helmet: <LiveTicketingHelmet i18nKey="publication.helmet" />,
            },
          },
        ],
      },
      {
        path: '',
        element: <LiveTicketingPage />,
        children: [
          {
            path: 'settings',
            element: <LiveTicketingSettingsViewer />,
            handle: {
              helmet: <LiveTicketingHelmet i18nKey="settings.helmet" />,
            },
          },
        ],
      },
      {
        path: 'edit',
        handle: {
          breadcrumb: <Translate ns="live_ticketing" i18nKey="settings.edit" />,
          helmet: <LiveTicketingHelmet i18nKey="settings.helmetEdit" />,
        },
        element: <LiveTicketingSettingsEditor />,
      },
    ],
  },
];

export default liveTicketingRoutes;

interface LiveTicketingHelmetProps {
  i18nKey: ParseKeys<'live_ticketing'>;
}

function LiveTicketingHelmet({ i18nKey }: LiveTicketingHelmetProps) {
  const { t } = useTranslation('live_ticketing');
  const params = useParams<{ eventId: string }>();
  invariant(params.eventId, 'Empty eventId');
  const { title } = useEvent(params.eventId);

  return <Helmet title={t(i18nKey, { title })} />;
}
