import { Alert, AlertIcon, Checkbox, useId } from '@chakra-ui/react';
import React from 'react';
import { useController, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EditionDto } from '../../../api';

export default function EditionVisibilityControl() {
  const { t } = useTranslation('edition');
  const id = useId(undefined, 'edition-visibility-control');
  const active = useWatch<EditionDto, 'active'>({ name: 'active' });
  const { field } = useController<EditionDto, 'visible'>({ name: 'visible' });

  return (
    <>
      <Checkbox
        ref={field.ref}
        onChange={field.onChange}
        onBlur={field.onBlur}
        name={field.name}
        isChecked={field.value}
        isDisabled={active}
        aria-describedby={`${id}-warning ${id}-info`}
      >
        {t('editor.visibility_label')}
      </Checkbox>
      {active && (
        <Alert status="info" mt={3} id={`${id}-info`} aria-live="polite">
          <AlertIcon />
          {t('editor.visibility_info_when_active')}
        </Alert>
      )}
      {!field.value && (
        <Alert status="warning" mt={3} id={`${id}-warning`} aria-live="polite">
          <AlertIcon />
          {t('editor.visibility_warning')}
        </Alert>
      )}
    </>
  );
}
