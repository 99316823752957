/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LandingPageRsvpModuleContentDto
 */
export interface LandingPageRsvpModuleContentDto {
    /**
     * 
     * @type {string}
     * @memberof LandingPageRsvpModuleContentDto
     */
    moduleType: string;
    /**
     * 
     * @type {boolean}
     * @memberof LandingPageRsvpModuleContentDto
     */
    commentsAllowed: boolean;
    /**
     * 
     * @type {string}
     * @memberof LandingPageRsvpModuleContentDto
     */
    helperText: string;
}

/**
 * Check if a given object implements the LandingPageRsvpModuleContentDto interface.
 */
export function instanceOfLandingPageRsvpModuleContentDto(value: object): boolean {
    if (!('moduleType' in value)) return false;
    if (!('commentsAllowed' in value)) return false;
    if (!('helperText' in value)) return false;
    return true;
}

export function LandingPageRsvpModuleContentDtoFromJSON(json: any): LandingPageRsvpModuleContentDto {
    return LandingPageRsvpModuleContentDtoFromJSONTyped(json, false);
}

export function LandingPageRsvpModuleContentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageRsvpModuleContentDto {
    if (json == null) {
        return json;
    }
    return {
        
        'moduleType': json['moduleType'],
        'commentsAllowed': json['commentsAllowed'],
        'helperText': json['helperText'],
    };
}

export function LandingPageRsvpModuleContentDtoToJSON(value?: LandingPageRsvpModuleContentDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'moduleType': value['moduleType'],
        'commentsAllowed': value['commentsAllowed'],
        'helperText': value['helperText'],
    };
}

