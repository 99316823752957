import { Stack } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { MailingPortraitModuleDataDto } from '../../../../api';
import { AttachmentImage } from '../../../../ui/attachments/attachment-image';
import { FileSuffixes, FileType } from '../../../../ui/attachments/upload/file-type';
import { DataTableColumn } from '../../../../ui/data-table';
import { ElementFormModal, ElementTableControl, useElementForm } from '../../../../ui/form/element-control';
import AddElementButton from '../../../../ui/form/element-control/add-element-button';
import DeleteElementButton from '../../../../ui/form/element-control/delete-element-button';
import EditElementButton from '../../../../ui/form/element-control/edit-element-button';
import InputFormControl from '../../../../ui/form/input-form-control';
import Translate from '../../../../util/translate/translate';
import { validateUrl } from '../../../../util/url/url-validation';
import ImageControl from '../../../../ui/form/image-control/image-control';

interface PortraitMailingModuleControlProps {
  name: string;
}

export default function PortraitMailingModuleControl({ name }: PortraitMailingModuleControlProps) {
  const { t } = useTranslation('mailing');

  const columns: DataTableColumn<MailingPortraitModuleDataDto>[] = [
    {
      key: 'photo',
      name: '',
      renderCell: (data) => {
        return <AttachmentImage metadata={data.image} />;
      },
    },
    {
      key: 'fileName',
      name: <Translate ns="mailing" i18nKey="portrait_module.file_name" />,
      renderCell: (data) => data.image.originalFileName,
    },
    {
      key: 'name',
      name: <Translate ns="mailing" i18nKey="portrait_module.name" />,
      renderCell: (data) => data.name,
    },
    {
      key: 'jobTitle',
      name: <Translate ns="mailing" i18nKey="portrait_module.jobTitle" />,
      renderCell: (data) => data.jobTitle,
    },
    {
      key: 'jobTitleEnglish',
      name: <Translate ns="mailing" i18nKey="portrait_module.jobTitleEnglish" />,
      renderCell: (data) => data.jobTitleEnglish,
    },
    {
      key: 'linkTarget',
      name: <Translate ns="mailing" i18nKey="portrait_module.link" />,
      renderCell: (data) => data.linkTarget,
    },
  ];

  return (
    <Stack>
      <ElementTableControl
        label={t('portrait_module.label')}
        maxElements={2}
        addButton={<AddElementButton label={t('portrait_module.add_portrait')} formModal={<PortraitFormModal />} />}
        editButton={<EditElementButton label={t('portrait_module.edit_portrait')} formModal={<PortraitFormModal />} />}
        deleteButton={
          <DeleteElementButton
            label={t('portrait_module.delete_portrait')}
            renderDeleteMessage={() => <Trans t={t} i18nKey="portrait_module.delete_message" />}
          />
        }
        name={`${name}.portraits`}
        columns={columns}
      />
    </Stack>
  );
}

function PortraitFormModal() {
  const { t } = useTranslation('mailing');
  const { t: tCommon } = useTranslation('common');
  const { t: tAttachment } = useTranslation('attachment');

  const { element: portraitData, onSubmit } = useElementForm<MailingPortraitModuleDataDto>();

  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  const maxFileSizeInMB = 5;

  const minPhotoDimension = { width: 400, height: 350 };

  const maxPhotoDimension = { width: 4000, height: 3500 };
  // Mail from Manja on the 23th of December 2022 around 3pm. Wish for webp to be available whereever jpeg is available.
  const acceptFileTypes = [FileType.JPEG, FileType.GIF, FileType.PNG, FileType.WEBP];

  const handleAddPortrait = async (MailingPortraitModuleDataDto: MailingPortraitModuleDataDto) => {
    cleanupMailingPortraitModuleDataDto(MailingPortraitModuleDataDto);
    onSubmit(MailingPortraitModuleDataDto);
  };

  function cleanupMailingPortraitModuleDataDto(MailingPortraitModuleDataDto: MailingPortraitModuleDataDto) {
    MailingPortraitModuleDataDto.linkTarget = isEmpty(MailingPortraitModuleDataDto.linkTarget)
      ? undefined
      : MailingPortraitModuleDataDto.linkTarget;
  }

  return (
    <ElementFormModal<MailingPortraitModuleDataDto>
      onSubmit={handleAddPortrait}
      element={portraitData}
      initialFocusRef={initialFocusRef}
      size="2xl"
    >
      <Stack spacing={4}>
        <ImageControl<MailingPortraitModuleDataDto>
          name="image"
          label={t('portrait_module.image')}
          maxFileSizeInMB={maxFileSizeInMB}
          acceptFileTypes={acceptFileTypes}
          namespace="mailing_portrait"
          isRequired
          helperText={
            tAttachment('helper_text.size_and_accepted_types', {
              size_mb: maxFileSizeInMB,
              acceptedFileTypes: acceptFileTypes.flatMap((type) => FileSuffixes[type]),
            }) +
            ' ' +
            tAttachment('helper_text.photo_dimension', {
              minWidth: minPhotoDimension.width,
              minHeight: minPhotoDimension.height,
              maxWidth: maxPhotoDimension.width,
              maxHeight: maxPhotoDimension.height,
            })
          }
        />

        <InputFormControl
          label={t('portrait_module.name')}
          ariaLabel={t('portrait_module.name')}
          isRequired
          name="name"
          maxLength={100}
        />

        <InputFormControl
          label={t('portrait_module.jobTitle')}
          ariaLabel={t('portrait_module.jobTitle')}
          isRequired
          name="jobTitle"
          maxLength={100}
        />

        <InputFormControl
          label={t('portrait_module.jobTitleEnglish')}
          ariaLabel={t('portrait_module.jobTitleEnglish')}
          name="jobTitleEnglish"
          maxLength={100}
          isRequired
        />

        <InputFormControl
          label={t('portrait_module.link')}
          ariaLabel={t('portrait_module.link')}
          name="linkTarget"
          validate={(value) => validateUrl(tCommon, value)}
        />
      </Stack>
    </ElementFormModal>
  );
}
