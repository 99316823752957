import React from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import GroupBreadcrumb from './common/group-breadcrumb';

const groupRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'groups',
    handle: {
      breadcrumb: <Translate ns="group" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./group-lister/group-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./group-editor/new-group-editor-route'),
      },
      {
        path: ':groupId',
        handle: {
          breadcrumb: <GroupBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./group-page/group-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./group-viewer/group-viewer-route'),
              },
              {
                path: 'personRelations',
                lazy: () => import('./group-relation/group-person-relation-viewer-route'),
              },
              {
                path: 'companyRelations',
                lazy: () => import('./group-relation/group-company-relation-viewer-route'),
              },
              {
                path: 'staffRelations',
                lazy: () => import('./group-relation/group-staff-relation-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./group-history/group-history-route'),
              },
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./group-editor/edit-group-editor-route'),
          },
        ],
      },
    ],
  },
];

export default groupRoutes;
