/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountingRecordListItemDto
 */
export interface AccountingRecordListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof AccountingRecordListItemDto
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof AccountingRecordListItemDto
     */
    date: Date;
    /**
     * 
     * @type {number}
     * @memberof AccountingRecordListItemDto
     */
    netAmount: number;
    /**
     * 
     * @type {number}
     * @memberof AccountingRecordListItemDto
     */
    grossAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountingRecordListItemDto
     */
    taxAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountingRecordListItemDto
     */
    taxRate?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountingRecordListItemDto
     */
    vatCode?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountingRecordListItemDto
     */
    recipientTaxExempted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountingRecordListItemDto
     */
    documentNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountingRecordListItemDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountingRecordListItemDto
     */
    itemPosition?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountingRecordListItemDto
     */
    itemDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountingRecordListItemDto
     */
    debitAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountingRecordListItemDto
     */
    creditAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountingRecordListItemDto
     */
    creditCostCenter?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountingRecordListItemDto
     */
    creditCostObject?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountingRecordListItemDto
     */
    taxType?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountingRecordListItemDto
     */
    paymentProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountingRecordListItemDto
     */
    paymentMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountingRecordListItemDto
     */
    reason?: string;
}

/**
 * Check if a given object implements the AccountingRecordListItemDto interface.
 */
export function instanceOfAccountingRecordListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('date' in value)) return false;
    if (!('netAmount' in value)) return false;
    return true;
}

export function AccountingRecordListItemDtoFromJSON(json: any): AccountingRecordListItemDto {
    return AccountingRecordListItemDtoFromJSONTyped(json, false);
}

export function AccountingRecordListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountingRecordListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'date': (new Date(json['date'])),
        'netAmount': json['netAmount'],
        'grossAmount': json['grossAmount'] == null ? undefined : json['grossAmount'],
        'taxAmount': json['taxAmount'] == null ? undefined : json['taxAmount'],
        'taxRate': json['taxRate'] == null ? undefined : json['taxRate'],
        'vatCode': json['vatCode'] == null ? undefined : json['vatCode'],
        'recipientTaxExempted': json['recipientTaxExempted'] == null ? undefined : json['recipientTaxExempted'],
        'documentNumber': json['documentNumber'] == null ? undefined : json['documentNumber'],
        'description': json['description'] == null ? undefined : json['description'],
        'itemPosition': json['itemPosition'] == null ? undefined : json['itemPosition'],
        'itemDescription': json['itemDescription'] == null ? undefined : json['itemDescription'],
        'debitAccount': json['debitAccount'] == null ? undefined : json['debitAccount'],
        'creditAccount': json['creditAccount'] == null ? undefined : json['creditAccount'],
        'creditCostCenter': json['creditCostCenter'] == null ? undefined : json['creditCostCenter'],
        'creditCostObject': json['creditCostObject'] == null ? undefined : json['creditCostObject'],
        'taxType': json['taxType'] == null ? undefined : json['taxType'],
        'paymentProvider': json['paymentProvider'] == null ? undefined : json['paymentProvider'],
        'paymentMethod': json['paymentMethod'] == null ? undefined : json['paymentMethod'],
        'reason': json['reason'] == null ? undefined : json['reason'],
    };
}

export function AccountingRecordListItemDtoToJSON(value?: AccountingRecordListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'date': ((value['date']).toISOString().substring(0,10)),
        'netAmount': value['netAmount'],
        'grossAmount': value['grossAmount'],
        'taxAmount': value['taxAmount'],
        'taxRate': value['taxRate'],
        'vatCode': value['vatCode'],
        'recipientTaxExempted': value['recipientTaxExempted'],
        'documentNumber': value['documentNumber'],
        'description': value['description'],
        'itemPosition': value['itemPosition'],
        'itemDescription': value['itemDescription'],
        'debitAccount': value['debitAccount'],
        'creditAccount': value['creditAccount'],
        'creditCostCenter': value['creditCostCenter'],
        'creditCostObject': value['creditCostObject'],
        'taxType': value['taxType'],
        'paymentProvider': value['paymentProvider'],
        'paymentMethod': value['paymentMethod'],
        'reason': value['reason'],
    };
}

