import React from 'react';
import { useTranslation } from 'react-i18next';
import { EventDto, PublishedEventSettingsDto } from '../../../api';
import EventReferenceHeader, { PublicEventReferenceHeader } from '../../event/event-reference/event-reference-header';

interface KeyPlayerEventHeaderProps {
  event: EventDto;
  isMobile?: boolean;
}

export default function KeyPlayerEventHeader({ event, isMobile = false }: KeyPlayerEventHeaderProps) {
  const { t } = useTranslation('key_player_list');
  const title = isMobile ? t('key_player_list') : t('lister.title', { event: event.title });

  return <EventReferenceHeader event={event} title={title} isMobile={isMobile} />;
}

interface PublicKeyPlayerEventHeaderProps {
  eventSettings: PublishedEventSettingsDto;
}

export function PublicKeyPlayerEventHeader({ eventSettings }: PublicKeyPlayerEventHeaderProps) {
  const { t } = useTranslation('key_player_list');
  const title = t('lister.title', { event: eventSettings.eventReference.title });

  return <PublicEventReferenceHeader eventSettings={eventSettings} title={title} />;
}
