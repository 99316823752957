import { debounce } from 'lodash-es';
import React from 'react';
import { FieldPath, UseFormReturn, useWatch } from 'react-hook-form';
import invariant from 'tiny-invariant';
import { FetchError, PersonDto, PersonDuplicatesDto } from '../../../api';
import personApi from '../../../data-access/person-api';
import { DEBOUNCE_TIME } from '../../../util/constants';
import DuplicateInfoBox from './duplicate-info-box';

const DUPLICATE_PARAM_KEYS: FieldPath<PersonDto>[] = [
  'firstName',
  'surname',
  'stageName',
  'alternativeNames',
  'dateOfBirth',
];

const DuplicatesContext = React.createContext<PersonDuplicatesDto | null>(null);
export const DuplicatesProvider = DuplicatesContext.Provider;

export function useDuplicateWarning({ watch, formState, getFieldState }: UseFormReturn<PersonDto>) {
  const [duplicatesDto, setDuplicatesDto] = React.useState<PersonDuplicatesDto | null>(null);
  const [searchingDuplicates, setSearchingDuplicates] = React.useState(false);

  const isValid = DUPLICATE_PARAM_KEYS.reduce(
    (isValid, name) => isValid && !getFieldState(name, formState).error,
    true,
  );

  const isValidRef = React.useRef(isValid);
  isValidRef.current = isValid;

  React.useEffect(() => {
    let abortController: AbortController | null = null;

    const handlePersonChange = debounce(async (person, { name }) => {
      abortController?.abort();
      abortController = null;
      if (name == null || person == null || !DUPLICATE_PARAM_KEYS.includes(name)) {
        return;
      }
      if (!isValidRef.current) {
        setDuplicatesDto(null);
        return;
      }
      invariant(
        // alternativeNames must be non-null list (can be empty)
        person.firstName != null && person.surname != null && person.alternativeNames != null,
        'Person is invalid due to missing firstName, surname or alternativeNames',
      );
      setSearchingDuplicates(true);
      abortController = new AbortController();
      let possibleDuplicates = null;
      try {
        possibleDuplicates = await personApi.getPossibleDuplicates(
          {
            fullName: { firstName: person.firstName, surname: person.surname },
            stageName: person?.stageName,

            dateOfBirth: person?.dateOfBirth ?? undefined,
            alternativeNames: person.alternativeNames as string[],
          },
          { signal: abortController.signal },
        );
      } catch (e) {
        // A FetchError which is caused by an AbortError is ok and expected
        if (e instanceof FetchError && e.cause.name === 'AbortError') {
          return;
        } else {
          throw e;
        }
      }

      setDuplicatesDto(possibleDuplicates);
      setSearchingDuplicates(false);
    }, DEBOUNCE_TIME);

    const subscription = watch(handlePersonChange);

    return () => {
      handlePersonChange.cancel();
      subscription.unsubscribe();
    };
  }, [watch]);

  return { duplicatesDto, searchingDuplicates, resetDuplicates: () => setDuplicatesDto(null) };
}

export function DuplicateWarning() {
  const duplicatesDto = React.useContext(DuplicatesContext);
  const personId = useWatch<PersonDto, 'id'>({ name: 'id' });

  const duplicateList = duplicatesDto != null ? duplicatesDto.duplicates?.filter((d) => d.id !== personId) : [];

  return (
    <>
      {duplicateList != null && duplicateList.length > 0 ? (
        <DuplicateInfoBox mt={4} duplicates={duplicateList} mainPerson={personId} canMerge={true} />
      ) : null}
    </>
  );
}
