import { PluginRegistry } from '../../../util/plugin/use-plugins';
import { TEAM_RELATION_EXTENSION } from '../../team/team-relation/team-relation-extension';
import { TEAM_RELATION_HISTORY_EXTENSION } from '../../team/team-relation/team-relation-history-extension';
import { SECTION_STAFF_LISTER_COLUMN_EXTENSION } from '../section-staff/section-staff-lister/section-staff-lister-column-extension';
import { sectionStaffListerTeamsColumns } from './section-staff-extensions/section-staff-lister-teams-column';
import { teamStaffHistoryExtension } from './team-staff-history/team-staff-history';
import { teamStaffPageContentExtension } from './team-staff-page-content';

export default function TeamStaffPluginProvider(registry: PluginRegistry) {
  registry.register(TEAM_RELATION_EXTENSION, teamStaffPageContentExtension);
  registry.register(TEAM_RELATION_HISTORY_EXTENSION, teamStaffHistoryExtension);
  registry.register(SECTION_STAFF_LISTER_COLUMN_EXTENSION, sectionStaffListerTeamsColumns);
}
