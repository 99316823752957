import { Textarea } from '@chakra-ui/react';
import React from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { RecipientLinkDto } from '../../../../api';
import FormControl from '../../../../ui/form/form-control';
import { EMAIL_PATTERN } from '../../../../util/constants';
import useAsyncValidation from '../../../../util/use-async-validation/use-async-validation';
import { validateEmailNotOnBlocklistFunction } from '../../../common/validation/validate-email-not-on-blocklist';

export interface AddEmailRecipient {
  emailRecipients: Array<RecipientLinkDto>;
}

export default function EmailMailingRecipientControl() {
  const { t } = useTranslation('mailing');
  const { t: tCommon } = useTranslation('common');
  const validateEmailNotOnBlocklist = useAsyncValidation(validateEmailNotOnBlocklistFunction());

  const { field } = useController<AddEmailRecipient, 'emailRecipients'>({
    name: 'emailRecipients',
    defaultValue: [],
    rules: {
      async validate(links) {
        if (links.length > 10) {
          return t('recipients.emails_error_count');
        }

        if (links.some((email) => email.email && !EMAIL_PATTERN.test(email.email))) {
          return t('recipients.emails_error_pattern');
        }

        const emails = links
          .map((link) => link.email)
          .filter<string>((email): email is string => typeof email === 'string');
        const notOnBlocklist = await validateEmailNotOnBlocklist(emails);
        if (!notOnBlocklist) {
          return tCommon('validation_error.emailOnBlocklistMultiple');
        }
      },
    },
  });

  const [value, setValue] = React.useState<string>(() => field.value.join('; '));

  return (
    <FormControl<AddEmailRecipient>
      label={t('recipients.emails')}
      helperText={t('recipients.emails_helper_text')}
      name="emailRecipients"
    >
      <Textarea
        {...field}
        value={value}
        onChange={(event) => {
          validateEmailNotOnBlocklist.reset();
          setValue(event.target.value);

          field.onChange(
            splitAndTrim(event.target.value).map(
              (email): RecipientLinkDto => ({
                id: uuid(),
                type: 'EMAIL',
                email,
              }),
            ),
          );
        }}
      />
    </FormControl>
  );
}

const splitAndTrim = (emails: string) => {
  return emails
    .split(';')
    .map((email) => email.trim())
    .filter((email) => email.length !== 0);
};
