/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Export file formats
 * @export
 */
export const ExportFormatDto = {
    CSV: 'CSV',
    PDF: 'PDF',
    DOCX: 'DOCX'
} as const;
export type ExportFormatDto = typeof ExportFormatDto[keyof typeof ExportFormatDto];


export function ExportFormatDtoFromJSON(json: any): ExportFormatDto {
    return ExportFormatDtoFromJSONTyped(json, false);
}

export function ExportFormatDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportFormatDto {
    return json as ExportFormatDto;
}

export function ExportFormatDtoToJSON(value?: ExportFormatDto | null): any {
    return value as any;
}

