import React from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyAdminListItemDto } from '../../../api';
import companyAdminApi from '../../../data-access/company-admin-api';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import useToast from '../../../ui/use-toast/use-toast';
import useDialog from '../../../util/use-dialog/use-dialog';
import CompanyAdminDeleteDialog from './company-admin-delete-dialog';

interface CompanyAdminDeleteButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  onSuccess?(): void;

  companyId: string;
  relation: CompanyAdminListItemDto;
  isLastAdmin: boolean;
  isDeletable: boolean;
}

const CompanyAdminDeleteButton = React.forwardRef<HTMLButtonElement, CompanyAdminDeleteButtonProps>(
  ({ children, companyId, relation, isDeletable, isLastAdmin, onSuccess, onClick, disabled, ...props }, ref) => {
    const { t } = useTranslation(['company', 'common']);
    const [deleteDialogIsOpen, onDeleteDialogClose, openDeleteDialog] = useDialog<false>();
    const showDeleteSuccessToast = useToast({
      id: 'group-company-deletion-success-toast',
      status: 'success',
    });

    const deleteRelation = async (event: React.MouseEvent<HTMLButtonElement>) => {
      onClick?.(event);
      if (event.defaultPrevented) {
        return;
      }

      const checked = await openDeleteDialog();
      if (checked) {
        await companyAdminApi.deleteCompanyAdminRelation({
          companyId,
          personId: relation.personId,
          occupationId: relation.occupationId,
        });

        showDeleteSuccessToast({
          title: t('company:externalAdmin.toast.deleteTitle'),
          description: t('company:externalAdmin.toast.deleteDescription', {
            name: `${relation.firstName} ${relation.surname}`,
          }),
        });
        onSuccess?.();
      }
    };

    return (
      <>
        <DisableableButton
          {...props}
          ref={ref}
          onClick={deleteRelation}
          disableReason={t('common:toast.status_conflict.description')}
          isDisabled={!isDeletable || disabled}
        >
          {children}
        </DisableableButton>
        <CompanyAdminDeleteDialog
          isOpen={deleteDialogIsOpen}
          onClose={onDeleteDialogClose}
          name={`${relation.firstName} ${relation.surname}`}
          isLastAdmin={isLastAdmin}
        />
      </>
    );
  },
);

export default CompanyAdminDeleteButton;
