/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EventReferenceDto } from './EventReferenceDto';
import {
    EventReferenceDtoFromJSON,
    EventReferenceDtoFromJSONTyped,
    EventReferenceDtoToJSON,
} from './EventReferenceDto';
import type { KeyPlayerRoleDto } from './KeyPlayerRoleDto';
import {
    KeyPlayerRoleDtoFromJSON,
    KeyPlayerRoleDtoFromJSONTyped,
    KeyPlayerRoleDtoToJSON,
} from './KeyPlayerRoleDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';

/**
 * The person is a key player in a historic event.
 * @export
 * @interface PersonIsKeyPlayerBlockerDto
 */
export interface PersonIsKeyPlayerBlockerDto {
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof PersonIsKeyPlayerBlockerDto
     */
    person: PersonReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof PersonIsKeyPlayerBlockerDto
     */
    reason: PersonIsKeyPlayerBlockerDtoReasonEnum;
    /**
     * 
     * @type {EventReferenceDto}
     * @memberof PersonIsKeyPlayerBlockerDto
     */
    event: EventReferenceDto;
    /**
     * 
     * @type {Array<KeyPlayerRoleDto>}
     * @memberof PersonIsKeyPlayerBlockerDto
     */
    roles: Array<KeyPlayerRoleDto>;
}


/**
 * @export
 */
export const PersonIsKeyPlayerBlockerDtoReasonEnum = {
    PERSON_IS_KEY_PLAYER: 'PERSON_IS_KEY_PLAYER'
} as const;
export type PersonIsKeyPlayerBlockerDtoReasonEnum = typeof PersonIsKeyPlayerBlockerDtoReasonEnum[keyof typeof PersonIsKeyPlayerBlockerDtoReasonEnum];


/**
 * Check if a given object implements the PersonIsKeyPlayerBlockerDto interface.
 */
export function instanceOfPersonIsKeyPlayerBlockerDto(value: object): boolean {
    if (!('person' in value)) return false;
    if (!('reason' in value)) return false;
    if (!('event' in value)) return false;
    if (!('roles' in value)) return false;
    return true;
}

export function PersonIsKeyPlayerBlockerDtoFromJSON(json: any): PersonIsKeyPlayerBlockerDto {
    return PersonIsKeyPlayerBlockerDtoFromJSONTyped(json, false);
}

export function PersonIsKeyPlayerBlockerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonIsKeyPlayerBlockerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'person': PersonReferenceDtoFromJSON(json['person']),
        'reason': json['reason'],
        'event': EventReferenceDtoFromJSON(json['event']),
        'roles': ((json['roles'] as Array<any>).map(KeyPlayerRoleDtoFromJSON)),
    };
}

export function PersonIsKeyPlayerBlockerDtoToJSON(value?: PersonIsKeyPlayerBlockerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'person': PersonReferenceDtoToJSON(value['person']),
        'reason': value['reason'],
        'event': EventReferenceDtoToJSON(value['event']),
        'roles': ((value['roles'] as Array<any>).map(KeyPlayerRoleDtoToJSON)),
    };
}

