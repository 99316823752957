import { HStack, Text } from '@chakra-ui/react';
import { faUserAlt, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import React, { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { PersonReferenceDto } from '../../../api';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';

export interface PersonReferenceViewerProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  personReference: Omit<PersonReferenceDto, 'emailAddresses'>;
  hidePersonKey?: boolean;
  flipName?: boolean;
  firstNameOnly?: boolean;
  surnameOnly?: boolean;
  isTruncated?: boolean;
  maxWidth?: number | string;
  spacing?: number;
  displayNameId?: string;
  readOnly?: boolean;
  icon?: IconDefinition;
}

const PersonReferenceViewer = forwardRef(
  (
    {
      personReference,
      flipName,
      firstNameOnly = false,
      surnameOnly = false,
      hidePersonKey,
      maxWidth,
      spacing,
      displayNameId,
      readOnly = false,
      icon = undefined,
      ...props
    }: PersonReferenceViewerProps,
    ref: ForwardedRef<HTMLAnchorElement | HTMLDivElement>,
  ) => {
    const { t } = useTranslation('person', { useSuspense: false });

    let displayName: string | undefined;

    if (firstNameOnly) {
      displayName = personReference.firstName;
    } else if (surnameOnly) {
      displayName = personReference.surname;
    } else if (flipName) {
      displayName = `${personReference.surname}, ${personReference.firstName}`;
    } else {
      displayName = `${personReference.firstName} ${personReference.surname}`;
    }

    const name =
      personReference.surname != null ? (
        <HStack spacing={1} overflow="hidden">
          <Text
            as="span"
            fontWeight={firstNameOnly || surnameOnly ? 'normal' : 'medium'}
            maxWidth={maxWidth}
            noOfLines={maxWidth ? 1 : undefined}
          >
            {displayName}
          </Text>
          {!hidePersonKey && (
            <Text as="span" fontWeight="normal">
              {personReference.personKey}
            </Text>
          )}
        </HStack>
      ) : (
        <Text as="span" fontWeight="medium" width="full">
          {t('forbidden_person_name')}
        </Text>
      );

    return (
      <Reference
        to={readOnly ? undefined : '/persons/' + personReference.id}
        necessaryPermission="PERSON:CAN_SEE_DETAILS"
        icon={icon ? icon : faUserAlt}
        spacing={spacing}
        id={displayNameId}
        ref={ref}
        {...props}
      >
        {name}
      </Reference>
    );
  },
);

export default PersonReferenceViewer;
