import { HStack, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { SectionStaffListItemDto, TeamReferenceDto } from '../../../../api';
import teamApi from '../../../../data-access/team-api';
import StringFilter from '../../../../ui/data-table/filter/string-filter';
import Optional from '../../../../ui/optional/optional';
import Translate from '../../../../util/translate/translate';
import TeamReference from '../../../team/team-reference/team-reference';
import { SectionStaffListerColumnExtension } from '../../section-staff/section-staff-lister/section-staff-lister-column-extension';

export const sectionStaffListerTeamsColumns: SectionStaffListerColumnExtension = {
  columnToAdd: {
    key: 'teams',
    name: <TeamHeader />,
    cellProps: {
      whiteSpace: 'nowrap',
    },
    renderCell: (staff: SectionStaffListItemDto) => <TeamCell teams={staff.teams || []} />,
    filterProperty: 'teams[*].name',
    filter: (
      <StringFilter
        availableOperators={['CONTAIN', 'START_WITH', 'END_WITH', 'IN']}
        label={<Translate ns="team">{(t) => t('lister.title')}</Translate>}
        loadOptions={async (value: string) => {
          const page = await teamApi.searchTeams({
            pageable: { size: 10 },
            filter: [`name,contain,${value}`],
          });

          return page.content.map((team) => team.name);
        }}
      />
    ),
    isSortable: false,
  },
};

function TeamHeader() {
  const { t } = useTranslation('team');
  return (
    <HStack>
      <Text>{t('lister.title')}</Text>
    </HStack>
  );
}

function TeamCell({ teams }: { teams: TeamReferenceDto[] }) {
  return (
    <Optional isEmpty={isEmpty(teams)}>
      <Wrap spacingX={4} spacingY={2}>
        {teams.map((team) => (
          <WrapItem key={team.id}>
            <TeamReference teamReference={team} />
          </WrapItem>
        ))}
      </Wrap>
    </Optional>
  );
}
