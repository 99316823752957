import { chakra, forwardRef, HTMLChakraProps } from '@chakra-ui/react';
import { get, useFormContext } from 'react-hook-form';

interface ErrorMessageProps extends HTMLChakraProps<'div'> {
  name: string;
}

const ErrorMessage = forwardRef<ErrorMessageProps, 'div'>(({ name, ...props }, ref) => {
  const { formState } = useFormContext();
  const error = get(formState.errors, name);

  if (error == null) {
    return null;
  }

  return (
    <chakra.div color="text.error" fontSize="sm" lineHeight="normal" {...props} ref={ref}>
      {error.message ?? error.root?.message}
    </chakra.div>
  );
});

export default ErrorMessage;
