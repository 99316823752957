/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MailingBlocklistPermissionDto
 */
export interface MailingBlocklistPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof MailingBlocklistPermissionDto
     */
    name: MailingBlocklistPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof MailingBlocklistPermissionDto
     */
    action: MailingBlocklistPermissionDtoActionEnum;
}


/**
 * @export
 */
export const MailingBlocklistPermissionDtoNameEnum = {
    MAILING_BLOCKLIST: 'MAILING_BLOCKLIST'
} as const;
export type MailingBlocklistPermissionDtoNameEnum = typeof MailingBlocklistPermissionDtoNameEnum[keyof typeof MailingBlocklistPermissionDtoNameEnum];

/**
 * @export
 */
export const MailingBlocklistPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    ADD_EMAIL_ADDRESS: 'CAN_ADD_EMAIL_ADDRESS',
    DELETE_EMAIL_ADDRESS: 'CAN_DELETE_EMAIL_ADDRESS'
} as const;
export type MailingBlocklistPermissionDtoActionEnum = typeof MailingBlocklistPermissionDtoActionEnum[keyof typeof MailingBlocklistPermissionDtoActionEnum];


/**
 * Check if a given object implements the MailingBlocklistPermissionDto interface.
 */
export function instanceOfMailingBlocklistPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function MailingBlocklistPermissionDtoFromJSON(json: any): MailingBlocklistPermissionDto {
    return MailingBlocklistPermissionDtoFromJSONTyped(json, false);
}

export function MailingBlocklistPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingBlocklistPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function MailingBlocklistPermissionDtoToJSON(value?: MailingBlocklistPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

