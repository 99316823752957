/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PhoneNumberListItemDto } from './PhoneNumberListItemDto';
import {
    PhoneNumberListItemDtoFromJSON,
    PhoneNumberListItemDtoFromJSONTyped,
    PhoneNumberListItemDtoToJSON,
} from './PhoneNumberListItemDto';
import type { RoleReferenceDto } from './RoleReferenceDto';
import {
    RoleReferenceDtoFromJSON,
    RoleReferenceDtoFromJSONTyped,
    RoleReferenceDtoToJSON,
} from './RoleReferenceDto';
import type { StaffStatusDto } from './StaffStatusDto';
import {
    StaffStatusDtoFromJSON,
    StaffStatusDtoFromJSONTyped,
    StaffStatusDtoToJSON,
} from './StaffStatusDto';
import type { TeamReferenceDto } from './TeamReferenceDto';
import {
    TeamReferenceDtoFromJSON,
    TeamReferenceDtoFromJSONTyped,
    TeamReferenceDtoToJSON,
} from './TeamReferenceDto';

/**
 * 
 * @export
 * @interface SectionStaffListItemDto
 */
export interface SectionStaffListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof SectionStaffListItemDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SectionStaffListItemDto
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof SectionStaffListItemDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof SectionStaffListItemDto
     */
    jobTitle?: string;
    /**
     * 
     * @type {Array<TeamReferenceDto>}
     * @memberof SectionStaffListItemDto
     */
    teams: Array<TeamReferenceDto>;
    /**
     * 
     * @type {RoleReferenceDto}
     * @memberof SectionStaffListItemDto
     */
    role?: RoleReferenceDto;
    /**
     * 
     * @type {Array<PhoneNumberListItemDto>}
     * @memberof SectionStaffListItemDto
     */
    phoneNumbers: Array<PhoneNumberListItemDto>;
    /**
     * 
     * @type {string}
     * @memberof SectionStaffListItemDto
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionStaffListItemDto
     */
    site?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionStaffListItemDto
     */
    room?: string;
    /**
     * 
     * @type {StaffStatusDto}
     * @memberof SectionStaffListItemDto
     */
    status: StaffStatusDto;
}

/**
 * Check if a given object implements the SectionStaffListItemDto interface.
 */
export function instanceOfSectionStaffListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('surname' in value)) return false;
    if (!('firstName' in value)) return false;
    if (!('teams' in value)) return false;
    if (!('phoneNumbers' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function SectionStaffListItemDtoFromJSON(json: any): SectionStaffListItemDto {
    return SectionStaffListItemDtoFromJSONTyped(json, false);
}

export function SectionStaffListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionStaffListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'surname': json['surname'],
        'firstName': json['firstName'],
        'jobTitle': json['jobTitle'] == null ? undefined : json['jobTitle'],
        'teams': ((json['teams'] as Array<any>).map(TeamReferenceDtoFromJSON)),
        'role': json['role'] == null ? undefined : RoleReferenceDtoFromJSON(json['role']),
        'phoneNumbers': ((json['phoneNumbers'] as Array<any>).map(PhoneNumberListItemDtoFromJSON)),
        'emailAddress': json['emailAddress'] == null ? undefined : json['emailAddress'],
        'site': json['site'] == null ? undefined : json['site'],
        'room': json['room'] == null ? undefined : json['room'],
        'status': StaffStatusDtoFromJSON(json['status']),
    };
}

export function SectionStaffListItemDtoToJSON(value?: SectionStaffListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'surname': value['surname'],
        'firstName': value['firstName'],
        'jobTitle': value['jobTitle'],
        'teams': ((value['teams'] as Array<any>).map(TeamReferenceDtoToJSON)),
        'role': RoleReferenceDtoToJSON(value['role']),
        'phoneNumbers': ((value['phoneNumbers'] as Array<any>).map(PhoneNumberListItemDtoToJSON)),
        'emailAddress': value['emailAddress'],
        'site': value['site'],
        'room': value['room'],
        'status': StaffStatusDtoToJSON(value['status']),
    };
}

