/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const VisaTemplateStatusDto = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    ARCHIVED: 'ARCHIVED'
} as const;
export type VisaTemplateStatusDto = typeof VisaTemplateStatusDto[keyof typeof VisaTemplateStatusDto];


export function VisaTemplateStatusDtoFromJSON(json: any): VisaTemplateStatusDto {
    return VisaTemplateStatusDtoFromJSONTyped(json, false);
}

export function VisaTemplateStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisaTemplateStatusDto {
    return json as VisaTemplateStatusDto;
}

export function VisaTemplateStatusDtoToJSON(value?: VisaTemplateStatusDto | null): any {
    return value as any;
}

