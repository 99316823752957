import React from 'react';
import { MailingTemplateDto, RichTextOptionsDto } from '../../../../api';
import RichTextOptionsControl from '../../../../ui/form/rich-text-options-control/rich-text-options-control';

interface TextModuleSettingsControlProps {
  name: string;
}

export default function TextModuleSettingsControl({ name }: TextModuleSettingsControlProps) {
  return (
    <RichTextOptionsControl<MailingTemplateDto>
      name={`${name as `moduleSettings.${number}`}.richTextOptions`}
      options={[
        RichTextOptionsDto.HEADLINE,
        RichTextOptionsDto.SUB_HEADLINE,
        RichTextOptionsDto.PARAGRAPH,
        RichTextOptionsDto.ITALIC,
        RichTextOptionsDto.BOLD,
        RichTextOptionsDto.CITATION,
        RichTextOptionsDto.HYPERLINK,
        RichTextOptionsDto.HYPERLINK_BUTTON,
      ]}
    />
  );
}
