import { ParseKeys } from 'i18next/typescript/t';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import useEvent from '../event/use-event/use-event';
import { EventEvaluationEditorAction } from './event-evaluation-editor/event-evaluation-editor';
import EventEvaluationHistory from './event-evaluation-history/event-evaluation-history';

const EventEvaluationPage = React.lazy(() => import('./event-evaluation-page/event-evaluation-page'));
const EventEvaluationViewer = React.lazy(() => import('./event-evaluation-viewer/event-evaluation-viewer'));
const EventEvaluationEditor = React.lazy(() => import('./event-evaluation-editor/event-evaluation-editor'));

const eventEvaluationRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'evaluation',
    handle: {
      breadcrumb: <Translate ns="event_evaluation" i18nKey="title" />,
    },
    children: [
      {
        path: '',
        element: <EventEvaluationPage />,
        children: [
          {
            path: '',
            element: <EventEvaluationViewer />,
            handle: {
              helmet: <EventEvaluationHelmet i18nKey="viewer.helmet" />,
            },
          },
          {
            path: 'history',
            element: <EventEvaluationHistory />,
            handle: {
              helmet: <EventEvaluationHelmet i18nKey="history.helmet" />,
            },
          },
        ],
      },
      {
        path: 'new',
        handle: {
          breadcrumb: <Translate ns="event_evaluation" i18nKey="action.new" />,
          helmet: <EventEvaluationHelmet i18nKey="editor.helmetNew" />,
        },
        element: <EventEvaluationEditor editorAction={EventEvaluationEditorAction.NEW} />,
      },
      {
        path: 'edit',
        handle: {
          breadcrumb: <Translate ns="event_evaluation" i18nKey="action.edit" />,
          helmet: <EventEvaluationHelmet i18nKey="editor.helmetEdit" />,
        },
        element: <EventEvaluationEditor editorAction={EventEvaluationEditorAction.EDIT} />,
      },
      {
        path: 'review',
        handle: {
          breadcrumb: <Translate ns="event_evaluation" i18nKey="action.review" />,
          helmet: <EventEvaluationHelmet i18nKey="editor.helmetReview" />,
        },
        element: <EventEvaluationEditor editorAction={EventEvaluationEditorAction.REVIEW} />,
      },
    ],
  },
];

export default eventEvaluationRoutes;

interface EventEvaluationHelmetProps {
  i18nKey: ParseKeys<'event_evaluation'>;
}

function EventEvaluationHelmet({ i18nKey }: EventEvaluationHelmetProps) {
  const { t } = useTranslation('event_evaluation');
  const params = useParams<{ eventId: string }>();
  invariant(params.eventId, 'Empty eventId');
  const { title } = useEvent(params.eventId);

  return <Helmet title={t(i18nKey, { title })} />;
}
