/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateTimeRangeDto } from './ClosedDateTimeRangeDto';
import {
    ClosedDateTimeRangeDtoFromJSON,
    ClosedDateTimeRangeDtoFromJSONTyped,
    ClosedDateTimeRangeDtoToJSON,
} from './ClosedDateTimeRangeDto';
import type { RundownCalendarLaneDto } from './RundownCalendarLaneDto';
import {
    RundownCalendarLaneDtoFromJSON,
    RundownCalendarLaneDtoFromJSONTyped,
    RundownCalendarLaneDtoToJSON,
} from './RundownCalendarLaneDto';

/**
 * Grouped rundown elements by lane
 * @export
 * @interface RundownCalendarDto
 */
export interface RundownCalendarDto {
    /**
     * 
     * @type {Array<RundownCalendarLaneDto>}
     * @memberof RundownCalendarDto
     */
    calendarLanes: Array<RundownCalendarLaneDto>;
    /**
     * 
     * @type {ClosedDateTimeRangeDto}
     * @memberof RundownCalendarDto
     */
    dateTimeRange: ClosedDateTimeRangeDto;
}

/**
 * Check if a given object implements the RundownCalendarDto interface.
 */
export function instanceOfRundownCalendarDto(value: object): boolean {
    if (!('calendarLanes' in value)) return false;
    if (!('dateTimeRange' in value)) return false;
    return true;
}

export function RundownCalendarDtoFromJSON(json: any): RundownCalendarDto {
    return RundownCalendarDtoFromJSONTyped(json, false);
}

export function RundownCalendarDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RundownCalendarDto {
    if (json == null) {
        return json;
    }
    return {
        
        'calendarLanes': ((json['calendarLanes'] as Array<any>).map(RundownCalendarLaneDtoFromJSON)),
        'dateTimeRange': ClosedDateTimeRangeDtoFromJSON(json['dateTimeRange']),
    };
}

export function RundownCalendarDtoToJSON(value?: RundownCalendarDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'calendarLanes': ((value['calendarLanes'] as Array<any>).map(RundownCalendarLaneDtoToJSON)),
        'dateTimeRange': ClosedDateTimeRangeDtoToJSON(value['dateTimeRange']),
    };
}

