/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The categories of participation
 * @export
 */
export const CategoryOfParticipationDto = {
    GUEST: 'GUEST',
    PLUS_ONE: 'PLUS_ONE',
    GUEST_LIAISON: 'GUEST_LIAISON',
    SEATFILLER: 'SEATFILLER',
    BODYGUARD: 'BODYGUARD'
} as const;
export type CategoryOfParticipationDto = typeof CategoryOfParticipationDto[keyof typeof CategoryOfParticipationDto];


export function CategoryOfParticipationDtoFromJSON(json: any): CategoryOfParticipationDto {
    return CategoryOfParticipationDtoFromJSONTyped(json, false);
}

export function CategoryOfParticipationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryOfParticipationDto {
    return json as CategoryOfParticipationDto;
}

export function CategoryOfParticipationDtoToJSON(value?: CategoryOfParticipationDto | null): any {
    return value as any;
}

