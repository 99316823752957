import React from 'react';
import { useTranslation } from 'react-i18next';
import { DescriptionGroup, DescriptionItem } from '../../../ui/description';
import SectionReference from '../../section/section-reference/section-reference';
import { DiscountDto } from '../../../api';

interface CodeDiscountViewerProps {
  discount: DiscountDto;
}

export default function CodeDiscountViewer({ discount }: CodeDiscountViewerProps) {
  const { t } = useTranslation(['code', 'common']);

  return (
    <DescriptionGroup label={t('code:paymentModifierOptions.DISCOUNT')}>
      <DescriptionItem label={t('code:discount.section')}>
        <SectionReference sectionReference={discount.section} />
      </DescriptionItem>
      <DescriptionItem label={t('code:discount.reason')}>{discount.reason}</DescriptionItem>
      <DescriptionItem label={t('code:discount.level')}>
        {t(`code:discount.levelOptions.${discount.discountLevel}`)}
      </DescriptionItem>
      {discount.discountAmount != null && (
        <DescriptionItem label={t('code:discount.amount')}>
          {t('common:format.currency', { value: discount.discountAmount })}
        </DescriptionItem>
      )}
      {discount.discountPercentage != null && (
        <DescriptionItem label={t('code:discount.percentage')}>
          {t('common:format.percent', { value: discount.discountPercentage })}
        </DescriptionItem>
      )}
    </DescriptionGroup>
  );
}
