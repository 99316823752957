import standApi from '../../data-access/stand-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';

export const standFetcher = createFetcherScope(standApi);
export const fetchStand = standFetcher.createFetcher(standApi.fetchStand);
export const checkIfNameExistsInEdition = standFetcher.createFetcher(standApi.nameExistsInEdition);
export const fetchStandListItems = standFetcher.createFetcher(standApi.searchStandListItems);
export const searchStandPersonRelationListItems = standFetcher.createFetcher(
  standApi.searchStandPersonRelationListItems,
);
export const searchStandCompanyRelationListItems = standFetcher.createFetcher(
  standApi.searchStandCompanyRelationListItems,
);
export const fetchStandReferences = standFetcher.createFetcher(standApi.searchStandReferences);
export const fetchStandHistory = standFetcher.createFetcher(standApi.fetchStandHistory);
export const fetchStandPersonRelationHistory = standFetcher.createFetcher(standApi.fetchStandHasPersonRelationHistory);
export const fetchStandCompanyRelationHistory = standFetcher.createFetcher(
  standApi.fetchStandHasCompanyRelationHistory,
);
