import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { EventDtoFromJSON } from '../../api';
import { companyIcon, eventIcon } from '../../ui/icons/business-objects';
import CompanyReference from '../company/company-reference/company-reference';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';
import SearchResultConnection from '../search/search-result-connection';

export default function EventSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'EVENT', `${result.resultType} is no instance of EventSearchResult`);

  const event = EventDtoFromJSON(result.result);
  const { t } = useTranslation(['event', 'common']);

  return (
    <SearchResult
      icon={eventIcon}
      title={event.title}
      link={`/events/${event.id}`}
      score={result.score}
      onClick={onClick}
      necessaryPermission={({ hasPermission, hasPermissionFromSection }) => {
        return (
          hasPermission('EVENT:CAN_SEE_DETAILS') ||
          event.owners
            .map((owner) => owner.section.id)
            .some((sectionId) => hasPermissionFromSection(sectionId, 'EVENT:CAN_SEE_DETAILS_OWN'))
        );
      }}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('event:englishTitle'),
            element: event.englishTitle,
          },
          {
            label: t('event:date_time_range.label'),
            element: t('common:format.datetime_range', { dateTimeRange: event.dateTimeRange }),
          },
          {
            label: t('event:edition.label'),
            element: event.edition.name,
          },
          {
            label: t('common:owners.label'),
            element: event.owners.map((owner) => owner.section.name).join(', '),
          },
          {
            label: t('event:realisation'),
            element: t(`event:realisationLabels.${event.venue.realisation}`),
          },
          {
            label: t('event:venue.location'),
            element: event.venue.location?.name,
          },
          {
            label: t('event:type.label'),
            element: t(`event:type.labels.${event.type}`),
          },
        ]}
      />
      {event.partners?.companies?.map((company) => (
        <SearchResultConnection
          key={company.id}
          connection={
            <SearchResultAttributes
              attributes={[{ element: t(`event:partner.labels.${event.partners!.partnerType!}`) }]}
            />
          }
          targetIcon={companyIcon}
          onTargetClick={onClick}
        >
          <CompanyReference size="sm" companyReference={company} hideIcon />
        </SearchResultConnection>
      ))}
    </SearchResult>
  );
}
