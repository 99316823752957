import useSWR from 'swr';
import { CustomRequestInit } from '../../../data-access/error-middleware';
import publicRundownApi from '../../../data-access/public-rundown-api';

export function usePublicRundown(publicationId: string) {
  const { data } = useSWR(
    ['publicRundown', publicationId],
    () => {
      return publicRundownApi.fetchPublishedRundownCalendar({ publicationId }, {
        allowedErrorCodes: [403],
      } as CustomRequestInit);
    },
    {
      suspense: true,
    },
  );

  return data!;
}

export function usePublishedRundownSettings(publicationId: string) {
  const { data } = useSWR(
    ['publishedRundownSettings', publicationId],
    () => {
      return publicRundownApi.fetchPublishedRundownSettings({ publicationId }, {
        allowedErrorCodes: [403],
      } as CustomRequestInit);
    },
    {
      suspense: true,
    },
  );

  return data!;
}

export function usePublishedLaneElement(publicationId: string, elementId: string) {
  const { data } = useSWR(
    ['publishedLaneElement', publicationId, elementId],
    () => {
      return publicRundownApi.fetchPublishedLaneElement({ publicationId, elementId }, {
        allowedErrorCodes: [403],
      } as CustomRequestInit);
    },
    {
      suspense: true,
    },
  );

  return data!;
}
