/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CostsDto
 */
export interface CostsDto {
    /**
     * 
     * @type {string}
     * @memberof CostsDto
     */
    charges?: CostsDtoChargesEnum;
    /**
     * 
     * @type {string}
     * @memberof CostsDto
     */
    details?: string;
}


/**
 * @export
 */
export const CostsDtoChargesEnum = {
    FREE_OF_CHARGE: 'FREE_OF_CHARGE',
    CHARGEABLE: 'CHARGEABLE'
} as const;
export type CostsDtoChargesEnum = typeof CostsDtoChargesEnum[keyof typeof CostsDtoChargesEnum];


/**
 * Check if a given object implements the CostsDto interface.
 */
export function instanceOfCostsDto(value: object): boolean {
    return true;
}

export function CostsDtoFromJSON(json: any): CostsDto {
    return CostsDtoFromJSONTyped(json, false);
}

export function CostsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CostsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'charges': json['charges'] == null ? undefined : json['charges'],
        'details': json['details'] == null ? undefined : json['details'],
    };
}

export function CostsDtoToJSON(value?: CostsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'charges': value['charges'],
        'details': value['details'],
    };
}

