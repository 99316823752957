import { isEqual, zipObjectDeep } from 'lodash-es';
import React, { useRef, useState } from 'react';
import { FieldValues, useFormContext, UseFormReturn, useWatch } from 'react-hook-form';
import { FieldPath } from 'react-hook-form/dist/types';
import invariant from 'tiny-invariant';

export default function useWatchChange<T extends FieldValues>(
  names: FieldPath<T>[],
  callback: (data: T) => Promise<void> | void,
  form?: UseFormReturn<T>,
) {
  const formContext = useFormContext<T>();
  const actualForm = form != null ? form : formContext;
  invariant(actualForm != null, 'Either formContext or a form must be provided');

  const namesRef = useRef(names);
  namesRef.current = names;
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  React.useEffect(() => {
    const subscription = actualForm.watch(async (data, { type, name }) => {
      if (type === 'change' && name != null && namesRef.current.includes(name)) {
        await callbackRef.current(data as T);
      }
    });

    return () => subscription.unsubscribe();
  }, [actualForm]);
}

// jaj, jst: works slightly different, this will isolate re-rendering at the custom hook level
export function useWatchChange2<T extends FieldValues>(
  names: FieldPath<T>[],
  callback: (data: T) => Promise<void> | void,
) {
  const newValue = useWatch({ name: names });
  const [lastValue, setLastValue] = useState(() => newValue);

  if (!isEqual(lastValue, newValue)) {
    callback(zipObjectDeep(names, newValue) as unknown as T);
    setLastValue(newValue);
  }
}
