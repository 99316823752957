/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CodeDto } from './CodeDto';
import {
    CodeDtoFromJSON,
    CodeDtoFromJSONTyped,
    CodeDtoToJSON,
} from './CodeDto';

/**
 * 
 * @export
 * @interface CreateCodeDto
 */
export interface CreateCodeDto {
    /**
     * 
     * @type {CodeDto}
     * @memberof CreateCodeDto
     */
    code: CodeDto;
    /**
     * 
     * @type {number}
     * @memberof CreateCodeDto
     */
    amount: number;
}

/**
 * Check if a given object implements the CreateCodeDto interface.
 */
export function instanceOfCreateCodeDto(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('amount' in value)) return false;
    return true;
}

export function CreateCodeDtoFromJSON(json: any): CreateCodeDto {
    return CreateCodeDtoFromJSONTyped(json, false);
}

export function CreateCodeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCodeDto {
    if (json == null) {
        return json;
    }
    return {
        
        'code': CodeDtoFromJSON(json['code']),
        'amount': json['amount'],
    };
}

export function CreateCodeDtoToJSON(value?: CreateCodeDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': CodeDtoToJSON(value['code']),
        'amount': value['amount'],
    };
}

