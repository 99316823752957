import React from 'react';
import { FieldPathValue, FieldValues, Path } from 'react-hook-form';
import { HistoryEntryDto } from '../../api';

export interface SubElementHistoryDisplaySettings<T extends FieldValues = any>
  extends Omit<HistoryDisplaySettings<T>, 'name' | 'subElementSettings'> {}

export default interface HistoryDisplaySettings<T extends FieldValues = any> {
  name: Path<T> | ((entry: HistoryEntryDto, value: T | undefined) => string | undefined);
  keyAttribute?: Path<T> | string;
  ignoreAttributes?: Path<T>[] | string[];
  attributeLabels: { [name in Path<T>]?: React.ReactNode | ((parent: T) => React.ReactNode) };
  valueFormatter: { [name in Path<T>]?: (value: FieldPathValue<T, name> | undefined, parent: T) => string | undefined };
  subElementSettings?: {
    [name in Path<T>]?: (value: FieldPathValue<T, name>) => SubElementHistoryDisplaySettings<any> | undefined;
  };
  diffEntireWord?: { [name in Path<T>]?: boolean };
  preformatted?: { [name in Path<T>]?: boolean };
  statusFormatter?: (value: T) => HistoryStatusEnum | undefined;
  showStringWithoutChange?: string;
  showEditDescription?: boolean;
  highlightAttributes?: string[];
  hideTableForCreatedAndDeleted?: boolean;
}

export enum HistoryStatusEnum {
  DELETED,
  ANONYMISED,
}

export function getConfiguredAttributes<T extends FieldValues>(settings: HistoryDisplaySettings<T>): Path<T>[] {
  return Object.keys(settings.attributeLabels) as Path<T>[];
}
