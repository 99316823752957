import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { BreadcrumbRouteObject } from '../../../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../../../ui/helmet/helmet-outlet';
import useFetcher from '../../../../util/swr/use-fetcher';
import CompanyPage from '../../../company/company-page/company-page';
import { fetchCompany } from '../../../company/company-queries';
import CompanyFormatPageContent from './company-format-page-content';

const companyFormatRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: '',
    element: <CompanyPage />,
    children: [
      {
        path: 'formats',
        element: <CompanyFormatPageContent />,
        handle: {
          helmet: <CompanyFormatsHelmet />,
        },
      },
    ],
  },
];

export default companyFormatRoutes;

function CompanyFormatsHelmet() {
  const { t } = useTranslation('relations');
  const params = useParams<{ companyId: string }>();
  invariant(params.companyId, 'Empty companyId');
  const company = useFetcher(fetchCompany, { id: params.companyId });

  return <Helmet title={t('companyToFormatHelmet', { title: company.name })} />;
}
