import { Container, ContainerProps, forwardRef, GridItem } from '@chakra-ui/react';
import React from 'react';
import PageSpinner from './page-spinner';

export interface PageContentProps extends ContainerProps {
  useFullWidth?: boolean;
  fixedHeight?: boolean;
}

function PageContent(
  { children, useFullWidth, fixedHeight, ...props }: PageContentProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <GridItem minHeight={0} ref={ref} overflowY="auto">
      <Container
        p={8}
        pb={10}
        maxW={useFullWidth ? 'full' : undefined}
        height={fixedHeight ? 'full' : undefined}
        {...props}
      >
        <React.Suspense fallback={<PageSpinner />}>
          <>{children}</>
        </React.Suspense>
      </Container>
    </GridItem>
  );
}

export default forwardRef<PageContentProps, 'div'>(PageContent);
