/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PublicEventReferenceDto } from './PublicEventReferenceDto';
import {
    PublicEventReferenceDtoFromJSON,
    PublicEventReferenceDtoFromJSONTyped,
    PublicEventReferenceDtoToJSON,
} from './PublicEventReferenceDto';
import type { PublishedEventSettingsDto } from './PublishedEventSettingsDto';
import {
    PublishedEventSettingsDtoFromJSON,
    PublishedEventSettingsDtoFromJSONTyped,
    PublishedEventSettingsDtoToJSON,
} from './PublishedEventSettingsDto';

/**
 * Settings for the public rundown.
 * @export
 * @interface PublishedRundownSettingsDto
 */
export interface PublishedRundownSettingsDto {
    /**
     * 
     * @type {PublishedEventSettingsDto}
     * @memberof PublishedRundownSettingsDto
     */
    eventSettings: PublishedEventSettingsDto;
    /**
     * 
     * @type {Array<PublicEventReferenceDto>}
     * @memberof PublishedRundownSettingsDto
     */
    connectedEvents: Array<PublicEventReferenceDto>;
}

/**
 * Check if a given object implements the PublishedRundownSettingsDto interface.
 */
export function instanceOfPublishedRundownSettingsDto(value: object): boolean {
    if (!('eventSettings' in value)) return false;
    if (!('connectedEvents' in value)) return false;
    return true;
}

export function PublishedRundownSettingsDtoFromJSON(json: any): PublishedRundownSettingsDto {
    return PublishedRundownSettingsDtoFromJSONTyped(json, false);
}

export function PublishedRundownSettingsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublishedRundownSettingsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'eventSettings': PublishedEventSettingsDtoFromJSON(json['eventSettings']),
        'connectedEvents': ((json['connectedEvents'] as Array<any>).map(PublicEventReferenceDtoFromJSON)),
    };
}

export function PublishedRundownSettingsDtoToJSON(value?: PublishedRundownSettingsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'eventSettings': PublishedEventSettingsDtoToJSON(value['eventSettings']),
        'connectedEvents': ((value['connectedEvents'] as Array<any>).map(PublicEventReferenceDtoToJSON)),
    };
}

