/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FormatReferenceDto } from './FormatReferenceDto';
import {
    FormatReferenceDtoFromJSON,
    FormatReferenceDtoFromJSONTyped,
    FormatReferenceDtoToJSON,
} from './FormatReferenceDto';
import type { ProfessionDto } from './ProfessionDto';
import {
    ProfessionDtoFromJSON,
    ProfessionDtoFromJSONTyped,
    ProfessionDtoToJSON,
} from './ProfessionDto';

/**
 * 
 * @export
 * @interface BuyerOccupationFormatDto
 */
export interface BuyerOccupationFormatDto {
    /**
     * 
     * @type {string}
     * @memberof BuyerOccupationFormatDto
     */
    jobTitle: string;
    /**
     * 
     * @type {ProfessionDto}
     * @memberof BuyerOccupationFormatDto
     */
    profession: ProfessionDto;
    /**
     * 
     * @type {FormatReferenceDto}
     * @memberof BuyerOccupationFormatDto
     */
    format: FormatReferenceDto;
}

/**
 * Check if a given object implements the BuyerOccupationFormatDto interface.
 */
export function instanceOfBuyerOccupationFormatDto(value: object): boolean {
    if (!('jobTitle' in value)) return false;
    if (!('profession' in value)) return false;
    if (!('format' in value)) return false;
    return true;
}

export function BuyerOccupationFormatDtoFromJSON(json: any): BuyerOccupationFormatDto {
    return BuyerOccupationFormatDtoFromJSONTyped(json, false);
}

export function BuyerOccupationFormatDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuyerOccupationFormatDto {
    if (json == null) {
        return json;
    }
    return {
        
        'jobTitle': json['jobTitle'],
        'profession': ProfessionDtoFromJSON(json['profession']),
        'format': FormatReferenceDtoFromJSON(json['format']),
    };
}

export function BuyerOccupationFormatDtoToJSON(value?: BuyerOccupationFormatDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'jobTitle': value['jobTitle'],
        'profession': ProfessionDtoToJSON(value['profession']),
        'format': FormatReferenceDtoToJSON(value['format']),
    };
}

