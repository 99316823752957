// https://tc39.es/proposal-unified-intl-numberformat/section6/locales-currencies-tz_proposed_out.html#table-sanctioned-simple-unit-identifiers
export type Unit = 'byte' | 'kilobyte' | 'megabyte' | 'gigabyte' | 'terabyte';

const sizes: Unit[] = ['byte', 'kilobyte', 'megabyte', 'gigabyte', 'terabyte'];

export default function formatFileSize(bytes: number): { amount: number; unit: Unit } {
  if (bytes === 0) return { amount: 0, unit: 'byte' };
  const i = parseInt('' + Math.floor(Math.log(bytes) / Math.log(1024)));
  return { amount: bytes / Math.pow(1024, i), unit: sizes[i] };
}
