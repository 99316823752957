/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { TagReferenceDto } from './TagReferenceDto';
import {
    TagReferenceDtoFromJSON,
    TagReferenceDtoFromJSONTyped,
    TagReferenceDtoToJSON,
} from './TagReferenceDto';
import type { TagRelationTypeDto } from './TagRelationTypeDto';
import {
    TagRelationTypeDtoFromJSON,
    TagRelationTypeDtoFromJSONTyped,
    TagRelationTypeDtoToJSON,
} from './TagRelationTypeDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * A relation between a tag and a person
 * @export
 * @interface TagPersonRelationDto
 */
export interface TagPersonRelationDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof TagPersonRelationDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof TagPersonRelationDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {TagReferenceDto}
     * @memberof TagPersonRelationDto
     */
    tag: TagReferenceDto;
    /**
     * 
     * @type {TagRelationTypeDto}
     * @memberof TagPersonRelationDto
     */
    relationType: TagRelationTypeDto;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof TagPersonRelationDto
     */
    relation: PersonReferenceDto;
}

/**
 * Check if a given object implements the TagPersonRelationDto interface.
 */
export function instanceOfTagPersonRelationDto(value: object): boolean {
    if (!('tag' in value)) return false;
    if (!('relationType' in value)) return false;
    if (!('relation' in value)) return false;
    return true;
}

export function TagPersonRelationDtoFromJSON(json: any): TagPersonRelationDto {
    return TagPersonRelationDtoFromJSONTyped(json, false);
}

export function TagPersonRelationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagPersonRelationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'tag': TagReferenceDtoFromJSON(json['tag']),
        'relationType': TagRelationTypeDtoFromJSON(json['relationType']),
        'relation': PersonReferenceDtoFromJSON(json['relation']),
    };
}

export function TagPersonRelationDtoToJSON(value?: TagPersonRelationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'tag': TagReferenceDtoToJSON(value['tag']),
        'relationType': TagRelationTypeDtoToJSON(value['relationType']),
        'relation': PersonReferenceDtoToJSON(value['relation']),
    };
}

