import { intersection, isEqual, isPlainObject, keys } from 'lodash-es';
import React, { MutableRefObject, useMemo, useRef, useState } from 'react';
import { FieldValues, get, Path, useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { FailureChangesDto, HistoryEntryDto, ResponseError, VersionDto } from '../../api';
import { asFailureDto } from '../../data-access/as-failure-dto';
import { isOptimisticLockingFailure } from '../../data-access/optimistic-locking';
import now from '../../util/now';
import useDialog from '../../util/use-dialog/use-dialog';
import HistoryDisplaySettings, { getConfiguredAttributes } from '../history/history-display-settings';
import OptimisticLockingDialog from './optimistic-locking-dialog';

export interface OptimisticLockingDialogProps<T extends FieldValues> {
  historyDisplaySettings: HistoryDisplaySettings<T>;
  form?: UseFormReturn<T>;
  mapFunction?: (json: any) => T;
  clearEntity?: (entity: T) => T;
  objectFieldPaths?: string[];
}

// returns a merge dialog for optimistic locking issues when the function inside the wrapper throws an optimistic locking exception
export default function useOptimisticLockingDialog<T extends FieldValues>({
  historyDisplaySettings,
  form,
  mapFunction,
  // TODO ClearEntity ist nur ein Workaround. Die EditionForm hat Änderungen die keine sein sollten. Dieses Problem muss geloöst werden.
  clearEntity,
  objectFieldPaths = [],
}: OptimisticLockingDialogProps<T>): {
  /**
   * @return whether an optimistic lock was encountered
   */
  catchOptimisticLockingFailure: (functionPossiblyThrowingOptimisticLock: () => Promise<any>) => Promise<boolean>;
  optimisticLockDialog: React.ReactNode;
} {
  const { t } = useTranslation('common');
  const [isOptimisticLockDialogOpen, onOptimisticLockDialogClose, openOptimisticLockDialog] = useDialog<boolean>();
  const [optimisticLockChanges, setOptimisticLockChanges] = useState<FailureChangesDto | undefined>();

  const { getValues, reset, setValue } = useFormContext() ?? form;

  // get initial values of the form. Used as 'before'
  const initialValues = useRef<T>(getValues() as T);

  return useMemo(() => {
    const ownChangesVersion: VersionDto = {
      revision: '',
      modifiedAt: new Date(now()),
      modifiedBy: { id: 'du', displayName: t('optimistic_lock.you'), email: 'unused@example.com' },
    };

    const applyChangeForAttribute = (object: T, attribute: Path<T>, shouldDirty: boolean) => {
      let newValue = get(object, attribute);

      if (newValue instanceof Date && isNaN(newValue.getTime())) {
        newValue = undefined;
      }

      if (isPlainObject(newValue) && !objectFieldPaths.includes(attribute)) {
        keys(newValue).forEach((path) => {
          applyChangeForAttribute(object, (attribute + '.' + path) as Path<T>, shouldDirty);
        });
      } else {
        const actualNewValue = newValue == null ? null : newValue;
        setValue(attribute, actualNewValue, { shouldDirty });
      }
    };

    const executeClear = (entity: T): T => {
      let result = entity;
      if (clearEntity != null) {
        result = clearEntity(entity);
      }
      return result;
    };

    // changes in the form mapped to the same dto as the server sent
    const ownChanges: HistoryEntryDto = {
      version: ownChangesVersion,
      before: initialValues.current,
      after: executeClear(getValues() as T),
    };

    // method for applying changes to the form.
    const ownChangedAttributes = getOwnChangedAttributes(historyDisplaySettings, initialValues, ownChanges);
    const applyOwnChanges = () => {
      ownChangedAttributes.forEach((attribute) =>
        applyChangeForAttribute(mapFunction?.(ownChanges.after) ?? ownChanges.after, attribute, true),
      );
    };

    const changedAttributes = getOtherChangedAttributes(historyDisplaySettings, optimisticLockChanges);
    const applyOtherChanges = () => {
      optimisticLockChanges?.historyEntries.forEach((entry) => {
        getConfiguredAttributes(historyDisplaySettings)
          .filter((attribute) => !isEqual(get(entry.before, attribute), get(entry.after, attribute)))
          .forEach((attribute) => {
            applyChangeForAttribute(mapFunction?.(entry.after) ?? entry.after, attribute, false);
          });
      });
    };

    // we reset the form and apply all changes from the server plus the revision change
    const onRevert = () => {
      reset();
      applyOtherChanges();
      setValue('version.revision', optimisticLockChanges?.rev);
    };

    // we reset the form and apply all changes from the server then all changes from the user plus the revision change
    const onMerge = () => {
      reset();
      applyOtherChanges();
      applyOwnChanges();
      setValue('version.revision', optimisticLockChanges?.rev);
    };

    // paths of attributes that both the server and the current user changed (for highlighting)
    const pathsWithIssues = intersection(changedAttributes, ownChangedAttributes) as Path<T>[];

    return {
      catchOptimisticLockingFailure: async (functionPossiblyThrowingOptimisticLock: () => any): Promise<boolean> => {
        try {
          await functionPossiblyThrowingOptimisticLock();
          return false;
        } catch (e) {
          if (!(e instanceof ResponseError)) {
            throw e;
          }
          const response = (e as ResponseError).response as Response;
          const failureDto = await asFailureDto(response);
          invariant(failureDto != null, 'Invalid response, missing failure dto');

          if (!isOptimisticLockingFailure(failureDto)) {
            throw e;
          }
          setOptimisticLockChanges(failureDto.changes);
          await openOptimisticLockDialog();
          return true;
        }
      },
      optimisticLockDialog: (
        <OptimisticLockingDialog<T>
          ownChanges={ownChanges}
          otherChanges={optimisticLockChanges?.historyEntries}
          isOpen={isOptimisticLockDialogOpen}
          onClose={onOptimisticLockDialogClose}
          historyDisplaySettings={historyDisplaySettings}
          issues={pathsWithIssues}
          onRevert={onRevert}
          onMerge={onMerge}
        />
      ),
    };
  }, [
    clearEntity,
    getValues,
    historyDisplaySettings,
    isOptimisticLockDialogOpen,
    mapFunction,
    objectFieldPaths,
    onOptimisticLockDialogClose,
    openOptimisticLockDialog,
    optimisticLockChanges,
    reset,
    setValue,
    t,
  ]);
}

function getOwnChangedAttributes<T extends FieldValues>(
  historyDisplaySettings: HistoryDisplaySettings<T>,
  initialValues: MutableRefObject<T>,
  ownChanges: HistoryEntryDto,
) {
  return getConfiguredAttributes(historyDisplaySettings).filter(
    (attribute) => !isEqual(get(initialValues.current, attribute), get(ownChanges.after, attribute)),
  );
}

function getOtherChangedAttributes<T extends FieldValues>(
  historyDisplaySettings: HistoryDisplaySettings<T>,
  optimisticLockChanges: FailureChangesDto | undefined,
) {
  return optimisticLockChanges?.historyEntries.flatMap((entry) =>
    getConfiguredAttributes(historyDisplaySettings).filter(
      (attribute) => !isEqual(get(entry.before, attribute), get(entry.after, attribute)),
    ),
  );
}
