/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  HistoryPageDto,
  PageableDto,
  TagDto,
  TagPageDto,
  TagRelationDto,
  TagRelationPageDto,
  TagRelationTypeDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    TagDtoFromJSON,
    TagDtoToJSON,
    TagPageDtoFromJSON,
    TagPageDtoToJSON,
    TagRelationDtoFromJSON,
    TagRelationDtoToJSON,
    TagRelationPageDtoFromJSON,
    TagRelationPageDtoToJSON,
    TagRelationTypeDtoFromJSON,
    TagRelationTypeDtoToJSON,
} from '../models/index';

export interface CreateTagRequest {
    tagDto: TagDto;
}

export interface CreateTagRelationsRequest {
    tagRelationDto: Array<TagRelationDto>;
}

export interface DeleteTagRequest {
    id: string;
}

export interface DeleteTagRelationsRequest {
    ids: Array<string>;
}

export interface FetchTagRequest {
    id: string;
}

export interface FetchTagHasRelationsHistoryByRelationRequest {
    id: string;
    relationType: TagRelationTypeDto;
    pageable?: PageableDto;
}

export interface FetchTagHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchTagRelationsHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface SearchTagRelationsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchTagsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface TagHasRelationsRequest {
    id: string;
}

export interface TagRelationExistRequest {
    tagId: string;
    relationIds: Array<string>;
    relationType: TagRelationTypeDto;
}

export interface UpdateTagRequest {
    id: string;
    tagDto: TagDto;
}

/**
 * 
 */
export class TagApi extends runtime.BaseAPI {

    /**
     * Create a new tag.
     */
    async createTagRaw(requestParameters: CreateTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['tagDto'] == null) {
            throw new runtime.RequiredError(
                'tagDto',
                'Required parameter "tagDto" was null or undefined when calling createTag().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tags`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TagDtoToJSON(requestParameters['tagDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new tag.
     */
    async createTag(requestParameters: CreateTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createTagRaw(requestParameters, initOverrides);
    }

    /**
     * Create multiple tag relations.
     */
    async createTagRelationsRaw(requestParameters: CreateTagRelationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['tagRelationDto'] == null) {
            throw new runtime.RequiredError(
                'tagRelationDto',
                'Required parameter "tagRelationDto" was null or undefined when calling createTagRelations().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tags/relations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['tagRelationDto']!.map(TagRelationDtoToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create multiple tag relations.
     */
    async createTagRelations(requestParameters: CreateTagRelationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createTagRelationsRaw(requestParameters, initOverrides);
    }

    /**
     * Delete the tag and all its relations.
     */
    async deleteTagRaw(requestParameters: DeleteTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteTag().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the tag and all its relations.
     */
    async deleteTag(requestParameters: DeleteTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTagRaw(requestParameters, initOverrides);
    }

    /**
     * Delete tag relations, for the given combination of tag and a business object.
     */
    async deleteTagRelationsRaw(requestParameters: DeleteTagRelationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['ids'] == null) {
            throw new runtime.RequiredError(
                'ids',
                'Required parameter "ids" was null or undefined when calling deleteTagRelations().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ids'] != null) {
            queryParameters['ids'] = requestParameters['ids']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tags/relations`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete tag relations, for the given combination of tag and a business object.
     */
    async deleteTagRelations(requestParameters: DeleteTagRelationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTagRelationsRaw(requestParameters, initOverrides);
    }

    /**
     * Get the tag business object.
     */
    async fetchTagRaw(requestParameters: FetchTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TagDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchTag().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagDtoFromJSON(jsonValue));
    }

    /**
     * Get the tag business object.
     */
    async fetchTag(requestParameters: FetchTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TagDto> {
        const response = await this.fetchTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fetchTagHasRelationsHistoryByRelationRaw(requestParameters: FetchTagHasRelationsHistoryByRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchTagHasRelationsHistoryByRelation().'
            );
        }

        if (requestParameters['relationType'] == null) {
            throw new runtime.RequiredError(
                'relationType',
                'Required parameter "relationType" was null or undefined when calling fetchTagHasRelationsHistoryByRelation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        if (requestParameters['relationType'] != null) {
            queryParameters['relationType'] = requestParameters['relationType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tags/versions-by-relation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchTagHasRelationsHistoryByRelation(requestParameters: FetchTagHasRelationsHistoryByRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchTagHasRelationsHistoryByRelationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the tag.
     */
    async fetchTagHistoryRaw(requestParameters: FetchTagHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchTagHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tags/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the tag.
     */
    async fetchTagHistory(requestParameters: FetchTagHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchTagHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fetchTagRelationsHistoryRaw(requestParameters: FetchTagRelationsHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchTagRelationsHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tags/{id}/relations-versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchTagRelationsHistory(requestParameters: FetchTagRelationsHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchTagRelationsHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for tag with its relations
     */
    async searchTagRelationsRaw(requestParameters: SearchTagRelationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TagRelationPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tags/relations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagRelationPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for tag with its relations
     */
    async searchTagRelations(requestParameters: SearchTagRelationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TagRelationPageDto> {
        const response = await this.searchTagRelationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for tags.
     */
    async searchTagsRaw(requestParameters: SearchTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TagPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for tags.
     */
    async searchTags(requestParameters: SearchTagsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TagPageDto> {
        const response = await this.searchTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get whether the tag has any relation.
     */
    async tagHasRelationsRaw(requestParameters: TagHasRelationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling tagHasRelations().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tags/{id}/has-relations`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get whether the tag has any relation.
     */
    async tagHasRelations(requestParameters: TagHasRelationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.tagHasRelationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get whether the combination of tag and a business object exists for a connection.
     */
    async tagRelationExistRaw(requestParameters: TagRelationExistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['tagId'] == null) {
            throw new runtime.RequiredError(
                'tagId',
                'Required parameter "tagId" was null or undefined when calling tagRelationExist().'
            );
        }

        if (requestParameters['relationIds'] == null) {
            throw new runtime.RequiredError(
                'relationIds',
                'Required parameter "relationIds" was null or undefined when calling tagRelationExist().'
            );
        }

        if (requestParameters['relationType'] == null) {
            throw new runtime.RequiredError(
                'relationType',
                'Required parameter "relationType" was null or undefined when calling tagRelationExist().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['tagId'] != null) {
            queryParameters['tagId'] = requestParameters['tagId'];
        }

        if (requestParameters['relationIds'] != null) {
            queryParameters['relationIds'] = requestParameters['relationIds']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['relationType'] != null) {
            queryParameters['relationType'] = requestParameters['relationType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tags/relationExists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get whether the combination of tag and a business object exists for a connection.
     */
    async tagRelationExist(requestParameters: TagRelationExistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.tagRelationExistRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new tag.
     */
    async templateTagRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TagDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tags/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new tag.
     */
    async templateTag(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TagDto> {
        const response = await this.templateTagRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the existing tag.
     */
    async updateTagRaw(requestParameters: UpdateTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateTag().'
            );
        }

        if (requestParameters['tagDto'] == null) {
            throw new runtime.RequiredError(
                'tagDto',
                'Required parameter "tagDto" was null or undefined when calling updateTag().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TagDtoToJSON(requestParameters['tagDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the existing tag.
     */
    async updateTag(requestParameters: UpdateTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateTagRaw(requestParameters, initOverrides);
    }

}
