/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OperationDto } from './OperationDto';
import {
    OperationDtoFromJSON,
    OperationDtoFromJSONTyped,
    OperationDtoToJSON,
} from './OperationDto';
import type { PermissionsDto } from './PermissionsDto';
import {
    PermissionsDtoFromJSON,
    PermissionsDtoFromJSONTyped,
    PermissionsDtoToJSON,
} from './PermissionsDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface ReportDto
 */
export interface ReportDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof ReportDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof ReportDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {PermissionsDto}
     * @memberof ReportDto
     */
    permissions?: PermissionsDto;
    /**
     * 
     * @type {string}
     * @memberof ReportDto
     */
    name: string;
    /**
     * 
     * @type {Array<OperationDto>}
     * @memberof ReportDto
     */
    operations: Array<OperationDto>;
}

/**
 * Check if a given object implements the ReportDto interface.
 */
export function instanceOfReportDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('operations' in value)) return false;
    return true;
}

export function ReportDtoFromJSON(json: any): ReportDto {
    return ReportDtoFromJSONTyped(json, false);
}

export function ReportDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'permissions': json['permissions'] == null ? undefined : PermissionsDtoFromJSON(json['permissions']),
        'name': json['name'],
        'operations': ((json['operations'] as Array<any>).map(OperationDtoFromJSON)),
    };
}

export function ReportDtoToJSON(value?: ReportDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'permissions': PermissionsDtoToJSON(value['permissions']),
        'name': value['name'],
        'operations': ((value['operations'] as Array<any>).map(OperationDtoToJSON)),
    };
}

