/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';
import type { VisaStatusDto } from './VisaStatusDto';
import {
    VisaStatusDtoFromJSON,
    VisaStatusDtoFromJSONTyped,
    VisaStatusDtoToJSON,
} from './VisaStatusDto';

/**
 * Organizational representation of a visa
 * @export
 * @interface VisaListItemDto
 */
export interface VisaListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof VisaListItemDto
     */
    id?: string;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof VisaListItemDto
     */
    applicant: PersonReferenceDto;
    /**
     * 
     * @type {boolean}
     * @memberof VisaListItemDto
     */
    personWithGuestSection: boolean;
    /**
     * 
     * @type {Array<SectionReferenceDto>}
     * @memberof VisaListItemDto
     */
    guestSections: Array<SectionReferenceDto>;
    /**
     * 
     * @type {string}
     * @memberof VisaListItemDto
     */
    passportNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof VisaListItemDto
     */
    dateOfIssue?: Date;
    /**
     * 
     * @type {Date}
     * @memberof VisaListItemDto
     */
    dateOfExpiry?: Date;
    /**
     * 
     * @type {VisaStatusDto}
     * @memberof VisaListItemDto
     */
    status: VisaStatusDto;
    /**
     * 
     * @type {VersionDto}
     * @memberof VisaListItemDto
     */
    version?: VersionDto;
}

/**
 * Check if a given object implements the VisaListItemDto interface.
 */
export function instanceOfVisaListItemDto(value: object): boolean {
    if (!('applicant' in value)) return false;
    if (!('personWithGuestSection' in value)) return false;
    if (!('guestSections' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function VisaListItemDtoFromJSON(json: any): VisaListItemDto {
    return VisaListItemDtoFromJSONTyped(json, false);
}

export function VisaListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisaListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'applicant': PersonReferenceDtoFromJSON(json['applicant']),
        'personWithGuestSection': json['personWithGuestSection'],
        'guestSections': ((json['guestSections'] as Array<any>).map(SectionReferenceDtoFromJSON)),
        'passportNumber': json['passportNumber'] == null ? undefined : json['passportNumber'],
        'dateOfIssue': json['dateOfIssue'] == null ? undefined : (new Date(json['dateOfIssue'])),
        'dateOfExpiry': json['dateOfExpiry'] == null ? undefined : (new Date(json['dateOfExpiry'])),
        'status': VisaStatusDtoFromJSON(json['status']),
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
    };
}

export function VisaListItemDtoToJSON(value?: VisaListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'applicant': PersonReferenceDtoToJSON(value['applicant']),
        'personWithGuestSection': value['personWithGuestSection'],
        'guestSections': ((value['guestSections'] as Array<any>).map(SectionReferenceDtoToJSON)),
        'passportNumber': value['passportNumber'],
        'dateOfIssue': value['dateOfIssue'] == null ? undefined : ((value['dateOfIssue']).toISOString().substring(0,10)),
        'dateOfExpiry': value['dateOfExpiry'] == null ? undefined : ((value['dateOfExpiry']).toISOString().substring(0,10)),
        'status': VisaStatusDtoToJSON(value['status']),
        'version': VersionDtoToJSON(value['version']),
    };
}

