import React from 'react';
import Translate from '../../../util/translate/translate';

export default function ParticipationBreadcrumb() {
  return (
    <>
      <Translate ns="participation" i18nKey="participation" />
    </>
  );
}
