import registry from '../../registry';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import { getMailingPageRoutes, getMailingRoutes } from './mailing-type-extension';

const mailingRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'mailings',
    handle: {
      breadcrumb: <Translate ns="mailing" i18nKey="lister.title" />,
    },
    children: [
      {
        index: true,
        lazy: () => import('./mailing-lister/mailing-lister-route'),
      },
      {
        path: ':mailingId',
        lazy: () => import('./mailing-page/mailing-route'),
        children: [
          {
            lazy: () => import('./mailing-page/mailing-page-route'),
            children: [
              {
                index: true,
                lazy: () => import('./mailing-viewer/mailing-viewer-route'),
              },
              {
                path: 'recipients',
                lazy: () => import('./mailing-recipient-lister/mailing-recipient-lister-route'),
              },
              {
                path: 'tracking',
                lazy: () => import('./mailing-tracking/mailing-tracking-route'),
              },
              {
                path: 'analytics',
                lazy: () => import('./mailing-analytics/mailing-analytics-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./mailing-history/mailing-history-route'),
              },
              ...getMailingPageRoutes(registry),
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./mailing-editor/edit-mailing-route'),
          },
          {
            path: 'copy',
            lazy: () => import('./mailing-editor/copy-mailing-route'),
          },
          {
            path: 'recipients/edit',
            lazy: () => import('./mailing-recipient-editor/edit-mailing-recipients-route'),
          },
          ...getMailingRoutes(registry),
        ],
      },
      {
        path: 'new',
        lazy: () => import('./mailing-editor/new-mailing-route'),
      },
    ],
  },
];

export default mailingRoutes;
