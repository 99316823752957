import { useCallback, useState } from 'react';

export default function useSessionStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = sessionStorage.getItem(key);
      return item != null ? JSON.parse(item) : initialValue;
    } catch (_) {
      return initialValue;
    }
  });

  const setStoredValueAct = useCallback(
    (value: T) => {
      setStoredValue(value);
      sessionStorage.setItem(key, JSON.stringify(value));
    },
    [key],
  );

  return [storedValue, setStoredValueAct] as const;
}
