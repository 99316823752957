import React from 'react';
import { FormatCompanyListItemDto } from '../../../../api';
import { DataTableColumn } from '../../../../ui/data-table';
import DateFilter from '../../../../ui/data-table/filter/date-filter';
import DateRangeFilter from '../../../../ui/data-table/filter/date-range-filter';
import EnumFilter from '../../../../ui/data-table/filter/enum-filter';
import StringFilter from '../../../../ui/data-table/filter/string-filter';
import LastModifiedLabel from '../../../../ui/version/last-modified-label';
import Translate from '../../../../util/translate/translate';
import FormatReference from '../../../format/format-reference/format-reference';
import { CONNECTION_TYPE_OPTIONS, STATUS_OPTIONS } from '../format-company-enum-constants';
import { useTranslation } from 'react-i18next';

export function useCompanyFormatTableColumns(): DataTableColumn<FormatCompanyListItemDto>[] {
  const { t } = useTranslation('format');
  return React.useMemo(
    () => [
      {
        key: 'format',
        name: <Translate ns="format" i18nKey="companyConnections.formats" />,
        cellProps: {
          whiteSpace: 'nowrap',
          textAlign: 'left',
        },
        renderCell: (data: FormatCompanyListItemDto) => <FormatReference format={data.format!} />,
        filter: (
          <StringFilter
            label={<Translate ns="format" i18nKey="companyConnections.formats" />}
            availableOperators={['CONTAIN', 'NOT_CONTAIN', 'START_WITH', 'NOT_START_WITH', 'END_WITH', 'NOT_END_WITH']}
          />
        ),
        isSortable: true,
        sortProperty: 'format',
      },
      {
        key: 'connectionType',
        name: <Translate ns="format" i18nKey="formatCompany.connectionType" />,
        renderCell: (data) => (
          <Translate ns="format">{(t) => t(`formatCompany.connectionTypeOptions.${data.connectionType}`)}</Translate>
        ),
        isSortable: true,
        filter: (
          <EnumFilter
            label={<Translate ns="format" i18nKey="formatCompany.connectionType" />}
            options={CONNECTION_TYPE_OPTIONS}
            renderOptionLabel={(key) => t(`formatCompany.connectionTypeOptions.${key}`)}
          />
        ),
      },
      {
        key: 'validityPeriod',
        name: <Translate ns="format" i18nKey="formatCompany.validityPeriod" />,
        cellProps: {
          whiteSpace: 'nowrap',
          width: '20%',
        },
        renderCell: (format) => (
          <Translate ns="common">{(t) => t('format.date_range', { dateRange: format.validityPeriod })}</Translate>
        ),
        isSortable: true,
        sortProperty: 'validityPeriod.start',
        filter: <DateRangeFilter />,
      },
      {
        key: 'status',
        name: <Translate ns="format" i18nKey="formatCompany.status" />,
        renderCell: (data) => (
          <Translate ns="format">{(t) => t(`formatCompany.statusOptions.${data.status}`)}</Translate>
        ),
        isSortable: true,
        filter: (
          <EnumFilter
            label={<Translate ns="format" i18nKey="formatCompany.status" />}
            options={STATUS_OPTIONS}
            renderOptionLabel={(key) => t(`formatCompany.statusOptions.${key}`)}
          />
        ),
      },
    ],
    [t],
  );
}

export default function useCompanyFormatTableColumnsWithModifiedAt(): DataTableColumn<FormatCompanyListItemDto>[] {
  const companyFormatTableColumns = useCompanyFormatTableColumns();
  return React.useMemo(() => {
    return [
      ...companyFormatTableColumns,
      {
        key: 'modifiedAt',
        name: <Translate ns="common" i18nKey="viewer.last_modification" />,
        isSortable: true,
        renderCell: (data) => <LastModifiedLabel version={data.version} />,
        filter: <DateFilter showTimeSelect />,
        filterWidth: 'md',
      },
    ];
  }, [companyFormatTableColumns]);
}
