import { Alert, AlertIcon, Center, chakra, Stack } from '@chakra-ui/react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { login, LoginDto } from '../../../../data-access/auth';
import { readAuthToken } from '../../../../data-access/auth-token-storage';
import Form from '../../../../ui/form/form';
import InputFormControl from '../../../../ui/form/input-form-control';
import SubmitButton from '../../../../ui/form/submit-button';
import useRedirectTo from '../../../../util/use-redirect-to/use-redirect-to';
import { useSwitchMobile } from '../../switch-mobile/switch-mobile';
import logoBerlinale from './logo_berlinale.svg';
import logoBerlinalX from './logo_berlinalx.svg';

export default function Login() {
  const { t } = useTranslation('app');
  const form = useForm<LoginDto>();
  const [invalidCredentials, setInvalidCredentials] = React.useState(false);
  const navigate = useNavigate();
  const redirectTo = useRedirectTo() ?? '/';
  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const { switchMobile, switchMobileDialog, isSwitchMobileDialogOpen } = useSwitchMobile();

  const handleValid = async (data: LoginDto) => {
    const success = await login(data);

    setInvalidCredentials(!success);

    if (success) {
      if (redirectTo === '/' && (await switchMobile())) {
        navigate(`/mobile/events`);
      } else {
        navigate(redirectTo);
      }
    }
  };

  if (readAuthToken() != null && !isSwitchMobileDialogOpen) {
    return <Navigate to={redirectTo} />;
  }

  return (
    <>
      <FormProvider {...form}>
        <Center
          w="full"
          h="full"
          bgColor="gray.900"
          bgImage={logoBerlinale}
          bgSize="108% auto"
          bgRepeat="no-repeat"
          bgPosition="70% 30%"
          position="relative"
        >
          <chakra.header position="absolute" top={20} left={20}>
            <h1>
              <img src={logoBerlinalX} alt={t('project_title')} />
            </h1>
          </chakra.header>
          <Stack>
            <Form onValid={handleValid} submitOnEnterKey initialFocusRef={initialFocusRef}>
              <Stack spacing={5}>
                <chakra.h2 fontSize="3xl" lineHeight={9} fontWeight="semibold" color="teal.400">
                  {t('login.title')}
                </chakra.h2>
                {invalidCredentials && (
                  <Alert status="error">
                    <AlertIcon />
                    {t('login.unknown_credentials')}
                  </Alert>
                )}
                <Stack
                  spacing={8}
                  sx={{
                    w: 'xs',
                    bg: 'layer.01',
                    borderRadius: 'lg',
                    p: 6,
                    pt: 4,
                    shadow: 'base',
                  }}
                >
                  <Stack>
                    <InputFormControl<LoginDto>
                      name="username"
                      label={t('login.username')}
                      isRequired
                      ref={initialFocusRef}
                    />
                    <InputFormControl<LoginDto>
                      name="password"
                      label={t('login.password')}
                      type="password"
                      isRequired
                    />
                  </Stack>

                  <SubmitButton type="submit" variant="primary" w="full">
                    {t('login.submit')}
                  </SubmitButton>
                </Stack>
              </Stack>
            </Form>
          </Stack>
        </Center>
      </FormProvider>
      {switchMobileDialog}
    </>
  );
}
