/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyEmailAddressDto
 */
export interface CompanyEmailAddressDto {
    /**
     * An email address
     * @type {string}
     * @memberof CompanyEmailAddressDto
     */
    email: string;
    /**
     * Can the information be published?
     * @type {boolean}
     * @memberof CompanyEmailAddressDto
     */
    publish?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CompanyEmailAddressDto
     */
    timestamp?: Date;
    /**
     * The label for the email address
     * @type {string}
     * @memberof CompanyEmailAddressDto
     */
    label: CompanyEmailAddressDtoLabelEnum;
    /**
     * The comment for the email address
     * @type {string}
     * @memberof CompanyEmailAddressDto
     */
    comment?: string;
}


/**
 * @export
 */
export const CompanyEmailAddressDtoLabelEnum = {
    CONTACT: 'CONTACT',
    LOGISTICS: 'LOGISTICS',
    STORE: 'STORE',
    PRESS_DEPARTMENT: 'PRESS_DEPARTMENT',
    TECHNOLOGY: 'TECHNOLOGY',
    OTHER: 'OTHER'
} as const;
export type CompanyEmailAddressDtoLabelEnum = typeof CompanyEmailAddressDtoLabelEnum[keyof typeof CompanyEmailAddressDtoLabelEnum];


/**
 * Check if a given object implements the CompanyEmailAddressDto interface.
 */
export function instanceOfCompanyEmailAddressDto(value: object): boolean {
    if (!('email' in value)) return false;
    if (!('label' in value)) return false;
    return true;
}

export function CompanyEmailAddressDtoFromJSON(json: any): CompanyEmailAddressDto {
    return CompanyEmailAddressDtoFromJSONTyped(json, false);
}

export function CompanyEmailAddressDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyEmailAddressDto {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'publish': json['publish'] == null ? undefined : json['publish'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
        'label': json['label'],
        'comment': json['comment'] == null ? undefined : json['comment'],
    };
}

export function CompanyEmailAddressDtoToJSON(value?: CompanyEmailAddressDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'publish': value['publish'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
        'label': value['label'],
        'comment': value['comment'],
    };
}

