import { Editor, Range, Transforms } from 'slate';
import invariant from 'tiny-invariant';
import { ParagraphElement } from '../format-types';
import { WithFunction } from './create-editor';

const withEnhancedBreaks: WithFunction = (editor) => {
  const { insertBreak } = editor;

  // Insert a soft break when hitting shift + enter.
  editor.insertSoftBreak = () => {
    Editor.insertText(editor, '\n');
  };

  // Always start with a new paragraph when hitting enter at the end of an element (even if the current node is e.g., a heading).
  editor.insertBreak = () => {
    invariant(editor.selection != null, 'Selection is required.');

    // Text is selected, proceed with default behavior.
    if (Range.isRange(editor.selection) && Range.isExpanded(editor.selection)) {
      insertBreak();

      return;
    }

    const [, path] = Editor.node(editor, editor.selection);
    const text = Editor.string(editor, path);

    // If the selection is not at the end of the node, proceed with default behavior.
    if (editor.selection.focus.offset !== text.length) {
      insertBreak();

      return;
    }

    // Insert a new paragraph after the current node
    Transforms.insertNodes(
      editor,
      {
        type: 'paragraph',
        children: [{ type: 'text', text: '' }],
      } satisfies ParagraphElement,
      {
        at: [editor.selection.focus.path[0] + 1],
        select: true,
      },
    );
  };

  return editor;
};

export default withEnhancedBreaks;
