/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductTagPermissionDto
 */
export interface ProductTagPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof ProductTagPermissionDto
     */
    name: ProductTagPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTagPermissionDto
     */
    action: ProductTagPermissionDtoActionEnum;
}


/**
 * @export
 */
export const ProductTagPermissionDtoNameEnum = {
    PRODUCT_TAG: 'PRODUCT-TAG'
} as const;
export type ProductTagPermissionDtoNameEnum = typeof ProductTagPermissionDtoNameEnum[keyof typeof ProductTagPermissionDtoNameEnum];

/**
 * @export
 */
export const ProductTagPermissionDtoActionEnum = {
    LINK: 'CAN_LINK',
    UNLINK: 'CAN_UNLINK'
} as const;
export type ProductTagPermissionDtoActionEnum = typeof ProductTagPermissionDtoActionEnum[keyof typeof ProductTagPermissionDtoActionEnum];


/**
 * Check if a given object implements the ProductTagPermissionDto interface.
 */
export function instanceOfProductTagPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function ProductTagPermissionDtoFromJSON(json: any): ProductTagPermissionDto {
    return ProductTagPermissionDtoFromJSONTyped(json, false);
}

export function ProductTagPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductTagPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function ProductTagPermissionDtoToJSON(value?: ProductTagPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

