import { Alert, AlertDescription, Box, chakra, DarkMode, Flex, Grid, GridItem, Spacer } from '@chakra-ui/react';
import { faWifiSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import PageSpinner from '../../../ui/page/page-spinner';
import { StickyOffsetContextProvider } from '../../../util/sticky-offset/sticky-offset';
import useOnline from '../../../util/use-online-offline/use-online';
import logoBerlinalX from '../app-navigation/logo_berlinalx.svg';
import ToggleColorModeButton from '../toggle-color-mode-button';

export interface AppMobileLayoutProps {
  children?: React.ReactNode;
}

const APP_MOBILE_HEADER_HEIGHT = 12;
const OFFLINE_HEADER_HEIGHT = 9;

export function AppMobileLayout({ children }: AppMobileLayoutProps) {
  const { t } = useTranslation('app');
  const [isOnline, setOnline] = useState<boolean>(true);
  useOnline(setOnline);

  return (
    <React.Suspense fallback={<PageSpinner />}>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Grid minHeight="full" gridTemplateRows={`auto ${!isOnline ? 'auto' : ''} 1fr`}>
        {!isOnline && (
          <DarkMode>
            <Alert
              status="error"
              bg="background.error"
              color="text.error"
              fontSize="sm"
              h={OFFLINE_HEADER_HEIGHT}
              justifyContent="center"
              borderRadius="none"
            >
              <Box as={FontAwesomeIcon} icon={faWifiSlash} mr={2} />
              <AlertDescription>{t('offline')}</AlertDescription>
            </Alert>
          </DarkMode>
        )}
        <GridItem position="sticky" top={0} zIndex="sticky">
          <Flex
            bgColor="gray.800"
            px={3}
            h={APP_MOBILE_HEADER_HEIGHT}
            alignItems="center"
            borderBottom="1px solid"
            borderColor="border.01"
          >
            <chakra.img src={logoBerlinalX} alt={t('project_title')} w={18} />
            <Spacer />
            <ToggleColorModeButton size="sm" />
          </Flex>
        </GridItem>
        <GridItem>
          <StickyOffsetContextProvider
            value={isOnline ? APP_MOBILE_HEADER_HEIGHT : APP_MOBILE_HEADER_HEIGHT + OFFLINE_HEADER_HEIGHT}
          >
            <React.Suspense fallback={<PageSpinner />}>{children}</React.Suspense>
          </StickyOffsetContextProvider>
        </GridItem>
      </Grid>
    </React.Suspense>
  );
}

export default AppMobileLayout;
