/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OperationTypeDto = {
    ARRAY_FUNCTION: 'ARRAY_FUNCTION',
    CONSTANT: 'CONSTANT',
    FIELD: 'FIELD',
    FILTER: 'FILTER',
    FOR: 'FOR',
    FOR_EDGE: 'FOR_EDGE',
    FOR_REF: 'FOR_REF',
    LET: 'LET',
    OBJECT: 'OBJECT',
    PATH: 'PATH',
    RETURN: 'RETURN',
    SIMPLE_OPERATION: 'SIMPLE_OPERATION',
    SORT: 'SORT',
    SORT_FIELD: 'SORT_FIELD'
} as const;
export type OperationTypeDto = typeof OperationTypeDto[keyof typeof OperationTypeDto];


export function OperationTypeDtoFromJSON(json: any): OperationTypeDto {
    return OperationTypeDtoFromJSONTyped(json, false);
}

export function OperationTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationTypeDto {
    return json as OperationTypeDto;
}

export function OperationTypeDtoToJSON(value?: OperationTypeDto | null): any {
    return value as any;
}

