import { TeamReferenceDto } from '../../../api';
import { teamIcon } from '../../../ui/icons/business-objects';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';

export interface TeamReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  teamReference: TeamReferenceDto;
}

export default function TeamReference({ teamReference, ...props }: TeamReferenceProps) {
  return (
    <Reference icon={teamIcon} to={`/teams/${teamReference.id}`} {...props}>
      {teamReference.name}
    </Reference>
  );
}
