import { debounce, DebounceSettings } from 'lodash-es';

export default function asyncDebounce<TFunction extends (...args: any[]) => Promise<any>>(
  func: TFunction,
  wait?: number,
  options?: DebounceSettings,
) {
  const debounced = debounce(
    (resolve: any, reject: any, args: Parameters<TFunction>) => {
      func(...args)
        .then(resolve)
        .catch(reject);
    },
    wait,
    options,
  );

  return (...args: Parameters<TFunction>): ReturnType<TFunction> =>
    new Promise((resolve, reject) => {
      debounced(resolve, reject, args);
    }) as ReturnType<TFunction>;
}
