import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { sectionIcon } from '../../ui/icons/business-objects';
import renderPhoneNumber from '../../ui/phone-number/render-phone-number';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function SectionSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'SECTION', `${result.resultType} is no instance of SectionSearchResult`);

  const section = result.result;
  const { t } = useTranslation('section');

  return (
    <SearchResult
      icon={sectionIcon}
      title={section.name}
      link={`/sections/${section.id}`}
      score={result.score}
      onClick={onClick}
      necessaryPermission={({ hasPermission, hasPermissionFromSection }) => {
        return (
          hasPermission('SECTION:CAN_SEE_DETAILS') ||
          hasPermissionFromSection(section.id, 'SECTION:CAN_SEE_DETAILS_OWN')
        );
      }}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('name_english'),
            element: section.englishName,
          },
          {
            label: t('abbreviation'),
            element: section.abbreviation,
          },
          {
            label: t('contacts.email.header'),
            element: section.emailAddresses.map((e) => e.email).join(', '),
          },
          {
            label: t('contacts.phone_number.header'),
            element: section.phoneNumbers.map(renderPhoneNumber).join(', '),
          },
          {
            label: t('status.label'),
            element: t(`statusOptions.${section.status}`),
          },
        ]}
      />
    </SearchResult>
  );
}
