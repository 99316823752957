/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  FailureDto,
  HistoryPageDto,
  MailingDesignBrandPageDto,
  MailingDesignDto,
  MailingDesignPageDto,
  PageableDto,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    MailingDesignBrandPageDtoFromJSON,
    MailingDesignBrandPageDtoToJSON,
    MailingDesignDtoFromJSON,
    MailingDesignDtoToJSON,
    MailingDesignPageDtoFromJSON,
    MailingDesignPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface ActivateMailingDesignRequest {
    id: string;
}

export interface CreateMailingDesignRequest {
    mailingDesignDto: MailingDesignDto;
}

export interface DeactivateMailingDesignRequest {
    id: string;
}

export interface DeleteMailingDesignByIdRequest {
    id: string;
}

export interface DesignDeletableRequest {
    id: string;
}

export interface FetchMailingDesignRequest {
    id: string;
}

export interface FetchMailingDesignHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchMailingDesignTemplateRequest {
    brand: string;
}

export interface HasActiveDesignRequest {
    brand: string;
}

export interface RelevantMailingDesignBrandsRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchMailingDesignBrandsRequest {
    brand: string;
}

export interface SearchMailingDesignsRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface UpdateMailingDesignRequest {
    id: string;
    mailingDesignDto: MailingDesignDto;
}

/**
 * 
 */
export class MailingDesignApi extends runtime.BaseAPI {

    /**
     * Activate mailing design
     */
    async activateMailingDesignRaw(requestParameters: ActivateMailingDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling activateMailingDesign().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-designs/{id}/activate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Activate mailing design
     */
    async activateMailingDesign(requestParameters: ActivateMailingDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.activateMailingDesignRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new mailing design.
     */
    async createMailingDesignRaw(requestParameters: CreateMailingDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingDesignDto>> {
        if (requestParameters['mailingDesignDto'] == null) {
            throw new runtime.RequiredError(
                'mailingDesignDto',
                'Required parameter "mailingDesignDto" was null or undefined when calling createMailingDesign().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-designs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MailingDesignDtoToJSON(requestParameters['mailingDesignDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingDesignDtoFromJSON(jsonValue));
    }

    /**
     * Create a new mailing design.
     */
    async createMailingDesign(requestParameters: CreateMailingDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingDesignDto> {
        const response = await this.createMailingDesignRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deactivate mailing design
     */
    async deactivateMailingDesignRaw(requestParameters: DeactivateMailingDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deactivateMailingDesign().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-designs/{id}/deactivate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deactivate mailing design
     */
    async deactivateMailingDesign(requestParameters: DeactivateMailingDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deactivateMailingDesignRaw(requestParameters, initOverrides);
    }

    /**
     * Delete mailing design by id
     */
    async deleteMailingDesignByIdRaw(requestParameters: DeleteMailingDesignByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteMailingDesignById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-designs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete mailing design by id
     */
    async deleteMailingDesignById(requestParameters: DeleteMailingDesignByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteMailingDesignByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Get the current deletability of the mailing design.
     */
    async designDeletableRaw(requestParameters: DesignDeletableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling designDeletable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-designs/{id}/checkStatus`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     * Get the current deletability of the mailing design.
     */
    async designDeletable(requestParameters: DesignDeletableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.designDeletableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a mailing design by ID.
     */
    async fetchMailingDesignRaw(requestParameters: FetchMailingDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingDesignDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchMailingDesign().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-designs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingDesignDtoFromJSON(jsonValue));
    }

    /**
     * Get a mailing design by ID.
     */
    async fetchMailingDesign(requestParameters: FetchMailingDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingDesignDto> {
        const response = await this.fetchMailingDesignRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of mailing design.
     */
    async fetchMailingDesignHistoryRaw(requestParameters: FetchMailingDesignHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchMailingDesignHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-designs/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of mailing design.
     */
    async fetchMailingDesignHistory(requestParameters: FetchMailingDesignHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchMailingDesignHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new mailing design.
     */
    async fetchMailingDesignTemplateRaw(requestParameters: FetchMailingDesignTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingDesignDto>> {
        if (requestParameters['brand'] == null) {
            throw new runtime.RequiredError(
                'brand',
                'Required parameter "brand" was null or undefined when calling fetchMailingDesignTemplate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['brand'] != null) {
            queryParameters['brand'] = requestParameters['brand'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-designs/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingDesignDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new mailing design.
     */
    async fetchMailingDesignTemplate(requestParameters: FetchMailingDesignTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingDesignDto> {
        const response = await this.fetchMailingDesignTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Determines whether there is an active design in current edition or edition-free for given brand.
     */
    async hasActiveDesignRaw(requestParameters: HasActiveDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        if (requestParameters['brand'] == null) {
            throw new runtime.RequiredError(
                'brand',
                'Required parameter "brand" was null or undefined when calling hasActiveDesign().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-designs/has-active-design/{brand}`.replace(`{${"brand"}}`, encodeURIComponent(String(requestParameters['brand']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     * Determines whether there is an active design in current edition or edition-free for given brand.
     */
    async hasActiveDesign(requestParameters: HasActiveDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.hasActiveDesignRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for mailing design brands that are in current or future editions or without edition.
     */
    async relevantMailingDesignBrandsRaw(requestParameters: RelevantMailingDesignBrandsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingDesignBrandPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-designs/relevant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingDesignBrandPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for mailing design brands that are in current or future editions or without edition.
     */
    async relevantMailingDesignBrands(requestParameters: RelevantMailingDesignBrandsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingDesignBrandPageDto> {
        const response = await this.relevantMailingDesignBrandsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for brands.
     */
    async searchMailingDesignBrandsRaw(requestParameters: SearchMailingDesignBrandsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['brand'] == null) {
            throw new runtime.RequiredError(
                'brand',
                'Required parameter "brand" was null or undefined when calling searchMailingDesignBrands().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['brand'] != null) {
            queryParameters['brand'] = requestParameters['brand'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-designs/brands`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Search for brands.
     */
    async searchMailingDesignBrands(requestParameters: SearchMailingDesignBrandsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.searchMailingDesignBrandsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for mailing designs.
     */
    async searchMailingDesignsRaw(requestParameters: SearchMailingDesignsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingDesignPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-designs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingDesignPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for mailing designs.
     */
    async searchMailingDesigns(requestParameters: SearchMailingDesignsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingDesignPageDto> {
        const response = await this.searchMailingDesignsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update mailing design by ID
     */
    async updateMailingDesignRaw(requestParameters: UpdateMailingDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingDesignDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateMailingDesign().'
            );
        }

        if (requestParameters['mailingDesignDto'] == null) {
            throw new runtime.RequiredError(
                'mailingDesignDto',
                'Required parameter "mailingDesignDto" was null or undefined when calling updateMailingDesign().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailing-designs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MailingDesignDtoToJSON(requestParameters['mailingDesignDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingDesignDtoFromJSON(jsonValue));
    }

    /**
     * Update mailing design by ID
     */
    async updateMailingDesign(requestParameters: UpdateMailingDesignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingDesignDto> {
        const response = await this.updateMailingDesignRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
