import React from 'react';
import { useParams } from 'react-router-dom';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchGroup } from '../group-queries';
import { getGroupId } from './get-group-id';

export default function GroupBreadcrumb() {
  const params = useParams<{ groupId: string }>();
  const group = useFetcher(fetchGroup, { id: getGroupId(params) });

  return <>{group.name}</>;
}
