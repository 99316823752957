import { createDescendantContext, DescendantsManager } from '@chakra-ui/descendant';
import { useMemo, useState } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import createDefinedContext from '../../util/context/create-defined-context/create-defined-context';
import useCallbackRef from '../../util/use-callback-ref/use-callback-ref';

export const [GlobalSearchDescendantsProvider, , useGlobalSearchDescendants, useGlobalSearchDescendant] =
  createDescendantContext<HTMLElement>();

type GlobalSearchContext = ReturnType<typeof useGlobalSearchState>;

export const [GlobalSearchContextProvider, useGlobalSearchContext] =
  createDefinedContext<GlobalSearchContext>('GlobalSearchContext');

export default function useGlobalSearchState(descendantManager: DescendantsManager<HTMLElement>) {
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(0);
  const [scannerIsOpen, setScannerIsOpen] = useState(false);
  const [helperTextIsOpen, setHelperTextIsOpen] = useState(false);

  const [fallbackPhrase, setFallbackPhrase] = useState<string | null>(null);
  const [fallbackFilter, setFallbackFilter] = useState<string | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const phrase = searchParams.get('searchPhrase');
  const filter = searchParams.get('searchFilter');

  const updateState = useCallbackRef(
    ({ phrase = state.phrase, filter = state.filter }: { phrase?: string | null; filter?: string | null }) => {
      const nextSearchParams = createSearchParams(searchParams);

      if (filter != null) {
        nextSearchParams.set('searchFilter', filter);
      } else {
        nextSearchParams.delete('searchFilter');
      }

      if (phrase != null) {
        nextSearchParams.set('searchPhrase', phrase);
      } else {
        nextSearchParams.delete('searchPhrase');
      }

      setSearchParams(nextSearchParams, { replace: true });
    },
  );

  const setPhrase = useCallbackRef((phrase: string | null) => {
    updateState({ phrase });

    setFallbackPhrase(phrase);
  });

  const setFilter = useCallbackRef((filter: string | null) => {
    updateState({ filter });

    setFallbackFilter(filter);
  });

  const removeState = useCallbackRef(() => {
    updateState({ phrase: null, filter: null });
  });

  const restoreState = useCallbackRef(() => {
    updateState({ phrase: fallbackPhrase ?? '', filter: fallbackFilter });
  });

  const highlightIndex = useCallbackRef((index: number | null) => {
    if (index != null) {
      const item = descendantManager.item(index);
      item?.node.focus();
    }

    setHighlightedIndex(index);
  });

  const highlightNext = useCallbackRef(() => {
    const item = highlightedIndex != null ? descendantManager.next(highlightedIndex) : descendantManager.first();
    item?.node.scrollIntoView({ block: 'nearest' });

    setHighlightedIndex(item?.index ?? null);
  });

  const highlightPrev = useCallbackRef(() => {
    const item = highlightedIndex != null ? descendantManager.prev(highlightedIndex) : descendantManager.last();
    item?.node.scrollIntoView({ block: 'nearest' });

    setHighlightedIndex(item?.index ?? null);
  });

  const clickHighlighted = useCallbackRef(() => {
    if (highlightedIndex == null) {
      return;
    }

    const item = descendantManager.item(highlightedIndex);
    item?.node.click();
  });

  const state = useMemo(() => ({ phrase, filter }), [phrase, filter]);

  return useMemo(
    () => ({
      phrase,
      setPhrase,
      filter,
      setFilter,
      highlightedIndex,
      highlightIndex,
      highlightNext,
      highlightPrev,
      clickHighlighted,
      setScannerIsOpen,
      scannerIsOpen,
      setHelperTextIsOpen,
      helperTextIsOpen,
      restoreState,
      removeState,
    }),
    [
      clickHighlighted,
      highlightIndex,
      highlightNext,
      highlightPrev,
      highlightedIndex,
      phrase,
      scannerIsOpen,
      helperTextIsOpen,
      setPhrase,
      restoreState,
      removeState,
      filter,
      setFilter,
    ],
  );
}
