import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { v4 as uuid } from 'uuid';
import { AwardDto, AwardWinnerRelationDto, EditionDto } from '../../../../api';
import awardWinnerApi from '../../../../data-access/award-winner-api';
import Form from '../../../../ui/form/form';
import SubmitButton from '../../../../ui/form/submit-button';
import useToast from '../../../../ui/use-toast/use-toast';
import { awardWinnerFetcher } from '../award-winner-queries';
import AwardWinnerEditorForm from './award-winner-editor-form';

interface AwardWinnerEditorDialogProps {
  isOpen: boolean;
  onClose: () => void;
  award: AwardDto;
  edition: EditionDto;
}

export default function AwardWinnerEditorDialog({ isOpen, onClose, award, edition }: AwardWinnerEditorDialogProps) {
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick={false} initialFocusRef={initialFocusRef}>
      <ModalOverlay />
      <ModalContent>
        <React.Suspense>
          <AwardWinnerModal award={award} edition={edition} initialFocusRef={initialFocusRef} onClose={onClose} />
        </React.Suspense>
      </ModalContent>
    </Modal>
  );
}

interface AwardWinnerModalProps {
  award: AwardDto;
  edition: EditionDto;
  initialFocusRef?: React.RefObject<any>;
  onClose: () => void;
}

function AwardWinnerModal({ award, edition, initialFocusRef, onClose }: AwardWinnerModalProps) {
  const { t } = useTranslation(['common', 'award']);

  const form = useForm<AwardWinnerRelationDto>({
    mode: 'all',
    defaultValues: { id: uuid(), award: award, edition: edition },
  });

  const showSuccessToast = useToast({
    id: 'add-winner-to-award-success-toast',
    status: 'success',
  });

  const handleValid = async (awardWinnerRelationDto: AwardWinnerRelationDto) => {
    invariant(award.id != null, 'Missing award.id');

    await awardWinnerApi.createAwardWinnerRelation({ awardWinnerRelationDto: awardWinnerRelationDto });

    showSuccessToast({
      title: t('award:connections.toast.success.title'),
      description: t('award:connections.toast.success.description', { title: award.title }),
    });

    onClose();

    await awardWinnerFetcher.mutate();
  };

  const formIsDirty = Object.keys(form.formState.dirtyFields).length > 0;

  return (
    <FormProvider {...form}>
      <Form<AwardWinnerRelationDto> onValid={handleValid} initialFocusRef={initialFocusRef}>
        <ModalHeader>{t('award:connections.add')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <AwardWinnerEditorForm award={award} edition={edition} initialFocusRef={initialFocusRef} />
        </ModalBody>

        <ModalFooter>
          <ButtonGroup spacing={4}>
            <Button onClick={onClose}>{t('common:action.abort')}</Button>
            <SubmitButton variant="primary" isDisabled={!formIsDirty}>
              {t('common:action.add')}
            </SubmitButton>
          </ButtonGroup>
        </ModalFooter>
      </Form>
    </FormProvider>
  );
}
