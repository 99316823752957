/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SeatAssignmentDto } from './SeatAssignmentDto';
import {
    SeatAssignmentDtoFromJSON,
    SeatAssignmentDtoFromJSONTyped,
    SeatAssignmentDtoToJSON,
} from './SeatAssignmentDto';

/**
 * 
 * @export
 * @interface UpdateSeatAssignmentsCommandDto
 */
export interface UpdateSeatAssignmentsCommandDto {
    /**
     * 
     * @type {Array<SeatAssignmentDto>}
     * @memberof UpdateSeatAssignmentsCommandDto
     */
    addedSeatAssignments: Array<SeatAssignmentDto>;
    /**
     * 
     * @type {Array<SeatAssignmentDto>}
     * @memberof UpdateSeatAssignmentsCommandDto
     */
    changedSeatAssignments: Array<SeatAssignmentDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateSeatAssignmentsCommandDto
     */
    deletedSeatIds: Array<string>;
}

/**
 * Check if a given object implements the UpdateSeatAssignmentsCommandDto interface.
 */
export function instanceOfUpdateSeatAssignmentsCommandDto(value: object): boolean {
    if (!('addedSeatAssignments' in value)) return false;
    if (!('changedSeatAssignments' in value)) return false;
    if (!('deletedSeatIds' in value)) return false;
    return true;
}

export function UpdateSeatAssignmentsCommandDtoFromJSON(json: any): UpdateSeatAssignmentsCommandDto {
    return UpdateSeatAssignmentsCommandDtoFromJSONTyped(json, false);
}

export function UpdateSeatAssignmentsCommandDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSeatAssignmentsCommandDto {
    if (json == null) {
        return json;
    }
    return {
        
        'addedSeatAssignments': ((json['addedSeatAssignments'] as Array<any>).map(SeatAssignmentDtoFromJSON)),
        'changedSeatAssignments': ((json['changedSeatAssignments'] as Array<any>).map(SeatAssignmentDtoFromJSON)),
        'deletedSeatIds': json['deletedSeatIds'],
    };
}

export function UpdateSeatAssignmentsCommandDtoToJSON(value?: UpdateSeatAssignmentsCommandDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'addedSeatAssignments': ((value['addedSeatAssignments'] as Array<any>).map(SeatAssignmentDtoToJSON)),
        'changedSeatAssignments': ((value['changedSeatAssignments'] as Array<any>).map(SeatAssignmentDtoToJSON)),
        'deletedSeatIds': value['deletedSeatIds'],
    };
}

