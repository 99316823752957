import React from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchAccount } from '../account-queries';

export default function AccountBreadcrumb() {
  const { accountId } = useParams<{ accountId: string }>();
  invariant(accountId, 'Empty accountId');
  const account = useFetcher(fetchAccount, { id: accountId });

  return <>{`${account.firstName} ${account.surname}`}</>;
}
