import React from 'react';
import { PersonPersonConnectionDto } from '../../../api';
import { DataTableColumn } from '../../../ui/data-table';
import Translate from '../../../util/translate/translate';
import PersonReference from '../person-reference/person-reference';

const personPersonConnectionColumns: DataTableColumn<PersonPersonConnectionDto>[] = [
  {
    key: 'connectionType',
    name: <Translate ns="person" i18nKey="connections.connectionType" />,
    renderCell: (data) =>
      data.connectionType && (
        <Translate ns="person" i18nKey={`connections.connectionTypeLabels.${data.connectionType}`} />
      ),
    cellProps: {
      whiteSpace: 'nowrap',
    },
  },
  {
    key: 'person',
    name: <Translate ns="person" i18nKey="person" />,
    renderCell: (data) => <PersonReference size="sm" personReference={data.connectedPerson} />,
  },
  {
    key: 'dateRange',
    name: <Translate ns="person" i18nKey="connections.date_range" />,
    renderCell: (data) => (
      <Translate ns="common">{(t) => t('format.date_range', { dateRange: data.dateRange })}</Translate>
    ),
  },
];

export default personPersonConnectionColumns;
