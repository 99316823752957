import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { AccessGroupDtoFromJSON } from '../../api';
import { accessGroupIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function AccessGroupSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'ACCESS_GROUP', `${result.resultType} is no instance of AccessGroupSearchResult`);

  const accessGroup = AccessGroupDtoFromJSON(result.result);
  const { t } = useTranslation('access_group');

  return (
    <SearchResult
      icon={accessGroupIcon}
      title={accessGroup.title}
      link={`/access-groups/${accessGroup.id}`}
      score={result.score}
      onClick={onClick}
      necessaryPermission="ACCESS_GROUP:CAN_SEE_DETAILS"
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('form.titleEnglish'),
            element: accessGroup.englishTitle,
          },
          {
            label: t('form.liveTicketingPrio'),
            element: t(`form.liveTicketingPrioValues.${accessGroup.liveTicketingPriority}`),
          },
          {
            label: t('viewer.status'),
            element: t(`viewer.statusLabel.${accessGroup.status}`),
          },
        ]}
      />
    </SearchResult>
  );
}
