import invoiceApi from '../../data-access/invoice-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';

export const invoiceFetcher = createFetcherScope(invoiceApi);
export const fetchInvoices = invoiceFetcher.createFetcher(invoiceApi.searchInvoices);
export const fetchInvoice = invoiceFetcher.createFetcher(invoiceApi.fetchInvoice);
export const fetchAccountingRecords = invoiceFetcher.createFetcher(invoiceApi.searchAccountingRecords);
export const fetchInvoiceHistory = invoiceFetcher.createFetcher(invoiceApi.fetchInvoiceHistory);

export const fetchAccountingRecordAggregations = invoiceFetcher.createFetcher(
  invoiceApi.searchAccountingRecordAggregations,
);
export const fetchRecipientUpdateApplications = invoiceFetcher.createFetcher(
  invoiceApi.searchRecipientUpdateApplications,
);

export const existsByRecipient = invoiceFetcher.createFetcher(invoiceApi.existsByRecipient);
