import { Button, Flex, Grid, Heading, HStack, Spacer, useDisclosure } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { CompanyDtoStatusEnum } from '../../api';
import { DisableableButton } from '../../ui/disableable-button/disableable-button';
import PageContent from '../../ui/page/page-content';
import useFetcher from '../../util/swr/use-fetcher';
import { fetchCompany } from '../company/company-queries';
import usePermission from '../permission/use-permission';
import CompanyAdminEditorDialog from './company-admin-lister/company-admin-editor-dialog';
import CompanyAdminLister from './company-admin-lister/company-admin-lister';

export function CompanyAdminPageContent() {
  const { t } = useTranslation(['company', 'common']);
  const { companyId } = useParams<{ companyId: string }>();
  invariant(companyId, 'Missing companyId');
  const company = useFetcher(fetchCompany, { id: companyId });
  const { isOpen: editDialogIsOpen, onOpen: onEditDialogOpen, onClose: onEditDialogClose } = useDisclosure();
  const { hasPermission } = usePermission();

  return (
    <PageContent useFullWidth>
      <Grid gridRowGap={6} maxH="full" gridTemplateRows="auto 1fr">
        <Flex as="header">
          <HStack spacing={4}>
            <Heading size="md" as="h3">
              {t('company:externalAdmin.lister')}
            </Heading>
          </HStack>
          <Spacer />
          {hasPermission('COMPANY:CAN_EDIT_EXTERNAL_COMPANY_ADMIN') && (
            <Button
              as={DisableableButton}
              variant="primary"
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
              onClick={onEditDialogOpen}
              aria-label={t('company:externalAdmin.add')}
              isDisabled={company.status !== CompanyDtoStatusEnum.ACTIVE}
              disableReason={t('common:toast.status_conflict.description')}
            >
              {t('company:externalAdmin.add')}
            </Button>
          )}
        </Flex>
        <CompanyAdminLister company={company} />
        <CompanyAdminEditorDialog isOpen={editDialogIsOpen} onClose={onEditDialogClose} company={company} />
      </Grid>
    </PageContent>
  );
}
