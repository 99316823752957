import { useMemo } from 'react';
import { RecipientLinkDto } from '../../../../api';
import DataTable from '../../../../ui/data-table/data-table';
import useMergedDataTableColumns from '../../../../ui/data-table/use-merged-data-table-columns';
import useRecipientLister from '../../../mailing/common/use-recipient-lister';
import useEmailRecipientColumns from '../../../mailing/mailing-recipient-lister/use-email-recipient-columns';
import usePersonRecipientColumns from '../../../mailing/mailing-recipient-lister/use-person-recipient-columns';

export default function UnpersonalizedGuestListInvitationMailingRecipientLister({ mailingId }: { mailingId: string }) {
  const { state, setState, recipientPage } = useRecipientLister(mailingId);

  const emailColumns = useEmailRecipientColumns(mailingId);
  const personColumns = usePersonRecipientColumns(mailingId);
  const mergedColumns = useMergedDataTableColumns<RecipientLinkDto>(
    useMemo(() => [emailColumns, personColumns], [emailColumns, personColumns]),
  );

  return (
    <DataTable
      rowKey={(recipient) => recipient.id!}
      page={recipientPage}
      columns={mergedColumns}
      state={state}
      onStateChange={setState}
      isPageable
    />
  );
}
