/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClassLevelPerGroupInnerDto } from './ClassLevelPerGroupInnerDto';
import {
    ClassLevelPerGroupInnerDtoFromJSON,
    ClassLevelPerGroupInnerDtoFromJSONTyped,
    ClassLevelPerGroupInnerDtoToJSON,
} from './ClassLevelPerGroupInnerDto';
import type { StaffListItemViewerDto } from './StaffListItemViewerDto';
import {
    StaffListItemViewerDtoFromJSON,
    StaffListItemViewerDtoFromJSONTyped,
    StaffListItemViewerDtoToJSON,
} from './StaffListItemViewerDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface AdminSectionRoleDto
 */
export interface AdminSectionRoleDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof AdminSectionRoleDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof AdminSectionRoleDto
     */
    version: VersionDto;
    /**
     * 
     * @type {Array<ClassLevelPerGroupInnerDto>}
     * @memberof AdminSectionRoleDto
     */
    classLevelPerGroup: Array<ClassLevelPerGroupInnerDto>;
    /**
     * 
     * @type {Array<StaffListItemViewerDto>}
     * @memberof AdminSectionRoleDto
     */
    sectionAdmins: Array<StaffListItemViewerDto>;
}

/**
 * Check if a given object implements the AdminSectionRoleDto interface.
 */
export function instanceOfAdminSectionRoleDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('version' in value)) return false;
    if (!('classLevelPerGroup' in value)) return false;
    if (!('sectionAdmins' in value)) return false;
    return true;
}

export function AdminSectionRoleDtoFromJSON(json: any): AdminSectionRoleDto {
    return AdminSectionRoleDtoFromJSONTyped(json, false);
}

export function AdminSectionRoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminSectionRoleDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': VersionDtoFromJSON(json['version']),
        'classLevelPerGroup': ((json['classLevelPerGroup'] as Array<any>).map(ClassLevelPerGroupInnerDtoFromJSON)),
        'sectionAdmins': ((json['sectionAdmins'] as Array<any>).map(StaffListItemViewerDtoFromJSON)),
    };
}

export function AdminSectionRoleDtoToJSON(value?: AdminSectionRoleDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'classLevelPerGroup': ((value['classLevelPerGroup'] as Array<any>).map(ClassLevelPerGroupInnerDtoToJSON)),
        'sectionAdmins': ((value['sectionAdmins'] as Array<any>).map(StaffListItemViewerDtoToJSON)),
    };
}

