/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HistoryEntryCreatedFromMergeOfDto
 */
export interface HistoryEntryCreatedFromMergeOfDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof HistoryEntryCreatedFromMergeOfDto
     */
    objectA?: string;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof HistoryEntryCreatedFromMergeOfDto
     */
    objectB?: string;
}

/**
 * Check if a given object implements the HistoryEntryCreatedFromMergeOfDto interface.
 */
export function instanceOfHistoryEntryCreatedFromMergeOfDto(value: object): boolean {
    return true;
}

export function HistoryEntryCreatedFromMergeOfDtoFromJSON(json: any): HistoryEntryCreatedFromMergeOfDto {
    return HistoryEntryCreatedFromMergeOfDtoFromJSONTyped(json, false);
}

export function HistoryEntryCreatedFromMergeOfDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoryEntryCreatedFromMergeOfDto {
    if (json == null) {
        return json;
    }
    return {
        
        'objectA': json['objectA'] == null ? undefined : json['objectA'],
        'objectB': json['objectB'] == null ? undefined : json['objectB'],
    };
}

export function HistoryEntryCreatedFromMergeOfDtoToJSON(value?: HistoryEntryCreatedFromMergeOfDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'objectA': value['objectA'],
        'objectB': value['objectB'],
    };
}

