import { Checkbox } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyDto } from '../../../api/models/CompanyDto';
import ConfirmDialog from '../../../ui/dialog/confirm-dialog';

export interface InvoiceRefundTaxDialogProps {
  company: CompanyDto;
  isOpen: boolean;

  onClose(confirm: boolean): void;
}

export default function InvoiceRefundTaxDialog({ company, isOpen, onClose, ...props }: InvoiceRefundTaxDialogProps) {
  const { t } = useTranslation(['invoice']);
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  const [confirmed, setConfirmed] = React.useState<boolean>(false);
  const onCloseWithReset = React.useCallback(
    (confirm: boolean) => {
      setConfirmed(false);
      onClose(confirm);
    },
    [onClose],
  );

  return (
    <ConfirmDialog
      {...props}
      isOpen={isOpen}
      initialFocusRef={initialFocusRef}
      confirmActionLabel={t('invoice:action.refundTax.label')}
      onClose={onCloseWithReset}
      isConfirmActionDisabled={!confirmed}
      submitButtonVariant="primary"
    >
      <Checkbox ref={initialFocusRef} isRequired isChecked={confirmed} onChange={(e) => setConfirmed(e.target.checked)}>
        {t('invoice:action.refundTax.confirm', { companyName: company.name })}
      </Checkbox>
    </ConfirmDialog>
  );
}
