/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateRangeDto } from './ClosedDateRangeDto';
import {
    ClosedDateRangeDtoFromJSON,
    ClosedDateRangeDtoFromJSONTyped,
    ClosedDateRangeDtoToJSON,
} from './ClosedDateRangeDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * One edition of the Berlinale festival.
 * @export
 * @interface EditionDto
 */
export interface EditionDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof EditionDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof EditionDto
     */
    version?: VersionDto;
    /**
     * The unique name. For regular ones following the pattern "number. Berlinale".
     * @type {string}
     * @memberof EditionDto
     */
    name: string;
    /**
     * 
     * @type {ClosedDateRangeDto}
     * @memberof EditionDto
     */
    dateRange: ClosedDateRangeDto;
    /**
     * 
     * @type {ClosedDateRangeDto}
     * @memberof EditionDto
     */
    festivalDateRange?: ClosedDateRangeDto;
    /**
     * Regular editions are only once a year having a number.
     * @type {string}
     * @memberof EditionDto
     */
    editionType: EditionDtoEditionTypeEnum;
    /**
     * Non visible editions are shown to administrators only.
     * @type {boolean}
     * @memberof EditionDto
     */
    visible?: boolean;
    /**
     * There is only one active edition which is the default selection for users.
     * @type {boolean}
     * @memberof EditionDto
     */
    readonly active?: boolean;
    /**
     * Only deletable edition can be deleted
     * @type {boolean}
     * @memberof EditionDto
     */
    readonly deletable?: boolean;
}


/**
 * @export
 */
export const EditionDtoEditionTypeEnum = {
    REGULAR: 'REGULAR',
    OUT_OF_BAND: 'OUT_OF_BAND'
} as const;
export type EditionDtoEditionTypeEnum = typeof EditionDtoEditionTypeEnum[keyof typeof EditionDtoEditionTypeEnum];


/**
 * Check if a given object implements the EditionDto interface.
 */
export function instanceOfEditionDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('dateRange' in value)) return false;
    if (!('editionType' in value)) return false;
    return true;
}

export function EditionDtoFromJSON(json: any): EditionDto {
    return EditionDtoFromJSONTyped(json, false);
}

export function EditionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'name': json['name'],
        'dateRange': ClosedDateRangeDtoFromJSON(json['dateRange']),
        'festivalDateRange': json['festivalDateRange'] == null ? undefined : ClosedDateRangeDtoFromJSON(json['festivalDateRange']),
        'editionType': json['editionType'],
        'visible': json['visible'] == null ? undefined : json['visible'],
        'active': json['active'] == null ? undefined : json['active'],
        'deletable': json['deletable'] == null ? undefined : json['deletable'],
    };
}

export function EditionDtoToJSON(value?: EditionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'name': value['name'],
        'dateRange': ClosedDateRangeDtoToJSON(value['dateRange']),
        'festivalDateRange': ClosedDateRangeDtoToJSON(value['festivalDateRange']),
        'editionType': value['editionType'],
        'visible': value['visible'],
    };
}

