import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { mutate } from 'swr';
import { logout } from '../../../data-access/auth';
import useToast from '../../../ui/use-toast/use-toast';
import { useSwitchMobileStorage } from '../switch-mobile/switch-mobile';

export default function Logout() {
  const { t } = useTranslation('app');
  const { reset } = useSwitchMobileStorage();
  const toast = useToast();
  const toastId = 'logout-toast';

  React.useEffect(() => {
    // Clear all cached data from SWR
    // noinspection JSIgnoredPromiseFromCall
    Mockables.mutate(() => true, undefined, { revalidate: false });
    reset();
    logout();
    if (!toast.isActive(toastId)) {
      toast({
        id: toastId,
        title: t('logout.toast.title'),
        status: 'success',
      });
    }
  }, [toast, t, reset]);

  return <Navigate to="/login" replace />;
}

export const Mockables = {
  mutate,
};
