import { HStack, Stack } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EventEvaluationDto } from '../../../api';
import FormControl from '../../../ui/form/form-control';
import MultiValueSelectControl from '../../../ui/form/select-control/multi-value-select-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import HelperPopover from '../../../ui/helper-buttons/helper-popover';
import { audienceStatusOptions, technicalDisruptionOptions } from '../event-evaluation-enum-constants';

export default function EvaluationProblemsControl() {
  const { t } = useTranslation('event_evaluation');
  useFormContext<EventEvaluationDto>();

  return (
    <>
      <Stack spacing={6}>
        <FormControl
          label={t('technical_disruption.label')}
          helperPopover={<HelperPopover children={t('technical_disruption.helper_popover')} />}
          helperText={t('technical_disruption.helper_text')}
          name="technicalDisruption"
        >
          <MultiValueSelectControl
            name="technicalDisruption"
            options={technicalDisruptionOptions}
            renderLabel={(value) => t(`technicalDisruptionOptions.${value}`)}
          />
        </FormControl>
        <HStack spacing={6} alignItems="start">
          <FormControl<EventEvaluationDto>
            label={t('audience.entry.label')}
            helperPopover={<HelperPopover children={t('audience.entry.helper_popover')} />}
            name="audienceEntry"
            isRequired
          >
            <ValueSelectControl
              options={audienceStatusOptions}
              renderLabel={(option) => t(`audienceEntryOptions.${option}`)}
              label={t('audience.entry.label')}
              name="audienceEntry"
              isRequired
            />
          </FormControl>
          <FormControl<EventEvaluationDto>
            label={t('audience.exit.label')}
            helperPopover={<HelperPopover children={t('audience.exit.helper_popover')} />}
            name="audienceExit"
            isRequired
          >
            <ValueSelectControl
              options={audienceStatusOptions}
              renderLabel={(option) => t(`audienceEntryOptions.${option}`)}
              label={t('audience.exit.label')}
              name="audienceExit"
              isRequired
            />
          </FormControl>
        </HStack>
      </Stack>
    </>
  );
}
