import React from 'react';
import { useLocation } from 'react-router-dom';

export interface AnchorLinkDestinationProps {
  name: string;
}

export default function AnchorLinkDestination({ name }: AnchorLinkDestinationProps) {
  const { hash } = useLocation();
  const ref = React.useRef<HTMLSpanElement>(null);

  React.useEffect(() => {
    if (hash.substring(1) === name) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [hash, name]);

  return <span ref={ref} aria-hidden="true" />;
}
