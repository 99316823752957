import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { ReactNode } from 'react';
import { SearchResultDto } from '../../api';
import { PluginToken } from '../../util/plugin/use-plugins';
import { Permission } from '../permission/use-permission';

export interface SearchFilter {
  key: SearchResultDto['resultType'];
  icon: IconDefinition | IconDefinition[];
  label: ReactNode;
  necessaryPermission?: Permission; // TODO rm optional
}

export const SEARCH_FILTER = new PluginToken<SearchFilter>('SearchFilter');
