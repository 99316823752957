/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EventReferenceDto } from './EventReferenceDto';
import {
    EventReferenceDtoFromJSON,
    EventReferenceDtoFromJSONTyped,
    EventReferenceDtoToJSON,
} from './EventReferenceDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';

/**
 * The person is part of the rundown of an historic event which prevents them from being anonymized
 * @export
 * @interface PersonIsInRundownBlockerDto
 */
export interface PersonIsInRundownBlockerDto {
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof PersonIsInRundownBlockerDto
     */
    person: PersonReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof PersonIsInRundownBlockerDto
     */
    reason: PersonIsInRundownBlockerDtoReasonEnum;
    /**
     * 
     * @type {EventReferenceDto}
     * @memberof PersonIsInRundownBlockerDto
     */
    event: EventReferenceDto;
}


/**
 * @export
 */
export const PersonIsInRundownBlockerDtoReasonEnum = {
    PERSON_IS_IN_RUNDOWN: 'PERSON_IS_IN_RUNDOWN'
} as const;
export type PersonIsInRundownBlockerDtoReasonEnum = typeof PersonIsInRundownBlockerDtoReasonEnum[keyof typeof PersonIsInRundownBlockerDtoReasonEnum];


/**
 * Check if a given object implements the PersonIsInRundownBlockerDto interface.
 */
export function instanceOfPersonIsInRundownBlockerDto(value: object): boolean {
    if (!('person' in value)) return false;
    if (!('reason' in value)) return false;
    if (!('event' in value)) return false;
    return true;
}

export function PersonIsInRundownBlockerDtoFromJSON(json: any): PersonIsInRundownBlockerDto {
    return PersonIsInRundownBlockerDtoFromJSONTyped(json, false);
}

export function PersonIsInRundownBlockerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonIsInRundownBlockerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'person': PersonReferenceDtoFromJSON(json['person']),
        'reason': json['reason'],
        'event': EventReferenceDtoFromJSON(json['event']),
    };
}

export function PersonIsInRundownBlockerDtoToJSON(value?: PersonIsInRundownBlockerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'person': PersonReferenceDtoToJSON(value['person']),
        'reason': value['reason'],
        'event': EventReferenceDtoToJSON(value['event']),
    };
}

