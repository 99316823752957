/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyDto } from './CompanyDto';
import {
    CompanyDtoFromJSON,
    CompanyDtoFromJSONTyped,
    CompanyDtoToJSON,
} from './CompanyDto';
import type { OccupationWithPersonDto } from './OccupationWithPersonDto';
import {
    OccupationWithPersonDtoFromJSON,
    OccupationWithPersonDtoFromJSONTyped,
    OccupationWithPersonDtoToJSON,
} from './OccupationWithPersonDto';

/**
 * 
 * @export
 * @interface UnconfirmedCompanyDto
 */
export interface UnconfirmedCompanyDto {
    /**
     * 
     * @type {CompanyDto}
     * @memberof UnconfirmedCompanyDto
     */
    company: CompanyDto;
    /**
     * 
     * @type {Array<OccupationWithPersonDto>}
     * @memberof UnconfirmedCompanyDto
     */
    occupations: Array<OccupationWithPersonDto>;
}

/**
 * Check if a given object implements the UnconfirmedCompanyDto interface.
 */
export function instanceOfUnconfirmedCompanyDto(value: object): boolean {
    if (!('company' in value)) return false;
    if (!('occupations' in value)) return false;
    return true;
}

export function UnconfirmedCompanyDtoFromJSON(json: any): UnconfirmedCompanyDto {
    return UnconfirmedCompanyDtoFromJSONTyped(json, false);
}

export function UnconfirmedCompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnconfirmedCompanyDto {
    if (json == null) {
        return json;
    }
    return {
        
        'company': CompanyDtoFromJSON(json['company']),
        'occupations': ((json['occupations'] as Array<any>).map(OccupationWithPersonDtoFromJSON)),
    };
}

export function UnconfirmedCompanyDtoToJSON(value?: UnconfirmedCompanyDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'company': CompanyDtoToJSON(value['company']),
        'occupations': ((value['occupations'] as Array<any>).map(OccupationWithPersonDtoToJSON)),
    };
}

