import { StaffListItemDto } from '../../../api';
import { DataTableColumn } from '../../../ui/data-table';
import { ParentDataTableColumn } from '../../../ui/data-table/data-table-column';
import { PluginBuilderToken } from '../../../util/plugin/use-plugins';

export interface StaffListerColumnExtension {
  columnToAdd: DataTableColumn<StaffListItemDto>;
}

export const STAFF_LISTER_COLUMN_EXTENSION = new PluginBuilderToken<ParentDataTableColumn<StaffListItemDto>>(
  'StaffListerColumnExtension',
);
