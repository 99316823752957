/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyDto } from './CompanyDto';
import {
    CompanyDtoFromJSON,
    CompanyDtoFromJSONTyped,
    CompanyDtoToJSON,
} from './CompanyDto';

/**
 * Company that will be created after merge of two other companies.
 * @export
 * @interface MergedCompanyDto
 */
export interface MergedCompanyDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof MergedCompanyDto
     */
    companyA: string;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof MergedCompanyDto
     */
    companyB: string;
    /**
     * 
     * @type {CompanyDto}
     * @memberof MergedCompanyDto
     */
    newCompany: CompanyDto;
}

/**
 * Check if a given object implements the MergedCompanyDto interface.
 */
export function instanceOfMergedCompanyDto(value: object): boolean {
    if (!('companyA' in value)) return false;
    if (!('companyB' in value)) return false;
    if (!('newCompany' in value)) return false;
    return true;
}

export function MergedCompanyDtoFromJSON(json: any): MergedCompanyDto {
    return MergedCompanyDtoFromJSONTyped(json, false);
}

export function MergedCompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MergedCompanyDto {
    if (json == null) {
        return json;
    }
    return {
        
        'companyA': json['companyA'],
        'companyB': json['companyB'],
        'newCompany': CompanyDtoFromJSON(json['newCompany']),
    };
}

export function MergedCompanyDtoToJSON(value?: MergedCompanyDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'companyA': value['companyA'],
        'companyB': value['companyB'],
        'newCompany': CompanyDtoToJSON(value['newCompany']),
    };
}

