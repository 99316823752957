/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Wrapper object for a boolean Value due to a bug in the typescript-fetch OpenAPI generator see https://github.com/swagger-api/swagger-codegen/issues/10026
 * @export
 * @interface BooleanDto
 */
export interface BooleanDto {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanDto
     */
    value: boolean;
}

/**
 * Check if a given object implements the BooleanDto interface.
 */
export function instanceOfBooleanDto(value: object): boolean {
    if (!('value' in value)) return false;
    return true;
}

export function BooleanDtoFromJSON(json: any): BooleanDto {
    return BooleanDtoFromJSONTyped(json, false);
}

export function BooleanDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BooleanDto {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'],
    };
}

export function BooleanDtoToJSON(value?: BooleanDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
    };
}

