/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RecipientUpdateApplicationTypeDto = {
    PERSON: 'PERSON',
    COMPANY: 'COMPANY'
} as const;
export type RecipientUpdateApplicationTypeDto = typeof RecipientUpdateApplicationTypeDto[keyof typeof RecipientUpdateApplicationTypeDto];


export function RecipientUpdateApplicationTypeDtoFromJSON(json: any): RecipientUpdateApplicationTypeDto {
    return RecipientUpdateApplicationTypeDtoFromJSONTyped(json, false);
}

export function RecipientUpdateApplicationTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecipientUpdateApplicationTypeDto {
    return json as RecipientUpdateApplicationTypeDto;
}

export function RecipientUpdateApplicationTypeDtoToJSON(value?: RecipientUpdateApplicationTypeDto | null): any {
    return value as any;
}

