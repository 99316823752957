import { ParseKeys } from 'i18next';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import { EditionEditorAction } from './edition-editor/edition-editor';
import useEdition from './use-edition/use-edition';

const EditionLister = React.lazy(() => import('./edition-lister/edition-lister'));
const EditionPage = React.lazy(() => import('./edition-page/edition-page'));
const EditionViewer = React.lazy(() => import('./edition-viewer/edition-viewer'));
const EditionHistory = React.lazy(() => import('./edition-history/edition-history'));
const EditionEditor = React.lazy(() => import('./edition-editor/edition-editor'));

const editionRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'editions',
    handle: {
      breadcrumb: <Translate ns="edition" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        handle: {
          helmet: <Translate ns="edition">{(t) => <Helmet title={t('lister.helmet')} />}</Translate>,
        },
        element: <EditionLister />,
      },
      {
        path: 'new',
        handle: {
          breadcrumb: <Translate ns="edition" i18nKey="action.new" />,
          helmet: <Translate ns="edition">{(t) => <Helmet title={t('editor.helmetNew')} />}</Translate>,
        },
        element: <EditionEditor editorAction={EditionEditorAction.NEW} />,
      },
      {
        path: ':editionId',
        handle: {
          breadcrumb: <EditionBreadcrumb />,
        },
        children: [
          {
            path: '',
            element: <EditionPage />,
            children: [
              {
                path: '',
                element: <EditionViewer />,
                handle: {
                  helmet: <EditionHelmet i18nKey="viewer.helmet" />,
                },
              },
              {
                path: 'history',
                element: <EditionHistory />,
                handle: {
                  helmet: <EditionHelmet i18nKey="history.helmet" />,
                },
              },
            ],
          },
          {
            path: 'edit',
            handle: {
              breadcrumb: <Translate ns="edition" i18nKey="action.edit" />,
              helmet: <EditionHelmet i18nKey="editor.helmetEdit" />,
            },
            element: <EditionEditor editorAction={EditionEditorAction.EDIT} />,
          },
        ],
      },
    ],
  },
];

interface EditionHelmetProps {
  i18nKey: ParseKeys<'edition'>;
}

function EditionHelmet({ i18nKey }: EditionHelmetProps) {
  const { t } = useTranslation('edition');
  const params = useParams<{ editionId: string }>();
  invariant(params.editionId, 'Empty editionId');
  const edition = useEdition(params.editionId);

  return <Helmet title={t(i18nKey, { edition: edition.name })} />;
}

function EditionBreadcrumb() {
  const { editionId } = useParams<{ editionId: string }>();
  invariant(editionId, 'Empty editionId');
  const edition = useEdition(editionId);

  return <>{edition.name}</>;
}

export default editionRoutes;
