import { Stack, Text, useId } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ValueSelect from '../../select/value-select';
import { useDataTableFilter } from '../data-table-context';

/**
 * Exists filter operations.
 */

export enum OperatorOption {
  EXISTS = 'exists',
  NOT_EXISTS = '!exists',
}

type OperatorOptionStrings = keyof typeof OperatorOption;

export interface ExistsFilterProps {
  label: React.ReactNode;
}

/**
 * Exists filter, may be used for all string columns in data tables.
 */

export default function ExistsFilter({ label }: ExistsFilterProps) {
  const { t } = useTranslation('common');
  const { property, getFilter, setFilter, removeFilters, initialFocusRef } = useDataTableFilter();
  const id = useId(undefined, 'exists-filter');
  const { operator } = getFilter(property) ?? { operator: null };
  const operatorOption = operator as OperatorOption | null;

  const handleOperatorChange = (operatorOption: OperatorOption | null) => {
    if (operatorOption) {
      setFilter({ property, operator: operatorOption, value: '' });
    } else {
      removeFilters(property);
    }
  };

  // get the enum key
  const getKeyByValue = (value: OperatorOption) => {
    const indexOfKey = Object.values(OperatorOption).indexOf(value);
    return Object.keys(OperatorOption)[indexOfKey] as OperatorOptionStrings;
  };

  return (
    <Stack as="fieldset" spacing={2} aria-labelledby={`${id}-label`}>
      <Text as="div" fontSize="sm" fontWeight="medium" id={`${id}-label`}>
        {label}
      </Text>
      <ValueSelect<OperatorOption>
        size="sm"
        options={[OperatorOption.EXISTS, OperatorOption.NOT_EXISTS]}
        renderLabel={(option) => t(`data_table.exists_filter.${getKeyByValue(option)}`)}
        name="operator"
        isClearable
        aria-label={t(`data_table.string_filter.operator_label`)}
        onChange={handleOperatorChange}
        value={operatorOption}
        ref={initialFocusRef}
      />
    </Stack>
  );
}
