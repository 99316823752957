import { HStack, StackItem } from '@chakra-ui/react';
import React, { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyRoleDto } from '../../../api';
import ValueSelectFormControl from '../../../ui/form/select-control/value-select-form-control';
import { companyFilmRoleOptions } from './film-enum-constants';

export interface CompanyFilmRoleSelectControlProps {
  initialFocusRef?: RefObject<HTMLInputElement | HTMLElement>;
}

export function CompanyFilmRoleSelectControl({ initialFocusRef }: CompanyFilmRoleSelectControlProps) {
  const { t } = useTranslation('company');

  return (
    <ValueSelectFormControl<CompanyRoleDto>
      label={t('relation.film.role')}
      options={companyFilmRoleOptions}
      renderLabel={(value) => <CompanyFilmRelationTypeRenderLabel relationType={value} />}
      getStringValue={(value) => t(`relation.film.roleOptions.${value}`)}
      name="role"
      ref={initialFocusRef}
      isRequired
    />
  );
}

function CompanyFilmRelationTypeRenderLabel({ relationType }: { relationType: CompanyRoleDto }) {
  const { t } = useTranslation('company');

  return (
    <HStack spacing={2} width="full" overflow="hidden">
      <StackItem noOfLines={1} flexShrink={1}>
        {t(`relation.film.roleOptions.${relationType}`)}
      </StackItem>
    </HStack>
  );
}
