import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';

export const sectionPageRoutes: () => (BreadcrumbRouteObject & HelmetRouteObject)[] = () => [
  {
    path: 'roles',
    lazy: () => import('./permission-role-lister/permission-role-lister-route'),
  },
];

export const staffPageRoutes: () => (BreadcrumbRouteObject & HelmetRouteObject)[] = () => [
  {
    path: 'roles',
    lazy: () => import('./permission-role-lister/roles-lister-for-staff-route'),
  },
];
