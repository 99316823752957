import { difference, get, has, isEmpty, isEqual, isObject } from 'lodash-es';
import React, { useRef } from 'react';
import { Path } from 'react-hook-form';
import { HistoryEntryDto } from '../../api';
import { ChangeDto, cleanArrays } from './change-dto';
import HistoryDisplaySettings, {
  HistoryStatusEnum,
  SubElementHistoryDisplaySettings,
} from './history-display-settings';
import { removeIgnoredAttributes } from './history-entry-diff-data-table';
import { SubElement } from './sub-element';
import { SubElements } from './sub-elements';
import useSizeEqualizer from './use-size-equalizer';

const DEFAULT_SUB_ELEMENT_KEY_ATTRIBUTE = 'timestamp';

interface HistoryEntryDataTableProps {
  entry: HistoryEntryDto;
  attributeLabels: HistoryDisplaySettings['attributeLabels'];
  valueFormatter: HistoryDisplaySettings['valueFormatter'];
  statusFormatter?: HistoryDisplaySettings['statusFormatter'];
  subElementSettings?: HistoryDisplaySettings['subElementSettings'];
  showStringWithoutChange?: HistoryDisplaySettings['showStringWithoutChange'];
  hideTableForCreatedAndDeleted?: HistoryDisplaySettings['hideTableForCreatedAndDeleted'];
}

export function useChangedHistoryEntries({
  entry,
  statusFormatter,
  attributeLabels,
  subElementSettings,
  valueFormatter,
  showStringWithoutChange,
  hideTableForCreatedAndDeleted,
}: HistoryEntryDataTableProps) {
  function attributeLabel(
    entry: any,
    labelOrFunc: React.ReactNode | ((parent: any) => React.ReactNode),
  ): React.ReactNode {
    if (labelOrFunc?.constructor === Function) {
      const func = labelOrFunc as (x: any) => React.ReactNode;
      return func(entry);
    } else {
      return labelOrFunc as React.ReactNode;
    }
  }
  function convertSubElement(entry: any, subElementSettings: SubElementHistoryDisplaySettings<any>): SubElements {
    const objectKeys = entry == null ? [] : Object.keys(subElementSettings.attributeLabels);
    const elements = objectKeys
      .map((key) => {
        const value = get(entry, key);
        const labelOrFunc = subElementSettings.attributeLabels[key];
        const keyString = attributeLabel(entry, labelOrFunc);
        const formatter = subElementSettings.valueFormatter[key];
        const valueString = (formatter != null ? formatter(value, entry) : (value as string)) ?? '';
        if (valueString === '') {
          return null;
        }
        return {
          attribute: key,
          key: keyString,
          value: valueString,
        } as SubElement<any>;
      })
      .filter(notEmpty);
    return {
      elements: elements,
    };
  }

  function convertSubElements(
    entries: any[],
    subElementSettings: SubElementHistoryDisplaySettings<any>,
  ): SubElements[] {
    if (entries == null) {
      return [];
    }
    return entries.map((element, index) => {
      return {
        elements: convertSubElement(element, subElementSettings).elements.map((e) => ({ ...e, index: index })),
      };
    });
  }

  function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
  }

  function convertEntryToString(path: string, entry: any, parent: any): string | SubElements[] {
    const formatter = valueFormatter[path];
    if (formatter != null) {
      const formatterForEntry = formatter(entry, parent);
      if (formatterForEntry != null) {
        return formatterForEntry;
      }
    }
    const entrySettings = subElementSettings == null ? null : subElementSettings[path];
    if (entrySettings != null) {
      if (Array.isArray(entry)) {
        return convertSubElements(entry, entrySettings(entry)!);
      } else {
        return [convertSubElement(entry, entrySettings(entry)!)];
      }
    } else {
      if (Array.isArray(entry)) {
        return '';
      } else {
        return (entry as string) ?? '';
      }
    }
  }

  function isArrayOfObjects(entry: any): boolean {
    return Array.isArray(entry) && entry.every((b) => isObject(b));
  }

  const convertPartialToChange = (entry: HistoryEntryDto, configuredPaths: Path<any>[]): ChangeDto<any>[] => {
    const result = configuredPaths
      .filter((path) => has(entry.before, path) || has(entry.after, path))
      .map((path) => {
        const parentBefore = entry.before;
        let before = get(parentBefore, path);
        const parentAfter = entry.after;
        let after = get(parentAfter, path);
        let newBefore = before;
        let newAfter = after;
        const listSettings = subElementSettings?.[path];

        if (
          listSettings != null &&
          ((isArrayOfObjects(before) && (after == null || isArrayOfObjects(after))) ||
            (before == null && isArrayOfObjects(after)))
        ) {
          before = before ?? [];
          after = after ?? [];

          newBefore = [];
          newAfter = [];

          before.forEach((b: any) => {
            const keyAttribute = listSettings(b)?.keyAttribute ?? DEFAULT_SUB_ELEMENT_KEY_ATTRIBUTE;
            const found = after.find((o: any) =>
              get(o, keyAttribute) instanceof Date
                ? get(o, keyAttribute).getTime() === get(b, keyAttribute).getTime()
                : get(o, keyAttribute) === get(b, keyAttribute),
            );
            newBefore.push(b);
            newAfter.push(found == null ? null : found);
          });

          const restOfAfter = difference(after, newAfter);
          restOfAfter.forEach((o) => {
            newBefore.push(null);
            newAfter.push(o);
          });
        }

        newBefore = removeIgnoredAttributes(newBefore, listSettings);
        newAfter = removeIgnoredAttributes(newAfter, listSettings);

        return {
          before: convertEntryToString(path, newBefore, parentBefore),
          after: convertEntryToString(path, newAfter, parentAfter),
          attribute: path,
          subElementSettings: listSettings != null ? listSettings(newBefore) : undefined,
        };
      })
      .filter((change) => {
        return (
          !isEqual(change.before, change.after) ||
          (showStringWithoutChange != null && change.before === showStringWithoutChange)
        );
      });

    result.forEach((change) => cleanArrays(change));
    return result.filter(
      (change) =>
        !(
          Array.isArray(change.before) &&
          change.before.length === 0 &&
          Array.isArray(change.after) &&
          change.after.length === 0
        ),
    );
  };

  const status = statusFormatter?.(entry.after);

  const sizeEqualizerContainerRef = useRef<HTMLDivElement>(null);
  useSizeEqualizer(sizeEqualizerContainerRef);

  if (
    status === HistoryStatusEnum.ANONYMISED ||
    status === HistoryStatusEnum.DELETED ||
    (isEmpty(entry.before) && isEmpty(entry.after))
  ) {
    return [];
  }

  if (hideTableForCreatedAndDeleted && (isEmpty(entry.before) || isEmpty(entry.after))) {
    return [];
  }

  const rows = convertPartialToChange(entry, Object.keys(attributeLabels) as Path<any>[]);

  if (rows.length === 0) {
    return [];
  }

  return rows;
}
