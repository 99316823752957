import { AccordionButton, AccordionItem, AccordionPanel, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import React from 'react';
import { Trans } from 'react-i18next';
import AccordionIcon from '../../../ui/accordion/accordion-icon';

export function HelperItem({ namespace, title, text }: { namespace: any; title: any; text: any[] }) {
  return (
    <AccordionItem>
      <AccordionButton>
        <span>
          <Trans ns={namespace} i18nKey={title} components={{ em: <em /> }} />
        </span>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel px={4} pt={3} pb={4}>
        {text.map((t, index) => (
          <Text key={index}>
            <Trans ns={namespace} i18nKey={t} components={{ ul: <UnorderedList />, li: <ListItem /> }} />
          </Text>
        ))}
      </AccordionPanel>
    </AccordionItem>
  );
}
