/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  FailureDto,
  LiveTicketingPublicationDto,
  LiveTicketingPublicationPageDto,
  PageableDto,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    LiveTicketingPublicationDtoFromJSON,
    LiveTicketingPublicationDtoToJSON,
    LiveTicketingPublicationPageDtoFromJSON,
    LiveTicketingPublicationPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface GetLiveTicketingPublicationsRequest {
    eventId: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface HasLiveTicketingPublicationRequest {
    eventId: string;
    personId: string;
}

export interface SavePublicationRequest {
    eventId: string;
    liveTicketingPublicationDto: LiveTicketingPublicationDto;
}

export interface SuspendLiveTicketingPublicationRequest {
    eventId: string;
    publicationId: string;
}

/**
 * 
 */
export class LiveTicketingPublicationsApi extends runtime.BaseAPI {

    /**
     * Retrieve a page of publications
     */
    async getLiveTicketingPublicationsRaw(requestParameters: GetLiveTicketingPublicationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LiveTicketingPublicationPageDto>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling getLiveTicketingPublications().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/publications/live-ticketing/{eventId}`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LiveTicketingPublicationPageDtoFromJSON(jsonValue));
    }

    /**
     * Retrieve a page of publications
     */
    async getLiveTicketingPublications(requestParameters: GetLiveTicketingPublicationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LiveTicketingPublicationPageDto> {
        const response = await this.getLiveTicketingPublicationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get whether the specified person already has valid live ticketing publication for the given event.
     */
    async hasLiveTicketingPublicationRaw(requestParameters: HasLiveTicketingPublicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling hasLiveTicketingPublication().'
            );
        }

        if (requestParameters['personId'] == null) {
            throw new runtime.RequiredError(
                'personId',
                'Required parameter "personId" was null or undefined when calling hasLiveTicketingPublication().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['personId'] != null) {
            queryParameters['personId'] = requestParameters['personId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/publications/live-ticketing/{eventId}/has-publication`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     * Get whether the specified person already has valid live ticketing publication for the given event.
     */
    async hasLiveTicketingPublication(requestParameters: HasLiveTicketingPublicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.hasLiveTicketingPublicationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Save a Publication
     */
    async savePublicationRaw(requestParameters: SavePublicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling savePublication().'
            );
        }

        if (requestParameters['liveTicketingPublicationDto'] == null) {
            throw new runtime.RequiredError(
                'liveTicketingPublicationDto',
                'Required parameter "liveTicketingPublicationDto" was null or undefined when calling savePublication().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/publications/live-ticketing/{eventId}`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LiveTicketingPublicationDtoToJSON(requestParameters['liveTicketingPublicationDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Save a Publication
     */
    async savePublication(requestParameters: SavePublicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.savePublicationRaw(requestParameters, initOverrides);
    }

    /**
     * Suspend a publication
     */
    async suspendLiveTicketingPublicationRaw(requestParameters: SuspendLiveTicketingPublicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling suspendLiveTicketingPublication().'
            );
        }

        if (requestParameters['publicationId'] == null) {
            throw new runtime.RequiredError(
                'publicationId',
                'Required parameter "publicationId" was null or undefined when calling suspendLiveTicketingPublication().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['publicationId'] != null) {
            queryParameters['publicationId'] = requestParameters['publicationId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/publications/live-ticketing/{eventId}/suspend`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Suspend a publication
     */
    async suspendLiveTicketingPublication(requestParameters: SuspendLiveTicketingPublicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.suspendLiveTicketingPublicationRaw(requestParameters, initOverrides);
    }

}
