/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { LandingPageRsvpModuleContentDto } from './LandingPageRsvpModuleContentDto';
import {
    instanceOfLandingPageRsvpModuleContentDto,
    LandingPageRsvpModuleContentDtoFromJSON,
    LandingPageRsvpModuleContentDtoFromJSONTyped,
    LandingPageRsvpModuleContentDtoToJSON,
} from './LandingPageRsvpModuleContentDto';

/**
 * @type LandingPageModuleContentDto
 * 
 * @export
 */
export type LandingPageModuleContentDto = { moduleType: 'rsvp' } & LandingPageRsvpModuleContentDto;

export function LandingPageModuleContentDtoFromJSON(json: any): LandingPageModuleContentDto {
    return LandingPageModuleContentDtoFromJSONTyped(json, false);
}

export function LandingPageModuleContentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageModuleContentDto {
    if (json == null) {
        return json;
    }
    switch (json['moduleType']) {
        case 'rsvp':
            return {...LandingPageRsvpModuleContentDtoFromJSONTyped(json, true), moduleType: 'rsvp'};
        default:
            throw new Error(`No variant of LandingPageModuleContentDto exists with 'moduleType=${json['moduleType']}'`);
    }
}

export function LandingPageModuleContentDtoToJSON(value?: LandingPageModuleContentDto | null): any {
    if (value == null) {
        return value;
    }
    switch (value['moduleType']) {
        case 'rsvp':
            return LandingPageRsvpModuleContentDtoToJSON(value);
        default:
            throw new Error(`No variant of LandingPageModuleContentDto exists with 'moduleType=${value['moduleType']}'`);
    }

}

