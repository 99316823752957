/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AllocationStatusDto } from './AllocationStatusDto';
import {
    AllocationStatusDtoFromJSON,
    AllocationStatusDtoFromJSONTyped,
    AllocationStatusDtoToJSON,
} from './AllocationStatusDto';

/**
 * Dto to open or close all allocations of a guest list at once
 * @export
 * @interface OpenCloseAllAllocationsDto
 */
export interface OpenCloseAllAllocationsDto {
    /**
     * 
     * @type {AllocationStatusDto}
     * @memberof OpenCloseAllAllocationsDto
     */
    status: AllocationStatusDto;
}

/**
 * Check if a given object implements the OpenCloseAllAllocationsDto interface.
 */
export function instanceOfOpenCloseAllAllocationsDto(value: object): boolean {
    if (!('status' in value)) return false;
    return true;
}

export function OpenCloseAllAllocationsDtoFromJSON(json: any): OpenCloseAllAllocationsDto {
    return OpenCloseAllAllocationsDtoFromJSONTyped(json, false);
}

export function OpenCloseAllAllocationsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenCloseAllAllocationsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'status': AllocationStatusDtoFromJSON(json['status']),
    };
}

export function OpenCloseAllAllocationsDtoToJSON(value?: OpenCloseAllAllocationsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': AllocationStatusDtoToJSON(value['status']),
    };
}

