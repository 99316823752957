import React from 'react';
import { sectionIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import SectionSearchResult from './section-search-result';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { useSectionPermissionClassGroupExtension } from './section-permissions';

export default function SectionPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, { type: 'SECTION', Component: SectionSearchResult });
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useSectionPermissionClassGroupExtension);
  registry.register(SEARCH_FILTER, {
    key: 'SECTION',
    icon: sectionIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.SECTION" />,
    necessaryPermission: 'SECTION:CAN_SEE_DETAILS_OWN',
  });
}
