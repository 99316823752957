/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PersonOnGuestListDto } from './PersonOnGuestListDto';
import {
    PersonOnGuestListDtoFromJSON,
    PersonOnGuestListDtoFromJSONTyped,
    PersonOnGuestListDtoToJSON,
} from './PersonOnGuestListDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';

/**
 * 
 * @export
 * @interface HasBothMergedPeopleButNotTheMergedPersonOnGuestListDto
 */
export interface HasBothMergedPeopleButNotTheMergedPersonOnGuestListDto {
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof HasBothMergedPeopleButNotTheMergedPersonOnGuestListDto
     */
    mergedInto: PersonReferenceDto;
    /**
     * 
     * @type {PersonOnGuestListDto}
     * @memberof HasBothMergedPeopleButNotTheMergedPersonOnGuestListDto
     */
    mergedFromA: PersonOnGuestListDto;
    /**
     * 
     * @type {PersonOnGuestListDto}
     * @memberof HasBothMergedPeopleButNotTheMergedPersonOnGuestListDto
     */
    mergedFromB: PersonOnGuestListDto;
}

/**
 * Check if a given object implements the HasBothMergedPeopleButNotTheMergedPersonOnGuestListDto interface.
 */
export function instanceOfHasBothMergedPeopleButNotTheMergedPersonOnGuestListDto(value: object): boolean {
    if (!('mergedInto' in value)) return false;
    if (!('mergedFromA' in value)) return false;
    if (!('mergedFromB' in value)) return false;
    return true;
}

export function HasBothMergedPeopleButNotTheMergedPersonOnGuestListDtoFromJSON(json: any): HasBothMergedPeopleButNotTheMergedPersonOnGuestListDto {
    return HasBothMergedPeopleButNotTheMergedPersonOnGuestListDtoFromJSONTyped(json, false);
}

export function HasBothMergedPeopleButNotTheMergedPersonOnGuestListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HasBothMergedPeopleButNotTheMergedPersonOnGuestListDto {
    if (json == null) {
        return json;
    }
    return {
        
        'mergedInto': PersonReferenceDtoFromJSON(json['mergedInto']),
        'mergedFromA': PersonOnGuestListDtoFromJSON(json['mergedFromA']),
        'mergedFromB': PersonOnGuestListDtoFromJSON(json['mergedFromB']),
    };
}

export function HasBothMergedPeopleButNotTheMergedPersonOnGuestListDtoToJSON(value?: HasBothMergedPeopleButNotTheMergedPersonOnGuestListDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'mergedInto': PersonReferenceDtoToJSON(value['mergedInto']),
        'mergedFromA': PersonOnGuestListDtoToJSON(value['mergedFromA']),
        'mergedFromB': PersonOnGuestListDtoToJSON(value['mergedFromB']),
    };
}

