import formatCompanyApi from '../../../data-access/format-company-api';
import { createFetcherScope } from '../../../util/swr/fetcher-scope';

export const formatCompanyFetcher = createFetcherScope(formatCompanyApi);

export const fetchCompaniesByFormat = formatCompanyFetcher.createFetcher(formatCompanyApi.searchFormatCompanyByFormat);
export const fetchFormatsByCompany = formatCompanyFetcher.createFetcher(formatCompanyApi.searchFormatCompanyByCompany);

export const fetchFormatCompanyHistoryByFormat = formatCompanyFetcher.createFetcher(
  formatCompanyApi.fetchFormatCompanyHistoryByFormat,
);
export const fetchFormatCompanyHistoryByCompany = formatCompanyFetcher.createFetcher(
  formatCompanyApi.fetchFormatCompanyHistoryByCompany,
);
