import { Box, Center, Tooltip } from '@chakra-ui/react';
import { faCodeMerge, faFaceDisguise, faPencil, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HistoryEntryDto } from '../../api';
import HistoryDisplaySettings, { HistoryStatusEnum } from './history-display-settings';

interface HistoryEntryIconProps {
  entry: HistoryEntryDto;
  statusFormatter?: HistoryDisplaySettings['statusFormatter'];
}

export default function HistoryEntryIcon({ entry, statusFormatter }: HistoryEntryIconProps) {
  const { t } = useTranslation('common');
  const status = statusFormatter?.(entry.after);

  const getActionTooltip = () => {
    if (entry.mergedInto != null || entry.createdFromMergeOf != null) {
      return undefined;
    }

    if (status === HistoryStatusEnum.DELETED) {
      return t('history.deleted_tooltip');
    }

    if (entry.before == null) {
      return t('history.created_tooltip');
    }

    return t(entry.after == null ? 'history.deleted_tooltip' : 'history.changed_tooltip');
  };

  const getActionIcon = () => {
    if (entry.mergedInto != null || entry.createdFromMergeOf != null) {
      return faCodeMerge;
    }

    if (status === HistoryStatusEnum.ANONYMISED) {
      return faFaceDisguise;
    }

    if (status === HistoryStatusEnum.DELETED) {
      return faTrashAlt;
    }

    if (entry.before == null) {
      return faPlus;
    }

    return entry.after == null ? faTrashAlt : faPencil;
  };

  const tooltip = getActionTooltip();

  return (
    <Center
      aria-label={tooltip}
      sx={{
        borderRadius: 'full',
        border: '1px',
        borderColor: 'border.01',
        w: 8,
        h: 8,
        bg: 'layer.01',
      }}
    >
      <Tooltip label={tooltip}>
        <Box
          as={FontAwesomeIcon}
          icon={getActionIcon()}
          fixedWidth
          sx={{
            color: 'text.muted',
          }}
        />
      </Tooltip>
    </Center>
  );
}
