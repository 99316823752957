import { forwardRef, Square, SquareProps } from '@chakra-ui/react';
import React from 'react';
import { AllocationColorDto, AllocationColorDtoColorNameEnum } from '../../../api';

const COLOR_MAP: Record<AllocationColorDtoColorNameEnum, string> = {
  [AllocationColorDtoColorNameEnum.GRAY]: 'gray',
  [AllocationColorDtoColorNameEnum.RED]: 'red',
  [AllocationColorDtoColorNameEnum.ORANGE]: 'orange',
  [AllocationColorDtoColorNameEnum.YELLOW]: 'yellow',
  [AllocationColorDtoColorNameEnum.GREEN]: 'green',
  [AllocationColorDtoColorNameEnum.BLUE]: 'blue',
  [AllocationColorDtoColorNameEnum.CYAN]: 'cyan',
  [AllocationColorDtoColorNameEnum.PURPLE]: 'purple',
  [AllocationColorDtoColorNameEnum.PINK]: 'pink',
};

interface AllocationColorProps extends Omit<SquareProps, 'color'> {
  color: AllocationColorDto | undefined;
  size?: number;
}

function AllocationColor({ color, size = 6, ...props }: AllocationColorProps, ref: React.ForwardedRef<HTMLDivElement>) {
  return color == null ? null : (
    <Square backgroundColor={getChakraColor(color)} size={size} borderRadius="sm" {...props} ref={ref} />
  );
}

export default forwardRef<AllocationColorProps, 'div'>(AllocationColor);

export function getChakraColor(color: AllocationColorDto): string {
  const chakraColor = COLOR_MAP[color.colorName];
  let intensity = color.intensity === 1 ? 50 : color.intensity * 200 - 200;
  if (
    intensity === 50 &&
    (color.colorName === AllocationColorDtoColorNameEnum.RED ||
      color.colorName === AllocationColorDtoColorNameEnum.PINK)
  ) {
    intensity = 100;
  }
  return `${chakraColor}.${intensity}`;
}
