import React from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import MailingDesignBreadcrumb from './mailing-design-breadcrumb';

const mailingDesignRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'mailing-designs',
    handle: {
      breadcrumb: <Translate ns="mailing_design" i18nKey="mailing_design" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./mailing-design-lister/mailing-design-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./mailing-design-editor/new-mailing-design-route'),
      },
      {
        path: ':designId',
        handle: {
          breadcrumb: <MailingDesignBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./mailing-design-page/mailing-design-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./mailing-design-viewer/mailing-design-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./mailing-design-history/mailing-design-history-route'),
              },
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./mailing-design-editor/edit-mailing-design-route'),
          },
        ],
      },
    ],
  },
];

export default mailingDesignRoutes;
