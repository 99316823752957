import { Box, HStack, StackItem } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { StatusDisplayOptions } from './status-display-options';

interface StatusDisplayProps {
  label: React.ReactNode;
  displayOptions: StatusDisplayOptions;
}

export default function StatusDisplay({ label, displayOptions }: StatusDisplayProps) {
  return (
    <HStack>
      <Box as={FontAwesomeIcon} icon={displayOptions.icon} color={displayOptions.iconColor} />
      <StackItem>{label}</StackItem>
    </HStack>
  );
}
