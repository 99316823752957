import { Box, Button, useMultiStyleConfig, useTab } from '@chakra-ui/react';

export default function CustomTab({ left, right, ...props }: any) {
  const tabProps = useTab(props);
  const isSelected = tabProps['aria-selected'];

  const styles = useMultiStyleConfig('Tabs', tabProps);

  return (
    <Button
      __css={styles.tab}
      {...tabProps}
      borderWidth={1}
      borderColor={isSelected ? 'border.interactive' : 'border.01'}
      fontSize="sm"
      fontWeight={600}
      width="50%"
      height={8}
      pt={1}
      color="text.interactive"
      bgColor={isSelected ? 'background.hero' : 'layer.02'}
      borderRightRadius={right ? 'md' : undefined}
      borderLeftRadius={left ? 'md' : undefined}
    >
      <Box>{tabProps.children}</Box>
    </Button>
  );
}
