import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';
import { difference, forEach, isArray, isPlainObject } from 'lodash-es';
import React, { ReactNode, RefObject, useEffect, useRef } from 'react';
import { DefaultValues, Path, useFormContext } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { useTranslation } from 'react-i18next';
import useCallbackRef from '../../../util/use-callback-ref/use-callback-ref';
import Form from '../form';
import { useElementFormModal } from './element-form-modal-context';

/**
 * Properties for element form modal.
 */
interface ElementFormModalProps<TElement> {
  onSubmit(element: TElement): void;
  onReset?(): void;
  submitDisabled?: boolean;
  element: TElement | undefined;
  initialFocusRef: RefObject<any>;
  defaultElement?: DefaultValues<TElement>;
  ignoreIsDirty?: boolean;
  children?: ReactNode;
  scrollBehavior?: ModalProps['scrollBehavior'];
  touchedFields?: Path<TElement>[];
  extraActionButton?: React.ReactElement;
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
}

/**
 * Default control to display elements in a modal.
 */
export default function ElementFormModal<TElement>({
  onSubmit,
  onReset,
  submitDisabled,
  element,
  defaultElement,
  initialFocusRef,
  ignoreIsDirty = false,
  children,
  scrollBehavior,
  touchedFields,
  extraActionButton,
  size,
}: ElementFormModalProps<TElement>) {
  const { onClose, label, isOpen } = useElementFormModal();
  const { resetField, formState } = useFormContext();
  const { t } = useTranslation('common');
  useElementFormModalReset({ isOpen, element, touchedFields, defaultElement, onReset });

  const prevFaultyFieldPathsRef = useRef<string[]>([]);

  const handleBlurForm = () => {
    // lh: Get previous faulty fields when some field inside the form was blurred.
    // This is needed for resetting the correct form fields inside handleFocusCloseButton.
    prevFaultyFieldPathsRef.current = getFaultyFieldPaths(formState.errors);
  };

  const handleFocusCloseButton = () => {
    // disableValidationRef.current = true;

    const faultyFieldPaths = getFaultyFieldPaths(formState.errors);

    // lh: Focusing a button and therefore blurring a form field will trigger validation of this
    // field. If the validation fails and an error is shown, the layout might shift which also moves
    // the respective close button up or down. On the other hand the click event is only fired if
    // both mouse down and up events are triggered. If the button has moved, the mouse might not sit
    // above the button anymore. So … let's reset the last error to also reset the layout to its
    // previous height etc.
    difference(faultyFieldPaths, prevFaultyFieldPathsRef.current).forEach((fieldPath) => resetField(fieldPath));
  };

  const formIsDirty = Object.keys(formState.dirtyFields).length > 0;
  const formIsValid = formState.isValid;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialFocusRef}
      closeOnOverlayClick={false}
      scrollBehavior={scrollBehavior}
      size={size}
    >
      <ModalOverlay />
      <ModalContent
        as={Form}
        noValidate
        onValid={(element: unknown) => {
          onSubmit(element as TElement);
          onClose();
        }}
        onBlur={handleBlurForm}
        onSubmit={(event) => {
          // lh: Prevent parent form submission.
          event.stopPropagation();
        }}
        initialFocusRef={undefined}
      >
        <ModalHeader>{label}</ModalHeader>
        <ModalCloseButton onFocus={handleFocusCloseButton} />
        <ModalBody>{children}</ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose} onFocus={handleFocusCloseButton}>
            {t('action.abort')}
          </Button>
          {extraActionButton}
          <Button
            variant="primary"
            type="submit"
            isDisabled={(!formIsDirty && !ignoreIsDirty) || formState.isSubmitting || submitDisabled || !formIsValid}
          >
            {element != null ? t('action.apply') : t('action.add')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function useElementFormModalReset<TElement>({
  isOpen,
  element,
  touchedFields = [],
  defaultElement,
  onReset,
}: {
  isOpen: boolean;
  element: TElement | undefined;
  touchedFields?: Path<TElement>[];
  defaultElement?: DefaultValues<TElement>;
  onReset?: () => void;
}) {
  const { reset, setValue } = useFormContext();

  const elementRef = useRef<TElement>();
  elementRef.current = element;

  const defaultElementRef = useRef<DefaultValues<TElement>>();
  defaultElementRef.current = defaultElement;

  const touchedFieldsRef = useRef<Path<TElement>[]>([]);
  touchedFieldsRef.current = touchedFields;

  const onResetStable = useCallbackRef(onReset);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const element = { ...defaultElementRef.current, ...elementRef.current } as DefaultValues<TElement>;

    reset(element);
    onResetStable?.();

    // Reset seems to be async, so we need to set the values after the reset.
    const timeoutId = setTimeout(() => {
      touchedFieldsRef.current.forEach((field) =>
        setValue(field, (element as any)[field], { shouldTouch: true, shouldValidate: true, shouldDirty: true }),
      );
    });

    return () => {
      clearTimeout(timeoutId);
    };
  }, [reset, setValue, isOpen, onResetStable]);
}

function getFaultyFieldPaths(errors: FieldErrors) {
  return getFieldPaths(errors, (value) => isFieldError(value));
}

function getFieldPaths(value: any, isPrimitive?: (value: any) => boolean) {
  const fieldPaths: string[] = [];

  const flatten = (collection: any, prefix = '') => {
    forEach(collection, (value, key) => {
      const path = `${prefix}${key}`;

      if ((isPrimitive == null || !isPrimitive(value)) && (isArray(value) || isPlainObject(value))) {
        flatten(value, `${path}.`);
      } else {
        fieldPaths.push(path);
      }
    });
  };

  flatten(value);

  return fieldPaths;
}

function isFieldError(value: any): value is FieldErrors {
  return value != null && value.type;
}
