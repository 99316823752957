import { identity } from 'lodash-es';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import useCallbackRef from '../use-callback-ref/use-callback-ref';

export interface UseSearchParamsStateDecodeEncode<T> {
  decode(searchParam: string): T;
  encode(value: T): string | null;
}

export interface UseSearchParamsStateOptions<T> {
  name: string;
  defaultValue: T;
  replace?: boolean;
}

export default function useStateFromSearchParams<T extends string | null>(
  options: UseSearchParamsStateOptions<T> & Partial<UseSearchParamsStateDecodeEncode<T>>,
): [string | null, (value: string | null) => void];
export default function useStateFromSearchParams<T>(
  options: UseSearchParamsStateOptions<T> & UseSearchParamsStateDecodeEncode<T>,
): [T, (value: T) => void];
export default function useStateFromSearchParams<T>({
  name,
  defaultValue,
  replace = true,
  decode = identity,
  encode = identity,
}: UseSearchParamsStateOptions<T> & Partial<UseSearchParamsStateDecodeEncode<T>>): [T, (value: T) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamValue = searchParams.get(name);
  const value = searchParamValue != null ? decode(searchParamValue) : defaultValue;

  const setValue = useCallbackRef((value: T) => {
    const nextSearchParams = createSearchParams(searchParams);
    const nextSearchParamValue = encode(value);

    if (nextSearchParamValue != null) {
      nextSearchParams.set(name, nextSearchParamValue);
    } else {
      nextSearchParams.delete(name);
    }

    setSearchParams(nextSearchParams, { replace });
  });

  return [value, setValue];
}
