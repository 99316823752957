import { Box, chakra, Spacer } from '@chakra-ui/react';
import React from 'react';
import useDefinedContext from '../../util/context/use-defined-context/use-defined-context';
import Pagination from '../pagination/pagination';
import { DataTableStateContext } from './data-table-context';
import DataTablePage from './data-table-page';

interface DataTableFooterProps {
  page: DataTablePage<unknown>;
  children?: React.ReactNode;
}

function DataTableFooter({ page, children }: DataTableFooterProps) {
  const { state, dispatch } = useDefinedContext(DataTableStateContext);
  const { size: pageSize = 0, page: currentPage = 0 } = state;
  const { totalPages = 1, totalElements = 0 } = page;

  return (
    <chakra.footer display="flex" alignItems="center" padding={2} borderTop="1px" borderColor="border.01">
      {children}
      <Spacer />
      <Box
        as={Pagination}
        px={4}
        page={{ size: pageSize, page: currentPage, totalPages, totalElements }}
        onPrevPage={() => dispatch({ type: 'PREV_PAGE' })}
        onNextPage={() => dispatch({ type: 'NEXT_PAGE' })}
      />
    </chakra.footer>
  );
}

export default React.memo(DataTableFooter);
