/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * Dto to save group of coupled people on guest list.
 * @export
 * @interface SavePersonOnGuestListGroupDto
 */
export interface SavePersonOnGuestListGroupDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof SavePersonOnGuestListGroupDto
     */
    groupId?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof SavePersonOnGuestListGroupDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof SavePersonOnGuestListGroupDto
     */
    groupOfPeople: Array<string>;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof SavePersonOnGuestListGroupDto
     */
    mainPerson?: string;
}

/**
 * Check if a given object implements the SavePersonOnGuestListGroupDto interface.
 */
export function instanceOfSavePersonOnGuestListGroupDto(value: object): boolean {
    if (!('groupOfPeople' in value)) return false;
    return true;
}

export function SavePersonOnGuestListGroupDtoFromJSON(json: any): SavePersonOnGuestListGroupDto {
    return SavePersonOnGuestListGroupDtoFromJSONTyped(json, false);
}

export function SavePersonOnGuestListGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SavePersonOnGuestListGroupDto {
    if (json == null) {
        return json;
    }
    return {
        
        'groupId': json['groupId'] == null ? undefined : json['groupId'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'groupOfPeople': json['groupOfPeople'],
        'mainPerson': json['mainPerson'] == null ? undefined : json['mainPerson'],
    };
}

export function SavePersonOnGuestListGroupDtoToJSON(value?: SavePersonOnGuestListGroupDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'groupId': value['groupId'],
        'version': VersionDtoToJSON(value['version']),
        'groupOfPeople': value['groupOfPeople'],
        'mainPerson': value['mainPerson'],
    };
}

