/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EventEvaluationDto } from './EventEvaluationDto';
import {
    EventEvaluationDtoFromJSON,
    EventEvaluationDtoFromJSONTyped,
    EventEvaluationDtoToJSON,
} from './EventEvaluationDto';
import type { EventReferenceDto } from './EventReferenceDto';
import {
    EventReferenceDtoFromJSON,
    EventReferenceDtoFromJSONTyped,
    EventReferenceDtoToJSON,
} from './EventReferenceDto';

/**
 * 
 * @export
 * @interface EventEvaluationSearchResultDto
 */
export interface EventEvaluationSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof EventEvaluationSearchResultDto
     */
    resultType: EventEvaluationSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof EventEvaluationSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {EventEvaluationDto}
     * @memberof EventEvaluationSearchResultDto
     */
    evaluation: EventEvaluationDto;
    /**
     * 
     * @type {EventReferenceDto}
     * @memberof EventEvaluationSearchResultDto
     */
    event: EventReferenceDto;
}


/**
 * @export
 */
export const EventEvaluationSearchResultDtoResultTypeEnum = {
    EVENT_EVALUATION: 'EVENT_EVALUATION'
} as const;
export type EventEvaluationSearchResultDtoResultTypeEnum = typeof EventEvaluationSearchResultDtoResultTypeEnum[keyof typeof EventEvaluationSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the EventEvaluationSearchResultDto interface.
 */
export function instanceOfEventEvaluationSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('evaluation' in value)) return false;
    if (!('event' in value)) return false;
    return true;
}

export function EventEvaluationSearchResultDtoFromJSON(json: any): EventEvaluationSearchResultDto {
    return EventEvaluationSearchResultDtoFromJSONTyped(json, false);
}

export function EventEvaluationSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventEvaluationSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'evaluation': EventEvaluationDtoFromJSON(json['evaluation']),
        'event': EventReferenceDtoFromJSON(json['event']),
    };
}

export function EventEvaluationSearchResultDtoToJSON(value?: EventEvaluationSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'evaluation': EventEvaluationDtoToJSON(value['evaluation']),
        'event': EventReferenceDtoToJSON(value['event']),
    };
}

