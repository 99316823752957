/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GroupReferenceDto } from './GroupReferenceDto';
import {
    GroupReferenceDtoFromJSON,
    GroupReferenceDtoFromJSONTyped,
    GroupReferenceDtoToJSON,
} from './GroupReferenceDto';

/**
 * A person merge conflict with its cause in group person relation
 * @export
 * @interface MergePersonGroupPersonConflictDto
 */
export interface MergePersonGroupPersonConflictDto {
    /**
     * 
     * @type {string}
     * @memberof MergePersonGroupPersonConflictDto
     */
    conflictType: MergePersonGroupPersonConflictDtoConflictTypeEnum;
    /**
     * 
     * @type {GroupReferenceDto}
     * @memberof MergePersonGroupPersonConflictDto
     */
    group?: GroupReferenceDto;
}


/**
 * @export
 */
export const MergePersonGroupPersonConflictDtoConflictTypeEnum = {
    GUEST_LIST: 'GUEST_LIST',
    PARTICIPATION: 'PARTICIPATION',
    GROUP_PERSON: 'GROUP_PERSON'
} as const;
export type MergePersonGroupPersonConflictDtoConflictTypeEnum = typeof MergePersonGroupPersonConflictDtoConflictTypeEnum[keyof typeof MergePersonGroupPersonConflictDtoConflictTypeEnum];


/**
 * Check if a given object implements the MergePersonGroupPersonConflictDto interface.
 */
export function instanceOfMergePersonGroupPersonConflictDto(value: object): boolean {
    if (!('conflictType' in value)) return false;
    return true;
}

export function MergePersonGroupPersonConflictDtoFromJSON(json: any): MergePersonGroupPersonConflictDto {
    return MergePersonGroupPersonConflictDtoFromJSONTyped(json, false);
}

export function MergePersonGroupPersonConflictDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MergePersonGroupPersonConflictDto {
    if (json == null) {
        return json;
    }
    return {
        
        'conflictType': json['conflictType'],
        'group': json['group'] == null ? undefined : GroupReferenceDtoFromJSON(json['group']),
    };
}

export function MergePersonGroupPersonConflictDtoToJSON(value?: MergePersonGroupPersonConflictDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'conflictType': value['conflictType'],
        'group': GroupReferenceDtoToJSON(value['group']),
    };
}

