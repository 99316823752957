/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PersonOnGuestListDto } from './PersonOnGuestListDto';
import {
    PersonOnGuestListDtoFromJSON,
    PersonOnGuestListDtoFromJSONTyped,
    PersonOnGuestListDtoToJSON,
} from './PersonOnGuestListDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * Dto to show group of coupled people on guest list.
 * @export
 * @interface PersonOnGuestListGroupDto
 */
export interface PersonOnGuestListGroupDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof PersonOnGuestListGroupDto
     */
    groupId: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof PersonOnGuestListGroupDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {Array<PersonOnGuestListDto>}
     * @memberof PersonOnGuestListGroupDto
     */
    groupOfPeople: Array<PersonOnGuestListDto>;
    /**
     * 
     * @type {PersonOnGuestListDto}
     * @memberof PersonOnGuestListGroupDto
     */
    mainPerson?: PersonOnGuestListDto;
}

/**
 * Check if a given object implements the PersonOnGuestListGroupDto interface.
 */
export function instanceOfPersonOnGuestListGroupDto(value: object): boolean {
    if (!('groupId' in value)) return false;
    if (!('groupOfPeople' in value)) return false;
    return true;
}

export function PersonOnGuestListGroupDtoFromJSON(json: any): PersonOnGuestListGroupDto {
    return PersonOnGuestListGroupDtoFromJSONTyped(json, false);
}

export function PersonOnGuestListGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonOnGuestListGroupDto {
    if (json == null) {
        return json;
    }
    return {
        
        'groupId': json['groupId'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'groupOfPeople': ((json['groupOfPeople'] as Array<any>).map(PersonOnGuestListDtoFromJSON)),
        'mainPerson': json['mainPerson'] == null ? undefined : PersonOnGuestListDtoFromJSON(json['mainPerson']),
    };
}

export function PersonOnGuestListGroupDtoToJSON(value?: PersonOnGuestListGroupDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'groupId': value['groupId'],
        'version': VersionDtoToJSON(value['version']),
        'groupOfPeople': ((value['groupOfPeople'] as Array<any>).map(PersonOnGuestListDtoToJSON)),
        'mainPerson': PersonOnGuestListDtoToJSON(value['mainPerson']),
    };
}

