import { chakra, Flex } from '@chakra-ui/react';
import { endOfDay, isValid } from 'date-fns';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DateRangeDto } from '../../../api';
import { DEBOUNCE_TIME } from '../../../util/constants';
import useDebouncedState from '../../../util/debounce/use-debounced-state';
import DateInputFormControl from '../../form/date-input-control/date-input-form-control';
import { useDataTableFilter } from '../data-table-context';

interface DateRangeFilterProps {
  showTimeSelect?: boolean;
  isTimestamp?: boolean;
  twoLine?: boolean;
}

/**
 * Date range filter, may be used for all date and date/time columns in data tables.
 * If showTimeSelect is set, a date/time control will be displayed instead of a simple date control.
 */
export default function DateRangeFilter({
  showTimeSelect = false,
  isTimestamp = false,
  twoLine = false,
}: DateRangeFilterProps) {
  const { t } = useTranslation('common');
  const form = useForm<Partial<DateRangeDto>>({ mode: 'all' });
  const { property, getFilter, setFilter, removeFilters, initialFocusRef, setIsCloseable } = useDataTableFilter();
  const startProperty = `${property}.start`;
  const endProperty = `${property}.end`;
  const startFilter = getFilter(startProperty);
  const endFilter = getFilter(endProperty);

  const [start, setStart] = useDebouncedState(
    startFilter?.value,
    (start) => {
      if (start == null) {
        removeFilters(startProperty);
      } else {
        setFilter({ operator: 'gte', value: start, property: startProperty });
      }
    },
    DEBOUNCE_TIME,
  );

  const [end, setEnd] = useDebouncedState(
    endFilter?.value,
    (end) => {
      if (end == null) {
        removeFilters(endProperty);
      } else {
        setFilter({ operator: 'lte', value: end, property: endProperty });
      }
    },
    DEBOUNCE_TIME,
  );

  React.useEffect(() => {
    form.setValue('start', fromISODate(start));
  }, [form, start]);

  React.useEffect(() => {
    form.setValue('end', fromISODate(end));
  }, [form, end]);

  React.useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      const { start, end } = value;

      if (name === 'start' && (start == null || isValid(start))) {
        setStart(start && toISODate(start, isTimestamp || showTimeSelect));
      }

      if (name === 'end' && (end == null || isValid(end))) {
        let adjustedEnd = end;

        if (!showTimeSelect && end != null) {
          adjustedEnd = endOfDay(end);
        }

        setEnd(adjustedEnd ? toISODate(adjustedEnd, isTimestamp || showTimeSelect) : undefined);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [form, isTimestamp, setEnd, setStart, showTimeSelect]);

  return (
    <FormProvider {...form}>
      <chakra.form>
        <Flex gap={3} flexDirection={twoLine ? 'column' : 'row'}>
          <DateInputFormControl
            label={t('data_table.date_range_filter.start')}
            name="start"
            size="sm"
            showTimeSelect={showTimeSelect}
            ref={initialFocusRef}
            onPickerToggle={(isOpen) => setIsCloseable(!isOpen)}
          />
          <DateInputFormControl
            label={t('data_table.date_range_filter.end')}
            name="end"
            size="sm"
            showTimeSelect={showTimeSelect}
            onPickerToggle={(isOpen) => setIsCloseable(!isOpen)}
          />
        </Flex>
      </chakra.form>
    </FormProvider>
  );
}

function toISODate(date: Date, withTime: boolean): string {
  return withTime ? date.toISOString() : date.toISOString().split('T')[0];
}

function fromISODate(isoDate: string | undefined): Date | undefined {
  if (isoDate == null) {
    return undefined;
  }

  return new Date(isoDate);
}
