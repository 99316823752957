/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The gender of the person.
 * @export
 */
export const GenderIdentityDto = {
    FEMALE: 'FEMALE',
    MALE: 'MALE',
    NOT_SPECIFIED: 'NOT_SPECIFIED',
    NON_BINARY: 'NON_BINARY',
    FREE_HAND_TEXT: 'FREE_HAND_TEXT'
} as const;
export type GenderIdentityDto = typeof GenderIdentityDto[keyof typeof GenderIdentityDto];


export function GenderIdentityDtoFromJSON(json: any): GenderIdentityDto {
    return GenderIdentityDtoFromJSONTyped(json, false);
}

export function GenderIdentityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenderIdentityDto {
    return json as GenderIdentityDto;
}

export function GenderIdentityDtoToJSON(value?: GenderIdentityDto | null): any {
    return value as any;
}

