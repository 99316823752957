/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AcceptRecipientUpdateApplicationRequestDto
 */
export interface AcceptRecipientUpdateApplicationRequestDto {
    /**
     * 
     * @type {boolean}
     * @memberof AcceptRecipientUpdateApplicationRequestDto
     */
    createPerson?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AcceptRecipientUpdateApplicationRequestDto
     */
    createCompany?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AcceptRecipientUpdateApplicationRequestDto
     */
    calculateFee?: boolean;
}

/**
 * Check if a given object implements the AcceptRecipientUpdateApplicationRequestDto interface.
 */
export function instanceOfAcceptRecipientUpdateApplicationRequestDto(value: object): boolean {
    return true;
}

export function AcceptRecipientUpdateApplicationRequestDtoFromJSON(json: any): AcceptRecipientUpdateApplicationRequestDto {
    return AcceptRecipientUpdateApplicationRequestDtoFromJSONTyped(json, false);
}

export function AcceptRecipientUpdateApplicationRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcceptRecipientUpdateApplicationRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'createPerson': json['createPerson'] == null ? undefined : json['createPerson'],
        'createCompany': json['createCompany'] == null ? undefined : json['createCompany'],
        'calculateFee': json['calculateFee'] == null ? undefined : json['calculateFee'],
    };
}

export function AcceptRecipientUpdateApplicationRequestDtoToJSON(value?: AcceptRecipientUpdateApplicationRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'createPerson': value['createPerson'],
        'createCompany': value['createCompany'],
        'calculateFee': value['calculateFee'],
    };
}

