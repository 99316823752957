/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccessGroupReferenceDto } from './AccessGroupReferenceDto';
import {
    AccessGroupReferenceDtoFromJSON,
    AccessGroupReferenceDtoFromJSONTyped,
    AccessGroupReferenceDtoToJSON,
} from './AccessGroupReferenceDto';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { OrderStatusDto } from './OrderStatusDto';
import {
    OrderStatusDtoFromJSON,
    OrderStatusDtoFromJSONTyped,
    OrderStatusDtoToJSON,
} from './OrderStatusDto';
import type { PaymentStatusDto } from './PaymentStatusDto';
import {
    PaymentStatusDtoFromJSON,
    PaymentStatusDtoFromJSONTyped,
    PaymentStatusDtoToJSON,
} from './PaymentStatusDto';
import type { ProductBundleReferenceDto } from './ProductBundleReferenceDto';
import {
    ProductBundleReferenceDtoFromJSON,
    ProductBundleReferenceDtoFromJSONTyped,
    ProductBundleReferenceDtoToJSON,
} from './ProductBundleReferenceDto';
import type { ProductReferenceDto } from './ProductReferenceDto';
import {
    ProductReferenceDtoFromJSON,
    ProductReferenceDtoFromJSONTyped,
    ProductReferenceDtoToJSON,
} from './ProductReferenceDto';

/**
 * List item for editor view orders
 * @export
 * @interface OrderEditorViewItemDto
 */
export interface OrderEditorViewItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof OrderEditorViewItemDto
     */
    id: string;
    /**
     * The unique key for a order starting with "BE".
     * @type {string}
     * @memberof OrderEditorViewItemDto
     */
    orderNumber: string;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof OrderEditorViewItemDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {Array<ProductReferenceDto>}
     * @memberof OrderEditorViewItemDto
     */
    products: Array<ProductReferenceDto>;
    /**
     * 
     * @type {Array<ProductBundleReferenceDto>}
     * @memberof OrderEditorViewItemDto
     */
    productBundles: Array<ProductBundleReferenceDto>;
    /**
     * 
     * @type {Array<AccessGroupReferenceDto>}
     * @memberof OrderEditorViewItemDto
     */
    accessGroups: Array<AccessGroupReferenceDto>;
    /**
     * 
     * @type {OrderStatusDto}
     * @memberof OrderEditorViewItemDto
     */
    status: OrderStatusDto;
    /**
     * 
     * @type {PaymentStatusDto}
     * @memberof OrderEditorViewItemDto
     */
    paymentStatus: PaymentStatusDto;
    /**
     * 
     * @type {Date}
     * @memberof OrderEditorViewItemDto
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the OrderEditorViewItemDto interface.
 */
export function instanceOfOrderEditorViewItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('orderNumber' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('products' in value)) return false;
    if (!('productBundles' in value)) return false;
    if (!('accessGroups' in value)) return false;
    if (!('status' in value)) return false;
    if (!('paymentStatus' in value)) return false;
    if (!('createdAt' in value)) return false;
    return true;
}

export function OrderEditorViewItemDtoFromJSON(json: any): OrderEditorViewItemDto {
    return OrderEditorViewItemDtoFromJSONTyped(json, false);
}

export function OrderEditorViewItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderEditorViewItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'orderNumber': json['orderNumber'],
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'products': ((json['products'] as Array<any>).map(ProductReferenceDtoFromJSON)),
        'productBundles': ((json['productBundles'] as Array<any>).map(ProductBundleReferenceDtoFromJSON)),
        'accessGroups': ((json['accessGroups'] as Array<any>).map(AccessGroupReferenceDtoFromJSON)),
        'status': OrderStatusDtoFromJSON(json['status']),
        'paymentStatus': PaymentStatusDtoFromJSON(json['paymentStatus']),
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function OrderEditorViewItemDtoToJSON(value?: OrderEditorViewItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'orderNumber': value['orderNumber'],
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'products': ((value['products'] as Array<any>).map(ProductReferenceDtoToJSON)),
        'productBundles': ((value['productBundles'] as Array<any>).map(ProductBundleReferenceDtoToJSON)),
        'accessGroups': ((value['accessGroups'] as Array<any>).map(AccessGroupReferenceDtoToJSON)),
        'status': OrderStatusDtoToJSON(value['status']),
        'paymentStatus': PaymentStatusDtoToJSON(value['paymentStatus']),
        'createdAt': ((value['createdAt']).toISOString()),
    };
}

