/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EventReferenceDto } from './EventReferenceDto';
import {
    EventReferenceDtoFromJSON,
    EventReferenceDtoFromJSONTyped,
    EventReferenceDtoToJSON,
} from './EventReferenceDto';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';

/**
 * A person merge conflict with its cause in a guest list
 * @export
 * @interface MergePersonGuestListConflictDto
 */
export interface MergePersonGuestListConflictDto {
    /**
     * 
     * @type {string}
     * @memberof MergePersonGuestListConflictDto
     */
    conflictType: MergePersonGuestListConflictDtoConflictTypeEnum;
    /**
     * 
     * @type {EventReferenceDto}
     * @memberof MergePersonGuestListConflictDto
     */
    event?: EventReferenceDto;
    /**
     * 
     * @type {Array<SectionReferenceDto>}
     * @memberof MergePersonGuestListConflictDto
     */
    owners?: Array<SectionReferenceDto>;
}


/**
 * @export
 */
export const MergePersonGuestListConflictDtoConflictTypeEnum = {
    GUEST_LIST: 'GUEST_LIST',
    PARTICIPATION: 'PARTICIPATION',
    GROUP_PERSON: 'GROUP_PERSON'
} as const;
export type MergePersonGuestListConflictDtoConflictTypeEnum = typeof MergePersonGuestListConflictDtoConflictTypeEnum[keyof typeof MergePersonGuestListConflictDtoConflictTypeEnum];


/**
 * Check if a given object implements the MergePersonGuestListConflictDto interface.
 */
export function instanceOfMergePersonGuestListConflictDto(value: object): boolean {
    if (!('conflictType' in value)) return false;
    return true;
}

export function MergePersonGuestListConflictDtoFromJSON(json: any): MergePersonGuestListConflictDto {
    return MergePersonGuestListConflictDtoFromJSONTyped(json, false);
}

export function MergePersonGuestListConflictDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MergePersonGuestListConflictDto {
    if (json == null) {
        return json;
    }
    return {
        
        'conflictType': json['conflictType'],
        'event': json['event'] == null ? undefined : EventReferenceDtoFromJSON(json['event']),
        'owners': json['owners'] == null ? undefined : ((json['owners'] as Array<any>).map(SectionReferenceDtoFromJSON)),
    };
}

export function MergePersonGuestListConflictDtoToJSON(value?: MergePersonGuestListConflictDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'conflictType': value['conflictType'],
        'event': EventReferenceDtoToJSON(value['event']),
        'owners': value['owners'] == null ? undefined : ((value['owners'] as Array<any>).map(SectionReferenceDtoToJSON)),
    };
}

