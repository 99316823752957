/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  IntrospectResponseDto,
  JwkSetDto,
  Oauth2ErrorDto,
  TokenResponseDto,
} from '../models/index';
import {
    IntrospectResponseDtoFromJSON,
    IntrospectResponseDtoToJSON,
    JwkSetDtoFromJSON,
    JwkSetDtoToJSON,
    Oauth2ErrorDtoFromJSON,
    Oauth2ErrorDtoToJSON,
    TokenResponseDtoFromJSON,
    TokenResponseDtoToJSON,
} from '../models/index';

export interface CreateTokenRequest {
    grantType: CreateTokenGrantTypeEnum;
    username?: string;
    password?: string;
    refreshToken?: string;
}

export interface IntrospectTokenRequest {
    token: string;
}

/**
 * 
 */
export class OAuth2Api extends runtime.BaseAPI {

    /**
     * Create a token using basic auth for client credentials.
     */
    async createTokenRaw(requestParameters: CreateTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenResponseDto>> {
        if (requestParameters['grantType'] == null) {
            throw new runtime.RequiredError(
                'grantType',
                'Required parameter "grantType" was null or undefined when calling createToken().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['grantType'] != null) {
            formParams.append('grant_type', requestParameters['grantType'] as any);
        }

        if (requestParameters['username'] != null) {
            formParams.append('username', requestParameters['username'] as any);
        }

        if (requestParameters['password'] != null) {
            formParams.append('password', requestParameters['password'] as any);
        }

        if (requestParameters['refreshToken'] != null) {
            formParams.append('refresh_token', requestParameters['refreshToken'] as any);
        }

        const response = await this.request({
            path: `/oauth2/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseDtoFromJSON(jsonValue));
    }

    /**
     * Create a token using basic auth for client credentials.
     */
    async createToken(requestParameters: CreateTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenResponseDto> {
        const response = await this.createTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Introspect an existing token.
     */
    async introspectTokenRaw(requestParameters: IntrospectTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IntrospectResponseDto>> {
        if (requestParameters['token'] == null) {
            throw new runtime.RequiredError(
                'token',
                'Required parameter "token" was null or undefined when calling introspectToken().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['token'] != null) {
            formParams.append('token', requestParameters['token'] as any);
        }

        const response = await this.request({
            path: `/oauth2/introspect`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntrospectResponseDtoFromJSON(jsonValue));
    }

    /**
     * Introspect an existing token.
     */
    async introspectToken(requestParameters: IntrospectTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IntrospectResponseDto> {
        const response = await this.introspectTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the JWK set.
     */
    async jwkSetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JwkSetDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/oauth2/jwks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JwkSetDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the JWK set.
     */
    async jwkSet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JwkSetDto> {
        const response = await this.jwkSetRaw(initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const CreateTokenGrantTypeEnum = {
    PASSWORD: 'password',
    REFRESH_TOKEN: 'refresh_token'
} as const;
export type CreateTokenGrantTypeEnum = typeof CreateTokenGrantTypeEnum[keyof typeof CreateTokenGrantTypeEnum];
