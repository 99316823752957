import Translate from '../../../util/translate/translate';
import SubMenuItem from '../../app/app-navigation/sub-menu-item';

const anonymisationMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="person" i18nKey="anonymisation.menu_item" />,
    path: '/anonymisation',
    neccessaryPermission: 'PERSON:CAN_ANONYMISE',
  },
];

export default anonymisationMenuItems;
