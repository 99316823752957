import React from 'react';
import { VersionDto } from '../../api';
import useLastModified from './use-last-modified';

interface LastModifiedLabelProps {
  version?: VersionDto;
  color?: string;
}

export default function LastModifiedLabel({ version, color }: LastModifiedLabelProps) {
  const lastModifiedString = useLastModified(version, color);

  return <>{lastModifiedString}</>;
}
