/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { MergePersonGroupPersonConflictDto } from './MergePersonGroupPersonConflictDto';
import {
    instanceOfMergePersonGroupPersonConflictDto,
    MergePersonGroupPersonConflictDtoFromJSON,
    MergePersonGroupPersonConflictDtoFromJSONTyped,
    MergePersonGroupPersonConflictDtoToJSON,
} from './MergePersonGroupPersonConflictDto';
import type { MergePersonGuestListConflictDto } from './MergePersonGuestListConflictDto';
import {
    instanceOfMergePersonGuestListConflictDto,
    MergePersonGuestListConflictDtoFromJSON,
    MergePersonGuestListConflictDtoFromJSONTyped,
    MergePersonGuestListConflictDtoToJSON,
} from './MergePersonGuestListConflictDto';
import type { MergePersonParticipationConflictDto } from './MergePersonParticipationConflictDto';
import {
    instanceOfMergePersonParticipationConflictDto,
    MergePersonParticipationConflictDtoFromJSON,
    MergePersonParticipationConflictDtoFromJSONTyped,
    MergePersonParticipationConflictDtoToJSON,
} from './MergePersonParticipationConflictDto';

/**
 * @type PersonMergeConflictDto
 * 
 * @export
 */
export type PersonMergeConflictDto = { conflictType: 'GROUP_PERSON' } & MergePersonGroupPersonConflictDto | { conflictType: 'GUEST_LIST' } & MergePersonGuestListConflictDto | { conflictType: 'PARTICIPATION' } & MergePersonParticipationConflictDto;

export function PersonMergeConflictDtoFromJSON(json: any): PersonMergeConflictDto {
    return PersonMergeConflictDtoFromJSONTyped(json, false);
}

export function PersonMergeConflictDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonMergeConflictDto {
    if (json == null) {
        return json;
    }
    switch (json['conflictType']) {
        case 'GROUP_PERSON':
            return {...MergePersonGroupPersonConflictDtoFromJSONTyped(json, true), conflictType: 'GROUP_PERSON'};
        case 'GUEST_LIST':
            return {...MergePersonGuestListConflictDtoFromJSONTyped(json, true), conflictType: 'GUEST_LIST'};
        case 'PARTICIPATION':
            return {...MergePersonParticipationConflictDtoFromJSONTyped(json, true), conflictType: 'PARTICIPATION'};
        default:
            throw new Error(`No variant of PersonMergeConflictDto exists with 'conflictType=${json['conflictType']}'`);
    }
}

export function PersonMergeConflictDtoToJSON(value?: PersonMergeConflictDto | null): any {
    if (value == null) {
        return value;
    }
    switch (value['conflictType']) {
        case 'GROUP_PERSON':
            return MergePersonGroupPersonConflictDtoToJSON(value);
        case 'GUEST_LIST':
            return MergePersonGuestListConflictDtoToJSON(value);
        case 'PARTICIPATION':
            return MergePersonParticipationConflictDtoToJSON(value);
        default:
            throw new Error(`No variant of PersonMergeConflictDto exists with 'conflictType=${value['conflictType']}'`);
    }

}

