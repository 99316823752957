import React from 'react';
import useSWRImmutable from 'swr/immutable';
import invariant from 'tiny-invariant';
import { StaffAccountDto } from '../../../api';
import staffAccountApi from '../../../data-access/staff-account-api';
import useDefinedContext from '../../../util/context/use-defined-context/use-defined-context';
import { Permission } from '../permission';

type AccountWithPermissions = Omit<StaffAccountDto, 'permissions'> & { readonly permissions: Permission[] };
export default function useStaffAccount() {
  return useDefinedContext(AccountContext) as AccountWithPermissions;
}

export const AccountContext = React.createContext<StaffAccountDto | undefined>(undefined);

interface AccountProviderProps {
  children: React.ReactNode;
}

export function AccountProvider({ children }: AccountProviderProps) {
  const { data: account } = useSWRImmutable('account', () => staffAccountApi.fetchSelf(), {
    suspense: true,
  });
  invariant(account != null, 'account == null');
  return <AccountContext.Provider value={account}>{children}</AccountContext.Provider>;
}
