/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AccountDto,
  AccountPageDto,
  DefaultEmailAddressDto,
  FailureDto,
  HistoryPageDto,
  PageableDto,
  SyncStatusInnerDto,
} from '../models/index';
import {
    AccountDtoFromJSON,
    AccountDtoToJSON,
    AccountPageDtoFromJSON,
    AccountPageDtoToJSON,
    DefaultEmailAddressDtoFromJSON,
    DefaultEmailAddressDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    SyncStatusInnerDtoFromJSON,
    SyncStatusInnerDtoToJSON,
} from '../models/index';

export interface CreateFromPersonRequest {
    accountDto: AccountDto;
}

export interface DeleteBasicAccountRequest {
    id: string;
}

export interface FetchAccountRequest {
    id: string;
}

export interface FetchAccountHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface GetSyncStatusRequest {
    emails: Array<string>;
}

export interface LockAccountRequest {
    id: string;
}

export interface SearchAccountsRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SendEmailVerificationRequest {
    id: string;
}

export interface SendResetPasswordRequest {
    id: string;
}

export interface SyncAccountRequest {
    id: string;
}

export interface TemplateProfessionalAccountRequest {
    personId: string;
}

export interface UnlockAccountRequest {
    id: string;
}

export interface UpdateAccountEmailRequest {
    id: string;
    defaultEmailAddressDto: DefaultEmailAddressDto;
}

/**
 * 
 */
export class AccountApi extends runtime.BaseAPI {

    /**
     * Create an professional account for a person.
     */
    async createFromPersonRaw(requestParameters: CreateFromPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccountDto>> {
        if (requestParameters['accountDto'] == null) {
            throw new runtime.RequiredError(
                'accountDto',
                'Required parameter "accountDto" was null or undefined when calling createFromPerson().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/accounts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountDtoToJSON(requestParameters['accountDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountDtoFromJSON(jsonValue));
    }

    /**
     * Create an professional account for a person.
     */
    async createFromPerson(requestParameters: CreateFromPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccountDto> {
        const response = await this.createFromPersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the account.
     */
    async deleteBasicAccountRaw(requestParameters: DeleteBasicAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteBasicAccount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/accounts/delete-basic/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the account.
     */
    async deleteBasicAccount(requestParameters: DeleteBasicAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteBasicAccountRaw(requestParameters, initOverrides);
    }

    /**
     * Fetch the account.
     */
    async fetchAccountRaw(requestParameters: FetchAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccountDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchAccount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/accounts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the account.
     */
    async fetchAccount(requestParameters: FetchAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccountDto> {
        const response = await this.fetchAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the history of the account.
     */
    async fetchAccountHistoryRaw(requestParameters: FetchAccountHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchAccountHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/accounts/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the history of the account.
     */
    async fetchAccountHistory(requestParameters: FetchAccountHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchAccountHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Syncs the account with given email and gets the sync status.
     */
    async getSyncStatusRaw(requestParameters: GetSyncStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SyncStatusInnerDto>>> {
        if (requestParameters['emails'] == null) {
            throw new runtime.RequiredError(
                'emails',
                'Required parameter "emails" was null or undefined when calling getSyncStatus().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['emails'] != null) {
            queryParameters['emails'] = requestParameters['emails']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/accounts/syncInfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SyncStatusInnerDtoFromJSON));
    }

    /**
     * Syncs the account with given email and gets the sync status.
     */
    async getSyncStatus(requestParameters: GetSyncStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SyncStatusInnerDto>> {
        const response = await this.getSyncStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Locks the account with given id.
     */
    async lockAccountRaw(requestParameters: LockAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling lockAccount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/accounts/{id}/lock`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Locks the account with given id.
     */
    async lockAccount(requestParameters: LockAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.lockAccountRaw(requestParameters, initOverrides);
    }

    /**
     * Search for accounts.
     */
    async searchAccountsRaw(requestParameters: SearchAccountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccountPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/accounts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for accounts.
     */
    async searchAccounts(requestParameters: SearchAccountsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccountPageDto> {
        const response = await this.searchAccountsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sends an Email verification request to the account with the given id.
     */
    async sendEmailVerificationRaw(requestParameters: SendEmailVerificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling sendEmailVerification().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/accounts/{id}/send-email-verification`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sends an Email verification request to the account with the given id.
     */
    async sendEmailVerification(requestParameters: SendEmailVerificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendEmailVerificationRaw(requestParameters, initOverrides);
    }

    /**
     * Sends a Password reset request to the account with the given id.
     */
    async sendResetPasswordRaw(requestParameters: SendResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling sendResetPassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/accounts/{id}/send-reset-password`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sends a Password reset request to the account with the given id.
     */
    async sendResetPassword(requestParameters: SendResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendResetPasswordRaw(requestParameters, initOverrides);
    }

    /**
     * Syncs the account with given id.
     */
    async syncAccountRaw(requestParameters: SyncAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling syncAccount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/accounts/sync/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Syncs the account with given id.
     */
    async syncAccount(requestParameters: SyncAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.syncAccountRaw(requestParameters, initOverrides);
    }

    /**
     * Fetch the template for creating a new account.
     */
    async templateProfessionalAccountRaw(requestParameters: TemplateProfessionalAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccountDto>> {
        if (requestParameters['personId'] == null) {
            throw new runtime.RequiredError(
                'personId',
                'Required parameter "personId" was null or undefined when calling templateProfessionalAccount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/accounts/template/{personId}`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters['personId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new account.
     */
    async templateProfessionalAccount(requestParameters: TemplateProfessionalAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccountDto> {
        const response = await this.templateProfessionalAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Unlocks the account with given id.
     */
    async unlockAccountRaw(requestParameters: UnlockAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling unlockAccount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/accounts/{id}/unlock`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unlocks the account with given id.
     */
    async unlockAccount(requestParameters: UnlockAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unlockAccountRaw(requestParameters, initOverrides);
    }

    /**
     * Update the existing account email.
     */
    async updateAccountEmailRaw(requestParameters: UpdateAccountEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccountDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateAccountEmail().'
            );
        }

        if (requestParameters['defaultEmailAddressDto'] == null) {
            throw new runtime.RequiredError(
                'defaultEmailAddressDto',
                'Required parameter "defaultEmailAddressDto" was null or undefined when calling updateAccountEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/accounts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DefaultEmailAddressDtoToJSON(requestParameters['defaultEmailAddressDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing account email.
     */
    async updateAccountEmail(requestParameters: UpdateAccountEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccountDto> {
        const response = await this.updateAccountEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
