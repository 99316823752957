import { chakra, Heading, Icon, Text } from '@chakra-ui/react';
import React from 'react';

const ResultIcon = React.lazy(() => import('./result-icon'));

type ResultStatus = 'error';

export interface ResultProps {
  title: React.ReactNode;
  description: React.ReactNode;
  status: ResultStatus;
}

const STATUS_COLORS: { [key in ResultStatus]: string } = {
  error: 'text.error',
};

export default function Result({ title, description, status }: ResultProps) {
  return (
    <chakra.div
      display="flex"
      flexDirection="column"
      justifyContent="center"
      textAlign="center"
      px={8}
      pt={12}
      pb={14}
      height="full"
    >
      <React.Suspense>
        <Icon as={ResultIcon} color={STATUS_COLORS[status]} alignSelf="center" w={10} h={10} mb={4} />
      </React.Suspense>
      <Heading as="h2" color={STATUS_COLORS[status]} mb={4} size="lg">
        {title}
      </Heading>
      <Text>{description}</Text>
    </chakra.div>
  );
}
