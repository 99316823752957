import { Center, ChakraProps, Spinner } from '@chakra-ui/react';
import React from 'react';

export interface PageSpinnerProps extends ChakraProps {}

export default function PageSpinner(props: PageSpinnerProps) {
  return (
    <Center py={12} h="full" {...props} data-test-suspense-fallback>
      <Spinner />
    </Center>
  );
}
