/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PersonTypeDto = {
    STAFF: 'STAFF',
    SERVICE_PROVIDER: 'SERVICE_PROVIDER',
    PROFESSIONAL: 'PROFESSIONAL'
} as const;
export type PersonTypeDto = typeof PersonTypeDto[keyof typeof PersonTypeDto];


export function PersonTypeDtoFromJSON(json: any): PersonTypeDto {
    return PersonTypeDtoFromJSONTyped(json, false);
}

export function PersonTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonTypeDto {
    return json as PersonTypeDto;
}

export function PersonTypeDtoToJSON(value?: PersonTypeDto | null): any {
    return value as any;
}

