import React from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchProduct } from '../product-queries';

export default function ProductBreadcrumb() {
  const { productId } = useParams<{ productId: string }>();
  invariant(productId, 'Empty productId');
  const product = useFetcher(fetchProduct, { id: productId });

  return <>{product.title}</>;
}
