/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AllocationReferenceDto } from './AllocationReferenceDto';
import {
    AllocationReferenceDtoFromJSON,
    AllocationReferenceDtoFromJSONTyped,
    AllocationReferenceDtoToJSON,
} from './AllocationReferenceDto';
import type { StatusStatisticsDto } from './StatusStatisticsDto';
import {
    StatusStatisticsDtoFromJSON,
    StatusStatisticsDtoFromJSONTyped,
    StatusStatisticsDtoToJSON,
} from './StatusStatisticsDto';

/**
 * 
 * @export
 * @interface StatusGroupStatisticsDto
 */
export interface StatusGroupStatisticsDto {
    /**
     * 
     * @type {AllocationReferenceDto}
     * @memberof StatusGroupStatisticsDto
     */
    allocation?: AllocationReferenceDto;
    /**
     * 
     * @type {StatusStatisticsDto}
     * @memberof StatusGroupStatisticsDto
     */
    review?: StatusStatisticsDto;
    /**
     * 
     * @type {StatusStatisticsDto}
     * @memberof StatusGroupStatisticsDto
     */
    invitation?: StatusStatisticsDto;
    /**
     * 
     * @type {StatusStatisticsDto}
     * @memberof StatusGroupStatisticsDto
     */
    response?: StatusStatisticsDto;
    /**
     * 
     * @type {StatusStatisticsDto}
     * @memberof StatusGroupStatisticsDto
     */
    attendance?: StatusStatisticsDto;
    /**
     * 
     * @type {StatusStatisticsDto}
     * @memberof StatusGroupStatisticsDto
     */
    cancellation?: StatusStatisticsDto;
}

/**
 * Check if a given object implements the StatusGroupStatisticsDto interface.
 */
export function instanceOfStatusGroupStatisticsDto(value: object): boolean {
    return true;
}

export function StatusGroupStatisticsDtoFromJSON(json: any): StatusGroupStatisticsDto {
    return StatusGroupStatisticsDtoFromJSONTyped(json, false);
}

export function StatusGroupStatisticsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusGroupStatisticsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'allocation': json['allocation'] == null ? undefined : AllocationReferenceDtoFromJSON(json['allocation']),
        'review': json['review'] == null ? undefined : StatusStatisticsDtoFromJSON(json['review']),
        'invitation': json['invitation'] == null ? undefined : StatusStatisticsDtoFromJSON(json['invitation']),
        'response': json['response'] == null ? undefined : StatusStatisticsDtoFromJSON(json['response']),
        'attendance': json['attendance'] == null ? undefined : StatusStatisticsDtoFromJSON(json['attendance']),
        'cancellation': json['cancellation'] == null ? undefined : StatusStatisticsDtoFromJSON(json['cancellation']),
    };
}

export function StatusGroupStatisticsDtoToJSON(value?: StatusGroupStatisticsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'allocation': AllocationReferenceDtoToJSON(value['allocation']),
        'review': StatusStatisticsDtoToJSON(value['review']),
        'invitation': StatusStatisticsDtoToJSON(value['invitation']),
        'response': StatusStatisticsDtoToJSON(value['response']),
        'attendance': StatusStatisticsDtoToJSON(value['attendance']),
        'cancellation': StatusStatisticsDtoToJSON(value['cancellation']),
    };
}

