/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ConfirmationBodyOccupationsDto } from './ConfirmationBodyOccupationsDto';
import {
    ConfirmationBodyOccupationsDtoFromJSON,
    ConfirmationBodyOccupationsDtoFromJSONTyped,
    ConfirmationBodyOccupationsDtoToJSON,
} from './ConfirmationBodyOccupationsDto';

/**
 * 
 * @export
 * @interface ConfirmationBodyDto
 */
export interface ConfirmationBodyDto {
    /**
     * whether to confirm the company or delete it. Must be undefined if company is already active and only occupations are confirmed
     * @type {boolean}
     * @memberof ConfirmationBodyDto
     */
    confirmCompany?: boolean;
    /**
     * 
     * @type {ConfirmationBodyOccupationsDto}
     * @memberof ConfirmationBodyDto
     */
    occupations?: ConfirmationBodyOccupationsDto;
}

/**
 * Check if a given object implements the ConfirmationBodyDto interface.
 */
export function instanceOfConfirmationBodyDto(value: object): boolean {
    return true;
}

export function ConfirmationBodyDtoFromJSON(json: any): ConfirmationBodyDto {
    return ConfirmationBodyDtoFromJSONTyped(json, false);
}

export function ConfirmationBodyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfirmationBodyDto {
    if (json == null) {
        return json;
    }
    return {
        
        'confirmCompany': json['confirmCompany'] == null ? undefined : json['confirmCompany'],
        'occupations': json['occupations'] == null ? undefined : ConfirmationBodyOccupationsDtoFromJSON(json['occupations']),
    };
}

export function ConfirmationBodyDtoToJSON(value?: ConfirmationBodyDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'confirmCompany': value['confirmCompany'],
        'occupations': ConfirmationBodyOccupationsDtoToJSON(value['occupations']),
    };
}

