/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AccountingPaymentStatusDto = {
    PAID: 'PAID',
    PAID_BY_CODE: 'PAID_BY_CODE',
    OPEN: 'OPEN',
    REFUND: 'REFUND',
    COMPLIMENTARY: 'COMPLIMENTARY'
} as const;
export type AccountingPaymentStatusDto = typeof AccountingPaymentStatusDto[keyof typeof AccountingPaymentStatusDto];


export function AccountingPaymentStatusDtoFromJSON(json: any): AccountingPaymentStatusDto {
    return AccountingPaymentStatusDtoFromJSONTyped(json, false);
}

export function AccountingPaymentStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountingPaymentStatusDto {
    return json as AccountingPaymentStatusDto;
}

export function AccountingPaymentStatusDtoToJSON(value?: AccountingPaymentStatusDto | null): any {
    return value as any;
}

