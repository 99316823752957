/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PermissionDto } from './PermissionDto';
import {
    PermissionDtoFromJSON,
    PermissionDtoFromJSONTyped,
    PermissionDtoToJSON,
} from './PermissionDto';

/**
 * 
 * @export
 * @interface SelfBySectionDto
 */
export interface SelfBySectionDto {
    /**
     * 
     * @type {string}
     * @memberof SelfBySectionDto
     */
    sectionId?: string;
    /**
     * 
     * @type {Array<PermissionDto>}
     * @memberof SelfBySectionDto
     */
    permission?: Array<PermissionDto>;
}

/**
 * Check if a given object implements the SelfBySectionDto interface.
 */
export function instanceOfSelfBySectionDto(value: object): boolean {
    return true;
}

export function SelfBySectionDtoFromJSON(json: any): SelfBySectionDto {
    return SelfBySectionDtoFromJSONTyped(json, false);
}

export function SelfBySectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelfBySectionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'sectionId': json['sectionId'] == null ? undefined : json['sectionId'],
        'permission': json['permission'] == null ? undefined : ((json['permission'] as Array<any>).map(PermissionDtoFromJSON)),
    };
}

export function SelfBySectionDtoToJSON(value?: SelfBySectionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sectionId': value['sectionId'],
        'permission': value['permission'] == null ? undefined : ((value['permission'] as Array<any>).map(PermissionDtoToJSON)),
    };
}

