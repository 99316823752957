/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductPermissionDto
 */
export interface ProductPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof ProductPermissionDto
     */
    name: ProductPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductPermissionDto
     */
    action: ProductPermissionDtoActionEnum;
}


/**
 * @export
 */
export const ProductPermissionDtoNameEnum = {
    PRODUCT: 'PRODUCT'
} as const;
export type ProductPermissionDtoNameEnum = typeof ProductPermissionDtoNameEnum[keyof typeof ProductPermissionDtoNameEnum];

/**
 * @export
 */
export const ProductPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS_OWN: 'CAN_SEE_DETAILS_OWN',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    EDIT_OWN: 'CAN_EDIT_OWN',
    EDIT: 'CAN_EDIT',
    CREATE: 'CAN_CREATE',
    COPY_OWN: 'CAN_COPY_OWN',
    COPY: 'CAN_COPY',
    ARCHIVE_OWN: 'CAN_ARCHIVE_OWN',
    ARCHIVE: 'CAN_ARCHIVE',
    ACTIVATE_OWN: 'CAN_ACTIVATE_OWN',
    ACTIVATE: 'CAN_ACTIVATE'
} as const;
export type ProductPermissionDtoActionEnum = typeof ProductPermissionDtoActionEnum[keyof typeof ProductPermissionDtoActionEnum];


/**
 * Check if a given object implements the ProductPermissionDto interface.
 */
export function instanceOfProductPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function ProductPermissionDtoFromJSON(json: any): ProductPermissionDto {
    return ProductPermissionDtoFromJSONTyped(json, false);
}

export function ProductPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function ProductPermissionDtoToJSON(value?: ProductPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

