import { Box, Button, Flex, HStack, Spacer, Stack, Text, Tooltip } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { RefObject } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  CompanyReferenceDto,
  FormatCompanyRelationDto,
  FormatCompanyStatusDto,
  FormatReferenceDto,
} from '../../../../api';
import companyApi from '../../../../data-access/company-api';
import formatCompanyApi from '../../../../data-access/format-company-api';
import DateInputFormControl from '../../../../ui/form/date-input-control/date-input-form-control';
import Label from '../../../../ui/form/label';
import ValueAsyncSelectFormControl from '../../../../ui/form/select-control/value-async-select-form-control';
import ValueSelectFormControl from '../../../../ui/form/select-control/value-select-form-control';
import { companyIcon, formatIcon } from '../../../../ui/icons/business-objects';
import now from '../../../../util/now';
import useActiveEdition from '../../../edition/use-active-edition/use-active-edition';
import ConnectionLine from '../../common/connection-line';
import { CONNECTION_TYPE_OPTIONS, STATUS_OPTIONS } from '../format-company-enum-constants';

interface ConnectionControlProps {
  formatReference: FormatReferenceDto;
  isEdit?: boolean;
  initialFocusRef?: RefObject<HTMLInputElement>;
}

export default function ConnectionControl({
  formatReference,
  isEdit = false,
  initialFocusRef,
}: ConnectionControlProps) {
  const { t } = useTranslation(['format', 'company', 'common']);

  const validateCompanyIsUnique = async (company: CompanyReferenceDto) => {
    if (company != null) {
      const isUnique = await formatCompanyApi.isFormatCompanyUnique({
        formatId: formatReference.id,
        companyId: company.id,
      });
      if (!isUnique.value) {
        return t('company:formatConnections.notUnique');
      }
    }
    return true;
  };

  return (
    <>
      <Stack spacing={2} backgroundColor="background.highlight" pr={2} py={2} pl={3} borderRadius="base">
        <Flex position="relative" align="center">
          <Box as={FontAwesomeIcon} icon={formatIcon} fixedWidth flexShrink={0} />
          <Box ml={3} aria-label={t('format:companyConnections.fromFormat')}>
            <Text as="span" fontWeight="medium">
              {formatReference.name}
            </Text>
          </Box>
        </Flex>
        <Flex align="center" position="relative">
          <ConnectionLine position="top" showArrow spacing={2} />
          <Box as={FontAwesomeIcon} icon={companyIcon} fixedWidth flexShrink={0} />
          <Box width="full" ml={3}>
            <ValueAsyncSelectFormControl
              isRequired
              name="company"
              ref={initialFocusRef}
              label={t('company:company')}
              loadOptions={async (value) => {
                const companies = await companyApi.searchActiveCompanyReferences({
                  q: value,
                  pageable: { sort: ['name,ASC'] },
                });
                return companies.content;
              }}
              renderLabel={(company) => company.name}
              aria-label={t('format:connections.toCompany')}
              optionIdentifier={(option) => option.id!}
              rules={{ validate: validateCompanyIsUnique }}
            />
          </Box>
        </Flex>
      </Stack>

      <Stack
        spacing={4}
        sx={{
          marginTop: 4,
          borderWidth: 1,
          borderRadius: 4,
          padding: 4,
          borderColor: 'border.01',
        }}
      >
        <FormatCompanyValidityControl />
        <ValueSelectFormControl
          name="connectionType"
          label={t('format:formatCompany.connectionType')}
          isRequired
          options={CONNECTION_TYPE_OPTIONS}
          renderLabel={(value) => t(`format:formatCompany.connectionTypeOptions.${value}`)}
        />
        <ValueSelectFormControl
          name="status"
          isRequired
          label={t('format:formatCompany.status')}
          options={STATUS_OPTIONS}
          renderLabel={(value) => t(`format:formatCompany.statusOptions.${value}`)}
          defaultValue={FormatCompanyStatusDto.ACTIVE}
          //TODO BX4179 terminate format company relation
          isDisabled={!isEdit}
        />
      </Stack>
    </>
  );
}

export function FormatCompanyValidityControl() {
  const { t } = useTranslation(['format', 'common']);
  const { watch, setValue } = useFormContext<FormatCompanyRelationDto>();
  const activeEdition = useActiveEdition();
  const start = watch('validityPeriod.start');

  const prefillToday = () => {
    setValue('validityPeriod.start', new Date(now()), {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue('validityPeriod.end', undefined, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const prefillActiveEdition = () => {
    setValue('validityPeriod.start', activeEdition.dateRange.start, {
      shouldDirty: true,
    });
    setValue('validityPeriod.end', activeEdition.dateRange.end, {
      shouldDirty: true,
    });
  };

  return (
    <Stack>
      <Label>{t('format:formatCompany.validityPeriod')}</Label>
      <HStack spacing={2}>
        <span>{t('common:prefill')}: </span> <Spacer />
        <Tooltip label={t('format:connections.asOfTodayTooltip')}>
          <Button size="sm" onClick={prefillToday}>
            {t('format:connections.asOfToday')}
          </Button>
        </Tooltip>
        <Tooltip label={t('format:connections.activeEditionTooltip')}>
          <Button size="sm" onClick={prefillActiveEdition}>
            {t('format:connections.activeEdition')}
          </Button>
        </Tooltip>
      </HStack>
      <HStack alignItems="start">
        <DateInputFormControl<FormatCompanyRelationDto>
          name="validityPeriod.start"
          isRequired
          deps={['validityPeriod.end']}
          label={t('format:formatCompany.start')}
        />
        <DateInputFormControl<FormatCompanyRelationDto>
          name="validityPeriod.end"
          deps={['validityPeriod.start']}
          label={t('format:formatCompany.end')}
          referenceDate={start}
          min={{
            value: start,
            message: t('common:validation_error.date_end_before_date_start'),
          }}
        />
      </HStack>
    </Stack>
  );
}
