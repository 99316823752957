import { produce } from 'immer';
import { union, without } from 'lodash-es';
import invariant from 'tiny-invariant';

export default interface DataTableState {
  page: number;
  size: number;
  sort: DataTableSort[];
  filter: DataTableFilter[];
  selection: string[];
}

export interface DataTableSort {
  property: string;
  direction: 'ASC' | 'DESC';
}

export interface DataTableFilter {
  property: string;
  operator?: string;
  value?: string;
  label?: string;
}

interface PrevPageAction {
  type: 'PREV_PAGE';
}

interface NextPageAction {
  type: 'NEXT_PAGE';
}

interface SortAction {
  type: 'SORT';
  payload: string;
}

export interface SetFilterOptions {
  // default is false
  replaceOnlyIfOperatorMatches?: boolean;
}

interface SetFilterAction {
  type: 'SET_FILTER';
  payload: { filter: DataTableFilter; options: SetFilterOptions };
}

interface ReplaceFilterAction {
  type: 'REPLACE_FILTER';
  payload: { oldFilterProperty: string; newFilter: DataTableFilter };
}

interface RemoveFiltersAction {
  type: 'REMOVE_FILTERS';
  payload: string[];
}

interface RemoveFilterAction {
  type: 'REMOVE_FILTER';
  payload: { property: string; operator: string };
}

interface AddSelectionAction {
  type: 'ADD_SELECTION';
  payload: string[];
}

interface RemoveSelectionAction {
  type: 'REMOVE_SELECTION';
  payload: string[];
}

interface SetSelectionAction {
  type: 'SET_SELECTION';
  payload: string[];
}

interface BroomAction {
  type: 'BROOM';
}

export type DataTableAction =
  | PrevPageAction
  | NextPageAction
  | SortAction
  | SetFilterAction
  | ReplaceFilterAction
  | RemoveFiltersAction
  | RemoveFilterAction
  | AddSelectionAction
  | RemoveSelectionAction
  | SetSelectionAction
  | BroomAction;

export function dataTableStateReducer(
  state: Partial<DataTableState>,
  action: DataTableAction,
): Partial<DataTableState> {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'PREV_PAGE':
        invariant(draft.page != null, 'Missing page');
        draft.page -= 1;

        break;
      case 'NEXT_PAGE':
        invariant(draft.page != null, 'Missing page');
        draft.page += 1;

        break;
      case 'SORT':
        if (draft.sort == null) {
          draft.sort = [];
        }

        // eslint-disable-next-line no-case-declarations
        const sortIndex = draft.sort.findIndex((sort) => sort.property === action.payload);

        draft.page = 0;

        // jaj, lh: New sorting
        if (sortIndex < 0) {
          draft.sort.push({ property: action.payload, direction: 'DESC' });
        }
        // jaj, lh: Sorting already exists.
        else {
          const sort = draft.sort[sortIndex];

          // jaj, lh: Switch sorting to ascending.
          if (sort.direction === 'DESC') {
            draft.sort[sortIndex].direction = 'ASC';
          }
          // jaj, lh: Remove sorting again.
          else if (sort.direction === 'ASC') {
            draft.sort.splice(sortIndex, 1);
          }
        }

        break;
      case 'SET_FILTER':
        if (draft.filter == null) {
          draft.filter = [];
        }

        // eslint-disable-next-line no-case-declarations
        const filterIndex = draft.filter.findIndex(
          (filter) =>
            filter.property === action.payload.filter.property &&
            (action.payload.options.replaceOnlyIfOperatorMatches === true
              ? filter.operator === action.payload.filter.operator
              : true),
        );

        draft.page = 0;

        // jaj, lh: Add filter
        if (filterIndex < 0) {
          draft.filter.push(action.payload.filter);
        }
        // jaj, lh: Replace filter
        else {
          draft.filter.splice(filterIndex, 1, action.payload.filter);
        }

        break;
      case 'REPLACE_FILTER':
        if (draft.filter == null) {
          draft.filter = [];
        }

        // eslint-disable-next-line no-case-declarations
        const oldFilterIndex = draft.filter.findIndex((filter) => filter.property === action.payload.oldFilterProperty);
        // eslint-disable-next-line no-case-declarations
        const newFilterIndex = draft.filter.findIndex(
          (filter) => filter.property === action.payload.newFilter.property,
        );

        draft.page = 0;

        // neither the old nor the new filter exists
        if (oldFilterIndex < 0 && newFilterIndex < 0) {
          draft.filter.push(action.payload.newFilter);
        }
        // new filter is already there, replace it
        else if (oldFilterIndex < 0 && newFilterIndex >= 0) {
          draft.filter.splice(newFilterIndex, 1, action.payload.newFilter);
        }
        // old filter is already there, replace it
        else if (oldFilterIndex >= 0 && newFilterIndex < 0) {
          draft.filter.splice(oldFilterIndex, 1, action.payload.newFilter);
        }
        // new and old filter are already there, remove one, replace the other
        else {
          const filter = draft.filter;
          filter.splice(newFilterIndex, 1, action.payload.newFilter);
          filter.splice(oldFilterIndex, 1);
          draft.filter = filter;
        }

        break;

      case 'REMOVE_FILTERS':
        if (draft.filter == null) {
          draft.filter = [];
        }

        draft.filter = draft.filter.filter((filter) => action.payload.indexOf(filter.property) === -1);
        draft.page = 0;

        break;

      case 'REMOVE_FILTER':
        if (draft.filter == null) {
          draft.filter = [];
        }

        draft.filter = draft.filter.filter(
          (filter) =>
            action.payload.property.indexOf(filter.property) === -1 || action.payload.operator !== filter.operator,
        );
        draft.page = 0;

        break;

      case 'ADD_SELECTION':
        if (draft.selection == null) {
          draft.selection = [];
        }
        draft.selection = union(draft.selection, action.payload);

        break;

      case 'REMOVE_SELECTION':
        if (draft.selection == null) {
          draft.selection = [];
        }
        draft.selection = without(draft.selection, ...action.payload);
        break;

      case 'SET_SELECTION':
        draft.selection = action.payload;

        break;

      case 'BROOM':
        // remove filter and sorting in all columns to initial state and jump back to first page
        draft.sort = [];
        draft.filter = [];
        draft.page = 0;

        break;
    }
  });
}
