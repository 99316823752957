import { Box, Button, forwardRef, VisuallyHidden } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface AppNavigationButtonProps {
  icon?: IconProp;
  isCollapsed?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const AppNavigationButton = forwardRef<AppNavigationButtonProps, 'button'>(
  ({ icon, isCollapsed, children, ...props }, ref) => {
    return (
      <Button
        variant="unstyled"
        sx={{
          display: 'flex',
          borderRadius: 'none',
          alignItems: 'center',
          justifyContent: 'start',
          pr: 6,
          py: 2,
          pl: icon == null ? 14 : 6,
          fontWeight: 'normal',
          width: 'full',
          whiteSpace: 'normal',
          '&.active': {
            color: 'teal.400',
            fontWeight: 'semibold',
            bg: 'whiteAlpha.50',
          },
        }}
        ref={ref}
        {...props}
      >
        {icon != null && (
          <Box
            as={FontAwesomeIcon}
            icon={icon}
            fixedWidth
            flexShrink={0}
            mr={isCollapsed ? undefined : 3}
            color="teal.400"
          />
        )}
        {isCollapsed ? <VisuallyHidden>{children}</VisuallyHidden> : children}
      </Button>
    );
  },
);

export default AppNavigationButton;
