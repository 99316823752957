/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { MailingReferenceDto } from './MailingReferenceDto';
import {
    MailingReferenceDtoFromJSON,
    MailingReferenceDtoFromJSONTyped,
    MailingReferenceDtoToJSON,
} from './MailingReferenceDto';
import type { OwnerDto } from './OwnerDto';
import {
    OwnerDtoFromJSON,
    OwnerDtoFromJSONTyped,
    OwnerDtoToJSON,
} from './OwnerDto';
import type { ReportReferenceDto } from './ReportReferenceDto';
import {
    ReportReferenceDtoFromJSON,
    ReportReferenceDtoFromJSONTyped,
    ReportReferenceDtoToJSON,
} from './ReportReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface ListDto
 */
export interface ListDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof ListDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof ListDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof ListDto
     */
    name: string;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof ListDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {Array<OwnerDto>}
     * @memberof ListDto
     */
    owners: Array<OwnerDto>;
    /**
     * 
     * @type {ReportReferenceDto}
     * @memberof ListDto
     */
    report?: ReportReferenceDto;
    /**
     * 
     * @type {Array<MailingReferenceDto>}
     * @memberof ListDto
     */
    mailings?: Array<MailingReferenceDto>;
}

/**
 * Check if a given object implements the ListDto interface.
 */
export function instanceOfListDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('owners' in value)) return false;
    return true;
}

export function ListDtoFromJSON(json: any): ListDto {
    return ListDtoFromJSONTyped(json, false);
}

export function ListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'name': json['name'],
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'owners': ((json['owners'] as Array<any>).map(OwnerDtoFromJSON)),
        'report': json['report'] == null ? undefined : ReportReferenceDtoFromJSON(json['report']),
        'mailings': json['mailings'] == null ? undefined : ((json['mailings'] as Array<any>).map(MailingReferenceDtoFromJSON)),
    };
}

export function ListDtoToJSON(value?: ListDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'name': value['name'],
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'owners': ((value['owners'] as Array<any>).map(OwnerDtoToJSON)),
        'report': ReportReferenceDtoToJSON(value['report']),
        'mailings': value['mailings'] == null ? undefined : ((value['mailings'] as Array<any>).map(MailingReferenceDtoToJSON)),
    };
}

