/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GroupStaffBelongsToSectionsInnerDto } from './GroupStaffBelongsToSectionsInnerDto';
import {
    GroupStaffBelongsToSectionsInnerDtoFromJSON,
    GroupStaffBelongsToSectionsInnerDtoFromJSONTyped,
    GroupStaffBelongsToSectionsInnerDtoToJSON,
} from './GroupStaffBelongsToSectionsInnerDto';
import type { StaffEmailAddressDto } from './StaffEmailAddressDto';
import {
    StaffEmailAddressDtoFromJSON,
    StaffEmailAddressDtoFromJSONTyped,
    StaffEmailAddressDtoToJSON,
} from './StaffEmailAddressDto';
import type { StaffPhoneNumberDto } from './StaffPhoneNumberDto';
import {
    StaffPhoneNumberDtoFromJSON,
    StaffPhoneNumberDtoFromJSONTyped,
    StaffPhoneNumberDtoToJSON,
} from './StaffPhoneNumberDto';

/**
 * A representation of a staff that is connected to the group.
 * @export
 * @interface GroupStaffDto
 */
export interface GroupStaffDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof GroupStaffDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupStaffDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof GroupStaffDto
     */
    surname: string;
    /**
     * 
     * @type {Array<GroupStaffBelongsToSectionsInnerDto>}
     * @memberof GroupStaffDto
     */
    belongsToSections: Array<GroupStaffBelongsToSectionsInnerDto>;
    /**
     * 
     * @type {StaffPhoneNumberDto}
     * @memberof GroupStaffDto
     */
    phoneNumber?: StaffPhoneNumberDto;
    /**
     * 
     * @type {StaffEmailAddressDto}
     * @memberof GroupStaffDto
     */
    emailAddress: StaffEmailAddressDto;
}

/**
 * Check if a given object implements the GroupStaffDto interface.
 */
export function instanceOfGroupStaffDto(value: object): boolean {
    if (!('firstName' in value)) return false;
    if (!('surname' in value)) return false;
    if (!('belongsToSections' in value)) return false;
    if (!('emailAddress' in value)) return false;
    return true;
}

export function GroupStaffDtoFromJSON(json: any): GroupStaffDto {
    return GroupStaffDtoFromJSONTyped(json, false);
}

export function GroupStaffDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupStaffDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'firstName': json['firstName'],
        'surname': json['surname'],
        'belongsToSections': ((json['belongsToSections'] as Array<any>).map(GroupStaffBelongsToSectionsInnerDtoFromJSON)),
        'phoneNumber': json['phoneNumber'] == null ? undefined : StaffPhoneNumberDtoFromJSON(json['phoneNumber']),
        'emailAddress': StaffEmailAddressDtoFromJSON(json['emailAddress']),
    };
}

export function GroupStaffDtoToJSON(value?: GroupStaffDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'firstName': value['firstName'],
        'surname': value['surname'],
        'belongsToSections': ((value['belongsToSections'] as Array<any>).map(GroupStaffBelongsToSectionsInnerDtoToJSON)),
        'phoneNumber': StaffPhoneNumberDtoToJSON(value['phoneNumber']),
        'emailAddress': StaffEmailAddressDtoToJSON(value['emailAddress']),
    };
}

