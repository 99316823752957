import { AsyncProps, GroupBase } from 'chakra-react-select';
import React from 'react';
import { ReactSelectVariant } from '../../theme/component/react-select';
import { DEBOUNCE_TIME } from '../../util/constants';
import AsyncSelect from './async-select';
import useAsyncNoOptionsMessage from './use-async-no-options-message';
import useDebouncedLoadOptions from './use-debounced-load-options';
import ValueSelectOption from './value-select-option';

export interface MultiValueAsyncSelectProps<
  TOption,
  TGroup extends GroupBase<ValueSelectOption<TOption>> = GroupBase<{
    value: TOption;
    label: React.ReactNode;
  }>,
> extends Omit<AsyncProps<ValueSelectOption<TOption>, true, TGroup>, 'onChange' | 'value' | 'isMulti' | 'loadOptions'> {
  renderLabel: (value: TOption) => React.ReactNode;
  optionIdentifier: (value: TOption) => string;
  onChange: (value: TOption[]) => void;
  values: TOption[];
  pageSizeLimit?: number;
  debounceTime?: number;
  loadOptions: (searchQuery: string, pageSizeLimit: number) => Promise<TOption[]>;
  variant?: ReactSelectVariant;
}

function MultiValueAsyncSelect<T>(
  {
    renderLabel,
    optionIdentifier,
    defaultOptions,
    values,
    onChange,
    loadOptions,
    pageSizeLimit = 50,
    debounceTime = DEBOUNCE_TIME,
    ...props
  }: MultiValueAsyncSelectProps<T>,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const selectedOption = values?.map((value) => ({ value: value, label: renderLabel(value) }));
  const debouncedLoadOptions = useDebouncedLoadOptions(loadOptions, pageSizeLimit, debounceTime);
  const noOptionsMessage = useAsyncNoOptionsMessage(defaultOptions !== false);

  const mappedLoadOptions = (value: string) =>
    debouncedLoadOptions(value).then((options) =>
      options.map((option) => ({
        value: option,
        label: renderLabel(option),
      })),
    );

  return (
    <AsyncSelect<ValueSelectOption<T>, true>
      {...props}
      isMulti={true}
      value={selectedOption}
      loadOptions={mappedLoadOptions}
      getOptionValue={({ value }) => optionIdentifier(value)}
      defaultOptions={defaultOptions}
      onChange={(selectedOptions) => {
        onChange(selectedOptions.map((option) => option.value));
      }}
      noOptionsMessage={noOptionsMessage}
      ref={ref}
    />
  );
}

export default React.forwardRef(MultiValueAsyncSelect);
