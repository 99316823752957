import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useOrderPermissionClassGroupExtension(): PermissionClassGroupExtension<'ORDER'> {
  const { t } = useTranslation(['order']);

  return {
    name: 'ORDER',
    label: t('order:order'),
    getActionLabel: (action) => t(`order:permission.${action}`),
    getAdditionalRequirement: (action) => t(`order:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`order:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
