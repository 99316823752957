import { createDescendantContext, DescendantsManager } from '@chakra-ui/descendant';
import React, { useMemo, useState } from 'react';
import createDefinedContext from '../../util/context/create-defined-context/create-defined-context';
import useCallbackRef from '../../util/use-callback-ref/use-callback-ref';

export const [SearchFiltersDescendantsProvider, , useSearchFiltersDescendants, useSearchFiltersDescendant] =
  createDescendantContext<HTMLElement>();

type SearchFiltersContext = ReturnType<typeof useSearchFiltersState>;

export const [SearchFiltersContextProvider, useSearchFiltersContext] =
  createDefinedContext<SearchFiltersContext>('SearchFiltersContext');

export default function useSearchFiltersState(descendantManager: DescendantsManager<HTMLElement>) {
  const [focusedFilterButtonIndex, setFocusedFilterButtonIndex] = useState<number | null>(null);

  const onFilterButtonFocus = useCallbackRef((event: React.FocusEvent<HTMLElement>) => {
    const index = descendantManager.indexOf(event.currentTarget);
    setFocusedFilterButtonIndex(index);
  });

  const onFilterButtonBlur = useCallbackRef(() => {
    setFocusedFilterButtonIndex(null);
  });

  const focusNextFilterButton = useCallbackRef(() => {
    const item =
      focusedFilterButtonIndex != null ? descendantManager.next(focusedFilterButtonIndex) : descendantManager.first();
    item?.node.focus();

    setFocusedFilterButtonIndex(item?.index ?? null);
  });

  const focusPrevFilterButton = useCallbackRef(() => {
    const item =
      focusedFilterButtonIndex != null ? descendantManager.prev(focusedFilterButtonIndex) : descendantManager.last();
    item?.node.focus();

    setFocusedFilterButtonIndex(item?.index ?? null);
  });

  return useMemo(
    () => ({
      onFilterButtonFocus,
      onFilterButtonBlur,
      focusedFilterButtonIndex,
      focusNextFilterButton,
      focusPrevFilterButton,
    }),
    [focusNextFilterButton, focusPrevFilterButton, focusedFilterButtonIndex, onFilterButtonBlur, onFilterButtonFocus],
  );
}
