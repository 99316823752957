/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormatEmailAddressDto
 */
export interface FormatEmailAddressDto {
    /**
     * An email address
     * @type {string}
     * @memberof FormatEmailAddressDto
     */
    email: string;
    /**
     * Can the information be published?
     * @type {boolean}
     * @memberof FormatEmailAddressDto
     */
    publish?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof FormatEmailAddressDto
     */
    timestamp?: Date;
    /**
     * The label for the email address
     * @type {string}
     * @memberof FormatEmailAddressDto
     */
    label: FormatEmailAddressDtoLabelEnum;
    /**
     * The comment for the email address
     * @type {string}
     * @memberof FormatEmailAddressDto
     */
    comment?: string;
}


/**
 * @export
 */
export const FormatEmailAddressDtoLabelEnum = {
    CONTACT: 'CONTACT',
    OTHER: 'OTHER'
} as const;
export type FormatEmailAddressDtoLabelEnum = typeof FormatEmailAddressDtoLabelEnum[keyof typeof FormatEmailAddressDtoLabelEnum];


/**
 * Check if a given object implements the FormatEmailAddressDto interface.
 */
export function instanceOfFormatEmailAddressDto(value: object): boolean {
    if (!('email' in value)) return false;
    if (!('label' in value)) return false;
    return true;
}

export function FormatEmailAddressDtoFromJSON(json: any): FormatEmailAddressDto {
    return FormatEmailAddressDtoFromJSONTyped(json, false);
}

export function FormatEmailAddressDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormatEmailAddressDto {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'publish': json['publish'] == null ? undefined : json['publish'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
        'label': json['label'],
        'comment': json['comment'] == null ? undefined : json['comment'],
    };
}

export function FormatEmailAddressDtoToJSON(value?: FormatEmailAddressDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'publish': value['publish'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
        'label': value['label'],
        'comment': value['comment'],
    };
}

