import { Menu, MenuItem, MenuList, Portal, ResponsiveValue, Tooltip } from '@chakra-ui/react';
import { faToggleOff, faToggleOn } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProductDto, ProductListItemDto, ProductStatusDto } from '../../../api';
import productApi from '../../../data-access/product-api';
import { BurgerMenuButton } from '../../../ui/burger-menu-button/burger-menu-button';
import usePermission from '../../permission/use-permission';
import { productFetcher } from '../product-queries';
import ProductStatusToggleButton from '../product-status-toggle/product-status-toggle-button';

interface ProductMenuProps {
  product: ProductDto | ProductListItemDto;
  belongsToSections: string[];
  size?: ResponsiveValue<'sm' | 'md'>;
}

export function ProductMenuList({ product, belongsToSections, size }: ProductMenuProps) {
  const { t } = useTranslation(['product']);
  const redColorIfNotArchived = product.status === ProductStatusDto.ARCHIVED ? 'text' : 'text.error';
  const [canBeArchived, setCanBeArchived] = React.useState<boolean>(false);
  const { hasPermission } = usePermission();

  async function checkCanBeArchived() {
    setCanBeArchived(false);
    setCanBeArchived(await productApi.canBeArchived({ id: product.id }));
  }

  return (
    (hasPermission('PRODUCT:CAN_ACTIVATE') ||
      hasPermission('PRODUCT:CAN_ACTIVATE_OWN') ||
      hasPermission('PRODUCT:CAN_ARCHIVE') ||
      hasPermission('PRODUCT:CAN_ARCHIVE_OWN')) && (
      <Menu onOpen={checkCanBeArchived}>
        <BurgerMenuButton size={size} />
        <Portal>
          <MenuList>
            <Tooltip label={product.status === ProductStatusDto.ARCHIVED ? t('product:action.reactivateTooltip') : ''}>
              <MenuItem
                as={ProductStatusToggleButton}
                product={product}
                canBeArchived={canBeArchived}
                onSuccess={() => productFetcher.mutate()}
                belongsToSections={belongsToSections}
                textColor={redColorIfNotArchived}
                icon={
                  <FontAwesomeIcon icon={product.status === ProductStatusDto.ARCHIVED ? faToggleOn : faToggleOff} />
                }
              >
                {product.status === ProductStatusDto.ARCHIVED
                  ? t('product:action.reactivate')
                  : t('product:action.archive')}
              </MenuItem>
            </Tooltip>
          </MenuList>
        </Portal>
      </Menu>
    )
  );
}
