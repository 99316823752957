import { find, reduce } from 'lodash-es';
import { useMemo } from 'react';
import Optional from '../optional/optional';
import DataTableColumn, { ChildDataTableColumn, ParentDataTableColumn } from './data-table-column';

type DataTableColumnWithoutFalsy<TData, TChildData = undefined> =
  | ParentDataTableColumn<TData>
  | ChildDataTableColumn<TChildData>;

export default function useMergedDataTableColumns<T>(
  columns: DataTableColumn<T>[][],
): DataTableColumnWithoutFalsy<T>[] {
  return useMemo(() => {
    const colsWithoutFalsyStuff: DataTableColumnWithoutFalsy<T>[][] = columns.map((cols) => {
      return cols.filter((col) => col != null && col !== false);
    });

    return (
      reduce(
        colsWithoutFalsyStuff,
        (result: DataTableColumnWithoutFalsy<T>[], current: DataTableColumnWithoutFalsy<T>[]) => {
          current.forEach((column) => {
            const resultCol = find(result, (resultCol) => resultCol.key === column.key);
            if (resultCol != null && isParentColumn(resultCol) && isParentColumn(column)) {
              // all properties should be the same except for renderCell
              const oldRenderCell = resultCol.renderCell;
              resultCol.renderCell = (data, index) => {
                return (
                  <Optional>
                    {oldRenderCell(data, index)}
                    {column.renderCell(data, index)}
                  </Optional>
                );
              };
            } else {
              result.unshift(column);
            }
          });
          return result;
        },
      ) || []
    );
  }, [columns]);
}

function isParentColumn<T>(column: DataTableColumnWithoutFalsy<T>): column is ParentDataTableColumn<T> {
  return (column as ParentDataTableColumn<T>).renderCell !== undefined;
}
