import { HStack, HTMLChakraProps, IconButton, Tooltip } from '@chakra-ui/react';
import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faBold,
  faH1,
  faH2,
  faItalic,
  faLink,
  faLinkSimple,
  faListOl,
  faListUl,
  faParagraph,
  faS,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';
import { faQuoteLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { RichTextOptionsDto } from '../../../api';

export const RICH_TEXT_OPTION_ICON: Record<RichTextOptionsDto, IconDefinition> = {
  BOLD: faBold,
  CITATION: faQuoteLeft,
  HEADLINE: faH1,
  SUB_HEADLINE: faH2,
  HYPERLINK: faLink,
  HYPERLINK_BUTTON: faLinkSimple,
  PARAGRAPH: faParagraph,
  ITALIC: faItalic,
  SMALL: faS,
  ORDERED_LIST: faListOl,
  UNORDERED_LIST: faListUl,
  ALIGN_LEFT: faAlignLeft,
  CENTER: faAlignCenter,
  ALIGN_RIGHT: faAlignRight,
  JUSTIFY: faAlignJustify,
};

export type RichTextOption = keyof typeof RichTextOptionsDto;

interface RichTextOptionsViewerProps extends HTMLChakraProps<'div'> {
  options: RichTextOption[];
  activeOptions?: RichTextOption[];
  onToggleOption?: (type: RichTextOption, isActive: boolean) => void;
  onBlurOption?(event: FocusEvent): void;
}

export default function RichTextOptionsViewer({
  options,
  activeOptions = [],
  onToggleOption,
  onBlurOption,
  sx,
  ...props
}: RichTextOptionsViewerProps) {
  const { t } = useTranslation('common');

  return (
    <HStack
      spacing="0"
      sx={{
        wrap: 'wrap',
        border: '1px',
        borderRadius: 'base',
        borderColor: 'border.01',
        width: 'fit-content',
        ...sx,
      }}
      {...props}
    >
      {options.map((type) => {
        const isActive = activeOptions.includes(type);
        const ariaLabel = t(`richTextOptions.options.${type}`) as string;

        return (
          <Tooltip key={type} label={ariaLabel}>
            <IconButton
              icon={<FontAwesomeIcon icon={RICH_TEXT_OPTION_ICON[type]} />}
              aria-pressed={isActive}
              variant="outline"
              onClick={() => onToggleOption?.(type, !isActive)}
              onBlur={onBlurOption}
              aria-label={ariaLabel}
              className={clsx({ 'is-active': isActive })}
              sx={{
                border: 0,
                borderRight: '1px',
                borderRadius: 0,
                borderColor: 'border.01',
                backgroundColor: 'layer.02',
                _first: {
                  borderLeftRadius: 'base',
                },
                _last: {
                  borderRight: '0px',
                  borderRightRadius: 'base',
                },
                '&.is-active': {
                  backgroundColor: 'layer.01',
                },
              }}
            />
          </Tooltip>
        );
      })}
    </HStack>
  );
}
