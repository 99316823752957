import React from 'react';
import { useTranslation } from 'react-i18next';
import { AttendeeDto } from '../../../api';
import { DataTableColumn } from '../../../ui/data-table';
import DateRangeFilter from '../../../ui/data-table/filter/date-range-filter';
import StringFilter from '../../../ui/data-table/filter/string-filter';
import Translate from '../../../util/translate/translate';
import PersonReference from '../../person/person-reference/person-reference';
import PersonReferenceViewer from '../../person/person-reference/person-reference-viewer';

interface useAttendeeColumnsProps {
  isReadOnly: boolean;
}

export default function useAttendeeColumns({ isReadOnly }: useAttendeeColumnsProps) {
  const { t } = useTranslation(['common', 'person']);

  const attendeeColumns: DataTableColumn<AttendeeDto>[] = [
    {
      key: 'person',
      sticky: true,
      name: <Translate ns="person" i18nKey="person" />,
      cellProps: {
        whiteSpace: 'nowrap',
        width: '50%',
        height: '49px',
      },
      renderCell: (attendee: AttendeeDto) =>
        isReadOnly ? (
          <PersonReferenceViewer personReference={attendee.person} hidePersonKey />
        ) : (
          <PersonReference personReference={attendee.person} hidePersonKey usePortalForCard />
        ),
      isSortable: true,
      filter: <StringFilter label={t('person:person')} />,
    },
    {
      key: 'timeOfAdmission',
      name: <Translate ns="live_ticketing" i18nKey="timeOfAdmission" />,
      cellProps: {
        whiteSpace: 'nowrap',
        width: '50%',
      },
      renderCell: (attendee: AttendeeDto) => t('common:format.date_time', { dateTime: attendee.timeOfAdmission }),
      isSortable: true,
      filter: <DateRangeFilter isTimestamp />,
    },
  ];

  return attendeeColumns;
}
