import { useMemo } from 'react';
import { RecipientLinkDto } from '../../../api';
import DataTable from '../../../ui/data-table/data-table';
import useMergedDataTableColumns from '../../../ui/data-table/use-merged-data-table-columns';
import useRecipientLister from '../../mailing/common/use-recipient-lister';
import usePersonRecipientColumns from '../../mailing/mailing-recipient-lister/use-person-recipient-columns';
import useListRecipientColumns from '../../mailing/mailing-recipient-lister/use-list-recipient-columns';

export default function CodeMailingRecipientLister({ mailingId }: { mailingId: string }) {
  const { state, setState, recipientPage } = useRecipientLister(mailingId);

  const personColumns = usePersonRecipientColumns(mailingId);
  const listColumns = useListRecipientColumns(mailingId);
  const mergedColumns = useMergedDataTableColumns<RecipientLinkDto>(
    useMemo(() => [listColumns, personColumns], [personColumns, listColumns]),
  );

  return (
    <DataTable
      rowKey={(recipient) => recipient.id!}
      page={recipientPage}
      columns={mergedColumns}
      state={state}
      onStateChange={setState}
      isPageable
    />
  );
}
