import { Editor, Element as SlateElement, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import {
  AdditionalBlockProperties,
  BlockElement,
  BlockFormatOption,
  EditorProps,
  ElementFormatOption,
} from './format-types';

export function isBlockActive(editor: EditorProps, ...format: ElementFormatOption[]) {
  return !!getActiveBlock(editor, ...format);
}

export function getActiveBlock(editor: EditorProps, ...format: ElementFormatOption[]) {
  const { selection } = editor;
  if (!selection) {
    return undefined;
  }

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && format.includes(n['type']),
    }),
  );
  return match;
}

// default never assignment to get a compile error if additional properties is used but no generic was provided
export function toggleBlock<T extends BlockElement = never>(
  editor: EditorProps,
  format: BlockFormatOption,
  fallbackFormat: BlockFormatOption | undefined,
  additionalProperties?: AdditionalBlockProperties<T>,
) {
  const isActive = isBlockActive(editor, format);

  if (isActive && fallbackFormat === undefined) {
    return;
  }

  const newProperties: Partial<SlateElement> = {
    type: isActive ? fallbackFormat : format,
    ...additionalProperties,
  };

  Transforms.setNodes(editor, newProperties);
  ReactEditor.focus(editor);
}
