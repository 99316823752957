import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EditionDto } from '../../../api';
import EditionImplicationType from '../edition-implication/edition-implication-type';

interface EditionImplicationIntroProps {
  affectedEditions: EditionDto[];
  type: EditionImplicationType | null;
}

export default function EditionImplicationIntro({ affectedEditions, type }: EditionImplicationIntroProps) {
  const { t } = useTranslation('edition');
  const [editionType, dateRange, name] = useWatch<EditionDto, ['editionType', 'dateRange', 'name']>({
    name: ['editionType', 'dateRange', 'name'],
  });

  const intro =
    type === EditionImplicationType.DATE_RANGE
      ? 'intro_change_date_range'
      : type === EditionImplicationType.NAME
        ? 'intro_change_name'
        : 'intro_change_date_range_and_name';

  return (
    <>
      {t(`editor.implication.${intro}`, {
        count: affectedEditions.length,
        editions: affectedEditions.map((edition) => edition.name),
        dateRange,
        editionType: editionType && t(`editionTypeOptions.${editionType}`),
        name,
      })}
    </>
  );
}
