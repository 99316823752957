import { Flex, HStack, Stack, Text, useId } from '@chakra-ui/react';
import React, { CSSProperties, Dispatch } from 'react';
import { useController } from 'react-hook-form';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';
import { FieldPathValue } from 'react-hook-form/dist/types/path';
import { Validate } from 'react-hook-form/dist/types/validator';
import { useTranslation } from 'react-i18next';
import { LayoutType } from '../../../feature/common/LayoutType';
import useControlledReducer from '../../../util/use-controlled-reducer/use-controlled-reducer';
import { DataTableColumn, DataTablePage } from '../../data-table';
import DataTable from '../../data-table/data-table';
import { createDataTableColumn } from '../../data-table/data-table-column';
import Label from '../label';
import ElementContext from './element-context';
import elementControlReducer, { ElementTableControlAction } from './element-control-reducer';

export const ElementTableDispatchContext = React.createContext<Dispatch<ElementTableControlAction<any>> | null>(null);

/**
 * Properties for element table control.
 */
export interface ElementTableControlProps<
  TFieldValues extends FieldValues,
  TElement,
  TChildElement = void,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  name: TName;
  columns: DataTableColumn<TElement, TChildElement>[];
  childRows?(data: TElement): TChildElement[] | undefined;
  label: React.ReactNode;
  helperPopover?: React.ReactElement;
  hint?: string;
  maxElements?: number;
  addButton?: React.ReactElement;
  editButton?: React.ReactElement;
  deleteButton?: React.ReactElement;
  extraActionButton?: React.ReactElement;
  isRequired?: boolean;
  validate?: Validate<FieldPathValue<TFieldValues, TName>, TFieldValues>;
  showHeader?: boolean;
  layout?: LayoutType;
  canEdit?: (element: TElement) => boolean;
  canDelete?: (element: TElement) => boolean;
  onChange?: (values: TElement[]) => void;
}

/**
 * Default control to add, edit, delete and display elements in a table.
 */
export default function ElementTableControl<
  TFieldValues extends FieldValues,
  TElement,
  TChildElement = void,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  columns,
  childRows,
  label,
  helperPopover,
  hint,
  maxElements,
  addButton,
  editButton,
  deleteButton,
  extraActionButton,
  isRequired,
  validate,
  showHeader,
  layout,
  canEdit,
  canDelete,
  onChange,
}: ElementTableControlProps<TFieldValues, TElement, TChildElement, TName>) {
  const { t } = useTranslation('common');
  const { field, fieldState } = useController({
    name,
    rules: {
      required: isRequired ? t('validation_error.required', { field: label }) : undefined,
      validate,
    },
  });
  const elements = React.useMemo(() => (field.value as TElement[]) ?? [], [field.value]);
  const page = React.useMemo(() => ({ content: elements }) as DataTablePage<TElement>, [elements]);
  const labelId = useId(undefined, 'element-table-control-label');
  const hintId = useId(undefined, 'element-table-control-hint');
  const handleChange = (values: TElement[]) => {
    field.onChange(values);
    onChange?.(values);
  };
  const dispatch = useControlledReducer<TElement[], ElementTableControlAction<TElement>>(
    elementControlReducer,
    elements,
    handleChange,
  );
  const hasValidationError = fieldState.error != null;

  const errorStyle: CSSProperties = {
    // 'red.500'
    border: '2px solid #e53e3e',
  };

  const columnsWithActions = React.useMemo(
    () => [
      ...columns,
      createDataTableColumn<TElement>({
        key: 'action',
        renderCell: (element, index) => (
          <ElementContext.Provider value={{ element, index }}>
            <Flex>
              {extraActionButton}
              {(!canEdit || canEdit(element)) && editButton}
              {(!canDelete || canDelete(element)) && deleteButton}
            </Flex>
          </ElementContext.Provider>
        ),
        cellProps: { pr: 2, w: 0, textAlign: 'end', whiteSpace: 'nowrap' },
      }),
    ],
    [canDelete, canEdit, columns, deleteButton, editButton, extraActionButton],
  );

  return (
    <Stack spacing={1}>
      <Stack spacing={0}>
        <HStack spacing={0} alignItems="flex-start">
          <Label id={labelId} isRequired={isRequired}>
            {label}
          </Label>
          {helperPopover}
        </HStack>
        {hint != null && (
          <Text id={hintId} fontSize="sm" color="text.muted">
            {hint}
          </Text>
        )}
      </Stack>

      <ElementTableDispatchContext.Provider value={dispatch}>
        <DataTable<TElement, TChildElement>
          size="sm"
          showHeader={showHeader}
          style={hasValidationError ? errorStyle : undefined}
          aria-labelledby={labelId}
          aria-describedby={hintId}
          page={page}
          columns={columnsWithActions}
          rowKey={(_, index) => index}
          childRows={childRows}
          empty={
            <Flex p={3} color="text.muted" fontSize="sm" justify="center">
              {t('data_table.empty')}
            </Flex>
          }
          footer={
            addButton != null &&
            (maxElements == null || elements.length < maxElements) && (
              <Flex borderTop="1px solid" borderColor="border.01">
                {React.cloneElement(addButton, {
                  variant: 'ghost',
                  w: 'full',
                  borderTopRadius: 'none',
                  ref: field.ref,
                  ...addButton.props,
                })}
              </Flex>
            )
          }
          layout={layout}
        />
      </ElementTableDispatchContext.Provider>

      <Text fontSize="sm" color="text.error">
        {fieldState.error?.message}
      </Text>
    </Stack>
  );
}
