import React, { createContext, ProviderProps } from 'react';
import invariant from 'tiny-invariant';

export default function createDefinedContext<T>(name: string, defaultValue?: T) {
  const Context = createContext<T | undefined>(defaultValue);

  const DefinedContextProvider = (props: ProviderProps<T>) => {
    return <Context.Provider {...props} />;
  };

  const useDefinedContext = () => {
    const value = React.useContext(Context);
    invariant(value != null, `Context "${name}" is missing`);

    return value as NonNullable<T>;
  };

  return [DefinedContextProvider, useDefinedContext] as const;
}
