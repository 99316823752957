/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';

/**
 * 
 * @export
 * @interface CodeSentToPeopleInnerDto
 */
export interface CodeSentToPeopleInnerDto {
    /**
     * 
     * @type {Date}
     * @memberof CodeSentToPeopleInnerDto
     */
    timestamp?: Date;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof CodeSentToPeopleInnerDto
     */
    person?: PersonReferenceDto;
}

/**
 * Check if a given object implements the CodeSentToPeopleInnerDto interface.
 */
export function instanceOfCodeSentToPeopleInnerDto(value: object): boolean {
    return true;
}

export function CodeSentToPeopleInnerDtoFromJSON(json: any): CodeSentToPeopleInnerDto {
    return CodeSentToPeopleInnerDtoFromJSONTyped(json, false);
}

export function CodeSentToPeopleInnerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodeSentToPeopleInnerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
        'person': json['person'] == null ? undefined : PersonReferenceDtoFromJSON(json['person']),
    };
}

export function CodeSentToPeopleInnerDtoToJSON(value?: CodeSentToPeopleInnerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
        'person': PersonReferenceDtoToJSON(value['person']),
    };
}

