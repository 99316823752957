/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CodePermissionDto
 */
export interface CodePermissionDto {
    /**
     * 
     * @type {string}
     * @memberof CodePermissionDto
     */
    name: CodePermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof CodePermissionDto
     */
    action: CodePermissionDtoActionEnum;
}


/**
 * @export
 */
export const CodePermissionDtoNameEnum = {
    CODE: 'CODE'
} as const;
export type CodePermissionDtoNameEnum = typeof CodePermissionDtoNameEnum[keyof typeof CodePermissionDtoNameEnum];

/**
 * @export
 */
export const CodePermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    SEND: 'CAN_SEND',
    INVALIDATE: 'CAN_INVALIDATE',
    EDIT: 'CAN_EDIT',
    CREATE: 'CAN_CREATE'
} as const;
export type CodePermissionDtoActionEnum = typeof CodePermissionDtoActionEnum[keyof typeof CodePermissionDtoActionEnum];


/**
 * Check if a given object implements the CodePermissionDto interface.
 */
export function instanceOfCodePermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function CodePermissionDtoFromJSON(json: any): CodePermissionDto {
    return CodePermissionDtoFromJSONTyped(json, false);
}

export function CodePermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodePermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function CodePermissionDtoToJSON(value?: CodePermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

