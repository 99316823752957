import { StandCompanyRelationTypeDto, StandPersonRelationTypeDto, StandStatusDto, StandTypeDto } from '../../api';

export const statusOptions = [StandStatusDto.CONFIRMED, StandStatusDto.PENDING, StandStatusDto.CANCELED];
export const typeOptions = [StandTypeDto.INDIVIDUAL, StandTypeDto.SHARED];
export const standPersonRelationTypeOptions = [
  StandPersonRelationTypeDto.OTHER,
  StandPersonRelationTypeDto.STAND_COORDINATOR,
  StandPersonRelationTypeDto.STAND_PERSONNEL,
  StandPersonRelationTypeDto.EXTERNAL_CONTACT,
];
export const standCompanyRelationTypeOptions = [
  StandCompanyRelationTypeDto.MAIN_PARTNER,
  StandCompanyRelationTypeDto.SECONDARY_PARTNER,
  StandCompanyRelationTypeDto.EXCLUDED,
];
