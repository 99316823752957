import {
  anatomy,
  getColorVar,
  mode,
  MultiStyleConfig,
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleFunction,
} from '@chakra-ui/theme-tools';

export const dataTableAnatomy = anatomy('dataTable').parts(
  'container',
  'tableContainer',
  'spinner',
  'spinnerContainer',
  'tr',
  'th',
  'td',
  'footer',
  'pageInfo',
);

export type DataTableAnatomy = typeof dataTableAnatomy;

export type DataTableSize = 'sm' | 'md';

const beforeShadow: SystemStyleFunction = (props) => {
  const gradientColor = mode('blackAlpha-300', 'blackAlpha-600')(props);

  return {
    content: `""`,
    width: 4,
    height: 'full',
    position: 'absolute',
    top: 0,
    right: '100%',
    backgroundImage: `linear-gradient(to right, transparent, var(--chakra-colors-${gradientColor}))`,
    pointerEvents: 'none',
  };
};

const sticky = {
  position: 'sticky',
  zIndex: 'stickyTableColumn',
  _first: {
    left: 0,
  },
  _notFirst: {
    right: 0,
  },
};

const baseStyle: PartsStyleFunction<DataTableAnatomy> = (props) => {
  const { theme } = props;

  return {
    container: {
      borderWidth: 1,
      borderColor: 'border.01',
      borderRadius: 'base',
      backgroundColor: 'layer.01',
      position: 'relative',
      width: '100%',
      minHeight: 0,
      _invalid: {
        borderColor: 'border.error',
        boxShadow: `0 0 0 1px ${getColorVar(theme, 'border.error')}`,
      },
    },
    tableContainer: {
      overflow: 'auto',
      borderRadius: 'base',
    },
    spinnerContainer: {
      position: 'absolute',
      top: 10,
      bottom: 10,
      bg: 'whiteAlpha.600',
      w: '100%',
      justify: 'center',
      align: 'center',
      pointerEvents: 'none',
    },
    th: {
      position: 'sticky',
      top: 0,
      zIndex: 'stickyTableHeader',
      backgroundColor: 'layer.02',
      _first: {
        paddingLeft: 4,
        borderTopLeftRadius: 'base',
      },
      _last: {
        borderTopRightRadius: 'base',
      },
      '&[data-sticky="true"]': {
        ...sticky,
        zIndex: 'stickyTableColumnHeader',
        _last: { _before: beforeShadow(props) },
      },
    },
    tr: {
      bg: 'layer.01',
      '&[data-clickable="true"]': {
        cursor: 'pointer',
      },
      '&[data-hovering="true"]': {
        bg: 'background.highlight',
      },
      td: {
        '&[data-sticky="true"]': {
          ...sticky,
          backgroundColor: 'inherit',
          _last: { _before: beforeShadow(props) },
        },
        '&[data-first-column="true"]': {
          paddingLeft: 4,
        },
        '&[data-last-row="true"]': {
          borderBottom: 'none',
        },
        lineHeight: 5,
        paddingY: 2,
      },
    },
    footer: {
      borderTop: '1px',
      borderColor: 'border.01',
      display: 'flex',
      alignItems: 'center',
    },
    pageInfo: {
      mr: 4,
    },
  };
};

const defaultProps = {
  size: 'md',
};

const sizes: Record<DataTableSize, PartsStyleObject<DataTableAnatomy>> = {
  sm: {
    th: {
      px: 2,
    },
    td: {
      px: 2,
    },
    footer: {
      py: 1,
      px: 4,
      fontSize: 'sm',
    },
  },
  md: {
    th: {
      px: 4,
    },
    td: {
      px: 4,
    },
    footer: {
      py: 2,
      px: 6,
      fontSize: 'md',
    },
  },
};

const DataTable: MultiStyleConfig<DataTableAnatomy> = {
  parts: dataTableAnatomy.keys,
  baseStyle,
  defaultProps,
  sizes,
};

export default DataTable;
