/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BlockElementDto } from './BlockElementDto';
import {
    BlockElementDtoFromJSON,
    BlockElementDtoFromJSONTyped,
    BlockElementDtoToJSON,
} from './BlockElementDto';
import type { CountryReferenceDto } from './CountryReferenceDto';
import {
    CountryReferenceDtoFromJSON,
    CountryReferenceDtoFromJSONTyped,
    CountryReferenceDtoToJSON,
} from './CountryReferenceDto';
import type { FileMetadataDto } from './FileMetadataDto';
import {
    FileMetadataDtoFromJSON,
    FileMetadataDtoFromJSONTyped,
    FileMetadataDtoToJSON,
} from './FileMetadataDto';
import type { PassportDataToShowDto } from './PassportDataToShowDto';
import {
    PassportDataToShowDtoFromJSON,
    PassportDataToShowDtoFromJSONTyped,
    PassportDataToShowDtoToJSON,
} from './PassportDataToShowDto';
import type { PersonalDataToShowDto } from './PersonalDataToShowDto';
import {
    PersonalDataToShowDtoFromJSON,
    PersonalDataToShowDtoFromJSONTyped,
    PersonalDataToShowDtoToJSON,
} from './PersonalDataToShowDto';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';
import type { TravelDataToShowDto } from './TravelDataToShowDto';
import {
    TravelDataToShowDtoFromJSON,
    TravelDataToShowDtoFromJSONTyped,
    TravelDataToShowDtoToJSON,
} from './TravelDataToShowDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';
import type { VisaTemplateStatusDto } from './VisaTemplateStatusDto';
import {
    VisaTemplateStatusDtoFromJSON,
    VisaTemplateStatusDtoFromJSONTyped,
    VisaTemplateStatusDtoToJSON,
} from './VisaTemplateStatusDto';

/**
 * A visa template
 * @export
 * @interface VisaTemplateDto
 */
export interface VisaTemplateDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof VisaTemplateDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof VisaTemplateDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof VisaTemplateDto
     */
    title: string;
    /**
     * 
     * @type {Array<CountryReferenceDto>}
     * @memberof VisaTemplateDto
     */
    countries: Array<CountryReferenceDto>;
    /**
     * 
     * @type {boolean}
     * @memberof VisaTemplateDto
     */
    personWithGuestSection: boolean;
    /**
     * 
     * @type {Array<SectionReferenceDto>}
     * @memberof VisaTemplateDto
     */
    guestSections: Array<SectionReferenceDto>;
    /**
     * 
     * @type {Array<PersonalDataToShowDto>}
     * @memberof VisaTemplateDto
     */
    personalDataToShow: Array<PersonalDataToShowDto>;
    /**
     * 
     * @type {Array<PassportDataToShowDto>}
     * @memberof VisaTemplateDto
     */
    passportDataToShow: Array<PassportDataToShowDto>;
    /**
     * 
     * @type {Array<TravelDataToShowDto>}
     * @memberof VisaTemplateDto
     */
    travelDataToShow: Array<TravelDataToShowDto>;
    /**
     * 
     * @type {boolean}
     * @memberof VisaTemplateDto
     */
    defaultTemplate: boolean;
    /**
     * 
     * @type {FileMetadataDto}
     * @memberof VisaTemplateDto
     */
    signature: FileMetadataDto;
    /**
     * 
     * @type {Array<BlockElementDto>}
     * @memberof VisaTemplateDto
     */
    textBlock: Array<BlockElementDto>;
    /**
     * 
     * @type {Array<BlockElementDto>}
     * @memberof VisaTemplateDto
     */
    signatory: Array<BlockElementDto>;
    /**
     * 
     * @type {FileMetadataDto}
     * @memberof VisaTemplateDto
     */
    visaPreviewDocument?: FileMetadataDto;
    /**
     * 
     * @type {VisaTemplateStatusDto}
     * @memberof VisaTemplateDto
     */
    status: VisaTemplateStatusDto;
}

/**
 * Check if a given object implements the VisaTemplateDto interface.
 */
export function instanceOfVisaTemplateDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('title' in value)) return false;
    if (!('countries' in value)) return false;
    if (!('personWithGuestSection' in value)) return false;
    if (!('guestSections' in value)) return false;
    if (!('personalDataToShow' in value)) return false;
    if (!('passportDataToShow' in value)) return false;
    if (!('travelDataToShow' in value)) return false;
    if (!('defaultTemplate' in value)) return false;
    if (!('signature' in value)) return false;
    if (!('textBlock' in value)) return false;
    if (!('signatory' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function VisaTemplateDtoFromJSON(json: any): VisaTemplateDto {
    return VisaTemplateDtoFromJSONTyped(json, false);
}

export function VisaTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisaTemplateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'title': json['title'],
        'countries': ((json['countries'] as Array<any>).map(CountryReferenceDtoFromJSON)),
        'personWithGuestSection': json['personWithGuestSection'],
        'guestSections': ((json['guestSections'] as Array<any>).map(SectionReferenceDtoFromJSON)),
        'personalDataToShow': ((json['personalDataToShow'] as Array<any>).map(PersonalDataToShowDtoFromJSON)),
        'passportDataToShow': ((json['passportDataToShow'] as Array<any>).map(PassportDataToShowDtoFromJSON)),
        'travelDataToShow': ((json['travelDataToShow'] as Array<any>).map(TravelDataToShowDtoFromJSON)),
        'defaultTemplate': json['defaultTemplate'],
        'signature': FileMetadataDtoFromJSON(json['signature']),
        'textBlock': ((json['textBlock'] as Array<any>).map(BlockElementDtoFromJSON)),
        'signatory': ((json['signatory'] as Array<any>).map(BlockElementDtoFromJSON)),
        'visaPreviewDocument': json['visaPreviewDocument'] == null ? undefined : FileMetadataDtoFromJSON(json['visaPreviewDocument']),
        'status': VisaTemplateStatusDtoFromJSON(json['status']),
    };
}

export function VisaTemplateDtoToJSON(value?: VisaTemplateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'title': value['title'],
        'countries': ((value['countries'] as Array<any>).map(CountryReferenceDtoToJSON)),
        'personWithGuestSection': value['personWithGuestSection'],
        'guestSections': ((value['guestSections'] as Array<any>).map(SectionReferenceDtoToJSON)),
        'personalDataToShow': ((value['personalDataToShow'] as Array<any>).map(PersonalDataToShowDtoToJSON)),
        'passportDataToShow': ((value['passportDataToShow'] as Array<any>).map(PassportDataToShowDtoToJSON)),
        'travelDataToShow': ((value['travelDataToShow'] as Array<any>).map(TravelDataToShowDtoToJSON)),
        'defaultTemplate': value['defaultTemplate'],
        'signature': FileMetadataDtoToJSON(value['signature']),
        'textBlock': ((value['textBlock'] as Array<any>).map(BlockElementDtoToJSON)),
        'signatory': ((value['signatory'] as Array<any>).map(BlockElementDtoToJSON)),
        'visaPreviewDocument': FileMetadataDtoToJSON(value['visaPreviewDocument']),
        'status': VisaTemplateStatusDtoToJSON(value['status']),
    };
}

