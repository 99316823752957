import React from 'react';
import { eventIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { useEventPermissionClassGroupExtension } from './event-permissions';
import EventSearchResult from './event-search-result';

export default function EventPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, { type: 'EVENT', Component: EventSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'EVENT',
    icon: eventIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.EVENT" />,
    necessaryPermission: 'EVENT:CAN_SEE_DETAILS_OWN',
  });
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useEventPermissionClassGroupExtension);
}
