/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PersonTagPermissionDto
 */
export interface PersonTagPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof PersonTagPermissionDto
     */
    name: PersonTagPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonTagPermissionDto
     */
    action: PersonTagPermissionDtoActionEnum;
}


/**
 * @export
 */
export const PersonTagPermissionDtoNameEnum = {
    PERSON_TAG: 'PERSON-TAG'
} as const;
export type PersonTagPermissionDtoNameEnum = typeof PersonTagPermissionDtoNameEnum[keyof typeof PersonTagPermissionDtoNameEnum];

/**
 * @export
 */
export const PersonTagPermissionDtoActionEnum = {
    LINK: 'CAN_LINK',
    UNLINK: 'CAN_UNLINK'
} as const;
export type PersonTagPermissionDtoActionEnum = typeof PersonTagPermissionDtoActionEnum[keyof typeof PersonTagPermissionDtoActionEnum];


/**
 * Check if a given object implements the PersonTagPermissionDto interface.
 */
export function instanceOfPersonTagPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function PersonTagPermissionDtoFromJSON(json: any): PersonTagPermissionDto {
    return PersonTagPermissionDtoFromJSONTyped(json, false);
}

export function PersonTagPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonTagPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function PersonTagPermissionDtoToJSON(value?: PersonTagPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

