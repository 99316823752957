/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FileMetadataDto } from './FileMetadataDto';
import {
    FileMetadataDtoFromJSON,
    FileMetadataDtoFromJSONTyped,
    FileMetadataDtoToJSON,
} from './FileMetadataDto';

/**
 * A header for the mailing design
 * @export
 * @interface MailingDesignHeaderDto
 */
export interface MailingDesignHeaderDto {
    /**
     * A hex color code
     * @type {string}
     * @memberof MailingDesignHeaderDto
     */
    backgroundColor: string;
    /**
     * 
     * @type {FileMetadataDto}
     * @memberof MailingDesignHeaderDto
     */
    logoMetadata?: FileMetadataDto;
    /**
     * 
     * @type {FileMetadataDto}
     * @memberof MailingDesignHeaderDto
     */
    keyVisualMobileMetadata?: FileMetadataDto;
    /**
     * 
     * @type {FileMetadataDto}
     * @memberof MailingDesignHeaderDto
     */
    keyVisualDesktopMetadata?: FileMetadataDto;
}

/**
 * Check if a given object implements the MailingDesignHeaderDto interface.
 */
export function instanceOfMailingDesignHeaderDto(value: object): boolean {
    if (!('backgroundColor' in value)) return false;
    return true;
}

export function MailingDesignHeaderDtoFromJSON(json: any): MailingDesignHeaderDto {
    return MailingDesignHeaderDtoFromJSONTyped(json, false);
}

export function MailingDesignHeaderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingDesignHeaderDto {
    if (json == null) {
        return json;
    }
    return {
        
        'backgroundColor': json['backgroundColor'],
        'logoMetadata': json['logoMetadata'] == null ? undefined : FileMetadataDtoFromJSON(json['logoMetadata']),
        'keyVisualMobileMetadata': json['keyVisualMobileMetadata'] == null ? undefined : FileMetadataDtoFromJSON(json['keyVisualMobileMetadata']),
        'keyVisualDesktopMetadata': json['keyVisualDesktopMetadata'] == null ? undefined : FileMetadataDtoFromJSON(json['keyVisualDesktopMetadata']),
    };
}

export function MailingDesignHeaderDtoToJSON(value?: MailingDesignHeaderDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'backgroundColor': value['backgroundColor'],
        'logoMetadata': FileMetadataDtoToJSON(value['logoMetadata']),
        'keyVisualMobileMetadata': FileMetadataDtoToJSON(value['keyVisualMobileMetadata']),
        'keyVisualDesktopMetadata': FileMetadataDtoToJSON(value['keyVisualDesktopMetadata']),
    };
}

