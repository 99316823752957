/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FunctionDto } from './FunctionDto';
import {
    FunctionDtoFromJSON,
    FunctionDtoFromJSONTyped,
    FunctionDtoToJSON,
} from './FunctionDto';
import type { GroupDto } from './GroupDto';
import {
    GroupDtoFromJSON,
    GroupDtoFromJSONTyped,
    GroupDtoToJSON,
} from './GroupDto';
import type { GroupStaffDto } from './GroupStaffDto';
import {
    GroupStaffDtoFromJSON,
    GroupStaffDtoFromJSONTyped,
    GroupStaffDtoToJSON,
} from './GroupStaffDto';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * The connection between a group and a staff
 * @export
 * @interface GroupStaffRelationDto
 */
export interface GroupStaffRelationDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof GroupStaffRelationDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof GroupStaffRelationDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {SectionReferenceDto}
     * @memberof GroupStaffRelationDto
     */
    section?: SectionReferenceDto;
    /**
     * 
     * @type {GroupDto}
     * @memberof GroupStaffRelationDto
     */
    group: GroupDto;
    /**
     * 
     * @type {GroupStaffDto}
     * @memberof GroupStaffRelationDto
     */
    staff: GroupStaffDto;
    /**
     * 
     * @type {FunctionDto}
     * @memberof GroupStaffRelationDto
     */
    staffFunction?: FunctionDto;
    /**
     * 
     * @type {boolean}
     * @memberof GroupStaffRelationDto
     */
    groupAdmin: boolean;
}

/**
 * Check if a given object implements the GroupStaffRelationDto interface.
 */
export function instanceOfGroupStaffRelationDto(value: object): boolean {
    if (!('group' in value)) return false;
    if (!('staff' in value)) return false;
    if (!('groupAdmin' in value)) return false;
    return true;
}

export function GroupStaffRelationDtoFromJSON(json: any): GroupStaffRelationDto {
    return GroupStaffRelationDtoFromJSONTyped(json, false);
}

export function GroupStaffRelationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupStaffRelationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'section': json['section'] == null ? undefined : SectionReferenceDtoFromJSON(json['section']),
        'group': GroupDtoFromJSON(json['group']),
        'staff': GroupStaffDtoFromJSON(json['staff']),
        'staffFunction': json['staffFunction'] == null ? undefined : FunctionDtoFromJSON(json['staffFunction']),
        'groupAdmin': json['groupAdmin'],
    };
}

export function GroupStaffRelationDtoToJSON(value?: GroupStaffRelationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'section': SectionReferenceDtoToJSON(value['section']),
        'group': GroupDtoToJSON(value['group']),
        'staff': GroupStaffDtoToJSON(value['staff']),
        'staffFunction': FunctionDtoToJSON(value['staffFunction']),
        'groupAdmin': value['groupAdmin'],
    };
}

