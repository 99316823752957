/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CategoryOfParticipationDto } from './CategoryOfParticipationDto';
import {
    CategoryOfParticipationDtoFromJSON,
    CategoryOfParticipationDtoFromJSONTyped,
    CategoryOfParticipationDtoToJSON,
} from './CategoryOfParticipationDto';
import type { GuestTypeDto } from './GuestTypeDto';
import {
    GuestTypeDtoFromJSON,
    GuestTypeDtoFromJSONTyped,
    GuestTypeDtoToJSON,
} from './GuestTypeDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';

/**
 * 
 * @export
 * @interface AddPeoplePeopleInnerDto
 */
export interface AddPeoplePeopleInnerDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof AddPeoplePeopleInnerDto
     */
    id: string;
    /**
     * 
     * @type {GuestTypeDto}
     * @memberof AddPeoplePeopleInnerDto
     */
    guestType?: GuestTypeDto;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof AddPeoplePeopleInnerDto
     */
    person?: PersonReferenceDto;
    /**
     * 
     * @type {CategoryOfParticipationDto}
     * @memberof AddPeoplePeopleInnerDto
     */
    category: CategoryOfParticipationDto;
}

/**
 * Check if a given object implements the AddPeoplePeopleInnerDto interface.
 */
export function instanceOfAddPeoplePeopleInnerDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('category' in value)) return false;
    return true;
}

export function AddPeoplePeopleInnerDtoFromJSON(json: any): AddPeoplePeopleInnerDto {
    return AddPeoplePeopleInnerDtoFromJSONTyped(json, false);
}

export function AddPeoplePeopleInnerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddPeoplePeopleInnerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'guestType': json['guestType'] == null ? undefined : GuestTypeDtoFromJSON(json['guestType']),
        'person': json['person'] == null ? undefined : PersonReferenceDtoFromJSON(json['person']),
        'category': CategoryOfParticipationDtoFromJSON(json['category']),
    };
}

export function AddPeoplePeopleInnerDtoToJSON(value?: AddPeoplePeopleInnerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'guestType': GuestTypeDtoToJSON(value['guestType']),
        'person': PersonReferenceDtoToJSON(value['person']),
        'category': CategoryOfParticipationDtoToJSON(value['category']),
    };
}

