/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const StandStatusDto = {
    CONFIRMED: 'CONFIRMED',
    PENDING: 'PENDING',
    CANCELED: 'CANCELED'
} as const;
export type StandStatusDto = typeof StandStatusDto[keyof typeof StandStatusDto];


export function StandStatusDtoFromJSON(json: any): StandStatusDto {
    return StandStatusDtoFromJSONTyped(json, false);
}

export function StandStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandStatusDto {
    return json as StandStatusDto;
}

export function StandStatusDtoToJSON(value?: StandStatusDto | null): any {
    return value as any;
}

