import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../../../permission-class-group/permission-class-group-extension';

export function useGroupPersonPermissionClassGroupExtension(): PermissionClassGroupExtension<'GROUP-PERSON'> {
  const { t } = useTranslation(['group']);

  return {
    name: 'GROUP-PERSON',
    label: t('group:personRelations.label'),
    getActionLabel: (action) => t(`group:personRelations.permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`group:personRelations.permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`group:personRelations.permissionInfo.${action}`, { defaultValue: '' }),
  };
}
