import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { faUserCrown, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StaffDto, StaffReferenceDto, TeamDto } from '../../../../api';
import staffApi from '../../../../data-access/staff-api';
import teamStaffApi from '../../../../data-access/team-staff-api';
import FormControl from '../../../../ui/form/form-control';
import ValueAsyncSelectControl from '../../../../ui/form/select-control/value-async-select-control';
import ConnectionLine from '../../common/connection-line';

export interface TeamStaffEditorControlProps {
  fromTeam: TeamDto;
  initialFocusRef?: React.RefObject<any>;
}

export default function TeamStaffEditorControl({ fromTeam, initialFocusRef }: TeamStaffEditorControlProps) {
  const { t } = useTranslation('relations');

  const loadOptions = async (query: string, pageSizeLimit: number) => {
    const page = await staffApi.searchStaffReferences({
      pageable: { size: pageSizeLimit },
      q: query,
    });

    return page.content;
  };

  const validateDuplicateStaff = useCallback(
    async (staff: StaffDto) => {
      if (staff == null) {
        return true;
      }
      const page = await teamStaffApi.searchTeamStaffRelations({
        filter: [`team.id,eq,${fromTeam.id}`, `staff.id,eq,${staff.id}`],
      });
      return page.totalElements === 0 || t('duplicate_staff_message');
    },
    [fromTeam.id, t],
  );

  return (
    <Stack spacing={2} backgroundColor="background.highlight" pr={2} py={2} pl={3} borderRadius="base">
      <Flex position="relative" align="center">
        <Box as={FontAwesomeIcon} icon={faUsers} fixedWidth flexShrink={0} />
        <Box ml={3} aria-label={t('from_team')}>
          <Text as="span" fontWeight="medium">
            {fromTeam.name}
          </Text>
        </Box>
      </Flex>
      <Flex align="center" position="relative">
        <ConnectionLine position="top" showArrow spacing={2} />
        <Box as={FontAwesomeIcon} icon={faUserCrown} fixedWidth flexShrink={0} />
        <Box width="full" ml={3}>
          <FormControl name="staff">
            <ValueAsyncSelectControl<StaffReferenceDto>
              aria-label={t('to_staff')}
              label={t('staff')}
              isRequired
              loadOptions={loadOptions}
              renderLabel={(staff) => `${staff.firstName} ${staff.surname}`}
              name="staff"
              optionIdentifier={(staff) => staff.id!}
              rules={{ validate: validateDuplicateStaff }}
              ref={initialFocusRef}
            />
          </FormControl>
        </Box>
      </Flex>
    </Stack>
  );
}
