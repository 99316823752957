/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FileMetadataDto } from './FileMetadataDto';
import {
    FileMetadataDtoFromJSON,
    FileMetadataDtoFromJSONTyped,
    FileMetadataDtoToJSON,
} from './FileMetadataDto';

/**
 * 
 * @export
 * @interface MailingPortraitModuleDataDto
 */
export interface MailingPortraitModuleDataDto {
    /**
     * 
     * @type {FileMetadataDto}
     * @memberof MailingPortraitModuleDataDto
     */
    image: FileMetadataDto;
    /**
     * 
     * @type {string}
     * @memberof MailingPortraitModuleDataDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MailingPortraitModuleDataDto
     */
    jobTitle: string;
    /**
     * 
     * @type {string}
     * @memberof MailingPortraitModuleDataDto
     */
    jobTitleEnglish: string;
    /**
     * A URL restricted through uri format of open api
     * @type {string}
     * @memberof MailingPortraitModuleDataDto
     */
    linkTarget?: string;
}

/**
 * Check if a given object implements the MailingPortraitModuleDataDto interface.
 */
export function instanceOfMailingPortraitModuleDataDto(value: object): boolean {
    if (!('image' in value)) return false;
    if (!('name' in value)) return false;
    if (!('jobTitle' in value)) return false;
    if (!('jobTitleEnglish' in value)) return false;
    return true;
}

export function MailingPortraitModuleDataDtoFromJSON(json: any): MailingPortraitModuleDataDto {
    return MailingPortraitModuleDataDtoFromJSONTyped(json, false);
}

export function MailingPortraitModuleDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingPortraitModuleDataDto {
    if (json == null) {
        return json;
    }
    return {
        
        'image': FileMetadataDtoFromJSON(json['image']),
        'name': json['name'],
        'jobTitle': json['jobTitle'],
        'jobTitleEnglish': json['jobTitleEnglish'],
        'linkTarget': json['linkTarget'] == null ? undefined : json['linkTarget'],
    };
}

export function MailingPortraitModuleDataDtoToJSON(value?: MailingPortraitModuleDataDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'image': FileMetadataDtoToJSON(value['image']),
        'name': value['name'],
        'jobTitle': value['jobTitle'],
        'jobTitleEnglish': value['jobTitleEnglish'],
        'linkTarget': value['linkTarget'],
    };
}

