/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ModeOfTransportDto = {
    TRAIN: 'TRAIN',
    FLIGHT: 'FLIGHT',
    PRIVATE: 'PRIVATE',
    NONE: 'NONE',
    OTHER: 'OTHER'
} as const;
export type ModeOfTransportDto = typeof ModeOfTransportDto[keyof typeof ModeOfTransportDto];


export function ModeOfTransportDtoFromJSON(json: any): ModeOfTransportDto {
    return ModeOfTransportDtoFromJSONTyped(json, false);
}

export function ModeOfTransportDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModeOfTransportDto {
    return json as ModeOfTransportDto;
}

export function ModeOfTransportDtoToJSON(value?: ModeOfTransportDto | null): any {
    return value as any;
}

