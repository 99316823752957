import { getYear, isFuture, isValid, Locale, parse } from 'date-fns';
import { getCentury, setCentury } from './century';

// lh: Custom parse function respecting the timezone offset.
export default function parseMultiple(
  dateString: string,
  formatStrings: string[],
  referenceDate: Date,
  options?: { locale?: Locale; autocompleteToPastDates?: boolean },
) {
  for (const formatString of formatStrings) {
    let date = parse(dateString, formatString, referenceDate, options);

    if (isValid(date)) {
      // jaj: guess the correct century when user input is not a complete year
      // eg: 1.1.20 does not include 2020
      if (!dateString.includes(String(getYear(date)))) {
        if (options?.autocompleteToPastDates) {
          // jaj: if only past dates are valid guess the last century if date would be in the future
          date = setCentury(date, isFuture(date) ? getCentury(date) - 1 : getCentury(date));
        } else {
          // jaj: always use 20xx as the year
          date = setCentury(date, 20);
        }
      }
      return date;
    }
  }

  return new Date(NaN);
}
