import { GridItem } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { mutate } from 'swr';
import { EventDto } from '../../../api';
import eventApi from '../../../data-access/event-api';
import { PageContent, PageHeader, PageSpinner } from '../../../ui/page';
import Page from '../../../ui/page/page';
import useToast from '../../../ui/use-toast/use-toast';
import usePromise from '../../../util/use-promise/use-promise';
import { LayoutType } from '../../common/LayoutType';
import EditionHeader from '../../edition/focused-edition-header/edition-header';
import { useFocusedOrActiveEditionId } from '../../edition/use-focused-or-active-edition/use-focused-or-active-edition-id';
import { EventCopySettings } from '../copy-settings-dialog/copy-settings-dialog';
import EventForm from '../event-form/event-form';
import { getEventCacheKey } from '../use-event/use-event';

export enum EventEditorAction {
  NEW = 'new',
  EDIT = 'edit',
  COPY = 'copy',
}

export interface EventEditorProps {
  layout: LayoutType;
  editorAction: EventEditorAction;
}

/**
 * Represents the editor of an {@link EventDto}.
 */
export default function EventEditor({ layout, editorAction }: EventEditorProps) {
  const { t } = useTranslation('event');
  const navigate = useNavigate();
  const showSuccessToast = useToast({
    id: 'event-editor-success-toast',
    status: 'success',
  });

  const { eventId } = useParams<{ eventId: string }>();
  const focusedEditionId = useFocusedOrActiveEditionId();

  const event = useEditorEvent(focusedEditionId, editorAction, eventId);

  const handleEventSave = async (eventDto: EventDto, copySettings: EventCopySettings) => {
    showSuccessToast.closeAll();

    let saved: EventDto;

    switch (editorAction) {
      case EventEditorAction.NEW:
        saved = await eventApi.createEvent({ eventWrapperDto: { event: eventDto } });
        break;
      case EventEditorAction.COPY:
        saved = await eventApi.createEvent({ eventWrapperDto: { event: eventDto, copySettings: copySettings } });
        break;
      case EventEditorAction.EDIT:
        if (eventId != null) {
          saved = await eventApi.updateEvent({ id: eventId, eventDto });
        } else {
          return;
        }
        break;
    }

    showSuccessToast({
      title: t('toast.success.title'),
      description: t('toast.success.description', { event: saved.title }),
    });

    if (saved.id != null) {
      await mutate(getEventCacheKey(saved.id), saved);
    }

    navigate(`/events/${saved.id}`);
  };

  return (
    <Page>
      <GridItem>
        <EditionHeader editionId={event?.edition.id} navigationTarget="events" />
        <PageHeader title={t(`action.${editorAction}`)} />
      </GridItem>
      <PageContent>
        {event != null ? (
          <EventForm event={event} onEventSave={handleEventSave} layout={layout} editorAction={editorAction} />
        ) : (
          <PageSpinner />
        )}
      </PageContent>
    </Page>
  );
}

function useEditorEvent(focusedEditionId: string, editorAction: EventEditorAction, eventId?: string) {
  return usePromise(
    (signal) => {
      switch (editorAction) {
        case EventEditorAction.NEW:
          return eventApi.templateEvent({ focusedEditionId }, { signal });
        case EventEditorAction.EDIT:
          if (eventId != null) {
            return eventApi.fetchEvent({ id: eventId }, { signal });
          }
          break;
        case EventEditorAction.COPY:
          if (eventId != null) {
            //TODO better create template in backend based on actual
            return Promise.all([
              eventApi.templateEvent({ focusedEditionId }, { signal }),
              eventApi.fetchEvent({ id: eventId }, { signal }),
            ]).then((values) => {
              const templateEvent = values[0];
              const actualEvent = values[1];
              return createCopyEvent(templateEvent, actualEvent);
            });
          }
          break;
      }
    },
    [editorAction, focusedEditionId, eventId],
  );
}

function createCopyEvent(templateEvent: EventDto, actualEvent: EventDto): EventDto {
  actualEvent.id = templateEvent.id;
  actualEvent.edition = templateEvent.edition;
  actualEvent.dateTimeRange = templateEvent.dateTimeRange;
  actualEvent.eventEventConnections = templateEvent.eventEventConnections;
  actualEvent.status = templateEvent.status;
  actualEvent.links = templateEvent.links;
  actualEvent.archivedLinks = templateEvent.archivedLinks;
  // clear out Attachments/Anlage, when added
  return actualEvent;
}
