/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';

/**
 * 
 * @export
 * @interface DiscountDto
 */
export interface DiscountDto {
    /**
     * 
     * @type {SectionReferenceDto}
     * @memberof DiscountDto
     */
    section: SectionReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    reason: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    discountLevel: DiscountDtoDiscountLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    discountType?: DiscountDtoDiscountTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DiscountDto
     */
    discountPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof DiscountDto
     */
    discountAmount?: number;
}


/**
 * @export
 */
export const DiscountDtoDiscountLevelEnum = {
    PAYMENT_EXEMPTION: 'PAYMENT_EXEMPTION',
    CUSTOM: 'CUSTOM'
} as const;
export type DiscountDtoDiscountLevelEnum = typeof DiscountDtoDiscountLevelEnum[keyof typeof DiscountDtoDiscountLevelEnum];

/**
 * @export
 */
export const DiscountDtoDiscountTypeEnum = {
    PERCENTAGE: 'PERCENTAGE',
    FIXED_AMOUNT: 'FIXED_AMOUNT'
} as const;
export type DiscountDtoDiscountTypeEnum = typeof DiscountDtoDiscountTypeEnum[keyof typeof DiscountDtoDiscountTypeEnum];


/**
 * Check if a given object implements the DiscountDto interface.
 */
export function instanceOfDiscountDto(value: object): boolean {
    if (!('section' in value)) return false;
    if (!('reason' in value)) return false;
    if (!('discountLevel' in value)) return false;
    return true;
}

export function DiscountDtoFromJSON(json: any): DiscountDto {
    return DiscountDtoFromJSONTyped(json, false);
}

export function DiscountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountDto {
    if (json == null) {
        return json;
    }
    return {
        
        'section': SectionReferenceDtoFromJSON(json['section']),
        'reason': json['reason'],
        'discountLevel': json['discountLevel'],
        'discountType': json['discountType'] == null ? undefined : json['discountType'],
        'discountPercentage': json['discountPercentage'] == null ? undefined : json['discountPercentage'],
        'discountAmount': json['discountAmount'] == null ? undefined : json['discountAmount'],
    };
}

export function DiscountDtoToJSON(value?: DiscountDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'section': SectionReferenceDtoToJSON(value['section']),
        'reason': value['reason'],
        'discountLevel': value['discountLevel'],
        'discountType': value['discountType'],
        'discountPercentage': value['discountPercentage'],
        'discountAmount': value['discountAmount'],
    };
}

