import { accordionAnatomy } from '@chakra-ui/anatomy';
import { MultiStyleConfig, PartsStyleFunction, PartsStyleObject } from '@chakra-ui/theme-tools';

const variantSimple: PartsStyleFunction<typeof accordionAnatomy> = () => {
  return {
    container: {
      borderWidth: '0',
      bg: 'layer.01',
      borderRadius: 'base',
      mb: 6,
      _last: {
        mb: 0,
        borderBottomWidth: 0,
      },
    },
    button: {
      bg: 'layer.02',
      color: 'text.interactive',
      fontWeight: 'semibold',
      justifyContent: 'space-between',
      textAlign: 'left',
      borderWidth: '1px',
      borderColor: 'border.01',
      borderTopRadius: 'base',
      px: 6,
      _hover: {
        bg: 'layer.02.hover',
        color: 'text.interactive.hover',
      },
      '&[aria-expanded="false"]': {
        borderBottomRadius: 'base',
      },
    },
    panel: {
      borderWidth: '1px',
      borderTopWidth: '0',
      borderColor: 'border.01',
      px: 6,
      pt: 4,
      pb: 8,
      borderBottomRadius: 'base',
    },
  };
};

const variants = {
  simple: variantSimple,
  unstyled: {},
};

const defaultProps = {
  variant: 'simple',
};

const baseStyle: PartsStyleObject<typeof accordionAnatomy> = {
  root: {
    // lh, jaj: Fixes bug where collapsable truncate components like menu lists inside accordions.
    '& .chakra-collapse': {
      overflow: 'initial !important',
    },
  },
};

const Accordion: MultiStyleConfig<typeof accordionAnatomy> = {
  parts: accordionAnatomy.keys,
  defaultProps,
  variants,
  baseStyle,
};

export default Accordion;
