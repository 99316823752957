import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';

export const sectionRoutes: () => (BreadcrumbRouteObject & HelmetRouteObject)[] = () => [
  {
    path: 'roles',
    handle: {
      breadcrumb: <Translate ns="permission_role" i18nKey="roles" />,
    },
    children: [
      {
        path: 'admin',
        handle: {
          breadcrumb: <Translate ns="permission_role" i18nKey="adminRole" />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./permission-role-page/permission-role-admin-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./role-viewer/admin-role-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./role-history/admin-role-history-route'),
              },
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./permission-role-editor/permission-role-admin-editor-route'),
          },
        ],
      },
      {
        path: 'new',
        lazy: () => import('./new-staff-role/new-staff-role-page-route'),
      },
      {
        path: ':roleId',
        handle: {
          breadcrumb: <Translate ns="permission_role" i18nKey="staffRole" />,
        },
        children: [
          {
            path: 'edit',
            lazy: () => import('./new-staff-role/edit-staff-role-page-route'),
          },
          {
            path: '',
            lazy: () => import('./permission-role-page/permission-role-staff-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./role-viewer/section-role-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./role-history/staff-role-history-route'),
              },
              {
                path: 'staffs',
                lazy: () => import('./role-staffs/role-staffs-route'),
              },
            ],
          },
        ],
      },
    ],
  },
];
