import { HStack, IconButton, Spacer, Stack, StackDivider, Tag, Text, useId } from '@chakra-ui/react';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { CategoryOfParticipationDto, GuestTypeDto, PersonReferenceDto } from '../../../api';
import PersonReference from '../../person/person-reference/person-reference';
import { GuestType, guestTypeOptions } from '../add-guests-to-guest-list-dialog/guest-selection-control';

export interface SelectedGuestViewModel {
  id: string;
  guestType?: GuestTypeDto;
  person?: PersonReferenceDto;
  category: CategoryOfParticipationDto;
  number?: string;
}

interface PersonOnGuestListSelectionViewerProps {
  guests: SelectedGuestViewModel[];
  onRemove: (guest: SelectedGuestViewModel) => void;
  additionalAction?: (guest: SelectedGuestViewModel) => ReactNode;
  compare: (a: SelectedGuestViewModel, b: SelectedGuestViewModel) => number;
}

export function PersonOnGuestListSelectionViewer({
  guests,
  onRemove,
  additionalAction,
  compare,
}: PersonOnGuestListSelectionViewerProps) {
  const persons: SelectedGuestViewModel[] = [];
  const dummies: SelectedGuestViewModel[] = [];
  const bodyguards: SelectedGuestViewModel[] = [];
  guests.forEach((guest) => {
    if (guest.category === CategoryOfParticipationDto.BODYGUARD) {
      bodyguards.push(guest);
    } else if (guest.person != null) {
      persons.push(guest);
    } else {
      dummies.push(guest);
    }
  });

  return (
    <Stack spacing={4}>
      <SelectedGuestsForCategoryViewer
        guests={persons}
        guestType={GuestType.PERSON}
        onRemove={onRemove}
        additionalAction={additionalAction}
        compare={compare}
      />
      <SelectedGuestsForCategoryViewer
        guests={dummies}
        guestType={GuestType.DUMMY}
        onRemove={onRemove}
        additionalAction={additionalAction}
        compare={compare}
      />
      <SelectedGuestsForCategoryViewer
        guests={bodyguards}
        guestType={GuestType.BODYGUARD}
        onRemove={onRemove}
        additionalAction={additionalAction}
        compare={compare}
      />
    </Stack>
  );
}

interface SelectedGuestsForCategoryViewerProps {
  guests: SelectedGuestViewModel[];
  guestType: GuestType;
  onRemove: (guest: SelectedGuestViewModel) => void;
  additionalAction?: (guest: SelectedGuestViewModel) => ReactNode;
  compare: (a: SelectedGuestViewModel, b: SelectedGuestViewModel) => number;
}

function SelectedGuestsForCategoryViewer({
  guestType,
  guests,
  onRemove,
  additionalAction,
  compare,
}: SelectedGuestsForCategoryViewerProps) {
  const { t: tCommon } = useTranslation('common');
  const { t } = useTranslation('guest_list');
  const id = useId();

  guests.sort(compare);

  return guests.length === 0 ? null : (
    <Stack
      borderWidth="thin"
      borderColor="border.01"
      borderRadius="base"
      divider={<StackDivider borderColor="border.01" />}
      spacing={0}
      padding={0}
      align="stretch"
    >
      {guests.map((guest, index) => {
        return (
          <HStack key={index} p={1} pl={4} spacing={1} aria-labelledby={`${id}-${index}`} role="group">
            {guest.guestType === GuestType.PERSON ? (
              <PersonReference personReference={guest.person} flipName displayNameId={`${id}-${index}`} />
            ) : (
              <>
                <FontAwesomeIcon icon={guestTypeOptions[guestType].icon} fixedWidth />
                <Text>
                  {guestType === GuestType.BODYGUARD ? t('bodyguardGuest') : guestTypeOptions[guestType].label(t)}{' '}
                  {guest.number}
                </Text>
              </>
            )}
            <Spacer />
            <Tag sx={{ hyphens: 'auto' }}>{t(`categoryOfParticipationOptions.${guest.category}`)}</Tag>
            {additionalAction?.(guest)}
            <IconButton
              size="sm"
              variant="ghost"
              colorScheme="red"
              aria-label={tCommon('action.delete')}
              onClick={() => onRemove(guest)}
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
            />
          </HStack>
        );
      })}
    </Stack>
  );
}

export function compareGuests(a: SelectedGuestViewModel, b: SelectedGuestViewModel) {
  if (
    Object.keys(CategoryOfParticipationDto).indexOf(a.category) >
    Object.keys(CategoryOfParticipationDto).indexOf(b.category)
  ) {
    return 1;
  }
  if (
    Object.keys(CategoryOfParticipationDto).indexOf(a.category) <
    Object.keys(CategoryOfParticipationDto).indexOf(b.category)
  ) {
    return -1;
  }

  if (a.number != null && b.number != null) {
    if (a.number > b.number) {
      return 1;
    }
    if (a.number < b.number) {
      return -1;
    }
  }

  if (a.person?.surname != null && b.person?.surname != null) {
    if (a.person.surname === b.person.surname) {
      invariant(a.person.firstName != null && b.person.firstName != null);
      return a.person.firstName.localeCompare(b.person.firstName);
    }
    return a.person.surname.localeCompare(b.person.surname);
  }

  return 0;
}
