/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AwardDto,
  AwardPageDto,
  BooleanDto,
  FailureDto,
  HistoryPageDto,
  PageableDto,
} from '../models/index';
import {
    AwardDtoFromJSON,
    AwardDtoToJSON,
    AwardPageDtoFromJSON,
    AwardPageDtoToJSON,
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface AwardDeletableRequest {
    id: string;
}

export interface CreateAwardRequest {
    awardDto: AwardDto;
}

export interface DeleteAwardRequest {
    id: string;
}

export interface FetchAwardRequest {
    id: string;
}

export interface FetchAwardHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface SearchAwardsRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
    editionIdentifier?: string;
}

export interface UpdateAwardRequest {
    id: string;
    awardDto: AwardDto;
}

/**
 * 
 */
export class AwardApi extends runtime.BaseAPI {

    /**
     * Get the current deletability of the award.
     */
    async awardDeletableRaw(requestParameters: AwardDeletableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling awardDeletable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/awards/{id}/can-delete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     * Get the current deletability of the award.
     */
    async awardDeletable(requestParameters: AwardDeletableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.awardDeletableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new award. The new award will be active.
     */
    async createAwardRaw(requestParameters: CreateAwardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AwardDto>> {
        if (requestParameters['awardDto'] == null) {
            throw new runtime.RequiredError(
                'awardDto',
                'Required parameter "awardDto" was null or undefined when calling createAward().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/awards`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AwardDtoToJSON(requestParameters['awardDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AwardDtoFromJSON(jsonValue));
    }

    /**
     * Create a new award. The new award will be active.
     */
    async createAward(requestParameters: CreateAwardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AwardDto> {
        const response = await this.createAwardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the award.
     */
    async deleteAwardRaw(requestParameters: DeleteAwardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteAward().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/awards/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the award.
     */
    async deleteAward(requestParameters: DeleteAwardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAwardRaw(requestParameters, initOverrides);
    }

    /**
     * Get the award business object.
     */
    async fetchAwardRaw(requestParameters: FetchAwardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AwardDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchAward().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/awards/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AwardDtoFromJSON(jsonValue));
    }

    /**
     * Get the award business object.
     */
    async fetchAward(requestParameters: FetchAwardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AwardDto> {
        const response = await this.fetchAwardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the team.
     */
    async fetchAwardHistoryRaw(requestParameters: FetchAwardHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchAwardHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/awards/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the team.
     */
    async fetchAwardHistory(requestParameters: FetchAwardHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchAwardHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for awards.
     */
    async searchAwardsRaw(requestParameters: SearchAwardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AwardPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        if (requestParameters['editionIdentifier'] != null) {
            queryParameters['editionIdentifier'] = requestParameters['editionIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/awards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AwardPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for awards.
     */
    async searchAwards(requestParameters: SearchAwardsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AwardPageDto> {
        const response = await this.searchAwardsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new award.
     */
    async templateAwardRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AwardDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/awards/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AwardDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new award.
     */
    async templateAward(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AwardDto> {
        const response = await this.templateAwardRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the existing award.
     */
    async updateAwardRaw(requestParameters: UpdateAwardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AwardDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateAward().'
            );
        }

        if (requestParameters['awardDto'] == null) {
            throw new runtime.RequiredError(
                'awardDto',
                'Required parameter "awardDto" was null or undefined when calling updateAward().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/awards/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AwardDtoToJSON(requestParameters['awardDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AwardDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing award.
     */
    async updateAward(requestParameters: UpdateAwardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AwardDto> {
        const response = await this.updateAwardRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
