/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StaffLanguageDto } from './StaffLanguageDto';
import {
    StaffLanguageDtoFromJSON,
    StaffLanguageDtoFromJSONTyped,
    StaffLanguageDtoToJSON,
} from './StaffLanguageDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface StaffPatchDto
 */
export interface StaffPatchDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof StaffPatchDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof StaffPatchDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {Array<StaffLanguageDto>}
     * @memberof StaffPatchDto
     */
    languages?: Array<StaffLanguageDto>;
    /**
     * 
     * @type {string}
     * @memberof StaffPatchDto
     */
    level?: StaffPatchDtoLevelEnum;
    /**
     * 
     * @type {number}
     * @memberof StaffPatchDto
     */
    mainGuestCount?: number;
    /**
     * 
     * @type {number}
     * @memberof StaffPatchDto
     */
    sideGuestCount?: number;
}


/**
 * @export
 */
export const StaffPatchDtoLevelEnum = {
    JUNIOR: 'JUNIOR',
    SENIOR: 'SENIOR'
} as const;
export type StaffPatchDtoLevelEnum = typeof StaffPatchDtoLevelEnum[keyof typeof StaffPatchDtoLevelEnum];


/**
 * Check if a given object implements the StaffPatchDto interface.
 */
export function instanceOfStaffPatchDto(value: object): boolean {
    return true;
}

export function StaffPatchDtoFromJSON(json: any): StaffPatchDto {
    return StaffPatchDtoFromJSONTyped(json, false);
}

export function StaffPatchDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffPatchDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'languages': json['languages'] == null ? undefined : ((json['languages'] as Array<any>).map(StaffLanguageDtoFromJSON)),
        'level': json['level'] == null ? undefined : json['level'],
        'mainGuestCount': json['mainGuestCount'] == null ? undefined : json['mainGuestCount'],
        'sideGuestCount': json['sideGuestCount'] == null ? undefined : json['sideGuestCount'],
    };
}

export function StaffPatchDtoToJSON(value?: StaffPatchDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'languages': value['languages'] == null ? undefined : ((value['languages'] as Array<any>).map(StaffLanguageDtoToJSON)),
        'level': value['level'],
        'mainGuestCount': value['mainGuestCount'],
        'sideGuestCount': value['sideGuestCount'],
    };
}

