import { Stack } from '@chakra-ui/react';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import PageForm from '../../../../ui/page/page-form';
import EmailMailingRecipientControl, {
  AddEmailRecipient,
} from '../../../mailing/mailing-recipient-editor/recipient-controls/email-mailing-recipient-control';
import PersonMailingRecipientControl, {
  AddPersonRecipient,
} from '../../../mailing/mailing-recipient-editor/recipient-controls/person-mailing-recipient-control';
import useMailingRecipientEditor from '../../../mailing/mailing-recipient-editor/use-mailing-recipient-editor';

interface EventMailingRecipientEditorProps {
  mailingId: string;
}

type FormType = AddEmailRecipient & AddPersonRecipient;

export default function UnpersonalizedGuestListInvitationMailingRecipientEditor({
  mailingId,
}: EventMailingRecipientEditorProps) {
  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const { form, onValid } = useMailingRecipientEditor<FormType>(mailingId, {
    emailRecipients: [],
    personRecipients: [],
  });

  const handleValid = async ({ emailRecipients, personRecipients }: FormType) => {
    await onValid({ recipients: [...emailRecipients, ...personRecipients] });
  };

  return (
    <FormProvider {...form}>
      <PageForm onValid={handleValid} height="full" initialFocusRef={initialFocusRef}>
        <Stack spacing={4}>
          <EmailMailingRecipientControl />
          <PersonMailingRecipientControl mailingId={mailingId} />
        </Stack>
      </PageForm>
    </FormProvider>
  );
}
