/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Audience entry / exit status
 * @export
 */
export const EventEvaluationAudienceStatusDto = {
    NO_PROBLEMS: 'NO_PROBLEMS',
    PROBLEMS: 'PROBLEMS'
} as const;
export type EventEvaluationAudienceStatusDto = typeof EventEvaluationAudienceStatusDto[keyof typeof EventEvaluationAudienceStatusDto];


export function EventEvaluationAudienceStatusDtoFromJSON(json: any): EventEvaluationAudienceStatusDto {
    return EventEvaluationAudienceStatusDtoFromJSONTyped(json, false);
}

export function EventEvaluationAudienceStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventEvaluationAudienceStatusDto {
    return json as EventEvaluationAudienceStatusDto;
}

export function EventEvaluationAudienceStatusDtoToJSON(value?: EventEvaluationAudienceStatusDto | null): any {
    return value as any;
}

