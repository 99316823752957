import { useMergeRefs } from '@chakra-ui/react';
import React from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Descendant } from 'slate';
import RichText from '../../rich-text/rich-text';
import { RichTextOptions } from '../../rich-text/rich-text-options';

export interface RichTextControlProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  name: TName;
  labelId: string;
  label?: React.ReactNode;
  isRequired?: boolean;
  maxLength?: number;
  options: RichTextOptions[];
  feedbackId?: string;
}

function RichTextControl<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  {
    name,
    labelId,
    label,
    maxLength,
    isRequired = false,
    options,
    feedbackId,
  }: RichTextControlProps<TFieldValues, TName>,
  ref: React.ForwardedRef<HTMLTextAreaElement>,
) {
  const { t } = useTranslation('common');
  const { field, fieldState } = useController({
    name,
    rules: {
      validate: (value) => {
        if (maxLength != null) {
          const totalLength = value.reduce(
            (sum: number, obj: any) =>
              sum +
              obj.children.reduce(
                (childSum: number, child: Descendant) => childSum + (child.type === 'text' ? child.text.length : 0),
                0,
              ),
            0,
          );

          if (totalLength > maxLength) return t('validation_error.max_length', { field: label, count: maxLength });
        }

        if (!isRequired) {
          return true;
        }

        return value == null ||
          !value.some((obj: any) =>
            obj.children.some((child: Descendant) => child.type === 'text' && child.text.length > 0),
          )
          ? t('validation_error.required', { field: label })
          : undefined;
      },
    },
  });

  const mergedRef = useMergeRefs(field.ref, ref);

  return (
    <RichText
      {...field}
      labelId={labelId}
      feedbackId={feedbackId}
      isInvalid={fieldState.invalid}
      ref={mergedRef}
      defaultOptions={options}
    />
  );
}

export default React.forwardRef(RichTextControl);
