import { Select, SelectProps } from '@chakra-ui/react';
import React from 'react';

interface RecordSelectProps<T extends Record<string, string>> extends Omit<SelectProps, 'children'> {
  children: (option: keyof T) => React.ReactNode;
  options: T;
}

function RecordSelect<T extends Record<string, string>>(
  { options, children, ...props }: RecordSelectProps<T>,
  ref: React.ForwardedRef<HTMLSelectElement>,
) {
  return (
    <Select {...props} ref={ref}>
      {Object.entries(options).map(([name, value]) => (
        <option key={name} value={value}>
          {children(name)}
        </option>
      ))}
    </Select>
  );
}

export default React.forwardRef(RecordSelect);
