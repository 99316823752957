import { MenuItem } from '@chakra-ui/react';
import { faDesktop, faMobile } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHref, useLocation, useResolvedPath } from 'react-router-dom';

export function SwitchToMobileButton() {
  const path = useResolvedPath('.');
  const mobilePath = { ...path, pathname: '/mobile' + path.pathname };
  const to = useHref(mobilePath);

  const { t } = useTranslation('common');

  return (
    <MenuItem as={RouterLink} to={to} icon={<FontAwesomeIcon icon={faMobile} fixedWidth />}>
      {t('action.layout.to_mobile')}
    </MenuItem>
  );
}

export function SwitchToDesktopButton() {
  const location = useLocation();
  const desktopLocation = { ...location, pathname: location.pathname.replace(/^\/mobile/, '') };
  const to = useHref(desktopLocation);

  const { t } = useTranslation('common');

  return (
    <MenuItem as={RouterLink} to={to} icon={<FontAwesomeIcon icon={faDesktop} fixedWidth />}>
      {t('action.layout.to_desktop')}
    </MenuItem>
  );
}
