/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccountingPostalAddressDto } from './AccountingPostalAddressDto';
import {
    AccountingPostalAddressDtoFromJSON,
    AccountingPostalAddressDtoFromJSONTyped,
    AccountingPostalAddressDtoToJSON,
} from './AccountingPostalAddressDto';
import type { RecipientPersonPostalAddressDto } from './RecipientPersonPostalAddressDto';
import {
    RecipientPersonPostalAddressDtoFromJSON,
    RecipientPersonPostalAddressDtoFromJSONTyped,
    RecipientPersonPostalAddressDtoToJSON,
} from './RecipientPersonPostalAddressDto';
import type { RecipientUpdateApplicationStatusDto } from './RecipientUpdateApplicationStatusDto';
import {
    RecipientUpdateApplicationStatusDtoFromJSON,
    RecipientUpdateApplicationStatusDtoFromJSONTyped,
    RecipientUpdateApplicationStatusDtoToJSON,
} from './RecipientUpdateApplicationStatusDto';
import type { RecipientUpdateApplicationTypeDto } from './RecipientUpdateApplicationTypeDto';
import {
    RecipientUpdateApplicationTypeDtoFromJSON,
    RecipientUpdateApplicationTypeDtoFromJSONTyped,
    RecipientUpdateApplicationTypeDtoToJSON,
} from './RecipientUpdateApplicationTypeDto';

/**
 * 
 * @export
 * @interface RecipientUpdateApplicationDto
 */
export interface RecipientUpdateApplicationDto {
    /**
     * 
     * @type {Date}
     * @memberof RecipientUpdateApplicationDto
     */
    applicationDate: Date;
    /**
     * 
     * @type {RecipientUpdateApplicationStatusDto}
     * @memberof RecipientUpdateApplicationDto
     */
    status: RecipientUpdateApplicationStatusDto;
    /**
     * 
     * @type {RecipientUpdateApplicationTypeDto}
     * @memberof RecipientUpdateApplicationDto
     */
    type: RecipientUpdateApplicationTypeDto;
    /**
     * 
     * @type {AccountingPostalAddressDto}
     * @memberof RecipientUpdateApplicationDto
     */
    companyAddress: AccountingPostalAddressDto;
    /**
     * 
     * @type {RecipientPersonPostalAddressDto}
     * @memberof RecipientUpdateApplicationDto
     */
    personAddress: RecipientPersonPostalAddressDto;
}

/**
 * Check if a given object implements the RecipientUpdateApplicationDto interface.
 */
export function instanceOfRecipientUpdateApplicationDto(value: object): boolean {
    if (!('applicationDate' in value)) return false;
    if (!('status' in value)) return false;
    if (!('type' in value)) return false;
    if (!('companyAddress' in value)) return false;
    if (!('personAddress' in value)) return false;
    return true;
}

export function RecipientUpdateApplicationDtoFromJSON(json: any): RecipientUpdateApplicationDto {
    return RecipientUpdateApplicationDtoFromJSONTyped(json, false);
}

export function RecipientUpdateApplicationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecipientUpdateApplicationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'applicationDate': (new Date(json['applicationDate'])),
        'status': RecipientUpdateApplicationStatusDtoFromJSON(json['status']),
        'type': RecipientUpdateApplicationTypeDtoFromJSON(json['type']),
        'companyAddress': AccountingPostalAddressDtoFromJSON(json['companyAddress']),
        'personAddress': RecipientPersonPostalAddressDtoFromJSON(json['personAddress']),
    };
}

export function RecipientUpdateApplicationDtoToJSON(value?: RecipientUpdateApplicationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'applicationDate': ((value['applicationDate']).toISOString()),
        'status': RecipientUpdateApplicationStatusDtoToJSON(value['status']),
        'type': RecipientUpdateApplicationTypeDtoToJSON(value['type']),
        'companyAddress': AccountingPostalAddressDtoToJSON(value['companyAddress']),
        'personAddress': RecipientPersonPostalAddressDtoToJSON(value['personAddress']),
    };
}

