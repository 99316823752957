/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GroupStaffBelongsToSectionsInnerDto } from './GroupStaffBelongsToSectionsInnerDto';
import {
    GroupStaffBelongsToSectionsInnerDtoFromJSON,
    GroupStaffBelongsToSectionsInnerDtoFromJSONTyped,
    GroupStaffBelongsToSectionsInnerDtoToJSON,
} from './GroupStaffBelongsToSectionsInnerDto';
import type { StaffEmailAddressDto } from './StaffEmailAddressDto';
import {
    StaffEmailAddressDtoFromJSON,
    StaffEmailAddressDtoFromJSONTyped,
    StaffEmailAddressDtoToJSON,
} from './StaffEmailAddressDto';
import type { StaffLanguageDto } from './StaffLanguageDto';
import {
    StaffLanguageDtoFromJSON,
    StaffLanguageDtoFromJSONTyped,
    StaffLanguageDtoToJSON,
} from './StaffLanguageDto';
import type { StaffPhoneNumberDto } from './StaffPhoneNumberDto';
import {
    StaffPhoneNumberDtoFromJSON,
    StaffPhoneNumberDtoFromJSONTyped,
    StaffPhoneNumberDtoToJSON,
} from './StaffPhoneNumberDto';
import type { StaffSourceDto } from './StaffSourceDto';
import {
    StaffSourceDtoFromJSON,
    StaffSourceDtoFromJSONTyped,
    StaffSourceDtoToJSON,
} from './StaffSourceDto';
import type { StaffStatusDto } from './StaffStatusDto';
import {
    StaffStatusDtoFromJSON,
    StaffStatusDtoFromJSONTyped,
    StaffStatusDtoToJSON,
} from './StaffStatusDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface StaffDto
 */
export interface StaffDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof StaffDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof StaffDto
     */
    version?: VersionDto;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof StaffDto
     */
    accountId?: string;
    /**
     * 
     * @type {string}
     * @memberof StaffDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof StaffDto
     */
    surname: string;
    /**
     * 
     * @type {StaffEmailAddressDto}
     * @memberof StaffDto
     */
    emailAddress: StaffEmailAddressDto;
    /**
     * 
     * @type {Array<GroupStaffBelongsToSectionsInnerDto>}
     * @memberof StaffDto
     */
    belongsToSections: Array<GroupStaffBelongsToSectionsInnerDto>;
    /**
     * List of phone numbers
     * @type {Array<StaffPhoneNumberDto>}
     * @memberof StaffDto
     */
    phoneNumbers: Array<StaffPhoneNumberDto>;
    /**
     * 
     * @type {string}
     * @memberof StaffDto
     */
    site?: string;
    /**
     * 
     * @type {string}
     * @memberof StaffDto
     */
    room?: string;
    /**
     * 
     * @type {Array<StaffLanguageDto>}
     * @memberof StaffDto
     */
    languages?: Array<StaffLanguageDto>;
    /**
     * 
     * @type {string}
     * @memberof StaffDto
     */
    level?: StaffDtoLevelEnum;
    /**
     * 
     * @type {number}
     * @memberof StaffDto
     */
    mainGuestCount?: number;
    /**
     * 
     * @type {number}
     * @memberof StaffDto
     */
    sideGuestCount?: number;
    /**
     * 
     * @type {StaffStatusDto}
     * @memberof StaffDto
     */
    status: StaffStatusDto;
    /**
     * 
     * @type {Date}
     * @memberof StaffDto
     */
    expiresAt?: Date;
    /**
     * 
     * @type {StaffSourceDto}
     * @memberof StaffDto
     */
    source: StaffSourceDto;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof StaffDto
     */
    extensions: { [key: string]: any; };
}


/**
 * @export
 */
export const StaffDtoLevelEnum = {
    JUNIOR: 'JUNIOR',
    SENIOR: 'SENIOR'
} as const;
export type StaffDtoLevelEnum = typeof StaffDtoLevelEnum[keyof typeof StaffDtoLevelEnum];


/**
 * Check if a given object implements the StaffDto interface.
 */
export function instanceOfStaffDto(value: object): boolean {
    if (!('firstName' in value)) return false;
    if (!('surname' in value)) return false;
    if (!('emailAddress' in value)) return false;
    if (!('belongsToSections' in value)) return false;
    if (!('phoneNumbers' in value)) return false;
    if (!('status' in value)) return false;
    if (!('source' in value)) return false;
    if (!('extensions' in value)) return false;
    return true;
}

export function StaffDtoFromJSON(json: any): StaffDto {
    return StaffDtoFromJSONTyped(json, false);
}

export function StaffDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'accountId': json['accountId'] == null ? undefined : json['accountId'],
        'firstName': json['firstName'],
        'surname': json['surname'],
        'emailAddress': StaffEmailAddressDtoFromJSON(json['emailAddress']),
        'belongsToSections': ((json['belongsToSections'] as Array<any>).map(GroupStaffBelongsToSectionsInnerDtoFromJSON)),
        'phoneNumbers': ((json['phoneNumbers'] as Array<any>).map(StaffPhoneNumberDtoFromJSON)),
        'site': json['site'] == null ? undefined : json['site'],
        'room': json['room'] == null ? undefined : json['room'],
        'languages': json['languages'] == null ? undefined : ((json['languages'] as Array<any>).map(StaffLanguageDtoFromJSON)),
        'level': json['level'] == null ? undefined : json['level'],
        'mainGuestCount': json['mainGuestCount'] == null ? undefined : json['mainGuestCount'],
        'sideGuestCount': json['sideGuestCount'] == null ? undefined : json['sideGuestCount'],
        'status': StaffStatusDtoFromJSON(json['status']),
        'expiresAt': json['expiresAt'] == null ? undefined : (new Date(json['expiresAt'])),
        'source': StaffSourceDtoFromJSON(json['source']),
        'extensions': json['extensions'],
    };
}

export function StaffDtoToJSON(value?: StaffDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'accountId': value['accountId'],
        'firstName': value['firstName'],
        'surname': value['surname'],
        'emailAddress': StaffEmailAddressDtoToJSON(value['emailAddress']),
        'belongsToSections': ((value['belongsToSections'] as Array<any>).map(GroupStaffBelongsToSectionsInnerDtoToJSON)),
        'phoneNumbers': ((value['phoneNumbers'] as Array<any>).map(StaffPhoneNumberDtoToJSON)),
        'site': value['site'],
        'room': value['room'],
        'languages': value['languages'] == null ? undefined : ((value['languages'] as Array<any>).map(StaffLanguageDtoToJSON)),
        'level': value['level'],
        'mainGuestCount': value['mainGuestCount'],
        'sideGuestCount': value['sideGuestCount'],
        'status': StaffStatusDtoToJSON(value['status']),
        'expiresAt': value['expiresAt'] == null ? undefined : ((value['expiresAt']).toISOString()),
        'source': StaffSourceDtoToJSON(value['source']),
        'extensions': value['extensions'],
    };
}

