import { useParams } from 'react-router-dom';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchUnconfirmedCompany } from '../unconfirmed-company-queries';
import { getUnconfirmedCompanyId } from './get-unconfirmed-company-id';

export default function UnconfirmedCompanyBreadcrumb() {
  const company = useFetcher(fetchUnconfirmedCompany, { id: getUnconfirmedCompanyId(useParams()) });

  return <>{company.company.name}</>;
}
