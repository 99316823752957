import { Text } from '@chakra-ui/react';
import React, { ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { StandDto, StandListItemDto } from '../../../api';
import { CustomRequestInit } from '../../../data-access/error-middleware';
import standApi from '../../../data-access/stand-api';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import useToast from '../../../ui/use-toast/use-toast';
import useDialog from '../../../util/use-dialog/use-dialog';
import StandDeleteDialog from './stand-delete-dialog';

export interface StandDeleteButtonProps extends ComponentPropsWithoutRef<'button'> {
  onSuccess?(): void;
  deletable: boolean;
  stand: StandDto | StandListItemDto;
}

const StandDeleteButton = React.forwardRef<HTMLButtonElement, StandDeleteButtonProps>(
  ({ children, stand, onSuccess, onClick, deletable, ...props }, ref) => {
    const { t } = useTranslation(['stand']);
    const [deleteDialogIsOpen, onDeleteDialogClose, openDeleteDialog] = useDialog<false>();
    const showDeleteSuccessToast = useToast({
      status: 'success',
    });

    const deleteStand = async (event: React.MouseEvent<HTMLButtonElement>) => {
      onClick?.(event);
      if (event.defaultPrevented) {
        return;
      }
      invariant(stand.id != null, 'Missing stand id');

      const checked = await openDeleteDialog();
      if (checked) {
        await standApi.deleteStandById({ id: stand.id }, {
          allowedErrorCodes: [409],
        } as CustomRequestInit);
        showDeleteSuccessToast({
          title: t('stand:toast.delete'),
          description: t('stand:toast.delete_message', {
            name: stand.name,
          }),
        });
        onSuccess?.();
      }
    };

    return (
      <>
        <DisableableButton
          {...props}
          ref={ref}
          onClick={deleteStand}
          isDisabled={!deletable}
          disableReason={<Text>{t('stand:action.deleteFailed')}</Text>}
        >
          {children}
        </DisableableButton>

        <StandDeleteDialog isOpen={deleteDialogIsOpen} stand={stand} onClose={onDeleteDialogClose} />
      </>
    );
  },
);

export default StandDeleteButton;
