/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PersonMarkerDto = {
    SERVICE_PROVIDER: 'SERVICE_PROVIDER',
    SPONSOR: 'SPONSOR',
    IFB_DELEGATES: 'IFB_DELEGATES',
    PRESS: 'PRESS',
    VIP_ARTISTIC_DIRECTOR: 'VIP_ARTISTIC_DIRECTOR',
    HOUSE_OF_REPRESENTATIVES_FOR_ENGAGEMENT_FEDERAL_AFFAIRS_AND_MEDIA: 'HOUSE_OF_REPRESENTATIVES_FOR_ENGAGEMENT_FEDERAL_AFFAIRS_AND_MEDIA',
    HOUSE_OF_REPRESENTATIVES_FOR_CULTURE_AND_EUROPE: 'HOUSE_OF_REPRESENTATIVES_FOR_CULTURE_AND_EUROPE',
    HOUSE_OF_REPRESENTATIVES_COMMITTEE: 'HOUSE_OF_REPRESENTATIVES_COMMITTEE',
    BUND: 'BUND',
    BUNDESTAG_BUDGET_COMMITTEE: 'BUNDESTAG_BUDGET_COMMITTEE',
    BUNDESTAG_CULTURE_COMMITTEE: 'BUNDESTAG_CULTURE_COMMITTEE',
    BUNDESTAG_COMMITTEE: 'BUNDESTAG_COMMITTEE',
    IFB_SELECTION_COMMITTEE: 'IFB_SELECTION_COMMITTEE',
    IFB_SELECTION_COMMITTEE_BERLINALE_SHORTS: 'IFB_SELECTION_COMMITTEE_BERLINALE_SHORTS',
    IFB_SELECTION_COMMITTEE_FORUM: 'IFB_SELECTION_COMMITTEE_FORUM',
    IFB_SELECTION_COMMITTEE_GENERATION: 'IFB_SELECTION_COMMITTEE_GENERATION',
    IFB_SELECTION_COMMITTEE_GERMAN_CINEMA_LOLA: 'IFB_SELECTION_COMMITTEE_GERMAN_CINEMA_LOLA',
    IFB_SELECTION_COMMITTEE_PANORAMA: 'IFB_SELECTION_COMMITTEE_PANORAMA',
    IFB_SELECTION_COMMITTEE_PERSPECTIVE_GERMAN_CINEMA: 'IFB_SELECTION_COMMITTEE_PERSPECTIVE_GERMAN_CINEMA',
    IFB_CONSULTANT: 'IFB_CONSULTANT',
    KBB_SUPERVISORY_BOARD: 'KBB_SUPERVISORY_BOARD',
    KBB_MANAGEMENT: 'KBB_MANAGEMENT',
    KBB_WITHOUT_IFB: 'KBB_WITHOUT_IFB',
    KBB_FORMER_EMPLOYEES: 'KBB_FORMER_EMPLOYEES',
    MEDIENBOARD_BERLIN_BRANDENBURG: 'MEDIENBOARD_BERLIN_BRANDENBURG',
    POLITICS_INTERNATIONAL: 'POLITICS_INTERNATIONAL',
    POLITICS_NATIONAL: 'POLITICS_NATIONAL'
} as const;
export type PersonMarkerDto = typeof PersonMarkerDto[keyof typeof PersonMarkerDto];


export function PersonMarkerDtoFromJSON(json: any): PersonMarkerDto {
    return PersonMarkerDtoFromJSONTyped(json, false);
}

export function PersonMarkerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonMarkerDto {
    return json as PersonMarkerDto;
}

export function PersonMarkerDtoToJSON(value?: PersonMarkerDto | null): any {
    return value as any;
}

