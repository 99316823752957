/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyReferenceDto } from './CompanyReferenceDto';
import {
    CompanyReferenceDtoFromJSON,
    CompanyReferenceDtoFromJSONTyped,
    CompanyReferenceDtoToJSON,
} from './CompanyReferenceDto';
import type { DateRangeDto } from './DateRangeDto';
import {
    DateRangeDtoFromJSON,
    DateRangeDtoFromJSONTyped,
    DateRangeDtoToJSON,
} from './DateRangeDto';
import type { FormatCompanyConnectionTypeDto } from './FormatCompanyConnectionTypeDto';
import {
    FormatCompanyConnectionTypeDtoFromJSON,
    FormatCompanyConnectionTypeDtoFromJSONTyped,
    FormatCompanyConnectionTypeDtoToJSON,
} from './FormatCompanyConnectionTypeDto';
import type { FormatCompanyStatusDto } from './FormatCompanyStatusDto';
import {
    FormatCompanyStatusDtoFromJSON,
    FormatCompanyStatusDtoFromJSONTyped,
    FormatCompanyStatusDtoToJSON,
} from './FormatCompanyStatusDto';
import type { FormatReferenceDto } from './FormatReferenceDto';
import {
    FormatReferenceDtoFromJSON,
    FormatReferenceDtoFromJSONTyped,
    FormatReferenceDtoToJSON,
} from './FormatReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * The connection between format and company
 * @export
 * @interface FormatCompanyRelationDto
 */
export interface FormatCompanyRelationDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof FormatCompanyRelationDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof FormatCompanyRelationDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {FormatReferenceDto}
     * @memberof FormatCompanyRelationDto
     */
    format: FormatReferenceDto;
    /**
     * 
     * @type {CompanyReferenceDto}
     * @memberof FormatCompanyRelationDto
     */
    company: CompanyReferenceDto;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof FormatCompanyRelationDto
     */
    validityPeriod: DateRangeDto;
    /**
     * 
     * @type {FormatCompanyConnectionTypeDto}
     * @memberof FormatCompanyRelationDto
     */
    connectionType: FormatCompanyConnectionTypeDto;
    /**
     * 
     * @type {FormatCompanyStatusDto}
     * @memberof FormatCompanyRelationDto
     */
    status: FormatCompanyStatusDto;
}

/**
 * Check if a given object implements the FormatCompanyRelationDto interface.
 */
export function instanceOfFormatCompanyRelationDto(value: object): boolean {
    if (!('format' in value)) return false;
    if (!('company' in value)) return false;
    if (!('validityPeriod' in value)) return false;
    if (!('connectionType' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function FormatCompanyRelationDtoFromJSON(json: any): FormatCompanyRelationDto {
    return FormatCompanyRelationDtoFromJSONTyped(json, false);
}

export function FormatCompanyRelationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormatCompanyRelationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'format': FormatReferenceDtoFromJSON(json['format']),
        'company': CompanyReferenceDtoFromJSON(json['company']),
        'validityPeriod': DateRangeDtoFromJSON(json['validityPeriod']),
        'connectionType': FormatCompanyConnectionTypeDtoFromJSON(json['connectionType']),
        'status': FormatCompanyStatusDtoFromJSON(json['status']),
    };
}

export function FormatCompanyRelationDtoToJSON(value?: FormatCompanyRelationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'format': FormatReferenceDtoToJSON(value['format']),
        'company': CompanyReferenceDtoToJSON(value['company']),
        'validityPeriod': DateRangeDtoToJSON(value['validityPeriod']),
        'connectionType': FormatCompanyConnectionTypeDtoToJSON(value['connectionType']),
        'status': FormatCompanyStatusDtoToJSON(value['status']),
    };
}

