/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountingRecordAggregationListItemDto
 */
export interface AccountingRecordAggregationListItemDto {
    /**
     * 
     * @type {Date}
     * @memberof AccountingRecordAggregationListItemDto
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof AccountingRecordAggregationListItemDto
     */
    debitAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountingRecordAggregationListItemDto
     */
    documentNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountingRecordAggregationListItemDto
     */
    creditAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountingRecordAggregationListItemDto
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof AccountingRecordAggregationListItemDto
     */
    grossAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountingRecordAggregationListItemDto
     */
    creditCostCenter?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountingRecordAggregationListItemDto
     */
    creditCostObject?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountingRecordAggregationListItemDto
     */
    taxType?: string;
    /**
     * 
     * @type {number}
     * @memberof AccountingRecordAggregationListItemDto
     */
    vatCode?: number;
}

/**
 * Check if a given object implements the AccountingRecordAggregationListItemDto interface.
 */
export function instanceOfAccountingRecordAggregationListItemDto(value: object): boolean {
    if (!('date' in value)) return false;
    return true;
}

export function AccountingRecordAggregationListItemDtoFromJSON(json: any): AccountingRecordAggregationListItemDto {
    return AccountingRecordAggregationListItemDtoFromJSONTyped(json, false);
}

export function AccountingRecordAggregationListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountingRecordAggregationListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'date': (new Date(json['date'])),
        'debitAccount': json['debitAccount'] == null ? undefined : json['debitAccount'],
        'documentNumber': json['documentNumber'] == null ? undefined : json['documentNumber'],
        'creditAccount': json['creditAccount'] == null ? undefined : json['creditAccount'],
        'description': json['description'] == null ? undefined : json['description'],
        'grossAmount': json['grossAmount'] == null ? undefined : json['grossAmount'],
        'creditCostCenter': json['creditCostCenter'] == null ? undefined : json['creditCostCenter'],
        'creditCostObject': json['creditCostObject'] == null ? undefined : json['creditCostObject'],
        'taxType': json['taxType'] == null ? undefined : json['taxType'],
        'vatCode': json['vatCode'] == null ? undefined : json['vatCode'],
    };
}

export function AccountingRecordAggregationListItemDtoToJSON(value?: AccountingRecordAggregationListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'date': ((value['date']).toISOString().substring(0,10)),
        'debitAccount': value['debitAccount'],
        'documentNumber': value['documentNumber'],
        'creditAccount': value['creditAccount'],
        'description': value['description'],
        'grossAmount': value['grossAmount'],
        'creditCostCenter': value['creditCostCenter'],
        'creditCostObject': value['creditCostObject'],
        'taxType': value['taxType'],
        'vatCode': value['vatCode'],
    };
}

