/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateTimeRangeDto } from './ClosedDateTimeRangeDto';
import {
    ClosedDateTimeRangeDtoFromJSON,
    ClosedDateTimeRangeDtoFromJSONTyped,
    ClosedDateTimeRangeDtoToJSON,
} from './ClosedDateTimeRangeDto';

/**
 * 
 * @export
 * @interface ServiceHoursDto
 */
export interface ServiceHoursDto {
    /**
     * 
     * @type {ClosedDateTimeRangeDto}
     * @memberof ServiceHoursDto
     */
    dateTimeRange: ClosedDateTimeRangeDto;
    /**
     * 
     * @type {string}
     * @memberof ServiceHoursDto
     */
    service: ServiceHoursDtoServiceEnum;
    /**
     * 
     * @type {string}
     * @memberof ServiceHoursDto
     */
    text?: string;
    /**
     * 
     * @type {Date}
     * @memberof ServiceHoursDto
     */
    timestamp?: Date;
}


/**
 * @export
 */
export const ServiceHoursDtoServiceEnum = {
    PUBLIC: 'PUBLIC',
    INTERNAL: 'INTERNAL'
} as const;
export type ServiceHoursDtoServiceEnum = typeof ServiceHoursDtoServiceEnum[keyof typeof ServiceHoursDtoServiceEnum];


/**
 * Check if a given object implements the ServiceHoursDto interface.
 */
export function instanceOfServiceHoursDto(value: object): boolean {
    if (!('dateTimeRange' in value)) return false;
    if (!('service' in value)) return false;
    return true;
}

export function ServiceHoursDtoFromJSON(json: any): ServiceHoursDto {
    return ServiceHoursDtoFromJSONTyped(json, false);
}

export function ServiceHoursDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceHoursDto {
    if (json == null) {
        return json;
    }
    return {
        
        'dateTimeRange': ClosedDateTimeRangeDtoFromJSON(json['dateTimeRange']),
        'service': json['service'],
        'text': json['text'] == null ? undefined : json['text'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
    };
}

export function ServiceHoursDtoToJSON(value?: ServiceHoursDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dateTimeRange': ClosedDateTimeRangeDtoToJSON(value['dateTimeRange']),
        'service': value['service'],
        'text': value['text'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
    };
}

