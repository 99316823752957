import { chakra, forwardRef, HTMLChakraProps, useCallbackRef } from '@chakra-ui/react';
import BaseQrScanner from 'qr-scanner';
import React, { useEffect, useRef } from 'react';

export function useQrScanner(qrScannerProps: Omit<QrScannerProps, 'lastScanResult'>) {
  const lastScanResult = useRef<string | null>(null);
  const resetLastScanResult = () => {
    lastScanResult.current = null;
  };

  const scanner = <QrScanner {...qrScannerProps} lastScanResult={lastScanResult} />;

  return { resetLastScanResult, scanner };
}

interface QrScannerProps extends HTMLChakraProps<'div'> {
  onDecode(result: string): void;
  lastScanResult: React.MutableRefObject<string | null>;
}

const QrScanner = forwardRef<QrScannerProps, 'div'>(({ onDecode, lastScanResult, ...props }: QrScannerProps, ref) => {
  const videoElementRef = useRef<HTMLVideoElement>(null);
  const onDecodeRef = useCallbackRef(onDecode);

  useEffect(() => {
    if (videoElementRef.current == null) {
      return;
    }

    const qrScanner = new BaseQrScanner(
      videoElementRef.current,
      (result) => {
        if (result.data !== lastScanResult.current) {
          lastScanResult.current = result.data;
          onDecodeRef(result.data);
        }
      },
      {
        // scan region must be true for highlightCodeOutline to work
        highlightScanRegion: true,
        highlightCodeOutline: true,
        maxScansPerSecond: 5,
        // scan the whole video, default is just the center 400x400
        calculateScanRegion: ({ width, height }) => ({ width, height }),
        onDecodeError: (e) => {
          if (typeof e !== 'string' || !e.includes(BaseQrScanner.NO_QR_CODE_FOUND)) {
            console.error(e);
          }
        },
      },
    );
    qrScanner.start();

    return () => qrScanner.destroy();
  }, [lastScanResult, onDecodeRef]);

  return (
    <chakra.div
      ref={ref}
      __css={{
        '.scan-region-highlight-svg': {
          display: 'none',
        },
        '.code-outline-highlight': {
          stroke: 'var(--chakra-colors-teal-500) !important',
        },
        position: 'relative',
        overflow: 'hidden',
      }}
      {...props}
    >
      <chakra.div position="absolute" top={0} left={0} width="full" height="full">
        <chakra.video ref={videoElementRef} objectFit="cover" width="full" height="full" />
      </chakra.div>
    </chakra.div>
  );
});
