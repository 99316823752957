import { Button, ButtonProps, Spinner } from '@chakra-ui/react';
import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface SubmitButtonProps extends ButtonProps {}

export default function SubmitButton({ type = 'submit', isLoading, alignSelf, ...props }: SubmitButtonProps) {
  const { t } = useTranslation('common');
  const { isSubmitting } = useFormState();

  return (
    <Button
      type={type}
      isLoading={isSubmitting || isLoading}
      spinner={<Spinner color="currentColor" width="1em" height="1em" label={t('misc.loading')} />}
      alignSelf={alignSelf ?? 'start'}
      {...props}
    />
  );
}
