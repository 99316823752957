/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  GroupTemplateDto,
  GroupTemplatePageDto,
  GroupTemplateReferenceDto,
  GroupTemplateReferencePageDto,
  GroupTemplateStatusDto,
  HistoryPageDto,
  PageableDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    GroupTemplateDtoFromJSON,
    GroupTemplateDtoToJSON,
    GroupTemplatePageDtoFromJSON,
    GroupTemplatePageDtoToJSON,
    GroupTemplateReferenceDtoFromJSON,
    GroupTemplateReferenceDtoToJSON,
    GroupTemplateReferencePageDtoFromJSON,
    GroupTemplateReferencePageDtoToJSON,
    GroupTemplateStatusDtoFromJSON,
    GroupTemplateStatusDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface CreateGroupTemplateRequest {
    groupTemplateDto: GroupTemplateDto;
}

export interface DeleteGroupTemplateByIdRequest {
    id: string;
}

export interface ExistsGroupTemplateNameRequest {
    name: string;
    id: string;
}

export interface FetchGroupTemplateRequest {
    id: string;
}

export interface FetchGroupTemplateHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface SearchGroupTemplateListItemsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchGroupTemplateReferencesRequest {
    pageable?: PageableDto;
    status?: Array<GroupTemplateStatusDto>;
    searchquery?: string;
}

export interface UpdateGroupTemplateRequest {
    id: string;
    groupTemplateDto: GroupTemplateDto;
}

/**
 * 
 */
export class GroupTemplateApi extends runtime.BaseAPI {

    /**
     * Create a new group template.
     */
    async createGroupTemplateRaw(requestParameters: CreateGroupTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['groupTemplateDto'] == null) {
            throw new runtime.RequiredError(
                'groupTemplateDto',
                'Required parameter "groupTemplateDto" was null or undefined when calling createGroupTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GroupTemplateDtoToJSON(requestParameters['groupTemplateDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new group template.
     */
    async createGroupTemplate(requestParameters: CreateGroupTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createGroupTemplateRaw(requestParameters, initOverrides);
    }

    /**
     * Delete group template by id
     */
    async deleteGroupTemplateByIdRaw(requestParameters: DeleteGroupTemplateByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteGroupTemplateById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete group template by id
     */
    async deleteGroupTemplateById(requestParameters: DeleteGroupTemplateByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteGroupTemplateByIdRaw(requestParameters, initOverrides);
    }

    /**
     */
    async existsGroupTemplateNameRaw(requestParameters: ExistsGroupTemplateNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupTemplateReferenceDto>> {
        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling existsGroupTemplateName().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling existsGroupTemplateName().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-templates/exists-name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupTemplateReferenceDtoFromJSON(jsonValue));
    }

    /**
     */
    async existsGroupTemplateName(requestParameters: ExistsGroupTemplateNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupTemplateReferenceDto | null | undefined > {
        const response = await this.existsGroupTemplateNameRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Get the group template business object.
     */
    async fetchGroupTemplateRaw(requestParameters: FetchGroupTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupTemplateDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchGroupTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupTemplateDtoFromJSON(jsonValue));
    }

    /**
     * Get the group template business object.
     */
    async fetchGroupTemplate(requestParameters: FetchGroupTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupTemplateDto> {
        const response = await this.fetchGroupTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the group template.
     */
    async fetchGroupTemplateHistoryRaw(requestParameters: FetchGroupTemplateHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchGroupTemplateHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-templates/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the group template.
     */
    async fetchGroupTemplateHistory(requestParameters: FetchGroupTemplateHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchGroupTemplateHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for group templates.
     */
    async searchGroupTemplateListItemsRaw(requestParameters: SearchGroupTemplateListItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupTemplatePageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupTemplatePageDtoFromJSON(jsonValue));
    }

    /**
     * Search for group templates.
     */
    async searchGroupTemplateListItems(requestParameters: SearchGroupTemplateListItemsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupTemplatePageDto> {
        const response = await this.searchGroupTemplateListItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for group templates.
     */
    async searchGroupTemplateReferencesRaw(requestParameters: SearchGroupTemplateReferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupTemplateReferencePageDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['searchquery'] != null) {
            queryParameters['searchquery'] = requestParameters['searchquery'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-templates/references`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupTemplateReferencePageDtoFromJSON(jsonValue));
    }

    /**
     * Search for group templates.
     */
    async searchGroupTemplateReferences(requestParameters: SearchGroupTemplateReferencesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupTemplateReferencePageDto> {
        const response = await this.searchGroupTemplateReferencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new group template.
     */
    async templateGroupTemplateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupTemplateDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-templates/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupTemplateDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new group template.
     */
    async templateGroupTemplate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupTemplateDto> {
        const response = await this.templateGroupTemplateRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the existing group template.
     */
    async updateGroupTemplateRaw(requestParameters: UpdateGroupTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateGroupTemplate().'
            );
        }

        if (requestParameters['groupTemplateDto'] == null) {
            throw new runtime.RequiredError(
                'groupTemplateDto',
                'Required parameter "groupTemplateDto" was null or undefined when calling updateGroupTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GroupTemplateDtoToJSON(requestParameters['groupTemplateDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the existing group template.
     */
    async updateGroupTemplate(requestParameters: UpdateGroupTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateGroupTemplateRaw(requestParameters, initOverrides);
    }

}
