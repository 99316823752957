import { Editor, Element, Node, NodeEntry, Transforms, Location } from 'slate';
import { isBlockActive } from './block';
import { EditorProps, FormatOption, InlineElement, InlineFormatOption } from './format-types';

export function isInlineBlockActive(editor: EditorProps, format: InlineFormatOption) {
  return isBlockActive(editor, format);
}

export function selectionIsInline(editor: EditorProps) {
  const { selection } = editor;
  if (!selection) {
    return false;
  }

  const nodesAtSelection = Array.from(Editor.nodes(editor, { at: selection }));
  return nodesAtSelection.some(([node]) => {
    if (node.type === 'editor' || node.type === 'text') {
      return false;
    }
    return Editor.isInline(editor, node);
  });
}

export function removeInline(editor: EditorProps, format: InlineFormatOption) {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === format,
  });
}

export function addInline(editor: EditorProps, format: InlineFormatOption, url: string) {
  const link: InlineElement = {
    type: format,
    url,
    children: [],
  };
  Transforms.wrapNodes(editor, link, { split: true });
}

export function updateInline(editor: EditorProps, at: Location, url: string) {
  Transforms.setNodes(editor, { url }, { at });
}

export function normalizeInline(editor: EditorProps) {
  const { normalizeNode } = editor;
  editor.normalizeNode = (entry: NodeEntry<Node>) => {
    const [node, path] = entry;

    // remove inline elements when switching to heading
    if (Element.isElement(node) && Editor.isBlock(editor, node) && node.type !== 'paragraph') {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (Element.isElement(child) && Editor.isInline(editor, child)) {
          Transforms.unwrapNodes(editor, { at: childPath });
          return;
        }
      }
    }

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry);
  };
}

export function normalizeChildLength(editor: EditorProps, maxLength: number, format: FormatOption) {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (path.length > 0 && node.type === format) {
      const text = Editor.string(editor, path);

      // Overwrite current node to have max length
      if (text.length > maxLength) {
        Transforms.insertText(editor, text.substring(0, maxLength), { at: [...path, 0] });
      }
    }

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode([node, path]);
  };
}
