/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AwardWinnerPermissionDto
 */
export interface AwardWinnerPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof AwardWinnerPermissionDto
     */
    name: AwardWinnerPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof AwardWinnerPermissionDto
     */
    action: AwardWinnerPermissionDtoActionEnum;
}


/**
 * @export
 */
export const AwardWinnerPermissionDtoNameEnum = {
    AWARD_PERSON: 'AWARD-PERSON'
} as const;
export type AwardWinnerPermissionDtoNameEnum = typeof AwardWinnerPermissionDtoNameEnum[keyof typeof AwardWinnerPermissionDtoNameEnum];

/**
 * @export
 */
export const AwardWinnerPermissionDtoActionEnum = {
    CAN_LINK_PERSON_WITH_AWARD: 'CAN_LINK_PERSON_WITH_AWARD'
} as const;
export type AwardWinnerPermissionDtoActionEnum = typeof AwardWinnerPermissionDtoActionEnum[keyof typeof AwardWinnerPermissionDtoActionEnum];


/**
 * Check if a given object implements the AwardWinnerPermissionDto interface.
 */
export function instanceOfAwardWinnerPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function AwardWinnerPermissionDtoFromJSON(json: any): AwardWinnerPermissionDto {
    return AwardWinnerPermissionDtoFromJSONTyped(json, false);
}

export function AwardWinnerPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AwardWinnerPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function AwardWinnerPermissionDtoToJSON(value?: AwardWinnerPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

