const Input = {
  variants: {
    outline: {
      field: {
        bg: 'layer.01',
        borderColor: 'border.01',
        borderRadius: 'base',
        _hover: {
          borderColor: 'border.01.hover',
        },
        _focusVisible: {
          borderColor: 'border.interactive',
          boxShadow: '0 0 0 1px var(--chakra-colors-border-interactive)',
        },
      },
    },
  },
};

export default Input;
