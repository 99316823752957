export const FileType = {
  GIF: 'image/gif',
  JPEG: 'image/jpeg',
  PDF: 'application/pdf',
  PNG: 'image/png',
  WEBP: 'image/webp',
  SVG: 'image/svg+xml',
  CSV: 'text/csv',
  WORD: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  EXCEL: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  POWERPOINT: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FileType = (typeof FileType)[keyof typeof FileType];

export const FileSuffixes: Record<FileType, string[]> = {
  [FileType.GIF]: ['.gif'],
  [FileType.JPEG]: ['.jpg', '.jpeg'],
  [FileType.PDF]: ['.pdf'],
  [FileType.PNG]: ['.png'],
  [FileType.WEBP]: ['.webp'],
  [FileType.SVG]: ['.svg'],
  [FileType.CSV]: ['.csv'],
  [FileType.WORD]: ['.docx'],
  [FileType.EXCEL]: ['.xlsx'],
  [FileType.POWERPOINT]: ['.pptx'],
};

export const KILOBYTES = 1024;
export const MEGABYTES = KILOBYTES * 1024;
export const GIGABYTES = MEGABYTES * 1024;
