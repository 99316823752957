import { ChakraProvider } from '@chakra-ui/react';
import '@ibm/plex/css/ibm-plex.css';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import i18n from './i18n';
import registry from './registry';
import theme from './theme';
import PageSpinner from './ui/page/page-spinner';
import { HistoryBackProvider } from './util/history/use-history-back';
import { PluginRegistryProvider } from './util/plugin/use-plugins';

export function App() {
  return (
    <PluginRegistryProvider value={registry}>
      <HistoryBackProvider>
        <HelmetProvider>
          <ChakraProvider theme={theme}>
            <I18nextProvider i18n={i18n()}>
              <React.Suspense fallback={<PageSpinner />}>
                <Outlet />
              </React.Suspense>
            </I18nextProvider>
          </ChakraProvider>
        </HelmetProvider>
      </HistoryBackProvider>
    </PluginRegistryProvider>
  );
}

export default App;
