/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Passport data to be shown within a visa
 * @export
 */
export const PassportDataToShowDto = {
    PASSPORT_NUMBER: 'PASSPORT_NUMBER',
    DATE_OF_ISSUE: 'DATE_OF_ISSUE',
    DATE_OF_EXPIRE: 'DATE_OF_EXPIRE',
    ISSUING_AUTHORITY: 'ISSUING_AUTHORITY'
} as const;
export type PassportDataToShowDto = typeof PassportDataToShowDto[keyof typeof PassportDataToShowDto];


export function PassportDataToShowDtoFromJSON(json: any): PassportDataToShowDto {
    return PassportDataToShowDtoFromJSONTyped(json, false);
}

export function PassportDataToShowDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PassportDataToShowDto {
    return json as PassportDataToShowDto;
}

export function PassportDataToShowDtoToJSON(value?: PassportDataToShowDto | null): any {
    return value as any;
}

