import React from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EventDto } from '../../../api';
import FormControl from '../../../ui/form/form-control';
import EditionSelect from '../../edition/edition-select/edition-select';
import useActiveEdition from '../../edition/use-active-edition/use-active-edition';

export default function EditionSelectionControl() {
  const { t } = useTranslation(['common', 'event']);
  const activeEdition = useActiveEdition();
  const { field } = useController({
    name: 'edition',
    rules: { deps: ['title', 'englishTitle'] },
    defaultValue: activeEdition,
  });

  return (
    <FormControl<EventDto>
      name="edition"
      label={t('event:edition.label')}
      isRequired
      helperText={t('event:edition.helper_text')}
    >
      <EditionSelect {...field} />
    </FormControl>
  );
}
