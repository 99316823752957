/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  HistoryPageDto,
  PageableDto,
  TeamStaffPageDto,
  TeamStaffRelationDto,
  TeamStaffRelationPostDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    TeamStaffPageDtoFromJSON,
    TeamStaffPageDtoToJSON,
    TeamStaffRelationDtoFromJSON,
    TeamStaffRelationDtoToJSON,
    TeamStaffRelationPostDtoFromJSON,
    TeamStaffRelationPostDtoToJSON,
} from '../models/index';

export interface CreateTeamStaffRelationRequest {
    teamStaffRelationPostDto: TeamStaffRelationPostDto;
}

export interface DeleteByIdsRequest {
    ids: Array<string>;
}

export interface FetchTeamStaffHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface SearchTeamStaffRelationsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

/**
 * 
 */
export class TeamStaffApi extends runtime.BaseAPI {

    /**
     * Create a team staff relation.
     */
    async createTeamStaffRelationRaw(requestParameters: CreateTeamStaffRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamStaffRelationDto>> {
        if (requestParameters['teamStaffRelationPostDto'] == null) {
            throw new runtime.RequiredError(
                'teamStaffRelationPostDto',
                'Required parameter "teamStaffRelationPostDto" was null or undefined when calling createTeamStaffRelation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/team-staff-relations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TeamStaffRelationPostDtoToJSON(requestParameters['teamStaffRelationPostDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamStaffRelationDtoFromJSON(jsonValue));
    }

    /**
     * Create a team staff relation.
     */
    async createTeamStaffRelation(requestParameters: CreateTeamStaffRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamStaffRelationDto> {
        const response = await this.createTeamStaffRelationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteByIdsRaw(requestParameters: DeleteByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['ids'] == null) {
            throw new runtime.RequiredError(
                'ids',
                'Required parameter "ids" was null or undefined when calling deleteByIds().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ids'] != null) {
            queryParameters['ids'] = requestParameters['ids']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/team-staff-relations/delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteByIds(requestParameters: DeleteByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteByIdsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async fetchTeamStaffHistoryRaw(requestParameters: FetchTeamStaffHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchTeamStaffHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/team-staff-relations/versionsByTeam/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchTeamStaffHistory(requestParameters: FetchTeamStaffHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchTeamStaffHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for team staff relations.
     */
    async searchTeamStaffRelationsRaw(requestParameters: SearchTeamStaffRelationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamStaffPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/team-staff-relations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamStaffPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for team staff relations.
     */
    async searchTeamStaffRelations(requestParameters: SearchTeamStaffRelationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamStaffPageDto> {
        const response = await this.searchTeamStaffRelationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
