/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderCancellationFeeDto } from './OrderCancellationFeeDto';
import {
    OrderCancellationFeeDtoFromJSON,
    OrderCancellationFeeDtoFromJSONTyped,
    OrderCancellationFeeDtoToJSON,
} from './OrderCancellationFeeDto';
import type { OrderProductViewerItemDto } from './OrderProductViewerItemDto';
import {
    OrderProductViewerItemDtoFromJSON,
    OrderProductViewerItemDtoFromJSONTyped,
    OrderProductViewerItemDtoToJSON,
} from './OrderProductViewerItemDto';

/**
 * 
 * @export
 * @interface OrderProductDto
 */
export interface OrderProductDto {
    /**
     * 
     * @type {OrderProductViewerItemDto}
     * @memberof OrderProductDto
     */
    product: OrderProductViewerItemDto;
    /**
     * 
     * @type {boolean}
     * @memberof OrderProductDto
     */
    cancelled?: boolean;
    /**
     * 
     * @type {OrderCancellationFeeDto}
     * @memberof OrderProductDto
     */
    cancellationFee?: OrderCancellationFeeDto;
    /**
     * 
     * @type {string}
     * @memberof OrderProductDto
     */
    reason?: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderProductDto
     */
    timestamp?: Date;
}

/**
 * Check if a given object implements the OrderProductDto interface.
 */
export function instanceOfOrderProductDto(value: object): boolean {
    if (!('product' in value)) return false;
    return true;
}

export function OrderProductDtoFromJSON(json: any): OrderProductDto {
    return OrderProductDtoFromJSONTyped(json, false);
}

export function OrderProductDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderProductDto {
    if (json == null) {
        return json;
    }
    return {
        
        'product': OrderProductViewerItemDtoFromJSON(json['product']),
        'cancelled': json['cancelled'] == null ? undefined : json['cancelled'],
        'cancellationFee': json['cancellationFee'] == null ? undefined : OrderCancellationFeeDtoFromJSON(json['cancellationFee']),
        'reason': json['reason'] == null ? undefined : json['reason'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
    };
}

export function OrderProductDtoToJSON(value?: OrderProductDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'product': OrderProductViewerItemDtoToJSON(value['product']),
        'cancelled': value['cancelled'],
        'cancellationFee': OrderCancellationFeeDtoToJSON(value['cancellationFee']),
        'reason': value['reason'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
    };
}

