/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { StandPersonRelationTypeDto } from './StandPersonRelationTypeDto';
import {
    StandPersonRelationTypeDtoFromJSON,
    StandPersonRelationTypeDtoFromJSONTyped,
    StandPersonRelationTypeDtoToJSON,
} from './StandPersonRelationTypeDto';
import type { StandReferenceDto } from './StandReferenceDto';
import {
    StandReferenceDtoFromJSON,
    StandReferenceDtoFromJSONTyped,
    StandReferenceDtoToJSON,
} from './StandReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * The relation between a stand and a person.
 * @export
 * @interface StandPersonRelationDto
 */
export interface StandPersonRelationDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof StandPersonRelationDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof StandPersonRelationDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {StandReferenceDto}
     * @memberof StandPersonRelationDto
     */
    stand: StandReferenceDto;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof StandPersonRelationDto
     */
    person: PersonReferenceDto;
    /**
     * 
     * @type {StandPersonRelationTypeDto}
     * @memberof StandPersonRelationDto
     */
    relationType: StandPersonRelationTypeDto;
}

/**
 * Check if a given object implements the StandPersonRelationDto interface.
 */
export function instanceOfStandPersonRelationDto(value: object): boolean {
    if (!('stand' in value)) return false;
    if (!('person' in value)) return false;
    if (!('relationType' in value)) return false;
    return true;
}

export function StandPersonRelationDtoFromJSON(json: any): StandPersonRelationDto {
    return StandPersonRelationDtoFromJSONTyped(json, false);
}

export function StandPersonRelationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandPersonRelationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'stand': StandReferenceDtoFromJSON(json['stand']),
        'person': PersonReferenceDtoFromJSON(json['person']),
        'relationType': StandPersonRelationTypeDtoFromJSON(json['relationType']),
    };
}

export function StandPersonRelationDtoToJSON(value?: StandPersonRelationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'stand': StandReferenceDtoToJSON(value['stand']),
        'person': PersonReferenceDtoToJSON(value['person']),
        'relationType': StandPersonRelationTypeDtoToJSON(value['relationType']),
    };
}

