/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrderCancellationFeeDto = {
    CHARGE: 'CHARGE',
    FREE: 'FREE'
} as const;
export type OrderCancellationFeeDto = typeof OrderCancellationFeeDto[keyof typeof OrderCancellationFeeDto];


export function OrderCancellationFeeDtoFromJSON(json: any): OrderCancellationFeeDto {
    return OrderCancellationFeeDtoFromJSONTyped(json, false);
}

export function OrderCancellationFeeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderCancellationFeeDto {
    return json as OrderCancellationFeeDto;
}

export function OrderCancellationFeeDtoToJSON(value?: OrderCancellationFeeDto | null): any {
    return value as any;
}

