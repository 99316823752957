import { HStack, Link, Text } from '@chakra-ui/react';
import { faLock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link as RouterLink } from 'react-router-dom';
import { AccessGroupReferenceDto } from '../../../api';
import HasPermission from '../../permission/has-permission';

interface AccessGroupReferenceProps {
  accessGroup: AccessGroupReferenceDto;
  size?: 'sm' | 'md';
  color?: string;
}

export default function AccessGroupReference({ accessGroup, color, size = 'md' }: AccessGroupReferenceProps) {
  return (
    <HasPermission necessaryPermission="ACCESS_GROUP:CAN_SEE_DETAILS" fallback={accessGroup.title}>
      <Link display="inline-block" as={RouterLink} to={'/access-groups/' + accessGroup.id} size={size} color={color}>
        <HStack spacing={1} alignItems="center" fontSize={size}>
          <FontAwesomeIcon icon={faLock} />
          <Text as="span" fontWeight="medium">
            {accessGroup.title}
          </Text>
        </HStack>
      </Link>
    </HasPermission>
  );
}
