/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LandingPageDesignBodyDto
 */
export interface LandingPageDesignBodyDto {
    /**
     * A hex color code
     * @type {string}
     * @memberof LandingPageDesignBodyDto
     */
    backgroundColor: string;
    /**
     * A hex color code
     * @type {string}
     * @memberof LandingPageDesignBodyDto
     */
    textSymbolColor: string;
    /**
     * A hex color code
     * @type {string}
     * @memberof LandingPageDesignBodyDto
     */
    buttonColor: string;
    /**
     * A hex color code
     * @type {string}
     * @memberof LandingPageDesignBodyDto
     */
    buttonHoverColor: string;
    /**
     * A hex color code
     * @type {string}
     * @memberof LandingPageDesignBodyDto
     */
    buttonActiveColor: string;
    /**
     * A hex color code
     * @type {string}
     * @memberof LandingPageDesignBodyDto
     */
    buttonTextColor: string;
    /**
     * A hex color code
     * @type {string}
     * @memberof LandingPageDesignBodyDto
     */
    buttonHoverTextColor: string;
    /**
     * A hex color code
     * @type {string}
     * @memberof LandingPageDesignBodyDto
     */
    buttonActiveTextColor: string;
    /**
     * A hex color code
     * @type {string}
     * @memberof LandingPageDesignBodyDto
     */
    moduleColor: string;
}

/**
 * Check if a given object implements the LandingPageDesignBodyDto interface.
 */
export function instanceOfLandingPageDesignBodyDto(value: object): boolean {
    if (!('backgroundColor' in value)) return false;
    if (!('textSymbolColor' in value)) return false;
    if (!('buttonColor' in value)) return false;
    if (!('buttonHoverColor' in value)) return false;
    if (!('buttonActiveColor' in value)) return false;
    if (!('buttonTextColor' in value)) return false;
    if (!('buttonHoverTextColor' in value)) return false;
    if (!('buttonActiveTextColor' in value)) return false;
    if (!('moduleColor' in value)) return false;
    return true;
}

export function LandingPageDesignBodyDtoFromJSON(json: any): LandingPageDesignBodyDto {
    return LandingPageDesignBodyDtoFromJSONTyped(json, false);
}

export function LandingPageDesignBodyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageDesignBodyDto {
    if (json == null) {
        return json;
    }
    return {
        
        'backgroundColor': json['backgroundColor'],
        'textSymbolColor': json['textSymbolColor'],
        'buttonColor': json['buttonColor'],
        'buttonHoverColor': json['buttonHoverColor'],
        'buttonActiveColor': json['buttonActiveColor'],
        'buttonTextColor': json['buttonTextColor'],
        'buttonHoverTextColor': json['buttonHoverTextColor'],
        'buttonActiveTextColor': json['buttonActiveTextColor'],
        'moduleColor': json['moduleColor'],
    };
}

export function LandingPageDesignBodyDtoToJSON(value?: LandingPageDesignBodyDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'backgroundColor': value['backgroundColor'],
        'textSymbolColor': value['textSymbolColor'],
        'buttonColor': value['buttonColor'],
        'buttonHoverColor': value['buttonHoverColor'],
        'buttonActiveColor': value['buttonActiveColor'],
        'buttonTextColor': value['buttonTextColor'],
        'buttonHoverTextColor': value['buttonHoverTextColor'],
        'buttonActiveTextColor': value['buttonActiveTextColor'],
        'moduleColor': value['moduleColor'],
    };
}

