/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BlockElementDto } from './BlockElementDto';
import {
    BlockElementDtoFromJSON,
    BlockElementDtoFromJSONTyped,
    BlockElementDtoToJSON,
} from './BlockElementDto';

/**
 * 
 * @export
 * @interface StandInvoiceDataDto
 */
export interface StandInvoiceDataDto {
    /**
     * 
     * @type {Array<BlockElementDto>}
     * @memberof StandInvoiceDataDto
     */
    comment?: Array<BlockElementDto>;
}

/**
 * Check if a given object implements the StandInvoiceDataDto interface.
 */
export function instanceOfStandInvoiceDataDto(value: object): boolean {
    return true;
}

export function StandInvoiceDataDtoFromJSON(json: any): StandInvoiceDataDto {
    return StandInvoiceDataDtoFromJSONTyped(json, false);
}

export function StandInvoiceDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandInvoiceDataDto {
    if (json == null) {
        return json;
    }
    return {
        
        'comment': json['comment'] == null ? undefined : ((json['comment'] as Array<any>).map(BlockElementDtoFromJSON)),
    };
}

export function StandInvoiceDataDtoToJSON(value?: StandInvoiceDataDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'comment': value['comment'] == null ? undefined : ((value['comment'] as Array<any>).map(BlockElementDtoToJSON)),
    };
}

