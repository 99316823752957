import { TableCellProps, TableColumnHeaderProps } from '@chakra-ui/react';
import { SystemStyleObject } from '@chakra-ui/styled-system';
import { produce } from 'immer';
import React from 'react';

export interface BaseDataTableColumn {
  key: string;
  name?: React.ReactNode;
  isNumeric?: boolean;
  cellProps?: TableCellProps;
  sticky?: boolean;
  headerCellProps?: TableColumnHeaderProps;
  bodyCellProps?: TableCellProps;
  style?: SystemStyleObject;
}

export interface ParentDataTableColumn<TData> extends BaseDataTableColumn {
  type?: 'parent';
  isSortable?: boolean;
  sortProperty?: string;
  filter?: React.ReactElement;
  filterProperty?: string;
  filterWidth?: 'md' | 'xs';
  hasTheBroom?: boolean; // broom is set automatically by table - set only if you need more than one broom column
  renderCell(data: TData, index: number): React.ReactNode;
}

export interface ChildDataTableColumn<TChildData> extends BaseDataTableColumn {
  type: 'child';
  renderChildCell(data: TChildData, index: number): React.ReactNode;
}

type DataTableColumn<TData, TChildData = undefined> =
  | ParentDataTableColumn<TData>
  | ChildDataTableColumn<TChildData>
  | false
  | null
  | undefined;

export function isDefinedDataTableColumn<TData, TChildData = undefined>(
  column: DataTableColumn<TData, TChildData>,
): column is Exclude<DataTableColumn<TData, TChildData>, false | null | undefined> {
  return column != null && column !== false;
}

export default DataTableColumn;

export function createDataTableColumn<TData>(
  column: Omit<ParentDataTableColumn<TData>, 'type'>,
): ParentDataTableColumn<TData> {
  return { type: 'parent', ...column };
}

export function createChildDataTableColumn<TChildData>(
  column: Omit<ChildDataTableColumn<TChildData>, 'type'>,
): ChildDataTableColumn<TChildData> {
  return { type: 'child', ...column };
}

export function isParentDataTableColumn<TData, TChildData = void>(
  column: DataTableColumn<TData, TChildData>,
): column is ParentDataTableColumn<TData> {
  return isDefinedDataTableColumn(column) && (column.type == null || column.type === 'parent');
}

export function isChildDataTableColumn<TData, TChildData = void>(
  column: DataTableColumn<TData, TChildData>,
): column is ChildDataTableColumn<TChildData> {
  return isDefinedDataTableColumn(column) && column.type === 'child';
}

export function isFilterableOrSortable<TData, TChildData = void>(column: DataTableColumn<TData, TChildData>) {
  return (
    isParentDataTableColumn(column) &&
    ((column as ParentDataTableColumn<TData>).isSortable || (column as ParentDataTableColumn<TData>).filter != null)
  );
}

export function addBroomToLastColumn<TData, TChildData>(columns: DataTableColumn<TData, TChildData>[]) {
  return produce(columns, (draft) => {
    const lastColumn = draft.at(-1);

    if (isParentDataTableColumn(lastColumn)) {
      lastColumn.hasTheBroom = true;
    }
  });
}
