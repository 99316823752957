/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StaffRolePermissionDto
 */
export interface StaffRolePermissionDto {
    /**
     * 
     * @type {string}
     * @memberof StaffRolePermissionDto
     */
    name: StaffRolePermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof StaffRolePermissionDto
     */
    action: StaffRolePermissionDtoActionEnum;
}


/**
 * @export
 */
export const StaffRolePermissionDtoNameEnum = {
    STAFF_ROLE: 'STAFF_ROLE'
} as const;
export type StaffRolePermissionDtoNameEnum = typeof StaffRolePermissionDtoNameEnum[keyof typeof StaffRolePermissionDtoNameEnum];

/**
 * @export
 */
export const StaffRolePermissionDtoActionEnum = {
    SEE_DETAILS_OF_OWN_ROLE: 'CAN_SEE_DETAILS_OF_OWN_ROLE',
    SEE_DETAILS_OF_OWN_SECTION: 'CAN_SEE_DETAILS_OF_OWN_SECTION',
    EDIT_OWN_SECTION: 'CAN_EDIT_OWN_SECTION',
    CREATE_OWN_SECTION: 'CAN_CREATE_OWN_SECTION',
    ASSIGN_MEMBERS_OWN_SECTION: 'CAN_ASSIGN_MEMBERS_OWN_SECTION',
    UNASSIGN_MEMBERS_OWN_SECTION: 'CAN_UNASSIGN_MEMBERS_OWN_SECTION',
    DEACTIVATE_OWN_SECTION: 'CAN_DEACTIVATE_OWN_SECTION',
    REACTIVATE_OWN_SECTION: 'CAN_REACTIVATE_OWN_SECTION',
    DELETE_OWN_SECTION: 'CAN_DELETE_OWN_SECTION',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    EDIT: 'CAN_EDIT',
    CREATE: 'CAN_CREATE',
    ASSIGN: 'CAN_ASSIGN',
    UNASSIGN: 'CAN_UNASSIGN',
    DEACTIVATE: 'CAN_DEACTIVATE',
    REACTIVATE: 'CAN_REACTIVATE',
    DELETE: 'CAN_DELETE'
} as const;
export type StaffRolePermissionDtoActionEnum = typeof StaffRolePermissionDtoActionEnum[keyof typeof StaffRolePermissionDtoActionEnum];


/**
 * Check if a given object implements the StaffRolePermissionDto interface.
 */
export function instanceOfStaffRolePermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function StaffRolePermissionDtoFromJSON(json: any): StaffRolePermissionDto {
    return StaffRolePermissionDtoFromJSONTyped(json, false);
}

export function StaffRolePermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffRolePermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function StaffRolePermissionDtoToJSON(value?: StaffRolePermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

