/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InternetAccessDto
 */
export interface InternetAccessDto {
    /**
     * 
     * @type {string}
     * @memberof InternetAccessDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InternetAccessDto
     */
    access: InternetAccessDtoAccessEnum;
    /**
     * 
     * @type {string}
     * @memberof InternetAccessDto
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof InternetAccessDto
     */
    timestamp?: Date;
}


/**
 * @export
 */
export const InternetAccessDtoAccessEnum = {
    LOGIN: 'LOGIN',
    PUBLIC: 'PUBLIC'
} as const;
export type InternetAccessDtoAccessEnum = typeof InternetAccessDtoAccessEnum[keyof typeof InternetAccessDtoAccessEnum];


/**
 * Check if a given object implements the InternetAccessDto interface.
 */
export function instanceOfInternetAccessDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('access' in value)) return false;
    return true;
}

export function InternetAccessDtoFromJSON(json: any): InternetAccessDto {
    return InternetAccessDtoFromJSONTyped(json, false);
}

export function InternetAccessDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternetAccessDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'access': json['access'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
    };
}

export function InternetAccessDtoToJSON(value?: InternetAccessDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'access': value['access'],
        'comment': value['comment'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
    };
}

