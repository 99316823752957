import { Editor } from 'slate';
import { EditorProps, MarkFormatOption } from './format-types';

export function toggleMark(editor: EditorProps, format: MarkFormatOption) {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
}

export function isMarkActive(editor: EditorProps, format: MarkFormatOption) {
  const marks = Editor.marks(editor);
  return marks != null ? marks[format] === true : false;
}
