import { faQrcode } from '@fortawesome/pro-regular-svg-icons';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import EVENT_CHILD_ROUTES from '../event/event-child-routes';
import { EVENT_MENU_ITEM, EventMenuItem } from '../event/event-page/event-page-menu';
import liveTicketingRoutes from './live-ticketing-routes';

export const liveTicketingListEventMenuItem: EventMenuItem = {
  order: 6,
  label: <Translate ns="live_ticketing" i18nKey="live_ticketing" />,
  icon: faQrcode,
  path: (eventId) => `/events/${eventId}/live-ticketing`,
};

export default function LiveTicketingPluginProvider(registry: PluginRegistry) {
  registry.register(EVENT_MENU_ITEM, liveTicketingListEventMenuItem);
  registry.register(EVENT_CHILD_ROUTES, liveTicketingRoutes);
}
