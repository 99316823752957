/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubzoneDto
 */
export interface SubzoneDto {
    /**
     * 
     * @type {string}
     * @memberof SubzoneDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SubzoneDto
     */
    capacity: number;
}

/**
 * Check if a given object implements the SubzoneDto interface.
 */
export function instanceOfSubzoneDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('capacity' in value)) return false;
    return true;
}

export function SubzoneDtoFromJSON(json: any): SubzoneDto {
    return SubzoneDtoFromJSONTyped(json, false);
}

export function SubzoneDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubzoneDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'capacity': json['capacity'],
    };
}

export function SubzoneDtoToJSON(value?: SubzoneDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'capacity': value['capacity'],
    };
}

