/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GroupDto } from './GroupDto';
import {
    GroupDtoFromJSON,
    GroupDtoFromJSONTyped,
    GroupDtoToJSON,
} from './GroupDto';
import type { GroupPersonDto } from './GroupPersonDto';
import {
    GroupPersonDtoFromJSON,
    GroupPersonDtoFromJSONTyped,
    GroupPersonDtoToJSON,
} from './GroupPersonDto';
import type { RelationTypeDto } from './RelationTypeDto';
import {
    RelationTypeDtoFromJSON,
    RelationTypeDtoFromJSONTyped,
    RelationTypeDtoToJSON,
} from './RelationTypeDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * The connection between a group and a person
 * @export
 * @interface GroupPersonRelationDto
 */
export interface GroupPersonRelationDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof GroupPersonRelationDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof GroupPersonRelationDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {GroupDto}
     * @memberof GroupPersonRelationDto
     */
    group: GroupDto;
    /**
     * 
     * @type {GroupPersonDto}
     * @memberof GroupPersonRelationDto
     */
    person: GroupPersonDto;
    /**
     * 
     * @type {RelationTypeDto}
     * @memberof GroupPersonRelationDto
     */
    relationType: RelationTypeDto;
}

/**
 * Check if a given object implements the GroupPersonRelationDto interface.
 */
export function instanceOfGroupPersonRelationDto(value: object): boolean {
    if (!('group' in value)) return false;
    if (!('person' in value)) return false;
    if (!('relationType' in value)) return false;
    return true;
}

export function GroupPersonRelationDtoFromJSON(json: any): GroupPersonRelationDto {
    return GroupPersonRelationDtoFromJSONTyped(json, false);
}

export function GroupPersonRelationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupPersonRelationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'group': GroupDtoFromJSON(json['group']),
        'person': GroupPersonDtoFromJSON(json['person']),
        'relationType': RelationTypeDtoFromJSON(json['relationType']),
    };
}

export function GroupPersonRelationDtoToJSON(value?: GroupPersonRelationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'group': GroupDtoToJSON(value['group']),
        'person': GroupPersonDtoToJSON(value['person']),
        'relationType': RelationTypeDtoToJSON(value['relationType']),
    };
}

