import { Button } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import useDialog from '../../../util/use-dialog/use-dialog';
import usePermission from '../../permission/use-permission';
import PlacementTemplateNameFormModal from '../../placement/placement-template/placement-template-editor/placement-template-name-form-modal';

interface NewPlacementButtonProps {
  isDisabled: boolean;
}

export function NewPlacementButton({ isDisabled }: NewPlacementButtonProps) {
  const { t } = useTranslation('placement_template');
  const [isOpen, onDialogClose, openDialog] = useDialog<string | false>();
  const navigate = useNavigate();
  const { hasPermission } = usePermission();
  const handleNewPlacementTemplateClick = async () => {
    const templateName = await openDialog();

    if (templateName !== false) {
      navigate({
        pathname: 'placement-templates/new',
        search: createSearchParams({ templateName }).toString(),
      });
    }
  };

  return (
    hasPermission('LOCATION:CAN_CREATE_PLACEMENT_TEMPLATE') && (
      <>
        <Button
          as={DisableableButton}
          disableReason={t('action.new_disabled')}
          variant="primary"
          onClick={handleNewPlacementTemplateClick}
          isDisabled={isDisabled}
        >
          {t('action.new')}
        </Button>
        <PlacementTemplateNameFormModal isOpen={isOpen} onClose={onDialogClose} />
      </>
    )
  );
}
