/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Color of the allocation
 * @export
 * @interface AllocationColorDto
 */
export interface AllocationColorDto {
    /**
     * The name of the color
     * @type {string}
     * @memberof AllocationColorDto
     */
    colorName: AllocationColorDtoColorNameEnum;
    /**
     * The intensity of the color
     * @type {number}
     * @memberof AllocationColorDto
     */
    intensity: number;
}


/**
 * @export
 */
export const AllocationColorDtoColorNameEnum = {
    GRAY: 'GRAY',
    RED: 'RED',
    ORANGE: 'ORANGE',
    YELLOW: 'YELLOW',
    GREEN: 'GREEN',
    BLUE: 'BLUE',
    CYAN: 'CYAN',
    PURPLE: 'PURPLE',
    PINK: 'PINK'
} as const;
export type AllocationColorDtoColorNameEnum = typeof AllocationColorDtoColorNameEnum[keyof typeof AllocationColorDtoColorNameEnum];


/**
 * Check if a given object implements the AllocationColorDto interface.
 */
export function instanceOfAllocationColorDto(value: object): boolean {
    if (!('colorName' in value)) return false;
    if (!('intensity' in value)) return false;
    return true;
}

export function AllocationColorDtoFromJSON(json: any): AllocationColorDto {
    return AllocationColorDtoFromJSONTyped(json, false);
}

export function AllocationColorDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllocationColorDto {
    if (json == null) {
        return json;
    }
    return {
        
        'colorName': json['colorName'],
        'intensity': json['intensity'],
    };
}

export function AllocationColorDtoToJSON(value?: AllocationColorDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'colorName': value['colorName'],
        'intensity': value['intensity'],
    };
}

