import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useAdminRolePermissionClassGroupExtension(): PermissionClassGroupExtension<'ADMIN_ROLE'> {
  const { t } = useTranslation('permission_role');

  return {
    name: 'ADMIN_ROLE',
    label: t('adminRole'),
    getActionLabel: (action) => t(`adminRolePermissions.permissions.${action}`),
    getAdditionalRequirement: (action) =>
      t(`adminRolePermissions.permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`adminRolePermissions.permissionInfo.${action}`, { defaultValue: '' }),
  };
}

export function useStaffRolePermissionClassGroupExtension(): PermissionClassGroupExtension<'STAFF_ROLE'> {
  const { t } = useTranslation('permission_role');

  return {
    name: 'STAFF_ROLE',
    label: t('staffRole'),
    getActionLabel: (action) => t(`staffRolePermissions.permissions.${action}`),
    getAdditionalRequirement: (action) =>
      t(`staffRolePermissions.permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`staffRolePermissions.permissionInfo.${action}`, { defaultValue: '' }),
  };
}
