import { Button, Flex, Heading, HStack, Spacer, Stack, useDisclosure } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { CompanyDtoStatusEnum } from '../../../api';
import { BreadcrumbRouteObject } from '../../../ui/breadcrumbs/breadcrumbs';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import { HelmetRouteObject } from '../../../ui/helmet/helmet-outlet';
import PageContent from '../../../ui/page/page-content';
import useFetcher from '../../../util/swr/use-fetcher';
import CompanyPage from '../../company/company-page/company-page';
import { fetchCompany } from '../../company/company-queries';
import usePermission from '../../permission/use-permission';
import CompanyStandDataTable from './company-stand-data-table';
import CompanyStandRelationEditorDialog from './company-stand-relation-editor-dialog';

const companyStandRoute: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: '',
    element: <CompanyPage />,
    children: [
      {
        path: 'stands',
        element: <CompanyStandContent />,
        handle: {
          helmet: <CompanyStandsHelmet />,
        },
      },
    ],
  },
];

export default companyStandRoute;

function CompanyStandContent() {
  const { t } = useTranslation('stand');
  const params = useParams<{ companyId: string }>();
  const { hasPermission } = usePermission();
  const companyId = params.companyId;
  invariant(companyId, 'Empty companyId');

  const company = useFetcher(fetchCompany, { id: params.companyId });

  const { isOpen: editDialogIsOpen, onOpen: onEditDialogOpen, onClose: onEditDialogClose } = useDisclosure();

  return (
    <PageContent useFullWidth>
      <Stack spacing={4} maxH="full">
        <Flex as="header">
          <HStack spacing={4}>
            <Heading size="sm" as="h4">
              {t('lister.title')}
            </Heading>
          </HStack>
          <Spacer />
          {hasPermission('COMPANY-STAND:CAN_LINK') && (
            <Button
              as={DisableableButton}
              isDisabled={company.status != CompanyDtoStatusEnum.ACTIVE}
              disableReason={t('companyRelation.companyIsNotActiveMessage')}
              variant="primary"
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
              onClick={onEditDialogOpen}
              aria-label={t('companyRelation.actions.addStand')}
            >
              {t('companyRelation.actions.addStand')}
            </Button>
          )}
        </Flex>
        <CompanyStandDataTable
          fallbackSort={[{ property: 'edition.name', direction: 'DESC' }]}
          forcedFilter={[{ property: 'company.id', operator: 'eq', value: company.id }]}
        />
        <CompanyStandRelationEditorDialog isOpen={editDialogIsOpen} onClose={onEditDialogClose} company={company} />
      </Stack>
    </PageContent>
  );
}

function CompanyStandsHelmet() {
  const { t } = useTranslation('stand');
  const params = useParams<{ companyId: string }>();
  invariant(params.companyId, 'Empty companyId');
  const company = useFetcher(fetchCompany, { id: params.companyId });

  return (
    <Helmet
      title={t('companyRelation.company.helmet', {
        companyName: company.name,
      })}
    />
  );
}
