/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryPhonePrefixDto } from './CountryPhonePrefixDto';
import {
    CountryPhonePrefixDtoFromJSON,
    CountryPhonePrefixDtoFromJSONTyped,
    CountryPhonePrefixDtoToJSON,
} from './CountryPhonePrefixDto';

/**
 * 
 * @export
 * @interface StaffPhoneNumberDto
 */
export interface StaffPhoneNumberDto {
    /**
     * 
     * @type {CountryPhonePrefixDto}
     * @memberof StaffPhoneNumberDto
     */
    countryCode: CountryPhonePrefixDto;
    /**
     * The dialing code of the phone number
     * @type {string}
     * @memberof StaffPhoneNumberDto
     */
    dialingCode?: string;
    /**
     * The number of the phone number
     * @type {string}
     * @memberof StaffPhoneNumberDto
     */
    number: string;
    /**
     * The extension of the phone number
     * @type {string}
     * @memberof StaffPhoneNumberDto
     */
    extension?: string;
    /**
     * Note for the phone number
     * @type {string}
     * @memberof StaffPhoneNumberDto
     */
    note?: string;
    /**
     * 
     * @type {Date}
     * @memberof StaffPhoneNumberDto
     */
    timestamp?: Date;
    /**
     * The label for the phone number
     * @type {string}
     * @memberof StaffPhoneNumberDto
     */
    label: StaffPhoneNumberDtoLabelEnum;
    /**
     * 
     * @type {boolean}
     * @memberof StaffPhoneNumberDto
     */
    publish: boolean;
}


/**
 * @export
 */
export const StaffPhoneNumberDtoLabelEnum = {
    OFFICE: 'OFFICE',
    FESTIVAL_MOBILE: 'FESTIVAL_MOBILE',
    MOBILE: 'MOBILE',
    OTHER: 'OTHER'
} as const;
export type StaffPhoneNumberDtoLabelEnum = typeof StaffPhoneNumberDtoLabelEnum[keyof typeof StaffPhoneNumberDtoLabelEnum];


/**
 * Check if a given object implements the StaffPhoneNumberDto interface.
 */
export function instanceOfStaffPhoneNumberDto(value: object): boolean {
    if (!('countryCode' in value)) return false;
    if (!('number' in value)) return false;
    if (!('label' in value)) return false;
    if (!('publish' in value)) return false;
    return true;
}

export function StaffPhoneNumberDtoFromJSON(json: any): StaffPhoneNumberDto {
    return StaffPhoneNumberDtoFromJSONTyped(json, false);
}

export function StaffPhoneNumberDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffPhoneNumberDto {
    if (json == null) {
        return json;
    }
    return {
        
        'countryCode': CountryPhonePrefixDtoFromJSON(json['countryCode']),
        'dialingCode': json['dialingCode'] == null ? undefined : json['dialingCode'],
        'number': json['number'],
        'extension': json['extension'] == null ? undefined : json['extension'],
        'note': json['note'] == null ? undefined : json['note'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
        'label': json['label'],
        'publish': json['publish'],
    };
}

export function StaffPhoneNumberDtoToJSON(value?: StaffPhoneNumberDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'countryCode': CountryPhonePrefixDtoToJSON(value['countryCode']),
        'dialingCode': value['dialingCode'],
        'number': value['number'],
        'extension': value['extension'],
        'note': value['note'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
        'label': value['label'],
        'publish': value['publish'],
    };
}

