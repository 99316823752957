import { values } from 'lodash-es';
import { LandingPageTemplateDto, RichTextOptionsDto } from '../../../../api';
import RichTextOptionsControl from '../../../../ui/form/rich-text-options-control/rich-text-options-control';

interface TextModuleControlProps {
  name: `moduleSettings.${number}`;
}

export default function TextModuleControl({ name }: TextModuleControlProps) {
  return (
    <RichTextOptionsControl<LandingPageTemplateDto> name={`${name}.options`} options={values(RichTextOptionsDto)} />
  );
}
