import React from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchProductBundle } from '../product-bundle-queries';

export default function ProductBreadcrumb() {
  const { productBundleId } = useParams<{ productBundleId: string }>();
  invariant(productBundleId, 'Empty productId');
  const productBundle = useFetcher(fetchProductBundle, { id: productBundleId });

  return <>{productBundle.title}</>;
}
