import React from 'react';
import { useTranslation } from 'react-i18next';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import useToast from '../../../ui/use-toast/use-toast';
import useDialog from '../../../util/use-dialog/use-dialog';
import AccountConvertDialog from './account-convert-dialog';

export interface AccountConvertButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  onSuccess?(): void;
  onConvert(): Promise<void>;

  displayName: string;
  isDeletable: boolean;
}

const AccountConvertButton = React.forwardRef<HTMLButtonElement, AccountConvertButtonProps>(
  ({ children, displayName, isDeletable, onSuccess, onConvert, onClick, disabled, ...props }, ref) => {
    const { t } = useTranslation('account');
    const [convertDialogIsOpen, onConvertDialogClose, openConvertDialog] = useDialog<false>();
    const showSuccessToast = useToast({
      id: 'account-convert-success-toast',
      status: 'success',
    });

    const convertAccount = async (event: React.MouseEvent<HTMLButtonElement>) => {
      onClick?.(event);
      if (event.defaultPrevented) {
        return;
      }

      const checked = await openConvertDialog();
      if (checked) {
        await onConvert();
        showSuccessToast({
          title: t('convertToast.convert'),
          description: t('convertToast.convertMessage', {
            name: displayName,
          }),
        });
        onSuccess?.();
      }
    };

    return (
      <>
        <DisableableButton
          {...props}
          ref={ref}
          onClick={convertAccount}
          disableReason={t('convert_failed.description')}
          isDisabled={!isDeletable || disabled}
        >
          {children}
        </DisableableButton>
        <AccountConvertDialog isOpen={convertDialogIsOpen} displayName={displayName} onClose={onConvertDialogClose} />
      </>
    );
  },
);

export default AccountConvertButton;
