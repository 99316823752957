import { useMemo } from 'react';
import Builder, { BuilderItem } from '../builder/builder';
import createDefinedContext from '../context/create-defined-context/create-defined-context';

// Add brand interface to enable TypeScript to distinguish between a plugin token and a plugin builder token
export interface PluginToken<T> {
  __brand: T & 'PluginToken';
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-declaration-merging
export class PluginToken<T> {
  constructor(public readonly description: string) {}
}

// Add brand interface to enable TypeScript to distinguish between a plugin token and a plugin builder token
export interface PluginBuilderToken<T> {
  __brand: T & 'PluginBuilderToken';
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging, @typescript-eslint/no-unused-vars
export class PluginBuilderToken<T extends BuilderItem> {
  constructor(public readonly description: string) {}
}

export class PluginRegistry {
  private plugins = new Map<PluginToken<any>, any[]>();

  register<T extends BuilderItem>(token: PluginBuilderToken<T>, plugin: (builder: Builder<T>) => Builder<T>): void;
  register<T>(token: PluginToken<T>, plugin: T): void;
  register(token: PluginToken<unknown> | PluginBuilderToken<BuilderItem>, plugin: unknown): void {
    let plugins = this.plugins.get(token);

    if (plugins == null) {
      this.plugins.set(token, (plugins = []));
    }

    plugins.push(plugin);
  }

  getAll<T extends BuilderItem>(token: PluginBuilderToken<T>): ((builder: Builder<T>) => Builder<T>)[];
  getAll<T>(token: PluginToken<T>): T[];
  getAll(token: PluginToken<unknown> | PluginBuilderToken<BuilderItem>): unknown[] {
    return this.plugins.get(token) ?? [];
  }

  callAll<T extends () => any>(token: PluginToken<T>): ReturnType<T>[] {
    return this.getAll(token).map((x) => x());
  }

  build<T extends BuilderItem>(token: PluginBuilderToken<T>, builder: Builder<T>): T[] {
    return this.getAll(token).reduce((builder, plugin) => plugin(builder), builder).items;
  }
}

const [PluginRegistryProvider, usePluginRegistryContext] = createDefinedContext<PluginRegistry>('PluginRegistry');

export { PluginRegistryProvider };

export default function usePlugins<T>(token: PluginToken<T>) {
  return usePluginRegistryContext().getAll(token);
}

export function useBuilderPlugins<T extends BuilderItem>(token: PluginBuilderToken<T>, builder: Builder<T>) {
  const registry = usePluginRegistryContext();

  return useMemo(() => registry.build(token, builder), [registry, token, builder]);
}
