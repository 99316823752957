/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParticipationLinkDto
 */
export interface ParticipationLinkDto {
    /**
     * A URL restricted through uri format of open api
     * @type {string}
     * @memberof ParticipationLinkDto
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipationLinkDto
     */
    label?: ParticipationLinkDtoLabelEnum;
    /**
     * 
     * @type {Date}
     * @memberof ParticipationLinkDto
     */
    timestamp: Date;
}


/**
 * @export
 */
export const ParticipationLinkDtoLabelEnum = {
    PRESS_DOCUMENT: 'PRESS_DOCUMENT',
    LEGITIMATION: 'LEGITIMATION',
    OTHER: 'OTHER'
} as const;
export type ParticipationLinkDtoLabelEnum = typeof ParticipationLinkDtoLabelEnum[keyof typeof ParticipationLinkDtoLabelEnum];


/**
 * Check if a given object implements the ParticipationLinkDto interface.
 */
export function instanceOfParticipationLinkDto(value: object): boolean {
    if (!('url' in value)) return false;
    if (!('timestamp' in value)) return false;
    return true;
}

export function ParticipationLinkDtoFromJSON(json: any): ParticipationLinkDto {
    return ParticipationLinkDtoFromJSONTyped(json, false);
}

export function ParticipationLinkDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipationLinkDto {
    if (json == null) {
        return json;
    }
    return {
        
        'url': json['url'],
        'label': json['label'] == null ? undefined : json['label'],
        'timestamp': (new Date(json['timestamp'])),
    };
}

export function ParticipationLinkDtoToJSON(value?: ParticipationLinkDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'url': value['url'],
        'label': value['label'],
        'timestamp': ((value['timestamp']).toISOString()),
    };
}

