import React from 'react';
import invariant from 'tiny-invariant';
import { TagRelationDto } from '../../../../api';
import HistoryPage from '../../../../ui/history/history-page';
import { PageState } from '../../../../ui/pagination/use-pagination-state';
import useFetcher from '../../../../util/swr/use-fetcher';
import { StandRelationHistoryExtension } from '../../../stand/stand-relation/stand-relation-history-extension';
import { fetchTagHasRelationHistoryByRelation } from '../../tag-queries';
import useStandTagHistorySettings from './use-stand-tag-history-settings';

export const standTagHistoryExtension: StandRelationHistoryExtension = {
  content: (standId, state, onStateChanged) => (
    <StandTagHistory standId={standId} state={state} onStateChanged={onStateChanged} />
  ),
};

interface PersonAccessGroupHistoryProps {
  standId: string;
  state: PageState;
  onStateChanged: (state: PageState) => void;
}

function StandTagHistory({ standId, state, onStateChanged }: PersonAccessGroupHistoryProps) {
  const standTagHistorySettings = useStandTagHistorySettings();

  const standTagHistoryPage = useFetcher(fetchTagHasRelationHistoryByRelation, {
    id: standId,
    pageable: state,
    relationType: 'STAND',
  });

  invariant(standTagHistoryPage != null, 'Missing stand tag relation history page');

  return (
    <HistoryPage<TagRelationDto>
      page={standTagHistoryPage}
      state={state}
      onStateChange={onStateChanged}
      historyDisplaySettings={standTagHistorySettings}
      showLine={false}
    />
  );
}
