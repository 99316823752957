/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyReferenceDto } from './CompanyReferenceDto';
import {
    CompanyReferenceDtoFromJSON,
    CompanyReferenceDtoFromJSONTyped,
    CompanyReferenceDtoToJSON,
} from './CompanyReferenceDto';

/**
 * A representation of a person that is connected to the group.
 * @export
 * @interface GroupPersonDto
 */
export interface GroupPersonDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof GroupPersonDto
     */
    id?: string;
    /**
     * The unique key for a person starting with "P".
     * @type {string}
     * @memberof GroupPersonDto
     */
    personKey: string;
    /**
     * The first name of the person in latin letters
     * @type {string}
     * @memberof GroupPersonDto
     */
    firstName: string;
    /**
     * The surname of the person in latin letters
     * @type {string}
     * @memberof GroupPersonDto
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof GroupPersonDto
     */
    jobTitle?: string;
    /**
     * 
     * @type {CompanyReferenceDto}
     * @memberof GroupPersonDto
     */
    company?: CompanyReferenceDto;
}

/**
 * Check if a given object implements the GroupPersonDto interface.
 */
export function instanceOfGroupPersonDto(value: object): boolean {
    if (!('personKey' in value)) return false;
    if (!('firstName' in value)) return false;
    if (!('surname' in value)) return false;
    return true;
}

export function GroupPersonDtoFromJSON(json: any): GroupPersonDto {
    return GroupPersonDtoFromJSONTyped(json, false);
}

export function GroupPersonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupPersonDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'personKey': json['personKey'],
        'firstName': json['firstName'],
        'surname': json['surname'],
        'jobTitle': json['jobTitle'] == null ? undefined : json['jobTitle'],
        'company': json['company'] == null ? undefined : CompanyReferenceDtoFromJSON(json['company']),
    };
}

export function GroupPersonDtoToJSON(value?: GroupPersonDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'personKey': value['personKey'],
        'firstName': value['firstName'],
        'surname': value['surname'],
        'jobTitle': value['jobTitle'],
        'company': CompanyReferenceDtoToJSON(value['company']),
    };
}

