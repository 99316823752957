/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SectionEmailAddressDto
 */
export interface SectionEmailAddressDto {
    /**
     * An email address
     * @type {string}
     * @memberof SectionEmailAddressDto
     */
    email: string;
    /**
     * Can the information be published?
     * @type {boolean}
     * @memberof SectionEmailAddressDto
     */
    publish?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof SectionEmailAddressDto
     */
    timestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof SectionEmailAddressDto
     */
    name?: string;
}

/**
 * Check if a given object implements the SectionEmailAddressDto interface.
 */
export function instanceOfSectionEmailAddressDto(value: object): boolean {
    if (!('email' in value)) return false;
    return true;
}

export function SectionEmailAddressDtoFromJSON(json: any): SectionEmailAddressDto {
    return SectionEmailAddressDtoFromJSONTyped(json, false);
}

export function SectionEmailAddressDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionEmailAddressDto {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'publish': json['publish'] == null ? undefined : json['publish'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function SectionEmailAddressDtoToJSON(value?: SectionEmailAddressDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'publish': value['publish'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
        'name': value['name'],
    };
}

