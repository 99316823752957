/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateTimeRangeDto } from './ClosedDateTimeRangeDto';
import {
    ClosedDateTimeRangeDtoFromJSON,
    ClosedDateTimeRangeDtoFromJSONTyped,
    ClosedDateTimeRangeDtoToJSON,
} from './ClosedDateTimeRangeDto';

/**
 * Event reference for public pages
 * @export
 * @interface PublicEventReferenceDto
 */
export interface PublicEventReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof PublicEventReferenceDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PublicEventReferenceDto
     */
    englishTitle: string;
    /**
     * 
     * @type {ClosedDateTimeRangeDto}
     * @memberof PublicEventReferenceDto
     */
    dateTimeRange: ClosedDateTimeRangeDto;
}

/**
 * Check if a given object implements the PublicEventReferenceDto interface.
 */
export function instanceOfPublicEventReferenceDto(value: object): boolean {
    if (!('title' in value)) return false;
    if (!('englishTitle' in value)) return false;
    if (!('dateTimeRange' in value)) return false;
    return true;
}

export function PublicEventReferenceDtoFromJSON(json: any): PublicEventReferenceDto {
    return PublicEventReferenceDtoFromJSONTyped(json, false);
}

export function PublicEventReferenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicEventReferenceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'englishTitle': json['englishTitle'],
        'dateTimeRange': ClosedDateTimeRangeDtoFromJSON(json['dateTimeRange']),
    };
}

export function PublicEventReferenceDtoToJSON(value?: PublicEventReferenceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'englishTitle': value['englishTitle'],
        'dateTimeRange': ClosedDateTimeRangeDtoToJSON(value['dateTimeRange']),
    };
}

