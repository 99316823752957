import { ReactNode } from 'react';
import { PageState } from '../../../ui/pagination/use-pagination-state';
import { PluginToken } from '../../../util/plugin/use-plugins';

export interface AwardWinnerHistoryExtension {
  content: (awardId: string, state: PageState, onStageChanged: (state: PageState) => void) => ReactNode;
}

export const AWARD_WINNER_HISTORY_EXTENSION = new PluginToken<AwardWinnerHistoryExtension>(
  'AwardWinnerHistoryExtension',
);
