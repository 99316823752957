/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryPhonePrefixDto } from './CountryPhonePrefixDto';
import {
    CountryPhonePrefixDtoFromJSON,
    CountryPhonePrefixDtoFromJSONTyped,
    CountryPhonePrefixDtoToJSON,
} from './CountryPhonePrefixDto';

/**
 * 
 * @export
 * @interface PhoneNumberDto
 */
export interface PhoneNumberDto {
    /**
     * 
     * @type {CountryPhonePrefixDto}
     * @memberof PhoneNumberDto
     */
    countryCode: CountryPhonePrefixDto;
    /**
     * The dialing code of the phone number
     * @type {string}
     * @memberof PhoneNumberDto
     */
    dialingCode?: string;
    /**
     * The number of the phone number
     * @type {string}
     * @memberof PhoneNumberDto
     */
    number: string;
    /**
     * The extension of the phone number
     * @type {string}
     * @memberof PhoneNumberDto
     */
    extension?: string;
    /**
     * Note for the phone number
     * @type {string}
     * @memberof PhoneNumberDto
     */
    note?: string;
    /**
     * 
     * @type {Date}
     * @memberof PhoneNumberDto
     */
    timestamp?: Date;
    /**
     * The label for the phone number
     * @type {string}
     * @memberof PhoneNumberDto
     */
    label: PhoneNumberDtoLabelEnum;
    /**
     * Can the information be published?
     * @type {boolean}
     * @memberof PhoneNumberDto
     */
    publish?: boolean;
    /**
     * Are the information confidential?
     * @type {boolean}
     * @memberof PhoneNumberDto
     */
    confidential?: boolean;
}


/**
 * @export
 */
export const PhoneNumberDtoLabelEnum = {
    MOBILE: 'MOBILE',
    BUSINESS: 'BUSINESS',
    PRIVATE: 'PRIVATE',
    OTHER: 'OTHER'
} as const;
export type PhoneNumberDtoLabelEnum = typeof PhoneNumberDtoLabelEnum[keyof typeof PhoneNumberDtoLabelEnum];


/**
 * Check if a given object implements the PhoneNumberDto interface.
 */
export function instanceOfPhoneNumberDto(value: object): boolean {
    if (!('countryCode' in value)) return false;
    if (!('number' in value)) return false;
    if (!('label' in value)) return false;
    return true;
}

export function PhoneNumberDtoFromJSON(json: any): PhoneNumberDto {
    return PhoneNumberDtoFromJSONTyped(json, false);
}

export function PhoneNumberDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhoneNumberDto {
    if (json == null) {
        return json;
    }
    return {
        
        'countryCode': CountryPhonePrefixDtoFromJSON(json['countryCode']),
        'dialingCode': json['dialingCode'] == null ? undefined : json['dialingCode'],
        'number': json['number'],
        'extension': json['extension'] == null ? undefined : json['extension'],
        'note': json['note'] == null ? undefined : json['note'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
        'label': json['label'],
        'publish': json['publish'] == null ? undefined : json['publish'],
        'confidential': json['confidential'] == null ? undefined : json['confidential'],
    };
}

export function PhoneNumberDtoToJSON(value?: PhoneNumberDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'countryCode': CountryPhonePrefixDtoToJSON(value['countryCode']),
        'dialingCode': value['dialingCode'],
        'number': value['number'],
        'extension': value['extension'],
        'note': value['note'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
        'label': value['label'],
        'publish': value['publish'],
        'confidential': value['confidential'],
    };
}

