import { Checkbox, CheckboxProps, FormControl, FormErrorMessage, FormHelperText } from '@chakra-ui/react';
import React from 'react';
import { Path, useController } from 'react-hook-form';
import ErrorMessage from './error-message';

export interface CheckboxControlProps<TFieldValues> {
  name: Path<TFieldValues> | string;
  label: string;
  spacing?: CheckboxProps['spacing'];
  required?: boolean;
  helperText?: string;
  onChange?: (newValue: boolean) => void;
}

export default function CheckboxControl<TFieldValues>({
  name,
  label,
  spacing,
  required,
  helperText,
  onChange,
}: CheckboxControlProps<TFieldValues>) {
  const { field } = useController({ name, rules: { required } });

  return (
    <FormControl>
      <Checkbox
        ref={field.ref}
        onChange={(event) => {
          field.onChange(event);
          onChange?.(event.target.checked);
        }}
        onBlur={field.onBlur}
        name={field.name}
        isChecked={field.value}
        spacing={spacing}
        required={required}
      >
        {label}
      </Checkbox>
      {helperText != null && <FormHelperText mt={1}>{helperText}</FormHelperText>}
      <ErrorMessage name={name} as={FormErrorMessage} />
    </FormControl>
  );
}
