/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  KeyPlayerPageDto,
  PageableDto,
  PublishedKeyPlayersListSettingsDto,
  StringPageDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    KeyPlayerPageDtoFromJSON,
    KeyPlayerPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    PublishedKeyPlayersListSettingsDtoFromJSON,
    PublishedKeyPlayersListSettingsDtoToJSON,
    StringPageDtoFromJSON,
    StringPageDtoToJSON,
} from '../models/index';

export interface FetchPublishedKeyPlayerListRequest {
    publicationId: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface FetchPublishedKeyPlayerListSettingsRequest {
    publicationId: string;
}

export interface SearchSectionsInListRequest {
    publicationId: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

/**
 * 
 */
export class PublicKeyPlayerApi extends runtime.BaseAPI {

    /**
     * Get published entity.
     */
    async fetchPublishedKeyPlayerListRaw(requestParameters: FetchPublishedKeyPlayerListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KeyPlayerPageDto>> {
        if (requestParameters['publicationId'] == null) {
            throw new runtime.RequiredError(
                'publicationId',
                'Required parameter "publicationId" was null or undefined when calling fetchPublishedKeyPlayerList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/public/key-player-list/{publicationId}`.replace(`{${"publicationId"}}`, encodeURIComponent(String(requestParameters['publicationId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KeyPlayerPageDtoFromJSON(jsonValue));
    }

    /**
     * Get published entity.
     */
    async fetchPublishedKeyPlayerList(requestParameters: FetchPublishedKeyPlayerListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KeyPlayerPageDto> {
        const response = await this.fetchPublishedKeyPlayerListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get published entity.
     */
    async fetchPublishedKeyPlayerListSettingsRaw(requestParameters: FetchPublishedKeyPlayerListSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublishedKeyPlayersListSettingsDto>> {
        if (requestParameters['publicationId'] == null) {
            throw new runtime.RequiredError(
                'publicationId',
                'Required parameter "publicationId" was null or undefined when calling fetchPublishedKeyPlayerListSettings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/public/key-player-list/{publicationId}/settings`.replace(`{${"publicationId"}}`, encodeURIComponent(String(requestParameters['publicationId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublishedKeyPlayersListSettingsDtoFromJSON(jsonValue));
    }

    /**
     * Get published entity.
     */
    async fetchPublishedKeyPlayerListSettings(requestParameters: FetchPublishedKeyPlayerListSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublishedKeyPlayersListSettingsDto> {
        const response = await this.fetchPublishedKeyPlayerListSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for section within given publication.
     */
    async searchSectionsInListRaw(requestParameters: SearchSectionsInListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringPageDto>> {
        if (requestParameters['publicationId'] == null) {
            throw new runtime.RequiredError(
                'publicationId',
                'Required parameter "publicationId" was null or undefined when calling searchSectionsInList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/public/key-player-list/{publicationId}/sections`.replace(`{${"publicationId"}}`, encodeURIComponent(String(requestParameters['publicationId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for section within given publication.
     */
    async searchSectionsInList(requestParameters: SearchSectionsInListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringPageDto> {
        const response = await this.searchSectionsInListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
