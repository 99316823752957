/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderDto } from './OrderDto';
import {
    OrderDtoFromJSON,
    OrderDtoFromJSONTyped,
    OrderDtoToJSON,
} from './OrderDto';

/**
 * 
 * @export
 * @interface OrderSearchResultDto
 */
export interface OrderSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof OrderSearchResultDto
     */
    resultType: OrderSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {OrderDto}
     * @memberof OrderSearchResultDto
     */
    result: OrderDto;
}


/**
 * @export
 */
export const OrderSearchResultDtoResultTypeEnum = {
    ORDER: 'ORDER'
} as const;
export type OrderSearchResultDtoResultTypeEnum = typeof OrderSearchResultDtoResultTypeEnum[keyof typeof OrderSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the OrderSearchResultDto interface.
 */
export function instanceOfOrderSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function OrderSearchResultDtoFromJSON(json: any): OrderSearchResultDto {
    return OrderSearchResultDtoFromJSONTyped(json, false);
}

export function OrderSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': OrderDtoFromJSON(json['result']),
    };
}

export function OrderSearchResultDtoToJSON(value?: OrderSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': OrderDtoToJSON(value['result']),
    };
}

