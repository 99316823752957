/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BlockElementDto } from './BlockElementDto';
import {
    BlockElementDtoFromJSON,
    BlockElementDtoFromJSONTyped,
    BlockElementDtoToJSON,
} from './BlockElementDto';
import type { CancellationDateRangeDto } from './CancellationDateRangeDto';
import {
    CancellationDateRangeDtoFromJSON,
    CancellationDateRangeDtoFromJSONTyped,
    CancellationDateRangeDtoToJSON,
} from './CancellationDateRangeDto';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { OwnerDto } from './OwnerDto';
import {
    OwnerDtoFromJSON,
    OwnerDtoFromJSONTyped,
    OwnerDtoToJSON,
} from './OwnerDto';
import type { PermissionsDto } from './PermissionsDto';
import {
    PermissionsDtoFromJSON,
    PermissionsDtoFromJSONTyped,
    PermissionsDtoToJSON,
} from './PermissionsDto';
import type { ProductBundlePriceDto } from './ProductBundlePriceDto';
import {
    ProductBundlePriceDtoFromJSON,
    ProductBundlePriceDtoFromJSONTyped,
    ProductBundlePriceDtoToJSON,
} from './ProductBundlePriceDto';
import type { ProductBundleStatusDto } from './ProductBundleStatusDto';
import {
    ProductBundleStatusDtoFromJSON,
    ProductBundleStatusDtoFromJSONTyped,
    ProductBundleStatusDtoToJSON,
} from './ProductBundleStatusDto';
import type { ProductExtendedReferenceDto } from './ProductExtendedReferenceDto';
import {
    ProductExtendedReferenceDtoFromJSON,
    ProductExtendedReferenceDtoFromJSONTyped,
    ProductExtendedReferenceDtoToJSON,
} from './ProductExtendedReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * A product bundle
 * @export
 * @interface ProductBundleDto
 */
export interface ProductBundleDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof ProductBundleDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof ProductBundleDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {PermissionsDto}
     * @memberof ProductBundleDto
     */
    permissions?: PermissionsDto;
    /**
     * The unique key for a product bundle starting with "AB".
     * @type {string}
     * @memberof ProductBundleDto
     */
    articleNumber: string;
    /**
     * 
     * @type {Array<OwnerDto>}
     * @memberof ProductBundleDto
     */
    owners: Array<OwnerDto>;
    /**
     * The name of the product bundle.
     * @type {string}
     * @memberof ProductBundleDto
     */
    title: string;
    /**
     * The english name of the product bundle.
     * @type {string}
     * @memberof ProductBundleDto
     */
    titleEnglish: string;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof ProductBundleDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {Array<BlockElementDto>}
     * @memberof ProductBundleDto
     */
    description?: Array<BlockElementDto>;
    /**
     * 
     * @type {Array<BlockElementDto>}
     * @memberof ProductBundleDto
     */
    descriptionEnglish?: Array<BlockElementDto>;
    /**
     * 
     * @type {Array<ProductExtendedReferenceDto>}
     * @memberof ProductBundleDto
     */
    products?: Array<ProductExtendedReferenceDto>;
    /**
     * 
     * @type {ProductBundlePriceDto}
     * @memberof ProductBundleDto
     */
    price: ProductBundlePriceDto;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleDto
     */
    paymentTerm: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleDto
     */
    immediatePurchase?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleDto
     */
    cancellationAllowed: boolean;
    /**
     * 
     * @type {Array<CancellationDateRangeDto>}
     * @memberof ProductBundleDto
     */
    cancellationConditions: Array<CancellationDateRangeDto>;
    /**
     * 
     * @type {ProductBundleStatusDto}
     * @memberof ProductBundleDto
     */
    status: ProductBundleStatusDto;
}

/**
 * Check if a given object implements the ProductBundleDto interface.
 */
export function instanceOfProductBundleDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('articleNumber' in value)) return false;
    if (!('owners' in value)) return false;
    if (!('title' in value)) return false;
    if (!('titleEnglish' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('price' in value)) return false;
    if (!('paymentTerm' in value)) return false;
    if (!('cancellationAllowed' in value)) return false;
    if (!('cancellationConditions' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function ProductBundleDtoFromJSON(json: any): ProductBundleDto {
    return ProductBundleDtoFromJSONTyped(json, false);
}

export function ProductBundleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductBundleDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'permissions': json['permissions'] == null ? undefined : PermissionsDtoFromJSON(json['permissions']),
        'articleNumber': json['articleNumber'],
        'owners': ((json['owners'] as Array<any>).map(OwnerDtoFromJSON)),
        'title': json['title'],
        'titleEnglish': json['titleEnglish'],
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'description': json['description'] == null ? undefined : ((json['description'] as Array<any>).map(BlockElementDtoFromJSON)),
        'descriptionEnglish': json['descriptionEnglish'] == null ? undefined : ((json['descriptionEnglish'] as Array<any>).map(BlockElementDtoFromJSON)),
        'products': json['products'] == null ? undefined : ((json['products'] as Array<any>).map(ProductExtendedReferenceDtoFromJSON)),
        'price': ProductBundlePriceDtoFromJSON(json['price']),
        'paymentTerm': json['paymentTerm'],
        'immediatePurchase': json['immediatePurchase'] == null ? undefined : json['immediatePurchase'],
        'cancellationAllowed': json['cancellationAllowed'],
        'cancellationConditions': ((json['cancellationConditions'] as Array<any>).map(CancellationDateRangeDtoFromJSON)),
        'status': ProductBundleStatusDtoFromJSON(json['status']),
    };
}

export function ProductBundleDtoToJSON(value?: ProductBundleDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'permissions': PermissionsDtoToJSON(value['permissions']),
        'articleNumber': value['articleNumber'],
        'owners': ((value['owners'] as Array<any>).map(OwnerDtoToJSON)),
        'title': value['title'],
        'titleEnglish': value['titleEnglish'],
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'description': value['description'] == null ? undefined : ((value['description'] as Array<any>).map(BlockElementDtoToJSON)),
        'descriptionEnglish': value['descriptionEnglish'] == null ? undefined : ((value['descriptionEnglish'] as Array<any>).map(BlockElementDtoToJSON)),
        'products': value['products'] == null ? undefined : ((value['products'] as Array<any>).map(ProductExtendedReferenceDtoToJSON)),
        'price': ProductBundlePriceDtoToJSON(value['price']),
        'paymentTerm': value['paymentTerm'],
        'immediatePurchase': value['immediatePurchase'],
        'cancellationAllowed': value['cancellationAllowed'],
        'cancellationConditions': ((value['cancellationConditions'] as Array<any>).map(CancellationDateRangeDtoToJSON)),
        'status': ProductBundleStatusDtoToJSON(value['status']),
    };
}

