/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalCompanyAdminCreationCheckDto
 */
export interface ExternalCompanyAdminCreationCheckDto {
    /**
     * 
     * @type {boolean}
     * @memberof ExternalCompanyAdminCreationCheckDto
     */
    occupationExists: boolean;
    /**
     * 
     * @type {number}
     * @memberof ExternalCompanyAdminCreationCheckDto
     */
    numberOfExistingRelations: number;
}

/**
 * Check if a given object implements the ExternalCompanyAdminCreationCheckDto interface.
 */
export function instanceOfExternalCompanyAdminCreationCheckDto(value: object): boolean {
    if (!('occupationExists' in value)) return false;
    if (!('numberOfExistingRelations' in value)) return false;
    return true;
}

export function ExternalCompanyAdminCreationCheckDtoFromJSON(json: any): ExternalCompanyAdminCreationCheckDto {
    return ExternalCompanyAdminCreationCheckDtoFromJSONTyped(json, false);
}

export function ExternalCompanyAdminCreationCheckDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalCompanyAdminCreationCheckDto {
    if (json == null) {
        return json;
    }
    return {
        
        'occupationExists': json['occupationExists'],
        'numberOfExistingRelations': json['numberOfExistingRelations'],
    };
}

export function ExternalCompanyAdminCreationCheckDtoToJSON(value?: ExternalCompanyAdminCreationCheckDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'occupationExists': value['occupationExists'],
        'numberOfExistingRelations': value['numberOfExistingRelations'],
    };
}

