/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ParticipationSecurityTypeDto = {
    IFB: 'IFB',
    NATIONAL_AUTHORITY: 'NATIONAL_AUTHORITY',
    FOREIGN_AUTHORITY: 'FOREIGN_AUTHORITY',
    PRIVATE: 'PRIVATE'
} as const;
export type ParticipationSecurityTypeDto = typeof ParticipationSecurityTypeDto[keyof typeof ParticipationSecurityTypeDto];


export function ParticipationSecurityTypeDtoFromJSON(json: any): ParticipationSecurityTypeDto {
    return ParticipationSecurityTypeDtoFromJSONTyped(json, false);
}

export function ParticipationSecurityTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipationSecurityTypeDto {
    return json as ParticipationSecurityTypeDto;
}

export function ParticipationSecurityTypeDtoToJSON(value?: ParticipationSecurityTypeDto | null): any {
    return value as any;
}

