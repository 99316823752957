import { InputProps, useControllableState } from '@chakra-ui/react';
import { callAllHandlers } from '@chakra-ui/utils';
import { useMemo, useState } from 'react';
import invariant from 'tiny-invariant';

interface UseFormattedNumberInputProps {
  value: string | number;
  onChange(value: string | number): void;
  minFractionDigits?: number;
  maxFractionDigits?: number;
  useGrouping?: boolean;
}

export default function useFormatterNumberInput({
  value,
  onChange,
  minFractionDigits,
  maxFractionDigits,
  useGrouping,
}: UseFormattedNumberInputProps) {
  // State for switching between controlled and uncontrolled mode when focused or blurred.
  const [focused, setFocused] = useState(false);

  // Number formatter to format the field value.
  const formatter = useMemo(() => {
    const formatter = new Intl.NumberFormat('de-DE', {
      style: 'decimal',
      minimumFractionDigits: minFractionDigits,
      maximumFractionDigits: maxFractionDigits,
      useGrouping,
    });

    return (value: number) => formatter.format(value);
  }, [minFractionDigits, maxFractionDigits, useGrouping]);

  // Number parser to parse the input value.
  const parser = useMemo(() => createNumberParser('de-DE'), []);

  const formattedValue =
    value == null
      ? ''
      : typeof value === 'number'
        ? // If the field value is a number, format it with the formatter.
          formatter(value)
        : // Otherwise, display the field value as is (must be invalid).
          value;

  const [stateValue, setStateValue] = useControllableState(
    // Use uncontrolled mode when focused to allow the user to enter any value and emit the parsed value to the field.
    // Use controlled mode when blurred to always display the formatted value from the field.
    focused
      ? {
          defaultValue: formattedValue,
          onChange(numberString) {
            const number = parser(numberString);

            onChange(isNaN(number) ? numberString : number);
          },
        }
      : {
          defaultValue: formattedValue,
          value: formattedValue,
        },
  );

  return {
    getInputProps(props?: InputProps): InputProps {
      return {
        ...props,
        value: stateValue,
        onChange: callAllHandlers(props?.onChange, (event) => setStateValue(event.target.value)),
        onFocus: callAllHandlers(props?.onFocus, () => setFocused(true)),
        onBlur: callAllHandlers(props?.onBlur, () => {
          setFocused(false);

          // When the field is blurred, reset the state value to the formatted value.
          // Otherwise, useControllableState will keep the last value from the uncontrolled mode
          // and the field will jump back to this value when focused again.
          setStateValue(formattedValue);
        }),
      };
    },
  };
}

/**
 * @see https://observablehq.com/@mbostock/localized-number-parsing
 */
function createNumberParser(locale: 'de-DE') {
  const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
  const numerals = [...Intl.NumberFormat(locale, { useGrouping: false }).format(9876543210)].reverse();
  const index = new Map(numerals.map((d, i) => [d, String(i)]));

  const groupRegExp = new RegExp(`[${parts.find((d) => d.type === 'group')?.value}]`, 'g');
  const decimalRegExp = new RegExp(`[${parts.find((d) => d.type === 'decimal')?.value}]`);
  const numeralRegExp = new RegExp(`[${numerals.join('')}]`, 'g');

  return (numberString: string) => {
    let parsedNumberString = numberString.trim();

    if (parsedNumberString === '') {
      return NaN;
    }

    parsedNumberString = parsedNumberString
      .replace(groupRegExp, '')
      .replace(decimalRegExp, '.')
      .replace(numeralRegExp, (d) => {
        const numeral = index.get(d);
        invariant(numeral != null, 'Invalid numeral');

        return numeral;
      });

    return +parsedNumberString;
  };
}
