/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UnconfirmedCompanyListItemDto
 */
export interface UnconfirmedCompanyListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof UnconfirmedCompanyListItemDto
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof UnconfirmedCompanyListItemDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof UnconfirmedCompanyListItemDto
     */
    confirmed: boolean;
    /**
     * 
     * @type {number}
     * @memberof UnconfirmedCompanyListItemDto
     */
    numberOfUnconfirmedOccupations: number;
    /**
     * 
     * @type {boolean}
     * @memberof UnconfirmedCompanyListItemDto
     */
    companyAdmin: boolean;
}

/**
 * Check if a given object implements the UnconfirmedCompanyListItemDto interface.
 */
export function instanceOfUnconfirmedCompanyListItemDto(value: object): boolean {
    if (!('companyId' in value)) return false;
    if (!('name' in value)) return false;
    if (!('confirmed' in value)) return false;
    if (!('numberOfUnconfirmedOccupations' in value)) return false;
    if (!('companyAdmin' in value)) return false;
    return true;
}

export function UnconfirmedCompanyListItemDtoFromJSON(json: any): UnconfirmedCompanyListItemDto {
    return UnconfirmedCompanyListItemDtoFromJSONTyped(json, false);
}

export function UnconfirmedCompanyListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnconfirmedCompanyListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'companyId': json['companyId'],
        'name': json['name'],
        'confirmed': json['confirmed'],
        'numberOfUnconfirmedOccupations': json['numberOfUnconfirmedOccupations'],
        'companyAdmin': json['companyAdmin'],
    };
}

export function UnconfirmedCompanyListItemDtoToJSON(value?: UnconfirmedCompanyListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'companyId': value['companyId'],
        'name': value['name'],
        'confirmed': value['confirmed'],
        'numberOfUnconfirmedOccupations': value['numberOfUnconfirmedOccupations'],
        'companyAdmin': value['companyAdmin'],
    };
}

