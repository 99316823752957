import { TFunction } from 'i18next';
import { OwnerDto, OwnerTypeDto } from '../../../api';
import HistoryDisplaySettings from '../../../ui/history/history-display-settings';

export function getOwnerSettings(t: TFunction<'common'>) {
  return (): HistoryDisplaySettings<OwnerDto> => ({
    attributeLabels: {
      section: t('owners.section.label'),
      type: t('owners.type'),
    },
    valueFormatter: {
      section: (value) => value?.name,
      type: (value) => (value == null ? undefined : t(`owners.typeLabels.${value as OwnerTypeDto}`)),
    },
    diffEntireWord: {
      section: true,
      type: true,
    },
    name: 'section',
  });
}
