import { ParseKeys } from 'i18next';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import useFetcher from '../../../util/swr/use-fetcher';
import useParam from '../../../util/use-params/use-params';
import { fetchPerson } from '../../person/person-queries';

interface ParticipationHelmetProps {
  i18nKey: ParseKeys<'participation'>;
}

export default function ParticipationHelmet({ i18nKey }: ParticipationHelmetProps) {
  const { t } = useTranslation('participation');
  const person = useFetcher(fetchPerson, { id: useParam('personId') });

  return (
    <Helmet
      title={t(i18nKey, {
        firstName: person.firstName,
        surname: person.surname,
      })}
    />
  );
}
