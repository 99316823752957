import { DefaultValues, useForm } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AddRecipientsDto } from '../../../api';
import recipientApi from '../../../data-access/recipient-api';
import useToast from '../../../ui/use-toast/use-toast';
import useCallbackRef from '../../../util/use-callback-ref/use-callback-ref';
import { recipientFetcher } from '../mailing-queries';

export default function useMailingRecipientEditor<T extends FieldValues>(
  mailingId: string,
  defaultValues?: DefaultValues<T>,
) {
  const form = useForm<T>({
    mode: 'all',
    defaultValues,
  });
  const navigate = useNavigate();
  const { t } = useTranslation('mailing');
  const toast = useToast({
    status: 'success',
  });

  const handleValid = useCallbackRef(async (addRecipients: AddRecipientsDto) => {
    await recipientApi.addRecipients({ id: mailingId, addRecipientsDto: addRecipients });
    await recipientFetcher.mutate();
    navigate(`/mailings/${mailingId}/recipients`);

    toast({ description: t('recipients.toast.add') });
  });

  return { form, onValid: handleValid };
}
