/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PlacementTemplateDto } from './PlacementTemplateDto';
import {
    PlacementTemplateDtoFromJSON,
    PlacementTemplateDtoFromJSONTyped,
    PlacementTemplateDtoToJSON,
} from './PlacementTemplateDto';

/**
 * 
 * @export
 * @interface PlacementTemplatePageDto
 */
export interface PlacementTemplatePageDto {
    /**
     * 
     * @type {Array<PlacementTemplateDto>}
     * @memberof PlacementTemplatePageDto
     */
    content: Array<PlacementTemplateDto>;
}

/**
 * Check if a given object implements the PlacementTemplatePageDto interface.
 */
export function instanceOfPlacementTemplatePageDto(value: object): boolean {
    if (!('content' in value)) return false;
    return true;
}

export function PlacementTemplatePageDtoFromJSON(json: any): PlacementTemplatePageDto {
    return PlacementTemplatePageDtoFromJSONTyped(json, false);
}

export function PlacementTemplatePageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlacementTemplatePageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'content': ((json['content'] as Array<any>).map(PlacementTemplateDtoFromJSON)),
    };
}

export function PlacementTemplatePageDtoToJSON(value?: PlacementTemplatePageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'content': ((value['content'] as Array<any>).map(PlacementTemplateDtoToJSON)),
    };
}

