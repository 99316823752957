/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RichTextOptionsDto = {
    HEADLINE: 'HEADLINE',
    SUB_HEADLINE: 'SUB_HEADLINE',
    PARAGRAPH: 'PARAGRAPH',
    SMALL: 'SMALL',
    ITALIC: 'ITALIC',
    BOLD: 'BOLD',
    CITATION: 'CITATION',
    HYPERLINK: 'HYPERLINK',
    HYPERLINK_BUTTON: 'HYPERLINK_BUTTON',
    UNORDERED_LIST: 'UNORDERED_LIST',
    ORDERED_LIST: 'ORDERED_LIST',
    ALIGN_LEFT: 'ALIGN_LEFT',
    CENTER: 'CENTER',
    ALIGN_RIGHT: 'ALIGN_RIGHT',
    JUSTIFY: 'JUSTIFY'
} as const;
export type RichTextOptionsDto = typeof RichTextOptionsDto[keyof typeof RichTextOptionsDto];


export function RichTextOptionsDtoFromJSON(json: any): RichTextOptionsDto {
    return RichTextOptionsDtoFromJSONTyped(json, false);
}

export function RichTextOptionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RichTextOptionsDto {
    return json as RichTextOptionsDto;
}

export function RichTextOptionsDtoToJSON(value?: RichTextOptionsDto | null): any {
    return value as any;
}

