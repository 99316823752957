import { chakra, Flex, GridItem, HStack, Text, useId } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

/**
 * Properties for description item.
 */
export interface DescriptionItemProps {
  label: ReactNode;
  span?: number;
  children: ReactNode;
  id?: string;
  helperPopover?: React.ReactElement;
  backgroundColor?: string;
}

/**
 * Description item for components with caption in viewers and editors.
 */
export default function DescriptionItem({
  label,
  children,
  span = 1,
  id: idProp,
  helperPopover,
  backgroundColor,
}: DescriptionItemProps) {
  const id = useId(idProp, 'description-item');

  return (
    <>
      <DescriptionGridItem as={Flex} sx={{ bgColor: 'layer.02' }}>
        <HStack spacing={0} alignItems="flex-start">
          <Text fontWeight="medium" marginRight={0} id={id}>
            {label}
            {helperPopover}
          </Text>
        </HStack>
      </DescriptionGridItem>
      <DescriptionGridItem
        as={Flex}
        sx={{
          bgColor: backgroundColor ?? 'layer.01',
          w: 'full',
          gridColumn: `span ${span * 2 - 1} / span ${span * 2 - 1}`,
        }}
        aria-labelledby={id}
      >
        <chakra.div overflowWrap="anywhere" maxW="full" w="full">
          {children}
        </chakra.div>
      </DescriptionGridItem>
    </>
  );
}

const DescriptionGridItem = chakra(GridItem, {
  baseStyle: {
    px: 4,
    py: 3,
    alignItems: 'center',
    position: 'relative',
    _before: {
      content: '""',
      position: 'absolute',
      backgroundColor: 'border.01',
      zIndex: 1,
      blockSize: 'calc(100% + var(--gap))',
      inlineSize: 'var(--gap)',
      insetBlockStart: 'calc(var(--gap) * -1)',
      insetInlineStart: 'calc(var(--gap) * -1)',
    },
    _after: {
      content: '""',
      position: 'absolute',
      backgroundColor: 'border.01',
      zIndex: 1,
      blockSize: 'var(--gap)',
      inlineSize: '100%',
      insetInlineStart: '0',
      insetBlockStart: 'calc(var(--gap) * -1)',
    },
  },
});
