import React from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import CodeBreadcrumb from './common/code-breadcrumb';

const codeRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'codes',
    handle: {
      breadcrumb: <Translate ns="code" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./code-lister/code-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./code-editor/new-code-route'),
      },
      {
        path: ':codeId',
        handle: {
          breadcrumb: <CodeBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./code-page/code-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./code-viewer/code-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./code-history/code-history-route'),
              },
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./code-editor/edit-code-route'),
          },
        ],
      },
    ],
  },
];

export default codeRoutes;
