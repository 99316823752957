/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OperationDto } from './OperationDto';
import {
    OperationDtoFromJSON,
    OperationDtoFromJSONTyped,
    OperationDtoToJSON,
} from './OperationDto';
import type { OperationTypeDto } from './OperationTypeDto';
import {
    OperationTypeDtoFromJSON,
    OperationTypeDtoFromJSONTyped,
    OperationTypeDtoToJSON,
} from './OperationTypeDto';

/**
 * 
 * @export
 * @interface SortFieldOperationDto
 */
export interface SortFieldOperationDto {
    /**
     * 
     * @type {OperationTypeDto}
     * @memberof SortFieldOperationDto
     */
    operationType: OperationTypeDto;
    /**
     * 
     * @type {Array<OperationDto>}
     * @memberof SortFieldOperationDto
     */
    subOperations: Array<OperationDto>;
    /**
     * 
     * @type {string}
     * @memberof SortFieldOperationDto
     */
    direction?: SortFieldOperationDtoDirectionEnum;
}


/**
 * @export
 */
export const SortFieldOperationDtoDirectionEnum = {
    ASC: 'ASC',
    DESC: 'DESC'
} as const;
export type SortFieldOperationDtoDirectionEnum = typeof SortFieldOperationDtoDirectionEnum[keyof typeof SortFieldOperationDtoDirectionEnum];


/**
 * Check if a given object implements the SortFieldOperationDto interface.
 */
export function instanceOfSortFieldOperationDto(value: object): boolean {
    if (!('operationType' in value)) return false;
    if (!('subOperations' in value)) return false;
    return true;
}

export function SortFieldOperationDtoFromJSON(json: any): SortFieldOperationDto {
    return SortFieldOperationDtoFromJSONTyped(json, false);
}

export function SortFieldOperationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortFieldOperationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'operationType': OperationTypeDtoFromJSON(json['operationType']),
        'subOperations': ((json['subOperations'] as Array<any>).map(OperationDtoFromJSON)),
        'direction': json['direction'] == null ? undefined : json['direction'],
    };
}

export function SortFieldOperationDtoToJSON(value?: SortFieldOperationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'operationType': OperationTypeDtoToJSON(value['operationType']),
        'subOperations': ((value['subOperations'] as Array<any>).map(OperationDtoToJSON)),
        'direction': value['direction'],
    };
}

