import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import { faSmileBeam } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import ButtonHelpViewer from './button-help-viewer';
import IconHelpViewer from './icon-help-viewer';

export default function HelpViewer() {
  const { t } = useTranslation('app');

  return (
    <Accordion allowMultiple>
      <AccordionItem>
        <h2>
          <AccordionButton>
            {t('help.general_info')}
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel>
          <Stack spacing={8}>
            <Text>{t('help.general_info_desc_1')}</Text>
            <HStack>
              <Text>{t('help.general_info_desc_2')}</Text>
              <FontAwesomeIcon icon={faSmileBeam} />
            </HStack>
          </Stack>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h2>
          <AccordionButton>
            {t('help.icon.legend')}
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel>
          <IconHelpViewer />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h2>
          <AccordionButton>
            {t('help.button_info')}
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel>
          <ButtonHelpViewer />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
