/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccountingRecordListItemDto } from './AccountingRecordListItemDto';
import {
    AccountingRecordListItemDtoFromJSON,
    AccountingRecordListItemDtoFromJSONTyped,
    AccountingRecordListItemDtoToJSON,
} from './AccountingRecordListItemDto';

/**
 * 
 * @export
 * @interface AccountingRecordPageDto
 */
export interface AccountingRecordPageDto {
    /**
     * The number of the page.
     * @type {number}
     * @memberof AccountingRecordPageDto
     */
    page: number;
    /**
     * The size of the page.
     * @type {number}
     * @memberof AccountingRecordPageDto
     */
    size: number;
    /**
     * The total number of data returned by the search.
     * @type {number}
     * @memberof AccountingRecordPageDto
     */
    totalElements: number;
    /**
     * The total number of pages of current size to fit all data.
     * @type {number}
     * @memberof AccountingRecordPageDto
     */
    totalPages: number;
    /**
     * 
     * @type {Array<AccountingRecordListItemDto>}
     * @memberof AccountingRecordPageDto
     */
    content: Array<AccountingRecordListItemDto>;
}

/**
 * Check if a given object implements the AccountingRecordPageDto interface.
 */
export function instanceOfAccountingRecordPageDto(value: object): boolean {
    if (!('page' in value)) return false;
    if (!('size' in value)) return false;
    if (!('totalElements' in value)) return false;
    if (!('totalPages' in value)) return false;
    if (!('content' in value)) return false;
    return true;
}

export function AccountingRecordPageDtoFromJSON(json: any): AccountingRecordPageDto {
    return AccountingRecordPageDtoFromJSONTyped(json, false);
}

export function AccountingRecordPageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountingRecordPageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'page': json['page'],
        'size': json['size'],
        'totalElements': json['totalElements'],
        'totalPages': json['totalPages'],
        'content': ((json['content'] as Array<any>).map(AccountingRecordListItemDtoFromJSON)),
    };
}

export function AccountingRecordPageDtoToJSON(value?: AccountingRecordPageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'page': value['page'],
        'size': value['size'],
        'totalElements': value['totalElements'],
        'totalPages': value['totalPages'],
        'content': ((value['content'] as Array<any>).map(AccountingRecordListItemDtoToJSON)),
    };
}

