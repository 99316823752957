import { faH2 } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { ReactEditor } from 'slate-react';
import { isBlockActive, toggleBlock } from '../block';
import { BlockFormatOption, EditorProps, Heading2Element } from '../format-types';
import { Render } from '../render';
import RichTextButton from '../rich-text-button';
import { WithFunction } from './create-editor';

const format: BlockFormatOption = 'heading2';

const withH1: (options: { render: Render<'block'>['render'] }) => WithFunction =
  ({ render }) =>
  (editor, { paragraphPresent, onlyOneBlockOption }) => {
    const h2Action: Render<'block'> = {
      type: format,
      render,
    };
    editor.renderers = [...editor.renderers, h2Action];

    if (!onlyOneBlockOption) {
      const h2ToolbarButton = (editor: EditorProps) => (
        <RichTextButton
          key={format}
          isActive={isBlockActive(editor, format)}
          isDisabled={isBlockActive(editor, 'citation', 'citation_text', 'citation_author')}
          onClick={(event) => {
            toggleBlock<Heading2Element>(editor, format, paragraphPresent ? 'paragraph' : undefined);
            ReactEditor.focus(editor);
            event.preventDefault();
          }}
          format={format}
          icon={faH2}
        />
      );
      editor.toolbarButtons = [h2ToolbarButton, ...editor.toolbarButtons];
    }

    return editor;
  };

export default withH1;
