import { GuestListListItemDto, RecipientLinkDto, RecipientTypeDto } from '../../../api';

export type PersonOnGuestListRecipientLink = RecipientLinkDto & {
  type: typeof RecipientTypeDto.PERSON_ON_GUEST_LIST;
  linkedRecipient: GuestListListItemDto;
};

export function isPeopleOnGuestListRecipientLink(
  recipient: RecipientLinkDto,
): recipient is PersonOnGuestListRecipientLink {
  return recipient.type === RecipientTypeDto.PERSON_ON_GUEST_LIST;
}
