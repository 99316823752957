import { Fragment, ReactNode, useCallback } from 'react';
import usePlugins, { PluginToken } from '../../../../util/plugin/use-plugins';
import { SectionStaffListItemDto } from '../../../../api';
import { DataTableState } from '../../../../ui/data-table';

export type SectionStaffSelectionActionExtension = (
  selectedStaffs: SectionStaffListItemDto[],
  patchState: (patchedState: Partial<DataTableState>) => void,
) => ReactNode;

export const SECTION_STAFF_LISTER_SELECTION_ACTION_EXTENSION = new PluginToken<SectionStaffSelectionActionExtension>(
  'SectionStaffListerSelectionActionExtension',
);

export default function useSectionStaffSelectionActions() {
  const selectionActions = usePlugins(SECTION_STAFF_LISTER_SELECTION_ACTION_EXTENSION);

  return useCallback(
    (staffs: SectionStaffListItemDto[], patchState: (patchedState: Partial<DataTableState>) => void) => {
      const mappedActions = selectionActions.map((extension) => extension(staffs, patchState)).filter(canRender);

      if (mappedActions.length === 0) {
        return null;
      }

      return mappedActions.map((x, i) => <Fragment key={i}>{x}</Fragment>);
    },
    [selectionActions],
  );
}

const canRender = (element: ReactNode) => {
  return element !== null && element !== undefined && element !== false;
};
