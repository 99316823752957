/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderConfirmationPermissionDto
 */
export interface OrderConfirmationPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof OrderConfirmationPermissionDto
     */
    name: OrderConfirmationPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderConfirmationPermissionDto
     */
    action: OrderConfirmationPermissionDtoActionEnum;
}


/**
 * @export
 */
export const OrderConfirmationPermissionDtoNameEnum = {
    ORDER_CONFIRMATION: 'ORDER_CONFIRMATION'
} as const;
export type OrderConfirmationPermissionDtoNameEnum = typeof OrderConfirmationPermissionDtoNameEnum[keyof typeof OrderConfirmationPermissionDtoNameEnum];

/**
 * @export
 */
export const OrderConfirmationPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    EDIT: 'CAN_EDIT',
    CREATE: 'CAN_CREATE',
    SEND: 'CAN_SEND',
    DOWNLOAD: 'CAN_DOWNLOAD',
    PAY: 'CAN_PAY',
    EXPORT: 'CAN_EXPORT'
} as const;
export type OrderConfirmationPermissionDtoActionEnum = typeof OrderConfirmationPermissionDtoActionEnum[keyof typeof OrderConfirmationPermissionDtoActionEnum];


/**
 * Check if a given object implements the OrderConfirmationPermissionDto interface.
 */
export function instanceOfOrderConfirmationPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function OrderConfirmationPermissionDtoFromJSON(json: any): OrderConfirmationPermissionDto {
    return OrderConfirmationPermissionDtoFromJSONTyped(json, false);
}

export function OrderConfirmationPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderConfirmationPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function OrderConfirmationPermissionDtoToJSON(value?: OrderConfirmationPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

