import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useStandPermissionClassGroupExtension(): PermissionClassGroupExtension<'STAND'> {
  const { t } = useTranslation(['stand']);

  return {
    name: 'STAND',
    label: t('stand:stand'),
    getActionLabel: (action) => t(`stand:permission.${action}`),
    getAdditionalRequirement: (action) => t(`stand:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`stand:permissionInfo.${action}`, { defaultValue: '' }),
  };
}

export function usePersonStandPermissionClassGroupExtension(): PermissionClassGroupExtension<'PERSON-STAND'> {
  const { t } = useTranslation(['stand']);

  return {
    name: 'PERSON-STAND',
    label: t('stand:personRelation.label'),
    getActionLabel: (action) => t(`stand:personRelation.permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`stand:personRelation.permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`stand:personRelation.permissionInfo.${action}`, { defaultValue: '' }),
  };
}

export function useCompanyStandPermissionClassGroupExtension(): PermissionClassGroupExtension<'COMPANY-STAND'> {
  const { t } = useTranslation(['stand']);

  return {
    name: 'COMPANY-STAND',
    label: t('stand:companyRelation.label'),
    getActionLabel: (action) => t(`stand:companyRelation.permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`stand:companyRelation.permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`stand:companyRelation.permissionInfo.${action}`, { defaultValue: '' }),
  };
}
