import { ChevronDownIcon } from '@chakra-ui/icons';
import { Alert, AlertIcon, Box, Button, CloseButton, Heading, HStack, IconButton, Stack, Text } from '@chakra-ui/react';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export default function ButtonHelpViewer() {
  const { t } = useTranslation('app');

  return (
    <Stack spacing={8}>
      <Heading as="h3" size="md">
        {t('help.headline_button')}
      </Heading>
      <ControlHelpItem
        control={
          <Button width="110px" variant="primary">
            Button
          </Button>
        }
        text={t('help.primary-button')}
        description={t('help.primary-button_desc')}
      />
      <ControlHelpItem
        control={<Button width="110px">Button</Button>}
        text={t('help.secondary-button')}
        description={t('help.secondary-button_desc')}
      />
      <ControlHelpItem
        control={
          <Button width="110px" colorScheme="red">
            Button
          </Button>
        }
        text={t('help.critical-button')}
        description={t('help.critical-button_desc')}
      />
      <ControlHelpItem
        control={
          <Button width="110px" disabled variant="primary">
            Button
          </Button>
        }
        text={t('help.disabled-button')}
        description={t('help.disabled-button_desc')}
      />
      <ControlHelpItem
        control={<IconButton mr="70px" icon={<FontAwesomeIcon icon={faEllipsisV} />} aria-label="help-kebab-button" />}
        text={t('help.kebab-button')}
        description={t('help.kebab-button_desc')}
      />
      <ControlHelpItem
        control={
          <Button width="110px" rightIcon={<ChevronDownIcon />}>
            Button
          </Button>
        }
        text={t('help.accordion-button')}
        description={t('help.accordion-button_desc')}
      />

      <Heading as="h3" size="md">
        {t('help.headline_alerts')}
      </Heading>

      <ControlHelpItem
        control={
          <Alert status="success" variant="solid">
            <AlertIcon />
            {t('help.alert')}
            <CloseButton alignSelf="flex-start" position="relative" right={-1} top={-1} />
          </Alert>
        }
        text={t('help.alert_success')}
        description={t('help.alert_success_desc')}
      />
      <ControlHelpItem
        control={
          <Alert status="info" variant="solid">
            <AlertIcon />
            {t('help.alert')}
            <CloseButton alignSelf="flex-start" position="relative" right={-1} top={-1} />
          </Alert>
        }
        text={t('help.alert_info')}
        description={t('help.alert_info_desc')}
      />
      <ControlHelpItem
        control={
          <Alert status="warning" variant="solid">
            <AlertIcon />
            {t('help.alert')}
            <CloseButton alignSelf="flex-start" position="relative" right={-1} top={-1} />
          </Alert>
        }
        text={t('help.alert_warning')}
        description={t('help.alert_warning_desc')}
      />
      <ControlHelpItem
        control={
          <Alert status="error" variant="solid">
            <AlertIcon />
            {t('help.alert')}
            <CloseButton alignSelf="flex-start" position="relative" right={-1} top={-1} />
          </Alert>
        }
        text={t('help.alert_error')}
        description={t('help.alert_error_desc')}
      />
    </Stack>
  );
}

interface ControlHelpItemProps {
  control: ReactNode;
  text: string;
  description?: string;
}

function ControlHelpItem({ control, text, description }: ControlHelpItemProps) {
  return (
    <HStack align="stretch" spacing={8}>
      <Box>{control}</Box>
      <Stack>
        <Text fontWeight="semibold">{text}</Text>
        <Text>{description}</Text>
      </Stack>
    </HStack>
  );
}
