/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ExternalOrderDetailDto = {
    PERSONAL_DATA: 'PERSONAL_DATA',
    OCCUPATION: 'OCCUPATION',
    FORMAT: 'FORMAT',
    UPLOAD_CONFIRMATION: 'UPLOAD_CONFIRMATION',
    UPLOAD_PHOTO: 'UPLOAD_PHOTO',
    UPLOAD_VISA: 'UPLOAD_VISA',
    UPLOAD_PRESS_DOCUMENT: 'UPLOAD_PRESS_DOCUMENT',
    PARTICIPATION_DATA: 'PARTICIPATION_DATA'
} as const;
export type ExternalOrderDetailDto = typeof ExternalOrderDetailDto[keyof typeof ExternalOrderDetailDto];


export function ExternalOrderDetailDtoFromJSON(json: any): ExternalOrderDetailDto {
    return ExternalOrderDetailDtoFromJSONTyped(json, false);
}

export function ExternalOrderDetailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalOrderDetailDto {
    return json as ExternalOrderDetailDto;
}

export function ExternalOrderDetailDtoToJSON(value?: ExternalOrderDetailDto | null): any {
    return value as any;
}

