/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Settings for event copy
 * @export
 * @interface EventCopySettingsDto
 */
export interface EventCopySettingsDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof EventCopySettingsDto
     */
    sourceEventId: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventCopySettingsDto
     */
    copyKeyPlayerList: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventCopySettingsDto
     */
    copyRundown: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventCopySettingsDto
     */
    copyGuestList: boolean;
}

/**
 * Check if a given object implements the EventCopySettingsDto interface.
 */
export function instanceOfEventCopySettingsDto(value: object): boolean {
    if (!('sourceEventId' in value)) return false;
    if (!('copyKeyPlayerList' in value)) return false;
    if (!('copyRundown' in value)) return false;
    if (!('copyGuestList' in value)) return false;
    return true;
}

export function EventCopySettingsDtoFromJSON(json: any): EventCopySettingsDto {
    return EventCopySettingsDtoFromJSONTyped(json, false);
}

export function EventCopySettingsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventCopySettingsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'sourceEventId': json['sourceEventId'],
        'copyKeyPlayerList': json['copyKeyPlayerList'],
        'copyRundown': json['copyRundown'],
        'copyGuestList': json['copyGuestList'],
    };
}

export function EventCopySettingsDtoToJSON(value?: EventCopySettingsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sourceEventId': value['sourceEventId'],
        'copyKeyPlayerList': value['copyKeyPlayerList'],
        'copyRundown': value['copyRundown'],
        'copyGuestList': value['copyGuestList'],
    };
}

