import { useTranslation } from 'react-i18next';
import { VersionDto } from '../../api';
import StaffAccountReference from '../../feature/staff-account/staff-account-reference/staff-account-reference';

export default function useLastModified(version?: VersionDto, color?: string) {
  const { t } = useTranslation('common');

  if (version) {
    return (
      <>
        {version.modifiedBy ? (
          <StaffAccountReference staffAccountReference={version.modifiedBy} color={color} />
        ) : (
          t('viewer.system')
        )}
        {', '}
        {t('viewer.last_modification_time', {
          dateTime: version.modifiedAt,
        })}
      </>
    );
  } else {
    return t('viewer.unknown');
  }
}
