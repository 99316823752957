/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MergeCompanyIdsDto
 */
export interface MergeCompanyIdsDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof MergeCompanyIdsDto
     */
    companyAId: string;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof MergeCompanyIdsDto
     */
    companyBId: string;
}

/**
 * Check if a given object implements the MergeCompanyIdsDto interface.
 */
export function instanceOfMergeCompanyIdsDto(value: object): boolean {
    if (!('companyAId' in value)) return false;
    if (!('companyBId' in value)) return false;
    return true;
}

export function MergeCompanyIdsDtoFromJSON(json: any): MergeCompanyIdsDto {
    return MergeCompanyIdsDtoFromJSONTyped(json, false);
}

export function MergeCompanyIdsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MergeCompanyIdsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'companyAId': json['companyAId'],
        'companyBId': json['companyBId'],
    };
}

export function MergeCompanyIdsDtoToJSON(value?: MergeCompanyIdsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'companyAId': value['companyAId'],
        'companyBId': value['companyBId'],
    };
}

