import {
  Button,
  ButtonGroup,
  FormControl as BaseFormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { without } from 'lodash-es';
import React from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { AddPeopleToGroupDto, GroupDto, PersonToAddDto } from '../../../../../api';
import groupPersonApi from '../../../../../data-access/group-person-api';
import ErrorMessage from '../../../../../ui/form/error-message';
import Form from '../../../../../ui/form/form';
import SubmitButton from '../../../../../ui/form/submit-button';
import useToast from '../../../../../ui/use-toast/use-toast';
import fallbackMiddleware from '../../../../../util/swr/fallback-middleware';
import useFetcher from '../../../../../util/swr/use-fetcher';
import { fetchGroupPersonRelations, groupPersonFetcher } from '../group-person-queries';
import PersonForGroupSelectionControl from './person-for-group-selection-control';
import { comparePeople, PersonInGroupSelectionViewer } from './person-in-group-selection-viewer';

interface GroupPersonEditorDialogProps {
  isOpen: boolean;
  onClose: () => void;
  group: GroupDto;
}

export default function GroupPersonEditorDialog({ isOpen, onClose, group }: GroupPersonEditorDialogProps) {
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick={false} initialFocusRef={initialFocusRef}>
      <ModalOverlay />
      <ModalContent>
        <React.Suspense>
          <GroupPersonFormModal group={group} initialFocusRef={initialFocusRef} onClose={onClose} />
        </React.Suspense>
      </ModalContent>
    </Modal>
  );
}

interface GroupPersonFormModalProps {
  group: GroupDto;
  initialFocusRef?: React.RefObject<any>;
  onClose: () => void;
}

function GroupPersonFormModal({ group, initialFocusRef, onClose }: GroupPersonFormModalProps) {
  const { t } = useTranslation(['common', 'group']);

  const form = useForm<AddPeopleToGroupDto>({
    mode: 'all',
    defaultValues: { people: [] },
  });

  const showSuccessToast = useToast({
    id: 'add-person-to-group-success-toast',
    status: 'success',
  });

  const handleValid = async (addPeopleDto: AddPeopleToGroupDto) => {
    invariant(group.id != null, 'Missing group.id');

    await groupPersonApi.addPeopleToGroup({ addPeopleToGroupDto: { ...addPeopleDto, group: group } });

    showSuccessToast({
      title: t('group:connections.toast.success.title'),
      description: t('group:connections.toast.success.description', { count: addPeopleDto.people.length }),
    });

    onClose();

    await groupPersonFetcher.mutate();
  };

  const formIsDirty = Object.keys(form.formState.dirtyFields).length > 0;

  return (
    <FormProvider {...form}>
      <Form<AddPeopleToGroupDto> onValid={handleValid} initialFocusRef={initialFocusRef}>
        <ModalHeader>{t('group:connections.add_person')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <PersonSelectionFrom group={group} initialFocusRef={initialFocusRef} />
        </ModalBody>

        <ModalFooter>
          <ButtonGroup spacing={4}>
            <Button onClick={onClose}>{t('common:action.abort')}</Button>
            <SubmitButton variant="primary" isDisabled={!formIsDirty}>
              {t('common:action.add')}
            </SubmitButton>
          </ButtonGroup>
        </ModalFooter>
      </Form>
    </FormProvider>
  );
}

export function PersonSelectionFrom({
  group,
  initialFocusRef,
}: {
  group: GroupDto;
  initialFocusRef?: React.RefObject<any>;
}) {
  const { t } = useTranslation('group');

  const existingRelations = useFetcher(
    fetchGroupPersonRelations,
    { pageable: { size: group.capacity! }, filter: [`group.id,eq,${group.id}`] },
    { use: [fallbackMiddleware] },
  );

  const validateLength = (persons: PersonToAddDto[]) => {
    return persons.length > 0 || t('connections.at_least_one_person');
  };

  const validateCapacity = (persons: PersonToAddDto[]) => {
    return (
      existingRelations.content.length + persons.length <= group.capacity! || t('connections.insufficient_capacity')
    );
  };

  const { fieldState, field } = useController<AddPeopleToGroupDto, 'people'>({
    name: 'people',
    rules: {
      validate: {
        length: validateLength,
        capacity: validateCapacity,
      },
    },
  });

  const addEntry = (entry: PersonToAddDto) => field.onChange([...field.value, entry]);

  const removeEntry = (entry: PersonToAddDto) => {
    const newValue = without(field.value, entry);
    field.onChange(newValue);
  };

  return (
    <BaseFormControl isInvalid={fieldState.error != null}>
      <Stack padding={4} bgColor="background.highlight" borderRadius="base" spacing={4}>
        <Stack borderRadius="base" borderWidth="1px" borderColor="border.01">
          <PersonForGroupSelectionControl
            groupId={group.id!}
            peopleAlreadyInGroup={field.value}
            onAdd={addEntry}
            initialFocusRef={initialFocusRef}
          />
        </Stack>
        <PersonInGroupSelectionViewer people={field.value} onRemove={removeEntry} compare={comparePeople} />
      </Stack>
      <ErrorMessage as={FormErrorMessage} name="people" />
    </BaseFormControl>
  );
}
