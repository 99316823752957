/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ExportDto,
  ExportPageDto,
  FailureDto,
  PageableDto,
} from '../models/index';
import {
    ExportDtoFromJSON,
    ExportDtoToJSON,
    ExportPageDtoFromJSON,
    ExportPageDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface FetchExportRequest {
    id: string;
}

export interface SearchExportsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

/**
 * 
 */
export class ExportApi extends runtime.BaseAPI {

    /**
     * Get the export.
     */
    async fetchExportRaw(requestParameters: FetchExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExportDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchExport().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exports/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExportDtoFromJSON(jsonValue));
    }

    /**
     * Get the export.
     */
    async fetchExport(requestParameters: FetchExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExportDto> {
        const response = await this.fetchExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for exports.
     */
    async searchExportsRaw(requestParameters: SearchExportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExportPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exports`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExportPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for exports.
     */
    async searchExports(requestParameters: SearchExportsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExportPageDto> {
        const response = await this.searchExportsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
