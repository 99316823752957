import { Stack, Text, useId } from '@chakra-ui/react';
import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import MultiValueAsyncSelect from '../../select/multi-value-async-select';
import ValueSelect from '../../select/value-select';
import { useDataTableFilter } from '../data-table-context';
import { getKeyByValue, OperatorOption } from './exists-or-in-filter';
import { joinAndEscapeFilterString, splitAndUnescapeFilterString } from './filter-utils';

type InFilterOptions = OperatorOption.EXISTS | OperatorOption.NOT_EXISTS | OperatorOption.IN;
export interface InFilterOption {
  label: string;
  value: string;
}

interface InFilterProps {
  label: ReactNode;
  showLabel?: boolean;
  loadOptions(searchQuery: string): Promise<InFilterOption[]>;
  options?: InFilterOptions[];
}

export default function InFilter({
  label,
  showLabel = true,
  loadOptions,
  options = [OperatorOption.EXISTS, OperatorOption.NOT_EXISTS, OperatorOption.IN],
}: InFilterProps) {
  const { t } = useTranslation('common');
  const { property, getFilter, setFilter, removeFilters, initialFocusRef } = useDataTableFilter();
  const id = useId(undefined, 'in-filter');
  const { value: filterValue, label: filterLabel, operator: filterOperator } = getFilter(property) ?? {};
  const [fallbackOperatorOption, setFallbackOperatorOption] = React.useState<OperatorOption | null>(
    options.length === 1 ? options[0] : null,
  );

  const operatorOption = (filterOperator as OperatorOption | undefined) ?? fallbackOperatorOption;

  const selectedOptions = useMemo(() => {
    if (filterValue == null || filterValue === '') {
      return [];
    }

    invariant(filterLabel != null && filterLabel !== '', 'Missing filter label');

    const selectedValues = splitAndUnescapeFilterString(filterValue);
    const selectedLabels = splitAndUnescapeFilterString(filterLabel);

    return selectedValues.map((value, index) => ({
      value,
      label: selectedLabels[index],
    }));
  }, [filterLabel, filterValue]);

  const handleSelectChange = (selectedOptions: InFilterOption[] | null) => {
    if (selectedOptions == null || selectedOptions.length === 0) {
      removeFilters(property);
    } else {
      setFilter({
        property: property,
        operator: OperatorOption.IN,
        value: joinAndEscapeFilterString(selectedOptions.map((option) => option.value)),
        label: joinAndEscapeFilterString(selectedOptions.map((option) => option.label)),
      });
    }
  };

  const handleOperatorChange = (operatorOption: OperatorOption | null) => {
    setFallbackOperatorOption(operatorOption);

    if (operatorOption === OperatorOption.IN) {
      removeFilters(property);
    } else if (operatorOption != null) {
      setFilter({
        property,
        operator: operatorOption,
        value: '',
      });
    } else {
      removeFilters(property);
    }
  };

  return (
    <Stack as="fieldset" spacing={2} aria-labelledby={`${id}-label`}>
      {showLabel && (
        <Text as="div" fontSize="sm" fontWeight="medium" id={`${id}-label`}>
          {label}
        </Text>
      )}
      <ValueSelect<OperatorOption>
        options={options}
        renderLabel={(option) => t(`data_table.exists_filter.${getKeyByValue(option)}`)}
        name="operator"
        isClearable
        size="sm"
        aria-label={t(`data_table.string_filter.operator_label`)}
        onChange={handleOperatorChange}
        value={operatorOption}
      />
      {operatorOption === 'in' && (
        <MultiValueAsyncSelect<InFilterOption>
          ref={initialFocusRef}
          size="sm"
          values={selectedOptions ?? []}
          loadOptions={loadOptions}
          aria-label={t(`data_table.string_filter.value_label`)}
          onChange={handleSelectChange}
          renderLabel={(option) => option.label}
          optionIdentifier={(option) => option.value}
        />
      )}
    </Stack>
  );
}
