/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyReferenceDto } from './CompanyReferenceDto';
import {
    CompanyReferenceDtoFromJSON,
    CompanyReferenceDtoFromJSONTyped,
    CompanyReferenceDtoToJSON,
} from './CompanyReferenceDto';

/**
 * 
 * @export
 * @interface MainOccupationListItemDto
 */
export interface MainOccupationListItemDto {
    /**
     * 
     * @type {CompanyReferenceDto}
     * @memberof MainOccupationListItemDto
     */
    company?: CompanyReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof MainOccupationListItemDto
     */
    jobTitle?: string;
}

/**
 * Check if a given object implements the MainOccupationListItemDto interface.
 */
export function instanceOfMainOccupationListItemDto(value: object): boolean {
    return true;
}

export function MainOccupationListItemDtoFromJSON(json: any): MainOccupationListItemDto {
    return MainOccupationListItemDtoFromJSONTyped(json, false);
}

export function MainOccupationListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MainOccupationListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'company': json['company'] == null ? undefined : CompanyReferenceDtoFromJSON(json['company']),
        'jobTitle': json['jobTitle'] == null ? undefined : json['jobTitle'],
    };
}

export function MainOccupationListItemDtoToJSON(value?: MainOccupationListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'company': CompanyReferenceDtoToJSON(value['company']),
        'jobTitle': value['jobTitle'],
    };
}

