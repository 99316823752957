/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EventArchivedLinkDto
 */
export interface EventArchivedLinkDto {
    /**
     * 
     * @type {string}
     * @memberof EventArchivedLinkDto
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof EventArchivedLinkDto
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof EventArchivedLinkDto
     */
    expirationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventArchivedLinkDto
     */
    timestamp?: Date;
}

/**
 * Check if a given object implements the EventArchivedLinkDto interface.
 */
export function instanceOfEventArchivedLinkDto(value: object): boolean {
    if (!('url' in value)) return false;
    return true;
}

export function EventArchivedLinkDtoFromJSON(json: any): EventArchivedLinkDto {
    return EventArchivedLinkDtoFromJSONTyped(json, false);
}

export function EventArchivedLinkDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventArchivedLinkDto {
    if (json == null) {
        return json;
    }
    return {
        
        'url': json['url'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'expirationDate': json['expirationDate'] == null ? undefined : (new Date(json['expirationDate'])),
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
    };
}

export function EventArchivedLinkDtoToJSON(value?: EventArchivedLinkDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'url': value['url'],
        'comment': value['comment'],
        'expirationDate': value['expirationDate'] == null ? undefined : ((value['expirationDate']).toISOString().substring(0,10)),
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
    };
}

