import useSWR from 'swr';
import invariant from 'tiny-invariant';
import eventEvaluationApi from '../../../data-access/event-evaluation-api';

export function getEventEvaluationCacheKey(eventId: string) {
  return ['eventEvaluation', eventId];
}

export default function useEventEvaluation(eventId: string) {
  const { data } = useSWR(
    getEventEvaluationCacheKey(eventId),
    () => eventEvaluationApi.fetchEvaluationByEventId({ eventId: eventId }),
    {
      suspense: true,
    },
  );
  invariant(data != null);

  return data;
}
