import React from 'react';
import { useParams } from 'react-router-dom';
import useFetcher from '../../util/swr/use-fetcher';
import { getMailingDesignId } from './common/get-mailing-design-id';
import { fetchMailingDesign } from './mailing-design-queries';

export default function MailingDesignBreadcrumb() {
  const params = useParams<{ designId: string }>();
  const mailingDesign = useFetcher(fetchMailingDesign, { id: getMailingDesignId(params) });
  const breadcrumb =
    mailingDesign.edition != null ? mailingDesign.brand + ' (' + mailingDesign.edition.name + ')' : mailingDesign.brand;

  return <>{breadcrumb}</>;
}
