/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PersonWithOccupationDto
 */
export interface PersonWithOccupationDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof PersonWithOccupationDto
     */
    personId: string;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof PersonWithOccupationDto
     */
    occupationId: string;
    /**
     * 
     * @type {string}
     * @memberof PersonWithOccupationDto
     */
    accountEmail?: string;
}

/**
 * Check if a given object implements the PersonWithOccupationDto interface.
 */
export function instanceOfPersonWithOccupationDto(value: object): boolean {
    if (!('personId' in value)) return false;
    if (!('occupationId' in value)) return false;
    return true;
}

export function PersonWithOccupationDtoFromJSON(json: any): PersonWithOccupationDto {
    return PersonWithOccupationDtoFromJSONTyped(json, false);
}

export function PersonWithOccupationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonWithOccupationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'personId': json['personId'],
        'occupationId': json['occupationId'],
        'accountEmail': json['accountEmail'] == null ? undefined : json['accountEmail'],
    };
}

export function PersonWithOccupationDtoToJSON(value?: PersonWithOccupationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'personId': value['personId'],
        'occupationId': value['occupationId'],
        'accountEmail': value['accountEmail'],
    };
}

