import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { MultiStyleConfig } from '@chakra-ui/theme-tools';

const Checkbox: MultiStyleConfig<typeof checkboxAnatomy> = {
  parts: checkboxAnatomy.keys,
  baseStyle: {
    container: {
      /* otherwise the whole area to the right of the label is also clickable */
      alignSelf: 'flex-start',
    },
    control: {
      border: '2px solid',
      borderColor: 'border.02',
      transitionProperty: 'common',
      transitionDuration: 'normal',
      _hover: {
        borderColor: 'border.02.hover',
      },
    },
  },
  sizes: {
    md: {
      control: {
        h: 5,
        w: 5,
      },
    },
  },
};

export default Checkbox;
