/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  LockDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    LockDtoFromJSON,
    LockDtoToJSON,
} from '../models/index';

export interface FindLockRequest {
    id: string;
}

export interface GainLockRequest {
    id: string;
}

export interface ReleaseLockRequest {
    id: string;
}

/**
 * 
 */
export class LockApi extends runtime.BaseAPI {

    /**
     * Lookup for a lock for the given entityId
     */
    async findLockRaw(requestParameters: FindLockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LockDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling findLock().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LockDtoFromJSON(jsonValue));
    }

    /**
     * Lookup for a lock for the given entityId
     */
    async findLock(requestParameters: FindLockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LockDto> {
        const response = await this.findLockRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Try to gain a lock on the referenced business object
     */
    async gainLockRaw(requestParameters: GainLockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LockDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling gainLock().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LockDtoFromJSON(jsonValue));
    }

    /**
     * Try to gain a lock on the referenced business object
     */
    async gainLock(requestParameters: GainLockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LockDto> {
        const response = await this.gainLockRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Release the lock.
     */
    async releaseLockRaw(requestParameters: ReleaseLockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling releaseLock().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Release the lock.
     */
    async releaseLock(requestParameters: ReleaseLockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.releaseLockRaw(requestParameters, initOverrides);
    }

}
