import { Text } from '@chakra-ui/react';
import { faUserRobot } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { PersonReferenceDto } from '../../../api';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';
import { VisitingCardPopover } from '../../../ui/visiting-card/visiting-card';
import PersonCard from './person-card';
import PersonReferenceViewer from './person-reference-viewer';

export interface PersonReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  personReference: Omit<PersonReferenceDto, 'emailAddresses'> | undefined;
  hidePersonKey?: boolean;
  flipName?: boolean;
  firstNameOnly?: boolean;
  surnameOnly?: boolean;
  isTruncated?: boolean;
  isInline?: boolean;
  spacing?: number;
  maxWidth?: number | string;
  // In some viewers the infos needs to be in a portal because of the zIndex or hidden overflow
  usePortalForCard?: boolean;
  hideIcon?: boolean;
  displayNameId?: string;
}

export default function PersonReference({
  personReference,
  size,
  hidePersonKey,
  flipName,
  firstNameOnly = false,
  surnameOnly = false,
  spacing,
  isTruncated,
  isInline,
  maxWidth,
  usePortalForCard,
  displayNameId,
  hideIcon = false,
  ...props
}: PersonReferenceProps) {
  if (personReference == null) {
    return (
      <Reference icon={faUserRobot} mr={1}>
        <Text as="span" fontWeight="normal" color="text.interactive">
          BerlinalX
        </Text>
      </Reference>
    );
  }
  return (
    <VisitingCardPopover
      necessaryPermission="PERSON:CAN_SEE_PERSON_CARD"
      trigger={
        <PersonReferenceViewer
          spacing={spacing}
          personReference={personReference}
          size={size}
          hidePersonKey={hidePersonKey}
          flipName={flipName}
          firstNameOnly={firstNameOnly}
          surnameOnly={surnameOnly}
          maxWidth={maxWidth ?? 240}
          isTruncated={isTruncated}
          alignItems={isInline ? 'baseline' : undefined}
          displayNameId={displayNameId}
          hideIcon={hideIcon}
          {...props}
        />
      }
      usePortal={usePortalForCard}
    >
      <PersonCard personId={personReference.id} flipName={flipName} />
    </VisitingCardPopover>
  );
}
