/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';
import type { SubzoneDto } from './SubzoneDto';
import {
    SubzoneDtoFromJSON,
    SubzoneDtoFromJSONTyped,
    SubzoneDtoToJSON,
} from './SubzoneDto';

/**
 * 
 * @export
 * @interface ZoneDto
 */
export interface ZoneDto {
    /**
     * 
     * @type {string}
     * @memberof ZoneDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ZoneDto
     */
    capacity: number;
    /**
     * 
     * @type {Array<SectionReferenceDto>}
     * @memberof ZoneDto
     */
    usedBy?: Array<SectionReferenceDto>;
    /**
     * 
     * @type {Array<SubzoneDto>}
     * @memberof ZoneDto
     */
    subzones?: Array<SubzoneDto>;
    /**
     * 
     * @type {Date}
     * @memberof ZoneDto
     */
    timestamp?: Date;
}

/**
 * Check if a given object implements the ZoneDto interface.
 */
export function instanceOfZoneDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('capacity' in value)) return false;
    return true;
}

export function ZoneDtoFromJSON(json: any): ZoneDto {
    return ZoneDtoFromJSONTyped(json, false);
}

export function ZoneDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZoneDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'capacity': json['capacity'],
        'usedBy': json['usedBy'] == null ? undefined : ((json['usedBy'] as Array<any>).map(SectionReferenceDtoFromJSON)),
        'subzones': json['subzones'] == null ? undefined : ((json['subzones'] as Array<any>).map(SubzoneDtoFromJSON)),
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
    };
}

export function ZoneDtoToJSON(value?: ZoneDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'capacity': value['capacity'],
        'usedBy': value['usedBy'] == null ? undefined : ((value['usedBy'] as Array<any>).map(SectionReferenceDtoToJSON)),
        'subzones': value['subzones'] == null ? undefined : ((value['subzones'] as Array<any>).map(SubzoneDtoToJSON)),
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
    };
}

