/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';

/**
 * Attendee of an event
 * @export
 * @interface AttendeeDto
 */
export interface AttendeeDto {
    /**
     * 
     * @type {Date}
     * @memberof AttendeeDto
     */
    timeOfAdmission: Date;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof AttendeeDto
     */
    person: PersonReferenceDto;
}

/**
 * Check if a given object implements the AttendeeDto interface.
 */
export function instanceOfAttendeeDto(value: object): boolean {
    if (!('timeOfAdmission' in value)) return false;
    if (!('person' in value)) return false;
    return true;
}

export function AttendeeDtoFromJSON(json: any): AttendeeDto {
    return AttendeeDtoFromJSONTyped(json, false);
}

export function AttendeeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttendeeDto {
    if (json == null) {
        return json;
    }
    return {
        
        'timeOfAdmission': (new Date(json['timeOfAdmission'])),
        'person': PersonReferenceDtoFromJSON(json['person']),
    };
}

export function AttendeeDtoToJSON(value?: AttendeeDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'timeOfAdmission': ((value['timeOfAdmission']).toISOString()),
        'person': PersonReferenceDtoToJSON(value['person']),
    };
}

