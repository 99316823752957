import { IconButton, Tooltip } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { FormatOption } from './format-types';

interface RichTextButtonProps {
  isActive: boolean | undefined;
  isDisabled?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  format: FormatOption;
  icon: IconDefinition;
}

function RichTextButton(
  { isActive, isDisabled, onClick, format, icon }: RichTextButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const { t } = useTranslation('common');

  return (
    <Tooltip key={format} label={t(`wysiwyg.aria_labels.${format}`)}>
      <IconButton
        size="sm"
        ref={ref}
        variant="outline"
        icon={<FontAwesomeIcon icon={icon} />}
        isActive={isActive}
        isDisabled={isDisabled}
        onClick={onClick}
        aria-label={t(`wysiwyg.aria_labels.${format}`)}
      />
    </Tooltip>
  );
}

export default React.forwardRef(RichTextButton);
