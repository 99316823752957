import { Box, Link, Wrap, WrapItem } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { SectionStaffListItemDto, StaffStatusDto } from '../../../../api';
import sectionStaffApi from '../../../../data-access/section-staff-api';
import { DataTableColumn, DataTableTruncatedText } from '../../../../ui/data-table';
import EnumFilter from '../../../../ui/data-table/filter/enum-filter';
import StringFilter from '../../../../ui/data-table/filter/string-filter';
import Optional from '../../../../ui/optional/optional';
import usePlugins from '../../../../util/plugin/use-plugins';
import { SECTION_STAFF_LISTER_COLUMN_EXTENSION } from './section-staff-lister-column-extension';
import { linkPhoneNumberString } from '../../../../ui/phone-number/link-phone-number';

export default function useSectionStaffTableColumns(sectionId: string) {
  const { t } = useTranslation(['staff']);
  const columnExtensions = usePlugins(SECTION_STAFF_LISTER_COLUMN_EXTENSION);

  return React.useMemo(() => {
    const columns: DataTableColumn<SectionStaffListItemDto>[] = [
      {
        key: 'surname',
        name: t('staff:surname'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        sticky: true,
        isSortable: true,
        renderCell: (staff) => (
          <DataTableTruncatedText>
            <Link as={RouterLink} to={`../../../staff/${staff.id!}`}>
              {staff.surname}
            </Link>
          </DataTableTruncatedText>
        ),
        filter: (
          <StringFilter
            label={t('staff:surname')}
            loadOptions={async (value: string) => {
              const page = await sectionStaffApi.searchSectionStaffRelations({
                pageable: { size: 10 },
                filter: [`surname,contain,${value}`],
                id: sectionId,
              });

              return page.content.map((staff) => staff.surname);
            }}
          />
        ),
      },
      {
        key: 'firstName',
        name: t('staff:firstName'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        isSortable: true,
        renderCell: (staff) => (
          <DataTableTruncatedText>
            <Link as={RouterLink} to={`../../../staff/${staff.id!}`}>
              {staff.firstName}
            </Link>
          </DataTableTruncatedText>
        ),
        filter: (
          <StringFilter
            label={t('staff:firstName')}
            loadOptions={async (value: string) => {
              const page = await sectionStaffApi.searchSectionStaffRelations({
                pageable: { size: 10 },
                filter: [`firstName,contain,${value}`],
                id: sectionId,
              });

              return page.content.map((staff) => staff.firstName);
            }}
          />
        ),
      },
      {
        key: 'jobTitle',
        name: t('staff:jobTitle'),
        isSortable: true,
        renderCell: (staff) => <Optional children={staff.jobTitle} />,
        filter: <StringFilter label={t('staff:jobTitle')} />,
      },
      {
        key: 'phoneNumber',
        name: t('staff:phone_number.header'),
        filterProperty: 'phoneNumbers[*].raw',
        filter: <StringFilter label={t('staff:phone_number.header')} />,
        cellProps: {},
        renderCell: (staff) => (
          <Optional isEmpty={isEmpty(staff.phoneNumbers)}>
            <Wrap>
              {staff.phoneNumbers?.map((number, index) => (
                <WrapItem key={index}>
                  <Link href={linkPhoneNumberString(number.raw)} isExternal>
                    {number.pretty}
                  </Link>
                </WrapItem>
              ))}
            </Wrap>
          </Optional>
        ),
      },
      {
        key: 'emailAddress',
        name: t('staff:email'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        isSortable: true,
        renderCell: (staff) => <Box>{staff.emailAddress}</Box>,
        filter: <StringFilter label={t('staff:email')} />,
      },

      {
        key: 'site',
        name: t('staff:site'),
        cellProps: {},
        isSortable: true,
        renderCell: (staff) => <Optional children={staff.site} />,
        filter: <StringFilter label={t('staff:site')} />,
      },
      {
        key: 'room',
        name: t('staff:room'),
        isSortable: true,
        cellProps: {},
        renderCell: (staff) => <Optional children={staff.room} />,
        filter: <StringFilter label={t('staff:room')} />,
      },
      {
        key: 'status',
        name: t('staff:status'),
        cellProps: {},
        isSortable: true,
        renderCell: (staff) => t(`staff:statusOptions.${staff.status}`),
        filter: (
          <EnumFilter
            label={t('staff:status')}
            options={[StaffStatusDto.ACTIVE, StaffStatusDto.INACTIVE]}
            renderOptionLabel={(key) => t(`staff:statusOptions.${key}`)}
          />
        ),
      },
    ];

    if (columnExtensions) {
      columns.splice(3, 0, ...columnExtensions.map((e) => e.columnToAdd));
    }

    return columns;
  }, [t, columnExtensions, sectionId]);
}
