/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryDto } from './CountryDto';
import {
    CountryDtoFromJSON,
    CountryDtoFromJSONTyped,
    CountryDtoToJSON,
} from './CountryDto';

/**
 * 
 * @export
 * @interface CountrySearchResultDto
 */
export interface CountrySearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof CountrySearchResultDto
     */
    resultType: CountrySearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CountrySearchResultDto
     */
    score: number;
    /**
     * 
     * @type {CountryDto}
     * @memberof CountrySearchResultDto
     */
    result: CountryDto;
}


/**
 * @export
 */
export const CountrySearchResultDtoResultTypeEnum = {
    COUNTRY: 'COUNTRY'
} as const;
export type CountrySearchResultDtoResultTypeEnum = typeof CountrySearchResultDtoResultTypeEnum[keyof typeof CountrySearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the CountrySearchResultDto interface.
 */
export function instanceOfCountrySearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function CountrySearchResultDtoFromJSON(json: any): CountrySearchResultDto {
    return CountrySearchResultDtoFromJSONTyped(json, false);
}

export function CountrySearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountrySearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': CountryDtoFromJSON(json['result']),
    };
}

export function CountrySearchResultDtoToJSON(value?: CountrySearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': CountryDtoToJSON(value['result']),
    };
}

