import { invoiceIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { COMPANY_ACTION_EXTENSION } from '../company/company-action-extension';
import { ORDER_CONFIRMATION_VIEWER_ITEM_EXTENSION } from '../order-confirmation/order-confirmation-viewer/order-confirmation-viewer-item-extension';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { orderConfirmationViewerInvoiceItem } from './invoice-order-confirmation-extension/invoice-order-confirmation-viewer-item';
import { useInvoicePermissionClassGroupExtension } from './invoice-permissions';
import { invoiceCompanyActionItem } from './invoice-refund-tax-button/invoice-company-action-item';
import InvoiceSearchResult from './invoice-search-result';

export default function InvoicePluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, {
    type: 'INVOICE',
    Component: InvoiceSearchResult,
  });
  registry.register(SEARCH_FILTER, {
    key: 'INVOICE',
    icon: invoiceIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.INVOICE" />,
    necessaryPermission: 'INVOICE:CAN_SEE_DETAILS',
  });
  registry.register(ORDER_CONFIRMATION_VIEWER_ITEM_EXTENSION, orderConfirmationViewerInvoiceItem);
  registry.register(COMPANY_ACTION_EXTENSION, invoiceCompanyActionItem);

  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useInvoicePermissionClassGroupExtension);
}
