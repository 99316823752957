import { ReactNode } from 'react';
import { PluginBuilderToken, PluginToken } from '../../../util/plugin/use-plugins';
import { BreadcrumbRouteObject } from '../../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../../ui/helmet/helmet-outlet';

export interface StaffPageTab {
  key: string;
  element: ReactNode;
}

export const STAFF_PAGE_TAB = new PluginBuilderToken<StaffPageTab>('STAFF_PAGE_TAB');

export type StaffPageRouteCallback = () => (BreadcrumbRouteObject & HelmetRouteObject)[];

export const STAFF_PAGE_ROUTES_CALLBACK = new PluginToken<StaffPageRouteCallback>('STAFF_PAGE_ROUTES_CALLBACK');
