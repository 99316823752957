/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AwardPermissionDto
 */
export interface AwardPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof AwardPermissionDto
     */
    name: AwardPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof AwardPermissionDto
     */
    action: AwardPermissionDtoActionEnum;
}


/**
 * @export
 */
export const AwardPermissionDtoNameEnum = {
    AWARD: 'AWARD'
} as const;
export type AwardPermissionDtoNameEnum = typeof AwardPermissionDtoNameEnum[keyof typeof AwardPermissionDtoNameEnum];

/**
 * @export
 */
export const AwardPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    EDIT: 'CAN_EDIT',
    CREATE: 'CAN_CREATE',
    DELETE: 'CAN_DELETE'
} as const;
export type AwardPermissionDtoActionEnum = typeof AwardPermissionDtoActionEnum[keyof typeof AwardPermissionDtoActionEnum];


/**
 * Check if a given object implements the AwardPermissionDto interface.
 */
export function instanceOfAwardPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function AwardPermissionDtoFromJSON(json: any): AwardPermissionDto {
    return AwardPermissionDtoFromJSONTyped(json, false);
}

export function AwardPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AwardPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function AwardPermissionDtoToJSON(value?: AwardPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

