/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Send the codes to the given recipients.
 * @export
 * @interface SendCodesRequestDto
 */
export interface SendCodesRequestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof SendCodesRequestDto
     */
    codes: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendCodesRequestDto
     */
    emailRecipients: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendCodesRequestDto
     */
    personRecipients: Array<string>;
}

/**
 * Check if a given object implements the SendCodesRequestDto interface.
 */
export function instanceOfSendCodesRequestDto(value: object): boolean {
    if (!('codes' in value)) return false;
    if (!('emailRecipients' in value)) return false;
    if (!('personRecipients' in value)) return false;
    return true;
}

export function SendCodesRequestDtoFromJSON(json: any): SendCodesRequestDto {
    return SendCodesRequestDtoFromJSONTyped(json, false);
}

export function SendCodesRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendCodesRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'codes': json['codes'],
        'emailRecipients': json['emailRecipients'],
        'personRecipients': json['personRecipients'],
    };
}

export function SendCodesRequestDtoToJSON(value?: SendCodesRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'codes': value['codes'],
        'emailRecipients': value['emailRecipients'],
        'personRecipients': value['personRecipients'],
    };
}

