/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Access token created.
 * @export
 * @interface TokenResponseDto
 */
export interface TokenResponseDto {
    /**
     * 
     * @type {string}
     * @memberof TokenResponseDto
     */
    accessToken: string;
    /**
     * 
     * @type {string}
     * @memberof TokenResponseDto
     */
    refreshToken?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenResponseDto
     */
    tokenType: TokenResponseDtoTokenTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof TokenResponseDto
     */
    expiresIn?: number;
}


/**
 * @export
 */
export const TokenResponseDtoTokenTypeEnum = {
    BEARER: 'Bearer'
} as const;
export type TokenResponseDtoTokenTypeEnum = typeof TokenResponseDtoTokenTypeEnum[keyof typeof TokenResponseDtoTokenTypeEnum];


/**
 * Check if a given object implements the TokenResponseDto interface.
 */
export function instanceOfTokenResponseDto(value: object): boolean {
    if (!('accessToken' in value)) return false;
    if (!('tokenType' in value)) return false;
    return true;
}

export function TokenResponseDtoFromJSON(json: any): TokenResponseDto {
    return TokenResponseDtoFromJSONTyped(json, false);
}

export function TokenResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'accessToken': json['access_token'],
        'refreshToken': json['refresh_token'] == null ? undefined : json['refresh_token'],
        'tokenType': json['token_type'],
        'expiresIn': json['expires_in'] == null ? undefined : json['expires_in'],
    };
}

export function TokenResponseDtoToJSON(value?: TokenResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'access_token': value['accessToken'],
        'refresh_token': value['refreshToken'],
        'token_type': value['tokenType'],
        'expires_in': value['expiresIn'],
    };
}

