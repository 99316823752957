/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  HistoryPageDto,
  PageableDto,
  StandCompanyPageDto,
  StandCompanyRelationDto,
  StandCompanyRelationTypeDto,
  StandCopyRequestDto,
  StandDto,
  StandPageDto,
  StandPersonPageDto,
  StandPersonRelationDto,
  StandPersonRelationTypeDto,
  StandReferenceDto,
  StandReferencePageDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    StandCompanyPageDtoFromJSON,
    StandCompanyPageDtoToJSON,
    StandCompanyRelationDtoFromJSON,
    StandCompanyRelationDtoToJSON,
    StandCompanyRelationTypeDtoFromJSON,
    StandCompanyRelationTypeDtoToJSON,
    StandCopyRequestDtoFromJSON,
    StandCopyRequestDtoToJSON,
    StandDtoFromJSON,
    StandDtoToJSON,
    StandPageDtoFromJSON,
    StandPageDtoToJSON,
    StandPersonPageDtoFromJSON,
    StandPersonPageDtoToJSON,
    StandPersonRelationDtoFromJSON,
    StandPersonRelationDtoToJSON,
    StandPersonRelationTypeDtoFromJSON,
    StandPersonRelationTypeDtoToJSON,
    StandReferenceDtoFromJSON,
    StandReferenceDtoToJSON,
    StandReferencePageDtoFromJSON,
    StandReferencePageDtoToJSON,
} from '../models/index';

export interface CopyStandRequest {
    standCopyRequestDto: StandCopyRequestDto;
}

export interface CreateStandRequest {
    standDto: StandDto;
}

export interface CreateStandCompanyRelationRequest {
    standCompanyRelationDto: StandCompanyRelationDto;
}

export interface CreateStandPersonRelationRequest {
    standPersonRelationDto: StandPersonRelationDto;
}

export interface DeleteStandByIdRequest {
    id: string;
}

export interface DeleteStandCompanyRelationsRequest {
    ids: Array<string>;
}

export interface DeleteStandPersonRelationsRequest {
    ids: Array<string>;
}

export interface FetchStandRequest {
    id: string;
}

export interface FetchStandHasCompanyRelationHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchStandHasPersonRelationHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchStandHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface IsStandDeletableRequest {
    id: string;
}

export interface NameExistsInEditionRequest {
    standId: string;
    name: string;
    editionId: string;
}

export interface SearchStandCompanyRelationListItemsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchStandListItemsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchStandPersonRelationListItemsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchStandReferencesRequest {
    pageable?: PageableDto;
    searchQuery?: string;
}

export interface StandCompanyRelationExistsRequest {
    standId: string;
    companyId: string;
    relationType: StandCompanyRelationTypeDto;
}

export interface StandPersonRelationExistsRequest {
    standId: string;
    personId: string;
    relationType: StandPersonRelationTypeDto;
}

export interface UpdateStandRequest {
    id: string;
    standDto: StandDto;
}

/**
 * 
 */
export class StandApi extends runtime.BaseAPI {

    /**
     * Copy stand
     */
    async copyStandRaw(requestParameters: CopyStandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['standCopyRequestDto'] == null) {
            throw new runtime.RequiredError(
                'standCopyRequestDto',
                'Required parameter "standCopyRequestDto" was null or undefined when calling copyStand().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stands/copy`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StandCopyRequestDtoToJSON(requestParameters['standCopyRequestDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Copy stand
     */
    async copyStand(requestParameters: CopyStandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.copyStandRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new stand.
     */
    async createStandRaw(requestParameters: CreateStandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['standDto'] == null) {
            throw new runtime.RequiredError(
                'standDto',
                'Required parameter "standDto" was null or undefined when calling createStand().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stands`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StandDtoToJSON(requestParameters['standDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new stand.
     */
    async createStand(requestParameters: CreateStandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createStandRaw(requestParameters, initOverrides);
    }

    /**
     * Create a stand company relation.
     */
    async createStandCompanyRelationRaw(requestParameters: CreateStandCompanyRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['standCompanyRelationDto'] == null) {
            throw new runtime.RequiredError(
                'standCompanyRelationDto',
                'Required parameter "standCompanyRelationDto" was null or undefined when calling createStandCompanyRelation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stands/stand-company-relations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StandCompanyRelationDtoToJSON(requestParameters['standCompanyRelationDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a stand company relation.
     */
    async createStandCompanyRelation(requestParameters: CreateStandCompanyRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createStandCompanyRelationRaw(requestParameters, initOverrides);
    }

    /**
     * Create a stand person relation.
     */
    async createStandPersonRelationRaw(requestParameters: CreateStandPersonRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['standPersonRelationDto'] == null) {
            throw new runtime.RequiredError(
                'standPersonRelationDto',
                'Required parameter "standPersonRelationDto" was null or undefined when calling createStandPersonRelation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stands/stand-person-relations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StandPersonRelationDtoToJSON(requestParameters['standPersonRelationDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a stand person relation.
     */
    async createStandPersonRelation(requestParameters: CreateStandPersonRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createStandPersonRelationRaw(requestParameters, initOverrides);
    }

    /**
     * Delete stand by id
     */
    async deleteStandByIdRaw(requestParameters: DeleteStandByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteStandById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stands/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete stand by id
     */
    async deleteStandById(requestParameters: DeleteStandByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteStandByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Delete stand-company relations.
     */
    async deleteStandCompanyRelationsRaw(requestParameters: DeleteStandCompanyRelationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['ids'] == null) {
            throw new runtime.RequiredError(
                'ids',
                'Required parameter "ids" was null or undefined when calling deleteStandCompanyRelations().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ids'] != null) {
            queryParameters['ids'] = requestParameters['ids']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stands/stand-company-relations`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete stand-company relations.
     */
    async deleteStandCompanyRelations(requestParameters: DeleteStandCompanyRelationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteStandCompanyRelationsRaw(requestParameters, initOverrides);
    }

    /**
     * Delete stand-person relations.
     */
    async deleteStandPersonRelationsRaw(requestParameters: DeleteStandPersonRelationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['ids'] == null) {
            throw new runtime.RequiredError(
                'ids',
                'Required parameter "ids" was null or undefined when calling deleteStandPersonRelations().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ids'] != null) {
            queryParameters['ids'] = requestParameters['ids']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stands/stand-person-relations`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete stand-person relations.
     */
    async deleteStandPersonRelations(requestParameters: DeleteStandPersonRelationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteStandPersonRelationsRaw(requestParameters, initOverrides);
    }

    /**
     * Get the stand business object.
     */
    async fetchStandRaw(requestParameters: FetchStandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StandDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchStand().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stands/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StandDtoFromJSON(jsonValue));
    }

    /**
     * Get the stand business object.
     */
    async fetchStand(requestParameters: FetchStandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StandDto> {
        const response = await this.fetchStandRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fetchStandHasCompanyRelationHistoryRaw(requestParameters: FetchStandHasCompanyRelationHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchStandHasCompanyRelationHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stands/{id}/versions-company-relations`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchStandHasCompanyRelationHistory(requestParameters: FetchStandHasCompanyRelationHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchStandHasCompanyRelationHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fetchStandHasPersonRelationHistoryRaw(requestParameters: FetchStandHasPersonRelationHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchStandHasPersonRelationHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stands/{id}/versions-person-relations`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchStandHasPersonRelationHistory(requestParameters: FetchStandHasPersonRelationHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchStandHasPersonRelationHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the stand.
     */
    async fetchStandHistoryRaw(requestParameters: FetchStandHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchStandHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stands/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the stand.
     */
    async fetchStandHistory(requestParameters: FetchStandHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchStandHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get whether the stand can be deleted.
     */
    async isStandDeletableRaw(requestParameters: IsStandDeletableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling isStandDeletable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stands/{id}/is-deletable`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get whether the stand can be deleted.
     */
    async isStandDeletable(requestParameters: IsStandDeletableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.isStandDeletableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get whether the given name already exists in the given edition
     */
    async nameExistsInEditionRaw(requestParameters: NameExistsInEditionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StandReferenceDto>> {
        if (requestParameters['standId'] == null) {
            throw new runtime.RequiredError(
                'standId',
                'Required parameter "standId" was null or undefined when calling nameExistsInEdition().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling nameExistsInEdition().'
            );
        }

        if (requestParameters['editionId'] == null) {
            throw new runtime.RequiredError(
                'editionId',
                'Required parameter "editionId" was null or undefined when calling nameExistsInEdition().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['standId'] != null) {
            queryParameters['standId'] = requestParameters['standId'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['editionId'] != null) {
            queryParameters['editionId'] = requestParameters['editionId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stands/name-exists-in-edition`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StandReferenceDtoFromJSON(jsonValue));
    }

    /**
     * Get whether the given name already exists in the given edition
     */
    async nameExistsInEdition(requestParameters: NameExistsInEditionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StandReferenceDto | null | undefined > {
        const response = await this.nameExistsInEditionRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Search for stand-company connection list items.
     */
    async searchStandCompanyRelationListItemsRaw(requestParameters: SearchStandCompanyRelationListItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StandCompanyPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stands/stand-company-relations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StandCompanyPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for stand-company connection list items.
     */
    async searchStandCompanyRelationListItems(requestParameters: SearchStandCompanyRelationListItemsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StandCompanyPageDto> {
        const response = await this.searchStandCompanyRelationListItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for stand list items.
     */
    async searchStandListItemsRaw(requestParameters: SearchStandListItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StandPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stands`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StandPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for stand list items.
     */
    async searchStandListItems(requestParameters: SearchStandListItemsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StandPageDto> {
        const response = await this.searchStandListItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for stand-person connection list items.
     */
    async searchStandPersonRelationListItemsRaw(requestParameters: SearchStandPersonRelationListItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StandPersonPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stands/stand-person-relations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StandPersonPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for stand-person connection list items.
     */
    async searchStandPersonRelationListItems(requestParameters: SearchStandPersonRelationListItemsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StandPersonPageDto> {
        const response = await this.searchStandPersonRelationListItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for stand references.
     */
    async searchStandReferencesRaw(requestParameters: SearchStandReferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StandReferencePageDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        if (requestParameters['searchQuery'] != null) {
            queryParameters['searchQuery'] = requestParameters['searchQuery'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stands/references`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StandReferencePageDtoFromJSON(jsonValue));
    }

    /**
     * Search for stand references.
     */
    async searchStandReferences(requestParameters: SearchStandReferencesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StandReferencePageDto> {
        const response = await this.searchStandReferencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get whether the given stand-company relation already exists
     */
    async standCompanyRelationExistsRaw(requestParameters: StandCompanyRelationExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['standId'] == null) {
            throw new runtime.RequiredError(
                'standId',
                'Required parameter "standId" was null or undefined when calling standCompanyRelationExists().'
            );
        }

        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling standCompanyRelationExists().'
            );
        }

        if (requestParameters['relationType'] == null) {
            throw new runtime.RequiredError(
                'relationType',
                'Required parameter "relationType" was null or undefined when calling standCompanyRelationExists().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['standId'] != null) {
            queryParameters['standId'] = requestParameters['standId'];
        }

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['relationType'] != null) {
            queryParameters['relationType'] = requestParameters['relationType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stands/stand-company-relation-exists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get whether the given stand-company relation already exists
     */
    async standCompanyRelationExists(requestParameters: StandCompanyRelationExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.standCompanyRelationExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get whether the given stand-person relation already exists
     */
    async standPersonRelationExistsRaw(requestParameters: StandPersonRelationExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['standId'] == null) {
            throw new runtime.RequiredError(
                'standId',
                'Required parameter "standId" was null or undefined when calling standPersonRelationExists().'
            );
        }

        if (requestParameters['personId'] == null) {
            throw new runtime.RequiredError(
                'personId',
                'Required parameter "personId" was null or undefined when calling standPersonRelationExists().'
            );
        }

        if (requestParameters['relationType'] == null) {
            throw new runtime.RequiredError(
                'relationType',
                'Required parameter "relationType" was null or undefined when calling standPersonRelationExists().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['standId'] != null) {
            queryParameters['standId'] = requestParameters['standId'];
        }

        if (requestParameters['personId'] != null) {
            queryParameters['personId'] = requestParameters['personId'];
        }

        if (requestParameters['relationType'] != null) {
            queryParameters['relationType'] = requestParameters['relationType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stands/stand-person-relation-exists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get whether the given stand-person relation already exists
     */
    async standPersonRelationExists(requestParameters: StandPersonRelationExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.standPersonRelationExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new stand.
     */
    async templateStandRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StandDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stands/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StandDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new stand.
     */
    async templateStand(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StandDto> {
        const response = await this.templateStandRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the existing stand.
     */
    async updateStandRaw(requestParameters: UpdateStandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateStand().'
            );
        }

        if (requestParameters['standDto'] == null) {
            throw new runtime.RequiredError(
                'standDto',
                'Required parameter "standDto" was null or undefined when calling updateStand().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stands/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StandDtoToJSON(requestParameters['standDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the existing stand.
     */
    async updateStand(requestParameters: UpdateStandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateStandRaw(requestParameters, initOverrides);
    }

}
