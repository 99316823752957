import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Checkbox,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { faUnlink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  OccupationDto,
  OccupationDtoConnectionTypeEnum,
  OccupationDtoStatusEnum,
  PersonReferenceDto,
} from '../../../../api';
import DateInputFormControl from '../../../../ui/form/date-input-control/date-input-form-control';
import ElementContext from '../../../../ui/form/element-control/element-context';
import { useElementFormModalReset } from '../../../../ui/form/element-control/element-form-modal';
import { ElementFormProvider, useElementForm } from '../../../../ui/form/element-control/element-form-modal-context';
import { ElementTableDispatchContext } from '../../../../ui/form/element-control/element-table-control';
import Form from '../../../../ui/form/form';
import { Radio } from '../../../../ui/form/radio-control/radio-control';
import useDefinedContext from '../../../../util/context/use-defined-context/use-defined-context';
import now from '../../../../util/now';
import ConnectionControl from './connection-control';

interface CancelConnectionButtonProps {
  label: string;
  fromPersonReference: PersonReferenceDto;
}

enum CancelAction {
  END = 'END',
  DELETE = 'DELETE',
}

export default function CancelConnectionButton({ label, fromPersonReference }: CancelConnectionButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDefinedContext(ElementTableDispatchContext);
  const { element, index } = useDefinedContext(ElementContext);
  const [action, setAction] = React.useState(CancelAction.END);

  const handleSubmit = React.useCallback(
    (element: any) => {
      if (action === CancelAction.END) {
        dispatch({
          type: 'EDIT_ELEMENT',
          payload: {
            element,
            index,
          },
        });
      } else {
        dispatch({ type: 'DELETE_ELEMENT', payload: index });
      }
    },
    [action, dispatch, index],
  );

  return (
    <>
      <Tooltip label={label}>
        <IconButton
          onClick={onOpen}
          size="sm"
          variant="ghost"
          icon={<FontAwesomeIcon icon={faUnlink} />}
          aria-label={label}
        />
      </Tooltip>

      <ElementFormProvider<OccupationDto> element={element} onSubmit={handleSubmit}>
        <CancelConnectionForm
          isOpen={isOpen}
          onClose={onClose}
          action={action}
          onActionChange={setAction}
          fromPersonReference={fromPersonReference}
        />
      </ElementFormProvider>
    </>
  );
}

interface CancelConnectionFormProps {
  isOpen: boolean;
  onClose(): void;
  action: CancelAction;
  onActionChange(action: CancelAction): void;
  fromPersonReference: PersonReferenceDto;
}

function CancelConnectionForm({
  isOpen,
  onClose,
  fromPersonReference,
  action,
  onActionChange,
}: CancelConnectionFormProps) {
  const { t } = useTranslation(['common', 'person']);
  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const { element, onSubmit } = useElementForm<OccupationDto>();
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(false);
  const { register } = useFormContext<OccupationDto>();

  useElementFormModalReset({
    isOpen,
    element: {
      ...element,
      dateRange: { ...element?.dateRange, end: new Date(now()) },
      status: OccupationDtoStatusEnum.TERMINATED,
      companyAdmin: false,
    },
  });

  const onCloseWithReset = () => {
    onActionChange(CancelAction.END);
    setDeleteConfirmation(false);
    onClose();
  };

  // jaj, cv, lh: inside react-hook-form not registered fields are omitted from the submitted data
  // fields without inputs in the UI will be removed from the given element when the form is submitted
  register('connectionType');
  register('profession');
  register('jobTitle');
  register('workingSectors');
  register('status');
  register('mainActivity');
  register('connectedCompany');
  register('formats');
  register('companyAdmin');
  register('id');

  return (
    <Modal isOpen={isOpen} onClose={onCloseWithReset} initialFocusRef={undefined}>
      <ModalOverlay />
      <ModalContent
        as={Form}
        noValidate
        onValid={(element: unknown) => {
          onSubmit(element as OccupationDto);
          onCloseWithReset();
        }}
        onSubmit={(event) => {
          event.stopPropagation();
        }}
        initialFocusRef={initialFocusRef}
      >
        <ModalHeader>{t('person:connections.end_connection')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ConnectionControl
            fromReference={fromPersonReference}
            connectionEditable={false}
            toReferenceEditable={false}
            showCompany={element?.connectionType === OccupationDtoConnectionTypeEnum.EMPLOYEE}
          />
          <Stack spacing={4}>
            <Stack spacing={4} mt={4} borderColor="border.01" borderWidth={1} borderRadius={4} p={4}>
              <RadioGroup value={action} onChange={onActionChange}>
                <Stack spacing={4}>
                  <Radio value={CancelAction.END} label={t('person:connections.end_connection')}>
                    {t('person:connections.description_end')}
                  </Radio>
                  <Radio value={CancelAction.DELETE} label={t('person:connections.delete_connection')}>
                    {t('person:connections.description_delete')}
                  </Radio>
                </Stack>
              </RadioGroup>
            </Stack>

            {action === CancelAction.END ? (
              <HStack alignItems="start" spacing={4}>
                <DateInputFormControl<OccupationDto>
                  name="dateRange.start"
                  isDisabled
                  label={t('person:connections.date_range_start')}
                />
                <DateInputFormControl<OccupationDto>
                  name="dateRange.end"
                  label={t('person:connections.date_range_end')}
                  isRequired
                  min={
                    element && {
                      value: element.dateRange.start,
                      message: t('common:validation_error.date_end_before_date_start'),
                    }
                  }
                />
              </HStack>
            ) : (
              <>
                <Alert status="warning">
                  <AlertIcon />
                  <AlertDescription>
                    <Text>{t('person:connections.alert_delete')}</Text>
                  </AlertDescription>
                </Alert>

                <Checkbox
                  onChange={(event) => setDeleteConfirmation(event.target.checked)}
                  isChecked={deleteConfirmation}
                  spacing={3}
                >
                  {t('person:connections.delete_confirmation')}
                </Checkbox>
              </>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onCloseWithReset}>
            {t('common:action.abort')}
          </Button>
          {action === CancelAction.END ? (
            <Button variant="primary" type="submit">
              {t('person:connections.end')}
            </Button>
          ) : (
            <Button colorScheme="red" type="submit" isDisabled={!deleteConfirmation}>
              {t('common:action.delete')}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
