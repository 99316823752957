/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  HistoryPageDto,
  PageableDto,
  PlacementTemplateDto,
  PlacementTemplatePageDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    PlacementTemplateDtoFromJSON,
    PlacementTemplateDtoToJSON,
    PlacementTemplatePageDtoFromJSON,
    PlacementTemplatePageDtoToJSON,
} from '../models/index';

export interface CreatePlacementTemplateRequest {
    parentId: string;
    placementTemplateDto: PlacementTemplateDto;
}

export interface DeletePlacementTemplateRequest {
    parentId: string;
    id: string;
}

export interface FetchPlacementTemplateRequest {
    parentId: string;
    id: string;
    copy?: boolean;
}

export interface PlacementTemplatesRequest {
    parentId: string;
}

export interface PlacementTemplatesHistoryRequest {
    parentId: string;
    pageable?: PageableDto;
}

export interface UpdatePlacementTemplateRequest {
    parentId: string;
    id: string;
    placementTemplateDto: PlacementTemplateDto;
}

/**
 * 
 */
export class PlacementTemplateApi extends runtime.BaseAPI {

    /**
     * Create a new placement template.
     */
    async createPlacementTemplateRaw(requestParameters: CreatePlacementTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlacementTemplateDto>> {
        if (requestParameters['parentId'] == null) {
            throw new runtime.RequiredError(
                'parentId',
                'Required parameter "parentId" was null or undefined when calling createPlacementTemplate().'
            );
        }

        if (requestParameters['placementTemplateDto'] == null) {
            throw new runtime.RequiredError(
                'placementTemplateDto',
                'Required parameter "placementTemplateDto" was null or undefined when calling createPlacementTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locations/{parentId}/placement-templates`.replace(`{${"parentId"}}`, encodeURIComponent(String(requestParameters['parentId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlacementTemplateDtoToJSON(requestParameters['placementTemplateDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlacementTemplateDtoFromJSON(jsonValue));
    }

    /**
     * Create a new placement template.
     */
    async createPlacementTemplate(requestParameters: CreatePlacementTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlacementTemplateDto> {
        const response = await this.createPlacementTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a placement template.
     */
    async deletePlacementTemplateRaw(requestParameters: DeletePlacementTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['parentId'] == null) {
            throw new runtime.RequiredError(
                'parentId',
                'Required parameter "parentId" was null or undefined when calling deletePlacementTemplate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deletePlacementTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locations/{parentId}/placement-templates/{id}`.replace(`{${"parentId"}}`, encodeURIComponent(String(requestParameters['parentId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a placement template.
     */
    async deletePlacementTemplate(requestParameters: DeletePlacementTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePlacementTemplateRaw(requestParameters, initOverrides);
    }

    /**
     * Get the placement template business object.
     */
    async fetchPlacementTemplateRaw(requestParameters: FetchPlacementTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlacementTemplateDto>> {
        if (requestParameters['parentId'] == null) {
            throw new runtime.RequiredError(
                'parentId',
                'Required parameter "parentId" was null or undefined when calling fetchPlacementTemplate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchPlacementTemplate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['copy'] != null) {
            queryParameters['copy'] = requestParameters['copy'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locations/{parentId}/placement-templates/{id}`.replace(`{${"parentId"}}`, encodeURIComponent(String(requestParameters['parentId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlacementTemplateDtoFromJSON(jsonValue));
    }

    /**
     * Get the placement template business object.
     */
    async fetchPlacementTemplate(requestParameters: FetchPlacementTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlacementTemplateDto> {
        const response = await this.fetchPlacementTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for placement templates.
     */
    async placementTemplatesRaw(requestParameters: PlacementTemplatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlacementTemplatePageDto>> {
        if (requestParameters['parentId'] == null) {
            throw new runtime.RequiredError(
                'parentId',
                'Required parameter "parentId" was null or undefined when calling placementTemplates().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locations/{parentId}/placement-templates`.replace(`{${"parentId"}}`, encodeURIComponent(String(requestParameters['parentId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlacementTemplatePageDtoFromJSON(jsonValue));
    }

    /**
     * Search for placement templates.
     */
    async placementTemplates(requestParameters: PlacementTemplatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlacementTemplatePageDto> {
        const response = await this.placementTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the placement templates history.
     */
    async placementTemplatesHistoryRaw(requestParameters: PlacementTemplatesHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['parentId'] == null) {
            throw new runtime.RequiredError(
                'parentId',
                'Required parameter "parentId" was null or undefined when calling placementTemplatesHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locations/{parentId}/placement-templates-history`.replace(`{${"parentId"}}`, encodeURIComponent(String(requestParameters['parentId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the placement templates history.
     */
    async placementTemplatesHistory(requestParameters: PlacementTemplatesHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.placementTemplatesHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the existing placement template.
     */
    async updatePlacementTemplateRaw(requestParameters: UpdatePlacementTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlacementTemplateDto>> {
        if (requestParameters['parentId'] == null) {
            throw new runtime.RequiredError(
                'parentId',
                'Required parameter "parentId" was null or undefined when calling updatePlacementTemplate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updatePlacementTemplate().'
            );
        }

        if (requestParameters['placementTemplateDto'] == null) {
            throw new runtime.RequiredError(
                'placementTemplateDto',
                'Required parameter "placementTemplateDto" was null or undefined when calling updatePlacementTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/locations/{parentId}/placement-templates/{id}`.replace(`{${"parentId"}}`, encodeURIComponent(String(requestParameters['parentId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PlacementTemplateDtoToJSON(requestParameters['placementTemplateDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlacementTemplateDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing placement template.
     */
    async updatePlacementTemplate(requestParameters: UpdatePlacementTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlacementTemplateDto> {
        const response = await this.updatePlacementTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
