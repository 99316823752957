/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TagRelationTypeDto } from './TagRelationTypeDto';
import {
    TagRelationTypeDtoFromJSON,
    TagRelationTypeDtoFromJSONTyped,
    TagRelationTypeDtoToJSON,
} from './TagRelationTypeDto';

/**
 * 
 * @export
 * @interface TagRelationReferenceDto
 */
export interface TagRelationReferenceDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof TagRelationReferenceDto
     */
    relationId: string;
    /**
     * 
     * @type {string}
     * @memberof TagRelationReferenceDto
     */
    name: string;
    /**
     * 
     * @type {TagRelationTypeDto}
     * @memberof TagRelationReferenceDto
     */
    relationType: TagRelationTypeDto;
    /**
     * Related section of owners is needed for permission concept, not all GOs contain an owner
     * @type {Array<string>}
     * @memberof TagRelationReferenceDto
     */
    belongsToSections?: Array<string>;
    /**
     * Additional owner used to provide an access via permissions from allocation owner
     * @type {Array<string>}
     * @memberof TagRelationReferenceDto
     */
    additionalOwnerSectionIds?: Array<string>;
}

/**
 * Check if a given object implements the TagRelationReferenceDto interface.
 */
export function instanceOfTagRelationReferenceDto(value: object): boolean {
    if (!('relationId' in value)) return false;
    if (!('name' in value)) return false;
    if (!('relationType' in value)) return false;
    return true;
}

export function TagRelationReferenceDtoFromJSON(json: any): TagRelationReferenceDto {
    return TagRelationReferenceDtoFromJSONTyped(json, false);
}

export function TagRelationReferenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagRelationReferenceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'relationId': json['relationId'],
        'name': json['name'],
        'relationType': TagRelationTypeDtoFromJSON(json['relationType']),
        'belongsToSections': json['belongsToSections'] == null ? undefined : json['belongsToSections'],
        'additionalOwnerSectionIds': json['additionalOwnerSectionIds'] == null ? undefined : json['additionalOwnerSectionIds'],
    };
}

export function TagRelationReferenceDtoToJSON(value?: TagRelationReferenceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'relationId': value['relationId'],
        'name': value['name'],
        'relationType': TagRelationTypeDtoToJSON(value['relationType']),
        'belongsToSections': value['belongsToSections'],
        'additionalOwnerSectionIds': value['additionalOwnerSectionIds'],
    };
}

