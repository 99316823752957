/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';

/**
 * A Film (content of FF).
 * @export
 * @interface FilmDto
 */
export interface FilmDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof FilmDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof FilmDto
     */
    ffId: number;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof FilmDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof FilmDto
     */
    originalTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof FilmDto
     */
    englishTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof FilmDto
     */
    germanTitle?: string;
}

/**
 * Check if a given object implements the FilmDto interface.
 */
export function instanceOfFilmDto(value: object): boolean {
    if (!('ffId' in value)) return false;
    if (!('edition' in value)) return false;
    return true;
}

export function FilmDtoFromJSON(json: any): FilmDto {
    return FilmDtoFromJSONTyped(json, false);
}

export function FilmDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilmDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'ffId': json['ffId'],
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'originalTitle': json['originalTitle'] == null ? undefined : json['originalTitle'],
        'englishTitle': json['englishTitle'] == null ? undefined : json['englishTitle'],
        'germanTitle': json['germanTitle'] == null ? undefined : json['germanTitle'],
    };
}

export function FilmDtoToJSON(value?: FilmDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'ffId': value['ffId'],
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'originalTitle': value['originalTitle'],
        'englishTitle': value['englishTitle'],
        'germanTitle': value['germanTitle'],
    };
}

