/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';

/**
 * 
 * @export
 * @interface GroupTemplateOwnerDto
 */
export interface GroupTemplateOwnerDto {
    /**
     * 
     * @type {SectionReferenceDto}
     * @memberof GroupTemplateOwnerDto
     */
    section: SectionReferenceDto;
    /**
     * 
     * @type {Date}
     * @memberof GroupTemplateOwnerDto
     */
    timestamp?: Date;
}

/**
 * Check if a given object implements the GroupTemplateOwnerDto interface.
 */
export function instanceOfGroupTemplateOwnerDto(value: object): boolean {
    if (!('section' in value)) return false;
    return true;
}

export function GroupTemplateOwnerDtoFromJSON(json: any): GroupTemplateOwnerDto {
    return GroupTemplateOwnerDtoFromJSONTyped(json, false);
}

export function GroupTemplateOwnerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupTemplateOwnerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'section': SectionReferenceDtoFromJSON(json['section']),
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
    };
}

export function GroupTemplateOwnerDtoToJSON(value?: GroupTemplateOwnerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'section': SectionReferenceDtoToJSON(value['section']),
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
    };
}

