/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttachmentLinksInnerDto } from './AttachmentLinksInnerDto';
import {
    AttachmentLinksInnerDtoFromJSON,
    AttachmentLinksInnerDtoFromJSONTyped,
    AttachmentLinksInnerDtoToJSON,
} from './AttachmentLinksInnerDto';
import type { ClosedDateTimeRangeDto } from './ClosedDateTimeRangeDto';
import {
    ClosedDateTimeRangeDtoFromJSON,
    ClosedDateTimeRangeDtoFromJSONTyped,
    ClosedDateTimeRangeDtoToJSON,
} from './ClosedDateTimeRangeDto';
import type { LaneDto } from './LaneDto';
import {
    LaneDtoFromJSON,
    LaneDtoFromJSONTyped,
    LaneDtoToJSON,
} from './LaneDto';
import type { LaneElementLocationsInnerDto } from './LaneElementLocationsInnerDto';
import {
    LaneElementLocationsInnerDtoFromJSON,
    LaneElementLocationsInnerDtoFromJSONTyped,
    LaneElementLocationsInnerDtoToJSON,
} from './LaneElementLocationsInnerDto';
import type { PublishedLaneElementPersonsInnerDto } from './PublishedLaneElementPersonsInnerDto';
import {
    PublishedLaneElementPersonsInnerDtoFromJSON,
    PublishedLaneElementPersonsInnerDtoFromJSONTyped,
    PublishedLaneElementPersonsInnerDtoToJSON,
} from './PublishedLaneElementPersonsInnerDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * Describe a rundown element for a specific lane
 * @export
 * @interface LaneElementDto
 */
export interface LaneElementDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof LaneElementDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof LaneElementDto
     */
    version?: VersionDto;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof LaneElementDto
     */
    eventId?: string;
    /**
     * 
     * @type {LaneDto}
     * @memberof LaneElementDto
     */
    lane: LaneDto;
    /**
     * 
     * @type {ClosedDateTimeRangeDto}
     * @memberof LaneElementDto
     */
    dateTimeRange: ClosedDateTimeRangeDto;
    /**
     * 
     * @type {string}
     * @memberof LaneElementDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof LaneElementDto
     */
    description?: string;
    /**
     * 
     * @type {Array<PublishedLaneElementPersonsInnerDto>}
     * @memberof LaneElementDto
     */
    persons: Array<PublishedLaneElementPersonsInnerDto>;
    /**
     * 
     * @type {Array<AttachmentLinksInnerDto>}
     * @memberof LaneElementDto
     */
    attachments: Array<AttachmentLinksInnerDto>;
    /**
     * 
     * @type {Array<LaneElementLocationsInnerDto>}
     * @memberof LaneElementDto
     */
    locations: Array<LaneElementLocationsInnerDto>;
}

/**
 * Check if a given object implements the LaneElementDto interface.
 */
export function instanceOfLaneElementDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('lane' in value)) return false;
    if (!('dateTimeRange' in value)) return false;
    if (!('title' in value)) return false;
    if (!('persons' in value)) return false;
    if (!('attachments' in value)) return false;
    if (!('locations' in value)) return false;
    return true;
}

export function LaneElementDtoFromJSON(json: any): LaneElementDto {
    return LaneElementDtoFromJSONTyped(json, false);
}

export function LaneElementDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaneElementDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'eventId': json['eventId'] == null ? undefined : json['eventId'],
        'lane': LaneDtoFromJSON(json['lane']),
        'dateTimeRange': ClosedDateTimeRangeDtoFromJSON(json['dateTimeRange']),
        'title': json['title'],
        'description': json['description'] == null ? undefined : json['description'],
        'persons': ((json['persons'] as Array<any>).map(PublishedLaneElementPersonsInnerDtoFromJSON)),
        'attachments': ((json['attachments'] as Array<any>).map(AttachmentLinksInnerDtoFromJSON)),
        'locations': ((json['locations'] as Array<any>).map(LaneElementLocationsInnerDtoFromJSON)),
    };
}

export function LaneElementDtoToJSON(value?: LaneElementDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'eventId': value['eventId'],
        'lane': LaneDtoToJSON(value['lane']),
        'dateTimeRange': ClosedDateTimeRangeDtoToJSON(value['dateTimeRange']),
        'title': value['title'],
        'description': value['description'],
        'persons': ((value['persons'] as Array<any>).map(PublishedLaneElementPersonsInnerDtoToJSON)),
        'attachments': ((value['attachments'] as Array<any>).map(AttachmentLinksInnerDtoToJSON)),
        'locations': ((value['locations'] as Array<any>).map(LaneElementLocationsInnerDtoToJSON)),
    };
}

