import { anatomy, MultiStyleConfig, PartsStyleFunction, PartsStyleObject } from '@chakra-ui/theme-tools';

export const reactSelectAnatomy = anatomy('reactSelect').parts(
  'control',
  'container',
  'valueContainer',
  'dropdownIndicator',
  'inputContainer',
  'placeholder',
  'singleValue',
  'indicatorSeparator',
  'menu',
  'menuList',
  'option',
);

export type ReactSelectSize = 'sm' | 'md' | 'lg';
export type ReactSelectVariant = 'outline';

const baseStyle: PartsStyleObject<typeof reactSelectAnatomy> = {
  control: {},
  container: {},
  valueContainer: {},
  dropdownIndicator: {
    px: 2,
  },
  inputContainer: {
    // Input containers (by default being "visible") inside elements like closed popovers ("hidden")
    // are still visible and therefore blocking the events of the elements behind them.
    // Let's hope this doesn't break anything.
    visibility: undefined,
    mx: 0,
    '[data-has-value="true"][data-is-multi="true"] &': {
      pl: 0.5,
    },
  },
  placeholder: {
    mx: 0,
  },
  singleValue: {
    mx: 0,
  },
  indicatorSeparator: {},
  menu: {
    zIndex: 'docked',
    overflow: 'initial',
  },
  menuList: {
    minWidth: 'initial',
  },
  option: {
    _selected: {
      bg: 'background.hero',
      color: 'text',
      _active: {
        bg: 'background.hero',
      },
    },
  },
};

const defaultProps = {
  size: 'md',
};

const sizes: Record<ReactSelectSize, PartsStyleObject<typeof reactSelectAnatomy>> = {
  sm: {
    valueContainer: {
      '[data-has-value="true"][data-is-multi="true"] &': {
        pl: 0.5,
      },
    },
  },
  md: {
    valueContainer: {
      '[data-has-value="true"][data-is-multi="true"] &': {
        pl: 1,
      },
    },
  },
  lg: {
    valueContainer: {
      '[data-has-value="true"][data-is-multi="true"] &': {
        pl: 1,
      },
    },
  },
};

const variants: Record<
  ReactSelectVariant,
  PartsStyleObject<typeof reactSelectAnatomy> | PartsStyleFunction<typeof reactSelectAnatomy>
> = {
  outline: {},
};

const ReactSelect: MultiStyleConfig<typeof reactSelectAnatomy> = {
  parts: reactSelectAnatomy.keys,
  baseStyle,
  defaultProps,
  sizes,
  variants,
};

export default ReactSelect;
