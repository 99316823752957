import { Badge } from '@chakra-ui/react';
import { Namespace, TFunction } from 'i18next';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { EditionImplicationDto, EditionImplicationDtoEditionOrderEnum } from '../../../api';
import StringDiff from '../../../ui/diff/string-diff';

interface EditionWillChangeProps {
  implication: EditionImplicationDto;
}

export default function EditionImplicationAgreeChange({ implication }: EditionWillChangeProps) {
  const { t } = useTranslation(['common', 'edition']);
  const i18nKeySuffix =
    implication.editionOrder === EditionImplicationDtoEditionOrderEnum.PREVIOUS
      ? getI18nKeySuffix(
          implication as EditionImplicationDto & {
            editionOrder: typeof EditionImplicationDtoEditionOrderEnum.PREVIOUS;
          },
          implication.affected.active ? 'active' : 'inactive',
        )
      : getI18nKeySuffix(
          implication as EditionImplicationDto & {
            editionOrder: typeof EditionImplicationDtoEditionOrderEnum.FOLLOWING;
          },
          'inactive',
        );
  const components = getComponents(t, implication);

  return (
    <Trans
      t={t}
      i18nKey={`edition:editor.implication.${i18nKeySuffix}`}
      values={{
        edition: implication.affected.name,
        dateRangeFrom: implication.affected.dateRange,
        dateRangeTo: implication.dateRange,
        nameFrom: implication.affected.name,
        nameTo: implication.name,
      }}
      components={components}
    />
  );
}

function getI18nKeySuffix<
  TEditionOrder extends EditionImplicationDtoEditionOrderEnum,
  TActiveSuffix extends 'active' | 'inactive',
>(implication: EditionImplicationDto & { editionOrder: TEditionOrder }, activeSuffix: TActiveSuffix) {
  if (implication.dateRange != null && implication.name != null) {
    return `agree_change_date_range_and_name.${implication.editionOrder}.${activeSuffix}` as const;
  }

  if (implication.dateRange != null) {
    return `agree_change_date_range.${implication.editionOrder}.${activeSuffix}` as const;
  }

  return `agree_change_name.${implication.editionOrder}.${activeSuffix}` as const;
}

function getComponents(t: TFunction<Namespace>, implication: EditionImplicationDto) {
  let components: { [element: string]: React.ReactElement } = {
    badge: <Badge verticalAlign="text-bottom" />,
  };

  if (implication.dateRange) {
    components = {
      ...components,
      dateRangeFrom: (
        <StringDiff
          to={t('common:format.date_range', {
            dateRange: implication.dateRange,
          })}
        />
      ),
      dateRangeTo: (
        <StringDiff
          from={t('common:format.date_range', {
            dateRange: implication.affected.dateRange,
          })}
        />
      ),
    };
  }

  if (implication.name) {
    components = {
      ...components,
      nameFrom: <StringDiff to={implication.name} />,
      nameTo: <StringDiff from={implication.affected.name} />,
    };
  }

  return components;
}
