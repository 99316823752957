import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { companyIcon, keyPlayerListIcon, sectionIcon } from '../../ui/icons/business-objects';
import CompanyReference from '../company/company-reference/company-reference';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';
import SearchResultConnection from '../search/search-result-connection';
import SectionReference from '../section/section-reference/section-reference';

export default function KeyPlayerSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'KEY_PLAYER', `${result.resultType} is no instance of KeyPlayerSearchResult`);

  const keyPlayer = result.result;
  const { t } = useTranslation(['key_player_list', 'common']);

  return (
    <SearchResult
      icon={keyPlayerListIcon}
      title={keyPlayer.person.firstName + ' ' + keyPlayer.person.surname}
      link={`/events/${keyPlayer.eventId}/key-player-list`}
      score={result.score}
      onClick={onClick}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('key_player_list:role'),
            element: keyPlayer.roles.map((r) => t(`key_player_list:roleLabel.${r}`)).join(', '),
          },
          {
            label: t('key_player_list:presence'),
            element: t(`key_player_list:presenceLabel.${keyPlayer.presence}`),
          },
          {
            label: t('key_player_list:lister.presence_time'),
            element: t('common:format.datetime_range', { dateTimeRange: keyPlayer.dateTimeRangePresence }),
          },
          {
            label: t('key_player_list:lister.comment'),
            element: keyPlayer.comment,
          },
        ]}
      />
      {keyPlayer.section != null && (
        <SearchResultConnection
          key={keyPlayer.section.section.id}
          onTargetClick={onClick}
          connection={
            <SearchResultAttributes
              attributes={[
                { element: t('key_player_list:lister.section') },
                { label: t('key_player_list:jobTitle'), element: keyPlayer.section.jobTitle },
              ]}
            />
          }
          targetIcon={sectionIcon}
        >
          <SectionReference size="sm" sectionReference={keyPlayer.section.section} hideIcon />
        </SearchResultConnection>
      )}
      {keyPlayer.company != null && (
        <SearchResultConnection key={keyPlayer.company.id} onTargetClick={onClick} targetIcon={companyIcon}>
          <CompanyReference size="sm" companyReference={keyPlayer.company} hideIcon />
        </SearchResultConnection>
      )}
    </SearchResult>
  );
}
