import {
  DarkMode,
  Divider,
  LightMode,
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  StackItem,
  useColorMode,
  useId,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import React from 'react';
import { PageSpinner } from '../page';
import usePermission, { Permission } from '../../feature/permission/use-permission';

interface VisitingPopoverProps {
  trigger: React.ReactNode;
  children?: React.ReactNode;
  usePortal?: boolean;
  necessaryPermission?: Permission;
}

export function VisitingCardPopover({ trigger, children, usePortal, necessaryPermission }: VisitingPopoverProps) {
  const { colorMode: outerColorMode } = useColorMode();
  const { hasPermission } = usePermission();

  if (necessaryPermission != null && !hasPermission(necessaryPermission)) {
    return trigger;
  }

  const content = (
    <PopoverContent rootProps={{ zIndex: 'modal' }} width="2xs" shadow="lg">
      <React.Suspense fallback={<PageSpinner />}>{children}</React.Suspense>
    </PopoverContent>
  );

  return (
    <DarkMode>
      <Popover isLazy size="sm" trigger="hover" openDelay={400}>
        {outerColorMode === 'light' ? (
          <LightMode>
            <PopoverTrigger>{trigger}</PopoverTrigger>
          </LightMode>
        ) : (
          <PopoverTrigger>{trigger}</PopoverTrigger>
        )}
        {usePortal ? <Portal>{content}</Portal> : content}
      </Popover>
    </DarkMode>
  );
}

interface VisitingCardProps {
  label: string;
  title: React.ReactNode;
  children?: React.ReactNode;
}

export default function VisitingCard({ label, title, children }: VisitingCardProps) {
  return (
    <>
      <PopoverHeader
        bgColor="background.hero"
        color="text"
        fontWeight="semibold"
        fontSize="xs"
        letterSpacing="wide"
        border="0"
        textTransform="uppercase"
        aria-label={label}
      >
        {title}
      </PopoverHeader>
      <Stack spacing={0} divider={<Divider />}>
        {React.Children.map(
          children,
          (child) =>
            child && (
              <StackItem py={2} px={3}>
                {child}
              </StackItem>
            ),
        )}
      </Stack>
    </>
  );
}

interface VisitingCardItemProps {
  label?: React.ReactNode;
  children?: React.ReactNode;
  length?: number;
}

export function VisitingCardItem({ label, children, length }: VisitingCardItemProps) {
  const id = useId(undefined, 'visiting-card-item');

  return (
    <Wrap justify="space-between" align="center" spacingX={4} spacingY={0.5} width="full" color="text">
      {label && (
        <WrapItem id={id} fontWeight="medium" fontSize="sm" width={length != null && length > 1 ? 'full' : undefined}>
          {label}
        </WrapItem>
      )}
      {children && (
        <WrapItem color="text.muted" fontSize="sm" display="block" aria-labelledby={id}>
          {children}
        </WrapItem>
      )}
    </Wrap>
  );
}
