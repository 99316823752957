import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import ParticipationBreadcrumb from './common/participation-breadcrumb';

const participationRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'participations',
    handle: {
      helmet: <Translate ns="participation">{(t) => <Helmet title={t('lister.helmet')} />}</Translate>,
      breadcrumb: <ParticipationBreadcrumb />,
    },
    children: [
      {
        path: '',
        lazy: () => import('../participation/participation-lister/participation-lister-route'),
      },
    ],
  },
];

export default participationRoutes;
