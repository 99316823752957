/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DateTimeRangeDto
 */
export interface DateTimeRangeDto {
    /**
     * 
     * @type {Date}
     * @memberof DateTimeRangeDto
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof DateTimeRangeDto
     */
    end?: Date;
}

/**
 * Check if a given object implements the DateTimeRangeDto interface.
 */
export function instanceOfDateTimeRangeDto(value: object): boolean {
    if (!('start' in value)) return false;
    return true;
}

export function DateTimeRangeDtoFromJSON(json: any): DateTimeRangeDto {
    return DateTimeRangeDtoFromJSONTyped(json, false);
}

export function DateTimeRangeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DateTimeRangeDto {
    if (json == null) {
        return json;
    }
    return {
        
        'start': (new Date(json['start'])),
        'end': json['end'] == null ? undefined : (new Date(json['end'])),
    };
}

export function DateTimeRangeDtoToJSON(value?: DateTimeRangeDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'start': ((value['start']).toISOString()),
        'end': value['end'] == null ? undefined : ((value['end']).toISOString()),
    };
}

