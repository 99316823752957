import { HTTPQuery } from '../api';

export default function stringifyQueryParams(query: HTTPQuery) {
  const params = new URLSearchParams();
  setQueryParams(params, query);

  return params.toString();
}

function setQueryParams(params: URLSearchParams, query: { [key: string]: any }) {
  Object.keys(query).forEach((name) => {
    const value = query[name];

    if (value == null) {
      return;
    }

    if (Array.isArray(value)) {
      if (value.length > 0) {
        value.forEach((value) => {
          params.append(name, String(value));
        });
      }
    } else if (typeof value === 'object') {
      setQueryParams(params, value);
    } else {
      params.set(name, String(value));
    }
  });
}
