import { Box, Heading, Stack, StackDivider } from '@chakra-ui/react';
import { ReactNode, useId } from 'react';

interface ModuleControlProps {
  label: ReactNode;
  children?: ReactNode;
}

export default function ModuleControl({ label, children }: ModuleControlProps) {
  const hasChildren = children != null;
  const labelId = `${useId()}-label`;

  return (
    <Stack
      spacing={0}
      divider={<StackDivider borderColor="border.01.muted" />}
      role="group"
      aria-labelledby={labelId}
      w="full"
    >
      <Box sx={{ px: 4, py: 3 }}>
        <Heading as="h4" size="sm" id={labelId}>
          {label}
        </Heading>
      </Box>
      {hasChildren && (
        <Box
          sx={{
            px: 4,
            pt: 3,
            pb: 4,
          }}
        >
          {children}
        </Box>
      )}
    </Stack>
  );
}
