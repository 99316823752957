/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FailureChangesDto } from './FailureChangesDto';
import {
    FailureChangesDtoFromJSON,
    FailureChangesDtoFromJSONTyped,
    FailureChangesDtoToJSON,
} from './FailureChangesDto';

/**
 * Information about the error occurred making the request fail.
 * @export
 * @interface FailureDto
 */
export interface FailureDto {
    /**
     * A unique code for the type of error to be used for localization.
     * @type {string}
     * @memberof FailureDto
     */
    code: string;
    /**
     * A human readable description of the error.
     * @type {string}
     * @memberof FailureDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof FailureDto
     */
    quote?: string;
    /**
     * A list of detailed errors for validation failures.
     * @type {Array<Error>}
     * @memberof FailureDto
     */
    errors?: Array<Error>;
    /**
     * 
     * @type {FailureChangesDto}
     * @memberof FailureDto
     */
    changes?: FailureChangesDto;
}

/**
 * Check if a given object implements the FailureDto interface.
 */
export function instanceOfFailureDto(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('message' in value)) return false;
    return true;
}

export function FailureDtoFromJSON(json: any): FailureDto {
    return FailureDtoFromJSONTyped(json, false);
}

export function FailureDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FailureDto {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'message': json['message'],
        'quote': json['quote'] == null ? undefined : json['quote'],
        'errors': json['errors'] == null ? undefined : json['errors'],
        'changes': json['changes'] == null ? undefined : FailureChangesDtoFromJSON(json['changes']),
    };
}

export function FailureDtoToJSON(value?: FailureDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'message': value['message'],
        'quote': value['quote'],
        'errors': value['errors'],
        'changes': FailureChangesDtoToJSON(value['changes']),
    };
}

