/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AdminSectionRoleReferenceDto } from './AdminSectionRoleReferenceDto';
import {
    AdminSectionRoleReferenceDtoFromJSON,
    AdminSectionRoleReferenceDtoFromJSONTyped,
    AdminSectionRoleReferenceDtoToJSON,
} from './AdminSectionRoleReferenceDto';
import type { ClassLevelPerGroupInnerDto } from './ClassLevelPerGroupInnerDto';
import {
    ClassLevelPerGroupInnerDtoFromJSON,
    ClassLevelPerGroupInnerDtoFromJSONTyped,
    ClassLevelPerGroupInnerDtoToJSON,
} from './ClassLevelPerGroupInnerDto';
import type { StaffSectionRoleStatusDto } from './StaffSectionRoleStatusDto';
import {
    StaffSectionRoleStatusDtoFromJSON,
    StaffSectionRoleStatusDtoFromJSONTyped,
    StaffSectionRoleStatusDtoToJSON,
} from './StaffSectionRoleStatusDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface StaffSectionRoleDto
 */
export interface StaffSectionRoleDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof StaffSectionRoleDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof StaffSectionRoleDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {AdminSectionRoleReferenceDto}
     * @memberof StaffSectionRoleDto
     */
    adminRole: AdminSectionRoleReferenceDto;
    /**
     * 
     * @type {Array<ClassLevelPerGroupInnerDto>}
     * @memberof StaffSectionRoleDto
     */
    classLevelPerGroup: Array<ClassLevelPerGroupInnerDto>;
    /**
     * 
     * @type {string}
     * @memberof StaffSectionRoleDto
     */
    name: string;
    /**
     * 
     * @type {StaffSectionRoleStatusDto}
     * @memberof StaffSectionRoleDto
     */
    status: StaffSectionRoleStatusDto;
}

/**
 * Check if a given object implements the StaffSectionRoleDto interface.
 */
export function instanceOfStaffSectionRoleDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('adminRole' in value)) return false;
    if (!('classLevelPerGroup' in value)) return false;
    if (!('name' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function StaffSectionRoleDtoFromJSON(json: any): StaffSectionRoleDto {
    return StaffSectionRoleDtoFromJSONTyped(json, false);
}

export function StaffSectionRoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffSectionRoleDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'adminRole': AdminSectionRoleReferenceDtoFromJSON(json['adminRole']),
        'classLevelPerGroup': ((json['classLevelPerGroup'] as Array<any>).map(ClassLevelPerGroupInnerDtoFromJSON)),
        'name': json['name'],
        'status': StaffSectionRoleStatusDtoFromJSON(json['status']),
    };
}

export function StaffSectionRoleDtoToJSON(value?: StaffSectionRoleDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'adminRole': AdminSectionRoleReferenceDtoToJSON(value['adminRole']),
        'classLevelPerGroup': ((value['classLevelPerGroup'] as Array<any>).map(ClassLevelPerGroupInnerDtoToJSON)),
        'name': value['name'],
        'status': StaffSectionRoleStatusDtoToJSON(value['status']),
    };
}

