import { ColorProps } from '@chakra-ui/react';
import { OrderStatusDto, PaymentStatusDto } from '../../api';

export const statusOptions = [
  OrderStatusDto.NEW,
  OrderStatusDto.IN_PROGRESS,
  OrderStatusDto.FORWARDED,
  OrderStatusDto.BLOCKED,
  OrderStatusDto.REJECTED,
  OrderStatusDto.WITHDRAWN,
  OrderStatusDto.ACCEPTED,
];

export const paymentOptions = [
  PaymentStatusDto.AWAITING_PAYMENT,
  PaymentStatusDto.PAID,
  PaymentStatusDto.IN_CANCELLATION,
  PaymentStatusDto.CANCELLED,
];

export const statusDisplay: {
  [key in OrderStatusDto]?: {
    bgColor: ColorProps['color'];
  };
} = {
  [OrderStatusDto.NEW]: {
    bgColor: 'background.info',
  },
  [OrderStatusDto.IN_PROGRESS]: {
    bgColor: 'background.warning',
  },
  [OrderStatusDto.FORWARDED]: {
    bgColor: 'background.warning',
  },
  [OrderStatusDto.BLOCKED]: {
    bgColor: 'background.warning',
  },
  [OrderStatusDto.REJECTED]: {
    bgColor: 'background.error',
  },
  [OrderStatusDto.WITHDRAWN]: {
    bgColor: 'background',
  },
  [OrderStatusDto.ACCEPTED]: {
    bgColor: 'background.success',
  },
};
