import { chakra, Text } from '@chakra-ui/react';
import { Diff, DIFF_DELETE, DIFF_INSERT, diff_match_patch } from 'diff-match-patch';
import React from 'react';
import invariant from 'tiny-invariant';

export interface DiffProps {
  children?: string | string[];
  from?: string;
  to?: string;
  diffEntireWord?: boolean;
  fontSize?: string;
}

const dmp = new diff_match_patch();

export default function StringDiff({ from, to, children, diffEntireWord, fontSize }: DiffProps) {
  invariant(
    (from != null && to == null) || (to != null && from == null),
    'Provide either property "from" or "to", not both.',
  );
  invariant(children != null, 'Missing text for diffing.');
  const base = Array.isArray(children) ? children.join('') : children;

  const diffs = React.useMemo(() => {
    let diffs = [[0, base] as Diff];

    if (diffEntireWord) {
      if (from != null && from !== base) {
        diffs = [
          [-1, from],
          [+1, base],
        ];
      }
      if (to != null && to !== base) {
        diffs = [
          [-1, base],
          [+1, to],
        ];
      }
    } else {
      diffs = dmp.diff_main(from ?? base, to ?? base);
      dmp.diff_cleanupSemantic(diffs);
    }

    return diffs;
  }, [base, from, to, diffEntireWord]);

  // 2022-05-06 it: `diffs` beschreibt alle Änderungen, die markiert werden sollen. Wenn alles markiert werden soll, sind es genau 2 Elemente in `diffs`.
  // Gibt es für den Vergleich vorher oder nachher keinen Wert (Neuanlage oder Löschen), ist in `diffs` nur 1 Element.
  const shouldAddPaddingX = diffs.length <= 2 || !!diffEntireWord;

  return (
    <span aria-label={base}>
      {diffs.map(([type, value], index) => {
        if (type === DIFF_INSERT) {
          if (to != null) {
            return null;
          }
          return (
            <Insert fontSize={fontSize} key={index} data-testid="insert" px={shouldAddPaddingX ? 1 : 0}>
              {value}
            </Insert>
          );
        }

        if (type === DIFF_DELETE) {
          if (from != null) {
            return null;
          }

          return (
            <Delete fontSize={fontSize} key={index} data-testid="delete" px={shouldAddPaddingX ? 1 : 0}>
              {value}
            </Delete>
          );
        }

        return (
          <Text as="span" fontSize={fontSize} key={index}>
            {value}
          </Text>
        );
      })}
    </span>
  );
}

export const Insert = chakra('span', {
  baseStyle: {
    borderRadius: 'sm',
    backgroundColor: 'background.insert',
    color: 'text.insert',
  },
});

export const Delete = chakra('span', {
  baseStyle: {
    borderRadius: 'sm',
    backgroundColor: 'background.delete',
    color: 'text.delete',
  },
});
