import groupTemplateApi from '../../data-access/group-template-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';

export const groupTemplateFetcher = createFetcherScope(groupTemplateApi);
export const fetchGroupTemplate = groupTemplateFetcher.createFetcher(groupTemplateApi.fetchGroupTemplate);
export const searchGroupTemplateListItems = groupTemplateFetcher.createFetcher(
  groupTemplateApi.searchGroupTemplateListItems,
);
export const searchGroupTemplateReferences = groupTemplateFetcher.createFetcher(
  groupTemplateApi.searchGroupTemplateReferences,
);
export const fetchGroupTemplateHistory = groupTemplateFetcher.createFetcher(groupTemplateApi.fetchGroupTemplateHistory);
