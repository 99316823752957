/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClassLevelDto } from './ClassLevelDto';
import {
    ClassLevelDtoFromJSON,
    ClassLevelDtoFromJSONTyped,
    ClassLevelDtoToJSON,
} from './ClassLevelDto';
import type { PermissionClassGroupReferenceDto } from './PermissionClassGroupReferenceDto';
import {
    PermissionClassGroupReferenceDtoFromJSON,
    PermissionClassGroupReferenceDtoFromJSONTyped,
    PermissionClassGroupReferenceDtoToJSON,
} from './PermissionClassGroupReferenceDto';

/**
 * 
 * @export
 * @interface ClassLevelPerGroupInnerDto
 */
export interface ClassLevelPerGroupInnerDto {
    /**
     * 
     * @type {ClassLevelDto}
     * @memberof ClassLevelPerGroupInnerDto
     */
    level?: ClassLevelDto;
    /**
     * 
     * @type {PermissionClassGroupReferenceDto}
     * @memberof ClassLevelPerGroupInnerDto
     */
    classGroup: PermissionClassGroupReferenceDto;
}

/**
 * Check if a given object implements the ClassLevelPerGroupInnerDto interface.
 */
export function instanceOfClassLevelPerGroupInnerDto(value: object): boolean {
    if (!('classGroup' in value)) return false;
    return true;
}

export function ClassLevelPerGroupInnerDtoFromJSON(json: any): ClassLevelPerGroupInnerDto {
    return ClassLevelPerGroupInnerDtoFromJSONTyped(json, false);
}

export function ClassLevelPerGroupInnerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassLevelPerGroupInnerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'level': json['level'] == null ? undefined : ClassLevelDtoFromJSON(json['level']),
        'classGroup': PermissionClassGroupReferenceDtoFromJSON(json['classGroup']),
    };
}

export function ClassLevelPerGroupInnerDtoToJSON(value?: ClassLevelPerGroupInnerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'level': ClassLevelDtoToJSON(value['level']),
        'classGroup': PermissionClassGroupReferenceDtoToJSON(value['classGroup']),
    };
}

