import { Alert, AlertDescription, AlertIcon, Box, ButtonProps, forwardRef, Stack, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { EventDto, EventEventConnectionDto, EventReferenceDto, EventStatusDto } from '../../../api';
import { ElementFormModal, ElementTableControl, useElementForm } from '../../../ui/form/element-control';
import AddElementButton from '../../../ui/form/element-control/add-element-button';
import DeleteElementButton from '../../../ui/form/element-control/delete-element-button';
import ElementContext from '../../../ui/form/element-control/element-context';
import useDefinedContext from '../../../util/context/use-defined-context/use-defined-context';
import eventEventColumns from '../event-table-columns/event-event-columns';
import CancelConnectionButton from './cancel-connection-button';
import ConnectionControl from './connection-control';

interface NewEventEventConnection extends EventEventConnectionDto {
  isNew?: boolean;
}

/**
 * FormControl to connect events with each other in {@link EventForm}.
 */
export default function EventEventConnectionControl() {
  const { t } = useTranslation('event');
  const [currentEventId, title, dateTimeRange] = useWatch<EventDto>({
    name: ['id', 'title', 'dateTimeRange'],
  });
  const fromEventReference = {
    id: currentEventId,
    title,
    dateTimeRange,
  } as EventReferenceDto;

  return (
    <ElementTableControl<EventDto, EventEventConnectionDto>
      label={t('eventConnections.label')}
      name="eventEventConnections"
      columns={eventEventColumns}
      addButton={<AddEventEventConnectionButton fromEventReference={fromEventReference} />}
      deleteButton={<EndOrDeleteConnectionButton fromEventReference={fromEventReference} />}
    />
  );
}

interface AddEventEventConnectionButtonProps extends Omit<ButtonProps, 'form'> {
  fromEventReference: EventReferenceDto;
}

const AddEventEventConnectionButton = forwardRef(
  (
    { fromEventReference, ...props }: AddEventEventConnectionButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const [status, eventEventConnections] = useWatch<EventDto>({
      name: ['status', 'eventEventConnections'],
    });
    const connections = eventEventConnections as EventEventConnectionDto[] | undefined;
    const { t } = useTranslation('event');

    return (
      <Tooltip label={t('eventConnections.add_tooltip')} isDisabled={status !== EventStatusDto.CANCELLED}>
        <Box
          width="full"
          display="block"
          outline="none"
          tabIndex={status === EventStatusDto.CANCELLED ? 0 : undefined}
          _focus={{ boxShadow: 'outline' }}
        >
          <AddElementButton
            {...props}
            label={t('eventConnections.add')}
            formModal={
              <EventEventConnectionFormModal
                fromEventReference={fromEventReference}
                eventEventConnections={connections}
              />
            }
            ref={ref}
            isDisabled={status === EventStatusDto.CANCELLED}
          />
        </Box>
      </Tooltip>
    );
  },
);

interface DeleteConnectionButtonProps {
  fromEventReference: EventReferenceDto;
}

function EndOrDeleteConnectionButton({ fromEventReference }: DeleteConnectionButtonProps) {
  const { t } = useTranslation('event');
  const { element } = useDefinedContext(ElementContext);
  // jaj, lh: Show delete button for unsaved connections and the cancel dialog for saved ones.
  return (element as NewEventEventConnection).isNew ? (
    <DeleteElementButton<EventEventConnectionDto>
      label={t('eventConnections.delete_connection')}
      renderDeleteMessage={(element) => (
        <Trans
          t={t}
          i18nKey="eventConnections.delete_message"
          values={{ connectedName: element.connectedEvent.title ?? t('forbiddenEventTitle') }}
        />
      )}
    />
  ) : (
    <CancelConnectionButton label={t('eventConnections.remove_connection')} fromEventReference={fromEventReference} />
  );
}

interface EventEventConnectionFormModalProps {
  fromEventReference: EventReferenceDto;
  eventEventConnections?: EventEventConnectionDto[];
}

function EventEventConnectionFormModal({
  fromEventReference,
  eventEventConnections,
}: EventEventConnectionFormModalProps) {
  const { element: eventEventConnection, onSubmit } = useElementForm<EventEventConnectionDto>();
  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const { t } = useTranslation('event');
  const isNew = eventEventConnection == null || (eventEventConnection as NewEventEventConnection).isNew;
  const handleSubmit = (eventEventConnection: EventEventConnectionDto) => {
    onSubmit({ ...eventEventConnection, isNew } as NewEventEventConnection);
  };

  return (
    <ElementFormModal<EventEventConnectionDto>
      onSubmit={handleSubmit}
      element={eventEventConnection}
      initialFocusRef={initialFocusRef}
    >
      <Stack spacing={4}>
        <ConnectionControl
          fromReference={fromEventReference}
          ref={initialFocusRef}
          connectedReferenceIds={eventEventConnections?.map((eec) => eec.connectedEvent.id)}
        />
        <Alert status="info">
          <AlertIcon />
          <AlertDescription>
            <Trans t={t} i18nKey="eventConnections.info" />
          </AlertDescription>
        </Alert>
      </Stack>
    </ElementFormModal>
  );
}
