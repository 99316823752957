import { ResponsiveValue } from '@chakra-ui/react';

export function getHelperTextSize(size: ResponsiveValue<string> | undefined) {
  if (size === 'md') {
    return 'sm';
  }

  if (size === 'sm') {
    return 'xs';
  }

  return size;
}
