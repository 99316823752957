import { Accordion, AccordionButton, AccordionItem, AccordionPanel, ExpandedIndex } from '@chakra-ui/react';
import React from 'react';
import { PersonDto } from '../../../api';
import AccordionIcon from '../../../ui/accordion/accordion-icon';
import { AccordionFormItem } from '../../../ui/form/use-accordion-form/use-accordion-form';
import AnchorLinkDestination from '../../../util/anchor-link-destination/anchor-link-destination';
import { LayoutType } from '../../common/LayoutType';
import { DuplicateWarning } from './duplicate-warning';
import PersonNameControl from './person-name-control';

export interface PersonInnerFormProps {
  layout: LayoutType;
  expandedIndices: ExpandedIndex;
  setExpandedIndices: React.Dispatch<React.SetStateAction<ExpandedIndex>>;
  accordionItems: AccordionFormItem<PersonDto>[];
  initialFocusRef?: React.RefObject<HTMLInputElement>;
}

export default function PersonInnerForm({
  layout,
  expandedIndices,
  setExpandedIndices,
  accordionItems,
  initialFocusRef,
}: PersonInnerFormProps) {
  return (
    <>
      <PersonNameControl layout={layout} initialFocusRef={initialFocusRef} />

      <Accordion variant="simple" allowMultiple mt={6} index={expandedIndices} onChange={setExpandedIndices}>
        {accordionItems.map((item) => (
          <AccordionItem key={item.name}>
            <AnchorLinkDestination name={item.name} />
            <AccordionButton>
              {item.button} <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>{item.panel}</AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>

      <DuplicateWarning />
    </>
  );
}
