import { FetchLandingPageTemplateHistoryRequest, LandingPageTemplateDto } from '../../api';
import landingPageTemplateApi from '../../data-access/landing-page-template-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';
import { LandingPageTemplateWithOrder } from './landing-page-template-history/use-landing-page-template-history-settings';

export const landingPageTemplateFetcher = createFetcherScope(landingPageTemplateApi);
export const fetchLandingPageTemplate = landingPageTemplateFetcher.createFetcher(
  landingPageTemplateApi.fetchLandingPageTemplate,
);
export const fetchLandingPageTemplates = landingPageTemplateFetcher.createFetcher(
  landingPageTemplateApi.searchLandingPageTemplates,
);
export const fetchLandingPageTemplateHistory = landingPageTemplateFetcher.createFetcher(
  async (request: FetchLandingPageTemplateHistoryRequest) => {
    const landingPageTemplateHistoryPage = await landingPageTemplateApi.fetchLandingPageTemplateHistory(request);

    return {
      ...landingPageTemplateHistoryPage,
      content: landingPageTemplateHistoryPage.content.map((landingPageTemplateHistoryEntry) => {
        const { after, before } = landingPageTemplateHistoryEntry;

        return {
          ...landingPageTemplateHistoryEntry,
          before: addModuleSettingsOrder(before),
          after: addModuleSettingsOrder(after),
        };
      }),
    };
  },
);
export const fetchModuleConstraints = landingPageTemplateFetcher.createFetcher(
  landingPageTemplateApi.fetchLandingPageModuleConstraints,
);
export const fetchLandingPageLoginOptions = landingPageTemplateFetcher.createFetcher(
  landingPageTemplateApi.fetchLandingPageLoginOptions,
);

function addModuleSettingsOrder(
  landingPageTemplate: LandingPageTemplateDto | undefined,
): LandingPageTemplateWithOrder | undefined {
  if (landingPageTemplate == null) {
    return undefined;
  }

  return {
    ...landingPageTemplate,
    moduleSettings: landingPageTemplate.moduleSettings.map((module, index) => ({
      ...module,
      // Add 1 to the index to make it 1-based
      order: index + 1,
    })),
  };
}
