/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AwardWinnerPageDto,
  AwardWinnerRelationDto,
  FailureDto,
  HistoryPageDto,
  PageableDto,
} from '../models/index';
import {
    AwardWinnerPageDtoFromJSON,
    AwardWinnerPageDtoToJSON,
    AwardWinnerRelationDtoFromJSON,
    AwardWinnerRelationDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface CreateAwardWinnerRelationRequest {
    awardWinnerRelationDto: AwardWinnerRelationDto;
}

export interface DeleteAwardWinnersByIdsRequest {
    ids: Array<string>;
}

export interface FetchAwardWinnerHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface SearchAwardWinnerRelationsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

/**
 * 
 */
export class AwardWinnerApi extends runtime.BaseAPI {

    /**
     * Create a award winner relation.
     */
    async createAwardWinnerRelationRaw(requestParameters: CreateAwardWinnerRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AwardWinnerRelationDto>> {
        if (requestParameters['awardWinnerRelationDto'] == null) {
            throw new runtime.RequiredError(
                'awardWinnerRelationDto',
                'Required parameter "awardWinnerRelationDto" was null or undefined when calling createAwardWinnerRelation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/award-winner-relations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AwardWinnerRelationDtoToJSON(requestParameters['awardWinnerRelationDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AwardWinnerRelationDtoFromJSON(jsonValue));
    }

    /**
     * Create a award winner relation.
     */
    async createAwardWinnerRelation(requestParameters: CreateAwardWinnerRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AwardWinnerRelationDto> {
        const response = await this.createAwardWinnerRelationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteAwardWinnersByIdsRaw(requestParameters: DeleteAwardWinnersByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['ids'] == null) {
            throw new runtime.RequiredError(
                'ids',
                'Required parameter "ids" was null or undefined when calling deleteAwardWinnersByIds().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ids'] != null) {
            queryParameters['ids'] = requestParameters['ids']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/award-winner-relations/delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteAwardWinnersByIds(requestParameters: DeleteAwardWinnersByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAwardWinnersByIdsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async fetchAwardWinnerHistoryRaw(requestParameters: FetchAwardWinnerHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchAwardWinnerHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/award-winner-relations/versions-by-award/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchAwardWinnerHistory(requestParameters: FetchAwardWinnerHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchAwardWinnerHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for award winner relations.
     */
    async searchAwardWinnerRelationsRaw(requestParameters: SearchAwardWinnerRelationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AwardWinnerPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/award-winner-relations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AwardWinnerPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for award winner relations.
     */
    async searchAwardWinnerRelations(requestParameters: SearchAwardWinnerRelationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AwardWinnerPageDto> {
        const response = await this.searchAwardWinnerRelationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
