/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ConfirmationStatusDto = {
    CONFIRMED: 'CONFIRMED',
    UNCONFIRMED: 'UNCONFIRMED'
} as const;
export type ConfirmationStatusDto = typeof ConfirmationStatusDto[keyof typeof ConfirmationStatusDto];


export function ConfirmationStatusDtoFromJSON(json: any): ConfirmationStatusDto {
    return ConfirmationStatusDtoFromJSONTyped(json, false);
}

export function ConfirmationStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfirmationStatusDto {
    return json as ConfirmationStatusDto;
}

export function ConfirmationStatusDtoToJSON(value?: ConfirmationStatusDto | null): any {
    return value as any;
}

