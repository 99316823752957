/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { LandingPageRsvpModuleSettingsDto } from './LandingPageRsvpModuleSettingsDto';
import {
    instanceOfLandingPageRsvpModuleSettingsDto,
    LandingPageRsvpModuleSettingsDtoFromJSON,
    LandingPageRsvpModuleSettingsDtoFromJSONTyped,
    LandingPageRsvpModuleSettingsDtoToJSON,
} from './LandingPageRsvpModuleSettingsDto';
import type { LandingPageTextModuleSettingsDto } from './LandingPageTextModuleSettingsDto';
import {
    instanceOfLandingPageTextModuleSettingsDto,
    LandingPageTextModuleSettingsDtoFromJSON,
    LandingPageTextModuleSettingsDtoFromJSONTyped,
    LandingPageTextModuleSettingsDtoToJSON,
} from './LandingPageTextModuleSettingsDto';

/**
 * @type LandingPageModuleSettingsDto
 * 
 * @export
 */
export type LandingPageModuleSettingsDto = { moduleType: 'rsvp' } & LandingPageRsvpModuleSettingsDto | { moduleType: 'text' } & LandingPageTextModuleSettingsDto;

export function LandingPageModuleSettingsDtoFromJSON(json: any): LandingPageModuleSettingsDto {
    return LandingPageModuleSettingsDtoFromJSONTyped(json, false);
}

export function LandingPageModuleSettingsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageModuleSettingsDto {
    if (json == null) {
        return json;
    }
    switch (json['moduleType']) {
        case 'rsvp':
            return {...LandingPageRsvpModuleSettingsDtoFromJSONTyped(json, true), moduleType: 'rsvp'};
        case 'text':
            return {...LandingPageTextModuleSettingsDtoFromJSONTyped(json, true), moduleType: 'text'};
        default:
            throw new Error(`No variant of LandingPageModuleSettingsDto exists with 'moduleType=${json['moduleType']}'`);
    }
}

export function LandingPageModuleSettingsDtoToJSON(value?: LandingPageModuleSettingsDto | null): any {
    if (value == null) {
        return value;
    }
    switch (value['moduleType']) {
        case 'rsvp':
            return LandingPageRsvpModuleSettingsDtoToJSON(value);
        case 'text':
            return LandingPageTextModuleSettingsDtoToJSON(value);
        default:
            throw new Error(`No variant of LandingPageModuleSettingsDto exists with 'moduleType=${value['moduleType']}'`);
    }

}

