import { ExpandedIndex } from '@chakra-ui/react';
import { uniq } from 'lodash-es';
import React from 'react';
import { FieldPath, FieldValues, get, SubmitErrorHandler } from 'react-hook-form';

export interface AccordionFormItem<TFieldValues extends FieldValues> {
  name: string;
  button: React.ReactNode;
  panel?: React.ReactNode;
  controls?: FieldPath<TFieldValues>[];
}

export default function useAccordionForm<TFieldValues extends FieldValues>(
  accordionItems: AccordionFormItem<TFieldValues>[],
  setExpandedIndices: React.Dispatch<React.SetStateAction<ExpandedIndex>>,
) {
  const handleInvalid = React.useCallback<SubmitErrorHandler<TFieldValues>>(
    (errors) => {
      const invalidAccordionItemIndices = accordionItems.reduce<number[]>(
        (invalidAccordionItemIndices, accordionItem, index) => {
          if (accordionItem.controls?.some((name) => get(errors, name) != null)) {
            return [...invalidAccordionItemIndices, index];
          }

          return invalidAccordionItemIndices;
        },
        [],
      );

      setExpandedIndices((prevIndices) => {
        if (!Array.isArray(prevIndices)) {
          prevIndices = [prevIndices];
        }

        return uniq([...prevIndices, ...invalidAccordionItemIndices]);
      });
    },
    [accordionItems, setExpandedIndices],
  );

  return { handleInvalid };
}
