import { eventIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import PERSON_CHILD_ROUTES from '../person/person-child-routes';
import { PERSON_TAB_ITEM, PersonTabItem } from '../person/person-page/person-page';
import eventsOfPersonRoutes from './events-of-person-routes';

export const eventsOfPersonTabItem: PersonTabItem = {
  order: 2,
  label: <Translate ns="events_of_person" i18nKey="lister.title" />,
  icon: eventIcon,
  to: './events-of-person',
};

export default function EventsOfPersonProvider(registry: PluginRegistry) {
  registry.register(PERSON_TAB_ITEM, eventsOfPersonTabItem);
  registry.register(PERSON_CHILD_ROUTES, eventsOfPersonRoutes);
}
