/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentTargetDto
 */
export interface PaymentTargetDto {
    /**
     * 
     * @type {number}
     * @memberof PaymentTargetDto
     */
    grossPrice: number;
    /**
     * 
     * @type {Date}
     * @memberof PaymentTargetDto
     */
    dueDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof PaymentTargetDto
     */
    internalDueDate: Date;
}

/**
 * Check if a given object implements the PaymentTargetDto interface.
 */
export function instanceOfPaymentTargetDto(value: object): boolean {
    if (!('grossPrice' in value)) return false;
    if (!('dueDate' in value)) return false;
    if (!('internalDueDate' in value)) return false;
    return true;
}

export function PaymentTargetDtoFromJSON(json: any): PaymentTargetDto {
    return PaymentTargetDtoFromJSONTyped(json, false);
}

export function PaymentTargetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentTargetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'grossPrice': json['grossPrice'],
        'dueDate': (new Date(json['dueDate'])),
        'internalDueDate': (new Date(json['internalDueDate'])),
    };
}

export function PaymentTargetDtoToJSON(value?: PaymentTargetDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'grossPrice': value['grossPrice'],
        'dueDate': ((value['dueDate']).toISOString().substring(0,10)),
        'internalDueDate': ((value['internalDueDate']).toISOString().substring(0,10)),
    };
}

