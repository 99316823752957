import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EventEvaluationDto, EventEvaluationStatusDto, PublicEventEvaluationDto } from '../../../api';
import eventEvaluationApi from '../../../data-access/event-evaluation-api';
import useToast from '../../../ui/use-toast/use-toast';
import useHistoryBack from '../../../util/history/use-history-back';
import { EventEvaluationEditorAction } from '../../event-evaluation/event-evaluation-editor/event-evaluation-editor';
import EventEvaluationForm from '../../event-evaluation/event-evaluation-form/event-evaluation-form';

/**
 * Event evaluation editor.
 */
interface PublicEventEvaluationEditorProps {
  publicEventEvaluation: PublicEventEvaluationDto;
}

export default function PublicEventEvaluationEditor({ publicEventEvaluation }: PublicEventEvaluationEditorProps) {
  const { t } = useTranslation('event_evaluation');

  const navigate = useNavigate();
  const back = useHistoryBack();

  const showSuccessToast = useToast({
    id: 'public-event-evaluation-editor-success-toast',
    status: 'success',
  });

  const fetchEventEvaluation = async (id: string): Promise<EventEvaluationDto> => {
    const publicEventEvaluation = eventEvaluationApi.fetchPublicEvaluation({ id });
    return (await publicEventEvaluation).eventEvaluation;
  };

  const handleSave = async (
    eventEvaluationDto: EventEvaluationDto,
    alreadySavedEvaluation: EventEvaluationDto | undefined,
    saveAction: EventEvaluationEditorAction,
  ) => {
    const id = eventEvaluationDto.id!;

    showSuccessToast.closeAll();

    let dto = eventEvaluationDto;
    if (alreadySavedEvaluation != null) {
      dto = { ...eventEvaluationDto, version: alreadySavedEvaluation.version };
    }
    await eventEvaluationApi.updateEvaluation({ id, eventEvaluationDto: dto });

    showSuccessToast({
      title: t('toast.success.title'),
      description: t(`toast.success.description.${saveAction}`),
    });

    navigate(back('..'));
  };

  const handleEditAbort = async () => {
    navigate(back('..'));
  };

  return (
    <EventEvaluationForm
      eventEvaluation={publicEventEvaluation.eventEvaluation}
      editorAction={
        publicEventEvaluation.eventEvaluation.status === EventEvaluationStatusDto.PENDING
          ? EventEvaluationEditorAction.NEW
          : EventEvaluationEditorAction.EDIT
      }
      eventDateTimeRange={publicEventEvaluation.eventSettings.eventReference.dateTimeRange}
      onSave={handleSave}
      onEditAbort={handleEditAbort}
      fetchEventEvaluation={fetchEventEvaluation}
    />
  );
}
