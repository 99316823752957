import { StyleConfig } from '@chakra-ui/theme-tools';

const Heading: StyleConfig = {
  baseStyle: {
    color: 'heading',
  },
  sizes: {
    md: {
      fontWeight: 'semibold',
    },
    sm: {
      fontWeight: 'semibold',
    },
  },
};

export default Heading;
