/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TagDto } from './TagDto';
import {
    TagDtoFromJSON,
    TagDtoFromJSONTyped,
    TagDtoToJSON,
} from './TagDto';

/**
 * 
 * @export
 * @interface TagSearchResultDto
 */
export interface TagSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof TagSearchResultDto
     */
    resultType: TagSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof TagSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {TagDto}
     * @memberof TagSearchResultDto
     */
    result: TagDto;
}


/**
 * @export
 */
export const TagSearchResultDtoResultTypeEnum = {
    TAG: 'TAG'
} as const;
export type TagSearchResultDtoResultTypeEnum = typeof TagSearchResultDtoResultTypeEnum[keyof typeof TagSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the TagSearchResultDto interface.
 */
export function instanceOfTagSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function TagSearchResultDtoFromJSON(json: any): TagSearchResultDto {
    return TagSearchResultDtoFromJSONTyped(json, false);
}

export function TagSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': TagDtoFromJSON(json['result']),
    };
}

export function TagSearchResultDtoToJSON(value?: TagSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': TagDtoToJSON(value['result']),
    };
}

