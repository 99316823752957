export default function listFormatter(
  value: string[],
  lng?: string,
  { prefix, suffix, ...options }: { prefix?: string; suffix?: string } & Intl.ListFormatOptions = {},
) {
  if (prefix != null || suffix != null) {
    value = value.map((value) => `${prefix ?? ''}${value}${suffix ?? ''}`);
  }

  return new Intl.ListFormat(lng, { ...options }).format(value);
}
