import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useVisaPermissionClassGroupExtension(): PermissionClassGroupExtension<'VISA'> {
  const { t } = useTranslation(['visa']);

  return {
    name: 'VISA',
    label: t('visa:visa'),
    getActionLabel: (action) => t(`visa:permission.${action}`),
    getAdditionalRequirement: (action) => t(`visa:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`visa:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
