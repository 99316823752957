import { MenuDivider, MenuItem } from '@chakra-ui/react';
import { faCopy, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { EventDto, EventListItemDto, EventStatusCheckDto } from '../../api';
import usePermission from '../permission/use-permission';
import EventDeleteButton from './event-delete/event-delete-button';

export default function useEventActions(onSuccess: () => void) {
  const { t } = useTranslation(['event']);
  //TODO keys
  const { hasPermission, hasPermissionFromSection } = usePermission();

  return useCallback(
    (event: EventDto | EventListItemDto, statusCheck: EventStatusCheckDto) => {
      const actions: ReactNode[] = [];

      const sectionIds = 'owners' in event ? event.owners.map((owner) => owner.section.id) : event.ownerSectionIds;
      const canCopy =
        hasPermission('EVENT:CAN_COPY') ||
        sectionIds.some((sectionId) => hasPermissionFromSection(sectionId, 'EVENT:CAN_COPY_OWN'));
      const canDelete =
        hasPermission('EVENT:CAN_DELETE') ||
        sectionIds.some((sectionId) => hasPermissionFromSection(sectionId, 'EVENT:CAN_DELETE_OWN'));

      if (canCopy) {
        actions.push(
          <MenuItem
            icon={<FontAwesomeIcon icon={faCopy} fixedWidth />}
            as={RouterLink}
            to={`/events/${event.id}/copy`}
            key="copyEvent"
          >
            {t('event:action.copy')}
          </MenuItem>,
        );
      }

      if (canCopy && canDelete) {
        actions.push(<MenuDivider key="divideEtImpera" />);
      }

      if (canDelete) {
        actions.push(
          <MenuItem
            as={EventDeleteButton}
            isDeletable={statusCheck.isDeletable}
            connectedEvents={statusCheck.connectedEvents}
            hasGuestList={statusCheck.hasGuestList}
            hasPersonInRundown={statusCheck.hasPersonInRundown}
            hasKeyPlayer={statusCheck.hasKeyPlayer}
            hasLiveTicketingAttendee={statusCheck.hasLiveTicketingAttendee}
            hasMailing={statusCheck.hasMailing}
            color="text.error"
            event={event}
            onSuccess={onSuccess}
            icon={<FontAwesomeIcon icon={faTrashAlt} fixedWidth />}
            key="deleteEvent"
          >
            {t('event:action.delete')}
          </MenuItem>,
        );
      }
      return actions;
    },
    [hasPermission, hasPermissionFromSection, onSuccess, t],
  );
}
