/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The type and subtype of an event
 * @export
 */
export const EventTypeDto = {
    MOVIE_SCREENING_GALA_PREMIERE: 'MOVIE_SCREENING_GALA_PREMIERE',
    MOVIE_SCREENING_PREMIERE: 'MOVIE_SCREENING_PREMIERE',
    MOVIE_SCREENING_REPETITION: 'MOVIE_SCREENING_REPETITION',
    MEETING_GROUP_MEETING: 'MEETING_GROUP_MEETING',
    MEETING_MEET_UP: 'MEETING_MEET_UP',
    MEETING_ONE_ON_ONE: 'MEETING_ONE_ON_ONE',
    MEETING_ROUND_TABLE: 'MEETING_ROUND_TABLE',
    MEETING_THINK_TANK: 'MEETING_THINK_TANK',
    MEETING_WORKSHOP: 'MEETING_WORKSHOP',
    NETWORKING_DINNER: 'NETWORKING_DINNER',
    NETWORKING_RECEPTION: 'NETWORKING_RECEPTION',
    NETWORKING_HAPPY_HOUR: 'NETWORKING_HAPPY_HOUR',
    NETWORKING_LOUNGE: 'NETWORKING_LOUNGE',
    NETWORKING_LUNCH: 'NETWORKING_LUNCH',
    NETWORKING_PARTY: 'NETWORKING_PARTY',
    NETWORKING_WARM_UP: 'NETWORKING_WARM_UP',
    AWARD_CEREMONY_BERLINALE: 'AWARD_CEREMONY_BERLINALE',
    AWARD_CEREMONY_MISC: 'AWARD_CEREMONY_MISC',
    PRESS_LAUNCH_PRESS_CONFERENCE: 'PRESS_LAUNCH_PRESS_CONFERENCE',
    PRESS_LAUNCH_DIRECTORS_TALK: 'PRESS_LAUNCH_DIRECTORS_TALK',
    PRESS_LAUNCH_INTERVIEW: 'PRESS_LAUNCH_INTERVIEW',
    PODIUM_DISCUSSION_LIVE_WORKSHOP: 'PODIUM_DISCUSSION_LIVE_WORKSHOP',
    PODIUM_DISCUSSION_PITCH: 'PODIUM_DISCUSSION_PITCH',
    PODIUM_DISCUSSION_PRESENTATION: 'PODIUM_DISCUSSION_PRESENTATION',
    PODIUM_DISCUSSION_SHOW_CASE: 'PODIUM_DISCUSSION_SHOW_CASE',
    PODIUM_DISCUSSION_TALK: 'PODIUM_DISCUSSION_TALK'
} as const;
export type EventTypeDto = typeof EventTypeDto[keyof typeof EventTypeDto];


export function EventTypeDtoFromJSON(json: any): EventTypeDto {
    return EventTypeDtoFromJSONTyped(json, false);
}

export function EventTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventTypeDto {
    return json as EventTypeDto;
}

export function EventTypeDtoToJSON(value?: EventTypeDto | null): any {
    return value as any;
}

