import { StaffListItemDto } from '../../../api';
import { ParentDataTableColumn } from '../../../ui/data-table/data-table-column';
import StringFilter from '../../../ui/data-table/filter/string-filter';
import Translate from '../../../util/translate/translate';
import PersonStaffReference from './person-staff-reference';

export const staffListerPersonColumn: ParentDataTableColumn<StaffListItemDto> = {
  key: 'person',
  name: <Translate ns="staff" i18nKey="person" />,
  cellProps: {
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
  renderCell: (data: StaffListItemDto) => <PersonStaffReference staff={data} flipName />,
  filter: (
    <StringFilter
      label={<Translate ns="staff" i18nKey="person" />}
      availableOperators={['CONTAIN', 'NOT_CONTAIN', 'START_WITH', 'NOT_START_WITH', 'END_WITH', 'NOT_END_WITH']}
    />
  ),
  isSortable: true,
  sortProperty: 'person.surname',
};
