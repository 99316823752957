import {
  CreatableProps,
  CreatableSelect,
  GroupBase,
  Select as ChakraReactSelect,
  SelectInstance,
} from 'chakra-react-select';
import React from 'react';
import invariant from 'tiny-invariant';
import useSelectProps from './use-select-props';

export interface SelectProps<TOption, TIsMulti extends boolean, TGroup extends GroupBase<TOption>>
  extends CreatableProps<TOption, TIsMulti, TGroup> {
  enableCreate?: boolean;
}

function Select<TOption, TIsMulti extends boolean = false, TGroup extends GroupBase<TOption> = GroupBase<TOption>>(
  { enableCreate = false, ...props }: Omit<SelectProps<TOption, TIsMulti, TGroup>, 'variant'>,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const selectRef = React.useRef<SelectInstance<TOption, TIsMulti, TGroup>>(null);
  const selectProps = useSelectProps(props);

  React.useImperativeHandle(ref, () => {
    invariant(selectRef.current?.inputRef != null);

    return selectRef.current.inputRef;
  });

  if (enableCreate) {
    return <CreatableSelect ref={selectRef} {...selectProps} />;
  } else {
    return <ChakraReactSelect ref={selectRef} {...selectProps} />;
  }
}

export default React.forwardRef(Select);
