import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { companyIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function CompanySearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'COMPANY', `${result.resultType} is no instance of CompanySearchResult`);
  const company = result.result;
  const { t } = useTranslation(['company', 'common']);

  return (
    <SearchResult
      icon={companyIcon}
      title={company.name}
      link={`/companies/${company.id}`}
      score={result.score}
      onClick={onClick}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('company:shortDescription'),
            element: company.shortDescription,
          },
          {
            label: t('company:synonyms.label'),
            element: t('common:format.list', {
              values: company.synonyms,
              type: 'disjunction',
              style: 'short',
            }),
          },
          {
            label: t('company:namesForPublication.header'),
            element: t('common:format.list', {
              values: company.namesForPublication?.map(({ name }) => name),
              type: 'disjunction',
              style: 'short',
            }),
          },
          {
            label: t('company:status'),
            element: t(`company:statusLabel.${company.status}`),
          },
        ]}
      />
    </SearchResult>
  );
}
