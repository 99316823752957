import {
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  InputProps,
  InputRightAddon,
  Textarea,
  useMergeRefs,
} from '@chakra-ui/react';
import React from 'react';
import { FieldValues, Path, useController, ValidateResult, ValidationValueMessage } from 'react-hook-form';
import { FieldPath } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';
import { ReactSelectVariant } from '../../theme/component/react-select';
import { CONTAINS_LETTER_OR_DIGIT } from '../../util/constants';
import FormControl, { FormControlProps } from './form-control';

export type Validate<TFieldValues extends FieldValues> = (
  value: string,
  data: TFieldValues,
) => ValidateResult | JSX.Element | Promise<ValidateResult | JSX.Element>;

export interface InputFormControlProps<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  label?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  showRequiredValidation?: boolean;
  isMultiline?: boolean;
  minLength?: number;
  maxLength?: number;
  isOptionalHelperText?: boolean;
  pattern?: ValidationValueMessage<RegExp | string>;
  validate?: Validate<TFieldValues> | Record<string, Validate<TFieldValues>>;
  helperText?: React.ReactNode;
  helperPopover?: React.ReactElement;
  placeholder?: InputProps['placeholder'];
  type?: InputProps['type'];
  deps?: FieldPath<TFieldValues>[];
  size?: FormControlProps<TFieldValues>['size'];

  transformValue?(value: string): string;

  variant?: ReactSelectVariant;

  onBlur?(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void;

  ariaLabel?: string;
  suffix?: React.ReactElement;
  leftAddOn?: React.ReactNode;
  rightAddOn?: React.ReactNode;

  onChange?(value: string | null): void;

  setNullOnEmptyInput?: boolean;
}

function InputFormControl<TFieldValues extends FieldValues>(
  {
    isRequired,
    showRequiredValidation = isRequired,
    name,
    label,
    isDisabled,
    isMultiline,
    minLength,
    maxLength,
    isOptionalHelperText,
    pattern,
    validate,
    helperText: helperTextProp,
    helperPopover,
    placeholder,
    type,
    deps,
    size,
    transformValue,
    variant,
    onBlur,
    ariaLabel,
    suffix,
    leftAddOn,
    rightAddOn,
    onChange,
    setNullOnEmptyInput = false,
  }: InputFormControlProps<TFieldValues>,
  ref: React.ForwardedRef<HTMLInputElement | HTMLTextAreaElement>,
) {
  const { t } = useTranslation('common');
  const customValidation = typeof validate === 'function' ? { custom: validate } : validate;
  const { field } = useController({
    name,
    rules: {
      deps,
      required: showRequiredValidation ? t('validation_error.required', { field: label }) : undefined,
      minLength: minLength && {
        value: minLength,
        message: t('validation_error.min_length', {
          field: label,
          count: minLength,
        }),
      },
      maxLength: maxLength && {
        value: maxLength,
        message: t('validation_error.max_length', {
          field: label,
          count: maxLength,
        }),
      },
      pattern: pattern && { value: RegExp(pattern.value), message: pattern.message },
      // React-Hook-Forms API does not allow JSX Elements as ValidationResults, but seems to work anyway
      validate: {
        containsLetterOrDigit: (value) =>
          !value || CONTAINS_LETTER_OR_DIGIT.test(value) || t('validation_error.containsLetterOrDigit'),
        ...customValidation,
      },
    },
  });
  const mergedRef = useMergeRefs(ref, field.ref);

  const helperText = React.useMemo(() => {
    if (minLength == null && maxLength == null) {
      return helperTextProp;
    }

    let validationHelpText = '';

    if (minLength != null && maxLength != null) {
      validationHelpText = t('validation_help.min_max_length', {
        minCount: minLength,
        maxCount: maxLength,
      });
    } else if (minLength != null) {
      validationHelpText = t('validation_help.min_length', {
        count: minLength,
      });
    } else if (maxLength != null) {
      validationHelpText = t('validation_help.max_length', {
        count: maxLength,
      });
    }

    return `${helperTextProp != null ? `${helperTextProp} ` : ''}${validationHelpText}${
      isOptionalHelperText ? ' ' + t('validation_help.optional') : ''
    }`;
  }, [maxLength, minLength, isOptionalHelperText, helperTextProp, t]);

  const handleChange = (newValue: string) => {
    const value = setNullOnEmptyInput ? (newValue === '' ? null : newValue) : newValue;

    field.onChange(value);
    onChange?.(value);
  };

  const props = {
    ...field,
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value = event.target.value;

      value = value.trimStart();

      // remove emojis
      value = value.replaceAll(/\p{Emoji_Presentation}/gu, '');

      value = transformValue?.(value) ?? value;

      handleChange(value);
    },
    onBlur: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onBlur?.(event);

      if (!event.defaultPrevented) {
        const value = event.target.value.trimEnd();

        if (value !== '' && value !== event.target.value) {
          handleChange(value);
        }

        field.onBlur();
      }
    },
    value: transformValue?.(field.value ?? '') ?? field.value ?? '',
    placeholder: placeholder,
    required: isRequired,
    size,
    variant,
    ref: mergedRef,
  };

  return (
    <FormControl
      name={name}
      label={label}
      helperText={helperText}
      isDisabled={isDisabled}
      isRequired={isRequired}
      size={size}
      variant={variant}
      helperPopover={helperPopover}
      aria-label={ariaLabel}
    >
      <Flex gap={1}>
        {isMultiline ? (
          <Textarea {...props} />
        ) : (
          <InputGroup>
            {leftAddOn && <InputLeftAddon children={leftAddOn} />}
            <Input {...props} type={type} />
            {rightAddOn && <InputRightAddon children={rightAddOn} />}
          </InputGroup>
        )}
        {suffix}
      </Flex>
    </FormControl>
  );
}

export default React.forwardRef(InputFormControl);
