/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const KeyPlayerRoleDto = {
    WELCOME_RED_CARPET: 'WELCOME_RED_CARPET',
    WELCOME_VIP_SPACE_PRESS_CONFERENCE: 'WELCOME_VIP_SPACE_PRESS_CONFERENCE',
    VISION_MIXER: 'VISION_MIXER',
    INTERPRETER: 'INTERPRETER',
    FILM_INTRODUCTION: 'FILM_INTRODUCTION',
    GUEST_LIAISON_OFFICER: 'GUEST_LIAISON_OFFICER',
    LOCATION_MANAGEMENT: 'LOCATION_MANAGEMENT',
    MEDIA_COORDINATOR: 'MEDIA_COORDINATOR',
    HOST: 'HOST',
    HOST_QA_ONLY: 'HOST_QA_ONLY',
    PANELIST: 'PANELIST',
    DIRECTOR: 'DIRECTOR',
    RED_CARPET_PHOTOGRAPHER: 'RED_CARPET_PHOTOGRAPHER',
    RED_CARPET_CAMERA: 'RED_CARPET_CAMERA',
    RED_CARPET_MANAGEMENT: 'RED_CARPET_MANAGEMENT',
    RED_CARPET_PUBLICIST: 'RED_CARPET_PUBLICIST',
    BERLINALE_SECURITY: 'BERLINALE_SECURITY',
    SPEAKER: 'SPEAKER',
    TECHNICIAN: 'TECHNICIAN',
    ZDF_PARTNER: 'ZDF_PARTNER',
    OTHERS: 'OTHERS'
} as const;
export type KeyPlayerRoleDto = typeof KeyPlayerRoleDto[keyof typeof KeyPlayerRoleDto];


export function KeyPlayerRoleDtoFromJSON(json: any): KeyPlayerRoleDto {
    return KeyPlayerRoleDtoFromJSONTyped(json, false);
}

export function KeyPlayerRoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KeyPlayerRoleDto {
    return json as KeyPlayerRoleDto;
}

export function KeyPlayerRoleDtoToJSON(value?: KeyPlayerRoleDto | null): any {
    return value as any;
}

