/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const WorkingSectorDto = {
    ANIMATION: 'ANIMATION',
    DOCUMENTARY_FILM: 'DOCUMENTARY_FILM',
    EXPERIMENTAL_FILM: 'EXPERIMENTAL_FILM',
    TV: 'TV',
    GAMING: 'GAMING',
    IMMERSIVE_MEDIA_VR_XR_AR: 'IMMERSIVE_MEDIA_VR_XR_AR',
    INFLIGHT: 'INFLIGHT',
    CHILDREN_YOUTH: 'CHILDREN_YOUTH',
    SHORT_FILM: 'SHORT_FILM',
    LGBTQIA_PLUS: 'LGBTQIA_PLUS',
    LITERATURE: 'LITERATURE',
    MUSIC: 'MUSIC',
    PODCAST: 'PODCAST',
    PRESS_ONLINE: 'PRESS_ONLINE',
    PRESS_PODCAST: 'PRESS_PODCAST',
    PRESS_PR: 'PRESS_PR',
    PRESS_PRINT: 'PRESS_PRINT',
    PRESS_RADIO_AUDIO: 'PRESS_RADIO_AUDIO',
    PRESS_SOCIAL_MEDIA: 'PRESS_SOCIAL_MEDIA',
    PRESS_SOCIAL_MEDIA_VIDEO: 'PRESS_SOCIAL_MEDIA_VIDEO',
    PRESS_TV_VIDEO: 'PRESS_TV_VIDEO',
    PRESS_WEB_TV: 'PRESS_WEB_TV',
    SERIES: 'SERIES',
    FEATURE_FILM: 'FEATURE_FILM',
    VIDEO_ON_DEMAND: 'VIDEO_ON_DEMAND',
    OTHER: 'OTHER'
} as const;
export type WorkingSectorDto = typeof WorkingSectorDto[keyof typeof WorkingSectorDto];


export function WorkingSectorDtoFromJSON(json: any): WorkingSectorDto {
    return WorkingSectorDtoFromJSONTyped(json, false);
}

export function WorkingSectorDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkingSectorDto {
    return json as WorkingSectorDto;
}

export function WorkingSectorDtoToJSON(value?: WorkingSectorDto | null): any {
    return value as any;
}

