import { MenuItem } from '@chakra-ui/react';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import placementTemplateApi from '../../../../data-access/placement-template-api';
import FinalDeleteConfirmDialog, {
  createDefaultDeleteChecklistItem,
} from '../../../../ui/dialog/final-delete-confirm-dialog';
import useToast from '../../../../ui/use-toast/use-toast';
import useDialog from '../../../../util/use-dialog/use-dialog';

interface Properties {
  locationId: string;
  templateId: string;
  templateName: string;
  onSuccess(): void;
}

export default function DeletePlacementTemplateMenuItem({
  locationId,
  templateId,
  templateName,
  onSuccess,
}: Properties) {
  const [dialogIsOpen, onDialogClose, openDialog] = useDialog<false>();
  const { t } = useTranslation('placement_template');

  const successToast = useToast({
    id: 'delete-placement-template-success-toast',
    status: 'success',
  });

  async function deletePlacement() {
    invariant(locationId != null, 'Missing location id');

    const checked = await openDialog();
    if (checked) {
      await placementTemplateApi.deletePlacementTemplate(
        {
          parentId: locationId,
          id: templateId,
        },
        { allowedErrorCodes: [409] } as RequestInit,
      );

      successToast({
        title: t('delete.toast.title'),
        description: t('delete.toast.description'),
      });

      onSuccess();
    }
  }

  const confirmationMessageChecklistItems = [
    createDefaultDeleteChecklistItem({
      objectTypeWithArticleGenitive: t('placement_template_with_article'),
      objectToDelete: templateName,
      additionalInfo: t('delete.confirmation_additional_infos'),
    }),
  ];

  return (
    <MenuItem color="text.error" icon={<FontAwesomeIcon icon={faTrashAlt} />} onClick={deletePlacement}>
      {t('delete.submit')}
      <FinalDeleteConfirmDialog
        isOpen={dialogIsOpen}
        objectTypeToDelete={t('placement_template')}
        confirmationCheckListItems={confirmationMessageChecklistItems}
        onClose={onDialogClose}
      />
    </MenuItem>
  );
}
