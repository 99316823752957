/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The result of a activation toggle check.
 * @export
 * @interface ActivationToggleCheckDto
 */
export interface ActivationToggleCheckDto {
    /**
     * 
     * @type {boolean}
     * @memberof ActivationToggleCheckDto
     */
    readonly canToggle: boolean;
}

/**
 * Check if a given object implements the ActivationToggleCheckDto interface.
 */
export function instanceOfActivationToggleCheckDto(value: object): boolean {
    if (!('canToggle' in value)) return false;
    return true;
}

export function ActivationToggleCheckDtoFromJSON(json: any): ActivationToggleCheckDto {
    return ActivationToggleCheckDtoFromJSONTyped(json, false);
}

export function ActivationToggleCheckDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivationToggleCheckDto {
    if (json == null) {
        return json;
    }
    return {
        
        'canToggle': json['canToggle'],
    };
}

export function ActivationToggleCheckDtoToJSON(value?: ActivationToggleCheckDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
    };
}

