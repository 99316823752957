/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ListOperatorTypeDto } from './ListOperatorTypeDto';
import {
    ListOperatorTypeDtoFromJSON,
    ListOperatorTypeDtoFromJSONTyped,
    ListOperatorTypeDtoToJSON,
} from './ListOperatorTypeDto';
import type { OperationDto } from './OperationDto';
import {
    OperationDtoFromJSON,
    OperationDtoFromJSONTyped,
    OperationDtoToJSON,
} from './OperationDto';
import type { OperationTypeDto } from './OperationTypeDto';
import {
    OperationTypeDtoFromJSON,
    OperationTypeDtoFromJSONTyped,
    OperationTypeDtoToJSON,
} from './OperationTypeDto';
import type { OperatorTypeDto } from './OperatorTypeDto';
import {
    OperatorTypeDtoFromJSON,
    OperatorTypeDtoFromJSONTyped,
    OperatorTypeDtoToJSON,
} from './OperatorTypeDto';

/**
 * 
 * @export
 * @interface ConstantOperationDto
 */
export interface ConstantOperationDto {
    /**
     * 
     * @type {OperationTypeDto}
     * @memberof ConstantOperationDto
     */
    operationType: OperationTypeDto;
    /**
     * 
     * @type {Array<OperationDto>}
     * @memberof ConstantOperationDto
     */
    subOperations: Array<OperationDto>;
    /**
     * 
     * @type {string}
     * @memberof ConstantOperationDto
     */
    stringValue?: string;
    /**
     * 
     * @type {Date}
     * @memberof ConstantOperationDto
     */
    dateValue?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantOperationDto
     */
    booleanValue?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConstantOperationDto
     */
    enumValues?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ConstantOperationDto
     */
    numberValue?: number;
    /**
     * 
     * @type {ListOperatorTypeDto}
     * @memberof ConstantOperationDto
     */
    listOperator?: ListOperatorTypeDto;
    /**
     * 
     * @type {number}
     * @memberof ConstantOperationDto
     */
    listOperatorXValue?: number;
    /**
     * 
     * @type {OperatorTypeDto}
     * @memberof ConstantOperationDto
     */
    operator?: OperatorTypeDto;
}

/**
 * Check if a given object implements the ConstantOperationDto interface.
 */
export function instanceOfConstantOperationDto(value: object): boolean {
    if (!('operationType' in value)) return false;
    if (!('subOperations' in value)) return false;
    return true;
}

export function ConstantOperationDtoFromJSON(json: any): ConstantOperationDto {
    return ConstantOperationDtoFromJSONTyped(json, false);
}

export function ConstantOperationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConstantOperationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'operationType': OperationTypeDtoFromJSON(json['operationType']),
        'subOperations': ((json['subOperations'] as Array<any>).map(OperationDtoFromJSON)),
        'stringValue': json['stringValue'] == null ? undefined : json['stringValue'],
        'dateValue': json['dateValue'] == null ? undefined : (new Date(json['dateValue'])),
        'booleanValue': json['booleanValue'] == null ? undefined : json['booleanValue'],
        'enumValues': json['enumValues'] == null ? undefined : json['enumValues'],
        'numberValue': json['numberValue'] == null ? undefined : json['numberValue'],
        'listOperator': json['listOperator'] == null ? undefined : ListOperatorTypeDtoFromJSON(json['listOperator']),
        'listOperatorXValue': json['listOperatorXValue'] == null ? undefined : json['listOperatorXValue'],
        'operator': json['operator'] == null ? undefined : OperatorTypeDtoFromJSON(json['operator']),
    };
}

export function ConstantOperationDtoToJSON(value?: ConstantOperationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'operationType': OperationTypeDtoToJSON(value['operationType']),
        'subOperations': ((value['subOperations'] as Array<any>).map(OperationDtoToJSON)),
        'stringValue': value['stringValue'],
        'dateValue': value['dateValue'] == null ? undefined : ((value['dateValue']).toISOString()),
        'booleanValue': value['booleanValue'],
        'enumValues': value['enumValues'],
        'numberValue': value['numberValue'],
        'listOperator': ListOperatorTypeDtoToJSON(value['listOperator']),
        'listOperatorXValue': value['listOperatorXValue'],
        'operator': OperatorTypeDtoToJSON(value['operator']),
    };
}

