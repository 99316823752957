/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImportSuccessDto
 */
export interface ImportSuccessDto {
    /**
     * 
     * @type {number}
     * @memberof ImportSuccessDto
     */
    successful: number;
}

/**
 * Check if a given object implements the ImportSuccessDto interface.
 */
export function instanceOfImportSuccessDto(value: object): boolean {
    if (!('successful' in value)) return false;
    return true;
}

export function ImportSuccessDtoFromJSON(json: any): ImportSuccessDto {
    return ImportSuccessDtoFromJSONTyped(json, false);
}

export function ImportSuccessDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportSuccessDto {
    if (json == null) {
        return json;
    }
    return {
        
        'successful': json['successful'],
    };
}

export function ImportSuccessDtoToJSON(value?: ImportSuccessDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'successful': value['successful'],
    };
}

