/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductListItemDto } from './ProductListItemDto';
import {
    ProductListItemDtoFromJSON,
    ProductListItemDtoFromJSONTyped,
    ProductListItemDtoToJSON,
} from './ProductListItemDto';

/**
 * 
 * @export
 * @interface ProductListItemPageDto
 */
export interface ProductListItemPageDto {
    /**
     * The number of the page.
     * @type {number}
     * @memberof ProductListItemPageDto
     */
    page: number;
    /**
     * The size of the page.
     * @type {number}
     * @memberof ProductListItemPageDto
     */
    size: number;
    /**
     * The total number of data returned by the search.
     * @type {number}
     * @memberof ProductListItemPageDto
     */
    totalElements: number;
    /**
     * The total number of pages of current size to fit all data.
     * @type {number}
     * @memberof ProductListItemPageDto
     */
    totalPages: number;
    /**
     * 
     * @type {Array<ProductListItemDto>}
     * @memberof ProductListItemPageDto
     */
    content: Array<ProductListItemDto>;
}

/**
 * Check if a given object implements the ProductListItemPageDto interface.
 */
export function instanceOfProductListItemPageDto(value: object): boolean {
    if (!('page' in value)) return false;
    if (!('size' in value)) return false;
    if (!('totalElements' in value)) return false;
    if (!('totalPages' in value)) return false;
    if (!('content' in value)) return false;
    return true;
}

export function ProductListItemPageDtoFromJSON(json: any): ProductListItemPageDto {
    return ProductListItemPageDtoFromJSONTyped(json, false);
}

export function ProductListItemPageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductListItemPageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'page': json['page'],
        'size': json['size'],
        'totalElements': json['totalElements'],
        'totalPages': json['totalPages'],
        'content': ((json['content'] as Array<any>).map(ProductListItemDtoFromJSON)),
    };
}

export function ProductListItemPageDtoToJSON(value?: ProductListItemPageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'page': value['page'],
        'size': value['size'],
        'totalElements': value['totalElements'],
        'totalPages': value['totalPages'],
        'content': ((value['content'] as Array<any>).map(ProductListItemDtoToJSON)),
    };
}

