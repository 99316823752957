/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FileMetadataDto } from './FileMetadataDto';
import {
    FileMetadataDtoFromJSON,
    FileMetadataDtoFromJSONTyped,
    FileMetadataDtoToJSON,
} from './FileMetadataDto';

/**
 * 
 * @export
 * @interface LandingPageDesignHeaderDto
 */
export interface LandingPageDesignHeaderDto {
    /**
     * A hex color code
     * @type {string}
     * @memberof LandingPageDesignHeaderDto
     */
    backgroundColor: string;
    /**
     * A hex color code
     * @type {string}
     * @memberof LandingPageDesignHeaderDto
     */
    textSymbolColor: string;
    /**
     * 
     * @type {FileMetadataDto}
     * @memberof LandingPageDesignHeaderDto
     */
    logoMetadata: FileMetadataDto;
    /**
     * A URL restricted through uri format of open api
     * @type {string}
     * @memberof LandingPageDesignHeaderDto
     */
    logoLinkDe?: string;
    /**
     * A URL restricted through uri format of open api
     * @type {string}
     * @memberof LandingPageDesignHeaderDto
     */
    logoLinkEn: string;
}

/**
 * Check if a given object implements the LandingPageDesignHeaderDto interface.
 */
export function instanceOfLandingPageDesignHeaderDto(value: object): boolean {
    if (!('backgroundColor' in value)) return false;
    if (!('textSymbolColor' in value)) return false;
    if (!('logoMetadata' in value)) return false;
    if (!('logoLinkEn' in value)) return false;
    return true;
}

export function LandingPageDesignHeaderDtoFromJSON(json: any): LandingPageDesignHeaderDto {
    return LandingPageDesignHeaderDtoFromJSONTyped(json, false);
}

export function LandingPageDesignHeaderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageDesignHeaderDto {
    if (json == null) {
        return json;
    }
    return {
        
        'backgroundColor': json['backgroundColor'],
        'textSymbolColor': json['textSymbolColor'],
        'logoMetadata': FileMetadataDtoFromJSON(json['logoMetadata']),
        'logoLinkDe': json['logoLinkDe'] == null ? undefined : json['logoLinkDe'],
        'logoLinkEn': json['logoLinkEn'],
    };
}

export function LandingPageDesignHeaderDtoToJSON(value?: LandingPageDesignHeaderDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'backgroundColor': value['backgroundColor'],
        'textSymbolColor': value['textSymbolColor'],
        'logoMetadata': FileMetadataDtoToJSON(value['logoMetadata']),
        'logoLinkDe': value['logoLinkDe'],
        'logoLinkEn': value['logoLinkEn'],
    };
}

