import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Link } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { DefaultPostalAddressDto } from '../../api';

// see https://developers.google.com/maps/documentation/urls/get-started
const googleMapsQueryUrl = new URL('https://www.google.com/maps/search/?api=1');

export function renderPostalAddress(postalAddress: DefaultPostalAddressDto): React.ReactNode {
  const locationString = postalAddressToString(postalAddress);

  googleMapsQueryUrl.searchParams.set('query', locationString);

  return (
    <Link href={googleMapsQueryUrl.href} isExternal>
      {locationString} <ExternalLinkIcon mx="2px" />
    </Link>
  );
}

export const postalAddressToString = ({ address, postCode, city, state, country }: DefaultPostalAddressDto): string =>
  (isEmpty(address) ? '' : address + ', ') +
  (isEmpty(postCode) ? '' : postCode + ' ') +
  `${city}, ${isEmpty(state) ? '' : state + ', '}` +
  country.name;
