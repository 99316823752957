import { Box, Button, Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Stack } from '@chakra-ui/react';
import { faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { countBy, isEmpty, now } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { RegisterOptions, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import {
  MailingDto,
  MailingFlexModuleSettingsDto,
  MailingModuleConstraintDto,
  MailingTemplateDataDto,
  ModuleTypeDto,
} from '../../../../api';
import ErrorMessage from '../../../../ui/form/error-message';
import SortableList from '../../../../ui/sortable-list/sortable-list';
import useModuleTypeExtension from '../../common/use-module-type-extension';

export interface FlexMailingModuleControlProps {
  template: MailingTemplateDataDto;
  connectionId?: string;
  name: string;
  settings: MailingFlexModuleSettingsDto;
  constraints: { [key in ModuleTypeDto]?: MailingModuleConstraintDto };
  validate?: RegisterOptions<MailingDto, `modules.${number}.modules`>['validate'];
}

export default function FlexMailingModuleControl({
  template,
  connectionId,
  name,
  settings,
  constraints,
  validate,
}: FlexMailingModuleControlProps) {
  const { t } = useTranslation('mailing_template');
  const numberOfModules = useNumberOfMailingModules();

  const { append, remove, move, fields } = useFieldArray<MailingDto, `modules.${number}.modules`>({
    name: `${name}.modules` as `modules.${number}.modules`,
    rules: { validate },
  });

  const getModule = useModuleTypeExtension(`${name}.modules`);

  const menuItems = settings.moduleOptions
    .filter((option) => {
      const constraint = constraints[option.moduleType];

      return constraint?.maxAmount == null || constraint.maxAmount > (numberOfModules[option.moduleType] ?? 0);
    })
    .map((option) => (
      <MenuItem
        key={option.moduleType}
        onClick={() => {
          append({
            moduleType: option.moduleType,
            timestamp: new Date(now()),
          });
        }}
      >
        {t(`moduleSettings.moduleTypeOptions.${option.moduleType}`)}
      </MenuItem>
    ));

  return (
    <>
      <Stack
        m={0}
        p={0}
        alignItems="stretch"
        gap={0}
        border="1px"
        borderColor="border.01"
        borderRadius="base"
        bg="layer.01"
      >
        <SortableList id={(item) => item.id} items={fields} onMove={move}>
          {({ moduleType }, index) => {
            const module = settings.moduleOptions.find((option) => option.moduleType === moduleType);

            invariant(module != null, `Could not find module settings for ${moduleType}`);

            const constraint = constraints[moduleType];

            return (
              <FlexMailingModuleContent
                onDelete={() => {
                  remove(index);
                }}
                isDeletable={
                  constraint == null ||
                  constraint.minAmount == null ||
                  (numberOfModules[moduleType] ?? 0) > constraint.minAmount
                }
              >
                {getModule({ template, module, index, connectionId, constraints })}
              </FlexMailingModuleContent>
            );
          }}
        </SortableList>

        <Menu placement="auto">
          <MenuButton as={Button} borderTopRadius={isEmpty(fields) ? '4px' : '0'} isDisabled={menuItems.length === 0}>
            <FontAwesomeIcon icon={faPlus} /> {t('moduleSettings.addModule')}
          </MenuButton>
          <MenuList>{menuItems}</MenuList>
        </Menu>
      </Stack>
      <ErrorMessage name={`${name}.modules`} color="text.error" />
    </>
  );
}

interface FlexMailingModuleContentProps {
  children?: React.ReactNode;
  onDelete?: () => void;
  isDeletable: boolean;
}

function FlexMailingModuleContent({ children, onDelete, isDeletable }: FlexMailingModuleContentProps) {
  const { t } = useTranslation('mailing_template');

  return (
    <Flex alignItems="stretch" w="full">
      <Box px={6} py={4} w="full">
        {children}
      </Box>
      {isDeletable && (
        <IconButton
          icon={<FontAwesomeIcon icon={faTrashAlt} />}
          variant="solid"
          aria-label={t('editor.deleteModule')}
          sx={{ borderRadius: '0', h: 'auto' }}
          onClick={onDelete}
        />
      )}
    </Flex>
  );
}

function useNumberOfMailingModules() {
  const { watch, getValues } = useFormContext<MailingDto>();
  const [numberOfModules, setNumberOfModules] = useState(() => getNumberOfModules(getValues()));

  useEffect(() => {
    const subscription = watch((values) => {
      setNumberOfModules(getNumberOfModules(values as MailingDto));
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return numberOfModules;
}

function getNumberOfModules(mailingTemplate: MailingDto) {
  return countBy(
    [
      ...mailingTemplate.modules,
      ...mailingTemplate.modules.flatMap((moduleSettings) => {
        if (moduleSettings.moduleType === 'FLEX') {
          return moduleSettings.modules ?? [];
        }

        return [];
      }),
    ],
    (module) => module.moduleType,
  );
}
