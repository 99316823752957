/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  FailureDto,
  FormatCompanyPageDto,
  FormatCompanyRelationDto,
  FormatTerminationDto,
  HistoryPageDto,
  PageableDto,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    FormatCompanyPageDtoFromJSON,
    FormatCompanyPageDtoToJSON,
    FormatCompanyRelationDtoFromJSON,
    FormatCompanyRelationDtoToJSON,
    FormatTerminationDtoFromJSON,
    FormatTerminationDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface CreateFormatCompanyByFormatRequest {
    formatCompanyRelationDto: FormatCompanyRelationDto;
}

export interface DeleteRelationRequest {
    id: string;
}

export interface FetchFormatCompanyHistoryByCompanyRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchFormatCompanyHistoryByFormatRequest {
    id: string;
    pageable?: PageableDto;
}

export interface IsFormatCompanyUniqueRequest {
    formatId?: string;
    companyId?: string;
    relationId?: string;
}

export interface SearchFormatCompanyByCompanyRequest {
    id: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchFormatCompanyByFormatRequest {
    id: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface TerminateRelationRequest {
    formatTerminationDto: FormatTerminationDto;
}

/**
 * 
 */
export class FormatCompanyApi extends runtime.BaseAPI {

    /**
     * Create a format company relation by format.
     */
    async createFormatCompanyByFormatRaw(requestParameters: CreateFormatCompanyByFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['formatCompanyRelationDto'] == null) {
            throw new runtime.RequiredError(
                'formatCompanyRelationDto',
                'Required parameter "formatCompanyRelationDto" was null or undefined when calling createFormatCompanyByFormat().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/format-company-relations/format`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormatCompanyRelationDtoToJSON(requestParameters['formatCompanyRelationDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a format company relation by format.
     */
    async createFormatCompanyByFormat(requestParameters: CreateFormatCompanyByFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createFormatCompanyByFormatRaw(requestParameters, initOverrides);
    }

    /**
     * Delete relation by id
     */
    async deleteRelationRaw(requestParameters: DeleteRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteRelation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/format-company-relations/{id}/delete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete relation by id
     */
    async deleteRelation(requestParameters: DeleteRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteRelationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async fetchFormatCompanyHistoryByCompanyRaw(requestParameters: FetchFormatCompanyHistoryByCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchFormatCompanyHistoryByCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/format-company-relations/versions-by-company/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchFormatCompanyHistoryByCompany(requestParameters: FetchFormatCompanyHistoryByCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchFormatCompanyHistoryByCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fetchFormatCompanyHistoryByFormatRaw(requestParameters: FetchFormatCompanyHistoryByFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchFormatCompanyHistoryByFormat().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/format-company-relations/versions-by-format/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchFormatCompanyHistoryByFormat(requestParameters: FetchFormatCompanyHistoryByFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchFormatCompanyHistoryByFormatRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get whether the combination of format and company is unique for an active connection.
     */
    async isFormatCompanyUniqueRaw(requestParameters: IsFormatCompanyUniqueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters['formatId'] != null) {
            queryParameters['formatId'] = requestParameters['formatId'];
        }

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['relationId'] != null) {
            queryParameters['relationId'] = requestParameters['relationId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/format-company-relations/is-unique`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     * Get whether the combination of format and company is unique for an active connection.
     */
    async isFormatCompanyUnique(requestParameters: IsFormatCompanyUniqueRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.isFormatCompanyUniqueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for company format relations by company.
     */
    async searchFormatCompanyByCompanyRaw(requestParameters: SearchFormatCompanyByCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormatCompanyPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling searchFormatCompanyByCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/format-company-relations/company/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormatCompanyPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for company format relations by company.
     */
    async searchFormatCompanyByCompany(requestParameters: SearchFormatCompanyByCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormatCompanyPageDto> {
        const response = await this.searchFormatCompanyByCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for format company relations by format.
     */
    async searchFormatCompanyByFormatRaw(requestParameters: SearchFormatCompanyByFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormatCompanyPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling searchFormatCompanyByFormat().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/format-company-relations/format/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormatCompanyPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for format company relations by format.
     */
    async searchFormatCompanyByFormat(requestParameters: SearchFormatCompanyByFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormatCompanyPageDto> {
        const response = await this.searchFormatCompanyByFormatRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Terminate the existing relation.
     */
    async terminateRelationRaw(requestParameters: TerminateRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['formatTerminationDto'] == null) {
            throw new runtime.RequiredError(
                'formatTerminationDto',
                'Required parameter "formatTerminationDto" was null or undefined when calling terminateRelation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/format-company-relations/terminateRelation`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FormatTerminationDtoToJSON(requestParameters['formatTerminationDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Terminate the existing relation.
     */
    async terminateRelation(requestParameters: TerminateRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.terminateRelationRaw(requestParameters, initOverrides);
    }

}
