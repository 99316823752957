/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SenderTypeDto = {
    SECTION: 'SECTION',
    STAFF: 'STAFF',
    TEAM: 'TEAM'
} as const;
export type SenderTypeDto = typeof SenderTypeDto[keyof typeof SenderTypeDto];


export function SenderTypeDtoFromJSON(json: any): SenderTypeDto {
    return SenderTypeDtoFromJSONTyped(json, false);
}

export function SenderTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SenderTypeDto {
    return json as SenderTypeDto;
}

export function SenderTypeDtoToJSON(value?: SenderTypeDto | null): any {
    return value as any;
}

