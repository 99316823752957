import { createStandaloneToast } from '@chakra-ui/react';
import theme from '../theme';
import { DEFAULT_TOAST_OPTIONS } from '../ui/use-toast/use-toast';

export const { toast } = createStandaloneToast({
  theme,
  defaultOptions: {
    ...DEFAULT_TOAST_OPTIONS,
  },
});
