import tagApi from '../../data-access/tag-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';

export const tagFetcher = createFetcherScope(tagApi);

export const fetchTag = tagFetcher.createFetcher(tagApi.fetchTag);
export const searchTags = tagFetcher.createFetcher(tagApi.searchTags);
export const searchTagRelations = tagFetcher.createFetcher(tagApi.searchTagRelations);
export const fetchTagHistory = tagFetcher.createFetcher(tagApi.fetchTagHistory);
export const fetchTagRelationsHistory = tagFetcher.createFetcher(tagApi.fetchTagRelationsHistory);
export const fetchTagHasRelationHistoryByRelation = tagFetcher.createFetcher(
  tagApi.fetchTagHasRelationsHistoryByRelation,
);
export const fetchTagHasConnections = tagFetcher.createFetcher(tagApi.tagHasRelations);
