import React from 'react';
import { useTranslation } from 'react-i18next';
import { StandDto, StandListItemDto } from '../../../api';
import FinalDeleteConfirmDialog, {
  createDefaultDeleteChecklistItem,
} from '../../../ui/dialog/final-delete-confirm-dialog';

export interface StandDeleteDialogProps {
  stand: StandDto | StandListItemDto;
  isOpen: boolean;
  onClose(confirm: boolean): void;
}

export default function StandDeleteDialog({ onClose, stand, ...props }: StandDeleteDialogProps) {
  const { t } = useTranslation(['stand']);

  const confirmationMessageChecklistItems = [
    createDefaultDeleteChecklistItem({
      objectTypeWithArticleGenitive: t('stand:stand_with_article_nominative'),
      objectToDelete: stand.name,
    }),
  ];

  return (
    <FinalDeleteConfirmDialog
      {...props}
      objectTypeToDelete={t('stand:stand')}
      confirmationCheckListItems={confirmationMessageChecklistItems}
      onClose={onClose}
    />
  );
}
