import { StyleConfig } from '@chakra-ui/theme-tools';

const Divider: StyleConfig = {
  baseStyle: {
    opacity: 1,
    borderColor: 'border.01',
  },
};

export default Divider;
