/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LocationDto } from './LocationDto';
import {
    LocationDtoFromJSON,
    LocationDtoFromJSONTyped,
    LocationDtoToJSON,
} from './LocationDto';

/**
 * 
 * @export
 * @interface LocationSearchResultDto
 */
export interface LocationSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof LocationSearchResultDto
     */
    resultType: LocationSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof LocationSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {LocationDto}
     * @memberof LocationSearchResultDto
     */
    result: LocationDto;
}


/**
 * @export
 */
export const LocationSearchResultDtoResultTypeEnum = {
    LOCATION: 'LOCATION'
} as const;
export type LocationSearchResultDtoResultTypeEnum = typeof LocationSearchResultDtoResultTypeEnum[keyof typeof LocationSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the LocationSearchResultDto interface.
 */
export function instanceOfLocationSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function LocationSearchResultDtoFromJSON(json: any): LocationSearchResultDto {
    return LocationSearchResultDtoFromJSONTyped(json, false);
}

export function LocationSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': LocationDtoFromJSON(json['result']),
    };
}

export function LocationSearchResultDtoToJSON(value?: LocationSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': LocationDtoToJSON(value['result']),
    };
}

