import { Params } from 'react-router-dom';
import invariant from 'tiny-invariant';
import useParam from '../../../util/use-params/use-params';

export default function getLandingPageTemplateId(params: Params) {
  invariant(params.landingPageTemplateId != null, 'Empty landingPageTemplateId');

  return params.landingPageTemplateId;
}

export function useLandingPageTemplateId() {
  return useParam('landingPageTemplateId');
}
