import { ReactNode } from 'react';
import { PageState } from '../../../ui/pagination/use-pagination-state';
import { PluginToken } from '../../../util/plugin/use-plugins';

export interface TeamRelationHistoryExtension {
  content: (teamId: string, state: PageState, onStageChanged: (state: PageState) => void) => ReactNode;
}

export const TEAM_RELATION_HISTORY_EXTENSION = new PluginToken<TeamRelationHistoryExtension>(
  'TeamRelationHistoryExtension',
);
