import { ReactNode } from 'react';
import { PageState } from '../../../ui/pagination/use-pagination-state';
import { PluginToken } from '../../../util/plugin/use-plugins';

export interface CompanyHistoryExtension {
  key: string;
  buttonLabel: ReactNode;
  content: (companyId: string, state: PageState, onStageChanged: (state: PageState) => void) => ReactNode;
}

export const COMPANY_HISTORY_EXTENSION = new PluginToken<CompanyHistoryExtension>('CompanyHistoryExtension');
