/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MarkerDto
 */
export interface MarkerDto {
    /**
     * 
     * @type {string}
     * @memberof MarkerDto
     */
    type: MarkerDtoTypeEnum;
    /**
     * Defines the position of an element in the format [x,y].
     * @type {Array<number>}
     * @memberof MarkerDto
     */
    position: Array<number>;
}


/**
 * @export
 */
export const MarkerDtoTypeEnum = {
    CAMERA: 'CAMERA'
} as const;
export type MarkerDtoTypeEnum = typeof MarkerDtoTypeEnum[keyof typeof MarkerDtoTypeEnum];


/**
 * Check if a given object implements the MarkerDto interface.
 */
export function instanceOfMarkerDto(value: object): boolean {
    if (!('type' in value)) return false;
    if (!('position' in value)) return false;
    return true;
}

export function MarkerDtoFromJSON(json: any): MarkerDto {
    return MarkerDtoFromJSONTyped(json, false);
}

export function MarkerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarkerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'position': json['position'],
    };
}

export function MarkerDtoToJSON(value?: MarkerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'position': value['position'],
    };
}

