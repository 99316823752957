import timeFormatter from './time-formatter';

interface DateTimeRange {
  start: string;
  end: string;
}

const prefixMap = new Map<string, string>([
  ['de', 'Seit'],
  ['en', 'Since'],
]);

export default function timeRangeFormatter(
  value: DateTimeRange | undefined,
  lng?: string,
  options?: Intl.DateTimeFormatOptions,
) {
  if (value === undefined) {
    return '';
  }

  if (value.end == null) {
    return `${prefixMap.get(lng ?? 'de')} ${timeFormatter(value.start, lng, options)}`;
  }

  let startOptions = options;
  let endOptions = options;
  if (new Date(value.start).getSeconds() === 0 && new Date(value.end).getSeconds() === 0) {
    startOptions = { ...options, second: undefined };
    endOptions = { ...options, second: undefined };
  }

  return `${timeFormatter(value.start, lng, startOptions)}\u200A\u200A–\u200A\u200A${timeFormatter(
    value.end,
    lng,
    endOptions,
  )}`;
}
