import { Code } from '@chakra-ui/react';
import { faBlockQuestion } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { SearchResultDto } from '../../api';
import { SearchResult } from './search-result';

export default function FallbackSearchResult({ onClick, result }: { result: SearchResultDto; onClick: () => void }) {
  return (
    <SearchResult icon={faBlockQuestion} title={result.resultType} link="." score={result.score} onClick={onClick}>
      <Code>
        <pre>{JSON.stringify(result, undefined, 2)}</pre>
      </Code>
    </SearchResult>
  );
}
