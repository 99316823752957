/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateRangeDto } from './ClosedDateRangeDto';
import {
    ClosedDateRangeDtoFromJSON,
    ClosedDateRangeDtoFromJSONTyped,
    ClosedDateRangeDtoToJSON,
} from './ClosedDateRangeDto';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { ModeOfTransportDto } from './ModeOfTransportDto';
import {
    ModeOfTransportDtoFromJSON,
    ModeOfTransportDtoFromJSONTyped,
    ModeOfTransportDtoToJSON,
} from './ModeOfTransportDto';
import type { ParticipationBookingClassDto } from './ParticipationBookingClassDto';
import {
    ParticipationBookingClassDtoFromJSON,
    ParticipationBookingClassDtoFromJSONTyped,
    ParticipationBookingClassDtoToJSON,
} from './ParticipationBookingClassDto';
import type { ParticipationConfirmationStatusDto } from './ParticipationConfirmationStatusDto';
import {
    ParticipationConfirmationStatusDtoFromJSON,
    ParticipationConfirmationStatusDtoFromJSONTyped,
    ParticipationConfirmationStatusDtoToJSON,
} from './ParticipationConfirmationStatusDto';
import type { ParticipationDocumentsInnerDto } from './ParticipationDocumentsInnerDto';
import {
    ParticipationDocumentsInnerDtoFromJSON,
    ParticipationDocumentsInnerDtoFromJSONTyped,
    ParticipationDocumentsInnerDtoToJSON,
} from './ParticipationDocumentsInnerDto';
import type { ParticipationFilmSubmissionsInnerDto } from './ParticipationFilmSubmissionsInnerDto';
import {
    ParticipationFilmSubmissionsInnerDtoFromJSON,
    ParticipationFilmSubmissionsInnerDtoFromJSONTyped,
    ParticipationFilmSubmissionsInnerDtoToJSON,
} from './ParticipationFilmSubmissionsInnerDto';
import type { ParticipationLinkDto } from './ParticipationLinkDto';
import {
    ParticipationLinkDtoFromJSON,
    ParticipationLinkDtoFromJSONTyped,
    ParticipationLinkDtoToJSON,
} from './ParticipationLinkDto';
import type { ParticipationPassportDataDto } from './ParticipationPassportDataDto';
import {
    ParticipationPassportDataDtoFromJSON,
    ParticipationPassportDataDtoFromJSONTyped,
    ParticipationPassportDataDtoToJSON,
} from './ParticipationPassportDataDto';
import type { ParticipationPrivilegesDto } from './ParticipationPrivilegesDto';
import {
    ParticipationPrivilegesDtoFromJSON,
    ParticipationPrivilegesDtoFromJSONTyped,
    ParticipationPrivilegesDtoToJSON,
} from './ParticipationPrivilegesDto';
import type { ParticipationSecurityInnerDto } from './ParticipationSecurityInnerDto';
import {
    ParticipationSecurityInnerDtoFromJSON,
    ParticipationSecurityInnerDtoFromJSONTyped,
    ParticipationSecurityInnerDtoToJSON,
} from './ParticipationSecurityInnerDto';
import type { ParticipationSecurityLevelDto } from './ParticipationSecurityLevelDto';
import {
    ParticipationSecurityLevelDtoFromJSON,
    ParticipationSecurityLevelDtoFromJSONTyped,
    ParticipationSecurityLevelDtoToJSON,
} from './ParticipationSecurityLevelDto';
import type { ParticipationTripsInnerDto } from './ParticipationTripsInnerDto';
import {
    ParticipationTripsInnerDtoFromJSON,
    ParticipationTripsInnerDtoFromJSONTyped,
    ParticipationTripsInnerDtoToJSON,
} from './ParticipationTripsInnerDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * A Person's Participation in an Edition.
 * @export
 * @interface ParticipationDto
 */
export interface ParticipationDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof ParticipationDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof ParticipationDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof ParticipationDto
     */
    person: PersonReferenceDto;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof ParticipationDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {ClosedDateRangeDto}
     * @memberof ParticipationDto
     */
    duration?: ClosedDateRangeDto;
    /**
     * 
     * @type {ParticipationSecurityLevelDto}
     * @memberof ParticipationDto
     */
    securityLevel: ParticipationSecurityLevelDto;
    /**
     * 
     * @type {Array<ParticipationSecurityInnerDto>}
     * @memberof ParticipationDto
     */
    security: Array<ParticipationSecurityInnerDto>;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipationDto
     */
    ifbTravelHealthInsurance?: boolean;
    /**
     * 
     * @type {Array<ParticipationTripsInnerDto>}
     * @memberof ParticipationDto
     */
    trips: Array<ParticipationTripsInnerDto>;
    /**
     * 
     * @type {ParticipationBookingClassDto}
     * @memberof ParticipationDto
     */
    bookingClass?: ParticipationBookingClassDto;
    /**
     * 
     * @type {number}
     * @memberof ParticipationDto
     */
    budget?: number;
    /**
     * 
     * @type {number}
     * @memberof ParticipationDto
     */
    realCost?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParticipationDto
     */
    attributes: Array<ParticipationDtoAttributesEnum>;
    /**
     * 
     * @type {Array<SectionReferenceDto>}
     * @memberof ParticipationDto
     */
    guestSections: Array<SectionReferenceDto>;
    /**
     * 
     * @type {Array<ParticipationPassportDataDto>}
     * @memberof ParticipationDto
     */
    passportDetails: Array<ParticipationPassportDataDto>;
    /**
     * 
     * @type {Array<ParticipationPrivilegesDto>}
     * @memberof ParticipationDto
     */
    privileges: Array<ParticipationPrivilegesDto>;
    /**
     * 
     * @type {Array<ParticipationDocumentsInnerDto>}
     * @memberof ParticipationDto
     */
    documents: Array<ParticipationDocumentsInnerDto>;
    /**
     * 
     * @type {Array<ParticipationLinkDto>}
     * @memberof ParticipationDto
     */
    links: Array<ParticipationLinkDto>;
    /**
     * 
     * @type {Array<ParticipationFilmSubmissionsInnerDto>}
     * @memberof ParticipationDto
     */
    filmSubmissions: Array<ParticipationFilmSubmissionsInnerDto>;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipationDto
     */
    travelOnlyForFestival: boolean;
    /**
     * 
     * @type {ModeOfTransportDto}
     * @memberof ParticipationDto
     */
    arrivalModeOfTransportSelfReported?: ModeOfTransportDto;
    /**
     * 
     * @type {ParticipationConfirmationStatusDto}
     * @memberof ParticipationDto
     */
    confirmationStatus: ParticipationConfirmationStatusDto;
}


/**
 * @export
 */
export const ParticipationDtoAttributesEnum = {
    PRESS: 'PRESS',
    EFM_BUYER: 'EFM_BUYER',
    EFM_EXHIBITOR: 'EFM_EXHIBITOR',
    COPRO_PROJECT: 'COPRO_PROJECT',
    COPRO_PARTICIPANT: 'COPRO_PARTICIPANT',
    COPRO_VISITOR: 'COPRO_VISITOR',
    BT_TALENT: 'BT_TALENT',
    BT_GUEST: 'BT_GUEST'
} as const;
export type ParticipationDtoAttributesEnum = typeof ParticipationDtoAttributesEnum[keyof typeof ParticipationDtoAttributesEnum];


/**
 * Check if a given object implements the ParticipationDto interface.
 */
export function instanceOfParticipationDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('person' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('securityLevel' in value)) return false;
    if (!('security' in value)) return false;
    if (!('trips' in value)) return false;
    if (!('attributes' in value)) return false;
    if (!('guestSections' in value)) return false;
    if (!('passportDetails' in value)) return false;
    if (!('privileges' in value)) return false;
    if (!('documents' in value)) return false;
    if (!('links' in value)) return false;
    if (!('filmSubmissions' in value)) return false;
    if (!('travelOnlyForFestival' in value)) return false;
    if (!('confirmationStatus' in value)) return false;
    return true;
}

export function ParticipationDtoFromJSON(json: any): ParticipationDto {
    return ParticipationDtoFromJSONTyped(json, false);
}

export function ParticipationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'person': PersonReferenceDtoFromJSON(json['person']),
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'duration': json['duration'] == null ? undefined : ClosedDateRangeDtoFromJSON(json['duration']),
        'securityLevel': ParticipationSecurityLevelDtoFromJSON(json['securityLevel']),
        'security': ((json['security'] as Array<any>).map(ParticipationSecurityInnerDtoFromJSON)),
        'ifbTravelHealthInsurance': json['ifbTravelHealthInsurance'] == null ? undefined : json['ifbTravelHealthInsurance'],
        'trips': ((json['trips'] as Array<any>).map(ParticipationTripsInnerDtoFromJSON)),
        'bookingClass': json['bookingClass'] == null ? undefined : ParticipationBookingClassDtoFromJSON(json['bookingClass']),
        'budget': json['budget'] == null ? undefined : json['budget'],
        'realCost': json['realCost'] == null ? undefined : json['realCost'],
        'attributes': json['attributes'],
        'guestSections': ((json['guestSections'] as Array<any>).map(SectionReferenceDtoFromJSON)),
        'passportDetails': ((json['passportDetails'] as Array<any>).map(ParticipationPassportDataDtoFromJSON)),
        'privileges': ((json['privileges'] as Array<any>).map(ParticipationPrivilegesDtoFromJSON)),
        'documents': ((json['documents'] as Array<any>).map(ParticipationDocumentsInnerDtoFromJSON)),
        'links': ((json['links'] as Array<any>).map(ParticipationLinkDtoFromJSON)),
        'filmSubmissions': ((json['filmSubmissions'] as Array<any>).map(ParticipationFilmSubmissionsInnerDtoFromJSON)),
        'travelOnlyForFestival': json['travelOnlyForFestival'],
        'arrivalModeOfTransportSelfReported': json['arrivalModeOfTransportSelfReported'] == null ? undefined : ModeOfTransportDtoFromJSON(json['arrivalModeOfTransportSelfReported']),
        'confirmationStatus': ParticipationConfirmationStatusDtoFromJSON(json['confirmationStatus']),
    };
}

export function ParticipationDtoToJSON(value?: ParticipationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'person': PersonReferenceDtoToJSON(value['person']),
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'duration': ClosedDateRangeDtoToJSON(value['duration']),
        'securityLevel': ParticipationSecurityLevelDtoToJSON(value['securityLevel']),
        'security': ((value['security'] as Array<any>).map(ParticipationSecurityInnerDtoToJSON)),
        'ifbTravelHealthInsurance': value['ifbTravelHealthInsurance'],
        'trips': ((value['trips'] as Array<any>).map(ParticipationTripsInnerDtoToJSON)),
        'bookingClass': ParticipationBookingClassDtoToJSON(value['bookingClass']),
        'budget': value['budget'],
        'realCost': value['realCost'],
        'attributes': value['attributes'],
        'guestSections': ((value['guestSections'] as Array<any>).map(SectionReferenceDtoToJSON)),
        'passportDetails': ((value['passportDetails'] as Array<any>).map(ParticipationPassportDataDtoToJSON)),
        'privileges': ((value['privileges'] as Array<any>).map(ParticipationPrivilegesDtoToJSON)),
        'documents': ((value['documents'] as Array<any>).map(ParticipationDocumentsInnerDtoToJSON)),
        'links': ((value['links'] as Array<any>).map(ParticipationLinkDtoToJSON)),
        'filmSubmissions': ((value['filmSubmissions'] as Array<any>).map(ParticipationFilmSubmissionsInnerDtoToJSON)),
        'travelOnlyForFestival': value['travelOnlyForFestival'],
        'arrivalModeOfTransportSelfReported': ModeOfTransportDtoToJSON(value['arrivalModeOfTransportSelfReported']),
        'confirmationStatus': ParticipationConfirmationStatusDtoToJSON(value['confirmationStatus']),
    };
}

