/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TagStatusDto } from './TagStatusDto';
import {
    TagStatusDtoFromJSON,
    TagStatusDtoFromJSONTyped,
    TagStatusDtoToJSON,
} from './TagStatusDto';
import type { TagTypeDto } from './TagTypeDto';
import {
    TagTypeDtoFromJSON,
    TagTypeDtoFromJSONTyped,
    TagTypeDtoToJSON,
} from './TagTypeDto';

/**
 * Organisational representation of grouped interests and persons
 * @export
 * @interface TagListItemDto
 */
export interface TagListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof TagListItemDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TagListItemDto
     */
    name: string;
    /**
     * 
     * @type {TagTypeDto}
     * @memberof TagListItemDto
     */
    type: TagTypeDto;
    /**
     * 
     * @type {TagStatusDto}
     * @memberof TagListItemDto
     */
    status: TagStatusDto;
}

/**
 * Check if a given object implements the TagListItemDto interface.
 */
export function instanceOfTagListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('type' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function TagListItemDtoFromJSON(json: any): TagListItemDto {
    return TagListItemDtoFromJSONTyped(json, false);
}

export function TagListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'type': TagTypeDtoFromJSON(json['type']),
        'status': TagStatusDtoFromJSON(json['status']),
    };
}

export function TagListItemDtoToJSON(value?: TagListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'type': TagTypeDtoToJSON(value['type']),
        'status': TagStatusDtoToJSON(value['status']),
    };
}

