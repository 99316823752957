/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryPhonePrefixDto } from './CountryPhonePrefixDto';
import {
    CountryPhonePrefixDtoFromJSON,
    CountryPhonePrefixDtoFromJSONTyped,
    CountryPhonePrefixDtoToJSON,
} from './CountryPhonePrefixDto';

/**
 * 
 * @export
 * @interface TeamPhoneNumberDto
 */
export interface TeamPhoneNumberDto {
    /**
     * 
     * @type {CountryPhonePrefixDto}
     * @memberof TeamPhoneNumberDto
     */
    countryCode: CountryPhonePrefixDto;
    /**
     * The dialing code of the phone number
     * @type {string}
     * @memberof TeamPhoneNumberDto
     */
    dialingCode?: string;
    /**
     * The number of the phone number
     * @type {string}
     * @memberof TeamPhoneNumberDto
     */
    number: string;
    /**
     * The extension of the phone number
     * @type {string}
     * @memberof TeamPhoneNumberDto
     */
    extension?: string;
    /**
     * Note for the phone number
     * @type {string}
     * @memberof TeamPhoneNumberDto
     */
    note?: string;
    /**
     * 
     * @type {Date}
     * @memberof TeamPhoneNumberDto
     */
    timestamp?: Date;
    /**
     * The label for the phone number
     * @type {string}
     * @memberof TeamPhoneNumberDto
     */
    label: TeamPhoneNumberDtoLabelEnum;
}


/**
 * @export
 */
export const TeamPhoneNumberDtoLabelEnum = {
    BUSINESS: 'BUSINESS',
    MOBILE: 'MOBILE'
} as const;
export type TeamPhoneNumberDtoLabelEnum = typeof TeamPhoneNumberDtoLabelEnum[keyof typeof TeamPhoneNumberDtoLabelEnum];


/**
 * Check if a given object implements the TeamPhoneNumberDto interface.
 */
export function instanceOfTeamPhoneNumberDto(value: object): boolean {
    if (!('countryCode' in value)) return false;
    if (!('number' in value)) return false;
    if (!('label' in value)) return false;
    return true;
}

export function TeamPhoneNumberDtoFromJSON(json: any): TeamPhoneNumberDto {
    return TeamPhoneNumberDtoFromJSONTyped(json, false);
}

export function TeamPhoneNumberDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamPhoneNumberDto {
    if (json == null) {
        return json;
    }
    return {
        
        'countryCode': CountryPhonePrefixDtoFromJSON(json['countryCode']),
        'dialingCode': json['dialingCode'] == null ? undefined : json['dialingCode'],
        'number': json['number'],
        'extension': json['extension'] == null ? undefined : json['extension'],
        'note': json['note'] == null ? undefined : json['note'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
        'label': json['label'],
    };
}

export function TeamPhoneNumberDtoToJSON(value?: TeamPhoneNumberDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'countryCode': CountryPhonePrefixDtoToJSON(value['countryCode']),
        'dialingCode': value['dialingCode'],
        'number': value['number'],
        'extension': value['extension'],
        'note': value['note'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
        'label': value['label'],
    };
}

