/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DateRangeDto
 */
export interface DateRangeDto {
    /**
     * 
     * @type {Date}
     * @memberof DateRangeDto
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof DateRangeDto
     */
    end?: Date;
}

/**
 * Check if a given object implements the DateRangeDto interface.
 */
export function instanceOfDateRangeDto(value: object): boolean {
    if (!('start' in value)) return false;
    return true;
}

export function DateRangeDtoFromJSON(json: any): DateRangeDto {
    return DateRangeDtoFromJSONTyped(json, false);
}

export function DateRangeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DateRangeDto {
    if (json == null) {
        return json;
    }
    return {
        
        'start': (new Date(json['start'])),
        'end': json['end'] == null ? undefined : (new Date(json['end'])),
    };
}

export function DateRangeDtoToJSON(value?: DateRangeDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'start': ((value['start']).toISOString().substring(0,10)),
        'end': value['end'] == null ? undefined : ((value['end']).toISOString().substring(0,10)),
    };
}

