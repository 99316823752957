import React from 'react';
import registry from '../../registry';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import useFetcher from '../../util/swr/use-fetcher';
import Translate from '../../util/translate/translate';
import useParam from '../../util/use-params/use-params';
import unconfirmedCompanyRoutes from '../unconfirmed-company/unconfirmed-company-routes';
import COMPANY_CHILD_ROUTES from './company-child-routes';
import { fetchCompany } from './company-queries';

const CompanyPage = React.lazy(() => import('./company-page/company-page'));

const companyRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'companies',
    handle: {
      breadcrumb: <Translate ns="company" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./company-lister/company-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./company-editor/new-company-route'),
      },
      {
        path: 'merge',
        lazy: () => import('./company-merger/company-merger-route'),
      },
      {
        path: 'duplicates',
        lazy: () => import('./company-duplicate-lister/company-duplicate-lister-route'),
      },
      {
        path: 'non-duplicates',
        lazy: () => import('./company-duplicate-lister/company-non-duplicate-lister-route'),
      },
      ...unconfirmedCompanyRoutes,
      {
        path: ':companyId',
        handle: {
          breadcrumb: <CompanyBreadcrumb />,
        },
        children: [
          {
            path: '',
            element: <CompanyPage />,
            children: [
              {
                path: '',
                lazy: () => import('./company-viewer/company-viewer-route'),
              },
              {
                path: 'comments',
                lazy: () => import('./company-comment/company-comment-viewer-route'),
              },
              {
                path: 'persons',
                lazy: () => import('./company-person/company-persons-route'),
              },
              {
                path: 'companies',
                lazy: () => import('./company-company/company-companies-route'),
              },
              {
                path: 'films',
                lazy: () => import('./company-film/company-films-route'),
              },
              {
                path: 'history',
                lazy: () => import('./company-history/company-history-route'),
              },
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./company-editor/edit-company-route'),
          },
          ...registry.getAll(COMPANY_CHILD_ROUTES).flat(),
        ],
      },
    ],
  },
];

function CompanyBreadcrumb() {
  const id = useParam('companyId');
  const company = useFetcher(fetchCompany, { id }, { suspense: true });

  return company.name;
}

export default companyRoutes;
