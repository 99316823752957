import React from 'react';
import { mailingDesignIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { useMailingDesignPermissionClassGroupExtension } from './mailing-design-permissions';
import MailingDesignSearchResult from './mailing-design-search-result';

export default function MailingDesignPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, { type: 'MAILING_DESIGN', Component: MailingDesignSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'MAILING_DESIGN',
    icon: mailingDesignIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.MAILING_DESIGN" />,
  });

  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useMailingDesignPermissionClassGroupExtension);
}
