import { HStack, List, ListItem, Tag } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocationPostalAddressDtoLabelEnum } from '../../../api';
import VisitingCard, { VisitingCardItem } from '../../../ui/visiting-card/visiting-card';
import { renderPostalAddressWithCoordinateIcon } from '../location-form/render-postal-address';
import useLocationCard from '../use-location-card/use-location-card';

const ADDRESSES_ORDER: LocationPostalAddressDtoLabelEnum[] = [
  LocationPostalAddressDtoLabelEnum.OFFICIAL,
  LocationPostalAddressDtoLabelEnum.STAGE_DOOR,
  LocationPostalAddressDtoLabelEnum.OTHER,
];

interface LocationCardProps {
  locationId: string;
  zone?: string;
  subZone?: string;
}

export default function LocationCard({ locationId, zone, subZone }: LocationCardProps) {
  const { t } = useTranslation('location');
  const { t: tCommon } = useTranslation('common');
  const locationDetails = useLocationCard(locationId);

  return (
    <VisitingCard label={locationDetails.name ?? ''} title={t('location')}>
      <VisitingCardItem label={locationDetails.name}>
        <Tag size="sm">{t(`statusLabel.${locationDetails.status}`)}</Tag>
      </VisitingCardItem>
      {locationDetails.addresses != null && (
        <VisitingCardItem label={tCommon('postalAddress.address')}>
          <List spacing={0}>
            {[...locationDetails.addresses]
              .sort((a, b) => ADDRESSES_ORDER.indexOf(a.label) - ADDRESSES_ORDER.indexOf(b.label))
              .map((address, index) => (
                <HStack as={ListItem} key={index}>
                  {renderPostalAddressWithCoordinateIcon(address, t('contacts.postal_address.search_for_coordinates'))}
                </HStack>
              ))}
          </List>
        </VisitingCardItem>
      )}
      {zone != null && <VisitingCardItem label={t('rooms.zones.table.zone')}>{zone}</VisitingCardItem>}
      {subZone != null && <VisitingCardItem label={t('rooms.zones.table.subzone')}>{subZone}</VisitingCardItem>}
    </VisitingCard>
  );
}
