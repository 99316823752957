import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
} from '@chakra-ui/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import {
  EditionDto,
  FormatDto,
  OccupationDto,
  OccupationFormatConfirmationStatusDto,
  PersonDto,
} from '../../../../api';
import formatOccupationApi from '../../../../data-access/format-occupation-api';
import personApi from '../../../../data-access/person-api';
import ValueAsyncSelect from '../../../../ui/select/value-async-select';
import ValueSelect from '../../../../ui/select/value-select';
import useToast from '../../../../ui/use-toast/use-toast';
import useActiveEdition from '../../../edition/use-active-edition/use-active-edition';
import { formatOccupationFetcher } from '../format-occupation-queries';

interface FormatOccupationEditorDialogProps {
  isOpen: boolean;
  onClose: () => void;
  format: FormatDto;
}

export default function FormatOccupationEditorDialog({ isOpen, onClose, format }: FormatOccupationEditorDialogProps) {
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} initialFocusRef={initialFocusRef}>
      <ModalOverlay />
      <ModalContent>
        <FormatOccupationEditor onClose={onClose} format={format} initialFocusRef={initialFocusRef} />
      </ModalContent>
    </Modal>
  );
}

interface FormatOccupationEditorProps {
  onClose: () => void;
  format: FormatDto;
  initialFocusRef: React.RefObject<HTMLInputElement>;
}

function FormatOccupationEditor({ onClose, format, initialFocusRef }: FormatOccupationEditorProps) {
  const { t } = useTranslation(['person', 'edition']);
  const { t: tCommon } = useTranslation('common');

  const [person, setPerson] = React.useState<PersonDto | null>(null);
  const [occupation, setOccupation] = React.useState<OccupationDto | null>(null);
  const [errorValue, setErrorValue] = React.useState<OccupationDto | null>(null);
  const activeEdition = useActiveEdition();

  const showSuccessToast = useToast({
    status: 'success',
  });

  const handleAdd = async () => {
    invariant(occupation != null, 'occupation must not be null');
    await formatOccupationApi.createRelationsByFormat({
      formatOccupationRelationDto: {
        edition: { id: activeEdition.id!, name: activeEdition.name },
        format: format,
        occupation: occupation,
        confirmationStatus: OccupationFormatConfirmationStatusDto.CONFIRMED,
      },
    });

    formatOccupationFetcher.mutate();

    showSuccessToast({
      title: <Trans t={t} i18nKey="person:formatConnections.toast.successTitle" />,
      description: <Trans t={t} i18nKey="person:formatConnections.toast.successDescription" />,
    });

    onClose();
  };

  return (
    <>
      <ModalHeader>{t('person:formatConnections.add')}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack spacing={4}>
          <FormControl isRequired>
            <FormLabel>{t('person:person')}</FormLabel>
            <ValueAsyncSelect<PersonDto>
              ref={initialFocusRef}
              placeholder={tCommon('select.placeholder')}
              loadOptions={async (value) => {
                const persons = await personApi.searchPersons({
                  q: value,
                  filter: ['status,eq,ACTIVE'],
                });
                return persons.content;
              }}
              renderLabel={(person) => `${person.firstName} ${person.surname} ${person.personKey}`}
              optionIdentifier={(option) => option.id!}
              onChange={async (newValue) => {
                if (newValue != null && newValue.id !== person?.id) {
                  // needs to fetch person because occupations are not resolved in list dto
                  const newPerson = await personApi.fetchPerson({ id: newValue.id! });
                  setPerson(newPerson);
                  setOccupation(null);
                }
              }}
              value={person}
            />
          </FormControl>
          <FormControl isRequired isInvalid={errorValue != null}>
            <FormLabel>{t('person:occupations.label')}</FormLabel>
            <ValueSelect<OccupationDto>
              placeholder={tCommon('select.placeholder')}
              renderLabel={(occupation) => occupation.jobTitle}
              options={person?.occupations == null ? [] : person.occupations}
              onChange={async (newValue) => {
                if (newValue != null) {
                  const isUnique = await formatOccupationApi.isFormatOccupationUnique({
                    formatId: format.id,
                    occupationId: newValue.id,
                    editionId: activeEdition.id,
                  });

                  setOccupation(newValue);
                  if (isUnique.value) {
                    setErrorValue(null);
                  } else {
                    setErrorValue(newValue);
                  }
                }
              }}
              value={occupation}
            />
            {errorValue != null && <FormErrorMessage>{t('person:formatConnections.notUnique')}</FormErrorMessage>}
          </FormControl>
          <FormControl isRequired>
            <FormLabel>{t('edition:edition')}</FormLabel>
            <ValueSelect<EditionDto>
              isRequired
              options={[activeEdition]}
              renderLabel={(edition) => edition.name}
              onChange={() => {}}
              value={activeEdition}
              defaultValue={activeEdition}
              isDisabled
            />
          </FormControl>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Flex width="100%">
          <Spacer />
          <Button mr={3} onClick={onClose}>
            {tCommon('action.abort')}
          </Button>
          <Button
            variant="primary"
            isDisabled={errorValue != null || person == null || occupation == null}
            onClick={() => handleAdd()}
          >
            {tCommon('action.add')}
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
}
