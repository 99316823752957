/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LandingPageDto } from './LandingPageDto';
import {
    LandingPageDtoFromJSON,
    LandingPageDtoFromJSONTyped,
    LandingPageDtoToJSON,
} from './LandingPageDto';

/**
 * 
 * @export
 * @interface LandingPageSearchResultDto
 */
export interface LandingPageSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof LandingPageSearchResultDto
     */
    resultType: LandingPageSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof LandingPageSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {LandingPageDto}
     * @memberof LandingPageSearchResultDto
     */
    result: LandingPageDto;
}


/**
 * @export
 */
export const LandingPageSearchResultDtoResultTypeEnum = {
    LANDING_PAGE: 'LANDING_PAGE'
} as const;
export type LandingPageSearchResultDtoResultTypeEnum = typeof LandingPageSearchResultDtoResultTypeEnum[keyof typeof LandingPageSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the LandingPageSearchResultDto interface.
 */
export function instanceOfLandingPageSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function LandingPageSearchResultDtoFromJSON(json: any): LandingPageSearchResultDto {
    return LandingPageSearchResultDtoFromJSONTyped(json, false);
}

export function LandingPageSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': LandingPageDtoFromJSON(json['result']),
    };
}

export function LandingPageSearchResultDtoToJSON(value?: LandingPageSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': LandingPageDtoToJSON(value['result']),
    };
}

