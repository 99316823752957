import React from 'react';
import invariant from 'tiny-invariant';
import { GroupPersonRelationDto } from '../../../../../api';
import HistoryPage from '../../../../../ui/history/history-page';
import { PageState } from '../../../../../ui/pagination/use-pagination-state';
import useFetcher from '../../../../../util/swr/use-fetcher';
import { GroupRelationHistoryExtension } from '../../../../group/group-relation/group-relation-history-extension';
import { fetchGroupPersonHistory } from '../group-person-queries';
import useGroupPersonHistorySettings from './use-group-person-relation-history-settings';

export const groupPersonHistoryExtension: GroupRelationHistoryExtension = {
  content: (groupId, state, onStateChanged) => (
    <GroupPersonHistory groupId={groupId} state={state} onStateChanged={onStateChanged} />
  ),
};

interface GroupPersonHistoryProps {
  groupId: string;
  state: PageState;
  onStateChanged: (state: PageState) => void;
}

function GroupPersonHistory({ groupId, state, onStateChanged }: GroupPersonHistoryProps) {
  const groupPersonHistorySettings = useGroupPersonHistorySettings();

  const groupPersonHistoryPage = useFetcher(fetchGroupPersonHistory, { id: groupId, pageable: state });
  invariant(groupPersonHistoryPage != null, 'Missing group person history page');

  return (
    <HistoryPage<GroupPersonRelationDto>
      page={groupPersonHistoryPage}
      state={state}
      onStateChange={onStateChanged}
      historyDisplaySettings={groupPersonHistorySettings}
      showLine={false}
    />
  );
}
