/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Access token introspected.
 * @export
 * @interface IntrospectResponseDto
 */
export interface IntrospectResponseDto {
    [key: string]: any | any;
    /**
     * 
     * @type {boolean}
     * @memberof IntrospectResponseDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof IntrospectResponseDto
     */
    sub?: string;
    /**
     * 
     * @type {string}
     * @memberof IntrospectResponseDto
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof IntrospectResponseDto
     */
    tokenType?: IntrospectResponseDtoTokenTypeEnum;
}


/**
 * @export
 */
export const IntrospectResponseDtoTokenTypeEnum = {
    BEARER: 'Bearer'
} as const;
export type IntrospectResponseDtoTokenTypeEnum = typeof IntrospectResponseDtoTokenTypeEnum[keyof typeof IntrospectResponseDtoTokenTypeEnum];


/**
 * Check if a given object implements the IntrospectResponseDto interface.
 */
export function instanceOfIntrospectResponseDto(value: object): boolean {
    if (!('active' in value)) return false;
    return true;
}

export function IntrospectResponseDtoFromJSON(json: any): IntrospectResponseDto {
    return IntrospectResponseDtoFromJSONTyped(json, false);
}

export function IntrospectResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntrospectResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
            ...json,
        'active': json['active'],
        'sub': json['sub'] == null ? undefined : json['sub'],
        'clientId': json['client_id'] == null ? undefined : json['client_id'],
        'tokenType': json['token_type'] == null ? undefined : json['token_type'],
    };
}

export function IntrospectResponseDtoToJSON(value?: IntrospectResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
            ...value,
        'active': value['active'],
        'sub': value['sub'],
        'client_id': value['clientId'],
        'token_type': value['tokenType'],
    };
}

