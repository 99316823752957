import { HStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputFormControl from '../../../../ui/form/input-form-control';

export interface AnchorMailingModuleControlProps {
  name: string;
}

export default function AnchorMailingModuleControl({ name }: AnchorMailingModuleControlProps) {
  const { t } = useTranslation('mailing');
  return (
    <HStack spacing={6} alignItems="top">
      <InputFormControl name={`${name}.anchorText`} label={t('anchor_module.label')} maxLength={200} />

      <InputFormControl
        name={`${name}.anchorTextEnglish`}
        label={t('anchor_module.label') + ' ' + t('anchor_module.english')}
        maxLength={200}
      />
    </HStack>
  );
}
