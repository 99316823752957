import React from 'react';
import { EventSecurityStaffDto } from '../../../api';
import { DataTableColumn } from '../../../ui/data-table';
import Optional from '../../../ui/optional/optional';
import Translate from '../../../util/translate/translate';

const securityStaffColumns: DataTableColumn<EventSecurityStaffDto>[] = [
  {
    key: 'securityStaff',
    name: <Translate ns="event" i18nKey="securityStaff.label" />,
    renderCell: (data) => <Translate ns="event">{(t) => t(`securityStaff.type.${data.type}`)}</Translate>,
  },
  {
    key: 'count',
    name: <Translate ns="event" i18nKey="securityStaff.count" />,
    renderCell: (data) => <Optional>{data.count}</Optional>,
  },
  {
    key: 'comment',
    name: <Translate ns="event" i18nKey="comment" />,
    renderCell: (data) => <Optional>{data.comment}</Optional>,
  },
];
/**
 * Exports columns for the security staff table inside {@link EventForm}
 */
export default securityStaffColumns;
