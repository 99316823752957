import { ReactNode } from 'react';
import { GroupDto } from '../../../api';
import { PluginToken } from '../../../util/plugin/use-plugins';

export interface GroupRelationExtension {
  pageContent: (group: GroupDto) => ReactNode;
}

export const GROUP_PERSON_RELATION_EXTENSION = new PluginToken<GroupRelationExtension>('GroupPersonRelationExtension');

export const GROUP_COMPANY_RELATION_EXTENSION = new PluginToken<GroupRelationExtension>(
  'GroupCompanyRelationExtension',
);

export const GROUP_STAFF_RELATION_EXTENSION = new PluginToken<GroupRelationExtension>('GroupStaffRelationExtension');
