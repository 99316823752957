import { chakra } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCatSpace,
  faJedi,
  faPortalExit,
  faSpaceStationMoonAlt,
  faStarfighter,
  faStarship,
  faStarshipFreighter,
  faTransporter,
  faTransporter1,
  faTransporter2,
  faTransporter3,
  faTransporterEmpty,
  faUserAlien,
  faUserVisor,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { random } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';

const transporterIcons = [faTransporter, faTransporter1, faTransporter2, faTransporter3, faTransporterEmpty];

interface AnimatedIconProps extends Omit<FontAwesomeIconProps, 'icon'> {
  icons: IconProp[];
}

/* istanbul ignore next */
function AnimatedIcon({ icons, ...props }: AnimatedIconProps) {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  React.useEffect(() => {
    let rafRef: number;

    const raf = () => {
      rafRef = requestAnimationFrame((millis) => {
        setSelectedIndex(Math.floor(millis / 300) % icons.length);
        raf();
      });
    };

    raf();

    return () => {
      cancelAnimationFrame(rafRef);
    };
  }, [icons]);

  return (
    <>
      {icons.map((icon, index) => (
        <FontAwesomeIcon
          key={index}
          icon={icon}
          style={{ display: selectedIndex === index ? 'block' : 'none' }}
          {...props}
        />
      ))}
    </>
  );
}

const emptyIcons = [
  <AnimatedIcon icons={transporterIcons} />,
  ...[
    faUserAlien,
    faJedi,
    faCatSpace,
    faPortalExit,
    faSpaceStationMoonAlt,
    faStarfighter,
    faStarship,
    faStarshipFreighter,
    faUserVisor,
  ].map((icon) => <FontAwesomeIcon icon={icon} />),
];

export default function Empty({ text }: { text?: string }) {
  const { t } = useTranslation('common');

  const [element] = React.useState(() => {
    return emptyIcons[random(emptyIcons.length - 1)];
  });

  return (
    <chakra.div p={12} display="flex" width="100%" flexDir="column" alignItems="center" bgColor="inherit">
      <chakra.div color="text.muted" opacity={0.5} display="flex" flexDir="column" alignItems="center">
        {React.cloneElement(element, { size: '3x' })}
        <chakra.p mt={4} fontWeight="bold" textAlign="center">
          {text ?? t('data_table.empty')}
        </chakra.p>
      </chakra.div>
    </chakra.div>
  );
}
