import { ReactNode } from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import { PluginToken } from '../../util/plugin/use-plugins';

interface LandingPageTemplateViewerTab {
  route: BreadcrumbRouteObject & HelmetRouteObject;
  tab: ReactNode;
}

export const LANDING_PAGE_TEMPLATE_VIEWER_TAB = new PluginToken<LandingPageTemplateViewerTab>(
  'LandingPageTemplateViewerTab',
);
