import { addDays, endOfDay, startOfDay } from 'date-fns';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EditionDto } from '../../../api';
import DateInputFormControl from '../../../ui/form/date-input-control/date-input-form-control';
import dateMax from '../../../util/date/date-max';
import validDate from '../../../util/date/valid-date';
import now from '../../../util/now';

export default function EditionDateRangeControl() {
  const { t } = useTranslation(['edition', 'common']);
  const { watch } = useFormContext<EditionDto>();
  const tomorrow = startOfDay(addDays(now(), 1));
  const start = validDate(watch('dateRange.start'));
  const end = validDate(watch('dateRange.end'));
  const [defaultStart] = React.useState(start);
  const [defaultEnd] = React.useState(end);
  const startInThePast = defaultStart != null && defaultStart.valueOf() < now();
  const endInThePast = defaultEnd != null && defaultEnd.valueOf() < now();

  return (
    <>
      <DateInputFormControl<EditionDto>
        isDisabled={startInThePast}
        deps={['dateRange.end']}
        name="dateRange.start"
        isRequired
        label={t('edition:start')}
        referenceDate={tomorrow}
        min={
          !startInThePast
            ? {
                value: tomorrow,
                message: t('edition:editor.validation_error.start.min'),
              }
            : undefined
        }
        max={
          !startInThePast
            ? {
                value: end,
                message: t('edition:editor.validation_error.start.max'),
              }
            : undefined
        }
        helperText={t('edition:editor.helper_text.start', {
          start: startOfDay(watch('dateRange.start')),
        })}
      />

      <DateInputFormControl<EditionDto>
        isDisabled={endInThePast}
        deps={['dateRange.start']}
        name="dateRange.end"
        referenceDate={dateMax(start, tomorrow)}
        isRequired
        min={
          !endInThePast
            ? {
                value: dateMax(start, tomorrow),
                message: t('edition:editor.validation_error.end.min'),
              }
            : undefined
        }
        label={t('edition:end')}
        helperText={
          end &&
          t('edition:editor.helper_text.end', {
            end: endOfDay(end),
          })
        }
      />
    </>
  );
}
