import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import ListBreadcrumb from './common/list-breadcrumb';

const listRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'lists',
    handle: {
      breadcrumb: <Translate ns="list" i18nKey="list" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./list-lister/list-lister-route'),
      },
      {
        path: ':listId',
        handle: {
          breadcrumb: <ListBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./list-page/list-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./list-viewer/list-viewer-route'),
              },
              {
                path: 'entries',
                lazy: () => import('./list-entries/list-entries-route'),
              },
              {
                path: 'history',
                lazy: () => import('./list-history/list-history-route'),
              },
            ],
          },
        ],
      },
    ],
  },
];

export default listRoutes;
