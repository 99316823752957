/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Reference of an Edition
 * @export
 * @interface EditionReferenceDto
 */
export interface EditionReferenceDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof EditionReferenceDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EditionReferenceDto
     */
    name?: string;
}

/**
 * Check if a given object implements the EditionReferenceDto interface.
 */
export function instanceOfEditionReferenceDto(value: object): boolean {
    if (!('id' in value)) return false;
    return true;
}

export function EditionReferenceDtoFromJSON(json: any): EditionReferenceDto {
    return EditionReferenceDtoFromJSONTyped(json, false);
}

export function EditionReferenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditionReferenceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function EditionReferenceDtoToJSON(value?: EditionReferenceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
    };
}

