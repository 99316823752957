import React from 'react';
import { PersonLocationRelationDto } from '../../../api';
import { DataTableColumn } from '../../../ui/data-table';
import Optional from '../../../ui/optional/optional';
import Translate from '../../../util/translate/translate';
import LocationReference from '../../location/location-reference/location-reference';

const personLocationRelationColumns: DataTableColumn<PersonLocationRelationDto>[] = [
  {
    key: 'relationType',
    name: <Translate ns="person" i18nKey="relations.relationType" />,
    renderCell: (data) =>
      data.relationType && <Translate ns="person" i18nKey={`relations.relationTypeLabels.${data.relationType}`} />,
    cellProps: {
      whiteSpace: 'nowrap',
    },
  },
  {
    key: 'location',
    name: <Translate ns="person" i18nKey="relations.location_title" />,
    renderCell: (data) => <LocationReference size="sm" locationReference={data.connectedLocation} />,
  },
  {
    key: 'dateRange',
    name: <Translate ns="person" i18nKey="relations.date_range" />,
    renderCell: (data) => (
      <Translate ns="common">{(t) => t('format.date_range', { dateRange: data.dateRange })}</Translate>
    ),
  },
  {
    key: 'comment',
    name: <Translate ns="person" i18nKey="relations.comment" />,
    renderCell: (data) => <Optional>{data.comment}</Optional>,
  },
];

export default personLocationRelationColumns;
