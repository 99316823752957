/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OperationDto } from './OperationDto';
import {
    OperationDtoFromJSON,
    OperationDtoFromJSONTyped,
    OperationDtoToJSON,
} from './OperationDto';
import type { OperationTypeDto } from './OperationTypeDto';
import {
    OperationTypeDtoFromJSON,
    OperationTypeDtoFromJSONTyped,
    OperationTypeDtoToJSON,
} from './OperationTypeDto';

/**
 * 
 * @export
 * @interface LetOperationDto
 */
export interface LetOperationDto {
    /**
     * 
     * @type {OperationTypeDto}
     * @memberof LetOperationDto
     */
    operationType: OperationTypeDto;
    /**
     * 
     * @type {Array<OperationDto>}
     * @memberof LetOperationDto
     */
    subOperations: Array<OperationDto>;
    /**
     * 
     * @type {string}
     * @memberof LetOperationDto
     */
    alias?: string;
}

/**
 * Check if a given object implements the LetOperationDto interface.
 */
export function instanceOfLetOperationDto(value: object): boolean {
    if (!('operationType' in value)) return false;
    if (!('subOperations' in value)) return false;
    return true;
}

export function LetOperationDtoFromJSON(json: any): LetOperationDto {
    return LetOperationDtoFromJSONTyped(json, false);
}

export function LetOperationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LetOperationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'operationType': OperationTypeDtoFromJSON(json['operationType']),
        'subOperations': ((json['subOperations'] as Array<any>).map(OperationDtoFromJSON)),
        'alias': json['alias'] == null ? undefined : json['alias'],
    };
}

export function LetOperationDtoToJSON(value?: LetOperationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'operationType': OperationTypeDtoToJSON(value['operationType']),
        'subOperations': ((value['subOperations'] as Array<any>).map(OperationDtoToJSON)),
        'alias': value['alias'],
    };
}

