import { HStack } from '@chakra-ui/react';
import React from 'react';
import { OrderConfirmationReferenceDto, OrderConfirmationReferenceDtoFromJSON, OrderDto } from '../../../api';
import { DescriptionItem } from '../../../ui/description';
import Translate from '../../../util/translate/translate';
import OrderConfirmationReference from '../order-confirmation-reference/order-confirmation-reference';

interface OrderConfirmationOrderDescriptionItemProps {
  order: OrderDto;
}

export default function OrderConfirmationOrderDescriptionItem({ order }: OrderConfirmationOrderDescriptionItemProps) {
  const orderConfirmation = useOrderConfirmation(order);

  return orderConfirmation != null ? (
    <DescriptionItem label={<Translate ns="order_confirmation" i18nKey="orderConfirmation" />} span={2}>
      <HStack>
        <OrderConfirmationReference orderConfirmation={orderConfirmation} />
      </HStack>
    </DescriptionItem>
  ) : undefined;
}

function useOrderConfirmation(order: OrderDto): OrderConfirmationReferenceDto {
  return OrderConfirmationReferenceDtoFromJSON(order.extensions.orderConfirmation);
}
