import { ParseKeys } from 'i18next';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import useEvent from '../event/use-event/use-event';

const RundownPublicationLister = React.lazy(() => import('./rundown-publication-lister/rundown-publication-lister'));
const RundownViewer = React.lazy(() => import('./rundown-viewer/rundown-viewer'));
const RundownSettingsEditor = React.lazy(() => import('./rundown-settings-editor/rundown-settings-editor'));
const RundownHistory = React.lazy(() => import('./rundown-history/rundown-history'));
const RundownPage = React.lazy(() => import('./rundown-page/rundown-page'));
const RundownViewerMobile = React.lazy(() => import('./rundown-viewer-mobile/rundown-viewer-mobile'));

const rundownRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'rundown',
    handle: {
      breadcrumb: <Translate ns="rundown" i18nKey="rundown" />,
    },
    children: [
      {
        path: '',
        element: <RundownPage fixedHeight />,
        children: [
          {
            path: '',
            element: <RundownViewer />,
            handle: {
              helmet: <RundownHelmet i18nKey="viewer.helmet" />,
            },
          },
        ],
      },
      {
        path: '',
        element: <RundownPage />,
        children: [
          {
            path: 'settings',
            element: <RundownSettingsEditor />,
            handle: {
              helmet: <RundownHelmet i18nKey="settings.helmet" />,
            },
          },
          {
            path: 'publication',
            element: <RundownPublicationLister />,
            handle: {
              helmet: <RundownHelmet i18nKey="publication.helmet" />,
            },
          },
          {
            path: 'history',
            element: <RundownHistory />,
            handle: {
              helmet: <RundownHelmet i18nKey="history.helmet" />,
            },
          },
        ],
      },
    ],
  },
];

export default rundownRoutes;

export const rundownRoutesMobile: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'rundown',
    handle: {
      helmet: <RundownHelmet i18nKey="viewer.helmet" />,
      breadcrumb: <Translate ns="rundown" i18nKey="rundown" />,
    },
    element: <RundownViewerMobile />,
  },
];

interface RundownHelmetProps {
  i18nKey: ParseKeys<'rundown'>;
}

function RundownHelmet({ i18nKey }: RundownHelmetProps) {
  const { t } = useTranslation('rundown');
  const params = useParams<{ eventId: string }>();
  invariant(params.eventId, 'Empty eventId');
  const { title } = useEvent(params.eventId);

  return <Helmet title={t(i18nKey, { title })} />;
}
