import { chakra, Checkbox, Stack } from '@chakra-ui/react';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputFormControl from '../../../ui/form/input-form-control';

/**
 * Control to edit person's contact data.
 */
export default function InitialCommentControl() {
  const { t } = useTranslation('person');

  const isCommentEmpty = (value: string) => {
    return value == null || value.length === 0;
  };

  const initialComment = useWatch({ name: 'initialComment' });

  const { trigger } = useFormContext();

  const [noPersonalDataConfirm, setNoPersonalDataConfirm] = React.useState(false);

  const validateComment = (fieldValue: string) => {
    if (!isCommentEmpty(fieldValue)) {
      return noPersonalDataConfirm;
    }
    return true;
  };

  const checkValid = (fieldValue: string) => {
    return validateComment(fieldValue) || t('comments.validation_error.confirmMissing');
  };

  React.useEffect(() => {
    trigger('initialComment'); // triggers validation on field
  }, [trigger, noPersonalDataConfirm]);

  return (
    <Stack spacing={6}>
      <InputFormControl
        name="initialComment"
        isMultiline
        label={t('comments.text')}
        maxLength={100}
        validate={checkValid}
      />
      <Checkbox
        spacing={3}
        isDisabled={isCommentEmpty(initialComment)}
        onChange={(event) => setNoPersonalDataConfirm(event.target.checked)}
      >
        <chakra.span color={!validateComment(initialComment) ? 'text.error' : 'inherit'}>
          {t('comments.no_sensible_data_confirmation_simple_form')}
        </chakra.span>
      </Checkbox>
    </Stack>
  );
}
