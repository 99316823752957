/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OccupationDto } from './OccupationDto';
import {
    OccupationDtoFromJSON,
    OccupationDtoFromJSONTyped,
    OccupationDtoToJSON,
} from './OccupationDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';

/**
 * 
 * @export
 * @interface OccupationWithPersonDto
 */
export interface OccupationWithPersonDto {
    /**
     * 
     * @type {OccupationDto}
     * @memberof OccupationWithPersonDto
     */
    occupation: OccupationDto;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof OccupationWithPersonDto
     */
    person: PersonReferenceDto;
}

/**
 * Check if a given object implements the OccupationWithPersonDto interface.
 */
export function instanceOfOccupationWithPersonDto(value: object): boolean {
    if (!('occupation' in value)) return false;
    if (!('person' in value)) return false;
    return true;
}

export function OccupationWithPersonDtoFromJSON(json: any): OccupationWithPersonDto {
    return OccupationWithPersonDtoFromJSONTyped(json, false);
}

export function OccupationWithPersonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OccupationWithPersonDto {
    if (json == null) {
        return json;
    }
    return {
        
        'occupation': OccupationDtoFromJSON(json['occupation']),
        'person': PersonReferenceDtoFromJSON(json['person']),
    };
}

export function OccupationWithPersonDtoToJSON(value?: OccupationWithPersonDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'occupation': OccupationDtoToJSON(value['occupation']),
        'person': PersonReferenceDtoToJSON(value['person']),
    };
}

