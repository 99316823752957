import { IconButton, Tooltip } from '@chakra-ui/react';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { PersonDto, PersonListItemDto } from '../../../api';
import personApi from '../../../data-access/person-api';
import useToast from '../../../ui/use-toast/use-toast';
import useDialog from '../../../util/use-dialog/use-dialog';
import PersonDeleteDialog from '../person-delete/person-delete-dialog';

export interface PersonDeleteCompletelyButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  person: PersonDto | PersonListItemDto;
  label?: string;
  onSuccess?(): void;
}

const PersonDeleteCompletelyButton = React.forwardRef<HTMLButtonElement, PersonDeleteCompletelyButtonProps>(
  ({ children, person, onSuccess, onClick, ...props }, ref) => {
    const { t } = useTranslation('person');
    const [deleteDialogIsOpen, onDeleteDialogClose, openDeleteDialog] = useDialog<false>();

    const showDeleteSuccessToast = useToast({
      id: 'person-delete-completely-success-toast',
      status: 'success',
    });

    const showDeleteFailedToast = useToast({
      id: 'person-delete-completely-failed-toast',
      status: 'error',
    });

    const deletePerson = async (event: React.MouseEvent<HTMLButtonElement>) => {
      onClick?.(event);

      if (event.defaultPrevented) {
        return;
      }

      invariant(person.id != null, 'Missing person id');

      const checked = await openDeleteDialog();
      if (checked) {
        personApi
          .deletePerson({ id: person.id })
          .then(() => {
            showDeleteSuccessToast({
              title: t('action.delete.delete_success.title'),
              description: t('action.delete.delete_success.description', {
                firstName: person.firstName,
                surname: person.surname,
                personKey: person.personKey,
              }),
            });
            onSuccess?.();
          })
          .catch(() =>
            showDeleteFailedToast({
              title: t('action.delete.delete_failed.title'),
              description: t('action.delete.delete_failed.description', {
                firstName: person.firstName,
                surname: person.surname,
                personKey: person.personKey,
              }),
            }),
          );
      }
    };

    return (
      <>
        {children ? (
          <button {...props} onClick={deletePerson} ref={ref}>
            {children}
          </button>
        ) : (
          <Tooltip label={t('action.delete.title')}>
            <IconButton
              onClick={deletePerson}
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              aria-label={t('action.delete.title')}
              variant="ghost"
              colorScheme="red"
              size="sm"
            />
          </Tooltip>
        )}
        <PersonDeleteDialog person={person} isOpen={deleteDialogIsOpen} onClose={onDeleteDialogClose} />
      </>
    );
  },
);

export default PersonDeleteCompletelyButton;
