import { Center, ListItem, MenuItem, Spinner, Stack, Text, UnorderedList } from '@chakra-ui/react';
import { faToggleOff, faToggleOn } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import {
  LandingPageDesignDto,
  LandingPageDesignListItemDto,
  LandingPageDesignStatusBlockerDto,
  LandingPageDesignStatusDto,
} from '../../../api';
import landingPageDesignApi from '../../../data-access/landing-page-design-api';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import useToast from '../../../ui/use-toast/use-toast';
import usePlugins, { PluginToken } from '../../../util/plugin/use-plugins';
import useFetcher from '../../../util/swr/use-fetcher';
import { getLandingPageDesignStatusBlockers, landingPageDesignFetcher } from '../landing-page-design-queries';

export interface LandingPageDesignStatusToggleMenuItemProps {
  landingPageDesign: LandingPageDesignDto | LandingPageDesignListItemDto;
}

export default function LandingPageDesignStatusToggleMenuItem({
  landingPageDesign,
}: LandingPageDesignStatusToggleMenuItemProps) {
  const { t } = useTranslation(['landing_page_design']);
  const archiveLandingPageDesign = landingPageDesign.status === LandingPageDesignStatusDto.ACTIVE;
  const statusBlockers = useFetcher(
    getLandingPageDesignStatusBlockers,
    { id: landingPageDesign.id },
    {
      suspense: false,
      // Only get status blockers if landing page design would be archived
      active: archiveLandingPageDesign,
    },
  );
  const toast = useToast();

  const handleClick = async () => {
    if (archiveLandingPageDesign) {
      await landingPageDesignApi.archiveLandingPageDesign({ id: landingPageDesign.id });
    } else {
      await landingPageDesignApi.activateLandingPageDesign({ id: landingPageDesign.id });
    }

    toast({
      status: 'success',
      title: archiveLandingPageDesign
        ? t('landing_page_design:toast.archive.header')
        : t('landing_page_design:toast.activate.header'),
      description: archiveLandingPageDesign
        ? t('landing_page_design:toast.archive.message', { brand: landingPageDesign.brand })
        : t('landing_page_design:toast.activate.message', { brand: landingPageDesign.brand }),
    });

    await landingPageDesignFetcher.mutate();
  };

  const icon = <FontAwesomeIcon icon={archiveLandingPageDesign ? faToggleOff : faToggleOn} fixedWidth />;
  const children = archiveLandingPageDesign
    ? t('landing_page_design:action.archive')
    : t('landing_page_design:action.activate');

  // Next landing page status would be ACTIVE.
  if (!archiveLandingPageDesign) {
    return (
      <MenuItem icon={icon} onClick={handleClick}>
        {children}
      </MenuItem>
    );
  }

  // Next landing page status would be ARCHIVED. Status blockers not loaded yet.
  if (statusBlockers == null) {
    return (
      <MenuItem
        isDisabled
        icon={
          <Center w={5}>
            <Spinner size="sm" />
          </Center>
        }
      >
        {children}
      </MenuItem>
    );
  }

  // Next landing page status would be ARCHIVED. Check for status blockers blocking landing page
  // design to be archived.
  return (
    <MenuItem
      as={DisableableButton}
      isDisabled={statusBlockers.length > 0}
      disableReason={
        <LandingPageDesignStatusDisableReason landingPageDesign={landingPageDesign} statusBlockers={statusBlockers} />
      }
      icon={icon}
      onClick={handleClick}
    >
      {children}
    </MenuItem>
  );
}

interface LandingPageDesignStatusDisableReasonProps {
  landingPageDesign: LandingPageDesignDto | LandingPageDesignListItemDto;
  statusBlockers: LandingPageDesignStatusBlockerDto[];
}

export const LANDING_PAGE_DESIGN_STATUS_BLOCKER_RENDERER = new PluginToken<
  (statusBlocker: LandingPageDesignStatusBlockerDto) => ReactNode
>('LandingPageDesignStatusBlockerRenderer');

function LandingPageDesignStatusDisableReason({
  landingPageDesign,
  statusBlockers,
}: LandingPageDesignStatusDisableReasonProps) {
  const { t } = useTranslation(['landing_page_design']);
  const statusBlockerExtension = usePlugins(LANDING_PAGE_DESIGN_STATUS_BLOCKER_RENDERER).at(0);
  invariant(statusBlockerExtension != null, 'Missing status blocker renderer');

  return (
    <Stack spacing={2}>
      <Text>{t('landing_page_design:action.archiveDisableReasonBlocker', { brand: landingPageDesign.brand })}</Text>
      <UnorderedList>
        {statusBlockers.map((blocker) => (
          <ListItem key={blocker.id}>{statusBlockerExtension(blocker)}</ListItem>
        ))}
      </UnorderedList>
    </Stack>
  );
}
