export function joinAndEscapeFilterString(values: string[]): string {
  return values.map((value) => value.replaceAll(',', '\\,')).join(',');
}

export function splitAndUnescapeFilterString(value: string): string[] {
  return value.split(',').reduce<string[]>((values, value) => {
    const prevValue = values.at(-1);

    // The Previous value ends with a backslash, so the comma is escaped. Let's
    // remove the backslash and append the current value to the previous one.
    if (prevValue != null && prevValue.endsWith('\\')) {
      values.splice(-1, 1, `${prevValue.slice(0, -1)},${value}`);
    }
    // Nothing to see here, just a normal value.
    else {
      values.push(value);
    }

    return values;
  }, []);
}
