/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryReferenceDto } from './CountryReferenceDto';
import {
    CountryReferenceDtoFromJSON,
    CountryReferenceDtoFromJSONTyped,
    CountryReferenceDtoToJSON,
} from './CountryReferenceDto';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';
import type { VisaTemplateStatusDto } from './VisaTemplateStatusDto';
import {
    VisaTemplateStatusDtoFromJSON,
    VisaTemplateStatusDtoFromJSONTyped,
    VisaTemplateStatusDtoToJSON,
} from './VisaTemplateStatusDto';

/**
 * Organizational representation of a visa template
 * @export
 * @interface VisaTemplateListItemDto
 */
export interface VisaTemplateListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof VisaTemplateListItemDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof VisaTemplateListItemDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof VisaTemplateListItemDto
     */
    title: string;
    /**
     * 
     * @type {Array<CountryReferenceDto>}
     * @memberof VisaTemplateListItemDto
     */
    countries: Array<CountryReferenceDto>;
    /**
     * 
     * @type {Array<SectionReferenceDto>}
     * @memberof VisaTemplateListItemDto
     */
    guestSections: Array<SectionReferenceDto>;
    /**
     * 
     * @type {boolean}
     * @memberof VisaTemplateListItemDto
     */
    defaultTemplate: boolean;
    /**
     * 
     * @type {VisaTemplateStatusDto}
     * @memberof VisaTemplateListItemDto
     */
    status: VisaTemplateStatusDto;
}

/**
 * Check if a given object implements the VisaTemplateListItemDto interface.
 */
export function instanceOfVisaTemplateListItemDto(value: object): boolean {
    if (!('title' in value)) return false;
    if (!('countries' in value)) return false;
    if (!('guestSections' in value)) return false;
    if (!('defaultTemplate' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function VisaTemplateListItemDtoFromJSON(json: any): VisaTemplateListItemDto {
    return VisaTemplateListItemDtoFromJSONTyped(json, false);
}

export function VisaTemplateListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisaTemplateListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'title': json['title'],
        'countries': ((json['countries'] as Array<any>).map(CountryReferenceDtoFromJSON)),
        'guestSections': ((json['guestSections'] as Array<any>).map(SectionReferenceDtoFromJSON)),
        'defaultTemplate': json['defaultTemplate'],
        'status': VisaTemplateStatusDtoFromJSON(json['status']),
    };
}

export function VisaTemplateListItemDtoToJSON(value?: VisaTemplateListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'title': value['title'],
        'countries': ((value['countries'] as Array<any>).map(CountryReferenceDtoToJSON)),
        'guestSections': ((value['guestSections'] as Array<any>).map(SectionReferenceDtoToJSON)),
        'defaultTemplate': value['defaultTemplate'],
        'status': VisaTemplateStatusDtoToJSON(value['status']),
    };
}

