/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PersonNameDto } from './PersonNameDto';
import {
    PersonNameDtoFromJSON,
    PersonNameDtoFromJSONTyped,
    PersonNameDtoToJSON,
} from './PersonNameDto';

/**
 * 
 * @export
 * @interface SimplePersonDto
 */
export interface SimplePersonDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof SimplePersonDto
     */
    id: string;
    /**
     * 
     * @type {PersonNameDto}
     * @memberof SimplePersonDto
     */
    name: PersonNameDto;
    /**
     * The unique key for a person starting with "P".
     * @type {string}
     * @memberof SimplePersonDto
     */
    personKey: string;
}

/**
 * Check if a given object implements the SimplePersonDto interface.
 */
export function instanceOfSimplePersonDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('personKey' in value)) return false;
    return true;
}

export function SimplePersonDtoFromJSON(json: any): SimplePersonDto {
    return SimplePersonDtoFromJSONTyped(json, false);
}

export function SimplePersonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimplePersonDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': PersonNameDtoFromJSON(json['name']),
        'personKey': json['personKey'],
    };
}

export function SimplePersonDtoToJSON(value?: SimplePersonDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': PersonNameDtoToJSON(value['name']),
        'personKey': value['personKey'],
    };
}

