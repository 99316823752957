/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BlockElementDto } from './BlockElementDto';
import {
    BlockElementDtoFromJSON,
    BlockElementDtoFromJSONTyped,
    BlockElementDtoToJSON,
} from './BlockElementDto';
import type { ModuleTypeDto } from './ModuleTypeDto';
import {
    ModuleTypeDtoFromJSON,
    ModuleTypeDtoFromJSONTyped,
    ModuleTypeDtoToJSON,
} from './ModuleTypeDto';

/**
 * A module to integrate text in a mailing.
 * @export
 * @interface MailingTextModuleContentDto
 */
export interface MailingTextModuleContentDto {
    /**
     * 
     * @type {ModuleTypeDto}
     * @memberof MailingTextModuleContentDto
     */
    moduleType: ModuleTypeDto;
    /**
     * 
     * @type {Date}
     * @memberof MailingTextModuleContentDto
     */
    timestamp?: Date;
    /**
     * 
     * @type {Array<BlockElementDto>}
     * @memberof MailingTextModuleContentDto
     */
    content?: Array<BlockElementDto>;
    /**
     * 
     * @type {Array<BlockElementDto>}
     * @memberof MailingTextModuleContentDto
     */
    contentEnglish?: Array<BlockElementDto>;
}

/**
 * Check if a given object implements the MailingTextModuleContentDto interface.
 */
export function instanceOfMailingTextModuleContentDto(value: object): boolean {
    if (!('moduleType' in value)) return false;
    return true;
}

export function MailingTextModuleContentDtoFromJSON(json: any): MailingTextModuleContentDto {
    return MailingTextModuleContentDtoFromJSONTyped(json, false);
}

export function MailingTextModuleContentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingTextModuleContentDto {
    if (json == null) {
        return json;
    }
    return {
        
        'moduleType': ModuleTypeDtoFromJSON(json['moduleType']),
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
        'content': json['content'] == null ? undefined : ((json['content'] as Array<any>).map(BlockElementDtoFromJSON)),
        'contentEnglish': json['contentEnglish'] == null ? undefined : ((json['contentEnglish'] as Array<any>).map(BlockElementDtoFromJSON)),
    };
}

export function MailingTextModuleContentDtoToJSON(value?: MailingTextModuleContentDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'moduleType': ModuleTypeDtoToJSON(value['moduleType']),
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
        'content': value['content'] == null ? undefined : ((value['content'] as Array<any>).map(BlockElementDtoToJSON)),
        'contentEnglish': value['contentEnglish'] == null ? undefined : ((value['contentEnglish'] as Array<any>).map(BlockElementDtoToJSON)),
    };
}

