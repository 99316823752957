/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FieldModelDto } from './FieldModelDto';
import {
    FieldModelDtoFromJSON,
    FieldModelDtoFromJSONTyped,
    FieldModelDtoToJSON,
} from './FieldModelDto';

/**
 * 
 * @export
 * @interface EdgeModelDto
 */
export interface EdgeModelDto {
    /**
     * 
     * @type {string}
     * @memberof EdgeModelDto
     */
    collection: string;
    /**
     * 
     * @type {string}
     * @memberof EdgeModelDto
     */
    fromCollection: string;
    /**
     * 
     * @type {string}
     * @memberof EdgeModelDto
     */
    fromTranslation: string;
    /**
     * 
     * @type {string}
     * @memberof EdgeModelDto
     */
    toCollection: string;
    /**
     * 
     * @type {string}
     * @memberof EdgeModelDto
     */
    toTranslation: string;
    /**
     * 
     * @type {Array<FieldModelDto>}
     * @memberof EdgeModelDto
     */
    fields: Array<FieldModelDto>;
    /**
     * 
     * @type {string}
     * @memberof EdgeModelDto
     */
    joinField?: string;
}

/**
 * Check if a given object implements the EdgeModelDto interface.
 */
export function instanceOfEdgeModelDto(value: object): boolean {
    if (!('collection' in value)) return false;
    if (!('fromCollection' in value)) return false;
    if (!('fromTranslation' in value)) return false;
    if (!('toCollection' in value)) return false;
    if (!('toTranslation' in value)) return false;
    if (!('fields' in value)) return false;
    return true;
}

export function EdgeModelDtoFromJSON(json: any): EdgeModelDto {
    return EdgeModelDtoFromJSONTyped(json, false);
}

export function EdgeModelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EdgeModelDto {
    if (json == null) {
        return json;
    }
    return {
        
        'collection': json['collection'],
        'fromCollection': json['fromCollection'],
        'fromTranslation': json['fromTranslation'],
        'toCollection': json['toCollection'],
        'toTranslation': json['toTranslation'],
        'fields': ((json['fields'] as Array<any>).map(FieldModelDtoFromJSON)),
        'joinField': json['joinField'] == null ? undefined : json['joinField'],
    };
}

export function EdgeModelDtoToJSON(value?: EdgeModelDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'collection': value['collection'],
        'fromCollection': value['fromCollection'],
        'fromTranslation': value['fromTranslation'],
        'toCollection': value['toCollection'],
        'toTranslation': value['toTranslation'],
        'fields': ((value['fields'] as Array<any>).map(FieldModelDtoToJSON)),
        'joinField': value['joinField'],
    };
}

