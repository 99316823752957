/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateTimeRangeDto } from './ClosedDateTimeRangeDto';
import {
    ClosedDateTimeRangeDtoFromJSON,
    ClosedDateTimeRangeDtoFromJSONTyped,
    ClosedDateTimeRangeDtoToJSON,
} from './ClosedDateTimeRangeDto';
import type { CompanyReferenceDto } from './CompanyReferenceDto';
import {
    CompanyReferenceDtoFromJSON,
    CompanyReferenceDtoFromJSONTyped,
    CompanyReferenceDtoToJSON,
} from './CompanyReferenceDto';
import type { KeyPlayerPersonDto } from './KeyPlayerPersonDto';
import {
    KeyPlayerPersonDtoFromJSON,
    KeyPlayerPersonDtoFromJSONTyped,
    KeyPlayerPersonDtoToJSON,
} from './KeyPlayerPersonDto';
import type { KeyPlayerRoleDto } from './KeyPlayerRoleDto';
import {
    KeyPlayerRoleDtoFromJSON,
    KeyPlayerRoleDtoFromJSONTyped,
    KeyPlayerRoleDtoToJSON,
} from './KeyPlayerRoleDto';
import type { PersonSectionDto } from './PersonSectionDto';
import {
    PersonSectionDtoFromJSON,
    PersonSectionDtoFromJSONTyped,
    PersonSectionDtoToJSON,
} from './PersonSectionDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface KeyPlayerDto
 */
export interface KeyPlayerDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof KeyPlayerDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof KeyPlayerDto
     */
    version?: VersionDto;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof KeyPlayerDto
     */
    eventId: string;
    /**
     * 
     * @type {KeyPlayerPersonDto}
     * @memberof KeyPlayerDto
     */
    person: KeyPlayerPersonDto;
    /**
     * 
     * @type {Array<KeyPlayerRoleDto>}
     * @memberof KeyPlayerDto
     */
    roles: Array<KeyPlayerRoleDto>;
    /**
     * 
     * @type {string}
     * @memberof KeyPlayerDto
     */
    presence: KeyPlayerDtoPresenceEnum;
    /**
     * 
     * @type {ClosedDateTimeRangeDto}
     * @memberof KeyPlayerDto
     */
    dateTimeRangePresence?: ClosedDateTimeRangeDto;
    /**
     * 
     * @type {string}
     * @memberof KeyPlayerDto
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof KeyPlayerDto
     */
    source: KeyPlayerDtoSourceEnum;
    /**
     * 
     * @type {CompanyReferenceDto}
     * @memberof KeyPlayerDto
     */
    company?: CompanyReferenceDto;
    /**
     * 
     * @type {PersonSectionDto}
     * @memberof KeyPlayerDto
     */
    section?: PersonSectionDto;
}


/**
 * @export
 */
export const KeyPlayerDtoPresenceEnum = {
    PRESENT_ON_DUTY: 'PRESENT_ON_DUTY',
    PRESENT_OFF_DUTY: 'PRESENT_OFF_DUTY',
    ABSENT: 'ABSENT'
} as const;
export type KeyPlayerDtoPresenceEnum = typeof KeyPlayerDtoPresenceEnum[keyof typeof KeyPlayerDtoPresenceEnum];

/**
 * @export
 */
export const KeyPlayerDtoSourceEnum = {
    RUNDOWN: 'RUNDOWN',
    CUSTOM: 'CUSTOM'
} as const;
export type KeyPlayerDtoSourceEnum = typeof KeyPlayerDtoSourceEnum[keyof typeof KeyPlayerDtoSourceEnum];


/**
 * Check if a given object implements the KeyPlayerDto interface.
 */
export function instanceOfKeyPlayerDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('eventId' in value)) return false;
    if (!('person' in value)) return false;
    if (!('roles' in value)) return false;
    if (!('presence' in value)) return false;
    if (!('source' in value)) return false;
    return true;
}

export function KeyPlayerDtoFromJSON(json: any): KeyPlayerDto {
    return KeyPlayerDtoFromJSONTyped(json, false);
}

export function KeyPlayerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KeyPlayerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'eventId': json['eventId'],
        'person': KeyPlayerPersonDtoFromJSON(json['person']),
        'roles': ((json['roles'] as Array<any>).map(KeyPlayerRoleDtoFromJSON)),
        'presence': json['presence'],
        'dateTimeRangePresence': json['dateTimeRangePresence'] == null ? undefined : ClosedDateTimeRangeDtoFromJSON(json['dateTimeRangePresence']),
        'comment': json['comment'] == null ? undefined : json['comment'],
        'source': json['source'],
        'company': json['company'] == null ? undefined : CompanyReferenceDtoFromJSON(json['company']),
        'section': json['section'] == null ? undefined : PersonSectionDtoFromJSON(json['section']),
    };
}

export function KeyPlayerDtoToJSON(value?: KeyPlayerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'eventId': value['eventId'],
        'person': KeyPlayerPersonDtoToJSON(value['person']),
        'roles': ((value['roles'] as Array<any>).map(KeyPlayerRoleDtoToJSON)),
        'presence': value['presence'],
        'dateTimeRangePresence': ClosedDateTimeRangeDtoToJSON(value['dateTimeRangePresence']),
        'comment': value['comment'],
        'source': value['source'],
        'company': CompanyReferenceDtoToJSON(value['company']),
        'section': PersonSectionDtoToJSON(value['section']),
    };
}

