import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FunctionDto, GroupStaffRelationDto } from '../../../../../api';
import HistoryDisplaySettings from '../../../../../ui/history/history-display-settings';

export default function useGroupStaffHistorySettings(): HistoryDisplaySettings<GroupStaffRelationDto> {
  const { t } = useTranslation(['relations', 'group']);

  return useMemo<HistoryDisplaySettings<GroupStaffRelationDto>>(() => {
    return {
      attributeLabels: {
        staff: t('relations:history.staff'),
        function: t('group:connections.function'),
        groupAdmin: t('group:connections.groupAdmin'),
      },
      valueFormatter: {
        staff: (value) => (value == null ? undefined : `${value.firstName} ${value.surname}`),
        function: (value: FunctionDto) => (value == null ? undefined : t(`group:connections.functions.${value}`)),
        groupAdmin: (value) => (value == null ? undefined : t(`group:connections.groupAdminStatus.${value}`)),
      },
      diffEntireWord: {
        staff: true,
        function: true,
        groupAdmin: true,
      },
      name: 'staff.surname',
      showEditDescription: true,
    };
  }, [t]);
}
