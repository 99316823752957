import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Checkbox,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { faUnlink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EventEventConnectionDto, EventReferenceDto } from '../../../api';
import ElementContext from '../../../ui/form/element-control/element-context';
import { ElementFormProvider, useElementForm } from '../../../ui/form/element-control/element-form-modal-context';
import { ElementTableDispatchContext } from '../../../ui/form/element-control/element-table-control';
import Form from '../../../ui/form/form';
import useDefinedContext from '../../../util/context/use-defined-context/use-defined-context';
import ConnectionControl from './connection-control';

interface CancelConnectionButtonProps {
  label: string;
  fromEventReference: EventReferenceDto;
}

export default function CancelConnectionButton({ label, fromEventReference }: CancelConnectionButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDefinedContext(ElementTableDispatchContext);
  const { element, index } = useDefinedContext(ElementContext);

  const handleSubmit = React.useCallback(() => dispatch({ type: 'DELETE_ELEMENT', payload: index }), [dispatch, index]);

  return (
    <>
      <Tooltip label={label}>
        <IconButton
          onClick={onOpen}
          size="sm"
          variant="ghost"
          icon={<FontAwesomeIcon icon={faUnlink} />}
          aria-label={label}
        />
      </Tooltip>

      <ElementFormProvider<EventEventConnectionDto> element={element} onSubmit={handleSubmit}>
        <CancelConnectionForm isOpen={isOpen} onClose={onClose} fromEventReference={fromEventReference} />
      </ElementFormProvider>
    </>
  );
}

interface CancelConnectionFormProps {
  isOpen: boolean;
  onClose(): void;
  fromEventReference: EventReferenceDto;
}

function CancelConnectionForm({ isOpen, onClose, fromEventReference }: CancelConnectionFormProps) {
  const { t } = useTranslation(['common', 'event']);
  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const { onSubmit } = useElementForm<EventEventConnectionDto>();
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(false);
  const onCloseWithReset = () => {
    setDeleteConfirmation(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseWithReset} initialFocusRef={initialFocusRef}>
      <ModalOverlay />
      <ModalContent
        as={Form}
        noValidate
        onValid={(element: unknown) => {
          onSubmit(element as EventEventConnectionDto);
          onCloseWithReset();
        }}
        onSubmit={(event) => {
          event.stopPropagation();
        }}
        initialFocusRef={undefined}
      >
        <ModalHeader>{t('event:eventConnections.delete_connection')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <ConnectionControl
              fromReference={fromEventReference}
              connectionEditable={false}
              toReferenceEditable={false}
            />
            <>
              <Alert status="warning">
                <AlertIcon />
                <AlertDescription>
                  <Text>{t('event:eventConnections.alert_delete')}</Text>
                </AlertDescription>
              </Alert>

              <Checkbox
                onChange={(event) => setDeleteConfirmation(event.target.checked)}
                isChecked={deleteConfirmation}
                spacing={3}
                ref={initialFocusRef}
              >
                {t('event:eventConnections.delete_confirmation')}
              </Checkbox>
            </>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onCloseWithReset}>
            {t('common:action.abort')}
          </Button>
          <Button colorScheme="red" type="submit" isDisabled={!deleteConfirmation}>
            {t('common:action.delete')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
