/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LanguagesDto } from './LanguagesDto';
import {
    LanguagesDtoFromJSON,
    LanguagesDtoFromJSONTyped,
    LanguagesDtoToJSON,
} from './LanguagesDto';

/**
 * 
 * @export
 * @interface StaffLanguageDto
 */
export interface StaffLanguageDto {
    /**
     * 
     * @type {LanguagesDto}
     * @memberof StaffLanguageDto
     */
    language: LanguagesDto;
    /**
     * Level of the language
     * @type {string}
     * @memberof StaffLanguageDto
     */
    languageLevel: StaffLanguageDtoLanguageLevelEnum;
}


/**
 * @export
 */
export const StaffLanguageDtoLanguageLevelEnum = {
    A: 'A',
    B: 'B',
    C: 'C'
} as const;
export type StaffLanguageDtoLanguageLevelEnum = typeof StaffLanguageDtoLanguageLevelEnum[keyof typeof StaffLanguageDtoLanguageLevelEnum];


/**
 * Check if a given object implements the StaffLanguageDto interface.
 */
export function instanceOfStaffLanguageDto(value: object): boolean {
    if (!('language' in value)) return false;
    if (!('languageLevel' in value)) return false;
    return true;
}

export function StaffLanguageDtoFromJSON(json: any): StaffLanguageDto {
    return StaffLanguageDtoFromJSONTyped(json, false);
}

export function StaffLanguageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffLanguageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'language': LanguagesDtoFromJSON(json['language']),
        'languageLevel': json['languageLevel'],
    };
}

export function StaffLanguageDtoToJSON(value?: StaffLanguageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'language': LanguagesDtoToJSON(value['language']),
        'languageLevel': value['languageLevel'],
    };
}

