/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EventReferenceDto } from './EventReferenceDto';
import {
    EventReferenceDtoFromJSON,
    EventReferenceDtoFromJSONTyped,
    EventReferenceDtoToJSON,
} from './EventReferenceDto';
import type { LaneElementDto } from './LaneElementDto';
import {
    LaneElementDtoFromJSON,
    LaneElementDtoFromJSONTyped,
    LaneElementDtoToJSON,
} from './LaneElementDto';

/**
 * 
 * @export
 * @interface LaneElementSearchResultDto
 */
export interface LaneElementSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof LaneElementSearchResultDto
     */
    resultType: LaneElementSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof LaneElementSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {LaneElementDto}
     * @memberof LaneElementSearchResultDto
     */
    laneElement: LaneElementDto;
    /**
     * 
     * @type {EventReferenceDto}
     * @memberof LaneElementSearchResultDto
     */
    event: EventReferenceDto;
}


/**
 * @export
 */
export const LaneElementSearchResultDtoResultTypeEnum = {
    RUNDOWN_LANE_ELEMENT: 'RUNDOWN_LANE_ELEMENT'
} as const;
export type LaneElementSearchResultDtoResultTypeEnum = typeof LaneElementSearchResultDtoResultTypeEnum[keyof typeof LaneElementSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the LaneElementSearchResultDto interface.
 */
export function instanceOfLaneElementSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('laneElement' in value)) return false;
    if (!('event' in value)) return false;
    return true;
}

export function LaneElementSearchResultDtoFromJSON(json: any): LaneElementSearchResultDto {
    return LaneElementSearchResultDtoFromJSONTyped(json, false);
}

export function LaneElementSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaneElementSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'laneElement': LaneElementDtoFromJSON(json['laneElement']),
        'event': EventReferenceDtoFromJSON(json['event']),
    };
}

export function LaneElementSearchResultDtoToJSON(value?: LaneElementSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'laneElement': LaneElementDtoToJSON(value['laneElement']),
        'event': EventReferenceDtoToJSON(value['event']),
    };
}

