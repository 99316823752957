import React from 'react';
import invariant from 'tiny-invariant';
import { productIcon } from '../../../ui/icons/business-objects';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';

interface ProductReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  productReference: { id: string; title: string; belongsToSections?: string[] };
}

export default function ProductReference({ productReference, ...props }: ProductReferenceProps) {
  const belongsToSections = productReference.belongsToSections;
  invariant(belongsToSections != null, 'must not be null');

  return (
    <Reference
      icon={productIcon}
      to={'/products/' + productReference.id}
      necessaryPermission={({ hasPermission, hasPermissionFromSection }) => {
        return (
          hasPermission('PRODUCT:CAN_SEE_DETAILS') ||
          belongsToSections.some((sectionId) => hasPermissionFromSection(sectionId, 'PRODUCT:CAN_SEE_DETAILS_OWN'))
        );
      }}
      {...props}
    >
      {productReference.title}
    </Reference>
  );
}
