import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { BreadcrumbRouteObject } from '../../../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../../../ui/helmet/helmet-outlet';
import useFetcher from '../../../../util/swr/use-fetcher';
import PersonPage from '../../../person/person-page/person-page';
import { fetchPerson } from '../../../person/person-queries';

const PersonAwardPageRelationContent = React.lazy(() => import('../person-award-page-content'));

const personAwardRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: '',
    element: <PersonPage />,
    children: [
      {
        path: 'awards',
        element: <PersonAwardPageRelationContent />,
        handle: {
          helmet: <PersonAwardsHelmet />,
        },
      },
    ],
  },
];

export default personAwardRoutes;

function PersonAwardsHelmet() {
  const { t } = useTranslation('award');
  const params = useParams<{ personId: string }>();
  invariant(params.personId, 'Empty personId');
  const person = useFetcher(fetchPerson, { id: params.personId });

  return <Helmet title={t('person.helmet', { firstName: person.firstName, surname: person.surname })} />;
}
