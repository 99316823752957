import { HStack } from '@chakra-ui/react';
import React from 'react';
import { FieldPath, FieldValues, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CodeDto, ProductReferenceDto } from '../../../api';
import productApi from '../../../data-access/product-api';
import FormControl from '../../../ui/form/form-control';
import MultiValueAsyncSelectControl from '../../../ui/form/select-control/multi-value-async-select-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';

const discountForWholeOrderOptions = [true, false];

interface DiscountWrapperProps<T extends FieldValues> {
  path?: FieldPath<T>;
}

export default function ProductSelectionControl<T extends FieldValues>({ path }: DiscountWrapperProps<T>) {
  const { t } = useTranslation(['code', 'common']);
  const prefixWithPath = <TPath extends string>(name: TPath) => (path != null ? (`${path}.${name}` as TPath) : name);

  const loadOptions = async (value: string, pageSizeLimit: number): Promise<ProductReferenceDto[]> => {
    const page = await productApi.searchProducts({
      pageable: { size: pageSizeLimit, sort: ['title,ASC'] },
      q: value,
    });
    return page.content.map((product) => ({
      id: product.id!,
      title: product.title,
      titleEnglish: product.titleEnglish,
      articleNumber: product.articleNumber,
      availability: product.price.availability,
      belongsToSections: product.owners.map((owner) => owner.section.id),
    }));
  };

  const discountWholeOrder = useWatch({ name: prefixWithPath('discountForWholeOrder') }) as boolean | undefined;
  const { setValue } = useFormContext();

  return (
    <HStack alignItems="start">
      <FormControl<CodeDto>
        name={prefixWithPath('discountForWholeOrder')}
        label={t('code:discountForWholeOrder')}
        isRequired
      >
        <ValueSelectControl<boolean>
          name={prefixWithPath('discountForWholeOrder')}
          label={t('code:discountForWholeOrder')}
          options={discountForWholeOrderOptions}
          isClearable={false}
          renderLabel={(option) => t(`common:boolean_viewer.${option}`)}
          // rule for required as the isRequired rule will say that false === undefined
          rules={{
            validate: (value) =>
              value == null
                ? t('common:validation_error.required', { field: t('code:discountForWholeOrder') })
                : undefined,
          }}
          onChange={(newValue) => {
            if (newValue === discountWholeOrder) {
              return;
            }
            if (newValue === true) {
              setValue(prefixWithPath('products'), undefined);
            }
          }}
        />
      </FormControl>
      {discountWholeOrder === false && (
        <FormControl<CodeDto> label={t('products')} name={prefixWithPath('products')} isRequired>
          <MultiValueAsyncSelectControl<ProductReferenceDto>
            label={t('products')}
            optionIdentifier={(product) => product.id}
            loadOptions={loadOptions}
            renderLabel={(option) => option.title}
            isRequired
            name={prefixWithPath('products')}
          />
        </FormControl>
      )}
    </HStack>
  );
}
