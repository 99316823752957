import unconfirmedCompanyApi from '../../data-access/unconfirmed-company-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';

export const unconfirmedCompanyFetcher = createFetcherScope(unconfirmedCompanyApi);
export const searchUnconfirmedCompanies = unconfirmedCompanyFetcher.createFetcher(
  unconfirmedCompanyApi.searchUnconfirmedCompanies,
);
export const fetchUnconfirmedCompany = unconfirmedCompanyFetcher.createFetcher(
  unconfirmedCompanyApi.fetchUnconfirmedCompany,
);
