/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryReferenceDto } from './CountryReferenceDto';
import {
    CountryReferenceDtoFromJSON,
    CountryReferenceDtoFromJSONTyped,
    CountryReferenceDtoToJSON,
} from './CountryReferenceDto';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { LocationZoneReferenceDto } from './LocationZoneReferenceDto';
import {
    LocationZoneReferenceDtoFromJSON,
    LocationZoneReferenceDtoFromJSONTyped,
    LocationZoneReferenceDtoToJSON,
} from './LocationZoneReferenceDto';
import type { StandCategoryDto } from './StandCategoryDto';
import {
    StandCategoryDtoFromJSON,
    StandCategoryDtoFromJSONTyped,
    StandCategoryDtoToJSON,
} from './StandCategoryDto';
import type { StandInvoiceDataDto } from './StandInvoiceDataDto';
import {
    StandInvoiceDataDtoFromJSON,
    StandInvoiceDataDtoFromJSONTyped,
    StandInvoiceDataDtoToJSON,
} from './StandInvoiceDataDto';
import type { StandStatusDto } from './StandStatusDto';
import {
    StandStatusDtoFromJSON,
    StandStatusDtoFromJSONTyped,
    StandStatusDtoToJSON,
} from './StandStatusDto';
import type { StandTypeDto } from './StandTypeDto';
import {
    StandTypeDtoFromJSON,
    StandTypeDtoFromJSONTyped,
    StandTypeDtoToJSON,
} from './StandTypeDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * Market Stand for EFM
 * @export
 * @interface StandDto
 */
export interface StandDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof StandDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof StandDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof StandDto
     */
    name: string;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof StandDto
     */
    edition: EditionReferenceDto;
    /**
     * The unique key for a stand starting with "ST".
     * @type {string}
     * @memberof StandDto
     */
    standId: string;
    /**
     * Manually assigned stand number
     * @type {string}
     * @memberof StandDto
     */
    standNumber?: string;
    /**
     * 
     * @type {LocationZoneReferenceDto}
     * @memberof StandDto
     */
    location: LocationZoneReferenceDto;
    /**
     * 
     * @type {StandCategoryDto}
     * @memberof StandDto
     */
    category?: StandCategoryDto;
    /**
     * 
     * @type {StandTypeDto}
     * @memberof StandDto
     */
    type: StandTypeDto;
    /**
     * 
     * @type {boolean}
     * @memberof StandDto
     */
    newCustomer: boolean;
    /**
     * 
     * @type {string}
     * @memberof StandDto
     */
    permanentComment?: string;
    /**
     * 
     * @type {string}
     * @memberof StandDto
     */
    editionRelatedComment?: string;
    /**
     * 
     * @type {StandStatusDto}
     * @memberof StandDto
     */
    status: StandStatusDto;
    /**
     * 
     * @type {Array<CountryReferenceDto>}
     * @memberof StandDto
     */
    countries: Array<CountryReferenceDto>;
    /**
     * 
     * @type {StandInvoiceDataDto}
     * @memberof StandDto
     */
    invoiceData: StandInvoiceDataDto;
}

/**
 * Check if a given object implements the StandDto interface.
 */
export function instanceOfStandDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('standId' in value)) return false;
    if (!('location' in value)) return false;
    if (!('type' in value)) return false;
    if (!('newCustomer' in value)) return false;
    if (!('status' in value)) return false;
    if (!('countries' in value)) return false;
    if (!('invoiceData' in value)) return false;
    return true;
}

export function StandDtoFromJSON(json: any): StandDto {
    return StandDtoFromJSONTyped(json, false);
}

export function StandDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'name': json['name'],
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'standId': json['standId'],
        'standNumber': json['standNumber'] == null ? undefined : json['standNumber'],
        'location': LocationZoneReferenceDtoFromJSON(json['location']),
        'category': json['category'] == null ? undefined : StandCategoryDtoFromJSON(json['category']),
        'type': StandTypeDtoFromJSON(json['type']),
        'newCustomer': json['newCustomer'],
        'permanentComment': json['permanentComment'] == null ? undefined : json['permanentComment'],
        'editionRelatedComment': json['editionRelatedComment'] == null ? undefined : json['editionRelatedComment'],
        'status': StandStatusDtoFromJSON(json['status']),
        'countries': ((json['countries'] as Array<any>).map(CountryReferenceDtoFromJSON)),
        'invoiceData': StandInvoiceDataDtoFromJSON(json['invoiceData']),
    };
}

export function StandDtoToJSON(value?: StandDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'name': value['name'],
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'standId': value['standId'],
        'standNumber': value['standNumber'],
        'location': LocationZoneReferenceDtoToJSON(value['location']),
        'category': StandCategoryDtoToJSON(value['category']),
        'type': StandTypeDtoToJSON(value['type']),
        'newCustomer': value['newCustomer'],
        'permanentComment': value['permanentComment'],
        'editionRelatedComment': value['editionRelatedComment'],
        'status': StandStatusDtoToJSON(value['status']),
        'countries': ((value['countries'] as Array<any>).map(CountryReferenceDtoToJSON)),
        'invoiceData': StandInvoiceDataDtoToJSON(value['invoiceData']),
    };
}

