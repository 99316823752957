import { useTranslation } from 'react-i18next';
import { RoleReferenceDto } from '../../../api';
import { adminRoleIcon, sectionRoleIcon } from '../../../ui/icons/business-objects';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';
import usePermission from '../../permission/use-permission';

export interface RoleReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  roleReference: RoleReferenceDto;
}

export default function RoleReference({ roleReference, ...props }: RoleReferenceProps) {
  const { hasPermission, hasPermissionFromSection } = usePermission();
  const { t } = useTranslation(['permission_role']);

  const canSeeSection =
    hasPermission('SECTION:CAN_SEE_DETAILS') ||
    hasPermissionFromSection(roleReference.sectionId, 'SECTION:CAN_SEE_DETAILS_OWN');

  const canSeeDetails =
    canSeeSection &&
    (roleReference.isAdminRole
      ? hasPermission('ADMIN_ROLE:CAN_SEE_DETAILS') ||
        (roleReference.permission.userHasRole && hasPermission('ADMIN_ROLE:CAN_SEE_DETAILS_OF_OWN_ROLE'))
      : hasPermission('STAFF_ROLE:CAN_SEE_DETAILS') ||
        hasPermissionFromSection(roleReference.sectionId, 'STAFF_ROLE:CAN_SEE_DETAILS_OF_OWN_SECTION') ||
        (roleReference.permission.userHasRole && hasPermission('STAFF_ROLE:CAN_SEE_DETAILS_OF_OWN_ROLE')));

  return (
    <Reference
      icon={roleReference.isAdminRole ? adminRoleIcon : sectionRoleIcon}
      to={canSeeDetails ? getUrl(roleReference) : undefined}
      {...props}
    >
      {roleReference.isAdminRole
        ? t('permission_role:adminRoleName', { section: roleReference.sectionName })
        : roleReference.name}
    </Reference>
  );
}

function getUrl(role: RoleReferenceDto) {
  const roleId = role.isAdminRole ? 'admin' : role.id;
  return `/sections/${role.sectionId}/roles/${roleId}`;
}
