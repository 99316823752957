/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AbstractReferenceDto } from './AbstractReferenceDto';
import {
    AbstractReferenceDtoFromJSON,
    AbstractReferenceDtoFromJSONTyped,
    AbstractReferenceDtoToJSON,
} from './AbstractReferenceDto';

/**
 * The result of a status check.
 * @export
 * @interface LocationStatusCheckDto
 */
export interface LocationStatusCheckDto {
    /**
     * 
     * @type {boolean}
     * @memberof LocationStatusCheckDto
     */
    readonly isDeletable: boolean;
    /**
     * 
     * @type {Array<AbstractReferenceDto>}
     * @memberof LocationStatusCheckDto
     */
    rundownReferences: Array<AbstractReferenceDto>;
    /**
     * 
     * @type {Array<AbstractReferenceDto>}
     * @memberof LocationStatusCheckDto
     */
    eventReferences: Array<AbstractReferenceDto>;
}

/**
 * Check if a given object implements the LocationStatusCheckDto interface.
 */
export function instanceOfLocationStatusCheckDto(value: object): boolean {
    if (!('isDeletable' in value)) return false;
    if (!('rundownReferences' in value)) return false;
    if (!('eventReferences' in value)) return false;
    return true;
}

export function LocationStatusCheckDtoFromJSON(json: any): LocationStatusCheckDto {
    return LocationStatusCheckDtoFromJSONTyped(json, false);
}

export function LocationStatusCheckDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationStatusCheckDto {
    if (json == null) {
        return json;
    }
    return {
        
        'isDeletable': json['isDeletable'],
        'rundownReferences': ((json['rundownReferences'] as Array<any>).map(AbstractReferenceDtoFromJSON)),
        'eventReferences': ((json['eventReferences'] as Array<any>).map(AbstractReferenceDtoFromJSON)),
    };
}

export function LocationStatusCheckDtoToJSON(value?: LocationStatusCheckDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'rundownReferences': ((value['rundownReferences'] as Array<any>).map(AbstractReferenceDtoToJSON)),
        'eventReferences': ((value['eventReferences'] as Array<any>).map(AbstractReferenceDtoToJSON)),
    };
}

