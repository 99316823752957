import { HStack, Stack } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EventEvaluationDto } from '../../../api';
import NumberInputControl from '../../../ui/form/number-input-control';
import HelperPopover from '../../../ui/helper-buttons/helper-popover';

export default function EvaluationAccessOccupancyControl() {
  const { t } = useTranslation('event_evaluation');
  useFormContext<EventEvaluationDto>();

  return (
    <>
      <Stack spacing={6}>
        <NumberInputControl
          label={t('tickets_issued.label')}
          helperPopover={<HelperPopover children={t('tickets_issued.helper_popover')} />}
          name="ticketsIssued"
          min={0}
          max={9999}
        />
        <HStack spacing={6} alignItems="start">
          <NumberInputControl
            label={t('access.with_ticket')}
            helperPopover={<HelperPopover children={t('access.helper_popover_with_ticket')} />}
            name="accessWithTicket"
            min={0}
            max={9999}
          />
          <NumberInputControl
            label={t('access.without_ticket')}
            helperPopover={<HelperPopover children={t('access.helper_popover_without_ticket')} />}
            name="accessWithoutTicket"
            min={0}
            max={9999}
          />
        </HStack>
        <NumberInputControl
          label={t('occupancy.label')}
          helperPopover={<HelperPopover children={t('occupancy.helper_popover')} />}
          name="occupancy"
          isRequired
          min={0}
          max={9999}
        />
      </Stack>
    </>
  );
}
