/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecipientUpdateApplicationListItemDto
 */
export interface RecipientUpdateApplicationListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof RecipientUpdateApplicationListItemDto
     */
    invoiceId: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientUpdateApplicationListItemDto
     */
    invoiceNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof RecipientUpdateApplicationListItemDto
     */
    applicationDate: Date;
}

/**
 * Check if a given object implements the RecipientUpdateApplicationListItemDto interface.
 */
export function instanceOfRecipientUpdateApplicationListItemDto(value: object): boolean {
    if (!('invoiceId' in value)) return false;
    if (!('invoiceNumber' in value)) return false;
    if (!('applicationDate' in value)) return false;
    return true;
}

export function RecipientUpdateApplicationListItemDtoFromJSON(json: any): RecipientUpdateApplicationListItemDto {
    return RecipientUpdateApplicationListItemDtoFromJSONTyped(json, false);
}

export function RecipientUpdateApplicationListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecipientUpdateApplicationListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'invoiceId': json['invoiceId'],
        'invoiceNumber': json['invoiceNumber'],
        'applicationDate': (new Date(json['applicationDate'])),
    };
}

export function RecipientUpdateApplicationListItemDtoToJSON(value?: RecipientUpdateApplicationListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'invoiceId': value['invoiceId'],
        'invoiceNumber': value['invoiceNumber'],
        'applicationDate': ((value['applicationDate']).toISOString()),
    };
}

