/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The type and subtype of a product
 * @export
 */
export const ProductTypeDto = {
    FESTIVAL_ACCREDITATION: 'FESTIVAL_ACCREDITATION',
    FESTIVAL_MERCHANDISE: 'FESTIVAL_MERCHANDISE',
    FESTIVAL_MOVIE_REGISTRATION: 'FESTIVAL_MOVIE_REGISTRATION',
    FESTIVAL_HOTEL_NIGHT: 'FESTIVAL_HOTEL_NIGHT',
    EFM_ACCREDITATION: 'EFM_ACCREDITATION',
    EFM_MOVIE_REGISTRATION: 'EFM_MOVIE_REGISTRATION',
    EFM_HOTEL_NIGHT: 'EFM_HOTEL_NIGHT',
    EFM_BOOTH: 'EFM_BOOTH',
    EFM_BUYER: 'EFM_BUYER',
    PRESS_ACCREDITATION: 'PRESS_ACCREDITATION',
    HOTEL_OFFICE_HOTEL_NIGHT: 'HOTEL_OFFICE_HOTEL_NIGHT',
    HOTEL_OFFICE_OTHER: 'HOTEL_OFFICE_OTHER',
    ALL_ACCREDITATION: 'ALL_ACCREDITATION',
    ALL_HOTEL_NIGHT: 'ALL_HOTEL_NIGHT',
    ALL_INVOICE_RECIPIENT_UPDATE_FEE: 'ALL_INVOICE_RECIPIENT_UPDATE_FEE'
} as const;
export type ProductTypeDto = typeof ProductTypeDto[keyof typeof ProductTypeDto];


export function ProductTypeDtoFromJSON(json: any): ProductTypeDto {
    return ProductTypeDtoFromJSONTyped(json, false);
}

export function ProductTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductTypeDto {
    return json as ProductTypeDto;
}

export function ProductTypeDtoToJSON(value?: ProductTypeDto | null): any {
    return value as any;
}

