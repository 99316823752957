/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';

/**
 * 
 * @export
 * @interface LaneSectionsInnerDto
 */
export interface LaneSectionsInnerDto {
    /**
     * 
     * @type {SectionReferenceDto}
     * @memberof LaneSectionsInnerDto
     */
    reference: SectionReferenceDto;
}

/**
 * Check if a given object implements the LaneSectionsInnerDto interface.
 */
export function instanceOfLaneSectionsInnerDto(value: object): boolean {
    if (!('reference' in value)) return false;
    return true;
}

export function LaneSectionsInnerDtoFromJSON(json: any): LaneSectionsInnerDto {
    return LaneSectionsInnerDtoFromJSONTyped(json, false);
}

export function LaneSectionsInnerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaneSectionsInnerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'reference': SectionReferenceDtoFromJSON(json['reference']),
    };
}

export function LaneSectionsInnerDtoToJSON(value?: LaneSectionsInnerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'reference': SectionReferenceDtoToJSON(value['reference']),
    };
}

