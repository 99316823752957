import { ParseKeys } from 'i18next';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { BreadcrumbRouteObject } from '../../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../../ui/helmet/helmet-outlet';
import Translate from '../../../util/translate/translate';
import useLocation from '../../location/use-location/use-location';
import PlacementTemplatePage from './placement-template-page/placement-template-page';
import usePlacementTemplate from './placement-template-viewer/use-placement-template';

const PlacementTemplateEditor = React.lazy(() => import('./placement-template-editor/placement-template-editor'));
const PlacementTemplateViewer = React.lazy(() => import('./placement-template-viewer/placement-template-viewer'));
const PlacementTemplateLister = React.lazy(() => import('./placement-template-lister/placement-template-lister'));

const placementTemplateRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'placement-templates',
    handle: {
      breadcrumb: <Translate ns="placement_template" i18nKey="placement_templates" />,
      helmet: <PlacementTemplateListerHelmet />,
    },
    element: <PlacementTemplateLister />,
  },
];

export default placementTemplateRoutes;

export const placementTemplateEditorRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'placement-templates',
    handle: {
      breadcrumb: <Translate ns="placement_template" i18nKey="placement_templates" />,
    },
    children: [
      {
        path: ':placementTemplateId',
        handle: {
          breadcrumb: <PlacementTemplateBreadcrumb />,
        },
        children: [
          {
            path: '',
            element: <PlacementTemplatePage />,
            children: [
              {
                path: '',
                element: <PlacementTemplateViewer />,
                handle: {
                  helmet: <PlacementTemplateHelmet i18nKey="viewer.helmet" />,
                },
              },
            ],
          },
          {
            path: 'edit',
            element: <PlacementTemplateEditor />,
            handle: {
              breadcrumb: <Translate ns="placement_template" i18nKey="action.edit" />,
              helmet: <PlacementTemplateHelmet i18nKey="editor.helmetEdit" />,
            },
          },
        ],
      },
      {
        path: 'new',
        element: <PlacementTemplateEditor />,
        handle: {
          breadcrumb: <Translate ns="placement_template" i18nKey="action.new" />,
          helmet: <NewPlacementTemplateHelmet />,
        },
      },
    ],
  },
];

function PlacementTemplateListerHelmet() {
  const { t } = useTranslation('placement_template');
  const { locationId } = useParams<{ locationId: string }>();
  invariant(locationId != null, 'Missing location id');
  const { name, abbreviation } = useLocation(locationId);

  return <Helmet title={t('lister.helmet', { location: name, abbreviation })} />;
}

function NewPlacementTemplateHelmet() {
  const { t } = useTranslation('placement_template');
  const { locationId } = useParams<{ locationId: string }>();
  invariant(locationId != null, 'Missing location id');
  const { name, abbreviation } = useLocation(locationId);

  return <Helmet title={t('editor.helmetNew', { location: name, abbreviation })} />;
}

interface PlacementTemplateHelmetProps {
  i18nKey: ParseKeys<'placement_template'>;
}

function PlacementTemplateHelmet({ i18nKey }: PlacementTemplateHelmetProps) {
  const { t } = useTranslation('placement_template');
  const { locationId, placementTemplateId } = useParams<{ locationId: string; placementTemplateId: string }>();
  invariant(locationId != null && placementTemplateId != null, 'Missing location or placement template id');
  const { name, abbreviation } = useLocation(locationId);
  const { placementTemplate } = usePlacementTemplate(locationId, placementTemplateId);

  return <Helmet title={t(i18nKey, { location: name, abbreviation, placementTemplate: placementTemplate.name })} />;
}

function PlacementTemplateBreadcrumb() {
  const { locationId, placementTemplateId } = useParams<{ locationId: string; placementTemplateId: string }>();
  invariant(locationId != null && placementTemplateId != null, 'Missing location or placement template id');
  const { placementTemplate } = usePlacementTemplate(locationId, placementTemplateId);

  return <>{placementTemplate.name}</>;
}
