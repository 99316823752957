import React from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import PermissionClassGroupBreadcrumb from './common/permission-class-group-breadcrumb';

const permissionClassGroupRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'permission-class-groups',
    handle: {
      breadcrumb: <Translate ns="permission_class_group" i18nKey="permissionClassGroups" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./permission-class-group-lister/permission-class-group-lister-route'),
      },
      {
        path: ':permissionClassGroupId',
        handle: {
          breadcrumb: <PermissionClassGroupBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./permission-class-group-page/permission-class-group-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./permission-class-group-viewer/permission-class-group-viewer-route'),
              },
              {
                path: 'admin-roles',
                lazy: () => import('../permission-role/role-lister-for-class-group/role-lister-for-class-group-route'),
              },
              {
                path: 'history',
                lazy: () => import('./permission-class-group-history/permission-class-group-history-route'),
              },
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./permission-class-group-editor/edit-permission-class-group-route'),
          },
        ],
      },
    ],
  },
];

export default permissionClassGroupRoutes;
