import { endOfDay, isAfter } from 'date-fns';
import now from '../now';
import dateFormatter from './date-formatter';

interface DateRange {
  start: string;
  end: string;
}

const prefixMap = new Map<string, string>([
  ['de', 'Seit'],
  ['en', 'Since'],
  ['de-future', 'Ab'],
  ['en-future', 'From'],
]);

export default function dateRangeFormatter(
  value: DateRange | undefined,
  lng?: string,
  options?: Intl.DateTimeFormatOptions,
): string {
  const today = new Date(now());
  if (value === undefined) {
    return '';
  }

  const prefix = isAfter(new Date(value.start), endOfDay(today))
    ? prefixMap.get(lng == null ? 'de-future' : lng + '-future')
    : prefixMap.get(lng ?? 'de');

  if (value.end == null) {
    return `${prefix} ${dateFormatter(value.start, lng, options)}`;
  }

  const start = dateFormatter(value.start, lng, options);
  const end = dateFormatter(value.end, lng, options);
  if (end === start) {
    return start;
  }

  return `${start}\u200A\u200A–\u200A\u200A${end}`;
}
