import { Link, Text, TextProps } from '@chakra-ui/react';
import { get, isFunction } from 'lodash-es';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { HistoryEntryDto } from '../../api';
import usePromise from '../../util/use-promise/use-promise';
import HistoryDisplaySettings, { HistoryStatusEnum } from './history-display-settings';
import invariant from 'tiny-invariant';

interface HistoryEntryDescriptionProps extends TextProps {
  entry: HistoryEntryDto;
  name: HistoryDisplaySettings['name'];
  statusFormatter?: HistoryDisplaySettings['statusFormatter'];
  showEditDescription?: HistoryDisplaySettings['showEditDescription'];

  fetchMerged?(id: string, signal: AbortSignal): Promise<any>;
}

export default function HistoryEntryDescription({
  entry,
  statusFormatter,
  name,
  fetchMerged,
  showEditDescription = false,
  ...props
}: HistoryEntryDescriptionProps) {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();

  const context = pathname.split('/', 2)[1];
  const status = statusFormatter?.(entry.after);

  const mergedInto = usePromise(
    (signal) =>
      fetchMerged != null && entry.mergedInto != null ? fetchMerged(entry.mergedInto, signal) : Promise.resolve(),
    [entry.mergedInto, fetchMerged],
  );

  const createdFromA = usePromise(
    (abort) =>
      fetchMerged != null && entry.createdFromMergeOf != null
        ? fetchMerged(entry.createdFromMergeOf.objectA!, abort)
        : Promise.resolve(),
    [entry.createdFromMergeOf, fetchMerged],
  );

  const createdFromB = usePromise(
    (abort) =>
      fetchMerged != null && entry.createdFromMergeOf != null
        ? fetchMerged(entry.createdFromMergeOf.objectB!, abort)
        : Promise.resolve(),
    [entry.createdFromMergeOf, fetchMerged],
  );

  const createdDescription = (attributeBefore: string | undefined, attributeAfter: string | undefined) => {
    if (entry.mergedInto != null && fetchMerged != null) {
      return (
        <Trans
          t={t}
          i18nKey="history.merged_into"
          values={{ displayName: mergedInto?.displayName, mergedWith: '' }}
          components={{
            merged: <Link as={RouterLink} to={`/${context}/${entry.mergedInto}`} />,
            mergedInto: <Link as={RouterLink} to={`/${context}/${entry.mergedInto}`} />,
          }}
        />
      );
    }

    if (entry.createdFromMergeOf != null && fetchMerged != null) {
      return (
        <Trans
          t={t}
          i18nKey="history.created_from_merge"
          values={{
            displayNameA: createdFromA?.displayName,
            displayNameB: createdFromB?.displayName,
          }}
          components={{
            mergedA: <Link as={RouterLink} to={`/${context}/${entry.createdFromMergeOf.objectA}`} />,
            mergedB: <Link as={RouterLink} to={`/${context}/${entry.createdFromMergeOf.objectB}`} />,
          }}
        />
      );
    }

    if (status === HistoryStatusEnum.ANONYMISED) {
      return attributeAfter != null ? t('history.anonymised_working_object') : '';
    }

    if (status === HistoryStatusEnum.DELETED) {
      return attributeAfter != null
        ? t('history.deleted_working_object', {
            workingObject: attributeAfter,
          })
        : '';
    }

    if (entry.before == null) {
      return attributeAfter != null
        ? t('history.created_working_object', {
            workingObject: attributeAfter,
          })
        : '';
    }

    if (entry.after == null) {
      return attributeBefore != null
        ? t('history.deleted_working_object', {
            workingObject: attributeBefore,
          })
        : '';
    }

    if (showEditDescription) {
      return t('history.edited_working_object', {
        workingObject: attributeBefore,
      });
    }

    return null;
  };

  const description = createdDescription(renderName(entry, name, 'before'), renderName(entry, name, 'after'));

  if (description == null) {
    return null;
  }

  return (
    <Text color="text.muted" fontSize="sm" mt={-1} mb={2} data-testid="history-description" {...props}>
      {description}
    </Text>
  );
}

function renderName(entry: HistoryEntryDto, name: HistoryDisplaySettings['name'], beforeOrAfter: 'before' | 'after') {
  invariant(name != null, 'name can only be set to undefined for subcontrols where the name is never rendered');

  if (isFunction(name)) {
    return name(entry, entry[beforeOrAfter]);
  }

  return get(entry[beforeOrAfter], name);
}
