import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupPersonRelationDto } from '../../../../../api';
import HistoryDisplaySettings from '../../../../../ui/history/history-display-settings';

export default function useGroupPersonHistorySettings(): HistoryDisplaySettings<GroupPersonRelationDto> {
  const { t } = useTranslation(['relations', 'group']);

  return useMemo<HistoryDisplaySettings<GroupPersonRelationDto>>(() => {
    return {
      attributeLabels: {
        person: t('relations:history.person'),
        relationType: t('group:connections.relationType'),
      },
      valueFormatter: {
        person: (value) => (value == null ? undefined : `${value.firstName} ${value.surname} (${value.personKey})`),
        relationType: (value) => (value == null ? undefined : t(`group:connections.relationTypeOptions.${value}`)),
      },
      diffEntireWord: {
        person: true,
        relationType: true,
      },
      name: 'person.personKey',
      showEditDescription: true,
    };
  }, [t]);
}
