import { formatDistanceToNow } from 'date-fns';
import { de, enUS } from 'date-fns/locale';

export default function dateRelativeFormatter(value: Date | number | string, lng?: string) {
  if (!(value instanceof Date)) {
    value = new Date(value);
  }

  const locale = lng === 'de' ? de : enUS;

  return formatDistanceToNow(value, { locale, addSuffix: true });
}
