import { ParseKeys } from 'i18next/typescript/t';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { BreadcrumbRouteObject } from '../../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../../ui/helmet/helmet-outlet';
import Translate from '../../../util/translate/translate';
import useEvent from '../../event/use-event/use-event';

const PlacementPage = React.lazy(() => import('./placement-page/placement-page'));
const PlacementViewer = React.lazy(() => import('./placement-viewer/placement-viewer'));
const PlacementHistory = React.lazy(() => import('./placement-history/placement-history'));
const PlacementComparerPage = React.lazy(() => import('./placement-comparer/placement-comparer-page'));
const PlacementEditor = React.lazy(() => import('./placement-editor/placement-editor'));

const PlacementPageMobile = React.lazy(() => import('./placement-mobile/placement-page-mobile'));

const placementRoutes: (BreadcrumbRouteObject | HelmetRouteObject)[] = [
  {
    path: 'placement',
    handle: {
      breadcrumb: <Translate ns="placement" i18nKey="placement" />,
    },
    children: [
      {
        path: '',
        element: <PlacementPage />,
        children: [
          {
            path: '',
            element: (
              // Suspense is needed for a bug with redux. We are not sure what is happening here
              // exactly. Only that the redux Provider component is using useLayoutEffect under the
              // hood and that its cleanup function is called when a async resource was suspended.
              // It is not clear what is triggering the suspense.
              <React.Suspense>
                <PlacementViewer />
              </React.Suspense>
            ),
            handle: {
              helmet: <PlacementHelmet i18nKey="viewer.helmet" />,
            },
          },
          {
            path: 'history',
            element: <PlacementHistory />,
            handle: {
              helmet: <PlacementHelmet i18nKey="history.title" />,
            },
          },
        ],
      },
      {
        path: 'compare',
        handle: {
          breadcrumb: <Translate ns="placement" i18nKey="compare.breadcrumb" />,
          helmet: <PlacementHelmet i18nKey="compare.helmet" />,
        },
        element: <PlacementComparerPage />,
      },
    ],
  },
  {
    path: 'placement/edit',
    handle: {
      breadcrumb: <Translate ns="placement" i18nKey="placement" />,
      helmet: <PlacementHelmet i18nKey="editor.helmet" />,
    },
    element: <PlacementEditor />,
  },
];

export default placementRoutes;

export const placementRoutesMobile: (BreadcrumbRouteObject | HelmetRouteObject)[] = [
  {
    path: 'placement',
    handle: {
      breadcrumb: <Translate ns="placement" i18nKey="placement" />,
      helmet: <PlacementHelmet i18nKey="viewer.helmet" />,
    },
    element: <PlacementPageMobile />,
  },
];

export const placementPrintRoutes: (BreadcrumbRouteObject | HelmetRouteObject)[] = [
  {
    path: 'events/:eventId/placement',
    lazy: () => import('./placement-export/placement-export-route'),
  },
];

interface PlacementHelmetProps {
  i18nKey: ParseKeys<'placement'>;
}

function PlacementHelmet({ i18nKey }: PlacementHelmetProps) {
  const { t } = useTranslation('placement');
  const params = useParams<{ eventId: string }>();
  invariant(params.eventId, 'Empty eventId');
  const { title } = useEvent(params.eventId);

  return <Helmet title={t(i18nKey, { title })} />;
}
