import { Stack, Text, useId } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ValueSelect from '../../select/value-select';
import { useDataTableFilter } from '../data-table-context';
import { joinAndEscapeFilterString, splitAndUnescapeFilterString } from './filter-utils';
import { StringFilterSelect } from './string-filter';

/**
 * Exists filter operations.
 */
export enum OperatorOption {
  EXISTS = 'exists',
  NOT_EXISTS = '!exists',
  IN = 'in',
}

type OperatorOptionStrings = keyof typeof OperatorOption;

export interface ExistsOrInFilterProps {
  label: React.ReactNode;
  searchPropertySuffix?: string;
  showLabel?: boolean;

  loadOptions(value: string): Promise<string[]>;
}

// get the enum key
export const getKeyByValue = (value: string) => {
  const indexOfKey = Object.values(OperatorOption).indexOf(value as any as OperatorOption);
  return Object.keys(OperatorOption)[indexOfKey] as OperatorOptionStrings;
};

/**
 * Exists filter, may be used for all string columns in data tables.
 */
export default function ExistsOrInFilter({
  label,
  searchPropertySuffix,
  showLabel = true,
  loadOptions,
}: ExistsOrInFilterProps) {
  const { t } = useTranslation('common');
  const {
    property: existsProperty,
    getFilter,
    setFilter,
    removeFilters,
    replaceFilter,
    initialFocusRef,
  } = useDataTableFilter();
  let searchProperty = existsProperty;
  if (searchPropertySuffix) {
    searchProperty += searchPropertySuffix;
  }

  const id = useId(undefined, 'exists-or-in-filter');
  const { operator, value: filterValue } = getFilter(existsProperty) ?? getFilter(searchProperty) ?? {};
  const [operatorOption, setOperatorOption] = React.useState<OperatorOption | null>(
    operator ? (operator as OperatorOption) : null,
  );

  const handleOperatorChange = (operatorOption: OperatorOption | null) => {
    setOperatorOption(operatorOption);
    if (operatorOption === OperatorOption.IN) {
      removeFilters(searchProperty, existsProperty);
    } else if (operatorOption) {
      replaceFilter(searchProperty, {
        property: existsProperty,
        operator: operatorOption,
        value: '',
      });
    } else {
      removeFilters(searchProperty, existsProperty);
    }
  };

  const handleSelectChange = (values: string[] | null) => {
    if (values == null || values.length === 0) {
      removeFilters(searchProperty);
    } else {
      setFilter({ property: searchProperty, operator: OperatorOption.IN, value: joinAndEscapeFilterString(values) });
    }
  };

  return (
    <Stack as="fieldset" spacing={2} aria-labelledby={`${id}-label`}>
      {showLabel && (
        <Text as="div" fontSize="sm" fontWeight="medium" id={`${id}-label`}>
          {label}
        </Text>
      )}
      <ValueSelect<OperatorOption>
        options={[OperatorOption.EXISTS, OperatorOption.NOT_EXISTS, OperatorOption.IN]}
        renderLabel={(option) => t(`data_table.exists_filter.${getKeyByValue(option)}`)}
        name="operator"
        isClearable
        size="sm"
        aria-label={t(`data_table.string_filter.operator_label`)}
        onChange={handleOperatorChange}
        value={operatorOption}
      />
      {operatorOption === OperatorOption.IN && (
        <StringFilterSelect
          ref={initialFocusRef}
          values={!filterValue || filterValue === '' ? [] : splitAndUnescapeFilterString(filterValue)}
          loadOptions={loadOptions}
          onChange={handleSelectChange}
        />
      )}
    </Stack>
  );
}
