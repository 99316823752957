import { Button, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import useToast from '../../../ui/use-toast/use-toast';
import { DeleteRelationsDialog } from './delete-relations-dialog';

interface DeleteRelationButtonProps {
  selection: string[] | undefined;
  resetSelection: () => void;
  deleteRelations: (ids: string[]) => Promise<void>;

  mutate(): Promise<unknown>;

  isDisabled: boolean;

  onOpen?(): void;

  onClose?(): void;
}

export default function DeleteRelationsButton({
  selection,
  resetSelection,
  onOpen: onOpenProp,
  onClose: onCloseProp,
  deleteRelations,
  mutate,
  isDisabled,
}: DeleteRelationButtonProps) {
  const { isOpen, onClose, getButtonProps, getDisclosureProps } = useDisclosure({
    onOpen: onOpenProp,
    onClose: onCloseProp,
  });
  const { t } = useTranslation('relations');

  const showDeleteSuccessToast = useToast({
    id: 'delete-relation-success-toast',
    status: 'success',
  });

  const handleDelete = async () => {
    invariant(selection != null && selection.length > 0, 'Selection must not be empty.');

    await deleteRelations(selection);

    showDeleteSuccessToast({
      title: t('toast.deleteSuccess', { count: selection.length }),
      description: t('toast.deleteDescription', { count: selection.length }),
    });
    resetSelection();

    await mutate();

    onClose();
  };

  return (
    <>
      <Button {...getButtonProps()} colorScheme="red" aria-label={t('action.remove_other')} isDisabled={isDisabled}>
        {t('action.remove_other')}
      </Button>
      <DeleteRelationsDialog
        isOpen={isOpen}
        onClose={onClose}
        onDelete={handleDelete}
        selectionCount={selection?.length ?? 0}
        {...getDisclosureProps()}
      />
    </>
  );
}
