/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StandCopyRequestDto
 */
export interface StandCopyRequestDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof StandCopyRequestDto
     */
    standId: string;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof StandCopyRequestDto
     */
    editionId: string;
}

/**
 * Check if a given object implements the StandCopyRequestDto interface.
 */
export function instanceOfStandCopyRequestDto(value: object): boolean {
    if (!('standId' in value)) return false;
    if (!('editionId' in value)) return false;
    return true;
}

export function StandCopyRequestDtoFromJSON(json: any): StandCopyRequestDto {
    return StandCopyRequestDtoFromJSONTyped(json, false);
}

export function StandCopyRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandCopyRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'standId': json['standId'],
        'editionId': json['editionId'],
    };
}

export function StandCopyRequestDtoToJSON(value?: StandCopyRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'standId': value['standId'],
        'editionId': value['editionId'],
    };
}

