/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderConfirmationsExportRequestDto
 */
export interface OrderConfirmationsExportRequestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderConfirmationsExportRequestDto
     */
    filters?: Array<string>;
}

/**
 * Check if a given object implements the OrderConfirmationsExportRequestDto interface.
 */
export function instanceOfOrderConfirmationsExportRequestDto(value: object): boolean {
    return true;
}

export function OrderConfirmationsExportRequestDtoFromJSON(json: any): OrderConfirmationsExportRequestDto {
    return OrderConfirmationsExportRequestDtoFromJSONTyped(json, false);
}

export function OrderConfirmationsExportRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderConfirmationsExportRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'filters': json['filters'] == null ? undefined : json['filters'],
    };
}

export function OrderConfirmationsExportRequestDtoToJSON(value?: OrderConfirmationsExportRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'filters': value['filters'],
    };
}

