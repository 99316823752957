/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ConnectedEntityReferenceDto } from './ConnectedEntityReferenceDto';
import {
    ConnectedEntityReferenceDtoFromJSON,
    ConnectedEntityReferenceDtoFromJSONTyped,
    ConnectedEntityReferenceDtoToJSON,
} from './ConnectedEntityReferenceDto';
import type { FileMetadataDto } from './FileMetadataDto';
import {
    FileMetadataDtoFromJSON,
    FileMetadataDtoFromJSONTyped,
    FileMetadataDtoToJSON,
} from './FileMetadataDto';
import type { LandingPageReferenceDto } from './LandingPageReferenceDto';
import {
    LandingPageReferenceDtoFromJSON,
    LandingPageReferenceDtoFromJSONTyped,
    LandingPageReferenceDtoToJSON,
} from './LandingPageReferenceDto';
import type { MailingModuleContentDto } from './MailingModuleContentDto';
import {
    MailingModuleContentDtoFromJSON,
    MailingModuleContentDtoFromJSONTyped,
    MailingModuleContentDtoToJSON,
} from './MailingModuleContentDto';
import type { MailingSenderDto } from './MailingSenderDto';
import {
    MailingSenderDtoFromJSON,
    MailingSenderDtoFromJSONTyped,
    MailingSenderDtoToJSON,
} from './MailingSenderDto';
import type { MailingTemplateDataDto } from './MailingTemplateDataDto';
import {
    MailingTemplateDataDtoFromJSON,
    MailingTemplateDataDtoFromJSONTyped,
    MailingTemplateDataDtoToJSON,
} from './MailingTemplateDataDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * A mailing for the Berlinale festival.
 * @export
 * @interface MailingDto
 */
export interface MailingDto {
    /**
     * 
     * @type {string}
     * @memberof MailingDto
     */
    name: string;
    /**
     * 
     * @type {LandingPageReferenceDto}
     * @memberof MailingDto
     */
    landingPage?: LandingPageReferenceDto;
    /**
     * 
     * @type {MailingSenderDto}
     * @memberof MailingDto
     */
    sender: MailingSenderDto;
    /**
     * An email address
     * @type {string}
     * @memberof MailingDto
     */
    alternativeReplyAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof MailingDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof MailingDto
     */
    titleEnglish?: string;
    /**
     * Subject of the email
     * @type {string}
     * @memberof MailingDto
     */
    subject?: string;
    /**
     * Subject of the email in english
     * @type {string}
     * @memberof MailingDto
     */
    subjectEnglish?: string;
    /**
     * 
     * @type {Array<FileMetadataDto>}
     * @memberof MailingDto
     */
    attachments?: Array<FileMetadataDto>;
    /**
     * 
     * @type {string}
     * @memberof MailingDto
     */
    urgency: MailingDtoUrgencyEnum;
    /**
     * 
     * @type {Array<MailingModuleContentDto>}
     * @memberof MailingDto
     */
    modules: Array<MailingModuleContentDto>;
    /**
     * 
     * @type {string}
     * @memberof MailingDto
     */
    emailAddressSource: MailingDtoEmailAddressSourceEnum;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof MailingDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof MailingDto
     */
    version: VersionDto;
    /**
     * 
     * @type {MailingTemplateDataDto}
     * @memberof MailingDto
     */
    template: MailingTemplateDataDto;
    /**
     * 
     * @type {ConnectedEntityReferenceDto}
     * @memberof MailingDto
     */
    connectedEntity?: ConnectedEntityReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof MailingDto
     */
    status: MailingDtoStatusEnum;
}


/**
 * @export
 */
export const MailingDtoUrgencyEnum = {
    URGENT: 'URGENT',
    REGULAR: 'REGULAR'
} as const;
export type MailingDtoUrgencyEnum = typeof MailingDtoUrgencyEnum[keyof typeof MailingDtoUrgencyEnum];

/**
 * @export
 */
export const MailingDtoEmailAddressSourceEnum = {
    PROFESSIONAL_ACCOUNT: 'PROFESSIONAL_ACCOUNT',
    CONTACT: 'CONTACT'
} as const;
export type MailingDtoEmailAddressSourceEnum = typeof MailingDtoEmailAddressSourceEnum[keyof typeof MailingDtoEmailAddressSourceEnum];

/**
 * @export
 */
export const MailingDtoStatusEnum = {
    DRAFT: 'DRAFT',
    MAILING_QUEUE: 'MAILING_QUEUE',
    SENT: 'SENT'
} as const;
export type MailingDtoStatusEnum = typeof MailingDtoStatusEnum[keyof typeof MailingDtoStatusEnum];


/**
 * Check if a given object implements the MailingDto interface.
 */
export function instanceOfMailingDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('sender' in value)) return false;
    if (!('urgency' in value)) return false;
    if (!('modules' in value)) return false;
    if (!('emailAddressSource' in value)) return false;
    if (!('id' in value)) return false;
    if (!('version' in value)) return false;
    if (!('template' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function MailingDtoFromJSON(json: any): MailingDto {
    return MailingDtoFromJSONTyped(json, false);
}

export function MailingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'landingPage': json['landingPage'] == null ? undefined : LandingPageReferenceDtoFromJSON(json['landingPage']),
        'sender': MailingSenderDtoFromJSON(json['sender']),
        'alternativeReplyAddress': json['alternativeReplyAddress'] == null ? undefined : json['alternativeReplyAddress'],
        'title': json['title'] == null ? undefined : json['title'],
        'titleEnglish': json['titleEnglish'] == null ? undefined : json['titleEnglish'],
        'subject': json['subject'] == null ? undefined : json['subject'],
        'subjectEnglish': json['subjectEnglish'] == null ? undefined : json['subjectEnglish'],
        'attachments': json['attachments'] == null ? undefined : ((json['attachments'] as Array<any>).map(FileMetadataDtoFromJSON)),
        'urgency': json['urgency'],
        'modules': ((json['modules'] as Array<any>).map(MailingModuleContentDtoFromJSON)),
        'emailAddressSource': json['emailAddressSource'],
        'id': json['id'],
        'version': VersionDtoFromJSON(json['version']),
        'template': MailingTemplateDataDtoFromJSON(json['template']),
        'connectedEntity': json['connectedEntity'] == null ? undefined : ConnectedEntityReferenceDtoFromJSON(json['connectedEntity']),
        'status': json['status'],
    };
}

export function MailingDtoToJSON(value?: MailingDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'landingPage': LandingPageReferenceDtoToJSON(value['landingPage']),
        'sender': MailingSenderDtoToJSON(value['sender']),
        'alternativeReplyAddress': value['alternativeReplyAddress'],
        'title': value['title'],
        'titleEnglish': value['titleEnglish'],
        'subject': value['subject'],
        'subjectEnglish': value['subjectEnglish'],
        'attachments': value['attachments'] == null ? undefined : ((value['attachments'] as Array<any>).map(FileMetadataDtoToJSON)),
        'urgency': value['urgency'],
        'modules': ((value['modules'] as Array<any>).map(MailingModuleContentDtoToJSON)),
        'emailAddressSource': value['emailAddressSource'],
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'template': MailingTemplateDataDtoToJSON(value['template']),
        'connectedEntity': ConnectedEntityReferenceDtoToJSON(value['connectedEntity']),
        'status': value['status'],
    };
}

