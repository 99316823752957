import useSWR from 'swr';
import invariant from 'tiny-invariant';
import { PublishedKeyPlayersListSettingsDto } from '../../../api';
import { CustomRequestInit } from '../../../data-access/error-middleware';
import publicKeyPlayerApi from '../../../data-access/public-key-player-api';
import fallbackMiddleware from '../../../util/swr/fallback-middleware';

export function usePublicKeyPlayerListSettings(publicationId: string): PublishedKeyPlayersListSettingsDto {
  const { data } = useSWR(
    ['publicKeyPlayerListSettings', publicationId],
    () => {
      return publicKeyPlayerApi.fetchPublishedKeyPlayerListSettings({ publicationId }, {
        allowedErrorCodes: [403, 404],
      } as CustomRequestInit);
    },
    {
      suspense: true,
      use: [fallbackMiddleware],
    },
  );
  invariant(data != null, 'Missing key public players list settings');

  return data;
}
