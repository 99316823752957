import {
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ResetButtonProps extends Omit<ButtonProps, 'onClick'> {
  reset: () => void;
  isDisabled: boolean;
}
export function ResetButton({ reset, isDisabled, ...props }: ResetButtonProps) {
  const { t } = useTranslation('common');
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} isDisabled={isDisabled} {...props}>
        {t('action.reset')}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('action.reset')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{t('action.reset_text')}</ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              {t('action.abort')}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              {t('action.continue')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
