/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LaneTypeDto } from './LaneTypeDto';
import {
    LaneTypeDtoFromJSON,
    LaneTypeDtoFromJSONTyped,
    LaneTypeDtoToJSON,
} from './LaneTypeDto';
import type { RundownCalendarElementDto } from './RundownCalendarElementDto';
import {
    RundownCalendarElementDtoFromJSON,
    RundownCalendarElementDtoFromJSONTyped,
    RundownCalendarElementDtoToJSON,
} from './RundownCalendarElementDto';

/**
 * Lane with elements in a rundown calendar
 * @export
 * @interface RundownCalendarLaneDto
 */
export interface RundownCalendarLaneDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof RundownCalendarLaneDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RundownCalendarLaneDto
     */
    title: string;
    /**
     * 
     * @type {LaneTypeDto}
     * @memberof RundownCalendarLaneDto
     */
    laneType: LaneTypeDto;
    /**
     * 
     * @type {Array<RundownCalendarElementDto>}
     * @memberof RundownCalendarLaneDto
     */
    elements: Array<RundownCalendarElementDto>;
}

/**
 * Check if a given object implements the RundownCalendarLaneDto interface.
 */
export function instanceOfRundownCalendarLaneDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('title' in value)) return false;
    if (!('laneType' in value)) return false;
    if (!('elements' in value)) return false;
    return true;
}

export function RundownCalendarLaneDtoFromJSON(json: any): RundownCalendarLaneDto {
    return RundownCalendarLaneDtoFromJSONTyped(json, false);
}

export function RundownCalendarLaneDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RundownCalendarLaneDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'laneType': LaneTypeDtoFromJSON(json['laneType']),
        'elements': ((json['elements'] as Array<any>).map(RundownCalendarElementDtoFromJSON)),
    };
}

export function RundownCalendarLaneDtoToJSON(value?: RundownCalendarLaneDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
        'laneType': LaneTypeDtoToJSON(value['laneType']),
        'elements': ((value['elements'] as Array<any>).map(RundownCalendarElementDtoToJSON)),
    };
}

