/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PeriodicityTypeDto = {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    BIWEEKLY: 'BIWEEKLY',
    MONTHLY: 'MONTHLY',
    TWO_MONTH: 'TWO_MONTH',
    THREE_MONTH: 'THREE_MONTH',
    SIX_MONTH: 'SIX_MONTH',
    YEARLY: 'YEARLY',
    NOT_SPECIFIED: 'NOT_SPECIFIED'
} as const;
export type PeriodicityTypeDto = typeof PeriodicityTypeDto[keyof typeof PeriodicityTypeDto];


export function PeriodicityTypeDtoFromJSON(json: any): PeriodicityTypeDto {
    return PeriodicityTypeDtoFromJSONTyped(json, false);
}

export function PeriodicityTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PeriodicityTypeDto {
    return json as PeriodicityTypeDto;
}

export function PeriodicityTypeDtoToJSON(value?: PeriodicityTypeDto | null): any {
    return value as any;
}

