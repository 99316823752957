import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { ReactNode, useState } from 'react';
import DraggableItem from './draggable-item';
import SortableItem from './sortable-item';

interface SortableListProps<T> {
  id(item: T): UniqueIdentifier;
  items: T[];
  children(item: T, index: number): ReactNode;
  onMove(prevIndex: number, newIndex: number): void;
}

export default function SortableList<T>({ id, items, children, onMove }: SortableListProps<T>) {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const sortableItems = items.map((item, index) => ({ id: id(item), item, index }));
  const activeSortableItem = activeId != null ? sortableItems.find(({ id }) => id === activeId) : null;

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;

    setActiveId(active.id);
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over != null && active.id !== over.id) {
      const oldIndex = sortableItems.findIndex(({ id }) => id === active.id);
      const newIndex = sortableItems.findIndex(({ id }) => id === over.id);

      onMove(oldIndex, newIndex);
    }

    setActiveId(null);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={sortableItems} strategy={verticalListSortingStrategy}>
        {sortableItems.map(({ id, item, index }) => (
          <SortableItem key={id} id={id} index={index}>
            {children(item, index)}
          </SortableItem>
        ))}
      </SortableContext>
      <DragOverlay>
        {activeSortableItem != null && (
          <DraggableItem isDragging>{children(activeSortableItem.item, activeSortableItem.index)}</DraggableItem>
        )}
      </DragOverlay>
    </DndContext>
  );
}
