import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useGroupTemplatePermissionClassGroupExtension(): PermissionClassGroupExtension<'GROUP_TEMPLATE'> {
  const { t } = useTranslation(['group_template']);

  return {
    name: 'GROUP_TEMPLATE',
    label: t('group_template:groupTemplate'),
    getActionLabel: (action) => t(`group_template:permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`group_template:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`group_template:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
