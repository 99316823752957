import React from 'react';
import { useTranslation } from 'react-i18next';
import FinalDeleteConfirmDialog, {
  createDefaultDeleteChecklistItem,
} from '../../../ui/dialog/final-delete-confirm-dialog';

export interface AccountDeleteDialogProps {
  displayName: string;
  isOpen: boolean;

  onClose(confirm: boolean): void;
}

export default function AccountDeleteDialog({ onClose, displayName, ...props }: AccountDeleteDialogProps) {
  const { t } = useTranslation('account');

  const confirmationMessageChecklistItems = [
    createDefaultDeleteChecklistItem({
      objectTypeWithArticleGenitive: t('accountWithArticle'),
      objectToDelete: displayName,
    }),
  ];

  return (
    <FinalDeleteConfirmDialog
      {...props}
      objectTypeToDelete={t('account')}
      confirmationCheckListItems={confirmationMessageChecklistItems}
      onClose={onClose}
    />
  );
}
