import { countBy } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { MailingTemplateDto, MailingModuleSettingsDto } from '../../../../api';
import useCallbackRef from '../../../../util/use-callback-ref/use-callback-ref';

export default function useNumberOfMailingModules(
  getModules: (mailingTemplate: MailingTemplateDto) => MailingModuleSettingsDto[],
) {
  const { watch, getValues } = useFormContext<MailingTemplateDto>();
  const getNumberOfModules = useCallbackRef((mailingTemplate) =>
    countBy(getModules(mailingTemplate), (module) => module.moduleType),
  );
  const [numberOfModules, setNumberOfModules] = useState(() => getNumberOfModules(getValues()));

  useEffect(() => {
    const subscription = watch((values) => {
      setNumberOfModules(getNumberOfModules(values as MailingTemplateDto));
    });

    return () => subscription.unsubscribe();
  }, [getNumberOfModules, watch]);

  return numberOfModules;
}
