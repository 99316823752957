import { Button, Divider, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HistoryEntryDto } from '../../api';
import Dialog from '../dialog/dialog';
import HistoryDisplaySettings from '../history/history-display-settings';
import HistoryEntry from '../history/history-entry';

interface OptimisticLockingDialogProps<T extends FieldValues> {
  isOpen: boolean;
  onClose(confirm: boolean): void;

  historyDisplaySettings: HistoryDisplaySettings<T>;
  ownChanges: HistoryEntryDto;
  otherChanges: HistoryEntryDto[] | undefined;
  issues: Path<T>[];
  onMerge: () => void;
  onRevert: () => void;
}

export default function OptimisticLockingDialog<T extends FieldValues>({
  isOpen,
  onClose,
  historyDisplaySettings,
  ownChanges,
  otherChanges,
  issues,
  onRevert,
  onMerge,
}: OptimisticLockingDialogProps<T>) {
  const { t } = useTranslation('common');
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  return (
    <>
      <Dialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={() => onClose(false)}
        size="4xl"
        status="warning"
        title={t('misc.warning')}
        footer={
          <>
            <Button
              ref={cancelRef}
              onClick={async () => {
                onRevert();
                onClose(false);
              }}
              mr={2}
            >
              {t('optimistic_lock.revert_changes')}
            </Button>
            <Button
              onClick={async () => {
                onMerge();
                onClose(false);
              }}
            >
              {t('optimistic_lock.merge_changes')}
            </Button>
          </>
        }
      >
        <Stack spacing={6}>
          <Text>{t('optimistic_lock.explanation_text')}</Text>

          <HistoryEntry<T>
            {...historyDisplaySettings}
            entry={ownChanges}
            showLine={false}
            highlightAttributes={issues}
          />

          <Divider />
          {otherChanges?.map((entry) => (
            <HistoryEntry<T>
              key={entry.version.modifiedAt!.toISOString()}
              {...historyDisplaySettings}
              entry={entry}
              showLine={false}
              highlightAttributes={issues}
            />
          ))}
        </Stack>
      </Dialog>
    </>
  );
}
