/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyDto } from './CompanyDto';
import {
    CompanyDtoFromJSON,
    CompanyDtoFromJSONTyped,
    CompanyDtoToJSON,
} from './CompanyDto';

/**
 * 
 * @export
 * @interface CompanySearchResultDto
 */
export interface CompanySearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof CompanySearchResultDto
     */
    resultType: CompanySearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CompanySearchResultDto
     */
    score: number;
    /**
     * 
     * @type {CompanyDto}
     * @memberof CompanySearchResultDto
     */
    result: CompanyDto;
}


/**
 * @export
 */
export const CompanySearchResultDtoResultTypeEnum = {
    COMPANY: 'COMPANY'
} as const;
export type CompanySearchResultDtoResultTypeEnum = typeof CompanySearchResultDtoResultTypeEnum[keyof typeof CompanySearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the CompanySearchResultDto interface.
 */
export function instanceOfCompanySearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function CompanySearchResultDtoFromJSON(json: any): CompanySearchResultDto {
    return CompanySearchResultDtoFromJSONTyped(json, false);
}

export function CompanySearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': CompanyDtoFromJSON(json['result']),
    };
}

export function CompanySearchResultDtoToJSON(value?: CompanySearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': CompanyDtoToJSON(value['result']),
    };
}

