/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ColumnDto } from './ColumnDto';
import {
    ColumnDtoFromJSON,
    ColumnDtoFromJSONTyped,
    ColumnDtoToJSON,
} from './ColumnDto';
import type { PublishedEventSettingsDto } from './PublishedEventSettingsDto';
import {
    PublishedEventSettingsDtoFromJSON,
    PublishedEventSettingsDtoFromJSONTyped,
    PublishedEventSettingsDtoToJSON,
} from './PublishedEventSettingsDto';

/**
 * List of persons that are involved in preparing, carrying out and follow up actions of an event.
 * @export
 * @interface PublishedKeyPlayersListSettingsDto
 */
export interface PublishedKeyPlayersListSettingsDto {
    /**
     * 
     * @type {PublishedEventSettingsDto}
     * @memberof PublishedKeyPlayersListSettingsDto
     */
    eventSettings: PublishedEventSettingsDto;
    /**
     * 
     * @type {Array<ColumnDto>}
     * @memberof PublishedKeyPlayersListSettingsDto
     */
    columns: Array<ColumnDto>;
}

/**
 * Check if a given object implements the PublishedKeyPlayersListSettingsDto interface.
 */
export function instanceOfPublishedKeyPlayersListSettingsDto(value: object): boolean {
    if (!('eventSettings' in value)) return false;
    if (!('columns' in value)) return false;
    return true;
}

export function PublishedKeyPlayersListSettingsDtoFromJSON(json: any): PublishedKeyPlayersListSettingsDto {
    return PublishedKeyPlayersListSettingsDtoFromJSONTyped(json, false);
}

export function PublishedKeyPlayersListSettingsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublishedKeyPlayersListSettingsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'eventSettings': PublishedEventSettingsDtoFromJSON(json['eventSettings']),
        'columns': ((json['columns'] as Array<any>).map(ColumnDtoFromJSON)),
    };
}

export function PublishedKeyPlayersListSettingsDtoToJSON(value?: PublishedKeyPlayersListSettingsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'eventSettings': PublishedEventSettingsDtoToJSON(value['eventSettings']),
        'columns': ((value['columns'] as Array<any>).map(ColumnDtoToJSON)),
    };
}

