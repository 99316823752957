import React from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchOrderConfirmation } from '../order-confirmation-queries';

export default function OrderConfirmationBreadcrumb() {
  const { orderConfirmationId } = useParams<{ orderConfirmationId: string }>();
  invariant(orderConfirmationId, 'Empty orderConfirmationId');
  const orderConfirmation = useFetcher(fetchOrderConfirmation, { id: orderConfirmationId });

  return <>{orderConfirmation.orderConfirmationNumber}</>;
}
