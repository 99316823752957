import { FetchMailingTemplateHistoryRequest, HistoryEntryDto, MailingTemplateDto } from '../../api';
import mailingTemplateApi from '../../data-access/mailing-template-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';
import { ModuleSettingsWithOrder } from './mailing-template-history/use-mailing-template-history-settings';

export const mailingTemplateFetcher = createFetcherScope(mailingTemplateApi);
export const fetchMailingTemplate = mailingTemplateFetcher.createFetcher(mailingTemplateApi.fetchMailingTemplate);
export const searchMailingTemplates = mailingTemplateFetcher.createFetcher(mailingTemplateApi.searchMailingTemplates);
export const fetchMailingTemplateHistory = mailingTemplateFetcher.createFetcher(
  async (request: FetchMailingTemplateHistoryRequest) => {
    const mailingTemplateHistoryPage = await mailingTemplateApi.fetchMailingTemplateHistory(request);

    return {
      ...mailingTemplateHistoryPage,
      content: mailingTemplateHistoryPage.content.map((mailingTemplateHistoryEntry) => {
        const { after, before } = mailingTemplateHistoryEntry as Omit<HistoryEntryDto, 'before' | 'after'> & {
          before?: MailingTemplateDto;
          after?: MailingTemplateDto;
        };

        return {
          ...mailingTemplateHistoryEntry,
          before: addModuleSettingsOrder(before),
          after: addModuleSettingsOrder(after),
        };
      }),
    };
  },
);
export const fetchModuleConstraints = mailingTemplateFetcher.createFetcher(mailingTemplateApi.fetchModuleConstraints);

interface MailingTemplateWithOrder extends MailingTemplateDto {
  moduleSettings: ModuleSettingsWithOrder[];
}

function addModuleSettingsOrder(mailingTemplate: MailingTemplateDto | undefined): MailingTemplateWithOrder | undefined {
  if (mailingTemplate == null) {
    return undefined;
  }

  return {
    ...mailingTemplate,
    moduleSettings: mailingTemplate.moduleSettings.map((module, index) => ({
      ...module,
      // Add 1 to the index to make it 1-based
      order: index + 1,
    })),
  };
}
