import React from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import usePlugins from '../../../util/plugin/use-plugins';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchSection } from '../section-queries';
import { SECTION_RELATION_EXTENSION } from './section-relation-extension';

export default function SectionRelationViewer() {
  const { sectionId } = useParams<{ sectionId: string }>();
  invariant(sectionId, 'Empty sectionId');
  const section = useFetcher(fetchSection, { id: sectionId });

  const sectionRelationExtension = usePlugins(SECTION_RELATION_EXTENSION);

  return <>{sectionRelationExtension.at(0)?.pageContent(section)}</>;
}
