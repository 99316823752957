export default function timeFormatter(
  value: Date | number | string,
  lng?: string,
  options?: Intl.DateTimeFormatOptions,
) {
  if (!(value instanceof Date)) {
    value = new Date(value);
  }

  return new Intl.DateTimeFormat(lng, {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
    ...options,
  }).format(value);
}
