import { eventIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { EVENT_MENU_ITEM, EVENT_MOBILE_MENU_ITEM, EventMenuItem } from './event-page/event-page-menu';

export function createEventMenuItem(isMobile = false): EventMenuItem {
  return {
    order: 1,
    label: <Translate ns="event" i18nKey="event" />,
    icon: eventIcon,
    path: (eventId) => `${isMobile ? '/mobile' : ''}/events/${eventId}`,
  };
}

export default function EventProvider(registry: PluginRegistry) {
  registry.register(EVENT_MENU_ITEM, createEventMenuItem());
  registry.register(EVENT_MOBILE_MENU_ITEM, createEventMenuItem(true));
}
