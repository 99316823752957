import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useMailingDesignPermissionClassGroupExtension(): PermissionClassGroupExtension<'MAILING_DESIGN'> {
  const { t } = useTranslation(['mailing_design']);

  return {
    name: 'MAILING_DESIGN',
    label: t('mailing_design:mailing_design'),
    getActionLabel: (action) => t(`mailing_design:permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`mailing_design:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`mailing_design:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
