/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FormatDto } from './FormatDto';
import {
    FormatDtoFromJSON,
    FormatDtoFromJSONTyped,
    FormatDtoToJSON,
} from './FormatDto';

/**
 * 
 * @export
 * @interface FormatSearchResultDto
 */
export interface FormatSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof FormatSearchResultDto
     */
    resultType: FormatSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof FormatSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {FormatDto}
     * @memberof FormatSearchResultDto
     */
    result: FormatDto;
}


/**
 * @export
 */
export const FormatSearchResultDtoResultTypeEnum = {
    FORMAT: 'FORMAT'
} as const;
export type FormatSearchResultDtoResultTypeEnum = typeof FormatSearchResultDtoResultTypeEnum[keyof typeof FormatSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the FormatSearchResultDto interface.
 */
export function instanceOfFormatSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function FormatSearchResultDtoFromJSON(json: any): FormatSearchResultDto {
    return FormatSearchResultDtoFromJSONTyped(json, false);
}

export function FormatSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormatSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': FormatDtoFromJSON(json['result']),
    };
}

export function FormatSearchResultDtoToJSON(value?: FormatSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': FormatDtoToJSON(value['result']),
    };
}

