/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FunctionDto = {
    SENIOR_ASSISTANCE: 'SENIOR_ASSISTANCE',
    JUNIOR_ASSISTANCE: 'JUNIOR_ASSISTANCE',
    PRESS_COORDINATION: 'PRESS_COORDINATION',
    PROTOCOL_OFFICER: 'PROTOCOL_OFFICER',
    BACKUP: 'BACKUP',
    OTHERS: 'OTHERS'
} as const;
export type FunctionDto = typeof FunctionDto[keyof typeof FunctionDto];


export function FunctionDtoFromJSON(json: any): FunctionDto {
    return FunctionDtoFromJSONTyped(json, false);
}

export function FunctionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FunctionDto {
    return json as FunctionDto;
}

export function FunctionDtoToJSON(value?: FunctionDto | null): any {
    return value as any;
}

