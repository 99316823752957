import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { faLink, faLinkSimple } from '@fortawesome/pro-regular-svg-icons';
import React, { ForwardedRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseSelection, NodeEntry, Path, Point, Text, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import Translate from '../../../util/translate/translate';
import { getActiveBlock, isBlockActive } from '../block';
import { EditorProps, InlineFormatOption, LinkButtonElement, LinkElement } from '../format-types';
import { addInline, isInlineBlockActive, normalizeChildLength, removeInline, updateInline } from '../inline';
import { Render } from '../render';
import RichTextButton from '../rich-text-button';
import { WithFunction } from './create-editor';

const format: InlineFormatOption = 'link';

const withLink: (options: { render: Render<'inline'>['render'] }) => WithFunction =
  ({ render }) =>
  (editor) => {
    const renderLink: Render<'inline'> = {
      type: format,
      render,
    };
    editor.renderers = [...editor.renderers, renderLink];

    const linkToolbarButton = (editor: EditorProps) => <CreateLink key={format} editor={editor} format={format} />;
    editor.toolbarButtons = [...editor.toolbarButtons, linkToolbarButton];

    const { isInline } = editor;
    editor.isInline = (element) => format === element.type || isInline(element);

    normalizeChildLength(editor, 50, format);

    return editor;
  };

export default withLink;

export function CreateLink({ editor, format }: { editor: EditorProps; format: InlineFormatOption }) {
  const initialFocusRef = React.useRef(null);
  const activeLink = getActiveBlock(editor, format);
  const { selection } = editor;

  const moveSelectionToNextSibling = (activeLocation: Path) => {
    activeLocation[activeLocation.length - 1] += 1;
    Transforms.select(editor, activeLocation);
    ReactEditor.focus(editor);
  };

  return (
    <Popover initialFocusRef={initialFocusRef} isLazy>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <RichTextButton
              isActive={isInlineBlockActive(editor, format)}
              isDisabled={!isBlockActive(editor, 'paragraph') && editor.selection !== null}
              onClick={(event) => {
                // if cursor is at last index of link, the cursor should be moved to next sibling after clicking link-button in toolbar
                if (isCursorPointAtLastIndex(selection, activeLink) && activeLink?.[1] != null) {
                  moveSelectionToNextSibling(activeLink?.[1]);
                  event.preventDefault();
                }
              }}
              format={format}
              icon={format === 'link' ? faLink : faLinkSimple}
            />
          </PopoverTrigger>
          <Portal>
            <PopoverContent width="xl">
              <PopoverArrow />
              <LinkPopoverContent onClose={onClose} ref={initialFocusRef} format={format} activeLink={activeLink} />
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
}

const LinkPopoverContent = React.forwardRef(
  (
    {
      onClose,
      format,
      activeLink,
    }: { onClose: () => void; format: InlineFormatOption; activeLink: NodeEntry | undefined },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const editor = useSlate();
    const initialLink = (activeLink?.[0] as LinkElement | LinkButtonElement | undefined)?.url;
    const [link, setLink] = useState(initialLink != null ? initialLink : '');
    const { t } = useTranslation('common');

    return (
      <PopoverBody>
        <HStack>
          <FormControl>
            <HStack>
              <FormLabel size="sm" mb={0} mr={1}>
                {t('wysiwyg.link_label')}:
              </FormLabel>
              <Input ref={ref} size="sm" value={link} onChange={(newValue) => setLink(newValue.target.value)} />
            </HStack>
          </FormControl>
          <Button
            size="sm"
            onClick={(event) => {
              event.preventDefault();
              if (activeLink != null) {
                updateInline(editor, activeLink[1], link);
              } else {
                addInline(editor, format, link);
              }
              setLink('');
              ReactEditor.focus(editor);
              onClose();
            }}
          >
            <Translate ns="common" i18nKey="action.apply" />
          </Button>
          {isInlineBlockActive(editor, format) && (
            <Button
              size="sm"
              onClick={(event) => {
                event.preventDefault();
                removeInline(editor, format);
                ReactEditor.focus(editor);
                onClose();
              }}
            >
              <Translate ns="common" i18nKey="action.delete" />
            </Button>
          )}
        </HStack>
      </PopoverBody>
    );
  },
);

function isCursorPointAtLastIndex(selection: BaseSelection, activeLink: NodeEntry | undefined): boolean {
  const activeText = ((activeLink?.[0] as LinkElement | LinkButtonElement | undefined)?.children[0] as Text | undefined)
    ?.text;
  const isPointSelection =
    selection?.focus != null && selection.anchor != null && Point.equals(selection.focus, selection.anchor);
  const isCursorPointAtLastIndex =
    isPointSelection && activeText != null && activeText.length === selection.focus.offset;

  return isCursorPointAtLastIndex && activeLink?.[1] != null;
}
