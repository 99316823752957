import { uniqBy } from 'lodash-es';
import { useMemo } from 'react';
import DataTableState, { DataTableFilter, DataTableSort } from './data-table-state';
import { filtersToSearchParams, sortsToSearchParams } from './use-data-table-state';

export default function useRequestParams(
  state?: Partial<DataTableState>,
  fallbackSort: DataTableSort[] = [],
  forcedFilter: DataTableFilter[] = [],
) {
  // rationale: https://github.com/facebook/react/issues/14476#issuecomment-471199055
  const fallbackSortJSON = JSON.stringify(fallbackSort);
  const forcedFilterJSON = JSON.stringify(forcedFilter);

  return useMemo(() => {
    const { filter = [], sort = [], page, size } = state ?? {};
    const sortWithFallback = sortsToSearchParams(uniqBy([...sort, ...fallbackSort], (sort) => sort.property));

    return {
      pageable: {
        page,
        size,
        sort: sortWithFallback,
      },
      filter: filtersToSearchParams([...filter, ...forcedFilter]),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fallbackSortJSON, forcedFilterJSON, state]);
}
