import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  IconButton,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { faArrowToTop } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { endOfDay, startOfDay } from 'date-fns';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { OccupationDto } from '../../../../api';
import ElementContext from '../../../../ui/form/element-control/element-context';
import useDefinedContext from '../../../../util/context/use-defined-context/use-defined-context';
import now from '../../../../util/now';

interface MakeMainActivityButtonProps {
  label: string;
  setMainActivity: (occupation: OccupationDto) => void;
}

export default function MakeMainActivityButton({ label, setMainActivity }: MakeMainActivityButtonProps) {
  const { t } = useTranslation('person');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const closeButtonRef = React.useRef<HTMLButtonElement>(null);
  const { element: occupation } = useDefinedContext(ElementContext);

  const onConfirm = (occupation: OccupationDto) => {
    onClose();
    setMainActivity(occupation);
  };

  return (
    <>
      {canSetToMainActivity(occupation) && (
        <Tooltip label={label}>
          <IconButton
            onClick={onOpen}
            size="sm"
            variant="ghost"
            icon={<FontAwesomeIcon icon={faArrowToTop} />}
            aria-label={label}
          />
        </Tooltip>
      )}
      <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={closeButtonRef}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>{label}</AlertDialogHeader>
          <AlertDialogBody>
            <Trans
              t={t}
              i18nKey="occupations.mainActivity.action.makeMainActivity_message"
              values={{ jobTitle: occupation.jobTitle }}
            />
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose} mr={3} ref={closeButtonRef}>
              {t('occupations.mainActivity.action.abort')}
            </Button>
            <Button onClick={() => onConfirm(occupation)} colorScheme="orange">
              {t('action.confirm')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

export function canSetToMainActivity(occupation: OccupationDto): boolean {
  const today = new Date(now());
  return (
    !occupation.mainActivity &&
    startOfDay(occupation.dateRange.start) < today &&
    (occupation.dateRange.end == null || today < endOfDay(occupation.dateRange.end))
  );
}
