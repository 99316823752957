import { Tbody } from '@chakra-ui/react';
import { SystemStyleObject } from '@chakra-ui/styled-system';
import React from 'react';
import DataTableBodyRow from './data-table-body-row';
import DataTableColumn from './data-table-column';
import DataTablePage from './data-table-page';

export interface DataTableBodyProps<TData, TChildData = void> {
  page: DataTablePage<TData>;
  columns: DataTableColumn<TData, TChildData>[];
  rowKey(data: TData | TChildData, index: number): React.Key;
  childRows?(data: TData): TChildData[] | undefined;
  rowStyle?(data: TData | TChildData): SystemStyleObject;
}

function DataTableBody<TData, TChildData = void>({
  page,
  rowKey,
  childRows,
  rowStyle,
  columns,
}: DataTableBodyProps<TData, TChildData>) {
  const lastIndex = page.content.length - 1;

  return (
    <Tbody>
      {page.content.map((data, index) => (
        <DataTableBodyRow
          key={rowKey(data, index)}
          data={data}
          index={index}
          columns={columns}
          rowStyle={rowStyle}
          childRows={childRows}
          rowKey={rowKey}
          lastRow={lastIndex === index}
        />
      ))}
    </Tbody>
  );
}

export default React.memo(DataTableBody) as typeof DataTableBody;
