import { Box, Flex, Stack, Text, tokenToCSSVar, useTheme } from '@chakra-ui/react';
import { faLink, faMapMarkerAlt, faUserAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import {
  PersonLocationRelationDto,
  PersonLocationRelationDtoRelationTypeEnum,
  PersonReferenceDto,
} from '../../../../api';
import { DEFAULT_PERSON_KEY } from '../../../../data-access/person-api';
import { useElementForm } from '../../../../ui/form/element-control';
import ValueSelectControl from '../../../../ui/form/select-control/value-select-control';
import connectionArrow from '../../../common/connection-arrow-light.svg';
import LocationSelectControl from '../../../location/location-select-control/location-select-control';

const relationTypeOptions = [
  PersonLocationRelationDtoRelationTypeEnum.HOUSEKEEPING,
  PersonLocationRelationDtoRelationTypeEnum.IFB_CINEMA_MANAGEMENT,
  PersonLocationRelationDtoRelationTypeEnum.LOUNGE_COORDINATION,
  PersonLocationRelationDtoRelationTypeEnum.CINEMA_SUPPORT,
  PersonLocationRelationDtoRelationTypeEnum.CLEANING_SERVICE,
  PersonLocationRelationDtoRelationTypeEnum.TECHNICAL_MANAGEMENT,
  PersonLocationRelationDtoRelationTypeEnum.CINEMA_MANAGEMENT,
];

export interface PersonLocationConnectionControlProps {
  connectionEditable?: boolean;
  toReferenceEditable?: boolean;
  fromReference: PersonReferenceDto;
}

function ConnectionControl(
  { connectionEditable = true, toReferenceEditable = true, fromReference }: PersonLocationConnectionControlProps,
  ref: React.ForwardedRef<HTMLSelectElement>,
) {
  const { t } = useTranslation('person');
  const { element } = useElementForm<PersonLocationRelationDto>();
  const spacing = connectionEditable ? 2 : 3;
  const { register } = useFormContext<PersonLocationRelationDto>();
  const connectedLocation = element?.connectedLocation;
  const connectionType = element?.relationType;

  invariant(connectionEditable || connectionType != null, 'Missing connection type when readonly');

  // jaj, cv, lh: inside react-hook-form not registered fields are omitted from the submitted data
  register('connectedLocation');

  return (
    <Stack
      spacing={spacing}
      backgroundColor="background.highlight"
      pr={2}
      py={connectionEditable ? 2 : 3}
      pl={3}
      borderRadius="base"
    >
      <Flex position="relative" align="center">
        <ConnectionLine position="bottom" spacing={spacing} />
        <Box as={FontAwesomeIcon} icon={faUserAlt} fixedWidth flexShrink={0} />
        <Box ml={3} aria-label={t('relations.from_person')}>
          <Text as="span" fontWeight="medium" wordBreak="break-all">
            {fromReference.surname === '' ? 'Neue Person' : fromReference.firstName + ' ' + fromReference.surname}
          </Text>
          {fromReference.personKey !== DEFAULT_PERSON_KEY && <Text as="span">{` ${fromReference.personKey}`}</Text>}
        </Box>
      </Flex>
      <Flex align="center" position="relative">
        <ConnectionLine position="top" spacing={spacing} />
        <Box as={FontAwesomeIcon} icon={faLink} fixedWidth flexShrink={0} />
        <ConnectionLine position="bottom" spacing={spacing} />
        <Box width="full" ml={3}>
          {connectionEditable ? (
            <ValueSelectControl<PersonLocationRelationDtoRelationTypeEnum>
              aria-label={t('relations.relationType')}
              options={relationTypeOptions}
              renderLabel={(value) => t(`relations.relationTypeLabels.${value}`)}
              name="relationType"
              ref={ref}
              isRequired
            />
          ) : (
            <Box aria-label={t('relations.relationType')}>
              <Text as="span">{t(`relations.relationTypeLabels.${connectionType!}`)}</Text>
            </Box>
          )}
        </Box>
      </Flex>
      <Flex align="center" position="relative">
        <ConnectionLine position="top" showArrow spacing={spacing} />
        <Box as={FontAwesomeIcon} icon={faMapMarkerAlt} fixedWidth flexShrink={0} />
        <Box width="full" ml={3}>
          {toReferenceEditable ? (
            <LocationSelectControl name="connectedLocation" aria-label={t('relations.connected_location')} isRequired />
          ) : (
            <Box aria-label={t('relations.connected_location')}>
              <Text as="span" fontWeight="medium">
                {connectedLocation?.name}
              </Text>{' '}
            </Box>
          )}
        </Box>
      </Flex>
    </Stack>
  );
}

export default React.forwardRef(ConnectionControl);

interface ConnectionLineProps {
  position: 'bottom' | 'top';
  showArrow?: boolean;
  spacing?: number;
}

function ConnectionLine({ position, showArrow, spacing = 2 }: ConnectionLineProps) {
  const theme = useTheme();
  const spacingCssVar = tokenToCSSVar('space', 3)(theme);

  return (
    <Box
      w={5}
      position="absolute"
      left={0}
      bgImage={connectionArrow}
      bgRepeat="no-repeat"
      top={position === 'bottom' ? `calc(50% + ${spacingCssVar})` : spacing / -2}
      bottom={position === 'top' ? `calc(50% + ${spacingCssVar})` : spacing / -2}
      // vb, lh: Arrow is at the bottom of the background image …
      bgPosition={`center ${showArrow ? 'bottom' : 'top'}`}
    />
  );
}
