/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FunctionDto } from './FunctionDto';
import {
    FunctionDtoFromJSON,
    FunctionDtoFromJSONTyped,
    FunctionDtoToJSON,
} from './FunctionDto';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';
import type { StaffReferenceDto } from './StaffReferenceDto';
import {
    StaffReferenceDtoFromJSON,
    StaffReferenceDtoFromJSONTyped,
    StaffReferenceDtoToJSON,
} from './StaffReferenceDto';

/**
 * Staff that will be added to a group.
 * @export
 * @interface StaffToAddDto
 */
export interface StaffToAddDto {
    /**
     * 
     * @type {SectionReferenceDto}
     * @memberof StaffToAddDto
     */
    section: SectionReferenceDto;
    /**
     * 
     * @type {StaffReferenceDto}
     * @memberof StaffToAddDto
     */
    staff: StaffReferenceDto;
    /**
     * 
     * @type {FunctionDto}
     * @memberof StaffToAddDto
     */
    staffFunction: FunctionDto;
}

/**
 * Check if a given object implements the StaffToAddDto interface.
 */
export function instanceOfStaffToAddDto(value: object): boolean {
    if (!('section' in value)) return false;
    if (!('staff' in value)) return false;
    if (!('staffFunction' in value)) return false;
    return true;
}

export function StaffToAddDtoFromJSON(json: any): StaffToAddDto {
    return StaffToAddDtoFromJSONTyped(json, false);
}

export function StaffToAddDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffToAddDto {
    if (json == null) {
        return json;
    }
    return {
        
        'section': SectionReferenceDtoFromJSON(json['section']),
        'staff': StaffReferenceDtoFromJSON(json['staff']),
        'staffFunction': FunctionDtoFromJSON(json['staffFunction']),
    };
}

export function StaffToAddDtoToJSON(value?: StaffToAddDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'section': SectionReferenceDtoToJSON(value['section']),
        'staff': StaffReferenceDtoToJSON(value['staff']),
        'staffFunction': FunctionDtoToJSON(value['staffFunction']),
    };
}

