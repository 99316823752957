import React from 'react';
import { Descendant } from 'slate';
import { RenderElementProps } from 'slate-react';
import { BlockFormatOption, EditorProps, InlineFormatOption, MarkFormatOption } from './format-types';

export type Render<TYPE extends 'mark' | 'block' | 'inline' = 'mark' | 'block' | 'inline'> = {
  type: TYPE extends 'mark' ? MarkFormatOption : BlockFormatOption | InlineFormatOption;
  render: TYPE extends 'mark'
    ? (children: Descendant[]) => React.ReactElement
    : (props: RenderElementProps) => React.ReactElement;
};

export function getRenderer<TYPE extends 'mark' | 'block' | 'inline'>(
  type: TYPE extends 'mark' ? MarkFormatOption : BlockFormatOption | InlineFormatOption,
  editor: EditorProps,
) {
  return (editor.renderers.find((action) => action.type === type) as Render<TYPE>)?.render;
}

export function getMarkRenderers(types: MarkFormatOption[], editor: EditorProps) {
  return editor.renderers.filter((action: Render<'mark'>) => types.includes(action.type)).map((style) => style.render);
}
