import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useGroupPermissionClassGroupExtension(): PermissionClassGroupExtension<'GROUP'> {
  const { t } = useTranslation(['group']);

  return {
    name: 'GROUP',
    label: t('group:group'),
    getActionLabel: (action) => t(`group:permission.${action}`),
    getAdditionalRequirement: (action) => t(`group:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`group:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
