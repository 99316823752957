/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MailingPortraitModuleDataDto } from './MailingPortraitModuleDataDto';
import {
    MailingPortraitModuleDataDtoFromJSON,
    MailingPortraitModuleDataDtoFromJSONTyped,
    MailingPortraitModuleDataDtoToJSON,
} from './MailingPortraitModuleDataDto';
import type { ModuleTypeDto } from './ModuleTypeDto';
import {
    ModuleTypeDtoFromJSON,
    ModuleTypeDtoFromJSONTyped,
    ModuleTypeDtoToJSON,
} from './ModuleTypeDto';

/**
 * 
 * @export
 * @interface MailingPortraitModuleContentDto
 */
export interface MailingPortraitModuleContentDto {
    /**
     * 
     * @type {ModuleTypeDto}
     * @memberof MailingPortraitModuleContentDto
     */
    moduleType: ModuleTypeDto;
    /**
     * 
     * @type {Date}
     * @memberof MailingPortraitModuleContentDto
     */
    timestamp?: Date;
    /**
     * 
     * @type {Array<MailingPortraitModuleDataDto>}
     * @memberof MailingPortraitModuleContentDto
     */
    portraits?: Array<MailingPortraitModuleDataDto>;
}

/**
 * Check if a given object implements the MailingPortraitModuleContentDto interface.
 */
export function instanceOfMailingPortraitModuleContentDto(value: object): boolean {
    if (!('moduleType' in value)) return false;
    return true;
}

export function MailingPortraitModuleContentDtoFromJSON(json: any): MailingPortraitModuleContentDto {
    return MailingPortraitModuleContentDtoFromJSONTyped(json, false);
}

export function MailingPortraitModuleContentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingPortraitModuleContentDto {
    if (json == null) {
        return json;
    }
    return {
        
        'moduleType': ModuleTypeDtoFromJSON(json['moduleType']),
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
        'portraits': json['portraits'] == null ? undefined : ((json['portraits'] as Array<any>).map(MailingPortraitModuleDataDtoFromJSON)),
    };
}

export function MailingPortraitModuleContentDtoToJSON(value?: MailingPortraitModuleContentDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'moduleType': ModuleTypeDtoToJSON(value['moduleType']),
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
        'portraits': value['portraits'] == null ? undefined : ((value['portraits'] as Array<any>).map(MailingPortraitModuleDataDtoToJSON)),
    };
}

