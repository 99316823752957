/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Reference of a Person
 * @export
 * @interface PersonReferenceDto
 */
export interface PersonReferenceDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof PersonReferenceDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PersonReferenceDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonReferenceDto
     */
    surname?: string;
    /**
     * The unique key for a person starting with "P".
     * @type {string}
     * @memberof PersonReferenceDto
     */
    personKey?: string;
    /**
     * List of contact email addresses (confidential and non-confidential)
     * @type {Array<string>}
     * @memberof PersonReferenceDto
     */
    emailAddresses?: Array<string>;
}

/**
 * Check if a given object implements the PersonReferenceDto interface.
 */
export function instanceOfPersonReferenceDto(value: object): boolean {
    if (!('id' in value)) return false;
    return true;
}

export function PersonReferenceDtoFromJSON(json: any): PersonReferenceDto {
    return PersonReferenceDtoFromJSONTyped(json, false);
}

export function PersonReferenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonReferenceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'surname': json['surname'] == null ? undefined : json['surname'],
        'personKey': json['personKey'] == null ? undefined : json['personKey'],
        'emailAddresses': json['emailAddresses'] == null ? undefined : json['emailAddresses'],
    };
}

export function PersonReferenceDtoToJSON(value?: PersonReferenceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'firstName': value['firstName'],
        'surname': value['surname'],
        'personKey': value['personKey'],
        'emailAddresses': value['emailAddresses'],
    };
}

