import { ReactNode } from 'react';
import { MailingTemplateDataDto, MailingModuleConstraintDto, MailingModuleSettingsDto, ModuleTypeDto } from '../../api';
import { PluginToken } from '../../util/plugin/use-plugins';

export default interface MailingModuleTypeExtension {
  type: ModuleTypeDto;

  moduleControl(props: {
    template: MailingTemplateDataDto;
    module: MailingModuleSettingsDto;
    name: string;
    constraints: { [key in ModuleTypeDto]?: MailingModuleConstraintDto };
    connectionId?: string;
  }): ReactNode;
}
export const MAILING_MODULE_TYPE_EXTENSION = new PluginToken<MailingModuleTypeExtension>('MailingModuleTypeExtension');
