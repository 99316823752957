import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import LandingPageDesignBreadcrumb from './common/landing-page-design-breadcrumb';

const landingPageDesignRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'landing-page-designs',
    handle: {
      breadcrumb: <Translate ns="landing_page_design" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./landing-page-design-lister/landing-page-design-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./landing-page-design-editor/new-landing-page-design-route'),
      },
      {
        path: ':designId',
        handle: {
          breadcrumb: <LandingPageDesignBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./landing-page-design-page/landing-page-design-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./landing-page-design-viewer/landing-page-design-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./landing-page-design-history/landing-page-design-history-route'),
              },
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./landing-page-design-editor/edit-landing-page-design-route'),
          },
        ],
      },
    ],
  },
];

export default landingPageDesignRoutes;
