import React from 'react';
import { useTranslation } from 'react-i18next';
import { GroupCompanyRelationDto } from '../../../../../api';
import FinalDeleteConfirmDialog, {
  createDefaultDeleteChecklistItem,
} from '../../../../../ui/dialog/final-delete-confirm-dialog';

export interface GroupCompanyDeleteDialogProps {
  relation: GroupCompanyRelationDto;
  isOpen: boolean;
  onClose(confirm: boolean): void;
}

export default function GroupCompanyDeleteDialog({ onClose, relation, ...props }: GroupCompanyDeleteDialogProps) {
  const { t } = useTranslation('group');

  const confirmationMessageChecklistItems = [
    createDefaultDeleteChecklistItem({
      objectTypeWithArticleGenitive: t('connections.group_company_with_article_genetive', {
        name: relation.company.name,
      }),
    }),
  ];

  return (
    <FinalDeleteConfirmDialog
      {...props}
      objectTypeToDelete={t('connections.company')}
      confirmationCheckListItems={confirmationMessageChecklistItems}
      onClose={onClose}
    />
  );
}
