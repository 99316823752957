import { CheckIcon, InfoIcon, WarningIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
} from '@chakra-ui/react';
import React from 'react';

export type DialogStatus = 'info' | 'warning' | 'success' | 'error';

const DIALOG_STATUSES = {
  info: { icon: InfoIcon, color: 'text.info' },
  warning: { icon: WarningIcon, color: 'text.warning' },
  error: { icon: WarningIcon, color: 'text.error' },
  success: { icon: CheckIcon, color: 'text.success' },
};

export interface DialogProps extends AlertDialogProps {
  status?: DialogStatus;
  title?: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
  closable?: boolean;
}

export default function Dialog({ title, children, footer, status, closable = true, ...props }: DialogProps) {
  return (
    <AlertDialog {...props}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          {closable && <AlertDialogCloseButton />}
          {title != null && (
            <AlertDialogHeader display="flex" alignItems="center" color="heading">
              {status != null &&
                React.createElement(DIALOG_STATUSES[status].icon, {
                  mr: 3,
                  color: DIALOG_STATUSES[status].color,
                })}
              {title}
            </AlertDialogHeader>
          )}
          <AlertDialogBody>{children}</AlertDialogBody>
          {footer != null && <AlertDialogFooter>{footer}</AlertDialogFooter>}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
