/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BlocklistEntryDto } from './BlocklistEntryDto';
import {
    BlocklistEntryDtoFromJSON,
    BlocklistEntryDtoFromJSONTyped,
    BlocklistEntryDtoToJSON,
} from './BlocklistEntryDto';

/**
 * 
 * @export
 * @interface BlocklistSearchResultDto
 */
export interface BlocklistSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof BlocklistSearchResultDto
     */
    resultType: BlocklistSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof BlocklistSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {BlocklistEntryDto}
     * @memberof BlocklistSearchResultDto
     */
    result: BlocklistEntryDto;
}


/**
 * @export
 */
export const BlocklistSearchResultDtoResultTypeEnum = {
    BLOCKLIST: 'BLOCKLIST'
} as const;
export type BlocklistSearchResultDtoResultTypeEnum = typeof BlocklistSearchResultDtoResultTypeEnum[keyof typeof BlocklistSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the BlocklistSearchResultDto interface.
 */
export function instanceOfBlocklistSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function BlocklistSearchResultDtoFromJSON(json: any): BlocklistSearchResultDto {
    return BlocklistSearchResultDtoFromJSONTyped(json, false);
}

export function BlocklistSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlocklistSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': BlocklistEntryDtoFromJSON(json['result']),
    };
}

export function BlocklistSearchResultDtoToJSON(value?: BlocklistSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': BlocklistEntryDtoToJSON(value['result']),
    };
}

