import { ExpandedIndex } from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router-dom';

// lh: Stores the current accordion state (indices of expanded accordion items) initialized by search params.
export default function useAccordionState(names: { name: string }[]) {
  const { hash } = useLocation();
  const [expandedIndices, setExpandedIndices] = React.useState<ExpandedIndex>(() => {
    const expandedIndex = names.findIndex((item) => item.name === hash.substring(1));

    if (expandedIndex === -1) {
      return [];
    }

    return [expandedIndex];
  });

  return [expandedIndices, setExpandedIndices] as const;
}
