/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  HistoryPageDto,
  PageableDto,
  ProductBundleDto,
  ProductBundlePageDto,
  ProductBundleReferenceDto,
  ProductBundleReferencePageDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    ProductBundleDtoFromJSON,
    ProductBundleDtoToJSON,
    ProductBundlePageDtoFromJSON,
    ProductBundlePageDtoToJSON,
    ProductBundleReferenceDtoFromJSON,
    ProductBundleReferenceDtoToJSON,
    ProductBundleReferencePageDtoFromJSON,
    ProductBundleReferencePageDtoToJSON,
} from '../models/index';

export interface ActivateProductBundleRequest {
    id: string;
}

export interface ArchiveProductBundleRequest {
    id: string;
}

export interface CanProductBundleBeArchivedRequest {
    id: string;
}

export interface CreateProductBundleRequest {
    productBundleDto: ProductBundleDto;
}

export interface FetchProductBundleRequest {
    id: string;
}

export interface FetchProductBundleHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FindByTitleRequest {
    isTitleEnglish: boolean;
    title: string;
    editionId: string;
}

export interface SearchProductBundleReferencesRequest {
    q?: string;
    pageable?: PageableDto;
}

export interface SearchProductBundlesRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface UpdateProductBundleRequest {
    id: string;
    productBundleDto: ProductBundleDto;
}

/**
 * 
 */
export class ProductBundleApi extends runtime.BaseAPI {

    /**
     * Activate the product bundle
     */
    async activateProductBundleRaw(requestParameters: ActivateProductBundleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling activateProductBundle().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/product-bundles/{id}/activate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Activate the product bundle
     */
    async activateProductBundle(requestParameters: ActivateProductBundleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.activateProductBundleRaw(requestParameters, initOverrides);
    }

    /**
     * Archive the product bundle
     */
    async archiveProductBundleRaw(requestParameters: ArchiveProductBundleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling archiveProductBundle().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/product-bundles/{id}/archive`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archive the product bundle
     */
    async archiveProductBundle(requestParameters: ArchiveProductBundleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.archiveProductBundleRaw(requestParameters, initOverrides);
    }

    /**
     * Get whether the product bundle can be archived.
     */
    async canProductBundleBeArchivedRaw(requestParameters: CanProductBundleBeArchivedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling canProductBundleBeArchived().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/product-bundles/{id}/can-be-archived`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get whether the product bundle can be archived.
     */
    async canProductBundleBeArchived(requestParameters: CanProductBundleBeArchivedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.canProductBundleBeArchivedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new product bundle.
     */
    async createProductBundleRaw(requestParameters: CreateProductBundleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductBundleDto>> {
        if (requestParameters['productBundleDto'] == null) {
            throw new runtime.RequiredError(
                'productBundleDto',
                'Required parameter "productBundleDto" was null or undefined when calling createProductBundle().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/product-bundles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProductBundleDtoToJSON(requestParameters['productBundleDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductBundleDtoFromJSON(jsonValue));
    }

    /**
     * Create a new product bundle.
     */
    async createProductBundle(requestParameters: CreateProductBundleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductBundleDto> {
        const response = await this.createProductBundleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the product bundle business object.
     */
    async fetchProductBundleRaw(requestParameters: FetchProductBundleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductBundleDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchProductBundle().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/product-bundles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductBundleDtoFromJSON(jsonValue));
    }

    /**
     * Get the product bundle business object.
     */
    async fetchProductBundle(requestParameters: FetchProductBundleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductBundleDto> {
        const response = await this.fetchProductBundleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the product bundle.
     */
    async fetchProductBundleHistoryRaw(requestParameters: FetchProductBundleHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchProductBundleHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/product-bundles/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the product bundle.
     */
    async fetchProductBundleHistory(requestParameters: FetchProductBundleHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchProductBundleHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the product bundle reference, if not unique within the given edition
     */
    async findByTitleRaw(requestParameters: FindByTitleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductBundleReferenceDto>> {
        if (requestParameters['isTitleEnglish'] == null) {
            throw new runtime.RequiredError(
                'isTitleEnglish',
                'Required parameter "isTitleEnglish" was null or undefined when calling findByTitle().'
            );
        }

        if (requestParameters['title'] == null) {
            throw new runtime.RequiredError(
                'title',
                'Required parameter "title" was null or undefined when calling findByTitle().'
            );
        }

        if (requestParameters['editionId'] == null) {
            throw new runtime.RequiredError(
                'editionId',
                'Required parameter "editionId" was null or undefined when calling findByTitle().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['isTitleEnglish'] != null) {
            queryParameters['isTitleEnglish'] = requestParameters['isTitleEnglish'];
        }

        if (requestParameters['title'] != null) {
            queryParameters['title'] = requestParameters['title'];
        }

        if (requestParameters['editionId'] != null) {
            queryParameters['editionId'] = requestParameters['editionId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/product-bundles/find-by-title`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductBundleReferenceDtoFromJSON(jsonValue));
    }

    /**
     * Get the product bundle reference, if not unique within the given edition
     */
    async findByTitle(requestParameters: FindByTitleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductBundleReferenceDto> {
        const response = await this.findByTitleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for product bundles references.
     */
    async searchProductBundleReferencesRaw(requestParameters: SearchProductBundleReferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductBundleReferencePageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/product-bundle-references`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductBundleReferencePageDtoFromJSON(jsonValue));
    }

    /**
     * Search for product bundles references.
     */
    async searchProductBundleReferences(requestParameters: SearchProductBundleReferencesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductBundleReferencePageDto> {
        const response = await this.searchProductBundleReferencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for product bundles.
     */
    async searchProductBundlesRaw(requestParameters: SearchProductBundlesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductBundlePageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/product-bundles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductBundlePageDtoFromJSON(jsonValue));
    }

    /**
     * Search for product bundles.
     */
    async searchProductBundles(requestParameters: SearchProductBundlesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductBundlePageDto> {
        const response = await this.searchProductBundlesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new product bundle.
     */
    async templateProductBundleRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductBundleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/product-bundles/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductBundleDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new product bundle.
     */
    async templateProductBundle(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductBundleDto> {
        const response = await this.templateProductBundleRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the existing product bundle.
     */
    async updateProductBundleRaw(requestParameters: UpdateProductBundleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductBundleDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateProductBundle().'
            );
        }

        if (requestParameters['productBundleDto'] == null) {
            throw new runtime.RequiredError(
                'productBundleDto',
                'Required parameter "productBundleDto" was null or undefined when calling updateProductBundle().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/product-bundles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProductBundleDtoToJSON(requestParameters['productBundleDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductBundleDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing product bundle.
     */
    async updateProductBundle(requestParameters: UpdateProductBundleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductBundleDto> {
        const response = await this.updateProductBundleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
