/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { RelationTypeDto } from './RelationTypeDto';
import {
    RelationTypeDtoFromJSON,
    RelationTypeDtoFromJSONTyped,
    RelationTypeDtoToJSON,
} from './RelationTypeDto';

/**
 * Person that will be added to a group.
 * @export
 * @interface PersonToAddDto
 */
export interface PersonToAddDto {
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof PersonToAddDto
     */
    person: PersonReferenceDto;
    /**
     * 
     * @type {RelationTypeDto}
     * @memberof PersonToAddDto
     */
    relationType: RelationTypeDto;
}

/**
 * Check if a given object implements the PersonToAddDto interface.
 */
export function instanceOfPersonToAddDto(value: object): boolean {
    if (!('person' in value)) return false;
    if (!('relationType' in value)) return false;
    return true;
}

export function PersonToAddDtoFromJSON(json: any): PersonToAddDto {
    return PersonToAddDtoFromJSONTyped(json, false);
}

export function PersonToAddDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonToAddDto {
    if (json == null) {
        return json;
    }
    return {
        
        'person': PersonReferenceDtoFromJSON(json['person']),
        'relationType': RelationTypeDtoFromJSON(json['relationType']),
    };
}

export function PersonToAddDtoToJSON(value?: PersonToAddDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'person': PersonReferenceDtoToJSON(value['person']),
        'relationType': RelationTypeDtoToJSON(value['relationType']),
    };
}

