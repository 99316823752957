/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AwardDto } from './AwardDto';
import {
    AwardDtoFromJSON,
    AwardDtoFromJSONTyped,
    AwardDtoToJSON,
} from './AwardDto';

/**
 * 
 * @export
 * @interface AwardSearchResultDto
 */
export interface AwardSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof AwardSearchResultDto
     */
    resultType: AwardSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AwardSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {AwardDto}
     * @memberof AwardSearchResultDto
     */
    result: AwardDto;
}


/**
 * @export
 */
export const AwardSearchResultDtoResultTypeEnum = {
    AWARD: 'AWARD'
} as const;
export type AwardSearchResultDtoResultTypeEnum = typeof AwardSearchResultDtoResultTypeEnum[keyof typeof AwardSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the AwardSearchResultDto interface.
 */
export function instanceOfAwardSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function AwardSearchResultDtoFromJSON(json: any): AwardSearchResultDto {
    return AwardSearchResultDtoFromJSONTyped(json, false);
}

export function AwardSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AwardSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': AwardDtoFromJSON(json['result']),
    };
}

export function AwardSearchResultDtoToJSON(value?: AwardSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': AwardDtoToJSON(value['result']),
    };
}

