import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
} from '@chakra-ui/react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { GroupCompanyRelationDto, GroupDto } from '../../../../../api';
import groupCompanyApi from '../../../../../data-access/group-company-api';
import Form from '../../../../../ui/form/form';
import SubmitButton from '../../../../../ui/form/submit-button';
import useToast from '../../../../../ui/use-toast/use-toast';
import { groupCompanyFetcher } from '../group-company-queries';
import GroupCompanyEditorForm from './group-company-editor-form';

interface GroupCompanyEditorDialogProps {
  isOpen: boolean;
  onClose: () => void;
  group: GroupDto;
}

export default function GroupCompanyEditorDialog({ isOpen, onClose, group }: GroupCompanyEditorDialogProps) {
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} initialFocusRef={initialFocusRef}>
      <ModalOverlay />
      <ModalContent>
        <GroupCompanyModal onClose={onClose} group={group} />
      </ModalContent>
    </Modal>
  );
}

interface GroupCompanyModalProps {
  onClose: () => void;
  group: GroupDto;
}

function GroupCompanyModal({ onClose, group }: GroupCompanyModalProps) {
  const { t } = useTranslation(['relations', 'common', 'group']);
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  const defaultRelation = {
    id: uuid(),
    group: group,
    company: undefined,
  };

  const form = useForm<GroupCompanyRelationDto>({
    mode: 'all',
    defaultValues: { ...defaultRelation },
  });

  const showSuccessToast = useToast({
    id: 'group-company-editor-success-toast',
    status: 'success',
  });

  const onSave = async (relationDto: GroupCompanyRelationDto) => {
    const saved = await groupCompanyApi.createGroupCompanyRelation({ groupCompanyRelationDto: relationDto });

    showSuccessToast({
      title: t('relations:toast.add_success'),
      description: t('relations:toast.relation_description', { name: `${saved.company.name}` }),
    });

    if (saved.id != null) {
      await groupCompanyFetcher.mutate();
    }

    onClose();
  };

  const formIsDirty = Object.keys(form.formState.dirtyFields).length > 0;

  return (
    <FormProvider {...form}>
      <Form<GroupCompanyRelationDto> onValid={onSave} initialFocusRef={initialFocusRef}>
        <ModalHeader>{t('group:action.connect_company')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <GroupCompanyEditorForm group={group} initialFocusRef={initialFocusRef} />
        </ModalBody>
        <ModalFooter>
          <Flex width="100%">
            <Spacer />
            <Button mr={3} onClick={onClose}>
              {t('common:action.abort')}
            </Button>
            <SubmitButton variant="primary" isDisabled={!formIsDirty}>
              {t('common:action.add')}
            </SubmitButton>
          </Flex>
        </ModalFooter>
      </Form>
    </FormProvider>
  );
}
