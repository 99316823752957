import { chakra, HTMLChakraProps } from '@chakra-ui/react';

interface PreProps extends HTMLChakraProps<'pre'> {}

export default function Pre({ ...props }: PreProps) {
  return (
    <chakra.pre
      __css={{
        fontFamily: 'mono',
        fontSize: 'sm',
        color: 'text',
        bg: 'blackAlpha.200',
        px: 3,
        py: 2,
        borderRadius: 'base',
        whiteSpace: 'pre-wrap',
        _dark: {
          bg: 'whiteAlpha.200',
        },
      }}
      {...props}
    />
  );
}
