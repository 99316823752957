import React from 'react';
import { participationIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import PERSON_CHILD_ROUTES from '../person/person-child-routes';
import { MERGE_CONFLICT_PROVIDER_EXTENSION } from '../person/person-merger/merge-conflict-provider-extension';
import { PERSON_TAB_ITEM, PersonTabItem } from '../person/person-page/person-page';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { useParticipationPermissionClassGroupExtension } from './participation-permissions';
import ParticipationSearchResult from './participation-search-result';
import { getMergeConflictMessages } from './person-extension/participation-merge-conflict-provider';
import personParticipationRoutes from './person-participation-routes';

export const participationPersonTabItem: PersonTabItem = {
  order: 2,
  label: <Translate ns="participation" i18nKey="lister.title" />,
  icon: participationIcon,
  to: './participations',
};
export default function ParticipationPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, {
    type: 'PARTICIPATION',
    Component: ParticipationSearchResult,
  });
  registry.register(SEARCH_FILTER, {
    key: 'PARTICIPATION',
    icon: participationIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.PARTICIPATION" />,
    necessaryPermission: 'PARTICIPATION:CAN_SEE_DETAILS',
  });
  registry.register(PERSON_TAB_ITEM, participationPersonTabItem);
  registry.register(PERSON_CHILD_ROUTES, personParticipationRoutes);
  registry.register(MERGE_CONFLICT_PROVIDER_EXTENSION, getMergeConflictMessages);
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useParticipationPermissionClassGroupExtension);
}
