import useSWR from 'swr';
import invariant from 'tiny-invariant';
import locationApi from '../../../data-access/location-api';

export function getLocationCardCacheKey(locationId: string) {
  return ['locationCard', locationId];
}

export default function useLocationCard(locationId: string) {
  const { data } = useSWR(getLocationCardCacheKey(locationId), () => locationApi.fetchLocationCard({ locationId }), {
    suspense: true,
  });
  invariant(data != null);
  return data;
}
