import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckmarkBooleanViewer from './checkmark-boolean-viewer';

export interface BooleanViewerProps {
  renderLabel?: (value: boolean) => React.ReactNode;
  value: boolean | undefined;
}

export default function BooleanViewer({ renderLabel, value = false }: BooleanViewerProps) {
  const { t } = useTranslation('common');

  return (
    <CheckmarkBooleanViewer<boolean>
      renderLabel={renderLabel == null ? (val) => t(`boolean_viewer.${val}`) : renderLabel}
      truthiness={(val) => val}
      value={value}
    />
  );
}
