import React from 'react';
import { Middleware } from 'swr';

// lh: Fallback middleware extends SWR hook to return data from previous fetcher if possible.
// Useful for things like data tables, e.g. to stop flickering when changing the data table state.
const fallbackMiddleware: Middleware = (useSWRNext) => {
  return (key, fetcher, config) => {
    const fallbackDataRef = React.useRef<any>();
    const swr = useSWRNext(key, fetcher, {
      ...config,
      // lh: Only suspense data fetching the first time when there is no fallback data yet.
      suspense: config.suspense && fallbackDataRef.current == null,
    });

    React.useEffect(() => {
      if (swr.data != null) {
        fallbackDataRef.current = swr.data;
      }
    }, [swr.data]);

    return { ...swr, data: swr.data ?? fallbackDataRef.current };
  };
};

export default fallbackMiddleware;
