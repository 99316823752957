import { Badge, HStack, StackItem } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditionDto, EditionReferenceDto } from '../../../api';
import editionApi from '../../../data-access/edition-api';
import ValueAsyncSelect, { ValueAsyncSelectProps } from '../../../ui/select/value-async-select';

export interface EditionSelectProps
  extends Omit<
    ValueAsyncSelectProps<EditionDto | EditionReferenceDto>,
    'renderLabel' | 'optionIdentifier' | 'loadOptions' | 'defaultOptions'
  > {
  isRequired?: boolean;
  filter?: string[];
  loadOptions?: (inputValue: string) => Promise<EditionDto[]>;
}

const EditionSelect = React.forwardRef(
  (
    {
      value,
      isRequired,
      filter = [],
      loadOptions = async (inputValue: string) => {
        inputValue = inputValue.trim();
        let additionalFilters: string[] = [];
        if (inputValue.length > 0) {
          additionalFilters = [`name,contain,${inputValue}`];
        }
        const page = await editionApi.searchEditions({
          filter: [...filter, ...additionalFilters],
          pageable: { sort: ['dateRange.start,DESC'] },
        });
        return page.content;
      },
      ...props
    }: EditionSelectProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <ValueAsyncSelect
        isRequired={isRequired}
        ref={ref}
        value={value}
        renderLabel={(edition) => <EditionRenderLabel edition={edition} />}
        optionIdentifier={({ id }) => id}
        defaultOptions
        loadOptions={loadOptions}
        {...props}
      />
    );
  },
);

export default EditionSelect;

export function EditionRenderLabel({ edition }: { edition: EditionDto | EditionReferenceDto }) {
  const { t } = useTranslation('edition');

  return (
    <HStack spacing={2} width="full" overflow="hidden">
      <StackItem noOfLines={1} flexShrink={1}>
        {edition.name}
      </StackItem>
      {(edition as EditionDto).active && (
        <Badge colorScheme="teal" variant="solid">
          {t('active')}
        </Badge>
      )}
    </HStack>
  );
}
