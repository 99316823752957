import { uniqueId } from 'lodash-es';
import { BareFetcher, Key, mutate, MutatorOptions, preload, SWRConfiguration } from 'swr';

export default interface Fetcher<TArgs extends Key = Key, TData = unknown, TError = unknown> {
  key: (args: TArgs) => Key[];
  fetcher: (args: TArgs) => Promise<TData>;
  config?: Omit<SWRConfiguration<TData, TError, BareFetcher<TData>>, 'suspense'>;

  mutate(args: TArgs, data?: Promise<TData> | TData, options?: MutatorOptions<TData>): Promise<TData | undefined>;

  preload(args: TArgs): Promise<TData | undefined>;
}

export function createFetcher<TArgs extends Key, TData = unknown, TError = unknown>(
  fetcher: (args: TArgs) => Promise<TData>,
  config?: Omit<SWRConfiguration<TData, TError, BareFetcher<TData>>, 'suspense'>,
): Fetcher<TArgs, TData, TError> {
  const id = uniqueId(`${fetcher.name || 'fetcher'}-`);

  return {
    key(args) {
      return [id, args];
    },
    fetcher,
    config,

    mutate(args, data, options) {
      return mutate(this.key(args), data ?? this.fetcher(args), options);
    },

    preload(args) {
      return preload(this.key(args), () => this.fetcher(args));
    },
  };
}
