import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useCodePermissionClassGroupExtension(): PermissionClassGroupExtension<'CODE'> {
  const { t } = useTranslation(['code']);

  return {
    name: 'CODE',
    label: t('code:code'),
    getActionLabel: (action) => t(`code:permission.${action}`),
    getAdditionalRequirement: (action) => t(`code:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`code:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
