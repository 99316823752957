/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyPermissionDto
 */
export interface CompanyPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyPermissionDto
     */
    name: CompanyPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanyPermissionDto
     */
    action: CompanyPermissionDtoActionEnum;
}


/**
 * @export
 */
export const CompanyPermissionDtoNameEnum = {
    COMPANY: 'COMPANY'
} as const;
export type CompanyPermissionDtoNameEnum = typeof CompanyPermissionDtoNameEnum[keyof typeof CompanyPermissionDtoNameEnum];

/**
 * @export
 */
export const CompanyPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    EDIT: 'CAN_EDIT',
    CREATE: 'CAN_CREATE',
    APPROVE: 'CAN_APPROVE',
    MERGE: 'CAN_MERGE',
    EDIT_EXTERNAL_COMPANY_ADMIN: 'CAN_EDIT_EXTERNAL_COMPANY_ADMIN',
    IMPORT: 'CAN_IMPORT',
    DELETE: 'CAN_DELETE',
    ADD_COMMENT: 'CAN_ADD_COMMENT',
    EDIT_COMMENT: 'CAN_EDIT_COMMENT',
    DELETE_COMMENT: 'CAN_DELETE_COMMENT',
    EDIT_COMMENT_OWN: 'CAN_EDIT_COMMENT_OWN',
    DELETE_COMMENT_OWN: 'CAN_DELETE_COMMENT_OWN',
    LINK_COMPANY_WITH_COMPANY: 'CAN_LINK_COMPANY_WITH_COMPANY',
    LINK_COMPANY_WITH_PERSON: 'CAN_LINK_COMPANY_WITH_PERSON',
    LINK_COMPANY_WITH_FILM: 'CAN_LINK_COMPANY_WITH_FILM'
} as const;
export type CompanyPermissionDtoActionEnum = typeof CompanyPermissionDtoActionEnum[keyof typeof CompanyPermissionDtoActionEnum];


/**
 * Check if a given object implements the CompanyPermissionDto interface.
 */
export function instanceOfCompanyPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function CompanyPermissionDtoFromJSON(json: any): CompanyPermissionDto {
    return CompanyPermissionDtoFromJSONTyped(json, false);
}

export function CompanyPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function CompanyPermissionDtoToJSON(value?: CompanyPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

