/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LocationReferenceDto } from './LocationReferenceDto';
import {
    LocationReferenceDtoFromJSON,
    LocationReferenceDtoFromJSONTyped,
    LocationReferenceDtoToJSON,
} from './LocationReferenceDto';

/**
 * 
 * @export
 * @interface AccessGroupLocationsInnerDto
 */
export interface AccessGroupLocationsInnerDto {
    /**
     * 
     * @type {LocationReferenceDto}
     * @memberof AccessGroupLocationsInnerDto
     */
    location: LocationReferenceDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessGroupLocationsInnerDto
     */
    zones?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof AccessGroupLocationsInnerDto
     */
    timestamp: Date;
}

/**
 * Check if a given object implements the AccessGroupLocationsInnerDto interface.
 */
export function instanceOfAccessGroupLocationsInnerDto(value: object): boolean {
    if (!('location' in value)) return false;
    if (!('timestamp' in value)) return false;
    return true;
}

export function AccessGroupLocationsInnerDtoFromJSON(json: any): AccessGroupLocationsInnerDto {
    return AccessGroupLocationsInnerDtoFromJSONTyped(json, false);
}

export function AccessGroupLocationsInnerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessGroupLocationsInnerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'location': LocationReferenceDtoFromJSON(json['location']),
        'zones': json['zones'] == null ? undefined : json['zones'],
        'timestamp': (new Date(json['timestamp'])),
    };
}

export function AccessGroupLocationsInnerDtoToJSON(value?: AccessGroupLocationsInnerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'location': LocationReferenceDtoToJSON(value['location']),
        'zones': value['zones'],
        'timestamp': ((value['timestamp']).toISOString()),
    };
}

