/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccessGroupDto } from './AccessGroupDto';
import {
    AccessGroupDtoFromJSON,
    AccessGroupDtoFromJSONTyped,
    AccessGroupDtoToJSON,
} from './AccessGroupDto';

/**
 * 
 * @export
 * @interface AccessGroupSearchResultDto
 */
export interface AccessGroupSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof AccessGroupSearchResultDto
     */
    resultType: AccessGroupSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AccessGroupSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {AccessGroupDto}
     * @memberof AccessGroupSearchResultDto
     */
    result: AccessGroupDto;
}


/**
 * @export
 */
export const AccessGroupSearchResultDtoResultTypeEnum = {
    ACCESS_GROUP: 'ACCESS_GROUP'
} as const;
export type AccessGroupSearchResultDtoResultTypeEnum = typeof AccessGroupSearchResultDtoResultTypeEnum[keyof typeof AccessGroupSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the AccessGroupSearchResultDto interface.
 */
export function instanceOfAccessGroupSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function AccessGroupSearchResultDtoFromJSON(json: any): AccessGroupSearchResultDto {
    return AccessGroupSearchResultDtoFromJSONTyped(json, false);
}

export function AccessGroupSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessGroupSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': AccessGroupDtoFromJSON(json['result']),
    };
}

export function AccessGroupSearchResultDtoToJSON(value?: AccessGroupSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': AccessGroupDtoToJSON(value['result']),
    };
}

