/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StaffAccountReferenceDto } from './StaffAccountReferenceDto';
import {
    StaffAccountReferenceDtoFromJSON,
    StaffAccountReferenceDtoFromJSONTyped,
    StaffAccountReferenceDtoToJSON,
} from './StaffAccountReferenceDto';

/**
 * General version information.
 * @export
 * @interface VersionDto
 */
export interface VersionDto {
    /**
     * The internal revision identifier of the current version.
     * @type {string}
     * @memberof VersionDto
     */
    revision?: string;
    /**
     * 
     * @type {Date}
     * @memberof VersionDto
     */
    modifiedAt?: Date;
    /**
     * 
     * @type {StaffAccountReferenceDto}
     * @memberof VersionDto
     */
    modifiedBy?: StaffAccountReferenceDto;
}

/**
 * Check if a given object implements the VersionDto interface.
 */
export function instanceOfVersionDto(value: object): boolean {
    return true;
}

export function VersionDtoFromJSON(json: any): VersionDto {
    return VersionDtoFromJSONTyped(json, false);
}

export function VersionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VersionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'revision': json['revision'] == null ? undefined : json['revision'],
        'modifiedAt': json['modifiedAt'] == null ? undefined : (new Date(json['modifiedAt'])),
        'modifiedBy': json['modifiedBy'] == null ? undefined : StaffAccountReferenceDtoFromJSON(json['modifiedBy']),
    };
}

export function VersionDtoToJSON(value?: VersionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'revision': value['revision'],
        'modifiedAt': value['modifiedAt'] == null ? undefined : ((value['modifiedAt']).toISOString()),
        'modifiedBy': StaffAccountReferenceDtoToJSON(value['modifiedBy']),
    };
}

