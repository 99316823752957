import { Alert, AlertIcon, AlertTitle, Badge, chakra, ListItem, UnorderedList, useId } from '@chakra-ui/react';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { EditionImplicationDto, EditionImplicationDtoEditionOrderEnum } from '../../../api';
import EditionImplicationType from '../edition-implication/edition-implication-type';
import EditionImplicationIntro from './edition-implication-intro';
import EditionImplicationWillChange from './edition-implication-will-change';

export interface EditionImplicationWarningProps {
  implications: EditionImplicationDto[];
  type: EditionImplicationType;
  id?: string;
}

export default function EditionImplicationWarning({ implications, type, id: idProp }: EditionImplicationWarningProps) {
  const { t } = useTranslation(['common', 'edition']);
  const id = useId(idProp, 'edition-implication-warning');

  implications = useMemo(
    () =>
      implications.filter(
        (implication) =>
          (type === EditionImplicationType.DATE_RANGE && implication.dateRange != null) ||
          (type === EditionImplicationType.NAME && implication.name != null),
      ),
    [implications, type],
  );

  if (implications.length === 0) {
    return null;
  }

  return (
    <Alert status="warning" aria-labelledby={`${id}-title`}>
      <AlertIcon />
      <chakra.div flex="1">
        <AlertTitle id={`${id}-title`}>{t('common:misc.warning')}</AlertTitle>
        <chakra.div mt={1}>
          <EditionImplicationIntro
            affectedEditions={implications.map((implication) => implication.affected)}
            type={type}
          />
        </chakra.div>
        <UnorderedList mt={1} listStyleType="'—'" marginInlineStart="0.8em" spacing={1}>
          {implications.map((implication, index) => (
            <ListItem pl={2} key={index}>
              <chakra.strong display="block">
                <Trans
                  t={t}
                  i18nKey={`edition:editor.implication.edition.${getImplicationHeadingKeySuffix(implication)}`}
                  values={{
                    edition: implication.affected.name,
                  }}
                  components={{ badge: <Badge verticalAlign="text-bottom" /> }}
                />
              </chakra.strong>{' '}
              <span>
                <EditionImplicationWillChange type={type} implication={implication} />
              </span>
            </ListItem>
          ))}
        </UnorderedList>
      </chakra.div>
    </Alert>
  );
}

function getImplicationHeadingKeySuffix(implication: EditionImplicationDto) {
  if (implication.editionOrder === EditionImplicationDtoEditionOrderEnum.PREVIOUS && implication.affected.active) {
    return `${implication.editionOrder}.active` as const;
  }

  return `${implication.editionOrder}.inactive` as const;
}
