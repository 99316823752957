import { HStack, Text } from '@chakra-ui/react';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isArray, isEmpty, isString } from 'lodash-es';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { HistoryEntryDto } from '../../api';
import { DataTableColumn } from '../data-table';
import DataTable from '../data-table/data-table';
import StringDiff from '../diff/string-diff';
import Pre from '../pre/pre';
import { ChangeDto } from './change-dto';
import { ElementType } from './element-types';
import HistoryDisplaySettings, {
  HistoryStatusEnum,
  SubElementHistoryDisplaySettings,
} from './history-display-settings';
import SubElementEntry from './sub-element-entry';
import useSizeEqualizer from './use-size-equalizer';

interface HistoryEntryDataTableProps extends React.HTMLAttributes<HTMLElement> {
  entry: HistoryEntryDto;
  attributeLabels: HistoryDisplaySettings['attributeLabels'];
  statusFormatter?: HistoryDisplaySettings['statusFormatter'];
  highlightAttributes?: HistoryDisplaySettings['highlightAttributes'];
  diffEntireWord?: HistoryDisplaySettings['diffEntireWord'];
  preformatted?: HistoryDisplaySettings['preformatted'];
  hideTableForCreatedAndDeleted?: HistoryDisplaySettings['hideTableForCreatedAndDeleted'];
  rows: ChangeDto<any>[];
}

// lh: This component is in dire need of refactoring. Best practice here probably: add tests with
// each bug and with sufficient test coverage, clean up this component and its logic.
export default function HistoryEntryDiffDataTable({
  entry,
  statusFormatter,
  attributeLabels,
  highlightAttributes,
  diffEntireWord,
  preformatted,
  hideTableForCreatedAndDeleted,
  rows,
  ...props
}: HistoryEntryDataTableProps) {
  const { t } = useTranslation('common');

  function attributeLabel(
    entry: any,
    labelOrFunc: React.ReactNode | ((parent: any) => React.ReactNode),
  ): React.ReactNode {
    if (labelOrFunc?.constructor === Function) {
      const func = labelOrFunc as (x: any) => React.ReactNode;
      return func(entry);
    } else {
      return labelOrFunc as React.ReactNode;
    }
  }

  const diffTableColumns: DataTableColumn<ChangeDto<unknown>>[] = React.useMemo(
    () => [
      {
        key: 'attribute',
        name: t('history.field'),
        renderCell: (change) => (
          <HStack>
            {highlightAttributes?.includes(change.attribute) && (
              <FontAwesomeIcon icon={faExclamationTriangle} color="orange" />
            )}
            <Text as="span" fontWeight="medium">
              {attributeLabel(null, attributeLabels[change.attribute])}
            </Text>
          </HStack>
        ),
      },
      {
        key: 'before',
        name: t('history.before'),
        renderCell: (change) => {
          if (change.before == null || change.before === '') {
            return <Text color="text.muted">{t('history.added')}</Text>;
          }

          if (isArray(change.after) && isArray(change.before)) {
            return (
              <SubElementEntry
                subElementsArray={change.before}
                subElementsArrayRef={change.after}
                type={ElementType.BEFORE}
                settings={change.subElementSettings}
              />
            );
          } else {
            invariant(isString(change.after));
            const element = (
              <StringDiff to={change.after} diffEntireWord={diffEntireWord?.[change.attribute]}>
                {change.before as string}
              </StringDiff>
            );

            if (preformatted?.[change.attribute]) {
              return <Pre width="full">{element}</Pre>;
            }

            return element;
          }
        },
        cellProps: {
          w: '50%',
          h: '1px', //lh, vb: Is needed to set div height inside if a table cell to 100%.
        },
        bodyCellProps: {
          verticalAlign: 'top',
        },
      },
      {
        key: 'after',
        name: t('history.after'),
        renderCell: (change) => {
          if (change.after == null || change.after === '') {
            return <Text color="text.muted">{t('history.removed')}</Text>;
          }
          if (isArray(change.before) && isArray(change.after)) {
            return (
              <SubElementEntry
                subElementsArray={change.after}
                subElementsArrayRef={change.before}
                type={ElementType.AFTER}
                settings={change.subElementSettings}
              />
            );
          } else {
            invariant(isString(change.before));
            const element = (
              <StringDiff from={change.before} diffEntireWord={diffEntireWord?.[change.attribute]}>
                {change.after as string}
              </StringDiff>
            );

            if (preformatted?.[change.attribute]) {
              return <Pre> width="full"{element}</Pre>;
            }

            return element;
          }
        },
        cellProps: {
          w: '50%',
          h: '1px', //lh, vb: Is needed to set div height inside if a table cell to 100%.
        },
        bodyCellProps: {
          verticalAlign: 'top',
        },
      },
    ],
    [attributeLabels, diffEntireWord, highlightAttributes, preformatted, t],
  );

  const status = statusFormatter?.(entry.after);

  const sizeEqualizerContainerRef = useRef<HTMLDivElement>(null);
  useSizeEqualizer(sizeEqualizerContainerRef);

  if (
    status === HistoryStatusEnum.ANONYMISED ||
    status === HistoryStatusEnum.DELETED ||
    (isEmpty(entry.before) && isEmpty(entry.after))
  ) {
    return null;
  }

  if (hideTableForCreatedAndDeleted && (isEmpty(entry.before) || isEmpty(entry.after))) {
    return null;
  }

  if (rows.length === 0) {
    return null;
  }

  return (
    <div ref={sizeEqualizerContainerRef}>
      <DataTable
        page={{ content: rows }}
        columns={diffTableColumns}
        rowKey={(data, index) => index}
        size="sm"
        empty={<></>}
        {...props}
      />
    </div>
  );
}

/*
vb: There can be ignored attributes defined in history settings for subelements. Those attributes
will be removed from each subelement using destructuring and will not be part of history diff.
Example: 'position' attribute in mailing text modules.

Notice: 'historyElement' is a sorted array. Do not change order of elements!
*/
export function removeIgnoredAttributes(
  historyElement: any,
  listSettings:
    | ((value: any) => HistoryDisplaySettings<any> | SubElementHistoryDisplaySettings<any> | undefined)
    | undefined,
) {
  const newList: any[] = [];

  if (Array.isArray(historyElement) && listSettings != null) {
    historyElement.forEach((element) => {
      if (element == null || isEmpty(element)) {
        newList.push(element);
      } else {
        let restOfElement = element;

        listSettings(restOfElement)?.ignoreAttributes?.forEach((att) => {
          const { [att]: _, ...rest } = restOfElement;
          restOfElement = rest;
        });

        if (!isEmpty(restOfElement)) {
          newList.push(restOfElement);
        } else {
          newList.push(null);
        }
      }
    });
    return newList;
  } else {
    return historyElement;
  }
}
