/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StatusGroupStatisticsDto } from './StatusGroupStatisticsDto';
import {
    StatusGroupStatisticsDtoFromJSON,
    StatusGroupStatisticsDtoFromJSONTyped,
    StatusGroupStatisticsDtoToJSON,
} from './StatusGroupStatisticsDto';

/**
 * 
 * @export
 * @interface GuestListStatisticsDto
 */
export interface GuestListStatisticsDto {
    /**
     * 
     * @type {StatusGroupStatisticsDto}
     * @memberof GuestListStatisticsDto
     */
    guestListStatistic: StatusGroupStatisticsDto;
    /**
     * 
     * @type {Array<StatusGroupStatisticsDto>}
     * @memberof GuestListStatisticsDto
     */
    allocationStatistics: Array<StatusGroupStatisticsDto>;
}

/**
 * Check if a given object implements the GuestListStatisticsDto interface.
 */
export function instanceOfGuestListStatisticsDto(value: object): boolean {
    if (!('guestListStatistic' in value)) return false;
    if (!('allocationStatistics' in value)) return false;
    return true;
}

export function GuestListStatisticsDtoFromJSON(json: any): GuestListStatisticsDto {
    return GuestListStatisticsDtoFromJSONTyped(json, false);
}

export function GuestListStatisticsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuestListStatisticsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'guestListStatistic': StatusGroupStatisticsDtoFromJSON(json['guestListStatistic']),
        'allocationStatistics': ((json['allocationStatistics'] as Array<any>).map(StatusGroupStatisticsDtoFromJSON)),
    };
}

export function GuestListStatisticsDtoToJSON(value?: GuestListStatisticsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'guestListStatistic': StatusGroupStatisticsDtoToJSON(value['guestListStatistic']),
        'allocationStatistics': ((value['allocationStatistics'] as Array<any>).map(StatusGroupStatisticsDtoToJSON)),
    };
}

