/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditionDto } from './EditionDto';
import {
    EditionDtoFromJSON,
    EditionDtoFromJSONTyped,
    EditionDtoToJSON,
} from './EditionDto';
import type { ImplicationVersionDto } from './ImplicationVersionDto';
import {
    ImplicationVersionDtoFromJSON,
    ImplicationVersionDtoFromJSONTyped,
    ImplicationVersionDtoToJSON,
} from './ImplicationVersionDto';

/**
 * Edition including version information of the used implications. Fails if the implicitly checked editions changed in the database
 * @export
 * @interface UpsertEditionDto
 */
export interface UpsertEditionDto {
    /**
     * 
     * @type {EditionDto}
     * @memberof UpsertEditionDto
     */
    edition: EditionDto;
    /**
     * 
     * @type {Array<ImplicationVersionDto>}
     * @memberof UpsertEditionDto
     */
    implications: Array<ImplicationVersionDto>;
}

/**
 * Check if a given object implements the UpsertEditionDto interface.
 */
export function instanceOfUpsertEditionDto(value: object): boolean {
    if (!('edition' in value)) return false;
    if (!('implications' in value)) return false;
    return true;
}

export function UpsertEditionDtoFromJSON(json: any): UpsertEditionDto {
    return UpsertEditionDtoFromJSONTyped(json, false);
}

export function UpsertEditionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpsertEditionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'edition': EditionDtoFromJSON(json['edition']),
        'implications': ((json['implications'] as Array<any>).map(ImplicationVersionDtoFromJSON)),
    };
}

export function UpsertEditionDtoToJSON(value?: UpsertEditionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'edition': EditionDtoToJSON(value['edition']),
        'implications': ((value['implications'] as Array<any>).map(ImplicationVersionDtoToJSON)),
    };
}

