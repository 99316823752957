import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
} from '@chakra-ui/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AccessGroupDto } from '../../../api';

interface AccessGroupStatusToggleDialogProps extends Pick<AlertDialogProps, 'isOpen' | 'onClose'> {
  accessGroup: AccessGroupDto;
  shouldActivate: boolean;

  onToggle(): void;
}

export default function AccessGroupStatusToggleDialog({
  accessGroup,
  shouldActivate,
  onToggle,
  onClose,
  ...props
}: AccessGroupStatusToggleDialogProps) {
  const closeButtonRef = React.useRef<HTMLButtonElement>(null);
  const { t } = useTranslation(['access_group', 'common']);

  return (
    <AlertDialog {...props} onClose={onClose} leastDestructiveRef={closeButtonRef}>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>
          {shouldActivate ? t('access_group:action.activate') : t('access_group:action.deactivate')}
        </AlertDialogHeader>
        <AlertDialogBody>
          <Trans
            t={t}
            i18nKey={
              shouldActivate
                ? 'access_group:activation.status.activate_message'
                : 'access_group:activation.status.deactivate_message'
            }
            values={{
              name: accessGroup.title,
            }}
          />
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button onClick={onClose} mr={3} ref={closeButtonRef}>
            {t('common:action.abort')}
          </Button>
          <Button
            onClick={onToggle}
            variant={shouldActivate ? 'primary' : 'solid'}
            colorScheme={shouldActivate ? 'teal' : 'red'}
          >
            {shouldActivate ? t('access_group:action.activate') : t('access_group:action.deactivate')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
