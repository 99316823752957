import { useFormControlContext } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface FormControlContextConsumerProps {
  children(context: ReturnType<typeof useFormControlContext>): ReactNode;
}

export default function FormControlContextConsumer({ children }: FormControlContextConsumerProps) {
  return children(useFormControlContext());
}
