import useSWR from 'swr';
import invariant from 'tiny-invariant';
import { EventDto, FetchError } from '../../../api';
import eventApi from '../../../data-access/event-api';

export function getEventCacheKey(eventId: string) {
  return ['event', eventId];
}

export default function useEvent(eventId: string, suspense?: true): EventDto;
export default function useEvent(eventId: string, suspense: false): EventDto | undefined;
export default function useEvent(eventId: string, suspense = true) {
  const { data } = useSWR(
    getEventCacheKey(eventId),
    () =>
      eventApi.fetchEvent({ id: eventId }).catch((reason) => {
        if (!(reason instanceof FetchError)) {
          throw reason;
        }
      }),
    {
      suspense,
    },
  );
  invariant(!suspense || data != null, 'Suspension failed');

  return data;
}
