import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyReferenceDtoStatusEnum, GroupCompanyRelationDto } from '../../../../../api';
import HistoryDisplaySettings from '../../../../../ui/history/history-display-settings';

export default function useGroupCompanyHistorySettings(): HistoryDisplaySettings<GroupCompanyRelationDto> {
  const { t } = useTranslation(['relations', 'group', 'common']);

  return useMemo<HistoryDisplaySettings<GroupCompanyRelationDto>>(() => {
    return {
      attributeLabels: {
        company: t('group:history.company'),
      },
      valueFormatter: {
        company: (value) =>
          value == null
            ? undefined
            : value.status === CompanyReferenceDtoStatusEnum.ARCHIVED
              ? `${value.name} ${t('common:history.business_object_deleted')}`
              : value.name,
      },
      diffEntireWord: {
        company: true,
      },
      name: 'company.name',
      showEditDescription: true,
    };
  }, [t]);
}
