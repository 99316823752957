/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePlacementCommandDto
 */
export interface CreatePlacementCommandDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof CreatePlacementCommandDto
     */
    locationId: string;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof CreatePlacementCommandDto
     */
    placementTemplateId: string;
}

/**
 * Check if a given object implements the CreatePlacementCommandDto interface.
 */
export function instanceOfCreatePlacementCommandDto(value: object): boolean {
    if (!('locationId' in value)) return false;
    if (!('placementTemplateId' in value)) return false;
    return true;
}

export function CreatePlacementCommandDtoFromJSON(json: any): CreatePlacementCommandDto {
    return CreatePlacementCommandDtoFromJSONTyped(json, false);
}

export function CreatePlacementCommandDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePlacementCommandDto {
    if (json == null) {
        return json;
    }
    return {
        
        'locationId': json['locationId'],
        'placementTemplateId': json['placementTemplateId'],
    };
}

export function CreatePlacementCommandDtoToJSON(value?: CreatePlacementCommandDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'locationId': value['locationId'],
        'placementTemplateId': value['placementTemplateId'],
    };
}

