import Input from './input';

const Textarea = {
  variants: {
    outline: {
      ...Input.variants.outline.field,
    },
  },
};

export default Textarea;
