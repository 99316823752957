import React from 'react';
import { useTranslation } from 'react-i18next';
import { GuestListListItemDto } from '../../../api';
import PersonReference from '../../person/person-reference/person-reference';

interface GuestNameControlProps {
  personOnList: GuestListListItemDto;
  isInline?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

export function GuestNameControl({
  personOnList: { guestType, number, person },
  isInline = false,
  size,
}: GuestNameControlProps) {
  const { t } = useTranslation('guest_list');

  if (person != null) {
    return (
      <PersonReference
        size={size}
        hidePersonKey
        flipName
        personReference={person}
        isTruncated
        usePortalForCard
        isInline={isInline}
      />
    );
  }
  return <>{`${guestType === 'BODYGUARD' ? t('bodyguardGuest') : t('guestTypeOptions.DUMMY')} ${number}`}</>;
}
