/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LocationReferenceDto } from './LocationReferenceDto';
import {
    LocationReferenceDtoFromJSON,
    LocationReferenceDtoFromJSONTyped,
    LocationReferenceDtoToJSON,
} from './LocationReferenceDto';

/**
 * 
 * @export
 * @interface LaneElementLocationsInnerDto
 */
export interface LaneElementLocationsInnerDto {
    /**
     * 
     * @type {LocationReferenceDto}
     * @memberof LaneElementLocationsInnerDto
     */
    location: LocationReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof LaneElementLocationsInnerDto
     */
    zone?: string;
    /**
     * 
     * @type {string}
     * @memberof LaneElementLocationsInnerDto
     */
    subZone?: string;
    /**
     * 
     * @type {Date}
     * @memberof LaneElementLocationsInnerDto
     */
    timestamp?: Date;
}

/**
 * Check if a given object implements the LaneElementLocationsInnerDto interface.
 */
export function instanceOfLaneElementLocationsInnerDto(value: object): boolean {
    if (!('location' in value)) return false;
    return true;
}

export function LaneElementLocationsInnerDtoFromJSON(json: any): LaneElementLocationsInnerDto {
    return LaneElementLocationsInnerDtoFromJSONTyped(json, false);
}

export function LaneElementLocationsInnerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaneElementLocationsInnerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'location': LocationReferenceDtoFromJSON(json['location']),
        'zone': json['zone'] == null ? undefined : json['zone'],
        'subZone': json['subZone'] == null ? undefined : json['subZone'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
    };
}

export function LaneElementLocationsInnerDtoToJSON(value?: LaneElementLocationsInnerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'location': LocationReferenceDtoToJSON(value['location']),
        'zone': value['zone'],
        'subZone': value['subZone'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
    };
}

