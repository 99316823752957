/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EmailOnBlocklistDto } from './EmailOnBlocklistDto';
import {
    instanceOfEmailOnBlocklistDto,
    EmailOnBlocklistDtoFromJSON,
    EmailOnBlocklistDtoFromJSONTyped,
    EmailOnBlocklistDtoToJSON,
} from './EmailOnBlocklistDto';

/**
 * @type MailingSendingInformationDto
 * 
 * @export
 */
export type MailingSendingInformationDto = { type: 'EMAIL_ON_BLOCKLIST' } & EmailOnBlocklistDto;

export function MailingSendingInformationDtoFromJSON(json: any): MailingSendingInformationDto {
    return MailingSendingInformationDtoFromJSONTyped(json, false);
}

export function MailingSendingInformationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingSendingInformationDto {
    if (json == null) {
        return json;
    }
    switch (json['type']) {
        case 'EMAIL_ON_BLOCKLIST':
            return {...EmailOnBlocklistDtoFromJSONTyped(json, true), type: 'EMAIL_ON_BLOCKLIST'};
        default:
            throw new Error(`No variant of MailingSendingInformationDto exists with 'type=${json['type']}'`);
    }
}

export function MailingSendingInformationDtoToJSON(value?: MailingSendingInformationDto | null): any {
    if (value == null) {
        return value;
    }
    switch (value['type']) {
        case 'EMAIL_ON_BLOCKLIST':
            return EmailOnBlocklistDtoToJSON(value);
        default:
            throw new Error(`No variant of MailingSendingInformationDto exists with 'type=${value['type']}'`);
    }

}

