import { useSearchParams } from 'react-router-dom';

export default function useRedirectTo() {
  const [searchParams] = useSearchParams();
  const redirectParam = searchParams.get('redirectTo');
  return redirectParam ? decodeURIComponent(redirectParam) : undefined;
}

export function createRedirectToParam(redirectTo: string): string {
  const searchParams = new URLSearchParams();
  searchParams.set('redirectTo', redirectTo);

  return searchParams.toString();
}
