/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CodeEditorContentDto } from './CodeEditorContentDto';
import {
    CodeEditorContentDtoFromJSON,
    CodeEditorContentDtoFromJSONTyped,
    CodeEditorContentDtoToJSON,
} from './CodeEditorContentDto';
import type { ModuleTypeDto } from './ModuleTypeDto';
import {
    ModuleTypeDtoFromJSON,
    ModuleTypeDtoFromJSONTyped,
    ModuleTypeDtoToJSON,
} from './ModuleTypeDto';

/**
 * 
 * @export
 * @interface MailingCodeConfigModuleContentDto
 */
export interface MailingCodeConfigModuleContentDto {
    /**
     * 
     * @type {ModuleTypeDto}
     * @memberof MailingCodeConfigModuleContentDto
     */
    moduleType: ModuleTypeDto;
    /**
     * 
     * @type {Date}
     * @memberof MailingCodeConfigModuleContentDto
     */
    timestamp?: Date;
    /**
     * 
     * @type {CodeEditorContentDto}
     * @memberof MailingCodeConfigModuleContentDto
     */
    code?: CodeEditorContentDto;
}

/**
 * Check if a given object implements the MailingCodeConfigModuleContentDto interface.
 */
export function instanceOfMailingCodeConfigModuleContentDto(value: object): boolean {
    if (!('moduleType' in value)) return false;
    return true;
}

export function MailingCodeConfigModuleContentDtoFromJSON(json: any): MailingCodeConfigModuleContentDto {
    return MailingCodeConfigModuleContentDtoFromJSONTyped(json, false);
}

export function MailingCodeConfigModuleContentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingCodeConfigModuleContentDto {
    if (json == null) {
        return json;
    }
    return {
        
        'moduleType': ModuleTypeDtoFromJSON(json['moduleType']),
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
        'code': json['code'] == null ? undefined : CodeEditorContentDtoFromJSON(json['code']),
    };
}

export function MailingCodeConfigModuleContentDtoToJSON(value?: MailingCodeConfigModuleContentDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'moduleType': ModuleTypeDtoToJSON(value['moduleType']),
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
        'code': CodeEditorContentDtoToJSON(value['code']),
    };
}

