/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RecipientTypeDto } from './RecipientTypeDto';
import {
    RecipientTypeDtoFromJSON,
    RecipientTypeDtoFromJSONTyped,
    RecipientTypeDtoToJSON,
} from './RecipientTypeDto';

/**
 * Id of recipient and dependent type
 * @export
 * @interface RecipientLinkDto
 */
export interface RecipientLinkDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof RecipientLinkDto
     */
    id: string;
    /**
     * 
     * @type {RecipientTypeDto}
     * @memberof RecipientLinkDto
     */
    type: RecipientTypeDto;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof RecipientLinkDto
     */
    linkedRecipient?: { [key: string]: any; };
    /**
     * only set when the frontend saves a new email recipient.
     * @type {string}
     * @memberof RecipientLinkDto
     */
    email?: string;
}

/**
 * Check if a given object implements the RecipientLinkDto interface.
 */
export function instanceOfRecipientLinkDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('type' in value)) return false;
    return true;
}

export function RecipientLinkDtoFromJSON(json: any): RecipientLinkDto {
    return RecipientLinkDtoFromJSONTyped(json, false);
}

export function RecipientLinkDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecipientLinkDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': RecipientTypeDtoFromJSON(json['type']),
        'linkedRecipient': json['linkedRecipient'] == null ? undefined : json['linkedRecipient'],
        'email': json['email'] == null ? undefined : json['email'],
    };
}

export function RecipientLinkDtoToJSON(value?: RecipientLinkDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'type': RecipientTypeDtoToJSON(value['type']),
        'linkedRecipient': value['linkedRecipient'],
        'email': value['email'],
    };
}

