/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Status of the team.
 * @export
 */
export const TeamStatusDto = {
    ACTIVE: 'ACTIVE',
    ARCHIVED: 'ARCHIVED'
} as const;
export type TeamStatusDto = typeof TeamStatusDto[keyof typeof TeamStatusDto];


export function TeamStatusDtoFromJSON(json: any): TeamStatusDto {
    return TeamStatusDtoFromJSONTyped(json, false);
}

export function TeamStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamStatusDto {
    return json as TeamStatusDto;
}

export function TeamStatusDtoToJSON(value?: TeamStatusDto | null): any {
    return value as any;
}

