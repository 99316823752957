/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OccupationDto } from './OccupationDto';
import {
    OccupationDtoFromJSON,
    OccupationDtoFromJSONTyped,
    OccupationDtoToJSON,
} from './OccupationDto';

/**
 * Create relation between company and person with given attributes.
 * @export
 * @interface CreateCompanyPersonRelationRequestDto
 */
export interface CreateCompanyPersonRelationRequestDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof CreateCompanyPersonRelationRequestDto
     */
    person: string;
    /**
     * 
     * @type {OccupationDto}
     * @memberof CreateCompanyPersonRelationRequestDto
     */
    occupation: OccupationDto;
}

/**
 * Check if a given object implements the CreateCompanyPersonRelationRequestDto interface.
 */
export function instanceOfCreateCompanyPersonRelationRequestDto(value: object): boolean {
    if (!('person' in value)) return false;
    if (!('occupation' in value)) return false;
    return true;
}

export function CreateCompanyPersonRelationRequestDtoFromJSON(json: any): CreateCompanyPersonRelationRequestDto {
    return CreateCompanyPersonRelationRequestDtoFromJSONTyped(json, false);
}

export function CreateCompanyPersonRelationRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCompanyPersonRelationRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'person': json['person'],
        'occupation': OccupationDtoFromJSON(json['occupation']),
    };
}

export function CreateCompanyPersonRelationRequestDtoToJSON(value?: CreateCompanyPersonRelationRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'person': value['person'],
        'occupation': OccupationDtoToJSON(value['occupation']),
    };
}

