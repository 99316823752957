/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The result of a status check.
 * @export
 * @interface CompanyStatusCheckDto
 */
export interface CompanyStatusCheckDto {
    /**
     * 
     * @type {boolean}
     * @memberof CompanyStatusCheckDto
     */
    readonly isDeletable: boolean;
}

/**
 * Check if a given object implements the CompanyStatusCheckDto interface.
 */
export function instanceOfCompanyStatusCheckDto(value: object): boolean {
    if (!('isDeletable' in value)) return false;
    return true;
}

export function CompanyStatusCheckDtoFromJSON(json: any): CompanyStatusCheckDto {
    return CompanyStatusCheckDtoFromJSONTyped(json, false);
}

export function CompanyStatusCheckDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyStatusCheckDto {
    if (json == null) {
        return json;
    }
    return {
        
        'isDeletable': json['isDeletable'],
    };
}

export function CompanyStatusCheckDtoToJSON(value?: CompanyStatusCheckDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
    };
}

