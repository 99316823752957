/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MailingDto } from './MailingDto';
import {
    MailingDtoFromJSON,
    MailingDtoFromJSONTyped,
    MailingDtoToJSON,
} from './MailingDto';

/**
 * 
 * @export
 * @interface MailingSearchResultDto
 */
export interface MailingSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof MailingSearchResultDto
     */
    resultType: MailingSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof MailingSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {MailingDto}
     * @memberof MailingSearchResultDto
     */
    result: MailingDto;
}


/**
 * @export
 */
export const MailingSearchResultDtoResultTypeEnum = {
    MAILING: 'MAILING'
} as const;
export type MailingSearchResultDtoResultTypeEnum = typeof MailingSearchResultDtoResultTypeEnum[keyof typeof MailingSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the MailingSearchResultDto interface.
 */
export function instanceOfMailingSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function MailingSearchResultDtoFromJSON(json: any): MailingSearchResultDto {
    return MailingSearchResultDtoFromJSONTyped(json, false);
}

export function MailingSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': MailingDtoFromJSON(json['result']),
    };
}

export function MailingSearchResultDtoToJSON(value?: MailingSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': MailingDtoToJSON(value['result']),
    };
}

