import React from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchReport } from '../reporting-fetcher';

export default function ReportingBreadcrumb() {
  const { reportId } = useParams<{ reportId: string }>();
  invariant(reportId, 'Empty reportId');
  const report = useFetcher(fetchReport, { id: reportId });

  return <>{report.name}</>;
}
