import useSWR from 'swr';
import invariant from 'tiny-invariant';
import exportApi from '../../../data-access/export-api';
import { DataTableState } from '../../../ui/data-table';
import useRequestParams from '../../../ui/data-table/use-request-params';
import fallbackMiddleware from '../../../util/swr/fallback-middleware';

export default function useExports(state?: DataTableState) {
  const requestParams = useRequestParams(state, [
    {
      property: 'version.modifiedAt',
      direction: 'DESC',
    },
  ]);
  const { data: page, mutate } = useSWR(['exports', requestParams], () => exportApi.searchExports(requestParams), {
    use: [fallbackMiddleware],
    suspense: true,
  });
  invariant(page != null, 'Missing export page');

  return { page, mutate };
}
