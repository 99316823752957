import { FileMetadataDto } from '../../../api';
import formatFileSize from '../../../util/file-size/file-size';
import Translate from '../../../util/translate/translate';
import { AttachmentImage } from '../../attachments/attachment-image';
import { DataTableColumn } from '../../data-table';
import Optional from '../../optional/optional';

const imageColumns: DataTableColumn<FileMetadataDto>[] = [
  {
    key: 'logo',
    name: '',
    renderCell: (metadata) => {
      return <AttachmentImage metadata={metadata} />;
    },
  },
  {
    key: 'file_name',
    name: <Translate ns="attachment" i18nKey="file_name" />,
    renderCell: (metadata) => metadata.originalFileName,
  },
  {
    key: 'resolution',
    name: <Translate ns="attachment" i18nKey="resolution" />,
    renderCell: (metadata) => (
      <Optional isEmpty={metadata.width === 0 && metadata.height === 0}>
        <Translate ns="attachment">
          {(t) =>
            t('resolution_dimensions', {
              width: metadata.width,
              height: metadata.height,
            })
          }
        </Translate>
      </Optional>
    ),
  },
  {
    key: 'size',
    name: <Translate ns="attachment" i18nKey="file_size" />,
    renderCell: (data) => <Translate ns="common">{(t) => t('format.file_size', formatFileSize(data.size))}</Translate>,
  },
];

export default imageColumns;
