import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Link, Tooltip } from '@chakra-ui/react';
import { faCompass } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { LocationPostalAddressDto } from '../../../api';
import Optional from '../../../ui/optional/optional';
import { postalAddressToString, renderPostalAddress } from '../../../ui/postal-address/render-postal-address';

// see https://developers.google.com/maps/documentation/urls/get-started
const googleMapsQueryUrl = new URL('https://www.google.com/maps/search/?api=1');

export function renderPostalAddressWithLinkToCoordinates(postalAddress: LocationPostalAddressDto): React.ReactNode {
  if (postalAddress.coordinates) {
    const locationString = postalAddressToString(postalAddress);
    googleMapsQueryUrl.searchParams.set('query', postalAddress.coordinates);
    return (
      <Link href={googleMapsQueryUrl.href} isExternal>
        {locationString} <ExternalLinkIcon mx="2px" />
      </Link>
    );
  } else {
    return renderPostalAddress(postalAddress);
  }
}

export function renderPostalAddressWithCoordinateIcon(
  postalAddress: LocationPostalAddressDto,
  coordinateInfoText: string,
) {
  const locationString = postalAddressToString(postalAddress);
  googleMapsQueryUrl.searchParams.set('query', locationString);

  if (postalAddress.coordinates != null) {
    const coordinatesGoogleMapsQueryUrl = googleMapsQueryUrl;
    coordinatesGoogleMapsQueryUrl.searchParams.set('query', postalAddress.coordinates);
    return (
      <>
        <Link href={googleMapsQueryUrl.href} isExternal>
          {locationString} <ExternalLinkIcon mx="2px" />
        </Link>
        <Tooltip label={coordinateInfoText}>
          <Link href={coordinatesGoogleMapsQueryUrl.href} isExternal>
            <FontAwesomeIcon icon={faCompass} />
          </Link>
        </Tooltip>
      </>
    );
  }
  return (
    <Link href={googleMapsQueryUrl.href} isExternal>
      {locationString} <ExternalLinkIcon mx="2px" />
    </Link>
  );
}

export function renderCoordinates(postalAddress: LocationPostalAddressDto): React.ReactNode {
  if (postalAddress.coordinates != null) {
    googleMapsQueryUrl.searchParams.set('query', postalAddress.coordinates);
    return (
      <Link href={googleMapsQueryUrl.href} isExternal>
        {postalAddress.coordinates} <ExternalLinkIcon mx="2px" />
      </Link>
    );
  } else {
    return <Optional>{postalAddress.coordinates}</Optional>;
  }
}
