import { GroupBase } from 'chakra-react-select';
import React from 'react';
import { ReactSelectVariant } from '../../theme/component/react-select';
import Select, { SelectProps } from './select';
import useOptionStringValue from './use-option-string-value';
import useSortedOptions from './use-sorted-options';

export interface ValueSelectProps<
  TOption,
  TGroup extends GroupBase<{ value: TOption; label: React.ReactNode }> = GroupBase<{
    value: TOption;
    label: React.ReactNode;
  }>,
> extends Omit<
    SelectProps<{ value: TOption; label: React.ReactNode }, false, TGroup>,
    | 'onChange'
    | 'value'
    | 'options'
    | 'isMulti'
    | 'defaultValue'
    | 'isSearchable'
    | 'variant'
    | 'getOptionValue'
    | 'getOptionLabel'
  > {
  options: TOption[];
  renderLabel: (value: TOption) => React.ReactNode;
  getStringValue?: (value: TOption) => string;
  onChange: (value: TOption | null) => void;
  value: TOption | null;
  defaultValue?: TOption | null;
  variant?: ReactSelectVariant;
}

/**
 *
 */
function ValueSelect<T>(
  { options, renderLabel, getStringValue, value, onChange, defaultValue, ...props }: ValueSelectProps<T>,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const valueOptions = options.map((value) => ({
    value,
    label: renderLabel(value),
  }));
  const selectedOption = value != null ? { value: value, label: renderLabel(value) } : null;
  const defaultValueOption = defaultValue ? { value: defaultValue, label: renderLabel(defaultValue) } : undefined;

  props = useSortedOptions({
    ...useOptionStringValue(props, getStringValue),
    options: valueOptions,
  });

  return (
    <Select
      {...props}
      isMulti={false}
      value={selectedOption}
      defaultValue={defaultValueOption}
      formatOptionLabel={(option) => option.label}
      isSearchable
      onChange={(selectedOption) => {
        onChange(selectedOption?.value ?? null);
      }}
      ref={ref}
    />
  );
}

export default React.forwardRef(ValueSelect);
