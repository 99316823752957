import { ReactNode } from 'react';
import { PageState } from '../../../ui/pagination/use-pagination-state';
import { PluginToken } from '../../../util/plugin/use-plugins';

export interface StandRelationHistoryExtension {
  content: (standId: string, state: PageState, onStageChanged: (state: PageState) => void) => ReactNode;
}

export const STAND_RELATION_HISTORY_EXTENSION = new PluginToken<StandRelationHistoryExtension>(
  'StandRelationHistoryExtension',
);
