import React from 'react';
import { ListReferenceDto } from '../../../api';
import { listIcon } from '../../../ui/icons/business-objects';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';

interface ListReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  list: ListReferenceDto;
}

export default function ListReference({ list, ...props }: ListReferenceProps) {
  return (
    <Reference icon={listIcon} to={'/lists/' + list.id} {...props}>
      {list.name}
    </Reference>
  );
}
