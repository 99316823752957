import registry from '../../registry';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import ACCESS_GROUP_CHILD_ROUTES from './access-group-child-routes';
import AccessGroupBreadcrumb from './common/access-group-breadcrumb';

const accessGroupRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'access-groups',
    handle: {
      breadcrumb: <Translate ns="access_group" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./access-group-lister/access-group-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./access-group-editor/new-access-group-route'),
      },
      {
        path: ':accessGroupId',
        handle: {
          breadcrumb: <AccessGroupBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./access-group-page/access-group-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./access-group-viewer/access-group-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./access-group-history/access-group-history-route'),
              },
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./access-group-editor/edit-access-group-route'),
          },
          ...registry.getAll(ACCESS_GROUP_CHILD_ROUTES).flat(),
        ],
      },
    ],
  },
];

export default accessGroupRoutes;
