import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  IconButton,
  Link,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { faArrowUpRightFromSquare, faCopy, faGlobeAmericas, faSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isAfter } from 'date-fns';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { PublicationDto } from '../../../api';
import DataTable, { DataTablePage, useDataTableState } from '../../../ui/data-table';
import DataTableColumn from '../../../ui/data-table/data-table-column';
import useRequestParams from '../../../ui/data-table/use-request-params';
import useToast from '../../../ui/use-toast/use-toast';
import now from '../../../util/now';
import Fetcher from '../../../util/swr/fetcher';
import useFetcher from '../../../util/swr/use-fetcher';

interface PublicationDataTableProps<DTO extends PublicationDto, PAGE extends DataTablePage<DTO>> {
  baseColumns: DataTableColumn<DTO>[];
  additionalColumns?: DataTableColumn<DTO>[];
  onSuspend: (publication: DTO) => Promise<void>;
  publicationFetcher: Fetcher<{ eventId: string }, PAGE, any>;
  linkContext: string;
  canSuspendPublication: boolean;
}

export default function PublicationDataTable<DTO extends PublicationDto, PAGE extends DataTablePage<DTO>>({
  baseColumns,
  additionalColumns,
  onSuspend,
  publicationFetcher,
  linkContext,
  canSuspendPublication,
}: PublicationDataTableProps<DTO, PAGE>) {
  const { t } = useTranslation('event');
  const { t: tCommon } = useTranslation('common');

  const { eventId } = useParams<{ eventId: string }>();
  invariant(eventId != null, 'Missing eventId');

  const [state, setState] = useDataTableState({ size: 100 });

  const requestParams = useRequestParams(state, [
    {
      property: 'dateOfPublication',
      direction: 'DESC',
    },
  ]);

  const page = useFetcher(publicationFetcher, { ...requestParams, eventId });

  invariant(page != null, 'Rundown Publications must not be null');

  const closeButtonRef = React.useRef<HTMLButtonElement>(null);
  const [publicationToSuspend, setPublicationToSuspend] = useState<DTO>();
  const { isOpen: suspendDialogIsOpen, onOpen: onSuspendDialogOpen, onClose: onSuspendDialogClose } = useDisclosure();

  const rowKey = React.useCallback((publication: DTO) => {
    return publication.id;
  }, []);

  const showSuspendSuccessToast = useToast({
    id: 'publication-suspend-success-toast',
    status: 'success',
  });

  const showCopyToast = useToast({
    id: 'publication-copy-toast',
    status: 'success',
  });

  const handleSuspend = async () => {
    invariant(publicationToSuspend?.id != null, 'publication id must be set');
    await onSuspend({ ...(publicationToSuspend as DTO) });
    showSuspendSuccessToast({
      title: t('publication.suspendToast.title'),
      description: t('publication.suspendToast.description', {
        firstName: publicationToSuspend.recipient.firstName,
        surname: publicationToSuspend.recipient.surname,
      }),
    });

    onSuspendDialogClose();
  };

  const getLink = React.useCallback(
    (publication: DTO) => {
      return `${document.location.origin}/public/${linkContext}/${publication.id}`;
    },
    [linkContext],
  );

  const copyLink = React.useCallback(
    (publication: DTO) => {
      const url = getLink(publication);
      const type = 'text/plain';
      const blob = new Blob([url], { type });
      const data = [new ClipboardItem({ [type]: blob })];

      navigator.clipboard.write(data).then(() => {
        showCopyToast({
          description: t('publication.copy_toast'),
        });
      });
    },
    [getLink, showCopyToast, t],
  );

  function isExpired(expiryDate: Date) {
    return isAfter(now(), expiryDate);
  }

  const columns: DataTableColumn<DTO>[] = React.useMemo(() => {
    return [
      ...baseColumns,
      ...(additionalColumns ?? []),
      {
        key: 'action',
        sticky: true,
        renderCell: (publication) =>
          publication.suspended == null &&
          !isExpired(publication.expiryDate) && (
            <Flex>
              <Tooltip label={t('publication.external_viewer')} placement="bottom-end">
                <IconButton
                  as={Link}
                  variant="ghost"
                  size="sm"
                  href={getLink(publication)}
                  isExternal
                  icon={<FontAwesomeIcon size="sm" icon={faArrowUpRightFromSquare} />}
                  aria-label={t('publication.external_viewer')}
                />
              </Tooltip>

              <Tooltip label={t('publication.copy')} placement="bottom-end">
                <IconButton
                  size="sm"
                  variant="ghost"
                  icon={<FontAwesomeIcon icon={faCopy} />}
                  onClick={() => copyLink(publication)}
                  aria-label={t('publication.copy')}
                />
              </Tooltip>

              {canSuspendPublication && (
                <Tooltip label={t('publication.suspend')} placement="bottom-end">
                  <IconButton
                    onClick={() => {
                      setPublicationToSuspend(publication);
                      onSuspendDialogOpen();
                    }}
                    size="sm"
                    colorScheme="red"
                    variant="ghost"
                    icon={
                      <span className="fa-layers fa-fw">
                        <FontAwesomeIcon icon={faSlash} />
                        <FontAwesomeIcon icon={faGlobeAmericas} />
                      </span>
                    }
                    aria-label={t('publication.suspend')}
                  />
                </Tooltip>
              )}
            </Flex>
          ),
      },
    ];
  }, [additionalColumns, baseColumns, copyLink, getLink, canSuspendPublication, onSuspendDialogOpen, t]);

  return (
    <>
      <DataTable<DTO>
        page={page}
        state={state}
        columns={columns}
        rowKey={rowKey}
        onStateChange={setState}
        isPageable={true}
        rowStyle={(data) =>
          data.suspendedBy != null || isExpired(data.expiryDate) ? { bg: 'background.muted', color: 'text.muted' } : {}
        }
      />
      <AlertDialog isOpen={suspendDialogIsOpen} onClose={onSuspendDialogClose} leastDestructiveRef={closeButtonRef}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>{t('publication.suspendHeader')}</AlertDialogHeader>
          <AlertDialogBody>
            <Trans
              t={t}
              i18nKey="publication.suspendBody"
              values={{
                firstName: publicationToSuspend?.recipient.firstName,
                surname: publicationToSuspend?.recipient.surname,
              }}
            />
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onSuspendDialogClose} mr={3} ref={closeButtonRef}>
              {tCommon('action.abort')}
            </Button>
            <Button onClick={handleSuspend} colorScheme="red">
              {t('publication.suspend')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
