// @see https://github.com/preactjs/signals/blob/main/packages/react/CHANGELOG.md#200
import '@preact/signals-react/auto';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import invariant from 'tiny-invariant';
import router from './router';

// @ts-expect-error todo
window.GlobalFetch = window;

const container = document.getElementById('root');
invariant(container != null);

const root = createRoot(container);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
