/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyReferenceDto } from './CompanyReferenceDto';
import {
    CompanyReferenceDtoFromJSON,
    CompanyReferenceDtoFromJSONTyped,
    CompanyReferenceDtoToJSON,
} from './CompanyReferenceDto';
import type { OccupationFormatConfirmationStatusDto } from './OccupationFormatConfirmationStatusDto';
import {
    OccupationFormatConfirmationStatusDtoFromJSON,
    OccupationFormatConfirmationStatusDtoFromJSONTyped,
    OccupationFormatConfirmationStatusDtoToJSON,
} from './OccupationFormatConfirmationStatusDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { ProfessionDto } from './ProfessionDto';
import {
    ProfessionDtoFromJSON,
    ProfessionDtoFromJSONTyped,
    ProfessionDtoToJSON,
} from './ProfessionDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';
import type { WorkingSectorDto } from './WorkingSectorDto';
import {
    WorkingSectorDtoFromJSON,
    WorkingSectorDtoFromJSONTyped,
    WorkingSectorDtoToJSON,
} from './WorkingSectorDto';

/**
 * Occupation relations by format
 * @export
 * @interface FormatOccupationListItemDto
 */
export interface FormatOccupationListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof FormatOccupationListItemDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof FormatOccupationListItemDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {PersonReferenceDto}
     * @memberof FormatOccupationListItemDto
     */
    person: PersonReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof FormatOccupationListItemDto
     */
    jobTitle: string;
    /**
     * 
     * @type {ProfessionDto}
     * @memberof FormatOccupationListItemDto
     */
    profession: ProfessionDto;
    /**
     * 
     * @type {Array<WorkingSectorDto>}
     * @memberof FormatOccupationListItemDto
     */
    workingSectors: Array<WorkingSectorDto>;
    /**
     * 
     * @type {CompanyReferenceDto}
     * @memberof FormatOccupationListItemDto
     */
    company?: CompanyReferenceDto;
    /**
     * 
     * @type {OccupationFormatConfirmationStatusDto}
     * @memberof FormatOccupationListItemDto
     */
    confirmationStatus: OccupationFormatConfirmationStatusDto;
}

/**
 * Check if a given object implements the FormatOccupationListItemDto interface.
 */
export function instanceOfFormatOccupationListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('person' in value)) return false;
    if (!('jobTitle' in value)) return false;
    if (!('profession' in value)) return false;
    if (!('workingSectors' in value)) return false;
    if (!('confirmationStatus' in value)) return false;
    return true;
}

export function FormatOccupationListItemDtoFromJSON(json: any): FormatOccupationListItemDto {
    return FormatOccupationListItemDtoFromJSONTyped(json, false);
}

export function FormatOccupationListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormatOccupationListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'person': PersonReferenceDtoFromJSON(json['person']),
        'jobTitle': json['jobTitle'],
        'profession': ProfessionDtoFromJSON(json['profession']),
        'workingSectors': ((json['workingSectors'] as Array<any>).map(WorkingSectorDtoFromJSON)),
        'company': json['company'] == null ? undefined : CompanyReferenceDtoFromJSON(json['company']),
        'confirmationStatus': OccupationFormatConfirmationStatusDtoFromJSON(json['confirmationStatus']),
    };
}

export function FormatOccupationListItemDtoToJSON(value?: FormatOccupationListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'person': PersonReferenceDtoToJSON(value['person']),
        'jobTitle': value['jobTitle'],
        'profession': ProfessionDtoToJSON(value['profession']),
        'workingSectors': ((value['workingSectors'] as Array<any>).map(WorkingSectorDtoToJSON)),
        'company': CompanyReferenceDtoToJSON(value['company']),
        'confirmationStatus': OccupationFormatConfirmationStatusDtoToJSON(value['confirmationStatus']),
    };
}

