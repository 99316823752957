import { Button, Flex, Heading, HStack, Spacer, Stack, useDisclosure } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PersonDto, PersonStatusDto, StandPersonListItemDto } from '../../../api';
import standApi from '../../../data-access/stand-api';
import { DataTableColumn } from '../../../ui/data-table';
import DataTableTruncatedText from '../../../ui/data-table/data-table-truncated-text';
import StringFilter from '../../../ui/data-table/filter/string-filter';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import useEditionFromSearchParams from '../../../util/use-edition-from-search-param/use-edition-from-search-param';
import EditionSelectButton from '../../edition/edition-select-button';
import useActiveEdition from '../../edition/use-active-edition/use-active-edition';
import usePermission from '../../permission/use-permission';
import StandReference from '../stand-reference/stand-reference';
import PersonStandRelationEditorDialog from './person-stand-relation-editor-dialog';
import StandPersonDataTable from './stand-person-data-table';

export function PersonStandRelations({ person }: { person: PersonDto }) {
  const { t } = useTranslation('stand');
  const activeEdition = useActiveEdition();
  const { hasPermission } = usePermission();
  const { edition, setEdition } = useEditionFromSearchParams(activeEdition);
  const { isOpen: editDialogIsOpen, onOpen: onEditDialogOpen, onClose: onEditDialogClose } = useDisclosure();

  return (
    <Stack spacing={4} maxH="full">
      <Flex as="header">
        <HStack spacing={4}>
          <Heading size="sm" as="h4">
            {t('lister.title')}
          </Heading>
          <EditionSelectButton edition={edition} onChange={setEdition} buttonLabel={edition.name} />
        </HStack>
        <Spacer />
        {hasPermission('PERSON-STAND:CAN_LINK') && (
          <Button
            as={DisableableButton}
            isDisabled={person.status != PersonStatusDto.ACTIVE}
            disableReason={t('personRelation.personIsNotActiveMessage')}
            variant="primary"
            leftIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={onEditDialogOpen}
            aria-label={t('personRelation.actions.addStand')}
          >
            {t('personRelation.actions.addStand')}
          </Button>
        )}
      </Flex>
      <StandPersonDataTable
        primaryColumn={useStandColumn()}
        fallbackSort={[{ property: 'stand.name', direction: 'ASC' }]}
        forcedFilter={[
          { property: 'person.id', operator: 'eq', value: person.id },
          { property: 'edition.id', operator: 'eq', value: edition.id },
        ]}
      />
      <PersonStandRelationEditorDialog isOpen={editDialogIsOpen} onClose={onEditDialogClose} person={person} />
    </Stack>
  );
}

function useStandColumn(): DataTableColumn<StandPersonListItemDto> {
  const { t } = useTranslation(['stand']);

  return useMemo(
    () => ({
      key: 'name',
      sticky: true,
      name: t('stand:name'),
      cellProps: {
        whiteSpace: 'nowrap',
        width: '25%',
      },
      isSortable: true,
      renderCell: (standPerson) => (
        <DataTableTruncatedText>
          <StandReference stand={standPerson.stand} />
        </DataTableTruncatedText>
      ),
      filter: (
        <StringFilter
          label={t('stand:name')}
          loadOptions={async (value: string) => {
            const page = await standApi.searchStandListItems({
              pageable: { size: 10 },
              filter: [`name,contain,${value}`],
            });

            return page.content.map((stand) => stand.name);
          }}
        />
      ),
    }),
    [t],
  );
}
