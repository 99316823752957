/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  PublishedLaneElementDto,
  PublishedRundownSettingsDto,
  RundownCalendarDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    PublishedLaneElementDtoFromJSON,
    PublishedLaneElementDtoToJSON,
    PublishedRundownSettingsDtoFromJSON,
    PublishedRundownSettingsDtoToJSON,
    RundownCalendarDtoFromJSON,
    RundownCalendarDtoToJSON,
} from '../models/index';

export interface FetchPublishedLaneElementRequest {
    publicationId: string;
    elementId: string;
}

export interface FetchPublishedRundownCalendarRequest {
    publicationId: string;
}

export interface FetchPublishedRundownSettingsRequest {
    publicationId: string;
}

/**
 * 
 */
export class PublicRundownApi extends runtime.BaseAPI {

    /**
     * Get the lane element for published entity.
     */
    async fetchPublishedLaneElementRaw(requestParameters: FetchPublishedLaneElementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublishedLaneElementDto>> {
        if (requestParameters['publicationId'] == null) {
            throw new runtime.RequiredError(
                'publicationId',
                'Required parameter "publicationId" was null or undefined when calling fetchPublishedLaneElement().'
            );
        }

        if (requestParameters['elementId'] == null) {
            throw new runtime.RequiredError(
                'elementId',
                'Required parameter "elementId" was null or undefined when calling fetchPublishedLaneElement().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/public/rundown/{publicationId}/element/{elementId}`.replace(`{${"publicationId"}}`, encodeURIComponent(String(requestParameters['publicationId']))).replace(`{${"elementId"}}`, encodeURIComponent(String(requestParameters['elementId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublishedLaneElementDtoFromJSON(jsonValue));
    }

    /**
     * Get the lane element for published entity.
     */
    async fetchPublishedLaneElement(requestParameters: FetchPublishedLaneElementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublishedLaneElementDto> {
        const response = await this.fetchPublishedLaneElementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get published entity.
     */
    async fetchPublishedRundownCalendarRaw(requestParameters: FetchPublishedRundownCalendarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RundownCalendarDto>> {
        if (requestParameters['publicationId'] == null) {
            throw new runtime.RequiredError(
                'publicationId',
                'Required parameter "publicationId" was null or undefined when calling fetchPublishedRundownCalendar().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/public/rundown/{publicationId}`.replace(`{${"publicationId"}}`, encodeURIComponent(String(requestParameters['publicationId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RundownCalendarDtoFromJSON(jsonValue));
    }

    /**
     * Get published entity.
     */
    async fetchPublishedRundownCalendar(requestParameters: FetchPublishedRundownCalendarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RundownCalendarDto> {
        const response = await this.fetchPublishedRundownCalendarRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get settings for published entity.
     */
    async fetchPublishedRundownSettingsRaw(requestParameters: FetchPublishedRundownSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublishedRundownSettingsDto>> {
        if (requestParameters['publicationId'] == null) {
            throw new runtime.RequiredError(
                'publicationId',
                'Required parameter "publicationId" was null or undefined when calling fetchPublishedRundownSettings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/public/rundown/{publicationId}/settings`.replace(`{${"publicationId"}}`, encodeURIComponent(String(requestParameters['publicationId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublishedRundownSettingsDtoFromJSON(jsonValue));
    }

    /**
     * Get settings for published entity.
     */
    async fetchPublishedRundownSettings(requestParameters: FetchPublishedRundownSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublishedRundownSettingsDto> {
        const response = await this.fetchPublishedRundownSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
