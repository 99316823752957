/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LanguagesDto } from './LanguagesDto';
import {
    LanguagesDtoFromJSON,
    LanguagesDtoFromJSONTyped,
    LanguagesDtoToJSON,
} from './LanguagesDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * An account representing an external user of the system.
 * @export
 * @interface AccountDto
 */
export interface AccountDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof AccountDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof AccountDto
     */
    version?: VersionDto;
    /**
     * An email address
     * @type {string}
     * @memberof AccountDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    surname: string;
    /**
     * 
     * @type {LanguagesDto}
     * @memberof AccountDto
     */
    language: LanguagesDto;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    status: AccountDtoStatusEnum;
    /**
     * The professional type refers to a person.
     * @type {string}
     * @memberof AccountDto
     */
    type: AccountDtoTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDto
     */
    enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDto
     */
    emailVerified: boolean;
    /**
     * 
     * @type {Date}
     * @memberof AccountDto
     */
    synchronizedAt?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountDto
     */
    emailOptions: Array<string>;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof AccountDto
     */
    extensions: { [key: string]: any; };
}


/**
 * @export
 */
export const AccountDtoStatusEnum = {
    UNCONFIRMED: 'UNCONFIRMED',
    CONFIRMED: 'CONFIRMED',
    LOCKED: 'LOCKED'
} as const;
export type AccountDtoStatusEnum = typeof AccountDtoStatusEnum[keyof typeof AccountDtoStatusEnum];

/**
 * @export
 */
export const AccountDtoTypeEnum = {
    BASIC: 'BASIC',
    PROFESSIONAL: 'PROFESSIONAL'
} as const;
export type AccountDtoTypeEnum = typeof AccountDtoTypeEnum[keyof typeof AccountDtoTypeEnum];


/**
 * Check if a given object implements the AccountDto interface.
 */
export function instanceOfAccountDto(value: object): boolean {
    if (!('email' in value)) return false;
    if (!('firstName' in value)) return false;
    if (!('surname' in value)) return false;
    if (!('language' in value)) return false;
    if (!('status' in value)) return false;
    if (!('type' in value)) return false;
    if (!('enabled' in value)) return false;
    if (!('emailVerified' in value)) return false;
    if (!('emailOptions' in value)) return false;
    if (!('extensions' in value)) return false;
    return true;
}

export function AccountDtoFromJSON(json: any): AccountDto {
    return AccountDtoFromJSONTyped(json, false);
}

export function AccountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'email': json['email'],
        'firstName': json['firstName'],
        'surname': json['surname'],
        'language': LanguagesDtoFromJSON(json['language']),
        'status': json['status'],
        'type': json['type'],
        'enabled': json['enabled'],
        'emailVerified': json['emailVerified'],
        'synchronizedAt': json['synchronizedAt'] == null ? undefined : (new Date(json['synchronizedAt'])),
        'emailOptions': json['emailOptions'],
        'extensions': json['extensions'],
    };
}

export function AccountDtoToJSON(value?: AccountDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'email': value['email'],
        'firstName': value['firstName'],
        'surname': value['surname'],
        'language': LanguagesDtoToJSON(value['language']),
        'status': value['status'],
        'type': value['type'],
        'enabled': value['enabled'],
        'emailVerified': value['emailVerified'],
        'synchronizedAt': value['synchronizedAt'] == null ? undefined : ((value['synchronizedAt']).toISOString()),
        'emailOptions': value['emailOptions'],
        'extensions': value['extensions'],
    };
}

