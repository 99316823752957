/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DateTimeRangeDto } from './DateTimeRangeDto';
import {
    DateTimeRangeDtoFromJSON,
    DateTimeRangeDtoFromJSONTyped,
    DateTimeRangeDtoToJSON,
} from './DateTimeRangeDto';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface LandingPageListItemDto
 */
export interface LandingPageListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof LandingPageListItemDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof LandingPageListItemDto
     */
    version: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof LandingPageListItemDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPageListItemDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof LandingPageListItemDto
     */
    brand: string;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof LandingPageListItemDto
     */
    edition?: EditionReferenceDto;
    /**
     * 
     * @type {DateTimeRangeDto}
     * @memberof LandingPageListItemDto
     */
    active: DateTimeRangeDto;
}

/**
 * Check if a given object implements the LandingPageListItemDto interface.
 */
export function instanceOfLandingPageListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('version' in value)) return false;
    if (!('name' in value)) return false;
    if (!('type' in value)) return false;
    if (!('brand' in value)) return false;
    if (!('active' in value)) return false;
    return true;
}

export function LandingPageListItemDtoFromJSON(json: any): LandingPageListItemDto {
    return LandingPageListItemDtoFromJSONTyped(json, false);
}

export function LandingPageListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': VersionDtoFromJSON(json['version']),
        'name': json['name'],
        'type': json['type'],
        'brand': json['brand'],
        'edition': json['edition'] == null ? undefined : EditionReferenceDtoFromJSON(json['edition']),
        'active': DateTimeRangeDtoFromJSON(json['active']),
    };
}

export function LandingPageListItemDtoToJSON(value?: LandingPageListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'name': value['name'],
        'type': value['type'],
        'brand': value['brand'],
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'active': DateTimeRangeDtoToJSON(value['active']),
    };
}

