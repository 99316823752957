/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { CommonPersonAnonymisationBlockerDto } from './CommonPersonAnonymisationBlockerDto';
import {
    instanceOfCommonPersonAnonymisationBlockerDto,
    CommonPersonAnonymisationBlockerDtoFromJSON,
    CommonPersonAnonymisationBlockerDtoFromJSONTyped,
    CommonPersonAnonymisationBlockerDtoToJSON,
} from './CommonPersonAnonymisationBlockerDto';
import type { PersonHasMarkerBlockerDto } from './PersonHasMarkerBlockerDto';
import {
    instanceOfPersonHasMarkerBlockerDto,
    PersonHasMarkerBlockerDtoFromJSON,
    PersonHasMarkerBlockerDtoFromJSONTyped,
    PersonHasMarkerBlockerDtoToJSON,
} from './PersonHasMarkerBlockerDto';
import type { PersonIsInRundownBlockerDto } from './PersonIsInRundownBlockerDto';
import {
    instanceOfPersonIsInRundownBlockerDto,
    PersonIsInRundownBlockerDtoFromJSON,
    PersonIsInRundownBlockerDtoFromJSONTyped,
    PersonIsInRundownBlockerDtoToJSON,
} from './PersonIsInRundownBlockerDto';
import type { PersonIsKeyPlayerBlockerDto } from './PersonIsKeyPlayerBlockerDto';
import {
    instanceOfPersonIsKeyPlayerBlockerDto,
    PersonIsKeyPlayerBlockerDtoFromJSON,
    PersonIsKeyPlayerBlockerDtoFromJSONTyped,
    PersonIsKeyPlayerBlockerDtoToJSON,
} from './PersonIsKeyPlayerBlockerDto';
import type { PersonIsOnGuestListBlockerDto } from './PersonIsOnGuestListBlockerDto';
import {
    instanceOfPersonIsOnGuestListBlockerDto,
    PersonIsOnGuestListBlockerDtoFromJSON,
    PersonIsOnGuestListBlockerDtoFromJSONTyped,
    PersonIsOnGuestListBlockerDtoToJSON,
} from './PersonIsOnGuestListBlockerDto';

/**
 * @type PersonAnonymisationBlockerDto
 * Describes the reason why a person can not be anonymised.
 * @export
 */
export type PersonAnonymisationBlockerDto = { reason: 'PERSON_HAS_MARKER' } & PersonHasMarkerBlockerDto | { reason: 'PERSON_IS_IN_RUNDOWN' } & PersonIsInRundownBlockerDto | { reason: 'PERSON_IS_KEY_PLAYER' } & PersonIsKeyPlayerBlockerDto | { reason: 'PERSON_IS_ON_GUEST_LIST' } & PersonIsOnGuestListBlockerDto | { reason: 'UNKNOWN' } & CommonPersonAnonymisationBlockerDto;

export function PersonAnonymisationBlockerDtoFromJSON(json: any): PersonAnonymisationBlockerDto {
    return PersonAnonymisationBlockerDtoFromJSONTyped(json, false);
}

export function PersonAnonymisationBlockerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonAnonymisationBlockerDto {
    if (json == null) {
        return json;
    }
    switch (json['reason']) {
        case 'PERSON_HAS_MARKER':
            return {...PersonHasMarkerBlockerDtoFromJSONTyped(json, true), reason: 'PERSON_HAS_MARKER'};
        case 'PERSON_IS_IN_RUNDOWN':
            return {...PersonIsInRundownBlockerDtoFromJSONTyped(json, true), reason: 'PERSON_IS_IN_RUNDOWN'};
        case 'PERSON_IS_KEY_PLAYER':
            return {...PersonIsKeyPlayerBlockerDtoFromJSONTyped(json, true), reason: 'PERSON_IS_KEY_PLAYER'};
        case 'PERSON_IS_ON_GUEST_LIST':
            return {...PersonIsOnGuestListBlockerDtoFromJSONTyped(json, true), reason: 'PERSON_IS_ON_GUEST_LIST'};
        case 'UNKNOWN':
            return {...CommonPersonAnonymisationBlockerDtoFromJSONTyped(json, true), reason: 'UNKNOWN'};
        default:
            throw new Error(`No variant of PersonAnonymisationBlockerDto exists with 'reason=${json['reason']}'`);
    }
}

export function PersonAnonymisationBlockerDtoToJSON(value?: PersonAnonymisationBlockerDto | null): any {
    if (value == null) {
        return value;
    }
    switch (value['reason']) {
        case 'PERSON_HAS_MARKER':
            return PersonHasMarkerBlockerDtoToJSON(value);
        case 'PERSON_IS_IN_RUNDOWN':
            return PersonIsInRundownBlockerDtoToJSON(value);
        case 'PERSON_IS_KEY_PLAYER':
            return PersonIsKeyPlayerBlockerDtoToJSON(value);
        case 'PERSON_IS_ON_GUEST_LIST':
            return PersonIsOnGuestListBlockerDtoToJSON(value);
        case 'UNKNOWN':
            return CommonPersonAnonymisationBlockerDtoToJSON(value);
        default:
            throw new Error(`No variant of PersonAnonymisationBlockerDto exists with 'reason=${value['reason']}'`);
    }

}

