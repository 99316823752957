import { ColorProps } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';

export type StatusDisplayOptions = {
  bgColor: ColorProps['color'];
  icon: IconProp;
  iconColor: ColorProps['color'];
};

export function createStatusDisplayOptions<StatusType extends string>(
  positiveStatus: StatusType,
  negativeStatus: StatusType,
): Record<StatusType, StatusDisplayOptions> {
  return {
    [positiveStatus]: {
      icon: faCheckCircle,
      iconColor: 'text.success',
      bgColor: 'background.success',
    },
    [negativeStatus]: {
      bgColor: 'background.error',
      icon: faTimesCircle,
      iconColor: 'text.error',
    },
  } as unknown as Record<StatusType, StatusDisplayOptions>;
}
