/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchPermissionDto
 */
export interface SearchPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof SearchPermissionDto
     */
    name: SearchPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchPermissionDto
     */
    action: SearchPermissionDtoActionEnum;
}


/**
 * @export
 */
export const SearchPermissionDtoNameEnum = {
    SEARCH: 'SEARCH'
} as const;
export type SearchPermissionDtoNameEnum = typeof SearchPermissionDtoNameEnum[keyof typeof SearchPermissionDtoNameEnum];

/**
 * @export
 */
export const SearchPermissionDtoActionEnum = {
    CAN_USE: 'CAN_USE'
} as const;
export type SearchPermissionDtoActionEnum = typeof SearchPermissionDtoActionEnum[keyof typeof SearchPermissionDtoActionEnum];


/**
 * Check if a given object implements the SearchPermissionDto interface.
 */
export function instanceOfSearchPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function SearchPermissionDtoFromJSON(json: any): SearchPermissionDto {
    return SearchPermissionDtoFromJSONTyped(json, false);
}

export function SearchPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function SearchPermissionDtoToJSON(value?: SearchPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

