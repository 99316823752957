import React from 'react';
import { AwardDto } from '../../../api';
import Translate from '../../../util/translate/translate';
import { AwardListerColumnExtension } from '../../award/award-lister/award-lister-column-extension';
import useActiveEdition from '../../edition/use-active-edition/use-active-edition';
import PersonAwardReference from './person-award-reference';

export const awardListerWinnerColumn: AwardListerColumnExtension = {
  columnToAdd: {
    key: 'winners',
    name: <PersonAwardColumnName />,
    cellProps: {
      whiteSpace: 'nowrap',
      textAlign: 'left',
    },
    renderCell: (data: AwardDto) => <PersonAwardReference award={data} />,
    isSortable: false,
  },
};

function PersonAwardColumnName() {
  const edition = useActiveEdition();

  return (
    <>
      <Translate ns="award" i18nKey="award-relation-viewer-route" />
      <br /> ({edition.name})
    </>
  );
}
