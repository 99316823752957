/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LandingPageTemplateDto } from './LandingPageTemplateDto';
import {
    LandingPageTemplateDtoFromJSON,
    LandingPageTemplateDtoFromJSONTyped,
    LandingPageTemplateDtoToJSON,
} from './LandingPageTemplateDto';

/**
 * 
 * @export
 * @interface LandingPageTemplateSearchResultDto
 */
export interface LandingPageTemplateSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof LandingPageTemplateSearchResultDto
     */
    resultType: LandingPageTemplateSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof LandingPageTemplateSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {LandingPageTemplateDto}
     * @memberof LandingPageTemplateSearchResultDto
     */
    result: LandingPageTemplateDto;
}


/**
 * @export
 */
export const LandingPageTemplateSearchResultDtoResultTypeEnum = {
    LANDING_PAGE_TEMPLATE: 'LANDING_PAGE_TEMPLATE'
} as const;
export type LandingPageTemplateSearchResultDtoResultTypeEnum = typeof LandingPageTemplateSearchResultDtoResultTypeEnum[keyof typeof LandingPageTemplateSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the LandingPageTemplateSearchResultDto interface.
 */
export function instanceOfLandingPageTemplateSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function LandingPageTemplateSearchResultDtoFromJSON(json: any): LandingPageTemplateSearchResultDto {
    return LandingPageTemplateSearchResultDtoFromJSONTyped(json, false);
}

export function LandingPageTemplateSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageTemplateSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': LandingPageTemplateDtoFromJSON(json['result']),
    };
}

export function LandingPageTemplateSearchResultDtoToJSON(value?: LandingPageTemplateSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': LandingPageTemplateDtoToJSON(value['result']),
    };
}

